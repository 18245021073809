import React, { Component } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import './users-styles.scss';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import PageTitle from '../../../components/PageTitle';
import userIcon from '../../../assets/images/user_avtar.png';

class IndividualUser extends Component {
    componentDidMount() {
        this.props.getSites()
        this.props.toggleNotification(false)
        if (this.props.location.pathname.indexOf('users') !== -1) {
            if (this.props.match.params && this.props.match.params.userId) {
                this.props.setPageTitle(labels.user_labels.edit_user)
                this.props.setCurrentUser(this.props.match.params.userId)
                this.props.setBreadCrumbs([
                    { text: labels.physioage_label, path: '/patients/list' },
                    { text: labels.user_labels.edit_user, path: '' }
                ])
                document.title = `${labels.physioage_label}: ${labels.user_labels.edit_user}`
            } else {
                this.props.addRoleToCurrentUser();
                this.props.setPageTitle(labels.user_labels.new_user)
                this.props.setBreadCrumbs([
                    { text: labels.physioage_label, path: '/patients/list' },
                    { text: labels.user_labels.new_user, path: '' }
                ])
                document.title = `${labels.physioage_label}: ${labels.user_labels.new_user}`
            }
        } else if (this.props.location.pathname.indexOf('account') !== -1) {
            document.title = `${labels.physioage_label}: ${labels.user_labels.edit_user}`
            this.props.setPageTitle(labels.user_labels.edit_user)
            this.props.setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.user_labels.edit_user, path: '' }
            ])
            this.props.setCurrentUser()
        }
        if (this.userForm && this.userForm.inputEntry) {
            this.userForm.inputEntry.focus()
        }
    }
    resetPassword(e) {
        if (this.props.currentUser && this.props.currentUser.id && e) {
            e.preventDefault()
            this.props.resetPassword(this.props.history, constants.edit)
        }
    }
    render() {
        return (
            this.props.currentUser ? <React.Fragment>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                        <PageTitle breadCrumbs={this.props.breadCrumbs}
                            pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                            navigatePatients={this.props.navigatePatients}
                            history={this.props.history}
                            {...this.props} />
                    </div>
                </div>
                <div className='col-lg-12 row'>
                    <div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 users-children'>

                            <div className='col-lg-3'></div>
                            <form className='user-form container col-lg-9 mt-4' onSubmit={(e) => {
                                e.preventDefault()
                                if (!this.props.currentUser.id) {
                                    this.props.saveUser(this.props.history, constants.add)
                                }
                            }}>
                                <FormControl onChange={this.props.editUser} required={true} id='role' value={this.props.currentUser && this.props.currentUser.isProvider == 1 ? "provider" : (this.props.currentUser.role ? this.props.currentUser.role : (this.props.loggedInUser.role == constants.logged_roles.CG ? constants.logged_roles.CG : 0))}
                                    label={labels.user_labels.role} type='select' labelClass='col-lg-2'
                                    inputClass='col-lg-10'
                                    options={this.props.currentUser && this.props.currentUser.id && this.props.currentUser.patientId
                                        ? constants.roles.edit_user : (this.props.loggedInUser.role == constants.logged_roles.CG || this.props.primaryContact ? constants.roles.caregiver_roles : constants.roles.add_user)}
                                    textKey={'text'} valueKey={'value'}
                                    disabled={this.props.loggedInUser
                                        && (this.props.loggedInUser.role == constants.logged_roles.AN || this.props.loggedInUser.role == constants.logged_roles.CG)
                                        && this.props.currentUser.role != constants.logged_roles.PT
                                        ? false : true} />
                                {/* {this.props.loggedInUser.role == constants.logged_roles.CG || (this.props.loggedInUser.role == constants.logged_roles.AN && (this.props.currentUser.role == constants.logged_roles.CG)) ?
                                    <div className="d-flex mb-2">
                                        <div><label className="col-lg-2"></label></div>
                                        <div className='col-lg-10 pl-0'>
                                            <FormControl onChange={this.props.editUser} id='isProvider'
                                                label={labels.user_labels.provider} checked={this.props.currentUser.isProvider ? true : false}
                                                type='checkbox' labelClass='ml-2' />
                                        </div>
                                    </div>
                                    : null} */}
                                {/* {this.props.loggedInUser.role == constants.logged_roles.CG || (this.props.loggedInUser.role == constants.logged_roles.AN && (this.props.currentUser.role == constants.logged_roles.CG)) ?
                                    <div className="d-flex mt-1 mb-3">
                                        <div><label className="col-lg-2"></label></div>
                                        <div className='col-lg-10 pl-0'>
                                            <span className='alert alert-warning p-0'>
                                                <i className='fa fa-warning pl-2 pr-2'></i><span className="pr-2">{labels.provider_labels.isprovider_help_text}</span>
                                            </span>
                                        </div>
                                    </div>
                                    : null} */}
                                {this.props.currentUser.isProvider && this.props.currentUser.role != constants.logged_roles.AN ?
                                    <FormControl type='select' id='honorificPrefix' onChange={this.props.editUser} value={this.props.currentUser.honorificPrefix}
                                        options={constants.honorific_prefix} label={labels.provider_labels.honorific_prefix} textKey={'value'} valueKey={'value'} labelClass='col-lg-2' inputClass='col-lg-10' /> : null}
                                <FormControl ref={(s) => this.userForm = s} required={true} onChange={this.props.editUser} value={this.props.currentUser.firstName} id='firstName' label={labels.user_labels.firstname} type='text' labelClass='col-lg-2' inputClass='col-lg-10' />
                                <FormControl required={true} onChange={this.props.editUser} value={this.props.currentUser.lastName} id='lastName' label={labels.user_labels.lastname} type='text' labelClass='col-lg-2' inputClass='col-lg-10' />
                                {this.props.currentUser.isProvider && this.props.currentUser.role != constants.logged_roles.AN ?
                                    <FormControl type='text' id='honorificSuffix' label={labels.provider_labels.honorific_suffix} onChange={this.props.editUser} value={this.props.currentUser.honorificSuffix}
                                        help={labels.provider_labels.honorific_suffix_help} labelClass='col-lg-2' inputClass='col-lg-10' /> : null}
                                {/* <FormControl required={true} onChange={this.props.editUser} value={this.props.currentUser.userName} id='userName' label={labels.user_labels.username} type='text' labelClass='col-lg-2' inputClass='col-lg-7' /> */}
                                <FormControl required={true} onChange={this.props.editUser} value={this.props.currentUser && this.props.currentUser.id ? this.props.currentUser.email : null} id='email' validate label={labels.user_labels.email} type='email' labelClass='col-lg-2' inputClass='col-lg-10' />
                                <div className='d-flex mb-2'>
                                    <div><label className="col-lg-2"></label></div>
                                    <div className="col-lg-10 pl-0">
                                        <FormControl onChange={this.props.editUser} id='active'
                                            label={labels.user_labels.active} checked={this.props.currentUser.active ? true : false}
                                            type='checkbox' labelClass='ml-2' />
                                    </div>
                                </div>
                                <FormControl onChange={this.props.editUser} id='primarySiteId'
                                    value={ this.props.currentUser && this.props.currentUser.primarySiteId && this.props.currentUser.primarySiteId} 
                                    label={labels.user_labels.site}
                                    type='select' labelClass='col-lg-2' inputClass='col-lg-10' 
                                    options={this.props.sites && this.props.loggedInUser && this.props.loggedInUser.siteIds ? this.props.loggedInUser.role == 'admin' ? this.props.sites : this.props.sites.filter(site => this.props.loggedInUser.siteIds.includes(site.id)) : []} 
                                    textKey={'name'} valueKey={'id'}
                                    disabled={this.props.primaryContact ? true : false} />
                                {this.props.loggedInUser.role == constants.logged_roles.AN && (this.props.currentUser.role == constants.logged_roles.CG || this.props.currentUser.role == "provider") && this.props.currentUser.active ?
                                    <div className='d-flex mb-3 mt-4'>
                                        <div><label className="col-lg-2"></label></div>
                                        <div className="col-lg-10 pl-0">
                                            <FormControl onChange={this.props.editUser} id='can_remove_patients'
                                                label={labels.user_labels.master} checked={this.props.currentUser.can_remove_patients ? true : false}
                                                type='checkbox' labelClass='ml-2' />
                                        </div>
                                    </div> : null}
                                {this.props.loggedInUser.role == constants.logged_roles.CG || (this.props.loggedInUser.role == constants.logged_roles.AN
                                    && (this.props.currentUser.role == constants.logged_roles.CG || this.props.currentUser.role == "provider")) && this.props.currentUser.active ?
                                    <div className="d-flex mb-4">
                                        <div><label className="col-lg-2"></label></div>
                                        <div className="col-lg-10 pl-0">
                                            <FormControl onChange={this.props.editUser} id='isPrimaryContact' disabled={this.props.primaryContact ? true : false}
                                                label={labels.user_labels.primary_contact} checked={this.props.currentUser.isPrimaryContact ? true : false}
                                                type='checkbox' labelClass='ml-2' />
                                        </div>
                                    </div>
                                    : null}
                                {this.props.showMessage && this.props.valueOfRole && this.props.valueOfRole == "patient" && !this.props.currentUser.patientId ?
                                    <div className="col-lg-7 help-inline">
                                        can not be set to patient in this way. Create patient users from the patient screen.
                                    </div> : ""}
                                {this.props.currentUser.id ?
                                    <div className='col-lg-7 d-flex justify-center update-user' >
                                        <FormControl text={labels.user_labels.update_user} type='button' btnType={constants.notification_type.success} onClick={() => this.props.saveUser(this.props.history, constants.edit)} />
                                    </div> : null
                                }
                                {this.props.currentUser.id && ((this.props.currentUser.role != constants.logged_roles.CG && this.props.currentUser.role != "provider" && this.props.currentUser.role != "0") ||
                                    (this.props.currentUser.id == this.props.loggedInUser.id))
                                    ? <legend className='reset-password-label'>{labels.user_labels.reset_password}</legend> : null}
                                {this.props.currentUser.role && ((this.props.currentUser.role != constants.logged_roles.CG && this.props.currentUser.role != "provider" && this.props.currentUser.role != "0") ||
                                    (this.props.currentUser.id == this.props.loggedInUser.id)) ?
                                    <>
                                        <FormControl required={this.props.currentUser.id ? false : true}
                                            onChange={this.props.editPassword} id='password'
                                            autocomplete="new-password"
                                            label={this.props.currentUser.id
                                                ? labels.user_labels.type_password
                                                : labels.user_labels.password}
                                            type='password' labelClass='col-lg-2' inputClass='col-lg-10'
                                            onKeyDown={(e) => e.keyCode == constants.enter_key_code ? this.resetPassword(e) : void (0)} />

                                        <FormControl required={this.props.currentUser.id ? false : true}
                                            onChange={this.props.editPassword}
                                            id='confirmPassword'
                                            label={this.props.currentUser.id
                                                ? labels.user_labels.confirm_password
                                                : labels.user_labels.retype_password}
                                            help={!this.props.isPasswordMatched ? labels.user_labels.password_not_matched : ''}
                                            type='password' labelClass='col-lg-2'
                                            inputClass={`col-lg-10 ${!this.props.isPasswordMatched ? constants.notification_type.error : ''}`}
                                            onKeyDown={(e) => e.keyCode == constants.enter_key_code ? this.resetPassword(e) : void (0)} />
                                    </> : ''}
                                <div className='col-lg-7 d-flex justify-center update-password' >
                                    {this.props.currentUser.id ?
                                        ((this.props.currentUser.role != constants.logged_roles.CG && this.props.currentUser.role != "provider" && this.props.currentUser.role != "0") ||
                                            (this.props.currentUser.id == this.props.loggedInUser.id))
                                            ? <FormControl text={labels.user_labels.update_password} type='button' btnType={constants.notification_type.success} onClick={() => this.props.resetPassword(this.props.history, constants.edit)} /> : '' :
                                        <FormControl text={labels.user_labels.add_user} type='button' btnType={constants.notification_type.success} />
                                    } </div>

                            </form>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-12 col-sm-12 col-xs-12 profile-pic-section'>
                        {this.props.currentUser.isProvider && this.props.currentUser.role != constants.logged_roles.AN ?
                            <div className='image-upload'>
                                <label for='fileInput' className='image-input'>
                                    <img alt={this.props.currentUser ? `${this.props.currentUser.firstname}${labels.provider_labels.avatar}` : `${labels.provider_labels.provider_name.toLowerCase()}${labels.provider_labels.avatar}`}
                                        src={this.props.currentUser && this.props.currentUser.avatarContent ? `data:${this.props.currentUser.avatarContentType};base64,${this.props.currentUser.avatarContent}` : userIcon} />

                                </label>
                                {this.props.currentUser && this.props.currentUser.avatarContent ? <span>
                                    <i className='fa fa-times-circle danger' title='Remove picture' onClick={(e) => this.props.removePic(e)}>{labels.provider_labels.remove_pic}</i>
                                </span> : null}
                                <input id='fileInput' type='file' onChange={(e) => this.props.picUploaded(e)} accept='image/*' />
                                <p className='help-block'>{labels.provider_labels.profile_pic}</p>
                            </div> : null}
                    </div>
                </div>

            </React.Fragment> : <div></div>
        )
    }
}
export default IndividualUser;
import React from 'react';
import ReactDom from 'react-dom';
import { connect } from 'react-redux';
import closeLight from '../../../assets/images/close-light.svg';
import close from '../../../assets/images/close.svg';

function StopRecommendationWarning(props) {
    return ReactDom.createPortal(
        <>
            <div className={`drawer-warning-overlay drawer-warning-overlay-${props.theme}`} onClick={() => (props.toggleStopRecommendationPopup(false))} />
            <div className={`warning-div popup-${props.theme} ${props.siteTheme}`}>
                <div className='row header no-gutters'>
                    <div className='col-11'></div>
                    <div className='col-1 pt-1'>
                        <a><img className='float-right' src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleStopRecommendationPopup(false))}></img></a>
                    </div>
                </div>
                <div className='warning-body'>
                    <h5>{props.resumeRecommendation ? `Resume the ${props.recType}?` : `Stop the ${props.recType}?`}</h5>
                    <hr />
                    <p>{props.resumeRecommendation ? `This will resume the ${props.recType.toLowerCase()} and the patient will be able to see it in the analytics page. Are you sure you want to resume the ${props.recType.toLowerCase()}?` : `This will stop the ${props.recType.toLowerCase()} and the patient cannot see it anymore in the analytics page. Are you sure you want to stop the ${props.recType.toLowerCase()}?`}</p>
                    <hr />
                    <div className='d-flex justify-content-center'>
                        <button className='btn drawer-cancel' onClick={() => { props.toggleStopRecommendationPopup(false); }}>Cancel</button>
                        <button className='btn drawer-ok ml-4' onClick={() => {
                            props.updateRecommendations(props.stopRecommendId ? props.stopRecommendId : 0, props.resumeRecommendation == 'historic' ? 'resumeHistoric' : (props.resumeRecommendation == 'current' ? 'resumeCurrent' : 'stop'));
                            props.toggleHistoryRecommendationPopup(false)
                            props.toggleStopRecommendationPopup(false);
                        }}>Yes</button>
                    </div>
                </div>    
            </div>

        </>,
        document.getElementById('surveyPortal')
    )
}

export default connect()(StopRecommendationWarning);
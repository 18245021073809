import React from 'react';
import PropTypes from 'prop-types';
import sprite from '../../../assets/images/sprite_quick-links.svg';
import physioAgeLogo from '../../../assets/images/analytics-masthead-inverted.svg'
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import TopNavContainer from '../containers/TopNavContainer';
export default class LoadingComponent extends React.Component {
  componentDidMount() {
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.add('body-class');
  }
  componentWillUnmount() {
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.remove('body-class');
  }
  static propTypes = {
    masthead_path: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
  }

  state_description(status) {
    if (status == 'finished')
      return labels.finished
    else if (status == 'pending') {
      return labels.pending
    }
    else {
      this.props.updateLoadingStatus(true)
      // return labels.no_data
    }
  }
  myClass(statusData, statusGraph) {
    if (statusData == 'pending') {
      return ('visible');
    }
    if (statusData == constants.notification_type.error) {
      if (statusGraph == 'pending' || statusGraph == constants.notification_type.error) {
        return ('visible');
      }
      else {
        return ('clear');
      }
    }
    else {
      return ('clear');
    }
  }

  render() {
    return (
      this.props.terms && this.props.siteData ?
        <section id='loading'>
          {this.props.toggleLoading(false)}
          {this.myClass(this.props.loadingData, this.props.loadingPatient) == 'clear' ? this.props.unMountingLoadingComponent(true) : ''}
          <TopNavContainer splashVisibility={this.myClass(this.props.loadingData, this.props.loadingPatient)} {...this.props} />
          {/* <div className={`all-loaders ${this.myClass(this.props.loadingData, this.props.loadingPatient)}`}>
            <div className='loading-row'>{labels.analytics_labels.loading_data} {this.state_description(this.props.loadingData)}</div>
            <div className='loading-row'>{labels.analytics_labels.loading_categories} {this.state_description(this.props.loadingCategories)}</div>
            <div className='loading-row'>{labels.analytics_labels.loading_sections} {this.state_description(this.props.loadingSections)}</div>
            <div className='loading-row'>{labels.analytics_labels.loading_patient} {this.state_description(this.props.loadingPatient)}</div>
            <div className='loading-row'>{labels.analytics_labels.loading_recommendations} {this.state_description(this.props.loadingRecommendations)}</div>
          </div> */}
          <Element name='splash'>
            <div id='splash' className={`${this.props.siteData ? `hero brand ${this.props.siteData.logoFile ? 'vendor' : ''}`
              : ''}`}>
              {this.props.siteData ?
                <>
                  <div className={`logo ${this.props.siteData.logoFile ? "mt-0 mb-0" : ''}`}>{this.props.siteData ? <img src={this.props.siteData.logoFile
                    ? `data:${this.props.siteData.logoContentType};base64,${this.props.siteData.logoFile}`
                    : physioAgeLogo} alt='PhysioAge Health Analytics' /> : ''}
                    <div className={`loading-data-text ${this.props.siteData.logoFile ? 'loading-data-text-black' : ''}`}>
                      Your data is being prepared...
                    </div>
                  </div>
                  {this.props.siteData.logoFile ?
                    <div className='powered-by-physioage row invisible'>
                      {labels.analytics_labels.powered_by_physioage}
                    </div> : null}
                </>
                : <></>}
              <div className='quick-links'>
                <Link className='link-item scrollable stroke' activeClass='active' to='biographical-info' spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}>
                  {(this.state_description(this.props.loadingSections) == "Finished" && this.state_description(this.props.loadingCategories) == "Finished") || this.myClass(this.props.loadingData, this.props.loadingPatient) == 'clear' ? <svg className={`animated-check ${this.props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                    <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${this.props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                  {/* <p className="splash-spinner">{this.state_description(this.props.loadingSections) == "Finished" && this.state_description(this.props.loadingCategories) == "Finished" ? <i className="fa fa-check" aria-hidden="true"></i> : <i className='fa fa-spinner fa-spin'></i>}</p> */}
                  <span className='title'>{this.props.terms['biographical_information']}</span>
                </Link>
                <Link activeClass='active' className='link-item scrollable fill' to='biomarkers-of-aging' spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}>
                  {(this.state_description(this.props.loadingPatient) == "Finished") || this.myClass(this.props.loadingData, this.props.loadingPatient) == 'clear' ? <svg className={`animated-check ${this.props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                    <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${this.props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                  {/* <p className="splash-spinner">{this.state_description(this.props.loadingPatient) == "Finished" ? <i className="fa fa-check" aria-hidden="true"></i> : <i className='fa fa-spinner fa-spin'></i>}</p> */}
                  <span className='title'>{this.props.terms['Biomarkers of Aging']}</span>
                </Link>
                <Link activeClass='active' className='link-item scrollable stroke' to='medication' spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}>
                  {(this.state_description(this.props.loadingRecommendations) == "Finished") || this.myClass(this.props.loadingData, this.props.loadingPatient) == 'clear' ? <svg className={`animated-check ${this.props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                    <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${this.props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                  {/* <p className="splash-spinner">{this.state_description(this.props.loadingRecommendations) == "Finished" ? <i className="fa fa-check" aria-hidden="true"></i> : <i className='fa fa-spinner fa-spin'></i>}</p> */}
                  <span className='title'>Medications</span>
                </Link>
                <Link activeClass='active' className='link-item scrollable stroke' to='health_status_indicators' spy={true}
                  smooth={true}
                  offset={-45}
                  duration={500}>
                  {(this.state_description(this.props.loadingData) == "Finished" && this.state_description(this.props.loadingCategories) == "Finished") || this.myClass(this.props.loadingData, this.props.loadingPatient) == 'clear' ? <svg className={`animated-check ${this.props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                    <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${this.props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                  {/* <p className="splash-spinner">{this.state_description(this.props.loadingData) == "Finished" && this.state_description(this.props.loadingCategories) == "Finished" ? <i className="fa fa-check" aria-hidden="true"></i> : <i className='fa fa-spinner fa-spin'></i>}</p> */}
                  <span className='title'>{this.props.terms['Health Report Card']}</span>
                </Link>
                <Link activeClass='active' className='link-item scrollable stroke' to='treatment-plan' spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}>
                  {(this.state_description(this.props.loadingRecommendations) == "Finished") || this.myClass(this.props.loadingData, this.props.loadingPatient) == 'clear' ? <svg className={`animated-check ${this.props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                    <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${this.props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                  {/* <p className="splash-spinner">{this.state_description(this.props.loadingRecommendations) == "Finished" ? <i className="fa fa-check" aria-hidden="true"></i> : <i className='fa fa-spinner fa-spin'></i>}</p> */}
                  <span className='title'>{this.props.terms['Treatment Plan']}</span>
                </Link>
              </div>
              <div className='nav-trigger'></div>
            </div>
          </Element>
        </section> : <>{this.props.toggleLoading(true)}</>
    );
  }
}

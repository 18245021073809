import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import PageTitle from '../../../components/PageTitle';
import edit from '../../../assets/images/edit.svg';
import EmailVerification from '../../../components/EmailVerification/EmailVerification';
import AuthenticatorVerification from '../../../components/AuthenticatorVerification/AuthenticatorVerification';
import PersonIcon from '@mui/icons-material/Person';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ImageIcon from '@mui/icons-material/Image';
import LanguageIcon from '@mui/icons-material/Language';
import EditIcon from '@mui/icons-material/Edit';

function UserDetails(props) {
    const [isOnEmail, setIsOnEmail] = useState(false);
    const [isOnAuth, setIsOnAuth] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isAuthVerified, setIsAuthVerified] = useState(false);
    const [primary, setPrimary] = useState();
    useEffect(() => {
        props.toggleNotification(false);
        if (!props.currentUser.email){
            props.setCurrentUser()
            
        }       
        setUserMFA(props)
        return () => {
            // componentWillUnmount() //
        }
    }, [props.currentUser, props.currentUser.email])

    const setUserMFA = (props) => {
        { props.currentUser.userMFA && props.currentUser.userMFA.map(mfa => {
            if (mfa.mfaMode === 'authenticator') {
                setIsAuthVerified(mfa.isVerified);
            }
            if (mfa.mfaMode === 'email') {
                setIsEmailVerified(mfa.isVerified);
            }
            return null;
        })}

        { props.currentUser.userMFA && props.currentUser.userMFA.map(mfa => {
            if (mfa.mfaMode === 'email' && mfa.isPrimary) {
                setPrimary('email');
            } else if (mfa.mfaMode === 'authenticator' && mfa.isPrimary) {
                setPrimary('authenticator');
            }
            return null;
        })}
    }

    const handleToggleEmail = () => {
        setIsOnEmail(!isOnEmail);
    };
    const handleToggleAuth = () => {
        setIsOnAuth(!isOnAuth);
        props.getQRCode()
    };
    const handlePrimary = (e) => {
        setPrimary(e.target.value);
        props.setPrimaryMFA(e.target.value)
    };
    const handleEmailDone = () => {
        setIsOnEmail('');
        props.setCurrentUser()
    };

    const handleAuthDone = () => {
        setIsOnAuth('');
        props.setCurrentUser()
    };

    const handleClose = () => {
        handleEmailDone()
        handleAuthDone()
    }

    const addUserSettingPath = (history) => {
        if (history && history.location && history.location.pathname){
            history.userDetailsPath = history.location.pathname + history.location.hash
        }
    }
    return (
        props.currentUser ?
            <React.Fragment>
                <div className='download-library user-settings'>
                    <div class="row header no-gutters b-bottom">
                        <div class="col-lg-9 col-md-8 col-sm-9 col-8">
                            <div class="heading-1">User Details</div>
                        </div>
                    </div>
                    <div className='row no-gutters height-100'>
                        <div className='col-lg-8 col-12'>
                            <div className='user-details'>
                                <div className='bb-color'>
                                    <div className='col-lg-12 '>
                                         { (props.currentUser && props.currentUser.role && props.currentUser.role != constants.logged_roles.PT) && !props.isDemo ?
                                            <div className="mr-2 edit-icon" title={`Edit User`} onClick={() =>{ addUserSettingPath(props.history);  props.headerNavigation(`/account`, props.history)}}>
                                                <EditIcon className={'user-icon-color'}/>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className='col-lg-12 d-flex details-row'>
                                        <div className='col-6 d-flex'>
                                            <div className='col-1'>
                                                <PersonIcon className={'user-icon-color'}/>
                                            </div>
                                            <div className='col-11'>
                                                <div className='label'> First Name</div>
                                                <div className={`value`}> {props.currentUser.firstName}</div>
                                            </div>
                                        </div>
                                        <div className='col-6 d-flex'>
                                            <div className='col-1'>
                                                <PersonIcon className={'user-icon-color'}/>
                                            </div>
                                            <div className='col-11'>    
                                                <div className='label'> Last Name</div>
                                                <div className={`value`}> {props.currentUser.lastName}</div>
                                            </div>
                                        </div>    
                                    </div>
                                    <div className='col-lg-12 d-flex details-row'>
                                        <div className='col-6 d-flex'>
                                            <div className='col-1'>
                                                <MarkunreadIcon className={'user-icon-color'}/>
                                            </div>
                                            <div className='col-11'>
                                                <div className='label'> Email</div>
                                                <div className={`value`}>  {props.currentUser.email}</div>
                                            </div>    
                                        </div>
                                        <div className='col-6 d-flex'>
                                            <div className='col-1'>
                                                <SupervisorAccountIcon className={'user-icon-color'}/>
                                            </div>
                                            <div className='col-11'>
                                                <div className='label'> Role</div>
                                                <div className='value text-capitalize'> {props.currentUser.originalRole}</div>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className='col-lg-12 d-flex details-row'>
                                        <div className='col-6 d-flex'>
                                            <div className='col-1'>
                                                <ImageIcon className={'user-icon-color'}/>
                                            </div>
                                            <div className='col-11'>
                                                <div className='label'> Active</div>
                                                <div className='value'> {props.currentUser.active == 1 ? 'Active' : "Deactive"}</div>
                                            </div>    
                                        </div>
                                        <div className='col-6 d-flex'>
                                            <div className='col-1'>
                                                <LanguageIcon className={'user-icon-color'}/>
                                            </div>
                                            <div className='col-11'>
                                                <div className='label'> Site</div>
                                                <div className='value'> {props.currentUser.siteName ? props.currentUser.siteName : 'Not mentioned'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { process.env.REACT_APP_MFA_ENABLED != 'false'?
                                <div>
                                    <div className='pb-3'>
                                        <div className='heading-1'>
                                            Two factor authentication
                                        </div>
                                        <div className='heading-3'>
                                            An additional layer of privacy
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <div class="row  no-gutters">
                                            <div class="col-lg-8 col-6  no-gutters">
                                                <div className='heading-2'>
                                                    Email verification
                                                </div>
                                                <div className='heading-3'>
                                                    {`We'll send a 6-digit code to ${props.currentUser.email}`}
                                                </div>
                                                { isEmailVerified && isAuthVerified ?
                                                <div className='heading-3'>
                                                    <input type="radio" name="primary" checked={primary === 'email'} value="email" onChange={(e) => handlePrimary(e)} /> Set as primary
                                                </div>
                                                : null }
                                            </div>
                                            <div class={`col-lg-2 col-3 no-gutters d-flex align-items-center justify-content-center f-w-600 ${isEmailVerified ? 'success' : ''}`}>{isEmailVerified ? 'Verified' : 'Unverified' }</div>
                                            <div class="col-lg-2 col-3 no-gutters d-flex align-items-center justify-content-center">
                                                <button  className='btn btn-primary mt-2' onClick={() => { handleToggleEmail(); props.sendOTP('email', props.currentUser.email, true)}} disabled={props.isDemo ? true : false}>{isEmailVerified ? 'Reverify' : 'Verify' }</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <div class="row  no-gutters">
                                            <div class="col-lg-8 col-6 no-gutters">
                                                <div className='heading-2'>
                                                    Authenticator App
                                                </div>
                                                <div className='heading-3'>
                                                    Set up an app like Google Authenticator or Duo Mobile to generate login codes
                                                </div>
                                                { isEmailVerified && isAuthVerified ?
                                                <div className='heading-3'>
                                                    <input type="radio" name="primary" checked={primary === 'authenticator'} value="authenticator" onChange={(e) => handlePrimary(e)} /> Set as primary
                                                </div>
                                                : null }
                                            </div>
                                            <div class={`col-lg-2 col-3 no-gutters d-flex align-items-center justify-content-center f-w-600 ${isAuthVerified ? 'success' : ''}`}>{isAuthVerified ? 'Verified' : 'Unverified' }</div>
                                            <div class="col-lg-2 col-3 no-gutters d-flex align-items-center justify-content-center">
                                                <button  className='btn btn-primary mt-2' onClick={() => handleToggleAuth()} disabled={props.isDemo ? true : false}>{isAuthVerified ? 'Reverify' : 'Verify' }</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {isOnEmail  && <EmailVerification {...props} onDone={handleEmailDone} onClose={handleClose}/>}
                {isOnAuth  && <AuthenticatorVerification {...props} onDone={handleAuthDone} onClose={handleClose} />}
            </React.Fragment> 
        : <div></div>
    );
};

export default connect()(UserDetails);

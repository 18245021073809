import React, { useEffect, useRef } from 'react';

function BarChart(props) {
    const {text, age, fillColor, theme, textColor} = props;
    // const age = props.age;
  const animatedDivRef = useRef(null);


  useEffect(() => {
    const animatedDiv = animatedDivRef.current;

    if (animatedDiv) {
      const animation = animatedDiv.animate(
        [{ width: '0%' }, { width: `${age}%` }],
        {
          duration: 2000,
          fill: 'forwards',
        }
      );

      return () => {
      };
    }
  }, [theme]);

  return (
    <div className={`bar-container-${text == 'PhysioAge' ? 'physioage' : 'real-age'}`}>
      <div className='text-container'>
        <div className={`start-text-${text == 'PhysioAge' ? 'physioage' : 'real-age'}`} style={{color : `${theme == 'dark-theme' ? '#F1F1F1' : 'black'}`}}>
           {text}
        </div>
        <div className={`end-text-${text == 'PhysioAge' ? 'physioage' : 'real-age'}`} style={{color : `${textColor}`}}>
          {age} years
        </div>
      </div>
      <div className='bars-container'>
        <div className={`bar-background-${text == 'PhysioAge' ? 'physioage' : 'real-age'}`} style={{ backgroundColor: `${theme == 'dark-theme' ? '#5B5B5B' : '#dee1e1'}`}}></div>
        <div ref={animatedDivRef} className={`animated-filled-div-${text == 'PhysioAge' ? 'physioage' : 'real-age'}`} style={{ backgroundColor: fillColor }}></div>
      </div>
    </div>
  );
}

export default BarChart;

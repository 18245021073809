import constants from "../config/constants"
import moment from 'moment';

export let idleTimer = constants.timeout_min_for_expire * 60
export const timerDecrement = () => {
    if (idleTimer > 0)
        idleTimer = idleTimer - 1
}
export const resetTimer = () => {
    idleTimer = constants.timeout_min_for_expire * 60
}
export const clearTimer = () => {
    idleTimer = 0
}

export let refreshTokenTimer = 0

export const tokenTimerIncrement = () => {
    if (refreshTokenTimer < constants.min_for_refresh * 60)
        refreshTokenTimer += 1
}
export const tokenTimerReset = () => {
    refreshTokenTimer = 0
}
export const returnLocalizedDate = (date) => {
    return moment(moment.utc(date).toDate())
}
import React from 'react';
import DatumChange from './DatumChangeCard';
import DatumAverage from './DatumAverage';
import Grade from './Grade';
import HistoricDataKey from './HistoricDataKey';
import HistoricDataChart from '../containers/HistoricDataChartContainer';
import HistoricDataTable from './HistoricDataTable';
import NoumenonDescription from '../containers/NoumenonDescriptionContainer';
import Recommendations from '../containers/RecommendationBlockContainer';
import ValueCard from './ValueCardComponent';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import constants from '../../../config/constants';
import constant from '../../../config/reportConstants';
import labels from '../../../config/localization';
import html2react from 'html-to-react'
import marked from 'marked';
import { getRouteStatus } from '../../../utils/parseJWT';


const breakpoint = () => {
  // Based on app/assets/stylesheets/brand.scss
  if (window.innerWidth < 480)
    return ('xs')

  else if (window.innerWidth < 768)
    return ('sm')

  else if (window.innerWidth < 992)
    return ('md')

  else if (window.innerWidth < 1025)
    // Drawer goes from full-screen to partial-screen at 1025
    return ('xm') // extra medium

  else if (window.innerWidth < 1200)
    return ('lg')

  else
    return ('xl')
}

export default class Drawer extends React.Component {

  constructor(props) {
    super(props);
    this.renderHistoricData = this.renderHistoricData.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
    window.addEventListener('resize', this.props.updateResolution);

  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  componentWillMount() {
    this.clearSpectrum();
  }

  componentDidUpdate() {
    if (!this.props.isSideBarActive && this.state.activeTab != '1') {
      this.setState({
        activeTab: '1'
      });
    }
    // this.doAddSpectrum();
  }

  componentDidMount() {
    this.props.updateResolution()
    // this.doAddSpectrum();

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.props.updateResolution)
  }
  putSvgInline(doc) {
    var svg = $(doc.documentElement);
    svg.removeAttr('xmlns:a');

    if (svg.children().length > 0 && document.querySelector('.drawer-content-spectrum')) {
      document.querySelector('.drawer-content-spectrum').style.display = 'block';
      document.querySelector('.drawer-content-spectrum').html(svg);
    }
    else {
      document.querySelector('.drawer-content-spectrum').style.display = 'none';
    }
  }

  clearSpectrum() {
    let element = document.querySelector('.drawer-content-spectrum');
    if (element) {
      element.html();
      element.hide();
    }
  }
  markdown2react(md) {
    marked.setOptions({
      renderer: new marked.Renderer(),
      sanitize: true,
      smartLists: true,
    });

    let parser = new html2react.Parser();
    return (parser.parse(marked(md)));
  }
  getMaxRange() {
    let max_ranges = 8;
    if (['xs', 'sm'].includes(breakpoint())) {
      max_ranges = 3;
    }
    else if (['md'].includes(breakpoint())) {
      max_ranges = 5;
    }
    else if (['xm'].includes(breakpoint())) {
      max_ranges = 7;
    }
    else if (['lg'].includes(breakpoint())) {
      // Drawer actually shrinks in size as it undocks from the left
      max_ranges = 5;
    }
    return max_ranges;
  }
  doAddSpectrum() {

    if (this.props.datum) {
      let datum_attributes = { value_label: this.props.terms && this.props.terms.You ? this.props.terms.You.toUpperCase() : 'YOU', value: this.props.datum.value, units: this.props.datum.units != '%' ? this.props.datum.units : this.props.datum.units + "25", palette: 'green_to_red' }
      let atts = Object.assign({}, datum_attributes, this.props.match_attributes, { style: 'dashboard', max_ranges: 10 })
      var params = Object.keys(atts).map(key => key + '=' + atts[key]).join('&');

      let sortedRanges = null

      //if the array contains the negative values
      if (this.props.datum.noumenonRubricRanges.filter(s => s.low < 0 || s.high < 0).length) {

        var replacedRanges = this.props.datum.noumenonRubricRanges;
        //replacing the null as the null is greater than the negative values
        replacedRanges.map(s => s.low ? s.low : s.low = '-9999')
        replacedRanges.map(s => s.high ? s.high : s.high = '-9999')
        sortedRanges = replacedRanges ? replacedRanges.sort(function (a, b) { return a.low - b.low })
          : null;
        //replacing back the -9999 with the null value as we need to prepopulate it in the drawers
        replacedRanges.map(s => s.low == '-9999' ? s.low = null : s.low);
        replacedRanges.map(s => s.high == '-9999' ? s.high = null : s.high);

        sortedRanges = replacedRanges;
      }
      else {
        sortedRanges = this.props.datum.noumenonRubricRanges ? this.props.datum.noumenonRubricRanges.sort(function (a, b) { return a.low - b.low })
          : null;
      }
      let ranges = sortedRanges ? sortedRanges.map(t => ([
        `lbl:${t.description ? t.description.replace(',', '') : this.props.terms[t.type.replace(/Range/g, "").toLowerCase()]
          ? this.props.terms[t.type.replace(/Range/g, "").toLowerCase()] :
          t.type.replace(/Range/g, "")}`,
        `cls:${t.type[0]}`,
        `rng:${(t.low || t.low == 0) && (t.high || t.high == 0) ? `${t.low}-${t.high}%7C` : ((!t.low && (t.high || t.high == 0)) ? `<${t.high}%7C` : ((t.low || t.low == 0) && !t.high) ? `>${t.low}` : '')}`
      ]).join(',')).join('') : ''
      let max_ranges = this.getMaxRange();

      let final = `${constants.biographical_labels.spectrum_route}?${params}&ranges=${ranges}&max_ranges=${max_ranges}`
      if (window.innerWidth <= constants.small_screen_resolution)
        final = `${final}&style=mobile`
      else
        final = `${final}&style=dashboard`
      return <object key={Math.random()} data={final} type="image/svg+xml">
      </object>

    }
  }
  renderCloseControl() {
    return (
      <div className='drawer-controls'>
        <div className='icon sm fill'>
          <button className='button-close' aria-label='Close Account Info Modal Box' onClick={() => {
            // if (window.location && window.location.hash && (window.history.length <= 3 || localStorage.getItem('canOpen') || getRouteStatus())) {
            //   // window.history.back()
            //   localStorage.removeItem('canOpen')
            //   this.props.history.replace({ ...window.location, hash: '' })
            // }
            // else if (window.location && window.location.hash) {
            // this.props.history.goBack()
            //   // this.props.closeSideBar(this.props.history)
            // }
            this.props.closeSideBar(this.props.history);
            // this.props.closeSideBarOnClose()
          }}>&times;</button>
        </div>
      </div>
    );
  }

  renderHeading() {
    return (
      <div className='drawer-heading'>
        {/* <div className='grade-icon'>
          <Grade points={this.props.datum.points} />
        </div> */}
        <div className='item-detail'>
          <div className='meta'>
            <div className='drawer-category'>{this.props.sectionValues.name}</div>
            <div className='drawer-title'>{this.props.datum.label}</div>
          </div>
        </div>
      </div>
    );
  }

  showDescription() {
    return (<NoumenonDescription code={this.props.datum.code} {...this.props} />);
  }

  renderHistoricData() {
    if (this.props.datum && this.props.datum.historicData) {
      let nonBlankValue = item => !!item.value
      let historicNonBlankData = this.props.datum ? this.props.datum.historicData.filter(nonBlankValue) : '';
      if (historicNonBlankData.length >= 2 && (this.props.datum.valueType == 'NM' || this.props.datum.valueType == 'Numeric')) {
        return (
          <div className='chart-content'>
            <HistoricDataKey noumenon_name={this.props.datum.label} should_render_age_line={false} />

            {/* --Commenting as we have created Customization component-- */}
            { /* this.props.role != 'patient' && this.props.datum && this.props.datum.valueFormats && this.props.datum.valueFormats.length > 0 ? 
              // <div className="d-flex justify-content-end mb-4">
              //   {/* <b>Unit Preference:</b> */
              //   <div className="d-flex flex-column col-3 col-lg-2 p-0 pr-2">
              //     <select className="w-100 unitSelect" value={this.props.analyticsUnitPreference ? this.props.analyticsUnitPreference : this.props.datum.units}
              //       onChange={this.props.setAnalyticsUnitPreference} type='select' id='analyticsUnitPreference'>
              //       {this.props.datum.valueFormats.map((val) => {
              //         return (
              //           <option value={val}>{val}</option>
              //         )
              //       })}
              //     </select>
              //     <p>(Choose preferred units)</p>
              //   </div>
              //   {this.props.datum.code && this.props.datum.units && this.props.analyticsUnitPreference && this.props.datum.units != this.props.analyticsUnitPreference ? <button onClick={() => this.props.changeAnalyticsUnitPreference(this.props.datum.code, this.props.history)} className="unit_preference_tick mr-2"><i class="fa fa-check" aria-hidden="true"></i></button> : ''}
              // </div>
              // : ''} */
            }
            <HistoricDataChart historic_data={historicNonBlankData} drawerType={this.props.drawerType} {...this.props} />
          </div>
        );
      } else if (historicNonBlankData.length > 0) {
        return (
          <div className='chart-content chart-content-table'>
            {/* this.props.role != 'patient' && this.props.datum && this.props.datum.valueFormats && this.props.datum.valueFormats.length > 0 ?
              <div className="d-flex justify-content-end mb-4">
                {/* <b>Unit Preference:</b> */
              //   <div className="d-flex flex-column col-3 col-lg-2 p-0 pr-2">
              //     <select className="w-100 unitSelect" value={this.props.analyticsUnitPreference ? this.props.analyticsUnitPreference : this.props.datum.units}
              //       onChange={this.props.setAnalyticsUnitPreference} type='select' id='analyticsUnitPreference'>
              //       {this.props.datum.valueFormats.map((val) => {
              //         return (
              //           <option value={val}>{val}</option>
              //         )
              //       })}
              //     </select>
              //     <p>(Choose preferred units)</p>
              //   </div>
              //   {this.props.datum.code && this.props.datum.units && this.props.analyticsUnitPreference && this.props.datum.units != this.props.analyticsUnitPreference ? <button onClick={() => this.props.changeAnalyticsUnitPreference(this.props.datum.code, this.props.history)} className="unit_preference_tick mr-2"><i class="fa fa-check" aria-hidden="true"></i></button> : ''}
              // </div> : ''}
            }
            <HistoricDataTable noumenon_name={this.props.datum.label} historic_data={historicNonBlankData} dateFormat={this.props.dateFormat ? this.props.dateFormat : ''} {...this.props} />
          </div>
        );
      }
    }
  }

  actuallyRender() {
    var isDatumInfoRendered = false
    const { store } = this.context;
    const reportConstants = constant["en"]
    if (this.props.showDrawerWarning) {
      this.toggle('3');
    }
    return (
      <React.Fragment>

        <div id='datumdrawerComponent' className={`drawer ${!this.props.isSideBarActive ? 'drawer-closed' : this.props.isSideBarActive ? 'open-drawer' : 'drawer-closed'}`}>
          {this.renderCloseControl()}

          <div className='drawer-body' id='drawer-body'>
            {this.renderHeading()}
            <div className='comments'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>

                <Nav tabs className='mt-4'>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '1' ? 'active text-secondary' : ''}
                      onClick={() => { this.toggle('1'); }}  >
                      {labels.analytics_labels.results_label}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '3' ? 'active text-secondary' : ''}
                      onClick={() => { this.toggle('3'); }} >
                      {labels.analytics_labels.recommendation_label}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '2' ? 'active text-secondary' : ''}
                      onClick={() => { this.toggle('2'); }}>
                      {labels.analytics_labels.more_info}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab} >
                  <TabPane tabId='1'>
                    <React.Fragment>
                      <div className='datum-drawer-spec'>
                        {this.props.datum.noumenonRubricRanges && this.props.datum.noumenonRubricRanges.length > 1 ?
                          <div className={`drawer-content-spectrum${this.props.isShowSpectrum ? '' : ' display-none'}`}>{this.doAddSpectrum()}
                          </div> : null}
                        <div className="drawer-content-data">
                          <div className="results">
                            <ValueCard datum={this.props.datum} drawerType={this.props.drawerType} {...this.props} />
                            <DatumChange datum={this.props.datum} changeFrom="baseline" dateFormat={this.props.dateFormat} {...this.props} />
                            <DatumChange datum={this.props.datum} changeFrom="previous" dateFormat={this.props.dateFormat} {...this.props} />
                            {this.props.should_render_population_grades ? <DatumAverage datum={this.props.datum} /> : null}
                          </div>
                        </div>
                        {this.renderHistoricData()}
                      </div>
                      {/* <div className='chart-content'> */}
                      {/* <HistoricDataKey noumenon_name={constants.biographical_labels.age_label_lowercase[this.props.ages]} should_render_age_line={true} />
                <HistoricDataChart noumenon_name={constants.biographical_labels.age_label_lowercase[this.props.ages]} historic_data={this.props.historic_data_chart ? this.props.historic_data_chart[constants.biographical_labels.historic_data_labels[this.props.ages]] : ''} /> */}
                      {/* </div> */}
                    </React.Fragment>
                  </TabPane>
                  <TabPane tabId='2'>
                    {this.props.categories ? this.props.categories.map(s => s.sections.map(t => t.data.map(u => {
                      if (u.code == this.props.datum.code && !isDatumInfoRendered) {
                        isDatumInfoRendered = true
                        return (<><div className='description-shadow'>
                          <div className='moreInfoDescription'>
                            <p><b>{u.label}</b></p>
                            <p> {this.props.noumenonReferences &&
                              this.props.noumenonReferences.filter(s => s.code == u.code).length && _.head(this.props.noumenonReferences.filter(s => s.code == u.code)).noumenonExplanation != null ?
                              this.markdown2react(_.head(this.props.noumenonReferences.filter(s => s.code == u.code)).noumenonExplanation) : labels.analytics_labels.no_description}</p>
                          </div>
                          {this.props.noumenonReferences &&
                            this.props.noumenonReferences.filter(s => s.code == u.code).length &&
                            _.head(this.props.noumenonReferences.filter(s => s.code == u.code)).referencesDTO.length ?
                            <div className='moreInfoReference'>
                              <p className='reference-heading'><b>References:</b></p>
                              {
                                _.head(this.props.noumenonReferences.filter(s => s.code == u.code)).referencesDTO.map((s, index) => {
                                  return (<div className='card' key='card-reference'>
                                    <p className="card-header"><b>Reference {index + 1} :</b></p>
                                    {s.url ?
                                      <>
                                        <p className="card-body url-reference"><b>URL : </b><a target="_blank" href={s.url}>{s.url}</a></p>
                                      </>
                                      : ''}
                                    {s.internalNotes ?
                                      <>
                                        <p className="card-body"><b>Internal Notes : </b>{s.internalNotes}</p>
                                      </>
                                      : ''}
                                  </div>)
                                })}
                            </div> : ''}</div></>)
                      }
                    }))) : ''}
                  </TabPane>
                  <TabPane tabId='3' >
                    <div className='drawer-content-data'>
                      <div className='padded'>
                        <Recommendations {...this.props} datum={this.props.datum} drawerType={this.props.drawerType} />
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div >
          </div>
        </div>
        <div className={`${this.props.isSideBarActive ? 'open-drawer ' : ''}drawer-shade`}></div>
      </React.Fragment >
    )
  }

  render() {
    if (this.props.datum) {
      return (this.actuallyRender());
    }
    else {
      return (null);
    }
  }
}

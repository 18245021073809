import { createSelector } from 'reselect';
import GradingAssistant from '../tools/grading_assistant';

//const categorySelector =  this.props.categories ? this.props.categories:''
//const dataSelector =  this.props.Data ? this.props.Data:''
//const valueFilterSelector = state => state.presentation.value_filter

let historicDatumValueSelector = function (datum) {
  if (datum.value) {
    return (datum)
  }
  else {
    let historic = datum.historicData ? datum.historicData.filter(d => d.value) : null;

    if (historic && historic.length > 0) {
      let last = historic[historic.length - 1];

      last.historic = true;

      last.historicData = datum.historicData;
      return last
    }
    else {
      return null
    }
  }
}

let historicGradeValueSelector = function (datum, visitId) {
  if (datum && datum.visitId == visitId && datum.value) {
    return (datum.value)
  }
  else {
    let historic = datum && datum.historicData ? datum.historicData.filter(d => d.value && d.visitId == visitId) : null;

    if (historic && historic.length > 0) {
      let last = historic[historic.length - 1];
      // last.value = historic[historic.length - 1].value;
      // last.points = historic[historic.length - 1].points;
      last.historic = true;

      last.historicData = datum.historicData;
      return last
    }
    else {
      return null
    }
  }
}

data = state => state.data
let timeBoundarySelector = createSelector(
  data,
  (data) => {
    let timestamps = data ? data.find(datum => datum.code == 'timestamp') : '';
    if (timestamps) {

      return ({
        starting_timestamp: parseInt(timestamps.historicData[0].value),
        ending_timestamp: parseInt(timestamps.historicData[timestamps.historicData.length - 1].value)
      })
    }
  }
)
var categories = state => state.categoryData, data = state => state.data, ss = state => state, visitId = state => state.visitId
let datasetSelector = createSelector(
  categories, data, visitId,
  // categorySelector,
  // dataSelector,
  // valueFilterSelector,
  (categories, data, visitId) => {
    let dataset = [];

    if (categories && categories.length) {
      categories.map(function (category) {
        let sections_out = [];

        category.sections.map(function (section) {
          section.data = [];

          section.codes.map(function (code) {
            let datum = data ? (!visitId ? data.find(i => (i.code == code)) : data.find(i => (i.code == code && i.visitId == visitId))) : {};

            if (datum && !datum.visible) {
              // do nothing
            }
            else if (datum && datum.value) {
              section.data.push(datum)
            }
            else if (datum) {
              let historic = historicDatumValueSelector(datum);
              if (historic) {
                section.data.push(historic);
              }
            }
          });

          section.points = parseFloat(round(GradingAssistant.grade_section(section),2));
          if (section.data.filter(i => i.value).length > 0) {
            sections_out.push(section);
          }
        });

        if (sections_out.length > 0) {
          category.sections = sections_out;
          dataset.push(category);
        }
        var catpoints = parseFloat(round(GradingAssistant.grade_category(category), 2));
        category.points = category.defaultWeight && catpoints != null ?
          catpoints * category.defaultWeight :
          catpoints;
      });
    }
    return (dataset);
  });
var categoriesGradeData = state => state.categoriesGradeData, data = state => state.data, ss = state => state, visitNumber = state => state.visitNumber, dateOfVisit = state => state.dateOfVisit;
let gradeSelector = createSelector(
  categoriesGradeData, data, visitNumber, dateOfVisit,
  // categorySelector,
  // dataSelector,
  // valueFilterSelector,
  (categoriesGradeData, data, visitNumber, dateOfVisit) => {
    let dataset = [];

    if (categoriesGradeData && categoriesGradeData.length) {
      categoriesGradeData.map(function (category) {
        let sections_out = [];

        category.sections.map(function (section) {
          section.data = [];

          section.codes.map(function (code) {
            let datum = data ? data.find(i => (i.code == code)) : ''
            if (datum && !datum.visible) {
              // do nothing
            }
            else if (datum && datum.visitId == visitNumber && datum.value) {
              section.data.push(datum)
            }
            else if (datum) {
              let historic = historicGradeValueSelector(datum, visitNumber);
              if (historic) {
                section.data.push(historic);
              }
            }
          });

          section.points = parseFloat(round(GradingAssistant.grade_section(section), 2));
          section.value = parseFloat(round(GradingAssistant.grade_section(section), 2));
          section.visitDate = dateOfVisit;
          section.label = section.name;
          section.type = "points"

          if (section.data.filter(i => i.value).length > 0) {
            sections_out.push(section);
          }
        });

        if (sections_out.length > 0) {
          category.sections = sections_out;
          dataset.push(category);
        }

        var catPoints =  parseFloat(round(GradingAssistant.grade_category(category), 2));

        // category.points = category.defaultWeight && catPoints ?
        //   catPoints * category.defaultWeight :
        //   !catPoints ? category.defaultWeight : catPoints;

        category.points = category.defaultWeight && catPoints != null ?
          catPoints * category.defaultWeight :
          catPoints;

        category.value = category.defaultWeight && catPoints ?
          catPoints * category.defaultWeight :
          !catPoints ? category.defaultWeight : catPoints;

        category.visitDate = dateOfVisit;
        category.label = category.name;
        category.type = "points"
      });
    }
    return (dataset);
  });

let round = function (value, scale) {
  return Number(Math.round(value + 'e' + scale) + 'e-' + scale).toFixed(scale);
}
let gpaSelector = function (dataset) {
  let points = dataset.map(category => category.points).filter(points => points);
  const reduceToSum = (accumulator, currentValue) => accumulator + currentValue;

  let sum = points.reduce(reduceToSum, 0)

  if (sum > 0) {
    let average = sum / points.length;
    return round(average, 2)
  }
  else {
    return null
  }
}

export { datasetSelector, historicDatumValueSelector, gpaSelector, gradeSelector, timeBoundarySelector, round };

import React, { Component } from 'react';

import './api-collection.scss';

class ApiCollection extends Component {
    componentDidMount() {
        document.body.style = 'background: #ffffff!important';
        this.props.getApiCollection()
        document.title = window.location.href
    }
    render() {
        return (
            this.props.apiCollection ? <div className='api-collection-details container-fluid'>
                <pre>{JSON.stringify(this.props.apiCollection)}</pre>
            </div> : null
        )
    }
}
export default ApiCollection
import {connect} from 'react-redux';
import BiomarkersOfAgingComponent from '../components/BiomarkersOfAgingComponent';
import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
  ...state.analytics
})
const mapDispatchToProps = {
  ...actionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(BiomarkersOfAgingComponent);

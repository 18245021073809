import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import SpriteIcon from './SpriteIcon';
import { roundNumber, uniformString } from '../tools/helpers';
import BiomarkerSparkline from './BiomarkerSparkline';
import tru from '../../../../src/tru.svg'
import constants from '../../../config/constants'
import BiomarkerAgingOverview from '../containers/BiomarkerAgingOverviewContainer';
import Loader from '../../../components/Loader';
import { scroller } from 'react-scroll';
import labels from '../../../config/localization';
import _ from 'lodash';


function BiomarkerAgingComponent(props) {
    useEffect(() => {
        let element = document.getElementsByClassName("header")
        element && element[0] ? element[0].scrollIntoView(0,0) : ""
        props.setRecType("Recommendation")
        props.toggleCategoryDetailsModal(false)
        return () => {
            // Anything in here is fired on component unmount.
        }
    }, [])

    if (props.categories && props.historic_data_chart && !props.biomarker_key_name && _.head(Object.values(props.historic_data_chart)[0]).code) {
        props.toggleBioAgeOverview(_.head(Object.values(props.historic_data_chart)[0]).code.toLowerCase(),
            _.last(Object.values(props.historic_data_chart)[0]).value, _.last(Object.values(props.historic_data_chart)[0]).visitDate, 'biomarker', _.head(Object.values(props.historic_data_chart)[0]).cultureLabel,
            props.history, _.head(Object.values(props.historic_data_chart)[0]).label, _.last(Object.values(props.historic_data_chart)[0]), _.head(Object.keys(props.historic_data_chart)));
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const setBiomarkerComparisonList = (e, bioMarkerAge) => {
        props.setBiomarkerComparisonList(bioMarkerAge)
        e.stopPropagation();
    }
    const card = (bio) => {
        return (
            <React.Fragment>
                {/* <CardContent sx={{ padding: '15px' }} className={`${"risk" == bio.batchClass && active != bio.label && 'risk-br'} ${"risk" == bio.batchClass && 'risk-bg'} ${active == bio.label && 'active'}`}  > */}
                    <CardContent sx={{ padding: '15px' }} className={`${bio.batchClass} ${bio.batchClassBr}`}  >
                    <Typography sx={{ float: 'right', margin: '0px' }} component={'div'} >
                        <div className='d-flex' onClick={(e) => { setBiomarkerComparisonList(e, bio.code) }}>
                            <Checkbox checked={props.biomarkerComparisonList ? props.biomarkerComparisonList.includes(bio.code) : false} 
                                sx={{
                                    padding: 0,
                                    color: props.theme == "light-theme" ?  "black" : "white",
                                    '&.Mui-checked': {
                                        color: props.theme == "light-theme" ? 'var(--btn-bg)' : "white",
                                    },
                                    borderRadius: "4px"
                                }} {...label} iconStyle={{fill: 'white'}} />
                        </div>
                    </Typography>

                    <Typography component={'div'}>
                        <div className="bio-name-div-1024 d-flex justify-content-start">
                            <div className='icon biomarkers-overall-page'> {bio.icon} </div>
                            <span className='pl-2 age-name align-self-center'>{bio.label}</span>
                        </div>
                    </Typography>

                    <Typography sx={{ pt: 1 }} component={'div'}>
                        <span className='value'>{bio.value}</span>
                    </Typography>
                    <Typography sx={{ pt: 1 }} component={'div'}>
                        <span className={`lable ${props.theme == "light-theme" ? bio.batchClassColor : "color-white"} `}>{bio.batchName}</span>
                    </Typography>
                    <Typography sx={{ pt: 1 }} component={'div'}>
                        <div>
                            {bio.graph}
                        </div>
                    </Typography>

                </CardContent>
            </React.Fragment>
        );
    }
    const [active, setActive] = useState('');

    { !active && props.historic_data_chart ? setActive(_.head(props.historic_data_chart[Object.keys(props.historic_data_chart)[0]]).label) : "" }
    const [sortType, setSortType] = React.useState('default');
    const filterBiomaker = (event, newSortType) => {
        setSortType(newSortType);
    };

    let historic_data_chart_keys = () => {
        if (sortType == "byRisk") {
            return (Object.keys(props.historic_data_chart).sort((a, b) => props.historic_data_chart[b].value - props.historic_data_chart[a].value))
        }
        else {
            return (Object.keys(props.historic_data_chart).sort((a, b) => a - b))
        }
    }
    const ToggleButton = styled(MuiToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: 'var(--btn-bg)',
            borderRadius: '4px'
        }
    });

    let batchClass = (physiological) => {
        var chronological = props.age_round;
        var diff = roundNumber((chronological - physiological), 1);
        if (diff < -2)
            return ('risk-bg');
        else if (diff > 2)
            return ('safe-bg');
        else
            return ('normal-bg');
    }

    let batchClassColor = (physiological) => {
        var chronological = props.age_round;
        var diff = roundNumber((chronological - physiological), 1);
        if (diff < -2)
            return ('risk');
        else if (diff > 2)
            return ('safe');
        else
            return ('normal');
    }

    let batchClassBr = (physiological) => {
        var chronological = props.age_round;
        var diff = roundNumber((chronological - physiological), 1);
        if (diff < -2)
            return ('risk-br');
        else if (diff > 2)
            return ('safe-br');
        else
            return ('normal-br');
      }

    let batchName = (physiological) => {
        var chronological = props.age_round;
        var diff = roundNumber((chronological - physiological), 1);
        if (diff < -2)
            return ('Risk');
        else if (diff > 2)
            return ('Safe');
        else
            return ('Normal');
    }

  
    let renderHistory = (ad) => {
        var historic_data = props.historic_data_chart && props.historic_data_chart[ad] ? props.historic_data_chart[ad] : []
        let hdata = historic_data.filter(bv => bv.value);
        if (hdata.length > 0)
            return (
                props.timeBoundaries ? <BiomarkerSparkline sortType={sortType} historic_data_values={hdata} timeBoundaries={props.timeBoundaries}
                    uniqueID={`boa_${props.historic_data_chart[ad] ? props.historic_data_chart[ad][0].enLabel + "_" + sortType : ''}`} /> : ''
            );
        else
            return (null);
    }
    let renderIcon = (ad) => {
        var historic_data = props.historic_data_chart && props.historic_data_chart[ad] ? props.historic_data_chart[ad] : []
        if (historic_data && _.head(historic_data).spriteName != 'tru') {
            return (
                <SpriteIcon label={historic_data ? _.head(historic_data).enLabel : ''}
                    sprite={historic_data ? _.head(historic_data).spriteName : ''} theme={props.theme} />
            )
        }
        else
            return (<img className='trudiag-svg-logo' src={props.marqueeBioCount && props.marqueeBioCount == 1 ? tru : tru} />)
    }

    return (
        <>
            {props.categories && props.recommendation_list && props.historic_data_chart ?
                <div className='biomarker-of-age'>
                    <div className="row header no-gutters b-bottom">
                        <div className="col">
                            <div className="heading-1">{props.terms['Biomarkers of Aging']}</div>
                        </div>
                    </div>

                    <div className='row biomarker-of-age-data pl-4 no-gutters pt-4'>
                        <div className='col-lg-6 col-md-12 biomarker-of-age-data-left-container'>
                            <div className='row filter-btn no-gutters d-flex align-items-center'>
                                <div className='col-4 toggle-button-risk-deault'>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={sortType}
                                        exclusive
                                        onChange={filterBiomaker}
                                        aria-label="Platform"
                                        fullWidth={true}
                                        size={'small'}
                                        sx={{ textTransform: 'none' }}
                                    >
                                        <ToggleButton value="default">{labels.analytics_labels.default}</ToggleButton>
                                        <ToggleButton value="byRisk">{labels.analytics_labels.by_risk}</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                <div className='col'>
                                    <div className='heading-2 d-flex align-items-center' onClick={() => props.toggleBiomarkerComparePopup(true)}>
                                        < DifferenceOutlinedIcon />
                                        <span className='pl-1'>
                                        {labels.analytics_labels.compare}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="biomarker-card">

                                <div className='row pt-2 no-gutters'>

                                    {props.historic_data_chart ?
                                        historic_data_chart_keys().map(ad => {
                                            if (typeof (props.historic_data_chart[ad]) == constants.object && props.historic_data_chart[ad].length > 0) {
                                                return (
                                                    <div className='col-lg-4 col-md-4 col-sm-4 col-6 biomarker-card-div-1024 '>
                                                        <Box sx={{ minWidth: '100%' }} onClick={() => {
                                                            props.toggleBioAgeOverview(_.head(props.historic_data_chart[ad]).code.toLowerCase(),
                                                                _.last(props.historic_data_chart[ad]).value, _.last(props.historic_data_chart[ad]).visitDate, 'biomarker', _.head(props.historic_data_chart[ad]).cultureLabel,
                                                                props.history, _.head(props.historic_data_chart[ad]).label, _.last(props.historic_data_chart[ad]), ad); setActive(_.head(props.historic_data_chart[ad]).label)
                                                        }}>
                                                            <Card sx={{ height: '200px' }} variant="outlined" className={`${batchClass(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : "")} ${batchClassBr(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : "")}`} >{
                                                                card({
                                                                    title: props.presentPatient.languagePreference != "en" ? (props.historic_data_chart[ad] ? _.head(props.historic_data_chart[ad]).label : '') : '',
                                                                    label: props.historic_data_chart[ad] ? _.head(props.historic_data_chart[ad]) && _.head(props.historic_data_chart[ad]).label : '',
                                                                    value: props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : '',
                                                                    code: props.historic_data_chart[ad] ? _.head(props.historic_data_chart[ad]) && _.head(props.historic_data_chart[ad]).code : '',
                                                                    icon: renderIcon(ad),
                                                                    batchClass: batchClass(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : ""),
                                                                    batchClassColor: batchClassColor(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : ""),
                                                                    batchName: batchName(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : ""),
                                                                    graph: renderHistory(ad)
                                                                })}</Card>
                                                        </Box>
                                                    </div>
                                                )
                                            }
                                        })
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 pl-1 biomarker-of-age-data-right-container pr-2'>
                            {props.biomarker_key_name ? <BiomarkerAgingOverview {...props} /> : ''}
                        </div>
                    </div>

                </div>
                : null
            }
        </>
    );
}
export default connect()(BiomarkerAgingComponent);
export default [
    {
        class: 'grade-Ap',
        display: 'A+',
        description: 'Athletic'
    },
    {
        class: 'grade-C',
        display: 'C',
        description: 'Borderline'
    },
    {
        class: 'grade-A',
        display: 'A',
        description: 'Optimal'
    },
    {
        class: 'grade-Cm',
        display: 'C-',
        description: 'Sub Par'
    },
    {
        class: 'grade-Am',
        display: 'A-',
        description: 'Quite Good'
    },
    {
        class: 'grade-Dp',
        display: 'D+',
        description: 'Mostly Poor.'
    },
    {
        class: 'grade-Bp',
        display: 'B+',
        description: 'Totally Healthy'
    },
    {
        class: 'grade-D',
        display: 'D',
        description: 'You might want to get that looked at.No, seriously.Now.'
    },
    {
        class: 'grade-B',
        display: 'B',
        description: 'Healthy'
    },
    {
        class: 'grade-Dm',
        display: 'D-',
        description: 'Oh...ouch.Too late.'

    },
    {
        class: 'grade-Bm',
        display: 'B-',
        description: 'Mostly Healthy'
    },
    {
        class: 'grade-F',
        display: 'F',
        description: `Thanks for visiting the ER.Hope you don't die.`
    },
    {
        class: 'grade-Cp',
        display: 'C+',
        description: 'Borderline - Good'
    }
]
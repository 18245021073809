import React, { useEffect, useState } from 'react';
import labels from '../../config/localization';
import './multiFactor-auth-style.scss';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import EmailVerification from '../EmailVerification/EmailVerification';
import AuthenticatorVerification from '../AuthenticatorVerification/AuthenticatorVerification';
import constants from '../../config/constants';

const MultiFactorAuth = (props) => {
    const [authMethod, setAuthMethod] = useState('');
    const [isOnEmail, setIsOnEmail] = useState(false);
    const [isOnAuth, setIsOnAuth] = useState(false);
    const [email, setEmail] = useState('');
    const [isMFARequired, setIsMFARequired] = useState(true)

    useEffect(() => {
        props.getAuthStatus()
        if (!props.isAuthenticated && window.location.href.indexOf('verify') == -1) {
          window.location.replace('/verify')
        }
        else if (props.isAuthenticated && localStorage.getItem(constants.accessToken)) {
          if (props.loggedInUser && (props.loggedInUser.role == 'admin' || props.loggedInUser.role == 'caregiver')
            && window.location.href.indexOf('patients/list') == -1) {
            window.location.replace('/patients/list')
          } else if (props.loggedInUser && (props.loggedInUser.role == 'patient')) {
            window.location.replace('/analytics')
          } else {
            window.location.replace('/verify')
          }
        }
        const storedUserData = JSON.parse(localStorage.getItem('userData'));
        let emailMFA =  props.currentUser && props.currentUser.userMFA && props.currentUser.userMFA.find(user => user.mfaMode == 'email')
        if (emailMFA) {
        setEmail(emailMFA.mfaValue)
        }
        else {
            if (props.currentUser && props.currentUser.email){
                setEmail(props.currentUser.email)
            }
            else {  
                setEmail(storedUserData && storedUserData.userName && storedUserData.userName)
            }
        }
        setIsMFARequired( storedUserData && storedUserData.isMFARequired )
        return () => {
            // componentWillUnmount() //
        }
    }, []);
    
    const handleAuthMethod = (e) => {
        setAuthMethod(e.target.value);
    };

    const handleEmailDone = () => {
        setIsOnEmail('');
    };

    const handleAuthDone = () => {
        setIsOnAuth('');
    };

    const handleClose = () => {
        handleEmailDone()
        handleAuthDone()
    }

  return (
    <>
    <div className='verification verify col-12'>
        <div className="pt-3 pb-5 col-12 no-gutters d-flex justify-content-center align-items-center">
            <div className='d-flex flex-column align-items-center text-center'>
                <div className='label-4'>
                    Protecting your account is our highest priority
                </div>
                <div className='label-5 col-10 col-md-8'>
                    For your account security, we now require two-factor authentication
                </div>
            </div>
        </div>
        <div className='col-12 no-gutters d-flex justify-content-center align-items-center'>
            <div className='card auth-method p-4 col-12 col-md-8 col-lg-6'>
                <div className='label-6 pb-3'>Please select your authentication method</div>
                <div className='pb-3'>
                    <div className='label-7'>
                        <input type="radio" name="authMethod" checked={authMethod === 'email'} value="email" onChange={(e) => handleAuthMethod(e)}/> Email verification
                    </div>
                    <div className='label-8 pl-3'>
                        {`We'll send a 6-digit code to ${email}`}
                    </div>
                </div>
                <div className='pb-3'>
                    <div className='label-7'>
                        <input type="radio" name="authMethod" checked={authMethod === 'auth'} value="auth" onChange={(e) => handleAuthMethod(e)}/> Authenticator app <span className='ml-2 recommended'>Recommended</span>
                    </div>
                    <div className='label-8 pl-3'>
                        Set up an app like Google Authenticator or Duo Mobile to generate login codes
                    </div>
                </div>
                {authMethod === "auth" &&
                    <div className='card pr-3 pt-3 mb-3'>
                        <div className='col-12 d-flex no-gutters p-0'>
                            <div className='col-1 no-gutters justify-content-center d-flex'>
                                <LightbulbOutlinedIcon sx={{color: '#337ab7', fontSize: '20px', background: '#80808033', borderRadius: '22px'}} />
                            </div>
                            <div className='col-11 no-gutters'>
                                <div className='label-9'>Set Up authenticator app</div>
                                <div className="label-8">An authenticator app goes beyond email verification to provide you with maximum account security.</div>
                                <div className='pt-3 pb-3'>
                                    <button className='btn btn-primary btn-getStarted' onClick={() => { setIsOnAuth(true); props.getQRCode()}}>Get started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" disabled={authMethod === "email" ? false : true} onClick={() => { setIsOnEmail(true);  props.sendOTP('email', email, true)}}>
                        Next
                    </button>
                    { !isMFARequired ?
                        <div >  
                            <button type="button" className="btn btn-primary btn-skip" onClick={() =>{props.handleSuceessDone()}}>
                                Skip
                            </button>
                        </div>
                    : null }
                </div>
            </div>
        </div>
    </div>
    {isOnEmail  && <EmailVerification {...props} onDone={handleEmailDone} onClose={handleClose}/>}
    {isOnAuth  && <AuthenticatorVerification {...props} onDone={handleAuthDone} onClose={handleClose} />}
    </>
  );
};

export default MultiFactorAuth;

export default `
@page{
    size:  9in 11in;
    margin-bottom:10px;
}

.phenoAge_levine svg{
    // width: 50px !important;
    height: 50px !important;
    position: relative;
    //left: 10px;
    bottom: 12px;
}


// .biomarker.phenoAge_levine {
//     //display: inline-block;
//     //margin-bottom: 20px;
//     //width: 170px;
//     position: relative;
//     //top: 18.8px;
//     right: 17px;
// }

.biomarker.truAge_trudiag .biomarker-details {
    position: relative;
    right: 16px;
}

use{
fill: #0093c2;
}	
svg symbol[id="pheno"]>g {
fill: inherit;
}

.biomarker-section{
    column-count:3;
    padding-bottom: 30px;
    display: contents;
}
.display-contents{
    display: contents!important;
}
.complete-col-span{
    column-span: all;
    page-break-after: avoid;
    margin-top: auto;
}
.p-5{
    padding: 15px;
}
.individual-summary-detail{
    margin-bottom: 20px;
}
p{
    margin:0!important;
}
.visit-summary-template{
    font-family: "Sarala", 'Noto Sans JP'!important;
    padding: 0;
}
.report-title{
    font-style: italic;
    color: #0093c2;
    margin-left: 5px;
}
.visit-summary-template .title{
    background-color: #0093c2;
    color: white;
    padding: 15px;
}
.recommendations-biomarker{
    flex-wrap: wrap;
}
.jw-recommendation-flex{
    display:flex;
}
.jw-recommendation-details{
    page-break-inside: avoid!important;
    display: flex;
}
.visit-summary-sidebar{
    display: flex;
    flex-direction: column;
    position: fixed;
    border-top: 5px solid #0093c2;
    background-color: #f2f7fa;
    height: 100%;
    max-width: 20%;
    padding: 30px;
    font-size: small;
}
.visit-summary-sidebar .summary-title{
    color: #0093c2;
}
.rec-division{
    color: #0093c2;
    margin: 30px 30px;
    border: 1px solid;
}
.visit-summary-right-section{
    margin-left: 28%;
    position: relative;
}
.biomarker{
    display: inline-block;
    margin-bottom: 20px;
    width: 170px;
}
.biomarker .icon{
    margin: 5% 15px 0 15px;
}
.biomarker .icon svg {
    stroke: #0093c2;
    color: #0093c2;
    width: 30px;
    height: 30px;
}
.jw-icon {
    color: #000000;
    display: grid;
    vertical-align: text-bottom;
    text-align: center;
    width: 100px;
    page-break-inside: avoid!important;
    font-size: 12px;
    word-break: break-word;
    line-height: 1;
    height: 70px;
}
.jw-icon svg{
    max-width: 1cm;
    max-height: 1cm;
    color: #000000;
    margin: auto;
    page-break-inside: avoid!important;
}
.biomarker-details{
    color: #0093c2!important;
}
.biomarker-details .name{
    font-weight: 500;
    margin: 0;
    font-size: 18px;
}
.stripe-bg:empty:after{
    content: "I'm sorry, there was not enough medical data present to generate a summary. Typically this means your data has not been entered into our system yet. Please try again later."
}
.stripe-bg:empty{
    padding :15px;
}
.grade-card .grade-block{
    margin-right:15px;
    font-weight: 700;
}
.grade-block{
    line-height: 26px;
    //margin-right: 10px;
    flex-basis: 0.5in;
    font-weight: 600;
    max-width: 30px;
}
.grade-card .description{
}
.rec-provider-name{
    color: #0093c2!important;
}
.jw-recommendation-card{
    page-break-inside: avoid!important;
    margin: 20px 0px;
}
.report-cards-section{
    column-count: 2;
}
.report-cards-section .content .category-points,.report-cards-section .content .category-name{
    margin:10px 0;
}
.category-points{
    display: flex;
    page-break-inside: avoid!important;
    max-width: 350px;
    word-break: break-word;
}
.category-name{
    page-break-inside: avoid!important;
    word-break: break-word;
    font-size: small;
    margin: 0;
    font-size: inherit;
}
.complete-col-span{
    column-span: all;
    padding-bottom: 10px;
}

.site-logo-sec{
    width: 100px;
    margin-left: 20%;
}
.grade-Ap               { color: #2eb263; }
.grade-A                { color: #37b45e; }
.grade-Am               { color: #48b854; }
.grade-Bp               { color: #59bc4a; }
.grade-B                { color: #72c13b; }
.grade-Bm               { color: #7bc336; }
.grade-Cp               { color: #c7d40a; }
.grade-C                { color: #D8D800; }
.grade-Cm               { color: #cda60e; }
.grade-Dp               { color: #c2771b; }
.grade-D                { color: #ba5225; }
.grade-Dm               { color: #b64129; }
.grade-F                { color: #b2302e; }
.grade-NA               { color: #999; }
.header{
    column-span: all;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 1000;
}
.jw-recommendation-content{
    padding: 0;
    max-width: 500px;
    page-break-inside: avoid!important;
}
.d-flex{
    display: flex;
}
.d-inline-flex{
    display: inline-flex;
}
// .report-cards-list{
//     display: flex;
//     flex-direction: column;
// }
.stripe-bg{
    background: repeating-linear-gradient(-45deg, #f2f7fa, #fff 10px);
    padding: 0px 15px;
}
.stripe-bg{
    height:auto;
}
h2,h4{
    margin: auto;
}
h3{
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
h3.name{
    color: #004b70;
}
.help-block{
    padding: 0!important;
  display: block;
  color: #737373;
}
.grade-card {
    display: inline-flex;
    min-width: 180px;
    min-height: 30px;
}
.grade-card .description {
}
.overall-grade-section{
    display: flex;
}
.overall-grade-section .grade-block{
    font-size: 2rem !important;
    margin-right: 0.8rem;
}
.overall-grade-section .overall-points{
    font-size: 2rem;
    line-height: 1.5rem;
}
.overall-grade-point{
    margin-bottom: 1.5%;
    font-size: 1rem;
}
.reportImg img {
    display: block;
    width: 5vh;
    position: relative;
    bottom: 13px;
    right: 5px;
}
.alignAllBiomarkerAges{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}
.truAge>div div:nth-child(2){   
    position: relative;
    right: 16px;
}
`
export default class GradingAssistant {
  static grade_categories( categories )
  {
    categories.forEach(this.grade_category.bind(this));
  }

  static grade_category( category )
  {
    category.sections.forEach(this.grade_section.bind(this))
  }

  static nonBlank( value )
  {
    return(value);
  }

  static isNumeric = (value) => typeof value === 'number';

  static grade_section( section )
  {
    var points = section.data.map(this.get_points).filter(this.isNumeric); 
    var total = points.reduce(this.sumByReduce, 0)
    var count = points.length;

    if( count > 0 ) { return(total / count); }
  }

  static grade_category( category )
  {
    var points = category.sections.map(this.grade_section.bind(this)).filter(this.isNumeric);
    var total = points.reduce(this.sumByReduce, 0)
    var count = points.length;

    if( count > 0 ) { return(total / count); }
  }

  static get_points( datum )
  {
    return( datum.points );
  } 

  static sumByReduce(a, b) { return( a + b ) };
};

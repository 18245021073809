import { BIOMARKERS } from '../tools/biomarkers';
import _ from 'lodash';

let uniformString = (...atts) => {
  let flat = [].concat(...atts);
  let out = atts.join('-').replace(/[^a-zA-Z0-9]/g, '-').replace(/--*/, '-').toLowerCase();
  return (out)
}

let hasValue = (datum) => {
  return (datum && datum.value && datum.value !== "")
}

let mostRecentDatum = (datum) => {
  if (hasValue(datum)) {
    return (datum);
  }
  else {
    let list = datum.filter(datum => hasValue(datum));
    if (list.length == 0) {
      return (null);
    }
    else {
      return (list[list.length - 1]);
    }
  }
}

let mostRecentValue = (datum) => {
  let recent = mostRecentDatum(datum);
  return (recent ? recent.value : null);
}
let roundNumber = (rnum, rlength) => {
  rlength = rlength ? parseInt(rlength) : 3
  var newnumber = Math.round(rnum * Math.pow(10, rlength)) / Math.pow(10, rlength);
  return newnumber;
}
let roundNumberFixedToOne = (rnum, rlength) => {
  rlength = rlength ? parseInt(rlength) : 3
  var newnumber = Math.round(rnum * Math.pow(10, rlength)) / Math.pow(10, rlength);
  return newnumber.toFixed(1);
}

let getRoundedValue = (value, scale) => {
  if (value) {
    var pattern = /[<>]/
    var rangeValuePattern = /^\d+(\.\d+)?-\d+(\.\d+)?$/
    if (value && rangeValuePattern.test(value)) {
        return value
    }
    else if (value && value.toString().match(pattern) == null) {
      if (!isNaN(parseFloat(value)))
        return roundNumber(parseFloat(value), scale)
      else
        return value
    }
    else {
      let symobol = value.slice(0, 1);
      if (!isNaN(parseFloat(value.replace(symobol, ""))))
        return `${symobol}${roundNumber(parseFloat(value.replace(symobol, ""), scale))}`
      else
        return `${symobol}${value.replace(symobol, "")}`

    }
  }
}
//handling reference ranges and rounding to scale
let getReferenceRanges = (range, scale) => {
  scale = scale ? parseInt(scale) : 3
  if (range == null || range.trim() == "") {
    return null;
  }
  else if (_.head(range) == '-' && range.indexOf("--") == -1) {
    var ranges = range.substr(1).trim().split('-');
    return `-${getRoundedValue(ranges[0], scale)} - ${getRoundedValue(ranges[1], scale)}`
  }
  else if (_.head(range) == '-' && range.indexOf("--") != -1) {
    range = range.replace("--", "-");
    var ranges = range.substr(1).Trim().split('-');
    return `-${getRoundedValue(_.head(ranges), scale)} - -${getRoundedValue(_.last(ranges), scale)}`
  }
  else if (range.indexOf(">") != -1) {
    var ranges = range.replace(">", "");
    ranges = ranges.replace("=", "");
    return `> ${getRoundedValue(ranges, scale)}`
  }
  else if (range.indexOf("<") != -1) {
    var ranges = range.replace("<", "");
    ranges = ranges.replace("=", "");
    return `< ${getRoundedValue(ranges, scale)}`
  }
  else if (range.indexOf("-") != -1) {
    var ranges = range.split('-');
    return `${getRoundedValue(_.head(ranges), scale)} - ${getRoundedValue(_.last(ranges), scale)}`
  } else {
    return null
  }
}
let IsInRange = (range, value, row) => {
  if (row != null && row.points != null && 1.7 <= row.points <= 3.3) {
    return true;
  }
  if (range == null || range == "") {
    return false
  }
  else if (_.head(range) == "-") {
    var ranges = range.substr(1).split("-");
    if (ranges.length <= 2) {
      return _.inRange(value, -_.head(ranges), _.last(ranges))
    }
    else if (ranges.length == 3) {
      return _.inRange(value, -_.head(ranges), -_.last(ranges))
    }
  }
  else if (range.toString().match(/[<>]/)) {
    var symbol = range.slice(0, 1);
    if (symbol == ">") {
      if (parseFloat(value) > parseFloat(range.replace(symbol, ""))) {
        return true;
      }
    }
    else if (symbol == "<") {
      if (parseFloat(value) < parseFloat(range.replace(symbol, ""))) {
        return true;
      }
    }
    return false;
  }
  else if (range.indexOf("-") != -1) {
    var ranges = range.split("-");
    return inRangeInclusive(parseFloat(value), parseFloat(_.head(ranges)), parseFloat(_.last(ranges)))
  }
  else {
    return false;
  }
}

let inRangeInclusive = (value, start, end) =>  {
  return value >= start && value <= end;
}

let getDateFormat = (date) => {
  switch (date) {
    case 'ymd': return 'YYYY/MM/DD';
    case 'dmy': return 'DD/MM/YYYY';
    case 'mdy': return 'MM/DD/YYYY';
    // case 'ydm': return 'yyyy/dd/MM';
    default: return 'MM/DD/YYYY';
  }
}
export { uniformString, mostRecentDatum, mostRecentValue, roundNumber, getRoundedValue, getReferenceRanges, IsInRange, getDateFormat, roundNumberFixedToOne };

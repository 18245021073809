import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import BootstrapTable from 'react-bootstrap-table-next';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { loginInput } from '../../../reducers/global-reducer';
import constants from '../../../config/constants';
import en_labels from '../../../config/en_labels';
import { returnLocalizedDate } from '../../../utils/resolve-timer'
import CustomPanelFieldsComponent from './CustomPanelFieldsComponent'
import { Typeahead } from 'react-bootstrap-typeahead';

const imagePanel = (panelName) => {
    const images = require.context('../../../assets/panel_samples', false, /\.(jpe?g)$/);
    try {
        let imgsrc = images(`./${panelName}.jpg`);
        return imgsrc;
    }
    catch (err) {
        return null
    }
}
const stylesClasses = {
    labelClass: 'col-lg-2',
    inputClass: 'col-lg-7'
}
const getDateFormat = (date) => {
    switch (date) {
        case 'ymd': return 'yyyy/MM/dd';
        case 'dmy': return 'dd/MM/yyyy';
        case 'mdy': return 'MM/dd/yyyy';
        // case 'ydm': return 'yyyy/dd/MM';
        default: return 'MM/dd/yyyy';
    }
}
const importValidation = (currentOrder) => {
    let currentPanels = currentOrder.panels
    if (currentPanels.length) {
        let suiteName = currentOrder.suiteName
        if (suiteName == constants.visit_labels.cognitive_function || suiteName == constants.visit_labels.bloodwork || suiteName == constants.visit_labels.immune_function
            || suiteName == constants.visit_labels.dna_methylation || suiteName == constants.visit_labels.igg_Glycosylation)
            return true
        else
            return false
    }
    else
        return false
}
const orderHeaders = [
    labels.visit_page_labels.patient_survey_table_label,
    labels.visit_page_labels.patient_survey_table_value,
    labels.visit_page_labels.patient_survey_table_units,
    labels.visit_page_labels.patient_survey_table_ref_range,
    labels.visit_page_labels.patient_survey_table_status,
]

const isCustomPanel = (panelId, ordersList) => {
    return ordersList.find((o) => o.id == panelId).panelType
}

function getFormatedRange(row) {
    let range
    range = (row.low != null && row.high != null) ? row.low + '-' + row.high :
        (row.low && !row.high) ? '>' + row.low :
            (row.high && !row.low) ? '<' + row.high :
                ''
    return range
}

class IndividualOrder extends Component {
    componentDidMount() {
        setTimeout(() => {
            if (this.manualEntry && this.manualEntry.inputEntry) {
                this.manualEntry.inputEntry.focus()
            } else if (this.manualEntry) {
                this.manualEntry.focus()
            }
            this.props.GetAllNoumenonNames()
            this.props.getUnitsData()
        }, 2000);

    }

    handleDateOfBirth(value, datePickerId) {
        let localDate = value && typeof (value) == 'string' ? value : value ? new Date(value).toLocaleDateString('en-US') : null
        if (value && localDate.split('/').length == 3 && localDate.split('/').filter(d => d).length == 3) {
            if (!isNaN(new Date(value).getDate())) {
                this.props.updateResultStatus(value, datePickerId);
            } else if (value && isNaN(new Date(value).getDate())) {
                var dateParts = value.target.value.split(charSplit);
                this.props.updateResultStatus(new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`), datePickerId);
            }
        } else {
            // if (this.props.currentOrder.displayDate)
            //     this.props.editDateOfBirth(new Date(this.props.currentOrder.displayDate), this.props.history)
            // else
            // void (0)
            this.props.updateResultStatus(value, datePickerId);
        }
    }
    componentWillMount() {
        this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history)
        if (this.props.isCalledSetCurrentOrder) {
            this.props.setCurrentOrder(this.props.match.params.visitId, this.props.match.params.panelId, this.props.history, null)
            this.props.setCalledSetCurrentOrder(false)
        }
    }
    componentWillReceiveProps() {
        this.props.setHeaderType(constants.visit_labels.order)
    }
    componentDidUpdate(prevProps) {
        if (prevProps.location && (prevProps.location.pathname != this.props.location.pathname))
            setTimeout(() => {
                if (this.manualEntry && this.manualEntry.inputEntry) {
                    this.manualEntry.inputEntry.focus()
                } else if (this.manualEntry) {
                    this.manualEntry.focus()
                }
            }, 2000);
    }
    getEnumerableRange(range) {
        return range && range.length > 0 ? range.split(',').map(a => ({ range: a })) : []
    }
    render() {
        Array.from(document.getElementsByClassName("rbt-input-main")).map(s => { s.tabIndex = -1 })
        const historyColumns = [
            {
                dataField: 'createdAt', text: labels.orders_labels.entry_date, editable: false,
                formatter: (cellContent, row) => {
                    return moment(row.createdAt, "MMM-DD-YYYY").format('YYYY') != "0001" ?
                        <div>{returnLocalizedDate(row.createdAt).format('MMMM Do, YYYY h:mm A')}</div>
                        : <div>See Archives</div>
                },
                headerStyle: { width: '30%' }
            },
            {
                dataField: 'source', text: labels.oddities_labels.source_label, editable: false,
                formatter: (cellContent, row) => {
                    if (row.sourceType == null) {
                        return <div className='history-source' title="Manual entry">
                            Historic
                        </div>;
                    }
                    else if (row.sourceType.toLowerCase() == "user" || row.sourceType.toLowerCase() == "provider") {
                        return (
                            <div className='history-source' title="Manual entry">
                                Manual entry
                            </div>)
                    }
                    else if (row.sourceType.toLowerCase() == "message") {
                        return <div className='history-source' title={row.source}>
                            <Link to={`/messages/details/${row.sourceId}`}>{row.source}</Link>
                        </div>
                    }
                    else {
                        return <div className='history-source' title={row.sourceType}>
                            {row.sourceType}
                        </div>
                    }
                },
                headerStyle: { width: '25%' }
            },
            {
                dataField: 'source', text: labels.oddities_labels.authority_label, editable: false,
                formatter: (cellContent, row) => {
                    if (row.sourceType == null) {
                        return null;
                    }
                    if (row.sourceType.toLowerCase() == "message") {
                        return <div className='history-source' title="Server">
                            Server
                        </div>
                    }
                    else {
                        return <div className='history-source' title={row.source}>
                            {row.source}
                        </div>
                    }
                },
                headerStyle: { width: '25%' }
            },
            {
                dataField: 'observationCount', text: 'Observations', editable: false,
                headerStyle: { width: '10%' }
            },
            {
                dataField: 'result ', editable: false,
                formatter: (cellContent, row) => (<a className='history-panel-view-btn' onClick={() => {
                    if (this.props.loggedInUser
                        && ((this.props.loggedInUser.role == constants.logged_roles.AN))
                        || (this.props.loggedInUser.role == constants.logged_roles.CG
                            && this.props.loggedInUser.siteIds.includes(this.props.currentVisit.siteId)))
                        this.props.history.push(`/visits/${row.visitId}/results/${row.resultId}`)
                    else if (this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.CG
                        && !this.props.loggedInUser.siteIds.includes(this.props.currentVisit.siteId)) {
                        this.props.history.push('/patients/list')
                        setTimeout(() => {
                            this.props.toggleNotification(true, 'warning', '', [en_labels.not_allowed], false, 5000)
                        }, 1000);
                    }
                    else
                        this.props.history.push('/')
                }}><label className='text-info'> {labels.visit_page_labels.view} </label></a>),
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                }
            }
        ]
        const accessionMessageColumns = [
            {
                dataField: 'result ', editable: false,
                formatter: (cellContent, row) => (<button onClick={() => this.props.importMessagesbyAccessionNumber(row.accessionNumber, this.props.currentVisit.siteId, this.props.history)}>{labels.visit_page_labels.nav_link_import}</button>),
            },
            {
                dataField: 'patientName', text: labels.patient_list_labels.patient_title, editable: false,
                formatter: (cellContent, row) => (<label> {row.patientName} <br></br> {row.dob} <label></label> </label>)
            },
            {
                dataField: 'accessionNumber', text: labels.orders_labels.lab_accession, editable: false,
                formatter: (cellContent, row) => (<label> {row.from} <br></br>{row.accessionNumber} <label></label> </label>)
            },
            { dataField: 'observationDate', text: labels.orders_labels.draw_date, editable: false },
            { dataField: 'minPublicationDate', text: labels.orders_labels.rpt_date, editable: false },
            { dataField: 'messageCount', text: labels.orders_labels.message_count, editable: false },
        ]
        const getValueType = (val, isTextArea) => {
            if (val.toLowerCase() == 'enumerable' || val.toLowerCase() == 'tx') {
                return 'select'
            } else if (val.toLowerCase() == 'numeric' || val.toLowerCase() == 'nm') {
                return 'alphanumeric'
            } else if (val.toLowerCase() == 'date' || val.toLowerCase() == 'dt') {
                return 'text'//dateonly
            } else if (val.toLowerCase() == 'st' && isTextArea == 1) {
                return 'textarea'
            } else if (val.toLowerCase() == 'string' || (val.toLowerCase() == 'st' && isTextArea == null)) {
                return 'textonly'
            } else return 'text'
        }
        const getValue = (row, index) => {

            let valueType = getValueType(row.valueType, row.isTextArea)
            if (valueType != 'select' || (row.valueList != null && row.valueList.length == 0)) {
                return (index == 0) ? (row.valueType != "DT" ? <FormControl onBlur={this.props.updateResultStatus}
                    type={getValueType(row.valueType, row.isTextArea)}
                    defaultValue={row.value} id={row.panelFieldId}
                    ref={(me) => this.manualEntry = me}
                /> : <FormControl id={row.panelFieldId}
                    locale={this.props.currentVisit.reportPreference
                        ? this.props.currentVisit.reportPreference
                        : null}
                    selected={row.value && !isNaN(Date.parse(row.value)) ? new Date(row.value + 'T00:00:00') : false}
                    onChange={(e) => this.handleDateOfBirth(e, row.panelFieldId)}
                    disabledKeyboardNavigation
                    dateFormat={getDateFormat(this.props.currentVisit.datePreference)}
                    onKeyDown={(e) => {
                        if (e.key.toLowerCase() == 'enter') {
                            this.handleDateOfBirth(e.target.value, row.panelFieldId)
                        }
                    }}
                    // help={labels.allowed_date_format.replace('<>', getDateFormat(this.props.currentVisit.datePreference).toLocaleUpperCase())}
                    isClearable={row.value ? true : false}
                    placeholder={getDateFormat(this.props.currentVisit.datePreference).toLocaleUpperCase()}
                    type='date' {...stylesClasses} required={true} />)
                    :
                    (row.valueType != "DT" ? <FormControl onBlur={this.props.updateResultStatus}
                        type={getValueType(row.valueType, row.isTextArea)}
                        defaultValue={row.value} id={row.panelFieldId}
                    /> : <FormControl id={row.panelFieldId}
                        locale={this.props.currentVisit.reportPreference
                            ? this.props.currentVisit.reportPreference
                            : null}
                        selected={row.value && !isNaN(Date.parse(row.value)) ? new Date(row.value + 'T00:00:00') : false}
                        onChange={(e) => this.handleDateOfBirth(e, row.panelFieldId)}
                        disabledKeyboardNavigation
                        dateFormat={getDateFormat(this.props.currentVisit.datePreference)}
                        onKeyDown={(e) => {
                            if (e.key.toLowerCase() == 'enter') {
                                this.handleDateOfBirth(e.target.value, row.panelFieldId)
                            }
                        }}
                        // help={labels.allowed_date_format.replace('<>', getDateFormat(this.props.currentVisit.datePreference).toLocaleUpperCase())}
                        isClearable={row.value ? true : false}
                        placeholder={getDateFormat(this.props.currentVisit.datePreference).toLocaleUpperCase()}
                        type='date' {...stylesClasses} required={true} />)
            } else {
                return index == 0 ? <select className='form-control' ref={(me) => this.manualEntry = me} onBlur={this.props.updateResultStatus} id={row.panelFieldId} defaultValue={row.value ? returnTXValue(row.value, row.valueList) : 0}>
                    {row.valueList != null ? row.valueList.map(vl => <option value={vl ? vl : 0}>{vl}</option>) : null}
                </select> :
                    <select className='form-control' onBlur={this.props.updateResultStatus} id={row.panelFieldId} defaultValue={row.value ? returnTXValue(row.value, row.valueList) : 0}>
                        {row.valueList != null ? row.valueList.map(vl => <option value={vl ? vl : 0}>{vl}</option>) : null}
                    </select>
            }


        }
        const returnTXValue = (value, valueList) => {
            let result = []
            value && valueList != null && valueList.length ?
                result = valueList.filter(s => {
                    if (s && (s.toLowerCase() == value.toLowerCase())) {
                        return s;
                    }
                }) : null
            if (result.length) {
                return _.head(result)
            }
        }
        const getRowClasses = (row) => {
            let classes = null;
            let value = parseFloat(row.value)
            if (row.valueType.toLowerCase() == 'nm' || row.valueType.toLowerCase() == 'numeric') {
                if (row.value != null && row.value.trim()) {
                    if (row.low != null || row.high != null) {
                        if ((row.high != null && value > row.high) || (row.low != null && value < row.low)) {
                            if (row.boundaryRange && row.units == row.original_units) {
                                if ((row.boundaryRange.high != null && value > row.boundaryRange.high) || (row.boundaryRange.low != null && value < row.boundaryRange.low)) {
                                    classes = 'exceed-boundary-range';
                                } else {
                                    classes = 'exceed-reference-range';
                                }
                            } else {
                                classes = 'exceed-reference-range';
                            }
                        }
                    }
                    else if (row.boundaryRange && row.units == row.original_units) {
                        if ((row.boundaryRange.high != null && value > row.boundaryRange.high) || (row.boundaryRange.low != null && value < row.boundaryRange.low)) {
                            classes = 'exceed-boundary-range';
                        }
                    }
                }
            }
            return classes;
        }
        return (
            <div className='container individual-visit'>
                <div className='col-md-12 col-sm-12 col-xs-12'>
                    <Nav tabs className='mb-4 pl-3 width-nav' >
                        <NavItem onClick={() => this.props.setActiveTab('history')}>
                            <NavLink className={this.props.activeTab == 'history' ? 'active text-secondary' : 'inactive-tab'}>
                                {labels.visit_page_labels.nav_link_history}
                            </NavLink>
                        </NavItem>
                        <NavItem onClick={() => this.props.setActiveTab('import')}>
                            <NavLink className={this.props.activeTab == 'import' ? 'active text-secondary' : 'inactive-tab'}>
                                {labels.visit_page_labels.nav_link_import}
                            </NavLink>
                        </NavItem>
                        <NavItem onClick={() => {
                            this.props.setActiveTab('manual-entry');
                            setTimeout(() => {
                                if (this.manualEntry && this.manualEntry.inputEntry) {
                                    this.manualEntry.inputEntry.focus()
                                } else if (this.manualEntry) {
                                    this.manualEntry.focus()
                                }
                            });
                        }}>
                            <NavLink className={this.props.activeTab == 'manual-entry' ? 'active text-secondary' : 'inactive-tab'}>
                                {labels.visit_page_labels.nav_link_manual_entry}
                            </NavLink>
                        </NavItem>

                        {this.props.currentVisit.planType == labels.visit_page_labels.professional && this.props.ordersList && this.props.ordersList.length > 0 && isCustomPanel(this.props.match.params.panelId, this.props.ordersList) ?
                            <NavItem onClick={() => this.props.setActiveTab('custom_fields')}>
                                <NavLink className={this.props.activeTab == 'custom_fields' ? 'active text-secondary' : 'inactive-tab'}>
                                    {labels.visit_page_labels.nav_link_custom_fields}
                                </NavLink>
                            </NavItem>
                            : null
                        }
                        {/* {imagePanel(this.props.match.params.panelName) ? */}
                        {this.props.currentOrder && this.props.currentOrder.sample ?
                            <NavItem onClick={() => this.props.setActiveTab('sample-image')}>
                                <NavLink className={this.props.activeTab == 'sample-image' ? 'active text-secondary' : 'inactive-tab'}>
                                    {labels.visit_page_labels.nav_link_sample_image}
                                    {/* <span className='badge badge-pill badge-warning text-light' >{labels.orders_labels.new}</span> */}
                                </NavLink>
                            </NavItem> : null}
                    </Nav>
                    <TabContent activeTab={this.props.activeTab} >
                        <TabPane tabId='history' className='visits-history-section'>
                            {this.props.orderResult && this.props.orderResult.length ?
                                <div className='bootstrap-table-container'>
                                    <BootstrapTable striped={true} bordered={false} hover={false}
                                        bootstrap4 keyField='id'
                                        data={this.props.orderResult} columns={historyColumns} />
                                </div>
                                :
                                <div className='jumbotron pl-5 pr-5 ' >
                                    <h4 className='pl-5 pr-5 ml-5 mr-5'>{labels.visit_page_labels.no_history}</h4>
                                </div>}

                        </TabPane>
                        <TabPane tabId='import'>

                            {!(this.props.currentVisit.acceptsMessages == 1) ?
                                <div className='jumbotron'>
                                    <div className='p-5 text-dark'>
                                        <h2>{labels.visit_page_labels.import_account_setup}</h2>
                                        <p>{labels.visit_page_labels.import_isnt_setup}</p>
                                        <h5><a href='mailto:contact@physioage.com'>{labels.visit_page_labels.import_contact_us} </a> {labels.visit_page_labels.import_details}</h5>
                                    </div>
                                </div> :
                                this.props.currentOrder.panels ?
                                    (!(importValidation(this.props.currentOrder))) ?
                                        <div className='jumbotron '>
                                            <div className='p-5 text-dark'>
                                                <h3 className='text-dark '>{labels.visit_page_labels.import_bloodwork}</h3>
                                            </div>
                                        </div>
                                        :
                                        <React.Fragment>
                                            {this.props.isSearchable ?
                                                <React.Fragment>
                                                    <div className='jumbotron text-dark '>
                                                        <div className='p-5 text-dark'>
                                                            <h3>{labels.visit_page_labels.import_search_header}</h3>
                                                            <p>{labels.visit_page_labels.import_search}</p>
                                                            <FormControl
                                                                id='patientName'
                                                                type='text'
                                                                className='w-50'
                                                                onChange={(e) => this.props.searchCustomName(e)} labelKey={'patientName'}
                                                                placeholder={labels.inbox_labels.name_label}
                                                                defaultValue={this.props.currentVisit.lastname}
                                                            />
                                                            {/*link  form enter  and api call for accession search */}
                                                        </div>
                                                    </div>
                                                    {this.props.currentMessages && this.props.currentMessages.length ?
                                                        <div className='import-tab-func'>
                                                            <div className='bootstrap-table-container'>
                                                                <BootstrapTable striped={false} bordered={false} hover={true}
                                                                    bootstrap4 keyField='id'
                                                                    data={this.props.currentMessages ? this.props.currentMessages : []} columns={accessionMessageColumns} />
                                                            </div>
                                                        </div>

                                                        : <div className='alert alert-danger ml-3'>
                                                            {labels.visit_page_labels.import_patient_notfound}
                                                        </div>}  </React.Fragment>
                                                : <React.Fragment>
                                                    {this.props.isImporting ?
                                                        (this.props.importmessages && this.props.importmessages.length ?
                                                            <div className='alert alert-info'>
                                                                <h3>{labels.orders_labels.import_headers}</h3>
                                                                <p>{labels.orders_labels.import_observations}</p>
                                                                <table className='react-bootstrap-table table '>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{labels.orders_labels.lab_accession}</th>
                                                                            <th>{labels.oddities_labels.reference}</th>
                                                                            <th>{labels.inbox_labels.received}</th>
                                                                            <th>{labels.visit_page_labels.patient_survey_table_status}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    {this.props.importmessages.map(x =>
                                                                        <tr>
                                                                            <td>{x.accessionNumber}</td>
                                                                            <td>{x.reference}</td>
                                                                            <td>{x.publicationDate ? moment(x.publicationDate).format(constants.dateFormats.ymd) : ''}</td>
                                                                            <td>
                                                                                <div>{x.orderId ? labels.orders_labels.imported_status : labels.orders_labels.wait_status}</div>
                                                                            </td>
                                                                        </tr>)}
                                                                </table>
                                                            </div> : <div>
                                                                <div className='alert alert-danger'>
                                                                    <h3>{labels.orders_labels.import_failed}</h3>
                                                                    <p>{labels.orders_labels.import_failed_alert}</p>
                                                                </div></div>) : ''
                                                    }  </React.Fragment>}
                                        </React.Fragment>
                                    : null}

                        </TabPane>
                        <TabPane tabId='manual-entry' className='panels-table'>
                            {!_.isEmpty(this.props.currentOrder) && this.props.currentOrder.panels.length > 0 ?
                                <div>
                                    <div className='alert alert-info'>
                                        {labels.visit_page_labels.customer_service_label}<a href='mailto:contact@physioage.com'>
                                            {labels.visit_page_labels.customer_service_name}</a>{labels.visit_page_labels.customer_service_label_2}
                                    </div>
                                    <div className='bootstrap-table-container'>
                                        <table className='react-bootstrap-table table'>
                                            <thead>
                                                <tr>{orderHeaders.map(oh => (
                                                    oh == labels.visit_page_labels.patient_survey_table_status ? <th className='pl-4'><span>{oh}</span></th> :
                                                        <th>{oh}</th>
                                                ))}</tr>
                                            </thead>
                                            <tbody>
                                                {this.props.currentOrder
                                                    && this.props.currentOrder.panels
                                                    ? this.props.currentOrder.panels.map((row, index) => (
                                                        <tr className={getRowClasses(row)}>
                                                            <td>
                                                                <label>{row.primaryLabel}
                                                                    {(row.primaryLabel != null && ((row.secondaryLabel.toLowerCase().slice(0, 11) === row.primaryLabel.toLowerCase().slice(0, 11)) || (row.secondaryLabel === row.primaryLabel))) ? null :
                                                                        <div className='text-muted'> {row.secondaryLabel}</div>}
                                                                    <span className='bounds-caution'>{labels.visit_page_labels.out_of_bounds}</span>
                                                                </label>
                                                            </td>
                                                            {row.valueType && row.valueType.toLowerCase() == "st" && row.isTextArea == 1 ?
                                                                <td className='observation-value' colspan="3">
                                                                    {getValue(row, index)}
                                                                </td>
                                                                :
                                                                <td className='observation-value'>
                                                                    {getValue(row, index)}
                                                                </td>
                                                            }
                                                            {row.valueType && row.valueType.toLowerCase() == "st" && row.isTextArea == 1 ?
                                                                "" :
                                                                <>
                                                                    <td className='observation-value'>
                                                                        {(row.valueType == "NM" || row.valueType == "Numeric") ?
                                                                            <Typeahead
                                                                                name={'units'}
                                                                                placeholder="Choose a unit..."
                                                                                labelKey={'units'}
                                                                                textKey={'units'}
                                                                                defaultSelected={row.units ? [row.units] : ''}
                                                                                id={row.panelFieldId}
                                                                                options={this.props.unitList
                                                                                    ? this.props.unitList : []}
                                                                                renderMenuItemChildren={(option) => (
                                                                                    <span id={option.id ? option.id : 0} key={`${option.units}`}>{`${option.units}`}</span>
                                                                                )}
                                                                                onChange={(e) => {
                                                                                    if (e) {
                                                                                        this.props.updateUnitsAndEeferenceRange(_.head(e) ? _.head(e).units : '', `${row.panelFieldId}`, 'units')
                                                                                    }
                                                                                }}

                                                                                onBlur={(e) => {
                                                                                    if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                                                                        this.props.updateUnitsAndEeferenceRange(e.target.value, `${row.panelFieldId}`, 'units')
                                                                                    }
                                                                                }}
                                                                            />
                                                                            : row.units
                                                                        }
                                                                    </td>
                                                                    <td className='form-group'>
                                                                        {(row.valueType == "NM" || row.valueType == "Numeric") ?
                                                                            <FormControl onBlur={(e) => this.props.updateUnitsAndEeferenceRange(e.target.value, `${row.panelFieldId}`, 'referenceRange')} name='referenceRange'
                                                                                placeholder='Enter reference range' type='text' defaultValue={getFormatedRange(row)} className='width103 form-control' tabindex="-1" />
                                                                            : (row.valueType == "TX") ?
                                                                                <FormControl value={this.getEnumerableRange(row.referenceRangesSet ? row.referenceRangesSet : [])}
                                                                                    onChange={(e) => this.props.updateUnitsAndEeferenceRange(e, `${row.panelFieldId}`, 'referenceRange')} type='select' id='referenceRangeTypeAhead' isMulti={true}
                                                                                    getOptionLabel={opt => opt.range}
                                                                                    getOptionValue={opt => opt.range}
                                                                                    options={row.valueList ? row.valueList.map((v) => ({ range: v })) : []} />
                                                                                : <label>{getFormatedRange(row)}</label>
                                                                        }
                                                                    </td>
                                                                </>
                                                            }
                                                            <td className='ta-c'><span>{row.resultStatus}</span></td>
                                                        </tr>)) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                this.props.currentVisit.planType == labels.visit_page_labels.professional && this.props.currentOrder
                                    && this.props.currentOrder.panels ?
                                    <div className='alert alert-info'>
                                        {labels.visit_page_labels.no_panel_label}
                                        <a onClick={() => this.props.setActiveTab('custom_fields')}> here</a>
                                    </div> : ''
                            }

                            {this.props.currentOrder && this.props.currentOrder.panels && !_.isEmpty(this.props.currentOrder) && this.props.currentOrder.panels.length > 0 ?
                                <FormControl type='button' className='btn-save'
                                    btnType='primary'
                                    text={labels.visit_page_labels.btn_save_label}
                                    onClick={() => !(this.props.observationError.length)
                                        ? this.props.saveResults(this.props.history)
                                        : this.props.toggleVisitModal(constants.popup_labels.outOfBounds)} />
                                : null}
                        </TabPane>
                        <TabPane tabId='custom_fields'>
                            <CustomPanelFieldsComponent {...this.props} />
                        </TabPane>
                        <TabPane tabId='sample-image' >
                            {/* {imagePanel(this.props.match.params.panelName) ? */}
                            {this.props.currentOrder && this.props.currentOrder.sample ?
                                <div className='card card-default'>
                                    <div className='card-header'>{labels.visit_page_labels.nav_link_sample_image}</div>
                                    <div className='card-body'>
                                        {/* <img src={imagePanel(this.props.match.params.panelName)} width='100%' alt={this.props.match.params.panelName} /> */}
                                        <img src={`data:${this.props.currentOrder.contentType};base64,${this.props.currentOrder.sample}`
                                        } width='100%'></img>
                                    </div>
                                </div>
                                : null}
                        </TabPane>
                    </TabContent>
                </div>
            </div >
        )
    }
}
export default IndividualOrder;
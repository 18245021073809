import React, { Component } from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';
import { parse } from 'qs';
const urls = ['biomarkermanual', 'usermanual']
class ManualForm extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        if (!(Object.keys(this.urlParams).length && this.urlParams.type) || urls.indexOf(this.urlParams.type) == -1) {
            this.props.history.push('statuscode/404')
        }
        if (this.urlParams.type == "biomarkermanual") {
            this.props.setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.help_page_labels.upload_doc_title, path: '' }
            ])
            this.props.noHeaderLoading(true)
            this.props.setPageTitle(labels.help_page_labels.upload_doc_title)
            document.title = `${labels.physioage_label} : ${labels.help_page_labels.upload_doc_title}`
        } else {
            this.props.setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.help_page_labels.upload_user_manual_title, path: '' }
            ])
            this.props.noHeaderLoading(true)
            this.props.setPageTitle(labels.help_page_labels.upload_user_manual_title)
            document.title = `${labels.physioage_label} : ${labels.help_page_labels.upload_user_manual_title}`
        }
        if (this.helpForm && this.helpForm.inputEntry)
            this.helpForm.inputEntry.focus()
    }
    componentWillUnmount() {
        this.props.noHeaderLoading(false)
    }
    render() {
        return (
            <form className='container manual-form col-lg-6' onSubmit={(e) => { e.preventDefault(); this.props.uploadDocument(this.props.history, this.urlParams.type) }} >
                <FormControl type='text' id='version' ref={(h) => this.helpForm = h} label={labels.help_page_labels.version} onChange={this.props.helpInput} required={true} />
                <FormControl type='file' label={labels.help_page_labels.attachment} onChange={this.props.postFile} />
                <div className='d-flex justify-center'>
                    <FormControl type='button' text={labels.help_page_labels.create_btn_text} btnType={constants.notification_type.success} />
                </div>
            </form>
        )
    }
}
export default ManualForm;
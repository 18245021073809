import { connect } from 'react-redux'
import { actionCreators } from '../modules/datamenu'
import { handleLoading, toggleLoading } from '../../../reducers/global-reducer';
import NoumenaDistribution from '../components/NoumenaDistribution';

const mapStateToProps = (state) => ({
    ...state.datamenu,
    isLoading: state.global.isLoading,
})

const mapDispatchToProps = {
    ...actionCreators,
    handleLoading, 
    toggleLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(NoumenaDistribution)
import {connect} from 'react-redux';
import MyRecommendationsComponent from '../components/MyRecommendationsComponent';
import {refuteRecommendationThunk, editRecommendationThunk} from '../thunks/recommendations';
// import {activeRecommendationsSelector} from '../selectors/recommendationsSelector';

// let refute = (dispatch) => {
//   return(atts) => {
//     dispatch(refuteRecommendationThunk(atts));
//   }
// }

// let edit = (dispatch) => {
//   return(atts) => {
//     dispatch(editRecommendationThunk(atts));
//   }
// }

// let newRecommendation = (dispatch) => {
//   return () => {
//     dispatch({ type: 'CLEAR_CURRENT_RECOMMENDATION' })
//   }
// }

// let mapStateToProps = (state) => {
//   return {
//     //  recommendations: activeRecommendationsSelector(state),
//     //  datum: state.drawer_content.datum,
//     //  provider: state.entities.provider,
//     //  editable: state.presentation.perspective === 'provider'
//   }
// }

// let mapDispatchToProps = (dispatch) => {
//   return {
//     refute: refute(dispatch),
//     edit: edit(dispatch),
//     newRecommendation: newRecommendation(dispatch)
//   }
// }
import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
  ...state.analytics,
})

const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(MyRecommendationsComponent);

import React, { Component } from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';
import moment from 'moment';


class UpdateSiteDocument extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.siteId) {
            this.props.setCurrentSiteDetails(this.props.match.params.siteId, 'update-document', this.props.history, this.props.match.params.documentId)
        }
    }

    render() {
        setTimeout(() => {
            var current = document.getElementsByClassName('app');
            current[0].className = current[0].className.replace('wrapper', '');
        }, 1000);
        return (
            this.props.currentDoc ?
            <>
                <div className='side-panel col-lg-3 col-md-3 col-sm-12 col-xs-12 site-documents'>
                    <div className='card instructions mb-4 mt-0'>
                        <div className='card-header info'>
                            <b>{labels.sites_labels.help}</b>
                        </div>
                        <div className='card-body'>
                            <p>{constants.update_doc_preview.help_text}</p>
                        </div>
                    </div>
                </div>
                {Object.keys(this.props.currentSite).length ?
                    <div className='update-site-form'>
                        <form className='container manual-form col-lg-9' onSubmit={(e) => { e.preventDefault(); this.props.uploadDocument(this.props.history) }} >
                            <FormControl type='text' id='title' label={labels.sites_labels.title} value={this.props.currentDoc ? this.props.currentDoc.title : ''} onChange={this.props.siteDocumentInput} required={true} help = {this.props.titleError ? this.props.titleError : ''}/>
                            <FormControl type='date'
                                id='publicationDate'
                                selected={ this.props.currentDoc.publicationDate ? new Date(this.props.currentDoc.publicationDate) : null}
                                onChange={this.props.handlePublicationDate}    
                                label={labels.sites_labels.publication_date}
                                popperPlacement="top-start"
                                disabledKeyboardNavigation 
                            />
                            <div className='d-flex'>
                                <label for={this.props.id} className={`form-check-label`}>
                                    {labels.sites_labels.visible}
                                </label>
                                <div className='check-content'>
                                    <input
                                        className='form-check-input ml-2'
                                        type='checkbox'
                                        onChange={this.props.siteDocumentInput} id='visible'
                                        value={this.props.currentDoc ? parseInt(this.props.currentDoc.visible) : ''}
                                        checked={this.props.currentDoc && parseInt(this.props.currentDoc.visible) ? true : false}
                                    /><br></br>
                                    <div className='help-block ml-2'>{constants.update_doc_preview.visible_help}</div>
                                </div>
                            </div>
                            {/* <FormControl onChange={this.props.siteDocumentInput} id='visible'
                                value={this.props.currentDoc ? parseInt(this.props.currentDoc.visible) : ''}
                                label={labels.sites_labels.visible} checked={this.props.currentDoc && parseInt(this.props.currentDoc.visible) ? true : false}
                                type='checkbox'
                                help={constants.update_doc_preview.visible_help}
                            /> */}
                            <div className='d-flex justify-center'>
                                <FormControl type='button' text={labels.sites_labels.update_document} btnType={constants.notification_type.success} />
                            </div>
                        </form>
                    </div> : ''}
            </>
            : ''
        )
    }
}
export default UpdateSiteDocument;
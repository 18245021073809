import React from 'react';
// https://physioagestorageqa.blob.core.windows.net/videos/Onboarding_video.mp4' 
import onboardingVideo from '../../assets/videos/onboarding_video.mp4' ;
  
const OnboardingVideo = () => {
    return (
        <div className='container onboarding-video'>
            <video controls >
                <source src={onboardingVideo} type="video/mp4" />
            </video>
        </div>
    );
}

export default OnboardingVideo;

import React, { Component } from 'react';
import moment from 'moment';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';
import { returnLocalizedDate } from '../../../utils/resolve-timer';
import LoadingTemplate from '../../../components/LoadingTemplate';

class UserCurrentManual extends Component {
    componentDidMount() {
        this.props.getCurrentManual('User')
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.help_page_labels.current_user_title, path: '' }
        ])
        this.props.setPageTitle(labels.help_page_labels.current_user_title)
        this.props.noHeaderLoading(true)
        document.title = `${labels.physioage_label} : ${labels.help_page_labels.current_user_title}`
    }
    render() {
        return (
            <>
                {/* {this.props.isLoading ? <LoadingTemplate haveSidebar={true} isGrid={true} noSidedata={true}/> :  */}
                {this.props.currentmanual ?
                    <>
                        <div className='col-lg-3 col-md-10 col-sm-12 col-xs-12'></div>
                        <div className='current-manual card col-lg-9 col-md-10 col-sm-12 col-xs-12'>
                            <h3>{this.props.currentmanual.version}</h3>
                            <p> {labels.help_page_labels.uploaded} {moment(returnLocalizedDate(this.props.currentmanual.createdAt)).format('YYYY-MM-DD h:mm:ss A')}</p>
                            <div className='d-flex btn-section'>

                                <FormControl type='button' btnType='info'
                                    text={labels.help_page_labels.download_label}
                                    onClick={() => this.props.downloadHelpFile(this.props.currentmanual.id)} />
                                {this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.AN ?
                                    <FormControl type='button'
                                        btnType='warning'
                                        text={labels.help_page_labels.delete_label}
                                        onClick={() => this.props.launchDeleteModal(this.props.currentmanual.id, 'User')} /> : null}
                            </div>
                        </div>
                    </> : null
                }
            </>
        )
    }
}
export default UserCurrentManual;
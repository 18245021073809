import React, { Component } from 'react';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import labels from '../../../config/localization';
import './corrections-styles.scss';
import PageTitle from '../../../components/PageTitle';
const columns = [
    { dataField: 'name', text: labels.corrections_labels.name, headerStyle: { width: '40%' } },
    {
        dataField: 'date', text: labels.corrections_labels.date, headerStyle: { width: '15%' },
        formatter: (cellContent, row) => (<span>{moment(row.date).format('L')}</span>)
    },
    { dataField: 'description', text: labels.corrections_labels.description, headerStyle: { width: '40%' } },
]
class Corrections extends Component {
    componentDidMount() {
        this.props.getCorrections()
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.corrections_labels.title, path: '' }
        ])
        document.title = `${labels.physioage_label}: ${labels.corrections_labels.title}`
        this.props.setPageTitle(labels.corrections_labels.title)
    }
    componentWillUnmount() {
        this.props.toggleNotification(false)
    }

    render() {
        return (
            <div className='corrections-section container'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                        <PageTitle breadCrumbs={this.props.breadCrumbs}
                            pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                            navigatePatients={this.props.navigatePatients}
                            history={this.props.history}
                            {...this.props} />
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 corrections-children'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    {this.props.corrections && this.props.corrections.length ?
                        <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 corrections-table'>
                            <div className='bootstrap-table-container'>
                                <BootstrapTable
                                    remote
                                    striped={false}
                                    bordered={false}
                                    hover={true}
                                    bootstrap4
                                    keyField='id'
                                    data={this.props.corrections}
                                    columns={columns}
                                    pagination={false}
                                />
                            </div>
                        </div>
                        : this.props.no_grid_data ?
                            <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12'>
                                <div className='jumbotron'>
                                    <h3>{labels.no_grid_data}</h3>
                                </div>
                            </div> : null}
                </div>

            </div>
        )
    }
}
export default Corrections
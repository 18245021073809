import { connect } from 'react-redux';
import TopNav from '../components/TopNavLoading';
import { actionCreators } from '../modules/analyticsBeta';

let mapStateToProps = (state) => ({
  ...state.analytics
})

let mapDispatchToProps = {
  ...actionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);

export default {
    physioage_label: "PhysioAge",
    physioage_report_label: "Отчеты PhysioAge",
    site_label: 'сайт',
    login_help: "Если у вас возникли проблемы со сбросом пароля, позвоните в нашу службу поддержки",
    login_help_text: "Войдите, используя свой адрес электронной почты.",
    login_btn_text: 'войти в систему',
    forgot_password_text: "Забыли пароль",
    forgot_password_label: 'Запрос сброса пароля',
    email_label: "Электронная почта",
    forgot_password_instruction: "Инструкции по сбросу вашего пароля будут доставлены на указанный выше адрес, если у вас есть аккаунт.",
    navigate_login: "Вернуться к экрану входа",
    reset_my_password_label: "Сбросить мой пароль",
    inside_us: 'Внутри Соединенных Штатов',
    outside_us: 'За пределами Соединенных Штатов',
    done_label: 'Готово',
    return_login_btn: 'Вернуться на страницу входа',
    reset_password_label: 'Сбросить пароль',
    password: 'пароль',
    confirm_password: 'Подтвердите Пароль',
    update_password: 'Update Password',
    update_password_btn: 'Обновить мой пароль',
    try_again: 'Please try again:!',
    link_expired: 'Link Expired!',
    link_expired_notification: 'The link you used to reset your password expired. Typically this happens when you wait several days to use the link, or when you (or someone else) went through the process of ressing your password again.!',
    set_password: 'Set Your Password!',
    set_password_notification: 'Your password must be changed before you can access your data. We know passwords are a hassle. But we think the next step will be worth your time.!',
    login_caution_start: 'Когда вы входите, вы соглашаетесь с нашими',
    login_caution_conj: 'и',
    login_cookies: 'включая, но не ограничиваясь разрешением использования файлов cookie.',
    invalid_email: `Invalid Email Address. Please provide a valid email address!`,
    unauth_login: `You must be an administrator to access this page.!`,
    something_went_wrong: 'Something went wrong!',
    not_available: 'NA!',
    none: 'None!',
    login_label: 'Login!',
    pagenotfound: {
        title: "Страница не найдена",
        help: 'You are looking for something which is not there!',
        redirect_label: "Перенаправление на дом",
        login_label: 'Войдите снова',
        session_expired: 'Сессия истекла',
        back_label: 'Navigate to home!'
    },
    footer_labels: {
        privacy: 'политика конфиденциальности',
        tos: 'условия обслуживания'
    },
    header_labels: {
        credits: "Кредиты",
        account: "Аккаунт",
        user_settings: "Настройки пользователя",
        logout: "Выход",
        feedback: "Обратная связь",
        patients: "пациентов",
        help: "Помогите",
        current_manual: "Текущее руководство",
        all_manuals: "Все руководства",
        videos: "Видео",
        ops: "OPS",
        active_site_report: "Отчет об активном сайте",
        providers: "провайдеры",
        sites: "Сайты",
        elk: 'лось',
        users: "Пользователи",
        billing_summary: "Сводка платежных данных",
        message_routing: 'Message Routing',
        inbox: "Входящие",
        oddities: "Странности",
        corrections: "Исправления",
        data: "Данные",
        explore_noumena: "Исследуй Нумену",
        volume_report: "Отчет по объему",
        vadr: 'VADR!',
        remaining_label: 'remaining!',
        practice: 'Practice!',
        explore_data: 'Исследуй Данные',
        download_library: 'Download Library!',
    },
    patient_list_labels: {
        title: "Список пациентов",
        patient_title: 'Patient!',
        filter_patients_label: "Фильтровать пациентов",
        site_label: "Сайт",
        name_label: "Имя",
        min_visits_label: "Минимальные посещения",
        search: "Поиск",
        title_label: "Список пациентов",
        add_patient: "Добавить пациента",
        patient_title_new: 'PhysioAge: New Patient!',
        new_patient_title: "Форма нового пациента",
        edit_patient_form: "Редактировать форму пациента",
        honorific_prefix: "Почетный префикс",
        honorific_suffix: "Достопочтенный суффикс",
        honorific_suffix_help: 'CPA, DDS, MDiv.',
        first_name: "Имя",
        last_name: "Фамилия",
        email: "Электронная почта",
        dob: "Дата рождения",
        dob_help: "После того, как вы выбрали год и месяц, вы ДОЛЖНЫ щелкнуть на день",
        site: "Сайт",
        provider: "Провайдер",
        language_pref: "Языковые предпочтения",
        save: "Сохранить",
        male: "мужчина",
        female: 'женщина',
        add_visit: "Добавить визит",
        edit_Patient: "Редактировать пациента",
        patient_details: "Сведения о пациенте",
        patient_portal: "Портал для пациентов",
        view_patient_portal: "Просмотр портала пациента",
        enable_patient_portal: "Включить портал пациента",
        resend_email: "Переслать приветственное письмо",
        portal_caution: "Они не использовали его.",
        portal_last_viewed: ' Last viewed!',
        portal_help: 'Этот пациент имеет доступ к порталу пациента.',
        portal_mail_help: "Прежде чем мы позволим этому пациенту получить доступ к порталу пациента, нам нужен его адрес электронной почты. Пожалуйста, введите его ниже.",
        enable_portal_caution: "Этот пациент не имеет доступа к порталу пациента. Нажмите кнопку ниже, чтобы включить доступ.",
        update_patient: "Обновление пациента",
        edit_label: "Редактировать",
        all_sites: "Все сайты",
        gender_label: "Пол",
        no_visits: "Нет посещений",
        clear_search: "Очистить поиск",
        create_visit: "Создать визит",
        visit_date: "Дата посещения",
        visits: 'Visits!',
        add_visit_help: "Выберите дату, чтобы добавить визит",
        view_health_analytics: "Просмотр аналитики здоровья",
        credits_elk: 'У вас нет кредитов, чтобы добавить визит',
        future_date: 'слишком далеко в будущем',
        agg_age_chart: "Диаграмма совокупного старения",
        resend_welcome_email: `We will send another welcome email to this user within the next 10 minutes.!`,
        no_visit_caution: `This patient does not have access to the patient portal.
                            Once you have set up a visit for them, you'll be able to provide access to them.!`,
        no_visits_patients: `Each patient visit must be set up before data will be available. Currently this system doesn't know about any visits for this patient.!`,
        data_entry: 'Data Entry!',
        never: 'Never!',
        new_visits: 'New visits for!',
        providers_validate: 'Select Sites to get providers!',
        sex: 'Sex!',
        last_active: 'Last Active!',
        identifier: 'Identifier!',
        identifier_help: 'Optional field to store your local patient identifiers!',
        delete_patient: 'Delete Patient!',
        delete_patient_prompt: 'Do you want to delete this patient permanently!',
        delete_patient_desc: "This will delete all the visits associated with the patient and its data!",
    },
    feedback_labels: {
        help_text: "Мы приветствуем и ценим любые ваши отзывы. Нам интересно услышать ваши мысли о том, как улучшить наше приложение",
        save_text: "Отправить отзыв",
        title: "Обратная связь",
        feedback_success: "Ваш отзыв доставлен. Спасибо за ваш вклад."
    },
    user_labels: {
        edit: "Редактировать",
        title: 'Список пользователей',
        new_user: "Новый пользователь",
        edit_user: "Редактировать пользователя",
        account_settings: "Настройки аккаунта",
        email_addresses_help: 'Адреса электронной почты для всех текущих пользователей:',
        firstname: "Имя",
        lastname: "Фамилия",
        username: "Имя пользователя",
        email: "Электронная почта",
        active: "активный",
        activeWithQ: `активный?`,
        site: "Сайт",
        role: "Роль",
        update_user: "Обновить пользователя",
        reset_password: "Сброс пароля",
        type_password: "Введите пароль",
        confirm_password: "Подтвердите пароль",
        update_password: "Обновить пароль",
        retype_password: "Повторите ввод пароля",
        password: "Пароль",
        add_user: "Добавить пользователя",
        password_not_matched: "Пароли не совпадают",
        job_type: 'Тип задания',
        provider_label: 'поставщик',
        gender_label: 'Пол',
        filter_users: 'Filter Users!',
        master: 'master!',
    },
    credits_labels: {
        title: 'Отчетные кредиты',
        add_credits: 'Добавить кредиты',
        download: 'Скачать',
        receipt: 'квитанция',
        history: 'История',
        receipt_header: 'Receipt!',
        date: 'Date :!',
        client: 'Client :!',
        description: 'Description!',
        quantity: 'Quantity!',
        amount: 'Amount!',
        ok_btn: 'OK!',
        credit_transactions_name: 'credit_transactions!.csv'
    },
    sites_labels: {
        new_site_label: 'Новый сайт',
        sites_list_label: 'Сайты',
        total_sites: 'Всего сайтов',
        total_clinic: 'Тотальные клиники',
        new_clinic: 'Новые клиники это Q',
        active_clinic: 'Активные клиники это Q',
        add_site: 'Добавить сайт',
        list_sites_filter: 'Список сайтов, принимающих рефералов',
        all_sites: 'Список всех сайтов',
        list_sites_filter_title: 'Sites Accepting Referrals!',
        name_label: 'Имя',
        category_label: 'Категория',
        billing_label: 'Биллинг',
        credits_label: 'Кредиты',
        providers_label: 'Провайдеры',
        patients_label: 'Пациенты',
        language_label: 'I18N',
        units_label: 'Единицы',
        name_help: 'Это имя, которое будет отображаться на портале для пациентов и будет именем отправителя, когда мы будем отправлять ссылки на пациентов по электронной почте. Он также будет использоваться в сухарях и других местах по всему сайту. ',
        site_referrals_help: 'Сайт принимает рефералов',
        preferences: 'Localization Preferences!',
        language_preference: 'Language Preference',
        date_preference: 'Предпочтение даты',
        unit_preference: 'Предпочтение единицы',
        unit_preference_help: 'Изменение этого для существующего сайта может занять некоторое время. Предлагаю вам проконсультироваться с разработчиком. ',
        margins_help: 'Добавить поля для размещения двухсторонних отверстий',
        logo_label: 'Логотип',
        banner_logo_label: 'Логотип баннера',
        settings: 'Other Settings!',
        sites_message_acknowledge: 'Сайт принимает сообщения',
        analytics_portal_acknowledge: 'Аналитика как портал',
        billing_strategy: 'Стратегия выставления счетов',
        brand: 'Бренд',
        default_panels: 'Панели по умолчанию',
        referral_info: 'Реферальная и публичная информация',
        address_help: 'Этот адрес будет предоставлен потенциальным пациентам через поиск поставщиков услуг и может быть нанесен на карту.',
        address_label: 'Адрес',
        city_label: 'Город',
        state_label: 'State',
        postal_code: 'Почтовый индекс',
        country: 'Страна',
        phone: 'Телефон',
        phone_help: 'Этот номер будет предоставлен пациентам и использован PhysioAge на случай, если нам потребуется связаться с ними по поводу сбоев на площадке.',
        website_label: 'Веб-сайт',
        other_info: 'Другая информация',
        principle_ehr: 'Принцип ЭМК',
        save_btn: 'Сохранить',
        edit_site: 'Редактировать',
        add_provider: 'Добавить провайдера',
        add_user: 'Добавить пользователя',
        add_elk: 'Добавить ELK',
        contact_info: 'Контактная информация',
        portal_label: 'Portal',
        accept_referral_label: 'Принять рефералов',
        users_label: 'Пользователи',
        recent_activity: 'Недавние действия',
        lastseen: 'Последний раз видели',
        month_label: 'Месяц',
        year_label: 'Год',
        visits_label: 'Посещения',
        analytics_label: 'Аналитика',
        old_portal_label: 'Старый портал',
        transaction_history: 'История транзакций',
        current_image_label: 'Current Image: !',
        referral: 'referral!',
        all: 'all!',
        not_uploaded: 'Not Uploaded!',
        download_attendee: 'Download Attendee List!',
        features_label: 'Features!',
        include_phone_in_summary: 'Include Phone Number in Visit Summary!'
    },
    provider_labels: {
        provider_list: 'Список провайдера',
        add_provider: 'Добавить провайдера',
        update_provider: 'Поставщик обновлений',
        edit_provider: 'Редактировать',
        list_title_label: 'Провайдеры',
        provider_name: 'Провайдер',
        sites_label: 'Сайты',
        patients_label: 'Пациенты',
        last_visit: 'Последний визит',
        honorific_prefix: 'Почетный префикс',
        firstname: 'Имя',
        lastname: 'Фамилия',
        honorific_suffix: 'Достопочтенный суффикс',
        honorific_suffix_help: 'CPA, DDS, MDiv',
        create_provider: 'Создать провайдера',
        profile_pic: 'Нажмите на изображение, чтобы изменить изображение',
        image_label: 'Образ',
        avatar: '-avatar',
        remove_pic: 'Remove picture!'
    },
    billing_labels: {
        title: 'Сводка платежных данных',
        site_label: 'Сайт',
        startdate_label: 'Дата начала',
        enddate_label: 'Дата окончания',
        generate: 'генерировать',
        num_reports_labels: 'Количество отчетов',
        charge_report: 'Стоимость за отчет',
        total_charge: 'Всего сборов',
        filter_label: 'Фильтр',
        physician: 'врач',
        patient: 'Пациент',
        timepoint: 'Момент времени',
        created: 'созданный',
        visit_date: 'Дата посещения'
    },
    message_routing_labels: {
        list_title: 'Правила маршрутизации сообщений',
        add_rule: 'Добавить правило',
        message_routes: 'Маршруты сообщений',
        new_message_routing: 'Правило маршрутизации нового сообщения',
        edit_message_routing: 'Изменить правило маршрутизации сообщений',
        sending_application: 'Отправка приложения',
        sending_facility: 'Средство отправки',
        receiving_application: 'Получение приложения',
        receiving_facility: 'Приемное средство',
        site: 'Сайт',
        panel: 'Панель',
        add_message_route: 'Добавить маршрут сообщения',
        update_message_route: 'Обновить маршрут сообщения',
        edit_label: 'Редактировать',
        apply_label: 'Применить',
        pid18_1:'Pid18 1',
        copy: 'Copy'
    },
    help_page_labels: {
        current_manual_title: 'Текущее руководство по биомаркерам',
        download_label: 'Скачать',
        delete_label: 'Удалить',
        all_manual_title: 'История руководства по биомаркерам',
        videos_title: 'Обучающие видео',
        cutometer: 'Кутометрия',
        cutometry: 'MPA 580',
        spirometer: 'Анализ пульсовой волны',
        spirometry1: 'SphygmoCor XCEL',
        spirometry2: 'SphygmoCor CP',
        sphygmoCor: 'Спирометрия',
        sphygmocor1: 'EasyOneAir',
        sphygmocor2: 'MidMark IQSpiro',
        upload_doc: 'Загрузить новый документ',
        upload_doc_title: 'Загрузить новое руководство по биомаркерам',
        version: 'Версия',
        attachment: 'приложение',
        create_btn_text: 'Создать файл справки',
        delete_warning: 'Вы уверены, что хотите удалить руководство?',
        ok_btn: 'Хорошо',
        cancel_btn: 'Отмена',
        helpFile_validation: 'Please upload pdf file!',
        version_validation: 'Please enter version!',
        invalid_file: 'Invalid file!',
        uploaded: 'Uploaded!',
    },
    elk_labels: {
        title: 'Кредитные операции',
        filter: 'Фильтр',
        site: 'Сайт',
        add_title: 'Добавить кредиты для',
        no_of_sites: 'Количество кредитов',
        comment: 'Комментарий',
        create_credit_btn: 'Создать кредитную транзакцию',
        user: 'User!',
        change: 'Change!'
    },
    tos_labels: {
        title: 'УСЛОВИЯ ЭКСПЛУАТАЦИИ',
        last_updated: 'Последнее обновление: 31 января 2018 г.'
    },
    privacy_labels: {
        title: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
        last_updated: 'Последнее обновление: 31 января 2018 г.'
    },
    corrections_labels: {
        title: 'исправления',
        name: 'название',
        date: 'Дата',
        description: 'Описание'
    },
    oddities_labels: {
        title: 'Уникальные странности',
        oddities: 'Странности',
        errors: 'Все ошибки',
        message_label: 'Сообщение',
        count_label: 'Count',
        resolve: 'Разрешить',
        ok_btn: 'Хорошо',
        cancel_btn: 'Отмена',
        close_btn: 'Close',
        observation: 'Наблюдение',
        noumenon: 'нуменон',
        id: 'сделал',
        resultId: 'ID результата',
        valueType: 'Тип значения',
        identifier: 'Идентификатор',
        subIdentifier: 'Sub-Identifier',
        label: 'ярлык',
        value: 'Значение',
        units: 'Единицы',
        referenceRange: 'Диапазон ссылок',
        abnormalFlags: 'Аномальные флаги',
        probability: 'вероятность',
        natureOfAbnormalTest: 'Природа ненормального теста',
        resultStatus: 'Статус результата',
        observedAt: 'наблюдал за',
        producer: 'продюсер',
        observationMethod: 'Метод наблюдения',
        equipmentInstance: 'Экземпляр оборудования',
        analyzedAt: 'Analysed at',
        createdAt: 'Создан в',
        updatedAt: 'Обновлено в',
        observerType: 'Тип наблюдателя',
        observerId: 'Идентификатор наблюдателя',
        datumId: 'Базовый ID',
        result_label: 'Результат',
        source_label: 'Источник',
        history: 'История',
        observation_status: 'Статус',
        observation_id: 'Идентификатор наблюдения',
        result_date: 'Дата результата',
        no_observations: 'No Observations found!',
        no_history: 'No History found!',
        reference: 'Reference!',
        date_time: 'Date / Time!',
        resolve_error: 'Are you sure to resolve this error?!',
        resolved: 'Resolved!',
        category: 'Category!',
        examples: 'examples!',
        messagegroup: 'Oddity Messages!',
        sample_observations: 'Sample Observations!',
        observations_info_1: 'observations!',
        observations_info_2: ' match identifier!',
        visit: 'Visit!',
        obx: 'OBX',
        identifier:'Identifier'
    },
    inbox_labels: {
        title: 'входящие',
        filter_title: 'Фильтр по отправителю',
        filter_subtitle: 'Фильтровать сообщение',
        raw_btn: 'необработанный',
        resolve_btn: 'Разрешить',
        resolve_title: 'Разрешить сообщение',
        demo_modal: 'Запустить демо-модал',
        msg_details: 'Детали сообщения',
        from_label: 'От',
        date_label: 'Дата',
        name_label: 'Имя пациента',
        dob_label: 'Пациент DOB',
        close_label: 'Закрыть',
        launch_demo_header: 'Необработанное содержимое сообщения',
        site_label: 'Сайт',
        patient: 'Patient!',
        dob: 'DOB!',
        dobLabel: 'dob!',
        visit: 'Visit!',
        received: 'Received!',
        processed: 'Processed!',
        back: 'Back!',
        aboutyear_back: 'about 1 year back!',
        about_year_back: 'years back!',
        days_back: 'days back!',
        reference: 'Reference!',
        createdAt: 'Created At!',
        processedAt: 'Processed At!',
        details: 'Details!',
        message_details: 'Message Details!',
        oddities: 'Oddities!',
        routedTo: 'Routed To!',
        imported: 'Imported?!',
        notrouted: 'Not Routed!',
        no_label: 'no!',
        un_routed_messages: 'Unrouted Messages',
        failure_messages: 'Failure Messages'
    },
    asr_labels: {
        title: 'Активные отчеты сайта',
        active_site_month: 'Активные сайты по месяцам',
        visit_volume: 'Объем посещений',
        active_sites: 'Активные сайты',
        excluding: 'исключая'
    },
    analytics_labels: {
        exit_Analytics: 'Выход из аналитики',
        menu: 'Меню',
        most_recent_visit: 'самый последний визит',
        years_old: 'years old',
        biographical_information_label: 'Biographical Information',
        biographical_info_label: 'Биографическая информация',
        biomarkers_of_aging_label: 'Биомаркеры старения',
        health_card_report_label: 'Отчет о состоянии здоровья',
        recommendations_label: 'Рекомендации',
        download_report_label: 'Скачать отчет',
        patient_report_label: 'Отчет пациента',
        download_recommendations: 'Скачать рекомендации',
        download_pdf_label: 'Просмотреть / скачать PDF',
        no_recommandations: 'Вы не ввели никаких рекомендаций.',
        no_recommandations_content: 'Чтобы ввести рекомендацию, вы должны быть в, который появляется справа, когда вы нажимаете на любой из параметров. Идея состоит в том, что, когда вы просматриваете лаборатории или биомаркеры во время ваших консультаций, вы можете нажать на параметр, например Без тестостерона, и затем с правой стороны экрана появится ящик. Вы можете посмотреть на спектр интерпретации, изменения по сравнению с исходным уровнем, график всех точек, таблицу данных, и при прокрутке внизу появится форма для ввода ваших рекомендаций. ',
        comparing_your_ages: 'Сравнение вашего возраста',
        logOut: 'Log Out!',
        no_visits_label: 'Patient has no visits on file.!',
        measured_recently: `This biomarker hasn't been measured recently.!`,
        older_actual_age: `years older than your actual age.!`,
        younger_actual_age: 'years younger than your actual age.!',
        average_for_age: 'About average for your age.!',
        older_actual_age_visits: `years older than average for your age.`,
        younger_actual_age_visits: `years younger than average for your age.`,
        older_younger: `neither older nor younger than average for your age.`,
        gpa_scale: 'Your GPA (4-point scale):!',
        report_card_label: 'Report Card!',
        treatment_plan: 'TREATMENT PLAN!',
        biomarkers_label: 'Biomarkers!',
        average_peers: 'Average for Your Peers!',
        everyone_label: 'Everyone!',
        change_from: 'Change From!',
        since_label: 'since!',
        baseline_label: 'Baseline!',
        previous_visit_label: 'Previous Visit!',
        results_label: 'Results!',
        recommendation_label: 'Recommendation!',
        more_info: 'More Info!',
        as_of_label: 'as of!',
        years_label: 'years!',
        your_age_label: 'Your Age!',
        your_label: 'Your!',
        date_label: 'Date!',
        grade_label: 'Grade!',
        value_label: 'Value!',
        units_label: 'Units!',
        reference_range: 'Reference Range!',
        no_recommandations_made: 'No recommendations have been made!',
        select_items_label: 'Select items from the search results, or the common recommendations on the left to add them to the list.!',
        submit_comments: 'Submit Comments!',
        recent_recommendations: 'Recent Recommendations!',
        changes_wont_stick: `Changes won't stick!`,
        demo_mode: `In demo mode, your changes will appear to have been saved. But if you come back to this page in the future, you won't see them. This allows all demo users to start with the same basic information each time.!`,
        loading_data: `Loading Data:!`,
        loading_categories: 'Loading Categories:!',
        loading_patient: 'Loading Patient:!',
        loading_recommendations: 'Loading Recommendations:!',
        normal_range: 'Normal Range!',
        optimal_range: 'Optimal Range!',
        no_description: 'No Description Available!',
        past_results: 'Your Past Results!',
        your_result: 'Your Result!',
        powered_by_physioage: 'Powered By PhysioAge!'
    },
    noumena_labels: {
        title: 'Исследуй Нумену',
        noumena: "нумена",
        filter_clouds: 'Фильтровать облака',
        noumenon: 'имя ноумен',
        site: 'Сайт',
        gender: 'Пол',
        max_deviations: 'Максимальные отклонения',
        update_btn: 'Обновление',
        heads_up: 'Heads Up!',
        filter_help: 'Облака будут генерироваться медленнее, если вы выберете элементы выше. Предварительно нарисованы только облака по умолчанию. ',
        volume_report: 'Отчет по объему',
        download_csv: 'Загрузить CSV',
        volume_report_name: 'volume_report.csv',
        aggregate_age_chart_title: 'Образцы агрегированных возрастных диаграмм',
        sample_report_title: 'Образцы оценок табеля успеваемости',
        sample_spectra_title: 'Образцы спектров',
        concerns_title: 'Обеспокоенность:',
        concerns: [
            'Как это выглядит при печати?',
            'Сколько чернил он использует?',
            'Как это выглядит для дальтоника? (их может быть не много, но мы знаем, что они существуют, с таким же успехом можно справиться и с ними.) '
        ],
        color_palette_help: 'Измените цветовые палитры, если хотите:',
        vadr_label: 'Very Abnormal Data Review!',
        no_results: 'No results!',
        next_page: 'Next Page!',
        no_results_code: 'No results for the noumena code!',
        value_format: 'Value Format!',
        system: 'System!',
        units: 'Units!',
        scale: 'Scale!',
        sorted_details: 'Sordid, Sorted Details!',
        code: 'Code!',
        appears_in_report: 'Appears in Report!',
        yes: 'Yes!',
        no: 'No!',
        equation: 'Equation!',
        gender: 'Gender!',
        anyone: 'Anyone!',
        male: 'Male!',
        female: 'Female!',
        no_values_to_plot: 'No values to plot the Graph!',
        age: 'Age!',
        visitDate: 'Visit Date!',
        visitId: 'Visit Id!',
        ordinal: 'Ordinal!',
        siteId: 'Site Id!',
        units: 'Units!',
        noumena_download: 'data!.csv',
        no_name: 'No name!',
    },
    visit_page_labels: {
        title: 'Baseline for!',
        breadcrum_for_visit: 'Базовая линия',
        surveys_label: 'Опросы',
        patient_conditions_link: 'Обследование состояния пациента',
        tests_label: 'Тесты',
        btn_tests: 'Добавить тест',
        btn_edit_patient: 'Редактировать пациента',
        btn_edit_visit: 'Редактировать визит',
        update_visit: 'Обновление визита',
        btn_delete_visit: 'Удалить визит',
        btn_visit_summary: 'Сводка посещения',
        btn_portal_view: 'Вид портала для этого посещения',
        btn_toggle_beacon: 'Toggle Beacon',
        report_label: 'Отчет пациента',
        download_link: 'Скачать',
        visit_success: 'Визит успешно создан',
        visit_update_success: 'Визит успешно обновлен',
        preview_link: 'предварительный просмотр',
        download_historic_data: 'Historical Data!',
        recycle_report_link: 'Рециркулировать',
        recycle_report_text: '(создать новый)',
        physician_report_label: 'Отчет врача',
        register_date_header: 'Регистрация даты посещения',
        active_ell_caution: 'У этого пациента активный ELK',
        update_date_header: 'Обновление даты посещения',
        error_handling: 'Произошла ошибка, введите другую дату',
        waiting_for_data: 'Ожидание данных',
        notes: 'Пожалуйста, заполните результаты испытаний, перечисленных слева. Как только это будет сделано, здесь появятся обзорные данные. ',
        test_add_order: 'Добавить заказ',
        test_panel_name: 'Имя теста',
        popup_title_addTest: 'Добавить новый тест',
        lbl_addTest: 'Добавить тест',
        patient_survey_title: 'Опрос пациентов',
        nav_link_history: 'История',
        nav_link_import: 'Импорт',
        nav_link_manual_entry: 'Ручной ввод',
        nav_link_sample_image: 'Sample Image',
        customer_service_label: 'Если единицы измерения или эталонные диапазоны на этой странице не соответствуют тем, которые вы получаете от лаборатории, введите их вручную, щелкнув соответствующее поле. Контакт ',
        customer_service_name: 'обслуживание клиентов',
        customer_service_label_2: ' с вопросами или проблемами.',
        patient_survey_table_label: 'Метка',
        patient_survey_table_value: 'Значение',
        patient_survey_table_units: 'Единицы',
        patient_survey_table_ref_range: 'Диапазон ссылок',
        patient_survey_table_status: 'Статус',
        btn_save_label: 'Сохранить результаты',
        label_patient_survey: 'Опрос пациентов',
        delete_warning: 'Вы уверены, что хотите удалить визит?',
        update_visit_help: 'Выберите дату для обновления посещения',
        elk_credits_one: 'Когда вы создаете этот визит, вы будете использовать один из ваших',
        elk_credits_two: 'оставшиеся кредиты.',
        visit_date: 'Дата посещения',
        future_date: 'слишком далеко в будущем',
        visit_creation_error: 'Дата может быть изменена в течение недели после создания посещения',
        credits_elk: 'Невозможно добавить момент времени. Не осталось ни одного отчета.',
        no_history: `Результаты не были введены или импортированы`,
        biomarkers: 'Биомаркеры старения',
        import_account_setup: `Ваша учетная запись не настроена для импорта`,
        import_contact_us: 'Contact Us!',
        import_details: 'for details!!',
        import_isnt_setup: `PhysioAge заключил партнерские отношения с CNS, LabCorp, Quest, Health Diagnostics, Bio-Reference и Access для отправки лабораторных работ непосредственно в наше программное обеспечение, избегая головной боли при вводе данных.`,
        import_bloodwork: `Извините, мы можем импортировать только результаты, связанные с анализом крови в настоящее время.`,
        import_search: `Введите имя пациента, которого вы ищете. Мы покажем все соответствующие сообщения, которые мы получили за последние несколько месяцев.`,
        import_search_header: `Поиск по имени пациента:`,
        import_patient_notfound: `Нет результатов, соответствующих вашему запросу`,
        physiological_ages: 'Physiological Ages!',
        age_weights: 'Age Weights!',
        results_title: 'Review Results!',
        out_of_bounds: 'Out Of Bounds!',
        view: 'View!',
        no_recommandations_caution: 'There is not enough data to generate a visit summary at this time.!',
        panel_name: 'Panel name!',
        browse_library: 'Browse Library!',
        upload_btn: 'Upload!',
        all_downloads: 'View all downloads for this patient!',
        delete_visit_desc: "This will delete all the data associated with the visit!",
        historical_data_validation:'Sorry, there is no historical data for the current visit.!'
    },
    orders_labels: {
        delete_warning: 'Вы уверены, что хотите удалить Заказ?',
        order_success: 'Добавлен заказ для',
        panel_validate: 'Выбранная панель уже добавлена для заказа',
        result: `результаты`,
        order_details: `Детали заказа`,
        out_of_bounds_exception_one: `Извините, вы не можете отправить данные за пределы допустимого. Согласно нашей панели врачей, значения за пределами являются настолько ненормальными, что они, безусловно, ошибочны.`,
        out_of_bounds_exception_two: `Если у вас возникли проблемы с поиском проблемы, обязательно посмотрите на единицы и пропущенные или лишние десятичные дроби .`,
        out_of_bounds_header: `Некоторые данные выходят за пределы`,
        close_btn: `Закрыть`,
        no_changes: 'No changes to save Results!',
        updated_panels: 'Successfully Updated Results!',
        baseline: 'Baseline!',
        one_year: '1 Year!',
        years: 'Years!',
        follow_up: 'FollowUp!',
        preset: 'Preset!',
        gap: 'Gap!',
        unfavourable: 'Unfavourable!',
        entry_date: 'Entry Date!',
        result_view: 'Result View!',
        new: 'New!',
        ordered_by: 'Ordered By:!',
        publication: 'Publication Date:!',
        lab_accession: 'Lab / Accession!',
        draw_date: 'Draw Date!',
        rpt_date: 'Rpt. Date!',
        message_count: '# Msgs!',
        imported_status: 'Imported!',
        wait_status: 'Wait!',
        import_headers: 'Importing Message(s)!',
        import_observations: 'Please wait while we process the following message. It can take up to 60 seconds per message as our system verifies each observation.!',
        import_failed: 'Import Failed.!',
        import_failed_alert: 'We have been alerted and will look in to it. We are sorry that this has happened.!',
        view_message: 'View Message!'
    },
    messages:
    {
        patient_add_success: 'Patient was successfully created!',
        patient_edit_success: 'Пациент был успешно обновлен',
        no_manuals_exist: 'Руководства не существуют',
        user_update_success: 'Пользователь был успешно обновлен.',
        user_add_success: 'Пользователь был успешно добавлен.',
        message_routing_apply: `Я посмотрю на все сообщения за прошлый год, которые еще не были сопоставлены, чтобы увидеть, какие из них применяются`,
        help_add_success: 'успешно удалено',
        elk_validation: 'Вы не можете заряжать сайт через веб-интерфейс',
        document_upload_success: 'Документ успешно загружен',
        help_document_validation: 'Пожалуйста, выберите файл PDF',
        select_visit_date: 'Пожалуйста, выберите дату посещения, чтобы добавить визит',
        select_panel: 'Пожалуйста, выберите имя панели для добавления теста',
        reset_password_mail_success: 'Инструкции для сброса пароля были отправлены на ваш адрес электронной почты.',
        password_req: 'Требуется пароль',
        confirm_pwd_req: 'Подтверждение пароля требуется',
        email_req: 'Email ID is required!',
        pic_validation_message: 'Пожалуйста, введите правильный файл изображения (png или jpg / jpeg)',
        clinic_validation: 'Принимать рефералов можно только в том случае, если сайт является клиникой.',
        site_select_validation: 'Выберите хотя бы один сайт для продолжения',
        password_updated: 'Пароль успешно обновлен',
        payment_site_validation: `You don't have access to add credits for selected site!`,
        elk_validation_zero_credits: `A Site Cannot Buy Zero Credits!`,
        elk_validation_negative_credits: `A Site Cannot Buy Negative Credits!`,
        multiple_card_validation: 'Select only one card for payment!',
        feedback_validation: 'Enter feedback text!',
        no_site_providers: `You won't be able to create any patients until providers are set up for this site. Please contact your PhysioAge vendor!`,
        old_age_restriction: 'Date of Birth implies that patient is impossibly old.!',
        patient_access_msg: 'Patient can now access the portal.!',
        proper_date_validation: 'Enter date in proper format!'
    },
    payments_labels: {
        title_help: 'Add Credits for!',
        num_credits: 'Number of Credits!',
        existing_card: 'Select an Existing Card!',
        new_card: 'Or Enter a New One:!',
        card_number: 'Card Number!',
        cvc_label: 'CVC!',
        expiration: 'Expiration (MM/YY)!',
        save_btn: 'Save!',
        test_cc_title: 'Test CC Numbers!',
        test_cc_help: 'This card works with any three digit CVC and any future expiry date:!! ',
        empty_card: 'This test card is empty:!',
        credits_label: 'Credits!',
        exp_label: 'Expiration date!',
        back_to_site: 'Back to the Site!',
        max_credits_validation: 'Total Credits amount must not be more than $20,000!'
    },
    report_labels: {
        next_gen_exam: 'Next Generation Physical Exam!',
        prepared_for: 'prepared for!',
        by_provider: 'by provider!',
        tests_performed: ' based on tests performed!',
        table_contents: 'Table of Contents!',
        introduction: 'Introduction!',
        compare_system: 'Comparing Systems!',
        health_status_indicator: 'Health Status Indicators!',
        exceptional_results: 'Exceptional Results!',
        abnormal_results: 'Abnormal Results!',
        complete_data: 'Complete Data!',
        historical_data: 'Historical Data!',
        disclaimer: 'Disclaimer!',
        physioage: 'PhysioAge!',
        cutoage: 'CutoAge!',
        immunoage: 'ImmunoAge!',
        pulmoage: 'Pulmoage!',
        telomerage: 'TelomerAge!',
        cardioage: 'CardioAge!',
        neuroage: 'NeuroAge!',
        man_has_arteries: 'A man is as old as his arteries!',
        english_hypocrates: 'known as the English Hypocrates!',
        test: 'The Test!',
        factors_affect: 'What factors affect my !',
        importance: 'Importance!',
        readmore: 'readmore!',
        older_avg: 'years older than average for your age.!',
        younger_avg: 'years younger than average for your age.!',
        is_label: 'is!',
        test_not_performed: 'test not performed!',
        about_test: 'About the Test!',
        skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging?!',
        skin_elasticity: 'Determining Skin Elasticity!',
        significance: 'Significance!',
        composite: 'Composite!',
        flag: 'Flag!',
        first_received: 'First Results Received!',
        last_received: 'Last Results Received!',
    },
    document_labels: {
        format: 'Format!',
        title: 'Title!',
        source: 'Source!',
        distributions: 'Distributions!',
        edit: 'Edit!',
        format_published: 'Format Published!',
        title: 'Title!',
        visit: 'Visit!',
        patient_downloads: 'Patient Downloads!',
        documents_text: 'To add documents, please go to the appropriate visit and find the "Upload" button.!',
        upload_document_for: 'Upload document for!',
        edit_document: 'Edit Document!',
        document_upload_optional: `<b>Optional. Will be set to today's date if you don't enter anything.</b> This field will be useful if you update this document in the future and what to know which is the most current. Once you have selected the year and month, you MUST click on the day.!`,
    },
    successfully_label: 'был успешно',
    added_label: 'добавлено',
    updated_label: 'обновленный',
    required_label: 'требуется',
    email_help: 'Введите идентификатор электронной почты',
    credits_caution_title: 'У вас осталось количество кредитов в отчете scrednumber.',
    credits_caution_help: 'Ваш кредитный баланс в отчете PhysioAge Reporting достиг scrednumber. Чтобы избежать возможных задержек, добавьте больше кредитов, используя вашу кредитную карту. ',
    credits_caution_contact: 'Свяжитесь с нами по вопросам.',
    acknowlegde: 'Подтвердить',
    no_grid_data: 'Currently, there is no data to display!',
    pending: 'Pending!',
    no_data: 'No Data!',
    finished: 'Finished!',
    allowed_date_format: 'Enter date in <> format!',
    no_visits: 'No Visits!',
    no_summary_data: `I'm sorry, there was not enough medical data present to generate a summary. Typically this means your data has not been entered into our system yet. Please try again later.!`,
    pages: 'Pages!'
}

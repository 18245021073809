import {connect} from 'react-redux';
import RecommendationList from '../components/RecommendationList';

import { actionCreators } from '../modules/analyticsBeta';
const mapStateToProps = (state) => ({
    ...state.analytics
})
const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(RecommendationList);
import React, { Component } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import PatientDocuments from './PatientDocuments';

const openbleFiles = ['pdf', 'jpg', 'png', 'jpeg']

class PatientsDocuments extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.patientId) {
            this.props.setCurrentPatient(this.props.match.params.patientId, null, 'document-list', true)
        }
    }
    render() {
        const columns = [
            {
                dataField: '', formatter: (cellcontent, row) => row.format == 'pdf' ?
                    <i class='fa fa-file-pdf-o'></i> : <i class='fa fa-file-o'></i>,
                headerStyle: { width: '5%' }
            },
            { dataField: 'format', text: labels.document_labels.format, headerStyle: { width: '10%' } },
            {
                dataField: 'title', text: labels.document_labels.title,
                formatter: (cellcontent, row) => {
                    if (row.format && openbleFiles.indexOf(row.format.toLowerCase()) != -1) {
                        return <Link to={`/documents/${row.documentId}.${row.format}`} target='_blank'>{row.title}</Link>
                    } else {
                        return <a onClick={() => this.props.downloadDocument(row, this.props.history)}>{row.title}</a>
                    }
                }, headerStyle: { width: '45%' }
            },
            { dataField: 'source', text: labels.document_labels.source, headerStyle: { width: '15%' } },
            { dataField: 'distributions', text: labels.document_labels.distributions, headerStyle: { width: '10%' } },
            {
                dataField: '', text: '', formatter: (cellcontent, row) => <a onClick={() =>
                    this.props.history.push(`/patients/${this.props.currentPatient.id}/documents/${row.documentId}/edit`)}>{labels.document_labels.edit}</a>,
                headerStyle: { width: '10%' }
            },
            {
                dataField: '', text: '', formatter: (cellcontent, row) => <span className='delete-document' onClick={() => this.props.deleteDocument(row.documentId, true)}><svg aria-hidden="true" title="Delete" fill="red">
                    <use xlinkHref="#trash" xmlnsXlink="http://www.w3.org/1999/xlink"></use>
                    <symbol id="trash" viewBox="0 0 16 16">
                        <path d="M2,6v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V6H2z"></path>
                        <path d="M12,3V1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H0v2h16V3H12z M10,3H6V2h4V3z"></path>
                    </symbol>
                </svg></span>
            }
        ]
        return (
            <>
                <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 site-documents'>
                    <FormControl type='button' btnType='primary' text='New Document'
                        onClick={() => this.props.history.push(`/patients/${this.props.currentPatient.id}/documents/new`)}
                    />
                    {/* <div className='card instructions'>
                        <div className='card-header info'>
                            <b>{labels.sites_labels.instructions}</b>
                        </div>
                        <div className='card-body'>
                            {
                                constants.doc_side_preview.paragraphs.map(s =>
                                    <p>{s}</p>
                                )
                            }
                            <ul>
                                {
                                    constants.doc_side_preview.list.map(s =>
                                        <li>{s}</li>
                                    )
                                }
                            </ul>
                            <p>{constants.doc_side_preview.last_paragraph}</p>
                        </div>
                    </div> */}
                </div>
                {Object.keys(this.props.currentPatient).length ?
                    this.props.currentPatientDocumentsList && this.props.currentPatientDocumentsList.length ?
                        <div className='document-table w-auto'>
                            <div className='bootstrap-table-container'>
                                <BootstrapTable striped={false} bordered={false} hover={true} bootstrap4 keyField='id'
                                    data={this.props.currentPatientDocumentsList ? this.props.currentPatientDocumentsList : []} columns={columns}
                                />
                            </div>
                        </div> :
                        <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 right-section p-0 site-doc-no-preview'>
                            <div className='card card-visit'>
                                <div className='card-body b-b-light'> 
                                    <h3>{constants.no_doc_preview.header}</h3>
                                </div>
                                <div className='patient-jumb jumbotron'>
                                    {
                                        constants.no_doc_preview.paragraphs.map(s =>
                                            <p>{s}</p>
                                        )
                                    }
                                    <ul>
                                        {
                                            constants.no_doc_preview.list.map(s =>
                                                <li>{s}</li>
                                            )
                                        }
                                    </ul>
                                    {
                                        constants.no_doc_preview.paragraphs_last.map(s =>
                                            <p>{s}</p>
                                        )
                                    }
                                    <FormControl type='button' text={constants.no_doc_preview.upload_a_doc} onClick={() => this.props.history.push(`/sites/${this.props.currentSite.id}/documents/new`)} className='btn btn-primary btn-huge' />
                                </div>

                            </div>

                        </div> : ''}
            </>
        )
    }
}

export default PatientsDocuments

import React, { Component } from 'react';
import _ from 'lodash';
import './documents-styles.scss';

class DocumentsView extends Component {
    componentDidMount() {
        document.body.style = 'background: #ffffff!important';
        document.title = window.location.href
        if (this.props.match.params && this.props.match.params.documentId) {
            let documentId = _.head(this.props.match.params.documentId.split('.'))
            this.props.setDocumentView(documentId)
        }
    }
    componentDidUpdate() {
        document.body.style.overflow = 'hidden'

    }
    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }
    render() {
        return this.props.libraryDocument && this.props.libraryDocument.documentFileContent
            ? this.props.isImage
                ?
                <a className='document-image'
                    href={`data:${this.props.libraryDocument.fileContentType};base64,${this.props.libraryDocument.documentFileContent}`}
                    download={this.props.libraryDocument.title}>
                    <img
                        src={`data:${this.props.libraryDocument.fileContentType};base64,${this.props.libraryDocument.documentFileContent}`}
                    />
                </a>
                :
                <a className='document-pdf'
                    href={this.props.downloadLink}
                    download={this.props.libraryDocument.title}>
                    <object data={this.props.downloadLink} />
                </a>
            : <></>

    }
}
export default DocumentsView
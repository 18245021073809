
import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import RecommendationRow from '../containers/RecommendationRowContainer';
import Loader from '../../../components/Loader';
import constants from '../../../config/constants'
import Sortable from 'sortablejs'
import labels from '../../../config/localization';


function Recommendations(props) {
  const [readOnly, setReadOnly] = useState(props.readOnly ? props.readOnly : false);
    useEffect(() => {
      if (props.loggedInUser &&
        (props.loggedInUser.role == constants.logged_roles.AN
          || props.loggedInUser.role == constants.logged_roles.CG)
        && props.recommendations && props.recommendations.length > 1) {
        let simpleList = document.getElementById('simpleList')
        if (!simpleList)
          return
        Sortable.create(simpleList, {
          animation: 150,
          // handle: '.handle',
          onUpdate: () => {
            props.savePriorities()
            props.toggleResetPriority()
          }
        })
      }
        // componentDidMount //
    return () => {
      // componentWillUnmount() //
    }
  }, [])

let isNullOrEmpty = (value) => {
  return (value === null || value === "");
}

let updatedMetaRecommendations = (recommendations, metaRecommendations) => {
  const newMetaRecommendations = Array.isArray(metaRecommendations) && Array.isArray(recommendations) ? metaRecommendations.filter(metaRecommendation => {
      return !recommendations.some(recommendation => {
          const matchBody = (isNullOrEmpty(recommendation.body) && isNullOrEmpty(metaRecommendation.body)) ? true : recommendation.body && metaRecommendation.body && 
              recommendation.body.replace(/\s/g, '').toLowerCase() === metaRecommendation.body.replace(/\s/g, '').toLowerCase();

          const matchBrand = (isNullOrEmpty(recommendation.brand) && isNullOrEmpty(metaRecommendation.brand)) ? true : recommendation.brand && metaRecommendation.brand &&
              recommendation.brand.replace(/\s/g, '').toLowerCase() === metaRecommendation.brand.replace(/\s/g, '').toLowerCase();

          const matchGroup = (isNullOrEmpty(recommendation.group) && isNullOrEmpty(metaRecommendation.group)) ? true : recommendation.group && metaRecommendation.group &&
              recommendation.group.replace(/\s/g, '').toLowerCase() === metaRecommendation.group.replace(/\s/g, '').toLowerCase();

          return matchBody && matchBrand && matchGroup;
      });
  }) : []
  return newMetaRecommendations
}

  let recommendations = () =>{
    if (props.readOnly){
      return (props.redOnlyRecommendations && props.redOnlyRecommendations.length > 0 ? props.redOnlyRecommendations : [])
    }
    else{  
      if (props.recType == "Medication"){
        if (props.recommendationType == "active") {
          return ( props.filterRecommendations.length > 0 ? props.filterRecommendations : props.medications && props.medications.length > 0 ? props.medications : [])
        }
        else if(props.recommendationType == "stopped") {
          return ( props.filterRecommendations.length > 0 ? props.filterRecommendations : props.stoppedMedications && props.stoppedMedications.length > 0 ? props.stoppedMedications : [])
        }
      }
      else if(props.recType == "Recommendation") {
        if (props.recommendationType == "meta") {
            return (props.filterRecommendations.length > 0 ? props.filterRecommendations : props.metaRecommendations && props.metaRecommendations.length > 0 ? updatedMetaRecommendations(props.recommendations, props.metaRecommendations) : [])
        }
        else if(props.recommendationType == "active") {
          return (props.filterRecommendations.length > 0 ? props.filterRecommendations : props.recommendations && props.recommendations.length > 0 ? props.recommendations : [])
        }
        else if(props.recommendationType == "stopped") {
          return (props.filterRecommendations.length > 0 ? props.filterRecommendations : props.stoppedRecommendations && props.stoppedRecommendations.length > 0 ? props.stoppedRecommendations : [])
        }
        else{
          return([])
        }
      }  
    }  
}

  let renderRecommendations = (list, isMeta) => {
    return (
      list.map(
        (recommendation, index) => {
          return (
            <RecommendationRow recommendation={recommendation} {...props}{...isMeta} index={index}  readOnly={readOnly} />
          )
        }
      )
    );
  }

  let renderGroup = (recommendations, isMeta) => {
    if (recommendations.length > 0) {
      return (
          <>
            <div className={`recommendation-list ${props.showRecommendationModal ? "overflow-hidden" : ""}`}  id="simpleList">
               {renderRecommendations(recommendations, isMeta)}
            </div>
          </>  
      )
    }
    else {
      return null;
    }
  }

  return (
     <>
        {
          props.isPriorityLoader && <center><i class="fa fa-circle-o-notch fa-spin fa-lg ml-4 priorityLoader"></i></center>
        }
        { props.isRecommendationsLoading ? <center><i className='fa fa-spinner fa-spin pt-4'></i></center> :
            
            recommendations() && recommendations().length > 0 ?
            renderGroup(recommendations(), { isMeta:  props.recommendationType == "meta" ? true : false }) :
            props.recommendationType == "meta" ?
              <div className='row justify-content-center p-3'>
                <div className='col-11'>
                  <div class="card no-meta-recommendations">
                    <div class="card-body">{`No ${labels.analytics_labels.suggestions} exists`}</div>
                  </div>
                </div>  
              </div>  
            : props.recommendationType == "stopped"  ?
                <div className='row justify-content-center p-3'>
                  <div className='col-11'>
                    <div class="card no-meta-recommendations">
                      <div class="card-body">No Stopped recommendations exists</div>
                    </div>
                  </div>  
                </div> 
            : props.readOnly ?
              <div className='row justify-content-center p-3'>
              <div className='col-11'>
                <div class="card no-meta-recommendations">
                  <div class="card-body">No Active recommendations exists</div>
                </div>
              </div>  
            </div>
            : props.recommendationType == "active"  ?
              <div className="instructions no-recommendations pt-3">
              <div className="card-content card-content-container">
              { props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ? 
                <>
                <p>You have not entered any recommendations.</p>
                <p>You can enter recommendations on the Recommendations tab of any "drawer". There are several ways to access drawers:
                </p>
                <ol style={{ lineHeight: '200%' }}>
                  <li><b>Test level</b> - Click on any test name (eg, HGA1C) to open the drawer for that test. </li>
                  <li><b>Category level</b> - Click on any Category name (eg; Heart Health) to open the drawer for that Category.</li>
                  <li><b>Section level</b> - Click on the letter grade to the  left of any Section (eg, Arterial Stiffness) to open the drawer for that Section</li>
                </ol>
                <p>To enter a recommendation tied to this Visit - Click on <b>Add Recommendation</b> above.</p><br />
                <p>Recommendations will be visible to patients in this section and on the Visit Summary PDF.</p>
                </>
                : <p> {props.recType}s added for you by the provider will be listed in this section.</p>
                }
              </div>
              </div>
            : "" 
        }
      </>
  )
}
export default connect()(Recommendations);


export default  [
    {

        "points": "3.2",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "3.2",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "1.1",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "1.2",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "2.7",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "2.6",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "3.1",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "0.4",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "6.1",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },

    {

        "points": "6.1",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "6.1",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "6.1",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "6.1",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "6.1",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },
    {

        "points": "6.1",
        "name": "Health Span Potential",
        "text": "Granulocyte Telomere Length: 7.4 kb | Intrinsic Epigenetic Age: 73.34 years"
    },

]

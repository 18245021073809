import { connect } from 'react-redux'
import ActiveSiteReport from '../components/ActiveSiteReports'
import { actionCreators } from '../modules/activesitereport'
import { setBreadCrumbs, setPageTitle, toggleNotification, navigatePatients, setCreditsCaution } from '../../../reducers/global-reducer';

const mapStateToProps = (state) => ({
    ...state.sitereport,
    isLoading: state.global.isLoading,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify
})

const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    navigatePatients,
    setCreditsCaution
}


export default connect(mapStateToProps, mapDispatchToProps)(ActiveSiteReport)
import React from 'react';
import DatumDrawer from './DatumDrawerComponent';
import BiomarkerDrawer from './BiomarkerDrawerComponent';
import ReportCardDrawer from './ReportCardDrawer';
import CategoryDrawer from './CategoryDrawer';
import SectionDrawer from './SectionDrawer';

export default class Drawer extends React.Component {
  renderDrawer(drawerType, props) {
    switch (drawerType) {
      case 'biomarker':
        return (
          <BiomarkerDrawer {...this.props} drawerType={this.props.drawerType} />
        )
      case 'datum':
        return (
          <DatumDrawer {...this.props} datum={this.props.datumValues} drawerType={this.props.drawerType} />
        )
      case 'reportCard':
        return (
          <ReportCardDrawer {...this.props} drawerType={this.props.drawerType} />
        )
      case 'category':
        return (
          <CategoryDrawer {...this.props} drawerType={this.props.drawerType} />
        )
      case 'section':
        return (
          <SectionDrawer {...this.props} drawerType={this.props.drawerType} />
        )
      default:
        return (null);
    }
  }

  render() {
    return (
      <div>
        {this.renderDrawer(this.props.drawerType ? this.props.drawerType : '', this.props)}
      </div>
    )
  }
}

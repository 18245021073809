import React, { Component, useState, useEffect } from 'react';
import countries from 'country-list';
import FormControl from '../../../components/FormControls';
import Notification from '../../../components/Notification';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import _ from 'lodash';
import moment from 'moment';


const stylesClasses = {
    labelClass: 'col-lg-2',
    inputClass: 'col-lg-8'
}
const dropdownClasses = {
    valueKey: 'code',
    textKey: 'value',
    labelClass: 'col-lg-2',
    inputClass: 'col-lg-8'
}

const ToggleSwitch = ({props}) => {
    const [isToggled, setIsToggled] = useState();
    const [toggleLabel, setToggleLabel] = useState('MFA is required for everyone');
    useEffect(() => {
        setIsToggled(props.currentSite && props.currentSite.mfaRequired)
        return () => {
            // componentWillUnmount() //
        }
    }, [props.currentSite.mfaRequired]);

    const handleToggle = () => {
      setIsToggled(!isToggled);
      setToggleLabel(!isToggled ? "MFA is required for everyone" : "MFA is optional for everyone");
      props.editSite({target: {id: "mfaRequired", value: !isToggled ? 1 : 0 }})
    };
  
    return (
      <div className="toggle-switch">
        <div className={`switch ${isToggled ? 'toggled' : ''}`} onClick={handleToggle}>
          <div className="switch-handle" />
        </div>{toggleLabel && <span className='pl-2 help-block'>{toggleLabel}</span>}
      </div>
    );
};

class SitesForm extends Component {

    componentDidMount() {
        let storedRole = localStorage.getItem('role') ? constants.logged_roles[localStorage.getItem('role')] : null
        let role = this.props.loggedInUser ? this.props.loggedInUser.role : storedRole
        if (role && role == constants.logged_roles.CG) {
            this.props.history.push('/patients/list')
        } else {
            this.props.toggleNotification(false)
            this.props.getPanels()
            if (this.props.match.params && this.props.match.params.siteId) {
                this.props.setCurrentSite(this.props.match.params.siteId)
            } else {
                this.props.setCurrentSite()
                this.props.setBreadCrumbs([
                    { text: labels.physioage_label, path: '/patients/list' },
                    { text: labels.sites_labels.new_site_label, path: '' }
                ])
                this.props.setPageTitle(labels.sites_labels.new_site_label)
                document.title = `${labels.physioage_label}: ${labels.sites_labels.new_site_label}`
            }
            if (this.siteForm && this.siteForm.inputEntry) {
                this.siteForm.inputEntry.focus()
            }
        }
        // this.props.setNoumenonFields()
    }

    getDefaultOrders(defaultorders) {
        return defaultorders ? defaultorders.split(',').map(a => ({ name: a })) : []
    }

    getDefaultNoumena(noumenonCodes){
        const mappedNoumenonCodes = (noumenonCodes && this.props.allNoumenonNameList) ?
         noumenonCodes.map(a => ({
            code: this.props.allNoumenonNameList.find(
              nouemon => nouemon.code === a
            )
              ? this.props.allNoumenonNameList.find(
                  item => item.code === a
                ).code
              : "",
            noumenonName: this.props.allNoumenonNameList.find(
              nouemon => nouemon.code === a
            )
              ? this.props.allNoumenonNameList.find(
                  item => item.code === a
                ).noumenonName
              : ""
          })) : []
        return mappedNoumenonCodes
    }
    render() {
        const selectedCodes = this.props.CreateNoumenonList ? this.props.CreateNoumenonList.map(noumenon => noumenon.code) : []
        const options = this.props.allNoumenonNameList ? this.props.allNoumenonNameList.map(noumenon => ({
            ...noumenon,
            isDisabled: selectedCodes.includes(noumenon.code),
          }))
         : []
        return (
            <React.Fragment>
                <div className='col-lg-3'></div>
                <form className='sites-form col-lg-9 justify-center mt-4' onSubmit={(e) => { e.preventDefault(); this.props.saveSite(this.props.history) }}>
                    <FormControl ref={(s) => this.siteForm = s} value={this.props.currentSite.name} onChange={this.props.editSite} type='text' id='name' required={true} {...stylesClasses} label={labels.sites_labels.name_label} help={labels.sites_labels.name_help} />
                    <FormControl onChange={this.props.editSite} value={this.props.currentSite.email ? this.props.currentSite.email : ''} id='email' validate label={labels.sites_labels.email} type='email' {...stylesClasses} help={labels.sites_labels.email_help} />
                    <FormControl value={this.props.currentSite.category} onChange={this.props.editSite} required={true} type='select' id='category' {...dropdownClasses} label={labels.sites_labels.category_label} options={constants.site_categories} />
                    <div className='d-flex site-form-active'>
                        <div><label className="col-lg-1"></label></div>
                        <FormControl checked={this.props.currentSite && this.props.currentSite.active ? true : false}
                            onChange={this.props.editSite} type='checkbox' id='active'
                            label={labels.sites_labels.active} labelClass='col-lg-8 ml-2' />
                    </div>
                    <div>
                        {/* Plan Details */}
                        {this.props.currentSite && this.props.currentSite.id && this.props.currentSite.subscriptionId ?
                            <div>
                                <legend>Plan Details</legend>
                                <FormControl onChange={this.props.editSite} required={true} id='planType'
                                    label={labels.user_labels.choose_plan} type='select'
                                    options={constants.plans}
                                    {...stylesClasses}
                                    textKey={'text'} valueKey={'value'}
                                    help={<div dangerouslySetInnerHTML={{ __html: labels.messages.plan_message }}></div>}
                                    value={this.props.currentSite && this.props.currentSite.planType ? this.props.currentSite.planType : ''}
                                />
                            </div>
                            : ''
                        }
                    </div>
                    <legend>{labels.sites_labels.features_label}</legend>
                    <FormControl checked={this.props.currentSite.acceptsReferrals && parseInt(this.props.currentSite.acceptsReferrals) ? true : false} onChange={this.props.editSite} type='checkbox' id='acceptsReferrals' label={labels.sites_labels.site_referrals_help} labelClass='col-lg-8' />
                    <FormControl checked={this.props.currentSite.enableDocuments} type='checkbox' id='enableDocuments' onChange={this.props.editSite} label="Enable 'Documents' Feature" labelClass='col-lg-8' />
                    <FormControl checked={this.props.currentSite.wantsWholePunchMargin && parseInt(this.props.currentSite.wantsWholePunchMargin) ? true : false} onChange={this.props.editSite} type='checkbox' id='wantsWholePunchMargin' label={labels.sites_labels.margins_help} labelClass='col-lg-8' />
                    <FormControl checked={this.props.currentSite.acceptsMessages} onChange={this.props.editSite} type='checkbox' id='acceptsMessages' label={labels.sites_labels.sites_message_acknowledge} labelClass='col-lg-8' />
                    <FormControl checked={this.props.currentSite.includePhoneInSummary} onChange={this.props.editSite} type='checkbox' id='includePhoneInSummary' label={labels.sites_labels.include_phone_in_summary} labelClass='col-lg-8' />
                    <legend>{labels.sites_labels.preferences}</legend>
                    <FormControl value={this.props.currentSite.languagePreference}
                        onChange={this.props.editSite} type='select' id='languagePreference' required={true}
                        {...dropdownClasses} label={labels.sites_labels.language_preference} options={constants.language_preference} />
                    <FormControl value={this.props.currentSite.datePreference}
                        onChange={this.props.editSite} type='select' id='datePreference'
                        {...dropdownClasses} label={labels.sites_labels.date_preference} options={constants.date_preference} />
                    <FormControl value={this.props.currentSite.unitPreference}
                        onChange={this.props.editSite} type='select' id='unitPreference' required={true}
                        {...dropdownClasses} label={labels.sites_labels.unit_preference} help={labels.sites_labels.unit_preference_help} options={constants.unit_preference} />
                    <FormControl onChange={this.props.logoUploaded} type='file' id='logo' label={labels.sites_labels.logo_label} {...stylesClasses} ref={r => this.changeLogoValue = r} />
                    {this.props.currentSite.logoFile ?
                        <React.Fragment>
                            <label>{labels.sites_labels.current_image_label}</label>
                            <img className='site-logo-preview' src={`data:${this.props.currentSite.logoContentType};base64,${this.props.currentSite.logoFile}`} />
                            {/* <i className='fa fa-times-circle danger' title='Remove picture' onClick={(e) => this.props.removePic(e, this.changeLogoValue, 'logo')}>{labels.provider_labels.remove_pic}</i> */}
                            <button className="btn btn-danger" onClick={(e) => this.props.removePic(e, this.changeLogoValue, 'logo')}>Remove</button>
                        </React.Fragment> : null
                    }
                    <FormControl onChange={this.props.logoUploaded} type='file' id='bannerLogo' label={labels.sites_labels.banner_logo_label} {...stylesClasses} ref={r => this.changeBannerLogoValue = r} />
                    {this.props.currentSite.bannerLogoFile ?
                        <React.Fragment>
                            <label>{labels.sites_labels.current_image_label}</label>
                            <img className='site-logo-preview' src={`data:${this.props.currentSite.bannerLogoContentType};base64,${this.props.currentSite.bannerLogoFile}`} />
                            {/* <i className='fa fa-times-circle danger' title='Remove picture' onClick={(e) => this.props.removePic(e, this.changeBannerLogoValue, 'bannerLogo')}>{labels.provider_labels.remove_pic}</i> */}
                            <button className="btn btn-danger" onClick={(e) => this.props.removePic(e, this.changeBannerLogoValue, 'bannerLogo')}>Remove</button>
                        </React.Fragment> : null
                    }
                    <legend>{labels.sites_labels.settings}</legend>
                    <FormControl value={this.props.currentSite.billingStrategy} onChange={this.props.editSite}
                        type='select' id='billingStrategy' {...dropdownClasses}
                        label={labels.sites_labels.billing_strategy} options={this.props.currentSite && this.props.currentSite.id ? constants.billing_strategy : constants.billing_strategy_credits} required={true}
                        defaultValue={_.head(constants.billing_strategy).value} />
                    { this.props.currentSite && process.env.REACT_APP_MFA_ENABLED != 'false' ?
                        <div className='d-flex pt-3 pb-3'>
                            <label className='col-2'>{labels.sites_labels.mfa}</label>
                            <div className='col-5 pt-2 p-0'>
                                <ToggleSwitch props={this.props}/>
                            </div>
                        </div>
                    : null }    

                    {/* out of scope */}
                    {/* <FormControl value={this.props.currentSite.brand} onChange={this.props.editSite} type='select' id='brand' {...stylesClasses} label={labels.sites_labels.brand} options={[]} /> */}
                    <span className="default-panel">
                        <FormControl value={this.getDefaultOrders(this.props.currentSite.defaultPanels)}
                            onChange={this.props.editPanels} type='select' id='defaultPanels' isMulti={true}
                            getOptionLabel={opt => opt.name}
                            getOptionValue={opt => opt.name}
                            label={labels.sites_labels.default_panels}
                            options={this.props.panelList ? this.props.panelList : []} className='col-lg-7' />
                    </span>
                    <FormControl value={this.props.currentSite.uclaPracticeCode} onChange={this.props.editSite}
                        type='text' id='uclaPracticeCode'
                        label={labels.sites_labels.ucla_practice_code} {...stylesClasses} />
                    <FormControl value={this.props.currentSite.truDiagApiLoginId} onChange={this.props.editSite}
                        type='text' id='truDiagApiLoginId'
                        label={labels.sites_labels.trudiag_api_login_id} {...stylesClasses} />
                    <FormControl value={this.props.currentSite.truDiagTransactionKey} onChange={this.props.editSite}
                        type='text' id='truDiagTransactionKey'
                        label={labels.sites_labels.trudiag_transaction_key} {...stylesClasses} />
                        
                    <FormControl value={this.props.currentSite.glycanAgeUserName} onChange={this.props.editSite}
                        type='text' id='glycanAgeUserName'
                        label={labels.sites_labels.glycanage_api_login_name} {...stylesClasses} />
                    <FormControl value={this.props.currentSite.glycanAgeAPIKey} onChange={this.props.editSite}
                        type='text' id='glycanAgeAPIKey'
                        label={labels.sites_labels.glycanage_transaction_key} {...stylesClasses} />    
                    {/* Site Specific GPA - PMI-3281 */}
                    <legend className='m-0 border-0'>{labels.sites_labels.gpa_noumena}</legend>    
                    { this.props.CreateNoumenonList ?
                        <div className='bootstrap-table-container createMultipleDocument'>
                            <table className='react-bootstrap-table table'>
                                <thead>  
                                <tr>
                                    <th className='col-6' >{labels.sites_labels.noumena}</th>
                                    <th className='col-3'>{labels.sites_labels.start_date}</th>
                                    <th className='col-3'>{labels.sites_labels.end_date}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.props.CreateNoumenonList.map((element, index) => (  
                                        <tr key={element.uuid}>
                                            <td className='col-6'>
                                                <FormControl
                                                    value={element.code}
                                                    onChange={ (e) => this.props.editNoumenon(index, e, element.uuid)} type='select' id='code'
                                                    options={options}
                                                    getOptionLabel={opt => opt.noumenonName}
                                                    isOptionDisabled={(option) => option.isDisabled}
                                                    getOptionValue={opt => opt.code}
                                                    textKey={'noumenonName'} valueKey={'code'}
                                                    className='form-control'
                                                />
                                            </td>
                                            <td className='col-3'>
                                                <FormControl type='date' id='startDate' onChange={ (e) => this.props.editNoumenon(index, {target: {id: 'startDate', value: e}}, element.uuid)} 
                                                    selected={element && element.startDate  ? new Date(element.startDate) : ''}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabledKeyboardNavigation
                                                    popperPlacement= {'bottom-end'}
                                                    maxDate={  
                                                        element.endDate ?
                                                        moment(element.endDate).add(-1, 'days').toDate() : ""}
                                                />
                                            </td>
                                            <td className='col-3'>
                                            <FormControl type='date' id='endDate' onChange={ (e) => this.props.editNoumenon(index, {target: {id: 'endDate', value: e}}, element.uuid)} 
                                                disabledKeyboardNavigation
                                                popperPlacement= {'bottom-end'}
                                                selected={element && element.endDate  ? new Date(element.endDate) : ''}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={ element.startDate  ?
                                                    moment(element.startDate ).add(1, 'days').toDate() : ""}
                                                
                                            />
                                            </td>
                                            <td>
                                            <i className="fa fa-trash status-indicator text-danger trash-icon" onClick={() =>this.props.removeNoumenonFields(element.uuid)}> </i> 
                                            </td>
                                        </tr>
                                        ))}
                                        <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <i className="fa fa-plus status-indicator text-success trash-icon" onClick={() => this.props.addNoumenonFields()}> </i>
                                        </td>
                                        </tr>
                                    
                                </tbody> 
                            </table>
                        </div>
                    : null }
                    <legend>{labels.sites_labels.referral_info}</legend>
                    <Notification notificationType='info' texts={[labels.sites_labels.address_help]} />
                    <FormControl required={this.props.currentSite.acceptsReferrals ? true : false} value={this.props.currentSite.address} onChange={this.props.editSite} type='text' id='address' {...stylesClasses} label={labels.sites_labels.address_label} />
                    <FormControl required={this.props.currentSite.acceptsReferrals ? true : false} value={this.props.currentSite.city} onChange={this.props.editSite} type='text' id='city' {...stylesClasses} label={labels.sites_labels.city_label} />
                    <FormControl required={this.props.currentSite.acceptsReferrals ? true : false} value={this.props.currentSite.state} onChange={this.props.editSite} type='text' id='state' {...stylesClasses} label={labels.sites_labels.state_label} />
                    <FormControl required={this.props.currentSite.acceptsReferrals ? true : false} value={this.props.currentSite.postalCode} onChange={this.props.editSite} type='text' id='postalCode' {...stylesClasses} label={labels.sites_labels.postal_code} />
                    <FormControl value={this.props.currentSite.country}
                        onChange={this.props.editSite} type='select' id='country' {...stylesClasses} label={labels.sites_labels.country} textKey='name' valueKey='code' options={countries.getData()} />
                    <FormControl value={this.props.currentSite.phone}
                        onChange={this.props.editSite} type='text' id='phone' required={true}
                        {...stylesClasses} label={labels.sites_labels.phone} help={labels.sites_labels.phone_help} />
                    <FormControl value={this.props.currentSite.website}
                        onChange={this.props.editSite} id='website' {...stylesClasses} label={labels.sites_labels.website_label} help={labels.sites_labels.website_help} />

                    <legend>{labels.sites_labels.other_info}</legend>
                    <FormControl value={this.props.currentSite.emrDetails ? this.props.currentSite.emrDetails : ''}
                        onChange={this.props.editSite} type='text' id='emrDetails'
                        {...stylesClasses} label={labels.sites_labels.what_emr_using} />
                    <FormControl onChange={this.props.editSite} type='text' id='principleEHR' {...stylesClasses} label={labels.sites_labels.principle_ehr} value={this.props.currentSite.principleEHR} />

                    <FormControl type='button' btnType={constants.notification_type.success} text={labels.sites_labels.save_btn} />
                </form>

            </React.Fragment>
        )
    }
}
export default SitesForm

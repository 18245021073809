export default  [
    {
        "id": 1,
        "visitID": "1234",
        "noteDate": "2023-07-20T00:00:00",
        "note": "Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting"
    },
    {
        "id": 2,
        "visitID": "1234",
        "noteDate": "2023-07-21T00:00:00",
        "note": "Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting"
    },
    {
        "id": 3,
        "visitID": "1234",
        "noteDate": "2023-07-22T00:00:00",
        "note": "Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting"
    },
    {
        "id": 4,
        "visitID": "1234",
        "noteDate": "2023-07-23T00:00:00",
        "note": "Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting"
    },
    {
        "id": 5,
        "visitID": "1234",
        "noteDate": "2023-07-24T00:00:00",
        "note": "Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting Lorem Ipsum is simply dummy text of the printing dummy text of and typesetting"
    }

]

import constants from "../config/constants";

function parseJWT() {
    let token = localStorage.getItem(constants.accessToken)
    if (token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let resultant = JSON.parse(window.atob(base64))
        return resultant
    }
    return {}
}
export function getRouteStatus() {
    //todo: check previous url, if analytics goBack, check for redirecturl, if it is hashed, replace
    let previousUrl = localStorage.getItem('previousurl')
    if (previousUrl && previousUrl.indexOf('analytics') != -1) {
        if (previousUrl.indexOf('#') != -1) {
            return true
        } else {
            return false
        }
    } else if (previousUrl) {
        return true
    }
}
export default parseJWT
export default {
  demo_1: {
    "id": 3,
    "firstname": "Joseph",
    "lastname": "Raffaele",
    "honorificSuffix": "MD",
    "avatarFileName": "joseph_raffaele_md.jpg",
    "avatarContentType": "image/jpeg",
    "avatarContent": null,
    "avatarFileSize": 32508,
    "createdAt": "2010-09-01T19:47:31",
    "updatedAt": "2018-01-19T21:23:59",
    "honorificPrefix": "",
    "sites": [
      {
        "id": 1,
        "name": "TA Sciences",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 3,
        "name": "Raffaele Medical",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 53,
        "name": "GDF11 Rejuvenation Trial",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 54,
        "name": "WorldLink: Hormones & Beyond, October, 2017",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 55,
        "name": "AMMG Fall 2017",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 56,
        "name": "MedQuest Pharmacy",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 61,
        "name": "PROD Testing Site",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 66,
        "name": "World Link Medical Conference 2018",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 67,
        "name": "A4M December 18",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 69,
        "name": "A4M May 2019",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 83,
        "name": "PhysioAge Demo Site",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      }
    ]
  },
  demo_2: {
    "id": 3,
    "firstname": "Joseph",
    "lastname": "Raffaele",
    "honorificSuffix": "MD",
    "avatarFileName": "joseph_raffaele_md.jpg",
    "avatarContentType": "image/jpeg",
    "avatarContent": null,
    "avatarFileSize": 32508,
    "createdAt": "2010-09-01T19:47:31",
    "updatedAt": "2018-01-19T21:23:59",
    "honorificPrefix": "",
    "sites": [
      {
        "id": 1,
        "name": "TA Sciences",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 3,
        "name": "Raffaele Medical",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 53,
        "name": "GDF11 Rejuvenation Trial",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 54,
        "name": "WorldLink: Hormones & Beyond, October, 2017",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 55,
        "name": "AMMG Fall 2017",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 56,
        "name": "MedQuest Pharmacy",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 61,
        "name": "PROD Testing Site",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 66,
        "name": "World Link Medical Conference 2018",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 67,
        "name": "A4M December 18",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 69,
        "name": "A4M May 2019",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      },
      {
        "id": 83,
        "name": "PhysioAge Demo Site",
        "value": null,
        "includeFromOBX": false,
        "isActive": false
      }
    ]
  }
}














import React from 'react';
import { connect } from 'react-redux';
import ReactDom from 'react-dom';
import closeLight from '../../../assets/images/close-light.svg';
import close from '../../../assets/images/close.svg';

function ProblemWarning(props){
        return ReactDom.createPortal(
            <>
                <div></div>
                <div className={`drawer-warning popup-${props.theme} ${props.siteTheme}`}>
                    <div className='row no-gutters'> 
                        <div className='col-11'></div>
                        <div className='col-1 pt-1'>
                            <a><img className='float-right' src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleProblemFormWarning(false))}></img></a>
                        </div>
                    </div>    
                    <div className='warning-body'>
                        <h5>{"Close the form?"}</h5>
                        <hr />
                        <p>{`The Problem form has been modified. Closing it will discard your changes. Do you want to continue?`}</p>
                        <hr />
                        <div className='d-flex justify-content-end'>
                            <button className='btn drawer-cancel' onClick={() => {  props.toggleProblemFormWarning(false); }}>Cancel</button>
                            <button className='btn drawer-ok ml-4' onClick={() => {
                                    props.toggleProblemFormWarning(false);
                                    props.toggleProblemModal(false);
                            }}>Ok</button>
                        </div>
                    </div>    
                </div>
            </>,
        document.getElementById('surveyPortal')
        )
}

export default connect()(ProblemWarning);
import {connect} from 'react-redux';
import SplashPage from '../components/SplashPage';
import masthead from '../../../assets/images/analytics-masthead-inverted.svg';
import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
  ...state.analytics
})

const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(SplashPage);

import React, { Component } from 'react';
import labels from '../../../config/localization';
import sample_spectra from '../../../config/sample_spectra';

class Spectra extends Component {
    componentDidMount() {
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.noumena_labels.sample_spectra_title, path: '' }
        ])
        this.props.setPageTitle(labels.noumena_labels.sample_spectra_title)
        document.title = `${labels.physioage_label}: ${labels.noumena_labels.sample_spectra_title}`
    }
    render() {
        return (
            <>
                <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                <div className='sample-charts container col-lg-9 spectra-section'>
                    {sample_spectra.map(spec => (
                        <React.Fragment>
                            <h1>{spec.title}</h1>
                            <div className='single-spectra-type row'>
                                {spec.ranges.map(range => (
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <h3>{range.title}</h3>
                                        <iframe src={range.src}></iframe>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </>
        )
    }
}
export default Spectra;
import {connect} from 'react-redux';
import ProviderNotesComponent from '../components/ProviderNotesComponent';

import { actionCreators } from '../modules/analyticsBeta';
const mapStateToProps = (state) => ({
    ...state.analytics
})
const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderNotesComponent);

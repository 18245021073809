import ForgotPassword from './ForgotPassword';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { resetPassword, loginInput, clearUsermail } from '../../reducers/global-reducer'

const mapStateToProps = state => ({
  ...state.global,
});

const mapDispatchToProps = {
  resetPassword, loginInput, clearUsermail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ForgotPassword));

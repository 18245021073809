import React, { Component } from 'react'
import Loader from '../../../components/Loader';

class MessageBody extends Component {
    componentDidMount() {
        if(!this.props.isAuthenticated){
            this.props.history.push('/login')
        }
        if (this.props.match.params && this.props.match.params.messageId) {
            this.props.setCurrentMessage(this.props.match.params.messageId)
        }

    }
    render() {
        return (
            <div>
                {this.props.isLoading ? <Loader /> : null}
                {this.props.messageBodyText}
            </div>
        )
    }
}
export default MessageBody
import React from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import { Typeahead } from 'react-bootstrap-typeahead';
import constants from '../../../config/constants';

class CustomPanelFieldsComponent extends React.Component {
   constructor() {
   super();
  }

  componentDidMount() {
    this.props.setDisableCustomFieldsBtn(true)
  }

  getEnumerableRange(range) {
    return range && range.length > 0 ? range.split(',').map(a => ({ range: a })) : []
  }

  render() {
    return (
      <div>
        {this.props.customFieldsError ? <div className='alert alert-danger'>
            {[...new Set(this.props.duplicate_noumenon_name)].join(",") + this.props.customFieldsError}

        </div> : null}
        <div className='bootstrap-table-container custom-field'>
            <table className='react-bootstrap-table table'>
                <thead>
                  <tr>
                    <th>{labels.custom_fields_labels.noumena}</th>
                    <th>{labels.custom_fields_labels.unites}</th>
                    <th>{labels.custom_fields_labels.reference_range}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                    {this.props.customFieldsFormValues.filter((cf)=> cf.status != constants.DELETE_STATUS).map((element, index) => (
                      <tr key={element.uuid}>
                        <td>
                            <Typeahead
                              defaultSelected= {element.noumenonName ? [element.noumenonName] : ''}
                              name={'noumenonName'}
                              placeholder="Choose a noumenon"
                              labelKey={'noumenonName'}
                              textKey={'noumenonName'}
                              id='noumenonNameTypeAhead'
                              options={this.props.noumenonNameList
                                ? this.props.noumenonNameList : []} 
                              renderMenuItemChildren={(option, props) => (
                                  <span id={option.id ? option.id : 0} key={`${option.noumenonName}`}>{`${option.noumenonName}`}</span>
                              )}
                              onChange={ e => this.props.editCostomPanelField(index, _.head(e), 'noumenonName',element.uuid )}
                              className={this.props.duplicate_noumenon_name.includes(element.noumenonName) ? 'is-invalid' : ''}
                            />
                            </td>
                        <td>
                            <Typeahead
                              defaultSelected= {element.units ? [element.units] : ''}
                              name={'units'}
                              placeholder="Choose a unit..."
                              labelKey={'units'}
                              textKey={'units'}
                              id='unitsTypeAhead'
                              options={this.props.unitList
                                ? this.props.unitList : []}
                              renderMenuItemChildren={(option, props) => (
                                  <span id={option.id ? option.id : 0} key={`${option.units}`}>{`${option.units}`}</span>
                              )}
                              onChange={ e =>  { if (e) { this.props.editCostomPanelField(index, _.head(e), 'units', element.uuid)}}}
                              disabled={ element.disableUnitAndRange }
                            />
                        </td>
                        <td>
                          {(element.valueType== "Enumerable") ?
                            <FormControl value={this.getEnumerableRange(element.enumerableRange)}
                                onChange={(e) => this.props.editCostomPanelField(index, e, 'referenceRange', element.uuid)} type='select' id='referenceRangeTypeAhead' isMulti={true}
                                getOptionLabel={opt => opt.range}
                                getOptionValue={opt => opt.range}
                                options={element.enumerableValuesList ? element.enumerableValuesList : []} /> :
                              <FormControl onBlur={(e) => this.props.editCostomPanelField(index, e, 'referenceRange', element.uuid)}  name='referenceRange'
                              placeholder='Enter reference range' type='text'  disabled={ element.disableUnitAndRange } className=' form-control' defaultValue={ element.range && Object.keys(element.range).length ? element.range : ''} />
                          }
                        </td>
                        <td>
                          <i className="fa fa-trash status-indicator text-danger trash-icon" onClick={() =>this.props.removeCustomPanelFields(element.uuid)}> </i>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <button className="btn btn-success" type="submit" onClick={ (e) => {e.preventDefault(), this.props.saveCustomPanelField(this.props.history)}} disabled={ this.props.isDisableCustomFieldsBtn} >Submit</button>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <i className="fa fa-plus status-indicator text-success trash-icon" onClick={() => this.props.addCustomPanelFields()}> </i>
                      </td>
                    </tr>
                </tbody> 
            </table>
        </div>
      </div>
    );
  }
}
export default CustomPanelFieldsComponent;
import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import { parse } from 'qs';
import { getRoundedValue } from '../../Analytics/tools/helpers'


export default class NoumenaDistribution extends Component {
    renderChart = () => {
        const noumenaName = this.params.noumena
        const noumenaData = this.props.noumenaData[noumenaName]

        // let histData = noumenaData.agesForGoogleChart.map(s => [s])
        let final = {}, pieChart = {}
        if (noumenaData != null) {
            noumenaData.gradePoints.map(nd => {
                if (final[nd]) {
                    final[nd] += 1
                } else {
                    final[nd] = 1
                }
            })
            let middleGraphData = {
                x: Object.keys(final).sort(),
                y: Object.keys(final).sort().map(s => final[s])
            }
            if (noumenaData.valueType != 'NM') {
                noumenaData.agesForGoogleChart.map(nd => {
                    if (pieChart[nd]) {
                        pieChart[nd] += 1
                    } else {
                        pieChart[nd] = 1
                    }
                })
            }
            let textBarValues = {
                x: Object.keys(pieChart),
                y: Object.values(pieChart)
            }
            let pieGraphData = Object.keys(pieChart).map(s => ({ name: s, y: pieChart[s] }))
            //vadr data
            let vadrDataHistPresentor = noumenaData.agesForGoogleChart ? _.compact(noumenaData.agesForGoogleChart) : null
            let vadrHist = {}
            let prevValue = _.min(vadrDataHistPresentor)
            let range = (_.max(vadrDataHistPresentor) - prevValue) / 10
            for (let i = 1; i <= 10; i++) {
                var maxValue = prevValue + range
                if (i == 10)
                    maxValue = prevValue + range + 1
                vadrHist[`${(prevValue + maxValue) / 2}`] = vadrDataHistPresentor.filter(p => prevValue <= p && p < maxValue)
                prevValue = maxValue

            }
            let histX = Object.keys(vadrHist).map(p => getRoundedValue(p, noumenaData.scale))
            // histX[histX.length-1] = histX[histX.length-1]-0.5
            let vadrHistogramData = {
                x: histX,
                y: Object.values(vadrHist).map(s => s.length)
            }
            // let zeroCount = vadrHistogramData.y.filter(s => s == 0).length
            let minAge = _.head(noumenaData.age.sort())
            let maxAge = _.last(noumenaData.age.sort())

            if (noumenaData.agesForGoogleChart.filter(s => s).length == 0) {
                return (<div className='jumbotron'>
                    <h1>{labels.noumena_labels.no_values_to_plot}</h1>
                </div>)
            }
            else if (this.params.chart === 'cloud.svg') {
                return (<React.Fragment>
                    {_.head(noumenaData.age) == 0 && noumenaData.valueType == 'NM' ? '' :
                        <React.Fragment>
                            {noumenaData.valueType != 'NM' ? <ReactHighcharts config={this.getPieChartConfig(pieGraphData)} ></ReactHighcharts> :
                                _.head(noumenaData.age) != 0 ? <ReactHighcharts
                                    config={this.scatterConfig(minAge, maxAge, noumenaData.male, noumenaData.female, this.props.history)}>
                                </ReactHighcharts> : ''}
                        </React.Fragment>}
                </React.Fragment>)
            } else if (this.params.chart === 'histogram.svg') {
                return (<ReactHighcharts config={this.getColumnChartConfig(noumenaData.valueType != 'NM' ? textBarValues : vadrHistogramData)}>
                </ReactHighcharts>)
            } else {
                return (
                    <React.Fragment>
                        {_.head(middleGraphData.x) != "N/A" ?
                            <ReactHighcharts config={this.getColumnChartConfig(middleGraphData)}>
                            </ReactHighcharts> : ''}
                    </React.Fragment>)
            }
        }
        else {
            return <center><h1>No results</h1></center>
        }
    }

    componentDidMount() {
        const allowedChartTypes = ['cloud.svg', 'histogram.svg', 'points_histogram.svg']
        this.params = this.props.match.params
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true)

        if (this.params && this.params.noumena && allowedChartTypes.indexOf(this.params.chart) != -1) {
            if (!this.props.noumenaData || !this.props.noumenaData[this.params.noumena]
                || this.urlParams.max_deviations !== undefined) {
                this.props.updateNoumenonNameValues(this.params.noumena, this.urlParams.max_deviations,this.urlParams.gender, constants.preview, this.props.history)
            }
        } else {
            this.props.history.push(`/statuscode/404`)
        }
    }

    chartsConfig = {
        minPadding: 50,
        maxPadding: 50,
        // height: '100%'
        height: window.screen.height - 150
    }

    scatterConfig(minAge, maxAge, maleData, femaleData, history) {
        return {
            chart: {
                type: 'scatter',
                animation: false,
                ...this.chartsConfig
            },
            yAxis: {
                title: {
                    enabled: false,
                }
            },
            xAxis: {
                tickPositions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                minTickInterval: 10,
                startOnTick: true,
                endOnTick: true
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: '{point.x}, {point.y}'
                    }
                },
                series: {
                    animation: false,
                    cursor: 'pointer',
                    turboThreshold:0,
                    events: {
                        click: function (event) {
                            // history.push(`/visits/${event.point.visitId}`)
                            window.open(window.location.origin + '/visits/' + event.point.visitId, '_blank').focus()
                        }
                    }
                }
            },
            series: [{
                name: labels.noumena_labels.female,
                color: '#f99deb',
                data: femaleData,
                animation: false,
                marker: {
                    symbol: 'circle',
                }
            }, {
                name: labels.noumena_labels.male,
                color: '#008FBC',
                data: maleData
            }]
        }
    }

    generateXaxisRange(minAge, maxAge){
        let xaxisRange = [];
        for(let value = minAge; value < maxAge; value=value+5){
            if(value < maxAge && maxAge - value >= 3){
                xaxisRange.push(value);
            }
        }
        xaxisRange.push(maxAge);
        return xaxisRange;
    }

    getPieChartConfig(data) {
        const config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                animation: false,
                ...this.chartsConfig
            },
            yAxis: {
                title: {
                    enabled: false,
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '{point.percentage:.1f} %'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                        format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>total: {point.total}',
                    }
                },
                series: {
                    animation: false
                }
            },
            series: [{
                colorByPoint: true,
                data: data
            }]
        }
        return config;
    }

    getColumnChartConfig(data) {
        for(let i = 0; i < data.x.length - 1; i++){
            for(let j=0; j < data.x.length - i - 1; j++){
                if(data.x[j] > data.x[j+1]){
                    //Sort data.x
                    let temp1 = data.x[j];
                    data.x[j] = data.x[j+1];
                    data.x[j+1] = temp1;
                   
                    //Sort data.y
                    let temp2 = data.y[j];
                    data.y[j] = data.y[j+1];
                    data.y[j+1] = temp2;
                }
            }
        }
        const config = {
            chart: {
                type: 'column',
                animation: false,
                ...this.chartsConfig
            },
            tooltip: {
                formatter: function () {
                    return 'The count for <b>' + this.x +
                        '</b> is <b>' + this.y + '</b>';
                }
            },
            yAxis: {
                title: {
                    enabled: false,
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: data.x
            },

            plotOptions: {
                series: {
                    animation: false,
                    pointWidth: 180 / data.x.length,
                    dataLabels: {
                        enabled: true
                    },
                    animation: false
                }
            },

            series: [{
                data: data.y,
                color: '#000000'
            }]
        }
        return config;
    }

    render() {
        return (
            <div>
                { this.params && this.props.noumenaData
                    ? this.renderChart()
                    : <h2 className="text-center">Loading...</h2>}
            </div>
        )
    }
}
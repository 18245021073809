import React, { Component } from 'react';

import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import userIcon from '../../../assets/images/user_avtar.png';
import constants from '../../../config/constants';
import PageTitle from '../../../components/PageTitle';
import Select, { components } from 'react-select';

const MultiValueRemove = (props) => {
    if (!props.data.removable) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
};
class ProviderForm extends Component {
    componentDidMount() {
        this.props.getSitesList()
        this.props.toggleNotification(false)
        if (this.props.match.params && this.props.match.params.providerId) {
            this.props.setCurrentProvider(this.props.match.params.providerId)
        } else {
            document.title = `${labels.physioage_label}: ${labels.provider_labels.add_provider}`
            this.props.setPageTitle(labels.provider_labels.add_provider)
            this.props.setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.provider_labels.add_provider, path: '' }
            ])

        }
        if (this.providersForm && this.providersForm.inputEntry)
            this.providersForm.inputEntry.focus()
    }
    render() {
        const { sitesList } = this.props;
        const options = sitesList ? 
          sitesList.filter(site => site.billingStrategy == 'credits').map(site => ({
            id: site.id,
            name: site.name,
            removable: true
          })) : [];
          let isClearable =  this.props.currentProvider && this.props.currentProvider.selectedsites ? this.props.currentProvider.selectedsites.some(option => option.removable === false) ? false : true : false;
        return (
            <>
                <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>

                <form className='provider-form container col-lg-9' onSubmit={(e) => { e.preventDefault(); this.props.submitProvider(this.props.history) }} >
                    <div className='col-lg-12 row'>
                        <div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
                            <FormControl ref={(pd) => this.providersForm = pd} value={this.props.currentProvider.honorificPrefix} type='select' id='honorificPrefix' options={constants.honorific_prefix} label={labels.provider_labels.honorific_prefix} onChange={this.props.editProvider} textKey={'value'} valueKey={'value'} />
                            <FormControl value={this.props.currentProvider.firstname} required={true} id='firstname' type='text' label={labels.provider_labels.firstname} onChange={this.props.editProvider} />
                            <FormControl value={this.props.currentProvider.lastname} required={true} id='lastname' type='text' label={labels.provider_labels.lastname} onChange={this.props.editProvider} />
                            <FormControl value={this.props.currentProvider.honorificSuffix} type='text' id='honorificSuffix' label={labels.provider_labels.honorific_suffix} help={labels.provider_labels.honorific_suffix_help} onChange={this.props.editProvider} />
                        </div>
                        <div className='col-lg-2 col-md-12 col-sm-12 col-xs-12 profile-pic-section'>
                            <div className='image-upload'>
                                <label for='fileInput' className='image-input'>
                                    <img alt={this.props.currentProvider ? `${this.props.currentProvider.firstname}${labels.provider_labels.avatar}` : `${labels.provider_labels.provider_name.toLowerCase()}${labels.provider_labels.avatar}`}
                                        src={this.props.currentProvider && this.props.currentProvider.avatarContent ? `data:${this.props.currentProvider.avatarContentType};base64,${this.props.currentProvider.avatarContent}` : userIcon} />

                                </label>
                                {this.props.currentProvider && this.props.currentProvider.avatarContent ? <span>
                                    <i className='fa fa-times-circle danger' title='Remove picture' onClick={(e) => this.props.removePic(e)}>{labels.provider_labels.remove_pic}</i>
                                </span> : null}
                                <input id='fileInput' type='file' onChange={(e) => this.props.picUploaded(e)} accept='image/*' />
                                <p className='help-block'>{labels.provider_labels.profile_pic}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12 row'>
                        <div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
                            <div className='sites-section'>
                                <label><abbr title='required'>*</abbr>{labels.provider_labels.sites_label}</label>
                                <FormControl type='select'
                                    value={this.props.currentProvider.selectedsites}
                                    id='sites' isMulti={true} 
                                    getOptionLabel={opt => opt.name}
                                    getOptionValue={opt => opt.id} 
                                    options={options}
                                    onChange={(e) => this.props.editProvider(e, 'selectedsites')} 
                                    components={{ MultiValueRemove }}
                                    isClearable={isClearable} />
                            </div>
                            { this.props.currentProvider && this.props.currentProvider.id ?
                               <>
                                <div className='mb-3'>
                                    <FormControl checked={ this.props.currentProvider.deactivate && this.props.currentProvider.deactivate ? true : false}
                                    type='checkbox' id='deactivate' label={labels.provider_labels.deactivate} onChange={this.props.editProvider} disabled = { this.props.availableProvidersList && this.props.availableProvidersList.length > 0 ? false : true } help= {this.props.availableProvidersList && this.props.availableProvidersList.length > 0 ? "" : labels.provider_labels.provider_not_availale}  />
                                </div>
                                { this.props.isDeactivated ?
                                    <FormControl ref={(pd) => this.providersForm = pd}
                                    value={this.props.currentProvider.assignedTo}
                                    type='select' id='assignedTo'
                                    options={this.props.availableProvidersList}
                                    label={labels.provider_labels.available_providers}
                                    required={this.props.currentProvider.deactivate ? true : false}
                                    onChange={this.props.editProvider} textKey={'name'} valueKey={'id'} />
                                : ""}
                                </>
                            : ""
                            }
                            <div className='create-provider-btn row justify-center'>
                                <FormControl type='button' btnType={constants.notification_type.success}
                                    text={this.props.currentProvider && this.props.currentProvider.id
                                        ? labels.provider_labels.update_provider : labels.provider_labels.create_provider} />
                            </div>
                        </div>    
                    </div>    
                </form>

            </>
        )
    }
}
export default ProviderForm
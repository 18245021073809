window.onscroll = function () {
  var nav = document.getElementById('site-logo-top-nav');
  var leftNav = document.getElementById('left-side-bar');
  var topNav = document.getElementById('topNav');
  var x = window.pageYOffset;
  if (nav && leftNav && topNav) {
    if (x > 550) {
      topNav.style.boxShadow = '0 1px 3px rgba(51, 51, 51, 0.12), 0 1px 2px rgba(51, 51, 51, 0.24)';
      nav.style.display = 'block';
      nav.classList.add('fade-in-element');
      leftNav.style.display = 'block';
      leftNav.classList.add('fade-in-element-sidebar');
    }
    else {
      topNav.style.boxShadow = 'none';
      nav.style.display = 'none';
      leftNav.style.display = 'none';
    }
  }
};
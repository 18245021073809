export default [
    {
        title: "Baseline, One Age",
        src: "//artisan.physioage.com/aggregate.svg?data=n:PulmoAge^v:39.0^b:53.200"
    },
    {
        title: "Baseline, Two Ages",
        src: "//artisan.physioage.com/aggregate.svg?data=n:PulmoAge^v:39.0^b:53.200|n:CutoAge^v:29.8^b:53.200"
    }
    , {
        title: "Baseline, Three Ages",
        src: "//artisan.physioage.com/aggregate.svg?data=n:CardioAge^v:57.4^b:44.200|n:PulmoAge^v:43.8^b:44.200|n:CutoAge^v:56.3^b:44.200"
    }
    , {

        src: "//artisan.physioage.com/aggregate.svg?data=n:CardioAge^v:24^b:23.500|n:PulmoAge^v:20^b:23.500|n:CutoAge^v:20^b:23.500"
    }
    , {
        title: "Baseline, All Ages",
        src: "//artisan.physioage.com/aggregate.svg?data=n:PhysioAge^v:42^b:41.800|n:CardioAge^v:20.0^b:41.800|n:PulmoAge^v:34.6^b:41.800|n:NeuroAge^v:24.6^b:41.800|n:CutoAge^v:37.1^b:41.800|n:ImmunoAge^v:61.5^b:41.800|n:TelomerAge^v:60.5^b:41.800"
    }
    , {
        title: "FU1, Two Ages",
        src: "//artisan.physioage.com/aggregate.svg?data=n:ImmunoAge^v:61.5,58.4^b:41.800,42.800|n:TelomerAge^v:60.5,58.2^b:41.800,42.800"
    }
    , {
        title: "FU3, Two Ages",
        src: "//artisan.physioage.com/aggregate.svg?data=n:ImmunoAge^v:61.5,58.4,67.2,58.6^b:41.800,42.800,43.100,44.800|n:TelomerAge^v:60.5,58.2,56.6,59.0^b:41.800,42.800,43.100,44.800"
    }
    , {
        title: "FU3, all ages",
        src: "//artisan.physioage.com/aggregate.svg?data=n:PhysioAge^v:42,,,37^b:41.800,42.800,43.100,44.800|n:CardioAge^v:20.0,,,20.0^b:41.800,42.800,43.100,44.800|n:PulmoAge^v:34.6,,,20.0^b:41.800,42.800,43.100,44.800|n:NeuroAge^v:24.6,,,^b:41.800,42.800,43.100,44.800|n:CutoAge^v:37.1,,,34.1^b:41.800,42.800,43.100,44.800|n:ImmunoAge^v:61.5,58.4,67.2,58.6^b:41.800,42.800,43.100,44.800|n:TelomerAge^v:60.5,58.2,56.6,59.0^b:41.800,42.800,43.100,44.800"
    }
    , {
        title: "FU3, some missing from",
        visit: { text: "Visit 731", path: "" },
        src: "//artisan.physioage.com/aggregate.svg?data=n:CardioAge^v:,,,35.0^b:69.100,69.800,70.000,70.500|n:NeuroAge^v:,,,77.0^b:69.100,69.800,70.000,70.500|n:ImmunoAge^v:62.5,67.1,,60.4^b:69.100,69.800,70.000,70.500|n:TelomerAge^v:70.6,69.0,69.0,56.6^b:69.100,69.800,70.000,70.500"
    }
    , {
        title: "FU4, lots of missing data",
        visit: { text: "Visit 2045", path: "" },
        src: "//artisan.physioage.com/aggregate.svg?data=n:CardioAge^v:35.0,,,,64.0^b:69.100,69.800,70.000,70.500,71.700|n:PulmoAge^v:,,,,56.6^b:69.100,69.800,70.000,70.500,71.700|n:NeuroAge^v:,,,77.0,^b:69.100,69.800,70.000,70.500,71.700|n:CutoAge^v:,,,,69.5^b:69.100,69.800,70.000,70.500,71.700|n:ImmunoAge^v:62.5,67.1,60.4,,^b:69.100,69.800,70.000,70.500,71.700|n:TelomerAge^v:70.6,69.0,69.0,56.6,^b:69.100,69.800,70.000,70.500,71.700"
    }
    , {
        title: "FU4, four ages",
        visit: { text: "Visit 320", path: "" },
        src: "//artisan.physioage.com/aggregate.svg?data=n:PhysioAge^v:40,36,41,39,38^b:49.700,50.000,50.300,50.500,50.800|n:CardioAge^v:47,27,47,47,35^b:49.700,50.000,50.300,50.500,50.800|n:PulmoAge^v:20,20,20,20,20^b:49.700,50.000,50.300,50.500,50.800|n:TelomerAge^v:64,66,66,64,66^b:49.700,50.000,50.300,50.500,50.800"
    }
    , {
        title: "FU5, one age",
        visit: { text: "Visit 4640", path: "" },
        src: "//artisan.physioage.com/aggregate.svg?data=n:ImmunoAge^v:55.8,57.8,62.0,62.3,64.5^b:53.600,53.900,54.200,54.500,54.800"
    }
    , {
        title: "FU8",
        visit: { text: "Visit 6683", path: "" },
        src: "//artisan.physioage.com/aggregate.svg?data=n:PhysioAge^v:82,74,,,,,,,^b:88,88,88,88,88,88,88,89,89|n:CardioAge^v:90,90,,,,,,,^b:88,88,88,88,88,88,88,89,89|n:PulmoAge^v:84,80,,,,,,,^b:88,88,88,88,88,88,88,89,89|n:NeuroAge^v:83,78,,,,,,,^b:88,88,88,88,88,88,88,89,89|n:CutoAge^v:77,59,,,,,,,^b:88,88,88,88,88,88,88,89,89|n:ImmunoAge^v:81,81,78,,,,,,78^b:88,88,88,88,88,88,88,89,89|n:TelomerAge^v:76,76,70,,,,,,70^b:88,88,88,88,88,88,88,89,89"
    }

    , {
        title: "FU9",
        visit: { text: "Visit 5173", path: "" },
        src: "//artisan.physioage.com/aggregate.svg?data=n:PhysioAge^v:40,,,,,,,,,^b:50.100,50.700,51.000,51.400,52.500,52.600,52.800,52.900,53.100,53.300|n:CardioAge^v:35,,,,,,,,,^b:50.100,50.700,51.000,51.400,52.500,52.600,52.800,52.900,53.100,53.300|n:PulmoAge^v:49,,,,,,,,,^b:50.100,50.700,51.000,51.400,52.500,52.600,52.800,52.900,53.100,53.300|n:NeuroAge^v:39,,,,,,,,,^b:50.100,50.700,51.000,51.400,52.500,52.600,52.800,52.900,53.100,53.300|n:CutoAge^v:46,,,,,,,,,^b:50.100,50.700,51.000,51.400,52.500,52.600,52.800,52.900,53.100,53.300|n:ImmunoAge^v:,59,50,,,,,,,^b:50.100,50.700,51.000,51.400,52.500,52.600,52.800,52.900,53.100,53.300|n:TelomerAge^v:,57,60,,,,,,,^b:50.100,50.700,51.000,51.400,52.500,52.600,52.800,52.900,53.100,53.300"
    }

]
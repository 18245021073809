import React from 'react';
import sprite from '../../../assets/images/sprite_base.svg'
export default class ProviderAvatarComponent extends React.Component {
  render() {
    return (
      this.props.currentProvider && this.props.currentProvider.avatarContent != null ?
        <img className={this.props.role != "patient" ? 'provider-photo' : 'patient-view-rec'} src={this.props.currentProvider.avatarContent != null
          ? `data:${this.props.currentProvider.avatarContentType};base64,${this.props.currentProvider.avatarContent}`
          : 'no image'} /> : <svg className={this.props.role != "patient" ? 'provider-photo provider-icon' : 'patient-view-rec provider-icon'} aria-hidden='true' title='provider'>
          {/* <use xlinkHref={`${sprite}#female`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
          <use xlinkHref="#female" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
          <symbol id="female" viewBox="0 0 48 48">
            <g class="nc-icon-wrapper"><path fill-rule="evenodd" clip-rule="evenodd" fill="#C6A279" d="M16.99757,31C13.6852036,31,11,28.3252907,11,25V14 c0-7.1800003,5.8199997-13,13-13s13,5.8199997,13,13v11c0,3.3155499-2.6845322,6-5.99757,6H16.99757z"></path> <rect x="18" y="23" fill-rule="evenodd" clip-rule="evenodd" fill="#EAC3A2" width="12" height="17"></rect> <path fill-rule="evenodd" clip-rule="evenodd" fill="#E0E0E0" d="M40.6829987,38.3419991L30,33l-6,6l-5.9820004-6 L7.3169999,38.3419991C5.2839999,39.3580017,4,41.4360008,4,43.7080002v2.2864037C4,46.549778,4.4492188,47,5.0087748,47h37.9824448 C43.5483589,47,44,46.5560265,44,45.9944038v-2.2864037C44,41.4360008,42.7159996,39.3580017,40.6829987,38.3419991z"></path> <path fill-rule="evenodd" clip-rule="evenodd" fill="#F1D9C5" d="M13,16v2c0,6.0650005,4.9350014,11,11,11s11-4.9349995,11-11v-2 c0-1.6568508-1.3431473-3-3-3c-3.4829292,0-6.5037708-1.9784393-8-4.8727989C22.5037708,11.0215607,19.4829292,13,16,13 C14.3431501,13,13,14.3431492,13,16z"></path></g>
          </symbol>
        </svg>
    )
  }
}


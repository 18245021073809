import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { parse } from 'qs';
import ReactPaginate from 'react-paginate';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import './message-routing-styles.scss';
import messages from '../../../config/messages';
import LoadingTemplate from '../../../components/LoadingTemplate';
import constants from '../../../config/constants';



class MessageRoutingList extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.props.setInitialLoading(true);
        this.urlParams = parse(queryString, true);
        this.props.clearSearch(this.urlParams, this.props.history)
        this.props.getSites()
        this.props.getPanelList()
        if (this.urlParams && this.urlParams.page)
            this.props.messagePageChange(parseInt(this.urlParams.page), this.props.history, this.urlParams, true)
        else
            this.props.resetMessageRouting()

        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.message_routing_labels.list_title, path: '' }
        ])
        document.title = `${labels.physioage_label}:${labels.message_routing_labels.list_title}`
        this.props.setPageTitle(labels.message_routing_labels.list_title)

        this.unlisten = this.props.history.listen((location, action) => {
            if (location && location.search && this.props.location
                && action == 'POP') {
                const queryString = location && location.search && location.search.substr(1)
                this.urlParams = parse(queryString, true);
                this.props.messagePageChange(parseInt(this.urlParams.page, false, this.urlParams, true))
            }
        })
    }
    componentWillUnmount() {
        this.props.toggleNotification(false)
        this.unlisten()
    }
    componentWillReceiveProps(newProps) {
        if ((this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search)) {
            this.props.resetMessageRouting()
        }
    }
    getCaret = (order, field) => {
        order = this.props.searchParam.sortOrder ? constants.sorting_order_decode[this.props.searchParam.sortOrder] : order
        if (this.props.searchParam) {
            // if (!this.props.searchParam.sortField && field != 'lastActiveDate') {
            //     return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>)
            // }
            if (!order) return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else if (order === 'asc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-up'></i></span>);
            else if (order === 'desc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
        }
        return null;
    }
    render() {
        const columns = [
            {
                dataField: 'sendingApplication',
                text: 'Sending Application',
                headerStyle: { width: '15%' },
                sort: true,
                sortCaret: (order, column) => this.getCaret(order, 'SendingApplication')
            },
            {
                dataField: 'sendingFacility', text: 'Sending Facility', headerStyle: { width: '15%' }, sort: true, sortCaret: (order, column) => this.getCaret(order, 'SendingFacility')
            },
            { dataField: 'receivingApplication', text: 'Receiving Application', headerStyle: { width: '15%' }, sort: true, sortCaret: (order, column) => this.getCaret(order, 'ReceivingApplication') },
            { dataField: 'receivingFacility', text: 'Receiving Facility', headerStyle: { width: '11%' }, sort: true, sortCaret: (order, column) => this.getCaret(order, 'ReceivingFacility') },
            { dataField: 'pid18_1', text: 'PID18.1', headerStyle: { width: '11%' }, sort: true, sortCaret: (order, column) => this.getCaret(order, 'Pid18_1') },
            { dataField: 'site', text: 'Site', headerStyle: { width: '14%' }, sort: true, sortCaret: (order, column) => this.getCaret(order, 'Site') },
            { dataField: 'panel', text: 'Panel', headerStyle: { width: '10%' }, sort: true, sortCaret: (order, column) => this.getCaret(order, 'Panel'), formatter: (cellContent, row) => (<span>{row.panel != "0" ? row.panel : ''}</span>) },
            {  headerStyle: { width: '4%' },
                formatter: (cellContent, row) => (
                   <i className='fa fa-pencil' onClick={() => this.props.history.push(`/messagerouting/edit/${row.id}`)}></i>
                 )
            },
            {   headerStyle: { width: '5%' },
                formatter: (cellContent, row) => (
                    <i class="fa fa-copy copy-btn-routing" title='Copy' onClick={() => this.props.copyMessageRoute(row)}></i>
                )
            },
        ]

        const paginationOptions = paginationFactory({
            page: this.props.searchParam ? this.props.searchParam.pageNumber : 1,
            pageSize: this.props.searchParam ? this.props.searchParam.pageSize : 10,
            onPageChange: (page) => {
                this.props.messageRoutigsPageChange(page, false, this.props.history);
                this.props.history.push(`?page=${page}`)
            },
            totalSize: this.props.totalCount,
            alwaysShowAllBtns: true,
            prePageText: 'Prev',
            nextPageText: 'Next',
            withFirstAndLast: false,
            hideSizePerPage: true,
        })

        const onTableChange = (type, { sortField, sortOrder }) => {
            if (type == 'sort') {
                let sortfield;
                switch (sortField) {
                    case 'sendingApplication': sortfield = 'SendingApplication';
                        break;
                    case 'sendingFacility': sortfield = 'SendingFacility'
                        break;
                    case 'receivingApplication': sortfield = 'ReceivingApplication'
                        break;
                    case 'receivingFacility': sortfield = 'ReceivingFacility'
                        break;
                    case 'pid18_1': sortfield = 'Pid18_1'
                        break;
                    case 'site': sortfield = 'Site'
                        break;
                    case 'panel': sortfield = 'Panel'
                        break;
                    default: sortfield = sortField
                }
                this.props.sortMessageRoutingList(sortfield, sortOrder, this.props.history, this.urlParams && this.urlParams.page ? this.urlParams.page : 1)
            }
        }

        return (
            this.props.isLoading && this.props.initialLoading ? <>
                <LoadingTemplate haveSidebar={true} isGrid={true} />
            </> :
                <>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                        <div className='card patients-filter'>
                            <div className='card-header'>
                                {labels.message_routing_labels.filter_routing_rules}
                            </div>
                            <form className='card-body' onSubmit={(e) => { e.preventDefault(); this.props.patientsPageChange(1, true); this.props.getPatients(this.props.history) }}>
                                    <FormControl
                                        id='siteId' label={labels.patient_list_labels.site_label}
                                        type='select' onChange={(e) => this.props.setSearchParam(e.target.value, e.target.id, false, this.props.history)}
                                        defaultOption={labels.patient_list_labels.all_sites}
                                        value={this.props.currentSearchFilters && this.props.currentSearchFilters.siteId ? parseInt(this.props.currentSearchFilters.siteId) : 0}
                                        disabled={!this.props.sites || !this.props.sites.length}
                                        options={this.props.sites ? this.props.sites : []} textKey={'name'} valueKey={'id'} />
                                    <FormControl
                                        id='panelName' label={labels.message_routing_labels.panel_label}
                                        type='select' onChange={(e) => this.props.setSearchParam(e.target.value, e.target.id, false, this.props.history)}
                                        defaultOption={labels.message_routing_labels.all_panels}
                                        value={this.props.currentSearchFilters && this.props.currentSearchFilters.panelName ? this.props.currentSearchFilters.panelName : 0}
                                        disabled={!this.props.panelList || !this.props.panelList.length}
                                        options={this.props.panelList ? this.props.panelList : []} textKey={'name'} valueKey={'name'} />
                            </form>
                        </div>
                        <div className='add-rule-btn-sec'>
                            <FormControl
                                type='button'
                                btnType='primary'
                                text={labels.message_routing_labels.add_rule}
                                onClick={() => this.props.addRule(this.props.history)} />
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0 rounded message-routing-table'>
                        {this.props.totalMessageRouting && this.props.totalMessageRouting.length ?
                            <>
                                <div className='bootstrap-table-container'>
                                    <BootstrapTable
                                        remote
                                        striped={false}
                                        bordered={false}
                                        hover={true}
                                        bootstrap4
                                        keyField='id'
                                        data={this.props.totalMessageRouting}
                                        columns={columns}
                                        onTableChange={onTableChange}
                                        pagination={false}
                                    />
                                </div>
                                {this.props.totalCount < 10 ? false :
                                    <div id='react-paginate' className='mt-3'>
                                        <ReactPaginate
                                            previousLabel={'Prev'}
                                            nextLabel={'Next'}
                                            breakLabel={'...'}
                                            previousClassName='page-item'
                                            nextClassName='page-item'
                                            breakClassName='page-item disabled'
                                            breakLinkClassName='page-link'
                                            pageClassName='page-item'
                                            pageLinkClassName='page-link'
                                            previousLinkClassName='page-link'
                                            nextLinkClassName='page-link'
                                            activeClassName='active'
                                            pageCount={Math.ceil(this.props.totalCount / 10)}
                                            marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                            pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                            disableInitialCallback={true}
                                            initialPage={this.props.searchParam ? this.props.searchParam.pageNumber - 1 : 0}
                                            onPageChange={(page) => {
                                                this.props.messagePageChange(Math.ceil(page.selected) + 1, this.props.history);
                                                //this.props.history.push(`?page=${Math.ceil(page.selected) + 1}`)
                                            }}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                        />
                                    </div>}
                            </> : this.props.no_grid_data ?
                                <div className='jumbotron'>
                                    <h3>{labels.no_grid_data}</h3>
                                </div> : null}
                    </div>
                </>
        )
    }
}
export default MessageRoutingList
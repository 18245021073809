import { connect } from 'react-redux'
import Users from '../components/Users';
import { actionCreators } from '../modules/user'
import { setBreadCrumbs, setPageTitle, toggleNotification, setLoggedInUser, navigatePatients, setCreditsCaution } from '../../../reducers/global-reducer';

const mapStateToProps = (state) => ({
    ...state.users,
    isLoading: state.global.isLoading,
    isParentContainer: state.global.isParentContainer,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify,
    loggedInUser: state.global.loggedInUser,
})

const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    setLoggedInUser,
    navigatePatients,
    setCreditsCaution
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
import {connect} from 'react-redux';
import DatumRowComponent from '../components/DatumRowComponent';
import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
    ...state.analytics
})
const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(DatumRowComponent);

import React, { Component } from 'react'
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

import './toaster-styles.scss'

class Toaster extends Component {
    render() {
        return (
            <>
                <div className={`toaster bg-danger my-2 rounded ${!this.props.isToasterActive || localStorage.getItem("displayToaster") == 'no' ? 'toaster-left fade' : 'toaster-right'}`}>
                    <Toast>
                        <ToastHeader toggle={() => this.props.onClose(false)}>
                            <i class="fa fa-bell danger" aria-hidden="true"></i>  Message Failures
                        </ToastHeader>
                        <ToastBody>
                            <p>Oops!! There are {this.props.routeFailureCount} un-resolved message {this.props.routeFailureCount > 1 ? 'failures' : 'failure'}</p>
                            <div className='close-toaster-menu'>
                                <span>
                                    <a onClick={() => this.props.toggleToaster(false, '/messages/failuremessages', this.props.history)}>View messages <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </a>
                                </span>
                                <span>
                                    <a onClick={() => this.props.ignoreToaster()} title="Ignoring toaster will remove the toaster for entire session">Ignore <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
                                </span>
                            </div>
                        </ToastBody>
                    </Toast>
                </div>
            </>
        )
    }
}
export default Toaster
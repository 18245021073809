import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import constants from '../../../config/constants';
import { Link } from 'react-router-dom';
import FormControl from '../../../components/FormControls/FormControl';
const openbleFiles = ['pdf', 'jpg', 'png', 'jpeg']

class CommonDocuments extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.visitId) {
            this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history, false, "en", true)
            this.props.setHeaderType(constants.visit_labels.common_documents)
        }
    }

    render() {
        const columns = [

            {
                dataField: 'format', text: 'Format', formatter: (cellcontent, row) => row.format == 'pdf' ?
                    <span><i class="fa fa-file-pdf-o "></i> {row.format}</span> : <span><i class="fa fa-file-o "></i> {row.format}</span>
            },
            {
                dataField: 'title', text: 'Title',
                headerStyle: { width: '45%' }
            },
            {
                dataField: '', text: '',
                formatter: (cellcontent, row) =>
                    row.distributedVisitIds && row.distributedVisitIds.length && row.distributedVisitIds.indexOf(this.props.currentVisit.id) >= 0 ?
                        <i>{constants.visit_labels.already_attached_label}</i> :
                        <FormControl type='button' text='Attach' btnType='primary btn-sm'
                            onClick={() => this.props.AttachDocument(row.documentId, this.props.history)} />
            },
            {
                dataField: '', text: '',
                formatter: (cellcontent, row) => {
                    if (row.format && openbleFiles.indexOf(row.format.toLowerCase()) != -1) {
                        return <Link to={`/documents/${row.documentId}.${row.format}`} target='_blank' ><FormControl type='button' text='View' btnType='info btn-sm' ></FormControl></Link>
                    } else {
                        return <FormControl type='button' text='View' btnType='info btn-sm' onClick={() => this.props.downloadDocument(row, this.props.history)} />
                    }
                }
            }

        ]
        return (
            this.props.common_documents ?
                // <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 right-section p-0 order-1'>
                <div className='common-documents w-auto'>
                    <div className='bootstrap-table-container'>
                        <BootstrapTable striped={true} bordered={false} hover={true} bootstrap4 keyField='id'
                            data={this.props.common_documents && this.props.common_documents.length ? this.props.common_documents : []} columns={columns}
                        />
                    </div>
                    {/* </div> */}
                </div> : ''
        )
    }
}
export default CommonDocuments
class Recommendation {
  constructor(object = {}) {
    this.id = object.id || null
    this.group = object.group || 'Further Testing'
    this.body = object.body || ''
    this.instructions = object.instructions || ''
    this.notes = object.notes || ''
    this.indicators = object.indicators || []
    this.providerName = "Mickey Mouse"
  }
}

let recommendation_list = [
  "7-Keto-DHEA: 200 mg",
  "Abilify",
  "Accupril",
  "Aciphex",
  "Actonel",
  "Actos",
  "Acyclovir",
  "Adalimumab",
  "Adderall",
  "Adipex P",
  "Advil",
  "Albuterol",
  "Aldactone",
  "Alendronate",
  "Aleve",
  "Allopurinol",
  "Alprazolam",
  "Altace",
  "Amaryl",
  "Ambien",
  "Amitriptyline",
  "Amlodipine",
  "Amoxicillin",
  "Amoxicillin + Clavulanate",
  "Amoxil",
  "Amphetamine",
  "Anastrozole: 0.25 mg",
  "Anastrozole: 0.5 mg",
  "AndroGel",
  "Apresoline",
  "Aricept",
  "Aripiprazole",
  "Armour Thyroid: 1 grain tablet",
  "Atenolol",
  "Ativan",
  "Atomoxetine",
  "Atorvastatin",
  "Augmentin",
  "Avapro",
  "Avodart",
  "Azithromycin",
  "Baclofen",
  "Bactroban",
  "Benazepril",
  "Bentyl",
  "Benzonatate",
  "Benztropine",
  "Biaxin",
  "Biest(Estriol/Estradiol): 0.75mg/8.25mg/ml Vanishing cream",
  "Biest(Estriol/Estradiol): 2mg/0.5mg/ml vaginal cream",
  "Brilinta",
  "Budesonide",
  "Buprenorphine",
  "Bupropion",
  "Buspar",
  "Buspirone",
  "Bystolic",
  "Caltrate",
  "Cardizem",
  "Cardura",
  "Carisoprodol",
  "Carvedilol",
  "Catapres",
  "Cefdinir",
  "Ceftin",
  "Cefuroxime",
  "Celebrex",
  "Celecoxib",
  "Celexa",
  "Cephalexin",
  "Cetirizine",
  "Cialis",
  "Cialis: 20 mg tablet",
  "Cialis: 5 mg tablet",
  "Cipro",
  "Ciprofloxacin",
  "Citalopram",
  "Clarithromycin",
  "Claritin",
  "Cleocin",
  "Clindamycin",
  "Clobetasol",
  "Clonazepam",
  "Clonidine",
  "Clopidogrel",
  "Clovate",
  "Codeine/APAP",
  "Cogentin",
  "Concerta",
  "CoQ10 (PA28): 100 mg",
  "Coreg",
  "Coumadin",
  "Cozaar",
  "Crestor",
  "Cyanocobalamin (APP): 1,000 mcg/ml injectable",
  "Cyclobenzaprine",
  "Cymbalta",
  "Dabigatran",
  "Deltasone",
  "Depakote",
  "Desvenlafaxine",
  "Desyrel",
  "Detrol",
  "Dexilant",
  "Dexlansoprazole",
  "Dexmethylphenidate",
  "Dextro-amphetamine",
  "DHEA: 20 mg",
  "DHEA: 30 mg",
  "DHEA/Estradiol/Testosterone: 25mg/9mg/1.5mg/ml lipo cream",
  "DHEA/Estriol: 5mg/1mg/gm vaginal cream",
  "DHEA (PA): 10 mg",
  "DHEA (PA): 15 mg",
  "DHEA (PA): 25 mg",
  "DHEA (PA): 5 mg",
  "DHEA/Pregnenolone: 10mg/25mg",
  "DHEA/Pregnenolone: 20mg/25mg",
  "DHEA/Pregnenolone: 25mg/25mg",
  "Diabeta",
  "Diazepam",
  "Diclofenac",
  "Dicyclomine",
  "Diflucan",
  "Digoxin",
  "Dilantin",
  "Diltiazem",
  "D.I.M. (PA): 300 mg",
  "Diovan",
  "Ditropan",
  "Diuretic",
  "Divalproex",
  "Dolophine",
  "Donepezil",
  "Doxazosin",
  "Dramamine",
  "Duloxetine",
  "Duragesic",
  "Dutasteride",
  "Effexor",
  "Elavil",
  "Enalapril",
  "Enbrel",
  "Enoxaparin",
  "Escitalopram",
  "Esomeprazole",
  "Estrace",
  "Estradiol",
  "Estradiol: 2 mg/ml lipo cream",
  "Estradiol: 3 mg/ml lipo cream",
  "Estradiol: 4 mg/ml lipo cream",
  "Estradiol: 6 mg/ml lipo cream",
  "Estradiol (Mylan): 0.0375 mg patch",
  "Estradiol/Progesterone: 2.5mg/175mg/ml lipo cream",
  "Estradiol/Progesterone: 2mg/50mg/ml Vanishing cream",
  "Estradiol/Progesterone/Testosterone: 4mg/190mg/2.5mg/ml Vanishing cream",
  "Estradiol/Testosterone: 5mg/1.5mg/ml lipo cream",
  "Estradiol/Testosterone: 5mg/1mg/ml lipo cream",
  "Estradiol/Testosterone: 5mg/3mg/ml lipo cream",
  "Estradiol/Testosterone: 8mg/1mg/ml Vanishing cream",
  "Estriol/Testosterone: 1mg/0.5mg/gm vaginal cream",
  "Estrogen",
  "Eszopiclone",
  "Etanercept",
  "Evista",
  "Exelon",
  "Ezetimibe",
  "Ezetimibe + Simvastatin Vytorin",
  "Famotidine",
  "Febuxostat",
  "Fenofibrate",
  "Fentanyl",
  "Ferrous Gluconate (Perrigo): 324 mg tablet",
  "Finasteride",
  "Flagyl",
  "Flexeril",
  "Flomax",
  "Flonase",
  "Fluconazole",
  "Fluoxetine",
  "Fluticasone",
  "Focalin",
  "Folic Acid",
  "Folvite",
  "Fosamax",
  "Furosemide",
  "Gabapentin",
  "Gablofen",
  "Gemfibrozil",
  "Genotropin: 0.4 mg SC mini-quick",
  "Genotropin: 0.6 mg SC mini-quick",
  "Genotropin: 0.8 mg SC mini-quick",
  "Glimepiride",
  "Glipizide",
  "Glucophage",
  "Glucotrol",
  "Glyburide",
  "Guaifenesin",
  "HCTZ",
  "Homocysteine Factors:",
  "Humalog",
  "Humira",
  "Hydralazine",
  "Hydrochlorothiazide",
  "Hydrocodone/APAP",
  "Hydrocortisone (Qualitest): 5 mg tablet",
  "Hytrin",
  "Ibuprofen",
  "Imitrex",
  "Inderal",
  "Insulin Aspart",
  "Insulin Detemir",
  "Insulin Glargine",
  "Insulin Lispro",
  "Irbesartan",
  "Januvia",
  "Keflex",
  "Kenalog",
  "Keppra",
  "Ketoconazole",
  "Klonopin",
  "K-Tab",
  "Lamictal",
  "Lamotrigine",
  "Lanoxin",
  "Lansoprazole",
  "Lantus",
  "Lasix",
  "Latanoprost",
  "Latuda",
  "Levalbuterol",
  "Levaquin",
  "Levemir",
  "Levetiracetam",
  "Levitra",
  "Levofloxacin",
  "Levothyroxine",
  "Lexapro",
  "Liothyronine (T3): 10 mcg SR",
  "Liothyronine (T3)/Levothyroxine (T4): 10mcg/90mcg SR",
  "Liothyronine (T3)/Levothyroxine (T4): 25mcg/50mcg SR",
  "Liothyronine (T3)/Levothyroxine (T4): 30mcg/110mcg SR",
  "Liothyronine (T3)/Levothyroxine (T4): 5mcg/50mcg SR",
  "Liothyronine (T3)/Levothyroxine (T4): 9mcg/30mcg SR",
  "Lipitor",
  "Liraglutide",
  "Lisdexamfetamine",
  "Lisinopril",
  "Lopid",
  "Lopressor",
  "Loratadine",
  "Lorazepam",
  "Losartan",
  "Lotensin",
  "Lovastatin",
  "Lovenox",
  "Lunesta",
  "Lurasidone",
  "Lyrica",
  "Macrobid",
  "Magnesium Aspartate (DOUGLAS): 100 mg",
  "Meclizine",
  "Medrol",
  "Melatonin (PA): 1 mg SR",
  "Meloxicam",
  "Memantine",
  "Metformin",
  "Methadone",
  "Methocarbamol",
  "Methotrexate",
  "Methylphenidate",
  "Methylprednisolone",
  "Metoclopramide",
  "Metoprolol",
  "Metronidazole",
  "Mevacor",
  "Minivelle: 0.1 mg/24hr patch",
  "Minocin",
  "Minocycline",
  "Mirtazepine",
  "Mobic",
  "Mometasone",
  "Montelukast",
  "Mupirocin",
  "Mycostatin",
  "Nabumetone",
  "Namenda",
  "Naproxen",
  "Nasonex",
  "Nature-Throid (Thyroid): 0.5 grain (32.5 mg) tablet",
  "Nature-Throid (Thyroid): 1.5 grain (97.5 mg) tablet",
  "Nature-Throid (Thyroid): 1 grain (65mg) tablet",
  "Nebivolol",
  "Neurontin",
  "Nexium",
  "Nifedipine",
  "Nitrofurantoin",
  "Nitroglycerine",
  "NitroStat",
  "Nizoral",
  "Norditropin FlexPro: 10 mg/1.5 ml pen",
  "Norditropin FlexPro: 15 mg/1.5 ml pen",
  "Norditropin FlexPro: 5 mg/1.5 ml pen",
  "Nortriptyline",
  "Norvasc",
  "Novolog",
  "NUTRA CoQ10: 200 mg soft gel",
  "NUTRA Marine Fish Oil (EPA/DHA): 1000 mg (360mg/270mg) soft gel",
  "NUTRA Melatonin: 1 mg SR",
  "NUTRA Melatonin: 3 mg SR",
  "Nutra Melatonin: 5 mg SR",
  "NUTRA Prostate Vcap",
  "NUTRA Vitamin ADK",
  "NUTRA Vitamin D3: 1,000 IU",
  "NUTRA Vitamin D3: 5,000 IU",
  "Nystatin",
  "Olanzapine",
  "Olopatadine",
  "Omeprazole",
  "Omnicef",
  "Omnipred",
  "Omnitrope: 5.8 mg injectable",
  "Ondansetron",
  "Onglyza",
  "Oseltamivir",
  "Oxybutynin",
  "Oxycodone",
  "Oxycodone/APAP",
  "OxyContin",
  "Pamelor",
  "Pantoprazole",
  "Paroxetine",
  "Patanol",
  "Paxil",
  "Penicillin",
  "Pen VK",
  "Pepcid",
  "Percocet",
  "Phenazopyridine",
  "Phenergan",
  "Phentermine",
  "Phenytoin",
  "Pioglitazone",
  "Plavix",
  "Potassium",
  "Pradaxa",
  "Pravachol",
  "Pravastatin",
  "Prednisolone",
  "Prednisone",
  "Pregabalin",
  "Pregnenolone: 100 mg",
  "Pregnenolone: 50 mg",
  "Pregnyl: 10,000 units injectable",
  "Premarin",
  "Prevacid",
  "Prilosec",
  "Prinivil",
  "Pristiq",
  "ProAir",
  "Procardia",
  "Progesterone: 100 mg/ml lipo cream",
  "Progesterone: 100 mg SR",
  "Progesterone: 150 mg SR",
  "Progesterone: 200 mg SR",
  "Progesterone: 250 mg SR",
  "Progesterone: 50 mg/ml lipo cream",
  "Progesterone/Testosterone: 200mg/0.75mg/ml Vanishing cream",
  "Promethazine",
  "Propranolol",
  "Proscar",
  "Prostate Formula Blend",
  "Protonix",
  "Prozac",
  "Pyridium",
  "Quetiapine",
  "Quinapril",
  "Rabeprazole",
  "Raloxifene",
  "Ramipril",
  "Ranitidine",
  "Reglan",
  "Relafen",
  "Remeron",
  "Requip",
  "Restoril",
  "Rheumatrex",
  "Risedronate",
  "Risperdal",
  "Risperidone",
  "Rivaroxaban",
  "Rivastigmine",
  "Robaxin",
  "Robitussin",
  "Ropinirole",
  "Rosuvastatin",
  "Saxagliptin",
  "Selegiline: 2.5 mg",
  "Seroquel",
  "Sertraline",
  "Sildenafil",
  "Simvastatin",
  "Singulair",
  "Sitagliptin",
  "Solifenacin",
  "Soma",
  "Spiriva",
  "Spironolactone",
  "Strattera",
  "Sumatriptan",
  "Synthroid",
  "Tadalifil",
  "Tamiflu",
  "Tamsulosin",
  "Taurine: 500 mg",
  "Temazepam",
  "Tenormin",
  "Terazosin",
  "Tessalon",
  "Testosterone",
  "Testosterone: 125 mg/ml lipo cream",
  "Testosterone: 150 mg/ml lipo cream",
  "Testosterone: 175 mg/ml lipo cream",
  "Testosterone: 200 mg/ml lipo cream",
  "Testosterone: 2 mg/ml lipo cream",
  "Testosterone: 3 mg/ml lipo cream",
  "Testosterone: 4 mg/ml lipo cream",
  "Testosterone: 6 mg/ml lipo cream",
  "Testosterone Enanthate (WW): 200 mg/ml injectable",
  "Thyroid: 0.5 grain (30mg)",
  "Thyroid: 0.75 grain (45mg)",
  "Thyroid: 1 grain (60mg)",
  "Ticagrelor",
  "Tiotropium",
  "Tizanidine",
  "Tolterodine",
  "Topamax",
  "Topiramate",
  "Tramadol",
  "Travatan",
  "Travoprost",
  "Trazodone",
  "Triamcinolone",
  "Triamterene",
  "Tricor",
  "Tylenol",
  "Uceris",
  "Uloric",
  "Ultram",
  "Valacyclovir",
  "Valium",
  "Valsartan",
  "Valtrex",
  "Vardenafil",
  "Vasotec",
  "Venlafaxine",
  "Verapamil",
  "Verelan",
  "VESIcare",
  "Viagra",
  "Viagra (Pfizer100): 100 mg tablet",
  "Vicodin",
  "Victoza",
  "Vitamin D",
  "Vitamin D3: 2,000 IU",
  "Vitamin D3: 4,000 IU",
  "Vitamin D3 (PA): 1,000 IU",
  "Vitamin D3 (PA): 5,000 IU",
  "Vivelle-Dot: 0.05 mg/24hr patch",
  "Vivelle-Dot: 0.1 mg/24 hr patch",
  "Voltaren",
  "Vyvanse",
  "Warfarin",
  "Wellbutrin",
  "Xalatan",
  "Xanax",
  "Xarelto",
  "Xopenex",
  "Zanaflex",
  "Zantac",
  "Zetia",
  "Zithromax",
  "Zocor",
  "Zofran",
  "Zoloft",
  "Zolpidem",
  "Zovirax",
  "Zyloprim",
  "Zyprexa",
  "Zyrtec"
]

export { recommendation_list };
export default Recommendation;

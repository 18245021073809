import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import _ from 'lodash';
import Grade from '../../Analytics/components/Grade';
import constant from '../../../config/constants';
import { CalculateChange } from '../../Analytics/tools/calendar';
import enLabels from '../../../config/en_labels';
import ResultsData from './ResultsData';
import constants from '../../../config/constants';
import { getLocalizedDate } from '../../../utils/reports-date-localization';
import getHistoricalChartUrl from '../../../utils/historical-chart-util';
import { IsInRange } from '../../Analytics/tools/helpers';
import ReactHighcharts from 'react-highcharts';


class PhysicianReportDownloadTemplate extends Component {

    componentDidMount() {
        if (this.props.match.params && this.props.match.params.visitId && !this.props.currentVisit.id) {
            this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history)
            this.props.getAllCategories(this.props.match.params.visitId, false)
        }
    }
    getAbnormalHeaders(showGrade, isBaseline) {
        const abnormalResultHeaders = []
        if (showGrade)
            abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.grade_label])
        abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.name_label])
        abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.result_label])
        abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.units_label])
        //abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.normal_range])
        abnormalResultHeaders.push("Lab Ref Range")
        abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.optimal_range])
        if (!isBaseline) {
            abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.baseline_label])
            abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.change])
        }
        abnormalResultHeaders.push(enLabels.report_labels.source_label)
        return abnormalResultHeaders;
    }
    // generateArtisanURL() {
    //     let data = this.props.dataForArtisanURL ? this.props.dataForArtisanURL : [];
    //     let followUpUrl = constants.biographical_labels.artisan_route
    //     let aggregateAge = {};
    //     data.map(age => {
    //         // if (aggregateAge[age.label] && age.value != "0" && age.timePoint == this.props.currentVisit.timePoint) {
    //         //     aggregateAge[age.label].push(`${age.value}`)
    //         // }
    //         // else {
    //         //     if(age.value != "0" && age.timePoint == this.props.currentVisit.timePoint){
    //         //         aggregateAge[age.label] = []
    //         //         aggregateAge[age.label].push(`${age.value}`)
    //         //     }
    //         // }
    //         if (age.value != "0" && age.value != 0 && age.value != "" && age.timePoint == this.props.currentVisit.timePoint) {
    //             aggregateAge[age.label] = []
    //             aggregateAge[age.label].push(`${age.value}`)
    //         }
    //     })
    //     let final = []
    //     let age = []
    //     let groupByTimePoint = _.groupBy(data, 'timePoint')
    //     age = Math.floor(_.head(groupByTimePoint[this.props.currentVisit.timePoint]).age);
    //     Object.keys(aggregateAge).map(agg => {
    //         // agg != constants.epigenAge && agg != constants.glycanAge && 
    //         if (aggregateAge[agg].filter(a => a).length) {
    //             final.push(`n:${constants.biographical_labels.ageLabels[agg]}^v:${aggregateAge[agg].join()}^b:${age}`)
    //         }
    //     })
    //     followUpUrl = `${followUpUrl}?data=${final.join('|')}`;
    //     return followUpUrl;

    // }
    getHistoricData() {
        if (this.props.categories) {
            var final = []
            var allData = []
            var allVisits = []

            this.props.categories.map(t => t.sections.map(s => { return { [s.name]: s.data } }).map(f => { final.push(f) }))
            if (final && final.length)
                final.map(f => f[_.head(Object.keys(f))].map(k => _.sortBy(k.historicData, (h) => new Date(h)).map(hd => {
                    allVisits.push(hd.visitDate);
                    allData.push(hd);
                })))
            allVisits = _.sortBy(_.uniq(allVisits), v => new Date(v))
            allData = _.groupBy(allData, 'label')
            // console.log(final)
            // console.log(allVisits)
            // console.log(allData)
            var finalPanelObs = final.map(f => {
                let fkey = _.head(Object.keys(f))
                return { [fkey]: f[fkey].map(l => l.label) }
            })
            if (allVisits && allVisits.length <= 10) {
                return <div className='patients-report-preview-table'>
                    <div className='bootstrap-table-container'>
                        <table className='table table-striped table-hover'>
                            <thead className='historic-data-visits-title'>
                                <th>{this.props.terms['Visit Date']}</th>
                                {allVisits.map(av => <th className='dates'>{moment(av).format('DD/MM')}<br></br>{moment(av).format('YYYY')}</th>)}
                            </thead>
                            <tbody>
                                {finalPanelObs.map(fpo => {
                                    var fkey = _.head(Object.keys(fpo))
                                    return (
                                        <>
                                            <tr className='historic-chart-panel-header'>
                                                <td><strong>{fkey}</strong></td>
                                                {allVisits.map(a => <td></td>)}
                                            </tr>
                                            {fpo[fkey].map(obs => <tr>
                                                <td>{obs}</td>
                                                {allVisits.map(currentVisit => {
                                                    let availableVal = _.head(allData[obs].filter(indiObs => indiObs.visitDate == currentVisit))
                                                    if (availableVal) {
                                                        return <td>{availableVal.value}</td>
                                                    } else {
                                                        return <td></td>
                                                    }
                                                })}
                                            </tr>)}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table >
                    </div>
                </div>
            }
            else {
                let baselineDate = _.head(allVisits)
                let recentVisit = _.last(allVisits)
                return <div className='patients-report-preview-table'>
                    <div className='bootstrap-table-container'>
                        <table className='table table-striped table-hover'>
                            <thead className='historic-data-visits-title'>
                                <th>{this.props.terms['Visit Date']}</th>
                                <th>{this.props.terms['Baseline']}</th>
                                <th>{this.props.terms['most recent visit']}</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {finalPanelObs.map(fpo => {
                                    var fkey = _.head(Object.keys(fpo))
                                    return (
                                        <>
                                            <tr className='historic-chart-panel-header'>
                                                <td><strong>{fkey}</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {fpo[fkey].map(obs => {
                                                let headValue = _.head(allData[obs].filter(indiObs => indiObs.visitDate == baselineDate))
                                                let tailValue = _.head(allData[obs].filter(indiObs => indiObs.visitDate == recentVisit))
                                                let valueType = _.head(allData[obs]).valueType
                                                return <tr>
                                                    <td>{obs}</td>
                                                    <td>{headValue ? headValue.value : ''}</td>
                                                    <td>{tailValue ? tailValue.value : ''}</td>
                                                    <td>{valueType != constants.text_value_type ? this.getChartSource(allData, obs, allVisits) : ''}</td>
                                                </tr>
                                            })}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table >
                    </div>
                </div>
            }

        }
    }

    getChartSource(allNumericalData, obs, allVistsDates) {
        let values = []
        allVistsDates.map(avd => {
            let preVal = allNumericalData[obs].filter(and => and.visitDate == avd)
            if (preVal && preVal.length) {
                var val = _.head(preVal).value
                if (!isNaN(parseInt(val)))
                    values.push(val)
                else
                    values.push('0')
            } else {
                values.push('0')
            }
        })
        if (parseFloat(_.min(values)) == 0 && parseFloat(_.max(values)) == 0 || (values.filter(v => v != '0').length <= 2)) {
            return ''
        } else {
            values = values ? values.filter(v => parseFloat(v)) : []
            values = values.map(v => parseFloat(v))
            values = values.filter(v => v != '0')
            let config = {
                chart: {
                    type: 'column',
                    height: 60,
                    width: 200,
                    backgroundColor: 'transparent'
                },
                title: {
                    text: '',
                },
                legend: {
                    enabled: false
                },
                    xAxis: {
                    lineWidth: 1,
                    tickLength: 0, 
                    lineColor: '#667788',
                    gridLineWidth: 0,
                    labels: {
                        enabled: false
                    }
                    },
                    yAxis: {
                    title: {
                        text: ''
                    },
                    lineWidth: 1.5, 
                    gridLineWidth: 0,
                    lineColor: '#667788'
                    },
                series: [{
                    color: '#667788',
                    data: values,
                }],
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<span>{point.y}</span>' // Show only value
                    },
            };
            return <div className='react-high-chart-noumenon'><ReactHighcharts config={config} /></div>
            // return <img src={getHistoricalChartUrl(values, allNumericalData)} />
        }
    }
    render() {
        // const details = [
        //     {
        //         dataField: 'firstname'
        //     },
        //     {
        //         dataField: 'patientId'
        //     },
        //     {
        //         dataField: 'date'
        //     },
        //     {
        //         dataField: 'timePoint'
        //     }
        // ]
        // const columns = [
        //     {
        //         dataField: 'points',
        //         formatter: (cellContent, row) => (<span><Grade points={row.points} /></span>),
        //         text: this.props.terms[enLabels.report_labels.grade_label],
        //     },
        //     {
        //         dataField: 'label',
        //         text: this.props.terms[enLabels.report_labels.name_label],
        //     },
        //     {
        //         dataField: 'value', text: this.props.terms[enLabels.report_labels.result_label],
        //         formatter: (cellContent, row) => {
        //             if (row.points >= 3.7) {
        //                 return <span className='success'>{row.value}</span>
        //             }
        //             else if (row.points < 1.5) {
        //                 return <span className='danger'>{row.value}</span>
        //             }
        //             else {
        //                 return <span>{row.value}</span>
        //             }
        //         }
        //     },
        //     { dataField: 'units', text: this.props.terms[enLabels.report_labels.units_label] },
        //     {
        //         dataField: 'referenceRange', text: this.props.terms[enLabels.report_labels.normal_range],
        //     },
        //     { dataField: 'optimalRange', text: this.props.terms[enLabels.report_labels.optimal_range] },
        //     {
        //         dataField: 'value',
        //         formatter: (cellContent, row) => (<span>{row.historicData ? _.head(row.historicData)['value'] : ''}</span>),
        //         text: this.props.terms[enLabels.report_labels.baseline_label],
        //         hidden: this.props.currentVisit.timePoint == constant.baseline ? true : false
        //     },
        //     {
        //         dataField: 'Change',
        //         formatter: (cellContent, row) => (<span>{CalculateChange(row)}</span>),
        //         text: this.props.terms[enLabels.report_labels.change],
        //         hidden: this.props.currentVisit.timePoint == constant.baseline ? true : false
        //     },
        //     {
        //         dataField: 'sourceIndex',
        //         formatter: (cellContent, row) => {
        //             var arr = [];
        //             for (var i = row.sourceIndex + 65; i <= 90; i++) {
        //                 return arr[i - 65] = String.fromCharCode(i);
        //             }
        //         },
        //         text: 'Source',
        //         hidden: false
        //     }
        // ]

        const historicColumnsHeader = [
            this.props.terms[enLabels.report_labels.name_label],
            this.props.terms[enLabels.report_labels.result_label],
            this.props.terms[enLabels.report_labels.visit_date]
        ]
        // const abnormalResults = [
        //     {
        //         dataField: 'points',
        //         formatter: (cellContent, row) => (<span><Grade points={row.points} /></span>),
        //         text: labels.analytics_labels.grade_label,
        //     },
        //     {
        //         dataField: 'label',
        //         text: labels.patient_list_labels.name_label,
        //     },
        //     {
        //         dataField: 'value', text: labels.oddities_labels.result_label,
        //         formatter: (cellContent, row) => {
        //             if (row.points >= 3.7) {
        //                 return <span className='success'>{row.value}</span>
        //             }
        //             else if (row.points < 1.5) {
        //                 return <span className='danger'>{row.value}</span>
        //             }
        //             else {
        //                 return <span>{row.value}</span>
        //             }
        //         }
        //     },
        //     { dataField: 'units', text: labels.analytics_labels.units_label },
        //     {
        //         dataField: 'referenceRange', text: labels.analytics_labels.normal_range,
        //     },
        //     { dataField: 'optimalRange', text: labels.analytics_labels.optimal_range, },
        //     {
        //         dataField: 'value',
        //         formatter: (cellContent, row) => (<span>{row.historicData ? _.head(row.historicData)['value'] : ''}</span>),
        //         text: labels.analytics_labels.baseline_label,
        //         hidden: this.props.currentVisit.timePoint == constant.baseline ? true : false
        //     },
        //     {
        //         dataField: 'Change',
        //         formatter: (cellContent, row) => (<span>{_.head(row.historicData)['value'] && !isNaN(parseInt(_.head(row.historicData)['value'])) ? Math.round(((row.value - _.head(row.historicData)['value']) / _.head(row.historicData)['value']) * 100) + '%' : ''}</span>),
        //         text: labels.elk_labels.change,
        //         hidden: this.props.currentVisit.timePoint == constant.baseline ? true : false
        //     },
        //     // {
        //     //     dataField: '',
        //     //     text: 'Source'
        //     // }

        // ]
        const sourceColumns = [
            {
                dataField: 'id',
                formatter: (cellContent, row, index) => {
                    var arr = [];
                    for (var i = index + 65; i <= 90; i++) {
                        if (i <= 90) {
                            return arr[i - 65] = String.fromCharCode(i);
                        }
                        return arr[i - 65] = String.fromCharCode(i - 26);
                    }
                },
                text: labels.report_labels.flag,
            },
            {
                dataField: 'panelName',
                text: labels.report_labels.description,
            },
            {
                dataField: 'resultsReceived', text: labels.report_labels.first_received, formatter: (cellContent, row) => (
                    <span>{row.resultsReceived && !isNaN(new Date(row.resultsReceived).getDate()) ? moment(row.resultsReceived).format("YYYY") != "0001" ? moment(row.resultsReceived).format('L') : "See Archives" : ""}</span>)
            },
            {
                dataField: 'lastResultsReceived', text: labels.report_labels.last_received,
                formatter: (cellContent, row) => (
                    <span>{row.lastResultsReceived && !isNaN(new Date(row.lastResultsReceived).getDate()) ? moment(row.lastResultsReceived).format("YYYY") != "0001" ? moment(row.lastResultsReceived).format('L') : "See Archives" : ""}</span>)
            },
        ]
        const historicColumns = [

            {
                dataField: 'label',
                text: this.props.terms[enLabels.report_labels.name_label],
            },
            {
                dataField: 'value',
                text: this.props.terms[enLabels.report_labels.result_label],
                formatter: (cellContent, row) => {
                    return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => {
                        return <span className='spanForAlignment'>{s.value}</span>
                    }) : ''}</span>
                },
            },
            {
                dataField: 'visitDate',
                text: this.props.terms[enLabels.report_labels.visit_date],
                formatter: (cellContent, row, index) => {
                    // let res = (row.historicData.map((hd, index) => {
                    //     if (index <= this.props.currentTimepoint) {
                    //         return hd.visitDate
                    //     }
                    // }))
                    // return <span>{res.map(s => { return !isNaN(new Date(s).getDate()) ? <span className='spanForAlignment'>{moment(s).format('L')}</span> : ' ' })}</span>
                    return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => { return !isNaN(new Date(s.visitDate).getDate()) ? <span className='spanForAlignment'>{moment(s.visitDate).format('L')}</span> : ' ' }) : ''}</span>
                },
            },
        ]
        return (
            <div id='physicianReportDownloadTemplate'>
                <div className='physician-report-title'><h1>{this.props.terms[enLabels.report_labels.physiciansreport]}</h1></div>
                <div className='physician-details'>
                    <div className='individual'>
                        <span className='physician-label'><span className='physician-detail'>{this.props.terms[enLabels.report_labels.name_label]}</span> {this.props.currentVisit.firstname} {this.props.currentVisit.lastname}</span></div>
                    <div className='individual'>
                        <span className='physician-label'><span className='physician-detail'>{labels.oddities_labels.identifier}</span>{this.props.currentVisit.patientId}</span>
                    </div>
                </div>
                <div className='physician-details'>
                    <div className='individual'>
                        <span className='physician-label'><span className='physician-detail'>{this.props.terms[enLabels.report_labels.visit_date]}</span>{this.props.currentVisit.date
                            ? getLocalizedDate(this.props.currentVisit.date, this.props.currentVisit.reportPreference, this.props.currentVisit.datePreference)
                            : labels.not_available}</span>
                    </div>
                    <div className='individual'>
                        <span className='physician-label'><span className='physician-detail'>{this.props.terms[enLabels.report_labels.timepoint]}</span>
                            {this.props.currentVisit.timePoint != constants.VISIT_TIMEPOINTS.BASELINE ? this.props.currentVisit.timePoint.replace(constants.VISIT_TIMEPOINTS.FOLLOWUP, this.props.terms[constants.VISIT_TIMEPOINTS.FOLLOWUP])
                                : this.props.terms[constants.VISIT_TIMEPOINTS.BASELINE]}</span>
                    </div>
                </div>

                <div className='artisen-physician'><img className='aggregate' style={{ width: '1000px' }} src={this.props.currentFollowUpUrl} /></div>
                {this.props.exceptionalData && this.props.exceptionalData.length > 0 ?
                    <div id='exceptional_results'>
                        <p className='break-page-before'></p>
                        <div className='abnormalResult'><h1>{this.props.terms[enLabels.report_labels.exceptional_results]}</h1>
                            <table><thead className='absolute-result-header'><tr>{this.getAbnormalHeaders(false, this.props.currentVisit.timePoint == constant.baseline).map(a => <th>{a}</th>)}</tr></thead></table>
                            {this.props.categories ? this.props.categories.map(
                                s => s.sections.map(
                                    t => (
                                        t.data.filter(u => u.points >= 3.7 && u.visitId == this.props.currentVisit.id).length ?
                                            <div className='patients-report-preview-table'>
                                                <div className='complete-col-span'>
                                                    <div className='categoriesPhysio'><h3>{t.name}</h3></div>
                                                    <ResultsData
                                                        baselineVisitId={this.props.currentVisit.baselineVisitId}
                                                        showGrade={false}
                                                        isBaseline={this.props.currentVisit.timePoint == constant.baseline}
                                                        data={t ? t.data.filter(s => s.points >= 3.7 && s.visitId == this.props.currentVisit.id) : ''} />
                                                    {/* <BootstrapTable
                                                                        remote
                                                                        striped
                                                                        bordered={false}
                                                                        keyField='id'
                                                                        data={t ? t.data.filter(s => s.points >= 3.7 && s.visitId == this.props.currentVisit.id) : ''}
                                                                        columns={columns}
                                                                    /> */}
                                                </div>
                                            </div> : ''))) : null}
                        </div>
                    </div> : ''}
                <div className='abnormalResult'>
                    {this.props.categories && this.props.abnormalData.length > 0 ?
                        <div id='abnormal_results'>
                            <p className='break-page-before'></p>
                            {/* <h1>{this.props.terms[enLabels.report_labels.abnormal_results]}</h1> */}
                            <h1>{this.props.terms['results_of_concern']}</h1>
                            <table><thead className='absolute-result-header'><tr>{this.getAbnormalHeaders(false, this.props.currentVisit.timePoint == constant.baseline).map(a => <th>{a}</th>)}</tr></thead></table>
                            {this.props.categories.map(
                                s => s.sections.map(
                                    p => (
                                        <div className='patients-report-preview-table'>
                                            {p.data.filter(s => ((s.points != null && s.points <= 1.3) || (s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s))) && s.visitId == this.props.currentVisit.id).length > 0 ?
                                                <React.Fragment>
                                                    <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                    <ResultsData
                                                        baselineVisitId={this.props.currentVisit.baselineVisitId}
                                                        showGrade={false}
                                                        showSource={true}
                                                        isBaseline={this.props.currentVisit.timePoint == constant.baseline}
                                                        data={p ? p.data.filter(s => ((s.points != null && s.points <= 1.3) || (s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s))) && s.visitId == this.props.currentVisit.id) : ''}
                                                    />
                                                    {/* <BootstrapTable
                                                        remote
                                                        striped={true}
                                                        bordered={false}
                                                        hover={true}
                                                        bootstrap4
                                                        keyField='id'
                                                        data={p ? p.data.filter(s => (s.points < 2 || s.points === null) && s.visitId == this.props.currentVisit.id) : ''}
                                                        columns={columns}
                                                    /> */}
                                                </React.Fragment> : null}
                                        </div>)))}
                        </div>
                        : ''}
                </div>
                <div className='completeData'>
                    {this.props.categories && this.props.completeData.length > 0 ?
                        <div id='complete_data'>
                            <p className='break-page-before'></p>
                            <h1>{this.props.terms[enLabels.report_labels.complete_data]}</h1>
                            <table><thead className='absolute-result-header'><tr>{this.getAbnormalHeaders(true, this.props.currentVisit.timePoint == constant.baseline).map(a => <th>{a}</th>)}</tr></thead></table>
                            {this.props.categories.map(
                                s => s.sections.map(
                                    p => (p.data.filter(s => s.visitId == this.props.currentVisit.id).length > 0 ?
                                       <>
                                            <div className='patients-report-preview-table'>
                                                <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                { p.data.filter(q => q.visitId == this.props.currentVisit.id && q.valueType == "ST" && q.isTextArea == 1).length > 0 ?
                                                    p.data.filter(q => q.visitId == this.props.currentVisit.id && q.valueType == "ST" && q.isTextArea == 1).map(noumenon => {
                                                        return (
                                                        <div className="pt-2 pb-2 text-color">
                                                            <div>
                                                                <div className='fw-10000 mb-2 h5 pb-2'>{noumenon.label}</div>
                                                            </div>
                                                            <div className='pb-2 noumenon-value-card'>
                                                                <div className='p-4 bb'>
                                                                    <div className='fw-10000 mb-2'>{this.props.terms[enLabels.report_labels.result_label]}</div>
                                                                    <div className='ml-3'>{noumenon.value}</div>
                                                                </div>
                                                                <div className='pt-2 p-4'>
                                                                    <div className='fw-10000 mb-2'>{this.props.terms['Baseline']}</div>
                                                                    <div className='ml-3'>{noumenon.historicData && noumenon.historicData.length &&
                                                                        noumenon.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId).length > 0 ?
                                                                        _.head(noumenon.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId)).value : ''}</div>
                                                                </div>
                                                            </div>
                                                        </div> )
                                                    })
                                                : ""}
                                                { p.data.filter(s => s.visitId == this.props.currentVisit.id && s.valueType != "ST" && s.isTextArea != 1).length > 0 ?
                                                    <ResultsData
                                                        baselineVisitId={this.props.currentVisit.baselineVisitId}
                                                        showGrade={true}
                                                        showSource={true}
                                                        isBaseline={this.props.currentVisit.timePoint == constant.baseline}
                                                        data={p.data.filter(s => s.visitId == this.props.currentVisit.id && s.valueType != "ST" && s.isTextArea != 1)}
                                                    />
                                                : ""}
                                            </div>
                                        </>
                                    : '')
                                ))}
                        </div>
                        : ''}
                </div>

                {/* {this.props.currentVisit.timePoint != constant.baseline ?
                    <div id='historic_data'>
                        <p className='break-page-before'></p>
                        {this.props.categories ?
                            <div className='hdataPhysician d-inline-block'>
                                <div>
                                    <h1>{this.props.terms['Historical Data']}</h1>
                                </div>
                                <div>
                                    {this.getHistoricData()}
                                </div>
                            </div>
                            : ''}
                    </div> : ''} */}

                {this.props.ordersList.length > 0 ?
                    <div className='sourcesPhysician'>
                        <p className='break-page-before'></p>
                        <h1>{labels.oddities_labels.source_label}s</h1>
                        <div className='patients-report-preview-table'>
                            <BootstrapTable
                                remote
                                striped={true}
                                bordered={false}
                                hover={true}
                                bootstrap4
                                keyField='id'
                                data={this.props.ordersList ? this.props.ordersList : []}
                                columns={sourceColumns}
                            />
                        </div>
                    </div> : ''}
            </div>
        )
    }
}
export default PhysicianReportDownloadTemplate
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'qs';
import ReactPaginate from 'react-paginate';

import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import providerDefault from '../../../assets/images/provider-default.png';
import LoadingTemplate from '../../../components/LoadingTemplate';
import PageTitle from '../../../components/PageTitle';
import constants from '../../../config/constants';
import { Typeahead } from 'react-bootstrap-typeahead';

class ProvidersList extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        // this.props.getProviders()
        this.props.clearSearch(this.urlParams, this.props.history)
        // this.props.getProviders(this.urlParams.page)
        this.props.getSitesList()
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.provider_labels.list_title_label, path: '' }
        ])
        document.title = `${labels.physioage_label}: ${labels.provider_labels.list_title_label}`
        this.props.setPageTitle(labels.provider_labels.list_title_label)

        this.unlisten = this.props.history.listen((location, action) => {
            if (location && location.search && this.props.location
                && action == 'POP') {
                const queryString = location && location.search && location.search.substr(1)
                this.urlParams = parse(queryString, true);
                this.props.clearSearch(this.urlParams)
            }
        })
        this.providerName = true
    }
    componentWillReceiveProps(newProps) {
        if (this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search) {
            this.props.resetProviders()
        }
    }
    componentWillUnmount() {
        this.unlisten()
    }
    render() {
        const columns = [
            {
                dataField: 'avatarPath',
                formatter: (cellContent, row) => (
                    <img
                        alt={row && row.avatarContent ? row.avatarFileName : ''}
                        src={row && row.avatarContent ? `data:${row.avatarContentType};base64,${row.avatarContent}` : providerDefault}
                        className='provider-avatar' />
                )
            },
            {
                dataField: 'firstname', text: labels.provider_labels.provider_name, sort: true,
                onSort: (sortField, sortOrder) => this.props.sortProviders(sortField, sortOrder, this.props.history, this.urlParams && this.urlParams.page ? this.urlParams.page : 1),
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    return 0;
                },
                formatter: (cellContent, row) => (<span><Link to={`/providers/${row.id}`}>
                    {row.honorificPrefix ? row.honorificPrefix : ''} {row.firstname} {row.lastname}{row.honorificSuffix ? ',' : ''} {row.honorificSuffix ? row.honorificSuffix : ''}</Link><i className='fa fa-pencil' onClick={() => this.props.navigateEditProvider(this.props.history, `/providers/edit/${row.id}`)}></i></span>)
            },
            {
                dataField: 'sitesCount', text: labels.provider_labels.sites_label, sort: true,
                onSort: (sortField, sortOrder) => this.props.sortProviders(sortField, sortOrder, this.props.history, this.urlParams && this.urlParams.page ? this.urlParams.page : 1),
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    return 0;
                }
            },
            {
                dataField: 'patientsCount', text: labels.provider_labels.patients_label, sort: true,
                onSort: (sortField, sortOrder) => this.props.sortProviders(sortField, sortOrder, this.props.history, this.urlParams && this.urlParams.page ? this.urlParams.page : 1),
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    return 0;
                }
            },
            {
                dataField: 'lastVisit', text: labels.provider_labels.last_visit, sort: true,
                onSort: (sortField, sortOrder) => this.props.sortProviders(sortField, sortOrder, this.props.history, this.urlParams && this.urlParams.page ? this.urlParams.page : 1),
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    return 0;
                },
                formatter: (cellContent, row) => (<span> {(row.lastVisit && row.lastVisit.length > 2) ? row.lastVisit.replace("-", "") : row.lastVisit}</span>)
            }
        ]
        const paginationOptions = paginationFactory({
            alwaysShowAllBtns: true,
            prePageText: 'Prev',
            nextPageText: 'Next',
            withFirstAndLast: false,
            hideSizePerPage: true,
            page: this.props.searchParam ? this.props.searchParam.pageNumber : 1,
            pageSize: this.props.searchParam ? this.props.searchParam.pageSize : 10,
            onPageChange: (page) => { this.props.ProviderPageChange(page, false, this.props.history) },
        });
        return (
            this.props.isLoading ? <LoadingTemplate isGrid={true} haveSidebar={true} /> :
                <>

                    <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                        <div className='card patients-filter'>
                            <div className='card-header'>
                                {labels.provider_labels.filter_providers_label}
                            </div>
                            <div className='card-body'>
                                <div className='form-group'>
                                    <label>Name</label>
                                    <Typeahead
                                        // onKeyDown={(e) => {
                                        //     if (e.keyCode == constants.enter_key_code || e.keyCode == constants.escape_key_code) {
                                        //         this.props.setSearchParam(e.target.value, 'name', false, this.props.history);
                                        //     }
                                        // }}
                                        onKeyDown={(e) => { e.length ? setSearchParam(_.head(e), 'name', false, this.props.history) : null; this.providerName = e.target.value ? false : true }}
                                        // onSearch={(name) => this.props.setSearchParam(name, 'name', false, this.props.history)}
                                        // onInputChange={(name) => this.props.setSearchParam(name, 'name', false, this.props.history)}
                                        id='name' ref={this.name} label={labels.patient_list_labels.name_label}
                                        type='typeahead'
                                        defaultSelected={this.props.currentSearchFilters && this.props.currentSearchFilters.name ? [this.props.currentSearchFilters.name] : []}
                                        options={this.props.currentNames}
                                        emptyLabel=''
                                        onChange={(e) => {
                                            if (e && e.length && _.head(e)) {
                                                this.props.setSearchParam(_.head(e), 'name', false, this.props.history)
                                                this.props.clearNames();
                                            }
                                        }}
                                        onBlur={(e) => {
                                            this.providerName = true;
                                            if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                                this.props.setSearchParam(e.target.value, 'name', false, this.props.history)
                                            }
                                        }
                                        }
                                    />
                                </div>
                                <FormControl
                                    id='siteid' label={labels.patient_list_labels.site_label}
                                    type='select' onChange={(e) => this.props.setSearchParam(e.target.value, e.target.id, false, this.props.history)}
                                    defaultOption={labels.patient_list_labels.all_sites}
                                    value={this.props.currentSearchFilters && this.props.currentSearchFilters.siteid ? parseInt(this.props.currentSearchFilters.siteid) : 0}
                                    disabled={!this.props.sitesList || !this.props.sitesList.length}
                                    options={this.props.sitesList ? this.props.sitesList : []} textKey={'name'} valueKey={'id'} />
                            </div>
                        </div>
                        <div className='add-patient-btn-sec'>
                            <FormControl type='button' btnType='primary' text={labels.provider_labels.add_provider}
                                disabled={true}
                                onClick={() => this.props.addProvider(this.props.history)} help="To add providers, navigate to the particular site and then click on add provider." />
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0 providers-list'>
                        <div className='providers-list-grid'>
                            {this.props.providers
                                && this.props.providers.length && this.props.providers[0].id != 0 ? <>
                                <BootstrapTable
                                    striped={false}
                                    bordered={false}
                                    hover={true}
                                    bootstrap4
                                    keyField='id'
                                    data={this.props.filteredProviders}
                                    columns={columns}
                                    pagination={false}
                                />
                                {this.props.providers.length < 10 ? false :
                                    <div id='react-paginate' className='mt-3'>
                                        <ReactPaginate
                                            previousLabel={'Prev'}
                                            nextLabel={'Next'}
                                            breakLabel={'...'}
                                            previousClassName='page-item'
                                            nextClassName='page-item'
                                            breakClassName='page-item disabled'
                                            breakLinkClassName='page-link'
                                            pageClassName='page-item'
                                            pageLinkClassName='page-link'
                                            previousLinkClassName='page-link'
                                            nextLinkClassName='page-link'
                                            activeClassName='active'
                                            pageCount={Math.ceil(this.props.providers.length / 10)}
                                            marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                            pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                            disableInitialCallback={true}
                                            forcePage={this.props.defaultPage ? parseInt(this.props.defaultPage) - 1 : 0}
                                            onPageChange={(page) => { this.props.ProviderPageChange(Math.ceil(page.selected) + 1, false, this.props.history) }}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                        />
                                    </div>}
                            </> : this.props.no_grid_data ?
                                <div className='jumbotron'>
                                    <h3>{labels.no_grid_data}</h3>
                                </div> : null
                            }

                        </div>
                    </div>

                </>
        )
    }
}
export default ProvidersList
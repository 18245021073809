import React, { useEffect, useRef } from 'react';
import constants from '../../../config/constants';

function generateLabels(rubricRanges, rangeInPercent, isDatum) {
    const labels = [];
    let labelPosition = 0;
    let previousPosition = 0;
    let rubricLength = isDatum &&  rubricRanges && rubricRanges.length ? rubricRanges.length : 5;
    let categorySectionRanges = [{low : 0, high : 0.7}, {low : 0.7, high : 1.7}, {low : 1.7, high : 2.7}, {low : 2.7, high : 3.7}, {low : 3.7, high : null}]
    for (let index = 0; index < rubricLength; index++) {
        const position = rangeInPercent[index];
        labelPosition = index == 0 ? 0 : 100/rubricRanges.length;
        labels.push(
            <div className = {`${!isDatum ? 'category-section' : 'datum'}-label`} style={{  }}>
                <div key={index} className='range-label-text'>
                {`${isDatum ? rubricRanges[index].description ? rubricRanges[index].description.replace('Range', '') : rubricRanges[index].type.replace('Range', '') : rubricRanges[index]}`}
                </div>
                {isDatum ? 
                <div className='range-value'>
                    {(rubricRanges[index].low != null && rubricRanges[index].high != null) ? 
                    parseFloat(rubricRanges[index].low) + ' to ' + parseFloat(rubricRanges[index].high) : 
                    rubricRanges[index].low != null ? '>' + parseFloat(rubricRanges[index].low) : 
                    rubricRanges[index].high != null ? '<' + parseFloat(rubricRanges[index].high) : ''}
                </div> : 
                <div className='range-value'>
                {(categorySectionRanges[index].low != null && categorySectionRanges[index].high != null) ? 
                parseFloat(categorySectionRanges[index].low) + ' to ' + parseFloat(categorySectionRanges[index].high) : 
                categorySectionRanges[index].low != null ? '>' + parseFloat(categorySectionRanges[index].low) : 
                categorySectionRanges[index].high != null ? '<' + parseFloat(categorySectionRanges[index].high) : ''}
            </div>
                }
            </div>
        );
        previousPosition = position;
    }
    return labels;
}

const AverageCalculator = (value) =>{
    const numbers = value.split('-').map(Number);
    return (_.head(numbers)+_.last(numbers))/2;
}

function GetArrowPosition(currentValue, isDatum, rubricRanges){
    let position = 0;
    const rangeRegex = /\d+-\d+/g;
    let arrowElement = document.getElementById('rubric-ranges-bar-arrow');
    if(!isDatum){
        if (arrowElement) {
            arrowElement.classList.remove('undefined-pointer');
        }
        position = parseFloat(currentValue) > 4.3 ? 100 : parseFloat(currentValue)*100/4.3;
        return position;
    }
    else{
        let index = -1;
        let eachUnitPercent = 100/rubricRanges.length;
        if(rangeRegex.test(currentValue)){
            currentValue = AverageCalculator(currentValue).toString();
        }
        for (let i = 0; i < rubricRanges.length; i++) {
            let low = rubricRanges[i].low;
            let high = rubricRanges[i].high;
            if (low === null && parseFloat(currentValue) < parseFloat(high)) {
                position = 5;
                break;
            }
            else if(high === null && parseFloat(currentValue) > parseFloat(low)){
                position = 100;
                break; 
            }
            else if(parseFloat(currentValue) >= parseFloat(low) && parseFloat(currentValue) <= parseFloat(high)){
                index = i;
                break;
            }
            else if(currentValue.charAt(0) == '<' || currentValue.charAt(0) == '>'){
                let modifiedValue = currentValue.slice(1);
                if (low === null && parseFloat(modifiedValue) <= parseFloat(high)) {
                    position = 5;
                    currentValue = modifiedValue;
                    break;
                }
                else if(high === null && parseFloat(modifiedValue) >= parseFloat(low)){
                    position = 100;
                    currentValue = modifiedValue;
                    break; 
                }
                else if(parseFloat(modifiedValue) >= parseFloat(low) && parseFloat(modifiedValue) <= parseFloat(high)){
                    index = i;
                    currentValue = modifiedValue;
                    break;
                }
            }
            else{
                continue;
            }
        }
        let range = rubricRanges[index];
        if( index == 0 && range.low != null && range.high != null){
            let valuePosition =  5 + (currentValue-range.low)*100/(_.last(rubricRanges).high ? _.last(rubricRanges).high : _.last(rubricRanges).low - range.low);
            return valuePosition;
        }
        if(index == -1 && position == 0){
            if (arrowElement) {
                arrowElement.classList.add('undefined-pointer');
            }
        }
        else{
            if (arrowElement) {
                arrowElement.classList.remove('undefined-pointer');
            }
        }
        position = index != -1 ? (index)*eachUnitPercent + 4 < 5 ? 5 : (index)*eachUnitPercent + ((currentValue - range.low)*100/(range.high - range.low))*eachUnitPercent/100 + 4 : position;
        return position > 100 ? 100 : position < 0 ? 5 : position-1 < 5 ? position : position-1;
    }
}

function RubricRangesComponent(props) {
    let rubricRanges = props.rubricRanges;
    let rangeInPercent = props.colorState != 'static-color' && props.rubricRanges && props.rubricRanges.length > 0 ? [] : [16.27, 30.23, 46.51, 69.76, 86.04];
    const animatedDivRef = useRef(null);
    const arrowRef = useRef(null);
    const staticColors = 'linear-gradient(to right, #B2302E 16.27%, #BA5225 30.23%, #D8D800 46.51%, #72C13B 69.76%, #37B45E 86.04%)';
    let linearGradient = 'linear-gradient(to right, ';
    if(rubricRanges && rubricRanges.length > 0){
        const numSplits = rubricRanges.length;
        const splitWidth = 100 / (numSplits);;
        for (let index = 0; index < rubricRanges.length; index++) {
            const start = index * splitWidth + 1;
            const end = (index + 1) * splitWidth;
            const colore = constants.chart_colors_of_rubricRanges.find(item => rubricRanges[index].type in item)[rubricRanges[index].type] 
            linearGradient += `${colore} ${start}%, ${colore} ${end}%`;
            if (index !== numSplits - 1) {
                linearGradient += ", ";
            }
        }
    }
    useEffect(() => {
        const arrow = arrowRef.current;
            if (arrow) {
                arrow.animate(
                [
                    { left: '5%' },
                    { left: `${GetArrowPosition(props.currentValue, props.colorState == 'static-color' ? false : true, props.rubricRanges ? props.rubricRanges : [])}%`},
                ],
                {
                    duration: 2000,
                    fill: 'forwards',
                    easing: 'ease-in-out',
                }
            );}
    }, [props.datumValues ? props.datumValues.code : props.datumValues, props.currentSection, props.currentCategory, props.currentValue]);

    return (
    <div className='rubric-ranges-bar-container'>
        <div className={`${props.colorState == 'static-color' ? 'category-section' : 'datum'}-labels-container`}>
            {generateLabels(props.colorState != 'static-color' ? rubricRanges : ['Critical', 'Disease', 'Borderline', 'Healthy', 'Optimal'], rangeInPercent, props.colorState == 'static-color' ? false : true)}
        </div>
        <div className='rubric-ranges-bar-arrow' id='rubric-ranges-bar-arrow'>
            <div ref={animatedDivRef} className={`animated-filled-div-arrow ${props.colorState == 'static-color' ? ' category-section' : ' datum-colors'}`} style={{ background: `${props.colorState == 'static-color' ? staticColors : linearGradient}` }}>
            </div>
            <div ref={arrowRef} className={`arrow${props.colorState == 'static-color' ? ' category-section' : ' datum-colors'}`}></div>
        </div>
    </div>
    );
}

export default RubricRangesComponent;

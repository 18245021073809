import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { parse } from 'qs';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import FormControl from '../../../components/FormControls';
import { returnLocalizedDate } from '../../../utils/resolve-timer'
import LoadingTemplate from '../../../components/LoadingTemplate';

class FailureMessages extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        if (this.urlParams && Object.keys(this.urlParams).length) {
            if (this.urlParams && !this.urlParams.page) {
                this.urlParams.page = 1
            }
            if (this.urlParams && !this.urlParams.category) {
                this.urlParams.category = "unresolved"
            }
            if (this.urlParams && !this.urlParams.type) {
                this.urlParams.category = "unrouted"
            }
            this.props.setDefaultFailureMessages(this.urlParams, this.props.history, true)
        }
        else
            this.props.setDefaultFailureMessages(this.urlParams, this.props.history)

        this.unlisten = this.props.history.listen((location, action) => {
            if (location && location.search && this.props.location
                && action == 'POP') {
                const queryString = location && location.search && location.search.substr(1)
                this.urlParams = parse(queryString, true);
                this.props.setDefaultFailureMessages(this.urlParams)
            }
        })
    }
    componentWillReceiveProps(newProps) {
        if (this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search) {
            this.props.setDefaultFailureMessages()
        }
    }
    componentWillUnmount() {
        this.unlisten()
    }
    render() {
        const columns = [
            {
                dataField: 'resolvedById',
                text: 'Resolve',
                formatter: (cellContent, row) => (<FormControl type='checkbox' id={row.messageId} onChange={(e) => this.props.resolveFailureMessage(e, row, this.urlParams, this.props.history)} />),
                hidden: this.props.isResolved == "no" ? false : true

            },
            { dataField: 'from', text: 'From' },
            {
                dataField: 'patientName', text: 'Patient',
                hidden: this.props.current_type == 'invalid' ? true : false
            },
            {
                dataField: 'dob', text: 'DOB',
                formatter: (cellContent, row) => <span>{row.dob ? moment(row.dob).format('L') : ''}</span>,
                hidden: this.props.current_type == 'invalid' ? true : false
            },
            { dataField: 'messageId', text: 'Message', formatter: (cellContent, row) => <a onClick={() => this.props.history.push(`/messages/details/${row.messageId}`)}>View</a> },
            { dataField: 'received', text: 'Received', hidden: this.props.isResolved == "no" ? false : true },
            { dataField: 'resolveByName', text: 'Resolved By', hidden: this.props.isResolved == "yes" ? false : true },
            { dataField: 'resolved', text: 'Resolved', hidden: this.props.isResolved == "yes" ? false : true }
        ]
        return (

            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0'>
                <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                    <div className='card failure-messages'>
                        <div className='card-header'>Failure Message Filters</div>
                        <div className='card-body'>
                            <FormControl
                                id='type'
                                label='Select failure type'
                                type='select' onChange={(e) => this.props.setActiveCategory('type', e.target.value, 1, this.props.history, true, true)}
                                value={this.props.current_type}
                                nodefaultOption={true}
                                options={constants.failure_message_type} textKey={'key'} valueKey={'value'} />
                            <FormControl
                                id='resolved'
                                label='Select category'
                                type='select' onChange={(e) => this.props.setActiveCategory('resolved', e.target.value, 1, this.props.history, true, true)}
                                value={this.props.current_category == "unresolved" ? "no" : "yes"}
                                nodefaultOption={true}
                                options={constants.failure_messages} textKey={'key'} valueKey={'value'} />
                            {this.props.totalFailureMessagesCount && this.props.isResolved == 'no' ?
                                <div className='unresolved-count'>
                                    <p>Unresolved {this.props.current_type == 'invalid' ? 'invalid' : 'unrouted'} messages count: {this.props.totalFailureMessagesCount}</p>
                                </div> : ''}
                        </div>
                    </div>

                </div>
                <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 order-1 p-0 failure-messages'>
                    {this.props.failureMessages && this.props.failureMessages.length ?
                        <>
                            <div className='bootstrap-table-container'>
                                <BootstrapTable
                                    remote
                                    striped={false}
                                    bordered={false}
                                    hover={true}
                                    bootstrap4
                                    keyField='id'
                                    data={this.props.failureMessages}
                                    columns={columns}
                                    pagination={false} />
                            </div>
                            {this.props.totalFailureMessagesCount < 10 ? false :
                                <div id='react-paginate' className='mt-3'>
                                    <ReactPaginate
                                        previousLabel={'Prev'}
                                        nextLabel={'Next'}
                                        breakLabel={'...'}
                                        previousClassName='page-item'
                                        nextClassName='page-item'
                                        breakClassName='page-item disabled'
                                        breakLinkClassName='page-link'
                                        pageClassName='page-item'
                                        pageLinkClassName='page-link'
                                        previousLinkClassName='page-link'
                                        nextLinkClassName='page-link'
                                        activeClassName='active'
                                        pageCount={Math.ceil(this.props.totalFailureMessagesCount / 10)}
                                        marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                        pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                        disableInitialCallback={true}
                                        initialPage={this.props.searchParam ? this.props.searchParam.pageNumber - 1 : 0}
                                        onPageChange={(page) => {
                                            this.props.FailureMessagesPageChange(Math.ceil(page.selected) + 1, this.props.history, true);

                                        }}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                    />
                                </div>}
                        </> : this.props.no_grid_data ?
                            <div className='jumbotron'>
                                <h3>{labels.no_grid_data}</h3>
                            </div> : null}
                </div>
            </div>
        )
    }
}
export default FailureMessages
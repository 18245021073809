import React, { Component } from 'react';
import constants from '../../../config/constants';
import labels from '../../../config/localization';
import { Typeahead } from 'react-bootstrap-typeahead';
import FormControl from '../../../components/FormControls/FormControl';

class NewQuestionnaire extends React.Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.visitId) {
            if (this.props.currentVisit && !Object.keys(this.props.currentVisit).length) {
                this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history)
                this.props.setHeaderType(constants.visit_labels.new_questionnaire, false)
            } else {
                this.props.setHeaderType(constants.visit_labels.new_questionnaire, true)
            }
            this.props.getQuestionnaires(this.props.match.params.visitId)
        }
    }
    getDefaultOrders(defaultorders) {
        return defaultorders ? defaultorders.split(',').map(a => ({ name: a })) : []
    }
    render() {
        return (
            <div className='container'>
                <div className='col-md-12 col-sm-12 col-xs-12'>
                    <div className='row new-order'>
                        <div className="col-3">
                            <label>
                                {labels.visit_page_labels.questionnaire_name}
                            </label>
                        </div>
                        <span className='col-9'>
                            <FormControl value={this.getDefaultOrders(this.props.multiSelectQuestionnaire)}
                                onChange={this.props.editMultiSelectQuestionnaire} type='select' id='questionnaireTypeAhead' isMulti={true}
                                getOptionLabel={opt => opt.name}
                                getOptionValue={opt => opt.name}
                                options={this.props.questionnairesList && this.props.questionnairesList.value
                                    ? this.props.questionnairesList.value : []} className='col-lg-7' />
                        </span>
                        {/* <div className='col-9'>
                            <Typeahead
                                placeholder="Choose a questionnaire..."
                                labelKey={option => `${option.name}`}
                                id='panelTypeAhead'
                                options={this.props.questionnairesList && this.props.questionnairesList.value ? this.props.questionnairesList.value : []}
                                renderMenuItemChildren={(option, props) => (
                                    <span id={option.id ? option.id : 0} key={`${option.name}`}>{`${option.name}`}</span>
                                )}
                                onChange={this.props.AddOrder}
                                ref={(ref) => this._typeahead = ref}
                            />
                        </div> */}
                    </div>
                    <br />
                    <div className='row justify-content-center'>
                        <div className='col-6'>
                            <FormControl type='button'
                                btnType={constants.notification_type.success}
                                text={labels.visit_page_labels.btn_questionnaire}
                                onClick={() => { this.props.addNewQuestionnaire(this.props.history) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NewQuestionnaire;
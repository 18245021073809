import React, {useRef,useEffect, useMemo, useState, useCallback} from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import close from '../../../assets/images/close.svg';
import moment from 'moment';
import { Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import labels from '../../../config/localization';
import { connect } from 'react-redux';
import constants from '../../../config/constants'
import _ from 'lodash';
import CardLoader from '../containers/CardLoaderContainer';
import closeLight from '../../../assets/images/close-light.svg';
import Select from 'react-select'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

function ProblemForm(props) {
    
    let code = useRef(null);
    let bodySite = useRef(null);
    useEffect(() => {
        //
    return () => { 
        // componentWillUnmount() //
    }
    }, [])
   
    const stylesClasses = {
        labelClass: 'recommendation-form-label',
        inputClass: 'recommendation-form-input'
    }

    const CustomStyle = {
        option: (base, state) => ({
          ...base,
          marginLeft: state.data.subOption ? "12px": '0px',
          fontWeight:  state.data.subOption ? "400": '500',
        }),
        control: (base, state) => ({
            ...base,
            borderColor: "lightgray",
            "&:focus": {
                borderColor: "#80bdff"
            }
        })
    }
   const checkForChanges = () => {
        let initialProblemForm = props.initialProblemForm ? { ...props.initialProblemForm } : {};
        let problemForm = {}
        if (props.problemForm) {
            problemForm["clinicalStatus"] = props.problemForm["clinicalStatus"];
            problemForm["verificationStatus"] = props.problemForm["verificationStatus"];
            problemForm["category"] = props.problemForm["category"];
            problemForm["severity"] = props.problemForm["severity"];
            problemForm["code"] = props.problemForm["code"];
            problemForm["bodySite"] = props.problemForm["bodySite"];
            problemForm["onSetType"] = props.problemForm["onSetType"];
            problemForm["onSetValue1"] = props.problemForm["onSetValue1"];
            problemForm["onSetValue2"] = props.problemForm["onSetValue2"];
            problemForm["abatementType"] = props.problemForm["abatementType"];
            problemForm["abatementValue1"] = props.problemForm["abatementValue1"];
            problemForm["abatementValue2"] = props.problemForm["abatementValue2"];
            problemForm["recordedDate"] = props.problemForm["recordedDate"];
            problemForm["notes"] = props.problemForm["notes"];
        }

        Object.keys(problemForm).forEach((p) => problemForm[p] == null || problemForm[p] == undefined || problemForm[p] == "" ? delete problemForm[p] : '');
        Object.keys(initialProblemForm).forEach((p) => initialProblemForm[p] == null || initialProblemForm[p] == undefined || initialProblemForm[p] == "" ? delete initialProblemForm[p] : '');
        if (_.isEqual(problemForm, initialProblemForm) && !_.isEqual(initialProblemForm, { })) {
            props.showNoProblemFormChangesError(true);
            return true;
        }
        else
            return false;

    }
    
    const saveProblem = () => {
        let noFormChanges = checkForChanges();
        if (!noFormChanges) {
            props.saveProblem(props.history, props.isPlanModal)
        }
    }
    
    return (
        <>
            <div className='drawer-warning-overlay'></div>
                <div className='recommendation problem'>
                    <div className='drawer-warning'>
                        { props.loadingProblemList != 'finished' ?
                            <>
                                <div className='alert alert-success'>
                                <i class="fa fa-spinner fa-spin medication-loading-spinner"></i>
                                <span>We are getting your problem list ready. Please wait for a while!</span>
                                </div>
                                <CardLoader  />
                            </>
                            :
                        <>
                            <div className='row header no-gutters'>
                                <div className='col-11 bold-14-text'>{'Add Problem' }</div>
                                <div className='col-1 pt-1'>
                                    <a><img className='float-right' src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleProblemModal(false))}></img></a>
                                </div>
                            </div>
                            { props.isLoading ?
                            <CardLoader  />
                            : 
                            <div className='row card-body-div no-gutters'>
                                {props.problemErrors && props.problemErrors.length ?
                                    <div className='alert alert-danger d-flex'>
                                        {props.problemErrors.map(re => <p>{re}</p>)}
                                    </div> : null
                                }
                                
                                <div className="col-12 mt-2">
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-4 col-md-4 col-xs-12  form-group'>
                                            <label className='recommendation-form-label'><span title='required'>*</span>{labels.analytics_labels.clinicalStatus}</label>
                                            <Select
                                                id='clinicalStatus'
                                                defaultValue={ (props.problemForm && props.problemForm.clinicalStatus ? {value: props.problemForm.clinicalStatus,label: constants.prolemClinicalStatus.find(a => a.value == props.problemForm.clinicalStatus).label } : null)}
                                                options={constants.prolemClinicalStatus}
                                                onChange={(e) => {props.editProblem(e, "clinicalStatus")}}
                                                styles={CustomStyle}
                                                isSearchable={false}
                                                required={true}
                                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                />    
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-xs-12  form-group'>
                                            <label className='recommendation-form-label'><span title='required'>*</span>{labels.analytics_labels.verificationStatus}</label>    
                                             <Select
                                                id='verificationStatus'
                                                defaultValue={ (props.problemForm && props.problemForm.verificationStatus ? {value: props.problemForm.verificationStatus,label: constants.prolemVerificationStatus.find(a => a.value == props.problemForm.verificationStatus).label } : null)}
                                                options={constants.prolemVerificationStatus}
                                                onChange={(e) => {props.editProblem(e, "verificationStatus")}}
                                                styles={CustomStyle}
                                                isSearchable={false}
                                                required={true}
                                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                />     
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-xs-12 form-group'>
                                            <label className='recommendation-form-label'>{labels.analytics_labels.severity}</label>    
                                            <Select
                                                id='severity'
                                                defaultValue={ (props.problemForm && props.problemForm.severity ? {value: props.problemForm.severity, label: constants.severityOption.find(a => a.value == props.problemForm.severity).label } : null)}
                                                options={constants.severityOption}
                                                onChange={(e) => {props.editProblem(e, "severity")}}
                                                styles={CustomStyle}
                                                isSearchable={false}
                                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                />
                                        </div>
                                    </div>
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-6 col-md-6 col-xs-12'>
                                            <div className='form-group custom-input'>
                                                <label className='recommendation-form-label'>Select {labels.analytics_labels.code}</label>
                                                <Typeahead
                                                    labelKey="value"
                                                    allowNew
                                                    onBlur={(e) => {
                                                        if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                                        props.editProblem([{ value: e.target.value }], "code")
                                                        }
                                                    }
                                                    }
                                                    onKeyDown={(e) => { props.editProblem([{value: e.target.value ? e.target.value : ""}], "code")}}
                                                    selected={props.problemForm && props.problemForm.code  ? [{ value: props.problemForm.code }] : null}
                                                    id='code'
                                                    options={props.code_list ? props.code_list.filter(code => code.value != "" && code.value != null) : []}
                                                    renderMenuItemChildren={(option, props) => (
                                                    <span id={option.value} key={option.value}>{option.value}</span>
                                                    )}
                                                    onChange={(e) => { e.length ? props.editProblem(e, "code") : ""}}
                                                    newSelectionPrefix={null}
                                                    ref={code}
                                                    {...stylesClasses}
                                                    placeholder="Select..."
                                                />

                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-xs-12'>
                                            <div className='form-group custom-input'>
                                                <label className='recommendation-form-label'>Select {labels.analytics_labels.bodySite}</label>
                                                <Typeahead
                                                    labelKey="value"
                                                    allowNew
                                                    onBlur={(e) => {
                                                        if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                                        props.editProblem([{ value: e.target.value }], "bodySite")
                                                        }
                                                    }
                                                    }
                                                    onKeyDown={(e) => { props.editProblem([{value: e.target.value ? e.target.value : ""}], "bodySite")}}
                                                    selected={props.problemForm && props.problemForm.bodySite  ? [{ value: props.problemForm.bodySite }] : null}
                                                    id='bodySite'
                                                    options={props.bodySite_list ? props.bodySite_list.filter(bodySite => bodySite.value != "" && bodySite.value != null) : []}
                                                    renderMenuItemChildren={(option, props) => (
                                                    <span id={option.value} key={option.value}>{option.value}</span>
                                                    )}
                                                    onChange={(e) => { e.length ? props.editProblem(e, "bodySite") : ""}}
                                                    newSelectionPrefix={null}
                                                    ref={bodySite}
                                                    {...stylesClasses}
                                                    placeholder="Select..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-4 col-md-4 col-xs-4 form-group'>
                                            <label className='recommendation-form-label'>{labels.analytics_labels.onset}</label>
                                            <Select
                                                id='onSetType'
                                                defaultValue={ (props.problemForm && props.problemForm.onSetType ? {value: props.problemForm.onSetType,label: constants.fuzzyTimeOption.find(a => a.value == props.problemForm.onSetType).label } : null)}
                                                options={constants.fuzzyTimeOption}
                                                onChange={(e) => {props.editProblem(e, "onSetType")}}
                                                styles={CustomStyle}
                                                isSearchable={false}
                                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                />    
                                        </div>

                                        { props.problemForm && props.problemForm.onSetType && props.problemForm.onSetType == "period" ?  
                                           <>
                                               {
                                               [
                                                {field: 'onSetValue1', field_label: labels.analytics_labels.start_date},
                                                {field: 'onSetValue2', field_label:labels.analytics_labels.end_date}
                                               ].map((field ) => (
                                                <div className='col-lg-4 col-md-4 col-xs-4 date-field'>
                                                    <FormControl id={field.field}
                                                        label={field.field_label}
                                                        type='date'
                                                        selected={
                                                            props.problemForm && props.problemForm[field.field] ?
                                                            new Date( props.problemForm[field.field]) : ''}
                                                        onChange={(e) => props.editProblem(e, field.field)}
                                                        disabledKeyboardNavigation
                                                        calendarIcon={true}
                                                        popperPlacement={"top"}
                                                        {...stylesClasses}
                                                        showTimeSelect={true}
                                                        timeFormat="HH:mm"
                                                        dateFormat="dd/MM/yyyy HH:mm"  
                                                        maxDate={  field.field == 'onSetValue1' &&
                                                            props.problemForm && props.problemForm['onSetValue2'] ?
                                                            moment(props.problemForm['onSetValue2']).add(-1, 'days').toDate() : ""}
                                                        
                                                        minDate={ field.field == 'onSetValue2' &&
                                                            props.problemForm && props.problemForm['onSetValue1'] ?
                                                            moment(props.problemForm['onSetValue1']).add(1, 'days').toDate() : ""}    
                                                    />
                                                </div>
                                               ))
                                                }
                                            </>
                                        
                                        : props.problemForm && props.problemForm.onSetType && props.problemForm.onSetType == "date" ?
                                            <div className='col-lg-4 col-md-4 col-xs-4 date-field'>
                                                <FormControl id='onSetValue1'
                                                    label={labels.analytics_labels.date}
                                                    type='date'
                                                    selected={
                                                        props.problemForm && props.problemForm.onSetValue1 ?
                                                        new Date(props.problemForm.onSetValue1) : ''}
                                                    onChange={(e) => props.editProblem(e, "onSetValue1")}
                                                    disabledKeyboardNavigation
                                                    calendarIcon={true}
                                                    popperPlacement={"top"}
                                                    {...stylesClasses}
                                                    showTimeSelect={true}
                                                    timeFormat="HH:mm"
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                />
                                            </div>
                                        :    props.problemForm && props.problemForm.onSetType && props.problemForm.onSetType == "age" ?
                                            <div className='col-lg-4 col-md-4 col-xs-4'>
                                                <FormControl id='onSetValue1'
                                                    label={labels.analytics_labels.age}
                                                    type='text'
                                                    value={
                                                        props.problemForm && props.problemForm.onSetValue1 ?
                                                        props.problemForm.onSetValue1 : ''}
                                                    onChange={props.editProblem}
                                                    disabledKeyboardNavigation
                                                    {...stylesClasses}
                                                />
                                        </div>
                                        : ""
                                      }
                                    </div>

                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-4 col-md-4 col-xs-4 form-group'>
                                            <label className='recommendation-form-label'>{labels.analytics_labels.abatement}</label>
                                            <Select
                                                id='abatementType'
                                                defaultValue={ (props.problemForm && props.problemForm.abatementType ? {value: props.problemForm.abatementType,label: constants.fuzzyTimeOption.find(a => a.value == props.problemForm.abatementType).label } : null)}
                                                options={constants.fuzzyTimeOption}
                                                onChange={(e) => {props.editProblem(e, "abatementType")}}
                                                styles={CustomStyle}
                                                isSearchable={false}
                                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                />    
                                        </div>
                                        { props.problemForm && props.problemForm.abatementType && props.problemForm.abatementType == "period" ?  
                                           <>
                                               {
                                               [
                                                {field: 'abatementValue1', field_label: labels.analytics_labels.start_date},
                                                {field: 'abatementValue2', field_label:labels.analytics_labels.end_date}
                                               ].map((field ) => (
                                                <div className='col-lg-4 col-md-4 col-xs-4 date-field'>
                                                    <FormControl id={field.field}
                                                        label={field.field_label}
                                                        type='date'
                                                        selected={
                                                            props.problemForm && props.problemForm[field.field] ?
                                                            new Date(props.problemForm[field.field]) : ''}
                                                        onChange={(e) => props.editProblem(e, field.field)}
                                                        disabledKeyboardNavigation
                                                        calendarIcon={true}
                                                        popperPlacement={"top"}
                                                        {...stylesClasses}
                                                        showTimeSelect={true}
                                                        timeFormat="HH:mm"
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        maxDate={ field.field == 'abatementValue1' &&
                                                            props.problemForm && props.problemForm['abatementValue2'] ?
                                                            moment(props.problemForm['abatementValue2']).add(-1, 'days').toDate() : ""}
                                                        
                                                        minDate={ field.field == 'abatementValue2' &&
                                                            props.problemForm && props.problemForm['abatementValue1'] ?
                                                            moment(props.problemForm['abatementValue1']).add(1, 'days').toDate() : ""}
                                                    />
                                                </div>
                                               ))
                                                }
                                            </>
                                        
                                        : props.problemForm && props.problemForm.abatementType && props.problemForm.abatementType == "date" ?
                                            <div className='col-lg-4 col-md-4 col-xs-4 date-field'>
                                                <FormControl id='abatementValue1'
                                                    label={labels.analytics_labels.date}
                                                    type='date'
                                                    selected={
                                                        props.problemForm && props.problemForm.abatementValue1 ?
                                                        new Date(props.problemForm.abatementValue1) : ''}
                                                    onChange={(e) => props.editProblem(e, "abatementValue1")}
                                                    disabledKeyboardNavigation
                                                    calendarIcon={true}
                                                    popperPlacement={"top"}
                                                    {...stylesClasses}
                                                    showTimeSelect={true}
                                                    timeFormat="HH:mm"
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                />
                                            </div>
                                        :    props.problemForm && props.problemForm.abatementType && props.problemForm.abatementType == "age" ?
                                            <div className='col-lg-4 col-md-4 col-xs-4'>
                                                <FormControl id='abatementValue1'
                                                    label={labels.analytics_labels.age}
                                                    type='text'
                                                    value={
                                                        props.problemForm && props.problemForm.abatementValue1 ?
                                                        props.problemForm.abatementValue1 : ''}
                                                    onChange={props.editProblem}
                                                    disabledKeyboardNavigation
                                                    {...stylesClasses}
                                                />
                                        </div>
                                        : ""
                                      }
                                    </div>
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-12 col-md-12 col-xs-12 notes form-group'>
                                            <div className='row'>
                                                <div className='col-11'>
                                                    <label className='recommendation-form-label'>{labels.analytics_labels.notes}</label>
                                                </div>    
                                                <div className='col-1 justify-content-center d-flex'>
                                                    <span onClick={() =>props.newProblemNotes() }>
                                                        <AddBoxRoundedIcon style={{ color: 'green', fontSize: '20px', cursor: 'pointer' }} />
                                                    </span>
                                                </div>
                                            </div>        
                                            {
                                                props.notesList && props.notesList.map((element, index) => (  
                                                    <div className='row'>
                                                        <div className='col-11'>
                                                            <FormControl type="textarea" id= "notes"
                                                                value={props.problemForm && props.problemForm.notes && props.problemForm.notes[index] &&props.problemForm.notes[index].note && props.problemForm.notes[index].note ? props.problemForm.notes[index].note : ""} 
                                                                inputClass={"mt-2"} placeholder={"Write here.."}
                                                                onChange={ (e) => props.addProblemNotes(index, e, element.uuid)} />
                                                        </div>
                                                        <div className='col-1'>
                                                            <i className="fa fa-trash status-indicator text-danger trash-icon pt-3 justify-content-center d-flex" onClick={() =>props.removeProblemNotes(element.uuid)}> </i> 
                                                        </div>
                                                    </div>
                                                ))
                                            } 
                                        </div>         
                                    </div>
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-4 col-md-4 col-xs-12 date-field'>
                                            <FormControl id='recordedDate'
                                                label={labels.analytics_labels.recordedDate}
                                                type='date'
                                                selected={
                                                    props.problemForm && props.problemForm.recordedDate ?
                                                    new Date(props.problemForm.recordedDate) : new Date()}
                                                onChange={(e) => props.editProblem(e, "recordedDate")}
                                                disabledKeyboardNavigation
                                                calendarIcon={true}
                                                popperPlacement={"top"}
                                                {...stylesClasses}
                                                required={true}
                                                showTimeSelect={true}
                                                timeFormat="HH:mm"
                                                dateFormat="dd/MM/yyyy HH:mm"
                                            />
                                        </div>
                                    </div>
                                    {renderWarning((props.isDemo || props.isPatientDemo))}
                                    <div className='d-flex row'>
                                        <div className='col-lg-8 col-md-8 col-sm-8 col-12'></div>
                                        <div className='col-lg-4 col-md-4 col-sm-4 col-12 d-flex justify-content-end'>

                                        <button className='cancle-btn btn mr-3' 
                                            onClick={() => {
                                                let clinicalStatus = props.problemForm && props.problemForm.clinicalStatus ? props.problemForm.clinicalStatus : '';
                                                let verificationStatus = props.problemForm && props.problemForm.verificationStatus ? props.problemForm.verificationStatus : '';
                                                let category = props.problemForm && props.problemForm.category ? props.problemForm.category : '';
                                                let severity = props.problemForm && props.problemForm.severity ? props.problemForm.severity : '';
                                                let code = props.problemForm && props.problemForm.code ? props.problemForm.code : '';
                                                let bodySite = props.problemForm && props.problemForm.bodySite ? props.problemForm.bodySite : '';
                                                let onSetType = props.problemForm && props.problemForm.onSetType ? props.problemForm.onSetType : '';
                                                let onSetValue1 = props.problemForm && props.problemForm.onSetValue1 ? props.problemForm.onSetValue1 : '';
                                                let onSetValue2 = props.problemForm && props.problemForm.onSetValue2 ? props.problemForm.onSetValue2 : '';
                                                let abatementType = props.problemForm && props.problemForm.abatementType ? props.problemForm.abatementType : '';
                                                let abatementValue1 = props.problemForm && props.problemForm.abatementValue1 ? props.problemForm.abatementValue1 : '';
                                                let abatementValue2 = props.problemForm && props.problemForm.abatementValue2 ? props.problemForm.abatementValue2 : '';
                                                let recordedDate = props.problemForm && props.problemForm.recordedDate ? props.problemForm.recordedDate : '';
                                                let notes = props.problemForm && props.problemForm.notes ? props.problemForm.notes : [];
                                                props.showNoProblemFormChangesError(false);
                                                let problemForm = { clinicalStatus: clinicalStatus, verificationStatus: verificationStatus, category: category, severity: severity, code: code, bodySite: bodySite, onSetType: onSetType, onSetValue1: onSetValue1, onSetValue2: onSetValue2, abatementType: abatementType, abatementValue1: abatementValue1, abatementValue2: abatementValue2, recordedDate: recordedDate, notes: notes }
                                                let initialProblemForm = props.initialProblemForm ? { ...props.initialProblemForm, recordedDate: recordedDate } : { };
                                                Object.keys(problemForm).forEach((p) => problemForm[p] == null || problemForm[p] == undefined || problemForm[p] == '' ? delete problemForm[p] : '');
                                                Object.keys(initialProblemForm).forEach((p) => initialProblemForm[p] == null || initialProblemForm[p] == undefined || initialProblemForm[p] == '' ? delete initialProblemForm[p] : '');
                                                if (problemForm == "" || _.isEqual(problemForm, initialProblemForm)) {
                                                    props.toggleProblemModal(false)
                                                }
                                                else
                                                    props.toggleProblemFormWarning(true);
                                            }} >Cancel</button>

                                            <button className='add-btn btn' onClick={ () => {saveProblem()}}>Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </> 
                    } 
                    </div>
               </div>
        </>
    )
}

const renderWarning = (shouldRender) => {
    if (shouldRender) {
      return (
        <div className='alert alert-warning d-flex'>
          <div className='icon lg alert-icon'>
            <svg aria-hidden='true' title='Persistence Warning'>
              <use xlinkHref="#x" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
              <symbol id="x" viewBox="0 0 512 512">
                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
              </symbol>
            </svg>
          </div>
          <div className='alert-content'>
            <strong>{labels.analytics_labels.changes_wont_stick}</strong>
            <p>{labels.analytics_labels.demo_mode}</p>
          </div>
        </div>
      )
    }
    else {
      return (null);
    }
}

export default connect()(ProblemForm);
export default {
    physioage_label: 'PhysioAge',
    physioage_footer_label: 'PhysioAge Systems, LLC. All rights reserved.',
    physioage_report_label: 'PhysioAge Reporting',
    site_label: 'Site',
    login_help: 'If you are having difficulty resetting your password, feel free to call our support number',
    login_help_text: 'Login with your email address.',
    login_btn_text: 'Sign in',
    signup_btn_text: 'Sign up',
    forgot_password_text: 'Forgot Password',
    forgot_password_label: 'Request Password Reset',
    email_label: 'Email',
    forgot_password_instruction: 'Instructions to reset your password will be delivered to the address above if you have an account.',
    navigate_login: 'Return to Login Screen',
    reset_my_password_label: 'Reset my password',
    inside_us: 'Inside the United States',
    outside_us: 'Outside the United States',
    done_label: 'Done',
    return_login_btn: 'Return to Login Page',
    reset_password_label: 'Reset Your Password',
    password: 'Password',
    confirm_password: 'Confirm Password',
    update_password: 'Update Password',
    update_password_btn: 'Update my Password',
    try_again: 'Please try again:',
    link_expired: 'Link Expired',
    link_expired_notification: 'The link you used to reset your password expired. Typically this happens when you wait several days to use the link, or when you (or someone else) went through the process of ressing your password again.',
    set_password: 'Set Your Password',
    set_password_notification: 'Your password must be changed before you can access your data. We know passwords are a hassle. But we think the next step will be worth your time.',
    login_caution_start: 'When you login, you agree to be bound by our',
    login_caution_conj: 'and',
    invalid_email: `Invalid Email Address. Please provide a valid email address`,
    login_cookies: 'including but not limited to allowing the use of cookies.',
    unauth_login: `You must be an administrator to access this page.`,
    something_went_wrong: 'Something went wrong',
    not_available: 'NA',
    none: 'None',
    login_label: 'Login',
    signup_title: 'PhysioAge Analytics Enrollment',
    next_label: 'Next',
    payment_gateway: 'PaymentGateway',
    payment_details: 'Payment Details',
    back_to_signup: 'Back',
    confirm_payment: 'Confirm Payment',
    first_name: 'First Name',
    last_name: 'Last Name',
    patient_downloads: 'Patient Downloads',
    proceed_to_add_credits: 'Proceed to Add Credits',
    document_list_for: 'Document List for',
    no_recommandation_changes: 'No new changes were made to Recommend',
    no_medication_changes: 'No new changes were made to Medication',
    no_problem_changes: 'No new changes were made to Problem',
    pagenotfound: {
        title: 'Page Not Found',
        help: 'You are looking for something which is not there',
        redirect_label: 'Redirect to home',
        login_label: 'Login again',
        session_expired: 'Session has expired',
        back_label: 'Navigate to home'
    },
    terms_and_conditions: 'Terms and Conditions',
    footer_labels: {
        privacy: 'Privacy Policy',
        tos: 'Terms of Service'
    },
    header_labels: {
        credits: 'Credits',
        account: 'Account',
        user_settings: 'User Settings',
        logout: 'Logout',
        feedback: 'Feedback',
        patients: 'Patients',
        help: 'Help',
        user_guide: 'User Guide',
        user_manuals: 'User Manuals',
        current_manual: 'Biomarker Manual',
        biomarker_interpretation: 'Biomarker Interpretation Guide',
        account_setup: 'Account Setup',
        all_manuals: 'All Manuals',
        videos: 'Videos',
        ops: 'OPS',
        active_site_report: 'Active Site Report',
        providers: 'Providers',
        sites: 'Sites',
        elk: 'ELK',
        users: 'Users',
        billing_summary: 'Billing Summary',
        message_routing: 'Message Routing',
        inbox: 'Inbox',
        oddities: 'Oddities',
        corrections: 'Corrections',
        data: 'Data',
        explore_noumena: 'Explore Noumena',
        compare_noumena: 'Compare Noumena',
        volume_report: 'Volume Report',
        vadr: 'VADR',
        remaining_label: 'remaining',
        practice: 'Practice',
        explore_data: 'Explore Data',
        download_library: 'Download Library',
        failure_messages: 'Failure Messages',
        schedule_a_call: 'Schedule a call',
        customize_noumena: 'CUSTOMIZE NOUMENA',
        feedback: 'Feedback',
        caregivers: 'Caregivers'
    },
    patient_list_labels: {
        title: 'Patients List',
        patient_title: 'Patient',
        filter_patients_label: 'Filter Patients',
        site_label: 'Site',
        name_label: 'Name',
        min_visits_label: 'Minimum visits',
        search: 'Search',
        title_label: 'Patient List',
        add_patient: 'Add Patient',
        patient_title_new: 'PhysioAge: New Patient',
        new_patient_title: 'New Patient form',
        edit_patient_form: 'Edit Patient form',
        honorific_prefix: 'Honorific Prefix',
        honorific_suffix: 'Honorific Suffix',
        honorific_suffix_help: 'CPA, DDS, MDiv.',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        dob: 'Date of Birth',
        dob_help: 'Once you have selected the year and month, you MUST click on the day.',
        site: 'Site',
        provider: 'Provider',
        language_pref: 'Language preference',
        save: 'Save',
        male: 'Male',
        female: 'Female',
        add_visit: 'Add Visit',
        edit_Patient: 'Edit Patient',
        patient_details: 'Patient Details',
        patient_portal: 'Patient Portal',
        view_patient_portal: 'View Patient Portal',
        enable_patient_portal: 'Enable Patient Portal',
        resend_email: 'Resend Welcome Email',
        portal_caution: ' They have not used it.',
        portal_last_viewed: ' Last viewed',
        portal_help: 'The welcome email has been sent',
        portal_mail_help: 'Before we can allow this patient to access the patient portal, we need their email address. Please enter it below.',
        enable_portal_caution: 'This patient does not have access to the patient portal. Click the button below to enable access.',
        update_patient: 'Update Patient',
        edit_label: 'Edit',
        all_sites: 'All Sites',
        gender_label: 'Gender',
        no_visits: 'No Visits',
        clear_search: 'Clear Search',
        create_visit: 'Create Visit',
        visit_date: 'Visit Date ',
        visits: 'Visits',
        add_visit_help: 'Select a date to add visit',
        view_health_analytics: 'View Health Analytics',
        view_health_analytics_beta: 'Analytics Beta',
        view_health_analytics_old: 'Analytics Old',
        credits_elk: `You don't have credits to add Visit`,
        future_date: ` is too far in the future `,
        agg_age_chart: 'Aggregate Aging Chart',
        resend_welcome_email: `We will send another welcome email to this user within the next 10 minutes.`,
        no_visit_caution: `This patient does not have access to the patient portal.
                            Once you have set up a visit for them, you'll be able to provide access to them.`,
        no_visits_patients: `Each patient visit must be set up before data will be available. Currently this system doesn't know about any visits for this patient.`,
        data_entry: 'Data Entry',
        never: 'Never',
        new_visits: 'New visits for',
        providers_validate: 'Select Sites to get providers',
        sex: 'Sex',
        last_active: 'Last Active',
        total_patients: 'Total Patients',
        total_visits: 'Total Visits',
        avg_visit_count: 'Avg. Visit Ct.',
        avg_data: 'Avg. Data',
        identifier: 'Identifier',
        identifier_help: 'Optional field to store your local patient identifiers',
        delete_patient: 'Delete Patient',
        delete_patient_prompt: 'Do you want to delete this patient permanently',
        delete_patient_desc: "This will delete all the visits associated with the patient and its data",
        patient_id: "Patient ID",
        view_demo_analytics: 'View Demo Analytics'
    },
    custom_noumenon_list: {
        noumenon_name_label: 'Noumenon Name',
        noumenon_code_label: 'Noumenon Code',
        units: 'Units',
        add_custom_noumenon: 'Add Noumenon',
        customize_noumenon: 'Customize Noumenon',
        customize_noumenon_list: 'Current Noumenon List',
        new_customize_noumenon: 'New Noumenon',
        edit_customize_noumenon: 'Edit Noumenon',
        add_rubric: 'Add Rubric',
        new_rubric: 'New Rubric',
        edit_rubric: 'Edit Rubric',
        new_range: 'New Range',
        edit_range: 'Edit Range',
        range: 'Range',
        no_customize_noumenon_changes: 'No new changes were made to Noumenon',
        no_rubric_changes: 'No new changes were made to Rubric',
        no_range_changes: 'No new changes were made to Range',
        noumenon: 'Noumenon',
        noumenonName: 'Noumenon',
        noumenonCode: 'Code',
        scale: "Scale",
        scale_help: "This scale is used to set the precision to the value. If you don't choose, it uses default precision i.e. 3 Point.",
        phase: 'Phase',
        startingAge: 'Starting Age',
        endingAge: 'Ending Age',
        startingHeight: 'Starting Height',
        endingHeight: 'Ending Height',
        type: 'Type',
        interpretation: 'Interpretation',
        interpretation_help: 'Only enter a description if different from what you selected above.',
        grade: "Grade",
        grade_help: "Only select this if different from the typical grade for the type above.",
        low: 'Low',
        high: 'High'

    },
    feedback_labels: {
        help_text: "We welcome and appreciate any feedback you have. We're interested to hear any thoughts you have about how to improve our application.",
        save_text: 'Send Feedback',
        title: 'Feedback',
        feedback_success: 'Your feedback has been delivered. Thank you for your input.',
        feedback_list: 'Feedback List',
        comment: 'Comment',
        rating: 'Rating',
        userID: 'User Id',
        source: 'Source'
    },
    user_labels: {
        edit: 'Edit',
        title: 'User List',
        new_user: 'New User',
        edit_user: 'Edit User',
        account_settings: 'Account Settings',
        email_addresses_help: 'Email addresses for all current users:',
        email_addresses: 'Email addresses:',
        firstname: 'First Name',
        lastname: 'Last Name',
        username: 'Username',
        available_provider: 'Available provider',
        email: 'Email',
        latest_visit_date: 'Latest Visit Date',
        active: 'Active',
        activeWithQ: `Active?`,
        site: 'Site',
        role: 'Role',
        update_user: 'Update User',
        reset_password: 'Reset Password',
        type_password: 'Type password',
        confirm_password: 'Confirm Password',
        update_password: 'Update Password',
        retype_password: 'Retype Password',
        password: 'Password',
        add_user: 'Add User',
        password_not_matched: 'Passwords did not match',
        job_type: 'Job Type',
        provider_label: 'Provider',
        gender_label: 'Gender',
        filter_users: 'Filter Users',
        master: 'Master (A user can delete a patient or a visit)',
        provider: 'Is a Provider?',
        primary_contact: 'Primary Contact (A primary contact for a site)',
        signup_comments: `Anything else you'd like to add?`,
        user_survey_label: 'How did you hear about us?',
        conference_label: 'Conference',
        newsletter_label: 'Newsletter',
        internet_search_label: 'Internet Search',
        magazine_label: 'Magazine',
        television_label: 'Television',
        friend_label: 'Friend',
        others_label: 'Others',
        choose_plan: 'Choose Plan',
        plan: 'Plan',
        view: 'View',
        delete: 'Delete',
        siteId: 'Site ID',
        enter_the_code: 'Enter the code',
        login_into_account: 'LOGIN IN TO YOUR ACCOUNT',
        two_factor_auth_required: 'Two-factor authentication required',
        send_6_digit_code: `Enter the 6-digit verification code we've sent to`,
        enter_the_code_gen_by_auth: 'Enter the code generated by your authenticator app for physioage.com',
        code: 'Code',
        invalid_code: 'The code you entered is incorrect, Please try again.',
        resend_code: 'Resend code',
        resend_code_in: 'Resend code in ',
        seconds: ' seconds',
        done: 'Done',
        email_verification_successfully_done: 'Your email verification has been successfully completed.',
        previous: 'Previous',
        next: "Next",
        cancel: 'Cancel',
        enter_the_code_manually: 'Enter code manually instead',
        use_authenticator_app: 'Use your authenticator app (Google Authenticator, Duo Mobile or others) to scan QR code.',
        dont_have_app: 'Don’t have an authenticator app?',
        connect_your_auth_app: 'Connect your authenticator app',
        success_auth_app: ' Your authenticator app has been connected successfully.',
        authenticate_via_email: "Authenticate via email",
        authenticate_via_app: "Authenticate via app",
        enter_your_full_email_address_as_your: "Enter your full email address as your accoiunt name, then enter this code into your authenticator app",
        open_your_auth_app_select_to_manually: "Open your authenticator app select to manually add your account.",
        scan_qr_code_insted: "Scan QR code instead",
        get_the_google_auth_app: "Get the Google Authenticator app",
        download_the_google_auth_app_by_scanning: "Download the Google Authenticator app by scanning this QR on your mobile phone."

    },
    credits_labels: {
        title: 'Report Credits',
        add_credits: 'Add Credits',
        download: 'Download',
        history: 'History',
        receipt: 'receipt',
        receipt_header: 'Receipt',
        date: 'Date :',
        client: 'Client :',
        description: 'Description',
        quantity: 'Quantity',
        amount: 'Amount',
        ok_btn: 'OK',
        credit_transactions_name: 'credit_transactions.csv'
    },
    sites_labels: {
        new_site_label: 'New Site',
        sites_list_label: 'Sites',
        total_sites: 'Total Sites',
        total_clinic: 'Total Clinics',
        new_clinic: 'New Clinics this Q',
        active_clinic: 'Active Clinics this Q',
        add_site: 'Add a Site',
        list_sites_filter: 'List Sites Accepting Referrals',
        list_sites_filter_title: 'Sites Accepting Referrals',
        all_sites: 'List All Sites',
        name_label: 'Name',
        email: 'Email',
        category_label: 'Category',
        billing_label: 'Billing',
        credits_label: 'Credits',
        providers_label: 'Providers',
        patients_label: 'Patients',
        language_label: 'I18N',
        units_label: 'Units',
        recent_visits: 'Recent Visits',
        active: 'Active',
        date_preference_help_tip: 'Example: MDY = June 12, 1968',
        logo_help_tip: 'Upload a high-resolution logo file to be featured on reports and your Patient Portal. (Image size: Minimum 150x150 pixels, Image File: png, jpg/jpeg or gif). Don’t have a suitable logo file handy at the moment? Just send it to support@physioage.com when you do.',
        name_help: 'This is the name that will appear in the patient portal and be the name of the sender when we email links to patients. It will also be used in breadcrumbs and other places throughout the site.',
        email_help: 'This will be the email address of the sender when we email links to patients',
        site_referrals_help: "Site Accepts 'Provider Finder' Referrals",
        preferences: 'Localization Preferences',
        language_preference: 'Language preference',
        date_preference: 'Date preference',
        unit_preference: 'Unit preference',
        electronic_lab_interfaces: 'Electronic Lab Interfaces',
        quest_account_number: 'Quest Account Number',
        labcorp_account_number: 'Labcorp Account Number',
        unit_preference_help: 'Changing this for an existing site could take a while. Suggest you consult a developer.',
        unit_preference_help_tip: 'Example: Imperial uses inches, SI uses centimeters',
        margins_help: 'Add margins to accommodate double-sided hole punches',
        logo_label: 'Logo',
        banner_logo_label: 'Banner logo',
        settings: 'Other Settings',
        sites_message_acknowledge: 'Site Accepts Messages',
        analytics_portal_acknowledge: 'Analytics As Portal',
        billing_strategy: 'Billing Strategy',
        ucla_practice_code: 'UCLA Practice Code',
        trudiag_api_login_id: 'TruDiagnostics API Login Id',
        trudiag_transaction_key: 'TruDiagnostics Transaction Key',
        glycanage_api_login_name: 'GlycanAge API Login Username',
        glycanage_transaction_key: 'GlycanAge Transaction Key',
        brand: 'Brand',
        default_panels: 'Default Panels',
        referral_info: 'Referral and Publicity Information',
        address_help: 'This address will be provided to potential patients through the Provider Finder and may be plotted on a map.',
        electronic_lab_interfaces_help: 'Provide your lab account numbers to import results electronically. We will configure Quest and labcorp interfaces for you at no charge with your Analytics Basic subscription.',
        default_panels_tip: `We can configure your account to collect data from diverse sources. Select the panels you'd like to include with every visit. There is no additional charge per panel, but some panels require special equipment to collect their required parameters. To minimize clutter, only select the panels you use on a regular basis. Additional panels can be easily added to any visit.`,
        payment_gateway_info: `Please enter your credit card information below. Your card will be charged for the first monthly subscription fee 60 days after your account is provisioned. You may cancel at any time.`,
        address_label: 'Address',
        city_label: 'City',
        street1_label: 'Street1',
        street2_label: 'Street2',
        state_label: 'State',
        postal_code: 'Postal Code',
        country: 'Country',
        phone: 'Phone',
        phone_help: 'This number will be provided to patients and used by PhysioAge in case we need to contact them about site outages.',
        website_label: 'Website',
        website_help: `Website must start with either http or https, like 'https://www.myclinic.com'
        `,
        other_info: 'Other Info',
        principle_ehr: 'Principle EHR',
        save_btn: 'Save',
        edit_site: 'Edit',
        add_provider: 'Add Provider',
        add_caregiver: 'Add Caregiver',
        add_user: 'Add User',
        add_elk: 'Add ELKs',
        contact_info: 'Contact Info',
        portal_label: 'Portal',
        accept_referral_label: 'Accept Referrals',
        users_label: 'Users',
        recent_activity: 'Recent Activity',
        lastseen: 'Last seen',
        month_label: 'Month',
        year_label: 'Year',
        visits_label: 'Visits',
        analytics_label: 'Analytics',
        old_portal_label: 'Old Portal',
        transaction_history: 'Transaction History',
        current_image_label: 'Current Image: ',
        referral: 'referral',
        all: 'all',
        not_uploaded: 'Not Uploaded',
        download_attendee: 'Download Attendee List',
        download_patients: 'Download Patient List',
        download_sites: 'Download Site List',
        features_label: 'Features',
        include_phone_in_summary: 'Include Phone Number in Visit Summary',
        instructions: 'Instructions',
        file: 'File',
        title: 'Title',
        publication_date: 'Publication Date',
        create_document: 'Create Document',
        update_document: 'Update Document',
        visible: 'Visible',
        help: 'Help',
        blood_and_urine: 'Blood and Urine',
        optional_biomarkers: 'Optional Biomarkers',
        active: 'Active',
        current_document: 'Current Document',
        status: 'Status',
        total_providers: 'Total Providers',
        total_patients: 'Total Patients',
        total_visits: 'Total Visits',
        last_visit: 'Last Visit',
        id: 'ID',
        units_system: 'Units System',
        what_emr_using: 'What EMR are you using?',
        analytics_beta_label: 'Analytics - Beta',
        gpa_noumena: 'GPA Noumena',
        noumena: 'Noumena Name',
        start_date: 'Start Date',
        end_date: 'End Date',
        add_gpa_noumenon: 'Add GPA Noumenon',
        mfa: 'Multi-Factor authentication (MFA)'
    },
    provider_labels: {
        providers_list: 'Provider List',
        add_provider: 'Add a Provider',
        update_provider: 'Update Provider',
        edit_provider: 'Edit Provider Details',
        edit: 'Edit',
        list_title_label: 'Providers',
        provider_name: 'Provider',
        sites_label: 'Sites',
        patients_label: 'Patients',
        last_visit: 'Last Visit',
        honorific_prefix: 'Honorific prefix',
        firstname: 'Firstname',
        lastname: 'Lastname',
        honorific_suffix: 'Honorific suffix',
        honorific_suffix_help: 'MD, DO, NP, PA, etc..',
        isprovider_help_text: 'It includes billing',
        create_provider: 'Create Provider',
        profile_pic: 'Click on image to change picture',
        image_label: 'Image',
        avatar: '-avatar',
        remove_pic: 'Remove picture',
        filter_providers_label: 'Filter Providers',
        deactivate: 'Deactivate',
        available_providers: 'Available Providers',
        provider_deactivated_successfully: 'provider deactivated successfully',
        provider_not_availale: "No available providers for the site"
    },
    caregiver_labels:{
        list_title_label: 'Caregivers',
        caregiver_name: 'Caregiver',
        add_caregiver: 'Add Caregiver',
        caregiver_list: 'Caregiver List',
        update_caregiver: 'Update Caregiver',
        create_caregiver: 'Create Caregiver',
        edit_caregiver: 'Edit Caregiver Details',
        name: 'Name',
    },
    billing_labels: {
        title: 'Billing Summary',
        site_label: 'Site',
        startdate_label: 'Start Date',
        enddate_label: 'End Date',
        generate: 'Generate',
        num_reports_labels: 'Number of Reports',
        charge_report: 'Charge per Report',
        total_charge: 'Total Charges',
        filter_label: 'Filter',
        physician: 'Physician',
        patient: 'Patient',
        timepoint: 'Timepoint',
        created: 'Created',
        visit_date: 'Visit Date'
    },
    message_routing_labels: {
        list_title: 'Message Routing Rules',
        add_rule: 'Add Rule',
        message_routes: 'Message Routes',
        new_message_routing: 'New Message Routing Rule',
        edit_message_routing: 'Edit Message Routing Rule',
        sending_application: 'Sending Application',
        sending_facility: 'Sending Facility',
        receiving_application: 'Receiving Application',
        receiving_facility: 'Receiving Facility',
        site: 'Site',
        panel: 'Panel',
        add_message_route: 'Create Message Route',
        update_message_route: 'Update Message Route',
        edit_label: 'Edit',
        apply_label: 'Apply',
        pid18_1: 'Pid18 1',
        copy: 'Copy',
        filter_routing_rules: 'Filter Routing Rules',
        panel_label: 'Panel',
        all_panels: 'All Panels'
    },
    help_page_labels: {
        current_manual_title: 'Current Biomarker Manual',
        current_user_title: 'Current User Guide',
        download_label: 'Download',
        delete_label: 'Delete',
        all_manual_title: 'Biomarker Manual history',
        user_manual: 'User Manual History',
        videos_title: 'Training Videos',
        cutometer: 'Cutometry',
        cutometry: 'MPA 580',
        spirometer: 'Pulse Wave Analysis',
        spirometry1: 'SphygmoCor XCEL',
        spirometry2: 'SphygmoCor CP',
        sphygmoCor: 'Spirometry',
        sphygmocor1: 'EasyOneAir',
        sphygmocor2: 'MidMark IQSpiro',
        upload_doc: 'Upload New Document',
        upload_doc_title: 'Upload New Biomarker Manual',
        upload_user_manual_title: 'Upload New User Manual',
        version: 'Version',
        attachment: 'Attachment',
        create_btn_text: 'Create Help File',
        delete_warning: 'Are you sure to delete the manual?',
        ok_btn: 'Ok',
        cancel_btn: 'Cancel',
        helpFile_validation: 'Please upload pdf file',
        version_validation: 'Please enter version',
        invalid_file: 'Invalid file',
        uploaded: 'Uploaded',
    },
    elk_labels: {
        title: 'Credit Transactions',
        filter: 'Filter',
        site: 'Site',
        add_title: 'Add credits for ',
        no_of_sites: 'Number of Credits',
        comment: 'Comment',
        create_credit_btn: 'Create Credit Transaction',
        user: 'User',
        change: 'Change'
    },
    tos_labels: {
        title: 'Terms of Service',
        last_updated: 'Last updated January 31, 2018'
    },
    tac_labels: {
        title1: 'PhysioAge Systems LLC',
        title2: 'PhysioAge Analytics',
        title3: 'Terms of Service'
    },
    privacy_labels: {
        title: 'PRIVACY POLICY',
        last_updated: 'Last updated January 31, 2018'
    },
    corrections_labels: {
        title: 'Corrections',
        name: 'Name',
        date: 'Date',
        description: 'Description'
    },
    oddities_labels: {
        title: 'Unique Oddities',
        oddities: 'Oddities',
        errors: 'All Errors',
        message_label: 'Message',
        count_label: 'Count',
        resolve: 'Resolve',
        ok_btn: 'Ok',
        cancel_btn: 'Cancel',
        close_btn: 'Close',
        observation: 'Observation',
        noumenon: 'Noumenon',
        id: 'ID',
        resultId: 'Result ID',
        valueType: 'Value Type',
        identifier: 'Identifier',
        subIdentifier: 'Sub-Identifier',
        label: 'Label',
        value: 'Value',
        units: 'Units',
        referenceRange: 'Reference Range',
        abnormalFlags: 'Abnormal Flags',
        probability: 'Probability',
        natureOfAbnormalTest: 'Nature of abnormal test',
        resultStatus: 'Result status',
        observedAt: 'Observed at',
        producer: 'Producer',
        observationMethod: 'Observation method',
        equipmentInstance: 'Equipment instance',
        analyzedAt: 'Analyzed at',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        observerType: 'Observer type',
        observerId: 'Observer ID',
        datumId: 'Datum ID',
        result_label: 'Result',
        source_label: 'Source',
        authority_label: 'Authority',
        history: 'History',
        observation_status: 'Status',
        observation_id: 'Observation ID',
        result_date: 'Result Date',
        no_observations: 'No Observations found',
        no_history: 'No History found',
        reference: 'Reference',
        date_time: 'Date / Time',
        resolve_error: 'Are you sure to resolve this error?',
        resolved: 'Resolved',
        category: 'Category',
        examples: 'examples',
        messagegroup: 'Oddity Messages',
        sample_observations: 'Sample Observations',
        observations_info_1: 'observations',
        observations_info_2: ' match identifier',
        visit: 'Visit',
        obx: 'OBX',
        identifier: 'Identifier',
        question: 'Question'
    },
    inbox_labels: {
        title: 'Inbox',
        filter_title: 'Filter By Sender',
        filter_subtitle: 'Filter Message',
        raw_btn: 'Raw',
        resolve_btn: 'Resolve',
        resolve_title: 'Resolve Message',
        demo_modal: 'Launch Demo Modal',
        msg_details: 'Message Details',
        from_label: 'From',
        date_label: 'Date',
        name_only_label: 'Name',
        name_label: 'Patient Name',
        dob_label: 'Patient DOB',
        close_label: 'Close',
        launch_demo_header: 'Raw Content of Message',
        site_label: 'Site',
        all_sites: 'All Sites',
        patient: 'Patient',
        dob: 'DOB',
        dobLabel: 'dob',
        visit: 'Visit',
        received: 'Received',
        processed: 'Processed',
        back: 'Back',
        aboutyear_back: 'about 1 year back',
        about_year_back: 'years back',
        days_back: 'days back',
        reference: 'Reference',
        createdAt: 'Created At',
        processedAt: 'Processed At',
        details: 'Details',
        message_details: 'Message Details',
        oddities: 'Oddities',
        routedTo: 'Routed To',
        imported: 'Imported?',
        notrouted: 'Not Routed',
        no_label: 'no',
        un_routed_messages: 'Unrouted Messages',
        failure_messages: 'Failure Messages',
        invalid_messages: 'Invalid Messages',
        mark_completed: 'Mark as Completed'
    },
    asr_labels: {
        title: 'Active Sites',
        active_site_month: 'Active Sites By Month',
        visit_volume: 'Visit Volume',
        active_sites: 'Active Sites',
        excluding: 'excluding'
    },
    analytics_labels: {
        exit_Analytics: 'Exit Analytics',
        exit_demo: 'Exit Demo',
        menu: 'Menu',
        most_recent_visit: 'most recent visit',
        Most_recent_Visit : "Most Recent Visit",
        years_old: 'years old',
        years: "Years",
        biographical_information_label: 'Biographical Information',
        biographical_info_label: 'Biographical Info',
        biomarkers_of_aging_label: 'Biomarkers of Aging',
        health_card_report_label: 'Health Report Card',
        recommendations_label: 'Recommendations',
        download_report_label: 'Download Report',
        patient_report_label: 'Patient Report',
        download_recommendations: 'Download Recommendations',
        download_pdf_label: 'View/Download PDF',
        download_label: 'Download',
        no_recommandations: 'You have not entered any recommendations.',
        no_recommandations_content: `To enter a recommendation, you have to be in the “drawer” that comes out from the right when you click on any of the parameters themselves. The idea is that when you are going over labs or biomarkers during your consultations, you can click on the parameter, for instance "Testosterone Free," and then a drawer comes out from the right side of the screen.  You can look at the interpretation spectrum, changes from baseline, a graph of all the points, table of data, and as you scroll down there is a form to enter your recommendations.`,
        comparing_your_ages: `Comparing Your Ages`,
        logOut: 'Log Out',
        no_visits_label: 'Patient has no visits on file.',
        measured_recently: `This biomarker hasn't been measured recently.`,
        older_actual_age: `years older than your actual age.`,
        older_actual_age_visits: `years older than average for your age.`,
        younger_actual_age: 'years younger than your actual age.',
        younger_actual_age_visits: `years younger than average for your age.`,
        older_younger: `neither older nor younger than average for your age.`,
        average_for_age: 'About average for your age.',
        gpa_scale: 'Your GPA (4-point scale):',
        report_card_label: 'Report Card',
        treatment_plan: 'TREATMENT PLAN',
        biomarkers_label: 'Biomarkers',
        current_label: 'Current',
        past_label: 'Past',
        average_peers: 'Average for Your Peers',
        everyone_label: 'Everyone',
        change_from: 'Change From',
        since_label: 'since',
        baseline_label: 'Baseline',
        previous_visit_label: 'Previous Visit',
        last_visit_label: 'last Visit',
        results_label: 'Results',
        recommendation_label: 'Recommendation',
        more_info: 'More Info',
        as_of_label: 'as of',
        years_label: 'years',
        your_age_label: 'Your Age',
        your_label: 'Your',
        date_label: 'Date',
        grade_label: 'Grade',
        value_label: 'Value',
        units_label: 'Units',
        reference_range: 'Reference Range',
        no_recommandations_made: 'No recommendations have been made',
        select_items_label: 'Select items from the search results, or the common recommendations on the left to add them to the list.',
        submit_comments: 'Submit Comments',
        recent_recommendations: 'Recent Recommendations',
        changes_wont_stick: `Changes won't stick`,
        demo_mode: `In demo mode, your changes will appear to have been saved. But if you come back to this page in the future, you won't see them. This allows all demo users to start with the same basic information each time.`,
        loading_data: `Loading Data:`,
        loading_categories: 'Loading Categories:',
        loading_sections: 'Loading Sections:',
        loading_patient: 'Loading Patient:',
        loading_recommendations: 'Loading Recommendations:',
        network_error: 'Check your network connection and reload the page',
        normal_range: 'Normal Range',
        optimal_range: 'Optimal Range',
        no_description: 'No Description Available',
        past_results: 'Your Past Results',
        your_result: 'Your Result',
        powered_by_physioage: 'Powered By PhysioAge',
        close: 'Close',
        howStronglyRecommended: 'Strength of recommendation',
        brand_ingredient: "Brand/Ingredient",
        instructions: 'Instructions',
        notes: "Notes",
        monopause_rating_scale: "Menopause Rating Scale (MRS)",
        provider_notes: 'Provider Notes',
        report_card: 'Report Card',
        questionnaires_info: 'To help us better interpret your results, please complete the following brief questionnaires',
        maybe_later: 'Maybe later',
        yes_sure: "Yes sure!",
        default: 'Default',
        by_risk: 'By Risk',
        compare: 'Compare',
        view_all: 'View All',
        add: 'Add',
        references: 'References:',
        reference: 'Reference',
        url: 'URL',
        internal_notes: 'Internal Notes',
        age_line: 'AgeLine',
        enable_all: 'Enable All',
        disable_all: 'Disable All',
        dashboard: 'Dashboard',
        interpretations: 'Interpretations',
        change_from_last_visit: 'Change from last Visit',
        since: 'since',
        change_from_baseline: 'Change from baseline',
        based_on: 'Based on',
        no_downloads_yet: 'No downloads yet.',
        downloads_message: 'If your provider adds any downloads for you, this is where they will appear.',
        prior_recommendations: 'Prior Recommendations',
        medication: 'Medication',
        nomenon_involved: 'Nomenon Involved',
        provider_notes: 'Provider Notes',
        cancel: 'Cancel',
        previous_notes: 'Previous Notes',
        create_notes: 'Create Notes',
        show_more: 'show more',
        successfully_saved: 'Successfully saved',
        successfully_submitted: 'Successfully submitted',
        question: 'Question',
        value: 'Value',
        units: 'Units',
        boundary_ranges: 'Boundary Ranges',
        review: 'Review',
        next: 'Next',
        prev: 'Prev',
        questionaire_review: 'Questionaire-Review',
        back: 'Back',
        save: 'Save',
        submit: 'Submit',
        enter_your_recommendation: 'Enter your Recommendation',
        dose: 'Dose',
        historic: 'Historic',
        recent: 'Recent',
        meta: 'Meta',
        download: 'Download',
        summary: 'Summary',
        visit_summary: 'Visit Summary',
        current_report: 'Current Report',
        past_report: 'Past Report',
        patient_past_report: 'Patient Past Report',
        patient_current_report: 'Patient Current Report',
        switch_to_old: 'Switch to Old',
        historic: 'Historic',
        suggestions: 'suggestions',
        suggestion: 'Suggestion',
        compare_biomarkers: 'Compare Biomarkers',
        select_all: 'Select all',
        deselect_all: 'Deselect all',
        clinicalStatus: "Clinical Status",
        verificationStatus: 'Verification Status',
        category: 'Category',
        severity: 'Severity',
        code: 'Code',
        bodySite: 'Body Site',
        onset: 'Onset',
        abatement: 'Abatement',
        recordedDate: 'Recorded Date',
        date: 'Date',
        start_date: 'Start Date',
        end_date: 'End Date',
        age: 'Age',
        add_problems: 'Add Problems',
        recommendation_list: 'Recommendation List',
        downlaod_pdf: 'Get Meds PDF',
        active_recommendation: 'Active Recommendation',
    },
    noumena_labels: {
        title: 'Explore Noumena',
        compare_noumena: 'Compare Noumena',
        noumena: 'Noumena',
        filter_clouds: 'Filter Clouds',
        noumenon: 'Noumenon name',
        select_noumenon_one: 'Select First Noumenon',
        select_noumenon_two: 'Select Second Noumenon ',
        site: 'Site',
        gender: 'Gender',
        max_deviations: 'Max Deviations',
        update_btn: 'Update',
        compare_noumenas: 'Compare',
        heads_up: 'Heads Up!',
        filter_help: 'Clouds will generate more slowly if you select items above. Only the default clouds are pre-drawn.',
        volume_report: 'Volume Report',
        download_csv: 'Download CSV',
        download_excel: 'Download Excel',
        volume_report_name: 'volume_report.csv',
        aggregate_age_chart_title: 'Sample Aggregate Age Charts',
        sample_report_title: 'Sample Report Card Grades',
        sample_spectra_title: 'Sample Spectra',
        concerns_title: 'Concerns:',
        concerns: [
            'What does it look like when printed?',
            'How much ink does it use?',
            'How does it look to the colorblind? (there may not be many of them but we know they exist, might as well handle them as well.)'
        ],
        color_palette_help: 'Change color palettes if you wish:',
        vadr_label: 'Very Abnormal Data Review',
        no_results: 'No results',
        next_page: 'Next Page',
        no_results_code: 'No results for the noumena code',
        value_format: 'Value Format',
        system: 'System',
        units: 'Units',
        scale: 'Scale',
        sorted_details: 'Sordid, Sorted Details',
        code: 'Code',
        first_code: 'First Noumenon Code',
        second_code: 'Second Noumenon Code',
        appears_in_report: 'Appears in Report',
        yes: 'Yes',
        no: 'No',
        equation: 'Equation',
        gender: 'Gender',
        anyone: 'Anyone',
        male: 'Male',
        female: 'Female',
        no_values_to_plot: 'No values to plot the Graph',
        age: 'Age',
        visitDate: 'Visit Date',
        visitId: 'Visit Id',
        ordinal: 'Ordinal',
        siteId: 'Site Id',
        units: 'Units',
        noumena_download: 'data.csv',
        no_name: 'No name',
        patientId: 'Patient Id'
    },
    visit_page_labels: {
        title: 'Baseline for ',
        breadcrum_for_visit: 'Baseline',
        surveys_label: 'Surveys',
        patient_conditions_link: 'Patient Conditions Survey',
        tests_label: 'Tests',
        btn_tests: 'Add Test',
        btn_edit_patient: 'Edit Patient',
        btn_edit_visit: 'Edit Visit',
        update_visit: 'Update Visit',
        btn_delete_visit: 'Delete Visit',
        btn_visit_summary: 'Visit Summary',
        btn_portal_view: 'Portal View of this visit',
        btn_toggle_beacon: 'Toggle Beacon',
        report_label: "Patient With Current Results",
        report_past_label: "Patient With Past Results",
        download_link: 'Download',
        download_current_link: 'PDF for this visit only',
        download_past_link: 'PDF with past results',
        visit_success: `Visit was successfully created.`,
        visit_update_success: `Visit was successfully updated.`,
        preview_link: 'Preview',
        download_historic_data: 'Historical Data',
        recycle_report_link: 'Recycle',
        recycle_report_text: '(create a new one)',
        physician_report_label: "Physician With Current Results",
        physician_past_report_label: "Physician With Past Results",
        register_date_header: 'Register Date Of Visit',
        active_ell_caution: 'This patient has an active ELK',
        update_date_header: 'Update Date Of Visit',
        error_handling: 'An Error Occured  Please Enter Another Date',
        waiting_for_data: 'Waiting for data',
        notes: 'Please fill in the results from the tests listed on the left. Once that is done, overview data will appear here.',
        test_add_order: 'Add Order',
        test_panel_name: 'Panel Name',
        popup_title_addTest: 'Add New Test',
        lbl_addTest: 'Add Test',
        patient_survey_title: 'Patient Survey for',
        nav_link_history: 'History',
        nav_link_import: 'Import',
        nav_link_manual_entry: 'Manual Entry',
        nav_link_custom_fields: 'Manage Fields',
        nav_link_sample_image: 'Sample Image',
        customer_service_label: "If the units or reference ranges on this page don't match what you receive from the lab, please enter them manually by clicking into the appropriate field. Contact ",
        customer_service_name: 'customer service',
        customer_service_label_2: " with questions or issues.",
        patient_survey_table_label: 'Label',
        patient_survey_table_value: 'Value',
        patient_survey_table_units: 'Units',
        patient_survey_table_ref_range: 'Reference Range',
        patient_survey_table_status: 'Status',
        btn_save_label: 'Save Results',
        label_patient_survey: 'Patient Survey',
        delete_warning: 'Are you sure to delete the visit?',
        update_visit_help: 'Select a date to Update visit',
        elk_credits_one: `When you create this visit, you will use one of your`,
        elk_credits_two: 'remaining credits.',
        visit_date: 'Visit Date ',
        future_date: ` is too far in the future `,
        visit_creation_error: `Date can be changed during a week after visit creation`,
        credits_elk: ` Cannot add Timepoint. No report credits remaining.`,
        no_history: `No results have been entered or imported.`,
        biomarkers: `Biomarkers of Aging`,
        import_account_setup: `Your account isn't set up for importing.`,
        import_contact_us: 'Contact Us',
        import_details: 'for details!',
        import_isnt_setup: `PhysioAge has partnered with CNS, LabCorp, Quest, Health Diagnostics, Bio-Reference and Access to get labwork sent directly to our software, avoiding the headaches of data entry.`,
        import_bloodwork: `Sorry, we don't support importing this kind of result right now.`,
        import_search: `Enter the name of the patient for whom you are searching. We'll show any matching messages we've received in the last few months.`,
        import_search_header: `Search by patient name:`,
        import_patient_notfound: `No results match your search.`,
        physiological_ages: 'Physiological Ages',
        age_weights: 'Age Weights',
        results_title: 'Review Results',
        out_of_bounds: 'Out Of Bounds',
        view: 'View',
        no_recommandations_caution: 'There is not enough data to generate a visit summary at this time.',
        panel_name: 'Panel name',
        browse_library: 'Browse Library',
        upload_btn: 'Upload',
        all_downloads: 'View all downloads for this patient',
        delete_visit_desc: "This will delete all the data associated with the visit",
        historical_data_validation: 'Sorry, there is no historical data for the current visit.',
        questionnaire: 'Questionnaire',
        btn_questionnaire: 'Add Questionnaire',
        questionnaire_name: 'Questionnaire name',
        suite_name: 'Suite Name',
        not_listed_in_the_list: 'Not listed in the list?',
        no_panel_label: 'Start adding fields by clicking ',
        professional: 'professional',
        upgrade_plan: ''
    },
    orders_labels: {
        delete_warning: 'Are you sure to delete the Order ',
        delete_waring_body: 'Deleting the order will permanently delete all the associated results which may impact the patient reports. Do you want to proceed with this action?',
        order_success: `Added an order for`,
        order_success_delete: `Successfully deleted an order for`,
        panel_validate: `Selected Panel was already added `,
        result: `results`,
        order_details: `Order Details`,
        out_of_bounds_exception_one: `Sorry, you can not submit data that is out-of-bounds. According to our physician panel, out-of-bound values are so abnormal that they are certainly wrong.`,
        out_of_bounds_exception_two: ` If you're having trouble finding the problem, be sure to look at the units and for missing or extra decimals.`,
        out_of_bounds_header: `Some Data is Out-Of-Bounds`,
        close_btn: 'Close',
        no_changes: 'No changes to save Results',
        updated_panels: 'Successfully Updated Results',
        baseline: 'Baseline',
        one_year: '1 Year',
        years: 'Years',
        follow_up: 'FollowUp',
        preset: 'Preset',
        gap: 'Gap',
        unfavourable: 'Unfavourable',
        entry_date: 'Entry Date',
        result_view: 'Result View',
        new: 'New',
        ordered_by: 'Ordered By:',
        publication: 'Publication Date:',
        lab_accession: 'Lab / Accession',
        draw_date: 'Draw Date',
        rpt_date: 'Rpt. Date',
        message_count: '# Msgs',
        imported_status: 'Imported',
        wait_status: 'Wait',
        import_headers: 'Importing Message(s)',
        import_observations: 'Please wait while we process the following message. It can take up to 60 seconds per message as our system verifies each observation.',
        import_failed: 'Import Failed.',
        import_failed_alert: 'We have been alerted and will look in to it. We are sorry that this has happened.',
        view_message: 'View Message'
    },
    questionnaire_labels: {
        delete_warning: 'Are you sure to delete the Questionnaire?',
        questionnaire_success: `Added a questionnaire for`,
        questions_data_not_present:'Patient have not answered any questions for this!'
    },
    custom_fields_labels: {
        custom_fields_success: `Successfully made changes to custom fields`,
        duplicate_noumenon_name: ' have been added multiple times in the same panel',
        custom_fields_noumenon_name_error: 'Please enter noumenon name or remove empty row',
        noumena: 'Noumena',
        unites: 'Units',
        reference_range: 'Reference Range',
        no_changes_found: 'No custom field changes found',
        noumenon_save: 'has been added.',
        noumenon_update: 'has been updated successfully.',
        noumenon_delete: 'has been deleted successfully',
        rubric_save: 'has been added successfully.',
        rubric_update: 'has been updated successfully.',
        rubric_delete: 'Rubric has been deleted successfully',
        range_save: 'has been added successfully.',
        range_update: 'has been updated successfully.',
        range_delete: 'Range has been deleted successfully',
        rubric_for: 'Rubric for',
        range_for: 'Range for',
        noumeon_delete_warning: 'Are you sure to delete?',
        noumeon_delete_message:'This action will delete the noumenon along with the units.',
        delete_warnings: {deleteRubric: "This action will delete the rubric along with the ranges", deleteRange: "This action will delete the range" },
    },
    messages:
    {
        patient_add_success: 'Patient was successfully created',
        patient_edit_success: 'Patient was successfully updated',
        no_manuals_exist: 'No Manuals Exist',
        user_update_success: 'User was successfully updated.',
        user_add_success: 'User was successfully Added.',
        user_add_success_signup: 'User was successfully created, please check your email to set password.',
        message_routing_apply: `I'll look at all messages from the past year that have not already been matched to see which ones apply.`,
        help_add_success: 'Successfully Deleted',
        marked_completed_status: ' questionnaire is marked as completed',
        elk_validation: 'You cannot charge a site by Web Interface',
        document_upload_success: 'Document upload successfully',
        help_document_validation: 'Please Select a PDF file',
        select_visit_date: 'Please Select Visit date to add visit',
        select_panel: 'Panel name is not included in the list',
        select_suit: 'Suit name is not included in the list',
        reset_password_mail_success: 'Instructions to reset your password have been sent to your email address.',
        password_req: 'Password is required',
        confirm_pwd_req: 'Confirm password is required',
        email_req: 'Email ID is required',
        pic_validation_message: 'Please enter valid image file(png, jpg/jpeg or gif)',
        pic_size_validation: 'Image width and height should be atleast 150 pixels',
        clinic_validation: 'Accepts referrals can only be set if the site is a clinic.',
        site_select_validation: 'Select atleast one site to proceed',
        password_updated: 'Password Successfully updated',
        payment_site_validation: `You don't have access to add credits for selected site`,
        elk_validation_zero_credits: `A Site Cannot Buy Zero Credits`,
        elk_validation_negative_credits: `A Site Cannot Buy Negative Credits`,
        multiple_card_validation: 'Select only one card for payment',
        feedback_validation: 'Enter feedback text',
        no_site_providers: `You won't be able to create any patients until providers are set up for this site. Please contact your PhysioAge vendor`,
        old_age_restriction: 'Date of Birth implies that patient is impossibly old.',
        patient_access_msg: 'The welcome email has been sent',
        proper_date_validation: 'Enter date in proper format',
        card_details_validation: 'Please enter valid card details',
        country_required: 'Country is Required',
        accept_terms: 'You need to accept Terms and Conditions',
        credit_info_1: `Purchase one credit for each new visit you'd like to document in PhysioAge Analytics. Document unlimited visits for the same patient for the six months following that visit for no additional charge.`,
        credit_info_2: `The confirmation email will say "Thank you for your subscription". Not to worry, your "subscription" expires tomorrow and will not renew. You will not be charged anything additional. Your credits will remain available until they are used.`,
        credit_info_3: `For volume discounts, contact Jerry Fortunato at jerry@physioAge.com`,
        // signup_intro_text_1: `Thank you for your subscription to PhysioAge Analytics Basic. Your monthly subscription fee of $100 entitles you to create unlimited visits. Your first 60 days are provided free of charge, and you can cancel at any time. Quest, Labcorp and CNS Vital Signs electronic interfaces will be configured at no additional charge.`,
        // signup_intro_text_2: `Upon submitting your information, we'll configure your instance and send links to your users to set their own passwords. Please fill in as many details as you can, including your logo file. Don't have your logo file handy? Not to worry, after submitting your response, you will receive an email of your submission as well as a link to edit your responses. Questions? Reach out to Jerry Fortunato at +1 347 633 7973 or jerry@physioage.com.`
        signup_intro_text_1: `Use this form to register your PhysioAge Analytics account. Your monthly subscription entitles you to create unlimited visits. Your first 60 days are provided free of charge, and you can cancel at any time. Quest, Labcorp and CNS Vital Signs electronic interfaces will be configured at no additional charge.`,
        signup_intro_text_2: `Please fill in as many details as you can, including your logo file. Don't have your logo file handy? Not to worry, you can always send it to support@physioage.com.`,
        signup_intro_text_3: `Questions? Reach out to Jerry Fortunato at +1 347 633 7973 or jerry@physioage.com.`,
        signun_intro_text_4: `How to Enroll with PhysioAge`,
        provider_message: 'A Provider is typically an MD, DO, PA or NP. The name of the provider is displayed on the patient report. Providers typically use the system to look up results and review results with patients.',
        caregiver_message: 'A Caregiver is any non-provider user. Caregivers typically create visits and enter results.',
        provider_and_caregiver_message: 'A Provider is typically an MD, DO, PA or NP. The name of the provider is displayed on the patient report. Providers typically use the system to look up results and review results with patients. A Caregiver is any non-provider user. Caregivers typically create visits and enter results.',
        select_questionnaire: 'Please select Questionnaire name',
        plan_message: `Learn more about plans by clicking <a href='https://physioage.com/pricing' target='_blank'>here</a>`
    },
    payments_labels: {
        title_help: 'Add Credits for',
        num_credits: 'Number of Credits',
        existing_card: 'Select an Existing Card',
        new_card: 'Or Enter a New One:',
        card_number: 'Card Number',
        cvc_label: 'CVC',
        expiration: 'Expiration (MM/YY)',
        save_btn: 'Save',
        test_cc_title: 'Test CC Numbers',
        test_cc_help: 'This card works with any three digit CVC and any future expiry date: ',
        empty_card: 'This test card is empty:',
        credits_label: 'Credits',
        exp_label: 'Expiration date',
        back_to_site: 'Back to the Site',
        max_credits_validation: 'Total Credits amount must not be more than $20,000'
    },
    report_labels: {
        next_gen_exam: 'Next Generation Physical Exam',
        prepared_for: 'prepared for',
        by_provider: 'by provider',
        tests_performed: 'based on tests performed',
        table_contents: 'Table of Contents',
        introduction: 'Introduction',
        compare_system: 'Comparing Systems',
        health_status_indicator: 'Health Status Indicators',
        exceptional_results: 'Exceptional Results',
        abnormal_results: 'Abnormal Results',
        complete_data: 'Complete Data',
        historical_data: 'Historical Data',
        disclaimer: 'Disclaimer',
        physioage: 'physioAge',
        cutoage: 'cutoAge',
        immunoage: 'immunoAge',
        pulmoage: 'pulmoAge',
        telomerage: 'telomerAge',
        cardioage: 'cardioAge',
        neuroage: 'neuroAge',
        epigenage: 'epigenAge',
        man_has_arteries: 'A man is as old as his arteries',
        english_hypocrates: 'known as the English Hypocrates',
        test: 'The Test',
        factors_affect: 'What factors affect my ',
        importance: 'Importance',
        readmore: 'readmore',
        older_avg: 'years older than average for your age.',
        younger_avg: 'years younger than average for your age.',
        is_label: 'is',
        test_not_performed: 'test not performed',
        about_test: 'About the Test',
        skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging?',
        skin_elasticity: 'Determining Skin Elasticity',
        significance: 'Significance',
        composite: 'Composite',
        flag: 'Flag',
        first_received: 'First Results Received',
        last_received: 'Last Results Received',
        name_label: 'Name',
        result_label: 'Result',
        visit_date: 'Visit Date',
        grade_label: 'Grade',
        units_label: 'Units',
        normal_range: 'Normal Range',
        optimal_range: 'Optimal Range',
        baseline_label: 'Baseline',
        change: 'Change',
        report_card_label: 'Report Card',
        biomarkers_of_aging_label: 'Biomarkers of Aging',
        health_card_report_label: 'Health Report Card',
        description: 'Description',
        powered_by: 'Powered By',
        physiciansreport: `Physician's Report`,
        timepoint: 'Timepoint',
        source_label: 'Source',
        comprehensive_health_analysis: 'Comprehensive Health Analysis',
        medication_type: 'Pharmaceutical'
    },
    document_labels: {
        format: 'Format',
        title: 'Title',
        source: 'Source',
        distributions: 'Distributions',
        edit: 'Edit',
        format_published: 'Format Published',
        title: 'Title',
        visit: 'Visit',
        patient_downloads: 'Patient Downloads',
        documents_text: 'To add documents, please go to the appropriate visit and find the "Upload" button.',
        upload_document_for: 'Upload document for',
        edit_document: 'Edit Document',
        document_upload_optional: `<b> Publication Date : Will be set to today's date if you don't enter anything.</b> This field will be useful if you update this document in the future and what to know which is the most current. Once you have selected the year and month, you MUST click on the day.`,
    },
    successfully_label: 'was successfully',
    added_label: 'added',
    updated_label: 'updated',
    required_label: 'is required',
    email_help: 'Enter Email ID',
    credits_caution_title: 'You have scrednumber report credits remaining.',
    credits_caution_help: 'Your report credit balance on PhysioAge Reporting has reached scrednumber. To avoid potential delays, please add more credits.',
    //removed credit card info as we are not using stripe anymore
    // now using your credit card.
    credits_caution_contact: 'Contact us at with questions.',
    acknowlegde: 'Acknowledge',
    no_grid_data: 'Currently, there is no data to display',
    pending: 'Pending',
    no_data: 'No Data',
    finished: 'Finished',
    allowed_date_format: 'Enter date in <> format',
    no_visits: 'No Visits',
    no_summary_data: `I'm sorry, there was not enough medical data present to generate a summary. Typically this means your data has not been entered into our system yet. Please try again later.
    `,
    not_allowed: 'Sorry, you are not allowed to access that page.',
    pages: 'Pages'
}

import React, { useEffect, useState } from 'react';
// import SpriteIcon from './SpriteIcon';
import { connect } from 'react-redux';
import '../../../assets/Analytics-JS/Analytics-scroll-sidebar'
import biomarker_of_age from '../../../assets/images/ecg_heart.svg';
import biomarker_dark from '../../../assets/images/biomarker_dark.svg';
import ecg_heart_black from '../../../assets/images/ecg_heart_black.svg';
import keyboard_double_arrow_left from '../../../assets/images/keyboard_double_arrow_left.svg';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import constants from '../../../config/constants';
import HomeIcon from '@mui/icons-material/Home';
import BarChartSharpIcon from '@mui/icons-material/BarChartSharp';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import labels from '../../../config/localization';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import PatientPdf from '../../../assets/sample pdfs/demo_female_patient_report.pdf';
import PatientPdfPast from '../../../assets/sample pdfs/demo_female_patient_report_past.pdf';
import PatientDemo2Pdf from '../../../assets/sample pdfs/demo_patient_report.pdf';
import demoSummary from '../../../assets/sample pdfs/demo_summary.pdf';
import demo2Summary from '../../../assets/sample pdfs/demo_2_summary.pdf'
import PersonIcon from '@mui/icons-material/Person';

function NavComponent(props) {
  useEffect(() => {
    setIsNavActive(props.currentPage ? props.currentPage : 'dashboard');
  }, [props.currentPage])
  const [isNavActive, setIsNavActive] = useState(props.history.location && props.history.location.hash ? _.last(props.history.location.hash.split("#")) : "dashboard");
  const setActive = (navbar) => {
    setIsNavActive(navbar)
    props.togglePages(navbar, props.history);
    if (window.innerWidth < 768)
      props.isLeftNavOpenend(false)

  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    let nav = document.querySelector('.left-nav')
    nav.offsetWidth < event.clientX ? props.isLeftNavOpenend(false) : ""
  };

  const returnNavOpenClass = (leftNavOpen) => {
    return leftNavOpen ? ' open-nav' : ' close-nav'
  }
  const returnForMainDiv = (leftNavOpen) => {
    if (leftNavOpen) {
      return ' main-div-nav-open'
    }
    else {
      open ? setAnchorEl(null) : ""
      return ' main-div-nav-close';
    }

  }
  const downloadDemoReport = (filePath) => {
    var link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
  }

  return (
    props.terms ?
      <nav className={"navbar navbar-light left-nav ai-baseline" + returnForMainDiv(props.isLeftNavOpen)} id="mySidebar">

        <ul className='sidebar nav list-group nav-stacked fixed' >
          {window.innerWidth >= constants.small_screen_resolution && window.innerWidth <= 992 ?
            <div className="left-navbar-arrow" id="left_nav_arrow">
              <a><img className="left_arrow-analytics" src={keyboard_double_arrow_left} alt={'verctorIcon_left'} onClick={() => props.isLeftNavOpen ? props.isLeftNavOpenend(false) : props.isLeftNavOpenend(true)}></img></a>
            </div>
            : null}
          <div className='top-menu'>
            <li className={(isNavActive == "dashboard" ? "active_li" : '') + returnNavOpenClass(props.isLeftNavOpen)} onClick={() => setActive("dashboard")} >
              <div className='text align-items-center' to=''>
                <span> <HomeIcon color={isNavActive == "dashboard" ? "#00486c" : "#c7e7f6"} className='mb-1' /></span>
                <span className="nav-name">{"Dashboard"}</span>
              </div>
            </li >
            {props.categories && props.categories.length ?
              <li className={(isNavActive == "report_card" ? "active_li" : '') + returnNavOpenClass(props.isLeftNavOpen)} onClick={() => setActive("report_card")}>
                <div className='text align-items-center'>
                  <span> <BarChartSharpIcon color={isNavActive == "report_card" ? "#00486c" : "#c7e7f6"} className='mb-1' /></span>
                  <span className="nav-name">{props.terms['Report Card']}</span>
                </div>
              </li> : ''}
            {props.historic_data_chart && props.historic_data_chart.biomarkerCount > 0 ?
              <li className={(isNavActive == "biomarker_of_age" ? "active_li" : '') + returnNavOpenClass(props.isLeftNavOpen)} onClick={() => setActive("biomarker_of_age")} >
                <div className='text align-items-center'>
                  <span><img src={isNavActive == "biomarker_of_age" ? biomarker_dark : window.innerWidth < 768 ? ecg_heart_black : biomarker_of_age} alt={"Biomarker of age"}></img></span>
                  <span className="nav-name">{props.terms['Biomarkers of Aging']}</span>
                </div>
              </li> : ''}
            {props.interpretations && props.interpretations.length && props.interpretations.filter(s => (!(s.sectionName || s.categoryName))).length ?
              <li className={(isNavActive == "interpretations" ? "active_li" : '') + returnNavOpenClass(props.isLeftNavOpen)} onClick={() => setActive("interpretations")} >
                <div className='text align-items-center'>
                  <span> <ChromeReaderModeIcon color={isNavActive == "interpretations" ? "#00486c" : "#c7e7f6"} className='mb-1' /></span>
                  <span className="nav-name">{"Interpretations"}</span>
                </div>
              </li> : ''}
            <li className={(isNavActive == "medication" ? "active_li" : '') + returnNavOpenClass(props.isLeftNavOpen)} onClick={() => setActive("medication")} >
              <div className='text align-items-center'>
                <span> <MedicationOutlinedIcon color={isNavActive == "medication" ? "#00486c" : "#c7e7f6"} className='mb-1' /></span>
                <span className="nav-name">{"Medication"}</span>
              </div>
            </li>
            <li className={(isNavActive == "treatment_plan" ? "active_li" : '') + returnNavOpenClass(props.isLeftNavOpen)} onClick={() => setActive("treatment_plan")} >
              <div className='text align-items-center'>
                <span> <VaccinesOutlinedIcon color={isNavActive == "treatment_plan" ? "#00486c" : "#c7e7f6"} className='mb-1' /></span>
                <span className="nav-name">{props.terms['Treatment Plan']}</span>
              </div>
            </li>
            <li className={(isNavActive == "problem_list" ? "active_li" : '') + returnNavOpenClass(props.isLeftNavOpen)} onClick={() => setActive("problem_list")} >
              <div className='text align-items-center'>
                <span> <LibraryBooksOutlinedIcon color={isNavActive == "problem_list" ? "#00486c" : "#c7e7f6"} className='mb-1' /></span>
                <span className="nav-name">{'Problem List'}</span>
              </div>
            </li>

            <li className={(isNavActive == "download_library" ? "active_li" : '') + returnNavOpenClass(props.isLeftNavOpen)} onClick={() => setActive("download_library")} >
              <div className='text align-items-center'>
                <span> <SaveAltIcon color={isNavActive == "download_library" ? "#00486c" : "#c7e7f6"} className='mb-1' />
                </span>
                <span className="nav-name">{"Download Library"}</span>
              </div>
            </li>
          </div>
          <div className='bottom-menu'>
            <li className={(isNavActive == "user_details" ? "active_li" : '') + returnNavOpenClass(props.isLeftNavOpen)} onClick={() => setActive("user_details")} >
              <div className='text align-items-center'>
                <span> <PersonIcon color={isNavActive == "user_details" ? "#00486c" : "#c7e7f6"} className='mb-1' />
                </span>
                <span className="nav-name">{"User Profile"}</span>
              </div>
            </li>
            <li className={returnNavOpenClass(props.isLeftNavOpen)}
              onClick={() => {
                if (props.loggedInUser && props.loggedInUser.role == constants.logged_roles.PT)
                  props.logOut(props.history)
                else if (props.isDemo)
                  window.location.href = 'https://physioage.com/'
                else
                  props.exitAnalytics(props.presentPatient.id, props.history)
              }}>
              <div className='text align-items-center'>
                <span> <LoginOutlinedIcon color={"#00486c"} className='mb-1' /></span>
                <span className="nav-name">
                  {props.isDemo
                    ? labels.analytics_labels.exit_demo
                    : props.loggedInUser && props.loggedInUser.role == constants.logged_roles.PT
                      ? labels.analytics_labels.logOut : labels.analytics_labels.exit_Analytics}</span>
              </div>
            </li>
          </div>
        </ul>
      </nav>
      : <div></div>

  );
}
const StyledMenu = styled((props) => (
  <Menu
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: window.innerWidth < 768 ? 'white' : '#2c6783',
    color: window.innerWidth < 768 ? 'black' : 'white',
    borderRadius: 4,
    marginLeft: theme.spacing(1),
    width: 160,
    marginTop: window.innerWidth > 992 ? "1.7%" : "6.3%",
    boxShadow: 'none',
    '& li': {
      fontSize: '12px',
      fontWeight: '300',
      display: 'block',
      paddingBottom: '5px',
      wordWrap: 'break-word',
      minHeight: 'auto'
    },
  },
}));

export default connect()(NavComponent);
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import sprite from '../../../assets/images/sprite_base.svg'
import Nav from './NavComponent';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import Recommendation from '../../Analytics/tools/recommendation';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { connect } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import DehazeIcon from '@mui/icons-material/Dehaze';
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from "@mui/material/styles";
import ToggleButton from '@mui/material/ToggleButton';
import { formatDateWithMoment } from '../tools/helpers';
import ListAltIcon from '@mui/icons-material/ListAlt';

const renderTooltip = props => (
  props.bioInformation && Object.keys(props.bioInformation).length ?
    <Tooltip {...props} className="mytooltip">
      <table className='survey-tooltip'>
        <tbody>
          {Object.keys(props.bioInformation).map(bioInformation => {
            return (<tr>
              <td>{bioInformation}</td>
              <td>{props.bioInformation[bioInformation]}</td>
            </tr>)
          })
          }
        </tbody>
      </table>
    </Tooltip> : ''
);
const PatientBreadcrumb = (props) => {
  return (
    <div className='row'>
      <div className='col-10 col-sm-9 d-flex p-0'>
        <div className='p-name col-md-4 col-lg-auto col-auto'>
          <span className={`float-right ${props.isPatientDemo ? 'anonymized-text' : '' } `}>
            {props.patient.firstname} {props.patient.lastname}
          </span>
        </div>
        <div className=''> | </div>
      <div className='p-age col-md-5 col-auto col-lg-auto d-flex'>
        {props.patient_age} {labels.analytics_labels.years}, <span className='capitalize'> {props.patient.gender}</span>
        <div className='info-logo pl-1'>
          <OverlayTrigger placement="auto" overlay={renderTooltip(props)}>
          <InfoIcon />
          </OverlayTrigger>
         </div>
      </div>
      
      </div>
      <div className='col-2 col-sm-3 p-0 d-flex'>
        { !props.isDemo && props.role != constants.logged_roles.PT ?
          <>
            <div className='provider-notes-icon mr-2' onClick={() => props.toggleProviderNotes(true)} help={"Provider Notes"}>
              <FeedOutlinedIcon  />
            </div>
            
            <div className='provider-notes-icon mr-4' onClick={() => props.toggleRecommendationListModal(true)} help={"Recommendation List"}>
              <ListAltIcon
                className='provider-notes-icon'
              />
            </div> 
        </>
          : null  
        }
      </div>  
    </div>
  )
}

const Switch = ({ isOn, handleToggle, dark, light, check }) => {
  return (
    <>
      <input
        checked={check}
        onChange={handleToggle}
        className="switch-checkbox"
        id={`switch`}
        type="checkbox"
      />
      <label
        style={{ background: isOn == "dark-theme" ? dark : light }}
        className="switch-label"
        htmlFor={`switch`}
      >
        <span className={`switch-button`} />
      </label>
    </>
  );
};

function TopNavComponent(props) {

  const [aspectRatio, setAspectRatio] = useState(null);

  useEffect(() => {
    const calculateAspectRatio = (siteData) => {
      return new Promise((resolve, reject) => {
        const src = siteData ? siteData.bannerLogoFile ? `data:${siteData.bannerLogoContentType};base64,${siteData.bannerLogoFile}`
          : (siteData.logoFile ? `data:${siteData.logoContentType};base64,${siteData.logoFile}` : '') : '';
        const img = new Image();
        img.src = src;

        img.onload = () => {
          const aspectRatio = img.naturalWidth / img.naturalHeight;
          resolve(aspectRatio);
        };

        img.onerror = (err) => {
          reject(err);
        };
      });
    }
    if (props.siteData) {
      calculateAspectRatio(props.siteData).then((ratio) => {
        setAspectRatio(ratio);
      }).catch((err) => {
        setAspectRatio(null);
      });
    }
  }, [props.siteData]);

  const renderCategories = () => {
    return (
      <nav className='secondary'>
        <div className='max-container'>
          {props.dataset.map(this.categoryLink)}
        </div>
      </nav>
    );
  }

  const last_visit = props.patient && props.patient.visits && props.patient.visits[props.patient.visits.length - 1];
  let currentPageName = () => {
    switch (props.currentPage) {
      case 'dashboard':
        return "Dashboard"
      case 'report_card':
        return 'Report Card'  
      case 'biomarker_of_age':
        return 'Biomarker Aging'
      case 'medication':
        return 'Medication'  
      case 'treatment_plan':
        return 'Treatment Plan'
      case 'user_details':
          return 'User Details'  
      default:
        return "Dashboard"
    }
  }
  const [checked, setChecked] = React.useState(props.theme);

  return (
    props.presentPatient && props.siteData ?
      <div className={`top-navbar analytics-menu ${props.siteData ? (props.siteData.logoFile || props.siteData.bannerLogoFile) ? 'analytics-beta' : '' : ''}`} id='topNav'>
        <nav className='container-fluid'>
          <div className='row m-0 top-nav header-container'>
            <div className='col-lg-7 col-md-6 col-12 header-breadcrumb p-0'>
              <div className='row no-gutters d-flex'>
                <div className={`col-lg-2 col-md-5 col-sm-12 bg-top-nav pl-3 pr-3 ${(props.siteData.logoFile || props.siteData.bannerLogoFile) ? 'site-specific-logo' : ''}`}>
                  <Link className='scrollable top-nav-brand-logo' to='splash' spy={true} smooth={true} duration={500} offset={-45}>
                    {props.siteData ? <img id='site-logo-top-nav' style={{ width: aspectRatio ? `${aspectRatio * (props.siteData.name.toLowerCase() == 'viavi' ? 20 : 54)}px` : '' }} className='nav-logo' src={props.siteData.bannerLogoFile
                      ? `data:${props.siteData.bannerLogoContentType};base64,${props.siteData.bannerLogoFile}`
                      : (props.siteData.logoFile ? `data:${props.siteData.logoContentType};base64,${props.siteData.logoFile}` : props.masthead_path_noLogo)} /> : ''}
                  </Link>
                </div>
                <div className={`col-12 left-nav-icon-m  pl-3 pr-3 ${props.theme == "light-theme" ? "bg-w" : "bg-black-header"}`}>
                  <div className='row no-gutters'>
                    <div className='col-9'>
                      <span className='page-title'>
                        {currentPageName()}
                      </span>
                    </div>  
                      <div className='col-2'>
                        <Switch
                          isOn={props.theme}
                          handleToggle={() => props.toggleTheme(props.theme)}
                          dark="#081115"
                          light="#E3E8EA"
                          check={ props.theme ? props.theme == "light-theme" ? true : false : true}
                        /> 
                      </div>
                      <div className='col-1'>
                        <DehazeIcon onClick={() => props.isLeftNavOpenend(props.isLeftNavOpen ? false : true)} />
                      </div>
                  </div>  
                </div>
                <div className={`col-lg-8 col-md-7 col-sm-12  pl-3 pr-3  m-auto ${props.theme == "light-theme" ? "bg-w" : "bg-black-header"}`}>
                  <PatientBreadcrumb patient={props.presentPatient} age_round={props.age_round} bioInformation={props.bioInformation} {...props} />
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-6 pl-0 header-breadcrumb m-auto pr-2'>
              <div className='row no-gutters justify-content-end align-items-center float-right provider-top-nav'>
                  <span className='most-recent-visit mr-2'>{`Switch to  ${props.theme == "light-theme" ? 'Dark' : 'Light'}`}</span>
                  <Switch
                    isOn={props.theme}
                    handleToggle={() => props.toggleTheme(props.theme)}
                    dark="#081115"
                    light="#E3E8EA"
                    check={ props.theme ? props.theme == "light-theme" ? true : false : true}
                  /> 

                { !props.isDemo && props.role != constants.logged_roles.PT ?
                  <>
                  <button className='btn provider-Notes btn-sm' onClick={() => props.toggleProviderNotes(true)}>{labels.analytics_labels.provider_notes}  </button>
                  <button className='btn provider-Notes btn-sm ml-2' onClick={() => props.toggleRecommendationListModal(true)}>{labels.analytics_labels.recommendation_list}  </button>
                  </>
                : null }
                <span className='most-recent-visit ml-2 align-middle'>{labels.analytics_labels.Most_recent_Visit}</span>
                <label className="visite-date ml-2">{formatDateWithMoment(props.lastVisitDate.replace(/-/g, '/'), props.dateFormat)} </label>             
                </div>  
            </div>
          </div>

        </nav>
      </div> : ''
  );
}
export default connect()(TopNavComponent);
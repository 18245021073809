import React from 'react';

import Rickshaw from '../tools/rubricplot'
import ChartTools, { filled_age_line_series } from '../tools/ChartTools'
import chartTimeScale from '../tools/chartTimeScale'
import constants from '../../../config/constants'

export default class BiomarkerOfAgingChart extends React.Component {
    constructor(props) {
        super(props)
        this.plot_data = this.plot_data.bind(this)
        this.chart_id = this.chart_id.bind(this)
    }
    componentDidMount() {
        this.plot_data()
    }

    chart_id() {
        return 'chart_container_' + this.props.title.toLowerCase()
    }
    plot_data() {
        let height = this.props.height || 600
        let width = this.props.width || 400
        let min = this.props.min || 17
        let max = this.props.max || 97
        let chart_element = document.getElementById(this.chart_id())
        if (chart_element) {
            let y_axis = chart_element.querySelector('.y_axis')
            width = width - y_axis.offsetWidth
            let data = this.props.historic_data;
            let dob = new Date(this.props.dob)
            let the_data = filled_age_line_series(dob, data, `${this.props.title}-home`);

            var graph = new Rickshaw.Graph({
                element: chart_element.querySelector('.chart_body'),
                interpolation: 'cardinal',
                renderer: 'multi',
                min: min,
                max: max,
                dotSize: 4,
                strokeWidth: 2,
                height: height,
                width: width,
                padding: { top: 0.02, bottom: 0.0, right: 0.1, left: 0.1 },
                series: the_data,
            })

            var x_axis = new Rickshaw.Graph.Axis.Time({
                graph: graph,
                timeFixture: new chartTimeScale(),
                ticksTreatment: 'fancy'
            })

            x_axis.render()

            var y_ticks = new Rickshaw.Graph.Axis.Y({
                graph: graph,
                orientation: 'left',
                tickFormat: Rickshaw.Fixtures.Number.formatKMBT,
                tickSize: 0.0001,
                ticksTreatment: 'fancy',
                element: y_axis
            })

            graph.render()
        }   
    }

    render() {
        return (
            <div className='chart-container'>
                <div className='title' title={this.props.languagePreference != "en" ? this.props.enLabel : ''}>{this.props.cultureLabel}
                </div>
                <div className='biomarker-chart' id={this.chart_id()}>
                    <div className='y_axis'></div>
                    <div className='chart_body'></div>
                </div>
            </div>
        )
    }
}

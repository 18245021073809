import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import ReactToPrint from 'react-to-print';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import FormControl from '../../../components/FormControls/FormControl';
import './visits-styles.scss';
import SpreadingRoutes from '../../../utils/SpreadingRoutes';
import moment from 'moment';
import PhysiciansReport from './PhysiciansReport';
import PatientReport from './PatientReport';
import IndividualVisit from './IndividualVisit';
import PatientReportDownloadTemplate from './PatientReportDownloadTemplate';
import PatientReportDownloadTemplatePast from './PatientReportDownloadTemplatePast';
import PhysicianReportDownloadTemplate from './PhysicianReportDownloadTemplate';
import PhysicianReportDownloadTemplatePast from './PhysicianReportDownloadTemplatePast';
import { addMonths } from 'date-fns';
import { Typeahead } from 'react-bootstrap-typeahead';
import PageTitle from '../../../components/PageTitle';
import Grade from '../../Analytics/components/Grade';
import BootstrapTable from 'react-bootstrap-table-next';
import { truncateSync } from 'fs';


const modalModes = {
    addtest: labels.visit_page_labels.popup_title_addTest,
    editvisit: labels.visit_page_labels.update_date_header,
    deleteorder: labels.orders_labels.delete_warning,
    deletevisit: labels.visit_page_labels.delete_warning,
    addcredits: '',
    out_of_bounds: labels.orders_labels.out_of_bounds_header,
    deletequestionnaire: labels.questionnaire_labels.delete_warning,
}
const patientDetails = {
    name: labels.patient_list_labels.name_label,
    displayDate: labels.inbox_labels.dob,
    patientId: labels.oddities_labels.identifier,
    gender: labels.patient_list_labels.gender_label,
    siteName: labels.patient_list_labels.site_label,
    visitDate: labels.visit_page_labels.visit_date,
    visits: labels.sites_labels.visits_label
}
const safeWeb = (panelName) => {
    var newString = panelName.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase().replace(/[^a-zA-Z0-9_]/g, '_').replace(/_{2,}/g, '_');
    return newString;
}
const excludeRoutes = ['reports', 'sites.cj', 'patients.cj', 'documents.cj']

class Visits extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.includeHeader = excludeRoutes.filter(er => this.props.location.pathname.indexOf(er) > -1).length == 0
        // window.addEventListener('click', (e) => {
        //     if ((this.props.isSectionModalOpen && document.getElementsByClassName('report-card_modal')
        //         && document.getElementsByClassName('report-card_modal')[0]
        //         && !document.getElementsByClassName('report-card_modal')[0].contains(e.target))) {
        //         this.props.closeSectionModal();
        //     }
        // })
    }

    componentWillUnmount() {
        this.props.toggleNotification(false)
        clearTimeout(this.props.intervalForOrders)
    }
    render() {
        const datacolumns = [
            {
                dataField: 'points',
                formatter: (cellContent, row) => (<span>{row.points || row.points === 0 ? <Grade points={row.points} /> : ""}</span>),
                text: labels.report_labels.grade_label,
                headerStyle: { width: '15%' }
            },
            {
                dataField: 'label',
                text: labels.oddities_labels.identifier,
                headerStyle: { width: '40%' }
            },
            {
                dataField: 'value',
                text: labels.oddities_labels.value,
                headerStyle: { width: '20%' }
            },
            {
                dataField: 'units',
                text: labels.oddities_labels.units,
                headerStyle: { width: '20%' }
            },
        ]

        const questionnairecolumns = [
            {
                dataField: 'label',
                text: labels.oddities_labels.question,
                headerStyle: { width: '60%' }
            },
            {
                dataField: 'value',
                text: labels.oddities_labels.value,
                headerStyle: { width: '25%' }
            },
            {
                dataField: 'units',
                text: labels.oddities_labels.units,
                headerStyle: { width: '15%' }
            }
        ]
        function getBadge(questionnaire) {
            if (!questionnaire.status && questionnaire.questionnaireQuestionsDTO && questionnaire.questionnaireQuestionsDTO.length) {
                return 'success';
            }
            else if (questionnaire.status == 'draft') {
                return 'danger';
            }
            else if (questionnaire.status == 'pending') {
                return 'warning';
            }
            else {
                return '';
            }
        }
        return (
            <div className={`visits-component row${this.includeHeader ? ' parent-component' : ''}`}>
                {this.includeHeader ?
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                        <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                        <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                            <PageTitle breadCrumbs={this.props.breadCrumbs}
                                pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                                navigatePatients={this.props.navigatePatients}
                                history={this.props.history}
                                {...this.props} />
                        </div>
                    </div> : null}
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 visits-section'>
                    {this.includeHeader ?
                        <div className='visitsPage side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                            <div className='card card-default visit-details-section'>
                                <div className='card-header'>
                                    {labels.visit_page_labels.surveys_label}
                                </div>
                                <div className='card-body p-0'>
                                    {this.props.ordersList ?
                                        this.props.ordersList.map(o => o.panelName == constants.visit_labels.patient_survey ?
                                            <React.Fragment>
                                                <a href='javascript:void(0)'
                                                    onClick={() => {this.props.setCalledSetCurrentOrder(false); this.props.setCurrentOrder(this.props.currentVisit.id, o.id, this.props.history, `/visits/${this.props.currentVisit.id}/${safeWeb(o.panelName)}/${o.id}`); }}>
                                                    <div className={`card-body-report mt-2 mb-3 ${this.props.currentOrder && this.props.currentOrder.orderId == o.id ? 'panel-active' : ''}`} >
                                                        <i className={o.enableLoader ? 'fa fa-spinner fa-spin expositer-loader' : o.orderStatus == constants.visit_labels.pending ? 'fa fa-times status-indicator text-danger ' : 'fa fa-check status-indicator text-success'}></i>
                                                        <a>{constants.visit_labels.patient_conditions_link}</a>
                                                    </div>
                                                </a>
                                            </React.Fragment>
                                            : null)
                                        : null}
                                </div>
                            </div>
                            <div className='card card-default visit-details-section'>
                                <div className='card-header'>
                                    {labels.visit_page_labels.tests_label}
                                </div>
                                <div className='mt-2 '>
                                    {this.props.ordersList ?
                                        this.props.ordersList.map(o => o.panelName != constants.visit_labels.patient_survey && o.type && o.type === 'test' ?
                                            <React.Fragment>
                                                <div className={`card-body-report ${this.props.currentOrder && this.props.currentOrder.orderId == o.id ? 'panel-active' : ''}`} >
                                                    <div className='d-flex justify-content-between'
                                                        onClick={() => { this.props.setCalledSetCurrentOrder(false);
                                                            this.props.setCurrentOrder(this.props.currentVisit.id, o.id, this.props.history, `/visits/${this.props.currentVisit.id}/${safeWeb(o.panelName)}/${o.id}`)
                                                        }}>
                                                        <div>
                                                            <i className={o.enableLoader ? 'fa fa-spinner fa-spin expositer-loader' : o.orderStatus == constants.visit_labels.pending ? 'fa fa-times status-indicator text-danger ' : 'fa fa-check status-indicator text-success'}></i>
                                                            <a href='javascript:void(0)'
                                                            >{o.suitName}</a>
                                                            <div className='panelName '>{o.panelName}</div>
                                                        </div>
                                                        <FormControl type='button' btnType='default '
                                                            className='sidebar-orders' text={labels.help_page_labels.delete_label}
                                                            onClick={(e) => { e.stopPropagation();
                                                                o.orderStatus == constants.visit_labels.pending ?
                                                                    this.props.deleteOrder(this.props.history, o.id) :
                                                                    this.props.toggleVisitModal(constants.popup_labels.deleteOrder, o.id)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            : null)
                                        : null}
                                </div>
                                <div className='card-body panel-list'>
                                    {/* <div>
                                    <div>
                                        {labels.visit_page_labels.lbl_addTest}:
                                    </div>
                                    <Typeahead
                                        labelKey={option => `${option.suiteName}: ${option.name}`}
                                        id='panelTypeAhead'
                                        options={this.props.panelList ? this.props.panelList : []}
                                        renderMenuItemChildren={(option, props) => (
                                            <span id={option.id ? option.id : 0} key={`${option.suiteName}:${option.name}`}>{`${option.suiteName}: ${option.name}`}</span>
                                        )}
                                        onChange={this.props.AddOrder}
                                        ref={(ref) => this._typeahead = ref}
                                    />
                                </div>
                                <br /> */}
                                    <div className='d-flex justify-end'>
                                        <FormControl type='button' btnType='primary'
                                            text={labels.visit_page_labels.btn_tests}
                                            onClick={() => this.props.addTests(this.props.history, false, true)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='card card-default visit-details-section'>
                                <div className='card-header'>
                                    {labels.visit_page_labels.questionnaire}
                                </div>
                                <div className='mt-2 '>
                                    {this.props.ordersList ?
                                        this.props.ordersList.map(o => o.type && o.type === 'questionnaire' ?
                                            <React.Fragment>
                                                <div className={`card-body-report ${this.props.currentOrder && this.props.currentOrder.orderId == o.id ? 'panel-active' : ''}`} >
                                                    <div className='d-flex justify-content-between' onClick={() => {
                                                        this.props.setCurrentQuestionnaire(this.props.currentVisit.id, o.id)
                                                    }}>
                                                        <div>
                                                            <i className={o.enableLoader ? 'fa fa-spinner fa-spin expositer-loader' : o.orderStatus == constants.visit_labels.pending ? 'fa fa-times status-indicator text-danger ' : 'fa fa-check status-indicator text-success'}></i>
                                                            <a>{o.panelName} </a>
                                                        </div>
                                                        {o.orderStatus == constants.visit_labels.pending ? <FormControl type='button' btnType='default '
                                                            className='sidebar-orders' text={labels.help_page_labels.delete_label}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.props.toggleVisitModal(constants.popup_labels.deleteQuestionnaire, o.id)
                                                            }} /> : null}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            : null)
                                        : null}
                                </div>
                                <div className='card-body panel-list'>
                                    <div className='d-flex justify-end'>
                                        <FormControl type='button' btnType='primary'
                                            text={labels.visit_page_labels.btn_questionnaire}
                                            onClick={() => this.props.addQuestionnaire(this.props.history)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {this.props.headerType == constants.visit_labels.visit ? <React.Fragment>
                                <div className='card-body-visit' >
                                    <FormControl type='button' btnType='default' text={labels.visit_page_labels.btn_edit_patient} onClick={() => this.props.history.push(`/patients/edit/${this.props.currentVisit.patientId}`)} />
                                </div>
                                <div className='card-body-visit' >
                                    <FormControl type='button' btnType='default' text={labels.visit_page_labels.btn_edit_visit} onClick={() => this.props.toggleVisitModal(constants.popup_labels.editVisit)} />
                                </div></React.Fragment> : null}

                            {(!this.props.ordersList)
                                || (this.props.ordersList && !this.props.ordersList.filter(a => a.orderStatus == constants.visit_labels.filled).length && this.props.loggedInUser.primarySiteId == this.props.currentVisit.siteId)
                                || (this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.AN)
                                || (this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.CG && this.props.loggedInUser.can_remove_patients && this.props.loggedInUser.primarySiteId == this.props.currentVisit.siteId)
                                ? <FormControl type='button' btnType='danger' text={labels.visit_page_labels.btn_delete_visit}
                                    id="visitDeleteBtn" onClick={() => this.props.toggleVisitModal(constants.popup_labels.deleteVisit)} /> : null}
                            {!(this.props.headerType == constants.visit_labels.visit) ?
                                <div className='card-body-visit mt-2' >
                                    <FormControl type='button' btnType='info' text={labels.visit_page_labels.btn_visit_summary} onClick={() => this.props.history.push(`/visits/${this.props.currentVisit.id}`)} />
                                </div> : null}
                            <div className='card card-default toggle-beacon'>
                                <div className='card-header'>
                                    {labels.visit_page_labels.report_label}
                                </div>
                                <div className='fa fa-file-pdf-o control card-body-report'>
                                    <a href='javascript:void(0)' onClick={() => this.props.getAllCategories(this.props.currentVisit.id, true, constants.visit_labels.patient_download, this.props.currentVisit.patientLanguagePref)}> {labels.visit_page_labels.download_link}</a>
                                </div>
                                <div className='fa fa-eye control control card-body-report'>
                                    <a onClick={() => this.props.navigateToReport(this.props.history, this.props.currentVisit.id, constants.visit_labels.patient, this.props.currentVisit.patientLanguagePref)}> {labels.visit_page_labels.preview_link}</a>
                                </div>

                                {/* <div className='fa fa-recycle control control card-body-report'>
                                <a href='javascript:void(0)'> {labels.visit_page_labels.recycle_report_link}</a>
                            </div>
                            <div className='recycle-text'>{labels.visit_page_labels.recycle_report_text}</div> */}
                            </div>
                            <div className={`card card-default toggle-beacon ${this.props.currentVisit.timePoint == constants.baseline ? "d-none" : ""}`}>
                                <div className='card-header'>
                                    {labels.visit_page_labels.report_past_label}
                                </div>
                                <div className='fa fa-file-pdf-o control card-body-report'>
                                    <a href='javascript:void(0)' onClick={() => this.props.getAllCategories(this.props.currentVisit.id, true, constants.visit_labels.patient_download_past, this.props.currentVisit.patientLanguagePref)}> {labels.visit_page_labels.download_link}</a>
                                </div>
                                <div className='fa fa-eye control control card-body-report'>
                                    <a onClick={() => this.props.navigateToReport(this.props.history, this.props.currentVisit.id, constants.visit_labels.patient_past, this.props.currentVisit.patientLanguagePref)}> {labels.visit_page_labels.preview_link}</a>
                                </div>
                            </div>
                            {this.props.currentVisit && this.props.currentVisit.timePoint != constants.baseline ?
                                <div className='card card-default toggle-beacon'>
                                    <div className='card-header'>
                                        {labels.visit_page_labels.download_historic_data}
                                    </div>
                                    <div className='fa fa-file-excel-o control control card-body-report mb-2'>
                                        <a onClick={() => this.props.downloadHistoricCategoryData(this.props.currentVisit.id, this.props.currentVisit.patientLanguagePref)}>
                                            {labels.visit_page_labels.download_link}
                                        </a>
                                    </div>
                                </div> : null}
                            <div className='card card-default toggle-beacon'>
                                <div className='card-header'>
                                    {labels.visit_page_labels.btn_visit_summary}
                                </div>
                                <React.Fragment>
                                    <div className='fa fa-file-pdf-o control card-body-report'>
                                        <a href='javascript:void(0)'
                                            onClick={() => this.props.downloadVisitRecommendations(this.props.history)}>
                                            {labels.visit_page_labels.download_link}
                                        </a>
                                    </div>
                                    {this.props.currentVisit && (!this.props.currentVisit.recommendations || !this.props.currentVisit.recommendations.length) ?
                                        < div className='mt-4 mb-2 mr-2 ml-2' dangerouslySetInnerHTML={{ __html: constants.no_recommendations_caution }}>
                                        </div> : null}
                                </React.Fragment>
                            </div>
                            <div className='card card-default toggle-beacon'>
                                <div className='card-header'>
                                    {labels.visit_page_labels.physician_report_label}
                                </div>
                                <div className='fa fa-file-pdf-o control card-body-report'>
                                    <a href='javascript:void(0)' onClick={() => this.props.getAllCategories(this.props.currentVisit.id, true, constants.visit_labels.physician_download, this.props.currentVisit.reportPreference, undefined, false)}> {labels.visit_page_labels.download_link}</a>
                                </div>
                                <div className='fa fa-eye control control card-body-report'>
                                    <a onClick={() => this.props.navigateToReport(this.props.history, this.props.currentVisit.id, constants.visit_labels.physician, this.props.currentVisit.reportPreference)}> {labels.visit_page_labels.preview_link}</a>
                                </div>
                                {/* <div className='fa fa-recycle control control card-body-report'>
                                <a href='javascript:void(0)'> {labels.visit_page_labels.recycle_report_link}</a>
                            </div>
                            <div className='recycle-text'>{labels.visit_page_labels.recycle_report_text}</div> */}
                            </div>
                            <div className={`card card-default toggle-beacon ${this.props.currentVisit.timePoint == constants.baseline ? "d-none" : ""}`}>
                                <div className='card-header'>
                                    {labels.visit_page_labels.physician_past_report_label}
                                </div>
                                <div className='fa fa-file-pdf-o control card-body-report'>
                                    <a href='javascript:void(0)' onClick={() => this.props.getAllCategories(this.props.currentVisit.id, true, constants.visit_labels.physician_past_download, this.props.currentVisit.reportPreference)}> {labels.visit_page_labels.download_link}</a>
                                </div>
                                <div className='fa fa-eye control control card-body-report'>
                                    <a onClick={() => this.props.navigateToReport(this.props.history, this.props.currentVisit.id, constants.visit_labels.physician_past, this.props.currentVisit.reportPreference)}> {labels.visit_page_labels.preview_link}</a>
                                </div>
                                {/* <div className='fa fa-recycle control control card-body-report'>
                                <a href='javascript:void(0)'> {labels.visit_page_labels.recycle_report_link}</a>
                            </div>
                            <div className='recycle-text'>{labels.visit_page_labels.recycle_report_text}</div> */}
                            </div>
                            <div className='card card-default patient-details-section toggle-beacon'>
                                <div className='card-header'>
                                    {labels.patient_list_labels.patient_details}
                                </div>
                                <div className='card-body'>
                                    {this.props.currentVisit ? <table>
                                        {Object.keys(patientDetails).map(pd => (
                                            pd != 'siteName' ? <tr>
                                                <td className='font-bold text-right text-nowrap'>{patientDetails[pd]}</td>
                                                <td className='text-left'>{this.props.currentVisit[pd] ? this.props.currentVisit[pd] : labels.not_available}</td>
                                            </tr> :
                                                <tr>
                                                    <td className='font-bold text-right text-nowrap'>{patientDetails[pd]}</td>
                                                    <td className='text-left'><Link to={`/sites/${this.props.currentVisit.siteId}`}>{this.props.currentVisit ? this.props.currentVisit[pd] : ''}</Link></td>
                                                </tr>
                                        ))}
                                    </table> : null}
                                </div>
                            </div>
                        </div>
                        : null}
                    <div className='visitsPage col-lg-9 col-md-12 col-sm-12 col-xs-12 right-section order-1'>
                        <SpreadingRoutes {...this.props} />
                    </div>
                </div>

                <div className={`modal fade ${this.props.isVisitModal ? 'show' : ''}`} role='dialog'>
                    <div className='modal-dialog modal-dialog-centered modal-md'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h3>{this.props.modalMode ? modalModes[this.props.modalMode] + this.props.order_panelName + " ?"  : null }</h3>
                                <i className='fa fa-times fa-lg status-indicator text-danger' onClick={() => this.props.toggleVisitModal()}></i>
                            </div>
                            <div className='modal-body'>
                                {this.props.updateVisitError ? <div className='alert alert-danger'>
                                    {this.props.updateVisitError}
                                </div> : null}
                                {this.props.orderError ? <div className='alert alert-danger'>
                                    {this.props.orderError}
                                </div> : null}
                                {this.props.modalMode == "deleteorder" ?
                                  <div className='mb-3'> { labels.orders_labels.delete_waring_body } </div> : null }
                                {this.props.modalMode == constants.popup_labels.editVisit ?
                                    <React.Fragment>
                                        <div className='d-flex flex-row ml-5'>
                                            <div className=' ml-4 mr-4 label_visit' >{labels.visit_page_labels.update_visit}</div>

                                            <FormControl id='update_visit'
                                                type='date' labelClass='col-lg-5'
                                                inputClass='col-lg-7'
                                                onChange={this.props.editVisit}
                                                selected={this.props.selectedVisitDate}
                                                help={labels.visit_page_labels.update_visit_help}
                                                maxDate={addMonths(new Date(), 1)} />

                                        </div>
                                        <div className='d-flex justify-end'>
                                            <FormControl type='button'
                                                btnType='primary' className='btn-sm'
                                                text={labels.visit_page_labels.update_visit} onClick={() => this.props.updateVisit(this.props.history)} />
                                        </div>
                                    </React.Fragment> :
                                    this.props.modalMode == constants.popup_labels.deleteVisit ?
                                        <React.Fragment>
                                            <div className="mb-4">{labels.visit_page_labels.delete_visit_desc}</div>
                                            <div className='d-flex justify-space-between'>
                                                <FormControl type='button'
                                                    btnType='default' className='btn-sm'
                                                    text={labels.help_page_labels.cancel_btn} onClick={() => this.props.toggleVisitModal()} />
                                                <FormControl type='button'
                                                    btnType='danger' className='btn-sm'
                                                    text={labels.help_page_labels.ok_btn}
                                                    onClick={() => this.props.deleteVisit(this.props.history)} />
                                            </div>
                                        </React.Fragment> :
                                        this.props.modalMode == constants.popup_labels.deleteOrder || this.props.modalMode == constants.popup_labels.deleteQuestionnaire ?
                                            <React.Fragment>
                                                <div className='d-flex justify-space-between'>
                                                    <FormControl type='button' className='btn-sm'
                                                        btnType='default'
                                                        text={labels.help_page_labels.cancel_btn} onClick={() => this.props.toggleVisitModal()} />
                                                    <FormControl type='button'
                                                        btnType='danger' className='btn-sm'
                                                        text={labels.help_page_labels.ok_btn}
                                                        onClick={() => this.props.deleteOrder(this.props.history)} />
                                                </div>
                                            </React.Fragment> :
                                            this.props.modalMode == constants.popup_labels.addCredits ?
                                                <React.Fragment>
                                                    <div className='d-flex justify-end'>
                                                        <FormControl type='button' btnType='primary'
                                                            text={labels.credits_labels.add_credits} className='btn-sm'
                                                            onClick={() => {
                                                                this.props.history.push(`/credits/${this.props.currentVisit.siteId}/new`)
                                                                //  this.props.addCreditTransaction(this.props.history, this.props.currentSite)
                                                            }} />
                                                    </div>
                                                </React.Fragment> :
                                                this.props.modalMode == constants.popup_labels.outOfBounds ?
                                                    <React.Fragment>
                                                        <p className='mb-3' >{labels.orders_labels.out_of_bounds_exception_one}</p>
                                                        <p className='mb-3' >{labels.orders_labels.out_of_bounds_exception_two}</p>
                                                        <hr></hr>
                                                        <div className='d-flex justify-end'>
                                                            <FormControl type='button' btnType='primary'
                                                                text={labels.orders_labels.close_btn} className='btn-md'
                                                                onClick={() => this.props.toggleVisitModal()} />
                                                        </div>
                                                    </React.Fragment> :
                                                    null
                                }
                            </div>

                        </div>
                    </div>
                </div>
                {this.props.isSectionModalOpen ?
                    <div className={`modal fade ${(this.props.isSectionModalOpen) ? 'show' : ''} report-card_modal`} role='dialog'>
                        <div className='modal-dialog modal-dialog-centered modal-md'>
                            <div className='modal-content'>
                                <div className='modal-header palette_default d-flex justify-content-start'>
                                    <div className='m-3'>
                                        <Grade points={this.props.currentSectionModal.points} />
                                    </div>
                                    <div className='ml-4 mt-2'>
                                        <div className='section-title'>{this.props.currentSectionModal.name}</div>
                                        {this.props.currentSectionModal.data.filter(csm => (csm.points != undefined && csm.points != null && !isNaN(csm.points))).length} of {this.props.currentSectionModal.data.length} tests scored
                                    </div>
                                </div>
                                <div className='modal-body report-card-modal-body'>
                                    <BootstrapTable bordered={false} hover={true} bootstrap4 keyField='id'
                                        data={this.props.currentSectionModal.data} columns={datacolumns}
                                    />
                                </div>
                                <div className='modal-footer'>
                                    <FormControl type='button' btnType='primary' onClick={() => { this.props.closeSectionModal() }} text={labels.inbox_labels.back} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                {this.props.isQuestionnaireModalOpen ?
                    <div className={`modal fade ${(this.props.isQuestionnaireModalOpen) ? 'show' : ''} report-card_modal`} role='dialog'>
                        <div className='modal-dialog modal-dialog-centered modal-md'>
                            <div className='modal-content'>
                                <div className='modal-header palette_default d-flex justify-content-start'>
                                    <div className='ml-4 mt-2'>
                                        <div className='section-title-questionnaire'>{this.props.currentQuestionnaire.questionnaireName} </div>
                                        <div className={`badge badge-${getBadge(this.props.currentQuestionnaire)}`}>
                                            {!this.props.currentQuestionnaire.status && this.props.currentQuestionnaire &&
                                                this.props.currentQuestionnaire.questionnaireQuestionsDTO && this.props.currentQuestionnaire.questionnaireQuestionsDTO.length ?
                                                'completed'
                                                : this.props.currentQuestionnaire.status == 'pending' ? 'incomplete' : this.props.currentQuestionnaire.status
                                            }</div>
                                    </div>
                                </div>
                                <div className='modal-body questionnaire-card-modal-body'>
                                    {this.props.currentQuestionnaire.questionnaireQuestionsDTO && this.props.currentQuestionnaire.questionnaireQuestionsDTO.length > 0 ?
                                        <BootstrapTable bordered={false} hover={true} bootstrap4 keyField='id'
                                            data={this.props.currentQuestionnaire.questionnaireQuestionsDTO} columns={questionnairecolumns}
                                        />
                                        : <p className='text-center'>{labels.questionnaire_labels.questions_data_not_present}</p>
                                    }
                                </div>
                                <div className='modal-footer'>
                                    { this.props.currentQuestionnaire.status == constants.visit_labels.pending ?
                                        <FormControl type='button' btnType='success' onClick={() => { this.props.markQuestionnaireCompleted(this.props.currentQuestionnaire.id, null, this.props.currentQuestionnaire.questionnaireName, this.props.history) }} text={labels.inbox_labels.mark_completed} />
                                        : '' 
                                    }
                                    <FormControl type='button' btnType='primary' onClick={() => { this.props.closeQuestionnaireModal() }} text={labels.inbox_labels.back} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                {
                    this.props.patientReportDownloadTemplateSection ? <div className='display-none reports-download-section'>
                        <PatientReportDownloadTemplate {...this.props} />
                    </div> : this.props.patientReportDownloadTemplatePastSection ? <div className='display-none reports-download-section'>
                        <PatientReportDownloadTemplatePast {...this.props} />
                    </div> : null
                }
                {
                    this.props.physicianReportDownloadTemplateSection ? <div className='display-none'>
                        <PhysicianReportDownloadTemplate {...this.props} />
                    </div> : this.props.physicianReportDownloadTemplatePastSection ? <div className='display-none'>
                        <PhysicianReportDownloadTemplatePast {...this.props} />
                    </div> : null
                }
            </div >
        )
    }
}
export default Visits;
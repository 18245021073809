import React, { Component } from 'react';
import labels from '../../config/localization';
import './set-password-styles.scss';
import FormControl from '../FormControls';
import Notification from '../Notification';
import cardLogo from '../../assets/images/logo_physioage-horizontal.svg';

class SetPassword extends Component {
    componentDidMount() {
        this.props.validateSetPassword(this.props.match.params.perishcode, this.props.history)
    }
    render() {
        return (
            <div className='app wrapper unauth-layout' >
                <div className='card parent-card status-card' >
                    <div className='row'>
                        <img src={cardLogo} alt={'physioage-logo'} />
                    </div>
                    <div className='row'>
                        <form className='linkexpired' onSubmit={(e) => { e.preventDefault(); this.props.setPassword(this.props.history) }}>
                            <h1>{labels.set_password}</h1>
                            {this.props.isNotify ? <Notification  {...this.props.notifications} /> : ''}
                            <p>{labels.set_password_notification}</p>
                            <FormControl id='resetPasswordTxt' placeholder={labels.password} type='password' onChange={this.props.loginInput} />
                            <FormControl id='resetConfirmPasswordTxt' type='password' placeholder={labels.confirm_password} onChange={this.props.loginInput} />
                            <div className='d-flex login-btn-section justify-content-center'>
                                <button className='btn btn-success'>{labels.update_password}</button>
                                <button className='btn btn-default' onClick={(e) => { e.preventDefault(); this.props.logOut(this.props.history) }}>
                                    {labels.header_labels.logout}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        )
    }
}
export default SetPassword;
import React, {useEffect } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import close from '../../../assets/images/close.svg';
import overview from '../../../assets/images/overview.svg';
import overviewLight from '../../../assets/images/overview_light.svg';
import add_notes from '../../../assets/images/add_notes.svg';
import viavi_add_notes from '../../../assets/images/viavi_theme_add.svg';
import edit from '../../../assets/images/edit.svg'
import delete_icon from '../../../assets/images/delete_red.svg'
import moment from 'moment';
import { getDateFormat } from '../tools/helpers';
import { connect } from 'react-redux';
import CardLoader from '../containers/CardLoaderContainer';
import { ToastContainer } from 'react-toastify';
import labels from '../../../config/localization';
import closeLight from '../../../assets/images/close-light.svg';
import constants from '../../../config/constants';


function ProviderNotesComponent(props) {

   const showMore = (e) => {
        e.currentTarget.classList.toggle("show-more-height")
        e.currentTarget.children[0].classList.toggle("d-none")
        e.currentTarget.children[1].classList.toggle("d-block")
    }
    useEffect(() => {
        props.getProviderNotes()
    return () => {
            // Anything in here is fired on component unmount.
    }
    }, [])

    return (
        <>
            <div className='drawer-warning-overlay'></div>
            <div className='provider-notes'>
                <div className='drawer-warning'>
                    <div className='d-flex row header no-gutters'>
                        <div className='col-11 bold-14-text'> {labels.analytics_labels.provider_notes} </div>
                        <div className='col-1 justify-content-end d-flex'>
                            <a><img src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleProviderNotes(false))}></img></a>
                        </div>
                    </div>
                    <div className='card-body-div'>
                        {props.providerNotesErrors && props.providerNotesErrors.length ?
                            <div className='alert alert-danger'>
                                {props.providerNotesErrors.map(re => <p>{re}</p>)}
                            </div> : null
                        }
                        {/* <ToastContainer autoClose={1000} /> */}
                        {props.showNewNotes ?
                            <div className="new-notes mt-2">
                                <div className='d-flex row'>
                                    <div className='col-8 bold-13-text'><img src={props.theme == "light-theme" ? overview : overviewLight} className="mb-1 mr-1"></img>{ props.providerNotesForm && props.providerNotesForm.id ? "Edit Notes"  : "New Notes"}</div>
                                    <div className='col-4'>
                                        <a className='float-right' onClick={() => (props.newProviderNotes())}>
                                        <img className='mb-1 mr-1' src={props.siteTheme == constants.VIAVI_THEME ? viavi_add_notes : add_notes} ></img>{labels.analytics_labels.create_notes} </a>
                                    </div>
                                </div>
                                <FormControl required={true} type="textarea" id= "notes" onChange={props.editProviderNotes} defaultValue={props.providerNotesForm && props.providerNotesForm.notes ? props.providerNotesForm.notes : ""} inputClass={"mt-2 text-area-note-height"} placeholder={"Type Here.."} />
                                <div className='d-flex row'>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-12'></div>
                                    <div className='col-lg-4 col-md-4 col-sm-4 col-12 d-flex justify-content-end'>
                                        <button className='cancle-btn btn mr-3' onClick={() => { props.toggleNewNotes(false) }}>{labels.analytics_labels.cancel}</button>
                                        <button className='add-btn btn' onClick={() => { props.saveProviderNotes() }}> {props.providerNotesForm && props.providerNotesForm.id ? 'Update' : 'Add' }</button>
                                    </div>
                                </div>
                            </div>
                        : ""} 
                        <div className='drawer-body mt-2'>
                            <div className='d-flex row'>
                                <div className='col-8 bold-13-text'><img src={props.theme == "light-theme" ? overview : overviewLight} className="mb-1 mr-1"></img> {labels.analytics_labels.previous_notes}</div>
                                {props.showNewNotes ?
                                    null :
                                    <div className='col-4'>
                                        <a className='float-right' onClick={() => (props.newProviderNotes())}>
                                        <img className='mb-1 mr-1' src={props.siteTheme == constants.VIAVI_THEME ? viavi_add_notes : add_notes} ></img>{labels.analytics_labels.create_notes} </a>
                                    </div>
                                }    
                            </div>
                            
                            <div className='notes-div'>
                                { !props.stopNotesLoading ? 
                                    <CardLoader  />
                                :
                                props.providerNotes && props.providerNotes.length > 0 ? 
                                    props.providerNotes.map(providerNote => {
                                    return (
                                        <div className='notes-body'>
                                            <div className='d-flex row'>
                                                <div className='col-10'>
                                                    <span className='visit-date'>{'Date :' + moment(providerNote.date).format(getDateFormat(props.presentPatient.datePreference))}</span>
                                                </div>
                                                <div className='col-2'>
                                                    <span className='visit-date float-right d-flex'>
                                                        <img className='mr-2 c-p edit' src={edit} onClick={() => props.updateProviderNotes(providerNote.id)}></img>
                                                        <img className='c-p' src={delete_icon} onClick={() => props.deleteProviderNotes(providerNote.id)}></img>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='notes'>
                                                { providerNote.notes && providerNote.notes.length < 80 ?
                                                    providerNote.notes
                                                    :
                                                    <div className='show-more-height c-p' onClick={(e) => showMore(e)}>
                                                        <span className='show-less-text'>
                                                            { providerNote.notes ? providerNote.notes.substring(0, 80) : ""} 
                                                            <span className="gray-text">... {labels.analytics_labels.show_more}</span>
                                                        </span>
                                                        <span className='show-more-text'>
                                                            {providerNote.notes}
                                                        </span>
                                                    </div>    
                                                }   
                                            </div>     
                                        
                                        </div>
                                    )
                                    })
                                :
                                ""
                                }
                            </div>    
                        </div>
                    </div>    
                </div>
            </div>
        </>
    )
}
export default connect()(ProviderNotesComponent);
import React from 'react';
import Grade from './Grade.js';
import labels from '../../../config/localization.js';

export default class DatumAverage extends React.Component {
  constructor(props)
  {
    super(props);
  }

  render() 
  {
    return(
      <div className='card reset card-wide'>
        <div className='result-wrapper'>
          <div className='result'>
            <div className='title'>{labels.analytics_labels.average_peers}</div>
            <div className='grade-card'>
              <div className='grade-icon'>
                <Grade points={this.props.datum.avg_population} />
              </div>
              <div>
                <div className='grade-population'>
                  { this.props.datum.population_text } 
                </div>
              </div>
            </div>
            <div className='grade-card'>
              <div className='grade-icon'>
                <Grade points={this.props.datum.avg_everyone} />
              </div>
              <div>
                <div className='grade-population'>
                  {labels.analytics_labels.everyone_label}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

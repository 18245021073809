import React from 'react';
import moment from 'moment';
import { mostRecentDatum } from '../tools/helpers';
import _ from 'lodash';
import SpriteIcon from '../components/SpriteIcon';
import { setSitePreference } from '../tools/calendar';
import sprite from '../../../assets/images/sprite_base.svg'
import labels from '../../../config/localization';
import Grade from './Grade';
import constants from '../../../config/constants';
import { getRoundedValue } from '../tools/helpers';
import NoumenonValue from './NoumenonValue';

export default class ValueCard extends React.Component {
  renderGrade(points) {
    if (!(points === null || points === undefined || isNaN(points))) {
      return (
        <div className="grade-icon">
          <Grade points={points} />
        </div>
      )
    }
    else { return (null) }
  }
  renderHeading(isCurrent) {
    if (isCurrent) {
      return (
        <div className='title'>{labels.analytics_labels.your_result}</div>
      )
    }
    else {
      return (
        <div className='title'>
          <div className='sprite'>
            <svg aria-hidden='true' title='Close'>
              {/* <use xlinkHref={`${sprite}#clock`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
              <use xlinkHref="#clock" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
              <symbol id="clock" viewBox="0 0 512 512">
                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z" class="">
                </path>
              </symbol>
            </svg>
          </div>
          {labels.analytics_labels.past_results}
        </div>
      )
    }
  }
  render() {
    if (this.props.drawerType == 'biomarker') {
      let isCurrent = moment(this.props.updated_lastVisitDate).format('YYYY-MM-DD') <= moment(this.props.dateOfVisit).format('YYYY-MM-DD')
      let value_class = isCurrent ? 'current-value' : 'historic-value'
      return (
        <div className={"card reset card-wide " + value_class}>
          <div className='result-wrapper'>
            <div className='result'>
              {this.renderHeading(isCurrent)}
              <div className='trend-card'>
                <div>
                  <div className="trend">{`${this.props.units} Years`}</div>
                </div>
              </div>
            </div>
            <div className="date">{labels.analytics_labels.as_of_label} {setSitePreference(this.props.siteData.datePreference, this.props.dateOfVisit)}</div>
          </div>
        </div>
      )
    }
    else if (this.props.drawerType == "reportCard") {
      let isCurrent = moment(this.props.updated_lastVisitDate).format('YYYY-MM-DD') <= moment(_.last(this.props.grading_data).visitDate).format('YYYY-MM-DD')
      let value_class = isCurrent ? 'current-value' : 'historic-value'
      return (<div className={"card reset card-wide " + value_class}>
        <div className='result-wrapper'>
          <div className='result'>
            {this.renderHeading(isCurrent)}
            <div className='trend-card'>
              {this.renderGrade(_.last(this.props.grading_data).points)}
              <div>
                <div className="trend">{`${_.last(this.props.grading_data).points} GPA`}</div>
              </div>
            </div>
          </div>
          <div className="date">{labels.analytics_labels.as_of_label} {setSitePreference(this.props.siteData.datePreference, _.last(this.props.grading_data).visitDate)}</div>
        </div>
      </div>)
    }
    else if (this.props.drawerType == "category") {
      let isCurrent = moment(this.props.updated_lastVisitDate).format('YYYY-MM-DD') <= moment(_.last(this.props.categoryGradesData).visitDate).format('YYYY-MM-DD')
      let value_class = isCurrent ? 'current-value' : 'historic-value'
      return (<div className={"card reset card-wide " + value_class}>
        <div className='result-wrapper'>
          <div className='result'>
            {this.renderHeading(isCurrent)}
            <div className='trend-card'>
              {this.renderGrade(_.last(this.props.categoryGradesData).points)}
              <div>
                <div className="trend">{`${_.last(this.props.categoryGradesData).value} GPA`}</div>
              </div>
            </div>
          </div>
          <div className="date">{labels.analytics_labels.as_of_label} {setSitePreference(this.props.siteData.datePreference, _.last(this.props.categoryGradesData).visitDate)}</div>
        </div>
      </div>)
    }
    else if (this.props.drawerType == "section") {
      let isCurrent = moment(this.props.updated_lastVisitDate).format('YYYY-MM-DD') <= moment(_.last(this.props.sectionGradesData).visitDate).format('YYYY-MM-DD')
      let value_class = isCurrent ? 'current-value' : 'historic-value'
      return (<div className={"card reset card-wide " + value_class}>
        <div className='result-wrapper'>
          <div className='result'>
            {this.renderHeading(isCurrent)}
            <div className='trend-card'>
              {this.renderGrade(_.last(this.props.sectionGradesData).points)}
              <div>
                <div className="trend">{`${_.last(this.props.sectionGradesData).value} GPA`}</div>
              </div>
            </div>
          </div>
          <div className="date">{labels.analytics_labels.as_of_label} {setSitePreference(this.props.siteData.datePreference, _.last(this.props.sectionGradesData).visitDate)}</div>
        </div>
      </div>)
    }
    else {
      let isCurrent = this.props.datum.value
      let value_class = isCurrent ? 'current-value' : 'historic-value'
      return (
        this.props.datum ?
          <>
          <div className={"card reset card-wide current-value"}>
            <div className="result-wrapper">
              <div className="result">
                {this.renderHeading(isCurrent)}
                <div className='trend-card'>
                  {this.renderGrade(this.props.datum.points)}
                  { (this.props.datum.valueType && this.props.datum.valueType.toLowerCase() == "st" && this.props.datum.isTextArea == 1) ?
                      <div className='trend'>
                        {this.props.datum.value.length > 50 ?
                      <div>
                       {this.props.datum.value.slice(0,50)} <span onMouseOver={() => this.props.openValueModel(this.props.datum.value, this.props.datum.label)}> ... </span>
                      </div>
                     : this.props.datum.value} </div>
                  :
                  this.props.datum.valueType == "NM" ?
                    <div className='trend'> {getRoundedValue(this.props.datum.value, this.props.datum.scale)} {this.props.datum.units}</div>
                    : <div className='trend'> {this.props.datum.value} {this.props.datum.units}</div>}
                </div>
              </div>
              <div className="date">{labels.analytics_labels.as_of_label} {setSitePreference(this.props.datum.siteDatePreference, this.props.datum.visitDate)}</div>
            </div>
          </div>
          <div className='data-section p-0 credits-children'>
              {this.props.openValueModal ?
                <NoumenonValue {...this.props}></NoumenonValue> : null}
          </div>
          </> : ''
      )
    }
  }
}

import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { HashLink as Link } from 'react-router-hash-link';
import moment from 'moment';
import _ from 'lodash';
import Grade from '../../Analytics/components/Grade';
import logo from '../../../assets/images/logoReport.png';
import '../../Analytics/components/Analytics.scss'
import ReactHighcharts from 'react-highcharts';
import HealthStatusIndicators from './HealthStatusIndicators';
import IndividualVisit from './IndividualVisit';
import ReactDOMServer from 'react-dom/server';
import constant from '../../../config/constants';
import { CalculateChange } from '../../Analytics/tools/calendar'
import html2react from 'html-to-react'
import marked from 'marked';
import { parse } from 'qs';
import { getLocalizedDate } from '../../../utils/reports-date-localization';
import constants from '../../../config/constants';
import en_labels from '../../../config/en_labels';
import report_constants from '../../../config/reportConstants';
import labels from '../../../config/localization';
import getHistoricalChartUrl from '../../../utils/historical-chart-util';
import { getRoundedValue, IsInRange } from '../../Analytics/tools/helpers';
import IndividualReportRecommendation from './IndividualReportRecommendation';
import IndividualReportProblem from './IndividualReportProblem';

class PatientReport extends Component {

    componentWillMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        this.locale = this.urlParams.locale ? this.urlParams.locale : constants.language_codes.ENGLISH
        if (Object.values(constants.language_codes).indexOf(this.locale) == -1)
            this.locale = constants.language_codes.ENGLISH
    }
    componentDidMount() {
        // document.body.style = 'background: #ffffff url(/static/media/icon_physioage.dc22930d.svg) no-repeat center right !important;';
        if (this.props.match && this.props.match.params && this.props.match.params.visitId && !this.props.currentVisit.id) {
            this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history, false, this.locale, false, false, "patient")
            // this.props.getAllCategories(this.props.match.params.visitId, false, "patient", this.locale, this.props.history)
            // this.props.followUpData()
        } else if (this.props.latestVisitId) {
            this.props.setCurrentVisit(this.props.latestVisitId, this.props.history, false, null, false, false, "patient")
            // this.props.getAllCategories(this.props.latestVisitId)
        } else if (this.props.currentVisit && this.props.currentVisit.id) {
            this.props.setCurrentVisit(this.props.currentVisit.id, this.props.history, false, null, false, false, "patient")
        }
        document.title = `${labels.physioage_label} Report`
        window.scrollTo(0, 0)
    }

    markdown2react(md) {
        marked.setOptions({
            renderer: new marked.Renderer(),
            sanitize: true,
            smartLists: true,
        });

        let parser = new html2react.Parser();
        return (parser.parse(marked(md)));
    }
    getTermTranslations(terms, key) {
        return terms ? _.head(terms.filter(s => s.key == key)).termTranslationsDTOList.text : ''
    }
    returnBiomarkerAgePassage(passagesData, ageName) {
        return (passagesData.filter(s => s.key.toLowerCase() == ageName.toLowerCase()).map(s => s.passagesDTO.map(t => {
            if (t.passageTranslationDTO != null && t.passageTranslationDTO.content != null) {
                return <div className="passage">
                    {/* {["Description", "Descriere", "説明"].indexOf(t.passageTranslationDTO.heading) == -1 ? <h3 className="passage-heading"><b>{t.passageTranslationDTO.heading}</b></h3> : " "} */}
                    {this.markdown2react(t.passageTranslationDTO.content)}
                </div>
            }
        })))
    }
    renderExplaination(passageData) {
        return <div className="passage">
            {this.markdown2react(passageData)}
        </div>
    }
    getFullPath() {
        return `${this.props.location.pathname}${this.props.location.search}`
    }
    // generateArtisanURL() {
    //     let data = this.props.dataForArtisanURL ? this.props.dataForArtisanURL : [];
    //     let followUpUrl = constants.biographical_labels.artisan_route
    //     let aggregateAge = {};
    //     data.map(age => {
    //         // if (aggregateAge[age.label] && age.value != "0" && age.timePoint == this.props.currentVisit.timePoint) {
    //         //     aggregateAge[age.label].push(`${age.value}`)
    //         // }
    //         // else {
    //         //     if(age.value != "0" && age.timePoint == this.props.currentVisit.timePoint){
    //         //         aggregateAge[age.label] = []
    //         //         aggregateAge[age.label].push(`${age.value}`)
    //         //     }
    //         // }
    //         if (age.value != "0" && age.value != 0 && age.value != "" && age.timePoint == this.props.currentVisit.timePoint) {
    //             aggregateAge[age.label] = []
    //             aggregateAge[age.label].push(`${age.value}`)
    //         }
    //     })
    //     let final = []
    //     let age = []
    //     let groupByTimePoint = _.groupBy(data, 'timePoint')
    //     age = Math.floor(_.head(groupByTimePoint[this.props.currentVisit.timePoint]).age);
    //     Object.keys(aggregateAge).map(agg => {
    //         // agg != constants.epigenAge && agg != constants.glycanAge && 
    //         if (aggregateAge[agg].filter(a => a).length) {
    //             final.push(`n:${constants.biographical_labels.ageLabels[agg]}^v:${aggregateAge[agg].join()}^b:${age}`)
    //         }
    //     })
    //     followUpUrl = `${followUpUrl}?data=${final.join('|')}`;
    //     return followUpUrl;

    // }
    renderCategoryGrade(category, visitId) {
        var reportCards = []
        const reportlabels = report_constants[this.props.currentVisit.reportPreference]
        category.map(p => {
            if (p.sections.length > 0) {
                p.sections.map(s => {

                    let currentVisitsData = s.data.filter(t => t.visitId == visitId
                        && !(t.points === undefined || t.points == null || isNaN(t.points)))

                    if (currentVisitsData.length > 0) {
                        var points = _.sum(currentVisitsData.map(csd => csd.points)) / currentVisitsData.length
                        reportCards.push(<div className='grade-card'>
                            <div className='grade-block'>
                                <Grade points={points} />
                            </div>
                            <React.Fragment>
                                <div className='description'>{s.name}</div>
                                <div className='reference'><Link to={`${this.getFullPath()}#${s.key}`}>{reportlabels.report_labels.readmore}</Link></div>
                            </React.Fragment>
                        </div>)
                    }
                })
            }

        })
        return reportCards;

    }
    ageCalculation(ageName) {
        var ageData = this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == ageName).map(s => s.value) : '';
        if (ageData) {
            var chronological = this.props.currentVisit.patientVisitAge;
            var physiological = ageData;
            if (!physiological)
                return "This biomarker hasn't been measured recently."
            else {
                var diff = getRoundedValue(chronological - physiological, 1);
                if (physiological > chronological)
                    return (`${this.props.terms['Your']} ${this.props.culturedBiomarkers[ageName]} ${this.props.terms['is']} ${physiological}, 
                        ${this.props.terms['6 years older than'].replace(this.props.terms['6 years older than'][0], Math.abs(diff))} ${this.props.terms['than average for your age']}`);
                else if (physiological < chronological)
                    return (`${this.props.terms['Your']} ${this.props.culturedBiomarkers[ageName]} ${this.props.terms['is']} ${physiological},
                         ${this.props.terms['7 years younger than'].replace(this.props.terms['7 years younger than'][0], diff)} ${this.props.terms['than average for your age']}`);
                else {
                    return (`${this.props.terms['Your']} ${this.props.culturedBiomarkers[ageName]} ${this.props.terms['is']} ${physiological}, ${this.props.terms['neither older nor younger']}`);
                }
            }
        }
        else {
            return this.props.terms['test not performed']
        }
    }
    getBaselineChart = (ageName) => {
       let age =  this.props.currentAggregateAge ? this.props.currentAggregateAge[ageName] : []
       let patientAge = this.props.currentVisit ? this.props.currentVisit.patientVisitAge : ''
        const plotLines = [];
        for (let i = 0; i <= 100; i += 5) {
            plotLines.push(
            {
                color: '#bbb',
                value: i,
                width: 1,
                zIndex: 3
            }
            );
        }
        let options = {
            chart: {
              type: 'scatter',
              zoomType: 'xy'
            },
            title: {
              text: 'Physiological Age vs Chronological Age'
            },
            xAxis: {
              title: {
                enabled: true,
                text: 'Chronological Age (years)'
              },
              startOnTick: true,
              endOnTick: true,
              showLastLabel: true,
              max: 100,
              min: 0,
              plotLines: plotLines,
              
            },
            yAxis: {
              title: {
                text: 'Physiological Age (years)'
              },
              startOnTick: true,
              endOnTick: true,
              showLastLabel: true,
              max: 100,
              min: 0,
              plotLines: plotLines
            },
            plotOptions: {
              scatter: {
                marker: {
                  radius: 5,
                  states: {
                    hover: {
                      enabled: true,
                      lineColor: 'rgb(100,100,100)'
                    }
                  }
                },
                states: {
                  hover: {
                    marker: {
                      enabled: false
                    }
                  }
                },
                tooltip: {
                  headerFormat: '',
                  pointFormat: `Patient Age: {point.x} years <br> ${this.props.culturedBiomarkers[ageName]} Age: {point.y} years`
                }
              }
            },
            series: [{
              color: '#36c',
              data: [[patientAge, parseFloat(age[0])]]
            }]
          };
        return (
            <div className='react-high-chart'> 
                <ReactHighcharts
                config={options}
                />
            </div>
          );
       // return <img className='baseline-chart' src={`http://chart.apis.google.com/chart?cht=s&chd=t:${this.props.currentVisit.patientVisitAge},0,100|${this.getAgeData(ageName)},0,100|100,0,0&chg=5,5,1,0,0&chco=${this.getPointColor(this.getAgeData(ageName), this.props.currentVisit.patientVisitAge)}&chxt=x,y,x,y&chxr=0,0,100,20|1,0,100,20&chs=320x250&chf=c,ls,135,FFFFFF,0.40,EEEEEE,0.2,FFFFFF,0.4&chxl=2:|Chronological Age|3:|Physiological Age|&chxp=2,50|3,50&chm=D,999999,0,1:2,1,-1`} />
    }
    
    getBiomakersConfig(rowData, graphVisitData, xAxisData) {
        if (rowData) {
            return {
                chart: {
                    type: 'spline'
                },
                xAxis: {
                    categories: xAxisData ? xAxisData : '',
                    tickInterval: 1,
                    plotLines: [{
                        color: 'black',
                        width: 2,
                        value: -0.5
                    }]
                },
                plotOptions: {
                    series: {
                        lineWidth: 2,
                        connectNulls: true,
                        marker: {
                            radius: 4
                        }
                    }
                },
                yAxis: {
                    value: 20,

                    tickInterval: 20,
                },
                series: [
                    {
                        data: rowData ? rowData.map(s => parseFloat(s)) : '',
                        color: '#36c'
                    },
                    {
                        data: graphVisitData ? graphVisitData : '',
                        color: '#bbb'
                    }
                ]
            };
        }
    }

    isReportCardExist(category, visitId) {
        let allData = []
        if (category)
            category.map(c => {
                c.sections.map(sec => sec.data.map(d => allData.push(d)))
            })
        allData = allData.filter(ad => ad.visitId == visitId && ad.points !== undefined && ad.points != null && !isNaN(ad.points))
        return allData.length ? true : false
    }
    getHistoricData() {
        if (this.props.categories) {
            var final = []
            var allData = []
            var allVisits = []

            this.props.categories.map(t => t.sections.map(s => { return { [s.name]: s.data } }).map(f => { final.push(f) }))
            if (final && final.length)
                final.map(f => f[_.head(Object.keys(f))].map(k => _.sortBy(k.historicData, (h) => new Date(h)).map(hd => {
                    allVisits.push(hd.visitDate);
                    allData.push(hd);
                })))
            allVisits = _.sortBy(_.uniq(allVisits), v => new Date(v))
            allData = _.groupBy(allData, 'label')
            // console.log(final)
            // console.log(allVisits)
            // console.log(allData)
            var finalPanelObs = final.map(f => {
                let fkey = _.head(Object.keys(f))
                return { [fkey]: f[fkey].map(l => l.label) }
            })
            if (allVisits && allVisits.length <= 10) {
                return <div className='patients-report-preview-table'>
                    <div className='bootstrap-table-container'>
                        <table className='table table-striped table-hover'>
                            <thead className='historic-data-visits-title'>
                                <th>{this.props.terms['Visit Date']}</th>
                                {allVisits.map(av => <th className='dates'>{moment(av).format('DD/MM')}<br></br>{moment(av).format('YYYY')}</th>)}
                            </thead>
                            <tbody>
                                {finalPanelObs.map(fpo => {
                                    var fkey = _.head(Object.keys(fpo))
                                    return (
                                        <>
                                            <div className='text-center historic-panel-header'>
                                                <b>{fkey}</b>
                                            </div>
                                            {fpo[fkey].map(obs => <tr>
                                                <td>{obs}</td>
                                                {allVisits.map(currentVisit => {
                                                    let availableVal = _.head(allData[obs].filter(indiObs => indiObs.visitDate == currentVisit))
                                                    if (availableVal) {
                                                        return <td>{availableVal.value}</td>
                                                    } else {
                                                        return <td></td>
                                                    }
                                                })}
                                            </tr>)}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table >
                    </div>
                </div>
            }
            else {
                let baselineDate = _.head(allVisits)
                let recentVisit = _.last(allVisits)
                return <div className='patients-report-preview-table'>
                    <div className='bootstrap-table-container'>
                        <table className='table table-striped table-hover'>
                            <thead className='historic-data-visits-title'>
                                <th>{this.props.terms['Visit Date']}</th>
                                <th>{this.props.terms['Baseline']}</th>
                                <th>{this.props.terms['most recent visit']}</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {finalPanelObs.map(fpo => {
                                    var fkey = _.head(Object.keys(fpo))
                                    return (
                                        <>
                                            <div className='text-center historic-panel-header'>
                                                <b>{fkey}</b>
                                            </div>
                                            {fpo[fkey].map(obs => {
                                                let headValue = _.head(allData[obs].filter(indiObs => indiObs.visitDate == baselineDate))
                                                let tailValue = _.head(allData[obs].filter(indiObs => indiObs.visitDate == recentVisit))
                                                let valueType = _.head(allData[obs]).valueType
                                                return <tr>
                                                    <td>{obs}</td>
                                                    <td>{headValue ? headValue.value : ''}</td>
                                                    <td>{tailValue ? tailValue.value : ''}</td>
                                                    <td>{valueType != constants.text_value_type ? this.getChartSource(allData, obs, allVisits) : ''}</td>
                                                </tr>
                                            })}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table >
                    </div>
                </div>
            }

        }
    }

    getChartSource(allNumericalData, obs, allVistsDates) {
        let values = []
        allVistsDates.map(avd => {
            let preVal = allNumericalData[obs].filter(and => and.visitDate == avd)
            if (preVal && preVal.length) {
                var val = _.head(preVal).value
                if (!isNaN(parseInt(val)))
                    values.push(val)
                else
                    values.push('0')
            } else {
                values.push('0')
            }
        })
        if (parseFloat(_.min(values)) == 0 && parseFloat(_.max(values)) == 0 || (values.filter(v => v != '0').length <= 2)) {
            return ''
        } else {
            values = values ? values.filter(v => parseFloat(v)) : []
            values = values.map(v => parseFloat(v))
            values = values.filter(v => v != '0')
            let config = {
                chart: {
                    type: 'column',
                    height: 60,
                    width: 200,
                    backgroundColor: 'transparent'
                },
                title: {
                    text: '',
                },
                legend: {
                    enabled: false
                },
                    xAxis: {
                    lineWidth: 1,
                    tickLength: 0, 
                    lineColor: '#667788',
                    gridLineWidth: 0,
                    labels: {
                        enabled: false
                    }
                    },
                    yAxis: {
                    title: {
                        text: ''
                    },
                    lineWidth: 1.5, 
                    gridLineWidth: 0,
                    lineColor: '#667788'
                    },
                series: [{
                    color: '#667788',
                    data: values,
                }],
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<span>{point.y}</span>' // Show only value
                    },
            };
            return <ReactHighcharts config={config} />
            // return <img src={getHistoricalChartUrl(values, allNumericalData)} />
        }
    }
    getFullName(detail) {
        return detail ? `${detail.providerHonorificPrefix ? `${detail.providerHonorificPrefix} ` : ''}${detail.providerName}${detail.providerHonorificSuffix ? `, ${detail.providerHonorificSuffix}` : ''}` : null
    }
    renderRecommendations() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <>
                    <h6 className='complete-col-span mt-5'><b>{this.props.terms['Your Recommendations']}</b> <span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h6>
                    {this.props.patientReportCurrentRecommendations.map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...this.props} key={i} />
                            )
                        }
                    )}
                </>
            );
        }
        else
            return null;

    }
    renderStoppedRecommendations() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <>
                    <h6 className='complete-col-span mt-5'><b>Stopped Recommendations</b> <span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h6>
                    {this.props.patientReportStoppedRecommendations.map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...this.props} key={i} />
                            )
                        }
                    )}
                </>
            );
        }
        else
            return null;

    }
    renderMedications() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <>
                    <h6 className='complete-col-span mt-5'><b>Your Medications </b><span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h6>
                    {this.props.patientReportCurrentRecommendations.filter(s => constants.medication_groups.includes(s.group)).map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...this.props} key={i} isMedication={true} />
                            )
                        }
                    )}
                </>
            );
        }
        else
            return null;
    }
    renderStoppedMedications() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <>
                    <h6 className='complete-col-span mt-5'><b>Stopped Medications </b><span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h6>
                    {this.props.patientReportStoppedRecommendations.filter(s => constants.medication_groups.includes(s.group)).map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...this.props} key={i} isMedication={true} />
                            )
                        }
                    )}
                </>
            );
        }
        else
            return null;
    }

    renderProblems() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <>
                    <h6 className='complete-col-span mt-5'><b>Youe Problems List </b><span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h6>
                    {this.props.patientReportProblems.map(
                        (problem, i) => {
                            return (
                                <IndividualReportProblem problem ={problem} {...this.props} key={i} />
                            )
                        }
                    )}
                </>
            );
        }
        else
            return null;
    }


    render() {
        var localeLabels = report_constants[this.locale].report_labels
        if (this.props.terms && this.props.currentVisit && Object.keys(this.props.currentVisit).length > 0) {
            const historicColumns = [
                {
                    dataField: 'label',
                    text: this.props.terms['Name'],
                    formatter: (cellContent, row) => (<span className='table-name-break'>{row.label}</span>)
                },
                {
                    dataField: 'value',
                    text: this.props.terms['Result'],
                    formatter: (cellContent, row) => {
                        return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => {
                            return s.value === null
                                ? "No-value " : <span className='spanForAlignment'>{(s.value && s.valueType == constants.numerical_value_type && !(_.startsWith(s.value, '>') || (_.startsWith(s.value, '<'))))
                                    ? parseFloat(s.value).toFixed(s.scale) : s.value}</span>
                        }) : ''}</span>
                    },
                },
                {
                    dataField: 'visitDate',
                    text: this.props.terms['Visit Date'],
                    formatter: (cellContent, row, index) => {
                        return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => { return !isNaN(new Date(s.visitDate).getDate()) ? <span className='spanForAlignment'>{moment(s.visitDate).format('L')}</span> : 'No-Date' }) : ''}</span>
                    },
                },
            ]
            const columns = [
                {
                    dataField: 'points',
                    formatter: (cellContent, row) => (row.points || row.points === 0 ? <span><Grade points={row.points} /></span> : ""),
                    text: this.props.terms['Grade'],
                },
                {
                    dataField: 'label',
                    formatter: (cellContent, row) => (<span className='table-name-break'>{row.label}</span>),
                    text: this.props.terms['Name']
                },
                {
                    dataField: 'value', text: this.props.terms['Result'],
                    formatter: (cellContent, row) => {
                        if (row.points >= 3.7) {
                            return <span className='success'>{(row.value && row.valueType == constants.numerical_value_type
                                && !((_.startsWith(row.value, '>') || (_.startsWith(row.value, '<')))))
                                ? getRoundedValue(row.value, row.scale) : row.value}</span>
                        }
                        else if ((row.valueType == "TX" && (row.referenceRange == null || (row.referenceRange != null && row.referenceRange.replace(/\s/g, "").split(",").includes(row.value.replace(/\s/g, "")))))) {
                            return <span>{row.value}</span>
                        }
                        else if ((row.valueType == "TX" && row.referenceRange && row.referenceRange != null && !(row.referenceRange.replace(/\s/g, "").split(",").includes(row.value.replace(/\s/g, ""))))) {
                            return <span className='danger' >{row.value}</span>
                        }
                        else if ((row.points != null && row.points <= 1.3) || (row.referenceRange && row.referenceRange != null && !IsInRange(row.referenceRange, row.value, row))) {
                            return <span className='danger'>{(row.value && row.valueType == constants.numerical_value_type &&
                                !((_.startsWith(row.value, '>') || (_.startsWith(row.value, '<')))))
                                ? getRoundedValue(row.value, row.scale) : row.value}</span>
                        }
                        else {
                            return <span>{(row.value && row.valueType == constants.numerical_value_type &&
                                !((_.startsWith(row.value, '>') || (_.startsWith(row.value, '<')))))
                                ? getRoundedValue(row.value, row.scale) : row.value}</span>
                        }
                    }
                },
                { dataField: 'units', text: this.props.terms['Units'] },
                {
                    dataField: 'referenceRange',
                    //text: this.props.terms['Normal Range'],
                    text: "Lab Ref Range"
                },
                { dataField: 'optimalRange', text: this.props.terms['Optimal Range'], },
                {
                    dataField: 'value',
                    formatter: (cellContent, row) => (<span>{row.historicData && row.historicData.length &&
                        row.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId).length > 0 ?
                        _.head(row.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId)).value : ''}</span>),
                    text: this.props.terms['Baseline'],
                    hidden: this.props.currentVisit && this.props.currentVisit.timePoint == constant.baseline ? true : false
                },
                {
                    dataField: 'Change',
                    formatter: (cellContent, row) => (<span>{(row.historicData && row.historicData.length) ? CalculateChange(row, this.props.currentVisit.baselineVisitId) : ''}</span>),
                    text: this.props.terms['Change'],
                    hidden: this.props.currentVisit && this.props.currentVisit.timePoint == constant.baseline ? true : false
                }
                //,
                // {
                //     dataField: 'sourceIndex',
                //     formatter: (cellContent, row) => {
                //         var arr = [];
                //         for (var i = row.sourceIndex + 65; i <= 90; i++) {
                //             return arr[i - 65] = String.fromCharCode(i);
                //         }
                //     },
                //     text: labels.report_labels.source_label,
                //     hidden: true
                // }
            ]

            return (
                this.props.terms && this.props.currentVisit && Object.keys(this.props.currentVisit).length > 0 && this.props.culturedBiomarkers && this.props.passagesData ?
                    <div>{
                        this.props.currentVisit ?
                            <div className='titlepage' id='patientReportDownload'>
                                <div className='reportImg'>
                                    {this.props.currentVisit.logoFile
                                        ? <img src={`data:${this.props.currentVisit.logoContentType};base64,${this.props.currentVisit.logoFile}`} />
                                        : <img className='physio-logo' src={logo} />}
                                </div>
                                <div className='titlepage'>
                                    <div className='title'>{this.props.terms[en_labels.report_labels.comprehensive_health_analysis]}</div>
                                    <div className='subtitle'>{this.props.currentVisit.timePoint.toLowerCase() != 'baseline' ? this.props.currentVisit.timePoint.replace('Follow-Up', this.props.terms['Follow-Up']) : this.props.terms['Baseline']}</div>
                                </div>
                                <div className='patient-identification'>
                                    <div className='title-block'>
                                        <div className='small-line'>
                                            {this.props.terms['prepared for']}
                                        </div>
                                        <div className='line'>
                                            {this.props.currentVisit.honorificPrefix ? this.props.currentVisit.honorificPrefix : ''} {this.props.currentVisit.firstname} {this.props.currentVisit.lastname}{this.props.currentVisit.honorificSuffix ? `, ${this.props.currentVisit.honorificSuffix}` : ''}
                                        </div>
                                    </div>
                                    <div className='title-block'>
                                        <div className='small-line'>
                                            {this.props.terms['by provider']}

                                        </div>
                                        <div className='line'>
                                            {this.props.currentVisit.providerHonorificPrefix ? this.props.currentVisit.providerHonorificPrefix : ''}  {this.props.currentVisit.providerName}{this.props.currentVisit.providerHonorificSuffix ? `, ${this.props.currentVisit.providerHonorificSuffix}` : ''}
                                        </div>
                                    </div>
                                    <div className='title-block'>
                                        <div className='small-line'>
                                            {this.props.terms['based on tests performed']}
                                        </div>
                                        <div className='line'>
                                            {getLocalizedDate(this.props.currentVisit.date, this.locale, this.props.currentVisit.datePreference)}
                                        </div>
                                    </div>
                                </div>
                                <div className='toc'>
                                    <div className='chapter-heading'>
                                        {this.props.terms['Table of Contents']}
                                    </div>
                                    <ul id='contents'>
                                        {this.props.followUpData && this.props.followUpData.filter(s => s.value != "0" && s.timePoint == this.props.currentVisit.timePoint).length > 0 ?
                                            <li>
                                                <Link to={`${this.getFullPath()}#introduction`}>{this.props.terms['Introduction']}</Link>
                                            </li> : ""}
                                        {this.props.completeData && this.props.completeData.length > 0
                                            && this.isReportCardExist(this.props.categories, this.props.currentVisit.id) ?
                                            <li>
                                                <Link to={`${this.getFullPath()}#report-card`}>
                                                    {this.props.terms['Report Card']}
                                                </Link>
                                            </li>
                                            : ''}
                                        {this.props.followUpData && this.props.followUpData.filter(s => s.value != "0" && s.timePoint == this.props.currentVisit.timePoint).length > 0 ?
                                            <li><Link to={`${this.getFullPath()}#boa`}>{this.props.terms['Biomarkers of Aging']}</Link>
                                                {/* {this.props.biomarkerAges ? this.props.biomarkerAges.map(s =>
                                                    <ul>
                                                        <li><Link offset={35} to={`${s}`}>{constant.biographical_labels.ageLabels[s]}</Link></li>
                                                    </ul>

                                                ) : ''} */}
                                                {this.props.culturedBiomarkers ? Object.keys(this.props.culturedBiomarkers).map(s =>
                                                    <ul>
                                                        <li><Link to={`${this.getFullPath()}#${s}`}>{this.props.culturedBiomarkers[s]}</Link></li>
                                                    </ul>
                                                ) : ""}
                                            </li> : ''}
                                        {this.props.followUpData && this.props.followUpData.filter(s => s.value != "0" && s.timePoint == this.props.currentVisit.timePoint).length > 0 ?
                                            <li><Link to={`${this.getFullPath()}#comparing_systems`}>{this.props.terms['Comparing Systems']}</Link></li> : ''}
                                        {this.props.completeData && this.props.completeData.length > 0 ?
                                            <li><Link to={`${this.getFullPath()}#health_status_indicators`}>{this.props.terms['Health Status Indicators']}</Link>
                                                <ul>
                                                    {this.props.categories ? this.props.categories.map(s => s.sections.map(t =>
                                                        t.data.filter(u => u.visitId == this.props.currentVisit.id).length > 0 ?
                                                            <li className='linkForHealth'>
                                                                <Link to={`${this.getFullPath()}#${t.key}`}>{t.name}</Link>
                                                            </li>
                                                            : '')) : ''}
                                                </ul>
                                            </li> : ''}
                                        {this.props.patientReportRecommendations && this.props.patientReportRecommendations.length > 0 ?
                                            <li><Link to={`${this.getFullPath()}#patient_treatment_plan`}>{this.props.terms['Treatment Plan']}</Link></li> : ''}
                                        {this.props.patientReportRecommendations && this.props.patientReportRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ?
                                            <li><Link to={`${this.getFullPath()}#patient_medications`}>Medications</Link></li> : ''}
                                        {this.props.patientReportProblems && this.props.patientReportProblems.length > 0 ?
                                            <li><Link to={`${this.getFullPath()}#patient_problems`}>Problems List</Link></li> : ''}
                                        {this.props.exceptionalData && this.props.exceptionalData.length > 0 ?
                                            <li><Link to={`${this.getFullPath()}#exceptional_results`}>{this.props.terms['Exceptional Results']}</Link></li> : ''}
                                        {this.props.abnormalData && this.props.abnormalData.length > 0 ?
                                            // <li><Link to={`${this.getFullPath()}#abnormal_results`}>{this.props.terms['Abnormal Results']}</Link></li> : ''}
                                            <li><Link to={`${this.getFullPath()}#concerning_results`}>{this.props.terms['results_of_concern']}</Link></li> : ''}
                                        {this.props.completeData && this.props.completeData.length > 0 ?
                                            <li>
                                                <Link to={`${this.getFullPath()}#complete_data`}>{this.props.terms['Complete Data']}</Link>
                                            </li> : ''}
                                        {/* {this.props.categories && this.props.currentVisit.timePoint != constant.baseline ?
                                            <li><Link to={`${this.getFullPath()}#historic_data`}>{this.props.terms['Historical Data']}</Link></li>
                                            : ''} */}
                                        <li><Link to={`${this.getFullPath()}#disclaimer`}>{localeLabels.disclaimer}</Link></li>
                                    </ul>
                                </div>
                                {this.props.followUpData && this.props.followUpData.filter(s => s.value != "0" && s.timePoint == this.props.currentVisit.timePoint).length > 0 ?
                                    <div className='chapter' id='introduction'>
                                        <h1 className='chapter-heading'>{this.props.terms['Introduction']}</h1>
                                        <div className='passage'>
                                            {this.props.currentVisit.timePoint == constant.baseline ? <center><img className='baseline-intro-img' src={`${this.locale}-baseline.JPG`} alt="Failed to load image"></img></center> : ''}
                                            {this.props.currentVisit.timePoint != constant.baseline ?
                                                // constants.introduction.map(s =>
                                                //     <p dangerouslySetInnerHTML={{ __html: s }}></p>
                                                // ) 
                                                <React.Fragment>
                                                    <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "followup_welcome_section")}</p>
                                                    <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "understanding_follow_up_biomarker_diagrams_section")}</p>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "baseline_welcome_section")}</p>
                                                    <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "baseline_illustrations_section")}</p>
                                                    <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "aging_happens_over_time_section")}</p>
                                                </React.Fragment>
                                            }

                                        </div>
                                    </div> : ""}
                                {this.props.completeData && this.props.completeData.length > 0
                                    && this.isReportCardExist(this.props.categories, this.props.currentVisit.id) ?
                                    <div className='chapter' id='report-card'>
                                        <h1 className='chapter-heading'>{this.props.terms['Report Card']}</h1>
                                        <div className='copy palette palette_default'>
                                            <div className='grade-card'>
                                                {this.props.categories ? this.renderCategoryGrade(this.props.categories, this.props.currentVisit.id) : ''}
                                            </div>
                                        </div>
                                    </div> : ''}
                                {this.props.followUpData && this.props.followUpData.filter(s => s.value != "0" && s.timePoint == this.props.currentVisit.timePoint).length > 0 ?
                                    <div className='chapter' id='boa'>
                                        <h1 className='chapter-heading'>{this.props.terms['Biomarkers of Aging']}</h1>
                                        <div className='copy'>
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'physioAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='physioAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['physioAge']}<span>	&trade;</span>	{this.props.terms['Composite']}</h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.physioAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        : this.getBaselineChart('physioAge')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('physioAge')}</b>
                                                    </p>
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "physioage_composite_section")}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'truAge_trudiag' && s.value != "0").length > 0 ?
                                                <div className='section' id='truAge_trudiag'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['truAge_trudiag']}</h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.truAge_trudiag : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        : this.getBaselineChart('truAge_trudiag')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('truAge_trudiag')}</b>
                                                    </p>
                                                    {this.renderExplaination(_.head(this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'truAge_trudiag' && s.value != "0")).noumenaExplaination)}
                                                    {/* {this.returnBiomarkerAgePassage(this.props.passagesData, "physioage_composite_section")} */}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'epigenAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='epigenAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['epigenAge']}</h2>
                                                    {/* <blockquote>
                                                        <p>{labels.report_labels.man_has_arteries}</p>
                                                        <div className='citation'>
                                                            {constant.name}<br></br>
                                                            <i>{labels.report_labels.english_hypocrates}</i>
                                                        </div>
                                                    </blockquote> */}
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts ref='epigenage' config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.epigenAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        : this.getBaselineChart('epigenAge')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('epigenAge')}</b>
                                                    </p>
                                                    {this.renderExplaination(_.head(this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'epigenAge' && s.value != "0")).noumenaExplaination)}
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "epigenage_section")}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'phenoAge_levine' && s.value != "0").length > 0 ?
                                                <div className='section' id='phenoAge_levine'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['phenoAge_levine']}</h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.phenoAge_levine : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        : this.getBaselineChart('phenoAge_levine')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('phenoAge_levine')}</b>
                                                    </p>
                                                    {/* {this.renderExplaination(_.head(this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'phenoAge_levine' && s.value != "0")).noumenaExplaination)} */}
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "phenoage_section")}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'glycanAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='glycanAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['glycanAge']}</h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?  
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts ref='glycanage' config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.glycanAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        :  this.getBaselineChart('glycanAge')
                                                        }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('glycanAge')}</b>
                                                    </p>
                                                    {this.renderExplaination(_.head(this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'glycanAge' && s.value != "0")).noumenaExplaination)}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'telomerAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='telomerAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['telomerAge']}</h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.telomerAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        : this.getBaselineChart('telomerAge')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('telomerAge')}</b>
                                                    </p>
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "telomerage_section")}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'immunoAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='immunoAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['immunoAge']}<span>&trade;</span></h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.immunoAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        : this.getBaselineChart('immunoAge')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('immunoAge')}</b>
                                                    </p>
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "immunoage_section")}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'cardioAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='cardioAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['cardioAge']}</h2>
                                                    {/* <blockquote>
                                                        <p>{labels.report_labels.man_has_arteries}</p>
                                                        <div className='citation'>
                                                            {constant.name}<br></br>
                                                            <i>{labels.report_labels.english_hypocrates}</i>
                                                        </div>
                                                    </blockquote> */}
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts ref='cardioage' config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.cardioAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div>
                                                         : this.getBaselineChart('cardioAge')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('cardioAge')}</b>
                                                    </p>
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "cardioage_section")}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'pulmoAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='pulmoAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['pulmoAge']}</h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.pulmoAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        :  this.getBaselineChart('pulmoAge')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('pulmoAge')}</b>
                                                    </p>
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "pulmoage_section")}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'neuroAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='neuroAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['neuroAge']}<span>&trade;</span></h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.neuroAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        : this.getBaselineChart('neuroAge')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('neuroAge')}</b>
                                                    </p>
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "neuroage_section")}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'cutoAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='cutoAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['cutoAge']}</h2>
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "cutoage_quote_section")}
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.cutoAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div>  
                                                        : this.getBaselineChart('cutoAge')}
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('cutoAge')}</b>
                                                    </p>
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "cutoage_section")}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'incite_trubiohealth_Age' && s.value != "0").length > 0 ?
                                                <div className='section' id='incite_trubiohealth_Age'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['incite_trubiohealth_Age']}</h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.incite_trubiohealth_Age : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> 
                                                        : this.getBaselineChart('incite_trubiohealth_Age')
                                                    }
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('incite_trubiohealth_Age')}</b>
                                                    </p>
                                                    {this.renderExplaination(_.head(this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'incite_trubiohealth_Age' && s.value != "0")).noumenaExplaination)}
                                                    {/* {this.returnBiomarkerAgePassage(this.props.passagesData, "physioage_composite_section")} */}
                                                </div> : '' : ''}
                                            {this.props.followUpData ? this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'omicmAge' && s.value != "0").length > 0 ?
                                                <div className='section' id='omicmAge'>
                                                    <h2 className='section-heading'>{this.props.culturedBiomarkers['omicmAge']}</h2>
                                                    {this.props.currentVisit.timePoint != constant.baseline ?
                                                        <div className='react-high-chart'>
                                                            <ReactHighcharts config={this.getBiomakersConfig(this.props.currentAggregateAge ? this.props.currentAggregateAge.omicmAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                        </div> : ''}
                                                    <p className='age_summary'>
                                                        <b>{this.ageCalculation('omicmAge')}</b>
                                                    </p>
                                                    {this.renderExplaination(_.head(this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == 'omicmAge' && s.value != "0")).noumenaExplaination)}
                                                    {/* {this.returnBiomarkerAgePassage(this.props.passagesData, "physioage_composite_section")} */}
                                                </div> : '' : ''}    

                                            {this.props.followUpData && this.props.followUpData.filter(s => s.value != "0" && s.timePoint == this.props.currentVisit.timePoint).length > 0 ?
                                                <div className='section' id='comparing_systems'>

                                                    <h2 className='section-heading'>{this.props.terms['Comparing Systems']}</h2>
                                                    {this.returnBiomarkerAgePassage(this.props.passagesData, "comparing_your_calculated_ages_section")}
                                                    <img className='aggregate' src={this.props.currentFollowUpUrl}></img>
                                                </div> : ''}
                                        </div>
                                    </div> : ''}

                                {this.props.completeData && this.props.completeData.length > 0 ?
                                    <div className='chapter' id='health_status_indicators'>
                                        <h1 className='chapter-heading'>{this.props.terms['Health Status Indicators']}</h1>
                                        <HealthStatusIndicators {...this.props}></HealthStatusIndicators>
                                    </div>
                                    : ''}
                                {this.props.patientReportRecommendations && this.props.patientReportRecommendations.length > 0 ? <div id="patient_treatment_plan">
                                    <h1 className='chapter-heading'>{this.props.terms['Treatment Plan']}</h1>
                                    {this.props.patientReportCurrentRecommendations && this.props.patientReportCurrentRecommendations.length > 0 ? this.renderRecommendations() : null}
                                    {this.props.patientReportStoppedRecommendations && this.props.patientReportStoppedRecommendations.length > 0 ? this.renderStoppedRecommendations() : null}
                                </div> : null}
                                {this.props.patientReportRecommendations && this.props.patientReportRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ? <div id="patient_medications">
                                    <h1 className='chapter-heading'>Medications</h1>
                                    {this.props.patientReportCurrentRecommendations && this.props.patientReportCurrentRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ? this.renderMedications() : null}
                                    {this.props.patientReportStoppedRecommendations && this.props.patientReportStoppedRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ? this.renderStoppedMedications() : null}
                                </div> : null}

                                {this.props.patientReportProblems && this.props.patientReportProblems.length > 0 ?
                                    <div id="patient_problems">
                                        <h1 className='chapter-heading'>Problems List</h1>
                                        {this.props.patientReportProblems && this.props.patientReportProblems ? this.renderProblems() : null}
                                    </div> 
                                : null}
                                
                                {this.props.exceptionalData && this.props.exceptionalData.length > 0 ?
                                    <div id='exceptional_results'>
                                        <div className='abnormalResult'><h1>{this.props.terms['Exceptional Results']}</h1>
                                            {this.props.categories.map(
                                                s => s.sections.map(
                                                    t => (
                                                        t.data.filter(u => u.points >= 3.7 && u.visitId == this.props.currentVisit.id).length ?
                                                            <div className='patients-report-preview-table'>
                                                                <React.Fragment>
                                                                    <div className='categoriesPhysio'><h3>{t.name}</h3></div>
                                                                    <div className='bootstrap-table-container'>
                                                                        <BootstrapTable
                                                                            remote
                                                                            striped={false}
                                                                            bordered={false}
                                                                            hover={true}
                                                                            bootstrap4
                                                                            keyField='id'
                                                                            data={t ? t.data.filter(s => s.points >= 3.7 && s.visitId == this.props.currentVisit.id) : ''}
                                                                            columns={columns}
                                                                        />
                                                                    </div>
                                                                </React.Fragment>
                                                            </div> : '')))}
                                        </div>
                                    </div> : ''}
                                {this.props.abnormalData && this.props.abnormalData.length > 0 ?
                                    <div id='concerning_results'>
                                        {/* <div className='abnormalResult'><h1>{this.props.terms['Abnormal Results']}</h1> */}
                                        <div className='abnormalResult'><h1>{this.props.terms['results_of_concern']}</h1>
                                            {this.props.categories.map(
                                                s => s.sections.map(
                                                    p => (
                                                        <div className='patients-report-preview-table'>
                                                            {p.data.filter(s => ((s.points != null && s.points <= 1.3 && s.valueType == constants.numerical_value_type) || (s.valueType == constants.numerical_value_type && s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s))) && s.visitId == this.props.currentVisit.id).length > 0 ?
                                                                <React.Fragment>
                                                                    <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                                    <div className='bootstrap-table-container'>
                                                                        <BootstrapTable
                                                                            remote
                                                                            striped={false}
                                                                            bordered={false}
                                                                            hover={true}
                                                                            bootstrap4
                                                                            keyField='id'
                                                                            data={p ? p.data.filter(s => ((s.points != null && s.points <= 1.3 && s.valueType == constants.numerical_value_type) || ( s.valueType == constants.numerical_value_type && s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s))) && s.visitId == this.props.currentVisit.id) : ''}
                                                                            columns={columns}
                                                                        />
                                                                    </div>
                                                                </React.Fragment> : null}
                                                        </div>)))}
                                        </div>
                                    </div> : ''}
                                {this.props.completeData && this.props.completeData.length > 0 ?
                                    <div id='complete_data'>
                                        <div className='completeData'><h1>{this.props.terms['Complete Data']}</h1>
                                            {this.props.categories.map(
                                                s => s.sections.map(
                                                    p => (p.data.filter(q => q.visitId == this.props.currentVisit.id).length > 0 ?
                                                       <>
                                                        <div className='patients-report-preview-table'>
                                                            <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                                { p.data.filter(q => q.visitId == this.props.currentVisit.id && q.valueType == "ST" && q.isTextArea == 1).length > 0 ?
                                                                    p.data.filter(q => q.visitId == this.props.currentVisit.id && q.valueType == "ST" && q.isTextArea == 1).map(noumenon => {
                                                                        return (
                                                                        <div className="pt-2 pb-2">
                                                                                <div>
                                                                                    <div className='fw-10000 mb-2 h5 pb-2'>{noumenon.label}</div>
                                                                                </div>
                                                                                <div className='pb-2 noumenon-value-card'>
                                                                                    <div className='p-4 bb'>
                                                                                        <div className='fw-10000 mb-2'>{this.props.terms[en_labels.report_labels.result_label]}</div>
                                                                                        <div className='ml-3'>{noumenon.value}</div>
                                                                                    </div>
                                                                                    <div className='pt-2 p-4'>
                                                                                        <div className='fw-10000 mb-2'>{this.props.terms['Baseline']}</div>
                                                                                        <div className='ml-3'>{noumenon.historicData && noumenon.historicData.length &&
                                                                                            noumenon.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId).length > 0 ?
                                                                                            _.head(noumenon.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId)).value : ''}</div>
                                                                                    </div>
                                                                                </div>
                                                                        </div> )
                                                                    })
                                                                : ""}
                                                            { p.data.filter(s => s.visitId == this.props.currentVisit.id && s.valueType != "ST" && s.isTextArea != 1).length > 0 ?
                                                                <div className='bootstrap-table-container'>
                                                                    <BootstrapTable
                                                                        remote
                                                                        striped={false}
                                                                        bordered={false}
                                                                        hover={true}
                                                                        bootstrap4
                                                                        keyField='id'
                                                                        data={p.data.filter(s => s.visitId == this.props.currentVisit.id).filter((q) => q.valueType != "ST" && q.isTextArea != 1)}
                                                                        columns={columns}
                                                                    />
                                                                </div>
                                                            : ""}
                                                        </div>
                                                        </>
                                                    : ""
                                                )))}
                                        </div>
                                    </div> : ''}
                                {/* <div id='historic_data'>
                                    {this.props.categories && this.props.currentVisit.timePoint != constant.baseline ?
                                        <div className='hdataPhysician'><h1>{this.props.terms['Historical Data']}</h1>
                                            {this.getHistoricData()}
                                        </div> : ''}
                                </div> */}
                                <div className='chapter' id='disclaimer'>
                                    <h1 className='chapter-heading'>{localeLabels.disclaimer}</h1>
                                    <div className='passage'>
                                        <div className='passage-heading'>{localeLabels.disclaimer_heading}</div>
                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "disclaimer_section")}
                                        {/* {constants.disclaimer.map(s =>
                                            <p>{s}</p>
                                        )} */}
                                    </div>
                                </div>
                            </div > : null
                    }
                    </div>
                    : '')
        }
        else {
            return null;
        }
    }
}
export default PatientReport
import React from 'react';
import grader from '../tools/grader.js'
import { connect } from 'react-redux';

function Grade(props) {
  let letter = () => {
    return grader(props.points)
  }

  let htmlClass = () => {
    var out = letter();
    if (out) {
      return ('grade-' + out.replace('+', 'p').replace('-', 'm').replace('/', ''));
    }
  }

    return (
        <div className={htmlClass() + ' grade-block' + (props.isReportCard ? ' report-card-grade' : '')}>
        {letter()}
        </div>
    )
}
export default (Grade);

import React, { Component } from 'react';

import './manuals-styles.scss';
import SpreadingRoutes from '../../../utils/SpreadingRoutes';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls';
import PageTitle from '../../../components/PageTitle';

class Help extends Component {
    componentWillUnmount() {
        this.props.toggleNotification(false)
    }

    render() {
        return (
            <div className={`manuals row parent-component${this.props.isParentContainer ? ' container' : ''}`}>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                        <PageTitle breadCrumbs={this.props.breadCrumbs}
                            pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                            navigatePatients={this.props.navigatePatients}
                            history={this.props.history}
                            {...this.props} />
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0'>
                    <SpreadingRoutes {...this.props} />
                </div>
                <div className={`modal fade ${this.props.isDeleteModal ? 'show' : ''}`} role='dialog'>
                    <div className='modal-dialog'>
                        <div className='modal-content'>
                            <div className='modal-body'>
                                {labels.help_page_labels.delete_warning}
                            </div>
                            <div className='modal-footer justify-space-between'>
                                <FormControl type='button'
                                    btnType='default'
                                    text={labels.help_page_labels.cancel_btn}
                                    onClick={() => this.props.launchDeleteModal()} />
                                <FormControl type='button'
                                    btnType='warning'
                                    text={labels.help_page_labels.ok_btn}
                                    onClick={() => this.props.deleteManual(this.props.history)} />
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        )
    }
}
export default Help;
export default {
  demo_1: {
    "id": 83,
    "name": "PhysioAge Demo Site",
    "category": "Test",
    "address": "",
    "city": "",
    "state": "",
    "country": "",
    "postalCode": "",
    "acceptsReferrals": 0,
    "acceptsMessages": 1,
    "billingStrategy": "recurring",
    "message": null,
    "createdAt": null,
    "updatedAt": null,
    "brand": "systems",
    "website": null,
    "principleEHR": "",
    "languagePreference": "en",
    "datePreference": "mdy",
    "unitPreference": "Imperial",
    "wantsWholePunchMargin": 0,
    "logoFile": null,
    "logoFileName": "your_logo.png",
    "logoContentType": "image/png",
    "logoFileSize": 6230,
    "logoUpdatedAt": null,
    "bannerLogoFile": null,
    "bannerLogoFileName": null,
    "bannerLogoContentType": null,
    "bannerLogoFileSize": null,
    "bannerLogoUpdatedAt": null,
    "defaultPanels": "Bone Densitometer,Cardio Coach VO2 Max,Carotid Intima-Media Thickness,CNS Vital Signs,Cutometer MPA 580,EasyOne Air Spirometer,Generic Bloodwork,Generic Metric Bloodwork,GlycanAge,Imatron EBT,InBody 570,Jamar Dynamometer,LabCorp,Patient Survey,Quest Labs,Repeat Diagnostics Telomere Lengths,Soterogram,Sphygmocor XCEL,UCLA Immune Function Panel,Vitals,Zymo Research DNAge,LC Panel 1,TruDiagnostics TruAge",
    "credits": 20,
    "stripeCustomerId": null,
    "phone": "800-456-7890",
    "includePhoneInSummary": 1,
    "providers": null,
    "users": null,
    "recentActivity": null,
    "patients": null,
    "enableDocuments": 1,
    "questAccNo": null,
    "labcorpAccNo": null,
    "subscriptionId": null,
    "email": null,
    "active": 1,
    "uclaPracticeCode": null,
    "emrDetails": null,
    "planType": null,
    "noumenonCodes": []
  },
  demo_2: {
    "id": 83,
    "name": "PhysioAge Demo Site",
    "category": "Test",
    "address": "",
    "city": "",
    "state": "",
    "country": "",
    "postalCode": "",
    "acceptsReferrals": 0,
    "acceptsMessages": 1,
    "billingStrategy": "recurring",
    "message": null,
    "createdAt": null,
    "updatedAt": null,
    "brand": "systems",
    "website": null,
    "principleEHR": "",
    "languagePreference": "en",
    "datePreference": "mdy",
    "unitPreference": "Imperial",
    "wantsWholePunchMargin": 0,
    "logoFile": null,
    "logoFileName": "your_logo.png",
    "logoContentType": "image/png",
    "logoFileSize": 6230,
    "logoUpdatedAt": null,
    "bannerLogoFile": null,
    "bannerLogoFileName": null,
    "bannerLogoContentType": null,
    "bannerLogoFileSize": null,
    "bannerLogoUpdatedAt": null,
    "defaultPanels": "Bone Densitometer,Cardio Coach VO2 Max,Carotid Intima-Media Thickness,CNS Vital Signs,Cutometer MPA 580,EasyOne Air Spirometer,Generic Bloodwork,Generic Metric Bloodwork,GlycanAge,Imatron EBT,InBody 570,Jamar Dynamometer,LabCorp,Patient Survey,Quest Labs,Repeat Diagnostics Telomere Lengths,Soterogram,Sphygmocor XCEL,UCLA Immune Function Panel,Vitals,Zymo Research DNAge,LC Panel 1",
    "credits": 20,
    "stripeCustomerId": null,
    "phone": "800-456-7890",
    "includePhoneInSummary": 1,
    "providers": null,
    "users": null,
    "recentActivity": null,
    "patients": null,
    "enableDocuments": 1,
    "questAccNo": null,
    "labcorpAccNo": null,
    "noumenonCodes": []
  }
}
import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import { connect } from 'react-redux';
import Grade from './Grade';
import constants from '../../../config/constants';
import { setSitePreference } from '../tools/calendar';

function ReportCardChart(props) {
    useEffect(() => {
      const ctx = document.getElementById('gaugeChart').getContext('2d');
      const gradientSegment = ctx.createLinearGradient(0, 0, 200, 0);
      gradientSegment.addColorStop(0.0, '#B2302E');
      gradientSegment.addColorStop(0.22, '#D1A701');
      gradientSegment.addColorStop(0.44, '#D8d800');
      gradientSegment.addColorStop(0.77, '#72C13B');
      gradientSegment.addColorStop(1, '#2EB263');

      const data = {
        datasets: [
          {
            data: [props.gpa, props.gpa >= 4.3 ? 0 : 4.3 - props.gpa],
            backgroundColor: [gradientSegment, props.theme == "light-theme" ? '#EDEDED' : "#5B5B5B"],
            borderWidth: 0,
            cutout: '92%',
            circumference: 270,
            rotation: 225,
            borderRedius: 4
          }
        ]
      };
  
      const gaugeChartText = {
        id: 'gaugeChartText',
        afterDatasetsDraw(chart) {
          const { ctx } = chart;
          const xCoor = _.head(chart.getDatasetMeta(0).data).x;
          const yCoor = _.head(chart.getDatasetMeta(0).data).y;
          const siteSpecificGPAData = props.siteSpecificLineGraphData;
          siteSpecificGPAData && siteSpecificGPAData.sort((a,b) => (new Date(b.visitDate) - new Date(a.visitDate)));
          let lastVisitDate = _.head(siteSpecificGPAData) && _.head(siteSpecificGPAData).visitDate ? setSitePreference(props.dateFormat, _.head(siteSpecificGPAData).visitDate) : '';
  
          function textLabel(text, x, y, fontSize, textBaseLine, textAlign, isGrade, width = 10, height = 10) {
            ctx.font = isGrade ? `${400} ${fontSize}px Arial, Helvetica, sans-serif` : `${300} ${fontSize}px 'Open Sans', Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif`;
            ctx.fillStyle = isGrade ? props.theme == 'dark-theme' ? props.siteTheme == constants.VIAVI_THEME ? '#92CCB7' : 'white' : '#0D3E56' : props.theme == 'dark-theme' ? '#CFCFCF' : 'black';
            ctx.textBaseLine = textBaseLine;
            ctx.textAlign = textAlign;
            isGrade ? ctx.fillText(text, x, y, 85, 35) : ctx.fillText(text, x, y);
          }
          ctx.save();
          textLabel(props.gpa ? props.gpa : 'NA', xCoor, yCoor+15, 32, 'bottom', 'center', true);
          textLabel('GPA Score', xCoor, yCoor+35, 12.64, 'bottom', 'center', false, 65, 11.05);
          props.siteSpecificLineGraphData && props.siteSpecificLineGraphData.length > 0  && textLabel(`${lastVisitDate}`, xCoor, yCoor+50, 10, 'bottom', 'center', false, 65, 11.05);
        }
      };
  
      const config = {
        type: 'doughnut',
        data,
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          hover: {
            mode: null // Disable hover actions
          },
          animation: {
            duration: 2500, // Increase the animation duration to 2000ms (2.5 seconds)
            easing: 'easeInOutQuad',
          },
        },
        plugins: [gaugeChartText]
      };
  
      const existingChart = Chart.getChart(ctx); // Get existing Chart instance
  
      if (existingChart) {
        existingChart.destroy(); // Destroy the existing instance
      }
  
      new Chart(ctx, config); // Create a new Chart instance
    }, [props.theme]);
  
    return (
      <div className='chartCard'>
        <div className='chartBox'>
          <canvas id='gaugeChart'></canvas>
          <div className={`${props.historic_data_chart && props.historic_data_chart.biomarkerCount > 0 && _.last(props.historic_data_chart['physioAges']) ? 'age-bars ' : ''} grade-icon`}>
              <Grade points={props.gpa} />
          </div>
        </div>
      </div>
    );
  };
  
  export default connect()(ReportCardChart);
  
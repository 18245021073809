import React from 'react';
// import IndividualCardElements from './IndividualCardElements';
import './signuppaymentgateway.scss';
import PaymentCardDetails from './PaymentCardDetails';
import labels from '../../config/localization';
import PageTitle from '../PageTitle';
import constants from '../../config/constants';
import FormControl from '../FormControls/FormControl';

class SignupPaymentGateway extends React.Component {
    componentDidMount() {
    }
    render() {
        return (
            <React.Fragment>
                <div className='col-lg-12 row d-flex flex-lg-row flex-column-reverse'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div className= "col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 mt-2">
                            <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                            </div>
                            <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-r-0'>
                                <PaymentCardDetails {...this.props} />
                            </div>
                        </div>    
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0">
                            <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                                <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 mt-5'>
                                    <FormControl text={labels.back_to_signup} className="mb-5" type='button' btnType={constants.notification_type.success} onClick={this.props.toggleSignupRendering} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default SignupPaymentGateway;
import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Loader from '../../../components/Loader';
import Recommendations from '../containers/RecommendationsContainer';
import labels from '../../../config/localization';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Typeahead } from 'react-bootstrap-typeahead';
import constants from '../../../config/constants';
import MedicationListTemplate from './MedicationListTemplate';
import moment from 'moment';

let active = (rec) => {
    if (rec.endedAt && moment(Date()).format("YYYY-MM-DD") > moment(rec.endedAt).format("YYYY-MM-DD")) {
      return false
    }
    else {
      return true
    }
  }

function MedicationComponent(props) {
    const [value, setValue] = React.useState( "active");
    const [openSearchField, setOpenSearchField] = React.useState(false);
    const [selectedSeachField, setSelectedSeachField] = React.useState( null);
    const [medications, setMedications] = useState(null);

    let seachField = useRef(null);
    const handleChange = (event, newValue) => {
        props.setRecommendationType(newValue)
        setValue(newValue);
        setOpenSearchField(false);
        props.resetRecommendationFilter();
        setSelectedSeachField([]);
        clearSearchField();
    };
    
    useMemo(() => {
        if (props.recommendationType !== value)
            setValue(props.recommendationType)
    })
    useEffect(() => {
        let element = document.getElementsByClassName("heading-1")
        element && element[0] ? element[0].scrollIntoView(0,0) : ""
        props.setRecommendationType("active")
        props.setRecType("Medication")
        props.setDrawerType(null)
        props.resetRecommendationFilter()
        props.toggleCategoryDetailsModal(false)
        if(props.medications){            
            setMedications(props.medications.filter(med => active(med)))
        }
        // componentDidMount //
    return () => {
        // componentWillUnmount() //
    }
  }, [props.medications])

  const editRecommendationSearch = (event, history, type, ) => {
    props.editRecommendationSearch(_.head(event), history, props.recType, type)
  }
  const medicationOptions = () => {
    if(props.recommendationType == "active") {
      return (props.medications && props.medications.length > 0 ? props.medications : [])
    }
    else if(props.recommendationType == "stopped") {
      return (props.stoppedMedications && props.stoppedMedications.length > 0 ? props.stoppedMedications : [])
    }
    else{
      return([])
    }
  }
  const clearSearchField =() =>{
    seachField.current.clear()
  }
  const getSelectedSeachField = (event) => {
    if (_.head(event)){ 
        setSelectedSeachField([_.head(event).body + " " + ( _.head(event).body ? _.head(event).body.includes(_.head(event).strength) ? "" : _.head(event).strength ? _.head(event).strength : "" : "")])
    }    
  }
    
    return (
        <> 
            { props.categories && props.medications  ? 
                <>
                <div className='treatment-plan'>
                    <div class="row header bg-white no-gutters b-bottom align-items-center">
                        <div class="col-lg-10 col-md-8 col-sm-8 col-7">
                            <div class="heading-1">{labels.analytics_labels.medication}</div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-4 col-5">
                            <div className='row d-flex'>
                                <div className='col-11 d-flex justify-content-end'>
                                { medications && medications.length ?
                                    <button onClick={() => props.downloadRecommendationList()} className="btn medication-list w-auto mr-2">{labels.analytics_labels.downlaod_pdf}</button>
                                : ""}    
                                {props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ?
                                    <button className='add-btn btn float-right' onClick={() => {
                                            props.toggleRecommendationModal(true);
                                            props.newRecommendation();
                                            props.toggleMetaRecommendations(false)
                                            props.setDrawerType(null)
                                        }}> {labels.analytics_labels.add}
                                    </button>
                                : "" }
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div className='row no-gutters height-100 height-95'>
                         <Box className='height-100' sx={{ width: '100%', padding: '0px 10px 0px 30px', typography: 'body1' }}>
                            <TabContext value={value} className='height-100'>
                                <Box className="d-flex row">
                                    <TabList onChange={handleChange} className='col-lg-8 col-6' >
                                        <Tab label="Active" value="active" textColor={"#3397C9"} indicatorColor={"#3397C9"} />
                                        <Tab label="Stopped" value="stopped" />
                                    </TabList>
                                    <div className='recommendation-search-field col-lg-4 col-6'>
                                        <form
                                            className={`${openSearchField ? "form-hover" : "form-close"}`}>
                                            <Typeahead
                                            labelKey="body"
                                            selected={selectedSeachField}
                                            id='noumenon-search'
                                            options={medicationOptions()}
                                            renderMenuItemChildren={(option, props) => (
                                                <span key={option.body}> {option.body + " " + ( option.body ? option.body.includes(option.strength) ? "" : option.strength ? option.strength : "" : "")}</span>
                                            )}
                                            onChange={(event) => { editRecommendationSearch(event, props.history, props.recommendationType); getSelectedSeachField(event) }}
                                            newSelectionPrefix={null}
                                            placeholder={"Search here ..."}
                                            ref={seachField}
                                            />
                                            <span className={`search-icon-div ${openSearchField ? "search-open" : "serach-close"}`}>
                                                {openSearchField ?
                                                    <CloseRoundedIcon onClick={() => { setOpenSearchField(false); setSelectedSeachField([]); props.resetRecommendationFilter(); clearSearchField(); setSelectedSeachField([]); }} class="search-icon" />
                                                    :
                                                    <SearchOutlinedIcon onClick={() => setOpenSearchField(true)} class="search-icon" />
                                                }
                                            </span>
                                        </form>    
                                    </div> 
                                </Box>
                                <TabPanel value="active" sx={{padding: 0, height: '100%'}} >
                                    <Recommendations {...props} />
                                </TabPanel>
                                <TabPanel value="stopped" sx={{padding: 0, height: '100%'}} > 
                                    <Recommendations {...props} />
                                </TabPanel>
                            </TabContext>
                        </Box>    
                    </div>
                </div> 
                {props.isMedicationList ?
                    <div className='display-none'>
                        <MedicationListTemplate {...props} />
                    </div>
                : null}
            </>
            : null}        
        </>
    );
}
export default connect()(MedicationComponent);
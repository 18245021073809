import {connect} from 'react-redux';
import ComparingBiomarkers from '../components/ComparingBiomarkersComponent';
import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
  ...state.analytics
})
const mapDispatchToProps = {
  ...actionCreators
}


export default connect(mapStateToProps, mapDispatchToProps)(ComparingBiomarkers);

import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import { toggleLoading, toggleNotification, setPageTitle, setBreadCrumbs, handleErrors, handleStatusCodes, handleLoading } from '../../../reducers/global-reducer';
import constants from '../../../config/constants';
import labels from '../../../config/localization';
import messages from '../../../config/messages';
import { promisesList, clearPromises } from '../../../utils/resolve-promises';
import resolveSearchFilters from '../../../utils/resolve-search-params';

const SET_PROPS = 'SET_PROPS';

// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }

function getProviders(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let searchParam = { ...getState().providers.searchParam }
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSearchFilters
            }
        })

        let page = searchParam.pageNumber ? searchParam.pageNumber : 1
        if (history)
            setSearchParamUrl(searchParam, currentSearchFilters, history)

        delete searchParam.sortField
        promisesList.push(axios.post(API_URL.PROVIDERS_LIST_URL, searchParam).then((response) => {
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    providers: response.data,
                    no_grid_data: response.data && response.data[0].id == 0 ? true : false,
                    defaultPage: page ? parseInt(page) : 1,
                    currentNames: response.data[0].providerNames? response.data[0].providerNames : []
                }
            })
            dispatch(ProviderPageChange(page ? parseInt(page) : 1 , false, history))
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}

export function clearSearch(urlparams, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortField: null,
                    sortOrder: -1,
                    searchFilters:  [
                        {
                          "fieldName": "siteid",
                          "fieldValue": null
                        },
                        {
                          "fieldName": "name",
                          "fieldValue": null
                        }
                      ]
                },
                currentSearchFilters: {},
                currentNames: [],
                isSearchCleared: true
            }
        })
        if (urlparams && Object.keys(urlparams).length) {
            let { siteid, name, sortOrder, sortField, page } = urlparams

            sortField = sortField ? sortField : ''
            sortOrder = sortOrder ? sortOrder : 'desc'
            if (siteid)
                dispatch(setSearchParam(siteid, 'siteid', false))

            if (name)
                dispatch(setSearchParam(name, 'name', false))
            if (page) {
                dispatch(ProviderPageChange(parseInt(page), false))
            }
            if (sortField) {
                dispatch(providersSortOrder(sortOrder, sortField, history, false))
            }
        }

        dispatch(getProviders())
        dispatch(toggleNotification(false))
    }
}

export function providersSortOrder(sortOrder, sortField, history, submit) {
    return (dispatch, getState) => {
        sortOrder = constants.sorting_order[sortOrder]
        let searchParam = Object.assign({}, getState().patients.searchParam, { sortOrder, sortField })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (!submit)
            dispatch(getProviders(history))

    }
}

export function clearFilters(isSearch) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortOrder: -1,
                    sortField: '',
                    searchFilters: []
                },
                currentNames: [],
                isSearchCleared: true
            }
        })
        if (isSearch)
            dispatch(getProviders())
    }
}

export function searchCustomName(eve, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                name: eve && eve.target ? eve.target.value : '',
                currentNames: []
            }
        })
        let namesList = getState().providers.filteredNamesList
        let search = namesList.filter(n => (n.firstName == eve.target.value || n.lastName == eve.target.value)).length ? true : false
        dispatch(setSearchParam(eve && eve.target ? eve.target.value : '', 'name', search, history))
    }
}

function getSitesList() {
    return (dispatch) => {
        promisesList.push(axios.get(API_URL.SITES_NAMES).then((response) => {
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    sitesList: response.data ? response.data.map(res => ({ id: res.id, name: res.name, value: null, billingStrategy: res.billingStrategy  })) : []
                }
            })
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}

export function clearNames() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNames: []
            }
        })
    }
}

function picUploaded(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        if (uploadedFile) {
            let imagetype = uploadedFile.type//.split('/')[uploadedFile.type.split('/').length - 1]
            if (constants.valid_image_types.indexOf(imagetype) != -1) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    let avatarContent = e.target.result
                    let currentProvider = getState().providers.currentProvider ? getState().providers.currentProvider : {}
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            currentProvider: Object.assign({}, currentProvider,
                                {
                                    avatarContent: avatarContent.split(',')[1],
                                    avatarContentType: imagetype,
                                    avatarFileSize: uploadedFile.size,
                                    avatarFileName: uploadedFile.name
                                })
                        }
                    })
                }
                reader.readAsDataURL(eve.target.files[0]);
            } else {
                window.scrollTo(0, 0)
                dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.pic_validation_message], false))
            }
        }
    }
}
function removePic(eve) {
    return (dispatch, getState) => {
        eve.preventDefault()
        let currentProvider = getState().providers.currentProvider ? getState().providers.currentProvider : {}
        dispatch({
            type: SET_PROPS,
            payload: {
                currentProvider: Object.assign({}, currentProvider, {
                    avatarContent: null,
                    avatarContentType: null,
                    avatarFileSize: null,
                    avatarFileName: null
                })
            }
        })
    }
}

function editProvider(eve, key) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentProvider = getState().providers.currentProvider ? getState().providers.currentProvider : {}
        let isDeactivated = getState().providers.isDeactivated ? getState().providers.isDeactivated : false
        let value;
        if (key != 'selectedsites'){
           value =  eve.target && eve.target.type != 'checkbox' ? eve.target.value : eve.target.checked ? 1 : 0
           if (eve.target.type == 'checkbox' && value == 1){
               isDeactivated = true
           }
           else if(eve.target.type == 'checkbox' && value == 0){
                isDeactivated = false
           }
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                currentProvider: Object.assign({}, currentProvider, { [!key ? eve.target.id : key]: !key ? value : eve }),
                isDeactivated: isDeactivated
            }
        })

    }
}
function submitProvider(history) {
    return (dispatch, getState) => {
        let currentProvider = getState().providers.currentProvider ? getState().providers.currentProvider : {}
        let requiredAssignedTo = currentProvider.deactivate == 1 ? true : false
        if (currentProvider.firstname && currentProvider.lastname && currentProvider.selectedsites && currentProvider.selectedsites.length && (
            (!requiredAssignedTo && !currentProvider.assignedTo) || (requiredAssignedTo && currentProvider.assignedTo))) {
            if (!requiredAssignedTo) {
                delete currentProvider["assignedTo"]
            }
            dispatch(toggleLoading(true))
            currentProvider.sites = currentProvider.selectedsites.map(s => s.id)
            axios[currentProvider.id ? 'put' : 'post'](API_URL.PROVIDERS_URL, { ...currentProvider }).then((response) => {
                dispatch(toggleLoading(false))
                dispatch(getProviders())
                if (requiredAssignedTo && currentProvider.assignedTo){
                    dispatch(toggleNotification(true, constants.notification_type.success, '',
                    [` ${currentProvider.firstName} ${currentProvider.lastname} ${labels.provider_labels.provider_deactivated_successfully}`]
                    , false))
                    history.push(`/providers`)
                } else {
                    history.push(`/providers/${currentProvider.id ? currentProvider.id : response.data}`)
                }
            }).catch((error) => {
                dispatch(handleErrors(error))

            })
        } else {
            let errorMessages = []
            if (!currentProvider.firstname) { errorMessages.push(`${labels.user_labels.firstname} ${labels.required_label}`) }
            if (!currentProvider.lastname) { errorMessages.push(`${labels.user_labels.lastname} ${labels.required_label}`) }
            if (!currentProvider.selectedsites || !currentProvider.selectedsites.length) { errorMessages.push(messages.site_select_validation) }
            if (currentProvider.deactivate && !currentProvider.assignedTo) { errorMessages.push(`${labels.user_labels.available_provider} ${labels.required_label}`) }
            window.scrollTo(0, 0)
            dispatch(toggleNotification(true, constants.notification_type.error, '', errorMessages, false))
        }
    }
}
export function navigateEditProvider(history, path) {
    return (dispatch) => {
        history.push(path)
        dispatch({
            type: SET_PROPS,
            payload: {
                currentProvider: {}
            }
        })
    }
}
function setProviderDetails(providerId) {
    return (dispatch) => {
        dispatch(setCurrentProvider(providerId, true))
    }
}
function setCurrentProvider(providerId, details) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.PROVIDERS_URL}/${providerId}`).then(response => {
            let currentProvider = response.data
            let isDeactivated = currentProvider.deactivate ? currentProvider.deactivate : false
            let sites = currentProvider.sites && currentProvider.sites.map(site => ({...site, removable: currentProvider.primarySiteId && currentProvider.primarySiteId === site.id ? false : true } ))
            if (sites && sites.some(site => site.removable !=  false)){
                sites[0].removable = false
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentProvider: { ...currentProvider, selectedsites: sites, assignedTo: null },
                    availableProvidersList: response.data.providersNameDTO,
                    isDeactivated: isDeactivated
                }
            })
            if (details) {
                let name = currentProvider
                    ? `${currentProvider.honorificPrefix ? currentProvider.honorificPrefix : ''} ${currentProvider.firstname} ${currentProvider.lastname}${currentProvider.honorificSuffix ? `, ${currentProvider.honorificSuffix}` : ''}` : ''
                dispatch(setPageTitle(name))
                document.title = `${labels.physioage_label}:${name}`
                dispatch(setBreadCrumbs([
                    { text: labels.physioage_label, path: '/patients/list' },
                    { text: name, path: '' }
                ]))
            } else {
                dispatch(setPageTitle(labels.provider_labels.edit_provider))
                document.title = `${labels.physioage_label}:${labels.provider_labels.edit_provider}`
                dispatch(setBreadCrumbs([
                    { text: labels.physioage_label, path: '/patients/list' },
                    { text: labels.provider_labels.edit_provider, path: '' }
                ]))
            }
            dispatch(handleLoading())
        })
            .catch(error => {
                dispatch(toggleLoading(false))
                dispatch(handleStatusCodes(error))
                dispatch(handleErrors(error))
            }))
    }
}
function addProvider(history) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentProvider: {}
            }
        })
        history.push('/providers/new')
    }
}
export function addSiteProvider(history, site) {
    return (dispatch) => {
        let selectedsites = site && site.map( s => ({...s, removable: true }))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentProvider: { selectedsites }
            }
        })
        history.push('/providers/new')
    }
}
function resetProviders() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                defaultPage: 1
            }
        })
        dispatch(getProviders())
    }
}
function ProviderPageChange(pageNumber, submit, history) {
    let sizePerPage = 10
    let startIndex = (pageNumber - 1) * sizePerPage
    let endIndex = startIndex + (sizePerPage - 1)
    return (dispatch, getState) => {
        let providers = getState().providers.providers
        dispatch({
            type: SET_PROPS,
            payload: {
                filteredProviders: providers.filter((t, i) => i >= startIndex && i <= endIndex),
                defaultPage: parseInt(pageNumber)
            }
        })
        let searchParam = Object.assign({}, getState().patients.searchParam, { pageNumber })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        if(history)
            setSearchParamUrl(searchParam, currentSearchFilters, history)
    }
}

function sortProviders(sortField, sortOrder, history, pageNumber) {
    return (dispatch, getState) => {
        let providers = getState().providers && getState().providers.providers ? getState().providers.providers : [];
        let searchParam = getState() && getState().patients && getState().patients.searchParam ? getState().patients.searchParam : {}
        //providers.sort(dynamicProvidersSort(sortField, sortOrder));
        let columns = [];
        columns.push(sortField);
        if (sortField == "firstname") {
            columns = ['firstname', 'lastname'];
            sortField = [sortField];
        }
        if (sortField == "lastVisit")
            providers = _.orderBy(providers, [(obj) => new Date(obj['lastVisitDate'])], sortOrder);
        else
            providers = _.orderBy(providers, columns, sortOrder);

        let pageNumChanged = false
        if (searchParam && (!searchParam.sortField || searchParam.sortField != sortField)) {
            searchParam.sortField = sortField
            if (searchParam.sortField != sortField)
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        providers: []
                    }
                })
            pageNumChanged = true
            searchParam.pageNumber = 1
        }

        if (searchParam.sortOrder != constants.sorting_order[sortOrder]) {
            searchParam.sortOrder = constants.sorting_order[sortOrder]
            searchParam.pageNumber = 1
        } else if (!pageNumChanged) {
            searchParam.pageNumber = pageNumber ? parseInt(pageNumber) : 1
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                providers: providers,
                ...searchParam
            }
        })
        let page = searchParam.pageNumber ?  searchParam.pageNumber : 1
        dispatch(ProviderPageChange(page, false, history));
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        if(history)
            setSearchParamUrl(searchParam, currentSearchFilters, history)

    }
}

function setSearchParamUrl(searchParam, currentSearchFilters, history) {
    let searchParamUrl = '', params = ''
    searchParam.pageNumber = searchParam.pageNumber ? searchParam.pageNumber : 1
    searchParam.sortField = searchParam.sortField ? searchParam.sortField : ''
    searchParam.sortOrder = searchParam.sortOrder ? searchParam.sortOrder : -1
    if (currentSearchFilters.siteid && !parseInt(currentSearchFilters.siteid))
        currentSearchFilters.siteid = ''
    let filters = Object.keys(currentSearchFilters).filter(csf => currentSearchFilters[csf]).map(csf => `${csf}=${currentSearchFilters[csf]}`)
    searchParamUrl = filters.join('&')
    let paramFilters = Object.keys(searchParam).filter(csf => searchParam[csf])
        .map(csf => {
            if (csf != 'searchFilters' && csf != 'pageSize')
                if (csf == "sortOrder") {
                    if (!searchParam[csf])
                        return `${csf}=desc`
                    else
                        return `${csf}=${constants.sorting_order_decode[searchParam.sortOrder.toString()]}`
                }
                else if (csf == 'pageNumber') {
                    return `page=${searchParam.pageNumber}`
                }
                else {
                    return `${csf}=${searchParam[csf]}`
                }
        })
    params = _.compact(paramFilters).join('&')
    if (searchParamUrl) {
        searchParamUrl = (`?${searchParamUrl}&${params}`)
    } else {
        searchParamUrl = (`?${params}`)
    }
    return history.push(searchParamUrl)
}

const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    currentProvider: {},
    no_grid_data: false,
    defaultPage: 1,
    filteredProviders: [],
    providers: [],
    searchParam: {
        pageSize: 10,
        pageNumber: 1,
        sortField: null,
        sortOrder: -1,
        searchFilters: []
    },
    currentNames: [],
    isDeactivated: false
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export function setSearchParam(val, key, search, history, activePageNumber) {
    return (dispatch, getState) => {
        let searchParam = Object.assign({}, getState().providers.searchParam)

        if ((getState().providers.currentSearchFilters.name == null || getState().providers.currentSearchFilters.name == "") && val == "" && key == "name"){
        }
        else {
            if (searchParam.searchFilters) {
                if (searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() == key.toLowerCase()).length) {
                    searchParam.searchFilters.map(sf => {
                        if (sf.fieldName == key) { sf.fieldValue = val }
                    })
                } else {
                    searchParam.searchFilters.push({
                        fieldName: key,
                        fieldValue: val
                    })
                }
            } else {
                searchParam.searchFilters = []
                searchParam.searchFilters.push({
                    fieldName: key,
                    fieldValue: val
                })
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    searchParam: !search ? Object.assign({}, searchParam, { pageNumber: activePageNumber ? activePageNumber : 1 }) :
                        searchParam
                }
            })
            if ((!search && key == 'name') || (!search && key == 'siteid') ) {
                dispatch(getProviders(history))
            }
        }
    }
}

export const actionCreators = {
    getProviders,
    picUploaded,
    removePic,
    getSitesList,
    editProvider,
    submitProvider,
    setCurrentProvider,
    addProvider,
    addSiteProvider,
    setProviderDetails,
    navigateEditProvider,
    resetProviders,
    ProviderPageChange,
    sortProviders,
    clearSearch,
    providersSortOrder,
    setSearchParam,
    clearFilters,
    searchCustomName,
    clearNames
}

import { connect } from 'react-redux';
import { actionCreators } from '../modules/visits';
import { setBreadCrumbs, setPageTitle, toggleNotification, navigatePatients, setCreditsCaution, downloadDocument } from '../../../reducers/global-reducer';
import Visits from '../components/Visits';
import { addCreditTransaction } from '../../ELK/modules/credittransactions'
import { toggleObservation, setActiveObsTab } from '../../Oddities/modules/oddities'
import { navigateMessage } from '../../MessageRouting/modules/messagerouting'

const mapStateToProps = (state) => ({
    ...state.visits,
    loggedInUser: state.global.loggedInUser,
    isParentContainer: state.global.isParentContainer,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify
})

const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    addCreditTransaction,
    toggleObservation,
    setActiveObsTab,
    navigateMessage,
    navigatePatients,
    setCreditsCaution,
    downloadDocument
}
export default connect(mapStateToProps, mapDispatchToProps)(Visits)
import React, { Component } from 'react';
import { parse } from 'qs';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls';
import NoumenaCharts from './NoumenaCharts';
import constants from '../../../config/constants';
import FilterNoumenaData from './FilterNoumenaData';
import LoadingTemplate from '../../../components/LoadingTemplate';
class ExploreNoumena extends Component {
    componentDidMount() {
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.noumena_labels.noumena, path: '' },
            { text: labels.noumena_labels.title, path: '' }
        ])
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);

        this.props.setPageTitle(labels.noumena_labels.title)
        document.title = `${labels.physioage_label}: ${labels.noumena_labels.title}`
        // if (!this.props.noumenaResponse) {
        if (this.urlParams && Object.keys(this.urlParams))
            this.props.setDefaultNoumenon(this.urlParams, this.props.history)
        else
            this.props.setNoumenonValues(1)
        // }

        // this.props.getSitesList()
        this.props.setUnitsData()
    }
    componentWillReceiveProps(newProps) {
        if (this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search) {
            this.props.clearNoumenaForm()
            this.props.setNoumenonValues(1)
        }
    }
    getDefaultNoumenaName() {
        let resultantNoumena = []
        if (this.props.unitsData && this.props.unitsData.length && this.props.currentNoumenaFilters && this.props.currentNoumenaFilters.noumenaName) {
            var result = this.props.unitsData.filter(u => u.noumenonCode == this.props.currentNoumenaFilters.noumenaName)
            if (result && result.length)
                resultantNoumena.push(_.head(result))
        }
        if (!resultantNoumena.length)
            resultantNoumena.push({ name: this.props.currentNoumenaFilters.noumenaName, noumenonCode: this.props.currentNoumenaFilters.noumenaName })
        return resultantNoumena
    }
    render() {
        return (
            this.props.isLoading ? <LoadingTemplate isCards={true} haveSidebar={true} /> :
                <>
                    <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                        <div className='compare-noumena-container mb-2'>
                            <FormControl type='button' btnType='info' text={labels.noumena_labels.compare_noumena} onClick={() => this.props.history.push('/data/compare_noumena')}/>
                        </div>
                        <div className='card'>
                            <div className='card-header'>{labels.noumena_labels.filter_clouds}</div>
                            <div className='card-body'>
                                <form onSubmit={(e) => {
                                    e.preventDefault(); this.props.clearNoumenaNames(); this.props.saveNoumena(this.props.history)
                                }}>
                                    <FormControl id='name' label={labels.noumena_labels.noumenon}
                                        type='typeahead' onSearch={this.props.populateSearchParam}
                                        defaultSelected={this.props.currentNoumenaFilters && this.props.currentNoumenaFilters.noumenaName
                                            ? this.getDefaultNoumenaName() : []}
                                        onKeyDown={(e) => {
                                            if (e.keyCode == constants.enter_key_code || e.keyCode == constants.escape_key_code) {
                                                this.props.clearNoumenaNames();
                                            }
                                        }}
                                        open={this.props.currentNoumenonNames && this.props.currentNoumenonNames.length ? true : false}
                                        onChange={(e) => { this.props.editNoumena(e, 'noumenaName'); this.props.clearNoumenaNames() }}
                                        onInputChange={this.props.populateSearchParam} labelKey={'name'} valueKey={'noumenonCode'}
                                        options={this.props.currentNoumenonNames}
                                        onBlur={() => { this.props.clearNoumenaNames(); }}
                                    />
                                    <FormControl
                                        id='siteId' label={labels.patient_list_labels.site_label}
                                        type='select'
                                        onChange={this.props.editNoumena}
                                        defaultValue={this.props.currentNoumenaFilters && this.props.currentNoumenaFilters.siteId
                                            ? parseInt(this.props.currentNoumenaFilters.siteId) : 0}
                                        defaultOption="All"
                                        options={this.props.sitesList && this.props.loggedInUser && this.props.loggedInUser.siteIds ? this.props.loggedInUser.role == 'admin' ? this.props.sitesList : this.props.sitesList.filter(site => this.props.loggedInUser.siteIds.includes(site.id)) : []}
                                        textKey={'name'} valueKey={'id'} />
                                    <FormControl id='gender' type='select' label={labels.noumena_labels.gender}
                                        defaultOption="All" options={constants.gender} onChange={this.props.editNoumena}
                                        textKey={'value'} valueKey={'value'}
                                        defaultValue={this.props.currentNoumenaFilters && this.props.currentNoumenaFilters.gender
                                            ? (this.props.currentNoumenaFilters.gender) : null}
                                    />
                                    <FormControl id='MaxDeviations' type='select'
                                        defaultOption="None"
                                        label={labels.noumena_labels.max_deviations}
                                        onChange={this.props.editNoumena}
                                        defaultValue={this.props.currentNoumenaFilters && this.props.currentNoumenaFilters.MaxDeviations
                                            ? parseInt(this.props.currentNoumenaFilters.MaxDeviations) : 0}
                                        options={constants.deviations} textKey={'value'} valueKey={'value'} />
                                    <FormControl type='button' btnType='info' text={labels.noumena_labels.update_btn} />
                                </form>
                                <div className='alert alert-danger filter-help'>
                                    <span><strong>{labels.noumena_labels.heads_up}</strong><p>{labels.noumena_labels.filter_help}</p></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0'>
                        {this.props.noumenaResponse ?
                            this.props.noumenaResponse.length > 0 ?
                                <FilterNoumenaData type="explore noumena" noumenaData={this.props.noumenaData} {...this.props}></FilterNoumenaData>
                                : <div className='jumbotron'>
                                    <h1>{labels.noumena_labels.no_results}</h1>
                                </div> :
                            <div className='jumbotron'>
                                {!this.props.isNoumenaExist ? <h1>{labels.visit_page_labels.waiting_for_data}<i className="fa fa-spinner fa-spin" style={{ marginLeft: '20px' }}></i></h1> : <h1>{labels.noumena_labels.no_results}</h1>
                                } </div>}


                        {this.props.noumenaResponse && this.props.noumenaResponse.length >= 3 ?
                            Object.keys(this.props.noumenaData ? this.props.noumenaData : '').map(s => s).length > 0 ?
                                <h3 style={{ marginTop: '15px' }}>
                                    <a onClick={() => this.props.setNoumenonValues(this.props.pageNumber, null, null, this.props.history)} style={{ fontSize: '1em', color: '#008FBC' }}>{labels.noumena_labels.next_page} &gt;&gt;</a>
                                </h3>
                                : '' : ''}
                    </div>
                </>
        )
    }
}
export default ExploreNoumena;
import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo_physioage-horizontal.svg';
import './header-styles.scss';
import header_navigations from '../../config/header_navigations';
import labels from '../../config/localization';
import constants from '../../config/constants';

const getNavigationPaths = (role, props) => {
  var navs = header_navigations.map((nav, i) => {
    if (nav.roles.indexOf(role) !== -1) {
      if (!nav.child) {
        return (
          <NavItem key={i}>
            <a onClick={() => { props.toggleNotification(false); props.headerNavigation(`/${nav.path}`, props.history) }} className='nav-item nav-link'>{nav.display}</a>
          </NavItem>
        )
      } else if (nav.child && nav.child.length) {
        return (
          <Dropdown isOpen={props.dropdownOpen == nav.display}
            toggle={() => props.toggleHeaderNavigation(nav.display)} nav inNavbar key={i}>
            <DropdownToggle nav caret>
              {nav.display}
            </DropdownToggle>
            <DropdownMenu center>
              {nav.child.map(ch => {
                if(ch.navpath){
                  return <DropdownItem onClick={() => window.open(ch.navpath)} className='dropdown-item' >{ch.display}</DropdownItem>
                }
                if (!ch.roles && !ch.checkSite && !ch.customizations) {
                  return <DropdownItem onClick={() => props.headerNavigation(`/${ch.path}`, props.history)} className='dropdown-item'>{ch.display}</DropdownItem >
                }
                else if (ch.roles.indexOf(role) != -1 && !ch.checkSite && !ch.customizations) {
                  return <DropdownItem onClick={() => props.headerNavigation(`/${ch.path}`, props.history)} className='dropdown-item'>{ch.display}</DropdownItem >
                } else if (ch.roles && ch.roles.indexOf(role) != -1 && ch.checkSite) {
                  if (props.loggedInUser && props.loggedInUser.site && props.loggedInUser.site.enableDocuments)
                    return <DropdownItem onClick={() => props.headerNavigation(`/sites/${props.loggedInUser.site.id}/documents`, props.history)} className='dropdown-item'>{ch.display}</DropdownItem >
                }
                else if (ch.roles && ch.roles.indexOf(role) != -1 && ch.customizations) {
                  if (props.loggedInUser && props.loggedInUser.site && props.loggedInUser.site.planType != constants.planTypeBasic )
                    return <DropdownItem onClick={() => props.headerNavigation(`/sites/${props.loggedInUser.site.id}/customizations`, props.history)} className='dropdown-item'>{ch.display}</DropdownItem >
                }
                
              })}
            </DropdownMenu>
          </Dropdown  >)
      }
    }
  })
  return navs
}

class Header extends Component {
  render() {
    return (
      <div className='header-section fixed-header' id='mainHeaderMenu'>
        <Navbar color='light' light expand='md' className='container'>
          <NavbarBrand><a onClick={() => { this.props.navigatePatients(this.props.history); this.props.headerCollapseToggle(false) }}><img src={logo} alt='Physioage-logo' className='logo-section' /></a></NavbarBrand>
          <NavbarToggler onClick={this.props.headerCollapseToggle} />
          <Collapse navbar isOpen={this.props.isHeaderCollapseOpen}>
            <Nav className='header-menu-list' navbar>
              <NavItem key='patientslist'>
                <a onClick={() => { this.props.toggleNotification(false); this.props.navigatePatients(this.props.history); this.props.headerCollapseToggle(false) }} className='nav-item nav-link'>{labels.header_labels.patients}</a>
              </NavItem>
              {getNavigationPaths(this.props.loggedInUser.role ? this.props.loggedInUser.role : 'admin', this.props)}
              <Dropdown nav inNavbar isOpen={this.props.dropdownOpen == 'account'}
                toggle={() => this.props.toggleHeaderNavigation('account')}>
                <DropdownToggle nav caret>
                  {labels.header_labels.account}
                </DropdownToggle>
                <DropdownMenu right>
                  {this.props.loggedInUser && this.props.loggedInUser.siteId && this.props.loggedInUser.siteBillingStrategy != constants.billing_strategy_recurring ?
                    <DropdownItem onClick={() => { this.props.headerNavigation(`/credits/${this.props.loggedInUser.siteId}/list`, this.props.history); }}>
                      {labels.header_labels.credits}{this.props.loggedInUser.site ? ` (${this.props.loggedInUser.site.credits} ${labels.header_labels.remaining_label})` : ''}
                    </DropdownItem> : null}
                  {this.props.loggedInUser.role == 'admin' ?
                    <DropdownItem onClick={() => { this.props.headerNavigation(`/messages/failuremessages`, this.props.history); }}>
                       {labels.header_labels.failure_messages} ({this.props.routeFailureCount} <i class="fa fa-bell danger" aria-hidden="true"></i>)
                    </DropdownItem> : ''}
                  <DropdownItem onClick={() => this.props.headerNavigation(`/user_settings`, this.props.history)} className='dropdown-item'>
                    {labels.header_labels.user_settings}</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => { this.props.logOut(this.props.history) }}>
                    {labels.header_labels.logout}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
            <button className='btn feedback-btn txt-uppercase' onClick={() => this.props.headerNavigation('/feedback', this.props.history)}>{labels.header_labels.feedback}</button>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;

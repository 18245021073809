import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import constants from '../../../config/constants';
import { toggleLoading, toggleNotification, handleErrors, handleStatusCodes, setBreadCrumbs, setPageTitle, setLoggedInUser, handleLoading, } from '../../../reducers/global-reducer';
import messages from '../../../config/messages';
import labels from '../../../config/localization';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import resolveSearchFilters from '../../../utils/resolve-search-params';

const SET_PROPS = 'SET_PROPS';
const requiredFields = ['credits']
// const reqFieldText = { credits: 'Credits' }


// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }

function getCreditTransactions() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                totalCount: 0,
            }
        })
        promisesList.push(axios.post(API_URL.CREDIT_TRANSACTIONS, { ...getState().elk.searchParam }).then(response => {
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    creditTransactions: response.data ? response.data.value : [],
                    totalCount: response.data ? response.data.totalCount : 0,
                    no_grid_data: response.data && response.data.value && !response.data.value.length ? true : false,
                    isFirstTimeLoading: false
                }
            })
        }).catch(error => {
            dispatch(handleErrors(error))
            dispatch(toggleFirstTimeLoad(false))
        }))
    }
}
function saveCredits(history, mode) {
    return (dispatch, getState) => {
        let creditsParams = { ...getState().elk.currentCredit }
        let totalCredits = creditsParams.site ? (parseInt(creditsParams.site.credits) + parseInt(creditsParams.credits)) : 0
        let req = requiredFields.filter(rf => (!creditsParams[rf] || parseInt(creditsParams[rf]) == 0 || parseInt(creditsParams[rf]) < 0))
        if (req && req.length) {
            window.scrollTo(0, 0)
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [messages.elk_validation]
                , false))
        } else if (totalCredits > constants.max_credits) {
            dispatch(toggleNotification(true, constants.notification_type.error, '', [labels.payments_labels.max_credits_validation], false))
        }
        else {
            dispatch(toggleLoading(true))
            let currentCredit = { ...getState().elk.currentCredit, siteId: getState().elk.currentCredit && getState().elk.currentCredit ? getState().elk.currentCredit.site.id : 0 }
            delete currentCredit.site
            axios.post(API_URL.CREDIT_TRANSACTIONS_URL, { ...currentCredit }).then(response => {
                dispatch(toggleLoading(false))
                dispatch(setLoggedInUser())
                // dispatch(getCreditTransactions())
                history.push(`/credit-transaction/list`)
            }).catch(error => {
                dispatch(handleErrors(error))
            })
        }
    }
}
function getSites() {
    return (dispatch) => {
        promisesList.push(axios.get(API_URL.SITES_NAMES).then((sites) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    sites: sites.data
                }
            })
            dispatch(handleLoading())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}
function setSearchParamUrl(searchParam, history) {
    let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
    let searchParamUrl = '', params = ''
    searchParam.pageNumber = searchParam.pageNumber ? searchParam.pageNumber : 1
    searchParam.sortOrder = searchParam.sortOrder ? searchParam.sortOrder : -1
    searchParam.sortField = searchParam.sortField && searchParam.sortField != "" ? searchParam.sortField : 'date'
    let filters = Object.keys(searchParam).filter(csf => searchParam[csf])
        .map(csf => {
            if (csf != 'searchFilters' && csf != 'pageSize')
                if (csf == "sortOrder") {
                    if (!searchParam[csf])
                        return `${csf}=desc`
                    else
                        return `${csf}=${constants.sorting_order_decode[searchParam.sortOrder.toString()]}`
                }
                else if (csf == 'pageNumber') {
                    return `page=${searchParam.pageNumber}`
                }
                else {
                    return `${csf}=${searchParam[csf]}`
                }
        })
    params = _.compact(filters).join('&')
    if (currentSearchFilters.siteId && parseInt(currentSearchFilters.siteId)) {
        searchParamUrl = (`?siteId=${currentSearchFilters.siteId}&${params}`)
    } else {
        searchParamUrl = (`?${params}`)
    }
    return history.push(searchParamUrl)
}
export function setSearchSite(siteId, instant, history, pageNumber) {
    return (dispatch, getState) => {
        let searchParam = getState() && getState().elk && getState().elk.searchParam ? { ...getState().patients.searchParam } : {}
        searchParam.searchFilters = [{
            fieldName: 'siteId',
            fieldValue: siteId
        }]
        if (pageNumber)
            searchParam.pageNumber = pageNumber
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (history)
            setSearchParamUrl(searchParam, history)

        if (instant)
            dispatch(getCreditTransactions())
    }
}
function sortCredits(key, order, isSearch, history, pageNumber, hasParameters) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                sortField: key,
                sortOrder: order
            }
        })
        let pageNumChanged = false
        let searchParam = getState() && getState().elk && getState().elk.searchParam ? getState().patients.searchParam : {}
        if (searchParam && (!searchParam.sortField || searchParam.sortField != key)) {
            searchParam.sortField = key
            if (searchParam.sortField != key) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        creditTransactions: [],
                    }
                })
            }
            pageNumChanged = true
            searchParam.pageNumber = 1
        }
        if (searchParam.sortOrder != constants.sorting_order[order]) {
            searchParam.sortOrder = constants.sorting_order[order]
            searchParam.pageNumber = 1
        } else if (!pageNumChanged) {
            searchParam.pageNumber = pageNumber ? parseInt(pageNumber) : 1
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: { ...searchParam }
            }
        })
        // if ((searchParam.pageNumber == 1 && searchParam.sortField == 'date' && searchParam.sortOrder == -1)
        //     && !searchParam.searchFilters.length && !hasParameters) {
        //     dispatch({
        //         type: SET_PROPS,
        //         payload: {
        //             hasParams: true
        //         }
        //     })
        //     history.push('/credit-transaction/list')
        // }
        if (history) {
            setSearchParamUrl(searchParam, history)
        }
        if (isSearch)
            dispatch(getCreditTransactions())
    }
}
function creditsPageChange(pageNumber, history, isSearch) {
    return (dispatch, getState) => {
        let searchParam = Object.assign({}, { ...getState().elk.searchParam }, { pageNumber })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        // let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        if (history)
            setSearchParamUrl(searchParam, history)

        if (isSearch)
            dispatch(getCreditTransactions())
    }
}
function sortFieldsChange(sortField, sortOrder, isSearch, history) {
    return (dispatch, getState) => {
        sortOrder = constants.sorting_order[sortOrder]
        let searchParam = Object.assign({}, { ...getState().elk.searchParam }, { sortField, sortOrder })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        // let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        if (history)
            setSearchParamUrl(searchParam, history)

        if (isSearch)
            dispatch(getCreditTransactions())
    }
}
function editCredit(eve) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCredit: { ...getState().elk.currentCredit, [eve.target.id]: eve.target.value }
            }
        })
    }
}
export function addCreditTransaction(history, site) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCredit: { site, transactionType: constants.transaction_type }
            }
        })
        history.push(`/credit-transaction/${site.id}/new`)
    }
}
export function loadCreditTransactions(siteId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        if (siteId) {
            promisesList.push(axios.get(`${API_URL.SITES_URL}/${siteId}`).then(response => {
                dispatch(handleLoading())
                let site = response.data
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentCredit: { site, transactionType: constants.transaction_type }
                    }
                })
                let pageTitle = `${labels.elk_labels.add_title} ${site ? site.name : ''}`
                dispatch(setPageTitle(pageTitle))
                dispatch(setBreadCrumbs([
                    { text: labels.physioage_label, path: '/patients/list' },
                    { text: pageTitle, path: '' }
                ]))
            }).catch(error => {
                dispatch(toggleLoading(false))
                dispatch(handleStatusCodes(error))
                dispatch(handleErrors(error))
            }))
        }
    }
}
function toggleFirstTimeLoad(status) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isFirstTimeLoading: status
            }
        })
    }
}
function getCreditTransactionsWithParams(urlparams, history) {
    return (dispatch, getState) => {
        let { siteId, page, sortField, sortOrder } = urlparams
        sortField = sortField ? sortField : 'date'
        sortOrder = sortOrder ? sortOrder : 'desc'
        dispatch({
            type: SET_PROPS,
            pageNumber: urlparams.pageNumber
        })
        if (siteId)
            dispatch(setSearchSite(parseInt(siteId), false, history))
        if (page)
            dispatch(creditsPageChange(parseInt(page), history, false))
        if (sortField && sortOrder)
            dispatch(sortFieldsChange(sortField, sortOrder, false, history))

        dispatch(getCreditTransactions())

    }
}
function clearCreditSearch() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    searchFilters: [

                    ],
                    sortField: 'date',
                    sortOrder: -1
                }
            }
        })
    }
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}
const initialState = {
    searchParam: {
        pageSize: 10,
        pageNumber: 1,
        searchFilters: [

        ],
        sortField: '',
        sortOrder: -1
    },
    currentCredit: {},
    no_grid_data: false,
    isFirstTimeLoading: false,
    pageNumber: 1,
    hasParams: false
}
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export const actionCreators = {
    getCreditTransactions,
    creditsPageChange,
    sortCredits,
    setSearchSite,
    getSites,
    editCredit,
    addCreditTransaction,
    saveCredits,
    loadCreditTransactions,
    toggleFirstTimeLoad,
    getCreditTransactionsWithParams,
    clearCreditSearch
}
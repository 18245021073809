import React, { Component } from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';

const stylesClasses = {
    labelClass: 'col-lg-2',
    inputClass: 'col-lg-7'
}
class MessageForm extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.messageId && this.props.match.params.labName) {
            this.props.setCurrentMessageDetails(this.props.match.params.messageId, this.props.match.params.labName)
            this.props.getSitesList()
        }
        this.props.setPageTitle('Edit Message')
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: 'Message', path: '/messages/details/' + this.props.match.params.messageId },
            { text: 'Edit', path: '' }
        ])
        document.title = `Edit Message`
    }
    render() {
        return (
            <>
                <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12 message-edit-form'>
                </div>
                <form className='message-form container col-lg-9' onSubmit={(e) => { e.preventDefault(); this.props.updateMessage(this.props.history) }} >
                    <FormControl id='body' value={this.props.currentMessage ? this.props.currentMessage.body : ''}
                        onChange={this.props.editMessage} label='Body' type='textarea'  {...stylesClasses}
                    />
                    <FormControl id='siteId' value={this.props.currentMessage ? this.props.currentMessage.siteId : ''}
                        onChange={this.props.editMessage} label={labels.patient_list_labels.site} type='select'
                        options={this.props.sitesList} valueKey={'id'} textKey={'name'} {...stylesClasses}
                        help = "Choose the site to which the message want to be routed. Once you choose the site it will be ready for import to the Patients. Ignore this if you want the message to route automatically."
                         />
                    <div className='d-flex justify-center mt-4 mt-lg-0'>
                        <FormControl type='button' btnType={constants.notification_type.success} text={labels.patient_list_labels.save} 
                        />
                    </div>
                </form>
            </>
        )
    }
}
export default MessageForm;
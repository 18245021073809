import _ from 'lodash';
import en_labels from './en_labels';
import zh_labels from './zh_labels';
import es_labels from './es_labels';
import ru_labels from './ru_labels';
import ro_labels from './ro_labels';

let language_code = navigator.language && navigator.language.toLowerCase()
    ? _.head(navigator.language.toLowerCase().split('-')) : 'en'
let labels = en_labels
// switch (language_code) {
//     case 'en': labels = en_labels
//         break;
//     case 'es': labels = es_labels
//         break;
//     case 'zh': labels = zh_labels
//         break;
//     case 'ru': labels = ru_labels
//         break;
//     case 'ro': labels = ro_labels
//         break;
// }
export default labels
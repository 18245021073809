import React, { Component } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller
} from 'react-scroll';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import _, { toArray } from 'lodash';
import reportConstants from '../../../config/reportConstants';
import Grade from '../../Analytics/components/Grade';
import logo from '../../../assets/images/patientReportLogo.png';
// import '../../Analytics/components/Analytics.scss'
import ReactHighcharts from 'react-highcharts';
import HealthStatusIndicators from './HealthStatusIndicatorsFunctional';
import IndividualVisit from './IndividualVisit';
import labels from '../../../config/localization';
import constant from '../../../config/constants';
import { CalculateChange } from '../../Analytics/tools/calendar'
import enLabels from '../../../config/en_labels';
import marked from 'marked';
import html2react from 'html-to-react';
import ResultsData from './ResultsData';
import { getLocalizedDate } from '../../../utils/reports-date-localization';
import constants from '../../../config/constants';
import report_constants from '../../../config/reportConstants'
import getHistoricalChartUrl from '../../../utils/historical-chart-util';
import { getRoundedValue, IsInRange } from '../../Analytics/tools/helpers';
import en_labels from '../../../config/en_labels';
import IndividualReportRecommendation from './IndividualReportRecommendation';
import IndividualReportProblem from './IndividualReportProblem';

class PatientReportDownloadTemplatePast extends Component {

    constructor(props) {
        super(props);
        this.state = { callFunctionInComponentDidUpdatee: true }
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params && this.props.match.params.visitId && !this.props.currentVisit.id) {
            this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history, true)
            // this.props.getAllCategories(this.props.match.params.visitId, false)
        } else if (this.props.latestVisitId) {
            this.props.setCurrentVisit(this.props.latestVisitId, this.props.history)
            // this.props.getAllCategories(this.props.latestVisitId)
        }
        this.reqNum = 0
        this.pagenumbersDictionary = {}
        this.previousOffset = 0

    }

    markdown2react(md) {
        marked.setOptions({
            renderer: new marked.Renderer(),
            sanitize: true,
            smartLists: true,
        });

        let parser = new html2react.Parser();
        return (parser.parse(marked(md)));
    }
    getTermTranslations(terms, key) {
        return terms ? _.head(terms.filter(s => s.key == key)).termTranslationsDTOList.text : ''
    }
    returnBiomarkerAgePassage(passagesData, ageName) {
        return (passagesData.filter(s => s.key.toLowerCase() == ageName.toLowerCase()).map(s => s.passagesDTO.map(t => {
            if (t.passageTranslationDTO != null && t.passageTranslationDTO.content != null) {
                return <div className="passage">
                    {/* {["Description", "Descriere", "説明"].indexOf(t.passageTranslationDTO.heading) == -1 ? <h3 className="passage-heading"><b>{t.passageTranslationDTO.heading}</b></h3> : " "} */}
                    {this.markdown2react(t.passageTranslationDTO.content)}
                </div>
            }
        })))
    }
    renderExplaination(passageData) {
        return <div className="passage">
            {this.markdown2react(passageData)}
        </div>
    }
    renderCategoryGrade(category, visitId) {
        const reportlabels = reportConstants[this.props.currentVisit.reportPreference]
        var reportCards = []
        category.map(p => {
            if (p.sections.length > 0) {
                p.sections.map(s => {

                    let visitsData = s.data.filter(t => !(t.points === undefined || t.points == null || isNaN(t.points)))

                    if (visitsData.length > 0) {
                        var points = _.sum(visitsData.map(csd => csd.points)) / visitsData.length
                        reportCards.push(<div className='grade-card'>
                            <Grade points={points} isReportCard={true} />
                            <React.Fragment>
                                <div className='description'>{s.name}</div>
                                <div className='reference'><a href={`#${s.key}`}>{reportlabels.report_labels.readmore}</a></div>
                            </React.Fragment>
                        </div>)
                    }
                })
            }

        })
        return reportCards;
    }
    getPointColor = (aggAge, age) => {
        if (aggAge) {
            if (aggAge > age)
                return 'RED'
            else if (aggAge < age)
                return 'GREEN'
        }
        return 'GREY'
    }
    getAgeData(ageName) {
        return this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 && this.props.biomarkerAgesData ? this.props.biomarkerAgesData[this.props.culturedBiomarkersForPast[ageName]].value : '';
    }
    getBaselineChart = (ageName) => {
        let age =  this.props.aggregateAge ? this.props.aggregateAge[ageName] : []
        let patientAge = this.props.currentVisit ? this.props.currentVisit.patientVisitAge : ''
         const plotLines = [];
         for (let i = 0; i <= 100; i += 5) {
             plotLines.push(
             {
                 color: '#bbb',
                 value: i,
                 width: 1,
                 zIndex: 3
             }
             );
         }
         let options = {
             chart: {
               type: 'scatter',
               animation: false,
               width: 320,
               height: 250
             },
             title: {
               text: 'Physiological Age vs Chronological Age'
             },
             xAxis: {
               title: {
                 enabled: true,
                 text: 'Chronological Age (years)'
               },
               startOnTick: true,
               endOnTick: true,
               showLastLabel: true,
               max: 100,
               min: 0,
               plotLines: plotLines,
               
             },
             yAxis: {
               title: {
                 text: 'Physiological Age (years)'
               },
               startOnTick: true,
               endOnTick: true,
               showLastLabel: true,
               max: 100,
               min: 0,
               plotLines: plotLines
             },
             plotOptions: {
               scatter: {
                 marker: {
                   radius: 5,
                   states: {
                     hover: {
                       enabled: true,
                       lineColor: 'rgb(100,100,100)'
                     }
                   }
                 },
                 states: {
                   hover: {
                     marker: {
                       enabled: false
                     }
                   }
                 },
                 tooltip: {
                   headerFormat: '',
                   pointFormat: `Patient Age: {point.x} years <br> ${this.props.culturedBiomarkers[ageName]} Age: {point.y} years`
                 }
               }
             },
             series: [{
               color: '#36c',
               data: [[patientAge, parseFloat(age[0])]]
             }]
           };
         return (
            <div className='react-high-chart'> 
                 <ReactHighcharts
                 config={options}
                 />
             </div>
           );
        // return <img className='baseline-chart' src={`http://chart.apis.google.com/chart?cht=s&chd=t:${this.props.currentVisit.patientVisitAge},0,100|${this.getAgeData(ageName)},0,100|100,0,0&chg=5,5,1,0,0&chco=${this.getPointColor(this.getAgeData(ageName), this.props.currentVisit.patientVisitAge)}&chxt=x,y,x,y&chxr=0,0,100,20|1,0,100,20&chs=320x250&chf=c,ls,135,FFFFFF,0.40,EEEEEE,0.2,FFFFFF,0.4&chxl=2:|Chronological Age|3:|Physiological Age|&chxp=2,50|3,50&chm=D,999999,0,1:2,1,-1`} />
    }
    ageCalculation(ageName) {
        var showAst = this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast[ageName], this.props.currentVisit.id)
        if (showAst) {
            return en_labels.analytics_labels.measured_recently;
        }
        const labels = reportConstants[this.props.currentVisit.reportPreference]
        var ageData = this.getAgeData(ageName) //this.props.followUpData ? Math.round(this.props.followUpData.filter(s => s.timePoint == this.props.currentVisit.timePoint && s.label == ageName && s.value != "0").map(s => s.value)) : '';
        if (ageData) {
            var chronological = this.props.currentVisit.patientVisitAge;
            var physiological = ageData;
            if (!physiological)
                return (labels.report_labels.measured_recently)
            else {
                var diff = getRoundedValue(chronological - physiological, 1);
                if (physiological > chronological)
                    return (`${this.props.terms['Your']} ${this.props.culturedBiomarkersForPast[ageName]} ${this.props.terms[enLabels.report_labels.is_label]} ${physiological}, 
                    ${this.props.terms['6 years older than'].replace(this.props.terms['6 years older than'][0], Math.abs(diff))} ${this.props.terms['than average for your age']}`);
                else if (physiological < chronological)
                    return (`${this.props.terms['Your']} ${this.props.culturedBiomarkersForPast[ageName]} ${this.props.terms[enLabels.report_labels.is_label]} ${physiological},
                    ${this.props.terms['7 years younger than'].replace(this.props.terms['7 years younger than'][0], diff)} ${this.props.terms['than average for your age']}`);
                else {
                    return (`${labels.report_labels.your_label} ${this.props.culturedBiomarkersForPast[ageName]} ${labels.report_labels.is_label} ${physiological}, ${this.props.terms['neither older nor younger']}`);
                }
            }
        }
        else {
            return <div className="warning-caution"><h3><i className="warn warning"></i> {this.props.terms[enLabels.report_labels.test_not_performed]}</h3></div>
        }
    }
    getBiomakersConfig(rowData, graphVisitData, xAxisData) {
        if (rowData) {
            return {
                chart: {
                    type: 'spline',
                    animation: false,
                    width: 320,
                    height: 250
                },
                xAxis: {
                    categories: xAxisData ? xAxisData : '',
                    tickInterval: 1,
                    plotLines: [{
                        color: 'black',
                        width: 2,
                        value: -0.5
                    }]
                },
                plotOptions: {
                    series: {
                        lineWidth: 2,
                        animation: false,
                        connectNulls: true,
                        marker: {
                            radius: 3
                        }
                    }
                },
                yAxis: {
                    value: 20,

                    tickInterval: 20,
                },
                series: [
                    {
                        data: rowData ? rowData.map(s => parseFloat(s)) : '',
                        color: '#36c'
                    },
                    {
                        data: graphVisitData ? graphVisitData : '',
                        color: '#bbb'
                    }
                ]
            };
        }
    }
    getAbnormalHeaders(showGrade, isBaseline) {
        const abnormalResultHeaders = []
        if (showGrade)
            abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.grade_label])
        abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.name_label])
        abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.result_label])
        abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.units_label])
        //abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.normal_range])
        abnormalResultHeaders.push("Lab Ref Range")
        abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.optimal_range])
        if (!isBaseline) {
            abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.baseline_label])
            abnormalResultHeaders.push(this.props.terms[enLabels.report_labels.change])
        }
        return abnormalResultHeaders;
    }
    isReportCardExist(category, visitId) {
        let allData = []
        if (category)
            category.map(c => {
                c.sections.map(sec => sec.data.map(d => allData.push(d)))
            })
        allData = allData.filter(ad => ad.points !== undefined && ad.points != null && !isNaN(ad.points))
        return allData.length ? true : false
    }
    getPageNumberFromOffset(s, add, previousSection, isHide) {
        return '' //temp fix to remove page numbers
        let currentEleOffset = document.getElementById(s) ? document.getElementById(s).offsetTop : null
        let previousSectionElement = document.getElementById(previousSection)
        let patientReportDownloadTemplatePast = document.getElementById('patientReportDownloadTemplatePast')
        let windownumber = 1000
        if (add && currentEleOffset && previousSectionElement) {
            let winVal = (windownumber - (previousSectionElement.offsetHeight ? previousSectionElement.offsetHeight : 0 % windownumber))
            this.reqNum = (winVal && !isNaN(winVal)) ? this.reqNum + winVal : this.reqNum
        }

        let reqNum
        if (document.getElementById(s) && patientReportDownloadTemplatePast
            && this.pagenumbersDictionary
            && !this.pagenumbersDictionary[s]
            && patientReportDownloadTemplatePast.offsetTop) {
            currentEleOffset = currentEleOffset ? currentEleOffset : this.reqNum + windownumber
            let page
            if (!isNaN(this.reqNum))
                page = parseInt((currentEleOffset + this.reqNum) - patientReportDownloadTemplatePast.offsetTop)
            else
                page = parseInt((currentEleOffset) - patientReportDownloadTemplatePast.offsetTop)

            reqNum = parseInt(page / windownumber)// page.toString().substr(0, pagenumber[page.toString().length])

            this.pagenumbersDictionary[s] = reqNum
            return !isHide ? reqNum : ''
        }
        return ''
    }
    getHistoricData() {
        if (this.props.categories && this.props.categories.length) {
            var final = []
            var allData = []
            var allVisits = []

            this.props.categories.map(t => t.sections.map(s => { return { [s.name]: s.data } }).map(f => { final.push(f) }))
            if (final && final.length)
                final.map(f => f[_.head(Object.keys(f))].map(k => _.sortBy(k.historicData, (h) => new Date(h)).map(hd => {
                    allVisits.push(hd.visitDate);
                    allData.push(hd);
                })))
            allVisits = _.sortBy(_.uniq(allVisits), v => new Date(v))
            allData = _.groupBy(allData, 'label')
            // console.log(final)
            // console.log(allVisits)
            // console.log(allData)
            var finalPanelObs = final.map(f => {
                let fkey = _.head(Object.keys(f))
                return { [fkey]: f[fkey].map(l => l.label) }
            })
            if (allVisits && allVisits.length <= 10) {
                return <>
                    <div className='patients-report-preview-table'>
                        <table className='table-striped table-hover'>
                            <thead className='historic-data-visits-title'>
                                <th>{this.props.terms['Visit Date']}</th>
                                {allVisits.map(av => <th className='dates'>{moment(av).format('DD/MM')}<br></br>{moment(av).format('YYYY')}</th>)}
                            </thead>
                            <tbody>
                                {finalPanelObs.map(fpo => {
                                    var fkey = _.head(Object.keys(fpo))
                                    return (
                                        <>
                                            <tr className='historic-chart-panel-header'>
                                                <td><strong>{fkey}</strong></td>
                                                {allVisits.map(a => <td></td>)}
                                            </tr>
                                            {fpo[fkey].map(obs => {
                                                return <><tr>
                                                    <td>{obs}</td>
                                                    {allVisits.map(currentVisit => {
                                                        let availableVal = _.head(allData[obs].filter(indiObs => indiObs.visitDate == currentVisit && indiObs.valueType != "ST" && indiObs.isTextArea != 1))
                                                        if (availableVal) {
                                                            return <td className='word-warp'>{availableVal.value}</td>
                                                        } else {
                                                            return <td></td>
                                                        }
                                                    })}
                                                </tr></>
                                            })}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table >
                    </div>
                </>
            }
            else {
                let baselineDate = _.head(allVisits)
                let recentVisit = _.last(allVisits)
                return <>
                    <div className='patients-report-preview-table'>
                        <div className='bootstrap-table-container'>
                            <table className='table table-striped table-hover'>
                                <thead className='historic-data-visits-title'>
                                    <th>{this.props.terms['Visit Date']}</th>
                                    <th>{this.props.terms['Baseline']}</th>
                                    <th>{this.props.terms['most recent visit']}</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    {finalPanelObs.map(fpo => {
                                        var fkey = _.head(Object.keys(fpo))
                                        return (
                                            <>
                                                <tr className='historic-chart-panel-header'>
                                                    <td><strong>{fkey}</strong></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                {fpo[fkey].map(obs => {
                                                    let headValue = _.head(allData[obs].filter(indiObs => indiObs.visitDate == baselineDate && indiObs.valueType != "ST" && indiObs.isTextArea != 1))
                                                    let tailValue = _.head(allData[obs].filter(indiObs => indiObs.visitDate == recentVisit && indiObs.valueType != "ST" && indiObs.isTextArea != 1))
                                                    let valueType = _.head(allData[obs]).valueType
                                                    return <tr>
                                                        <td>{obs}</td>
                                                        <td>{headValue ? headValue.value : ''}</td>
                                                        <td>{tailValue ? tailValue.value : ''}</td>
                                                        <td>{valueType != constants.text_value_type ? this.getChartSource(allData, obs, allVisits) : ''}</td>
                                                    </tr>
                                                })}
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table >
                        </div>
                    </div>
                </>
            }

        }
    }
    getNoumenonValueData() {
        if (this.props.categories) {
            var final = []
            var allData = []
            var allVisits = []
            this.props.categories.map(t => t.sections.map(s => { return { [s.name]: s.data } }).map(f => { final.push(f) }))
            if (final && final.length)
                final.map(f => f[_.head(Object.keys(f))].map(k => _.sortBy(k.historicData, (h) => new Date(h)).map(hd => {
                    allVisits.push(hd.visitDate);
                    allData.push(hd);
                })))
            allVisits = _.sortBy(_.uniq(allVisits), v => new Date(v))
            allData = _.groupBy(allData, 'label')
            var finalPanelObs = final.map(f => {
                let fkey = _.head(Object.keys(f))
                return { [fkey]: f[fkey].map(l => l.label) }
            })
            return <div>
                {finalPanelObs.map(fpo => {
                    var fkey = _.head(Object.keys(fpo));
                    return (<>
                        {fpo[fkey].map(obs => {
                            if (allData[obs] && allData[obs].filter(indiObs => indiObs.valueType == "ST" && indiObs.isTextArea == 1).length > 0) {
                                return <div className="pt-2 pb-2 text-color">
                                    <div>
                                        <div className='fw-10000 mb-2 h5 pb-2'>{fkey}</div>
                                    </div>
                                    <div className='pb-2 noumenon-value-card'>
                                        <div className='fw-10000 mb-2 h5 pl-4 pt-4'>{obs}</div>
                                        {allVisits.map(currentVisit => {
                                            let availableVal = _.head(allData[obs].filter(indiObs => indiObs.visitDate == currentVisit && indiObs.valueType == "ST" && indiObs.isTextArea == 1))
                                            if (availableVal) {
                                                return <div className='bt'>
                                                    <div className='pl-4 pt-2 ml-3'>
                                                        <div className='fw-10000 mb-2'>{this.props.terms['Visit Date']}</div>
                                                        <div className='ml-3'>{moment(currentVisit).format('DD/MM/YYYY')}</div>
                                                        <div className='fw-10000 mb-2'> {this.props.terms[en_labels.report_labels.result_label]} </div>
                                                        <div className='ml-3 mr-3 mb-3'>{availableVal.value}</div>
                                                    </div>
                                                </div>
                                            } else {
                                                return <p></p>
                                            }
                                        })}
                                    </div>
                                </div>
                            } else { return <></> }
                        })}
                    </>)
                })}
            </div>
        }
    }
    getChartSource(allNumericalData, obs, allVistsDates) {
        let values = []
        allVistsDates.map(avd => {
            let preVal = allNumericalData[obs].filter(and => and.visitDate == avd)
            if (preVal && preVal.length) {
                var val = _.head(preVal).value
                if (!isNaN(parseInt(val)))
                    values.push(val)
                else
                    values.push('0')
            } else {
                values.push('0')
            }
        })
        if (parseFloat(_.min(values)) == 0 && parseFloat(_.max(values)) == 0 || (values.filter(v => v != '0').length <= 2)) {
            return ''
        } else {
            values = values ? values.filter(v => parseFloat(v)) : []
            values = values.map(v => parseFloat(v))
            values = values.filter(v => v != '0')
            let config = {
                chart: {
                    type: 'column',
                    height: 60,
                    width: 200,
                    backgroundColor: 'transparent'
                },
                title: {
                    text: '',
                },
                legend: {
                    enabled: false
                },
                    xAxis: {
                    lineWidth: 1,
                    tickLength: 0, 
                    lineColor: '#667788',
                    gridLineWidth: 0,
                    labels: {
                        enabled: false
                    }
                    },
                    yAxis: {
                    title: {
                        text: ''
                    },
                    lineWidth: 1.5, 
                    gridLineWidth: 0,
                    lineColor: '#667788'
                    },
                series: [{
                    color: '#667788',
                    data: values,
                }],
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<span>{point.y}</span>' // Show only value
                    },
            };
            return <div className='react-high-chart-noumenon'><ReactHighcharts config={config} /></div>
            // return <img src={getHistoricalChartUrl(values, allNumericalData)} />
        }
    }
    showAsterisk(followupdata, age, id) {
        if (followupdata != null && Object.keys(followupdata).length) {
            return !Object.keys(followupdata).filter(s => followupdata[s].value != "0" && followupdata[s].cultureLabel == age && followupdata[s].visitId == id).length
        }
    }
    getFullName(detail) {
        return detail ? `${detail.providerHonorificPrefix ? `${detail.providerHonorificPrefix} ` : ''}${detail.providerName}${detail.providerHonorificSuffix ? `, ${detail.providerHonorificSuffix}` : ''}` : null
    }
    renderRecommendations() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><strong>{this.props.terms['Your Recommendations']}</strong> <span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h3>
                    {this.props.patientReportCurrentRecommendations.map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...this.props} key={i} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;

    }
    renderStoppedRecommendations() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><strong>Stopped Recommendations</strong> <span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h3>
                    {this.props.patientReportStoppedRecommendations.map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...this.props} key={i} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;

    }
    renderMedications() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><strong>Medications</strong> <span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h3>
                    {this.props.patientReportCurrentRecommendations.filter(s => constants.medication_groups.includes(s.group)).map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...this.props} key={i} isMedication={true} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;
    }
    renderStoppedMedications() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><strong>Stopped Medications</strong> <span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h3>
                    {this.props.patientReportStoppedRecommendations.filter(s => constants.medication_groups.includes(s.group)).map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...this.props} key={i} isMedication={true} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;
    }
    renderProblems() {
        if (Object.keys(this.props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><b>Your Problems List</b><span className='rec-provider-name'>{this.props.terms['from_recommending_provider'].replace('%{provider_name}', this.getFullName(this.props.currentVisit))}</span></h3>
                    {this.props.patientReportProblems.map(
                        (problem, i) => {
                            return (
                                <IndividualReportProblem problem ={problem}{...this.props} key={i} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;
    }
    addToTableOfContents(ff, arr, isHealthStatusIndicators, props) {
        if (isHealthStatusIndicators) {
            {
                props.categories ?
                    props.categories.map(
                        s => s.sections.map((p) => {
                            if (p.data.filter(q => q.value).length > 0) {
                                arr.push(p.key)
                            }
                        })) : ''
            }
        }
        else {
            arr.push(ff);
        }
        if (props && !props.patientReportLoaded && !isHealthStatusIndicators) {
            props.isPatientReportLoaded(true)
            props.addToTableOfContentsArray(arr)
        }

    }
    render() {
        var tocArray = [];
        var localeLabels = Object.keys(this.props.currentVisit).length ? report_constants[this.props.currentVisit.patientLanguagePref].report_labels : ''
        if (this.props.currentVisit && Object.keys(this.props.currentVisit).length > 0 && this.props.terms) {
            // const constants = reportConstants[this.props.match.params.locale]
            // const labels = constants
            const historicColumns = [
                {
                    dataField: 'label',
                    text: this.props.terms[enLabels.report_labels.name_label],
                    formatter: (cellContent, row) => (<span className='table-name-break'>{row.label}</span>)
                },
                {
                    dataField: 'value',
                    text: this.props.terms[enLabels.report_labels.result_label],
                    formatter: (cellContent, row) => {
                        return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => {
                            return s.value === null
                                ? "No-value " : <span className='spanForAlignment'>{(s.value && s.valueType == constants.numerical_value_type)
                                    ? parseFloat(s.value).toFixed(s.scale) : s.value}</span>
                        }) : ''}</span>
                    },
                },
                {
                    dataField: 'visitDate',
                    text: this.props.terms[enLabels.report_labels.visit_date],
                    formatter: (cellContent, row, index) => {
                        return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => { return !isNaN(new Date(s.visitDate).getDate()) ? <span className='spanForAlignment'>{moment(s.visitDate).format('L')}</span> : 'No-Date' }) : ''}</span>
                    },
                },
            ]

            const historicColumnsHeader = [
                this.props.terms[enLabels.report_labels.name_label],
                this.props.terms[enLabels.report_labels.result_label],
                this.props.terms[enLabels.report_labels.visit_date]
            ]
            const columns = [
                {
                    dataField: 'points',
                    formatter: (cellContent, row) => (<span><Grade points={row.points} /></span>),
                    text: this.props.terms[enLabels.report_labels.grade_label],
                },
                {
                    dataField: 'label',
                    formatter: (cellContent, row) => (<span className='table-name-break'>{row.label}</span>),
                    text: this.props.terms[enLabels.report_labels.name_label]
                },
                {
                    dataField: 'value', text: this.props.terms[enLabels.report_labels.result_label],
                    formatter: (cellContent, row) => {
                        if (row.points >= 3.7) {
                            return <span className='success'>{(row.value && row.valueType == constants.numerical_value_type)
                                ? parseFloat(row.value).toFixed(row.scale) : row.value}</span>
                        }
                        else if (row.points < 1.5) {
                            return <span className='danger'>{(row.value && row.valueType == constants.numerical_value_type)
                                ? parseFloat(row.value).toFixed(row.scale) : row.value}</span>
                        }
                        else {
                            return <span>{(row.value && row.valueType == constants.numerical_value_type)
                                ? parseFloat(row.value).toFixed(row.scale) : row.value}</span>
                        }
                    }
                },
                { dataField: 'units', text: this.props.terms[enLabels.report_labels.units_label] },
                {
                    dataField: 'referenceRange', text: this.props.terms[enLabels.report_labels.normal_range],
                },
                { dataField: 'optimalRange', text: this.props.terms[enLabels.report_labels.optimal_range], },
                {
                    dataField: 'value',
                    formatter: (cellContent, row) => (<span>{row.historicData && _.head(row.historicData)['visitId'] != row.visitId ? _.head(row.historicData)['value'] : ''}</span>),
                    text: this.props.terms[enLabels.report_labels.baseline_label],
                    hidden: this.props.currentVisit && this.props.currentVisit.timePoint == constant.baseline ? true : false
                },
                {
                    dataField: 'Change',
                    formatter: (cellContent, row) => (<span>{CalculateChange(row)}</span>),
                    text: this.props.terms[enLabels.report_labels.change],
                    hidden: this.props.currentVisit && this.props.currentVisit.timePoint == constant.baseline ? true : false
                }
            ]
            return (
                this.props.terms && this.props.currentVisit && Object.keys(this.props.currentVisit).length > 0 && this.props.passagesData ?
                    <div>{
                        this.props.currentVisit && this.props.aggregateAge && this.props.graphVisitData ?
                            <div className='titlepage' id='patientReportDownloadTemplatePast'>
                                <div id="main-toc">
                                    <div className='coverpage' id='coverpage'>
                                        <div className='reportImg'>
                                            <img src={this.props.currentVisit.logoFile
                                                ? `data:${this.props.currentVisit.logoContentType};base64,${this.props.currentVisit.logoFile}`
                                                : this.props.physioageLogo}></img>
                                        </div>
                                        <div className='titlepage'>
                                            <div className='title'>{this.props.terms[enLabels.report_labels.comprehensive_health_analysis]}</div>
                                            <div className='subtitle'>{this.props.currentVisit.timePoint.toLowerCase() != 'baseline' ? this.props.currentVisit.timePoint.replace('Follow-Up', this.props.terms['Follow-Up']) : this.props.terms['Baseline']}</div>
                                        </div>
                                        <div className='cover-page-details row'>
                                            <div className='patient-identification'>
                                                <div className='title-block'>
                                                    <div className='small-line'>
                                                        {this.props.terms[enLabels.report_labels.prepared_for]}
                                                    </div>
                                                    <div className='line'>
                                                        {this.props.currentVisit.honorificPrefix} {this.props.currentVisit.firstname} {this.props.currentVisit.lastname}{this.props.currentVisit.honorificSuffix ? `, ${this.props.currentVisit.honorificSuffix}` : ''}
                                                    </div>
                                                </div>
                                                <div className='title-block'>
                                                    <div className='small-line'>
                                                        {this.props.terms[enLabels.report_labels.by_provider]}
                                                    </div>
                                                    <div className='line'>
                                                        {this.props.currentVisit.providerHonorificPrefix} {this.props.currentVisit.providerName}{this.props.currentVisit.providerHonorificSuffix ? `, ${this.props.currentVisit.providerHonorificSuffix}` : ''}
                                                    </div>
                                                </div>
                                                <div className='title-block'>
                                                    <div className='small-line'>
                                                        {this.props.terms[enLabels.report_labels.tests_performed]}
                                                    </div>
                                                    <div className='line'>
                                                        {getLocalizedDate(this.props.currentVisit.date, this.props.currentVisit.reportPreference, this.props.currentVisit.datePreference)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='reports-powered-by'>
                                                <div>{this.props.terms[enLabels.report_labels.powered_by]}</div>
                                                <img src={this.props.poweredByLogo} />
                                            </div>
                                        </div>
                                    </div>
                                    <p className='break-page-before'></p>
                                    <div className='toc' id='toc'>
                                        <div className='chapter-heading' style={{ paddingTop: 20 }}>
                                            {this.props.terms[enLabels.report_labels.table_contents]}
                                        </div>
                                        <ul id='contents' className='contents'>
                                            {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ?
                                                <>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#introduction'>{this.props.terms[enLabels.report_labels.introduction]}</a>
                                                        <span class="paddingLeft" id="##introduction">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                </> : ''}
                                            {this.props.completeData && this.props.completeData.length > 0
                                                && this.isReportCardExist(this.props.categories, this.props.currentVisit.id) ?
                                                <>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#report-card'>
                                                            {this.props.terms[enLabels.report_labels.report_card_label]}
                                                        </a>
                                                        <span class="paddingLeft" id="##report-card">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                </> : ''}
                                            {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ?
                                                <li>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#boa'>
                                                            {this.props.terms[enLabels.report_labels.biomarkers_of_aging_label]}</a>
                                                        <span class="paddingLeft" id="##boa">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                    {this.props.culturedBiomarkersForPast ? Object.keys(this.props.culturedBiomarkersForPast).map(s => {
                                                        var showAst = this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast[s], this.props.currentVisit.id)
                                                        return (<ul>
                                                            <li className="flexSpaceBetween"><a className="xref paddingRight" href={`#${s}`}>{this.props.culturedBiomarkersForPast[s]}{showAst ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}</a><span class="paddingLeft" id={`##${s}`}>0</span></li>
                                                            <hr class="dottedLine" />
                                                        </ul>)
                                                    }) : ''}
                                                </li>
                                                : ''}
                                            {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ?
                                                <>
                                                    <li className="flexSpaceBetween">

                                                        <a className="xref paddingRight" href='#comparing_systems'>{this.props.terms[enLabels.report_labels.compare_system]}</a>
                                                        <span class="paddingLeft" id="##comparing_systems">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                </>
                                                : ''}
                                            {this.props.completeData && this.props.completeData.length > 0 ?
                                                <li>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#health_status_indicators'>{this.props.terms[enLabels.report_labels.health_status_indicator]}</a>
                                                        <span class="paddingLeft" id="##health_status_indicators">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                    <ul>
                                                        {this.props.categories ? this.props.categories.map(s => s.sections.map(t =>
                                                            t.data.filter(u => u.value).length > 0 ?
                                                                <><li className='linkForHealth flexSpaceBetween'><a className="xref paddingRight" href={`#${t.key}`}>{t.name}</a> <span class="paddingLeft" id={`##${t.key}`}>0</span></li>
                                                                    <hr class="dottedLine" /></>
                                                                : '')) : ''}
                                                    </ul>
                                                </li> : ''}
                                            {this.props.patientReportRecommendations && this.props.patientReportRecommendations.length > 0 ?
                                                <>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#patient_treatment_plan'>{this.props.terms['Treatment Plan']}</a>
                                                        <span class="paddingLeft" id="##patient_treatment_plan">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                </> : ''}
                                            {this.props.patientReportRecommendations && this.props.patientReportRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ?
                                                <>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#patient_medications'>Medications</a>
                                                        <span class="paddingLeft" id="##patient_medications">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                </> : ''}
                                            {this.props.patientReportProblems && this.props.patientReportProblems.length > 0 ?
                                                <>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#patient_problems'>Problems List</a>
                                                        <span class="paddingLeft" id="##patient_problems">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                </> : ''} 
                                            {this.props.exceptionalData && this.props.exceptionalData.length > 0 ?
                                                <><li className="flexSpaceBetween"><a className="xref paddingRight" href='#exceptional_results'>{this.props.terms[enLabels.report_labels.exceptional_results]}</a><span class="paddingLeft" id="##exceptional_results">0</span></li>
                                                    <hr class="dottedLine" /></> : ''}
                                            {this.props.abnormalData && this.props.abnormalData.length > 0 ?
                                                // <li><a className="xref" href='#abnormal_results'>{this.props.terms[enLabels.report_labels.abnormal_results]}</a></li> : ''}
                                                <>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#abnormal_results'>{this.props.terms['results_of_concern']}</a>
                                                        <span class="paddingLeft" id="##abnormal_results">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                </> : ''}
                                            {this.props.completeData && this.props.completeData.length > 0 ?
                                                <>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#complete_data'>{this.props.terms[enLabels.report_labels.complete_data]}</a>
                                                        <span class="paddingLeft" id="##complete_data">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                </> : ''}
                                            {this.props.currentVisit.timePoint != constant.baseline
                                                && this.props.categories && this.props.categories.length ?
                                                <>
                                                    <li className="flexSpaceBetween">
                                                        <a className="xref paddingRight" href='#historic_data'>{this.props.terms[enLabels.report_labels.historical_data]}{this.getPageNumberFromOffset('historic_data', true, 'complete_data')}</a>
                                                        <span class="paddingLeft" id="##historic_data">0</span>
                                                    </li>
                                                    <hr class="dottedLine" />
                                                </>
                                                : ''}
                                            <>
                                                <li className="flexSpaceBetween"><a className="xref paddingRight" href='#disclaimer'>{localeLabels.disclaimer}</a><span class="paddingLeft" id="##disclaimer">0</span></li>
                                                <hr class="dottedLine" />
                                            </>
                                        </ul>
                                    </div>
                                </div>
                                <div id="other-toc"> 
                                    {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ?
                                        <div className='chapter two-columns-layout' id='introduction'>
                                            <h1 className='chapter-heading'>{this.props.terms[enLabels.report_labels.introduction]}{this.addToTableOfContents("introduction", tocArray)}</h1><span className="headerId">##introduction</span>
                                            <div className='passage'>
                                                {this.props.currentVisit.timePoint == constant.baseline ? <center className='baseline-image'><img src={this.props.baselineGraph} alt="Failed to load image"></img></center> : ''}
                                                {this.props.currentVisit.timePoint != constant.baseline ?
                                                    <React.Fragment>
                                                        <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "followup_welcome_section")}</p>
                                                        <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "understanding_follow_up_biomarker_diagrams_section")}</p>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "baseline_welcome_section")}</p>
                                                        <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "baseline_illustrations_section")}</p>
                                                        <p>{this.returnBiomarkerAgePassage(this.props.passagesData, "aging_happens_over_time_section")}</p>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div> : ''}
                                    {this.props.completeData && this.props.completeData.length > 0
                                        && this.isReportCardExist(this.props.categories, this.props.currentVisit.id) ?
                                        <div className='chapter two-columns-layout' id='report-card'>
                                            <h1 className='chapter-heading'>{this.props.terms[enLabels.report_labels.report_card_label]}{this.addToTableOfContents("report-card", tocArray)}</h1><span className="headerId">##report-card</span>
                                            <div className='copy palette palette_default'>
                                                <div className='grade-card'>
                                                    {this.renderCategoryGrade(this.props.categories, this.props.currentVisit.id)}
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                    {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ?
                                        <div className='chapter two-columns-layout' id='boa'>
                                            <h1 className='chapter-heading'>{this.props.terms[enLabels.report_labels.biomarkers_of_aging_label]}{this.addToTableOfContents("boa", tocArray)}</h1><span className="headerId">##boa</span>
                                            <div className='copy'>
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'physioAge').length > 0 ?
                                                    <div className='section'>
                                                        <h2 className='section-heading' id='physioAge'>{this.props.culturedBiomarkersForPast[enLabels.report_labels.physioage]}	{this.props.terms[enLabels.report_labels.composite]}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['physioAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("physioAge", tocArray)}</h2><span className="headerId">##physioAge</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.physioAge : ''
                                                                    , this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div>
                                                            : this.getBaselineChart('physioAge')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('physioAge')}</b>
                                                        </p>
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "physioage_composite_section")}

                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 && this.props.biomarkerAgesData ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'truAge_trudiag').length > 0 ?
                                                    <>
                                                    {<br></br>}
                                                    <div className='section' id='truAge_trudiag'>
                                                        <h2 className='section-heading'>{this.props.culturedBiomarkersForPast['truAge_trudiag']}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['truAge_trudiag'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("truAge_trudiag", tocArray)}</h2><span className="headerId">##truAge_trudiag</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.truAge_trudiag : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div> : this.getBaselineChart('truAge_trudiag')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('truAge_trudiag')}</b>
                                                        </p>
                                                        {this.renderExplaination(this.props.biomarkerAgesData[this.props.culturedBiomarkersForPast['truAge_trudiag']].noumenaExplaination)}
                                                        {/* {this.returnBiomarkerAgePassage(this.props.passagesData, "physioage_composite_section")} */}
                                                    </div>
                                                    </>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 && this.props.biomarkerAgesData ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'epigenAge').length > 0 ?
                                                    <div className='section'>
                                                        <h2 className='section-heading' id='epigenAge'>{this.props.culturedBiomarkersForPast[enLabels.report_labels.epigenage]}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['epigenAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("epigenAge", tocArray)}</h2><span className="headerId">##epigenAge</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.epigenAge : ''
                                                                    , this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div>
                                                            : this.getBaselineChart('epigenAge')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('epigenAge')}</b>
                                                        </p>
                                                        {this.renderExplaination(this.props.biomarkerAgesData[this.props.culturedBiomarkersForPast['epigenAge']].noumenaExplaination)}
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "epigenage_section")}
                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 && this.props.biomarkerAgesData ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'phenoAge_levine').length > 0 ?
                                                    <div className='section' id='phenoAge_levine'>
                                                        <h2 className='section-heading'>{this.props.culturedBiomarkersForPast['phenoAge_levine']}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['phenoAge_levine'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("phenoAge_levine", tocArray)}</h2><span className="headerId">##phenoAge_levine</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.phenoAge_levine : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div> : this.getBaselineChart('phenoAge_levine')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('phenoAge_levine')}</b>
                                                        </p>
                                                        {/* {this.renderExplaination(this.props.biomarkerAgesData[this.props.culturedBiomarkersForPast['phenoAge_levine']].noumenaExplaination)} */}
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "phenoage_section")}
                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 && this.props.biomarkerAgesData ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'glycanAge').length > 0 ?
                                                    <div className='section' id='glycanAge'>
                                                        <h2 className='section-heading'>{this.props.culturedBiomarkersForPast['glycanAge']}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['glycanAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("glycanAge", tocArray)}</h2><span className="headerId">##glycanAge</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts ref='glycanage' config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.glycanAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div> : this.getBaselineChart('glycanAge')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('glycanAge')}</b>
                                                        </p>
                                                        {this.renderExplaination(this.props.biomarkerAgesData[this.props.culturedBiomarkersForPast['glycanAge']].noumenaExplaination)}
                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'telomerAge').length > 0 ?
                                                    <div className='section'>
                                                        <h2 className='section-heading' id='telomerAge'>{this.props.culturedBiomarkersForPast[enLabels.report_labels.telomerage]}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['telomerAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("telomerAge", tocArray)}</h2><span className="headerId">##telomerAge</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.telomerAge : ''
                                                                    , this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div>
                                                            : this.getBaselineChart('telomerAge')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('telomerAge')}</b>
                                                        </p>
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "telomerage_section")}

                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'immunoAge').length > 0 ?
                                                    <div className='section'>
                                                        <h2 className='section-heading' id='immunoAge'>{this.props.culturedBiomarkersForPast[enLabels.report_labels.immunoage]}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['immunoAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("immunoAge", tocArray)}</h2><span className="headerId">##immunoAge</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.immunoAge : ''
                                                                    , this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div>
                                                            : this.getBaselineChart('immunoAge')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('immunoAge')}</b>
                                                        </p>
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "immunoage_section")}

                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'cardioAge').length > 0 ?
                                                    <div className='section'>
                                                        <h2 className='section-heading' id='cardioAge'>{this.props.culturedBiomarkersForPast['cardioAge']}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['cardioAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("cardioAge", tocArray)}</h2><span className="headerId">##cardioAge</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts ref='cardioage' config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.cardioAge : ''
                                                                    , this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div>
                                                            : this.getBaselineChart('cardioAge')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('cardioAge')}</b>
                                                        </p>
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "cardioage_section")}
                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'pulmoAge').length > 0 ?
                                                    <div className='section'>
                                                        <h2 className='section-heading' id='pulmoAge'>{this.props.culturedBiomarkersForPast[enLabels.report_labels.pulmoage]}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['pulmoAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("pulmoAge", tocArray)}</h2><span className="headerId">##pulmoAge</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.pulmoAge : ''
                                                                    , this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div>
                                                            : this.getBaselineChart('pulmoAge')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('pulmoAge')}</b>
                                                        </p>
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "pulmoage_section")}
                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'neuroAge').length > 0 ?
                                                    <div className='section'>
                                                        <h2 className='section-heading' id='neuroAge'>{this.props.culturedBiomarkersForPast[enLabels.report_labels.neuroage]}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['neuroAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("neuroAge", tocArray)}</h2><span className="headerId">##neuroAge</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.neuroAge : ''
                                                                    , this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div>
                                                            : this.getBaselineChart('neuroAge')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('neuroAge')}</b>
                                                        </p>
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "neuroage_section")}
                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'cutoAge').length > 0 ?
                                                    <div className='section'>
                                                        <h2 className='section-heading' id='cutoAge'>{this.props.culturedBiomarkersForPast[enLabels.report_labels.cutoage]}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['cutoAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("cutoAge", tocArray)}</h2><span className="headerId">##cutoAge</span>
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "cutoage_quote_section")}
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.cutoAge : ''
                                                                    , this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div>
                                                            : this.getBaselineChart('cutoAge')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('cutoAge')}</b>
                                                        </p>
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "cutoage_section")}

                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 && this.props.biomarkerAgesData ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'incite_trubiohealth_Age').length > 0 ?
                                                    <div className='section' id='incite_trubiohealth_Age'>
                                                        <h2 className='section-heading'>{this.props.culturedBiomarkersForPast['incite_trubiohealth_Age']}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['incite_trubiohealth_Age'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("incite_trubiohealth_Age", tocArray)}</h2><span className="headerId">##incite_trubiohealth_Age</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.incite_trubiohealth_Age : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div> : this.getBaselineChart('incite_trubiohealth_Age')}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('incite_trubiohealth_Age')}</b>
                                                        </p>
                                                        {this.renderExplaination(this.props.biomarkerAgesData[this.props.culturedBiomarkersForPast['incite_trubiohealth_Age']].noumenaExplaination)}
                                                        {/* {this.returnBiomarkerAgePassage(this.props.passagesData, "physioage_composite_section")} */}
                                                    </div>
                                                    : '' : ''}
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 && this.props.biomarkerAgesData ? Object.keys(this.props.culturedBiomarkersForPast).filter(s => s == 'omicmAge').length > 0 ?
                                                    <div className='section' id='omicmAge'>
                                                        <h2 className='section-heading'>{this.props.culturedBiomarkersForPast['omicmAge']}{this.showAsterisk(this.props.followUpData, this.props.culturedBiomarkersForPast['omicmAge'], this.props.currentVisit.id) ? <span title={en_labels.analytics_labels.measured_recently}>*</span> : ''}{this.addToTableOfContents("omicmAge", tocArray)}</h2><span className="headerId">##omicmAge</span>
                                                        {this.props.currentVisit.timePoint != constant.baseline ?
                                                            <div className='react-high-chart'>
                                                                <ReactHighcharts config={this.getBiomakersConfig(this.props.aggregateAge ? this.props.aggregateAge.omicmAge : '', this.props.graphVisitData ? this.props.graphVisitData : '', this.props.xAxisLabels ? this.props.xAxisLabels : '')} />
                                                            </div> : ''}
                                                        <p className='age_summary'>
                                                            <b>{this.ageCalculation('omicmAge')}</b>
                                                        </p>
                                                        {this.renderExplaination(this.props.biomarkerAgesData[this.props.culturedBiomarkersForPast['omicmAge']].noumenaExplaination)}
                                                        {/* {this.returnBiomarkerAgePassage(this.props.passagesData, "physioage_composite_section")} */}
                                                    </div>
                                                    : '' : ''}    
                                                {this.props.culturedBiomarkersForPast && Object.keys(this.props.culturedBiomarkersForPast).length > 0 ?
                                                    <div className='section' >
                                                        <h2 className='section-heading' id='comparing_systems'>{this.props.terms[enLabels.report_labels.compare_system]}{this.addToTableOfContents("comparing_systems", tocArray)}</h2><span className="headerId">##comparing_systems</span>
                                                        {this.returnBiomarkerAgePassage(this.props.passagesData, "comparing_your_calculated_ages_section")}
                                                        <iframe className='aggregate' src={this.props.followUpUrl} />
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                        : ''}


                                    {this.props.completeData && this.props.completeData.length > 0 ?
                                        <div className='chapter' id='health_status_indicators'>
                                            <h1 className='chapter-heading'>{this.props.terms[enLabels.report_labels.health_status_indicator]}{this.addToTableOfContents("health_status_indicators", tocArray)}</h1><span className="headerId">##health_status_indicators</span>
                                            <HealthStatusIndicators {...this.props} patientReportDownloadTemplatePast></HealthStatusIndicators> {this.addToTableOfContents("", tocArray, true, this.props)}
                                        </div> : ''}
                                    {this.props.patientReportRecommendations && this.props.patientReportRecommendations.length > 0 ? <div id="patient_treatment_plan">
                                        <h1 className='chapter-heading'>{this.props.terms['Treatment Plan']}{this.addToTableOfContents("patient_treatment_plan", tocArray)}</h1><span className="headerId">##patient_treatment_plan</span>
                                        {this.props.patientReportCurrentRecommendations && this.props.patientReportCurrentRecommendations.length > 0 ? this.renderRecommendations() : null}
                                        {this.props.patientReportStoppedRecommendations && this.props.patientReportStoppedRecommendations.length > 0 ? this.renderStoppedRecommendations() : null}
                                    </div> : null}
                                    {this.props.patientReportRecommendations && this.props.patientReportRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ? 
                                        <div id="patient_medications">
                                            <h1 className='chapter-heading'>Medications{this.addToTableOfContents("patient_medications", tocArray)}</h1><span className="headerId">##patient_medications</span>
                                            {this.props.patientReportCurrentRecommendations && this.props.patientReportCurrentRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ? this.renderMedications() : null}
                                            {this.props.patientReportStoppedRecommendations && this.props.patientReportStoppedRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ? this.renderStoppedMedications() : null}
                                        </div> 
                                    : null}
                                    {this.props.patientReportProblems && this.props.patientReportProblems.length > 0 ?
                                        <div id="patient_problems">
                                            <h1 className='chapter-heading'>Problems List{this.addToTableOfContents("patient_problems", tocArray)}</h1><span className="headerId">##patient_problems</span>
                                            {this.props.patientReportProblems && this.props.patientReportProblems.length > 0 ? this.renderProblems() : null}
                                        </div> 
                                    : null}
                                    {((this.props.exceptionalData && this.props.exceptionalData.length > 0)
                                        || (this.props.abnormalData && this.props.abnormalData.length > 0))
                                        ? <div id='exceptional_results_section'>

                                            {this.props.exceptionalData && this.props.exceptionalData.length > 0 ?
                                                <div id='exceptional_results'>
                                                    <p className='break-page-before'></p>
                                                    <div className='abnormalResult'><h1>{this.props.terms[enLabels.report_labels.exceptional_results]}{this.addToTableOfContents("exceptional_results", tocArray)}</h1><span className="headerId">##exceptional_results</span>
                                                        <table><thead className='absolute-result-header'><tr>{this.getAbnormalHeaders(false, this.props.currentVisit.timePoint == constant.baseline).map(a => <th>{a}</th>)}</tr></thead></table>
                                                        {this.props.categories ? this.props.categories.map(
                                                            s => s.sections.map(
                                                                t => (
                                                                    t.data.filter(u => u.points >= 3.7).length ?
                                                                        <div className='patients-report-preview-table'>
                                                                            <div className='complete-col-span'>
                                                                                <div className='categoriesPhysio'><h3>{t.name}</h3></div>
                                                                                <ResultsData
                                                                                    baselineVisitId={this.props.currentVisit.baselineVisitId}
                                                                                    showGrade={false}
                                                                                    isBaseline={this.props.currentVisit.timePoint == constant.baseline}
                                                                                    data={t ? t.data.filter(s => s.points >= 3.7) : ''} />
                                                                                {/* <BootstrapTable
                                                                        remote
                                                                        striped
                                                                        bordered={false}
                                                                        keyField='id'
                                                                        data={t ? t.data.filter(s => s.points >= 3.7 && s.visitId == this.props.currentVisit.id) : ''}
                                                                        columns={columns}
                                                                    /> */}
                                                                            </div>
                                                                        </div> : ''))) : null}
                                                    </div>
                                                </div> : ''}
                                            {this.props.abnormalData && this.props.abnormalData.length > 0 ?
                                                <div id='abnormal_results'>
                                                    {/* <div className='abnormalResult'><h1>{this.props.terms[enLabels.report_labels.abnormal_results]}</h1> */}
                                                    <div className='abnormalResult'><h1>{this.props.terms['results_of_concern']}{this.addToTableOfContents("abnormal_results", tocArray)}</h1><span className="headerId">##abnormal_results</span>
                                                        <table><thead className='absolute-result-header'><tr>{this.getAbnormalHeaders(false, this.props.currentVisit.timePoint == constant.baseline).map(a => <th>{a}</th>)}</tr></thead></table>
                                                        {this.props.categories ? this.props.categories.map(
                                                            s => s.sections.map(
                                                                p => (
                                                                    <div className='patients-report-preview-table'>
                                                                        {p.data.filter(s => ((s.points != null && s.points <= 1.3) || (s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s)))).length > 0 ?
                                                                            <React.Fragment>
                                                                                <div className='complete-col-span'>
                                                                                    <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                                                    <ResultsData
                                                                                        baselineVisitId={this.props.currentVisit.baselineVisitId}
                                                                                        showGrade={false}
                                                                                        isBaseline={this.props.currentVisit.timePoint == constant.baseline}
                                                                                        data={p ? p.data.filter(s => ((s.points != null && s.points <= 1.3) || (s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s)))) : ''} />
                                                                                    {/* <BootstrapTable
                                                                            remote
                                                                            striped
                                                                            bordered={false}
                                                                            keyField='id'
                                                                            data={p ? p.data.filter(s => (s.points < 2 || s.points === null) && s.visitId == this.props.currentVisit.id) : ''}
                                                                            columns={columns}
                                                                        /> */}
                                                                                </div></React.Fragment> : null}
                                                                    </div>))) : null}
                                                    </div>
                                                </div> : ''}
                                        </div> : ''}
                                    {this.props.completeData && this.props.completeData.length > 0 ?
                                        <div id='complete_data'>
                                            <p className='break-page-before'></p>
                                            <div className='completeData'><h1>{this.props.terms[enLabels.report_labels.complete_data]}{this.addToTableOfContents("complete_data", tocArray)}</h1><span className="headerId">##complete_data</span>
                                                <table><thead className='absolute-result-header'><tr>{this.getAbnormalHeaders(true, this.props.currentVisit.timePoint == constant.baseline).map(a => <th>{a}</th>)}</tr></thead></table>
                                                {this.props.categories ? this.props.categories.map(
                                                    s => s.sections.map(
                                                        p => (p.data.filter(q => q).length > 0 ?
                                                            <>
                                                                <div className='patients-report-preview-table complete-col-span'>
                                                                    <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                                    {p.data.filter(q => q.valueType == "ST" && q.isTextArea == 1).length > 0 ?
                                                                        p.data.filter(q => q.valueType == "ST" && q.isTextArea == 1).map(noumenon => {
                                                                            return (
                                                                                <div className="pt-2 pb-2 text-color">
                                                                                    <div>
                                                                                        <div className='fw-10000 mb-2 h5 pb-2'>{noumenon.label}</div>
                                                                                    </div>
                                                                                    <div className='pb-2 noumenon-value-card'>
                                                                                        <div className='p-4 bb'>
                                                                                            <div className='fw-10000 mb-2'>{this.props.terms[en_labels.report_labels.result_label]}</div>
                                                                                            <div className='ml-3'>{noumenon.value}</div>
                                                                                        </div>
                                                                                        <div className='pt-2 p-4'>
                                                                                            <div className='fw-10000 mb-2'>{this.props.terms['Baseline']}</div>
                                                                                            <div className='ml-3'>{noumenon.historicData && noumenon.historicData.length &&
                                                                                                noumenon.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId).length > 0 ?
                                                                                                _.head(noumenon.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId)).value : ''}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>)
                                                                        })
                                                                        : ""}
                                                                    {p.data.filter(s => s.valueType != "ST" && s.isTextArea != 1).length > 0 ?
                                                                        <ResultsData
                                                                            baselineVisitId={this.props.currentVisit.baselineVisitId}
                                                                            showGrade={true}
                                                                            isBaseline={this.props.currentVisit.timePoint == constant.baseline}
                                                                            data={p.data.filter(s => s.valueType != "ST" && s.isTextArea != 1)} />
                                                                        : ""}
                                                                </div>
                                                            </> : '')
                                                    )) : null}
                                            </div>
                                        </div> : ''}
                                    {this.props.categories && this.props.categories.length && this.props.currentVisit.timePoint != constant.baseline ?
                                        <div id='historic_data'>
                                            <p className='break-page-before'></p>
                                            <div className='hdataPhysician d-inline-block'>
                                                <div>
                                                    <h1>{this.props.terms['Historical Data']}{this.addToTableOfContents("historic_data", tocArray)}</h1><span className="headerId">##historic_data</span>
                                                </div>
                                                <div>
                                                    {this.getNoumenonValueData()}
                                                    {this.getHistoricData()}
                                                </div>

                                            </div>
                                        </div> : ''}

                                    {this.props.passagesData && this.props.passagesData.length ? <div className='chapter' id='disclaimer'>
                                        <h1 className='chapter-heading'>{localeLabels.disclaimer}{this.addToTableOfContents("disclaimer", tocArray, false, this.props)}</h1><span className="headerId">##disclaimer</span>
                                        <div className='passage'>
                                            <div className='passage-heading'>{localeLabels.disclaimer_heading}</div>
                                            {this.returnBiomarkerAgePassage(this.props.passagesData, "disclaimer_section")}
                                        </div>
                                    </div> : null}
                                </div></div> : null
                    }
                    </div >
                    : '')
        }
        else {
            return null;
        }
    }
}
export default PatientReportDownloadTemplatePast
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { parse } from 'qs';
import _ from 'lodash';
import labels from '../../../config/localization';
import './oddities-styles.scss';
import LoadingTemplate from '../../../components/LoadingTemplate';

class ObservationsList extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        if (this.urlParams && (!this.props.observationsList || !this.props.observationsList.length)) {
            this.props.searchObservation(this.urlParams.identifier, this.urlParams.limit)
        }
        this.props.setPageTitle(null)
        this.props.setBreadCrumbs([])
        document.title = `${labels.physioage_label}: ${labels.oddities_labels.sample_observations}`
    }
    render() {
        const ObsColumns = [
            {
                text: labels.oddities_labels.visit, headerStyle: { width: '25%' },
                formatter: (cellContent, row) => (<a onClick={() => this.props.history.push(`/visits/${row.visitId}`)} >{row.visitName} for {row.lastName},{row.firstName}</a>)
            },
            {
                dataField: 'valueType', headerStyle: { width: '10%' },
                text: labels.oddities_labels.valueType
            },
            {
                dataField: 'identifier', headerStyle: { width: '40%' },
                text: labels.oddities_labels.identifier,
                formatter: (cellContent, row) => (row.identifier ? <span className={`${row.identifier.indexOf(' ') != -1 ? 'break-word' : ' break-all'}`}>{row.identifier}</span> : '')
            },
            {
                dataField: 'value', headerStyle: { width: '10%' },
                text: labels.oddities_labels.value
            },
            {
                dataField: 'units', headerStyle: { width: '8%', wordBreak: 'break-all' },
                text: labels.oddities_labels.units
            },
            {
                dataField: 'id', headerStyle: { width: '5%' },
                text: '',
                formatter: (cellContent, row) => (<a onClick={() => this.props.history.push(`/observations/${row.id}`)} >{labels.oddities_labels.obx}</a>)
            },
            {
                dataField: 'resultId', headerStyle: { width: '5%' },
                text: '',
                formatter: (cellContent, row) => (<a onClick={() => this.props.history.push(`/visits/${row.visitId}/results/${row.resultId}`)} >{labels.oddities_labels.result_label}</a>)
            }
        ]
        return (
            this.props.isLoading ? <LoadingTemplate haveSidebar={false} isGrid={true} noSidedata={true} /> :
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 observation-list-section'>
                    <h1>{labels.oddities_labels.sample_observations}</h1>
                    {this.props.observationsList && this.props.observationsList.length ? <div className='observations-list'>
                        <h2><strong>{this.props.observationsCount} {labels.oddities_labels.observations_info_1}</strong> {labels.oddities_labels.observations_info_2} {_.head(this.props.observationsList).identifier}</h2>
                        <div className='observations-list-container'>
                            <div className='bootstrap-table-container'>
                                <BootstrapTable
                                    bordered={false}
                                    bootstrap4
                                    keyField='id'
                                    data={this.props.observationsList}
                                    columns={ObsColumns}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div> : this.props.observationsList && this.props.observationsCount == 0 && this.props.no_obs_data ?
                            <h2><strong>{this.props.observationsCount} {labels.oddities_labels.observations_info_1}</strong>
                                {labels.oddities_labels.observations_info_2} {this.urlParams && this.urlParams.query
                                    ? this.urlParams.query.identifier : ''}</h2>
                            : null}
                </div>
        )
    }
}

export default ObservationsList;
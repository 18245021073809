import React, { Component } from 'react';
import SpreadingRoutes from '../../../utils/SpreadingRoutes';
import PageTitle from '../../../components/PageTitle';

class Users extends Component {
    componentWillUnmount() {
        this.props.toggleNotification(false)
    }

    render() {
        return (
            <div className={`users-component row parent-component${this.props.isParentContainer ? ' container' : ''}`}>
                <SpreadingRoutes {...this.props} />
               
            </div>
        )
    }
}

export default Users;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import _ from 'lodash';
import { parse } from 'qs';
import Grade from '../../Analytics/components/Grade'
import constant from '../../../config/constants';
import { CalculateChange } from '../../Analytics/tools/calendar'
import { getLocalizedDate } from '../../../utils/reports-date-localization';
import constants from '../../../config/constants';
import getHistoricalChartUrl from '../../../utils/historical-chart-util';
import { getRoundedValue, IsInRange } from '../../Analytics/tools/helpers';
import enLabels from '../../../config/en_labels';
import ReactHighcharts from 'react-highcharts';


class PhysiciansReport extends Component {
    componentWillMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        this.locale = this.urlParams.locale ? this.urlParams.locale : constant.language_codes.ENGLISH
        if (Object.values(constant.language_codes).indexOf(this.locale) == -1)
            this.locale = constant.language_codes.ENGLISH
    }
    componentDidMount() {

        if (this.props.match.params && this.props.match.params.visitId && !this.props.currentVisit.id) {
            this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history, false, this.locale, false, false, "physician")
            // this.props.getAllCategories(this.props.match.params.visitId, false, "physician", this.locale, this.props.history)
        }
        window.scrollTo(0, 0)
    }
    // generateArtisanURL() {
    //     let data = this.props.dataForArtisanURL ? this.props.dataForArtisanURL : [];
    //     let followUpUrl = constants.biographical_labels.artisan_route
    //     let aggregateAge = {};
    //     data.map(age => {
    //         // if (aggregateAge[age.label] && age.value != "0" && age.timePoint == this.props.currentVisit.timePoint) {
    //         //     aggregateAge[age.label].push(`${age.value}`)
    //         // }
    //         // else {
    //         //     if(age.value != "0" && age.timePoint == this.props.currentVisit.timePoint){
    //         //         aggregateAge[age.label] = []
    //         //         aggregateAge[age.label].push(`${age.value}`)
    //         //     }
    //         // }
    //         if (age.value != "0" && age.value != 0 && age.value != "" && age.timePoint == this.props.currentVisit.timePoint) {
    //             aggregateAge[age.label] = []
    //             aggregateAge[age.label].push(`${age.value}`)
    //         }
    //     })
    //     let final = []
    //     let age = []
    //     let groupByTimePoint = _.groupBy(data, 'timePoint')
    //     age = Math.floor(_.head(groupByTimePoint[this.props.currentVisit.timePoint]).age);
    //     Object.keys(aggregateAge).map(agg => {
    //         // agg != constants.epigenAge && agg != constants.glycanAge && 
    //         if (aggregateAge[agg].filter(a => a).length) {
    //             final.push(`n:${constants.biographical_labels.ageLabels[agg]}^v:${aggregateAge[agg].join()}^b:${age}`)
    //         }
    //     })
    //     followUpUrl = `${followUpUrl}?data=${final.join('|')}`;
    //     return followUpUrl;

    // }
    //  details = [
    //     {
    //         dataField: 'firstname'
    //     },
    //     {
    //         dataField: 'patientId'
    //     },
    //     {
    //         dataField: 'date'
    //     },
    //     {
    //         dataField: 'timePoint'
    //     }
    // ]
    getHistoricData() {
        if (this.props.categories) {
            var final = []
            var allData = []
            var allVisits = []

            this.props.categories.map(t => t.sections.map(s => { return { [s.name]: s.data } }).map(f => { final.push(f) }))
            if (final && final.length)
                final.map(f => f[_.head(Object.keys(f))].map(k => _.sortBy(k.historicData, (h) => new Date(h)).map(hd => {
                    allVisits.push(hd.visitDate);
                    allData.push(hd);
                })))
            allVisits = _.sortBy(_.uniq(allVisits), v => new Date(v))
            allData = _.groupBy(allData, 'label')
            // console.log(final)
            // console.log(allVisits)
            // console.log(allData)
            var finalPanelObs = final.map(f => {
                let fkey = _.head(Object.keys(f))
                return { [fkey]: f[fkey].map(l => l.label) }
            })
            if (allVisits && allVisits.length <= 10) {
                return <div className='patients-report-preview-table'>
                    <div className='bootstrap-table-container'>
                        <table className='table table-striped table-hover'>
                            <thead className='historic-data-visits-title'>
                                <th>{this.props.terms['Visit Date']}</th>
                                {allVisits.map(av => <th className='dates'>{moment(av).format('DD/MM')}<br></br>{moment(av).format('YYYY')}</th>)}
                            </thead>
                            <tbody>
                                {finalPanelObs.map(fpo => {
                                    var fkey = _.head(Object.keys(fpo))
                                    return (
                                        <>
                                            <div className='text-center historic-panel-header'>
                                                <b>{fkey}</b>
                                            </div>
                                            {fpo[fkey].map(obs => <tr>
                                                <td>{obs}</td>
                                                {allVisits.map(currentVisit => {
                                                    let availableVal = _.head(allData[obs].filter(indiObs => indiObs.visitDate == currentVisit))
                                                    if (availableVal) {
                                                        return <td>{availableVal.value}</td>
                                                    } else {
                                                        return <td></td>
                                                    }
                                                })}
                                            </tr>)}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table >
                    </div>
                </div>
            }
            else {
                let baselineDate = _.head(allVisits)
                let recentVisit = _.last(allVisits)
                return <div className='patients-report-preview-table'>
                    <div className='bootstrap-table-container'>
                        <table className='table table-striped table-hover'>
                            <thead className='historic-data-visits-title'>
                                <th>{this.props.terms['Visit Date']}</th>
                                <th>{this.props.terms['Baseline']}</th>
                                <th>{this.props.terms['most recent visit']}</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {finalPanelObs.map(fpo => {
                                    var fkey = _.head(Object.keys(fpo))
                                    return (
                                        <>
                                            <div className='text-center historic-panel-header'>
                                                <b>{fkey}</b>
                                            </div>
                                            {fpo[fkey].map(obs => {
                                                let headValue = _.head(allData[obs].filter(indiObs => indiObs.visitDate == baselineDate))
                                                let tailValue = _.head(allData[obs].filter(indiObs => indiObs.visitDate == recentVisit))
                                                let valueType = _.head(allData[obs]).valueType
                                                return <tr>
                                                    <td>{obs}</td>
                                                    <td>{headValue ? headValue.value : ''}</td>
                                                    <td>{tailValue ? tailValue.value : ''}</td>
                                                    <td>{valueType != constants.text_value_type ? this.getChartSource(allData, obs, allVisits) : ''}</td>
                                                </tr>
                                            })}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table >
                    </div>
                </div>
            }

        }
    }

    getChartSource(allNumericalData, obs, allVistsDates) {
        let values = []
        allVistsDates.map(avd => {
            let preVal = allNumericalData[obs].filter(and => and.visitDate == avd)
            if (preVal && preVal.length) {
                var val = _.head(preVal).value
                if (!isNaN(parseInt(val)))
                    values.push(val)
                else
                    values.push('0')
            } else {
                values.push('0')
            }
        })
        if ((parseFloat(_.min(values)) == 0 && parseFloat(_.max(values)) == 0) || (values.filter(v => v != '0').length <= 2)) {
            return ''
        } else {
            values = values ? values.filter(v => parseFloat(v)) : []
            values = values.map(v => parseFloat(v))
            values = values.filter(v => v != '0')
            let config = {
                chart: {
                    type: 'column',
                    height: 60,
                    width: 200,
                    backgroundColor: 'transparent'
                },
                title: {
                    text: '',
                },
                legend: {
                    enabled: false
                },
                    xAxis: {
                    lineWidth: 1,
                    tickLength: 0, 
                    lineColor: '#667788',
                    gridLineWidth: 0,
                    labels: {
                        enabled: false
                    }
                    },
                    yAxis: {
                    title: {
                        text: ''
                    },
                    lineWidth: 1.5, 
                    gridLineWidth: 0,
                    lineColor: '#667788'
                    },
                series: [{
                    color: '#667788',
                    data: values,
                }],
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<span>{point.y}</span>' // Show only value
                    },
            };
            return <ReactHighcharts config={config} />
            // return <img src={getHistoricalChartUrl(values, allNumericalData)} />
        }
    }
    render() {
        if (this.props.terms) {
            const columns = [
                {
                    dataField: 'points',
                    formatter: (cellContent, row) => (row.points || row.points === 0 ? <span><Grade points={row.points} /></span> : ""),
                    text: this.props.terms['Grade']
                },
                {
                    dataField: 'label',
                    text: this.props.terms['Name']
                },
                {
                    dataField: 'value', text: this.props.terms['Result'],
                    formatter: (cellContent, row) => {
                        if (row.points >= 3.7) {
                            return <span className='success'>{(row.valueType == "NM" || row.valueType == "Numeric") ? getRoundedValue(row.value, row.scale) : row.value}</span>
                        }
                        else if ((row.valueType == "TX" && (row.referenceRange == null || (row.referenceRange != null && row.referenceRange.replace(/\s/g, "").split(",").includes(row.value.replace(/\s/g, "")))))) {
                            return <span>{row.value}</span>
                        }
                        else if ((row.valueType == "TX" && row.referenceRange && row.referenceRange != null && !(row.referenceRange.replace(/\s/g, "").split(",").includes(row.value.replace(/\s/g, ""))))) {
                            return <span className='danger' >{row.value}</span>
                        }
                        else if ((row.points != null && row.points <= 1.3) || (row.referenceRange && row.referenceRange != null && !IsInRange(row.referenceRange, row.value, row))) {
                            return <span className='danger'>{(row.value && row.valueType == constants.numerical_value_type &&
                                !((_.startsWith(row.value, '>') || (_.startsWith(row.value, '<')))))
                                ? getRoundedValue(row.value, row.scale) : row.value}</span>
                        }
                        else {
                            return <span>{(row.valueType == "NM" || row.valueType == "Numeric") ? getRoundedValue(row.value, row.scale) : row.value}</span>
                        }
                    }
                },
                { dataField: 'units', text: this.props.terms['Units'] },
                {
                    dataField: 'referenceRange',
                    //text: this.props.terms['Normal Range']
                    text: "Lab Ref Range"
                },
                { dataField: 'optimalRange', text: this.props.terms['Optimal Range'] },
                {
                    dataField: 'value',
                    formatter: (cellContent, row) => (<span>{row.historicData && row.historicData.length &&
                        row.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId).length > 0 ?
                        _.head(row.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId)).value : ''}</span>),
                    text: this.props.terms['Baseline'],
                    hidden: this.props.currentVisit && this.props.currentVisit.timePoint == constant.baseline ? true : false
                },
                {
                    dataField: 'Change',
                    formatter: (cellContent, row) => (<span>{CalculateChange(row, this.props.currentVisit.baselineVisitId)}</span>),
                    text: this.props.terms['Change'],
                    hidden: this.props.currentVisit.timePoint == constant.baseline ? true : false
                }
                ,
                {
                    dataField: 'sourceIndex',
                    formatter: (cellContent, row) => {
                        var arr = [];
                        for (var i = row.sourceIndex + 65; i <= 90; i++) {
                            return arr[i - 65] = String.fromCharCode(i);
                        }
                    },
                    text: 'Source',
                    hidden: false
                }
            ]
            const sourceColumns = [
                {
                    dataField: 'id',
                    formatter: (cellContent, row, index) => {
                        var arr = [];
                        for (var i = index + 65; i <= 300; i++) {
                            if (i <= 90) {
                                return arr[i - 65] = String.fromCharCode(i);
                            }
                            return arr[i - 65] = String.fromCharCode(i - 26);
                        }
                    },
                    text: labels.report_labels.flag
                },
                {
                    dataField: 'panelName',
                    formatter: (cellContent, row) => (<span>{row.panelName}</span>),
                    text: labels.corrections_labels.description
                },
                {
                    dataField: 'resultsReceived', text: labels.report_labels.first_received, formatter: (cellContent, row) => (
                        <span>{row.resultsReceived && !isNaN(new Date(row.resultsReceived).getDate()) ? moment(row.resultsReceived).format("YYYY") != "0001" ? moment(row.resultsReceived).format('L') : "See Archives" : ""}</span>)
                },
                {
                    dataField: 'lastResultsReceived', text: labels.report_labels.last_received,
                    formatter: (cellContent, row) => (
                        <span>{row.lastResultsReceived && !isNaN(new Date(row.lastResultsReceived).getDate()) ? moment(row.lastResultsReceived).format("YYYY") != "0001" ? moment(row.lastResultsReceived).format('L') : "See Archives" : ""}</span>)
                },
            ]
            const historicColumns = [

                {
                    dataField: 'label',
                    text: this.props.terms['Name']
                },
                {
                    dataField: 'value',
                    text: this.props.terms['Result'],
                    formatter: (cellContent, row) => {
                        // let res = (row.historicData.map((hd, index) => {
                        //     if (index <= this.props.currentTimepoint) {
                        //         return hd.value
                        //     }
                        // }))
                        return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => {
                            return <span className='spanForAlignment'>{s.value}</span>
                        }) : ''}</span>
                    }
                },
                {
                    dataField: 'visitDate',
                    text: this.props.terms['Visit Date'],
                    formatter: (cellContent, row, index) => {
                        // let res = (row.historicData.map((hd, index) => {
                        //     if (index <= this.props.currentTimepoint) {
                        //         return hd.visitDate
                        //     }
                        // }))
                        return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => { return !isNaN(new Date(s.visitDate).getDate()) ? <span className='spanForAlignment'>{moment(s.visitDate).format('L')}</span> : ' ' }) : ''}</span>
                    }
                }
            ]
            return (
                this.props.terms && Object.keys(this.props.terms).length > 0 && this.props.currentVisit && Object.keys(this.props.currentVisit).length > 0 ?
                    <div id='physician_report'>
                        <div><h1>{this.props.terms[`Physician's Report`]}</h1></div>
                        <div><h3>{this.props.terms['Name']} : {this.props.currentVisit.firstname} {this.props.currentVisit.lastname}</h3></div>
                        <div><h3>{labels.oddities_labels.identifier} : {this.props.currentVisit.patientId}</h3></div>
                        <div><h3>{this.props.terms['Visit Date']} : {this.props.currentVisit.date
                            ? getLocalizedDate(this.props.currentVisit.date, this.locale, this.props.currentVisit.datePreference)
                            : labels.not_available}</h3></div>
                        <div><h3>{this.props.terms['Timepoint']} : {this.props.currentVisit.timePoint.toLowerCase() != 'baseline' ? this.props.currentVisit.timePoint.replace('Follow-Up', this.props.terms['Follow-Up']) : this.props.terms['Baseline']}</h3></div>
                        <div className='artisen-physician'><iframe className='aggregate' src={this.props.currentFollowUpUrl}></iframe></div>
                        {this.props.exceptionalData && this.props.exceptionalData.length > 0 ?
                            <div id='exceptional_results'>
                                <div className='abnormalResult'><h1>{this.props.terms['Exceptional Results']}</h1>
                                    {this.props.categories.map(
                                        s => s.sections.map(
                                            t => (
                                                t.data.filter(u => u.points >= 3.7 && u.visitId == this.props.currentVisit.id).length ?
                                                    <div className='patients-report-preview-table'>
                                                        <React.Fragment>
                                                            <div className='categoriesPhysio'><h3>{t.name}</h3></div>
                                                            <div className='bootstrap-table-container'>
                                                                <BootstrapTable
                                                                    remote
                                                                    striped={false}
                                                                    bordered={false}
                                                                    hover={true}
                                                                    bootstrap4
                                                                    keyField='id'
                                                                    data={t ? t.data.filter(s => s.points >= 3.7 && s.visitId == this.props.currentVisit.id) : ''}
                                                                    columns={columns}
                                                                />
                                                            </div>
                                                        </React.Fragment>
                                                    </div> : '')))}
                                </div>
                            </div> : ''}
                        {this.props.categories && this.props.abnormalData.length > 0 ?
                            <div className='abnormalResult'>
                                {/* <h1>{this.props.terms['Abnormal Results']}</h1> */}
                                <h1>{this.props.terms['results_of_concern']}</h1>
                                {this.props.categories.map(
                                    s => s.sections.map(
                                        p => (
                                            <>
                                                {p.data.filter(s => ((s.points != null && s.points <= 1.3) || (s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s))) && s.visitId == this.props.currentVisit.id).length > 0 ?
                                                    <div className='patients-report-preview-table'>
                                                        <React.Fragment>
                                                            <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                            <BootstrapTable
                                                                remote
                                                                striped={false}
                                                                bordered={false}
                                                                hover={true}
                                                                bootstrap4
                                                                keyField='id'
                                                                data={p ? p.data.filter(s => ((s.points != null && s.points <= 1.3) || (s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s))) && s.visitId == this.props.currentVisit.id) : ''}
                                                                columns={columns}
                                                            /></React.Fragment></div> : null}
                                            </>)))}
                            </div> : ''}
                        {this.props.categories && this.props.completeData.length > 0 ?
                            <div className='completeData'><h1>{this.props.terms['Complete Data']}</h1>
                                {this.props.categories.map(
                                    s => s.sections.map(
                                        p => (p.data.filter(s => s.visitId == this.props.currentVisit.id).length > 0 ?
                                           <>
                                            <div className='patients-report-preview-table'>
                                                <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                { p.data.filter(q => q.visitId == this.props.currentVisit.id && q.valueType == "ST" && q.isTextArea == 1).length > 0 ?
                                                    p.data.filter(q => q.visitId == this.props.currentVisit.id && q.valueType == "ST" && q.isTextArea == 1).map(noumenon => {
                                                        return (
                                                        <div className="pt-2 pb-2">
                                                            <div>
                                                                <div className='fw-10000 mb-2 h5 pb-2'>{noumenon.label}</div>
                                                            </div>
                                                            <div className='pb-2 noumenon-value-card'>
                                                                <div className='p-4 bb'>
                                                                    <div className='fw-10000 mb-2'>{this.props.terms[enLabels.report_labels.result_label]}</div>
                                                                    <div className='ml-3'>{noumenon.value}</div>
                                                                </div>
                                                                <div className='pt-2 p-4'>
                                                                    <div className='fw-10000 mb-2'>{this.props.terms['Baseline']}</div>
                                                                    <div className='ml-3'>{noumenon.historicData && noumenon.historicData.length &&
                                                                        noumenon.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId).length > 0 ?
                                                                        _.head(noumenon.historicData.filter(s => s.visitId == this.props.currentVisit.baselineVisitId)).value : ''}</div>
                                                                </div>
                                                            </div>
                                                        </div> )
                                                    })
                                                : ""}
                                                { p.data.filter(s => s.visitId == this.props.currentVisit.id && s.valueType != "ST" && s.isTextArea != 1).length > 0 ?
                                                    <BootstrapTable
                                                        remote
                                                        striped={false}
                                                        bordered={false}
                                                        hover={true}
                                                        bootstrap4
                                                        keyField='id'
                                                        data={p.data.filter(s => s.visitId == this.props.currentVisit.id && s.valueType != "ST" && s.isTextArea != 1)}
                                                        columns={columns}
                                                    />
                                                : "" }
                                            </div>
                                            </>
                                        : '')
                                    ))}
                            </div> : ''}
                        {/* {this.props.categories && this.props.currentVisit.timePoint != constant.baseline ?
                            <div id='historic_data'>
                                <div className='hdataPhysician'><h1>{this.props.terms['Historical Data']}</h1>
                                    {this.getHistoricData()}
                                </div>
                            </div>
                            : ''} */}
                        {this.props.ordersList.length > 0 ?
                            <div className='sourcesPhysician'>
                                <h1>{labels.oddities_labels.source_label}s</h1>
                                <div className='patients-report-preview-table'>
                                    <BootstrapTable
                                        remote
                                        striped={false}
                                        bordered={false}
                                        hover={true}
                                        bootstrap4
                                        keyField='id'
                                        data={this.props.ordersList ? this.props.ordersList : []}
                                        columns={sourceColumns}
                                    />
                                </div>
                            </div> : ''}
                    </div> : ""
            )
        }
        else {
            return null;
        }
    }
}
export default PhysiciansReport
import React from 'react';

export default class DrawerWarningComponent extends React.Component {
    render() {
        return (
            <>
                <div className='drawer-warning-overlay'></div>
                <div className='drawer-warning'>
                    <h5>{!this.props.showPlanModal ? "Close the drawer?" : "Close the form?"}</h5>
                    <hr />
                    <p>The Recommendation form has been modified. Closing it will discard your changes. Do you want to continue?</p>
                    <hr />
                    <div className='d-flex justify-content-end'>
                        <button className='btn drawer-ok' onClick={() => {
                            this.props.toggleDrawerWarning(false);
                            this.props.toggleRecommendFormWarning(false);
                            if (this.props.showPlanModal)
                                this.props.togglePlanModal(false);
                            else
                                this.props.closeSideBar(this.props.history, true);
                        }}>Ok</button>
                        <button className='ml-4 btn drawer-cancel' onClick={() => { this.props.toggleDrawerWarning(false); this.props.toggleRecommendFormWarning(false); }}>Cancel</button>
                    </div>
                </div>
            </>
        )
    }
}
import { connect } from 'react-redux';
import AnalyticsBeta from '../components/AnalyticsBeta';
import { actionCreators } from '../modules/analyticsBeta';
import {
    logOut,
    getAuthStatus,
    setLoggedInUser,
    setHistory,
    autoRefreshToken,
    toggleLoading,
    toggleNotification,
    checkAccessToken,
    downloadDocument,
    toggleToaster,
    ignoreToaster,
    setModelWidth,
    sendOTP, validateOTP, 
    getQRCode,
    handleSuceessDone
} from '../../../reducers/global-reducer'
import { withRouter } from 'react-router-dom';
import { addToTableOfContents } from '../../Visits/modules/visits';

const mapStateToProps = (state) => ({
    ...state.analytics, ...state.global,
    isLoading: state.global.isLoading,
    isAuthenticated: state.global.isAuthenticated,
    loggedInUser: state.global.loggedInUser,
    patientReportDownloadTemplateSection: state.visits.patientReportDownloadTemplateSection,
    patientReportDownloadTemplatePastSection: state.visits.patientReportDownloadTemplatePastSection,
    tableOfContents: state.visits.tableOfContents,
    providerNotes: state.analytics.providerNotes ? state.analytics.providerNotes : []
})

const mapDispatchToProps = {
    ...actionCreators,
    logOut,
    getAuthStatus,
    setLoggedInUser,
    setHistory,
    autoRefreshToken,
    toggleLoading,
    toggleNotification,
    checkAccessToken,
    downloadDocument,
    toggleToaster,
    ignoreToaster,
    setModelWidth,
    addToTableOfContents,
    sendOTP, validateOTP, 
    getQRCode, handleSuceessDone
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnalyticsBeta))
import { connect } from 'react-redux';
import InterpretationsComponent from '../components/InterpretationsComponent';
import { actionCreators } from '../modules/analytics';

let mapStateToProps = (state) => ({
    ...state.analytics
})

let mapDispatchToProps = {
    ...actionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(InterpretationsComponent);
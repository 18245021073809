import moment from 'moment';
import constants from '../../../config/constants'

// import Rickshaw from 'rickshaw'

// This import is actually unused, but it is necessary to load it one way or another to
// define the correct type of plot.
// import './rubricplot'

const ages = [
  'PhysioAge',
  'CardioAge',
  'PulmoAge',
  'NeuroAge',
  'CutoAge',
  'ImmunoAge',
  'TelomerAge',
  'EpigenAge'
]

export default class ChartTools {
  constructor(historic_data, margin = 0.3) {
    this.historic_data = historic_data;
    let scale = this.historic_data[0].scale;//this.historic_data[0].scale;

    var min = this.min_value();
    var max = this.max_value();
    var span = max - min

    if (span > 4) {
      var padding = span * margin;
    } else {
      var padding = 5.0;
    }

    this.plot_min = round_at_scale(min - padding, scale);
    this.plot_max = round_at_scale(max + padding, scale);
  }

  datumValue(datum) {
    if (datum.value[0] == '<' || datum.value[0] == '>')
      return (parseFloat(datum.value.slice(1)));
    else
      return (parseFloat(datum.value));
  }

  nonBlank(datum) {
    return (datum.value || datum.value === 0);
  }

  values() {
    return (
      this.historic_data && this.historic_data.length ?
        this.historic_data.
          filter((datum) => { return (datum.value || datum.value === 0) }).
          map((datum) => {
            if (datum.value[0] == ">" || datum.value[0] == "<") {
              return (parseFloat(datum.value.slice(1)))
            }
            return (parseFloat(datum.value))
          })
        : '');
  }

  min_value() {
    return (Math.min(...this.values()));
  }

  max_value() {
    return (Math.max(...this.values()));
  }

  static rickshawCargo(datum) {
    if (datum.value[0] == '<' || datum.value[0] == '>') {
      return ({ x: (new Date(datum.visitDate).getTime() / 1000), y: round_at_scale(parseFloat(datum.value.slice(1)), datum.scale), datum: datum });
    }
    else {
      var date = new Date(moment(datum.visitDate).format("YYYY-MM-DD")).getTime()
      return ({ x: (date / 1000), y: round_at_scale(parseFloat(datum.value), datum.scale), datum: datum });
    }
  }

  static hasValue(point) {
    return ((point.x || (point.x === 0)) && (point.y || point.y === 0));
  }
}

function process_aggregate_chart_data(data) {
  // Process Date
  data.dob = new Date(data.dob)

  data.data.series = data.data.series.map((item) => {
    let visit_date = new Date(item.date)
    return Object.assign(item, {
      actual_age: visit_date.getYear() - data.dob.getYear(),
    })
  })

  return data
}

function filled_age_line_series(dob, data, title) {
  dob = new Date(dob)
  data = data.map((item) => {
    let datum = {
      value: item.value,
      units: item.units || 'years',
      visit_date: moment(item.visitDate).format(constants.dateFormats.ymd),
      points: item.points,
      code: item.code,
      displayVisitAges: item.displayVisitAges,
      label: item.label,
      explanationDTO: item.explanationDTO
    }
    return {
      x: new Date(datum.visit_date).getTime() / 1000,
      y: parseInt(item.value),
      datum: datum
    }
  })

  let series = [
    {
      color: 'blue',
      data: data,
      tension: 1,
      dob: dob,
      title: title.replace(/\s/g, ''),
      renderer: 'rubricplot_filled_with_age_line',
      isBeta: false
    }
  ]
  return series
}

function is_biomarker_of_aging(code, ages) {
  return ages.indexOf(code.toLowerCase()) != -1
}


function round_at_scale(value, scale) {
  let multiplier = (10 ** scale) || 1000;
  return (Math.round(value * multiplier) / multiplier);
}
let chartWidth = () => {
  let margin_left = 0;
  let margin_right = 0;
  let position_left = 0;

  let drawer = document.getElementById('drawer-body');
  let y_axis = drawer.querySelector('.y_axis');

  // For use later
  // if( y_axis && $(y_axis).css('display') != 'none' ) { console.log("y_axis visible")}

  switch (Breakpoint()) {
    case 'xs':
    case 'sm':
    case 'md':
    case 'xm':
      margin_left = 24;
      margin_right = 24;
      position_left = -20 + 40;
      return (drawerWidth() - margin_left - margin_right - position_left - 40)

    default:
      margin_left = 24 + 40;
      margin_right = 24;
      position_left = -20 + 40;
      return (drawerWidth() - margin_left - margin_right - position_left - 70)
  }
}
let Breakpoint = () => {
  // Based on app/assets/stylesheets/brand.scss

  if (window.innerWidth < 480)
    return ('xs')

  else if (window.innerWidth < 768)
    return ('sm')

  else if (window.innerWidth < 992)
    return ('md')

  else if (window.innerWidth < 1025)
    // Drawer goes from full-screen to partial-screen at 1025
    return ('xm') // extra medium

  else if (window.innerWidth < 1200)
    return ('lg')

  else
    return ('xl')
}

let drawerWidth = () => {
  let drawer = document.getElementById('drawer-body');

  if (drawer && drawer.offsetWidth > window.innerWidth * 0.15) {
    return (drawer.offsetWidth)
  }
  else if (drawerIsFullScreen()) {
    return (window.innerWidth)
  }
  else {
    return (window.innerWidth * 0.6)
  }
}
let drawerIsFullScreen = () => {
  return (window.innerWidth < 1025)
}

export { process_aggregate_chart_data, filled_age_line_series, is_biomarker_of_aging, chartWidth }

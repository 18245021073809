
import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import constants from '../../../config/constants'
import labels from '../../../config/localization';
import _ from 'lodash';
import moment from 'moment';
import { roundNumber, uniformString } from '../tools/helpers';
import SpriteIcon from './SpriteIcon';
import BiomarkerSparkline from './BiomarkerSparkline';
import tru from '../../../../src/tru.svg'
import tru_visitsummary from '../../../../src/tru_visitsummary.svg'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function BiomarkerAgingList(props) {
  let [defaultOpened, setDefaultOpened] = useState(false);
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const ref = useRef(null);


  useEffect(() => {
    // componentDidMount //

    // componentWillReceiveProps //
    let ad = constants.biographical_labels.historic_data_labels[props.hashDrawer]
    if (props.hashDrawer
      && document.getElementById(`biomarker-${props.hashDrawer}`)
      && props.historic_data_chart
      && typeof (props.historic_data_chart[ad]) == constants.object
      && props.historic_data_chart[ad].length > 0
      && !defaultOpened) {
      setDefaultOpened(true)
      scroller.scrollTo(`biomarker-${props.hashDrawer}`, { offset: -70 })
      document.getElementById(`biomarker-${props.hashDrawer}`).click()
    }

    return () => {
      // componentWillUnmount() //
    }
  }, [])

  useMemo(() => {
    // componentDidUpdate() //
    if (defaultOpened)
      setDefaultOpened(false)

  })

  let ageMessage = (biomarker, latestDate, ageDate) => {
    var chronological = props.age_round;
    var physiological = biomarker;
    if (moment(ageDate).format('YYYY-MM-DD') < moment(latestDate).format('YYYY-MM-DD')) {
      return (labels.analytics_labels.measured_recently);
    }
    else {
      if (!physiological)
        return (labels.analytics_labels.measured_recently)
      else {
        var diff = roundNumber((chronological - physiological), 1);

        if (diff < -2)
          return (`${Math.abs(diff)}  ${labels.analytics_labels.older_actual_age}`);
        else if (diff > 2)
          return (`${diff}  ${labels.analytics_labels.younger_actual_age}`);
        else
          return (labels.analytics_labels.average_for_age);
      }
    }
  }

  let ageClass = (physiological, latestDate, ageDate) => {
    var chronological = props.age_round;
    if (moment(ageDate).format('YYYY-MM-DD') < moment(latestDate).format('YYYY-MM-DD')) {
      return ('historic');
    }
    else {
      var diff = roundNumber((chronological - physiological), 1);
      if (diff < -2)
        return ('danger');
      else if (diff > 2)
        return (constants.notification_type.success);
      else
        return ('warning');
    }
  }

  let batchClassColor = (physiological) => {
    var chronological = props.age_round;
    var diff = roundNumber((chronological - physiological), 1);
    if (diff < -2)
        return ('risk');
    else if (diff > 2)
        return ('safe');
    else
        return ('normal');
}


  let renderHistory = (ad) => {
    var historic_data = props.historic_data_chart && props.historic_data_chart[ad] ? props.historic_data_chart[ad] : []
    let hdata = historic_data.filter(bv => bv.value);
    if (hdata.length > 0)
      return (
        props.timeBoundaries ? <BiomarkerSparkline historic_data_values={hdata} timeBoundaries={props.timeBoundaries}
        uniqueID={`boa_${props.historic_data_chart[ad] ? props.historic_data_chart[ad][0].enLabel : ''}`} theme = {props.theme}/> : ''
      );
    else
      return (null);
  }
  let batchClassBg = (physiological) => {
    var chronological = props.age_round;
    var diff = roundNumber((chronological - physiological), 1);
    if (diff < -2)
        return ('risk-bg');
    else if (diff > 2)
        return ('safe-bg');
    else
        return ('normal-bg');
}

let batchClassBr = (physiological) => {
  var chronological = props.age_round;
  var diff = roundNumber((chronological - physiological), 1);
  if (diff < -2)
      return ('risk-br');
  else if (diff > 2)
      return ('safe-br');
  else
      return ('normal-br');
}

let batchName = (physiological) => {
    var chronological = props.age_round;
    var diff = roundNumber((chronological - physiological), 1);
    if (diff < -2)
        return ('Risk');
    else if (diff > 2)
        return ('Safe');
    else
        return ('Normal');
}
  let renderBiomarker = (ad, ages) => {
    return (
      <div className={`biomarker-card ${batchClassBg(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : "")}  ${batchClassBr(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : "")}`}><span id={`biomarker-${uniformString(ages)}`} className={`biomarker  ${props.historic_data_chart[ad] ? _.head(props.historic_data_chart[ad]).enLabel : ''}  ${props.marqueeBioCount && props.marqueeBioCount == 1 && ages != 'physioage' ? 'biomarkerMarquee' : ''}`}>
        <div className='biomarker-body'>
          <div className='d-flex logo-biomarker-name'>
            <div className='icon'>
              {props.historic_data_chart[ad] && _.head(props.historic_data_chart[ad]).spriteName != 'tru' ? 
                <SpriteIcon label={props.historic_data_chart[ad] ? _.head(props.historic_data_chart[ad]).enLabel : ''}
                  sprite={props.historic_data_chart[ad] ? _.head(props.historic_data_chart[ad]).spriteName : ''} theme={props.theme}/>
                :
                <img className='trudiag-svg-logo' src={props.marqueeBioCount && props.marqueeBioCount == 1 ? tru : tru}></img>
              }
            </div>
            <div className='name' title={props.presentPatient.languagePreference != "en" ? (props.historic_data_chart[ad] ? _.head(props.historic_data_chart[ad]).enLabel : '') : ''}>{props.historic_data_chart[ad] ? _.head(props.historic_data_chart[ad]).label : ''}
            </div>
          </div>

          <div className="age pb-2">
            <div className='w-100'>{props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : ''}</div>
          </div>

          <div className={`pb-3 health-status ${props.theme == "light-theme" ? batchClassColor(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : "") : "color-white"} `}>
            <div className='w-100'>{batchName(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : "")}</div>
          </div>

          {/* <div className='average pb-2'>{ageMessage(props.historic_data_chart[ad] ? _.last(props.historic_data_chart[ad]).value : '',
              props.updated_lastVisitDate, _.last(props.historic_data_chart[ad]).visitDate)}</div> */}
  
          <div className='graph pb-3'>{renderHistory(ad)}</div>
        </div>
      </span>
      </div>)
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2
    }
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    return <ArrowCircleRightOutlinedIcon sx={{fontSize: "small"}} className='carousel-arrow right-arrow' onClick={() => onClick()} />;
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    return <ArrowCircleLeftOutlinedIcon  sx={{fontSize: "small"}} className='carousel-arrow left-arrow' onClick={() => onClick()} />;
  };
  return (
    props.historic_data_chart && props.historic_data_chart.biomarkerCount > 0 ?
      <div className='biomarker-container'>
        <div className='d-flex header-div align-items-center'>
          <div className='col'><div className='heading'>{props.terms['Biomarkers of Aging']}</div> </div>
          <div className='col-auto row m-0'>
            <div className='col-1'></div>
            <div className="col-auto d-flex">
              <div className="view-all-text" onClick={() => {props.togglePages('biomarker_of_age', props.history)}}> {labels.analytics_labels.view_all}</div>
            </div>
          </div>
        </div>
        <div className={`biomarkers-grid-view`} >
          <div className='render-biomarker'>
            <Carousel
              responsive={responsive} 
              arrows={true} 
              customRightArrow={<CustomRightArrow />}
              customLeftArrow={<CustomLeftArrow />}
              minimumTouchDrag={30}>
                  { Object.keys(props.historic_data_chart).map(ad => {
                    let ages = constants.biographical_labels.agesForDrawer[ad]
                    if (typeof (props.historic_data_chart[ad]) == constants.object && props.historic_data_chart[ad].length > 0){
                      return ( renderBiomarker(ad, ages) )
                    }
                  })}
            </Carousel>
          </div>
        </div>  
        
      </div> : ''
  )

}
export default connect()(BiomarkerAgingList);


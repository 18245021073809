import React from 'react';
import { setSitePreference } from '../tools/calendar'
import { Link } from 'react-router-dom';
import labels from '../../../config/localization';

export default class DocumentListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.renderDocumentRow = this.renderDocumentRow.bind(this);
    this.state = { older_expanded: false }
  }

  renderDocumentRow(doc) {
    const openbleFiles = ['jpg', 'png', 'jpeg']
    return (
      !this.props.isDemo ?
        <li key={doc.title} className='document-list-row'>
          {doc.format && openbleFiles.indexOf(doc.format.toLowerCase()) != -1 ?
            <Link to={`/documents/${doc.documentId}.json`} target='_blank'>
              <div className='icon'>
                <svg aria-hidden='true'>
                  <use xlinkHref="#download"></use>
                  <symbol id="download" viewBox="0 0 48 48">
                    <line data-cap="butt" fill="none" stroke-width="3" stroke-miterlimit="10" x1="24" y1="3" x2="24" y2="36" stroke-linejoin="miter" stroke-linecap="butt"></line>
                    <polyline fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" points="11,23 24,36 37,23 " stroke-linejoin="miter"></polyline>
                    <line data-color="color-2" fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" x1="2" y1="45" x2="46" y2="45" stroke-linejoin="miter"></line>
                  </symbol>
                </svg>
              </div>
            </Link> :
            <a onClick={() => this.props.downloadDocument(doc, this.props.history)}>
              <div className='icon'>
                <svg aria-hidden='true'>
                  <use xlinkHref="#download"></use>
                  <symbol id="download" viewBox="0 0 48 48">
                    <line data-cap="butt" fill="none" stroke-width="3" stroke-miterlimit="10" x1="24" y1="3" x2="24" y2="36" stroke-linejoin="miter" stroke-linecap="butt"></line>
                    <polyline fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" points="11,23 24,36 37,23 " stroke-linejoin="miter"></polyline>
                    <line data-color="color-2" fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" x1="2" y1="45" x2="46" y2="45" stroke-linejoin="miter"></line>
                  </symbol>
                </svg>
              </div>
            </a>}
          <div className='title'>{doc.title}</div>
          <div className='publication-date'>{setSitePreference(this.props.presentPatient.datePreference, doc.publicationDate)}</div>
        </li> :
        <React.Fragment>
          <li key={doc.title} className='document-list-row'>
            <a onClick={() => this.props.downloadDocument(doc, this.props.history, true)}>
              <div className='icon'>
                <svg aria-hidden='true'>
                  <use xlinkHref="#download"></use>
                  <symbol id="download" viewBox="0 0 48 48">
                    <line data-cap="butt" fill="none" stroke-width="3" stroke-miterlimit="10" x1="24" y1="3" x2="24" y2="36" stroke-linejoin="miter" stroke-linecap="butt"></line>
                    <polyline fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" points="11,23 24,36 37,23 " stroke-linejoin="miter"></polyline>
                    <line data-color="color-2" fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" x1="2" y1="45" x2="46" y2="45" stroke-linejoin="miter"></line>
                  </symbol>
                </svg>
              </div>
            </a>
            <div className='title'>{doc.title}</div>
            <div className='publication-date'>{setSitePreference(this.props.presentPatient.datePreference, doc.publicationDate)}</div></li></React.Fragment>
    )
  }

  render() {
    if (!this.props.siteData.enableDocuments) {
      return (null);
    }
    else if (!this.props.currentPatientDocumentsList) {
      return (
        <section id='no-documents' className='document-list-beta'>
          <div className='max-container'>
            <div className='card medium-width-card'>
              <div className='card-content'>
                <h2> {labels.analytics_labels.no_downloads_yet}</h2>
                <p>{labels.analytics_labels.downloads_message}</p>
              </div>
            </div>
          </div>
        </section>
      )
    }
    else {
      let currentDocs = this.props.currentPatientDocumentsList.filter(u => u.visitId == this.props.presentPatient.latestVisitId)
      let oldDocs = this.props.currentPatientDocumentsList.filter(u => u.visitId != this.props.presentPatient.latestVisitId)
      if (currentDocs.length == 0) {
        return (<section id='document-list' className='document-list-beta'>
          <div className='max-container'>
            <div className='card medium-width-card'>
              <div className='card-content'>
                {this.renderDocumentSublist({ title: this.subtitle(oldDocs), documents: oldDocs, can_toggle: false })}
              </div>
            </div>
          </div>
        </section>)
      }
      return (
        <section id='document-list' className='document-list-beta'>
          <div className='max-container'>
            <div className='card medium-width-card'>
              <div className='card-content'>
                {this.renderDocumentSublist({ title: 'Current Files', subtitle: ' ( ' + this.subtitle(currentDocs) + ' )', documents: currentDocs, can_toggle: false })}
                {oldDocs.length > 0 && this.renderDocumentSublist({ title: 'Older Files', subtitle: ' ( ' + this.subtitle(oldDocs) + ' )', documents: oldDocs, can_toggle: true })}
              </div>
            </div>
          </div>
        </section>
      )
    }
  }

  renderToggle(expanded) {
    if (expanded) {
      return (
        <svg aria-hidden='true'>
          <use xlinkHref="#slide-up"></use>
          <symbol id="slide-up" viewBox="0 0 16 6">
            <path fill="none" stroke-width="2px" d="M0,6L8,0L16,6"></path>
          </symbol>
        </svg>
      );
    }
    else {
      return (
        <svg aria-hidden='true'>
          <use xlinkHref="#slide-down"></use>
          <symbol id="slide-down" viewBox="0 0 16 6">
            <path fill="none" stroke-width="2px" d="M0,0L8,6L16,0"></path>
          </symbol>
        </svg>
      );
    }
  }

  subtitle(documents) {
    if (documents.length == 1) {
      return ('1 document')
    }
    else {
      return (documents.length.toString() + ' documents')
    }
  }

  renderDocumentSublist({ title, subtitle, documents, can_toggle }) {
    if (!documents || documents.length == 0) {
      return null
    } else if (can_toggle == false) {
      return (
        <div className='document-sublist'>
          <div className='document-sublist-heading'>
            <span className='sublist-title'>{title}</span>
            <span className='sublist-subtitle'>{subtitle}</span>
          </div>
          {documents.map(this.renderDocumentRow)}
        </div>
      )
    } else {
      let toggleExpansion = () => { this.setState({ older_expanded: !this.state.older_expanded }) };
      let expanded = this.state.older_expanded;

      return (
        <div className='document-sublist' onClick={toggleExpansion}>
          <div className='document-sublist-heading'>
            <span className='sublist-title'>{title}</span>
            <span className='sublist-subtitle'>{subtitle}</span>
            <div className='icon expander'>
              {this.renderToggle(expanded)}
            </div>
          </div>
          {expanded ? documents.map(this.renderDocumentRow) : null}
        </div>
      )
    }
  }
}

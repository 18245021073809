import React, { useEffect, useMemo } from 'react';
import labels from '../../../config/localization';
import { parse } from 'qs';
import FormControl from '../../../components/FormControls';
import constants from '../../../config/constants';
import ScatterChart from './ScatterChart';
import LoadingTemplate from '../../../components/LoadingTemplate';
import _ from 'lodash';
import FilterCompareNoumenaData from './FilterCompareNoumenaData';

const CompareNoumena = (props) => {
    useEffect(() => {
        // Set breadcrumbs
        props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.noumena_labels.noumena, path: '' },
            { text: labels.noumena_labels.compare_noumena, path: '' }
        ]);

        // Parse query string
        const queryString = props.location ? props.location.search ? props.location.search.substr(1) : '' : '';
        const urlParams = parse(queryString, true);

        // Set page title and document title
        props.setPageTitle(labels.noumena_labels.compare_noumena);
        document.title = `${labels.physioage_label}: ${labels.noumena_labels.compare_noumena}`;

        //Set default Noumenon if URL params exist
        if (urlParams && Object.keys(urlParams).length > 0) {
            props.setDefaultcompareNoumenas(1, props.history, urlParams);
        } else {
            props.setDefaultcompareNoumenas(1, props.history);
        }

        // Set units data
        props.setUnitsData();

        // Cleanup function to reset props on unmount
        return () => {
        };

    }, []);

    const isValidNoumena = useMemo(() => {
        const firstResponse = _.head(props.firstNoumenaResponse);
        const secondResponse = _.head(props.secondNoumenaResponse);
        const firstNoumenonData = firstResponse ? firstResponse.noumenonData : null;
        const secondNoumenonData = secondResponse ? secondResponse.noumenonData : null;

        return firstNoumenonData && secondNoumenonData && firstNoumenonData.length > 0 && secondNoumenonData.length > 0 &&
            _.head(firstNoumenonData).valueType === 'NM' && _.head(secondNoumenonData).valueType === 'NM';
    }, [props.firstNoumenaResponse, props.secondNoumenaResponse]);

    const getDefaultNoumenaName = (isFirstInput) => {
        let resultantNoumena = []
        let currentCompareNoumena = isFirstInput ? props.currentCompareNoumenaFilterOne : props.currentCompareNoumenaFilterTwo;
        if (props.unitsData && props.unitsData.length && currentCompareNoumena && currentCompareNoumena.noumenaName) {
            var result = props.unitsData.filter(u => u.noumenonCode == currentCompareNoumena.noumenaName)
            if (result && result.length)
                resultantNoumena.push(_.head(result))
        }
        if (!resultantNoumena.length)
            resultantNoumena.push({ name: currentCompareNoumena.noumenaName, noumenonCode: currentCompareNoumena.noumenaName })
        return resultantNoumena
    }

    const getNoumenaName = (code, props) => {
        return props.unitsData ? _.head(props.unitsData.filter(u => u.noumenonCode == code)).name : '';
    }

    return (
        props.isLoading ? <LoadingTemplate isCards={true} haveSidebar={true} /> :
            <>
                <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                    <div className='explore-noumena-container mb-2'>
                        <FormControl type='button' btnType='info' text={labels.noumena_labels.title} onClick={() => props.history.push('/data/noumena')}/>
                    </div>
                    <div className='card'>
                        <div className='card-header'>{labels.noumena_labels.filter_clouds}</div>
                        <div className='card-body'>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                props.clearCompareNoumenaNamesForFirstNoumena()
                                props.clearCompareNoumenaNamesForSecondNoumena();
                                props.compareNoumenas(props.history);
                            }}>
                                <FormControl
                                    id='name'
                                    label={labels.noumena_labels.select_noumenon_one}
                                    type='typeahead'
                                    onSearch={props.populateFirstSearchParam}
                                    defaultSelected={props.currentCompareNoumenaFilterOne && props.currentCompareNoumenaFilterOne.noumenaName
                                        ? getDefaultNoumenaName(true) : []}
                                    onKeyDown={(e) => {
                                        if (e.keyCode == constants.enter_key_code || e.keyCode == constants.escape_key_code) {
                                            props.clearCompareNoumenaNamesForFirstNoumena();
                                        }
                                    }}
                                    open={props.currentNoumenonNamesOne && props.currentNoumenonNamesOne.length ? true : false}
                                    onChange={(e) => { props.editNoumena(e, 'firstNoumenaName'); props.clearCompareNoumenaNamesForFirstNoumena() }}
                                    onInputChange={props.populateFirstSearchParam}
                                    labelKey={'name'}
                                    valueKey={'noumenonCode'}
                                    options={props.currentNoumenonNamesOne}
                                    onBlur={() => { props.clearCompareNoumenaNamesForFirstNoumena(); }}
                                />
                                {(props.noumenaForm && props.noumenaForm.firstNoumenaName && props.noumenaForm.firstNoumenaName.length == 0) && <span style={{ color: 'red' }}>Please select noumena to compare</span>}
                                <FormControl
                                    id='anotherName'
                                    label={labels.noumena_labels.select_noumenon_two}
                                    type='typeahead'
                                    onSearch={props.populateSecondSearchParam}
                                    defaultSelected={props.currentCompareNoumenaFilterTwo && props.currentCompareNoumenaFilterTwo.noumenaName
                                        ? getDefaultNoumenaName(false) : []}
                                    onKeyDown={(e) => {
                                        if (e.keyCode == constants.enter_key_code || e.keyCode == constants.escape_key_code) {
                                            props.clearCompareNoumenaNamesForSecondNoumena();
                                        }
                                    }}
                                    open={props.currentNoumenonNamesTwo && props.currentNoumenonNamesTwo.length ? true : false}
                                    onChange={(e) => { props.editNoumena(e, 'secondNoumenaName'); props.clearCompareNoumenaNamesForSecondNoumena() }}
                                    onInputChange={props.populateSecondSearchParam}
                                    labelKey={'name'}
                                    valueKey={'noumenonCode'}
                                    options={props.currentNoumenonNamesTwo}
                                    onBlur={() => { props.clearCompareNoumenaNamesForSecondNoumena(); }}
                                />
                                {(props.noumenaForm && props.noumenaForm.secondNoumenaName && props.noumenaForm.secondNoumenaName.length == 0) && <span style={{ color: 'red' }}>Please select noumena to compare</span>}
                                <FormControl
                                    id='siteId' label={labels.patient_list_labels.site_label}
                                    type='select'
                                    onChange={props.editNoumena}
                                    defaultValue={props.currentCompareNoumenaFilterOne && props.currentCompareNoumenaFilterOne.siteId
                                        ? parseInt(props.currentCompareNoumenaFilterOne.siteId) : 0}
                                    defaultOption="All"
                                    options={props.sitesList && props.loggedInUser && props.loggedInUser.siteIds ? props.loggedInUser.role == 'admin' ? props.sitesList : props.sitesList.filter(site => props.loggedInUser.siteIds.includes(site.id)) : []}
                                    textKey={'name'} valueKey={'id'} />

                                <FormControl id='gender' type='select' label={labels.noumena_labels.gender}
                                    defaultOption="All" options={constants.gender} onChange={props.editNoumena}
                                    textKey={'value'} valueKey={'value'}
                                    defaultValue={props.currentCompareNoumenaFilterOne && props.currentCompareNoumenaFilterOne.gender
                                        ? (props.currentCompareNoumenaFilterOne.gender) : null}
                                />
                                <FormControl id='MaxDeviations' type='select'
                                    defaultOption="None"
                                    label={labels.noumena_labels.max_deviations}
                                    onChange={props.editNoumena}
                                    defaultValue={props.currentCompareNoumenaFilterOne && props.currentCompareNoumenaFilterOne.MaxDeviations
                                        ? parseInt(props.currentCompareNoumenaFilterOne.MaxDeviations) : 0}
                                    options={constants.deviations} textKey={'value'} valueKey={'value'} />

                                <FormControl
                                    type='button'
                                    btnType='info'
                                    text={labels.noumena_labels.compare_noumenas}
                                    disabled={(props.noumenaForm && props.noumenaForm.firstNoumenaName && props.noumenaForm.firstNoumenaName.length == 0) || (props.noumenaForm && props.noumenaForm.secondNoumenaName && props.noumenaForm.secondNoumenaName.length == 0)}
                                />
                            </form>
                            <div className='alert alert-danger filter-help'>
                                <span><strong>{labels.noumena_labels.heads_up}</strong><p>{labels.noumena_labels.filter_help}</p></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0'>
                    {props.firstNoumenaResponse && props.secondNoumenaResponse ? isValidNoumena ?
                            <div className='compare-noumena-scatter-plot'>
                                <a onClick={() => props.downloadCSVCompareNoumena(props, props.code)} style={{ fontSize: '1em', color: '#008FBC' }}>{labels.noumena_labels.download_excel}</a>
                                <FilterCompareNoumenaData {...props}/>
                            </div> :
                            <div className='jumbotron'>
                                <h2>{labels.noumena_labels.no_values_to_plot}</h2>
                            </div> :
                        <div className='jumbotron'>
                            {props.isCompareNoumenaLoading ? <h2>{labels.visit_page_labels.waiting_for_data}<i className="fa fa-spinner fa-spin" style={{ marginLeft: '20px' }}></i></h2> :
                                !props.firstNoumenaResponse && !props.secondNoumenaResponse ? (<h2>{labels.noumena_labels.no_values_to_plot}</h2>) :
                                    (props.firstNoumenaResponse ? (<h2>{`No results for ${getNoumenaName(props.currentCompareNoumenaFilterTwo.noumenaName, props)}`}</h2>) :
                                        (props.secondNoumenaResponse ? (<h2>{`No results for ${getNoumenaName(props.currentCompareNoumenaFilterOne.noumenaName, props)}`}</h2>) : null))
                            }
                        </div>}
                </div>
            </>
    )
};

export default CompareNoumena;

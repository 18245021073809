import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import { toggleLoading, toggleNotification, setPageTitle, setBreadCrumbs, handleErrors, handleStatusCodes, handleLoading } from '../../../reducers/global-reducer';
import constants from '../../../config/constants';
import labels from '../../../config/localization';
import messages from '../../../config/messages';
import { promisesList, clearPromises } from '../../../utils/resolve-promises';
import resolveSearchFilters from '../../../utils/resolve-search-params';

const SET_PROPS = 'SET_PROPS';

function getCaregivers(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let searchParam = { ...getState().caregivers.searchParam }
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSearchFilters
            }
        })

        let page = searchParam.pageNumber ? searchParam.pageNumber : 1
        if (history)
            setSearchParamUrl(searchParam, currentSearchFilters, history)

        delete searchParam.sortField

        promisesList.push(axios.post(API_URL.USERS_SEARCH_URL, searchParam).then((response) => {
            let caregivers = response.data
            let currentNames = []
            caregivers.map(user => {
                currentNames.push(user.name)
            })
            dispatch({
                type: SET_PROPS,
                payload: {
                    caregivers: caregivers,
                    allMails: getUserEmails(caregivers),
                    no_grid_data: caregivers && !caregivers.length ? true : false,
                    defaultPage: page ? parseInt(page) : 1,
                    currentNames: currentNames
                }
            })
            dispatch(toggleLoading(false))
            dispatch(CaregiverPageChange(page ? parseInt(page) : 1 , false, history))
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}

export function clearSearch(urlparams, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortField: null,
                    sortOrder: -1,
                    searchFilters:  [
                        // {
                        //   "fieldName": "siteid",
                        //   "fieldValue": null
                        // },
                        // {
                        //   "fieldName": "name",
                        //   "fieldValue": null
                        // },
                        {
                            "fieldName": "role",
                            "fieldValue": 'caregiver'
                        },
                        {   
                            'fieldName': "isprovider",
                            'fieldValue': "0"
                        }
                      ]
                },
                currentSearchFilters: {},
                currentNames: [],
                isSearchCleared: true
            }
        })
        if (urlparams && Object.keys(urlparams).length) {
            let { siteid, name, sortOrder, sortField, page } = urlparams

            sortField = sortField ? sortField : ''
            sortOrder = sortOrder ? sortOrder : 'desc'
            if (siteid)
                dispatch(setSearchParam(siteid, 'siteid', false))

            if (name)
                dispatch(setSearchParam(name, 'name', false))
            if (page) {
                dispatch(CaregiverPageChange(parseInt(page), false))
            }
            if (sortField) {
                dispatch(caregiversSortOrder(sortOrder, sortField, history, false))
            }
        }
        dispatch(getCaregivers())
        dispatch(toggleNotification(false))
    }
}

export function caregiversSortOrder(sortOrder, sortField, history, submit) {
    return (dispatch, getState) => {
        sortOrder = constants.sorting_order[sortOrder]
        let searchParam = Object.assign({}, getState().patients.searchParam, { sortOrder, sortField })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (!submit)
            dispatch(getCaregivers(history))

    }
}

export function clearFilters(isSearch) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortOrder: -1,
                    sortField: '',
                    searchFilters: []
                },
                currentNames: [],
                isSearchCleared: true
            }
        })
        if (isSearch)
            dispatch(getCaregivers())
    }
}

export function searchCustomName(eve, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                name: eve && eve.target ? eve.target.value : '',
                currentNames: []
            }
        })
        let namesList = getState().caregivers.filteredNamesList
        let search = namesList.filter(n => (n.firstName == eve.target.value || n.lastName == eve.target.value)).length ? true : false
        dispatch(setSearchParam(eve && eve.target ? eve.target.value : '', 'name', search, history))
    }
}

function getSitesList() {
    return (dispatch, getState) => {
        console.log(getState().caregivers.sitesList)
        if (!getState().caregivers.sitesList){
            promisesList.push(axios.get(API_URL.SITES_NAMES).then((response) => {
                dispatch(handleLoading())
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        sitesList: response.data ? response.data.map(res => ({ id: res.id, name: res.name, value: null })) : []
                    }
                })
            }).catch(error => {
                dispatch(handleErrors(error))
            }))
        }    
    }
}

export function clearNames() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNames: []
            }
        })
    }
}

function editCaregiver(eve, key) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentCaregiver = getState().caregivers.currentCaregiver ? getState().caregivers.currentCaregiver : {}
        // let isDeactivated = getState().caregivers.isDeactivated ? getState().caregivers.isDeactivated : false
        let value;

        if (key != 'selectedsites'){
           value =  eve.target && eve.target.type != 'checkbox' ? eve.target.value : eve.target.checked ? 1 : 0
        }
    

        dispatch({
            type: SET_PROPS,
            payload: {
                currentCaregiver: Object.assign({}, currentCaregiver, { [!key ? eve.target.id : key]: !key ? value : eve }),
                // isDeactivated: isDeactivated
            }
        })

    }
}

function submitCaregiver(history) {
    return (dispatch, getState) => {
        let currentCaregiver = getState().caregivers.currentCaregiver ? getState().caregivers.currentCaregiver : {}
        let requiredAssignedTo = currentCaregiver.deactivate == 1 ? true : false
        if (currentCaregiver.firstName && currentCaregiver.lastName && currentCaregiver.selectedsites && currentCaregiver.selectedsites.length && (
            (!requiredAssignedTo && !currentCaregiver.assignedTo) || (requiredAssignedTo && currentCaregiver.assignedTo))) {
            if (!requiredAssignedTo) {
                delete currentCaregiver["assignedTo"]
            }
            dispatch(toggleLoading(true))
            currentCaregiver.siteIds = currentCaregiver.selectedsites.map(s => s.id)
            currentCaregiver['primarySiteId'] = currentCaregiver.primarySiteId && currentCaregiver.primarySiteId != 0 ? currentCaregiver.primarySiteId : currentCaregiver.siteIds.length > 0 ? currentCaregiver.siteIds[0] : 0
            axios[currentCaregiver.id ? 'put' : 'post'](API_URL.USERS_URL, { ...currentCaregiver }).then((response) => {
                dispatch(toggleLoading(false))
                dispatch(getCaregivers())
                if (requiredAssignedTo && currentCaregiver.assignedTo){
                    dispatch(toggleNotification(true, constants.notification_type.success, '',
                    [` ${currentCaregiver.firstNamN} ${currentCaregiver.lastname} ${labels.provider_labels.provider_deactivated_successfully}`]
                    , false))
                    history.push(`/caregivers`)
                } else {
                    history.push(`/caregivers/${currentCaregiver.id ? currentCaregiver.id : response.data}`)
                }
            }).catch((error) => {
                dispatch(handleErrors(error))

            })
        } else {
            let errorMessages = []
            if (!currentCaregiver.firstName) { errorMessages.push(`${labels.user_labels.firstname} ${labels.required_label}`) }
            if (!currentCaregiver.lastName) { errorMessages.push(`${labels.user_labels.lastname} ${labels.required_label}`) }
            if (!currentCaregiver.selectedsites || !currentCaregiver.selectedsites.length) { errorMessages.push(messages.site_select_validation) }
            if (currentCaregiver.deactivate && !currentCaregiver.assignedTo) { errorMessages.push(`${labels.user_labels.available_provider} ${labels.required_label}`) }
            window.scrollTo(0, 0)
            dispatch(toggleNotification(true, constants.notification_type.error, '', errorMessages, false))
        }
    }
}

export function navigateEditCaregiver(history, path) {
    return (dispatch) => {
        history.push(path)
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCaregiver: {}
            }
        })
    }
}
function setCaregiverDetails(providerId) {
    return (dispatch) => {
        dispatch(setCurrentCaregiver(providerId, true))
    }
}
function setCurrentCaregiver(caregiverId, details) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.USERS_URL}?id=${caregiverId}&includeProvider=true`).then(response => {
            let currentCaregiver = response.data
            let isDeactivated = currentCaregiver.deactivate ? currentCaregiver.deactivate : false   
            let sites = getState().caregivers.sitesList ? getState().caregivers.sitesList.filter(site => currentCaregiver.siteIds.includes(site.id)) : []
            sites = sites.map(site => ({...site, removable: currentCaregiver.primarySiteId && currentCaregiver.primarySiteId === site.id ? false : true } ))
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentCaregiver: { ...currentCaregiver, selectedsites: sites, assignedTo: null },
                    availableProvidersList: response.data.providersNameDTO,
                    isDeactivated: isDeactivated
                }
            })
            if (details) {
                let name = currentCaregiver
                    ? `${currentCaregiver.firstName} ${currentCaregiver.lastName}` : ''
                dispatch(setPageTitle(name))
                document.title = `${labels.physioage_label}:${name}`
                dispatch(setBreadCrumbs([
                    { text: labels.physioage_label, path: '/patients/list' },
                    { text: name, path: '' }
                ]))
            } else {
                dispatch(setPageTitle(labels.caregiver_labels.edit_caregiver))
                document.title = `${labels.physioage_label}:${labels.caregiver_labels.edit_caregiver}`
                dispatch(setBreadCrumbs([
                    { text: labels.physioage_label, path: '/patients/list' },
                    { text: labels.caregiver_labels.edit_caregiver, path: '' }
                ]))
            }
            dispatch(handleLoading())
        })
            .catch(error => {
                dispatch(toggleLoading(false))
                dispatch(handleStatusCodes(error))
                dispatch(handleErrors(error))
            }))
    }
}

function addCaregiver(history) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCaregiver: {}
            }
        })
        history.push('/caregivers/new')
    }
}
export function addSiteCaregiver(history, site) {
    return (dispatch) => {
        let selectedsites = site && site.map( s => ({...s, removable: true }))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCaregiver: { selectedsites, role: 'caregiver', active: 1, isProvider: 0, isPrimaryContact: 0  }
            }
        })
        history.push('/caregivers/new')
    }
}
function resetCaregivers() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                defaultPage: 1
            }
        })
        dispatch(getCaregivers())
    }
}
function CaregiverPageChange(pageNumber, submit, history) {
    let sizePerPage = 10
    let startIndex = (pageNumber - 1) * sizePerPage
    let endIndex = startIndex + (sizePerPage - 1)
    return (dispatch, getState) => {
        let caregivers = getState().caregivers.caregivers
        dispatch({
            type: SET_PROPS,
            payload: {
                filteredCaregivers: caregivers.filter((t, i) => i >= startIndex && i <= endIndex),
                defaultPage: parseInt(pageNumber)
            }
        })
        let searchParam = Object.assign({}, getState().caregivers.searchParam, { pageNumber })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        if(history)
            setSearchParamUrl(searchParam, currentSearchFilters, history)
    }
}

function sortCaregivers(sortField, sortOrder, history, pageNumber) {
    return (dispatch, getState) => {
        let caregivers = getState().caregivers && getState().caregivers.caregivers ? getState().caregivers.caregivers : [];
        let searchParam = getState() && getState().patients && getState().patients.searchParam ? getState().patients.searchParam : {}
        let columns = [];
        columns.push(sortField);
        if (sortField == "firstname") {
            columns = ['firstname', 'lastname'];
            sortField = [sortField];
        }
        if (sortField == "lastVisit")
            caregivers = _.orderBy(caregivers, [(obj) => new Date(obj['lastVisitDate'])], sortOrder);
        else
            caregivers = _.orderBy(caregivers, columns, sortOrder);

        let pageNumChanged = false
        if (searchParam && (!searchParam.sortField || searchParam.sortField != sortField)) {
            searchParam.sortField = sortField
            if (searchParam.sortField != sortField)
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        caregivers: []
                    }
                })
            pageNumChanged = true
            searchParam.pageNumber = 1
        }

        if (searchParam.sortOrder != constants.sorting_order[sortOrder]) {
            searchParam.sortOrder = constants.sorting_order[sortOrder]
            searchParam.pageNumber = 1
        } else if (!pageNumChanged) {
            searchParam.pageNumber = pageNumber ? parseInt(pageNumber) : 1
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                caregivers: caregivers,
                ...searchParam
            }
        })
        let page = searchParam.pageNumber ?  searchParam.pageNumber : 1
        dispatch(CaregiverPageChange(page, false, history));
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        if(history)
            setSearchParamUrl(searchParam, currentSearchFilters, history)

    }
}

function setSearchParamUrl(searchParam, currentSearchFilters, history) {
    let searchParamUrl = '', params = ''
    searchParam.pageNumber = searchParam.pageNumber ? searchParam.pageNumber : 1
    searchParam.sortField = searchParam.sortField ? searchParam.sortField : ''
    searchParam.sortOrder = searchParam.sortOrder ? searchParam.sortOrder : -1
    if (currentSearchFilters.siteid && !parseInt(currentSearchFilters.siteid))
        currentSearchFilters.siteid = ''
    let filters = Object.keys(currentSearchFilters).filter(csf => currentSearchFilters[csf]).map(csf => `${csf}=${currentSearchFilters[csf]}`)
    searchParamUrl = filters.join('&')
    let paramFilters = Object.keys(searchParam).filter(csf => searchParam[csf])
        .map(csf => {
            if (csf != 'searchFilters' && csf != 'pageSize')
                if (csf == "sortOrder") {
                    if (!searchParam[csf])
                        return `${csf}=desc`
                    else
                        return `${csf}=${constants.sorting_order_decode[searchParam.sortOrder.toString()]}`
                }
                else if (csf == 'pageNumber') {
                    return `page=${searchParam.pageNumber}`
                }
                else {
                    return `${csf}=${searchParam[csf]}`
                }
        })
    params = _.compact(paramFilters).join('&')
    if (searchParamUrl) {
        searchParamUrl = (`?${searchParamUrl}&${params}`)
    } else {
        searchParamUrl = (`?${params}`)
    }
    return history.push(searchParamUrl)
}

const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    currentCaregiver: {},
    no_grid_data: false,
    defaultPage: 1,
    filteredCaregivers: [],
    caregivers: [],
    searchParam: {
        pageSize: 10,
        pageNumber: 1,
        sortField: null,
        sortOrder: -1,
        searchFilters: []
    },
    currentNames: [],
    isDeactivated: false
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export function setSearchParam(val, key, search, history, activePageNumber) {
    return (dispatch, getState) => {
        let searchParam = Object.assign({}, getState().caregivers.searchParam)

        if ((getState().caregivers.currentSearchFilters.name == null || getState().caregivers.currentSearchFilters.name == "") && val == "" && key == "name"){
        }
        else {
            if (searchParam.searchFilters) {
                if (searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() == key.toLowerCase()).length) {
                    searchParam.searchFilters.map(sf => {
                        if (sf.fieldName == key) { sf.fieldValue = val }
                    })
                } else {
                    searchParam.searchFilters.push({
                        fieldName: key,
                        fieldValue: val
                    })
                }
            } else {
                searchParam.searchFilters = []
                searchParam.searchFilters.push({
                    fieldName: key,
                    fieldValue: val
                })
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    searchParam: !search ? Object.assign({}, searchParam, { pageNumber: activePageNumber ? activePageNumber : 1 }) :
                        searchParam
                }
            })
            if ((!search && key == 'name') || (!search && key == 'siteid') ) {
                dispatch(getCaregivers(history))
            }
        }
    }
}

export function filterCaregivers(searchFieldName) {
    return (dispatch, getState) => {
        let filteredCaregivers = [...getState().caregivers.caregivers]

        if (searchFieldName) {
            let search = searchFieldName.toLowerCase()
            filteredCaregivers = filteredCaregivers.filter(u => u.name.toLowerCase().indexOf(search) > -1)
        }
        let userEmails = getUserEmails(filteredCaregivers)
        dispatch({
            type: SET_PROPS,
            payload: {
                searchFieldName: searchFieldName.toLowerCase(),
                filteredCaregivers: filteredCaregivers ,
                allMails: userEmails
            }
        })
    }
}

function getUserEmails(users) {
    let userEmails = []
    if (users) {
        users.map(user => {
            userEmails.push(user.email)
        })
    }
    return userEmails.length ? userEmails.join('; ') : ''
}

export const actionCreators = {
    getCaregivers,
    getSitesList,
    editCaregiver,
    submitCaregiver,
    setCurrentCaregiver,
    addCaregiver,
    addSiteCaregiver,
    setCaregiverDetails,
    navigateEditCaregiver,
    resetCaregivers,
    CaregiverPageChange,
    sortCaregivers,
    clearSearch,
    caregiversSortOrder,
    setSearchParam,
    clearFilters,
    searchCustomName,
    clearNames,
    filterCaregivers,
    getUserEmails
}

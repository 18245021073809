import React, { Component } from 'react'
import moment from 'moment';
import labels from '../../../config/localization';
import { returnLocalizedDate } from '../../../utils/resolve-timer'
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';

const reqFields = {
    from: labels.inbox_labels.from_label,
    site: labels.inbox_labels.routedTo,
    reference: labels.inbox_labels.reference,
    patientName: labels.inbox_labels.patient,
    createdAt: labels.inbox_labels.createdAt,
    panelName: labels.inbox_labels.imported,
    processedAt: labels.inbox_labels.processedAt
}
const safeWeb = (panelName) => {
    var newString = panelName.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase().replace(/[^a-zA-Z0-9_]/g, '_').replace(/_{2,}/g, '_');
    return newString;
}
class MessageDetails extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.labName && this.props.match.params.messageId) {
            this.props.setCurrentMessageDetails(this.props.match.params.messageId, this.props.match.params.labName)
        }
        this.props.setPageTitle(null)
        this.props.setBreadCrumbs([])
        document.title = `${labels.physioage_label}: ${labels.inbox_labels.message_details}`
    }
    getImportedValue() {
        if (this.props.currentMessage && this.props.currentMessage.orderId && parseInt(this.props.currentMessage.orderId))
            return <a onClick={() => this.props.history.push(`/visits/${this.props.currentMessage.visitId}/${safeWeb(this.props.currentMessage.panelName)}/${this.props.currentMessage.orderId}`)}>{this.props.currentMessage.panelName}</a>
        else
            return <i>{labels.inbox_labels.no_label}</i>
    }
    getRow(colArr) {
        return <>{colArr.map(c => <>
            <td>{reqFields[c]}</td><td>{c == 'panelName'
                ? this.getImportedValue()
                : c == 'patientName' ? <span className='msg-detail-patient'><span>{this.props.currentMessage[c]}</span> {this.props.currentMessage.dob ? (`(${labels.inbox_labels.dobLabel}: ${moment(this.props.currentMessage.dob).format('L')})`) : null}</span> :
                    ((c == 'processedAt' || c == 'createdAt') && this.props.currentMessage[c]) ? moment(returnLocalizedDate(this.props.currentMessage[c])).format('YYYY-MM-DD hh:mm:ss A')
                        : this.props.currentMessage[c] ? this.props.currentMessage[c] : c == 'site' ? <i>{labels.inbox_labels.notrouted}</i> : ''}</td>
        </>)}</>
    }
    render() {
        return (
            <div className='message-details-sec container col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                {this.props.currentMessage ?
                    <React.Fragment>
                        <h1>{labels.inbox_labels.message_details}</h1>
                        <div className='card'>
                            <div className='card-header message-details-card'>
                                <div>
                                    {labels.inbox_labels.details}
                                </div>
                                {this.props.currentMessage && !this.props.currentMessage.site && this.props.currentMessage.from == 'UCLA' ?
                                    <div className='message-controls'>
                                        <div className='mr-2'><FormControl type='button' btnType='primary' text='Edit' onClick={() => this.props.history.push(`/messages/edit/${this.props.currentMessage.from}/${this.props.currentMessage.id}`)} /></div>
                                        <div title = 'Click on Process if you want to reprocess the message again'><FormControl type='button' btnType='primary' text='Process' onClick={() => this.props.updateMessage(this.props.history, true)} /></div>
                                    </div> : ''}
                            </div>

                            {/* <table className='card-body pl-0 pr-0 table col-lg-12'>
                                <tbody>
                                    {Object.keys(reqFields).map(r =>
                                        <tr className='data-row col-lg-6' colSpan={2}><td className='col-lg-4'>{reqFields[r]}</td>
                                            {(r != 'createdAt' && r != 'processedAt')
                                                ? <td className='col-lg-8'>{this.props.currentMessage[r]}{this.props.currentMessage[r]}{this.props.currentMessage[r]}{this.props.currentMessage[r]}</td>
                                                : <td className='col-lg-8'>{this.props.currentMessage[r] ? moment(this.props.currentMessage[r]).format("YYYY-MM-DD HH:mm:ss UTC") : ""}</td>}
                                        </tr>
                                    )}
                                </tbody>
                            </table> */}
                            <table className='table'>
                                {this.props.isVerySmallScreen ?
                                    <tbody>
                                        {Object.keys(reqFields).map(req => <tr><td>{this.getRow([req])}</td></tr>)}
                                    </tbody>
                                    : <tbody>
                                        <tr colSpan='4' className='data-row mt-2 mb-2'>
                                            {this.getRow(['from', 'site'])}
                                        </tr>
                                        <tr colSpan='4' className='data-row mt-2 mb-2'>
                                            {this.getRow(['reference', 'patientName'])}
                                        </tr>
                                        <tr colSpan='4' className='data-row mt-2 mb-2'>
                                            {this.getRow(['createdAt', 'panelName'])}
                                        </tr>
                                        <tr colSpan='4' className='data-row mt-2 mb-2'>
                                            {this.getRow(['processedAt', ''])}
                                        </tr>
                                    </tbody>
                                }
                            </table>
                            {this.props.currentMessage.odditieDTOs && this.props.currentMessage.odditieDTOs.length ? <div className='pl-2 pr-2 mt-4'>
                                <h3>{labels.inbox_labels.oddities}</h3>
                                <ul>
                                    {this.props.currentMessage.odditieDTOs.map(odd => <li>{odd.message}</li>)}
                                </ul>
                            </div> : null}
                        </div>
                        <div className='message-details-body card'>
                            <pre> {this.props.currentMessage.body ? this.props.currentMessage.body : '...'}</pre>
                        </div>
                    </React.Fragment> : this.props.no_grid_data ? labels.no_grid_data : null}

            </div>
        )
    }
}
export default MessageDetails
import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';
import { Chart } from 'react-google-charts';
import moment from 'moment';

import labels from '../../../config/localization';
import './active-site-report-styles.scss';
import constants from '../../../config/constants';
import LoadingTemplate from '../../../components/LoadingTemplate';
import PageTitle from '../../../components/PageTitle';

const getCaret = (order) => {
    if (!order) return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;<i className='fa fa-caret-up'></i></span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
    return null;
}

const columns = [
    {
        dataField: 'date', text: labels.sites_labels.month_label, sort: true,
        formatter: (cellContent, row) => (<span>{moment(row.date).format('MMMM, YYYY')}</span>),
        sortCaret: (order, column) => getCaret(order)
    },
    { dataField: 'count', text: labels.asr_labels.active_sites, sort: true, sortCaret: (order, column) => getCaret(order) },
]

class ActiveSiteReport extends Component {
    componentDidMount() {
        // this.props.getActiveSiteReports()
        this.props.getVisitVolume()
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.asr_labels.title, path: '' }
        ])
        this.props.setPageTitle(labels.asr_labels.title)
        document.title = `${labels.physioage_label}: ${labels.asr_labels.title}`
        this.props.toggleNotification(false)
    }
    componentWillUnmount() {
        this.props.toggleNotification(false)
    }
    getActiveSitesByMonth(activeSitesMonths) {
        if (activeSitesMonths) {
            // activeSitesMonths = _.orderBy(activeSitesMonths, ['year', 'month'], ['asc', 'asc'])
            activeSitesMonths = _.reverse(activeSitesMonths)
            var res = [labels.sites_labels.month_label, labels.asr_labels.active_sites]
            var finaldata = []
            finaldata.push(res, ...activeSitesMonths.map(asr => [`${constants.months[asr.month]}, ${asr.year}`, asr.count]))
            return finaldata
        } else {
            return []
        }
    }

    getVisitVolume(activeSitesVolume) {
        if (activeSitesVolume && activeSitesVolume.length) {
            var req = []
            var finalData = [labels.sites_labels.month_label]
            activeSitesVolume.map(asv => {
                asv.monthlyVisitVolumes.map(mvv => {
                    if (req.filter(r => (parseInt(r.month) == parseInt(mvv.month) && parseInt(r.year) == parseInt(mvv.year))).length == 0)
                        req.push({ month: parseInt(mvv.month), year: parseInt(mvv.year) })
                }
                )
                finalData.push(asv.name)
            }
            )
            let allMonths = _.orderBy(req, ['year', 'month'], ['asc', 'asc'])

            let result = allMonths.map(am => {
                var monthData = [`${constants.months[am.month]}, ${am.year}`]
                activeSitesVolume.map(asv => {
                    let mvvMon = asv.monthlyVisitVolumes.map(m => parseInt(m.month))
                    if (mvvMon.indexOf(am.month) != -1) {
                        monthData.push(_.head(asv.monthlyVisitVolumes.filter(mvv => mvv.month == am.month)).count)
                    } else {
                        monthData.push(0)
                    }
                })
                return monthData
            })
            return [finalData, ...result];
        } else {
            return []
        }
    }

    render() {
        return (
            <>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                        <PageTitle breadCrumbs={this.props.breadCrumbs}
                            pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                            navigatePatients={this.props.navigatePatients}
                            history={this.props.history}
                            {...this.props} />
                    </div>
                </div>
                <br></br>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 data-section p-0'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 '>

                        <h2>{labels.asr_labels.active_site_month}</h2>
                        {this.props.activeSitesMonths && this.props.activeSitesMonths.length ?
                            <Chart chartType='ColumnChart'
                                height='400px'
                                options={{
                                    legend: { position: 'top' }
                                }}
                                data={this.getActiveSitesByMonth(this.props.activeSitesMonths)}
                                className='active-site-reports bg-white' />
                            :
                            this.props.isLoading ? <LoadingTemplate isCards={true} /> : null
                        }
                        <h2 className='mt-5'>{labels.asr_labels.visit_volume}</h2>
                        {
                            this.props.activeSitesVolume && this.props.activeSitesVolume.length
                                ? <Chart
                                    chartType='LineChart'
                                    data={this.getVisitVolume(this.props.activeSitesVolume)}
                                    options={{
                                        legend: { position: 'top', maxLines: 3 }
                                    }}
                                    height='400px'
                                    className='active-site-reports bg-white'
                                /> :
                                this.props.isLoading ? <LoadingTemplate isCards={true} /> : null
                        }
                        <div className='active-site-reports mt-5'>
                            {this.props.activeSitesMonths && this.props.activeSitesMonths.length ?
                                <BootstrapTable
                                    remote={{ sort: false }}
                                    striped={false}
                                    bordered={false}
                                    hover={true}
                                    bootstrap4
                                    keyField='id'
                                    data={_.orderBy(this.props.activeSitesMonths.map((asm) => {
                                        return { year: asm.year, month: asm.month, count: asm.count, date: new Date(`${asm.year}/${asm.month}`) }
                                    }), ['date'])}
                                    columns={columns}
                                    pagination={false}
                                /> : this.props.no_grid_data ?
                                    <div className='jumbotron'>
                                        <h3>{labels.no_grid_data}</h3>
                                    </div> : null}
                            {this.props.excludedSitesList ? <span>({labels.asr_labels.excluding} {this.props.excludedSitesList})</span> : ''}
                        </div>
                    </div>
                </div >

            </>
        )
    }
}
export default ActiveSiteReport
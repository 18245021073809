
import React, { Component } from 'react';
import constants from '../../../config/constants';
import PatientReportDownloadTemplate from './PatientReportDownloadTemplate';
import PatientReportDownloadTemplatePast from './PatientReportDownloadTemplatePast';

class HandleDownloadReport extends Component { 
    
    reportype(type) {
        switch (type) {
            case 'patientcurrent':
                return constants.visit_labels.patient_download;
            case 'patientpast':
                return constants.visit_labels.patient_download_past;
            default:
                return 'nil';
        }
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        if (this.props.match.params && this.props.match.params.visitID) {
            setTimeout(() => {
                this.props.setCurrentVisit(this.props.match.params.visitID)
            }, 4000);
            if (this.props.match.params.type == "summary"){
                setTimeout(() => {
                    this.props.getOrders(this.props.match.params.visitID)
                }, 2000);
            }
            setTimeout(() => {
                if (['patientcurrent', 'patientpast'].includes(this.props.match.params.type)) {
                    if (this.props && this.props.currentVisit && this.props.currentVisit.id)
                        this.props.getAllCategories(this.props.match.params.visitID, true, this.reportype(this.props.match.params.type), 'en', null, null, true)
                }
                if (this.props.match.params.type == "summary") {
                    this.props.downloadVisitRecommendations(this.props.history, true, this.props.match.params.visitID, true)
                }
            }, 8000);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        console.log('Page is being refreshed or closed');
        event.returnValue = ''; 
        this.removeAccessToken()
    };

    removeAccessToken() {
        if (localStorage.getItem('access_token'))
            localStorage.removeItem('access_token');
        if (localStorage.getItem('refreshToken'))
            localStorage.removeItem('refreshToken');
    }
    render() {
        return (
            <>{
                !this.props.reportDownloaded ?
                    this.props.patientReportDownloadTemplateSection ? <div className='display-none reports-download-section'>
                        <PatientReportDownloadTemplate {...this.props} />
                    </div> : this.props.patientReportDownloadTemplatePastSection ? <div className='display-none reports-download-section'>
                        <PatientReportDownloadTemplatePast {...this.props} />
                    </div> : null
                    : <div>
                        Successfully downloaded the patient report. Now please close the browser and continue exploring the analytics.
                        {this.removeAccessToken()}
                    </div>}
            </>
        )
    }
}
export default HandleDownloadReport

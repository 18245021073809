import { connect } from 'react-redux';
import { setBreadCrumbs, setPageTitle } from '../../reducers/global-reducer';
import { withRouter } from 'react-router';
import RelComponent from './RelComponent';

const mapStateToProps = state => ({
    ...state.global
});

const mapDispatchToProps = {
    setBreadCrumbs, setPageTitle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RelComponent);

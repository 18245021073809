import React from 'react';
import marked from 'marked';
import html2react from 'html-to-react'

class InterpretationsComponent extends React.Component {
    markdown2react(md) {
        marked.setOptions({
            renderer: new marked.Renderer(),
            sanitize: true,
            smartLists: true,
        });

        let parser = new html2react.Parser();
        return (parser.parse(marked(md)));
    }
    render() {
        return (
            <>
                <section className="page-section" id="analytics-interpretations">
                    <div className="max-container jw-recommendation-container">
                        <h2 className="card-group-title">Interpretations</h2>
                        <div className="card">
                            <div className="card-content">
                                {this.props.interpretations.map((inpObj, index) => {
                                    return (
                                        <>
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">{this.markdown2react(inpObj.interpretation)}</p>
                                                <b>(based on {inpObj.noumenaList})</b>
                                            </div>
                                            {this.props.interpretations.length > 1 && index < this.props.interpretations.length - 1 ? <hr className="mt-4 mb-4" /> : ''}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default InterpretationsComponent;
import React from 'react';

export default class DrawerMedicationWarningComponent extends React.Component {
    render() {
        return (
            <>
                <div className='drawer-warning-overlay'></div>
                <div className='drawer-warning'>
                    <h5>"Close the form?"</h5>
                    <hr />
                    <p>The Medication form has been modified. Closing it will discard your changes. Do you want to continue?</p>
                    <hr />
                    <div className='d-flex justify-content-end'>
                        <button className='btn drawer-ok' onClick={() => {
                            this.props.toggleMedicationFormWarning(false);
                            if (this.props.showMedicationModal)
                                this.props.toggleMedicationModal(false);    
                        }}>Ok</button>
                        <button className='ml-4 btn drawer-cancel' onClick={() => {this.props.toggleMedicationFormWarning(false); }}>Cancel</button>
                    </div>
                </div>
            </>
        )
    }
}
import React from 'react';
import ReactDom from 'react-dom';
import FormControl from '../../../components/FormControls';
import constants from '../../../config/constants';
import labels from '../../../config/localization';

class QuestionaireForm extends React.Component {

    constructor(props) {
        super(props);
        // this.answeredQuestionsCount = this.answeredQuestionsCount.bind(this);
        // this.calculateAverageRating = this.calculateAverageRating.bind(this);
        // this.calculateRatingPercentage = this.calculateRatingPercentage.bind(this);
    }

    // answeredQuestionsCount() {
    //     return (Object.keys(this.props.questionnaireRating).length);
    // }
    // calculateAverageRating() {
    //     let pointsString = Object.values(this.props.questionnaireRating);
    //     let pointsInt = pointsString.map((item) => parseInt(item, 10));
    //     let sumOfPoints = pointsInt.length > 0 ? pointsInt.reduce((a, b) => a + b) : 0;
    //     let totalQuestions = parseFloat(Object.keys(constants.questionnaireData).length);
    //     return sumOfPoints / totalQuestions;
    // }

    // calculateRatingPercentage() {
    //     let pointsString = Object.values(this.props.questionnaireRating);
    //     let pointsInt = pointsString.map((item) => parseInt(item, 10));
    //     let sumOfPoints = pointsInt.length > 0 ? pointsInt.reduce((a, b) => a + b) : 0;
    //     let totalPoints = parseFloat(Object.keys(constants.questionnaireData).length * 5);
    //     return (100 * sumOfPoints) / totalPoints;
    // }
    render() {
        let numer = 0;
        if (this.props.surveyData[this.props.questionnairePageCount - 1] && this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO) {
            this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.map((q, index) => {
                if (q.valueType != "Enumerable") {
                    numer = 1;
                }
            })
        }
        return ReactDom.createPortal(
            <>
                <div className="overlay_styles" onClick={() => (this.props.toggleSurveyPopup(false))} />
                {this.props.surveySubmitted ?
                    <div className="w-50 card ratingCardStyle rating_styles">
                        {/* {this.props.submittedToast()} */}
                        <div className="card-header d-flex flex-row align-items-center text-white bg-success">
                            <i class="fa fa-check-circle text-white bg-success ratingTick"></i>
                            {this.props.status == "draft" ?
                                <h5 className="ml-4">Successfully saved</h5>
                                :
                                <h5 className="ml-4">Successfully submitted</h5>
                            }
                        </div>
                        <div className="card-body">
                            {/* <h6>Average Rating</h6>
                            <div className="d-flex flex-row align-items-center"><h5 className="mr-1"><b>{this.calculateAverageRating()}</b></h5>/<span className="ml-2">5</span></div>
                            <p>Total Questions Answered: <b>{this.answeredQuestionsCount()}</b> out of 10</p>
                            <p>Percentage: <b>{this.calculateRatingPercentage()}%</b></p> */}
                            <h5>Thank you for completing the questionnaires!</h5>
                            <div>Your response has been recorded. We really appreciate your effort and time devoted filling the questionnaire. Your answers will help us improve our services of you.</div>
                            <div className="d-flex flex-row-reverse"><button type="button" className="btn btn-success" onClick={() => (this.props.toggleSurveyPopup(false))}>Ok</button></div>
                        </div>
                    </div> :
                    <div className="mobileScroll">
                        <form className="card survey_styles questionnaireFormStyle w-75 old_questionnaire">
                            {this.props.questionnairePageCount <= this.props.surveyData.length ?
                                <>
                                    <div className="card-header surveyHeader">
                                        <h3>{this.props.surveyData[this.props.questionnairePageCount - 1].name}</h3>
                                        <p>{this.props.surveyData[this.props.questionnairePageCount - 1].explanation}</p>
                                        <i class="fa fa-close questionnaireFormCloseIcon" onClick={() => (this.props.toggleSurveyPopup(false))}></i>
                                    </div>
                                    <div className="card-body">
                                        <div className="container">
                                            {numer == 0 ?
                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-8"><b className="boldShadow">Question</b></div>
                                                    <div className="col-3"><b className="boldShadow">Value</b></div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-5"><b className="boldShadow">Question</b></div>
                                                    <div className="col-3"><b className="boldShadow">Value</b></div>
                                                    <div className="col-1"><b className="boldShadow">Units</b></div>
                                                    <div className="col-2"><b className="boldShadow">Boundary Ranges</b></div>
                                                </div>}
                                            <br />
                                            {this.props.surveyData[this.props.questionnairePageCount - 1] && this.props.surveyData[this.props.questionnairePageCount - 1].introductionsDTO && this.props.surveyData[this.props.questionnairePageCount - 1].introductionsDTO.map((introduction) => {
                                                return (
                                                    <div className='card mb-4 p-3'>
                                                        <div className='alert alert-info'>
                                                            {introduction.introduction}
                                                        </div>
                                                        {introduction.questionsDTO.map((q, index) => {
                                                            if (q.valueType == "Enumerable") {
                                                                return (
                                                                    <>
                                                                        <div className={`row mb-3`} id={q.id}>
                                                                            <div className="col-1 semiboldShadow">{index + 1}.</div>
                                                                            <div className={`${numer == 0 ? "col-8" : "col-5"}`}><div className="semiboldShadow">{q.question}</div><div className={q.subQuestion ? "" : "d-none"}>{q.subQuestion}</div></div>
                                                                            <div className="col-3">
                                                                                <select className="emumSelect"
                                                                                    value={
                                                                                        this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).changed == true &&
                                                                                            this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value != null ?
                                                                                            this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value :
                                                                                            this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value ?
                                                                                                this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value : ""
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                        this.props.editAnalyticsSurveyData(e, this.props.questionnairePageCount - 1, q.id)
                                                                                    }
                                                                                    }
                                                                                >
                                                                                    {q.valueList ? q.valueList.map((op) => (
                                                                                        <option value={op}>{op}</option>
                                                                                    )) : []}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <>
                                                                        <div className={`row mb-3 ${this.props.validSurveyFields && this.props.validSurveyFields[this.props.surveyData[this.props.questionnairePageCount - 1].name] && this.props.validSurveyFields[this.props.surveyData[this.props.questionnairePageCount - 1].name][index] ? "" : "exceed-boundary-range"}`} id={q.id}>
                                                                            <div className="col-1 semiboldShadow">{index + 1}.</div>
                                                                            <div className="col-5"><div className="semiboldShadow">{q.question}</div><div className={q.subQuestion ? "" : "d-none"}>{q.subQuestion}</div><span className={`bounds-caution ${this.props.validSurveyFields && this.props.validSurveyFields[this.props.surveyData[this.props.questionnairePageCount - 1].name] && this.props.validSurveyFields[this.props.surveyData[this.props.questionnairePageCount - 1].name][index] ? "d-none" : "d-inline"}`}>{labels.visit_page_labels.out_of_bounds}</span></div>
                                                                            <div className="col-3">
                                                                                <input type="text"
                                                                                    value={
                                                                                        this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).changed ?
                                                                                            this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value :
                                                                                            this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value ?
                                                                                                this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value : ""
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                        this.props.editAnalyticsSurveyData(e, this.props.questionnairePageCount - 1, q.id)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="col-1">{q.dataRangesDTO && q.dataRangesDTO["units"] ? q.dataRangesDTO["units"] : ""}</div>
                                                                            <div className="col-2">{q.dataRangesDTO && q.dataRangesDTO ? (q.dataRangesDTO.low && q.dataRangesDTO.high ? q.dataRangesDTO.low + " - " + q.dataRangesDTO.high : (q.dataRangesDTO.low && !q.dataRangesDTO.high ? '>' + q.dataRangesDTO.low : (q.dataRangesDTO.high && !q.dataRangesDTO.low) ? '<' + q.dataRangesDTO.high : "")) : ""}</div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        })}

                                                    </div>

                                                )
                                            })}
                                            {this.props.surveyData[this.props.questionnairePageCount - 1] && this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO && this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.filter(ques => ques.introduction == false).map((q, index) => {
                                                return (
                                                    <>
                                                        {q.valueType == "Enumerable" ?
                                                            <div className={`row pr-3 pl-3 mb-3`} id={q.id}>
                                                                <div className="col-1 semiboldShadow">{index + 1}.</div>
                                                                <div className={`${numer == 0 ? "col-8" : "col-5"}`}><div className="semiboldShadow">{q.question}</div><div className={q.subQuestion ? "" : "d-none"}>{q.subQuestion}</div></div>
                                                                <div className="col-3">
                                                                    <select className="emumSelect"
                                                                        value={
                                                                            this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).changed == true &&
                                                                                this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value != null ?
                                                                                this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value :
                                                                                this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value ?
                                                                                    this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value : ""

                                                                        }
                                                                        onChange={(e) => {
                                                                            this.props.editAnalyticsSurveyData(e, this.props.questionnairePageCount - 1, q.id)
                                                                        }
                                                                        }
                                                                    >
                                                                        {q.valueList ? q.valueList.map((op) => (
                                                                            <option value={op}>{op}</option>
                                                                        )) : []}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className={`row pr-3 pl-3 mb-3 ${this.props.validSurveyFields && this.props.validSurveyFields[this.props.surveyData[this.props.questionnairePageCount - 1].name] && this.props.validSurveyFields[this.props.surveyData[this.props.questionnairePageCount - 1].name][index] ? "" : "exceed-boundary-range"}`} id={q.id}>
                                                                <div className="col-1 semiboldShadow">{index + 1}.</div>
                                                                <div className="col-5"><div className="semiboldShadow">{q.question}</div><div className={q.subQuestion ? "" : "d-none"}>{q.subQuestion}</div><span className={`bounds-caution ${this.props.validSurveyFields && this.props.validSurveyFields[this.props.surveyData[this.props.questionnairePageCount - 1].name] && this.props.validSurveyFields[this.props.surveyData[this.props.questionnairePageCount - 1].name][index] ? "d-none" : "d-inline"}`}>{labels.visit_page_labels.out_of_bounds}</span></div>
                                                                <div className="col-3">
                                                                    <input type="text"
                                                                        value={
                                                                            this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).changed ?
                                                                                this.props.resultDTO[this.props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value :
                                                                                this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value ?
                                                                                    this.props.surveyData[this.props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value : ""
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.props.editAnalyticsSurveyData(e, this.props.questionnairePageCount - 1, q.id)
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-1">{q.dataRangesDTO && q.dataRangesDTO["units"] ? q.dataRangesDTO["units"] : ""}</div>
                                                                <div className="col-2">{q.dataRangesDTO && q.dataRangesDTO ? (q.dataRangesDTO.low && q.dataRangesDTO.high ? q.dataRangesDTO.low + " - " + q.dataRangesDTO.high : (q.dataRangesDTO.low && !q.dataRangesDTO.high ? '>' + q.dataRangesDTO.low : (q.dataRangesDTO.high && !q.dataRangesDTO.low) ? '<' + q.dataRangesDTO.high : "")) : ""}</div>
                                                            </div>}
                                                    </>
                                                )
                                            })}

                                        </div>

                                        <div className={`d-flex ${this.props.questionnairePageCount == 1 ? "flex-row" : "flex-row"} justify-content-between`}>
                                            <button type="button" className={`btn surveyPrev ${this.props.questionnairePageCount == 1 ? 'invisible' : 'visible'}`} onClick={() => (this.props.editQuestionnairePageCount("sub"))}>Prev</button>
                                            <div className="row"><p className="text-secondary">{this.props.questionnairePageCount}/{this.props.surveyData.length}</p></div>
                                            {this.props.questionnairePageCount < this.props.surveyData.length ?
                                                <button type="button" className="btn surveyNext" onClick={() => (this.props.editQuestionnairePageCount("add"))}>Next</button>
                                                : <button type="button" className="btn surveyReview" onClick={() => (this.props.editQuestionnairePageCount("add"))}>Review</button>}
                                        </div>
                                        <br />

                                    </div>
                                </>
                                :
                                <>
                                    <div className="card-header surveyHeader">
                                        <h3>Questionaire-Review</h3>
                                        <i class="fa fa-close questionnaireFormCloseIcon" onClick={() => (this.props.toggleSurveyPopup(false))}></i>
                                    </div>
                                    <div className="card-body">
                                        <div className="container">
                                            <div className='alert alert-warning surveySubmitAlert'>
                                                <i className='fa fa-warning ml-2 mr-2'></i><span>Please review your results here, if you want to re visit to this Questionnaire please save your results. If you don't want to, then submit your results.</span>
                                            </div>
                                            <div className="row">
                                                <div className="col-1"></div>
                                                <div className="col-9"><b className="boldShadow">Question</b></div>
                                                <div className="col-2"><b className="boldShadow">Value</b></div>
                                            </div>
                                            <br />
                                            {this.props.resultDTO && this.props.resultDTO.map((survey, index1) => {
                                                let count = 0;
                                                return (
                                                    <>
                                                        <div className="reviewSurveyHeader d-flex justify-content-center" onClick={() => (this.props.editQuestionnairePageCount(index1 + 1))}>
                                                            <div className="survey-name">{this.props.surveyData && this.props.surveyData[index1] ? this.props.surveyData[index1].name : ""}</div>
                                                        </div>
                                                        <br />
                                                        {survey.CreateObservationDTOs && survey.CreateObservationDTOs.map((ques, index2) => {
                                                            if (ques.changed) {
                                                                count++;
                                                                return (
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-1 semiboldShadow">{count}.</div>
                                                                            <div className="col-9 semiboldShadow">{this.props.surveyData && this.props.surveyData[index1] && this.props.surveyData[index1].questionsDTO && this.props.surveyData[index1].questionsDTO[index2] ? this.props.surveyData[index1].questionsDTO[index2].question : ""}</div>
                                                                            <div className="col-2">{ques.value}</div>
                                                                        </div>
                                                                        <br />
                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                        {count == 0 ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-12 semiboldShadow">You haven't answered any new questions, please click <span className="survey-here-link" onClick={() => (this.props.editQuestionnairePageCount(index1 + 1))}>here</span> if you would like to take the Questionnaire again.</div>
                                                                </div>
                                                                <br />
                                                            </>
                                                            : null}
                                                    </>
                                                )
                                            })}

                                        </div>

                                        <div className={`d-flex flex-row justify-content-between`}>
                                            <div className='col-lg-9'>
                                                <button type="button" className={`btn surveyPrev`} onClick={() => (this.props.editQuestionnairePageCount("sub"))}>Back</button>
                                            </div>
                                            <div>
                                                <button type="button" className="btn surveySave" onClick={() => (this.props.submitAnalyticsSurveyForm("draft"))}>Save</button>
                                            </div>
                                            <div className='ml-3 mr-3'>
                                                <button type="button" className="btn btn-success surveySubmit" onClick={() => (this.props.submitAnalyticsSurveyForm(null))}>Submit</button>
                                            </div>
                                        </div>
                                        <br />

                                    </div>
                                </>}
                        </form>
                    </div>}
            </>,
            document.getElementById('surveyPortal')
        )
    }
}


export default QuestionaireForm;
export default {
    physioage_label: 'PhysioAge',
    physioage_report_label: 'PhysioAge Reporting',
    site_label: 'Sitio',
    login_help: 'Si tiene dificultades para restablecer su contraseña, no dude en llamar a nuestro número de soporte',
    login_help_text: 'Inicie sesión con su dirección de correo electrónico.',
    login_btn_text: 'Registrarse',
    forgot_password_text: 'Se te olvidó tu contraseña',
    forgot_password_label: 'Petición para la recuperación de contraseña',
    email_label: 'Email',
    forgot_password_instruction: 'Las instrucciones para restablecer su contraseña se enviarán a la dirección anterior si tiene una cuenta.',
    navigate_login: 'Volver a la pantalla de inicio de sesión',
    reset_my_password_label: 'Restablecer mi contraseña',
    inside_us: 'Dentro de los estados unidos',
    outside_us: 'Fuera de los estados unidos',
    done_label: 'Hecho',
    return_login_btn: 'Volver a la página de inicio de sesión',
    reset_password_label: 'Restablecer su contraseña',
    password: 'Contraseña',
    confirm_password: 'Confirmar contraseña',
    update_password: 'Update Password',
    update_password_btn: 'Actualizar mi contraseña',
    try_again: 'Inténtalo de nuevo:',
    link_expired: 'Enlace expirado',
    link_expired_notification: 'El enlace que usó para restablecer su contraseña caducó. Por lo general, esto sucede cuando espera varios días para usar el enlace, o cuando usted (u otra persona) pasó por el proceso de volver a cambiar su contraseña.',
    set_password: 'Establece tu contraseña',
    set_password_notification: 'Su contraseña debe cambiarse antes de que pueda acceder a sus datos. Sabemos que las contraseñas son una molestia. Pero creemos que el próximo paso valdrá la pena.',
    login_caution_start: 'Al iniciar sesión, aceptas estar vinculado por nuestro',
    login_caution_conj: 'and',
    login_cookies: 'incluyendo pero no limitado a permitir el uso de cookies',
    invalid_email: `Dirección de correo electrónico no válida Por favor ingrese su dirección de correo electrónico válida`,
    unauth_login: `Debe ser administrador para acceder a esta página.`,
    something_went_wrong: 'Algo salió mal',
    not_available: 'NA',
    none: 'Ninguno',
    login_label: 'Login@',
    pagenotfound: {
        title: 'Página no encontrada',
        help: 'Estás buscando algo que no está ahí.',
        redirect_label: 'Redirigir a casa',
        login_label: 'Ingresar de nuevo',
        session_expired: 'La sesion ha expirado',
        back_label: 'Navigate to home'
    },
    footer_labels: {
        privacy: 'Política de privacidad',
        tos: 'Términos de servicio'
    },
    header_labels: {
        credits: 'Creditos',
        account: 'Cuenta',
        user_settings: 'Ajustes de usuario',
        logout: 'Cerrar sesión',
        feedback: 'Realimentación',
        patients: 'Patients',
        help: 'ayuda',
        current_manual: 'Manual actual',
        all_manuals: 'Todos los manuales',
        videos: 'Videos',
        ops: 'OPS',
        active_site_report: 'Informe de sitio activo',
        providers: 'Proveedores',
        sites: 'Sitios',
        elk: 'ELK',
        users: 'Usuarios',
        billing_summary: 'Resumen de facturación',
        message_routing: 'Enrutamiento de mensajes',
        inbox: 'Inbox',
        oddities: 'Rarezas',
        corrections: 'Correcciones',
        data: 'Datos',
        explore_noumena: 'Explore Noumena',
        volume_report: 'Informe de volumen',
        vadr: 'VADR',
        remaining_label: 'restante',
        practice: 'Practice@',
        explore_data: 'Explore Datos',
        download_library: 'Download Library@',
        caregivers: 'Caregivers'
    },
    patient_list_labels: {
        title: 'Lista de pacientes',
        patient_title: 'Paciente',
        filter_patients_label: 'Filtrar pacientes',
        site_label: 'Sitio',
        name_label: 'Nombre',
        min_visits_label: 'Visitas mínimas',
        search: 'Buscar',
        title_label: 'Lista de pacientes',
        add_patient: 'Añadir paciente',
        patient_title_new: 'PhysioAge: Paciente nuevo',
        new_patient_title: 'Añadir paciente',
        edit_patient_form: 'Editar formulario de paciente',
        honorific_prefix: 'Prefijo honorífico',
        honorific_suffix: 'Sufijo honorífico',
        honorific_suffix_help: 'CPA, DDS, MDiv.',
        first_name: 'Nombre de pila',
        last_name: 'Apellido',
        email: 'Email',
        dob: 'Fecha de nacimiento',
        dob_help: 'Una vez que haya seleccionado el año y el mes, DEBE hacer clic en el día.',
        site: 'Sitio',
        provider: 'Proveedor',
        language_pref: 'Preferencia de idioma',
        save: 'Salvar',
        male: 'Masculino',
        female: 'Hembra',
        add_visit: 'Añadir visita',
        edit_Patient: 'Editar Paciente',
        patient_details: 'Detalles del paciente',
        patient_portal: 'Portal del paciente',
        view_patient_portal: 'Ver Portal del Paciente',
        enable_patient_portal: 'Habilitar el Portal del Paciente',
        resend_email: 'Reenviar correo electrónico de bienvenida',
        portal_caution: 'No lo han usado.',
        portal_last_viewed: ' Visto por ultima vez',
        portal_help: 'Este paciente tiene acceso al portal del paciente..',
        portal_mail_help: 'Antes de permitir que este paciente acceda al portal del paciente, necesitamos su dirección de correo electrónico. Por favor ingréselo a continuación.',
        enable_portal_caution: 'Este paciente no tiene acceso al portal del paciente. Haga clic en el botón de abajo para habilitar el acceso.',
        update_patient: 'Actualizar paciente',
        edit_label: 'Editar',
        all_sites: 'Todos los sitios',
        gender_label: 'Género',
        no_visits: 'Sin visitas',
        clear_search: 'Borrar búsqueda',
        create_visit: 'Crear visita',
        visit_date: 'Fecha de visita',
        visits: 'Visitas',
        add_visit_help: 'Seleccione una fecha para agregar visita',
        view_health_analytics: 'Ver análisis de salud',
        credits_elk: `No tienes créditos para agregar Visita`,
        future_date: ` está demasiado lejos en el futuro `,
        agg_age_chart: 'Cuadro de envejecimiento agregado',
        resend_welcome_email: `Enviaremos otro correo electrónico de bienvenida a este usuario en los próximos 10 minutos.`,
        no_visit_caution: `Este paciente no tiene acceso al portal del paciente.
                               Una vez que haya configurado una visita para ellos, podrá proporcionarles acceso.`,
        no_visits_patients: `Each patient visit must be set up before data will be available. Currently this system doesn't know about any visits for this patient.@`,
        data_entry: 'Entrada de datos',
        never: 'Nunca',
        new_visits: 'Nuevas visitas para',
        providers_validate: 'Seleccionar sitios para obtener proveedores',
        sex: 'Sexo',
        last_active: 'Último Activo',
        identifier: 'Identifier@',
        identifier_help: 'Optional field to store your local patient identifiers@',
        delete_patient: 'Delete Patient@',
        delete_patient_prompt: 'Do you want to delete this patient permanently@',
        delete_patient_desc: "This will delete all the visits associated with the patient and its data@",
    },
    feedback_labels: {
        help_text: "Agradecemos cualquier comentario que tenga. Estamos interesados en escuchar cualquier idea que tenga sobre cómo mejorar nuestra aplicación.",
        save_text: 'Enviar comentarios',
        title: 'Realimentación',
        feedback_success: 'Sus comentarios han sido entregados. Gracias por su aporte.'
    },
    user_labels: {
        edit: 'Editar',
        title: 'Lista de usuarios',
        new_user: 'Nuevo usuario',
        edit_user: 'Editar usuario',
        account_settings: 'Configuración de la cuenta',
        email_addresses_help: 'Direcciones de correo electrónico para todos los usuarios actuales:',
        firstname: 'nombre',
        lastname: 'Apellido',
        username: 'nombre de usuario',
        email: 'Correo electrónico',
        active: 'activo',
        activeWithQ: `Active?`,
        site: 'sitio',
        role: 'rol',
        update_user: 'Actualizar usuario',
        reset_password: 'Restablecer contraseña',
        type_password: 'Escriba contraseña',
        confirm_password: 'Confirmar contraseña',
        update_password: 'Actualizar contraseña',
        retype_password: 'Repetir contraseña',
        password: 'contraseña',
        add_user: 'Añadir usuario',
        password_not_matched: 'Las contraseñas no coinciden',
        job_type: 'Tipo de empleo',
        provider_label: 'Proveedor',
        gender_label: 'Género',
        filter_users: 'Filter Users@',
        master: 'master@',
    },
    credits_labels: {
        title: 'Créditos del informe',
        add_credits: 'Agregar créditos',
        download: 'Descargar',
        receipt: 'recibo',
        history: 'historia',
        receipt_header: 'Recibo',
        date: 'Fecha :',
        client: 'Cliente :',
        description: 'Descripción',
        quantity: 'Quantity',
        amount: 'Cantidad',
        ok_btn: 'Okay',
        credit_transactions_name: 'credit_transactions.csv'
    },
    sites_labels: {
        new_site_label: 'Nuevo sitio',
        sites_list_label: 'Sitios',
        total_sites: 'Sitios totales',
        total_clinic: 'Clínicas totales',
        new_clinic: 'Nuevas clínicas esta Q',
        active_clinic: 'Clínicas activas esta Q',
        add_site: 'Agregar un sitio',
        list_sites_filter: 'Listar sitios aceptando referencias',
        list_sites_filter_title: 'Sites Accepting Referrals@',
        all_sites: 'Listar todos los sitios',
        name_label: 'Nombre',
        category_label: 'Categoría',
        billing_label: 'Facturación',
        credits_label: 'Créditos',
        providers_label: 'Proveedores',
        patients_label: 'Pacientes',
        language_label: 'I18N',
        units_label: 'Unidades',
        name_help: 'Este es el nombre que aparecerá en el portal del paciente y será el nombre del remitente cuando enviemos enlaces a los pacientes por correo electrónico. También se utilizará en migas de pan y otros lugares en todo el sitio. ',
        site_referrals_help: 'El sitio acepta referencias de Buscador de proveedores',
        preferences: 'Localization Preferences@',
        language_preference: 'preferencia de idioma',
        date_preference: 'preferencia de fecha',
        unit_preference: 'preferencia de unidad',
        unit_preference_help: 'Cambiar esto para un sitio existente podría tomar un tiempo. Te sugiero que consultes a un desarrollador. ',
        margins_help: 'Agregue márgenes para acomodar perforaciones de doble cara',
        logo_label: 'Logo',
        banner_logo_label: 'Bandera logo',
        settings: 'Other Settings@',
        sites_message_acknowledge: 'El sitio acepta mensajes',
        analytics_portal_acknowledge: 'Analytics como portal',
        billing_strategy: 'Estrategia de facturación',
        brand: 'Marca',
        default_panels: 'Paneles predeterminados',
        referral_info: 'Información de referencia y publicidad',
        address_help: 'Esta dirección se proporcionará a los pacientes potenciales a través del Buscador de proveedores y se puede trazar en un mapa.',
        address_label: 'Dirección',
        city_label: 'Ciudad',
        state_label: 'Estado',
        postal_code: 'Código postal',
        country: 'país',
        phone: 'teléfono',
        phone_help: 'Este número se proporcionará a los pacientes y será utilizado por PhysioAge en caso de que necesitemos comunicarnos con ellos sobre las interrupciones del sitio',
        website_label: 'Sitio web',
        other_info: 'Otra información',
        principle_ehr: 'Principio EHR',
        save_btn: 'Guardar',
        edit_site: 'Editar',
        add_provider: 'Agregar proveedor',
        add_user: 'Agregar usuario',
        add_elk: 'Añadir ELKs',
        contact_info: 'Información de contacto',
        portal_label: 'Portal',
        accept_referral_label: 'Aceptar referencias',
        users_label: 'Usuarios',
        recent_activity: 'Actividad reciente',
        lastseen: 'Visto por última vez',
        month_label: 'Month@',
        year_label: 'Year@',
        visits_label: 'Visitas',
        analytics_label: 'Analítica',
        old_portal_label: 'Portal viejo',
        transaction_history: 'Historial de transacciones',
        current_image_label: 'Imagen actual: ',
        referral: 'remisión',
        all: 'all@',
        not_uploaded: 'Not Uploaded@',
        download_attendee: 'Download Attendee List@',
        features_label: 'Features@',
        include_phone_in_summary: 'Include Phone Number in Visit Summary@'
    },
    provider_labels: {
        providers_list: 'Lista de proveedor',
        add_provider: 'Agregar un proveedor',
        update_provider: 'Proveedor de actualizaciones',
        edit_provider: 'Editar',
        list_title_label: 'Proveedores',
        provider_name: 'Proveedor',
        sites_label: 'Sites',
        patients_label: 'Pacientes',
        last_visit: 'Última visita',
        honorific_prefix: 'prefijo honorífico',
        firstname: 'nombre',
        lastname: 'Apellido',
        honorific_suffix: 'Sufijo honorífico',
        honorific_suffix_help: 'CPA, DDS, MDiv',
        create_provider: 'Crear Proveedor',
        profile_pic: 'Haga clic en la imagen para cambiar la imagen',
        image_label: 'Imagen',
        avatar: '-avatar@',
        remove_pic: 'Remove picture@'
    },
    billing_labels: {
        title: 'Resumen de facturación',
        site_label: 'Sitio',
        startdate_label: 'Fecha de inicio',
        enddate_label: 'Fecha de finalización',
        generate: 'generar',
        num_reports_labels: 'Número de informes',
        charge_report: 'Cargo por informe',
        total_charge: 'Cargos totales',
        filter_label: 'Filtrar',
        physician: 'Médico',
        patient: 'Paciente',
        timepoint: 'Punto de tiempo',
        created: 'Creado',
        visit_date: 'Fecha de visita'
    },
    message_routing_labels: {
        list_title: 'Reglas de enrutamiento de mensajes',
        add_rule: 'Agregar regla',
        message_routes: 'Rutas de mensajes',
        new_message_routing: 'Nueva regla de enrutamiento de mensajes',
        edit_message_routing: 'Editar regla de enrutamiento de mensajes',
        send_application: 'Aplicación de envío',
        sending_facility: 'Facilidad de envío',
        receiving_application: 'Recibiendo Solicitud',
        receiving_facility: 'Facilidad receptora',
        site: 'Sitio',
        panel: 'Panel',
        add_message_route: 'Agregar ruta de mensaje',
        update_message_route: 'Actualizar ruta de mensaje',
        edit_label: 'Editar',
        apply_label: 'Aplicar',
        pid18_1:'Pid18 1',
        copy: 'Copy'
    },
    help_page_labels: {
        current_manual_title: 'Current Biomarker Manual',
        download_label: 'Descargar',
        delete_label: 'Delete',
        all_manual_title: 'Biomarker Manual history',
        videos_title: 'Videos de entrenamiento',
        cutometer: 'cutometría',
        cutometry: 'MPA 580',
        spirometer: 'análisis de onda de pulso',
        spirometry1: 'SphygmoCor XCEL',
        spirometry2: 'SphygmoCor CP',
        sphygmoCor: 'Spirometry',
        sphygmocor1: 'EasyOneAir',
        sphygmocor2: 'MidMark IQSpiro',
        upload_doc: 'Cargar documento nuevo',
        upload_doc_title: 'Cargar nuevo manual de biomarcadores',
        version: 'versión',
        attachment: 'Archivo adjunto',
        create_btn_text: 'Crear archivo de ayuda',
        delete_warning: '¿Está seguro de eliminar el manual?',
        ok_btn: 'Okay',
        cancel_btn: 'Cancelar',
        helpFile_validation: 'Por favor suba el archivo pdf',
        version_validation: 'Por favor ingrese la versión',
        invalid_file: 'Archivo inválido',
        uploaded: 'subir',
    },
    elk_labels: {
        title: 'Transacciones de crédito',
        filter: 'filtro',
        site: 'sitio',
        add_title: 'Agregar créditos para',
        no_of_sites: 'Número de Créditos',
        comment: 'Comentario',
        create_credit_btn: 'Crear transacción de crédito',
        user: 'Usuario',
        change: 'Cambio'
    },
    tos_labels: {
        title: 'TÉRMINOS DE USO',
        last_updated: 'Última actualización 31 de enero de 2018'
    },
    privacy_labels: {
        title: 'POLÍTICA DE PRIVACIDAD',
        last_updated: 'Última actualización 31 de enero de 2018'
    },
    corrections_labels: {
        title: 'Correcciones',
        name: 'Nombre',
        date: 'Fecha',
        description: 'Descripción'
    },
    oddities_labels: {
        title: 'Rarezas únicas',
        oddities: 'rarezas',
        errors: 'Todos los errores',
        message_label: 'Mensaje',
        count_label: 'Contar',
        resolve: 'resolver',
        ok_btn: 'Okay',
        cancel_btn: 'Cancelar',
        close_btn: 'Cerrar',
        observation: 'observación',
        noumenon: 'Noumenon@',
        id: 'ID',
        resultId: 'ID de resultado',
        valueType: 'Value Type',
        identifier: 'identificador',
        subIdentifier: 'Sub-identificador',
        label: 'Etiqueta',
        value: 'valor',
        units: 'Unidades',
        referenceRange: 'rango de referencia',
        abnormalFlags: 'Banderas anormales',
        probability: 'probabilidad',
        natureOfAbnormalTest: 'Naturaleza de prueba anormal',
        resultStatus: 'estado del resultado',
        observedAt: 'Observado en',
        producer: 'Productor',
        observationMethod: 'Método de observación',
        equipmentInstance: 'Instancia de equipo',
        analyzedAt: 'Analizado en',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en',
        observerType: 'Tipo de observador',
        observerId: 'ID de observador',
        datumId: 'ID de Datum',
        result_label: 'Resultado',
        source_label: 'Fuente',
        history: 'historia',
        observation_status: 'Estado',
        observation_id: 'ID de observación',
        result_date: 'Fecha de resultado',
        no_observations: 'No se encontraron observaciones',
        no_history: 'No se encontró historia',
        reference: 'Referencia',
        date_time: 'Fecha / hora',
        resolve_error: '¿Estás seguro de resolver este error?',
        resolved: 'Resolved@',
        category: 'Category@',
        examples: 'examples@',
        messagegroup: 'Oddity Messages@',
        sample_observations: 'Sample Observations@',
        observations_info_1: 'observations@',
        observations_info_2: ' match identifier@',
        visit: 'Visit@',
        obx: 'OBX',
        identifier:'Identifier'
    },
    inbox_labels: {
        title: 'Bandeja de entrada',
        filter_title: 'Filtrar por remitente',
        filter_subtitle: 'Filtrar mensaje',
        raw_btn: 'Raw@',
        resolve_btn: 'Resolver@',
        resolve_title: 'Resolver mensaje',
        demo_modal: 'Launch Demo Modal',
        msg_details: 'Detalles del mensaje',
        from_label: 'Desde',
        date_label: 'Fecha',
        name_label: 'Nombre del paciente',
        dob_label: 'Fecha de Nacimiento del Paciente',
        close_label: 'Cerca',
        launch_demo_header: 'Contenido sin formato del mensaje',
        site_label: 'Sitio',
        patient: 'Paciente',
        dob: 'DOB',
        dobLabel: 'dob',
        visit: 'Visita',
        received: 'Recibido',
        processed: 'Procesada',
        back: 'Atrás',
        aboutyear_back: 'aproximadamente 1 año atrás',
        about_year_back: 'years back@',
        days_back: 'days back@',
        reference: 'Reference@',
        createdAt: 'Created At@',
        processedAt: 'Processed At@',
        details: 'Details@',
        message_details: 'Message Details@',
        oddities: 'Oddities@',
        routedTo: 'Routed To@',
        imported: 'Imported?@',
        notrouted: 'Not Routed@',
        no_label: 'no@',
        un_routed_messages: 'Unrouted Messages',
        failure_messages: 'Failure Messages'
    },
    asr_labels: {
        title: 'Informes del sitio activo',
        active_site_month: 'Sitios activos por mes',
        visit_volume: 'Volumen de visita',
        active_sites: 'Sitios activos',
        excluding: 'excluyendo'
    },
    analytics_labels: {
        exit_Analytics: 'Analítica de salida',
        menu: 'menú',
        most_recent_visit: 'visita más reciente',
        years_old: 'años',
        biographical_information_label: 'Biographical Information',
        biographical_info_label: 'Información biográfica',
        biomarkers_of_aging_label: 'Biomarkers of Aging@',
        health_card_report_label: 'Informe de salud',
        recommendations_label: 'Recomendaciones',
        download_report_label: 'Descargar informe',
        patient_report_label: 'Informe del paciente',
        download_recommendations: 'Descargar recomendaciones',
        download_pdf_label: 'Ver / descargar PDF',
        no_recommandations: 'No ha introducido ninguna recomendación.',
        no_recommandations_content: 'Para ingresar una recomendación, debe estar en el" cajón "que sale de la derecha cuando hace clic en cualquiera de los parámetros. La idea es que cuando vaya a los laboratorios o biomarcadores durante sus consultas, puede hacer clic en el parámetro, por ejemplo "Sin testosterona", y luego aparecerá un cajón en el lado derecho de la pantalla. Puede mirar el espectro de interpretación, los cambios desde la línea de base, un gráfico de todos los puntos, la tabla de datos y, a medida que se desplaza hacia abajo, hay un formulario para ingresar sus recomendaciones',
        comparing_your_ages: 'Comparando sus edades',
        logOut: 'Log Out',
        no_visits_label: 'El paciente no tiene visitas en el archivo.',
        measured_recently: `Este biomarcador no se ha medido recientemente.`,
        older_actual_age: `años mayor que tu edad real.`,
        younger_actual_age: 'años más joven que tu edad real.',
        average_for_age: 'Sobre el promedio para tu edad.',
        older_actual_age_visits: `years older than average for your age.`,
        younger_actual_age_visits: `years younger than average for your age.`,
        older_younger: `neither older nor younger than average for your age.`,
        gpa_scale: 'Su promedio de calificaciones (escala de 4 puntos):',
        report_card_label: 'Boleta de calificaciones',
        treatment_plan: 'PLAN DE TRATAMIENTO',
        biomarkers_label: 'Biomarkers@',
        average_peers: 'Promedio para sus compañeros',
        everyone_label: 'Todos',
        change_from: 'Cambiar de',
        since_label: 'ya que',
        baseline_label: 'Baseline',
        previous_visit_label: 'Visita previa',
        results_label: 'Resultados',
        recommendation_label: 'Recomendación',
        more_info: 'Más información',
        as_of_label: 'a partir de',
        years_label: 'años',
        your_age_label: 'Tu edad',
        your_label: 'Tu',
        date_label: 'Fecha',
        grade_label: 'Grado',
        value_label: 'Valor',
        units_label: 'Unidades',
        reference_range: 'Rango de referencia',
        no_recommandations_made: 'No se han hecho recomendaciones',
        select_items_label: 'Seleccione elementos de los resultados de búsqueda o las recomendaciones comunes a la izquierda para agregarlos a la lista.',
        submit_comments: 'Enviar comentarios',
        recent_recommendations: 'Recomendaciones recientes',
        changes_wont_stick: `Los cambios no se pegarán`,
        demo_mode: `En el modo de demostración, sus cambios parecerán haberse guardado. Pero si vuelve a esta página en el futuro, no los verá. Esto permite que todos los usuarios de demostración comiencen con la misma información básica cada vez.`,
        loading_data: `Cargando datos:`,
        loading_categories: 'Cargando Categorías:',
        loading_patient: 'Cargando paciente:',
        loading_recommendations: 'Recomendaciones de carga:',
        normal_range: 'Rango normal',
        optimal_range: 'Rango óptimo',
        no_description: 'No Description Available@',
        past_results: 'Your Past Results@',
        your_result: 'Your Result@',
        powered_by_physioage: 'Powered By PhysioAge@'
    },
    noumena_labels: {
        title: 'Explorar Noumena',
        noumena: 'Noumena@',
        filter_clouds: 'Filtrar Clouds',
        Noumenon: 'Nombre Noumenon',
        site: 'sitio',
        gender: 'género',
        max_deviaciones: 'Max desviaciones',
        update_btn: 'Actualizar',
        heads_up: 'Heads Up!',
        filter_help: 'Las nubes se generarán más lentamente si selecciona los elementos anteriores. Solo las nubes predeterminadas están dibujadas previamente. ',
        volume_report: 'Informe de volumen',
        download_csv: 'Descargar CSV',
        volume_report_name: 'volume_report.csv',
        aggregate_age_chart_title: 'Muestras de edad agregadas de muestra',
        sample_report_title: 'Grados de la tarjeta de informe de muestra',
        sample_spectra_title: 'Sample Spectra',
        concerns_title: 'Preocupaciones:',
        concerns: [
            '¿Qué aspecto tiene cuando se imprime?',
            '¿Cuánta tinta usa?',
            '¿Cómo se ve el daltónico? (Puede que no haya muchos de ellos, pero sabemos que existen, también podrían manejarlos).'
        ],
        color_palette_help: 'Cambia las paletas de colores si lo deseas:',
        vadr_label: 'Very Abnormal Data Review@',
        no_results: 'No hay resultados',
        next_page: 'Siguiente página',
        no_results_code: 'No hay resultados para el código noumena',
        value_format: 'Formato de valor',
        system: 'Sistema',
        units: 'Unidades',
        scale: 'Escala',
        sorted_details: 'Sordid, Sorted Detalles',
        code: 'Code@',
        appears_in_report: 'Aparece en el informe',
        yes: 'Sí',
        no: 'No@',
        equation: 'Ecuación',
        gender: 'Género',
        anyone: 'Nadie',
        male: 'Masculino',
        female: 'Hembra',
        no_values_to_plot: 'No hay valores para trazar el gráfico',
        age: 'Años',
        visitDate: 'Fecha de visita',
        visitId: 'Id de visita',
        ordinal: 'Ordinal@',
        siteId: 'Sitio Id',
        units: 'Unidades',
        noumena_download: 'data@.csv',
        no_name: 'Sin nombre',
    },
    visit_page_labels: {
        title: 'Línea de base para',
        breadcrum_for_visit: 'Baseline@',
        surveys_label: 'Encuestas',
        patient_conditions_link: 'Encuesta de condiciones del paciente',
        tests_label: 'Tests',
        btn_tests: 'Agregar prueba',
        btn_edit_patient: 'Editar paciente',
        btn_edit_visit: 'Editar visita',
        update_visit: 'Update Visit',
        btn_delete_visit: 'Eliminar visita',
        btn_visit_summary: 'Resumen de la visita',
        btn_portal_view: 'Vista del portal de esta visita',
        btn_toggle_beacon: 'Toggle Beacon@',
        report_label: "Informe del paciente",
        download_link: 'Descargar',
        visit_success: `La visita fue creada exitosamente`,
        visit_update_success: `La visita se actualizó con éxito`,
        preview_link: 'Vista previa',
        download_historic_data: 'Historical Data@',
        recycle_report_link: 'Recycle@',
        recycle_report_text: '(crear uno nuevo)',
        physician_report_label: "Informe del médico",
        register_date_header: 'Registrar fecha de visita',
        active_ell_caution: 'Este paciente tiene un ELK activo',
        update_date_header: 'Actualizar fecha de visita',
        error_handling: 'Ocurrió un error, ingrese otra fecha',
        waiting_for_data: 'Esperando datos',
        notes: 'Por favor, complete los resultados de las pruebas enumeradas a la izquierda. Una vez hecho esto, los datos generales aparecerán aquí. ',
        test_add_order: 'Agregar orden',
        test_panel_name: 'Nombre de la prueba',
        popup_title_addTest: 'Agregar nueva prueba',
        lbl_addTest: 'Agregar prueba',
        patient_survey_title: 'Encuesta de pacientes para',
        nav_link_history: 'Historia',
        nav_link_import: 'Importar',
        nav_link_manual_entry: 'Entrada manual',
        nav_link_sample_image: 'Sample Image',
        customer_service_label: "Si las unidades o rangos de referencia en esta página no coinciden con lo que recibe del laboratorio, ingréselos manualmente haciendo clic en el campo correspondiente. Contacto ",
        customer_service_name: 'servicio al cliente',
        customer_service_label_2: " con preguntas o problemas.",
        patient_survey_table_label: 'Etiqueta',
        patient_survey_table_value: 'Valor',
        patient_survey_table_units: 'Unidades',
        patient_survey_table_ref_range: 'Range Range',
        patient_survey_table_status: 'Estado',
        btn_save_label: 'Guardar resultados',
        label_patient_survey: 'Encuesta de pacientes',
        delete_warning: '¿Está seguro de eliminar la visita?',
        update_visit_help: 'Seleccione una fecha para actualizar la visita',
        elk_credits_one: `Cuando crees esta visita, usarás uno de tus`,
        elk_credits_two: 'créditos restantes',
        visit_date: 'Fecha de visita',
        future_date: `está demasiado lejos en el futuro`,
        visit_creation_error: `La fecha se puede cambiar durante una semana después de la creación de la visita`,
        credits_elk: 'No se puede agregar Timepoint. No hay créditos de informe restantes.',
        no_history: `No se han introducido ni importado resultados.`,
        biomarkers: `Biomarcadores del envejecimiento`,
        import_account_setup: `Tu cuenta no está configurada para importar.`,
        import_contact_us: 'Contact Us@',
        import_details: 'for details!@',
        import_isnt_setup: `PhysioAge se ha asociado con CNS, LabCorp, Quest, Health Diagnostics, Bio-Reference y Access para enviar el trabajo de laboratorio directamente a nuestro software, evitando los dolores de cabeza de la entrada de datos.`,
        import_bloodwork: `Lo sentimos, solo podemos importar resultados relacionados con el análisis de sangre en este momento.`,
        import_search: `Ingrese el nombre del paciente que está buscando. Mostraremos todos los mensajes coincidentes que hayamos recibido en los últimos meses.`,
        import_search_header: `Búsqueda por nombre de paciente:`,
        import_patient_notfound: `Ningún resultado coincide con su búsqueda.`,
        physiological_ages: 'Physiological Ages@',
        age_weights: 'Age Weights@',
        results_title: 'Review Results@',
        out_of_bounds: 'Out Of Bounds@',
        view: 'View@',
        no_recommandations_caution: 'There is not enough data to generate a visit summary at this time.@',
        panel_name: 'Panel name@',
        browse_library: 'Browse Library@',
        upload_btn: 'Upload@',
        all_downloads: 'View all downloads for this patient@',
        delete_visit_desc: "This will delete all the data associated with the visit@",
        historical_data_validation:'Sorry, there is no historical data for the current visit.@'
    },
    orders_labels: {
        delete_warning: '¿Está seguro de eliminar la orden?',
        order_success: 'Se agregó una orden para',
        panel_validate: 'El panel seleccionado ya se ha agregado para el pedido',
        result: `resultados`,
        order_details: `Detalles del pedido`,
        out_of_bounds_exception_one: `Lo sentimos, no puede enviar datos fuera de los límites. Según nuestro panel de médicos, los valores fuera de límite son tan anormales que ciertamente están equivocados.`,
        out_of_bounds_exception_two: ` Si tiene problemas para encontrar el problema, asegúrese de mirar las unidades y buscar decimales adicionales o faltantes.`,
        out_of_bounds_header: `Algunos datos están fuera de límites`,
        close_btn: 'Cerrar',
        no_changes: 'No changes to save Results',
        updated_panels: 'Successfully Updated Results',
        baseline: 'Baseline@',
        one_year: '1 Year@',
        years: 'Years@',
        follow_up: 'FollowUp@',
        preset: 'Preset@',
        gap: 'Gap@',
        unfavourable: 'Unfavourable@',
        entry_date: 'Entry Date@',
        result_view: 'Result View@',
        new: 'New@',
        ordered_by: 'Ordered By:@',
        publication: 'Publication Date:@',
        lab_accession: 'Lab / Accession@',
        draw_date: 'Draw Date@',
        rpt_date: 'Rpt. Date@',
        message_count: '# Msgs@',
        imported_status: 'Imported@',
        wait_status: 'Wait@',
        import_headers: 'Importing Message(s)@',
        import_observations: 'Please wait while we process the following message. It can take up to 60 seconds per message as our system verifies each observation.@',
        import_failed: 'Import Failed.@',
        import_failed_alert: 'We have been alerted and will look in to it. We are sorry that this has happened.@',
        view_message: 'View Message@'
    },
    messages:
    {
        patient_add_success: 'Patient was successfully created@',
        patient_edit_success: 'El paciente se actualizó con éxito',
        no_manuals_exist: 'No existen manuales',
        user_update_success: 'El usuario se actualizó con éxito.',
        user_add_success: 'El usuario fue agregado exitosamente.',
        message_routing_apply: 'Miraré todos los mensajes del año pasado que aún no se han encontrado para ver cuáles se aplican',
        help_add_success: 'Eliminado con éxito',
        elk_validation: 'No puede cobrar un sitio por la Interfaz Web',
        document_upload_success: 'Carga del documento con éxito',
        help_document_validation: 'Seleccione un archivo PDF',
        select_visit_date: 'Seleccione la fecha de visita para agregar la visita',
        select_panel: 'Por favor, seleccione el nombre del panel para agregar la prueba',
        reset_password_mail_success: 'Se han enviado instrucciones para restablecer su contraseña a su dirección de correo electrónico.',
        password_req: 'Se requiere contraseña',
        confirm_pwd_req: 'Confirmar contraseña es requerida',
        pic_validation_message: 'Por favor ingrese un archivo de imagen válido (png o jpg / jpeg)',
        clinic_validation: 'Acepta referencias solo se pueden establecer si el sitio es una clínica.',
        site_select_validation: 'Seleccione al menos un sitio para continuar',
        password_updated: 'Contraseña exitosamente actualizada',
        email_req: 'Email ID is required@',
        payment_site_validation: `You don't have access to add credits for selected site@`,
        elk_validation_zero_credits: `A Site Cannot Buy Zero Credits@`,
        elk_validation_negative_credits: `A Site Cannot Buy Negative Credits@`,
        multiple_card_validation: 'Select only one card for payment@',
        feedback_validation: 'Enter feedback text@',
        no_site_providers: `You won't be able to create any patients until providers are set up for this site. Please contact your PhysioAge vendor@`,
        old_age_restriction: 'Date of Birth implies that patient is impossibly old.@',
        patient_access_msg: 'Patient can now access the portal.@',
        proper_date_validation: 'Enter date in proper format@'
    },
    payments_labels: {
        title_help: 'Add Credits for@',
        num_credits: 'Number of Credits@',
        existing_card: 'Select an Existing Card@',
        new_card: 'Or Enter a New One:@',
        card_number: 'Card Number@',
        cvc_label: 'CVC@',
        expiration: 'Expiration (MM/YY)@',
        save_btn: 'Save@',
        test_cc_title: 'Test CC Numbers@',
        test_cc_help: 'This card works with any three digit CVC and any future expiry date:@ ',
        empty_card: 'This test card is empty:@',
        credits_label: 'Credits@',
        exp_label: 'Expiration date@',
        back_to_site: 'Back to the Site@',
        max_credits_validation: 'Total Credits amount must not be more than $20,000@'
    },
    report_labels: {
        next_gen_exam: 'Next Generation Physical Exam@',
        prepared_for: 'prepared for@',
        by_provider: 'by provider@',
        tests_performed: ' based on tests performed@',
        table_contents: 'Table of Contents@',
        introduction: 'Introduction@',
        compare_system: 'Comparing Systems@',
        health_status_indicator: 'Health Status Indicators@',
        exceptional_results: 'Exceptional Results@',
        abnormal_results: 'Abnormal Results@',
        complete_data: 'Complete Data@',
        historical_data: 'Historical Data@',
        disclaimer: 'Disclaimer@',
        physioage: 'PhysioAge@',
        cutoage: 'CutoAge@',
        immunoage: 'ImmunoAge@',
        pulmoage: 'Pulmoage@',
        telomerage: 'TelomerAge@',
        cardioage: 'CardioAge@',
        neuroage: 'NeuroAge@',
        man_has_arteries: 'A man is as old as his arteries@',
        english_hypocrates: 'known as the English Hypocrates@',
        test: 'The Test@',
        factors_affect: 'What factors affect my @',
        importance: 'Importance@',
        readmore: 'readmore@',
        older_avg: 'years older than average for your age.@',
        younger_avg: 'years younger than average for your age.@',
        is_label: 'is@',
        test_not_performed: 'test not performed@',
        about_test: 'About the Test@',
        skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging?@',
        skin_elasticity: 'Determining Skin Elasticity@',
        significance: 'Significance@',
        composite: 'Composite@',
        flag: 'Flag@',
        first_received: 'First Results Received@',
        last_received: 'Last Results Received@',
    },
    document_labels: {
        format: 'Format@',
        title: 'Title@',
        source: 'Source@',
        distributions: 'Distributions@',
        edit: 'Edit@',
        format_published: 'Format Published@',
        title: 'Title@',
        visit: 'Visit@',
        patient_downloads: 'Patient Downloads@',
        documents_text: 'To add documents, please go to the appropriate visit and find the "Upload" button.@',
        upload_document_for: 'Upload document for@',
        edit_document: 'Edit Document@',
        document_upload_optional: `<b>Optional. Will be set to today's date if you don't enter anything.</b> This field will be useful if you update this document in the future and what to know which is the most current. Once you have selected the year and month, you MUST click on the day.@`,
    },
    successfully_label: 'fue exitoso',
    add_label: 'added',
    updated_label: 'updated',
    required_label: 'se requiere',
    email_help: 'Ingresar ID de correo electrónico',
    credits_caution_title: 'Usted tiene scrednumber informes créditos restantes.',
    credits_caution_help: 'El saldo de su informe de crédito en PhysioAge Reporting ha llegado a scrednumber. Para evitar posibles retrasos, agregue más créditos ahora con su tarjeta de crédito. ',
    credits_caution_contact: 'Contáctenos en con preguntas.',
    acknowlegde: 'Reconocimiento',
    no_grid_data: 'Currently, there is no data to display@',
    pending: 'Pending@',
    no_data: 'No Data@',
    finished: 'Finished@',
    allowed_date_format: 'Enter date in <> format@',
    no_visits: 'No Visits@',
    no_summary_data: `I'm sorry, there was not enough medical data present to generate a summary. Typically this means your data has not been entered into our system yet. Please try again later.@`,
    pages: 'Pages@'
}

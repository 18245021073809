
window.onscroll = function() {

var header = document.getElementById('left-side-bar');
var btns = header.getElementsByClassName('scrollable');
for (var i = 0; i < btns.length; i++) {
  btns[i].addEventListener('click', function() {
  var current = document.getElementsByClassName('active');
  current[0].className = current[0].className.replace(' active', '');
  this.className += ' active';
  });
}
}
export default {
  "value": {
    "recommendationsGroupDTO": [
      {
        "id": 603,
        "recommendationGroupId": 6,
        "position": 159,
        "visible": 1,
        "groupName": "Diet",
        "body": "Time-restricted eating.",
        "recommendationCount": 11,
        "productType": null,
        "bodyWithProduct": "Time-restricted eating.",
        "recommendationStrengths": [
          {
            "recommendationId": 603,
            "strengthId": 436,
            "strength": "Time-restricted eating.",
            "recommendationInstructions": [
              {
                "id": 3027,
                "instruction": "Eat only between 12 and 8 pm (16 hr window)",
                "recommendationId": 0,
                "strengthId": 436
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 498,
        "recommendationGroupId": 1,
        "position": 54,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Testosterone",
        "recommendationCount": 7,
        "productType": "cream",
        "bodyWithProduct": "Testosterone cream",
        "recommendationStrengths": [
          {
            "recommendationId": 498,
            "strengthId": 265,
            "strength": "0.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2310,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 265
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 266,
            "strength": "0.75 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2311,
                "instruction": "Apply 1ml (4 clicks) topically daily.",
                "recommendationId": 0,
                "strengthId": 266
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 267,
            "strength": "1 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2312,
                "instruction": "Apply 0.5ml (2 pumps) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 267
              },
              {
                "id": 2313,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 267
              },
              {
                "id": 2314,
                "instruction": "Apply 1ml (2 pumps) topically once daily.",
                "recommendationId": 0,
                "strengthId": 267
              },
              {
                "id": 2315,
                "instruction": "Apply 1ml (2 pumps) Topically once daily",
                "recommendationId": 0,
                "strengthId": 267
              },
              {
                "id": 2316,
                "instruction": "Apply 1/2ml to 1ml (1 to 2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 267
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 268,
            "strength": "1.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2317,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 268
              },
              {
                "id": 2318,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 268
              },
              {
                "id": 2319,
                "instruction": "Apply 1ml (2 pumps) Topically once daily",
                "recommendationId": 0,
                "strengthId": 268
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 269,
            "strength": "10 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2320,
                "instruction": "Apply 1ml (4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 269
              },
              {
                "id": 2321,
                "instruction": "Apply 0.5ml (1 pump) topically to upper arm area each morning as directed.",
                "recommendationId": 0,
                "strengthId": 269
              },
              {
                "id": 2322,
                "instruction": "Apply 0.5ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 269
              },
              {
                "id": 2323,
                "instruction": "Apply 1/2ml to 1ml (2-4 Clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 269
              },
              {
                "id": 2324,
                "instruction": "Apply 0.5ml (2 clicks) Topically to arms Twice Daily",
                "recommendationId": 0,
                "strengthId": 269
              },
              {
                "id": 2325,
                "instruction": "Apply 1/2ml to 1ml (2 -4 Clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 269
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 270,
            "strength": "100 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2326,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2327,
                "instruction": "Apply 1ml (2 pumps) topically behind the knee Every Morning",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2328,
                "instruction": "Apply 1ml to 2ml (2 to 4 pumps) to each arm and shoulder daily. ",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2329,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2330,
                "instruction": "Apply 1ml (2 pumps) to arms Every Morning",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2331,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2332,
                "instruction": "Apply 0.5ml (1 pump) topically to arm area Twice Daily",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2333,
                "instruction": "Apply 0.5ml (1 pump) topically to each arm daily as directed (Total 1ml daily)",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2334,
                "instruction": "Apply 1ml (2 pumps) to arms and shoulders daily",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2335,
                "instruction": "Apply 1ml to 2ml (2 to 4 pumps) to each arm and shoulder daily",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2336,
                "instruction": "Apply 1ml to 1.5ml (2 to 3 pumps) to arms Every Morning after shower",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2337,
                "instruction": "Apply 2 clicks (0.5ml) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2338,
                "instruction": "Apply 0.5ml -1ml (1-2 Pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2339,
                "instruction": "Apply 0.5ml (1 pump) topically Daily as directed",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2340,
                "instruction": "Apply 0.5ml (1 pump) topically to each arm daily as directed as directed (Total 1ml daily)",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2341,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2342,
                "instruction": "Apply 1ml to 1.5ml (2 to 3 pumps) to arms and shoulders daily",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2343,
                "instruction": "Apply 0.5ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 270
              },
              {
                "id": 2344,
                "instruction": "Apply 1ml (2 pumps) to arms daily",
                "recommendationId": 0,
                "strengthId": 270
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 271,
            "strength": "11 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2345,
                "instruction": "Apply 1ml (2 pumps) topically to arms Daily",
                "recommendationId": 0,
                "strengthId": 271
              },
              {
                "id": 2346,
                "instruction": "Apply 0.5ml (1 pump) topically Every Morning",
                "recommendationId": 0,
                "strengthId": 271
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 272,
            "strength": "12 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2347,
                "instruction": "Apply 0.5ml (1 pump) behind each knee Every Morning (total 2 pumps daily)",
                "recommendationId": 0,
                "strengthId": 272
              },
              {
                "id": 2348,
                "instruction": "Apply 0.5ml (1 pump) Every Morning behind each knee total 2 pumps daily",
                "recommendationId": 0,
                "strengthId": 272
              },
              {
                "id": 2349,
                "instruction": "Apply 1/2ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 272
              },
              {
                "id": 2350,
                "instruction": "Apply 0.5ml (1 pump) behind the knees Every Morning ",
                "recommendationId": 0,
                "strengthId": 272
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 273,
            "strength": "125 mg/gm",
            "recommendationInstructions": [
              {
                "id": 2351,
                "instruction": "Apply 1.5 grams topically daily",
                "recommendationId": 0,
                "strengthId": 273
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 274,
            "strength": "125 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2352,
                "instruction": "Apply 1ml (2 pumps) topically once daily.",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2353,
                "instruction": "Apply 1ml (2 pumps) topically to arms Every Morning and 0.5ml (1 pump) at bedtime",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2354,
                "instruction": "Apply 1ml (2 pumps) to shoulders daily as directed",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2355,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2356,
                "instruction": "Apply 1ml (4 clicks) topically to arms Daily",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2357,
                "instruction": "Apply 1ml (2 pumps) to arms and shoulders Every Morning",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2358,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily as directed",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2359,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm daily.",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2360,
                "instruction": "Apply 1ml (2 pumps) topically Daily. (1 pump behind each knee)",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2361,
                "instruction": "Apply 1ml (2 pumps) to arms daily. Apply 1 pump to each arm total 2 pumps daily.",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2362,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2363,
                "instruction": "Apply 1ml (2 pumps) topically Every Morning",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2364,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2365,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area each morning as directed.",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2366,
                "instruction": "Apply 1ml (2 pumps) to upper arm area each  morning",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2367,
                "instruction": "Apply 1ml (2 pumps) to upper arm/shoulder area Daily",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2368,
                "instruction": "Apply 0.5ml (1 pump) to each arm (total 1ml, 2 pumps) Every Morning ",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2369,
                "instruction": "Apply 0.5ml (1 pump) to each arm daily (total 2 pumps)",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2370,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily as directed. ",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2371,
                "instruction": "Apply 1ml (2 pumps) topically to arm area daily each morning.",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2372,
                "instruction": "Apply 1ml to 1.5ml (2 to 3 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2373,
                "instruction": "Apply 1ml (2 pumps) topically to arms Every Morning",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2374,
                "instruction": "Apply 0.5ml (1 pump) topically to each arm (total 2 pumps) daily as directed",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2375,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) behind knees daily",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2376,
                "instruction": "Apply 1ml (2 pumps) topically Daily. Apply 1 pump to each arm. ",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2377,
                "instruction": "Apply 1ml (4 clicks) topically to biceps, triceps, and shoulders bilaterally Every Morning",
                "recommendationId": 0,
                "strengthId": 274
              },
              {
                "id": 2378,
                "instruction": "Apply 1ml (4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 274
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 275,
            "strength": "13 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2379,
                "instruction": "Apply 1ml (4 clicks) topically behind knees Daily",
                "recommendationId": 0,
                "strengthId": 275
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 276,
            "strength": "14 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2380,
                "instruction": "Apply 1ml (4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 276
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 277,
            "strength": "140 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2381,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 277
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 278,
            "strength": "150 mg/gm",
            "recommendationInstructions": [
              {
                "id": 2382,
                "instruction": "Apply 1 gram to arm area daily each morning .",
                "recommendationId": 0,
                "strengthId": 278
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 279,
            "strength": "150 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2383,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area every morning.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2384,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2385,
                "instruction": "Apply 1ml (2 pumps) to arms Daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2386,
                "instruction": "Apply 1ml (2 pumps) topically daily ",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2387,
                "instruction": "Apply 1ml (2 pumps) topically to arms Twice Daily. Use 3 to 4 pumps daily as directed.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2388,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) behind the knees Daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2389,
                "instruction": "Apply 1ml (4 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2390,
                "instruction": "Apply 1ml (2 pumps) to upper arm daily.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2391,
                "instruction": "Apply 1ml (2 pumps) to arms Daily as directed",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2392,
                "instruction": "Apply 1ml (4 clicks) topically behind knees Every Morning ",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2393,
                "instruction": "Apply 1ml (2 pumps) topically Every Morning ",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2394,
                "instruction": "Apply 0.75ml (3 clicks) topically every day.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2395,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Every Morning",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2396,
                "instruction": "Apply 1ml (4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2397,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically to arms Twice Daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2398,
                "instruction": "Apply 1ml (2 pumps) topically to each arm daily (total 4 pumps daily)",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2399,
                "instruction": "Apply 0.5 ml to 1ml (1 to 2 pumps) topically to arms daily as directed",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2400,
                "instruction": "Apply 1/4ml to 1ml (1 to 4 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2401,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) behind knees daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2402,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2403,
                "instruction": "Apply 1ml (2 pumps) topically to arms daily.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2404,
                "instruction": "Apply 1ml (1 pump) topically daily to arms.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2405,
                "instruction": "Apply 1ml (2 pumps) to arms and shoulders Every Morning",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2406,
                "instruction": "Apply 1ml (2 pumps) topically to arms and shoulders daily as directed",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2407,
                "instruction": "Apply 1ml (2 pumps) to arms daily. Apply 1 pump to each arm total 2 pumps daily.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2408,
                "instruction": "Apply 1.5ml (3 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2409,
                "instruction": "Apply 1ml (2 pumps) to arms Every Morning",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2410,
                "instruction": " Apply 1Ml (2 Pumps) topically to shoulders or arm areas every day",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2411,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2412,
                "instruction": "Apply 1ml (2 pumps) to arms and shoulders daily as directed",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2413,
                "instruction": "Apply 1/4ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2414,
                "instruction": "Apply 1ml (2 pumps) topically to arms daily as directed",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2415,
                "instruction": "Apply 2ml (2 pumps) to upper arm area each  morning.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2416,
                "instruction": "Apply 1/2ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2417,
                "instruction": "Apply 1 gram (2 pumps) topically to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2418,
                "instruction": "Apply 1 ml ( two pumps) daily behind the knees",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2419,
                "instruction": "Apply 1 ml (2 pumps) daily behind the knees",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2420,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2421,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) Topically daily",
                "recommendationId": 0,
                "strengthId": 279
              },
              {
                "id": 2422,
                "instruction": "Apply 1ml  (4 clicks) topically daily to biceps,triceps and shoulders. bilaterally",
                "recommendationId": 0,
                "strengthId": 279
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 280,
            "strength": "16 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2423,
                "instruction": "Apply 0.5ml (1 pump) to arms Every Morning after showering",
                "recommendationId": 0,
                "strengthId": 280
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 281,
            "strength": "160 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2424,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily as directed",
                "recommendationId": 0,
                "strengthId": 281
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 282,
            "strength": "17.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2425,
                "instruction": "Apply 1ml (4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 282
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 283,
            "strength": "175 mg/gm",
            "recommendationInstructions": [
              {
                "id": 2426,
                "instruction": "Apply 1 gram topically daily to arm.",
                "recommendationId": 0,
                "strengthId": 283
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 284,
            "strength": "175 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2427,
                "instruction": "Apply 1ml (2 pumps) to arms Every Morning",
                "recommendationId": 0,
                "strengthId": 284
              },
              {
                "id": 2428,
                "instruction": "Apply 1ml (2 pumps) topically to arms and shoulders daily as directed",
                "recommendationId": 0,
                "strengthId": 284
              },
              {
                "id": 2429,
                "instruction": "Apply 1ml (2 pumps) topically every day to arms.",
                "recommendationId": 0,
                "strengthId": 284
              },
              {
                "id": 2430,
                "instruction": "Apply 1Ml (2 Pumps) topically to arms and shoulders Every Morning",
                "recommendationId": 0,
                "strengthId": 284
              },
              {
                "id": 2431,
                "instruction": "Apply 0.5ml (1 pump) to upper arm area each morning as directed",
                "recommendationId": 0,
                "strengthId": 284
              },
              {
                "id": 2432,
                "instruction": "Apply 1ml (2 pumps) topically daily ",
                "recommendationId": 0,
                "strengthId": 284
              },
              {
                "id": 2433,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed.",
                "recommendationId": 0,
                "strengthId": 284
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 285,
            "strength": "2 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2434,
                "instruction": "Apply 0.5ml to 1 ml (2 to 4 pumps) topically Daily as directed",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2435,
                "instruction": "Apply 1 gram (2 pumps) to thigh area every night",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2436,
                "instruction": "Apply 1ml (4 clicks) topically daily.",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2437,
                "instruction": "Apply 1ml (2 pumps) topically daily ",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2438,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2439,
                "instruction": "Apply 1ml (2 pumps) topically to arm every morning after showering",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2440,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2441,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2442,
                "instruction": "Apply 1/2ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2443,
                "instruction": "Apply 1/2 gram (2 clicks) Topically daily",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2444,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2445,
                "instruction": "Apply 1 ml  (two pumps) topically daily as directed.",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2446,
                "instruction": "Apply 1/2ml to 1ml (1 to 2 pumps) to arms daily",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2447,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2448,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) behind knee once daily",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2449,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically behind the knee Daily",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2450,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically behind knees Every Morning",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2451,
                "instruction": "Apply 2ml (8 pumps) to upper arm twice weekly as directed. ",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2452,
                "instruction": "Apply 1 ml (2 pumps) topically to upper arm daily.",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2453,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily as directed. Start with 1 pump",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2454,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2455,
                "instruction": "Apply 1ml to 1.25ml (4 to 5 pumps) topically Daily as directed",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2456,
                "instruction": "Apply 1/4Ml to 1Ml (1 to 4 Clicks) to inner thigh once daily  ",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2457,
                "instruction": "Apply 0.5ml (2 pumps) to upper arm Daily as directed. ",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2458,
                "instruction": "Apply 1/2ml (1 pump) topically to upper arm daily.",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2459,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2460,
                "instruction": "Apply 0.5ml (1 pump) topically to arm area daily.",
                "recommendationId": 0,
                "strengthId": 285
              },
              {
                "id": 2461,
                "instruction": "Apply two pumps daily as directed.",
                "recommendationId": 0,
                "strengthId": 285
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 286,
            "strength": "2.25 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2462,
                "instruction": "Apply 1ml (2 pumps) topically every day.",
                "recommendationId": 0,
                "strengthId": 286
              },
              {
                "id": 2463,
                "instruction": "Apply 1ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 286
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 287,
            "strength": "2.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2464,
                "instruction": "Apply 0.5ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 287
              },
              {
                "id": 2465,
                "instruction": "Apply 1 gm  (4 clicks) topically daily",
                "recommendationId": 0,
                "strengthId": 287
              },
              {
                "id": 2466,
                "instruction": "Apply 1mL (4 clicks) topically daily",
                "recommendationId": 0,
                "strengthId": 287
              },
              {
                "id": 2467,
                "instruction": "Apply 1ml (2 pumps) topically to arm every morning after showering",
                "recommendationId": 0,
                "strengthId": 287
              },
              {
                "id": 2468,
                "instruction": "Apply 1 gm  ( 4 clicks ) topically daily",
                "recommendationId": 0,
                "strengthId": 287
              },
              {
                "id": 2469,
                "instruction": "Apply 1ml (2 pumps) topically behind knee daily as directed",
                "recommendationId": 0,
                "strengthId": 287
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 288,
            "strength": "20 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2470,
                "instruction": "Apply 1ml (4 clicks) topically daily",
                "recommendationId": 0,
                "strengthId": 288
              },
              {
                "id": 2471,
                "instruction": "Apply 0.5ml (1 pump) Topically to arms Daily",
                "recommendationId": 0,
                "strengthId": 288
              },
              {
                "id": 2472,
                "instruction": "Apply 0.5ml (2 clicks) Topically to arms Daily",
                "recommendationId": 0,
                "strengthId": 288
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 289,
            "strength": "200 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2473,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm daily.",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2474,
                "instruction": "Apply 1ml (2 pumps) topically to arms daily",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2475,
                "instruction": "Apply 1ml (2 Pumps) to arms Daily",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2476,
                "instruction": "Apply 1ml (4 clicks) topically daily ",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2477,
                "instruction": "Apply 1.5ml (3 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2478,
                "instruction": "Apply 1.5ml (6 pumps ) topically Daily",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2479,
                "instruction": "Apply 1ml (2 pumps) to arms Every Morning",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2480,
                "instruction": "Apply 1ml (2 pumps) topically Every Morning ",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2481,
                "instruction": "Apply 1ml (2 pumps) topically to arm area daily in the morning.",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2482,
                "instruction": "Apply 1.25ml (5 clicks) daily behind the knees",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2483,
                "instruction": "Apply 1ml to 1.5ml (2 to 3 pumps) topically to arm area each morning",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2484,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2485,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2486,
                "instruction": "Apply 0.25ml to 1ml (1-4 clicks) to upper arms after shower every day",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2487,
                "instruction": "Apply 1.25ml (5 pumps ) topically Daily",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2488,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2489,
                "instruction": "Apply 0.5ml (1 pump) to upper arm area each morning as directed.",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2490,
                "instruction": "Apply 1ml to 1.5ml (2 to 3 pumps) topically Daily as directed",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2491,
                "instruction": "Apply 0.5ml to upper arm area each morning as directed.",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2492,
                "instruction": "Apply 1ml (4 clicks) topically Every Morning ",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2493,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 289
              },
              {
                "id": 2494,
                "instruction": "Apply 1ml (2 pumps) topically Daily as directed",
                "recommendationId": 0,
                "strengthId": 289
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 290,
            "strength": "3 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2495,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2496,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2497,
                "instruction": "Apply 2 pumps (1ml) topically as directed Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2498,
                "instruction": "Apply 1ml (2 pumps) Topically Every Morning and 1/2ml (1 pump) every evening",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2499,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2500,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2501,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) to thighs Every Morning",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2502,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) to back of knee daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2503,
                "instruction": "Apply 1ml (4 clicks) behind the knees Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2504,
                "instruction": "Apply 1ml (4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2505,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2506,
                "instruction": "Apply 0.25ml (1 click) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2507,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) daily as directed.",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2508,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2509,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) behind the knees Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2510,
                "instruction": "Apply 0.75ml (3 pumps) topically once daily.",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2511,
                "instruction": "Apply 0.25ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2512,
                "instruction": "Apply 1ml (2 pumps) to upper arm area each morning as directed.",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2513,
                "instruction": "Apply 1ml (2 pumps) topically once daily as directed.",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2514,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily. Start with 1 pump daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2515,
                "instruction": "Apply 1ml (2 pumps) topically to arm every morning after showering",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2516,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2517,
                "instruction": "Apply 1ml (2 pumps) topically behind knees daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2518,
                "instruction": "Apply 1ml (2 pumps) once daily to thighs.",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2519,
                "instruction": "Apply 1ml to 1.5ml (2 to 3 pumps) behind knees daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2520,
                "instruction": "Apply 1/2ml (1 pump) topically daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2521,
                "instruction": "Apply 1ml (2 pumps) topically every day.",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2522,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically behind knees daily as directed",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2523,
                "instruction": "Apply 1/4 ml - 1 ml  (1-4 clicks) daily as directed",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2524,
                "instruction": "Apply 0.25ml (1 click) topically Daily ",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2525,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically behind knees daily as directed",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2526,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2527,
                "instruction": "Apply 1/2ml (1 pump) topically daily.",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2528,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically behind knees daily as directed",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2529,
                "instruction": "Apply 0.5ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2530,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically as directed daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2531,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically daily as directed. ",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2532,
                "instruction": "Apply 0.25ml - 1.0 ml  (1-4 clicks) topically  to arms daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2533,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) behind knees daily",
                "recommendationId": 0,
                "strengthId": 290
              },
              {
                "id": 2534,
                "instruction": "Apply 0.5ml (1 pump) daily as directed.",
                "recommendationId": 0,
                "strengthId": 290
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 291,
            "strength": "3.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2535,
                "instruction": "Apply 1ml (2 pumps) to arms Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 291
              },
              {
                "id": 2536,
                "instruction": "Apply 1/4Ml to 1/2ml (1 to 2 Clicks) topically Twice Daily. Titrate as directed",
                "recommendationId": 0,
                "strengthId": 291
              },
              {
                "id": 2537,
                "instruction": "Apply 1/4Ml (1 Click) topically Twice Daily. Titrate as directed",
                "recommendationId": 0,
                "strengthId": 291
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 292,
            "strength": "4 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2538,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2539,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2540,
                "instruction": "Apply 0.5ml (1 pump) topically to arm twice daily as directed.",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2541,
                "instruction": "Apply 1/2ml (1 pump) topically every day to arms.",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2542,
                "instruction": "Apply 0.5ml (1 pump) topically to arm area daily.",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2543,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2544,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2545,
                "instruction": "Apply 1ml (4 Clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2546,
                "instruction": "Apply 0.75ml (3 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2547,
                "instruction": "Apply 1ml (4 clicks) to arm daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2548,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2549,
                "instruction": "Apply 0.5ml (1 pump) topically Daily ",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2550,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2551,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically behind knee daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2552,
                "instruction": "Apply 1-4 pumps (1/4 ml to 1 ml) topically daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2553,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2554,
                "instruction": "Apply 1ml (2 pumps) to arms Daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2555,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2556,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically behind knees daily as directed",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2557,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2558,
                "instruction": "Apply 1ml (2 pumps) topically behind knee daily as directed",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2559,
                "instruction": "Apply 1ml (2 pumps) behind the knee daily",
                "recommendationId": 0,
                "strengthId": 292
              },
              {
                "id": 2560,
                "instruction": "Apply 1 ml to 1.5 ml (4 - 6 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 292
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 293,
            "strength": "4.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2561,
                "instruction": "Apply 1ml (2 pumps) to arms Daily",
                "recommendationId": 0,
                "strengthId": 293
              },
              {
                "id": 2562,
                "instruction": "Apply 1/2ml (2 clicks) topically daily.",
                "recommendationId": 0,
                "strengthId": 293
              },
              {
                "id": 2563,
                "instruction": "Apply 0.5ml (2 pumps) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 293
              },
              {
                "id": 2564,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area daily.",
                "recommendationId": 0,
                "strengthId": 293
              },
              {
                "id": 2565,
                "instruction": "Apply 1ml (4 clicks) topically daily.",
                "recommendationId": 0,
                "strengthId": 293
              },
              {
                "id": 2566,
                "instruction": "Apply1ml (2 pumps) to arms Daily",
                "recommendationId": 0,
                "strengthId": 293
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 294,
            "strength": "5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2567,
                "instruction": "Apply 1ml (4 clicks) topically daily ",
                "recommendationId": 0,
                "strengthId": 294
              },
              {
                "id": 2568,
                "instruction": "Apply 1ml (2 pumps) to arm Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 294
              },
              {
                "id": 2569,
                "instruction": "Apply 0.5ml (1 pump) topically daily.",
                "recommendationId": 0,
                "strengthId": 294
              },
              {
                "id": 2570,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 294
              },
              {
                "id": 2571,
                "instruction": "Apply 0.5ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 294
              },
              {
                "id": 2572,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 294
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 295,
            "strength": "6 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2573,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2574,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2575,
                "instruction": "Apply 0.5ml (2 clicks) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2576,
                "instruction": "Apply 3/4 gram (3 clicks) Topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2577,
                "instruction": "Apply 1ml (2 pumps) behind the knee daily",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2578,
                "instruction": "Apply 0.5ml (1 pump) topically to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2579,
                "instruction": "Apply 0.5ml (2 clicks) topically behind each knee Every Morning Total 1ml (4 clicks) daily",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2580,
                "instruction": "Apply 1Ml (4 Clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2581,
                "instruction": "Apply 0.5ml (2 clicks) topically Every Morning and 0.25ml (1 click) at bedtime",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2582,
                "instruction": "Apply 1ml (4 clicks) to arm daily",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2583,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2584,
                "instruction": "Apply 0.5ml (2 pumps) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2585,
                "instruction": "Apply 1ml (2 pumps) topically Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2586,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 295
              },
              {
                "id": 2587,
                "instruction": "Apply 0.5ml (2 clicks) topically Every Morning ",
                "recommendationId": 0,
                "strengthId": 295
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 296,
            "strength": "6.25 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2588,
                "instruction": "Apply 0.5Ml-1Ml (2 to 4 Clicks) topically Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 296
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 297,
            "strength": "60 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2589,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 297
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 298,
            "strength": "7 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2590,
                "instruction": "Apply 1ml (4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 298
              },
              {
                "id": 2591,
                "instruction": "Apply 1ml (4 clicks) topically once daily.",
                "recommendationId": 0,
                "strengthId": 298
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 299,
            "strength": "7.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2592,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 299
              },
              {
                "id": 2593,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 299
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 300,
            "strength": "8 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2594,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 300
              },
              {
                "id": 2595,
                "instruction": "Apply 1ml (2 pumps) Every Morning and 0.5ml (1 pump) every afternoon.",
                "recommendationId": 0,
                "strengthId": 300
              },
              {
                "id": 2596,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 300
              },
              {
                "id": 2597,
                "instruction": "Apply 0.25ml (1 pump) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 300
              },
              {
                "id": 2598,
                "instruction": " Apply 1ml (2 pumps) to your upper arms and shoulders Every Morning  ",
                "recommendationId": 0,
                "strengthId": 300
              },
              {
                "id": 2599,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) behind knees daily. ",
                "recommendationId": 0,
                "strengthId": 300
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 301,
            "strength": "80 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2600,
                "instruction": "Apply 0.5ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 301
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 498,
            "strengthId": 302,
            "strength": "9 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2601,
                "instruction": "Apply 1/2ml (2 clicks) Topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 302
              },
              {
                "id": 2602,
                "instruction": "Apply 1ml (2 pumps) topically once daily.",
                "recommendationId": 0,
                "strengthId": 302
              },
              {
                "id": 2603,
                "instruction": "Apply 1/2 ml (2 clicks ) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 302
              },
              {
                "id": 2604,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 302
              },
              {
                "id": 2605,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 302
              },
              {
                "id": 2606,
                "instruction": "Apply 1ml (4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 302
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 599,
        "recommendationGroupId": 4,
        "position": 155,
        "visible": 1,
        "groupName": "Supplement",
        "body": "TA-65",
        "recommendationCount": 5,
        "productType": "capsule",
        "bodyWithProduct": "TA-65 capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 599,
            "strengthId": 431,
            "strength": "250 units",
            "recommendationInstructions": null,
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 606,
        "recommendationGroupId": 7,
        "position": 162,
        "visible": 1,
        "groupName": "Exercise",
        "body": "Zone 2 cardio",
        "recommendationCount": 4,
        "productType": null,
        "bodyWithProduct": "Zone 2 cardio",
        "recommendationStrengths": [
          {
            "recommendationId": 606,
            "strengthId": 439,
            "strength": "Zone 2 cardio",
            "recommendationInstructions": [
              {
                "id": 3030,
                "instruction": "Exercise at 60-70% of maximum heart rate for 60 min 4 times a week.",
                "recommendationId": 0,
                "strengthId": 439
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 601,
        "recommendationGroupId": 4,
        "position": 157,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Elysium Basis",
        "recommendationCount": 3,
        "productType": "capsule",
        "bodyWithProduct": "Elysium Basis capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 601,
            "strengthId": 434,
            "strength": "Elysium Basis",
            "recommendationInstructions": [
              {
                "id": 3025,
                "instruction": "Take 2 capsules daily",
                "recommendationId": 0,
                "strengthId": 434
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 445,
        "recommendationGroupId": 1,
        "position": 1,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Armour Thyroid",
        "recommendationCount": 2,
        "productType": "tablet",
        "bodyWithProduct": "Armour Thyroid tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 445,
            "strengthId": 1,
            "strength": "0.25 grain",
            "recommendationInstructions": [
              {
                "id": 1539,
                "instruction": "Take 1 tablet by mouth daily at noon ",
                "recommendationId": 0,
                "strengthId": 1
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 445,
            "strengthId": 2,
            "strength": "0.5 grain",
            "recommendationInstructions": [
              {
                "id": 1540,
                "instruction": "Take one tablet by mouth every afternoon at 3 p.m.",
                "recommendationId": 0,
                "strengthId": 2
              },
              {
                "id": 1541,
                "instruction": "Take 2 tablets By Mouth Every Morning and 1 tablet every day at 3pm",
                "recommendationId": 0,
                "strengthId": 2
              },
              {
                "id": 1542,
                "instruction": "Take 2 Tablets By Mouth Every Morning and 1 tablet By Mouth every afternoon ",
                "recommendationId": 0,
                "strengthId": 2
              },
              {
                "id": 1543,
                "instruction": "Take one tablet by mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 2
              },
              {
                "id": 1544,
                "instruction": "Take 3 Tablets By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 2
              },
              {
                "id": 1545,
                "instruction": "Take one tablet by mouth every afternoon at 3 pm. ",
                "recommendationId": 0,
                "strengthId": 2
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 445,
            "strengthId": 3,
            "strength": "1 grain",
            "recommendationInstructions": [
              {
                "id": 1546,
                "instruction": "Take one tablet by mouth twice daily.",
                "recommendationId": 0,
                "strengthId": 3
              },
              {
                "id": 1547,
                "instruction": "Take one tablet by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 3
              },
              {
                "id": 1548,
                "instruction": "Take one tablet by mouth every morning",
                "recommendationId": 0,
                "strengthId": 3
              },
              {
                "id": 1549,
                "instruction": "Crunch and swallow 1 tablet by mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 3
              },
              {
                "id": 1550,
                "instruction": "Take 1 Tablet By Mouth Every Morning and Every Evening",
                "recommendationId": 0,
                "strengthId": 3
              },
              {
                "id": 1551,
                "instruction": "Take 1 Tablet By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 3
              },
              {
                "id": 1552,
                "instruction": "Take one tablet by mouth every morning on an empty stomach. chew before swallowing.",
                "recommendationId": 0,
                "strengthId": 3
              },
              {
                "id": 1553,
                "instruction": "Take 1 Tablet By Mouth every afternoon",
                "recommendationId": 0,
                "strengthId": 3
              },
              {
                "id": 1554,
                "instruction": "Take 1 Tablet By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 3
              },
              {
                "id": 1555,
                "instruction": "Take 1 Tablet By Mouth Every Morning with one 0.25grain tablet ",
                "recommendationId": 0,
                "strengthId": 3
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 445,
            "strengthId": 4,
            "strength": "1.5 grain",
            "recommendationInstructions": [
              {
                "id": 1556,
                "instruction": "Take 1 Tablet By Mouth Every Morning         ",
                "recommendationId": 0,
                "strengthId": 4
              },
              {
                "id": 1557,
                "instruction": "Take one tablet by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 4
              },
              {
                "id": 1558,
                "instruction": "Take one tablet by mouth Every Morning and one in the afternoon",
                "recommendationId": 0,
                "strengthId": 4
              },
              {
                "id": 1559,
                "instruction": "Take one tablet by mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 4
              },
              {
                "id": 1560,
                "instruction": "Take one tablet by mouth daily.",
                "recommendationId": 0,
                "strengthId": 4
              },
              {
                "id": 1561,
                "instruction": "Take 1.5 Tablets By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 4
              },
              {
                "id": 1562,
                "instruction": "Take 1 Tablet By Mouth Every Morning and 1 Every afternoon.",
                "recommendationId": 0,
                "strengthId": 4
              },
              {
                "id": 1563,
                "instruction": "Take 1 Tablet By Mouth Every Morning and 1/2 tablet By Mouth every afternoon",
                "recommendationId": 0,
                "strengthId": 4
              },
              {
                "id": 1564,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 4
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 445,
            "strengthId": 5,
            "strength": "3 grain",
            "recommendationInstructions": [
              {
                "id": 1565,
                "instruction": "Take 1 Tablet By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 5
              },
              {
                "id": 1566,
                "instruction": "Take one tablet by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 5
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 604,
        "recommendationGroupId": 6,
        "position": 160,
        "visible": 1,
        "groupName": "Diet",
        "body": "Intermittent Fasting",
        "recommendationCount": 2,
        "productType": null,
        "bodyWithProduct": "Intermittent Fasting",
        "recommendationStrengths": [
          {
            "recommendationId": 604,
            "strengthId": 437,
            "strength": "Intermittent Fasting",
            "recommendationInstructions": [
              {
                "id": 3028,
                "instruction": "Fast once a week for 24 hrs (Dinner to dinner)",
                "recommendationId": 0,
                "strengthId": 437
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 590,
        "recommendationGroupId": 4,
        "position": 146,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin D3",
        "recommendationCount": 1,
        "productType": "capsule",
        "bodyWithProduct": "Vitamin D3 capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 590,
            "strengthId": 414,
            "strength": "10,000 IU",
            "recommendationInstructions": [
              {
                "id": 2966,
                "instruction": "Take one capsule by mouth once daily.",
                "recommendationId": 0,
                "strengthId": 414
              },
              {
                "id": 2967,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 414
              },
              {
                "id": 2968,
                "instruction": "Take 1 Capsule By Mouth twice weekly ",
                "recommendationId": 0,
                "strengthId": 414
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 590,
            "strengthId": 415,
            "strength": "2,000 IU",
            "recommendationInstructions": [
              {
                "id": 2969,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 415
              },
              {
                "id": 2970,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 415
              },
              {
                "id": 2971,
                "instruction": "Take one capsule by mouth daily",
                "recommendationId": 0,
                "strengthId": 415
              },
              {
                "id": 2972,
                "instruction": "Take 1 Capsule By Mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 415
              },
              {
                "id": 2973,
                "instruction": "Take 1 Capsule By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 415
              },
              {
                "id": 2974,
                "instruction": "Take one capsule daily",
                "recommendationId": 0,
                "strengthId": 415
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 590,
            "strengthId": 416,
            "strength": "2,500 IU",
            "recommendationInstructions": [
              {
                "id": 2975,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 416
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 590,
            "strengthId": 417,
            "strength": "3,000 IU",
            "recommendationInstructions": [
              {
                "id": 2976,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 417
              },
              {
                "id": 2977,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 417
              },
              {
                "id": 2978,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 417
              },
              {
                "id": 2979,
                "instruction": "Take one capsule by mouth daily",
                "recommendationId": 0,
                "strengthId": 417
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 590,
            "strengthId": 418,
            "strength": "4,000 IU",
            "recommendationInstructions": [
              {
                "id": 2980,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 418
              },
              {
                "id": 2981,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 418
              },
              {
                "id": 2982,
                "instruction": "Take 1 capsule by mouth once daily with food",
                "recommendationId": 0,
                "strengthId": 418
              },
              {
                "id": 2983,
                "instruction": "Take 1 Capsule By Mouth five days weekly with food",
                "recommendationId": 0,
                "strengthId": 418
              },
              {
                "id": 2984,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 418
              },
              {
                "id": 2985,
                "instruction": "Take one capsule by mouth once daily.",
                "recommendationId": 0,
                "strengthId": 418
              },
              {
                "id": 2986,
                "instruction": "Take 2 Capsules By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 418
              },
              {
                "id": 2987,
                "instruction": "Take 1 capsule by mouth daily with food.",
                "recommendationId": 0,
                "strengthId": 418
              },
              {
                "id": 2988,
                "instruction": "Take one capsule by mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 418
              },
              {
                "id": 2989,
                "instruction": "Take as directed.",
                "recommendationId": 0,
                "strengthId": 418
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 590,
            "strengthId": 419,
            "strength": "50,000 IU",
            "recommendationInstructions": [
              {
                "id": 2990,
                "instruction": "Take 1 Capsule By Mouth twice monthly",
                "recommendationId": 0,
                "strengthId": 419
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 590,
            "strengthId": 420,
            "strength": "7,000IU",
            "recommendationInstructions": [
              {
                "id": 2991,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 420
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 590,
            "strengthId": 421,
            "strength": "7,500 IU",
            "recommendationInstructions": [
              {
                "id": 2992,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 421
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 446,
        "recommendationGroupId": 1,
        "position": 2,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Biest(estriol/estradiol)",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Biest(estriol/estradiol) cream",
        "recommendationStrengths": [
          {
            "recommendationId": 446,
            "strengthId": 6,
            "strength": "3.5mg/13mg/ml",
            "recommendationInstructions": [
              {
                "id": 1567,
                "instruction": "Apply 0.5ml (1 pump) behind the knee Every Morning and 0.5ml (1pump) Every Evening ",
                "recommendationId": 0,
                "strengthId": 6
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 446,
            "strengthId": 7,
            "strength": "3.5mg/20mg/ml",
            "recommendationInstructions": [
              {
                "id": 1568,
                "instruction": "Apply 0.5ml (1 pump) behind the knee Every Morning and 0.5ml (1pump) Every Evening ",
                "recommendationId": 0,
                "strengthId": 7
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 446,
            "strengthId": 8,
            "strength": "3mg/2.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1569,
                "instruction": "Apply 0.75ml (3 clicks) topically Every Morning and 0.5ml (2 clicks every evening",
                "recommendationId": 0,
                "strengthId": 8
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 446,
            "strengthId": 9,
            "strength": "3mg/4.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1570,
                "instruction": "Apply 0.5ml (2 clicks) topically Every Morning and 0.5ml (2 clicks every evening",
                "recommendationId": 0,
                "strengthId": 9
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 446,
            "strengthId": 10,
            "strength": "4.5mg/7mg/ml",
            "recommendationInstructions": [
              {
                "id": 1571,
                "instruction": "Apply 0.5ml (2 clicks) topically Every Morning and 0.5ml (2 clicks every evening",
                "recommendationId": 0,
                "strengthId": 10
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 446,
            "strengthId": 11,
            "strength": "6mg/9mg/ml",
            "recommendationInstructions": [
              {
                "id": 1572,
                "instruction": "Apply 0.5ml (2 clicks) topically Every Morning and 0.5ml (2 clicks) every evening",
                "recommendationId": 0,
                "strengthId": 11
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 447,
        "recommendationGroupId": 1,
        "position": 3,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Biest(Estriol/Estradiol)/Prog/Test",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Biest(Estriol/Estradiol)/Prog/Test cream",
        "recommendationStrengths": [
          {
            "recommendationId": 447,
            "strengthId": 12,
            "strength": "4/12/190/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1573,
                "instruction": "Apply 1.5ml (3 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 12
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 448,
        "recommendationGroupId": 1,
        "position": 4,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Biest(Estriol/Estradiol)/Progesterone/Testosterone",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Biest(Estriol/Estradiol)/Progesterone/Testosterone cream",
        "recommendationStrengths": [
          {
            "recommendationId": 448,
            "strengthId": 13,
            "strength": "2/14/190/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1574,
                "instruction": "Apply 1ml to 1.5ml (2 to 3 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 13
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 449,
        "recommendationGroupId": 1,
        "position": 5,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Chorionic Gonadotropin (Fresenius Kabi)",
        "recommendationCount": 0,
        "productType": "injectable",
        "bodyWithProduct": "Chorionic Gonadotropin (Fresenius Kabi) injectable",
        "recommendationStrengths": [
          {
            "recommendationId": 449,
            "strengthId": 14,
            "strength": "10,000 IU",
            "recommendationInstructions": [
              {
                "id": 1575,
                "instruction": "Mix with 3ml bacteriostatic water and inject 0.25ml Subcutaneously Three times weekly ",
                "recommendationId": 0,
                "strengthId": 14
              },
              {
                "id": 1576,
                "instruction": "Mix with 3ml bacteriostatic water and inject 0.25ml Three times weekly ",
                "recommendationId": 0,
                "strengthId": 14
              },
              {
                "id": 1577,
                "instruction": "Dilute with 3.5ml of bacteriostatic water and inject 0.3ml subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 14
              },
              {
                "id": 1578,
                "instruction": "Mix with 3ml bacteriostatic water and inject 0.25ml subcutaneously 3 times weekly ",
                "recommendationId": 0,
                "strengthId": 14
              },
              {
                "id": 1579,
                "instruction": "Mix with 3ml bacteriostatic water and inject 0.25ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 14
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 450,
        "recommendationGroupId": 1,
        "position": 6,
        "visible": 1,
        "groupName": "Hormone",
        "body": "DHEA",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "DHEA capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 450,
            "strengthId": 15,
            "strength": "1 mg",
            "recommendationInstructions": [
              {
                "id": 1580,
                "instruction": "Take one capsule by mouth daily.",
                "recommendationId": 0,
                "strengthId": 15
              },
              {
                "id": 1581,
                "instruction": "Take one capsule by mouth daily as directed may  increase up to 5 capsules as needed",
                "recommendationId": 0,
                "strengthId": 15
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 16,
            "strength": "125 mg",
            "recommendationInstructions": [
              {
                "id": 1582,
                "instruction": "Take 1 capsule by mouth once daily.",
                "recommendationId": 0,
                "strengthId": 16
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 17,
            "strength": "15 mg",
            "recommendationInstructions": [
              {
                "id": 1583,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 17
              },
              {
                "id": 1584,
                "instruction": "Take one capsule by mouth daily",
                "recommendationId": 0,
                "strengthId": 17
              },
              {
                "id": 1585,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 17
              },
              {
                "id": 1586,
                "instruction": "Take one capsule daily",
                "recommendationId": 0,
                "strengthId": 17
              },
              {
                "id": 1587,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 17
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 19,
            "strength": "20 mg",
            "recommendationInstructions": [
              {
                "id": 1589,
                "instruction": "Take 1 Capsule By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 19
              },
              {
                "id": 1590,
                "instruction": "Take 1 Capsule By Mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 19
              },
              {
                "id": 1591,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 19
              },
              {
                "id": 1592,
                "instruction": "Take one capsule by mouth every morning as directed. ",
                "recommendationId": 0,
                "strengthId": 19
              },
              {
                "id": 1593,
                "instruction": "Take 1 capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 19
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 20,
            "strength": "25 mg",
            "recommendationInstructions": [
              {
                "id": 1594,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 20
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 21,
            "strength": "30 mg",
            "recommendationInstructions": [
              {
                "id": 1595,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 21
              },
              {
                "id": 1596,
                "instruction": "Take one capsule by mouth each morning.",
                "recommendationId": 0,
                "strengthId": 21
              },
              {
                "id": 1597,
                "instruction": "Take 1 capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 21
              },
              {
                "id": 1598,
                "instruction": "Take one capsule by mouth daily. ",
                "recommendationId": 0,
                "strengthId": 21
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 22,
            "strength": "35 mg",
            "recommendationInstructions": [
              {
                "id": 1599,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 22
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 23,
            "strength": "40 mg",
            "recommendationInstructions": [
              {
                "id": 1600,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 23
              },
              {
                "id": 1601,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 23
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 24,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 1602,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 24
              },
              {
                "id": 1603,
                "instruction": "Take one capsule by mouth daily",
                "recommendationId": 0,
                "strengthId": 24
              },
              {
                "id": 1604,
                "instruction": "Take one capsule by mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 24
              },
              {
                "id": 1605,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 24
              },
              {
                "id": 1606,
                "instruction": "Take one capsule by mouth each day. ",
                "recommendationId": 0,
                "strengthId": 24
              },
              {
                "id": 1607,
                "instruction": "Take one Capsule By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 24
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 26,
            "strength": "7.5 mg",
            "recommendationInstructions": [
              {
                "id": 1609,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 26
              },
              {
                "id": 1610,
                "instruction": "Take 1 Capsule By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 26
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 450,
            "strengthId": 27,
            "strength": "75 mg",
            "recommendationInstructions": [
              {
                "id": 1611,
                "instruction": "Take one capsule by mouth once daily.",
                "recommendationId": 0,
                "strengthId": 27
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 451,
        "recommendationGroupId": 1,
        "position": 7,
        "visible": 1,
        "groupName": "Hormone",
        "body": "DHEA",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "DHEA cream",
        "recommendationStrengths": [
          {
            "recommendationId": 451,
            "strengthId": 18,
            "strength": "160 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1588,
                "instruction": "Apply 1 gram (2 pumps) Topically daily",
                "recommendationId": 0,
                "strengthId": 18
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 451,
            "strengthId": 25,
            "strength": "50 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1608,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 25
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 452,
        "recommendationGroupId": 1,
        "position": 8,
        "visible": 1,
        "groupName": "Hormone",
        "body": "DHEA (PA)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "DHEA (PA) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 452,
            "strengthId": 28,
            "strength": "10 mg",
            "recommendationInstructions": [
              {
                "id": 1612,
                "instruction": "Take one capsule by mouth each morning.",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1613,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1614,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1615,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1616,
                "instruction": "Take one capsule by mouth once daily.",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1617,
                "instruction": "Take 1 capsule by mouth each morning.",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1618,
                "instruction": "Take one capsule by mouth once daily each morning.",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1619,
                "instruction": "Take 1 capsule by mouth every other day.",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1620,
                "instruction": "Take one capsule by mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1621,
                "instruction": "Take one capsule by mouth daily.",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1622,
                "instruction": "Take one capsule by mouth daily",
                "recommendationId": 0,
                "strengthId": 28
              },
              {
                "id": 1623,
                "instruction": "Take 1 capsule by mouth Every Morning as directed.",
                "recommendationId": 0,
                "strengthId": 28
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 452,
            "strengthId": 29,
            "strength": "25 mg",
            "recommendationInstructions": [
              {
                "id": 1624,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 29
              },
              {
                "id": 1625,
                "instruction": "Take one capsule by mouth daily. ",
                "recommendationId": 0,
                "strengthId": 29
              },
              {
                "id": 1626,
                "instruction": "Take one capsule by mouth each morning.",
                "recommendationId": 0,
                "strengthId": 29
              },
              {
                "id": 1627,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 29
              },
              {
                "id": 1628,
                "instruction": "Take one capsule by mouth daily",
                "recommendationId": 0,
                "strengthId": 29
              },
              {
                "id": 1629,
                "instruction": "Take as directed",
                "recommendationId": 0,
                "strengthId": 29
              },
              {
                "id": 1630,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 29
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 452,
            "strengthId": 30,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 1631,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 30
              },
              {
                "id": 1632,
                "instruction": "Take one capsule by mouth daily ",
                "recommendationId": 0,
                "strengthId": 30
              },
              {
                "id": 1633,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 30
              },
              {
                "id": 1634,
                "instruction": "Take 1 capsule by mouth each morning.",
                "recommendationId": 0,
                "strengthId": 30
              },
              {
                "id": 1635,
                "instruction": "Take one capsule by mouth daily.",
                "recommendationId": 0,
                "strengthId": 30
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 453,
        "recommendationGroupId": 1,
        "position": 9,
        "visible": 1,
        "groupName": "Hormone",
        "body": "DHEA (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "DHEA (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 453,
            "strengthId": 31,
            "strength": "10 mg",
            "recommendationInstructions": [
              {
                "id": 1636,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 31
              },
              {
                "id": 1637,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 31
              },
              {
                "id": 1638,
                "instruction": "Take 1 Capsule By Mouth daily in the  morning",
                "recommendationId": 0,
                "strengthId": 31
              },
              {
                "id": 1639,
                "instruction": "Take 2 Capsules By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 31
              },
              {
                "id": 1640,
                "instruction": "Take 1 Capsule By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 31
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 453,
            "strengthId": 32,
            "strength": "25 mg",
            "recommendationInstructions": [
              {
                "id": 1641,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 32
              },
              {
                "id": 1642,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 32
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 453,
            "strengthId": 33,
            "strength": "50 mg",
            "recommendationInstructions": [
              {
                "id": 1643,
                "instruction": "Take 1 Capsule By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 33
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 454,
        "recommendationGroupId": 1,
        "position": 10,
        "visible": 1,
        "groupName": "Hormone",
        "body": "DHEA/Estradiol/Testosterone",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "DHEA/Estradiol/Testosterone cream",
        "recommendationStrengths": [
          {
            "recommendationId": 454,
            "strengthId": 34,
            "strength": "10/6/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1644,
                "instruction": "Apply 1ml (2 pumps) topically to arm area Daily",
                "recommendationId": 0,
                "strengthId": 34
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 454,
            "strengthId": 35,
            "strength": "15/9/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1645,
                "instruction": "Apply 1ml (2 pumps) topically to arm area Daily",
                "recommendationId": 0,
                "strengthId": 35
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 454,
            "strengthId": 36,
            "strength": "40mg/6mg/3mg/ml",
            "recommendationInstructions": [
              {
                "id": 1646,
                "instruction": "Apply 1ml (4 clicks) topically Every Morning",
                "recommendationId": 0,
                "strengthId": 36
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 455,
        "recommendationGroupId": 1,
        "position": 11,
        "visible": 1,
        "groupName": "Hormone",
        "body": "DHEA/Estriol",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "DHEA/Estriol cream",
        "recommendationStrengths": [
          {
            "recommendationId": 455,
            "strengthId": 37,
            "strength": "10mg/1mg/gm",
            "recommendationInstructions": [
              {
                "id": 1647,
                "instruction": "Apply 1 gram intravaginally Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 37
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 455,
            "strengthId": 38,
            "strength": "5mg/1mg/gm",
            "recommendationInstructions": [
              {
                "id": 1648,
                "instruction": "Insert 1 gram vaginally Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 38
              },
              {
                "id": 1649,
                "instruction": "Apply 1 gram vaginally Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 38
              },
              {
                "id": 1650,
                "instruction": "Apply 1gm vaginally Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 38
              },
              {
                "id": 1651,
                "instruction": "Apply 1 gram Intravaginally every night at bedtime",
                "recommendationId": 0,
                "strengthId": 38
              },
              {
                "id": 1652,
                "instruction": "Insert 1 gram intravaginally Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 38
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 455,
            "strengthId": 39,
            "strength": "5mg/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1653,
                "instruction": "Insert 1 gm (4 clicks) vaginally every night at bedtime",
                "recommendationId": 0,
                "strengthId": 39
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 456,
        "recommendationGroupId": 1,
        "position": 12,
        "visible": 1,
        "groupName": "Hormone",
        "body": "DHEA/Pregnenolone",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "DHEA/Pregnenolone capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 456,
            "strengthId": 40,
            "strength": "10mg/15mg",
            "recommendationInstructions": [
              {
                "id": 1654,
                "instruction": "Take one capsule by mouth once daily in the morning.",
                "recommendationId": 0,
                "strengthId": 40
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 41,
            "strength": "10mg/25mg",
            "recommendationInstructions": [
              {
                "id": 1655,
                "instruction": "Take one capsule by mouth each morning.",
                "recommendationId": 0,
                "strengthId": 41
              },
              {
                "id": 1656,
                "instruction": "Take one capsule by mouth as directed.",
                "recommendationId": 0,
                "strengthId": 41
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 42,
            "strength": "10mg/50mg",
            "recommendationInstructions": [
              {
                "id": 1657,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 42
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 43,
            "strength": "125mg/25mg",
            "recommendationInstructions": [
              {
                "id": 1658,
                "instruction": "Take one capsule by mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 43
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 44,
            "strength": "15mg/25mg",
            "recommendationInstructions": [
              {
                "id": 1659,
                "instruction": "Take one capsule by mouth each morning as directed.",
                "recommendationId": 0,
                "strengthId": 44
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 45,
            "strength": "20mg/25mg",
            "recommendationInstructions": [
              {
                "id": 1660,
                "instruction": "Take one capsule by mouth each morning.",
                "recommendationId": 0,
                "strengthId": 45
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 46,
            "strength": "20mg/50mg",
            "recommendationInstructions": [
              {
                "id": 1661,
                "instruction": "Take 1 Capsule By Mouth Every Morning  ",
                "recommendationId": 0,
                "strengthId": 46
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 47,
            "strength": "25mg/25mg",
            "recommendationInstructions": [
              {
                "id": 1662,
                "instruction": "Take one capsule by mouth once daily in the morning. ",
                "recommendationId": 0,
                "strengthId": 47
              },
              {
                "id": 1663,
                "instruction": "Take one capsule by mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 47
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 48,
            "strength": "50mg/25mg",
            "recommendationInstructions": [
              {
                "id": 1664,
                "instruction": "Take one capsule by mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 48
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 49,
            "strength": "50mg/40mg",
            "recommendationInstructions": [
              {
                "id": 1665,
                "instruction": "Take one capsule by mouth once daily in the morning.",
                "recommendationId": 0,
                "strengthId": 49
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 50,
            "strength": "50mg/50mg",
            "recommendationInstructions": [
              {
                "id": 1666,
                "instruction": "Take one capsule by mouth every other day",
                "recommendationId": 0,
                "strengthId": 50
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 51,
            "strength": "65mg/25mg",
            "recommendationInstructions": [
              {
                "id": 1667,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 51
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 456,
            "strengthId": 52,
            "strength": "90mg/25mg",
            "recommendationInstructions": [
              {
                "id": 1668,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 52
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 457,
        "recommendationGroupId": 1,
        "position": 13,
        "visible": 1,
        "groupName": "Hormone",
        "body": "DHEA/Progesterone",
        "recommendationCount": 0,
        "productType": "S.R. capsule",
        "bodyWithProduct": "DHEA/Progesterone S.R. capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 457,
            "strengthId": 53,
            "strength": "10mg/100mg",
            "recommendationInstructions": [
              {
                "id": 1669,
                "instruction": "Take one capsule by mouth once daily in the morning.",
                "recommendationId": 0,
                "strengthId": 53
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 457,
            "strengthId": 54,
            "strength": "5mg/200mg",
            "recommendationInstructions": [
              {
                "id": 1670,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 54
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 458,
        "recommendationGroupId": 1,
        "position": 14,
        "visible": 1,
        "groupName": "Hormone",
        "body": "DIM (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "DIM (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 458,
            "strengthId": 55,
            "strength": "300 mg",
            "recommendationInstructions": [
              {
                "id": 1671,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 55
              },
              {
                "id": 1672,
                "instruction": "Take 1 Capsule By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 55
              },
              {
                "id": 1673,
                "instruction": "Take one capsule by mouth once daily. ",
                "recommendationId": 0,
                "strengthId": 55
              },
              {
                "id": 1674,
                "instruction": "Take one capsule by mouth every morning ",
                "recommendationId": 0,
                "strengthId": 55
              },
              {
                "id": 1675,
                "instruction": "Take 1 capsule by mouth daily in the morning ",
                "recommendationId": 0,
                "strengthId": 55
              },
              {
                "id": 1676,
                "instruction": "Take 1 Capsule By Mouth up to Twice Daily ",
                "recommendationId": 0,
                "strengthId": 55
              },
              {
                "id": 1677,
                "instruction": "Take one capsule by mouth each morning",
                "recommendationId": 0,
                "strengthId": 55
              },
              {
                "id": 1678,
                "instruction": "Take one capsule by mouth each morning. ",
                "recommendationId": 0,
                "strengthId": 55
              },
              {
                "id": 1679,
                "instruction": "Take one capsule by mouth daily ",
                "recommendationId": 0,
                "strengthId": 55
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 459,
        "recommendationGroupId": 1,
        "position": 15,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estradiol",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Estradiol cream",
        "recommendationStrengths": [
          {
            "recommendationId": 459,
            "strengthId": 56,
            "strength": "1 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1680,
                "instruction": "Apply 1ml (2 pumps) topically every night at bedtime.",
                "recommendationId": 0,
                "strengthId": 56
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 57,
            "strength": "1.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1681,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 57
              },
              {
                "id": 1682,
                "instruction": "Apply 1ml (2 pumps) topically behind knees daily",
                "recommendationId": 0,
                "strengthId": 57
              },
              {
                "id": 1683,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily. ",
                "recommendationId": 0,
                "strengthId": 57
              },
              {
                "id": 1684,
                "instruction": "Apply 0.5ml (1 pump) Topically daily as tolerated.",
                "recommendationId": 0,
                "strengthId": 57
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 58,
            "strength": "10 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1685,
                "instruction": "Apply 1ml (4 clicks) to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 58
              },
              {
                "id": 1686,
                "instruction": "Apply 1/2ml (1 pump) topically every night at bedtime.",
                "recommendationId": 0,
                "strengthId": 58
              },
              {
                "id": 1687,
                "instruction": "Apply 0.5ml (1 pump) topically daily",
                "recommendationId": 0,
                "strengthId": 58
              },
              {
                "id": 1688,
                "instruction": "Apply 1ml (4 clicks) behind knees daily. ",
                "recommendationId": 0,
                "strengthId": 58
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 59,
            "strength": "12 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1689,
                "instruction": "Apply 2 pumps (1ml) to back of knee daily after shower/exercise or at bedtime",
                "recommendationId": 0,
                "strengthId": 59
              },
              {
                "id": 1690,
                "instruction": "Apply 0.5ml (1 pump) to arm area daily each morning.",
                "recommendationId": 0,
                "strengthId": 59
              },
              {
                "id": 1691,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily  ",
                "recommendationId": 0,
                "strengthId": 59
              },
              {
                "id": 1692,
                "instruction": "Apply 1ml to 2ml (4 to 8 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 59
              },
              {
                "id": 1693,
                "instruction": "Apply 1ml (4 clicks) topically daily",
                "recommendationId": 0,
                "strengthId": 59
              },
              {
                "id": 1694,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 59
              },
              {
                "id": 1695,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 59
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 60,
            "strength": "13.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1696,
                "instruction": "Apply 1ml (2 pumps) topically  to arms every morning after showering.",
                "recommendationId": 0,
                "strengthId": 60
              },
              {
                "id": 1697,
                "instruction": "Apply 0.5ml (2 pumps) topically Twice Daily  ",
                "recommendationId": 0,
                "strengthId": 60
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 61,
            "strength": "14 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1698,
                "instruction": "Apply 1ml (4 clicks) topically daily",
                "recommendationId": 0,
                "strengthId": 61
              },
              {
                "id": 1699,
                "instruction": "Apply 1ml (4 clicks) topically daily.",
                "recommendationId": 0,
                "strengthId": 61
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 62,
            "strength": "15 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1700,
                "instruction": "Apply alternating doses of 1ml (2 pumps) with 1.5ml (3 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 62
              },
              {
                "id": 1701,
                "instruction": "Apply 0.75ml to 1ml (3 to 4 clicks) topically Daily as directed",
                "recommendationId": 0,
                "strengthId": 62
              },
              {
                "id": 1702,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 62
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 63,
            "strength": "16 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1703,
                "instruction": "Apply 0.5ml (2clicks) topically Daily week 1, 0.75ml (3clicks) weeks 2 and 3, 0.5ml (2clicks) week 4",
                "recommendationId": 0,
                "strengthId": 63
              },
              {
                "id": 1704,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 63
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 64,
            "strength": "19 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1705,
                "instruction": "Apply 0.25ml (1 click) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 64
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 65,
            "strength": "2 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1706,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1707,
                "instruction": "Apply 1/2ml to 1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1708,
                "instruction": "Apply 0.5ml-1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1709,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically daily as directed.",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1710,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1711,
                "instruction": "Apply 0.5ml to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1712,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1713,
                "instruction": "Apply 0.5ml (2 clicks) topically daily, titrate up to 1.5ml (6 clicks) daily",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1714,
                "instruction": "Apply 1ml (2 pumps) behind knees daily as directed. 1 pump behind each knee.",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1715,
                "instruction": "Apply 1 ml ( two pumps ) daily as directed",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1716,
                "instruction": "Apply two pumps daily as directed",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1717,
                "instruction": "Apply 0.5ml (2 clicks) topically daily, plus 0.25ml (1 click) as needed for symptoms.",
                "recommendationId": 0,
                "strengthId": 65
              },
              {
                "id": 1718,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically behind knee daily as directed",
                "recommendationId": 0,
                "strengthId": 65
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 66,
            "strength": "2.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1719,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 66
              },
              {
                "id": 1720,
                "instruction": "Apply 0.5ml (1 pump) behind knees Twice Daily ",
                "recommendationId": 0,
                "strengthId": 66
              },
              {
                "id": 1721,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically daily as directed.",
                "recommendationId": 0,
                "strengthId": 66
              },
              {
                "id": 1722,
                "instruction": "Apply 1ml (2 pumps) topically Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 66
              },
              {
                "id": 1723,
                "instruction": "Apply 1ml (2 pumps) behind knee after shower daily as directed",
                "recommendationId": 0,
                "strengthId": 66
              },
              {
                "id": 1724,
                "instruction": "Apply 0.25ml (1 click) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 66
              },
              {
                "id": 1725,
                "instruction": "Apply 0.5ml (1 pump) Topically daily as tolerated.",
                "recommendationId": 0,
                "strengthId": 66
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 67,
            "strength": "21 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1726,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 67
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 68,
            "strength": "3 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1727,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1728,
                "instruction": "Apply 0.5Ml to 1Ml (1 to 2 pumps) to arms Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1729,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1730,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) behind knees Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1731,
                "instruction": "Apply 1/2ml to 1ml (1 to 2 pumps) topically to arms Daily",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1732,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) behind the knees Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1733,
                "instruction": "Apply 1 ml (4 clicks) topically daily ",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1734,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1735,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) behind the knees once daily. ",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1736,
                "instruction": "Apply 1ml (4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1737,
                "instruction": "Apply 0.5ml (2 clicks) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1738,
                "instruction": "Apply 0.5ml (1 pump) topically Daily ",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1739,
                "instruction": "Apply 1ml (2 pumps) Topically Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1740,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically daily. Start with 2 clicks and titrate",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1741,
                "instruction": "Apply 0.5ml (2 clicks) to trunk twice daily.",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1742,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically behind knees daily as directed",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1743,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically behind the knee Daily",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1744,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically days 18 to 28.",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1745,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1746,
                "instruction": "Apply 1 ml (2 pumps) daily as directed",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1747,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1748,
                "instruction": "Apply 2ml (4 pumps) Topically Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1749,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 68
              },
              {
                "id": 1750,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 pumps) topically as directed daily",
                "recommendationId": 0,
                "strengthId": 68
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 69,
            "strength": "3.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1751,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 69
              },
              {
                "id": 1752,
                "instruction": "Apply 1 ml (4 clicks) topically daily ",
                "recommendationId": 0,
                "strengthId": 69
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 70,
            "strength": "4 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1753,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1754,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1755,
                "instruction": "Apply 1ml (4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1756,
                "instruction": "Apply 1ml (2 pumps) topically to arms Daily",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1757,
                "instruction": "Apply 0.5ml to 1ml (2-4 clicks) topically daily.",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1758,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily. Start with 3 clicks.",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1759,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically daily ",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1760,
                "instruction": "Apply 0.5ml (1 pump) topically behind each knee Daily (Total 2 pumps daily)",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1761,
                "instruction": "Apply 0.5ml (1 pump) topically Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1762,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1763,
                "instruction": "Apply 0.5ml (2 clicks) to arm area every morning.",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1764,
                "instruction": "Apply 1 ml (4 clicks) topically daily ",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1765,
                "instruction": "Apply 1ml (2 pumps) to arms Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1766,
                "instruction": "Apply 1ml (4 clicks) topically Every Morning ",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1767,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically daily",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1768,
                "instruction": "Apply 0.5ml (1 pump) to upper arm area every morning.",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1769,
                "instruction": "Apply 1/2ml to 1ml (1 to 2 pumps) topically to arms Daily",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1770,
                "instruction": "Apply 0.5ml (2 clicks) to trunk twice daily.",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1771,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Every Morning ",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1772,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) daily as directed.",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1773,
                "instruction": "Apply 0.5ml (1 pump) topically Daily ",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1774,
                "instruction": "Apply 1ml (2 pumps) behind knee after shower daily as directed",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1775,
                "instruction": "Apply 1 ml (2 pumps) daily as directed",
                "recommendationId": 0,
                "strengthId": 70
              },
              {
                "id": 1776,
                "instruction": "Apply 2 pumps (1ml) to back of knee daily after shower/exercise or at bedtime",
                "recommendationId": 0,
                "strengthId": 70
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 71,
            "strength": "4.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1777,
                "instruction": "Apply 0.5ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 71
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 72,
            "strength": "5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1778,
                "instruction": "Apply 1/2ml (1 pump) Topically Every Morning and 1/2ml (1 pump) Every Evening",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1779,
                "instruction": "Apply 1ml (2 pumps) to thighs Daily ",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1780,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1781,
                "instruction": "Apply 1ml (2 pumps) to upper arm Daily ",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1782,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1783,
                "instruction": "Apply 1ml (4 clicks) behind the knees Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1784,
                "instruction": "Apply 0.5ml (1 pump) topically behind each knee Daily (Total 2 pumps daily)",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1785,
                "instruction": "Apply 1/2ml to 1ml (1 to 2 pumps) topically to arms Daily",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1786,
                "instruction": "Apply 1ml (2 pumps) topically daily to upper arm area daily in the morning as directed.",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1787,
                "instruction": "Apply 0.25 ml ( 1 pump) to 1 ml(4 Pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1788,
                "instruction": "Apply 1ml (4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1789,
                "instruction": "Apply 0.5ml (2 clicks) to trunk twice daily.",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1790,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) behind knees daily. ",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1791,
                "instruction": "Apply 0.5ml (2 pumps) to upper arm Twice Daily as directed",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1792,
                "instruction": "Apply 2ml (8 pumps) to upper arm twice weekly as directed.",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1793,
                "instruction": "Apply 1.5ml (6 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1794,
                "instruction": "Apply 1/2Ml (1 Pump) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1795,
                "instruction": "Apply 0.5ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1796,
                "instruction": "Apply 1ml (1 pump) topically daily",
                "recommendationId": 0,
                "strengthId": 72
              },
              {
                "id": 1797,
                "instruction": "Apply 0.5ml (2 pumps) to upper arm daily as directed.",
                "recommendationId": 0,
                "strengthId": 72
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 73,
            "strength": "6 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1798,
                "instruction": "Apply 1/2ml (1 pump) topically Every Morning and 1/2ml (1 pump) Every Evening",
                "recommendationId": 0,
                "strengthId": 73
              },
              {
                "id": 1799,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 73
              },
              {
                "id": 1800,
                "instruction": "Apply 1/2 gram (2 clicks) Topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 73
              },
              {
                "id": 1801,
                "instruction": "Apply 0.5ml (2 clicks) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 73
              },
              {
                "id": 1802,
                "instruction": "Apply 1ml (4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 73
              },
              {
                "id": 1803,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 73
              },
              {
                "id": 1804,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 73
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 74,
            "strength": "7 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1805,
                "instruction": "Apply 1/2 ml (1 pump) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 74
              },
              {
                "id": 1806,
                "instruction": "Apply 1/2 ml (2 pumps) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 74
              },
              {
                "id": 1807,
                "instruction": "Apply 0.5ml (2 clicks) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 74
              },
              {
                "id": 1808,
                "instruction": "Apply 1ml (2 pumps) behind knees daily. ",
                "recommendationId": 0,
                "strengthId": 74
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 75,
            "strength": "7.5 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1809,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 75
              },
              {
                "id": 1810,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 75
              },
              {
                "id": 1811,
                "instruction": "Apply 1/2Ml (1 Pump) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 75
              },
              {
                "id": 1812,
                "instruction": "Apply 1ml (2 pumps) topically Daily alternating with 1.5ml (3 pumps)",
                "recommendationId": 0,
                "strengthId": 75
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 76,
            "strength": "8 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1813,
                "instruction": "Apply 1ml (2 pumps) topically once daily.",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1814,
                "instruction": "Apply 0.5ml (2 pumps) behind knee Twice Daily ",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1815,
                "instruction": "Apply 0.5ml (1 pump) to arms Twice Daily ",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1816,
                "instruction": "Apply 2 pumps (1ml) to back of knee daily after shower/exercise or at bedtime",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1817,
                "instruction": "Apply 1/2ml  (2 clicks) Topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1818,
                "instruction": "Apply 0.5ml (2 clicks) topically twice a day. may apply additional 0.25ml (1 click) As Needed",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1819,
                "instruction": "Apply 1ml (4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1820,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 pumps) topically daily ",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1821,
                "instruction": "Apply 0.5ml (1 pump) topically daily",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1822,
                "instruction": "Apply 1/2ml (1 pump) topically every night at bedtime.",
                "recommendationId": 0,
                "strengthId": 76
              },
              {
                "id": 1823,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 76
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 459,
            "strengthId": 77,
            "strength": "9 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1824,
                "instruction": "Apply 0.5ml (2 clicks) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1825,
                "instruction": "Apply 0.5ml (2 clicks) topically behind knees Twice Daily ",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1826,
                "instruction": "Apply 1ml (4 clicks) topically daily.",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1827,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1828,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1829,
                "instruction": "Apply 0.5ml (2 pumps) topically Twice Daily  ",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1830,
                "instruction": "Apply 1ml to 1.5ml (2 to 3 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1831,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1832,
                "instruction": "Apply 0.5ml (1 pump) to arm area daily each morning.",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1833,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 77
              },
              {
                "id": 1834,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 77
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 460,
        "recommendationGroupId": 1,
        "position": 16,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estradiol (Mylan)",
        "recommendationCount": 0,
        "productType": "patch",
        "bodyWithProduct": "Estradiol (Mylan) patch",
        "recommendationStrengths": [
          {
            "recommendationId": 460,
            "strengthId": 78,
            "strength": "0.025 mg/24hr",
            "recommendationInstructions": [
              {
                "id": 1835,
                "instruction": "Apply 1 patch topically twice weekly",
                "recommendationId": 0,
                "strengthId": 78
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 460,
            "strengthId": 79,
            "strength": "0.025 mg/day",
            "recommendationInstructions": [
              {
                "id": 1836,
                "instruction": "Apply 1 patch topically twice weekly ",
                "recommendationId": 0,
                "strengthId": 79
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 460,
            "strengthId": 80,
            "strength": "0.0375 mg/24hr",
            "recommendationInstructions": [
              {
                "id": 1837,
                "instruction": "Apply 1 patch topically twice weekly",
                "recommendationId": 0,
                "strengthId": 80
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 460,
            "strengthId": 81,
            "strength": "0.0375 mg/day",
            "recommendationInstructions": [
              {
                "id": 1838,
                "instruction": "Apply one patch topically twice weekly",
                "recommendationId": 0,
                "strengthId": 81
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 460,
            "strengthId": 82,
            "strength": "0.075mg/24hr",
            "recommendationInstructions": [
              {
                "id": 1839,
                "instruction": "Apply 1 patch topically twice weekly ",
                "recommendationId": 0,
                "strengthId": 82
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 460,
            "strengthId": 83,
            "strength": "0.1 mg/24hr",
            "recommendationInstructions": [
              {
                "id": 1840,
                "instruction": "Apply 1 patch topically twice weekly",
                "recommendationId": 0,
                "strengthId": 83
              },
              {
                "id": 1841,
                "instruction": "Apply 1 patch  topically twice weekly",
                "recommendationId": 0,
                "strengthId": 83
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 461,
        "recommendationGroupId": 1,
        "position": 17,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estradiol (Sandoz)",
        "recommendationCount": 0,
        "productType": "patch",
        "bodyWithProduct": "Estradiol (Sandoz) patch",
        "recommendationStrengths": [
          {
            "recommendationId": 461,
            "strengthId": 84,
            "strength": "0.075 mg/day",
            "recommendationInstructions": [
              {
                "id": 1842,
                "instruction": "Apply 1 patch topically twice weekly ",
                "recommendationId": 0,
                "strengthId": 84
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 462,
        "recommendationGroupId": 1,
        "position": 18,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estradiol/Progesterone",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Estradiol/Progesterone cream",
        "recommendationStrengths": [
          {
            "recommendationId": 462,
            "strengthId": 85,
            "strength": "1.5mg/200mg/gm",
            "recommendationInstructions": [
              {
                "id": 1843,
                "instruction": "Apply 1gm topically daily to arm.",
                "recommendationId": 0,
                "strengthId": 85
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 462,
            "strengthId": 86,
            "strength": "2mg/200mg/gm",
            "recommendationInstructions": [
              {
                "id": 1844,
                "instruction": "Apply 1gm topically daily to arm.",
                "recommendationId": 0,
                "strengthId": 86
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 462,
            "strengthId": 87,
            "strength": "2mg/200mg/ml",
            "recommendationInstructions": [
              {
                "id": 1845,
                "instruction": "Apply 1ml (2 pumps) topically daily to arm.",
                "recommendationId": 0,
                "strengthId": 87
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 462,
            "strengthId": 88,
            "strength": "2mg/50mg/ml",
            "recommendationInstructions": [
              {
                "id": 1846,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 88
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 462,
            "strengthId": 89,
            "strength": "3mg/100mg/ml",
            "recommendationInstructions": [
              {
                "id": 1847,
                "instruction": "Apply 0.5ml (1 pump) to upper arm area each morning as needed",
                "recommendationId": 0,
                "strengthId": 89
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 463,
        "recommendationGroupId": 1,
        "position": 19,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estradiol/Progesterone/Testosterone",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Estradiol/Progesterone/Testosterone cream",
        "recommendationStrengths": [
          {
            "recommendationId": 463,
            "strengthId": 90,
            "strength": "10/190/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1848,
                "instruction": "Apply 1ml (2 pumps) topically to legs once daily in the morning as directed. ",
                "recommendationId": 0,
                "strengthId": 90
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 91,
            "strength": "2.5/195/7mg/ml",
            "recommendationInstructions": [
              {
                "id": 1849,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 91
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 92,
            "strength": "2.5/200/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1850,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm every morning. ",
                "recommendationId": 0,
                "strengthId": 92
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 93,
            "strength": "2/195/7mg/ml",
            "recommendationInstructions": [
              {
                "id": 1851,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 93
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 94,
            "strength": "3.5/125/0.75mg/ml",
            "recommendationInstructions": [
              {
                "id": 1852,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 94
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 95,
            "strength": "3.5/180/0.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1853,
                "instruction": "Apply 0.5ml (1 pump) topically daily. ",
                "recommendationId": 0,
                "strengthId": 95
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 96,
            "strength": "3/125/0.75mg/ml",
            "recommendationInstructions": [
              {
                "id": 1854,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 96
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 97,
            "strength": "3/150/0.75mg/ml",
            "recommendationInstructions": [
              {
                "id": 1855,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 97
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 98,
            "strength": "3/190/7mg/ml",
            "recommendationInstructions": [
              {
                "id": 1856,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 98
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 99,
            "strength": "3/75/0.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1857,
                "instruction": "Apply 1/2ml to 1ml (1 to 2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 99
              },
              {
                "id": 1858,
                "instruction": "Apply 1/2ml (1 pump) topically daily.",
                "recommendationId": 0,
                "strengthId": 99
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 100,
            "strength": "4/180/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1859,
                "instruction": "Apply 0.5mL (1 pump) topically once daily.",
                "recommendationId": 0,
                "strengthId": 100
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 101,
            "strength": "4/190/2.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1860,
                "instruction": "Apply 1/2ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 101
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 102,
            "strength": "4/190/7mg/ml",
            "recommendationInstructions": [
              {
                "id": 1861,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 102
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 103,
            "strength": "5/190/7mg/ml",
            "recommendationInstructions": [
              {
                "id": 1862,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 103
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 104,
            "strength": "6/100/3.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1863,
                "instruction": "Apply 0.5 gram (2 clicks) Topically daily",
                "recommendationId": 0,
                "strengthId": 104
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 105,
            "strength": "7/100/3.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1864,
                "instruction": "Apply 0.5 gram (2 clicks) Topically daily",
                "recommendationId": 0,
                "strengthId": 105
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 106,
            "strength": "7/150/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1865,
                "instruction": "Apply 1ml (2 pumps) topically to legs once daily in the morning as directed. ",
                "recommendationId": 0,
                "strengthId": 106
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 463,
            "strengthId": 107,
            "strength": "9/180/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1866,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area every morning.",
                "recommendationId": 0,
                "strengthId": 107
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 464,
        "recommendationGroupId": 1,
        "position": 20,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estradiol/Testosterone",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Estradiol/Testosterone cream",
        "recommendationStrengths": [
          {
            "recommendationId": 464,
            "strengthId": 108,
            "strength": "10mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1867,
                "instruction": "Apply 1ml (2 pumps) topically once daily in the morning to upper arm area.",
                "recommendationId": 0,
                "strengthId": 108
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 109,
            "strength": "10mg/3mg/ml",
            "recommendationInstructions": [
              {
                "id": 1868,
                "instruction": "Apply 0.5ml (1 pump) to arms Twice Daily",
                "recommendationId": 0,
                "strengthId": 109
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 110,
            "strength": "10mg/7mg/ml",
            "recommendationInstructions": [
              {
                "id": 1869,
                "instruction": "Apply 0.5ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 110
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 111,
            "strength": "11mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1870,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area once daily in the morning. ",
                "recommendationId": 0,
                "strengthId": 111
              },
              {
                "id": 1871,
                "instruction": "Apply 1/2ml (1 pump) to upper arms twice daily.",
                "recommendationId": 0,
                "strengthId": 111
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 112,
            "strength": "11mg/9mg/ml",
            "recommendationInstructions": [
              {
                "id": 1872,
                "instruction": "Apply 1ml (4 clicks) topically behind knees daily as directed",
                "recommendationId": 0,
                "strengthId": 112
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 113,
            "strength": "12mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1873,
                "instruction": "Apply 1ml (4 clicks) topically to arm area Every Morning",
                "recommendationId": 0,
                "strengthId": 113
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 114,
            "strength": "12mg/3.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1874,
                "instruction": "Apply 0.5ml (1 pump) to arms Twice Daily",
                "recommendationId": 0,
                "strengthId": 114
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 115,
            "strength": "12mg/3mg/ml",
            "recommendationInstructions": [
              {
                "id": 1875,
                "instruction": "Apply 1ml (4 clicks) topically to arm area Every Morning",
                "recommendationId": 0,
                "strengthId": 115
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 116,
            "strength": "12mg/4mg/ml",
            "recommendationInstructions": [
              {
                "id": 1876,
                "instruction": "Apply 0.5ml (1 pump) to arms Twice Daily",
                "recommendationId": 0,
                "strengthId": 116
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 117,
            "strength": "12mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1877,
                "instruction": "Apply 0.5ml (1 pump) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 117
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 118,
            "strength": "12mg/7.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1878,
                "instruction": "Apply 0.5ml (1 pump) topically to upper arm twice daily.",
                "recommendationId": 0,
                "strengthId": 118
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 119,
            "strength": "14mg/14mg/ml",
            "recommendationInstructions": [
              {
                "id": 1879,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 119
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 120,
            "strength": "14mg/18mg/ml",
            "recommendationInstructions": [
              {
                "id": 1880,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 120
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 121,
            "strength": "16mg/13mg/ml",
            "recommendationInstructions": [
              {
                "id": 1881,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 121
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 122,
            "strength": "1mg/0.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1882,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 122
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 123,
            "strength": "1mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1883,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 123
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 124,
            "strength": "1mg/4.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1884,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 124
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 125,
            "strength": "1mg/4mg/ml",
            "recommendationInstructions": [
              {
                "id": 1885,
                "instruction": "Apply 1ml (4 clicks) to arms Daily ",
                "recommendationId": 0,
                "strengthId": 125
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 126,
            "strength": "2.5mg/0.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1886,
                "instruction": "Apply 0.5ml (1 pump) topically to upper arm area twice daily.",
                "recommendationId": 0,
                "strengthId": 126
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 127,
            "strength": "2.5mg/0.75mg/ml",
            "recommendationInstructions": [
              {
                "id": 1887,
                "instruction": "Apply 0.5ml (1 pump) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 127
              },
              {
                "id": 1888,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 127
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 128,
            "strength": "2.5mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1889,
                "instruction": "Apply 1ml (2 pumps) to upper arm area Every Morning",
                "recommendationId": 0,
                "strengthId": 128
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 129,
            "strength": "2.5mg/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1890,
                "instruction": "Apply 1ml (2 pumps) topically every morning.",
                "recommendationId": 0,
                "strengthId": 129
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 130,
            "strength": "2.5mg/2.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1891,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 130
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 131,
            "strength": "2mg/1.25mg/ml",
            "recommendationInstructions": [
              {
                "id": 1892,
                "instruction": "Apply 1ml (2 pumps) to upper arm area Every Morning",
                "recommendationId": 0,
                "strengthId": 131
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 132,
            "strength": "2mg/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1893,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 132
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 133,
            "strength": "2mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1894,
                "instruction": "Apply 0.25ml to 1ml (1 to 4 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 133
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 134,
            "strength": "2mg/3mg/ml",
            "recommendationInstructions": [
              {
                "id": 1895,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 134
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 135,
            "strength": "3.5/5.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1896,
                "instruction": "Apply 1ml (4 clicks) topically daily.",
                "recommendationId": 0,
                "strengthId": 135
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 136,
            "strength": "3.5mg/5.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1897,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 136
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 137,
            "strength": "3mg/0.75mg/ml",
            "recommendationInstructions": [
              {
                "id": 1898,
                "instruction": "Apply 1ml (2 pumps) topically to arm area daily each morning.",
                "recommendationId": 0,
                "strengthId": 137
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 138,
            "strength": "3mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1899,
                "instruction": "Apply 1ml (2 pumps) topically every morning.",
                "recommendationId": 0,
                "strengthId": 138
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 139,
            "strength": "3mg/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1900,
                "instruction": "Apply 1ml (2 pumps) to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 139
              },
              {
                "id": 1901,
                "instruction": "Apply 1ml (2 pumps) topically to arm area daily each morning.",
                "recommendationId": 0,
                "strengthId": 139
              },
              {
                "id": 1902,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm daily.",
                "recommendationId": 0,
                "strengthId": 139
              },
              {
                "id": 1903,
                "instruction": "Apply 1ml (2 pumps) topically to alternating arms once daily.",
                "recommendationId": 0,
                "strengthId": 139
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 140,
            "strength": "3mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1904,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 140
              },
              {
                "id": 1905,
                "instruction": "Apply 1ml (2 pumps) topically to arm area before bedtime.",
                "recommendationId": 0,
                "strengthId": 140
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 141,
            "strength": "4.5mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1906,
                "instruction": "Apply 1/2ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 141
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 142,
            "strength": "4.5mg/3mg/ml",
            "recommendationInstructions": [
              {
                "id": 1907,
                "instruction": "Apply 1ml (2 pumps) to upper arm area each morning.",
                "recommendationId": 0,
                "strengthId": 142
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 143,
            "strength": "4.5mg/4.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1908,
                "instruction": "Apply 1.5ml (3 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 143
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 144,
            "strength": "4.5mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1909,
                "instruction": "Apply 0.5ml (2 clicks) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 144
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 145,
            "strength": "4mg/0.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1910,
                "instruction": "Apply 1ml (2 pumps) to upper arm area once daily before bedtime.",
                "recommendationId": 0,
                "strengthId": 145
              },
              {
                "id": 1911,
                "instruction": "Apply 0.5ml (1 pump)  topically to upper arm twice daily",
                "recommendationId": 0,
                "strengthId": 145
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 146,
            "strength": "4mg/0.75mg/ml",
            "recommendationInstructions": [
              {
                "id": 1912,
                "instruction": "Apply 0.5ml (1 pump) topically to upper arm area twice daily.",
                "recommendationId": 0,
                "strengthId": 146
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 147,
            "strength": "4mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1913,
                "instruction": "Apply 1/2ml to 1ml (1 to 2 pumps) topically to arm area once daily.",
                "recommendationId": 0,
                "strengthId": 147
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 148,
            "strength": "4mg/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1914,
                "instruction": "Apply 1ml (2 pumps) to arms Daily",
                "recommendationId": 0,
                "strengthId": 148
              },
              {
                "id": 1915,
                "instruction": "Apply 0.5ml (1 pump) topically daily.",
                "recommendationId": 0,
                "strengthId": 148
              },
              {
                "id": 1916,
                "instruction": "Apply 1ml (2 pumps) topically to arm area before bedtime.",
                "recommendationId": 0,
                "strengthId": 148
              },
              {
                "id": 1917,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 148
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 149,
            "strength": "4mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1918,
                "instruction": "Apply 1ml (2 pumps) topically to arm area before bedtime.",
                "recommendationId": 0,
                "strengthId": 149
              },
              {
                "id": 1919,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 149
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 150,
            "strength": "4mg/4mg/ml",
            "recommendationInstructions": [
              {
                "id": 1920,
                "instruction": "Apply 0.5ml (1 pump) topically Daily",
                "recommendationId": 0,
                "strengthId": 150
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 151,
            "strength": "4mg/5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1921,
                "instruction": "Apply 1ml (4 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 151
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 152,
            "strength": "4mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1922,
                "instruction": "Apply 0.5ml (1 pump) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 152
              },
              {
                "id": 1923,
                "instruction": "Apply 1ml (4 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 152
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 153,
            "strength": "5mg/0.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1924,
                "instruction": "Apply 1ml (2 pumps) topically to arm area Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 153
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 154,
            "strength": "5mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1925,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area each morning. ",
                "recommendationId": 0,
                "strengthId": 154
              },
              {
                "id": 1926,
                "instruction": "Apply 0.5ml (2 clicks) topically to upper arm area Every Morning and Every Evening ",
                "recommendationId": 0,
                "strengthId": 154
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 155,
            "strength": "5mg/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1927,
                "instruction": "Apply 0.5ml (1 pump) topically to upper arm twice daily.",
                "recommendationId": 0,
                "strengthId": 155
              },
              {
                "id": 1928,
                "instruction": "Apply 1ml (2 pumps) topically to arm area before bedtime.",
                "recommendationId": 0,
                "strengthId": 155
              },
              {
                "id": 1929,
                "instruction": "Apply 0.5ml (1 pump) topically daily to arms",
                "recommendationId": 0,
                "strengthId": 155
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 156,
            "strength": "5mg/2.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1930,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 156
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 157,
            "strength": "5mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1931,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 157
              },
              {
                "id": 1932,
                "instruction": "Apply 1/2ml (1 pump) topically twice daily as directed.",
                "recommendationId": 0,
                "strengthId": 157
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 158,
            "strength": "5mg/3mg/gm",
            "recommendationInstructions": [
              {
                "id": 1933,
                "instruction": "Apply 1.5gm topically to arm area once daily in the morning.",
                "recommendationId": 0,
                "strengthId": 158
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 159,
            "strength": "5mg/3mg/ml",
            "recommendationInstructions": [
              {
                "id": 1934,
                "instruction": "Apply 0.5ml (1 pump) topically twice daily",
                "recommendationId": 0,
                "strengthId": 159
              },
              {
                "id": 1935,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 159
              },
              {
                "id": 1936,
                "instruction": "Apply 1.5ml (3 pumps) topically to arm area once daily in the morning.",
                "recommendationId": 0,
                "strengthId": 159
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 160,
            "strength": "5mg/4.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1937,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 160
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 161,
            "strength": "5mg/5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1938,
                "instruction": "Apply 1/2ml to 1ml (1 to 2 pumps) topically daily to arms.",
                "recommendationId": 0,
                "strengthId": 161
              },
              {
                "id": 1939,
                "instruction": "Apply 1/2ml (1 pump) topically daily.",
                "recommendationId": 0,
                "strengthId": 161
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 162,
            "strength": "5mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1940,
                "instruction": "Apply 0.5ml (1 pump) topically Daily ",
                "recommendationId": 0,
                "strengthId": 162
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 163,
            "strength": "6.5mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1941,
                "instruction": "Apply 1ml (2 pumps) to arm area Daily",
                "recommendationId": 0,
                "strengthId": 163
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 164,
            "strength": "6mg/0.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1942,
                "instruction": "Apply 1ml (2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 164
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 165,
            "strength": "6mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1943,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area each morning. ",
                "recommendationId": 0,
                "strengthId": 165
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 166,
            "strength": "6mg/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1944,
                "instruction": "Apply 1ml (2 pumps) topically daily.",
                "recommendationId": 0,
                "strengthId": 166
              },
              {
                "id": 1945,
                "instruction": "Apply 0.25ml (1 pump) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 166
              },
              {
                "id": 1946,
                "instruction": "Apply 0.5ml (1 pump) topically daily to arms",
                "recommendationId": 0,
                "strengthId": 166
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 167,
            "strength": "6mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1947,
                "instruction": "Apply 0.5ml (1 pump) topically to alternating arms Twice Daily ",
                "recommendationId": 0,
                "strengthId": 167
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 168,
            "strength": "6mg/3mg/ml",
            "recommendationInstructions": [
              {
                "id": 1948,
                "instruction": "Apply 0.5ml (1 pump) behind each knee daily. Total 2 pumps daily.",
                "recommendationId": 0,
                "strengthId": 168
              },
              {
                "id": 1949,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 168
              },
              {
                "id": 1950,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 168
              },
              {
                "id": 1951,
                "instruction": "Apply 1.5ml (3 pumps) to upper arm area daily.",
                "recommendationId": 0,
                "strengthId": 168
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 169,
            "strength": "6mg/4.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1952,
                "instruction": "Apply 1ml (2 pumps) to arm area once daily in the morning. ",
                "recommendationId": 0,
                "strengthId": 169
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 170,
            "strength": "6mg/4mg/ml",
            "recommendationInstructions": [
              {
                "id": 1953,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 170
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 171,
            "strength": "6mg/5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1954,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 171
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 172,
            "strength": "6mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1955,
                "instruction": "Apply 0.75ml to 1ml (3 to 4 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 172
              },
              {
                "id": 1956,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 172
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 173,
            "strength": "7.5mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1957,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm area once daily in the morning. ",
                "recommendationId": 0,
                "strengthId": 173
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 174,
            "strength": "7mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1958,
                "instruction": "Apply 1ml (2 pumps) topically every morning ",
                "recommendationId": 0,
                "strengthId": 174
              },
              {
                "id": 1959,
                "instruction": "Apply 1ml (2 pumps) topically once daily in the morning to upper arm area.",
                "recommendationId": 0,
                "strengthId": 174
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 175,
            "strength": "7mg/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1960,
                "instruction": "Apply 0.5ml (1 pump) topically daily.",
                "recommendationId": 0,
                "strengthId": 175
              },
              {
                "id": 1961,
                "instruction": "Apply 0.5ml (1 pump) topically to alternating arms Twice Daily ",
                "recommendationId": 0,
                "strengthId": 175
              },
              {
                "id": 1962,
                "instruction": "Apply 1ml (2 pumps) topically to upper arm each morning.",
                "recommendationId": 0,
                "strengthId": 175
              },
              {
                "id": 1963,
                "instruction": "Apply 0.5ml (1 pump) topically daily to arms",
                "recommendationId": 0,
                "strengthId": 175
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 176,
            "strength": "7mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1964,
                "instruction": "Apply 0.5ml (1 pump) topically to alternating arms Twice Daily ",
                "recommendationId": 0,
                "strengthId": 176
              },
              {
                "id": 1965,
                "instruction": "Apply 0.5ml (1 pump) topically to upper arm area twice daily.",
                "recommendationId": 0,
                "strengthId": 176
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 177,
            "strength": "7mg/4mg/ml",
            "recommendationInstructions": [
              {
                "id": 1966,
                "instruction": "Apply 1ml (2 pumps) behind knees daily.",
                "recommendationId": 0,
                "strengthId": 177
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 178,
            "strength": "7mg/5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1967,
                "instruction": "Apply 0.5ml (1 pump) topically to each arm (total 2 pumps) every morning.",
                "recommendationId": 0,
                "strengthId": 178
              },
              {
                "id": 1968,
                "instruction": "Apply 0.5ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 178
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 179,
            "strength": "7mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1969,
                "instruction": "Apply 0.5ml (1 pump) topically to each arm (total 2 pumps) every morning.",
                "recommendationId": 0,
                "strengthId": 179
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 180,
            "strength": "8mg/1mg/ml",
            "recommendationInstructions": [
              {
                "id": 1970,
                "instruction": "Apply 1ml (2 pumps) topically to arm area each morning.",
                "recommendationId": 0,
                "strengthId": 180
              },
              {
                "id": 1971,
                "instruction": "Apply 0.5ml (1 pump) topically to arm area each morning.",
                "recommendationId": 0,
                "strengthId": 180
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 181,
            "strength": "8mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 1972,
                "instruction": "Apply 1ml (2 pumps) topically to arm area each morning.",
                "recommendationId": 0,
                "strengthId": 181
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 182,
            "strength": "8mg/3mg/ml",
            "recommendationInstructions": [
              {
                "id": 1973,
                "instruction": "Apply 0.5ml to 1ml (1 to 2 pumps) topically daily",
                "recommendationId": 0,
                "strengthId": 182
              },
              {
                "id": 1974,
                "instruction": "Apply 1ml (2 pumps) topically every morning",
                "recommendationId": 0,
                "strengthId": 182
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 183,
            "strength": "8mg/5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1975,
                "instruction": "Apply 0.5ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 183
              },
              {
                "id": 1976,
                "instruction": "Apply 0.5ml (2 pumps) topically to arm area once daily in the morning.",
                "recommendationId": 0,
                "strengthId": 183
              },
              {
                "id": 1977,
                "instruction": "Apply 1ml (2 pumps) to arm area once daily each morning as directed.",
                "recommendationId": 0,
                "strengthId": 183
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 184,
            "strength": "8mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1978,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 184
              },
              {
                "id": 1979,
                "instruction": "Apply 1ml (4 clicks) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 184
              },
              {
                "id": 1980,
                "instruction": "Apply 0.5ml (1 pump) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 184
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 185,
            "strength": "9mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1981,
                "instruction": "Apply 1ml (2 pumps) topically to arm area once daily each morning.",
                "recommendationId": 0,
                "strengthId": 185
              },
              {
                "id": 1982,
                "instruction": "Apply 0.25ml (1 pump) topically Twice Daily",
                "recommendationId": 0,
                "strengthId": 185
              },
              {
                "id": 1983,
                "instruction": "Apply 1ml (2 pumps) topically once daily in the morning to upper arm area.",
                "recommendationId": 0,
                "strengthId": 185
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 186,
            "strength": "9mg/12mg/ml",
            "recommendationInstructions": [
              {
                "id": 1984,
                "instruction": "Apply 1ml (2 pumps) topically Daily ",
                "recommendationId": 0,
                "strengthId": 186
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 187,
            "strength": "9mg/3.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1985,
                "instruction": "Apply 1ml (4 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 187
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 188,
            "strength": "9mg/3mg/ml",
            "recommendationInstructions": [
              {
                "id": 1986,
                "instruction": "Apply 0.5ml (1 pump) to arms Twice Daily",
                "recommendationId": 0,
                "strengthId": 188
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 189,
            "strength": "9mg/4.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 1987,
                "instruction": "Apply 0.75ml to 1ml (3 to 4 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 189
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 464,
            "strengthId": 190,
            "strength": "9mg/6mg/ml",
            "recommendationInstructions": [
              {
                "id": 1988,
                "instruction": "Apply 1/2ml (1 pump) to upper arms twice daily.",
                "recommendationId": 0,
                "strengthId": 190
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 465,
        "recommendationGroupId": 1,
        "position": 21,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estriol",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Estriol cream",
        "recommendationStrengths": [
          {
            "recommendationId": 465,
            "strengthId": 191,
            "strength": "3 mg/ml",
            "recommendationInstructions": [
              {
                "id": 1989,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 191
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 466,
        "recommendationGroupId": 1,
        "position": 22,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estriol Versabase",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Estriol Versabase cream",
        "recommendationStrengths": [
          {
            "recommendationId": 466,
            "strengthId": 192,
            "strength": "3 mg/gm",
            "recommendationInstructions": [
              {
                "id": 1990,
                "instruction": "Apply topically to face Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 192
              },
              {
                "id": 1991,
                "instruction": "Apply to face and neck areas daily as directed",
                "recommendationId": 0,
                "strengthId": 192
              },
              {
                "id": 1992,
                "instruction": "Use as directed",
                "recommendationId": 0,
                "strengthId": 192
              },
              {
                "id": 1993,
                "instruction": "Apply to face and neck Every Night At Bedtime As Directed ",
                "recommendationId": 0,
                "strengthId": 192
              },
              {
                "id": 1994,
                "instruction": "Apply 1/2gm to 1gm to face and neck Every Night At Bedtime As Directed ",
                "recommendationId": 0,
                "strengthId": 192
              },
              {
                "id": 1995,
                "instruction": "Apply as directed",
                "recommendationId": 0,
                "strengthId": 192
              },
              {
                "id": 1996,
                "instruction": "Apply to face and neck every morning as directed",
                "recommendationId": 0,
                "strengthId": 192
              },
              {
                "id": 1997,
                "instruction": "Apply to face and neck Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 192
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 467,
        "recommendationGroupId": 1,
        "position": 23,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estriol/Chrysaderm",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Estriol/Chrysaderm cream",
        "recommendationStrengths": [
          {
            "recommendationId": 467,
            "strengthId": 193,
            "strength": "3mg/10%/ml",
            "recommendationInstructions": [
              {
                "id": 1998,
                "instruction": "Apply 1 ml (2 pumps) topically to arms every night at bedtime ",
                "recommendationId": 0,
                "strengthId": 193
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 468,
        "recommendationGroupId": 1,
        "position": 24,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Estriol/Testosterone",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Estriol/Testosterone cream",
        "recommendationStrengths": [
          {
            "recommendationId": 468,
            "strengthId": 194,
            "strength": "0.5mg/0.5mg/gm",
            "recommendationInstructions": [
              {
                "id": 1999,
                "instruction": "Apply 1 gram intravaginally at bedtime",
                "recommendationId": 0,
                "strengthId": 194
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 468,
            "strengthId": 195,
            "strength": "1mg/0.5mg/gm",
            "recommendationInstructions": [
              {
                "id": 2000,
                "instruction": "Apply 1 gram vaginally Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 195
              },
              {
                "id": 2001,
                "instruction": "Insert 1 to 2 grams intra vaginally every night at bedtime",
                "recommendationId": 0,
                "strengthId": 195
              },
              {
                "id": 2002,
                "instruction": "Insert 1gm vaginally Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 195
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 468,
            "strengthId": 196,
            "strength": "2mg/1.5mg/gm",
            "recommendationInstructions": [
              {
                "id": 2003,
                "instruction": "Apply 0.5gm intravaginally Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 196
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 468,
            "strengthId": 197,
            "strength": "2mg/2mg/gm",
            "recommendationInstructions": [
              {
                "id": 2004,
                "instruction": "Apply 0.5gm intravaginally Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 197
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 469,
        "recommendationGroupId": 1,
        "position": 25,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Genotropin",
        "recommendationCount": 0,
        "productType": "mini-quick",
        "bodyWithProduct": "Genotropin mini-quick",
        "recommendationStrengths": [
          {
            "recommendationId": 469,
            "strengthId": 198,
            "strength": "0.4 mg",
            "recommendationInstructions": [
              {
                "id": 2005,
                "instruction": "Inject one syringe subcutaneously daily as directed",
                "recommendationId": 0,
                "strengthId": 198
              },
              {
                "id": 2006,
                "instruction": "Inject one syringe Subcutaneously Every Morning as directed",
                "recommendationId": 0,
                "strengthId": 198
              },
              {
                "id": 2007,
                "instruction": "Inject one syringe subcutaneously every morning as directed.",
                "recommendationId": 0,
                "strengthId": 198
              },
              {
                "id": 2008,
                "instruction": "Mix and inject one syringe each morning.",
                "recommendationId": 0,
                "strengthId": 198
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 469,
            "strengthId": 199,
            "strength": "0.6 mg",
            "recommendationInstructions": [
              {
                "id": 2009,
                "instruction": "Inject 1 syringe (0.6mg) subcutaneously once daily six days a week as directed.",
                "recommendationId": 0,
                "strengthId": 199
              },
              {
                "id": 2010,
                "instruction": "Mix and inject 1 syringe subcutaneously daily as directed",
                "recommendationId": 0,
                "strengthId": 199
              },
              {
                "id": 2011,
                "instruction": "Mix and inject contents of one syringe subcutaneously daily or as directed by physician.",
                "recommendationId": 0,
                "strengthId": 199
              },
              {
                "id": 2012,
                "instruction": "Use as directed by physician.",
                "recommendationId": 0,
                "strengthId": 199
              },
              {
                "id": 2013,
                "instruction": "Inject one syringe Subcutaneously Every Morning as directed",
                "recommendationId": 0,
                "strengthId": 199
              },
              {
                "id": 2014,
                "instruction": "Inject 0.6mg Subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 199
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 469,
            "strengthId": 200,
            "strength": "0.8 mg",
            "recommendationInstructions": [
              {
                "id": 2015,
                "instruction": "Mix and inject one syringe subcutaneously twice daily.",
                "recommendationId": 0,
                "strengthId": 200
              },
              {
                "id": 2016,
                "instruction": "Inject the contents of 1 syringe daily",
                "recommendationId": 0,
                "strengthId": 200
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 470,
        "recommendationGroupId": 1,
        "position": 26,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Levothyroxine (T4) (MYLAN)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Levothyroxine (T4) (MYLAN) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 470,
            "strengthId": 201,
            "strength": "125 mcg",
            "recommendationInstructions": [
              {
                "id": 2017,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 201
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 470,
            "strengthId": 202,
            "strength": "175 mcg",
            "recommendationInstructions": [
              {
                "id": 2018,
                "instruction": "Take 1 Tablet By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 202
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 470,
            "strengthId": 203,
            "strength": "75 mcg",
            "recommendationInstructions": [
              {
                "id": 2019,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 203
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 471,
        "recommendationGroupId": 1,
        "position": 27,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Liothyronine (T3)",
        "recommendationCount": 0,
        "productType": "S.R. capsule",
        "bodyWithProduct": "Liothyronine (T3) S.R. capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 471,
            "strengthId": 204,
            "strength": "10 mcg",
            "recommendationInstructions": [
              {
                "id": 2020,
                "instruction": "Take one capsule by mouth once daily in the afternoon.",
                "recommendationId": 0,
                "strengthId": 204
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 471,
            "strengthId": 205,
            "strength": "5 mcg",
            "recommendationInstructions": [
              {
                "id": 2021,
                "instruction": "Take up to 2 capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 205
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 472,
        "recommendationGroupId": 1,
        "position": 28,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Liothyronine (T3) (Greenstone)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Liothyronine (T3) (Greenstone) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 472,
            "strengthId": 206,
            "strength": "5 mcg",
            "recommendationInstructions": [
              {
                "id": 2022,
                "instruction": "Take 1 Tablet By Mouth Every Morning and 2 tablets By Mouth every afternoon ",
                "recommendationId": 0,
                "strengthId": 206
              },
              {
                "id": 2023,
                "instruction": "Take 1 Tablet By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 206
              },
              {
                "id": 2024,
                "instruction": "Take 2 tablets by mouth every morning",
                "recommendationId": 0,
                "strengthId": 206
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 473,
        "recommendationGroupId": 1,
        "position": 29,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Liothyronine (T3) (Mayne)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Liothyronine (T3) (Mayne) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 473,
            "strengthId": 207,
            "strength": "5 mcg",
            "recommendationInstructions": [
              {
                "id": 2025,
                "instruction": "Take 1 Tablet By Mouth Every Morning and 2 tablets By Mouth every afternoon",
                "recommendationId": 0,
                "strengthId": 207
              },
              {
                "id": 2026,
                "instruction": "Take 2 Tablets By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 207
              },
              {
                "id": 2027,
                "instruction": "Take 2 Tablets By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 207
              },
              {
                "id": 2028,
                "instruction": "Take 1 Tablet By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 207
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 474,
        "recommendationGroupId": 1,
        "position": 30,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Liothyronine (T3)/Levothyroxine (T4)",
        "recommendationCount": 0,
        "productType": "S.R. capsule",
        "bodyWithProduct": "Liothyronine (T3)/Levothyroxine (T4) S.R. capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 474,
            "strengthId": 208,
            "strength": "10mcg/30mcg",
            "recommendationInstructions": [
              {
                "id": 2029,
                "instruction": "Take one capsule by mouth twice daily.",
                "recommendationId": 0,
                "strengthId": 208
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 474,
            "strengthId": 210,
            "strength": "15mcg/30mcg",
            "recommendationInstructions": [
              {
                "id": 2031,
                "instruction": "Take one capsule by mouth twice daily.",
                "recommendationId": 0,
                "strengthId": 210
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 474,
            "strengthId": 211,
            "strength": "20mcg/30mcg",
            "recommendationInstructions": [
              {
                "id": 2032,
                "instruction": "Take one capsule by mouth twice daily.",
                "recommendationId": 0,
                "strengthId": 211
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 474,
            "strengthId": 212,
            "strength": "25mcg/125mcg",
            "recommendationInstructions": [
              {
                "id": 2033,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 212
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 474,
            "strengthId": 213,
            "strength": "25mcg/75mcg",
            "recommendationInstructions": [
              {
                "id": 2034,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 213
              },
              {
                "id": 2035,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 213
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 474,
            "strengthId": 214,
            "strength": "30mcg/110mcg",
            "recommendationInstructions": [
              {
                "id": 2036,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 214
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 474,
            "strengthId": 217,
            "strength": "7.5mcg/100mcg",
            "recommendationInstructions": [
              {
                "id": 2039,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 217
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 475,
        "recommendationGroupId": 1,
        "position": 31,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Liothyronine (T3)/Levothyroxine (T4)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Liothyronine (T3)/Levothyroxine (T4) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 475,
            "strengthId": 209,
            "strength": "13.5mcg/57mcg",
            "recommendationInstructions": [
              {
                "id": 2030,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 209
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 475,
            "strengthId": 215,
            "strength": "5mcg/25mcg",
            "recommendationInstructions": [
              {
                "id": 2037,
                "instruction": "Take 1 to 2 capsules by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 215
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 476,
        "recommendationGroupId": 1,
        "position": 32,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Liothyronine (T3)/Levothyroxine (T4)",
        "recommendationCount": 0,
        "productType": "clear capsule",
        "bodyWithProduct": "Liothyronine (T3)/Levothyroxine (T4) clear capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 476,
            "strengthId": 216,
            "strength": "5mcg/30mcg",
            "recommendationInstructions": [
              {
                "id": 2038,
                "instruction": "Take one capsule by mouth daily.",
                "recommendationId": 0,
                "strengthId": 216
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 477,
        "recommendationGroupId": 1,
        "position": 33,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Minivelle",
        "recommendationCount": 0,
        "productType": "patch",
        "bodyWithProduct": "Minivelle patch",
        "recommendationStrengths": [
          {
            "recommendationId": 477,
            "strengthId": 218,
            "strength": "0.05 mg/24hr",
            "recommendationInstructions": [
              {
                "id": 2040,
                "instruction": "Apply 1 patch topically twice weekly",
                "recommendationId": 0,
                "strengthId": 218
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 477,
            "strengthId": 219,
            "strength": "0.1 mg/24hr",
            "recommendationInstructions": [
              {
                "id": 2041,
                "instruction": "Apply 1 patch twice weekly",
                "recommendationId": 0,
                "strengthId": 219
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 478,
        "recommendationGroupId": 1,
        "position": 34,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Natesto",
        "recommendationCount": 0,
        "productType": "gel",
        "bodyWithProduct": "Natesto gel",
        "recommendationStrengths": [
          {
            "recommendationId": 478,
            "strengthId": 220,
            "strength": "5.5mg/0.122gm",
            "recommendationInstructions": [
              {
                "id": 2042,
                "instruction": "Instill 1 spray in each nostril Three Times A Day",
                "recommendationId": 0,
                "strengthId": 220
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 479,
        "recommendationGroupId": 1,
        "position": 35,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Nature-Throid",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Nature-Throid tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 479,
            "strengthId": 221,
            "strength": "1 grain (65mg)",
            "recommendationInstructions": [
              {
                "id": 2043,
                "instruction": "Take 1 Tablet By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 221
              },
              {
                "id": 2044,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 221
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 480,
        "recommendationGroupId": 1,
        "position": 36,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Norditropin FlexPro",
        "recommendationCount": 0,
        "productType": "pen",
        "bodyWithProduct": "Norditropin FlexPro pen",
        "recommendationStrengths": [
          {
            "recommendationId": 480,
            "strengthId": 222,
            "strength": "10 mg/1.5 ml",
            "recommendationInstructions": [
              {
                "id": 2045,
                "instruction": "Dial and inject 0.5mg subcutaneously each morning as directed.",
                "recommendationId": 0,
                "strengthId": 222
              },
              {
                "id": 2046,
                "instruction": "Inject 0.6mg subcutaneously each day",
                "recommendationId": 0,
                "strengthId": 222
              },
              {
                "id": 2047,
                "instruction": "Dial and inject 0.5mg Subcutaneously Daily ",
                "recommendationId": 0,
                "strengthId": 222
              },
              {
                "id": 2048,
                "instruction": "Dial and inject 0.8mg subcutaneously each morning.",
                "recommendationId": 0,
                "strengthId": 222
              },
              {
                "id": 2049,
                "instruction": "Dial and inject 0.65mg subcutaneously daily.",
                "recommendationId": 0,
                "strengthId": 222
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 480,
            "strengthId": 223,
            "strength": "15 mg/1.5 ml",
            "recommendationInstructions": [
              {
                "id": 2050,
                "instruction": "Dial and inject 1mg Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 223
              },
              {
                "id": 2051,
                "instruction": "Dial and inject 0.6mg Subcutaneously six days per week",
                "recommendationId": 0,
                "strengthId": 223
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 480,
            "strengthId": 224,
            "strength": "5 mg/1.5 ml",
            "recommendationInstructions": [
              {
                "id": 2052,
                "instruction": "Dial and inject 0.5mg Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2053,
                "instruction": "Dial and inject 0.35mg Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2054,
                "instruction": "Dial and inject 0.5mg Subcutaneously Every Morning",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2055,
                "instruction": "Dial and inject 0.35mg subcutaneously each morning as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2056,
                "instruction": "Dial and inject 0.5mg subcutaneously each morning as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2057,
                "instruction": "Dial and inject 0.6mg Subcutaneously Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2058,
                "instruction": "Dial and inject 0.6mg Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2059,
                "instruction": "Dial and inject 0.6mg Subcutaneously Every Morning ",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2060,
                "instruction": "Dial and inject 0.35mg subcutaneously every morning.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2061,
                "instruction": "Dial and inject 0.5mg subcutaneously five days a week, as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2062,
                "instruction": "Dial and inject 0.5mg subcutaneously each day as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2063,
                "instruction": "Dial and inject 0.4 mg subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2064,
                "instruction": "Dial and inject 0.9mg subcutaneously each morning as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2065,
                "instruction": "Dial and inject 0.6mg subcutaneously every night at bedtime.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2066,
                "instruction": "Dial and inject 0.5mg subcutaneously once daily.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2067,
                "instruction": "Dial and inject 0.4mg subcutaneously once daily 5 days a week as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2068,
                "instruction": "Dial and inject 0.5mg subcutaneously once daily as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2069,
                "instruction": "Dial and inject 0.45mg subcutaneously each morning.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2070,
                "instruction": "Dial and inject 0.25mg to 0.35mg Subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2071,
                "instruction": "Dial and inject 0.5mg subcutaneously every morning.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2072,
                "instruction": "Dial and inject 0.5 mg subcutaneously every morning.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2073,
                "instruction": "Inject 0.5mg subcutaneously every morning 5 days per week.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2074,
                "instruction": "Dial and inject 0.4mg subcutaneously each morning.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2075,
                "instruction": "Dial and inject 0.35mg subcutaneously once daily.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2076,
                "instruction": "Dial and inject 0.5mg Subcutaneously daily for 5 days per week.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2077,
                "instruction": "Inject 0.6mg subcutaneously each day",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2078,
                "instruction": "Inject 0.4mg Subcutaneously Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2079,
                "instruction": "Dial and inject 0.6mg subcutaneously each morning",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2080,
                "instruction": "Inject 0.5mg subcutaneously every morning ",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2081,
                "instruction": "Inject 0.45 mg Subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2082,
                "instruction": "Iinject 0.35mg Subcutaneously Daily ",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2083,
                "instruction": "Dial and inject 0.35mg Subcutaneously Daily increase as directed",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2084,
                "instruction": "Inject 0.5mg subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2085,
                "instruction": "Dial and inject 0.6mg Subcutaneously five days per week in the morning",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2086,
                "instruction": "Dial and inject 0.4mg subcutaneously once daily as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2087,
                "instruction": "Inject 0.25mg Subcutaneously Daily ",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2088,
                "instruction": "Dial and inject 0.35mg Subcutaneously Every Morning ",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2089,
                "instruction": "Dial and inject 0.5 mg daily",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2090,
                "instruction": "Dial and inject 0.6mg subcutaneously each morning.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2091,
                "instruction": "Dial and inject 0.8mg subcutaneously each morning as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2092,
                "instruction": "Dial and inject 0.8mg Subcutaneously 6 days weekly",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2093,
                "instruction": "Dial and inject 0.35mg subcutaneously each day as directed.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2094,
                "instruction": "Dial and inject 0.25mg to 0.35mg subcutaneously every morning.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2095,
                "instruction": "Dial and inject 0.25mg subcutaneously every morning.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2096,
                "instruction": "Dial and inject up to 0.5mg Subcutaneously daily.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2097,
                "instruction": "Dial and inject 0.5mg Subcutaneously Daily increase as directed",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2098,
                "instruction": "Dial and inject 0.5mg subcutaneous daily ",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2099,
                "instruction": "Dial and inject 0.5mg subcutaneously each morning.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2100,
                "instruction": "Dial and inject 0.9mg Subcutaneously Daily ",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2101,
                "instruction": "Dial and inject 0.7mg Subcutaneously Every Morning ",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2102,
                "instruction": "Dial and inject 0.35mg subcutaneously daily.",
                "recommendationId": 0,
                "strengthId": 224
              },
              {
                "id": 2103,
                "instruction": "Dial and inject 0.4mg Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 224
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 481,
        "recommendationGroupId": 1,
        "position": 37,
        "visible": 1,
        "groupName": "Hormone",
        "body": "NP Thyroid",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "NP Thyroid tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 481,
            "strengthId": 225,
            "strength": "0.25 grain",
            "recommendationInstructions": [
              {
                "id": 2104,
                "instruction": "Take 1 Tablet By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2105,
                "instruction": "Take 2 Tablets By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2106,
                "instruction": "Take 1 Tablet By Mouth every afternoon",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2107,
                "instruction": "Take 1 Tablet By Mouth Every Morning with 0.5 grain",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2108,
                "instruction": "Take 1 Tablet By Mouth Every Morning with the 1 grain ",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2109,
                "instruction": "Take 1 Tablet By Mouth at 3pm",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2110,
                "instruction": "Take 2 Tablets By Mouth Every Morning and 1 By Mouth at 12pm",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2111,
                "instruction": "Take 1 tablet in the morning and 1 tablet in the afternoon",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2112,
                "instruction": "Take 1 Tablet By Mouth daily at 3pm",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2113,
                "instruction": "Take 1 Tablet By Mouth Daily with the 0.5 grain",
                "recommendationId": 0,
                "strengthId": 225
              },
              {
                "id": 2114,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 225
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 481,
            "strengthId": 226,
            "strength": "0.5 grain",
            "recommendationInstructions": [
              {
                "id": 2115,
                "instruction": "Take 1 Tablet By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2116,
                "instruction": "Take 1 Tablet By Mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2117,
                "instruction": "Take 1 Tablet By Mouth Every afternoon",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2118,
                "instruction": "Take one tablet by mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2119,
                "instruction": "Take 1 tablet By Mouth every afternoon.",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2120,
                "instruction": "Take 1 tablet by mouth daily         ",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2121,
                "instruction": "Take one tablet by mouth every morning.           ",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2122,
                "instruction": "Take one tablet by mouth once daily at 3:00 pm ",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2123,
                "instruction": "Take 1 Tablet By Mouth at 12pm",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2124,
                "instruction": "Take 1 Tablet By Mouth every morning and 1 every afternoon",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2125,
                "instruction": "Take 1 Tablet By Mouth Every morning and one in the evening",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2126,
                "instruction": "Take 1 Tablet By Mouth at 3pm",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2127,
                "instruction": "Take 1 Tablet By Mouth at noon",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2128,
                "instruction": "Take 1 tablet By Mouth Every Morning with 0.25grain and 1 tablet at noon",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2129,
                "instruction": "Take one capsule by mouth once daily in the morning ",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2130,
                "instruction": "Take 1 and 1/2 tablet By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2131,
                "instruction": "Take 2 Tablets By Mouth every morning and 1 tablet at 12pm ",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2132,
                "instruction": "Take 2 Tablets By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2133,
                "instruction": "Take 1 Tablet By Mouth Every day at noon",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2134,
                "instruction": "Take 1 Tablet By Mouth Daily with the 0.25 grain",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2135,
                "instruction": "Take 1 Tablet By Mouth Twice Daily with the 1 grain",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2136,
                "instruction": "Take 1 to 2 tablets By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 226
              },
              {
                "id": 2137,
                "instruction": "Take 1 Tablet By Mouth Every Morning with the 1 grain",
                "recommendationId": 0,
                "strengthId": 226
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 481,
            "strengthId": 227,
            "strength": "1 grain",
            "recommendationInstructions": [
              {
                "id": 2138,
                "instruction": "Take 1 Tablet By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2139,
                "instruction": "Take 1 Tablet By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2140,
                "instruction": "Take one tablet by mouth every morning ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2141,
                "instruction": "Take 1 Tablet By Mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2142,
                "instruction": "Take one tablet by mouth once daily. ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2143,
                "instruction": "Take 1 Tablet By Mouth Every Morning with one 0.25grain tablet",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2144,
                "instruction": "Take 1 Tablet By Mouth Every Morning and take 1 tablet every day at 3pm. ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2145,
                "instruction": "Take one tablet by mouth each morning.    ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2146,
                "instruction": "Take 1 Tablet By Mouth Daily at 12pm ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2147,
                "instruction": "Take 1 Tablet By Mouth Every Morning with the 0.25 grain ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2148,
                "instruction": "Take two tablets by mouth daily in the morning and 2 tablets at 11 am. ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2149,
                "instruction": "Take 2 Tablets By Mouth Daily as directed",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2150,
                "instruction": "Take 1 Tablet By Mouth Every Morning and Every Evening ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2151,
                "instruction": "Take 1 Tablet By Mouth Every Morning and 1 every afternoon ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2152,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2153,
                "instruction": "Take 1Tablet By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2154,
                "instruction": "Take one tablet by mouth twice daily. ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2155,
                "instruction": "Take 1 Tablet By Mouth Every Morning and 1/2 tablet at 12pm. ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2156,
                "instruction": "Take 1 Tablet By Mouth Daily in the morning and 1/2 tablet every afternoon. ",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2157,
                "instruction": "Take 1 Tablet By Mouth Every Morning with the 0.5 grain",
                "recommendationId": 0,
                "strengthId": 227
              },
              {
                "id": 2158,
                "instruction": "Take one tablet by mouth every morning on an empty stomach. chew before swallowing. ",
                "recommendationId": 0,
                "strengthId": 227
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 481,
            "strengthId": 228,
            "strength": "1.5 grain",
            "recommendationInstructions": [
              {
                "id": 2159,
                "instruction": "Take 1 Tablet By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2160,
                "instruction": "Take one tablet by mouth every morning ",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2161,
                "instruction": "Take 1 Tablet By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2162,
                "instruction": "Take 1 Tablet By Mouth daily",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2163,
                "instruction": "Take one tablet by mouth every morning.    ",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2164,
                "instruction": "Take 1 tablet By Mouth Every Morning and 1/2 tablet at 3pm",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2165,
                "instruction": "Take one tablet by mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2166,
                "instruction": "Take one tablet by mouth in the morning and 1/2 tablet in the afternoon. ",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2167,
                "instruction": "Take one tablet by mouth daily.",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2168,
                "instruction": "Take 1/2 tablet By Mouth Every Morning and 1/2 tablet at noon. ",
                "recommendationId": 0,
                "strengthId": 228
              },
              {
                "id": 2169,
                "instruction": "Take 1 Tablet By Mouth Every Morning on an empty stomach ",
                "recommendationId": 0,
                "strengthId": 228
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 482,
        "recommendationGroupId": 1,
        "position": 38,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Omnitrope",
        "recommendationCount": 0,
        "productType": "injectable",
        "bodyWithProduct": "Omnitrope injectable",
        "recommendationStrengths": [
          {
            "recommendationId": 482,
            "strengthId": 229,
            "strength": "5.8 mg",
            "recommendationInstructions": [
              {
                "id": 2170,
                "instruction": "Mix and inject 0.35mg (0.07ml or 7 units on an insulin syringe) Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2171,
                "instruction": "Mix 1.14ml bacteriostatic water. inject 0.75mg (0.15ml or 15 units on syringe) Subcutaneously Daily ",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2172,
                "instruction": "Mix and inject 0.6mg (0.12ml or 12 units on insulin syringe) Subcutaneously Daily ",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2173,
                "instruction": "Mix with 3.5ml and inject 0.5mg (0.3ml or 30 units on syringe) Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2174,
                "instruction": "Mix with 1.14ml water and inject 0.35mg (0.07ml or 7 lines on an insulin syringe) Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2175,
                "instruction": "Mix with bacteriostatic water and inject 0.35mg (0.07ml or 7 units on syringe) Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2176,
                "instruction": "Mix and inject 0.08ml (or 8 units on syringe) subcutaneously daily as directed.",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2177,
                "instruction": "Mix with 1.14ml water and inject 0.5mg (0.1ml or 10 units on syringe) Subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2178,
                "instruction": "Mix with bacteriostatic water and inject 0.5mg (0.1ml or 10 units on syringe) Subcutaneously Daily ",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2179,
                "instruction": "Mix and inject 0.5mg (0.1ml or 10 units on an insulin syringe) Subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2180,
                "instruction": "Mix with 1.14 ml and inject 0.5mg (0.1ml or 10 units on syringe) under the skin once daily.",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2181,
                "instruction": "Mix and inject 0.35mg (0.07ml or 7 lines on insulin syringe) Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2182,
                "instruction": "Mix as directed and inject 0.35mg (0.07ml or 7 lines on syringe) Subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2183,
                "instruction": "Mix as directed, inject 0.5mg (0.1ml or 10 units on insulin syringe) subcutaneously daily.",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2184,
                "instruction": "Mix with 1.14ml diluent and inject 0.6mg (0.12ml or 12 lines on an insulin syringe) Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2185,
                "instruction": "Mix as directed and inject 0.5mg (0.1ml or 10 units on syringe) Subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2186,
                "instruction": "Mix with bacteriostatic water and inject 0.35mg Daily (0.07ml to 7 units on an insulin syringe) ",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2187,
                "instruction": "Mix and inject 0.5mg (0.1ml or 10 lines on insulin syringe) Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2188,
                "instruction": "Mix with bacteriostatic water and inject 0.35mg (0.07ml to 7 units on an insulin syringe",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2189,
                "instruction": "Mix with bacteriostatic water and inject 0.35mg (0.07ml to 7 units on an insulin syringe Daily ",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2190,
                "instruction": "Mix with 1.12ml Bacteriostatic water, Inject 0.5mg (0.1ml or 10 units on syringe) Subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2191,
                "instruction": "Mix with 1.14 ml and inject 0.75mg (0.15ml or 15 units on syringe) under the skin once daily.",
                "recommendationId": 0,
                "strengthId": 229
              },
              {
                "id": 2192,
                "instruction": "Inject 0.25mg (0.05mL or 5 units on an insulin syringe) Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 229
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 483,
        "recommendationGroupId": 1,
        "position": 39,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Oxytocin",
        "recommendationCount": 0,
        "productType": "spray",
        "bodyWithProduct": "Oxytocin spray",
        "recommendationStrengths": [
          {
            "recommendationId": 483,
            "strengthId": 230,
            "strength": "100 units/ml",
            "recommendationInstructions": [
              {
                "id": 2193,
                "instruction": "Instill 1-2 sprays twice to four times daily",
                "recommendationId": 0,
                "strengthId": 230
              },
              {
                "id": 2194,
                "instruction": "Instill 1-2 sprays up to four times daily as needed and directed",
                "recommendationId": 0,
                "strengthId": 230
              },
              {
                "id": 2195,
                "instruction": "Instill 1 to 2 sprays two to four times daily",
                "recommendationId": 0,
                "strengthId": 230
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 484,
        "recommendationGroupId": 1,
        "position": 40,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Pregnenolone",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Pregnenolone capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 484,
            "strengthId": 231,
            "strength": "30 mg",
            "recommendationInstructions": [
              {
                "id": 2196,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 231
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 484,
            "strengthId": 232,
            "strength": "50 mg",
            "recommendationInstructions": [
              {
                "id": 2197,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 232
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 485,
        "recommendationGroupId": 1,
        "position": 41,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Pregnenolone/Progesterone",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Pregnenolone/Progesterone capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 485,
            "strengthId": 233,
            "strength": "50mg/150mg",
            "recommendationInstructions": [
              {
                "id": 2198,
                "instruction": "Take one capsule by mouth at bedtime",
                "recommendationId": 0,
                "strengthId": 233
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 486,
        "recommendationGroupId": 1,
        "position": 42,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Pregnyl (Merck)",
        "recommendationCount": 0,
        "productType": "injectable",
        "bodyWithProduct": "Pregnyl (Merck) injectable",
        "recommendationStrengths": [
          {
            "recommendationId": 486,
            "strengthId": 234,
            "strength": "10,000 units",
            "recommendationInstructions": [
              {
                "id": 2199,
                "instruction": "Mix with 3ml bacteriostatic water and inject 0.25ml Subcutaneously twice weekly",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2200,
                "instruction": "Mix with 3ml bacteriostatic water and inject 0.25ml Subcutaneously Three times weekly ",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2201,
                "instruction": "Mix with 4ml bacteriostatic water and inject 0.25ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2202,
                "instruction": "Mix with 5ml bacteriostatic water and inject 0.25ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2203,
                "instruction": "Mix with 3ml bacteriostatic water and inject 0.25ml Three times weekly ",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2204,
                "instruction": "Dilute with 3.5ml of bacteriostatic water and inject 0.3ml subcutaneously daily",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2205,
                "instruction": "Dilute with 7ml of bacteriostatic water and inject 0.2ml to 0.56 ml  twice weekly ",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2206,
                "instruction": "Mix with 5ml bacteriostatic water and inject 0.25ml twice weekly. ",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2207,
                "instruction": "Mix with 5ml bacteriostatic water and inject 0.125ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2208,
                "instruction": "Mix with 5ml bacteriostatic water and inject 0.1ml Subcutaneously Daily ",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2209,
                "instruction": "Mix with 3ml bacteriostatic water and inject 0.25ml sucutaneously Three times weekly",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2210,
                "instruction": "Mix and inject 0.25ml Subcutaneously three times weekly or as directed",
                "recommendationId": 0,
                "strengthId": 234
              },
              {
                "id": 2211,
                "instruction": "Mix with 5ml BW and inject 0.25ml Daily Subcutaneously ",
                "recommendationId": 0,
                "strengthId": 234
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 487,
        "recommendationGroupId": 1,
        "position": 43,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Progesterone",
        "recommendationCount": 0,
        "productType": "S.R. capsule",
        "bodyWithProduct": "Progesterone S.R. capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 487,
            "strengthId": 235,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2212,
                "instruction": "Take 1 capsule by mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2213,
                "instruction": "Take one capsule by mouth before bedtime.",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2214,
                "instruction": "Take one capsule by mouth once daily at bedtime.",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2215,
                "instruction": "Take one capsule by mouth every night at bedtime.",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2216,
                "instruction": "Take one capsule by mouth at bedtime",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2217,
                "instruction": "Take one capsule by mouth before bedtime .",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2218,
                "instruction": "Take 1 to 2 Capsules By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2219,
                "instruction": "Take 1 capsule by mouth once daily at bedtime.",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2220,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime days 14 thru 28",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2221,
                "instruction": "Take one capsule by mouth on days 1-14 of month.",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2222,
                "instruction": "Take one capsule by mouth every night at bedtime ",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2223,
                "instruction": "Take one capsule by mouth before bedtime ",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2224,
                "instruction": "Take one capsule by mouth every evening at bedtime ",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2225,
                "instruction": "Take 1 to 2 capsules by mouth every night at bedtime on days 15-28.",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2226,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime days 15-28",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2227,
                "instruction": "Take 1 Capsule By Mouth Every other Night At Bedtime alternating with 2 at bedtime",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2228,
                "instruction": "Take two capsules by mouth before bedtime ",
                "recommendationId": 0,
                "strengthId": 235
              },
              {
                "id": 2229,
                "instruction": "Take two capsules by mouth every night at bedtime on days 15-28.",
                "recommendationId": 0,
                "strengthId": 235
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 487,
            "strengthId": 240,
            "strength": "125 mg",
            "recommendationInstructions": [
              {
                "id": 2238,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 240
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 487,
            "strengthId": 241,
            "strength": "150 mg",
            "recommendationInstructions": [
              {
                "id": 2239,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 241
              },
              {
                "id": 2240,
                "instruction": "Take one capsule by mouth before bedtime.",
                "recommendationId": 0,
                "strengthId": 241
              },
              {
                "id": 2241,
                "instruction": "Take one capsule by mouth every night at bedtime.",
                "recommendationId": 0,
                "strengthId": 241
              },
              {
                "id": 2242,
                "instruction": "Take one capsule by mouth before bedtime ",
                "recommendationId": 0,
                "strengthId": 241
              },
              {
                "id": 2243,
                "instruction": "Take one capsule by mouth every evening at bedtime ",
                "recommendationId": 0,
                "strengthId": 241
              },
              {
                "id": 2244,
                "instruction": "Take one capsule by mouth once daily at bedtime.",
                "recommendationId": 0,
                "strengthId": 241
              },
              {
                "id": 2245,
                "instruction": "Take 1 Capsule By Mouth twice weekly",
                "recommendationId": 0,
                "strengthId": 241
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 487,
            "strengthId": 243,
            "strength": "200 mg",
            "recommendationInstructions": [
              {
                "id": 2249,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2250,
                "instruction": "Take one capsule by mouth before bedtime ",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2251,
                "instruction": "Take one capsule by mouth before bedtime.",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2252,
                "instruction": "Take one capsule by mouth once daily at bedtime.",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2253,
                "instruction": "Take one capsule by mouth every night at bedtime.",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2254,
                "instruction": "Take 1 capsule by mouth once daily before bedtime.",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2255,
                "instruction": "Take 1 Capsule By Mouth Every Night ",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2256,
                "instruction": "Take 1 capsule by mouth before bedtime.",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2257,
                "instruction": "Take one capsule by mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2258,
                "instruction": "Take 1 capsule By Mouth Every Night At Bedtime days 15-28",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2259,
                "instruction": "Take one capsule by mouth before bedtime on days 15-28 of your cycle.",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2260,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime days 1 thru 14. ",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2261,
                "instruction": "Take one capsule by mouth every evening at bedtime ",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2262,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime days 15-28. ",
                "recommendationId": 0,
                "strengthId": 243
              },
              {
                "id": 2263,
                "instruction": "Take one capsule by mouth once daily before bedtime.",
                "recommendationId": 0,
                "strengthId": 243
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 487,
            "strengthId": 246,
            "strength": "25 mg",
            "recommendationInstructions": [
              {
                "id": 2273,
                "instruction": "Take 1 to 2 Capsules By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 246
              },
              {
                "id": 2274,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 246
              },
              {
                "id": 2275,
                "instruction": "Take 2 capsules by mouth alternating with 3 capsules every night at bedtime.",
                "recommendationId": 0,
                "strengthId": 246
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 487,
            "strengthId": 249,
            "strength": "250 mg",
            "recommendationInstructions": [
              {
                "id": 2281,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 249
              },
              {
                "id": 2282,
                "instruction": "Take one capsule by mouth before bedtime on days 15-28 of your cycle.",
                "recommendationId": 0,
                "strengthId": 249
              },
              {
                "id": 2283,
                "instruction": "Take one capsule by mouth before bedtime.",
                "recommendationId": 0,
                "strengthId": 249
              },
              {
                "id": 2284,
                "instruction": "Take one capsule by mouth once daily at bedtime.",
                "recommendationId": 0,
                "strengthId": 249
              },
              {
                "id": 2285,
                "instruction": "Take one capsule by mouth every evening at bedtime",
                "recommendationId": 0,
                "strengthId": 249
              },
              {
                "id": 2286,
                "instruction": "Take 1 Capsule By Mouth days 13-28",
                "recommendationId": 0,
                "strengthId": 249
              },
              {
                "id": 2287,
                "instruction": "Take 1 capsule by mouth before bedtime.",
                "recommendationId": 0,
                "strengthId": 249
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 487,
            "strengthId": 250,
            "strength": "300 mg",
            "recommendationInstructions": [
              {
                "id": 2288,
                "instruction": "Take one capsule by mouth nightly at bedtime on days 1-14 of cycle.",
                "recommendationId": 0,
                "strengthId": 250
              },
              {
                "id": 2289,
                "instruction": "Take 1 capsule by mouth before bedtime on days 15-28 of your cycle.",
                "recommendationId": 0,
                "strengthId": 250
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 487,
            "strengthId": 252,
            "strength": "50 mg",
            "recommendationInstructions": [
              {
                "id": 2291,
                "instruction": "Take one capsule by mouth before bedtime.",
                "recommendationId": 0,
                "strengthId": 252
              },
              {
                "id": 2292,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 252
              },
              {
                "id": 2293,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime with 200mg",
                "recommendationId": 0,
                "strengthId": 252
              },
              {
                "id": 2294,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime with the 100mg",
                "recommendationId": 0,
                "strengthId": 252
              },
              {
                "id": 2295,
                "instruction": "Take 1 to 2 Capsules By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 252
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 487,
            "strengthId": 256,
            "strength": "75 mg",
            "recommendationInstructions": [
              {
                "id": 2301,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 256
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 488,
        "recommendationGroupId": 1,
        "position": 44,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Progesterone",
        "recommendationCount": 0,
        "productType": "suppository",
        "bodyWithProduct": "Progesterone suppository",
        "recommendationStrengths": [
          {
            "recommendationId": 488,
            "strengthId": 236,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2230,
                "instruction": "Unwrap and insert 1 suppository vaginally Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 236
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 488,
            "strengthId": 248,
            "strength": "25 mg",
            "recommendationInstructions": [
              {
                "id": 2279,
                "instruction": "Insert 1 suppository vaginally Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 248
              },
              {
                "id": 2280,
                "instruction": "Insert 1 suppository vaginally Every Night At Bedtime days 15-28",
                "recommendationId": 0,
                "strengthId": 248
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 488,
            "strengthId": 254,
            "strength": "50 mg",
            "recommendationInstructions": [
              {
                "id": 2298,
                "instruction": "Insert 1 suppository vaginally Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 254
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 489,
        "recommendationGroupId": 1,
        "position": 45,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Progesterone",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Progesterone capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 489,
            "strengthId": 237,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2231,
                "instruction": "Take one capsule by mouth before bedtime. ",
                "recommendationId": 0,
                "strengthId": 237
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 489,
            "strengthId": 244,
            "strength": "200 mg",
            "recommendationInstructions": [
              {
                "id": 2264,
                "instruction": "Take one capsule by mouth every night at bedtime",
                "recommendationId": 0,
                "strengthId": 244
              },
              {
                "id": 2265,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 244
              },
              {
                "id": 2266,
                "instruction": "Take one capsule by mouth before bedtime. ",
                "recommendationId": 0,
                "strengthId": 244
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 489,
            "strengthId": 247,
            "strength": "25 mg",
            "recommendationInstructions": [
              {
                "id": 2276,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime.",
                "recommendationId": 0,
                "strengthId": 247
              },
              {
                "id": 2277,
                "instruction": "Take 1 to 3 Capsules By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 247
              },
              {
                "id": 2278,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 247
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 489,
            "strengthId": 251,
            "strength": "35 mg",
            "recommendationInstructions": [
              {
                "id": 2290,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime.",
                "recommendationId": 0,
                "strengthId": 251
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 489,
            "strengthId": 253,
            "strength": "50 mg",
            "recommendationInstructions": [
              {
                "id": 2296,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 253
              },
              {
                "id": 2297,
                "instruction": "Take one capsule by mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 253
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 490,
        "recommendationGroupId": 1,
        "position": 46,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Progesterone",
        "recommendationCount": 0,
        "productType": "S.R. Dye Free Vcap ",
        "bodyWithProduct": "Progesterone S.R. Dye Free Vcap ",
        "recommendationStrengths": [
          {
            "recommendationId": 490,
            "strengthId": 238,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2232,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 238
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 491,
        "recommendationGroupId": 1,
        "position": 47,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Progesterone",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Progesterone cream",
        "recommendationStrengths": [
          {
            "recommendationId": 491,
            "strengthId": 239,
            "strength": "100 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2233,
                "instruction": "Apply 1ml (2 pumps) to thighs or abdomen at bedtime.",
                "recommendationId": 0,
                "strengthId": 239
              },
              {
                "id": 2234,
                "instruction": "Apply 1/2ml to 1ml (1 to 2 pumps) topically Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 239
              },
              {
                "id": 2235,
                "instruction": "Apply 1ml (2 pumps) topically on days 1-14 of month.",
                "recommendationId": 0,
                "strengthId": 239
              },
              {
                "id": 2236,
                "instruction": "Apply 1ml (2 pumps) to arms Daily",
                "recommendationId": 0,
                "strengthId": 239
              },
              {
                "id": 2237,
                "instruction": "Apply 1ml (4 pumps) topically Every Night At Bedtime as directed",
                "recommendationId": 0,
                "strengthId": 239
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 491,
            "strengthId": 242,
            "strength": "150 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2246,
                "instruction": "Apply 1ml (2 pumps) to upper arm area Daily",
                "recommendationId": 0,
                "strengthId": 242
              },
              {
                "id": 2247,
                "instruction": "Apply 1ml (2 pumps) topically Daily",
                "recommendationId": 0,
                "strengthId": 242
              },
              {
                "id": 2248,
                "instruction": "Apply 1ml (4 clicks) topically daily",
                "recommendationId": 0,
                "strengthId": 242
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 491,
            "strengthId": 245,
            "strength": "200 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2267,
                "instruction": "Apply 0.5ml (2 clicks) topically Twice Daily ",
                "recommendationId": 0,
                "strengthId": 245
              },
              {
                "id": 2268,
                "instruction": "Apply 1.5ml (6 clicks) topically Daily ",
                "recommendationId": 0,
                "strengthId": 245
              },
              {
                "id": 2269,
                "instruction": "Apply 1ml (1 pump) to arms Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 245
              },
              {
                "id": 2270,
                "instruction": "Apply 2ml (4 pumps) topically Daily alternating with 2.5ml (5 pumps) every other day",
                "recommendationId": 0,
                "strengthId": 245
              },
              {
                "id": 2271,
                "instruction": "Apply 1ml (4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 245
              },
              {
                "id": 2272,
                "instruction": "Apply 1 ml(1 pump ) topically daily",
                "recommendationId": 0,
                "strengthId": 245
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 491,
            "strengthId": 255,
            "strength": "50 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2299,
                "instruction": "Apply 0.5ml (2 clicks) topically twice daily.",
                "recommendationId": 0,
                "strengthId": 255
              },
              {
                "id": 2300,
                "instruction": "Apply 0.5ml (1 pump) every night at bedtime to arms on days 15-28 of cycle as tolerated",
                "recommendationId": 0,
                "strengthId": 255
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 492,
        "recommendationGroupId": 1,
        "position": 48,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Progesterone (AKORN)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Progesterone (AKORN) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 492,
            "strengthId": 257,
            "strength": "200 mg",
            "recommendationInstructions": [
              {
                "id": 2302,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 257
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 493,
        "recommendationGroupId": 1,
        "position": 49,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Progesterone (REDDY)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Progesterone (REDDY) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 493,
            "strengthId": 258,
            "strength": "200 mg",
            "recommendationInstructions": [
              {
                "id": 2303,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 258
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 494,
        "recommendationGroupId": 1,
        "position": 50,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Progesterone (Virtus)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Progesterone (Virtus) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 494,
            "strengthId": 259,
            "strength": "200 mg",
            "recommendationInstructions": [
              {
                "id": 2304,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 259
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 495,
        "recommendationGroupId": 1,
        "position": 51,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Progesterone/Testosterone",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Progesterone/Testosterone cream",
        "recommendationStrengths": [
          {
            "recommendationId": 495,
            "strengthId": 260,
            "strength": "100mg/2mg/ml",
            "recommendationInstructions": [
              {
                "id": 2305,
                "instruction": "Apply 0.5ml to 1ml (2 to 4 clicks) topically Daily",
                "recommendationId": 0,
                "strengthId": 260
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 495,
            "strengthId": 261,
            "strength": "190mg/1.5mg/ml",
            "recommendationInstructions": [
              {
                "id": 2306,
                "instruction": "Apply 1ml (2 pumps) topically daily as directed",
                "recommendationId": 0,
                "strengthId": 261
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 495,
            "strengthId": 262,
            "strength": "200mg/0.75mg/ml",
            "recommendationInstructions": [
              {
                "id": 2307,
                "instruction": "Apply 1ml (2 pumps) topically Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 262
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 496,
        "recommendationGroupId": 1,
        "position": 52,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Prometrium (Virtus)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Prometrium (Virtus) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 496,
            "strengthId": 263,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2308,
                "instruction": "Insert 1 capsule vaginally days 1-10 ",
                "recommendationId": 0,
                "strengthId": 263
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 497,
        "recommendationGroupId": 1,
        "position": 53,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Synthroid (Abbott90)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Synthroid (Abbott90) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 497,
            "strengthId": 264,
            "strength": "137 mcg",
            "recommendationInstructions": [
              {
                "id": 2309,
                "instruction": "Take 1 Tablet By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 264
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 499,
        "recommendationGroupId": 1,
        "position": 55,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Testosterone Cypionate (Cipla)",
        "recommendationCount": 0,
        "productType": "injectable",
        "bodyWithProduct": "Testosterone Cypionate (Cipla) injectable",
        "recommendationStrengths": [
          {
            "recommendationId": 499,
            "strengthId": 303,
            "strength": "200 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2607,
                "instruction": "Inject 0.45ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 303
              },
              {
                "id": 2608,
                "instruction": "Inject 1ml Subcutaneously Every Week ",
                "recommendationId": 0,
                "strengthId": 303
              },
              {
                "id": 2609,
                "instruction": "Inject 0.5ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 303
              },
              {
                "id": 2610,
                "instruction": "Inject 0.18ml Subcutaneously Three times weekly ",
                "recommendationId": 0,
                "strengthId": 303
              },
              {
                "id": 2611,
                "instruction": "Inject 0.2ml Subcutaneously Every Other Day ",
                "recommendationId": 0,
                "strengthId": 303
              },
              {
                "id": 2612,
                "instruction": "Inject 0.2ml Subcutaneously Three times weekly  ",
                "recommendationId": 0,
                "strengthId": 303
              },
              {
                "id": 2613,
                "instruction": "Inject 0.3ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 303
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 500,
        "recommendationGroupId": 1,
        "position": 56,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Testosterone Cypionate (Premier)",
        "recommendationCount": 0,
        "productType": "injectable",
        "bodyWithProduct": "Testosterone Cypionate (Premier) injectable",
        "recommendationStrengths": [
          {
            "recommendationId": 500,
            "strengthId": 304,
            "strength": "200 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2614,
                "instruction": "Inject 0.18ml subcutaneously three times a week",
                "recommendationId": 0,
                "strengthId": 304
              },
              {
                "id": 2615,
                "instruction": "Inject 0.3ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 304
              },
              {
                "id": 2616,
                "instruction": "Inject 0.5ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 304
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 501,
        "recommendationGroupId": 1,
        "position": 57,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Testosterone Cypionate (Wilshire)",
        "recommendationCount": 0,
        "productType": "injectable",
        "bodyWithProduct": "Testosterone Cypionate (Wilshire) injectable",
        "recommendationStrengths": [
          {
            "recommendationId": 501,
            "strengthId": 305,
            "strength": "200 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2617,
                "instruction": "Inject 0.18ml subcutaneously three times a week ",
                "recommendationId": 0,
                "strengthId": 305
              },
              {
                "id": 2618,
                "instruction": "Inject 0.2ml Subcutaneously Every Other Day ",
                "recommendationId": 0,
                "strengthId": 305
              },
              {
                "id": 2619,
                "instruction": "Inject 0.5ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 305
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 502,
        "recommendationGroupId": 1,
        "position": 58,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Testosterone Enanthate (WW)",
        "recommendationCount": 0,
        "productType": "injectable",
        "bodyWithProduct": "Testosterone Enanthate (WW) injectable",
        "recommendationStrengths": [
          {
            "recommendationId": 502,
            "strengthId": 306,
            "strength": "200 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2620,
                "instruction": "Inject 0.3ml Subcutaneously twice weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2621,
                "instruction": "Inject 0.2ml Subcutaneously Three times weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2622,
                "instruction": "Inject 0.25ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2623,
                "instruction": "Inject 0.35ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2624,
                "instruction": "Inject 0.3ml Subcutaneously Three times weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2625,
                "instruction": "Inject 0.25ml subcutaneously three times weekly.",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2626,
                "instruction": "Inject 0.22ml Subcutaneously Three times weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2627,
                "instruction": "Inject 0.3ml Subcutaneously twice weekly As Directed ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2628,
                "instruction": "Inject 0.35ml subcutaneously twice weekly.",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2629,
                "instruction": "Inject 0.3ml subcutaneously twice weekly.",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2630,
                "instruction": "Inject 0.35ml Subcutaneously Three times weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2631,
                "instruction": "Inject 0.2ml subcutaneously three times weekly.",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2632,
                "instruction": "Inject 0.18ml Subcutaneously three times weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2633,
                "instruction": "Inject 0.25ml subcutaneously twice weekly.",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2634,
                "instruction": "Inject 0.275ml subcutaneously Three times weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2635,
                "instruction": "Inject 0.25ml subcutaneously Three times weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2636,
                "instruction": "Inject 0.45ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2637,
                "instruction": " Inject 0.3ml Subcutaneously twice weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2638,
                "instruction": "Inject 0.13ml Subcutaneously Twice a week",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2639,
                "instruction": "Inject 0.2ml Subcutaneously twice weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2640,
                "instruction": "Inject 0.5ml Subcutaneously on Tuesday and 0.3ml on Friday",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2641,
                "instruction": "Inject 0.25 ml subcutaneously three times weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2642,
                "instruction": "Inject 0.2ml Subcutaneously Three times weekly or 0.3ml Subcutaneously twice weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2643,
                "instruction": "Inject 0.5ml Subcutaneously Three times weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2644,
                "instruction": "Inject 0.3ml Subcutaneously twice weekly on Tuesday and Friday ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2645,
                "instruction": "Inject 0.4ml Subcutaneously Three times weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2646,
                "instruction": "Inject 0.35 ml intramuscularly twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2647,
                "instruction": "Inject 0.27ml Subcutaneously Three times weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2648,
                "instruction": "Inject 0.4ml Subcutaneously twice weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2649,
                "instruction": "Inject 0.3ml subcutaneously  twice weekly",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2650,
                "instruction": "Inject 0.35 ml subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2651,
                "instruction": "Inject 0.25ml subcutaneously twice a week ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2652,
                "instruction": "Inject 0.1ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2653,
                "instruction": "Inject 0.6ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2654,
                "instruction": "Inject 0.5ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2655,
                "instruction": "Inject 0.03ml Subcutaneously twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2656,
                "instruction": "Inject 0.23ml Subcutaneously three times weekly  ",
                "recommendationId": 0,
                "strengthId": 306
              },
              {
                "id": 2657,
                "instruction": "Inject 0.25 ml intramuscularly twice weekly ",
                "recommendationId": 0,
                "strengthId": 306
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 503,
        "recommendationGroupId": 1,
        "position": 59,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Thyroid",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Thyroid capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 503,
            "strengthId": 307,
            "strength": "0.5 grain (30mg)",
            "recommendationInstructions": [
              {
                "id": 2658,
                "instruction": "Take one capsule by mouth once daily in the morning",
                "recommendationId": 0,
                "strengthId": 307
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 503,
            "strengthId": 308,
            "strength": "1 grain (60mg)",
            "recommendationInstructions": [
              {
                "id": 2659,
                "instruction": "Take 1 Capsule By Mouth every afternoon.",
                "recommendationId": 0,
                "strengthId": 308
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 503,
            "strengthId": 309,
            "strength": "1.25 grain (75mg)",
            "recommendationInstructions": [
              {
                "id": 2660,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 309
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 503,
            "strengthId": 310,
            "strength": "1.5 grain (90mg)",
            "recommendationInstructions": [
              {
                "id": 2661,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 310
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 503,
            "strengthId": 311,
            "strength": "2 grain (120mg)",
            "recommendationInstructions": [
              {
                "id": 2662,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 311
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 504,
        "recommendationGroupId": 1,
        "position": 60,
        "visible": 1,
        "groupName": "Hormone",
        "body": "Vivelle-Dot",
        "recommendationCount": 0,
        "productType": "patch",
        "bodyWithProduct": "Vivelle-Dot patch",
        "recommendationStrengths": [
          {
            "recommendationId": 504,
            "strengthId": 312,
            "strength": "0.05 mg/24hr",
            "recommendationInstructions": [
              {
                "id": 2663,
                "instruction": "Apply 1 patch twice weekly.",
                "recommendationId": 0,
                "strengthId": 312
              },
              {
                "id": 2664,
                "instruction": "Apply 1 patch topically twice weekly ",
                "recommendationId": 0,
                "strengthId": 312
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 504,
            "strengthId": 313,
            "strength": "0.075 mg/24hr",
            "recommendationInstructions": [
              {
                "id": 2665,
                "instruction": "Apply 1 patch twice weekly.",
                "recommendationId": 0,
                "strengthId": 313
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 504,
            "strengthId": 314,
            "strength": "0.1 mg/24 hr",
            "recommendationInstructions": [
              {
                "id": 2666,
                "instruction": "Apply 1 patch every other day as directed.",
                "recommendationId": 0,
                "strengthId": 314
              },
              {
                "id": 2667,
                "instruction": "Apply 1 patch topically twice weekly",
                "recommendationId": 0,
                "strengthId": 314
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 504,
            "strengthId": 433,
            "strength": "0.0375 mg/24 hr",
            "recommendationInstructions": [
              {
                "id": 3024,
                "instruction": "Apply twice weekly as directed",
                "recommendationId": 0,
                "strengthId": 433
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 505,
        "recommendationGroupId": 2,
        "position": 61,
        "visible": 1,
        "groupName": "Other",
        "body": "Alcohol Prep",
        "recommendationCount": 0,
        "productType": "pad",
        "bodyWithProduct": "Alcohol Prep pad",
        "recommendationStrengths": [
          {
            "recommendationId": 505,
            "strengthId": 315,
            "strength": "Alcohol Prep",
            "recommendationInstructions": [
              {
                "id": 2668,
                "instruction": "Use as directed",
                "recommendationId": 0,
                "strengthId": 315
              },
              {
                "id": 2669,
                "instruction": "As Directed ",
                "recommendationId": 0,
                "strengthId": 315
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 506,
        "recommendationGroupId": 2,
        "position": 62,
        "visible": 1,
        "groupName": "Other",
        "body": "Alcohol Swabs",
        "recommendationCount": 0,
        "productType": null,
        "bodyWithProduct": "Alcohol Swabs",
        "recommendationStrengths": [
          {
            "recommendationId": 506,
            "strengthId": 316,
            "strength": "BD",
            "recommendationInstructions": [
              {
                "id": 2670,
                "instruction": "Use as directed ",
                "recommendationId": 0,
                "strengthId": 316
              },
              {
                "id": 2671,
                "instruction": "As Directed  ",
                "recommendationId": 0,
                "strengthId": 316
              },
              {
                "id": 2672,
                "instruction": "Use as directed.",
                "recommendationId": 0,
                "strengthId": 316
              },
              {
                "id": 2673,
                "instruction": "Use to clean the vial top and area for injection",
                "recommendationId": 0,
                "strengthId": 316
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 507,
        "recommendationGroupId": 2,
        "position": 63,
        "visible": 1,
        "groupName": "Other",
        "body": "Bacteriostatic Water (HW)",
        "recommendationCount": 0,
        "productType": null,
        "bodyWithProduct": "Bacteriostatic Water (HW)",
        "recommendationStrengths": [
          {
            "recommendationId": 507,
            "strengthId": 317,
            "strength": "30 ml",
            "recommendationInstructions": [
              {
                "id": 2674,
                "instruction": "Use as directed",
                "recommendationId": 0,
                "strengthId": 317
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 508,
        "recommendationGroupId": 2,
        "position": 64,
        "visible": 1,
        "groupName": "Other",
        "body": "Facial Cream (RM)",
        "recommendationCount": 0,
        "productType": null,
        "bodyWithProduct": "Facial Cream (RM)",
        "recommendationStrengths": [
          {
            "recommendationId": 508,
            "strengthId": 318,
            "strength": "Facial Cream (RM)",
            "recommendationInstructions": [
              {
                "id": 2675,
                "instruction": "Use as directed",
                "recommendationId": 0,
                "strengthId": 318
              },
              {
                "id": 2676,
                "instruction": "Apply to face and neck every night at bedtime as directed",
                "recommendationId": 0,
                "strengthId": 318
              },
              {
                "id": 2677,
                "instruction": "Apply to face and neck areas Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 318
              },
              {
                "id": 2678,
                "instruction": "Apply topically to face Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 318
              },
              {
                "id": 2679,
                "instruction": "Apply to face and neck Daily",
                "recommendationId": 0,
                "strengthId": 318
              },
              {
                "id": 2680,
                "instruction": "Apply to face and neck Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 318
              },
              {
                "id": 2681,
                "instruction": "Apply to face and neck as directed Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 318
              },
              {
                "id": 2682,
                "instruction": "Apply 1/2gm to 1gm to face and neck Every Night At Bedtime as directed",
                "recommendationId": 0,
                "strengthId": 318
              },
              {
                "id": 2683,
                "instruction": "Apply topically as directed",
                "recommendationId": 0,
                "strengthId": 318
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 509,
        "recommendationGroupId": 2,
        "position": 65,
        "visible": 1,
        "groupName": "Other",
        "body": "Measuring Stick",
        "recommendationCount": 0,
        "productType": null,
        "bodyWithProduct": "Measuring Stick",
        "recommendationStrengths": [
          {
            "recommendationId": 509,
            "strengthId": 319,
            "strength": "Measuring Stick",
            "recommendationInstructions": null,
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 510,
        "recommendationGroupId": 2,
        "position": 66,
        "visible": 1,
        "groupName": "Other",
        "body": "Medi-Cool",
        "recommendationCount": 0,
        "productType": null,
        "bodyWithProduct": "Medi-Cool",
        "recommendationStrengths": [
          {
            "recommendationId": 510,
            "strengthId": 320,
            "strength": "Pack",
            "recommendationInstructions": [
              {
                "id": 2684,
                "instruction": "Use as directed ",
                "recommendationId": 0,
                "strengthId": 320
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 511,
        "recommendationGroupId": 2,
        "position": 67,
        "visible": 1,
        "groupName": "Other",
        "body": "Placebo",
        "recommendationCount": 0,
        "productType": "cream",
        "bodyWithProduct": "Placebo cream",
        "recommendationStrengths": [
          {
            "recommendationId": 511,
            "strengthId": 321,
            "strength": "0 mg/gm",
            "recommendationInstructions": [
              {
                "id": 2685,
                "instruction": "Use as directed",
                "recommendationId": 0,
                "strengthId": 321
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 512,
        "recommendationGroupId": 2,
        "position": 68,
        "visible": 1,
        "groupName": "Other",
        "body": "Placebo",
        "recommendationCount": 0,
        "productType": "Phytobase cream",
        "bodyWithProduct": "Placebo Phytobase cream",
        "recommendationStrengths": [
          {
            "recommendationId": 512,
            "strengthId": 322,
            "strength": "0 mg/gm",
            "recommendationInstructions": [
              {
                "id": 2686,
                "instruction": "Use as directed",
                "recommendationId": 0,
                "strengthId": 322
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 513,
        "recommendationGroupId": 3,
        "position": 69,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Anastrozole",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Anastrozole capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 513,
            "strengthId": 323,
            "strength": "0.1 mg",
            "recommendationInstructions": [
              {
                "id": 2687,
                "instruction": "Take 1 Capsule By Mouth twice weekly ",
                "recommendationId": 0,
                "strengthId": 323
              },
              {
                "id": 2688,
                "instruction": "Take 1 capsule By Mouth Every Other Day",
                "recommendationId": 0,
                "strengthId": 323
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 513,
            "strengthId": 324,
            "strength": "0.25 mg",
            "recommendationInstructions": [
              {
                "id": 2689,
                "instruction": "Take 1 Capsule By Mouth twice weekly",
                "recommendationId": 0,
                "strengthId": 324
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 513,
            "strengthId": 325,
            "strength": "0.5 mg",
            "recommendationInstructions": [
              {
                "id": 2690,
                "instruction": "Take 1 Capsule By Mouth twice weekly ",
                "recommendationId": 0,
                "strengthId": 325
              },
              {
                "id": 2691,
                "instruction": "Take 1 Capsule By Mouth weekly",
                "recommendationId": 0,
                "strengthId": 325
              },
              {
                "id": 2692,
                "instruction": "Take 1 Capsule By Mouth Three times weekly ",
                "recommendationId": 0,
                "strengthId": 325
              },
              {
                "id": 2693,
                "instruction": "Take 1 capsule By Mouth twice weekly with injections",
                "recommendationId": 0,
                "strengthId": 325
              },
              {
                "id": 2694,
                "instruction": "Take 1 Capsule By Mouth Every Week",
                "recommendationId": 0,
                "strengthId": 325
              },
              {
                "id": 2695,
                "instruction": "Take 1 Capsule By Mouth on twice weekly ",
                "recommendationId": 0,
                "strengthId": 325
              },
              {
                "id": 2696,
                "instruction": "Take 1 Capsule By Mouth twice to three times weekly",
                "recommendationId": 0,
                "strengthId": 325
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 514,
        "recommendationGroupId": 3,
        "position": 70,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Armodafinil (Mylan)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Armodafinil (Mylan) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 514,
            "strengthId": 326,
            "strength": "50 mg",
            "recommendationInstructions": [
              {
                "id": 2697,
                "instruction": "Take 2 Tablets By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 326
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 515,
        "recommendationGroupId": 3,
        "position": 71,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Armodafinil (Teva)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Armodafinil (Teva) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 515,
            "strengthId": 327,
            "strength": "50 mg",
            "recommendationInstructions": [
              {
                "id": 2698,
                "instruction": "Take 2 Tablets By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 327
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 516,
        "recommendationGroupId": 3,
        "position": 72,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Cialis",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Cialis tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 516,
            "strengthId": 328,
            "strength": "20 mg",
            "recommendationInstructions": [
              {
                "id": 2699,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 328
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 516,
            "strengthId": 329,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2700,
                "instruction": "Take 1 Tablet By Mouth As Needed before intercourse",
                "recommendationId": 0,
                "strengthId": 329
              },
              {
                "id": 2701,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 329
              },
              {
                "id": 2702,
                "instruction": "Take one tablet by mouth every day.",
                "recommendationId": 0,
                "strengthId": 329
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 517,
        "recommendationGroupId": 3,
        "position": 73,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Cortef",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Cortef tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 517,
            "strengthId": 330,
            "strength": "10 mg",
            "recommendationInstructions": [
              {
                "id": 2703,
                "instruction": "Take one tablet by mouth twice daily.",
                "recommendationId": 0,
                "strengthId": 330
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 518,
        "recommendationGroupId": 3,
        "position": 74,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Depo-Testosterone (PFIZER)",
        "recommendationCount": 0,
        "productType": "injectable",
        "bodyWithProduct": "Depo-Testosterone (PFIZER) injectable",
        "recommendationStrengths": [
          {
            "recommendationId": 518,
            "strengthId": 331,
            "strength": "100 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2704,
                "instruction": "Inject 0.2ml Subcutaneously Daily",
                "recommendationId": 0,
                "strengthId": 331
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 518,
            "strengthId": 332,
            "strength": "200 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2705,
                "instruction": "Inject 0.7ml Intramuscularly Every Week ",
                "recommendationId": 0,
                "strengthId": 332
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 519,
        "recommendationGroupId": 3,
        "position": 75,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Erythromycin",
        "recommendationCount": 0,
        "productType": "dye free capsule",
        "bodyWithProduct": "Erythromycin dye free capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 519,
            "strengthId": 333,
            "strength": "50 mg",
            "recommendationInstructions": [
              {
                "id": 2706,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 333
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 520,
        "recommendationGroupId": 3,
        "position": 76,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Finasteride",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Finasteride capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 520,
            "strengthId": 334,
            "strength": "0.5 mg",
            "recommendationInstructions": [
              {
                "id": 2707,
                "instruction": "Take 1 Capsule By Mouth twice weekly",
                "recommendationId": 0,
                "strengthId": 334
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 521,
        "recommendationGroupId": 3,
        "position": 77,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Finasteride (AURO)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Finasteride (AURO) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 521,
            "strengthId": 335,
            "strength": "1 mg",
            "recommendationInstructions": [
              {
                "id": 2708,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 335
              },
              {
                "id": 2709,
                "instruction": "Take one tablet by mouth four days each week. ",
                "recommendationId": 0,
                "strengthId": 335
              },
              {
                "id": 2710,
                "instruction": "Take 1 Tablet By Mouth twice weekly ",
                "recommendationId": 0,
                "strengthId": 335
              },
              {
                "id": 2711,
                "instruction": "Take 1 Tablet By Mouth Three times weekly",
                "recommendationId": 0,
                "strengthId": 335
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 522,
        "recommendationGroupId": 3,
        "position": 78,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Fluoxetine (Auro)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Fluoxetine (Auro) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 522,
            "strengthId": 336,
            "strength": "10 mg",
            "recommendationInstructions": [
              {
                "id": 2712,
                "instruction": "Take one capsule By Mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 336
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 523,
        "recommendationGroupId": 3,
        "position": 79,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Fluoxetine HCl (TEVA)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Fluoxetine HCl (TEVA) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 523,
            "strengthId": 337,
            "strength": "10 mg",
            "recommendationInstructions": [
              {
                "id": 2713,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 337
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 524,
        "recommendationGroupId": 3,
        "position": 80,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Hydrocortisone (Greenstone)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Hydrocortisone (Greenstone) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 524,
            "strengthId": 338,
            "strength": "10 mg",
            "recommendationInstructions": [
              {
                "id": 2714,
                "instruction": "Take 1 tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 338
              },
              {
                "id": 2715,
                "instruction": "Take 1/2 tablet By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 338
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 524,
            "strengthId": 339,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2716,
                "instruction": "Take 2 Tablets By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 339
              },
              {
                "id": 2717,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 339
              },
              {
                "id": 2718,
                "instruction": "Take 1 Tablet By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 339
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 525,
        "recommendationGroupId": 3,
        "position": 81,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Metformin (Caraco)",
        "recommendationCount": 0,
        "productType": "E.R tablet",
        "bodyWithProduct": "Metformin (Caraco) E.R tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 525,
            "strengthId": 340,
            "strength": "500 mg",
            "recommendationInstructions": [
              {
                "id": 2719,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 340
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 526,
        "recommendationGroupId": 3,
        "position": 82,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Naltrexone",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Naltrexone capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 526,
            "strengthId": 341,
            "strength": "1.5 mg",
            "recommendationInstructions": [
              {
                "id": 2720,
                "instruction": "Take up to 3 Capsules By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 341
              },
              {
                "id": 2721,
                "instruction": "Take 1 to 3 capsules by mouth daily. Increase as directed",
                "recommendationId": 0,
                "strengthId": 341
              },
              {
                "id": 2722,
                "instruction": "Take 1-3 capsules By Mouth Every Night At Bedtime titrate as directed",
                "recommendationId": 0,
                "strengthId": 341
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 526,
            "strengthId": 342,
            "strength": "3 mg",
            "recommendationInstructions": [
              {
                "id": 2723,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 342
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 526,
            "strengthId": 343,
            "strength": "4 mg",
            "recommendationInstructions": [
              {
                "id": 2724,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 343
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 527,
        "recommendationGroupId": 3,
        "position": 83,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Propecia (Finasteride90)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Propecia (Finasteride90) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 527,
            "strengthId": 344,
            "strength": "1 mg",
            "recommendationInstructions": [
              {
                "id": 2725,
                "instruction": "Take one tablet by mouth once daily.",
                "recommendationId": 0,
                "strengthId": 344
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 528,
        "recommendationGroupId": 3,
        "position": 84,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Sildenafil (Amneal)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Sildenafil (Amneal) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 528,
            "strengthId": 345,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2726,
                "instruction": "Take one tablet by mouth 1 hour prior to intercourse as needed",
                "recommendationId": 0,
                "strengthId": 345
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 529,
        "recommendationGroupId": 3,
        "position": 85,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Sildenafil (Torrent)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Sildenafil (Torrent) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 529,
            "strengthId": 346,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2727,
                "instruction": "Take one tablet by mouth 1 hour before intercourse as needed",
                "recommendationId": 0,
                "strengthId": 346
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 530,
        "recommendationGroupId": 3,
        "position": 86,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Tadalafil (Accord)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Tadalafil (Accord) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 530,
            "strengthId": 347,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2728,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 347
              },
              {
                "id": 2729,
                "instruction": "Take one tablet by mouth daily",
                "recommendationId": 0,
                "strengthId": 347
              },
              {
                "id": 2730,
                "instruction": "Take one tablet by mouth daily as needed",
                "recommendationId": 0,
                "strengthId": 347
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 531,
        "recommendationGroupId": 3,
        "position": 87,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Tadalafil (Amneal)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Tadalafil (Amneal) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 531,
            "strengthId": 348,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2731,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 348
              },
              {
                "id": 2732,
                "instruction": "Take one tablet by mouth daily",
                "recommendationId": 0,
                "strengthId": 348
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 532,
        "recommendationGroupId": 3,
        "position": 88,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Tadalafil (Camber)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Tadalafil (Camber) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 532,
            "strengthId": 349,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2733,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 349
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 533,
        "recommendationGroupId": 3,
        "position": 89,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Tadalafil (Sun)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Tadalafil (Sun) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 533,
            "strengthId": 350,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2734,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 350
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 534,
        "recommendationGroupId": 3,
        "position": 90,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Telmisartan (Ascend)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Telmisartan (Ascend) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 534,
            "strengthId": 351,
            "strength": "80 mg",
            "recommendationInstructions": [
              {
                "id": 2735,
                "instruction": "Take one tablet by mouth daily",
                "recommendationId": 0,
                "strengthId": 351
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 535,
        "recommendationGroupId": 3,
        "position": 91,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Telmisartan (Mylan)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Telmisartan (Mylan) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 535,
            "strengthId": 352,
            "strength": "80 mg",
            "recommendationInstructions": [
              {
                "id": 2736,
                "instruction": "Take one tablet by mouth daily",
                "recommendationId": 0,
                "strengthId": 352
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 536,
        "recommendationGroupId": 3,
        "position": 92,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Zolpidem (NORTHSTAR)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Zolpidem (NORTHSTAR) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 536,
            "strengthId": 353,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2737,
                "instruction": "Take 1 to 2 tablets By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 353
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 537,
        "recommendationGroupId": 4,
        "position": 93,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Acetyl-L-Carnitine (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Acetyl-L-Carnitine (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 537,
            "strengthId": 354,
            "strength": "500 mg",
            "recommendationInstructions": [
              {
                "id": 2738,
                "instruction": "Take 1 Capsule By Mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 354
              },
              {
                "id": 2739,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 354
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 538,
        "recommendationGroupId": 4,
        "position": 94,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Alpha Lipoic Acid (DOUGLAS)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Alpha Lipoic Acid (DOUGLAS) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 538,
            "strengthId": 355,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2740,
                "instruction": "Take one tablet by mouth twice daily.",
                "recommendationId": 0,
                "strengthId": 355
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 539,
        "recommendationGroupId": 4,
        "position": 95,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Alpha Lipoic Acid (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Alpha Lipoic Acid (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 539,
            "strengthId": 356,
            "strength": "200 mg",
            "recommendationInstructions": [
              {
                "id": 2741,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 356
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 540,
        "recommendationGroupId": 4,
        "position": 96,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Alpha-GPC (Douglas)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Alpha-GPC (Douglas) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 540,
            "strengthId": 357,
            "strength": "500 mg",
            "recommendationInstructions": [
              {
                "id": 2742,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 357
              },
              {
                "id": 2743,
                "instruction": "Take 1 capsule by mouth twice daily",
                "recommendationId": 0,
                "strengthId": 357
              },
              {
                "id": 2744,
                "instruction": "Take 2 Capsules By Mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 357
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 541,
        "recommendationGroupId": 4,
        "position": 97,
        "visible": 1,
        "groupName": "Supplement",
        "body": "B Complex (PURE)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "B Complex (PURE) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 541,
            "strengthId": 358,
            "strength": "B Complex (PURE)",
            "recommendationInstructions": [
              {
                "id": 2745,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 358
              },
              {
                "id": 2746,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 358
              },
              {
                "id": 2747,
                "instruction": "Take 1 Capsule By Mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 358
              },
              {
                "id": 2748,
                "instruction": "Use as directed",
                "recommendationId": 0,
                "strengthId": 358
              },
              {
                "id": 2749,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 358
              },
              {
                "id": 2750,
                "instruction": "Take 1 Capsule By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 358
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 542,
        "recommendationGroupId": 4,
        "position": 98,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Berberine Support (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Berberine Support (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 542,
            "strengthId": 359,
            "strength": "Berberine Support (RM)",
            "recommendationInstructions": [
              {
                "id": 2751,
                "instruction": "Take 1 Capsule By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 359
              },
              {
                "id": 2752,
                "instruction": "Take 1 Capsule By Mouth Twice Daily Before Meal ",
                "recommendationId": 0,
                "strengthId": 359
              },
              {
                "id": 2753,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 359
              },
              {
                "id": 2754,
                "instruction": "Take 1 capsule every morning and evening before meals.",
                "recommendationId": 0,
                "strengthId": 359
              },
              {
                "id": 2755,
                "instruction": "Take 1 capsule By Mouth Every Morning and 1 every evening",
                "recommendationId": 0,
                "strengthId": 359
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 543,
        "recommendationGroupId": 4,
        "position": 99,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Biodim",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Biodim capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 543,
            "strengthId": 360,
            "strength": "150 mg",
            "recommendationInstructions": [
              {
                "id": 2756,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 360
              },
              {
                "id": 2757,
                "instruction": "Take 2 capsules by mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 360
              },
              {
                "id": 2758,
                "instruction": "Take 2 Capsules By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 360
              },
              {
                "id": 2759,
                "instruction": "Take two  capsules by mouth each morning. ",
                "recommendationId": 0,
                "strengthId": 360
              },
              {
                "id": 2760,
                "instruction": "Take two capsules by mouth once daily. ",
                "recommendationId": 0,
                "strengthId": 360
              },
              {
                "id": 2761,
                "instruction": "Take 2 capsules by mouth each morning. ",
                "recommendationId": 0,
                "strengthId": 360
              },
              {
                "id": 2762,
                "instruction": "Take two capsules by mouth every morning.    ",
                "recommendationId": 0,
                "strengthId": 360
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 544,
        "recommendationGroupId": 4,
        "position": 100,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Biotin (N/B)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Biotin (N/B) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 544,
            "strengthId": 361,
            "strength": "1,000 mcg",
            "recommendationInstructions": [
              {
                "id": 2763,
                "instruction": "Take 2 Tablets By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 361
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 545,
        "recommendationGroupId": 4,
        "position": 101,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Comprehensive Nutritional Support (RM)",
        "recommendationCount": 0,
        "productType": "packet",
        "bodyWithProduct": "Comprehensive Nutritional Support (RM) packet",
        "recommendationStrengths": [
          {
            "recommendationId": 545,
            "strengthId": 362,
            "strength": "Premium Pack Plus",
            "recommendationInstructions": [
              {
                "id": 2764,
                "instruction": "Take one packet by mouth once daily. ",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2765,
                "instruction": "Take As Directed",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2766,
                "instruction": "Use as directed ",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2767,
                "instruction": "Take contents of one packet daily",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2768,
                "instruction": "Take contents of 1 packet By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2769,
                "instruction": "Take as directed with food",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2770,
                "instruction": "Take contents of 1 packet By Mouth Every Evening with dinner",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2771,
                "instruction": "Take as directed with dinner",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2772,
                "instruction": "Use As Directed Every Morning",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2773,
                "instruction": "Take one pack daily with food",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2774,
                "instruction": "Take contents of one packet daily with food",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2775,
                "instruction": "As Directed  ",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2776,
                "instruction": "Take contents of 1 packet daily with food ",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2777,
                "instruction": "Take the contents of one packet Daily ",
                "recommendationId": 0,
                "strengthId": 362
              },
              {
                "id": 2778,
                "instruction": "Take 1 packet by mouth twice daily as directed",
                "recommendationId": 0,
                "strengthId": 362
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 546,
        "recommendationGroupId": 4,
        "position": 102,
        "visible": 1,
        "groupName": "Supplement",
        "body": "CoQ10 (PA)",
        "recommendationCount": 0,
        "productType": "soft gel",
        "bodyWithProduct": "CoQ10 (PA) soft gel",
        "recommendationStrengths": [
          {
            "recommendationId": 546,
            "strengthId": 363,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2779,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 363
              },
              {
                "id": 2780,
                "instruction": "Take 1 Capsule By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 363
              },
              {
                "id": 2781,
                "instruction": "Take 1 softgel By Mouth once daily.",
                "recommendationId": 0,
                "strengthId": 363
              },
              {
                "id": 2782,
                "instruction": "Take one capsule by mouth daily.",
                "recommendationId": 0,
                "strengthId": 363
              },
              {
                "id": 2783,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 363
              },
              {
                "id": 2784,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 363
              },
              {
                "id": 2785,
                "instruction": "Take 1-2 soft gels by mouth daily",
                "recommendationId": 0,
                "strengthId": 363
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 547,
        "recommendationGroupId": 4,
        "position": 103,
        "visible": 1,
        "groupName": "Supplement",
        "body": "COQ10 (RM)",
        "recommendationCount": 0,
        "productType": "soft gel",
        "bodyWithProduct": "COQ10 (RM) soft gel",
        "recommendationStrengths": [
          {
            "recommendationId": 547,
            "strengthId": 364,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2786,
                "instruction": "Take 2 Capsules By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 364
              },
              {
                "id": 2787,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 364
              },
              {
                "id": 2788,
                "instruction": "Take 2 Capsules By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 364
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 547,
            "strengthId": 365,
            "strength": "200 mg",
            "recommendationInstructions": [
              {
                "id": 2789,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 365
              },
              {
                "id": 2790,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 365
              },
              {
                "id": 2791,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 365
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 548,
        "recommendationGroupId": 4,
        "position": 104,
        "visible": 1,
        "groupName": "Supplement",
        "body": "CoQ10 Ubiquinol (RM)",
        "recommendationCount": 0,
        "productType": "soft gel",
        "bodyWithProduct": "CoQ10 Ubiquinol (RM) soft gel",
        "recommendationStrengths": [
          {
            "recommendationId": 548,
            "strengthId": 366,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2792,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 366
              },
              {
                "id": 2793,
                "instruction": "Take 2 Capsules By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 366
              },
              {
                "id": 2794,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 366
              },
              {
                "id": 2795,
                "instruction": "Take 1 capsule by mouth daily with food.",
                "recommendationId": 0,
                "strengthId": 366
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 549,
        "recommendationGroupId": 4,
        "position": 105,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Cyanocobalamin (APP)",
        "recommendationCount": 0,
        "productType": "injectable",
        "bodyWithProduct": "Cyanocobalamin (APP) injectable",
        "recommendationStrengths": [
          {
            "recommendationId": 549,
            "strengthId": 367,
            "strength": "1,000 mcg/ml",
            "recommendationInstructions": [
              {
                "id": 2796,
                "instruction": "Inject 1ml Subcutaneously once monthly.   ",
                "recommendationId": 0,
                "strengthId": 367
              },
              {
                "id": 2797,
                "instruction": "Inject 0.1ml Subcutaneously 3 to 4 times per week as needed. ",
                "recommendationId": 0,
                "strengthId": 367
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 550,
        "recommendationGroupId": 4,
        "position": 106,
        "visible": 1,
        "groupName": "Supplement",
        "body": "D.I.M. (PA)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "D.I.M. (PA) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 550,
            "strengthId": 368,
            "strength": "300 mg",
            "recommendationInstructions": [
              {
                "id": 2798,
                "instruction": "Take one capsule by mouth each morning.",
                "recommendationId": 0,
                "strengthId": 368
              },
              {
                "id": 2799,
                "instruction": "Take one capsule by mouth daily.",
                "recommendationId": 0,
                "strengthId": 368
              },
              {
                "id": 2800,
                "instruction": "Take one capsule by mouth each morning",
                "recommendationId": 0,
                "strengthId": 368
              },
              {
                "id": 2801,
                "instruction": "Take one capsule by mouth once daily.",
                "recommendationId": 0,
                "strengthId": 368
              },
              {
                "id": 2802,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 368
              },
              {
                "id": 2803,
                "instruction": "Take one capsule by mouth each day.",
                "recommendationId": 0,
                "strengthId": 368
              },
              {
                "id": 2804,
                "instruction": "Take 1 capsule by mouth once daily. ",
                "recommendationId": 0,
                "strengthId": 368
              },
              {
                "id": 2805,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 368
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 551,
        "recommendationGroupId": 4,
        "position": 107,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Ferrous Gluconate (Perrigo)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Ferrous Gluconate (Perrigo) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 551,
            "strengthId": 369,
            "strength": "324 mg",
            "recommendationInstructions": [
              {
                "id": 2806,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 369
              },
              {
                "id": 2807,
                "instruction": "Take 1 Tablet By Mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 369
              },
              {
                "id": 2808,
                "instruction": "Take 1 Tablet By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 369
              },
              {
                "id": 2809,
                "instruction": "Take one tablet by mouth twice daily.",
                "recommendationId": 0,
                "strengthId": 369
              },
              {
                "id": 2810,
                "instruction": "Take one tablet by mouth daily with food.",
                "recommendationId": 0,
                "strengthId": 369
              },
              {
                "id": 2811,
                "instruction": "Take one tablet by mouth daily.",
                "recommendationId": 0,
                "strengthId": 369
              },
              {
                "id": 2812,
                "instruction": "Take 1 Tablet By Mouth Every Other Day ",
                "recommendationId": 0,
                "strengthId": 369
              },
              {
                "id": 2813,
                "instruction": "Take 1 to 2 tablets By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 369
              },
              {
                "id": 2814,
                "instruction": "Take one tablet by mouth daily",
                "recommendationId": 0,
                "strengthId": 369
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 552,
        "recommendationGroupId": 4,
        "position": 108,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Homocysteine Factors (Pure)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Homocysteine Factors (Pure) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 552,
            "strengthId": 370,
            "strength": "Homocysteine Factors (Pure)",
            "recommendationInstructions": [
              {
                "id": 2815,
                "instruction": "Take 2 Capsules By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2816,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2817,
                "instruction": "Take 2 Capsules By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2818,
                "instruction": "Take 2 Capsules By Mouth Every Morning with food",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2819,
                "instruction": "Take 3 Capsules By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2820,
                "instruction": "Take 1 To 2 Capsules By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2821,
                "instruction": "Take 1 Capsule By Mouth Twice Daily with food",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2822,
                "instruction": "Take 2 Capsules By Mouth in the morning with food and 1 capsule with dinner",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2823,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2824,
                "instruction": "Take one capsule by mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2825,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2826,
                "instruction": "Take 1 Capsule By Mouth every other day alternating with 2 every other day. ",
                "recommendationId": 0,
                "strengthId": 370
              },
              {
                "id": 2827,
                "instruction": "Take 3 Capsules By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 370
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 553,
        "recommendationGroupId": 4,
        "position": 109,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Joint (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Joint (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 553,
            "strengthId": 371,
            "strength": "Joint (RM)",
            "recommendationInstructions": [
              {
                "id": 2828,
                "instruction": "Take two capsules by mouth twice daily ",
                "recommendationId": 0,
                "strengthId": 371
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 554,
        "recommendationGroupId": 4,
        "position": 110,
        "visible": 1,
        "groupName": "Supplement",
        "body": "L-Carnitine (Pure)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "L-Carnitine (Pure) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 554,
            "strengthId": 372,
            "strength": "L-Carnitine (Pure)",
            "recommendationInstructions": [
              {
                "id": 2829,
                "instruction": "Take 2 Capsules By Mouth Twice Daily with food",
                "recommendationId": 0,
                "strengthId": 372
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 555,
        "recommendationGroupId": 4,
        "position": 111,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Magnesium Aspartate (DOUGLAS)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Magnesium Aspartate (DOUGLAS) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 555,
            "strengthId": 373,
            "strength": "100 mg",
            "recommendationInstructions": [
              {
                "id": 2830,
                "instruction": "Take 1 capsule by mouth once daily as directed",
                "recommendationId": 0,
                "strengthId": 373
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 556,
        "recommendationGroupId": 4,
        "position": 112,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Magnesium Glycinate (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Magnesium Glycinate (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 556,
            "strengthId": 374,
            "strength": "250 mg",
            "recommendationInstructions": [
              {
                "id": 2831,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 374
              },
              {
                "id": 2832,
                "instruction": "Take 2 Capsules By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 374
              },
              {
                "id": 2833,
                "instruction": "Take 2 Capsules By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 374
              },
              {
                "id": 2834,
                "instruction": "Take 2 to 4 Capsules By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 374
              },
              {
                "id": 2835,
                "instruction": "Take 2 Capsules By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 374
              },
              {
                "id": 2836,
                "instruction": "Take 1 Capsule By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 374
              },
              {
                "id": 2837,
                "instruction": "Take 1 to 2 capsules By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 374
              },
              {
                "id": 2838,
                "instruction": "Take 4 capsules By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 374
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 557,
        "recommendationGroupId": 4,
        "position": 113,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Magnesium Oxide (Cypress)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Magnesium Oxide (Cypress) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 557,
            "strengthId": 375,
            "strength": "400 mg",
            "recommendationInstructions": [
              {
                "id": 2839,
                "instruction": "Take 1 Tablet By Mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 375
              },
              {
                "id": 2840,
                "instruction": "Take 1 Tablet By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 375
              },
              {
                "id": 2841,
                "instruction": "Take one tablet by mouth every night at bedtime",
                "recommendationId": 0,
                "strengthId": 375
              },
              {
                "id": 2842,
                "instruction": "Take one tablet by mouth daily",
                "recommendationId": 0,
                "strengthId": 375
              },
              {
                "id": 2843,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 375
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 558,
        "recommendationGroupId": 4,
        "position": 114,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Magnesium Oxide (Qualitest)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Magnesium Oxide (Qualitest) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 558,
            "strengthId": 376,
            "strength": "400 mg",
            "recommendationInstructions": [
              {
                "id": 2844,
                "instruction": "Take 1 Tablet By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 376
              },
              {
                "id": 2845,
                "instruction": "Take one tablet by mouth every night at bedtime",
                "recommendationId": 0,
                "strengthId": 376
              },
              {
                "id": 2846,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 376
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 559,
        "recommendationGroupId": 4,
        "position": 115,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Marine Fish Oil (RM)",
        "recommendationCount": 0,
        "productType": "soft gel",
        "bodyWithProduct": "Marine Fish Oil (RM) soft gel",
        "recommendationStrengths": [
          {
            "recommendationId": 559,
            "strengthId": 377,
            "strength": "(EPA/DHA) 360mg/270mg",
            "recommendationInstructions": [
              {
                "id": 2847,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 377
              },
              {
                "id": 2848,
                "instruction": "Take two capsules by mouth twice daily. ",
                "recommendationId": 0,
                "strengthId": 377
              },
              {
                "id": 2849,
                "instruction": "Take as directed",
                "recommendationId": 0,
                "strengthId": 377
              },
              {
                "id": 2850,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 377
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 560,
        "recommendationGroupId": 4,
        "position": 116,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Melatonin",
        "recommendationCount": 0,
        "productType": "S.R. capsule",
        "bodyWithProduct": "Melatonin S.R. capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 560,
            "strengthId": 378,
            "strength": "2 mg",
            "recommendationInstructions": [
              {
                "id": 2851,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 378
              },
              {
                "id": 2852,
                "instruction": "Take 1 to 2 capsules By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 378
              },
              {
                "id": 2853,
                "instruction": "Take 2 Capsules By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 378
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 560,
            "strengthId": 379,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2854,
                "instruction": "Take one capsule by mouth before bedtime.",
                "recommendationId": 0,
                "strengthId": 379
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 560,
            "strengthId": 380,
            "strength": "6 mg",
            "recommendationInstructions": [
              {
                "id": 2855,
                "instruction": "Take one capsule by mouth before bedtime.",
                "recommendationId": 0,
                "strengthId": 380
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 561,
        "recommendationGroupId": 4,
        "position": 117,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Melatonin (PA)",
        "recommendationCount": 0,
        "productType": "S.R. capsule",
        "bodyWithProduct": "Melatonin (PA) S.R. capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 561,
            "strengthId": 381,
            "strength": "1 mg",
            "recommendationInstructions": [
              {
                "id": 2856,
                "instruction": "Take one capsule by mouth at bedtime",
                "recommendationId": 0,
                "strengthId": 381
              },
              {
                "id": 2857,
                "instruction": "Take 1 capsule by mouth every night before bedtime. ",
                "recommendationId": 0,
                "strengthId": 381
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 562,
        "recommendationGroupId": 4,
        "position": 118,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Melatonin (RM)",
        "recommendationCount": 0,
        "productType": "S.R. capsule",
        "bodyWithProduct": "Melatonin (RM) S.R. capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 562,
            "strengthId": 382,
            "strength": "1 mg",
            "recommendationInstructions": [
              {
                "id": 2858,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 382
              },
              {
                "id": 2859,
                "instruction": "Take 1 To 2 Capsules By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 382
              },
              {
                "id": 2860,
                "instruction": "Take 1-3 capsules by mouth once daily before bedtime. ",
                "recommendationId": 0,
                "strengthId": 382
              },
              {
                "id": 2861,
                "instruction": "Take one capsule by mouth before bedtime. ",
                "recommendationId": 0,
                "strengthId": 382
              },
              {
                "id": 2862,
                "instruction": "Take 1 Capsule By Mouth Every other night to every night at bedtime. ",
                "recommendationId": 0,
                "strengthId": 382
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 562,
            "strengthId": 384,
            "strength": "10 mg",
            "recommendationInstructions": [
              {
                "id": 2864,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 384
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 562,
            "strengthId": 385,
            "strength": "25 mg",
            "recommendationInstructions": [
              {
                "id": 2865,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 385
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 562,
            "strengthId": 386,
            "strength": "3 mg",
            "recommendationInstructions": [
              {
                "id": 2866,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 386
              },
              {
                "id": 2867,
                "instruction": "Take 1  Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 386
              },
              {
                "id": 2868,
                "instruction": "Take 1 to 2 capsules By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 386
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 563,
        "recommendationGroupId": 4,
        "position": 119,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Melatonin (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Melatonin (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 563,
            "strengthId": 383,
            "strength": "1 mg",
            "recommendationInstructions": [
              {
                "id": 2863,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 383
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 564,
        "recommendationGroupId": 4,
        "position": 120,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Melatonin (RM)",
        "recommendationCount": 0,
        "productType": "spray",
        "bodyWithProduct": "Melatonin (RM) spray",
        "recommendationStrengths": [
          {
            "recommendationId": 564,
            "strengthId": 387,
            "strength": "3 mg/ml",
            "recommendationInstructions": [
              {
                "id": 2869,
                "instruction": "Instill 1 to 2 sprays every night at bedtime as directed",
                "recommendationId": 0,
                "strengthId": 387
              },
              {
                "id": 2870,
                "instruction": "Instill 1 spray under tongue every night at bedtime",
                "recommendationId": 0,
                "strengthId": 387
              },
              {
                "id": 2871,
                "instruction": "Instill 1 to 2 sprays under tongue Every Night At Bedtime as needed",
                "recommendationId": 0,
                "strengthId": 387
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 565,
        "recommendationGroupId": 4,
        "position": 121,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Melatonin (RM)",
        "recommendationCount": 0,
        "productType": "S.R. Vcap",
        "bodyWithProduct": "Melatonin (RM) S.R. Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 565,
            "strengthId": 388,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2872,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 388
              },
              {
                "id": 2873,
                "instruction": "Take 3 capsules by mouth every night at bedtime ",
                "recommendationId": 0,
                "strengthId": 388
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 566,
        "recommendationGroupId": 4,
        "position": 122,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Methylated B Vitamins (RM)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Methylated B Vitamins (RM) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 566,
            "strengthId": 389,
            "strength": "Methylated B Vitamins (RM)",
            "recommendationInstructions": [
              {
                "id": 2874,
                "instruction": "Take 2 Capsules By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2875,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2876,
                "instruction": "Take 1 Capsule By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2877,
                "instruction": "Take two capsules by mouth daily",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2878,
                "instruction": "Take one capsule by mouth daily",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2879,
                "instruction": "Take 2 Capsules By Mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2880,
                "instruction": "Take 3 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2881,
                "instruction": "Take 2 capsules by mouth daily with food",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2882,
                "instruction": "Take one capsule by mouth daily with food",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2883,
                "instruction": "Take 2 Capsules By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2884,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2885,
                "instruction": "Take 1 capsule by mouth Every Morning and Every Evening ",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2886,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2887,
                "instruction": "Take 2 Capsules By Mouth Every Morning and Take 1 Capsule every afternoon",
                "recommendationId": 0,
                "strengthId": 389
              },
              {
                "id": 2888,
                "instruction": "Take 1 to 2 capsules By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 389
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 567,
        "recommendationGroupId": 4,
        "position": 123,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Methylcobalamin (RM)",
        "recommendationCount": 0,
        "productType": "liquid",
        "bodyWithProduct": "Methylcobalamin (RM) liquid",
        "recommendationStrengths": [
          {
            "recommendationId": 567,
            "strengthId": 390,
            "strength": "2500 mcg",
            "recommendationInstructions": [
              {
                "id": 2889,
                "instruction": "Instill 5 drops (0.25ml) under the tongue daily as directed",
                "recommendationId": 0,
                "strengthId": 390
              },
              {
                "id": 2890,
                "instruction": "Instill 0.5ml under tongue Daily ",
                "recommendationId": 0,
                "strengthId": 390
              },
              {
                "id": 2891,
                "instruction": "Instill 0.5ml under tongue daily as directed",
                "recommendationId": 0,
                "strengthId": 390
              },
              {
                "id": 2892,
                "instruction": "Instill 0.5ml under tongue three times weekly ",
                "recommendationId": 0,
                "strengthId": 390
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 568,
        "recommendationGroupId": 4,
        "position": 124,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Methylcobalamin B-12 (RM)",
        "recommendationCount": 0,
        "productType": "spray",
        "bodyWithProduct": "Methylcobalamin B-12 (RM) spray",
        "recommendationStrengths": [
          {
            "recommendationId": 568,
            "strengthId": 391,
            "strength": "1000 mcg",
            "recommendationInstructions": [
              {
                "id": 2893,
                "instruction": "Instill 1 spray by mouth daily",
                "recommendationId": 0,
                "strengthId": 391
              },
              {
                "id": 2894,
                "instruction": "Instill 1 spray under the tongue Daily ",
                "recommendationId": 0,
                "strengthId": 391
              },
              {
                "id": 2895,
                "instruction": "Instill 1 to 2 sprays under tongue daily as directed",
                "recommendationId": 0,
                "strengthId": 391
              },
              {
                "id": 2896,
                "instruction": "Instill 1 spray under tongue daily",
                "recommendationId": 0,
                "strengthId": 391
              },
              {
                "id": 2897,
                "instruction": "Instill 1 to 2 sprays under tongue Daily ",
                "recommendationId": 0,
                "strengthId": 391
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 569,
        "recommendationGroupId": 4,
        "position": 125,
        "visible": 1,
        "groupName": "Supplement",
        "body": "N-Acetyl-L-Cysteine (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "N-Acetyl-L-Cysteine (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 569,
            "strengthId": 392,
            "strength": "N-Acetyl-L-Cysteine (RM)",
            "recommendationInstructions": [
              {
                "id": 2898,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 392
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 570,
        "recommendationGroupId": 4,
        "position": 126,
        "visible": 1,
        "groupName": "Supplement",
        "body": "NEO40 Pro",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "NEO40 Pro tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 570,
            "strengthId": 393,
            "strength": "NEO40 Pro",
            "recommendationInstructions": [
              {
                "id": 2899,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 393
              },
              {
                "id": 2900,
                "instruction": "Take 2 Tablets By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 393
              },
              {
                "id": 2901,
                "instruction": "Take 1 Tablet By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 393
              },
              {
                "id": 2902,
                "instruction": "Take One lozenge by mouth daily- allow to dissolve",
                "recommendationId": 0,
                "strengthId": 393
              },
              {
                "id": 2903,
                "instruction": "Take 1 to 2 tablets By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 393
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 571,
        "recommendationGroupId": 4,
        "position": 127,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Niacin (RM)",
        "recommendationCount": 0,
        "productType": "S.R. tablet",
        "bodyWithProduct": "Niacin (RM) S.R. tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 571,
            "strengthId": 394,
            "strength": "500 mg",
            "recommendationInstructions": [
              {
                "id": 2904,
                "instruction": "Take 2 Tablets By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 394
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 572,
        "recommendationGroupId": 4,
        "position": 128,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Probiotic (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Probiotic (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 572,
            "strengthId": 395,
            "strength": "25 BILLION CFU'S",
            "recommendationInstructions": [
              {
                "id": 2905,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 395
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 573,
        "recommendationGroupId": 4,
        "position": 129,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Prosta-Support Formula",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Prosta-Support Formula tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 573,
            "strengthId": 396,
            "strength": "Prosta-Support Formula",
            "recommendationInstructions": [
              {
                "id": 2906,
                "instruction": "Take 4 tablets By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 396
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 574,
        "recommendationGroupId": 4,
        "position": 130,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Prostate Formula (PA)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Prostate Formula (PA) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 574,
            "strengthId": 397,
            "strength": "blend",
            "recommendationInstructions": [
              {
                "id": 2907,
                "instruction": "Take one capsule by mouth twice daily. ",
                "recommendationId": 0,
                "strengthId": 397
              },
              {
                "id": 2908,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 397
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 575,
        "recommendationGroupId": 4,
        "position": 131,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Prostate Formula (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Prostate Formula (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 575,
            "strengthId": 398,
            "strength": "Blend",
            "recommendationInstructions": [
              {
                "id": 2909,
                "instruction": "Take 1 Capsule By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 398
              },
              {
                "id": 2910,
                "instruction": "Take 2 Capsules By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 398
              },
              {
                "id": 2911,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 398
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 576,
        "recommendationGroupId": 4,
        "position": 132,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Quercetin (Pure)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Quercetin (Pure) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 576,
            "strengthId": 399,
            "strength": "500 mg",
            "recommendationInstructions": [
              {
                "id": 2912,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 399
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 577,
        "recommendationGroupId": 4,
        "position": 133,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Selegiline",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Selegiline capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 577,
            "strengthId": 400,
            "strength": "1.5 mg",
            "recommendationInstructions": [
              {
                "id": 2913,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 400
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 577,
            "strengthId": 401,
            "strength": "2.5 mg",
            "recommendationInstructions": [
              {
                "id": 2914,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 401
              },
              {
                "id": 2915,
                "instruction": "Take one capsule by mouth daily monday through friday",
                "recommendationId": 0,
                "strengthId": 401
              },
              {
                "id": 2916,
                "instruction": "Take one capsule by mouth monday through friday",
                "recommendationId": 0,
                "strengthId": 401
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 578,
        "recommendationGroupId": 4,
        "position": 134,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Selegiline HCL (APOTEX)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Selegiline HCL (APOTEX) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 578,
            "strengthId": 402,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2917,
                "instruction": "Take one tablet by mouth once daily in the morning with meal",
                "recommendationId": 0,
                "strengthId": 402
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 579,
        "recommendationGroupId": 4,
        "position": 135,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Selegiline HCL (APOTEX)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Selegiline HCL (APOTEX) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 579,
            "strengthId": 403,
            "strength": "5 mg",
            "recommendationInstructions": [
              {
                "id": 2918,
                "instruction": "Take one capsule by mouth once  daily in the morning with a meal",
                "recommendationId": 0,
                "strengthId": 403
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 580,
        "recommendationGroupId": 4,
        "position": 136,
        "visible": 1,
        "groupName": "Supplement",
        "body": "SP Ultimate (Pure)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "SP Ultimate (Pure) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 580,
            "strengthId": 404,
            "strength": "SP Ultimate (Pure)",
            "recommendationInstructions": [
              {
                "id": 2919,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 404
              },
              {
                "id": 2920,
                "instruction": "Take 2 Capsules By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 404
              },
              {
                "id": 2921,
                "instruction": "Take 2 capsule by mouth twice daily",
                "recommendationId": 0,
                "strengthId": 404
              },
              {
                "id": 2922,
                "instruction": "Take 4 capsules by mouth daily in the morning",
                "recommendationId": 0,
                "strengthId": 404
              },
              {
                "id": 2923,
                "instruction": "Take 2 capsules by mouth twice daily. ",
                "recommendationId": 0,
                "strengthId": 404
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 581,
        "recommendationGroupId": 4,
        "position": 137,
        "visible": 1,
        "groupName": "Supplement",
        "body": "TA-65 (60ct)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "TA-65 (60ct) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 581,
            "strengthId": 405,
            "strength": "500 units",
            "recommendationInstructions": [
              {
                "id": 2924,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2925,
                "instruction": "Take 2 Tablets By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2926,
                "instruction": "Take 1 Tablet By Mouth Twice Daily",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2927,
                "instruction": "Take 1/2 tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2928,
                "instruction": "Take 1 Tablet By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2929,
                "instruction": "Take 2 Tablets By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2930,
                "instruction": "Take 1 and 1/2 tablet by mouth daily",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2931,
                "instruction": "Take 1/2 tablet By Mouth Twice Daily ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2932,
                "instruction": "Take 2 Tablets By Mouth Every Morning and Every Evening ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2933,
                "instruction": "Take 1/2 tab daily in the morning",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2934,
                "instruction": "Take 1/2 tablet By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2935,
                "instruction": "Take 1 Tablet By Mouth Every Morning and 1/2 tablet Every Evening ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2936,
                "instruction": "Take 3 Tablets By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2937,
                "instruction": "Take 1 Tablet By Mouth Every Morning and 2 tablets Every Night At Bedtime ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2938,
                "instruction": "Take 1/2 Tablet By Mouth  Daily ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2939,
                "instruction": "Take 1/2 tablet daily",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2940,
                "instruction": "Take 2 Tablets By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2941,
                "instruction": "Take 1/2 tablet by mouth in the morning",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2942,
                "instruction": "Take 1/2 to 1 tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2943,
                "instruction": "Take one capsule by mount daily in the morning",
                "recommendationId": 0,
                "strengthId": 405
              },
              {
                "id": 2944,
                "instruction": "Take one tablet by mouth daily",
                "recommendationId": 0,
                "strengthId": 405
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 582,
        "recommendationGroupId": 4,
        "position": 138,
        "visible": 1,
        "groupName": "Supplement",
        "body": "TA-65 (90ct)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "TA-65 (90ct) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 582,
            "strengthId": 406,
            "strength": "250 units",
            "recommendationInstructions": [
              {
                "id": 2945,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 406
              },
              {
                "id": 2946,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 406
              },
              {
                "id": 2947,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 406
              },
              {
                "id": 2948,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 406
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 583,
        "recommendationGroupId": 4,
        "position": 139,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Tocotrienols (pure)",
        "recommendationCount": 0,
        "productType": "soft gel",
        "bodyWithProduct": "Tocotrienols (pure) soft gel",
        "recommendationStrengths": [
          {
            "recommendationId": 583,
            "strengthId": 407,
            "strength": "60 mg",
            "recommendationInstructions": [
              {
                "id": 2949,
                "instruction": "Take 2 Capsules By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 407
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 584,
        "recommendationGroupId": 4,
        "position": 140,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Turmeric Curcumin Phytosome (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Turmeric Curcumin Phytosome (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 584,
            "strengthId": 408,
            "strength": "500 mg",
            "recommendationInstructions": [
              {
                "id": 2950,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 408
              },
              {
                "id": 2951,
                "instruction": "Take 1 Capsule By Mouth Twice Daily with food",
                "recommendationId": 0,
                "strengthId": 408
              },
              {
                "id": 2952,
                "instruction": "Take 1 Capsule By Mouth Twice Daily  ",
                "recommendationId": 0,
                "strengthId": 408
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 585,
        "recommendationGroupId": 4,
        "position": 141,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin A, D3, K2 (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Vitamin A, D3, K2 (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 585,
            "strengthId": 409,
            "strength": "Vitamin A, D3, K2 (RM)",
            "recommendationInstructions": [
              {
                "id": 2953,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 409
              },
              {
                "id": 2954,
                "instruction": "Take 1 Capsule By Mouth Every Morning ",
                "recommendationId": 0,
                "strengthId": 409
              },
              {
                "id": 2955,
                "instruction": "Take one capsule by mouth daily ",
                "recommendationId": 0,
                "strengthId": 409
              },
              {
                "id": 2956,
                "instruction": "Take 1 Capsule By Mouth Every Other Day",
                "recommendationId": 0,
                "strengthId": 409
              },
              {
                "id": 2957,
                "instruction": "Take 2 Capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 409
              },
              {
                "id": 2958,
                "instruction": "Take 1 Capsule By Mouth Daily with food         ",
                "recommendationId": 0,
                "strengthId": 409
              },
              {
                "id": 2959,
                "instruction": "Take 1 Capsule By Mouth every Monday, Wednesday, and Friday. ",
                "recommendationId": 0,
                "strengthId": 409
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 586,
        "recommendationGroupId": 4,
        "position": 142,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin B12 (Major)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Vitamin B12 (Major) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 586,
            "strengthId": 410,
            "strength": "250 mcg",
            "recommendationInstructions": [
              {
                "id": 2960,
                "instruction": "Take 1 Tablet By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 410
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 587,
        "recommendationGroupId": 4,
        "position": 143,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin B12 (Sundown)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Vitamin B12 (Sundown) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 587,
            "strengthId": 411,
            "strength": "500 mcg",
            "recommendationInstructions": [
              {
                "id": 2961,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 411
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 588,
        "recommendationGroupId": 4,
        "position": 144,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin C (RM)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Vitamin C (RM) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 588,
            "strengthId": 412,
            "strength": "1000 mg",
            "recommendationInstructions": [
              {
                "id": 2962,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 412
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 589,
        "recommendationGroupId": 4,
        "position": 145,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin D2 (Bionpharma)",
        "recommendationCount": 0,
        "productType": "soft gel",
        "bodyWithProduct": "Vitamin D2 (Bionpharma) soft gel",
        "recommendationStrengths": [
          {
            "recommendationId": 589,
            "strengthId": 413,
            "strength": "50,000 IU",
            "recommendationInstructions": [
              {
                "id": 2963,
                "instruction": "Take 1 Capsule By Mouth weekly. ",
                "recommendationId": 0,
                "strengthId": 413
              },
              {
                "id": 2964,
                "instruction": "Take 1 Capsule By Mouth weekly ",
                "recommendationId": 0,
                "strengthId": 413
              },
              {
                "id": 2965,
                "instruction": "Take 1 soft gel By Mouth Every Week ",
                "recommendationId": 0,
                "strengthId": 413
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 591,
        "recommendationGroupId": 4,
        "position": 147,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin D3 (PA)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Vitamin D3 (PA) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 591,
            "strengthId": 422,
            "strength": "1,000 IU",
            "recommendationInstructions": [
              {
                "id": 2993,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 422
              }
            ],
            "recommendationNotes": null
          },
          {
            "recommendationId": 591,
            "strengthId": 423,
            "strength": "5,000 IU",
            "recommendationInstructions": [
              {
                "id": 2994,
                "instruction": "Take 1 Capsule By Mouth Daily",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 2995,
                "instruction": "Take 1 capsule by mouth daily with food.    ",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 2996,
                "instruction": "Take one capsule by mouth daily",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 2997,
                "instruction": "Take one capsule by mouth every day with food",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 2998,
                "instruction": "Take two capsules by mouth weekly. ",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 2999,
                "instruction": "Take one capsule by mouth twice weekly with food. ",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 3000,
                "instruction": "Take one capsule by mouth daily with food.           ",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 3001,
                "instruction": "Take one capsule by mouth daily.",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 3002,
                "instruction": "Take 2 capsules by mouth Monday and Friday and 1 capsule other days in the morning. ",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 3003,
                "instruction": "Take one capsule by mouth every morning.",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 3004,
                "instruction": "Take 1 Capsule By Mouth five days per week",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 3005,
                "instruction": "Take 1 capsule by mouth every day. ",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 3006,
                "instruction": "Take 1 Capsule By Mouth Daily with food",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 3007,
                "instruction": "Take 1 Capsule By Mouth Every Morning  ",
                "recommendationId": 0,
                "strengthId": 423
              },
              {
                "id": 3008,
                "instruction": "Take one capsule by mouth once daily and 1 extra capsule twice weekly",
                "recommendationId": 0,
                "strengthId": 423
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 592,
        "recommendationGroupId": 4,
        "position": 148,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin D3 (Pure)",
        "recommendationCount": 0,
        "productType": "drop",
        "bodyWithProduct": "Vitamin D3 (Pure) drop",
        "recommendationStrengths": [
          {
            "recommendationId": 592,
            "strengthId": 424,
            "strength": "1000IU",
            "recommendationInstructions": [
              {
                "id": 3009,
                "instruction": "Instill 2 drops By Mouth daily as directed ",
                "recommendationId": 0,
                "strengthId": 424
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 593,
        "recommendationGroupId": 4,
        "position": 149,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin D3 (RM)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Vitamin D3 (RM) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 593,
            "strengthId": 425,
            "strength": "5000 IU",
            "recommendationInstructions": [
              {
                "id": 3010,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 425
              },
              {
                "id": 3011,
                "instruction": "Take 1 Capsule By Mouth Every Morning",
                "recommendationId": 0,
                "strengthId": 425
              },
              {
                "id": 3012,
                "instruction": "Take 1 capsule by mouth daily Monday thru Friday and 2 capsules on Saturday and Sunday",
                "recommendationId": 0,
                "strengthId": 425
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 594,
        "recommendationGroupId": 4,
        "position": 150,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin E (Pure)",
        "recommendationCount": 0,
        "productType": "soft gel",
        "bodyWithProduct": "Vitamin E (Pure) soft gel",
        "recommendationStrengths": [
          {
            "recommendationId": 594,
            "strengthId": 426,
            "strength": "Vitamin E (Pure)",
            "recommendationInstructions": [
              {
                "id": 3013,
                "instruction": "Take 2 capsules by mouth daily",
                "recommendationId": 0,
                "strengthId": 426
              },
              {
                "id": 3014,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 426
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 595,
        "recommendationGroupId": 4,
        "position": 151,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin K (Solgar)",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Vitamin K (Solgar) tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 595,
            "strengthId": 427,
            "strength": "100 mcg",
            "recommendationInstructions": [
              {
                "id": 3015,
                "instruction": "Take 1 Tablet By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 427
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 596,
        "recommendationGroupId": 4,
        "position": 152,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Vitamin K2 (MK-7) (Douglas)",
        "recommendationCount": 0,
        "productType": "Vcap",
        "bodyWithProduct": "Vitamin K2 (MK-7) (Douglas) Vcap",
        "recommendationStrengths": [
          {
            "recommendationId": 596,
            "strengthId": 428,
            "strength": "90 mcg",
            "recommendationInstructions": [
              {
                "id": 3016,
                "instruction": "Take 2 capsules By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 428
              },
              {
                "id": 3017,
                "instruction": "Take 1 Capsule By Mouth Every Night At Bedtime",
                "recommendationId": 0,
                "strengthId": 428
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 597,
        "recommendationGroupId": 4,
        "position": 153,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Zinc (Pure)",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Zinc (Pure) capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 597,
            "strengthId": 429,
            "strength": "30 mg",
            "recommendationInstructions": [
              {
                "id": 3018,
                "instruction": "Take 1 Capsule By Mouth Daily ",
                "recommendationId": 0,
                "strengthId": 429
              },
              {
                "id": 3019,
                "instruction": "Take 1 Capsule By Mouth Daily as directed",
                "recommendationId": 0,
                "strengthId": 429
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 598,
        "recommendationGroupId": 3,
        "position": 154,
        "visible": 1,
        "groupName": "Pharmaceutical",
        "body": "Sirolimus",
        "recommendationCount": 0,
        "productType": "tablet",
        "bodyWithProduct": "Sirolimus tablet",
        "recommendationStrengths": [
          {
            "recommendationId": 598,
            "strengthId": 430,
            "strength": "2 mg",
            "recommendationInstructions": [
              {
                "id": 3020,
                "instruction": "Take 3 tablets once a week with or without food.",
                "recommendationId": 0,
                "strengthId": 430
              },
              {
                "id": 3021,
                "instruction": "Take 3 tablets once a week for 8 weeks with or without food.",
                "recommendationId": 0,
                "strengthId": 430
              },
              {
                "id": 3022,
                "instruction": "Take 2 tablets once a week for 8 weeks with or without food.",
                "recommendationId": 0,
                "strengthId": 430
              }
            ],
            "recommendationNotes": [
              {
                "id": 2,
                "note": "If creatinine > 1.2 mg/dL, then start with 2 tablets once weekly.",
                "recommendationId": 0,
                "strengthId": 430
              }
            ]
          }
        ]
      },
      {
        "id": 600,
        "recommendationGroupId": 4,
        "position": 156,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Prodrome Neuro",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Prodrome Neuro capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 600,
            "strengthId": 432,
            "strength": "Prodrome Neuro",
            "recommendationInstructions": [
              {
                "id": 3023,
                "instruction": "Take 2 caps daily with food.",
                "recommendationId": 0,
                "strengthId": 432
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 602,
        "recommendationGroupId": 4,
        "position": 158,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Elysium Signal",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Elysium Signal capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 602,
            "strengthId": 435,
            "strength": "Elysium Signal",
            "recommendationInstructions": [
              {
                "id": 3026,
                "instruction": "Take 2 capsules daily",
                "recommendationId": 0,
                "strengthId": 435
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 605,
        "recommendationGroupId": 6,
        "position": 161,
        "visible": 1,
        "groupName": "Diet",
        "body": "5:2 diet",
        "recommendationCount": 0,
        "productType": null,
        "bodyWithProduct": "5:2 diet",
        "recommendationStrengths": [
          {
            "recommendationId": 605,
            "strengthId": 438,
            "strength": "5:2 diet",
            "recommendationInstructions": [
              {
                "id": 3029,
                "instruction": "Eat only 500 calories on two non-contiguous days every week.",
                "recommendationId": 0,
                "strengthId": 438
              }
            ],
            "recommendationNotes": null
          }
        ]
      },
      {
        "id": 607,
        "recommendationGroupId": 4,
        "position": 163,
        "visible": 1,
        "groupName": "Supplement",
        "body": "Lithium Orotate",
        "recommendationCount": 0,
        "productType": "capsule",
        "bodyWithProduct": "Lithium Orotate capsule",
        "recommendationStrengths": [
          {
            "recommendationId": 607,
            "strengthId": 440,
            "strength": "10 mg/N-Acetylcysteine 200 mg",
            "recommendationInstructions": [
              {
                "id": 3031,
                "instruction": "Take one capsule daily with or without food.",
                "recommendationId": 0,
                "strengthId": 440
              }
            ],
            "recommendationNotes": null
          }
        ]
      }
    ],
    "groupDTO": [
      {
        "id": 1,
        "name": "Hormone"
      },
      {
        "id": 2,
        "name": "Other"
      },
      {
        "id": 3,
        "name": "Pharmaceutical"
      },
      {
        "id": 4,
        "name": "Supplement"
      },
      {
        "id": 5,
        "name": "General Instructions"
      },
      {
        "id": 6,
        "name": "Diet"
      },
      {
        "id": 7,
        "name": "Exercise"
      }
    ]
  },
  "isSuccess": true,
  "statusCode": 0,
  "errors": [
    ""
  ]
}
import React, { Component } from 'react';
import SpreadingRoutes from '../../../utils/SpreadingRoutes';

import './documents-styles.scss';

class Documents extends Component {
    componentWillUnmount() {
        this.props.toggleNotification(false)
    }

    render() {
        return (
            <SpreadingRoutes {...this.props} />
        )
    }
}

export default Documents
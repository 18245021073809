import SetPassword from './SetPassword';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { resetPassword, loginInput, clearUsermail ,validateSetPassword,logOut,setPassword} from '../../reducers/global-reducer'

const mapStateToProps = state => ({
  ...state.global,
});

const mapDispatchToProps = {
  resetPassword, loginInput, clearUsermail,validateSetPassword,logOut,setPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SetPassword));

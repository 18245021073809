export default {
  demo_1: [
    {
      "id": 10544,
      "timePoint": "Baseline",
      "visitDate": "2013-03-20T00:00:00",
      "dateOfVisit": "Mar 20, 2013",
      "dataEntry": [
        false,
        false,
        true,
        false,
        true,
        true,
        false,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        false,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ],
      "siteId": 83
    },
    {
      "id": 10545,
      "timePoint": "Follow-Up 1",
      "visitDate": "2014-03-24T00:00:00",
      "dateOfVisit": "Mar 24, 2014",
      "dataEntry": [
        false,
        false,
        true,
        false,
        true,
        true,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ],
      "siteId": 83
    },
    {
      "id": 10546,
      "timePoint": "Follow-Up 2",
      "visitDate": "2015-03-23T00:00:00",
      "dateOfVisit": "Mar 23, 2015",
      "dataEntry": [
        false,
        false,
        true,
        false,
        true,
        true,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ],
      "siteId": 83
    },
    {
      "id": 10547,
      "timePoint": "Follow-Up 3",
      "visitDate": "2015-06-08T00:00:00",
      "dateOfVisit": "Jun 08, 2015",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        true,
        false
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ],
      "siteId": 83
    },
    {
      "id": 10548,
      "timePoint": "Follow-Up 4",
      "visitDate": "2016-04-04T00:00:00",
      "dateOfVisit": "Apr 04, 2016",
      "dataEntry": [
        false,
        false,
        true,
        false,
        true,
        true,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ],
      "siteId": 83
    },
    {
      "id": 10549,
      "timePoint": "Follow-Up 5",
      "visitDate": "2017-04-17T00:00:00",
      "dateOfVisit": "Apr 17, 2017",
      "dataEntry": [
        false,
        false,
        true,
        false,
        true,
        true,
        false,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ],
      "siteId": 83
    },
    {
      "id": 10550,
      "timePoint": "Follow-Up 6",
      "visitDate": "2018-03-13T00:00:00",
      "dateOfVisit": "Mar 13, 2018",
      "dataEntry": [
        false,
        false,
        true,
        false,
        true,
        true,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ],
      "siteId": 83
    },
    {
      "id": 10551,
      "timePoint": "Follow-Up 7",
      "visitDate": "2019-03-12T00:00:00",
      "dateOfVisit": "Mar 12, 2019",
      "dataEntry": [
        false,
        false,
        true,
        false,
        true,
        true,
        false,
        true,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ],
      "siteId": 83
    },
    {
      "id": 13107,
      "timePoint": "Follow-Up 8",
      "visitDate": "2020-02-27T00:00:00",
      "dateOfVisit": "Feb 27, 2020",
      "dataEntry": [
        false,
        false,
        false,
        false,
        true,
        true,
        true,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        true,
        true,
        false,
        true,
        true,
        true,
        false,
        false,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 18
        }
      ],
      "siteId": 83
    },
    {
      "id": 13246,
      "timePoint": "Follow-Up 9",
      "visitDate": "2021-03-01T00:00:00",
      "dateOfVisit": "Mar 01, 2021",
      "dataEntry": [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
        true,
        true,
        true,
        false
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 18
        }
      ],
      "siteId": 83
    },
    {
      "id": 20613,
      "timePoint": "Follow-Up 10",
      "visitDate": "2022-04-04T00:00:00",
      "dateOfVisit": "Apr 04, 2022",
      "dataEntry": [
        true,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        true,
        false,
        true,
        true,
        true,
        false,
        true,
        false,
        true,
        true,
        true,
        false,
        false,
        true,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 18
        }
      ],
      "siteId": 83
    },
    {
      "id": 20612,
      "timePoint": "Follow-Up 11",
      "visitDate": "2023-03-22T00:00:00",
      "dateOfVisit": "Mar 22, 2023",
      "dataEntry": [
        true,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        true,
        false,
        true,
        true,
        true,
        false,
        true,
        false,
        true,
        true,
        true,
        false,
        false,
        true,
        true,
        true,
        true
      ],
      "ageData": null,
      "patientDOB": "1960-05-22T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 16
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 18
        }
      ],
      "siteId": 83
    }
  ],
  demo_2: [
    {
      "id": 814,
      "timePoint": "Baseline",
      "visitDate": "2007-05-21T00:00:00",
      "dateOfVisit": "May 21, 2007",
      "dataEntry": [
        true,
        false,
        true,
        true,
        true,
        false,
        true,
        true,
        true,
        false,
        false,
        true,
        false,
        true
      ],
      "ageData": {
        "physioage": "54",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "53",
        "immunoage": "",
        "cardioage": "60",
        "pulmoage": "50",
        "neuroage": "51",
        "cutoage": "45"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        }
      ]
    },
    {
      "id": 881,
      "timePoint": "Follow-Up 1",
      "visitDate": "2007-11-28T00:00:00",
      "dateOfVisit": "Nov 28, 2007",
      "dataEntry": [
        true,
        false,
        true,
        true,
        true,
        false,
        true,
        true,
        true,
        false,
        false,
        true,
        false
      ],
      "ageData": {
        "physioage": "47",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "59",
        "immunoage": "",
        "cardioage": "31",
        "pulmoage": "52",
        "neuroage": "51",
        "cutoage": "44"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        }
      ]
    },
    {
      "id": 899,
      "timePoint": "Follow-Up 2",
      "visitDate": "2008-01-23T00:00:00",
      "dateOfVisit": "Jan 23, 2008",
      "dataEntry": [
        true,
        false,
        true,
        true,
        true,
        false,
        true,
        true,
        true,
        false,
        false,
        true,
        false
      ],
      "ageData": {
        "physioage": "41",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "56",
        "immunoage": "",
        "cardioage": "23",
        "pulmoage": "51",
        "neuroage": "49",
        "cutoage": "41"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        }
      ]
    },
    {
      "id": 974,
      "timePoint": "Follow-Up 3",
      "visitDate": "2008-07-02T00:00:00",
      "dateOfVisit": "Jul 02, 2008",
      "dataEntry": [
        true,
        false,
        true,
        true,
        true,
        false,
        false,
        true,
        true,
        false,
        false,
        true,
        false
      ],
      "ageData": {
        "physioage": "50",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "60",
        "immunoage": "49",
        "cardioage": "39",
        "pulmoage": "50",
        "neuroage": "",
        "cutoage": "44"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        }
      ]
    },
    {
      "id": 997,
      "timePoint": "Follow-Up 4",
      "visitDate": "2008-09-11T00:00:00",
      "dateOfVisit": "Sep 11, 2008",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        false,
        false,
        true,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "63",
        "immunoage": "60",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 15,
          "suitePosition": 13
        }
      ]
    },
    {
      "id": 1561,
      "timePoint": "Follow-Up 5",
      "visitDate": "2010-06-14T00:00:00",
      "dateOfVisit": "Jun 14, 2010",
      "dataEntry": [
        true,
        true,
        true
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "57",
        "immunoage": "56",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        }
      ]
    },
    {
      "id": 1463,
      "timePoint": "Follow-Up 6",
      "visitDate": "2011-05-31T00:00:00",
      "dateOfVisit": "May 31, 2011",
      "dataEntry": [
        true,
        true
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "58",
        "immunoage": "62",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        }
      ]
    },
    {
      "id": 2871,
      "timePoint": "Follow-Up 7",
      "visitDate": "2013-09-25T00:00:00",
      "dateOfVisit": "Sep 25, 2013",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "54",
        "immunoage": "59",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 27,
          "suitePosition": 12
        }
      ]
    },
    {
      "id": 3304,
      "timePoint": "Follow-Up 8",
      "visitDate": "2014-03-18T00:00:00",
      "dateOfVisit": "Mar 18, 2014",
      "dataEntry": [
        true,
        true,
        true,
        true,
        false,
        false,
        true,
        false,
        false,
        true
      ],
      "ageData": {
        "physioage": "40",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "",
        "immunoage": "",
        "cardioage": "23",
        "pulmoage": "67",
        "neuroage": "48",
        "cutoage": "36"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 27,
          "suitePosition": 12
        }
      ]
    },
    {
      "id": 3641,
      "timePoint": "Follow-Up 9",
      "visitDate": "2014-06-25T00:00:00",
      "dateOfVisit": "Jun 25, 2014",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "54",
        "immunoage": "",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 19,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        }
      ]
    },
    {
      "id": 4647,
      "timePoint": "Follow-Up 10",
      "visitDate": "2016-01-06T00:00:00",
      "dateOfVisit": "Jan 06, 2016",
      "dataEntry": [
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": {
        "physioage": "48",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "59",
        "immunoage": "73",
        "cardioage": "27",
        "pulmoage": "61",
        "neuroage": "39",
        "cutoage": "41"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        }
      ]
    },
    {
      "id": 4715,
      "timePoint": "Follow-Up 11",
      "visitDate": "2016-02-16T00:00:00",
      "dateOfVisit": "Feb 16, 2016",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "",
        "immunoage": "62",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        }
      ]
    },
    {
      "id": 4950,
      "timePoint": "Follow-Up 12",
      "visitDate": "2016-07-25T00:00:00",
      "dateOfVisit": "Jul 25, 2016",
      "dataEntry": [
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        true,
        true,
        true,
        false
      ],
      "ageData": {
        "physioage": "48",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "",
        "immunoage": "63",
        "cardioage": "39",
        "pulmoage": "65",
        "neuroage": "38",
        "cutoage": "41"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        }
      ]
    },
    {
      "id": 5374,
      "timePoint": "Follow-Up 13",
      "visitDate": "2017-02-02T00:00:00",
      "dateOfVisit": "Feb 02, 2017",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "59",
        "immunoage": "",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        }
      ]
    },
    {
      "id": 6012,
      "timePoint": "Follow-Up 14",
      "visitDate": "2017-05-11T00:00:00",
      "dateOfVisit": "May 11, 2017",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "",
        "immunoage": "",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 31,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        }
      ]
    },
    {
      "id": 6273,
      "timePoint": "Follow-Up 15",
      "visitDate": "2017-08-29T00:00:00",
      "dateOfVisit": "Aug 29, 2017",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "",
        "immunoage": "",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        }
      ]
    },
    {
      "id": 6832,
      "timePoint": "Follow-Up 16",
      "visitDate": "2017-12-28T00:00:00",
      "dateOfVisit": "Dec 28, 2017",
      "dataEntry": [
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
        true,
        false,
        true,
        true
      ],
      "ageData": {
        "physioage": "44",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "",
        "immunoage": "",
        "cardioage": "43",
        "pulmoage": "65",
        "neuroage": "44",
        "cutoage": "31"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        }
      ]
    },
    {
      "id": 7329,
      "timePoint": "Follow-Up 17",
      "visitDate": "2018-04-19T00:00:00",
      "dateOfVisit": "Apr 19, 2018",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "58",
        "immunoage": "61",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        }
      ]
    },
    {
      "id": 8907,
      "timePoint": "Follow-Up 18",
      "visitDate": "2019-02-21T00:00:00",
      "dateOfVisit": "Feb 21, 2019",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        true
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "64",
        "glycanage": "",
        "telomerage": "55",
        "immunoage": "",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ]
    },
    {
      "id": 9883,
      "timePoint": "Follow-Up 19",
      "visitDate": "2019-09-16T00:00:00",
      "dateOfVisit": "Sep 16, 2019",
      "dataEntry": [
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": {
        "physioage": "50",
        "incite_trubiohealth_age": "",
        "epigenage": "65",
        "glycanage": "",
        "telomerage": "59",
        "immunoage": "61",
        "cardioage": "56",
        "pulmoage": "74",
        "neuroage": "30",
        "cutoage": "39"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        }
      ]
    },
    {
      "id": 11256,
      "timePoint": "Follow-Up 20",
      "visitDate": "2020-02-06T00:00:00",
      "dateOfVisit": "Feb 06, 2020",
      "dataEntry": [
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": {
        "physioage": "53",
        "incite_trubiohealth_age": "",
        "epigenage": "65",
        "glycanage": "22",
        "telomerage": "57",
        "immunoage": "63",
        "cardioage": "52",
        "pulmoage": "76",
        "neuroage": "33",
        "cutoage": "47"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 18
        }
      ]
    },
    {
      "id": 11546,
      "timePoint": "Follow-Up 21",
      "visitDate": "2020-04-28T00:00:00",
      "dateOfVisit": "Apr 28, 2020",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "",
        "immunoage": "",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 9,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        }
      ]
    },
    {
      "id": 11590,
      "timePoint": "Follow-Up 22",
      "visitDate": "2020-05-04T00:00:00",
      "dateOfVisit": "May 04, 2020",
      "dataEntry": [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        true
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "",
        "telomerage": "",
        "immunoage": "",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        }
      ]
    },
    {
      "id": 12149,
      "timePoint": "Follow-Up 23",
      "visitDate": "2020-06-09T00:00:00",
      "dateOfVisit": "Jun 09, 2020",
      "dataEntry": [
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "",
        "glycanage": "23",
        "telomerage": "",
        "immunoage": "",
        "cardioage": "",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": ""
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 18
        }
      ]
    },
    {
      "id": 12457,
      "timePoint": "Follow-Up 24",
      "visitDate": "2020-11-04T00:00:00",
      "dateOfVisit": "Nov 04, 2020",
      "dataEntry": [
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": {
        "physioage": "60",
        "incite_trubiohealth_age": "",
        "epigenage": "65",
        "glycanage": "21",
        "telomerage": "59",
        "immunoage": "61",
        "cardioage": "52",
        "pulmoage": "73",
        "neuroage": "",
        "cutoage": "49"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 6,
          "suitePosition": 5
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 18
        }
      ]
    },
    {
      "id": 13806,
      "timePoint": "Follow-Up 25",
      "visitDate": "2021-04-14T00:00:00",
      "dateOfVisit": "Apr 14, 2021",
      "dataEntry": [
        true,
        false,
        false,
        false,
        true,
        true,
        true,
        false,
        false,
        true,
        false,
        true,
        true,
        true,
        true,
        true
      ],
      "ageData": {
        "physioage": "",
        "incite_trubiohealth_age": "",
        "epigenage": "68",
        "glycanage": "22",
        "telomerage": "61",
        "immunoage": "64",
        "cardioage": "68",
        "pulmoage": "",
        "neuroage": "",
        "cutoage": "51"
      },
      "patientDOB": "1959-08-29T00:00:00",
      "dataEntryDTO": [
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 8,
          "suitePosition": 1
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 21,
          "suitePosition": 2
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 17,
          "suitePosition": 3
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 32,
          "suitePosition": 4
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 1,
          "suitePosition": 5
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 22,
          "suitePosition": 6
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 14,
          "suitePosition": 7
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 11,
          "suitePosition": 8
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 30,
          "suitePosition": 9
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 13,
          "suitePosition": 10
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 16,
          "suitePosition": 11
        },
        {
          "dataEntry": false,
          "shouldLoad": false,
          "panelPosition": 28,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 29,
          "suitePosition": 12
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": 2,
          "suitePosition": 15
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 17
        },
        {
          "dataEntry": true,
          "shouldLoad": false,
          "panelPosition": null,
          "suitePosition": 18
        }
      ]
    }
  ]
}
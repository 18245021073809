import {connect} from 'react-redux';
import LoadingComponent from '../components/LoadingComponent';
import { actionCreators } from '../modules/analyticsBeta';
import {toggleLoading} from '../../../reducers/global-reducer';
const mapStateToProps = (state) => ({
  ...state.analytics,
})

const mapDispatchToProps = {
  ...actionCreators,
  toggleLoading
}
// let mapStateToProps = (state, ownprops) => {
//   return {
//     shouldRender: false,//!state.loading_states.every(ls => ls.finished),
//     loading_states: state.loading_states || [],
//   };
// }

// let mapDispatchToProps = (dispatch) => {
//   return { }
// }

export default connect(mapStateToProps, mapDispatchToProps)(LoadingComponent);

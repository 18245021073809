import _ from 'lodash'
function getHistoricalChartUrl(values, allNumericalData) {
    values = values ? values.filter(v => parseFloat(v)) : []
    values = values.map(v => parseFloat(v))
    var minvalue = (_.min(values) * 0.9)
    var maxvalue = (_.max(values) * 1.1)
    var bar_width = values.length < 10 ? 4 : values.length < 25 ? 3 : values.length < 34 ? 2 : 1
    let finalUrl = "https://chart.googleapis.com/chart?cht="
    if (values.length < 40) {
        finalUrl = `${finalUrl}bvs&chbh=${bar_width}`
    }
    else {
        finalUrl = `${finalUrl}ls`
    }
    finalUrl = `${finalUrl}&chs=200x30&chd=t:${values.join()}&chf=bg,s,FFFFFF00&chco=667788&chds=${minvalue},${maxvalue}`

    return finalUrl
}

export default getHistoricalChartUrl
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FormControl from '../../../components/FormControls/FormControl';
import reportConstants from '../../../config/reportConstants';
import BootstrapTable from 'react-bootstrap-table-next';
import Grade from '../../Analytics/components/GradeFunctional';
import labels from '../../../config/localization';
import { CalculateChange } from '../../Analytics/tools/calendar'
import html2react from 'html-to-react'
import marked from 'marked';
import enLabels from '../../../config/en_labels';
import constants from '../../../config/constants';
import { getRoundedValue, IsInRange } from '../../Analytics/tools/helpers';
import _ from 'lodash';

const breakpoint = () => {
    // Based on app/assets/stylesheets/brand.scss
    if (window.innerWidth < 480)
        return ('xs')

    else if (window.innerWidth < 768)
        return ('sm')

    else if (window.innerWidth < 992)
        return ('md')

    else if (window.innerWidth < 1025)
        // Drawer goes from full-screen to partial-screen at 1025
        return ('xm') // extra medium

    else if (window.innerWidth < 1200)
        return ('lg')

    else
        return ('xl')
}
let getMaxRange = () => {
    let max_ranges = 8;
    if (['xs', 'sm'].includes(breakpoint())) {
        max_ranges = 3;
    }
    else if (['md'].includes(breakpoint())) {
        max_ranges = 5;
    }
    else if (['xm'].includes(breakpoint())) {
        max_ranges = 7;
    }
    else if (['lg'].includes(breakpoint())) {
        // Drawer actually shrinks in size as it undocks from the left
        max_ranges = 5;
    }
    else {
        max_ranges = 4;
    }
    return max_ranges;
}
let doAddSpectrum = (datum, props) => {
    if (datum) {
        let datum_attributes = { value_label: props.terms && props.terms.You ? props.terms.You.toUpperCase() : 'YOU', value: datum.value, units: datum.units ? datum.units != '%' ? datum.units : datum.units + "25" : "", palette: 'green_to_red' }
        let atts = Object.assign({}, datum_attributes, props.match_attributes, { style: 'dashboard', max_ranges: 10 })
        var params = Object.keys(atts).map(key => key + '=' + atts[key]).join('&');

        let sortedRanges = null

        //if the array contains the negative values
        if (datum.noumenonRubricRanges.filter(s => s.low < 0 || s.high < 0).length) {

            var replacedRanges = datum.noumenonRubricRanges;
            //replacing the null as the null is greater than the negative values
            replacedRanges.map(s => s.low ? s.low : s.low = '-9999')
            replacedRanges.map(s => s.high ? s.high : s.high = '-9999')
            sortedRanges = replacedRanges ? replacedRanges.sort(function (a, b) { return a.low - b.low })
                : null;
            //replacing back the -9999 with the null value as we need to prepopulate it in the drawers
            replacedRanges.map(s => s.low == '-9999' ? s.low = null : s.low);
            replacedRanges.map(s => s.high == '-9999' ? s.high = null : s.high);

            sortedRanges = replacedRanges;
        }
        else {
            sortedRanges = datum.noumenonRubricRanges ? datum.noumenonRubricRanges.sort(function (a, b) { return a.low - b.low })
                : null;
        }

        // let sortedRanges = datum.noumenonRubricRanges ? datum.noumenonRubricRanges.sort(function (a, b) { return a.low - b.low }) : null; 
        // ? datum.noumenonRubricRanges.sort(function (a, b) { return a.low - b.low }) : '';
        let max_ranges = getMaxRange();
        let ranges = sortedRanges ? sortedRanges.map(t => ([
            `lbl:${t.description ? t.description.replace(',', '') : props.terms[t.type.replace(/Range/g, "").toLowerCase()] ?
                props.terms[t.type.replace(/Range/g, "").toLowerCase()]
                : t.type.replace(/Range/g, "")}`,
            `cls:${t.type[0]}`,
            `rng:${(t.low || t.low == 0) && (t.high || t.high == 0) ? `${t.low}-${t.high}%7C` : ((!t.low && (t.high || t.high == 0)) ? `<${t.high}%7C` : ((t.low || t.low == 0) && !t.high) ? `>${t.low}` : '')}`
        ]).join(',')).join('') : ''
        let final = `${constants.biographical_labels.spectrum_route}?${params}&ranges=${ranges}&max_ranges=${max_ranges}&style=narrow`
        return <iframe className={`spectrum ${sortedRanges.length > 5 ? 'full-width-huge' : 'full-width'}`} src={final} />
    }
}
let markdown2react = (md) => {
    marked.setOptions({
        renderer: new marked.Renderer(),
        sanitize: true,
        smartLists: true,
    });

    let parser = new html2react.Parser();
    return (parser.parse(marked(md)));
}

function HealthStatusIndicatorsFunctional(props) {
    const columns = [
        {
            dataField: 'points',
            formatter: (cellContent, row) => (<span>{row.points || row.points === 0 ? <Grade points={row.points} /> : ""}</span>),
            text: props.terms[enLabels.report_labels.grade_label],
        },
        {
            dataField: 'label',
            formatter: (cellContent, row) => (<span className='table-name-break'>{row.label}</span>),
            text: props.terms[enLabels.report_labels.name_label],
        },
        {
            dataField: 'value', text: props.terms[enLabels.report_labels.result_label],
            formatter: (cellContent, row) => {
                if (row.points >= 3.7) {
                    return <span className='success'>{(row.value && row.valueType == constants.numerical_value_type &&
                        !((_.startsWith(row.value, '>') || (_.startsWith(row.value, '<')))))
                        ? getRoundedValue(row.value, row.scale) : row.value}</span>
                }
                else if ((row.valueType == "TX" && (row.referenceRange == null || (row.referenceRange != null && row.referenceRange.replace(/\s/g, "").split(",").includes(row.value.replace(/\s/g, "")))))) {
                    return <span>{row.value}</span>
                }
                else if ((row.valueType == "TX" && row.referenceRange && row.referenceRange != null && !(row.referenceRange.replace(/\s/g, "").split(",").includes(row.value.replace(/\s/g, ""))))) {
                    return <span className='danger' >{row.value}</span>
                }
                else if ((row.points != null && row.points <= 1.3) || (row.referenceRange && row.referenceRange != null && !IsInRange(row.referenceRange, row.value, row))) {
                    return <span className='danger'>{(row.value && row.valueType == constants.numerical_value_type &&
                        !((_.startsWith(row.value, '>') || (_.startsWith(row.value, '<')))))
                        ? getRoundedValue(row.value, row.scale) : row.value}</span>
                }
                else {
                    return <span>{(row.value && row.valueType == constants.numerical_value_type
                        && !((_.startsWith(row.value, '>') || (_.startsWith(row.value, '<')))))
                        ? getRoundedValue(row.value, row.scale) : row.value}</span>
                }
            }
        },
        { dataField: 'units', text: props.terms[enLabels.report_labels.units_label] },
        {
            dataField: 'referenceRange',
            //text: props.terms[enLabels.report_labels.normal_range]
            text: "Lab Ref Range"
        },
        { dataField: 'optimalRange', text: props.terms[enLabels.report_labels.optimal_range] },
        {
            dataField: 'value',
            formatter: (cellContent, row) => (<span>{row.historicData && row.historicData.length &&
                row.historicData.filter(s => s.visitId == props.currentVisit.baselineVisitId).length > 0 ?
                _.head(row.historicData.filter(s => s.visitId == props.currentVisit.baselineVisitId)).value : ''}</span>),
            text: props.terms['Baseline'],
            hidden: props.currentVisit && props.currentVisit.timePoint == constants.baseline ? true : false
        },
        {
            dataField: 'Change',
            formatter: (cellContent, row) => (<span>{(row.historicData && row.historicData.length) ? CalculateChange(row, props.currentVisit.baselineVisitId) : ''}</span>),
            text: props.terms[enLabels.report_labels.change],
            hidden: props.currentVisit && props.currentVisit.timePoint == constants.baseline ? true : false
        },
        {
            dataField: 'sourceIndex',
            formatter: (cellContent, row) => {
                var arr = [];
                for (var i = row.sourceIndex + 65; i <= 90; i++) {
                    return arr[i - 65] = String.fromCharCode(i);
                }
            },
            text: labels.oddities_labels.source_label,
            hidden: true
        }
    ]
    return (
        <div className='health-status-indicators-section'>
            {props.categories ?
                props.categories.map(
                    s => s.sections.map((p) => {
                        if (p.data.filter(props.patientReportDownloadTemplatePast ? q => q.value : q => q.visitId == props.currentVisit.id).length > 0) {
                            return (
                                <React.Fragment>
                                    <div className='complete-col-span'>
                                        <h2 className='section-heading' id={p.key}>{p.name}</h2><span className="headerId">##{p.key}</span>
                                        <div className='section-data'>
                                            {p.data.filter(props.patientReportDownloadTemplatePast ? q => q.value : q => q.visitId == props.currentVisit.id).filter((q) => (q.valueType == "ST" && q.isTextArea == 1)).map(noumenon => {
                                                return (
                                                    <div className="pt-2 pb-2">
                                                        <div>
                                                            <div className='fw-10000 mb-2 h5 pb-2'>{noumenon.label}</div>
                                                        </div>
                                                        <div className='pb-2 noumenon-value-card'>
                                                            <div className='p-4 bb'>
                                                                <div className='fw-10000 mb-2'>{props.terms[enLabels.report_labels.result_label]}</div>
                                                                <div className='ml-3'>{noumenon.value}</div>
                                                            </div>
                                                            <div className='pt-2 p-4'>
                                                                <div className='fw-10000 mb-2'>{props.terms['Baseline']}</div>
                                                                <div className='ml-3'>{noumenon.historicData && noumenon.historicData.length &&
                                                                    noumenon.historicData.filter(s => s.visitId == props.currentVisit.baselineVisitId).length > 0 ?
                                                                    _.head(noumenon.historicData.filter(s => s.visitId == props.currentVisit.baselineVisitId)).value : ''}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {p.data.filter(props.patientReportDownloadTemplatePast ? q => q.value : q => q.visitId == props.currentVisit.id).filter((q) => q.valueType != "ST" && q.isTextArea != 1).length > 0 ?
                                                <div className='patients-report-preview-table'>
                                                    <div className='bootstrap-table-container'>
                                                        <BootstrapTable
                                                            remote
                                                            striped
                                                            bordered={false}
                                                            keyField='id'
                                                            data={p.data.filter(props.patientReportDownloadTemplatePast ? q => q.value : q => q.visitId == props.currentVisit.id).filter((q) => q.valueType != "ST" && q.isTextArea != 1)}
                                                            columns={columns}
                                                        />
                                                    </div>
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    </div>

                                    <div className='two-columns-layout'>
                                        <div className='passage'>
                                            {p.passagesDTO.map(q => {
                                                if (q.passageTranslationDTO != null) {
                                                    return (<div><b className='healthStatHeadings'>{["Description", "Descriere", "説明"].indexOf(q.passageTranslationDTO.heading) == -1 ? q.passageTranslationDTO.heading : null}</b>
                                                        <p>{markdown2react(q.passageTranslationDTO.content)}</p></div>)
                                                }
                                            }
                                            )}
                                        </div>
                                        {p.data.map(u => (
                                            (props.patientReportDownloadTemplatePast ? u => u.value : u.visitId == props.currentVisit.id) ?
                                                u.noumenonRubricRanges && u.noumenonRubricRanges.length > 0 ?
                                                    <div className='passage' id={`#${u.code}`}>
                                                        <span className='name'>{u.label} </span>
                                                        <span className='explanation'>
                                                            {props.noumenonReferences &&
                                                                props.noumenonReferences.filter(s => s.code == u.code).length && _.head(props.noumenonReferences.filter(s => s.code == u.code)).noumenonExplanation != null ?
                                                                <p>{markdown2react(_.head(props.noumenonReferences.filter(s => s.code == u.code)).noumenonExplanation)}</p>
                                                                : ''}
                                                            {/* <p>{u.noumenonExplanation}</p> */}
                                                        </span>

                                                        <div className='spectrum'>
                                                            {doAddSpectrum(u, props)}<br></br>
                                                        </div>
                                                    </div> : '' : ''
                                        ))}
                                    </div>
                                </React.Fragment>)
                        }
                    })) : ''}
        </div>)
}

export default connect()(HealthStatusIndicatorsFunctional);
import React from 'react';

import './rel-component-styles.scss';
import Rels from '../../config/rel';
import PageTitle from '../PageTitle';
import labels from '../../config/localization';

class RelComponent extends React.Component {
    componentDidMount() {
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.pages, path: '' }
        ])
        this.props.setPageTitle(labels.physioage_report_label)
        document.title = `${labels.physioage_label}: ${labels.pages}`
    }
    render() {
        return (
            <div className='rel-component react-bootstrap-table table table-stripe table-hover col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0 mt-5'>
                <PageTitle {...this.props} />
                <tbody>
                    {Rels.map(r => <tr>
                        <td className='m-5'>{r.rel}</td>
                        <td className='m-5'>{r.description}</td>
                    </tr>)}
                </tbody>
            </div>
        )
    }
}
export default RelComponent
import React, { useMemo } from 'react';
import ScatterChart from './ScatterChart';

const FilterCompareNoumenaData = (props) => {
    const filterCompareNoumenaData = useMemo(() => {
        if (props.firstNoumenaResponse && props.secondNoumenaResponse) {
            const firstResponseData = _.head(props.firstNoumenaResponse).noumenonData;
            const secondResponseData = _.head(props.secondNoumenaResponse).noumenonData;

            const firstDataMap = new Map(firstResponseData.map(item => [item.visitId, item]));
            const secondDataMap = new Map(secondResponseData.map(item => [item.visitId, item]));

            const uniqueVisitIds = [...new Set([...firstDataMap.keys(), ...secondDataMap.keys()])].sort((a, b) => a - b);

            return uniqueVisitIds.map(visitId => {
                const firstItem = firstDataMap.get(visitId) || {};
                const secondItem = secondDataMap.get(visitId) || {};

                return {
                    x: firstItem.value ? Number(firstItem.value) : 0,
                    y: secondItem.value ? Number(secondItem.value) : 0,
                    visitId: Number(visitId),
                    gender: firstItem.gender != null ? firstItem.gender : (secondItem.gender != null ? secondItem.gender : ''),
                    patientId: firstItem.patientId ? Number(firstItem.patientId) : (secondItem.patientId ? Number(secondItem.patientId) : 0)
                };
            });
        }
        return [];
    }, [props.firstNoumenaResponse, props.secondNoumenaResponse]);

    return (
        <ScatterChart {...props} scatterData={filterCompareNoumenaData}
            xLabel={`${_.head(props.firstNoumenaResponse).label}`}
            xUnits={`${_.head(_.head(props.firstNoumenaResponse).noumenonData).units == null ? 'NA' : _.head(_.head(props.firstNoumenaResponse).noumenonData).units}`}
            yLabel={`${_.head(props.secondNoumenaResponse).label}`}
            yUnits={`${_.head(_.head(props.secondNoumenaResponse).noumenonData).units == null ? 'NA' : _.head(_.head(props.secondNoumenaResponse).noumenonData).units}`}
            patientId={`${props.firstNoumenaResponse ? _.head(_.head(props.firstNoumenaResponse).noumenonData).patientId :
                _.head(_.head(props.secondNoumenaResponse).noumenonData).patientId}`}
        />
    );
};

// Memoizing Component to rerender only if props change
export default React.memo(FilterCompareNoumenaData, (prevProps, nextProps) => {
    // Returning true will prevent rerender if props are shallowly equal
    return prevProps.firstNoumenaResponse === nextProps.firstNoumenaResponse && prevProps.secondNoumenaResponse === nextProps.secondNoumenaResponse;
});

import React, { Component } from 'react';

class VisitDocuments extends Component {
    componentDidMount() {
        document.body.style = 'background: #ffffff!important';

        if (this.props.match.params
            && this.props.match.params.visitId
            && !isNaN(this.props.match.params.visitId)) {
            this.props.setVisitDocuments(this.props.match.params.visitId)
        } else {
            this.props.history.push('/statuscode/404')
        }
        document.title = window.location.href
    }
    render() {
        return (
            this.props.allVisitDocuments ? <div className='visit-document-details container-fluid'>
                <pre>{JSON.stringify(this.props.allVisitDocuments)}</pre>
            </div> : null
        )
    }
}
export default VisitDocuments
import { connect } from 'react-redux';
import VisitSummary from '../components/VisitSummary';
import { actionCreators } from '../modules/analytics';
import {toggleLoading, toggleNotification, setModelWidth, toggleReportLoading} from '../../../reducers/global-reducer';
let mapStateToProps = (state) => ({
  ...state.analytics,
  isLoading: state.global.isLoading,
  isSmallScreen: state.global.isSmallScreen,
  isReportLoading: state.global.isReportLoading
})

let mapDispatchToProps = {
    ...actionCreators,
    toggleLoading,
    toggleNotification,
    setModelWidth,
    toggleReportLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitSummary);

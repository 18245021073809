import React, { Component } from 'react';
import _ from 'lodash';
import UnAuthLayout from '../../layouts/UnAuthLayout';
import Login from '../Login';
import constants from '../../config/constants';
import { resetTimer, timerDecrement, refreshTokenTimer, tokenTimerIncrement, tokenTimerReset } from '../../utils/resolve-timer';


export default function (ComposedComponent) {
  class Authentication extends Component {
    constructor() {
      super();
      window.addEventListener('load', () => {
        refreshTokenTimerHandler = setInterval(() => {
          this.props.checkAccessToken()
        }, constants.min_for_refresh * 60 * 1000);
      })

    }
    throwInvalidRoute() {
      let currentRoute = _.head(this.props.routes.filter(r => this.props.location.pathname.indexOf(r.path) != -1))
      let role = this.props.loggedInUser && this.props.loggedInUser.role ? this.props.loggedInUser.role : constants.logged_roles[localStorage.getItem(constants.role)]
      if (currentRoute && currentRoute.roles && currentRoute.roles.length && !this.props.location.pathname.includes('mobilerequest')) {
        if (currentRoute.roles.indexOf(role) == -1) {
          window.location.replace('/statuscode/404')
        }
      }
    }
    componentDidMount() {
      if (!this.props.location.pathname.includes('mobilerequest')) {
        this.props.clearSentMailStatus()
        this.props.checkAccessToken()
        refreshTokenTimerHandler = setInterval(() => {
          this.props.checkAccessToken()
        }, constants.min_for_refresh * 60 * 1000);

        this.props.getAuthStatus()
        this.throwInvalidRoute()

        if (!this.props.isAuthenticated && window.location.href.indexOf('login') == -1) {
          window.location.replace('/login')
        }
        if (this.props.location && this.props.location.pathname == '/') {
          if ((this.props.loggedInUser.role == constants.logged_roles.AN || this.props.loggedInUser.role == constants.logged_roles.CG)
            && window.location.href.indexOf('patients/list') == -1) {
            window.location.replace('/patients/list')
          }
          else if (this.props.loggedInUser.role == constants.logged_roles.PT)
            window.location.replace('/analytics')
          else
            window.location.replace('/login')
        }
      }
      else if (this.props.location.pathname && this.props.location.pathname.includes('mobilerequest')) {
        var perishableToken = _.head(_.last(this.props.location.pathname.split('/')).split("?"));
        this.props.checkAccessToken(true, perishableToken)
      }

    }
    componentWillReceiveProps() {
      this.props.getAuthStatus()
      this.throwInvalidRoute()
      if (this.props.location && this.props.location.pathname == '/') {
        if ((this.props.loggedInUser.role == constants.logged_roles.AN || this.props.loggedInUser.role == constants.logged_roles.CG)
          && window.location.href.indexOf('patients/list') == -1) {
          window.location.replace('/patients/list')
        }
        else if (this.props.loggedInUser.role == constants.logged_roles.PT)
          window.location.replace('/analytics')
        else
          window.location.replace('/login')
      }

      // else if ((this.props.loggedInUser.role == constants.logged_roles.PT || this.props.loggedInUser.role == constants.logged_roles.CG) && this.props.loggedInUser.patientId) {
      //   if (this.props.location.pathname.indexOf('.json') == -1) {
      //     this.props.history.push(`/analytics`)
      //   }
      // }

    }
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location)
        window.scrollTo(0, 0)
    }
    render() {
      if (!this.props.isAuthenticated) {
        return UnAuthLayout(Login)
      } else {
        return <ComposedComponent {...this.props} />
      }
    }
  }
  return Authentication;
}

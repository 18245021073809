import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import { toggleLoading, toggleNotification, handleErrors, handleLoading } from '../../../reducers/global-reducer';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import constants from '../../../config/constants';

const SET_PROPS = 'SET_PROPS';
const CLEAR_TABS = 'CLEAR_TABS';

// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }

function getOddities() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                oddities: [],
                no_grid_data: false
            }
        })
        // let defaultSortedField = getState().oddities.defaultSortedField
        promisesList.push(axios.post(API_URL.ODDITY_URL).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    oddities: response.data ? response.data : [],
                    no_grid_data: response.data && !response.data.uniqueOdditieCount ? true : false,
                    headerType: 'oddities',
                    // openObsModal: false,
                }
            })
            let filteredOddities = getState().oddities.filteredOddities
            let currentOddityPage = getState().oddities.currentOddityPage
            if (!filteredOddities || !filteredOddities.length) {
                dispatch(oddityPageChange(currentOddityPage ? currentOddityPage : 1))
            }
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))
        }))

    }
}
function sortOddities(field, order, history) {
    return (dispatch, getState) => {
        let isChange = (getState().oddities.sortField != field || getState().oddities.sortOrder != order)
        dispatch({
            type: SET_PROPS,
            payload: {
                sortField: field,
                sortOrder: order
            }
        })
        if (isChange)
            dispatch(oddityPageChange(1, history))
    }
}
function getOddityErrors() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                oddityerrors: [],
                totalErrors: 0
            }
        })
        promisesList.push(axios.post(API_URL.ODDITY_ERRORS, { ...getState().oddities.searchParam }).then(response => {
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    oddityerrors: response.data && response.data.value ? response.data.value : [],
                    totalErrors: response.data && response.data.totalCount ? response.data.totalCount : 0,
                    no_grid_data: response.data && response.data.value && !response.data.value.length ? true : false
                }
            })
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}
function errorPageChange(pageNumber, history, canReload) {

    return (dispatch, getState) => {
        let searchParam = Object.assign({}, getState().oddities.searchParam, { pageNumber })
        dispatch(toggleNotification(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })

        if (!canReload && history) {
            history.push(`?tab=errors&page=${pageNumber}`)
        }
        dispatch(getOddityErrors())
    }
}

export function odditiesSortParams(sortField, sortOrder, history) {
    return (dispatch, getState) => {
        let sizePerPage = 10
        let page = getState().oddities.currentOddityPage
        let startIndex = (page - 1) * sizePerPage
        let endIndex = startIndex + (sizePerPage - 1)

        sortField = sortField ? sortField : 'count'
        sortOrder = sortOrder ? sortOrder : 'desc'
        dispatch({
            type: SET_PROPS,
            payload: {
                sortField,
                sortOrder
            }
        })
        let oddities = { ...getState().oddities.oddities }
        if (oddities && oddities.uniqueOdditieMessage) {
            oddities.uniqueOdditieMessage = oddities.uniqueOdditieMessage.map(uom => {
                if (!uom.resolved)
                    uom.resolved = 0
                return uom
            })
            oddities.uniqueOdditieMessage = _.orderBy(oddities.uniqueOdditieMessage, [sortField], [sortOrder])
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                filteredOddities: oddities.uniqueOdditieMessage ? oddities.uniqueOdditieMessage.filter((t, i) => i >= startIndex && i <= endIndex) : []
            }
        })
        if (history)
            history.push(`?tab=oddity&page=${getState().oddities.currentOddityPage}&sortField=${sortField}&sortOrder=${sortOrder}`)

    }
}

function oddityPageChange(page, history, canReload) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentOddityPage: page
            }
        })
        let sortField = getState().oddities.sortField ? getState().oddities.sortField : 'count'
        let sortOrder = getState().oddities.sortOrder ? getState().oddities.sortOrder : 'desc'
        dispatch({
            type: SET_PROPS,
            payload: {
                sortField,
                sortOrder
            }
        })
        let sizePerPage = 10
        let startIndex = (page - 1) * sizePerPage
        let endIndex = startIndex + (sizePerPage - 1)
        let oddities = getState().oddities.oddities
        if (oddities && oddities.uniqueOdditieMessage) {
            oddities.uniqueOdditieMessage = oddities.uniqueOdditieMessage.map(uom => {
                if (!uom.resolved)
                    uom.resolved = 0
                return uom
            })
            oddities.uniqueOdditieMessage = _.orderBy(oddities.uniqueOdditieMessage, [sortField], [sortOrder])
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                filteredOddities: oddities.uniqueOdditieMessage ? oddities.uniqueOdditieMessage.filter((t, i) => i >= startIndex && i <= endIndex) : []
            }
        })
        if (!canReload && history)
            history.push(`?tab=oddity&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}`)

    }
}
function resolveError(removeErrorId) {
    return (dispatch, getState) => {
        let openErrorModal = getState() && getState().oddities ? getState().oddities.openErrorModal : true
        dispatch({
            type: SET_PROPS,
            payload: {
                removeErrorId
            }
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                openErrorModal: !openErrorModal
            }
        })
    }
}
function confirmResolveError() {
    return (dispatch, getState) => {
        let removeErrorId = getState() && getState().oddities ? getState().oddities.removeErrorId : 0
        dispatch(toggleLoading(true))
        promisesList.push(axios.delete(`${API_URL.ODDITY_ERROR_RESOLVE}/${removeErrorId}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    openErrorModal: false
                }
            })
            dispatch(handleLoading())
            dispatch(getOddities())
            dispatch(getOddityErrors())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}
export function setActiveTab(tab, history) {
    return (dispatch, getState) => {
        tab = tab.toLowerCase()
        if (tab == 'errors')
            dispatch(errorPageChange(1, history))

        let oddities = getState().oddities
        if (tab == 'oddity' && (oddities.currentOddityPage && oddities.currentOddityPage != 1
            || oddities.sortOrder && oddities.sortOrder != 'desc'
            || oddities.sortField && oddities.sortField != 'count')) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    sortOrder: 'desc',
                    sortField: 'count'
                }
            })
            if (history)
                dispatch(getOddities())
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentOddityPage: 1
                }
            })
            dispatch(oddityPageChange(1, history, true))
        }
        if (tab == 'oddity' || tab == 'errors') {
            dispatch(toggleNotification(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    activeTab: tab
                }
            })
            if (history)
                history.push(`?tab=${tab}`)
        } else {

            dispatch(clearTabSelection(history))
        }
    }
}
export function setActiveObsTab(tab) {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                activeObsTab: tab
            }
        })
    }
}
export function getObservation(obsId) {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.OBSERVATION_URL}/${obsId}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentObservation: response.data,
                    no_grid_data: response.data && Object.keys(response.data).length ? false : true
                }
            })
            // dispatch({
            //     type: SET_PROPS,
            //     payload: {
            //         openObsModal: !openObsModal
            //     }
            // })
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))

        }))
    }
}
export function toggleObservation(obsId) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                activeObsTab: 'observation'
            }
        })
        // let openObsModal = getState() && getState().oddities ? getState().oddities.openObsModal : true
        if (obsId) {
            dispatch(getObservation(obsId))
        } else {
            // dispatch({
            //     type: SET_PROPS,
            //     payload: {
            //         openObsModal: !openObsModal
            //     }
            // })
            dispatch(toggleLoading(false))
        }
    }
}
function clearTabSelection(history) {
    return (dispatch) => {
        if (history)
            history.push('?tab=oddity')
        dispatch({
            type: CLEAR_TABS,
            // openObsModal: false,
            payload: {
                sortField: 'count',
                sortOrder: 'desc',
                currentOddityPage: 1,
                no_grid_data: false
            }
        })
        dispatch(oddityPageChange(1))
    }
}
function editOddity(event, row) {
    return (dispatch, getState) => {
        let oddities = { ...getState().oddities.oddities }
        let message = row.message
        let resolved = event.target.checked ? 1 : 0
        dispatch(toggleLoading(true))
        axios.put(API_URL.ODDITY_RESOLVE, { message, resolved, category: row.category }).then(response => {
            dispatch(getOddities())
            dispatch({
                type: SET_PROPS,
                payload: {
                    filteredOddities: []
                }
            })
            dispatch(getOddityErrors())
        }).catch(errors => {
            dispatch(handleErrors(errors))
        })
    }
}
function getMessageGroup(msg, history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        axios.get(`${API_URL.ODDITY_MESSAGE_GROUP}?msg=${encodeURIComponent(msg)}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    messageGroup: response.data && response.data.value ? response.data.value : [],
                    no_grid_data: response.data && (!response.data.value || !response.data.value.length) ? true : false
                }
            })
            if (history)
                history.push(`/oddities?message=${encodeURIComponent(msg)}`)
            dispatch(toggleLoading(false))
        }).catch(error => dispatch(handleErrors(error)))
    }
}
function searchObservation(identifier, limit, history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.SEARCH_OBSERVATION}?identifier=${encodeURIComponent(identifier)}&limit=${limit || 10}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    observationsList: response.data ? response.data.observationDTOs : [],
                    observationsCount: response.data ? response.data.totalCount : 0,
                    no_obs_data: response.data && response.data.length ? false : true
                }
            })
            dispatch(toggleLoading(false))
            if (history) {
                if (limit)
                    history.push(`/observations?identifier=${encodeURIComponent(identifier)}&limit=${limit}`)
                else history.push(`/observations?identifier=${encodeURIComponent(identifier)}`)
            }
        }).catch(errors => dispatch(handleErrors(errors))))
    }
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    },
    [CLEAR_TABS]: (state, action) => {
        return Object.assign({}, state, {
            activeTab: 'oddity',
            searchParam: {
                pageSize: 10,
                pageNumber: 1,
                sortOrder: 1
            },
            ...action.payload
        })
    }
}

const initialState = {
    activeTab: 'oddity',
    activeObsTab: 'observation',
    oddities: {},
    oddityerrors: [],
    searchParam: {
        pageSize: 10,
        pageNumber: 1,
        sortOrder: 1
    },
    sortOrder: 'desc',
    sortField: 'count',
    openErrorModal: false,
    // openObsModal: false,
    totalErrors: 0,
    no_grid_data: false,
    headerType: null,
    observationsList: []
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export const actionCreators = {
    setActiveTab,
    setActiveObsTab,
    getOddities,
    getOddityErrors,
    errorPageChange,
    resolveError,
    confirmResolveError,
    toggleObservation,
    clearTabSelection,
    editOddity,
    sortOddities,
    oddityPageChange,
    getMessageGroup,
    getObservation,
    searchObservation,
    odditiesSortParams
}
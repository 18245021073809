export default {
    en: {
        report_labels: {
            next_gen_exam: 'Next Generation Physical Exam',
            name_label: 'Name',
            result_label: 'Result',
            visit_date: 'Visit Date ',
            units_label: 'Units',
            normal_range: 'Normal Range',
            optimal_range: 'Optimal Range',
            baseline_label: 'Baseline',
            change: 'Change',
            source_label: 'Source',
            report_card_label: 'Report Card',
            biomarkers_of_aging_label: 'Biomarkers of Aging',
            measured_recently: `This biomarker hasn't been measured recently.`,
            older_actual_age: `years older than your actual age.`,
            older_actual_age_visits: `years older than average for your age.`,
            younger_actual_age: 'years younger than your actual age.',
            younger_actual_age_visits: `years younger than average for your age.`,
            older_younger: `neither older nor younger than average for your age.`,
            your_label: 'Your',
            grade_label: 'Grade',
            prepared_for: 'prepared for',
            by_provider: 'by provider',
            tests_performed: ' based on tests performed',
            table_contents: 'Table of Contents',
            introduction: 'Introduction',
            compare_system: 'Comparing Systems',
            health_status_indicator: 'Health Status Indicators',
            exceptional_results: 'Exceptional Results',
            abnormal_results: 'Abnormal Results',
            complete_data: 'Complete Data',
            historical_data: 'Historical Data',
            disclaimer: 'Disclaimer',
            disclaimer_heading: '',
            physioage: 'PhysioAge',
            cutoage: 'CutoAge',
            immunoage: 'ImmunoAge',
            pulmoage: 'Pulmoage',
            telomerage: 'TelomerAge',
            cardioage: 'CardioAge',
            neuroage: 'NeuroAge',
            man_has_arteries: 'A man is as old as his arteries',
            english_hypocrates: 'known as the English Hypocrates',
            test: 'The Test',
            factors_affect: 'What factors affect my ',
            importance: 'Importance',
            readmore: 'read more',
            older_avg: 'years older than average for your age.',
            younger_avg: 'years younger than average for your age.',
            is_label: 'is',
            test_not_performed: 'test not performed',
            about_test: 'About the Test',
            skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging?',
            skin_elasticity: 'Determining Skin Elasticity',
            significance: 'Significance',
            composite: 'Composite',
            flag: 'Flag',
            first_received: 'First Results Received',
            last_received: 'Last Results Received',
            powered_by: 'Powered By',
            years: 'years'
        }
    },
    es: {
        report_labels: {
            next_gen_exam: 'Next Generation Physical Exam#',
            name_label: 'Name#',
            result_label: 'Result#',
            visit_date: 'Visit Date# ',
            units_label: 'Units#',
            normal_range: 'Normal Range#',
            optimal_range: 'Optimal Range#',
            baseline_label: 'Baseline#',
            change: 'Change#',
            source_label: 'Source#',
            report_card_label: 'Report Card#',
            biomarkers_of_aging_label: 'Biomarkers of Aging#',
            measured_recently: `This biomarker hasn't been measured recently.#`,
            older_actual_age: `years older than your actual age.#`,
            older_actual_age_visits: `years older than average for your age.#`,
            younger_actual_age: 'years younger than your actual age.#',
            younger_actual_age_visits: `years younger than average for your age.#`,
            older_younger: `neither older nor younger than average for your age.#`,
            your_label: 'Your#',
            grade_label: 'Grade#',
            prepared_for: 'prepared for#',
            by_provider: 'by provider#',
            tests_performed: ' based on tests performed#',
            table_contents: 'Table of Contents#',
            introduction: 'Introduction#',
            compare_system: 'Comparing Systems#',
            health_status_indicator: 'Health Status Indicators#',
            exceptional_results: 'Exceptional Results#',
            abnormal_results: 'Abnormal Results#',
            complete_data: 'Complete Data#',
            historical_data: 'Historical Data#',
            disclaimer: 'Limitación de Responsabilidad',
            disclaimer_heading: 'Descripción',
            physioage: 'PhysioAge#',
            cutoage: 'CutoAge#',
            immunoage: 'ImmunoAge#',
            pulmoage: 'Pulmoage#',
            telomerage: 'TelomerAge#',
            cardioage: 'CardioAge#',
            neuroage: 'NeuroAge#',
            man_has_arteries: 'A man is as old as his arteries#',
            english_hypocrates: 'known as the English Hypocrates#',
            test: 'The Test#',
            factors_affect: 'What factors affect my#',
            importance: 'Importance#',
            readmore: 'read more#',
            older_avg: 'years older than average for your age.#',
            younger_avg: 'years younger than average for your age.#',
            is_label: 'is#',
            test_not_performed: 'test not performed#',
            about_test: 'About the Test#',
            skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging?#',
            skin_elasticity: 'Determining Skin Elasticity#',
            significance: 'Significance#',
            composite: 'Composite#',
            flag: 'Flag#',
            first_received: 'First Results Received#',
            last_received: 'Last Results Received#',
            powered_by: 'Powered By#',
            years: 'años'
        },
    },
    ja: {
        report_labels: {
            next_gen_exam: 'Next Generation Physical Exam#',
            name_label: 'Name#',
            result_label: 'Result#',
            visit_date: 'Visit Date# ',
            units_label: 'Units#',
            normal_range: 'Normal Range#',
            optimal_range: 'Optimal Range#',
            baseline_label: 'Baseline#',
            change: 'Change#',
            source_label: 'Source#',
            report_card_label: 'Report Card#',
            biomarkers_of_aging_label: 'Biomarkers of Aging#',
            measured_recently: `This biomarker hasn't been measured recently.#`,
            older_actual_age: `years older than your actual age.#`,
            older_actual_age_visits: `years older than average for your age.#`,
            younger_actual_age: 'years younger than your actual age.#',
            younger_actual_age_visits: `years younger than average for your age.#`,
            older_younger: `neither older nor younger than average for your age.#`,
            your_label: 'Your#',
            grade_label: 'Grade#',
            prepared_for: 'prepared for#',
            by_provider: 'by provider#',
            tests_performed: ' based on tests performed#',
            table_contents: 'Table of Contents#',
            introduction: 'Introduction#',
            compare_system: 'Comparing Systems#',
            health_status_indicator: 'Health Status Indicators#',
            exceptional_results: 'Exceptional Results#',
            abnormal_results: 'Abnormal Results#',
            complete_data: 'Complete Data#',
            historical_data: 'Historical Data#',
            disclaimer: '免責事項',
            disclaimer_heading: '説明',
            physioage: 'PhysioAge#',
            cutoage: 'CutoAge#',
            immunoage: 'ImmunoAge#',
            pulmoage: 'Pulmoage#',
            telomerage: 'TelomerAge#',
            cardioage: 'CardioAge#',
            neuroage: 'NeuroAge#',
            man_has_arteries: 'A man is as old as his arteries#',
            english_hypocrates: 'known as the English Hypocrates#',
            test: 'The Test#',
            factors_affect: 'What factors affect my#',
            importance: 'Importance#',
            readmore: 'read more#',
            older_avg: 'years older than average for your age.#',
            younger_avg: 'years younger than average for your age.#',
            is_label: 'is#',
            test_not_performed: 'test not performed#',
            about_test: 'About the Test#',
            skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging?#',
            skin_elasticity: 'Determining Skin Elasticity#',
            significance: 'Significance#',
            composite: 'Composite#',
            flag: 'Flag#',
            first_received: 'First Results Received#',
            last_received: 'Last Results Received#',
            years: '年份'
        }
    },
    ro: {
        report_labels: {
            next_gen_exam: 'Next Generation Physical Exam#',
            name_label: 'Name#',
            result_label: 'Result#',
            visit_date: 'Visit Date# ',
            units_label: 'Units#',
            normal_range: 'Normal Range#',
            optimal_range: 'Optimal Range#',
            baseline_label: 'Baseline#',
            change: 'Change#',
            source_label: 'Source#',
            report_card_label: 'Report Card#',
            biomarkers_of_aging_label: 'Biomarkers of Aging#',
            measured_recently: `This biomarker hasn't been measured recently.#`,
            older_actual_age: `years older than your actual age.#`,
            older_actual_age_visits: `years older than average for your age.#`,
            younger_actual_age: 'years younger than your actual age.#',
            younger_actual_age_visits: `years younger than average for your age.#`,
            older_younger: `neither older nor younger than average for your age.#`,
            your_label: 'Your#',
            grade_label: 'Grade#',
            prepared_for: 'prepared for#',
            by_provider: 'by provider#',
            tests_performed: ' based on tests performed#',
            table_contents: 'Table of Contents#',
            introduction: 'Introduction#',
            compare_system: 'Comparing Systems#',
            health_status_indicator: 'Health Status Indicators#',
            exceptional_results: 'Exceptional Results#',
            abnormal_results: 'Abnormal Results#',
            complete_data: 'Complete Data#',
            historical_data: 'Historical Data#',
            disclaimer: 'act de renunțare',
            disclaimer_heading: 'Descriere',
            physioage: 'PhysioAge#',
            cutoage: 'CutoAge#',
            immunoage: 'ImmunoAge#',
            pulmoage: 'Pulmoage#',
            telomerage: 'TelomerAge#',
            cardioage: 'CardioAge#',
            neuroage: 'NeuroAge#',
            man_has_arteries: 'A man is as old as his arteries#',
            english_hypocrates: 'known as the English Hypocrates#',
            test: 'The Test#',
            factors_affect: 'What factors affect my#',
            importance: 'Importance#',
            readmore: 'read more#',
            older_avg: 'years older than average for your age.#',
            younger_avg: 'years younger than average for your age.#',
            is_label: 'is#',
            test_not_performed: 'test not performed#',
            about_test: 'About the Test#',
            skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging?#',
            skin_elasticity: 'Determining Skin Elasticity#',
            significance: 'Significance#',
            composite: 'Composite#',
            flag: 'Flag#',
            first_received: 'First Results Received#',
            last_received: 'Last Results Received#',
            years: 'ani'
        }
    },
    pl: {
        report_labels: {
            next_gen_exam: 'Next Generation Physical Exam#',
            name_label: 'Name#',
            result_label: 'Result#',
            visit_date: 'Visit Date# ',
            units_label: 'Units#',
            normal_range: 'Normal Range#',
            optimal_range: 'Optimal Range#',
            baseline_label: 'Baseline#',
            change: 'Change#',
            source_label: 'Source#',
            report_card_label: 'Report Card#',
            biomarkers_of_aging_label: 'Biomarkers of Aging#',
            measured_recently: `This biomarker hasn't been measured recently.#`,
            older_actual_age: `years older than your actual age.#`,
            older_actual_age_visits: `years older than average for your age.#`,
            younger_actual_age: 'years younger than your actual age.#',
            younger_actual_age_visits: `years younger than average for your age.#`,
            older_younger: `neither older nor younger than average for your age.#`,
            your_label: 'Your#',
            grade_label: 'Grade#',
            prepared_for: 'prepared for#',
            by_provider: 'by provider#',
            tests_performed: ' based on tests performed#',
            table_contents: 'Table of Contents#',
            introduction: 'Introduction#',
            compare_system: 'Comparing Systems#',
            health_status_indicator: 'Health Status Indicators#',
            exceptional_results: 'Exceptional Results#',
            abnormal_results: 'Abnormal Results#',
            complete_data: 'Complete Data#',
            historical_data: 'Historical Data#',
            disclaimer: 'Disclaimer',
            disclaimer_heading: 'Opis',
            physioage: 'PhysioAge#',
            cutoage: 'CutoAge#',
            immunoage: 'ImmunoAge#',
            pulmoage: 'Pulmoage#',
            telomerage: 'TelomerAge#',
            cardioage: 'CardioAge#',
            neuroage: 'NeuroAge#',
            man_has_arteries: 'A man is as old as his arteries#',
            english_hypocrates: 'known as the English Hypocrates#',
            test: 'The Test#',
            factors_affect: 'What factors affect my#',
            importance: 'Importance#',
            readmore: 'read more#',
            older_avg: 'years older than average for your age.#',
            younger_avg: 'years younger than average for your age.#',
            is_label: 'is#',
            test_not_performed: 'test not performed#',
            about_test: 'About the Test#',
            skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging?#',
            skin_elasticity: 'Determining Skin Elasticity#',
            significance: 'Significance#',
            composite: 'Composite#',
            flag: 'Flag#',
            first_received: 'First Results Received#',
            last_received: 'Last Results Received#',
            powered_by: 'Powered By#',
            years: 'lat/a'
        },
    }
}

export default {
    physioage_label: 'PhysioAge',
    physioage_report_label: 'PhysioAge Reporting',
    site_label: '现场',
    login_help: '如果您在重置密码时遇到困难,请随时拨打我们的支持电话',
    login_help_text: '使用您的电子邮件地址登录.',
    login_btn_text: '登入',
    forgot_password_text: '忘记密码',
    forgot_password_label: '请求密码重置',
    email_label: '电子邮件',
    forgot_password_instruction: '如果您有帐户,重置密码的说明将发送到上面的地址。',
    navigate_login: '返回登录屏幕',
    reset_my_password_label: '重置我的密码',
    inside_us: '在美国境内',
    outside_us: '在美国之外',
    done_label: '完成',
    return_login_btn: '返回登录页面',
    reset_password_label: '重置你的密码',
    password: '密码',
    confirm_password: '确认密码',
    update_password: 'Update Password',
    update_password_btn: '更新我的密码',
    try_again: 'Please try again:?',
    link_expired: 'Link Expired?',
    link_expired_notification: 'The link you used to reset your password expired. Typically this happens when you wait several days to use the link, or when you (or someone else) went through the process of ressing your password again.?',
    set_password: 'Set Your Password?',
    set_password_notification: 'Your password must be changed before you can access your data. We know passwords are a hassle. But we think the next step will be worth your time.?',
    login_caution_start: '当您登录时,您同意受我们的约束',
    login_caution_conj: '和',
    invalid_email: `Invalid Email Address. Please provide a valid email address?`,
    login_cookies: '包括但不限于允许使用 饼干。',
    unauth_login: `You must be an administrator to access this page.?`,
    something_went_wrong: 'Something went wrong?',
    not_available: 'NA?',
    none: 'None?',
    login_label: 'Login?',
    pagenotfound: {
        title: '网页未找到',
        help: '你正在寻找不存在的东西',
        redirect_label: '重定向到家',
        login_label: '再次登录',
        session_expired: '会话已过期',
        back_label: 'Navigate to home?'
    },
    footer_labels: {
        privacy: '隐私政策',
        tos: '服务条款'
    },
    header_labels: {
        credits: '积分',
        account: '帐户',
        user_settings: '用户设置',
        logout: '登出',
        feedback: '反馈',
        patients: '耐心',
        help: '救命',
        current_manual: '当前手册',
        all_manuals: '所有手册',
        videos: '影片',
        ops: 'OPS',
        active_site_report: '活动站点报告',
        providers: '供应商',
        sites: '网站',
        elk: '三心水',
        users: '用户',
        billing_summary: '结算摘要',
        message_routing: '消息路由',
        inbox: '收件箱',
        oddities: '天下奇闻',
        corrections: '更正',
        data: '数据',
        explore_noumena: '探索 诺美纳',
        volume_report: '卷报告',
        vadr: 'VADR?',
        remaining_label: 'remaining?',
        practice: 'Practice?',
        explore_data: '探索 数据',
        download_library: 'Download Library?',
    },
    patient_list_labels: {
        title: '患者名单',
        patient_title: 'Patient?',
        filter_patients_label: '过滤患者',
        site_label: '现场',
        name_label: '名称',
        min_visits_label: '最低访问量',
        search: '搜索',
        title_label: '患者名单',
        add_patient: '加病人',
        patient_title_new: 'PhysioAge: New Patient?',
        new_patient_title: '新的患者表格',
        edit_patient_form: '编辑患者表格',
        honorific_prefix: '荣誉前缀',
        honorific_suffix: '荣誉后缀',
        honorific_suffix_help: 'CPA, DDS, MDiv.',
        first_name: '名字',
        last_name: '姓',
        email: '电子邮件',
        dob: '出生日期',
        dob_help: '一旦选择了年份和月份,您必须点击当天.',
        site: '现场',
        provider: '提供商',
        language_pref: '语言首选项',
        save: '保存',
        male: '男',
        female: '女',
        add_visit: '添加访问',
        edit_Patient: '编辑病人',
        patient_details: '患者详情',
        patient_portal: '病人门户',
        view_patient_portal: '查看患者门户',
        enable_patient_portal: '启用病人门户',
        resend_email: '重新发送欢迎电子邮件',
        portal_caution: '他们没有使用它。',
        portal_last_viewed: ' Last viewed?',
        portal_help: '该患者可以访问患者门户。',
        portal_mail_help: '在我们允许该患者访问患者门户之前,我们需要他们的电子邮件地址。请在下面输入。',
        enable_portal_caution: '该患者无法访问患者门户。单击下面的按钮以启用访问权限。',
        update_patient: '更新患者',
        edit_label: '编辑',
        all_sites: '所有网站',
        gender_label: '性别',
        no_visits: '没有访问',
        clear_search: '清除搜索',
        create_visit: '创建访问',
        visit_date: '访问日期 ',
        visits: 'Visits?',
        add_visit_help: '选择要添加访问的日期',
        view_health_analytics: '查看健康分析',
        credits_elk: `您没有积分来添加访问`,
        future_date: ` 未来太过分了 `,
        agg_age_chart: '总体老化图表',
        resend_welcome_email: `We will send another welcome email to this user within the next 10 minutes.?`,
        no_visit_caution: `This patient does not have access to the patient portal.
                            Once you have set up a visit for them, you'll be able to provide access to them.?`,
        no_visits_patients: `Each patient visit must be set up before data will be available. Currently this system doesn't know about any visits for this patient.?`,
        data_entry: 'Data Entry?',
        never: 'Never?',
        new_visits: 'New visits for?',
        providers_validate: 'Select Sites to get providers?',
        sex: 'Sex?',
        last_active: 'Last Active?',
        identifier: 'Identifier?',
        identifier_help: 'Optional field to store your local patient identifiers?',
        delete_patient: 'Delete Patient?',
        delete_patient_prompt: 'Do you want to delete this patient permanently?',
        delete_patient_desc: "This will delete all the visits associated with the patient and its data?",
    },
    feedback_labels: {
        help_text: "我们欢迎并感谢您提供的任何反馈。我们有兴趣听到您对如何改进我们的应用程序的任何想法。",
        save_text: '发送反馈',
        title: '反馈',
        feedback_success: '您的反馈意见已经完成。谢谢您的意见。'
    },
    user_labels: {
        edit: '编辑',
        title: '用户列表',
        new_user: '新用户',
        edit_user: '编辑用户',
        account_settings: '帐户设置',
        email_addresses_help: '所有当前用户的电子邮件地址:',
        firstname: '名字',
        lastname: '姓氏',
        username: '用户名',
        email: '电子邮件',
        active: '活跃',
        activeWithQ: `活跃?`,
        site: '网站',
        role: '角色',
        update_user: '更新用户',
        reset_password: '重置密码',
        type_password: '输入密码',
        confirm_password: '确认密码',
        update_password: '更新密码',
        retype_password: '重新输入密码',
        password: '密码',
        add_user: '添加用户',
        password_not_matched: '密码不匹配',
        job_type: '工作类型',
        provider_label: '提供商',
        gender_label: '性别',
        filter_users: 'Filter Users?',
        master: 'master?',
    },
    credits_labels: {
        title: '报告点数',
        add_credits: '添加积分',
        download: '下载',
        receipt: '收据',
        history: '历史',
        receipt_header: 'Receipt?',
        date: 'Date :?',
        client: 'Client :?',
        description: 'Description?',
        quantity: 'Quantity?',
        amount: 'Amount?',
        ok_btn: 'OK?',
        credit_transactions_name: 'credit_transactions?.csv'
    },
    sites_labels: {
        new_site_label: '新网站',
        sites_list_label: '网站',
        total_sites: '总站点',
        total_clinic: '总诊所',
        new_clinic: '新诊所此Q',
        active_clinic: '活跃诊所此Q',
        add_site: '添加网站',
        list_sites_filter: '列出接受推荐的网站',
        list_sites_filter_title: 'Sites Accepting Referrals?',
        all_sites: '列出所有网站',
        name_label: '姓名',
        category_label: '类别',
        billing_label: '结算',
        credits_label: '积分',
        providers_label: '提供商',
        patients_label: '患者',
        language_label: 'I18N',
        units_label: '单位',
        name_help: '这是将在患者门户网站上显示的名称,当我们通过电子邮件发送给患者的链接时,该名称是发件人的姓名。它也将用于整个网站的面包屑和其他地方。',
        site_referrals_help: '提供者查找器推荐',
        preferences: 'Localization Preferences?',
        language_preference: '语言偏好',
        date_preference: '日期偏好',
        unit_preference: '单位偏好',
        unit_preference_help: '为现有网站更改此信息可能需要一段时间。建议你咨询开发者。',
        margins_help: '添加边距以容纳双面打孔',
        logo_label: '商标',
        banner_logo_label: '横幅标志',
        settings: 'Other Settings?',
        sites_message_acknowledge: '网站接受消息',
        analytics_portal_acknowledge: '分析作为门户',
        billing_strategy: '结算策略',
        brand: '品牌',
        default_panels: '默认面板',
        referral_info: '推荐和宣传信息',
        address_help: '此地址将通过提供者查找器提供给潜在患者,并可能在地图上绘制。',
        address_label: '地址',
        city_label: '城市',
        state_label: '状态',
        postal_code: '邮政编码',
        country: '国家',
        phone: '电话',
        phone_help: '这个号码将提供给患者并由 PhysioAge 使用,以防我们需要就站点中断与他们联系。',
        website_label: '网站',
        other_info: '其他信息',
        principle_ehr: '原则 EHR',
        save_btn: '保存',
        edit_site: '编辑',
        add_provider: '添加提供商',
        add_user: '添加用户',
        add_elk: '添加ELK',
        contact_info: '联系信息',
        portal_label: '门户',
        accept_referral_label: '接受推荐',
        users_label: '用户',
        recent_activity: '近期活动',
        lastseen: '上次见过',
        month_label: '月',
        year_label: '年',
        visits_label: '访问',
        analytics_label: '分析',
        old_portal_label: '旧门户',
        transaction_history: '交易历史',
        current_image_label: 'Current Image: ?',
        referral: 'referral?',
        all: 'all?',
        not_uploaded: 'Not Uploaded?',
        download_attendee: 'Download Attendee List?',
        features_label: 'Features?',
        include_phone_in_summary: 'Include Phone Number in Visit Summary?'
    },
    provider_labels: {
        providers_list: '提供商清单',
        add_provider: '添加提供商',
        update_provider: '更新提供商',
        edit_provider: '编辑',
        list_title_label: '提供商',
        provider_name: '提供者',
        sites_label: '网站',
        patients_label: '患者',
        last_visit: '上次访问',
        honorific_prefix: '荣誉前缀',
        firstname: '名字',
        lastname: '姓氏',
        honorific_suffix: '荣誉后缀',
        honorific_suffix_help: 'CPA,DDS,MDiv',
        create_provider: '创建提供者',
        profile_pic: '点击图片更改图片',
        image_label: '图片',
        avatar: '-avatar',
        remove_pic: 'Remove picture?'
    },
    billing_labels: {
        title: '结算摘要',
        site_label: '网站',
        startdate_label: '开始日期',
        enddate_label: '结束日期',
        generate: '生成',
        num_reports_labels: '报告数',
        charge_report: '按报告收费',
        total_charge: '总费用',
        filter_label: '过滤器',
        physician: '医师',
        patient: '患者',
        timepoint: '时间点',
        created: '创建',
        visit_date: '访问日期'
    },
    message_routing_labels: {
        list_title: '消息路由规则',
        add_rule: '添加规则',
        message_routes: '消息路由',
        new_message_routing: '新邮件路由规则',
        edit_message_routing: '编辑邮件路由规则',
        sending_application: '发送应用',
        sending_facility: '发送设施',
        receiving_application: '接收申请',
        receiving_facility: '接收设施',
        site: '网站',
        panel: '小组',
        add_message_route: '添加消息路由',
        update_message_route: '更新消息路由',
        edit_label: '编辑',
        apply_label: '申请',
        pid18_1: 'Pid18 1#',
        copy: 'Copy'
    },
    help_page_labels: {
        current_manual_title: '当前生物标记手册',
        download_label: '下载',
        delete_label: '删除',
        all_manual_title: '生物标记手册历史',
        videos_title: '培训视频',
        cutometer: 'Cutometry',
        cutometry: 'MPA 580',
        spirometer: '脉搏波分析',
        spirometry1: 'SphygmoCor XCEL',
        spirometry2: 'SphygmoCor CP',
        sphygmoCor: '肺活量测定',
        sphygmocor1: 'EasyOneAir',
        sphygmocor2: 'MidMark IQSpiro',
        upload_doc: '上传新文档',
        upload_doc_title: '上传新的生物标记手册',
        version: '版本',
        attachment: '附件',
        create_btn_text: '创建帮助文件',
        delete_warning: '你确定要删除手册吗？',
        ok_btn: '好',
        cancel_btn: '取消',
        helpFile_validation: 'Please upload pdf file?',
        version_validation: 'Please enter version?',
        invalid_file: 'Invalid file?',
        uploaded: 'Uploaded?',
    },
    elk_labels: {
        title: '信用交易',
        filter: '过滤器',
        site: '网站',
        add_title: '添加积分',
        no_of_sites: '积分数',
        comment: '评论',
        create_credit_btn: '创建信用交易',
        user: 'User?',
        change: 'Change?'
    },
    tos_labels: {
        title: '使用条款',
        last_updated: '上次更新时间为2018年1月31日'
    },
    privacy_labels: {
        title: '隐私政策',
        last_updated: '上次更新时间为2018年1月31日'
    },
    corrections_labels: {
        title: '更正',
        name: '名称',
        date: '日期',
        description: '描述'
    },
    oddities_labels: {
        title: '独特的奇怪',
        oddities: '奇怪',
        errors: '所有错误',
        message_label: '消息',
        count_label: '计数',
        resolve: '解决',
        ok_btn: '好',
        cancel_btn: '取消',
        close_btn: '关闭',
        observation: '观察',
        noumenon: '本体',
        id: 'ID',
        resultId: '结果ID',
        valueType: '值类型',
        identifier: '标识符',
        subIdentifier: '子标识符',
        label: '标签',
        value: '价值',
        units: '单位',
        referenceRange: '参考范围',
        abnormalFlags: '异常标志',
        probability: '概率',
        natureOfAbnormalTest: '异常测试的性质',
        resultStatus: '结果状态',
        observedAt: '观察',
        producer: '制片人',
        observationMethod: '观察方法',
        equipmentInstance: '设备实例',
        analyzedAt: '分析',
        createdAt: '创建于',
        updatedAt: '更新于',
        observerType: '观察者类型',
        observerId: '观察者ID',
        datumId: '基准ID',
        result_label: '结果',
        source_label: '来源',
        history: '历史',
        observation_status: '状态',
        observation_id: '观察ID',
        result_date: '结果日期',
        no_observations: 'No Observations found?',
        no_history: 'No History found?',
        reference: 'Reference?',
        date_time: 'Date / Time?',
        resolve_error: 'Are you sure to resolve this error??',
        resolved: 'Resolved?',
        category: 'Category?',
        examples: 'examples?',
        messagegroup: 'Oddity Messages?',
        sample_observations: 'Sample Observations?',
        observations_info_1: 'observations?',
        observations_info_2: ' match identifier?',
        visit: 'Visit?',
        obx: 'OBX'
    },
    inbox_labels: {
        title: '收件箱',
        filter_title: '按发件人过滤',
        filter_subtitle: '过滤消息',
        raw_btn: 'Raw',
        resolve_btn: '解决',
        resolve_title: '解决消息',
        demo_modal: '启动演示模式',
        msg_details: '消息详细信息',
        from_label: '从',
        date_label: '日期',
        name_label: '患者姓名',
        dob_label: '患者DOB',
        close_label: '关闭',
        launch_demo_header: '消息的原始内容',
        site_label: '网站',
        patient: 'Patient?',
        dob: 'DOB?',
        dobLabel: 'dob?',
        visit: 'Visit?',
        received: 'Received?',
        processed: 'Processed?',
        back: 'Back?',
        aboutyear_back: 'about 1 year back?',
        about_year_back: 'years back?',
        days_back: 'days back?',
        reference: 'Reference?',
        createdAt: 'Created At?',
        processedAt: 'Processed At?',
        details: 'Details?',
        message_details: 'Message Details?',
        oddities: 'Oddities?',
        routedTo: 'Routed To?',
        imported: 'Imported??',
        notrouted: 'Not Routed?',
        no_label: 'no?',
        un_routed_messages: 'Unrouted Messages',
        failure_messages: 'Failure Messages'
    },
    asr_labels: {
        title: '活动站点报告',
        active_site_month: '按月活动网站',
        visit_volume: '访问量',
        active_sites: '活动网站',
        excluding: '排除'
    },
    analytics_labels: {
        exit_Analytics: '退出分析',
        menu: '菜单',
        most_recent_visit: '最近访问',
        years_old: '岁',
        biographical_information_label: 'Biographical Information',
        biographical_info_label: '传记信息',
        biomarkers_of_aging_label: '衰老的生物标志物',
        health_card_report_label: '健康报告卡',
        recommendations_label: '建议',
        download_report_label: '下载报告',
        patient_report_label: '患者报告',
        download_recommendations: '下载建议',
        download_pdf_label: '查看/下载PDF',
        no_recommandations: '你还没有输入任何建议。',
        no_recommandations_content: '要输入建议,当您单击任何参数本身时,您必须位于右侧出现的“抽屉”中。我们的想法是,当您在咨询期间查看实验室或生物标记时,可以单击参数,例如“Testosterone Free”,然后从屏幕右侧出来一个抽屉。你可以查看解释谱,从基线的变化,所有点的图表,数据表,当你向下滚动时,有一个表格来输入你的建议。',
        comparison_your_ages: '比较你的年龄',
        logOut: 'Log Out?',
        no_visits_label: 'Patient has no visits on file.?',
        measured_recently: `This biomarker hasn't been measured recently.?`,
        older_actual_age: `years older than your actual age.?`,
        younger_actual_age: 'years younger than your actual age.?',
        average_for_age: 'About average for your age.?',
        older_actual_age_visits: `years older than average for your age.`,
        younger_actual_age_visits: `years younger than average for your age.`,
        older_younger: `neither older nor younger than average for your age.`,
        gpa_scale: 'Your GPA (4-point scale):?',
        report_card_label: 'Report Card?',
        treatment_plan: 'TREATMENT PLAN?',
        biomarkers_label: 'Biomarkers?',
        average_peers: 'Average for Your Peers?',
        everyone_label: 'Everyone?',
        change_from: 'Change From?',
        since_label: 'since?',
        baseline_label: 'Baseline?',
        previous_visit_label: 'Previous Visit?',
        results_label: 'Results?',
        recommendation_label: 'Recommendation?',
        more_info: 'More Info?',
        as_of_label: 'as of?',
        years_label: 'years?',
        your_age_label: 'Your Age?',
        your_label: 'Your?',
        date_label: 'Date?',
        grade_label: 'Grade?',
        value_label: 'Value?',
        units_label: 'Units?',
        reference_range: 'Reference Range?',
        no_recommandations_made: 'No recommendations have been made?',
        select_items_label: 'Select items from the search results, or the common recommendations on the left to add them to the list.?',
        submit_comments: 'Submit Comments?',
        recent_recommendations: 'Recent Recommendations?',
        changes_wont_stick: `Changes won't stick?`,
        demo_mode: `In demo mode, your changes will appear to have been saved. But if you come back to this page in the future, you won't see them. This allows all demo users to start with the same basic information each time.?`,
        loading_data: `Loading Data:?`,
        loading_categories: 'Loading Categories:?',
        loading_patient: 'Loading Patient:?',
        loading_recommendations: 'Loading Recommendations:?',
        normal_range: 'Normal Range?',
        optimal_range: 'Optimal Range?',
        no_description: 'No Description Available?',
        past_results: 'Your Past Results?',
        your_result: 'Your Result?',
        powered_by_physioage: 'Powered By PhysioAge?'
    },
    noumena_labels: {
        title: '探索Noumena',
        noumena: 'Noumena',
        filter_clouds: '过滤云',
        noumenon: '本体名',
        site: '网站',
        gender: '性别',
        max_deviations: '最大偏差',
        update_btn: '更新',
        heads_up: '抬头！',
        filter_help: '如果您选择上面的项目,云将生成更慢。 仅预先绘制默认云。',
        volume_report: '卷报告',
        download_csv: '下载CSV',
        volume_report_name: 'volume_report.csv',
        aggregate_age_chart_title: '样本聚合年龄图',
        sample_report_title: '样本报告卡等级',
        sample_spectra_title: '样品光谱',
        concerns_title: '关注:',
        concerns: [
            '印刷时看起来像什么？',
            '它用了多少墨水？',
            '它如何看待色盲？ （可能没有很多,但我们知道它们存在,也可以处理它们。）'
        ],
        color_palette_help: '如果您愿意,可以更改调色板:',
        vadr_label: 'Very Abnormal Data Review?',
        no_results: 'No results?',
        next_page: 'Next Page?',
        no_results_code: 'No results for the noumena code?',
        value_format: 'Value Format?',
        system: 'System?',
        units: 'Units?',
        scale: 'Scale?',
        sorted_details: 'Sordid, Sorted Details?',
        code: 'Code?',
        appears_in_report: 'Appears in Report?',
        yes: 'Yes?',
        no: 'No?',
        equation: 'Equation?',
        gender: 'Gender?',
        anyone: 'Anyone?',
        male: 'Male?',
        female: 'Female?',
        no_values_to_plot: 'No values to plot the Graph?',
        age: 'Age?',
        visitDate: 'Visit Date?',
        visitId: 'Visit Id?',
        ordinal: 'Ordinal?',
        siteId: 'Site Id?',
        units: 'Units?',
        noumena_download: 'data?.csv',
        no_name: 'No name?',
    },
    visit_page_labels: {
        title: '基线',
        breadcrum_for_visit: '基线',
        surveys_label: '调查',
        patient_conditions_link: '患者状况调查',
        tests_label: '测试',
        btn_tests: '添加测试',
        btn_edit_patient: '编辑患者',
        btn_edit_visit: '编辑访问',
        update_visit: '更新访问',
        btn_delete_visit: '删除访问',
        btn_visit_summary: '访问摘要',
        btn_portal_view: '此次访问的门户视图',
        btn_toggle_beacon: '切换灯塔',
        report_label: '患者报告',
        download_link: '下载',
        visit_success: '访问已成功创建。',
        visit_update_success: '访问已成功更新。',
        preview_link: '预览',
        download_historic_data: 'Historical Data?',
        recycle_report_link: '回收',
        recycle_report_text: '（创建一个新的）',
        physician_report_label: '医师报告',
        register_date_header: '注册访问日期',
        active_ell_caution: '这名患者有活跃的ELK',
        update_date_header: '更新访问日期',
        error_handling: '发生错误请输入另一个日期',
        waiting_for_data: '等待数据',
        notes: '请填写左侧列出的测试结果。完成后,概览数据将显示在此处。',
        test_add_order: '添加订单',
        test_panel_name: '测试名称',
        popup_title_addTest: '添加新测试',
        lbl_addTest: '添加测试',
        patient_survey_title: '患者调查',
        nav_link_history: '历史',
        nav_link_import: '导入',
        nav_link_manual_entry: '手动输入',
        nav_link_sample_image: '示例图像',
        customer_service_label: '如果此页面上的单位或参考范围与您从实验室收到的不匹配，请点击相应字段手动输入。联系 ',
        customer_service_name: '客户服务',
        customer_service_label_2: ' 有疑问或问题',
        patient_survey_table_label: '标签',
        patient_survey_table_value: '价值',
        patient_survey_table_units: '单位',
        patient_survey_table_ref_range: '参考范围',
        patient_survey_table_status: '状态',
        btn_save_label: '保存结果',
        label_patient_survey: '患者调查',
        delete_warning: '您确定要删除访问吗？',
        update_visit_help: '选择更新访问的日期',
        elk_credits_one: '当你创建这个访问时,你将使用你的一个',
        elk_credits_two: '剩余学分。',
        visit_date: '访问日期',
        future_date: '将来太远了',
        visit_creation_error: '访问创建后一周内可以更改日期',
        credits_elk: '无法添加时间点。没有报告信用额度',
        no_history: `未输入或导入任何结果。`,
        biomarkers: `衰老的生物标志物`,
        import_account_setup: `您的帐户未设置为导入。`,
        import_contact_us: 'Contact Us?',
        import_details: 'for details!?',
        import_isnt_setup: `PhysioAge与CNS,LabCorp,Quest,Health Diagnostics,Bio-Reference and Access合作,将labwork直接发送到我们的软件,避免了数据输入的麻烦。`,
        import_bloodwork: `对不起,我们目前只能输入与血液相关的结果。`,
        import_search: `输入您要搜索的患者姓名。我们将显示我们在过去几个月收到的任何匹配消息。`,
        import_search_header: `按患者姓名搜索:`,
        import_patient_notfound: `没有结果符合您的搜索。`,
        physiological_ages: 'Physiological Ages?',
        age_weights: 'Age Weights?',
        results_title: 'Review Results?',
        out_of_bounds: 'Out Of Bounds?',
        view: 'View?',
        no_recommandations_caution: 'There is not enough data to generate a visit summary at this time.?',
        panel_name: 'Panel name?',
        browse_library: 'Browse Library?',
        upload_btn: 'Upload?',
        all_downloads: 'View all downloads for this patient?',
        delete_visit_desc: "This will delete all the data associated with the visit?",
        historical_data_validation:'Sorry, there is no historical data for the current visit.?'
    },
    orders_labels: {
        delete_warning: '您确定要删除订单吗？',
        order_success: '添加了一个订单',
        panel_validate: '已选择面板已添加到订单',
        result: `结果`,
        order_details: `订单详细信息`,
        out_of_bounds_exception_one: `抱歉,您无法提交超出范围的数据。根据我们的医生小组的说法,越界的价值是如此不正常,以至于它们肯定是错误的。`,
        out_of_bounds_exception_two: `如果您在查找问题时遇到问题,请务必查看单位以及缺失或额外的小数。`,
        out_of_bounds_header: `有些数据超出了界限`,
        close_btn: '关',
        no_changes: 'No changes to save Results?',
        updated_panels: 'Successfully Updated Results?',
        baseline: 'Baseline?',
        one_year: '1 Year?',
        years: 'Years?',
        follow_up: 'FollowUp?',
        preset: 'Preset?',
        gap: 'Gap?',
        unfavourable: 'Unfavourable?',
        entry_date: 'Entry Date?',
        result_view: 'Result View?',
        new: 'New?',
        ordered_by: 'Ordered By:?',
        publication: 'Publication Date:?',
        lab_accession: 'Lab / Accession?',
        draw_date: 'Draw Date?',
        rpt_date: 'Rpt. Date?',
        message_count: '# Msgs?',
        imported_status: 'Imported?',
        wait_status: 'Wait?',
        import_headers: 'Importing Message(s)',
        import_observations: 'Please wait while we process the following message. It can take up to 60 seconds per message as our system verifies each observation.?',
        import_failed: 'Import Failed.?',
        import_failed_alert: 'We have been alerted and will look in to it. We are sorry that this has happened.?',
        view_message: 'View Message'
    },
    messages:
    {
        patient_add_success: 'Patient was successfully created?',
        patient_edit_success: '患者已成功更新',
        no_manuals_exist: '没有手册存在',
        user_update_success: '用户已成功更新。',
        user_add_success: '用户已成功添加。',
        message_routing_apply: `我将查看过去一年中尚未匹配的所有消息,以查看哪些消息适用。`,
        help_add_success: '已成功删除',
        elk_validation: '您无法通过Web界面向网站收费',
        document_upload_success: '文档上传成功',
        help_document_validation: '请选择一个PDF文件',
        select_visit_date: '请选择访问日期以添加访问',
        select_panel: '请选择面板名称以添加测试',
        reset_password_mail_success: '重置密码的说明已发送到您的电子邮件地址。',
        password_req: '需要密码',
        confirm_pwd_req: '确认密码是必需的',
        email_req: 'Email ID is required?',
        pic_validation_message: '请输入有效的图片文件（png或jpg / jpeg）',
        clinic_validation: '接受推荐只能在网站是诊所时设置。',
        site_select_validation: '选择至少一个网站继续',
        password_updated: '密码已成功更新',
        payment_site_validation: `You don't have access to add credits for selected site?`,
        elk_validation_zero_credits: `A Site Cannot Buy Zero Credits?`,
        elk_validation_negative_credits: `A Site Cannot Buy Negative Credits?`,
        multiple_card_validation: 'Select only one card for payment?',
        feedback_validation: 'Enter feedback text?',
        no_site_providers: `You won't be able to create any patients until providers are set up for this site. Please contact your PhysioAge vendor?`,
        old_age_restriction: 'Date of Birth implies that patient is impossibly old.?',
        patient_access_msg: 'Patient can now access the portal.?',
        proper_date_validation: 'Enter date in proper format?'
    },
    payments_labels: {
        title_help: 'Add Credits for?',
        num_credits: 'Number of Credits?',
        existing_card: 'Select an Existing Card?',
        new_card: 'Or Enter a New One:?',
        card_number: 'Card Number?',
        cvc_label: 'CVC?',
        expiration: 'Expiration (MM/YY)?',
        save_btn: 'Save?',
        test_cc_title: 'Test CC Numbers?',
        test_cc_help: 'This card works with any three digit CVC and any future expiry date: ?',
        empty_card: 'This test card is empty:?',
        credits_label: 'Credits?',
        exp_label: 'Expiration date?',
        back_to_site: 'Back to the Site?',
        max_credits_validation: 'Total Credits amount must not be more than $20,000?'
    },
    report_labels: {
        next_gen_exam: 'Next Generation Physical Exam?',
        prepared_for: 'prepared for?',
        by_provider: 'by provider?',
        tests_performed: ' based on tests performed?',
        table_contents: 'Table of Contents?',
        introduction: 'Introduction?',
        compare_system: 'Comparing Systems?',
        health_status_indicator: 'Health Status Indicators?',
        exceptional_results: 'Exceptional Results?',
        abnormal_results: 'Abnormal Results?',
        complete_data: 'Complete Data?',
        historical_data: 'Historical Data?',
        disclaimer: 'Disclaimer?',
        physioage: 'PhysioAge?',
        cutoage: 'CutoAge?',
        immunoage: 'ImmunoAge?',
        pulmoage: 'Pulmoage?',
        telomerage: 'TelomerAge?',
        cardioage: 'CardioAge?',
        neuroage: 'NeuroAge?',
        man_has_arteries: 'A man is as old as his arteries?',
        english_hypocrates: 'known as the English Hypocrates?',
        test: 'The Test?',
        factors_affect: 'What factors affect my ?',
        importance: 'Importance?',
        readmore: 'readmore?',
        older_avg: 'years older than average for your age.?',
        younger_avg: 'years younger than average for your age.?',
        is_label: 'is?',
        test_not_performed: 'test not performed?',
        about_test: 'About the Test?',
        skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging??',
        skin_elasticity: 'Determining Skin Elasticity?',
        significance: 'Significance?',
        composite: 'Composite?',
        flag: 'Flag?',
        first_received: 'First Results Received?',
        last_received: 'Last Results Received?',
    },
    document_labels: {
        format: 'Format?',
        title: 'Title?',
        source: 'Source?',
        distributions: 'Distributions?',
        edit: 'Edit?',
        format_published: 'Format Published?',
        title: 'Title?',
        visit: 'Visit?',
        patient_downloads: 'Patient Downloads?',
        documents_text: 'To add documents, please go to the appropriate visit and find the "Upload" button.?',
        upload_document_for: 'Upload document for?',
        edit_document: 'Edit Document?',
        document_upload_optional: `<b>Optional. Will be set to today's date if you don't enter anything.</b> This field will be useful if you update this document in the future and what to know which is the most current. Once you have selected the year and month, you MUST click on the day.?`,
    },
    successful_label: '成功了',
    added_label: '已添加',
    updated_label: '已更新',
    required_label: '是必需的',
    email_help: '输入电子邮件ID',
    credits_caution_title: '您已将剩余的报告信用评为scrednumber。',
    credits_caution_help: '您在PhysioAge报表上的报表余额已达到scrednumber。为避免潜在的延误,请使用您的信用卡立即添加更多积分。',
    credits_caution_contact: '问题与我们联系。',
    acknowlegde: '承认',
    no_grid_data: 'Currently, there is no data to display?',
    pending: 'Pending?',
    no_data: 'No Data?',
    finished: 'Finished?',
    allowed_date_format: 'Enter date in <> format?',
    no_visits: 'No Visits?',
    no_summary_data: `I'm sorry, there was not enough medical data present to generate a summary. Typically this means your data has not been entered into our system yet. Please try again later.?`,
    pages: 'Pages?'
}

import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import constants from '../../../config/constants'
import labels from '../../../config/localization';
import _ from 'lodash';
import Sortable from 'sortablejs'
import Recommendations from '../containers/RecommendationsContainer';
import InterpretationComponent from './InterpretationComponent';
import html2react from 'html-to-react';
import marked from 'marked';


function RecommendationList(props) {
  const [currentPage, setCurrentPage] = useState(props.interpretations && props.interpretations.length > 0 && props.recommendations && props.recommendations.length == 0 ? "interpretations" : "treatment_plan");
  let markdown2react = (md) => {
    marked.setOptions({
        renderer: new marked.Renderer(),
        sanitize: true,
        smartLists: true,
    });

    let parser = new html2react.Parser();
    return (parser.parse(marked(md)));
  }
  return (
    <div className='recommendation-list-container'>
      <div className='header-div row no-gutters pr-3 pl-3'>
        <div className='col d-flex align-items-center'>
          <div className={`normal-heading mr-1 ${currentPage == "treatment_plan" ? "heading": ""}`}  onClick={() => {setCurrentPage("treatment_plan") }}>{props.terms['Treatment Plan']}</div>
          { props.interpretations && props.interpretations.length > 0 ?
            <>
            | <div className={`normal-heading ml-1 ${currentPage == "interpretations" ? "heading": ""}`} onClick={() => {setCurrentPage("interpretations") }}>Interpretations</div>
            </>
          : ""}
        </div>
        { currentPage == "treatment_plan" ?
        <div className='col-auto d-flex pr-3 align-items-center'>
          <div className='col-4 p-0 d-flex align-items-center'><span className='view-all-text' onClick={() => {props.togglePages('treatment_plan', props.history)}}>{labels.analytics_labels.view_all}</span></div>
          <div className='col-8 d-flex pl-0 align-items-center'>
            <div className='mr-1'><span className={`recent text ${props.recommendationType == "active" ? "active" : ""}`} onClick={() => props.setRecommendationType("active")}>{labels.analytics_labels.recent}</span></div> 
            { props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ? 
             <> | <div className='ml-1 mr-1'>
              <span className={`suggestions text ${props.recommendationType == "meta" ? "active" : ""}`} onClick={() => props.setRecommendationType("meta")}>{labels.analytics_labels.suggestions}</span>
            </div></>
            : null }
            {!props.isDemo && props.presentPatient && props.presentPatient.priorVisitId != 0 && !props.isRecommendationsLoading ?
              <> |
              <div className='ml-1 mr-1'>
                <span onClick={() => props.getHistoryRecommendations()} className='historic text'> {labels.analytics_labels.historic}</span>
              </div>
            </>  
            : ''}
          </div>
        </div>
        : ""
        }
      </div>
      { currentPage == "treatment_plan" ?
        <Recommendations {...props} />
      :  
      <div className='interpretations-information'>
        { props.interpretations && props.interpretations.filter(s => (!(s.sectionName || s.categoryName))).length
              && props.interpretations.filter(s => (!(s.sectionName || s.categoryName))).map((inpObj, index) => {
                return (
                    <div className='card mb-2'>
                      <p className="card-header"><b>{labels.analytics_labels.based_on} {inpObj.noumenaList}</b></p>
                      <div class="card-body">
                        <p class="card-text">{markdown2react(inpObj.interpretation)}</p>
                        { inpObj.references && inpObj.references.length > 0 ?
                            <div className='moreInfoReference references'>
                                <p className='reference-heading'><b>{labels.analytics_labels.references}</b></p>
                                {
                                    inpObj.references.map((s, index) => {
                                        return (<div className='card' key='card-reference'>
                                            <p className="card-header"><b>{labels.analytics_labels.reference} {index + 1} :</b></p>
                                            {s.url ?
                                                <>
                                                    <p className="card-body url-reference"><b>{labels.analytics_labels.url} : </b><a target="_blank" href={s.url}>{s.url}</a></p>
                                                </>
                                                : ''}
                                            {s.internalNotes ?
                                                <>
                                                    <p className="card-body"><b>{labels.analytics_labels.internal_notes} : </b>{s.internalNotes}</p>
                                                </>
                                                : ''}
                                        </div>)
                                    })
                                }
                            </div> : ''
                        }
                      </div>
                </div>)
           })}
      </div>
      }  
    </div>
  )

}
export default connect()(RecommendationList);


import ProgressLoader from './ProgressLoader';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toggleReportLoading } from '../../reducers/global-reducer';

const mapStateToProps = state => ({
  ...state.global,
});

const mapDispatchToProps = {
    toggleReportLoading
};

const initialState = {
    isProgressLoading: false,
}    

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ProgressLoader));
import React from 'react';
import PropTypes from 'prop-types';
import Recommendation, { recommendation_list } from '../tools/recommendation';
import constants from '../../../config/constants';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import en_labels from '../../../config/en_labels';
import sprite from '../../../assets/images/sprite_base.svg';
import CreatableSelect from "react-select/lib/Creatable";
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';
import { addMonths } from 'date-fns';
import moment from 'moment';

export default class MedicationInputComponent extends React.Component {
  static propTypes = {
    datum: PropTypes.object.isRequired,
    recommendation: PropTypes.object.isRequired,
    provideRecommendation: PropTypes.func.isRequired,
    updateRecommendation: PropTypes.func.isRequired,
    dataPersists: PropTypes.bool.isRequired,
  };

  constructor(props, context) {
    super(props, context)
    this.state = {
      recommendation: new Recommendation(this.props.recommendation)
    }
    this.creatableSelect = React.createRef()
    this.notes = React.createRef()
    this.instructions = React.createRef()
    this.brand = React.createRef()
    this.strength = React.createRef()
  }

  componentDidMount() {
    this.brandInput = true
    this.recommendationInput = true
    this.instructionInput = true
    this.notesInput = true
    this.strengthInput = true

    this.unlisten = this.props.history.listen((location, action) => {
      if (location && this.props.location && action == 'POP') {
        let group = this.props.medicationForm && this.props.medicationForm.group ? this.props.medicationForm.group : 'Supplement';
        let brand = this.props.medicationForm && this.props.medicationForm.brand ? this.props.medicationForm.brand : "";
        let body = this.props.medicationForm && this.props.medicationForm.body ? this.props.medicationForm.body : "";
        let strength = this.props.medicationForm && this.props.medicationForm.strength ? this.props.medicationForm.strength : "";
        let recommendationStrengthType = this.props.medicationForm && this.props.medicationForm.recommendationStrengthType ? this.props.medicationForm.recommendationStrengthType : '';
        let instructions = this.props.medicationForm && this.props.medicationForm.instructions ? this.props.medicationForm.instructions : "";
        let notes = this.props.medicationForm && this.props.medicationForm.notes ? this.props.medicationForm.notes : "";
        let startedAt = this.props.medicationForm && this.props.medicationForm.startedAt ? this.props.medicationForm.startedAt : "";
        let endedAt = this.props.medicationForm && this.props.medicationForm.endedAt ? this.props.medicationForm.endedAt : "";

        this.props.showNoMedicationChangesError(false);
        let medicationForm = { group: group, brand: brand, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt }
        let initialMedicationForm = this.props.initialMedicationForm ? { ...this.props.initialMedicationForm } : { group: 'Supplement' };
        Object.keys(medicationForm).forEach((p) => medicationForm[p] == null || medicationForm[p] == undefined || medicationForm[p] == '' ? delete medicationForm[p] : '');
        Object.keys(initialMedicationForm).forEach((p) => initialMedicationForm[p] == null || initialMedicationForm[p] == undefined || initialMedicationForm[p] == '' ? delete initialMedicationForm[p] : '');

        if (medicationForm == "" || _.isEqual(medicationForm, initialMedicationForm)) {
          this.props.toggleMedicationModal(false);
          this.props.toggleMetaRecommendations(false);
        }
        else
          this.props.toggleMedicationFormWarning(true);
      }
    });
  }

  componentWillUnmount() {
    this.unlisten()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      recommendation: new Recommendation(nextProps.recommendation)
    })
  }

  componentDidUpdate() {
    if (!this.props.isSideBarActive && !this.props.showMedicationModal)
      this.clearForm()

    if (this.props.medicationForm && _.isEqual({ group: "Supplement" }, this.props.medicationForm) && !(this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.body)) {
      this._typeahead.getInstance().clear()
    }

    if (this.props.medicationForm && !this.props.medicationForm.body) {
      this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.body
        ? '' : this._typeahead.getInstance().clear()
    }

    if (this.props.medicationForm && _.isEqual({ group: "Supplement" }, this.props.medicationForm) && !(this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.instructions)) {
      this.instructions.getInstance().clear()
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.medicationForm && !this.props.medicationForm.body) {
        this.instructions.getInstance().clear()
      }
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.medicationForm && !this.props.medicationForm.instructions) {
        this.instructions.getInstance().clear()
      }
    }

    if (this.props.medicationForm && _.isEqual({ group: "Supplement" }, this.props.medicationForm) && !(this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.strength)) {
      this.strength.getInstance().clear()
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.medicationForm && !this.props.medicationForm.body) {
        this.strength.getInstance().clear()
      }
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.medicationForm && !this.props.medicationForm.strength) {
        this.strength.getInstance().clear()
      }
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.medicationForm && !this.props.medicationForm.brand) {
        this.brand.getInstance().clear()
      }
    }


    if (this.props.medicationForm && _.isEqual({ group: "Supplement" }, this.props.medicationForm) && !(this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.notes)) {
      this.notes.getInstance().clear()
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.medicationForm && !this.props.medicationForm.body) {
        this.notes.getInstance().clear()
      }
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.medicationForm && !this.props.medicationForm.notes) {
        this.notes.getInstance().clear()
      }
    }

  }

  clearForm = () => {
    if (this.recommendationStrengthType && this.recommendationStrengthType.current)
      this.recommendationStrengthType.current.inputEntry.value = ''
  }

  checkForChanges = () => {
    let initialMedicationForm = this.props.initialMedicationForm ? { ...this.props.initialMedicationForm } : {};
    let medicationForm = {}
    if (this.props.medicationForm) {
      medicationForm["group"] = this.props.medicationForm["group"];
      medicationForm["brand"] = this.props.medicationForm["brand"];
      medicationForm["body"] = this.props.medicationForm["body"];
      medicationForm["strength"] = this.props.medicationForm["strength"];
      // medicationForm["recommendationStrengthType"] = this.props.medicationForm["recommendationStrengthType"];
      medicationForm["instructions"] = this.props.medicationForm["instructions"];
      medicationForm["notes"] = this.props.medicationForm["notes"];
      medicationForm["startedAt"] = this.props.medicationForm["startedAt"];
      medicationForm["endedAt"] = this.props.medicationForm["endedAt"];
      medicationForm["productType"] = this.props.medicationForm["productType"];

    }

    Object.keys(medicationForm).forEach((p) => medicationForm[p] == null || medicationForm[p] == undefined || medicationForm[p] == "" ? delete medicationForm[p] : '');
    Object.keys(initialMedicationForm).forEach((p) => initialMedicationForm[p] == null || initialMedicationForm[p] == undefined || initialMedicationForm[p] == "" ? delete initialMedicationForm[p] : '');

    let groupName = 'Supplement'

    if (_.isEqual(medicationForm, initialMedicationForm) && !_.isEqual(initialMedicationForm, { group: groupName })) {
      this.props.showNoMedicationChangesError(true);
      return true;
    }
    else
      return false;

  }

  saveMedication = () => {
    let noFormChanges = this.checkForChanges();
    if (!noFormChanges) {
      this.props.saveMedication(this.props.history, this.props.isPlanModal)
      this._typeahead.clear()
      this.brand.clear()
      this.strength.clear()
      this.instructions.clear()
      this.notes.clear()
      this.clearForm()
    }
  }

  getStrengthList = (list) => {
    let arr = []
    list && list.length && list.map(s => {
      if (s.value && arr.map(t => t.value.replace(/\s/g, '')).indexOf(s.value.replace(/\s/g, '')) == -1) {
        return arr.push(s);
      }
    })
    return arr;
  }
  render() {
    return (
      <div className='search'>
        {this.props.medicationErrors && this.props.medicationErrors.length ?
          <div className='alert alert-danger'>
            {this.props.medicationErrors.map(re => <p>{re}</p>)}
          </div> : null
        }
        {
          this.props.loadingMedications != 'finished' ?
            <div className='alert alert-success'>
              <i class="fa fa-spinner fa-spin medication-loading-spinner"></i>
              <span>We are getting your medications ready. Please wait for a while!</span>
            </div>
            : null
        }
        <FormControl className='form-control' id='group'
          value={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.group ? this.props.metaRecommendationForm.group : null) : (this.props.medicationForm && this.props.medicationForm.group ? this.props.medicationForm.group : null)}
          onChange={this.props.editMedication}
          label='Group:' type='select'
          options={this.props.groups_recommendations ? this.props.groups_recommendations.filter((group) => constants.medication_groups.includes(group["value"])) : []}
          textKey={'value'} valueKey={'value'}
        />

        <div className='form-group'>
          <label>Select {labels.analytics_labels.brand_ingredient}</label>
          <Typeahead
            labelKey="value"
            allowNew
            onKeyDown={(e) => { e.length ? this.props.editMedication(_.head(e), "brand") : null; this.brandInput = e.target.value ? false : true }}
            selected={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.brand ? [{ value: this.props.metaRecommendationForm.brand }] : [{ value: '' }]) : this.props.medicationForm && this.props.medicationForm.brand && this.brandInput ? [{ value: this.props.medicationForm.brand }] : null}
            id='brand'
            disabled={this.props.isMetaRecommendation ? true : false}
            options={this.props.brand_list ? this.props.brand_list : []}
            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editMedication(_.head(e), "brand")}
            newSelectionPrefix={null}
            ref={(ref) => this.brand = ref}
            onBlur={(e) => {
              this.brandInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editMedication({ value: e.target.value }, "brand")
              }
            }
            }
          />
        </div>

        <div className='form-group'>
          <label>Enter your Medication</label>
          <Typeahead
            labelKey="value"
            allowNew
            onKeyDown={(e) => { e.length ? this.props.editMedication(_.head(e), "body") : null; this.recommendationInput = e.target.value ? false : true }}
            selected={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.body ? [{ value: this.props.metaRecommendationForm.body }] : [{ value: '' }]) : this.props.medicationForm && this.props.medicationForm.body && this.recommendationInput ? [{ value: this.props.medicationForm.body }] : null}
            id='customTypeahead'
            disabled={this.props.isMetaRecommendation ? true : false}
            options={this.props.recommendation_list ?  this.props.recommendation_list.filter(
              (item, index, self) =>index === self.findIndex((t) => t.value === item.value)
            ) : []}

            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editMedication(_.head(e), "body")}
            newSelectionPrefix={null}
            ref={(ref) => this._typeahead = ref}
            onBlur={(e) => {
              this.recommendationInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editMedication({ value: e.target.value }, "body")
              }
            }
            }
          />
        </div>

        <div className='form-group'>
          <label>Dose</label>
          <Typeahead
            labelKey="value"
            allowNew
            selected={this.props.isMetaRecommendation ? '' : this.props.medicationForm && this.props.medicationForm.strength && this.strengthInput ? [{ value: this.props.medicationForm.strength }] : null}
            onKeyDown={(e) => { e.length ? this.props.editMedication(_.head(e), "strength") : null; this.strengthInput = e.target.value ? false : true }}
            id='strengthTypeahead'
            options={this.props.strength_list ? this.getStrengthList(this.props.strength_list) : []}
            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editMedication(_.head(e), "strength")}
            newSelectionPrefix={null}
            ref={(ref) => this.strength = ref}
            onBlur={(e) => {
              this.strengthInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editMedication({ value: e.target.value }, "strength")
              }
            }
            }
          />
        </div>

        {/* <FormControl
            id='recommendationStrengthType' label={labels.analytics_labels.howStronglyRecommended} name='recommendationStrengthType'
            value={ this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.recommendationStrengthType) ? this.props.metaRecommendationForm.recommendationStrengthType : "0" : this.props.medicationForm && this.props.medicationForm.recommendationStrengthType ? this.props.medicationForm.recommendationStrengthType : "0" }
            onChange={(e) => this.props.editMedication(e, "recommendationStrengthType")  }
            type='select' options={this.props.strengthTypes}
            textKey={'name'} valueKey={'name'}
            nodefaultOption={true}
        /> */}

        <div className='form-group'>
          <label>Enter instructions or sig. (optional)</label>
          <Typeahead
            labelKey="value"
            allowNew
            selected={this.props.isMetaRecommendation ? '' : this.props.medicationForm && this.props.medicationForm.instructions && this.instructionInput ? [{ value: this.props.medicationForm.instructions }] : null}
            onKeyDown={(e) => { e.length ? this.props.editMedication(_.head(e), "instruction") : null; this.instructionInput = e.target.value ? false : true }}
            id='instructionTypeahead'
            options={ this.props.instruction_list ?  this.props.instruction_list.filter(a=> a.value != "" && a.value != null).filter(
              (item, index, self) =>index === self.findIndex((t) => t.value === item.value)
            ) : []}
            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editMedication(_.head(e), "instruction")}
            newSelectionPrefix={null}
            ref={(ref) => this.instructions = ref}
            onBlur={(e) => {
              this.instructionInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editMedication({ value: e.target.value }, "instruction")
              }
            }
            }
          />
        </div>

        <div className='form-group'>
          <label>Enter patient notes (optional)</label>
          <Typeahead
            labelKey="value"
            allowNew
            selected={this.props.isMetaRecommendation ? '' : this.props.medicationForm && this.props.medicationForm.notes && this.notesInput ? [{ value: this.props.medicationForm.notes }] : null}
            onKeyDown={(e) => { e.length ? this.props.editMedication(_.head(e), "notes") : null; this.notesInput = e.target.value ? false : true }}
            id='notesTypeahead'
            options={this.props.notes_list ? this.props.notes_list : []}
            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editMedication(_.head(e), "notes")}
            newSelectionPrefix={null}
            ref={(ref) => this.notes = ref}
            onBlur={(e) => {
              this.notesInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editMedication({ value: e.target.value }, "notes")
              }
            }
            }
          />
        </div>

        <div className='form-group row'>
          <div className='col-lg-6'>
            <FormControl id='update_visit'
              label={labels.billing_labels.startdate_label}
              type='date'
              selected={this.props.medicationForm && this.props.medicationForm.startedAt ? new Date(this.props.medicationForm.startedAt) : ''}
              onChange={(e) => this.props.editMedication(e, "startedAt")}
              maxDate={this.props.medicationForm && this.props.medicationForm.endedAt ? moment(this.props.medicationForm.endedAt).add(-1, 'days').toDate() : ""}
              disabledKeyboardNavigation
            />
          </div>
          <div className='col-lg-6'>
            <FormControl id='update_visit'
              label={labels.billing_labels.enddate_label}
              type='date'
              selected={this.props.medicationForm && this.props.medicationForm.endedAt ? new Date(this.props.medicationForm.endedAt) : ''}
              onChange={(e) => this.props.editMedication(e, "endedAt")}
              minDate={this.props.medicationForm && this.props.medicationForm.startedAt ? moment(this.props.medicationForm.startedAt).add(1, 'days').toDate() : ""}
              disabledKeyboardNavigation
            />
          </div>
          <div className='col-lg-3'>
          </div>
          <div className='col-lg-9'>
            <label>
              {labels.allowed_date_format.replace('<>', 'MM/DD/YYYY')}
            </label>
          </div>
        </div>

        {this.renderWarning(this.props.isDemo)}
        <button disabled={this.props.isRecommendationsLoading ? true : false} className={`btn ${this.props.showMedicationModal ? 'btn-success' : 'btn-default'}`} onClick={this.saveMedication}>Save</button>
      </div>
    );
  }

  renderWarning(shouldRender) {
    if (shouldRender) {
      return (
        <div className='alert alert-warning d-flex'>
          <div className='icon lg alert-icon'>
            <svg aria-hidden='true' title='Persistence Warning'>
              {/* <use xlinkHref={`${sprite}#x`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
              <use xlinkHref="#x" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
              <symbol id="x" viewBox="0 0 512 512">
                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
              </symbol>
            </svg>
          </div>
          <div className='alert-content'>
            <strong>{labels.analytics_labels.changes_wont_stick}</strong>
            <p>{labels.analytics_labels.demo_mode}</p>
          </div>
        </div>
      )
    }
    else {
      return (null);
    }
  }


}

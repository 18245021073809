export default `
.responsive-div {
    width: 100%;
    padding: 20px;
    background-color: white;
    box-sizing: border-box;
}
.provider-name{
    font-size: 18px;
}
.heading-1{
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
}
.logo{
    padding: 10px 10px 10px 6px;
}

.heading-div{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #14375d;
    width: 57%;
    flex-direction: column;
}

table{
    color: black;
}
.table-1 th, .table-2 th{
    background-color: #a6bed1 !important;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}
td{
    background-color: #d9d9d9 !important;
    font-size: 12px;
    overflow-wrap: anywhere;
}
th,td{
    border: 5px solid white;
}
thead th:first-child{
    width: 10%;
    height: 60px;
}
thead th{
    width: 30%;
}

.nav-logo {
    height: 46px !important;
    width: 152px;
    // padding-left: 10px;
}
.viavi-theme_nav-logo {
    height: 60px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
.table-3 th, .table-3 td{
    border: none
}
.header {
    font-weight: bold;
    background-color: #a6bed1;
    justify-content: center;
    display: flex;
}
.table {
    margin-bottom: 0px !important;
}
.table-1 th:first-child {
    width: 10%;
    padding: 20px 0px 20px 0px;
}
.table-1 th {
    width: 30%;
    padding: 20px 0px 20px 0px;
}
.table-2 th:first-child {
    width: 10%;
}
.table-2 td:first-child {
    width: 90%;
    padding: 0px !important;
}
.table-3 td {
    border-right: 5px solid white;
    padding: 10px !important;
}
.table-3 td:nth-child(1){
    width: 33% !important;
    padding: 10px !important;
}
.table-3 td:nth-child(2){
    width: 33.4% !important;
    padding: 10px !important;
}
.table-3 td:nth-child(3){
    width: 33% !important;
    border-right: none;
    padding: 10px !important;
}
`
import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import labels from '../../../config/localization';
import LoadingTemplate from '../../../components/LoadingTemplate';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/scss/font-awesome.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import PageTitle from '../../../components/PageTitle';


    const breadCrumbs = [
        { text: labels.physioage_label, path: '/patients/list' },
        { text: labels.feedback_labels.feedback_list, path: '' }
    ]
    export class FeedBackList extends Component {
        componentDidMount() {
            this.props.getAllFeedbackList()
            document.title = `${labels.physioage_label}: ${labels.feedback_labels.feedback_list}`
            this.props.setBreadCrumbs(breadCrumbs)
            this.props.setPageTitle(labels.feedback_labels.feedback_list)
        } 
        render() {
            const columns = [
                {
                    dataField: 'userID', text: labels.feedback_labels.userID,
                    sort: true, formatter: (cellContent, row) => (<span>{row.userID}</span>),
                    headerStyle: { width: '11%' },
                },
                {
                    dataField: 'source', text: labels.feedback_labels.source,
                    sort: true, formatter: (cellContent, row) => (<span>{row.source}</span>),
                    headerStyle: { width: '10%' },
                },
                {
                    dataField: 'comment', text: labels.feedback_labels.comment,
                    sort: true, formatter: (cellContent, row) => (<span>{row.comment}</span>),
                    headerStyle: { width: '70%' },
                },
                {
                    dataField: 'rating', text: labels.feedback_labels.rating, sort: true,
                    formatter: (cellContent, row) => (<span className='pl-3'>{row.rating}</span>),
                    headerStyle: { width: '5%' },
                    
                },
                {  
                    dataField: '', headerStyle: { width: '5%' }, text:  'Action',
                    formatter: (cellcontent, row) => ( <span>
                        <div className='text-danger'>
                            <span className='pl-3'><i class='fa fa-trash' 
                                onClick={() =>{ this.props.deleteFeedback(row.id)}} 
                                aria-hidden="true"></i> 
                            </span>
                        </div>
                        </span>
                    ) 
                }

            ]
            return (
            this.props.isLoading ? <>
                <LoadingTemplate haveSidebar={true} isGrid={true} />
            </> : <>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                        <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                        <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                            < PageTitle breadCrumbs={this.props.breadCrumbs}
                                pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                                navigatePatients={this.props.navigatePatients}
                                history={this.props.history}
                                {...this.props} />
                        </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 users-children'>

                        <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2 users-list-sidepanel'>
                        </div>
                        <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0'>
                            <div className='users-table w-auto'>
                                {this.props.feedbackList && this.props.feedbackList.length ?
                                    <div className='bootstrap-table-container'>
                                        <BootstrapTable striped={false} bordered={false} hover={true} bootstrap4 keyField='id'
                                            data={this.props.feedbackList ? this.props.feedbackList : []} columns={columns}
                                        />
                                    </div>
                                    : <div className='jumbotron'>
                                        <h3>{labels.no_grid_data}</h3>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </>
            )
        }    
    }
    export default FeedBackList;
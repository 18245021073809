import React from 'react';
import Grade from '../../Analytics/components/Grade';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import labels from '../../../config/localization';
import '../../Data/components/palettes.scss';
const _ = require("lodash"); 
class HealthReportCardComponent extends React.Component {
  constructor() {
    super();
    this.renderCategoryGrade = this.renderCategoryGrade.bind(this);
  }

  renderCategoryGrade(category, visitId) {

    // let visitId = this.props.currentVisit.id
    // let finalCard = null
    // if (category.sections.length > 0) {
    //   category.sections.map(s => {

    //     let currentVisitsData = s.data.filter(t => t.visitId == visitId
    //       && !(t.points === undefined || t.points == null || isNaN(t.points)))

    //     if (currentVisitsData.length > 0) {
    //       var points = _.sum(currentVisitsData.map(csd => csd.points)) / currentVisitsData.length
    //       finalCard = (
    //         <div className='report-card-item default'>
    //           <div className='content'>
    //             <Grade points={points} />
    //             <div className='title'>{s.name}</div>
    //           </div>
    //         </div>
    //       )
    //     }
    //   })
    // }
    // return finalCard
    var reportCards = []
    category.map(p => {
      if (p.sections.length > 0) {
        p.sections.map(s => {
          let currentVisitsData = s.data.filter(t => t.visitId == visitId
            && !(t.points === undefined || t.points == null || isNaN(t.points)))

          if (currentVisitsData.length > 0) {
            var points = _.sum(currentVisitsData.map(csd => csd.points)) / currentVisitsData.length
            reportCards.push(<div className='report-card-item cursor-pointer default'>
              <div className='content' onClick={() => this.props.openSectionModal(s.id)}>
                <Grade points={points} />
                <div className='title'>{s.name}</div>
              </div>
            </div>)
          }
        })
      }

    })
    return reportCards;

    // if (category.sections.length > 0 && category.points) {
    //   console.log(category.points)
    //   return (
    //     <div key={'category_grade_for_' + category.name} className='report-card-item default'>
    //       {/* <Link to={uniformString(category.enName, 'category')} className='scrollable' spy = {true} smooth = {true} duration = {500} offset={-45}> */}
    //       <div className='content'>
    //         <Grade points={category.points} />
    //         <div className='title'>{category.name}</div>
    //       </div>
    //       {/* </Link> */}

    //     </div>
    //   )
    // }
    // else {
    //   return (null);
    // }
  }

  render_overall_grade(number) {
    let designer_has_made_this_beautiful = false

    if (!designer_has_made_this_beautiful) {
      return (<div className='gpa-container'>
        <div className='name'>{labels.analytics_labels.gpa_scale}</div>
        <div className='gpa'>{number}</div></div>);
    }
    else {
      return (null);
    }
  }
  render() {
    return (
      <div className='health-category' id='visitHealthReportCard'>
        {/* <div className='heading'>
          <div className='meta'>
          </div>
        </div> */}
        <div className='card p-5 palette palette_default'>
          <h2 className='section-title pb-4'>Report Card</h2>
          {/* {this.render_overall_grade(this.props.gpa)} */}
          <div className='report-card-content'>
            {this.props.individualVisitData ? (this.renderCategoryGrade(this.props.individualVisitData, this.props.currentVisit.id)) : ''}
          </div>
        </div>
      </div>
    );
  }
}
export default HealthReportCardComponent;
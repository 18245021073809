import React, { Component } from 'react';
import './notification-styles.scss';

class Notification extends Component {
    render() {
        return (
            <div className={`notification alert alert-${this.props.notificationType ? this.props.notificationType : 'default'}`}>
                {this.props.title && !['VerifyOTP', 'VerifyEmail'].includes(this.props.title) ? <strong>{this.props.title}</strong> : ''}
                <ul className={this.props.texts && this.props.texts.length == 1 ? 'no-list-style' : ''}>
                    {this.props.texts ? this.props.texts.map(text => (
                        <li>{text}</li>
                    )
                    ) : null
                    }
                </ul>
            </div>
        )
    }
}
export default Notification
import React, {useRef, useEffect, useMemo, useState} from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import close from '../../../assets/images/close.svg';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import labels from '../../../config/localization';
import { connect } from 'react-redux';
import constants from '../../../config/constants'
import _ from 'lodash';
import CardLoader from '../containers/CardLoaderContainer';
import closeLight from '../../../assets/images/close-light.svg';
import Checkbox from '@mui/material/Checkbox';

function RecomendationForm(props) {
    let body = useRef(null);
    let notes = useRef(null);
    let instructions = useRef(null);
    let brand = useRef(null);
    let strength = useRef(null);
    const [text, setText] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const memoizedRecommendationForm = useMemo(() => props.recommendationForm, [props.recommendationForm]);
    const [isMorningRecomendedTime, setIsMorningRecomendedTime] = useState(memoizedRecommendationForm.isMorningRecomendedTime);
    const [isMidDayRecomendedTime, setIsMidDayRecomendedTime] = useState(memoizedRecommendationForm.isMidDayRecomendedTime);
    const [isNightRecomendedTime, setIsNightRecomendedTime] = useState(memoizedRecommendationForm.isNightRecomendedTime);

    useEffect(() => {
        setIsMorningRecomendedTime(memoizedRecommendationForm.isMorningRecomendedTime);
        setIsMidDayRecomendedTime(memoizedRecommendationForm.isMidDayRecomendedTime);
        setIsNightRecomendedTime(memoizedRecommendationForm.isNightRecomendedTime);
    }, [memoizedRecommendationForm]);

    const handleCheckboxClick = (type) => {
        let updatedForm = { ...props.recommendationForm };

        if (type === 'isMorningRecomendedTime') {
            const newValue = !isMorningRecomendedTime ? 1 : 0;
            setIsMorningRecomendedTime(newValue === 1);
            updatedForm.isMorningRecomendedTime = newValue;
            props.instruction_list.filter((sig) => sig[type] == updatedForm[type])
        } else if (type === 'isMidDayRecomendedTime') {
            const newValue = !isMidDayRecomendedTime ? 1 : 0;
            setIsMidDayRecomendedTime(newValue === 1);
            updatedForm.isMidDayRecomendedTime = newValue;
        } else if (type === 'isNightRecomendedTime') {
            const newValue = !isNightRecomendedTime ? 1 : 0;
            setIsNightRecomendedTime(newValue === 1);
            updatedForm.isNightRecomendedTime = newValue;
        }
        if (props.instruction_list && props.instruction_list.length ){
            let sig = props.instruction_list.find((sig) => (sig.isMorningRecomendedTime == updatedForm.isMorningRecomendedTime 
                                                            && sig.isMidDayRecomendedTime == updatedForm.isMidDayRecomendedTime
                                                            && sig.isNightRecomendedTime == updatedForm.isNightRecomendedTime))
            sig ? updatedForm.instructions = sig.value : null
        }
        props.updateRecommendationForm(updatedForm);
    }

    const handleSelection = (selected) => {
        if (selected.length > 0) {
        const selectedText = selected[0].value;
        const newOption = { text: selectedText};
        props.addRecommendationNotes(selectedText)
        setSelectedOptions([...selectedOptions, newOption]);
        }
    };

    const handleTextChange = (index, event) => {
        const newTextareas = [...selectedOptions];
        newTextareas[index].text = event.target.value;
        setSelectedOptions(newTextareas);
        props.editRecommendationNotes(event.target.value, index)
    };

    const handleRemove = (index) => {
        const newTextareas = selectedOptions.filter((_, i) => i !== index);
        setSelectedOptions(newTextareas);
        props.removeRecommendationNotes(index)
    };

    useMemo(() => {
         // componentDidUpdate() //
        if (props.recommendationForm && _.isEqual({ group: "General Instructions" }, props.recommendationForm) && !(props.isMetaRecommendation && props.metaRecommendationForm && props.metaRecommendationForm.body)) {
            if (body.current)
                body.current.clear()
        }

        if (props.recommendationForm && !props.recommendationForm.body) {
            props.isMetaRecommendation && props.metaRecommendationForm && props.metaRecommendationForm.body
            ? '' : body.current ?  body.current.clear() : ""
        }

        if (props.recommendationForm && _.isEqual({ group: "General Instructions" }, props.recommendationForm) && !(props.isMetaRecommendation && props.metaRecommendationForm && props.metaRecommendationForm.instructions)) {
            instructions.current ?  instructions.current.clear() : ""
        }

        if (!props.isMetaRecommendation) {
            if (props.recommendationForm && !props.recommendationForm.body) {
                instructions.current ? instructions.current.clear() : ""
            }
        }

        if (!props.isMetaRecommendation) {
            if (props.recommendationForm && !props.recommendationForm.instructions) {
                instructions.current ? instructions.current.clear() : ""
            }
        }

        if (props.recommendationForm && _.isEqual({ group: "Pharmaceutical" }, props.recommendationForm) && !(props.isMetaRecommendation && props.metaRecommendationForm && props.metaRecommendationForm.strength)) {
            strength.current ? strength.current.clear() : ""
        }

        if (!props.isMetaRecommendation) {
            if (props.recommendationForm && !props.recommendationForm.body) {
                strength.current ? strength.current.clear() : ""
            }
        }

        if (!props.isMetaRecommendation) {
            if (props.recommendationForm && !props.recommendationForm.strength) {
                strength.current ? strength.current.clear() : ""
            }
        }

        if (!props.isMetaRecommendation) {
            if (props.recommendationForm && !props.recommendationForm.brand) {
                brand.current ? brand.current.clear() : ""
            }
        }

        // if (props.recommendationForm && _.isEqual({ group: "Pharmaceutical" }, props.recommendationForm) && !(props.isMetaRecommendation && props.metaRecommendationForm && props.metaRecommendationForm.recommendationStrengthType)) {
        //   this.recommendationStrengthType.value = ""
        // }

        // if (!props.isMetaRecommendation)  {
        //     if (props.recommendationForm && !props.recommendationForm.strength) {
        //       this.recommendationStrengthType.value = ""
        //     }      
        // }   

        // if (!props.isMetaRecommendation)  {
        //   if (props.recommendationForm && !props.recommendationForm.recommendationStrengthType) {
        //     this.recommendationStrengthType.value = ""
        //   }      
        // }


        if (props.recommendationForm && _.isEqual({ group: "General Instructions" }, props.recommendationForm) && !(props.isMetaRecommendation && props.metaRecommendationForm && props.metaRecommendationForm.notes)) {
            notes.current ? notes.current.clear() : ""
        }

        if (!props.isMetaRecommendation) {
            if (props.recommendationForm && !props.recommendationForm.body) {
                selectedOptions.length ? setSelectedOptions([]) : null
                notes.current ? notes.current.clear() : ""
            }
        }

        if (!props.isMetaRecommendation) {
            if (props.recommendationForm && !props.recommendationForm.notes) {
                selectedOptions.length ? setSelectedOptions([]) : null
                notes.current ? notes.current.clear() : ""
            }
        }
    })
    useEffect(() => {
        // componentDidMount //
        if (props && props.history) {
            let unlisten = props.history.listen((location, action) => {
            if (location && props.location && action == 'POP') {
                let group = props.recommendationForm && props.recommendationForm.group ? props.recommendationForm.group : 'General Instructions';
                let brand = props.recommendationForm && props.recommendationForm.brand ? props.recommendationForm.brand : "";
                let body = props.recommendationForm && props.recommendationForm.body ? props.recommendationForm.body : "";
                let strength = props.recommendationForm && props.recommendationForm.strength ? props.recommendationForm.strength : "";
                let recommendationStrengthType = props.recommendationForm && props.recommendationForm.recommendationStrengthType ? props.recommendationForm.recommendationStrengthType : "";
                let instructions = props.recommendationForm && props.recommendationForm.instructions ? props.recommendationForm.instructions : "";
                let notes = props.recommendationForm && props.recommendationForm.notes ? props.recommendationForm.notes : "";
                let startedAt = props.recommendationForm && props.recommendationForm.startedAt ? props.recommendationForm.startedAt : "";
                let endedAt = props.recommendationForm && props.recommendationForm.endedAt ? props.recommendationForm.endedAt : "";
                let isMorningRecomendedTime = props.recommendationForm && props.recommendationForm.isMorningRecomendedTime ? props.recommendationForm.isMorningRecomendedTime : 0;
                let isMidDayRecomendedTime = props.recommendationForm && props.recommendationForm.isMidDayRecomendedTime ? props.recommendationForm.isMidDayRecomendedTime : 0;
                let isNightRecomendedTime = props.recommendationForm && props.recommendationForm.isNightRecomendedTime ? props.recommendationForm.isNightRecomendedTime : 0;
                
                props.showNoFormChangesError(false);
                let recommendationForm = { group: group, brand: brand, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt, isMorningRecomendedTime: isMorningRecomendedTime, isMidDayRecomendedTime: isMidDayRecomendedTime, isNightRecomendedTime: isNightRecomendedTime}
                let initialRecommendationForm = props.initialRecommendationForm ? { ...props.initialRecommendationForm } : { group: 'General Instructions' };
                Object.keys(recommendationForm).forEach((p) => recommendationForm[p] == null || recommendationForm[p] == undefined || recommendationForm[p] == '' ? delete recommendationForm[p] : '');
                Object.keys(initialRecommendationForm).forEach((p) => initialRecommendationForm[p] == null || initialRecommendationForm[p] == undefined || initialRecommendationForm[p] == '' ? delete initialRecommendationForm[p] : '');
    
                if (recommendationForm == "" || _.isEqual(recommendationForm, initialRecommendationForm)) {
                // props.togglePlanModal(false);
                props.toggleMetaRecommendations(false);
                }
                else
                props.toggleRecommendFormWarning(true);
            }
            })
        } 
        let defaultOptions = props.recommendationForm && props.recommendationForm.notes ? props.recommendationForm.notes : []
        typeof defaultOptions === 'string' ? defaultOptions = defaultOptions.split('\n') : null
        let initialSelectedOptions = defaultOptions
        ? defaultOptions.map(option => ({ text: option, textarea: '' }))
        : [];
        setSelectedOptions(initialSelectedOptions)
        if (props.recommendationForm) {
            setIsMorningRecomendedTime(props.recommendationForm.isMorningRecomendedTime ? props.recommendationForm.isMorningRecomendedTime : 0);
            setIsMidDayRecomendedTime(props.recommendationForm.isMidDayRecomendedTime ?  props.recommendationForm.isMidDayRecomendedTime : 0);
            setIsNightRecomendedTime(props.recommendationForm.isNightRecomendedTime ? props.recommendationForm.isNightRecomendedTime : 0);
        }
        props.getCustomRecommendation(props.presentPatient.siteId)
        props.getCustomSigs(props.presentPatient.siteId)
        
    return () => { }
    }, [])

    const stylesClasses = {
        labelClass: 'recommendation-form-label',
        inputClass: 'recommendation-form-input'
    }
   const checkForChanges = () => {
        let initialRecommendationForm = props.initialRecommendationForm ? { ...props.initialRecommendationForm } : {};
        let recommendationForm = {}
        if (props.recommendationForm) {
            recommendationForm["group"] = props.recommendationForm["group"];
            recommendationForm["brand"] = props.recommendationForm["brand"];
            recommendationForm["body"] = props.recommendationForm["body"];
            recommendationForm["strength"] = props.recommendationForm["strength"];
            // recommendationForm["recommendationStrengthType"] = props.recommendationForm["recommendationStrengthType"];
            recommendationForm["instructions"] = props.recommendationForm["instructions"];
            recommendationForm["notes"] = props.recommendationForm["notes"];
            recommendationForm["startedAt"] = props.recommendationForm["startedAt"];
            recommendationForm["endedAt"] = props.recommendationForm["endedAt"];
            recommendationForm["productType"] = props.recommendationForm["productType"];
            recommendationForm["isMorningRecomendedTime"] = props.recommendationForm["isMorningRecomendedTime"];
            recommendationForm["isMidDayRecomendedTime"] = props.recommendationForm["isMidDayRecomendedTime"];
            recommendationForm["isNightRecomendedTime"] = props.recommendationForm["isNightRecomendedTime"];
        }

        Object.keys(recommendationForm).forEach((p) => recommendationForm[p] == null || recommendationForm[p] == undefined || recommendationForm[p] == "" ? delete recommendationForm[p] : '');
        Object.keys(initialRecommendationForm).forEach((p) => initialRecommendationForm[p] == null || initialRecommendationForm[p] == undefined || initialRecommendationForm[p] == "" ? delete initialRecommendationForm[p] : '');

        let groupName = props.sectionValues && props.sectionValues.groupName ?
            props.sectionValues.groupName
            : 'General Instructions'
        if (_.isEqual(recommendationForm, initialRecommendationForm) && !_.isEqual(initialRecommendationForm, { group: groupName })) {
            props.showNoFormChangesError(true);
            return true;
        }
        else
            return false;

    }
    
   const saveRecommendation = () => {
    let noFormChanges = checkForChanges();
    if (!noFormChanges) {
        props.saveRecommendation(props.history, props.isPlanModal)
        body.current.clear()
        brand.current.clear()
        strength.current.clear()
        instructions.current.clear()
        notes.current.clear()
        // this.clearForm()
    }
    }
    
    const saveMetaRecommendation = () => {
        // props.deleteMetaRecommendation(props.metaRecordIndex);
        // props.saveMetaRecommendationNotes(notes.current && notes.current.inputEntry.value || '')
        props.saveRecommendation(props.history, props.isPlanModal)
        body.current.clear()
        instructions.current.clear()
        brand.current.clear()
        notes.current.clear()
        brand.current.clear()
    }

    const getStrengthList = (list) => {
        let arr = []
        list && list.length && list.map(s => {
          if (s.value && arr.map(t => t.value.replace(/\s/g, '')).indexOf(s.value.replace(/\s/g, '')) == -1) {
            return arr.push(s);
          }
        })
        return arr;
    }

    const updateRecommendationInstruction = (e) =>{
        const selected = e.target.value
        const option = props.instruction_list.find(o => o.value === selected);
        if (option) {
            props.editRecommendation([option], "instruction");
        }
        else {
            props.editRecommendation([{value: selected}], "instruction")
        }
    }

    return (
        <>
            <div className='drawer-warning-overlay'></div>
                <div className='recommendation'>
                    <div className='drawer-warning'>
                        { props.loadingMedications != 'finished' ? 
                            <>
                                <div className='alert alert-success'>
                                <i class="fa fa-spinner fa-spin medication-loading-spinner"></i>
                                <span>We are getting your recommendations ready. Please wait for a while!</span>
                                </div>
                                <CardLoader  />
                            </>
                            :
                        <>
                            <div className='row header no-gutters'>
                                <div className='col-11 bold-14-text'>{ props.isMetaRecommendation ?  `Edit ${labels.analytics_labels.suggestion}` : props.recommendationForm && props.recommendationForm.id ? `Edit ${props.recType}` : `Add ${props.recType}`}</div>
                                <div className='col-1 pt-1'>
                                    <a><img className='float-right' src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleRecommendationModal(false))}></img></a>
                                </div>
                            </div>
                            { props.isLoading ?
                            <CardLoader  />
                            : 
                            <div className='row card-body-div no-gutters'>
                                {props.recommendationErrors && props.recommendationErrors.length ?
                                    <div className='alert alert-danger'>
                                        {props.recommendationErrors.map(re => <p>{re}</p>)}
                                    </div> : null
                                }
                                
                                <div className="col-12 mt-2">
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-6 col-md-6 col-xs-12'>
                                            <FormControl className='form-control' id='group'
                                                value={props.isMetaRecommendation ? (props.metaRecommendationForm && props.metaRecommendationForm.group ? props.metaRecommendationForm.group : null) : (props.recommendationForm && props.recommendationForm.group ? props.recommendationForm.group : null)}
                                                onChange={props.editRecommendation}
                                                label='Group' type='select'
                                                options={props.recType == "Medication" ? props.groups_recommendations.filter((group) => constants.medication_groups.includes(group["value"])) : props.groups_recommendations}
                                                textKey={'value'} valueKey={'value'} disabled={props.isMetaRecommendation ? true : false}
                                                required={true}
                                                nodefaultOption={true} {...stylesClasses}
                                                is_no_required_icon_color={true} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-xs-12'>
                                            <div className='form-group'>
                                                <label className='recommendation-form-label'>Select {labels.analytics_labels.brand_ingredient}</label>
                                                <Typeahead
                                                    labelKey="value"
                                                    allowNew
                                                    onBlur={(e) => {
                                                        if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                                        props.editRecommendation([{ value: e.target.value }], "brand")
                                                        }
                                                    }
                                                    }
                                                    onKeyDown={(e) => { props.editRecommendation([{value: e.target.value ? e.target.value : ""}], "brand")}} //; brandInput =  e.target.value ? false : true }}
                                                    selected={props.isMetaRecommendation ? (props.metaRecommendationForm && props.metaRecommendationForm.brand ? [{ value: props.metaRecommendationForm.brand }] : [{ value: '' }]) : props.recommendationForm && props.recommendationForm.brand  ? [{ value: props.recommendationForm.brand }] : null}
                                                    id='brand'
                                                    disabled={props.isMetaRecommendation ? true : false}
                                                    options={props.brand_list ? props.brand_list.filter(brand => brand.value != "" && brand.value != null) : []}
                                                    renderMenuItemChildren={(option, props) => (
                                                    <span id={option.value} key={option.value}>{option.value}</span>
                                                    )}
                                                    onChange={(e) => { e.length ? props.editRecommendation(e, "brand") : ""}}
                                                    newSelectionPrefix={null}
                                                    ref={brand}
                                                    {...stylesClasses}
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-6 col-md-6 col-xs-12'>
                                            <div className='form-group'>
                                                <label className='recommendation-form-label'>{ labels.analytics_labels.enter_your_recommendation }<span title='required'>*</span></label>
                                                <Typeahead
                                                    labelKey="value"
                                                    allowNew
                                                    onBlur={(e) => {
                                                        if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                                        props.editRecommendation([{ value: e.target.value }], "body")
                                                        }
                                                    }
                                                    }
                                                    onKeyDown={(e) => { props.editRecommendation([{value: e.target.value ? e.target.value : ""}], "body") }}
                                                    selected={props.isMetaRecommendation ? (props.metaRecommendationForm && props.metaRecommendationForm.body ? [{ value: props.metaRecommendationForm.body }] : [{ value: '' }]) : props.recommendationForm && props.recommendationForm.body ? [{ value: props.recommendationForm.body }] : null}
                                                    id='customTypeahead'
                                                    disabled={props.isMetaRecommendation ? true : false}
                                                    options={props.recommendation_list ?  props.recommendation_list.filter(
                                                        (item, index, self) =>index === self.findIndex((t) => t.value === item.value)
                                                    ) : []}
                                                    renderMenuItemChildren={(option, props) => (
                                                    <span id={option.value} key={option.value}>{option.value}</span>
                                                    )}
                                                    onChange={(e) => { e.length ? props.editRecommendation(e, "body"): ""}}
                                                    newSelectionPrefix={null}
                                                    ref={body}
                                                    required={true}
                                                    {...stylesClasses}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-xs-12'>
                                            <div className='form-group'>
                                                <label className='recommendation-form-label'>{labels.analytics_labels.dose}</label>
                                                <Typeahead
                                                    labelKey="value"
                                                    allowNew
                                                    onBlur={(e) => {
                                                        if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                                        props.editRecommendation([{ value: e.target.value }], "strength")
                                                        }
                                                    }
                                                    }
                                                    selected={props.isMetaRecommendation ? '' : props.recommendationForm && props.recommendationForm.strength ? [{ value: props.recommendationForm.strength }] : null}
                                                    onKeyDown={(e) => { props.editRecommendation([{value: e.target.value ? e.target.value : ""}], "strength") }}
                                                    id='strengthTypeahead'
                                                    options={props.strength_list ? getStrengthList(props.strength_list) : []}
                                                    renderMenuItemChildren={(option, props) => (
                                                    <span id={option.value} key={option.value}>{option.value}</span>
                                                    )}
                                                    onChange={(e) => { e.length ? props.editRecommendation(e, "strength"): ""}}
                                                    newSelectionPrefix={null}
                                                    ref={ strength }
                                                    {...stylesClasses}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-12 col-md-12 col-xs-12'>
                                            <div className='form-group'>
                                                <div className='row'>
                                                    <div className='col-5'>
                                                        <label className='recommendation-form-label'>Enter instructions or sig. (optional)</label>
                                                    </div>
                                                    <div className='col-7 p-0'>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <Checkbox checked={memoizedRecommendationForm.isMorningRecomendedTime ? true : false} 
                                                                    sx={{
                                                                        padding: 0,
                                                                        color: props.theme == "light-theme" ?  "black" : "white",
                                                                        '&.Mui-checked': {
                                                                            color: props.theme == "light-theme" ? 'var(--btn-bg)' : "white",
                                                                        },
                                                                        borderRadius: "4px"
                                                                    }} iconstyle={{fill: 'white'}} onClick={ () => handleCheckboxClick('isMorningRecomendedTime')} />
                                                                    <span className='pl-1' style={{color: props.theme === "light-theme" ?  "black" : "white" }}>Morning</span>
                                                            </div>
                                                            <div className='col-4'>    
                                                                <Checkbox checked={memoizedRecommendationForm.isMidDayRecomendedTime ? true : false} 
                                                                sx={{
                                                                    padding: 0,
                                                                    color: props.theme == "light-theme" ?  "black" : "white",
                                                                    '&.Mui-checked': {
                                                                        color: props.theme == "light-theme" ? 'var(--btn-bg)' : "white",
                                                                    },
                                                                    borderRadius: "4px"
                                                                }} iconstyle={{fill: 'white'}} 
                                                                onClick={() => handleCheckboxClick('isMidDayRecomendedTime')} />
                                                                <span className='pl-1' style={{color: props.theme === "light-theme" ?  "black" : "white" }}> MidDay </span>
                                                            </div>
                                                            <div className='col-4'>    
                                                                <Checkbox checked={memoizedRecommendationForm.isNightRecomendedTime ? true : false} 
                                                                sx={{
                                                                    padding: 0,
                                                                    color: props.theme == "light-theme" ?  "black" : "white",
                                                                    '&.Mui-checked': {
                                                                        color: props.theme == "light-theme" ? 'var(--btn-bg)' : "white",
                                                                    },
                                                                    borderRadius: "4px"
                                                                }} iconstyle={{fill: 'white'}} 
                                                                onClick={ () => handleCheckboxClick('isNightRecomendedTime')} />
                                                                <span className='pl-1' style={{color: props.theme === "light-theme" ?  "black" : "white" }}>Night</span>
                                                            </div>
                                                        </div>         
                                                    </div>
                                                </div>    
                                                <Typeahead
                                                    labelKey="value"
                                                    allowNew
                                                    onBlur={(e) => {
                                                        if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                                            updateRecommendationInstruction(e)
                                                        }
                                                    }}
                                                    selected={props.isMetaRecommendation ? '' : props.recommendationForm && props.recommendationForm.instructions ? [{ value: props.recommendationForm.instructions }] : null}
                                                    onKeyUp={(e) => { 
                                                        updateRecommendationInstruction(e)
                                                    }}
                                                    id='instructionTypeahead'
                                                    options={ props.instruction_list ?  props.instruction_list.filter(a=> a.value != "" && a.value != null).filter(
                                                        (item, index, self) =>index === self.findIndex((t) => t.value === item.value)
                                                    ) : []}
                                                    renderMenuItemChildren={(option, props) => (
                                                    <span id={option.value} key={option.value}>{option.value}</span>
                                                    )}
                                                    onChange={(e) => e.length ? props.editRecommendation(e, "instruction") : ""}
                                                    newSelectionPrefix={null}
                                                    ref={instructions}
                                                    {...stylesClasses}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-12 col-md-12 col-xs-12'>
                                            <div className='form-group'>
                                                <label className='recommendation-form-label'>Enter patient notes (optional)</label>
                                                <Typeahead
                                                    labelKey="value"
                                                    allowNew
                                                    // onBlur={(e) => {
                                                    //     if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                                    //         handleSelection([{ value: e.target.value }])
                                                    //     }
                                                    // }
                                                    // }
                                                    // selected={props.isMetaRecommendation ? '' : props.recommendationForm && props.recommendationForm.notes ? [{ value: props.recommendationForm.notes }] : null}
                                                    // onKeyDown={(e) => {  props.editRecommendation([{value: e.target.value ? e.target.value : ""}], "notes")}}
                                                    selected={[]}
                                                    id='notesTypeahead'
                                                    options= { [...(props.notes_templates_list ? props.notes_templates_list : []), ...(props.notes_list ?  props.notes_list : []) ].filter(option => !selectedOptions.some(selected => selected.text === option.value)) }
                                                    renderMenuItemChildren={(option, props) => (
                                                    <span id={option.value} key={option.value}>{option.value}</span>
                                                    )}
                                                    onChange={handleSelection}
                                                    newSelectionPrefix={null}
                                                    ref={notes}
                                                    {...stylesClasses}
                                                    // highlightOnlyResult
                                                />
                                                 {selectedOptions.map((option, index) => (
                                                    <div key={index} className='row'>
                                                        <div className='col-11'>
                                                            <FormControl type="textarea" value={option.text} onChange={(e) => handleTextChange(index, e)}  className='form-control recommendation-form-input mt-3' />
                                                        </div>
                                                        <div className='col-1'>    
                                                            <i className="fa fa-trash status-indicator text-danger trash-icon pt-3 justify-content-center d-flex" onClick={() => handleRemove(index)} />
                                                        </div>
                                                    </div>   
                                                 ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex row mb-3'>
                                        <div className='col-lg-6 col-md-6 col-xs-12'>
                                            <FormControl id='update_visit'
                                                label={labels.billing_labels.startdate_label}
                                                type='date'
                                                selected={props.isMetaRecommendation ? (props.metaRecommendationForm && props.metaRecommendationForm.startedAt) ?
                                                    new Date(props.metaRecommendationForm.startedAt) : '' :
                                                    props.recommendationForm && props.recommendationForm.startedAt ?
                                                    new Date(props.recommendationForm.startedAt) : ''}
                                                onChange={(e) => props.editRecommendation(e, "startedAt")}
                                                maxDate={props.isMetaRecommendation ? (props.metaRecommendationForm && props.metaRecommendationForm.endedAt) ?
                                                    moment(props.metaRecommendationForm.endedAt).add(-1, 'days').toDate() : "" :
                                                    props.recommendationForm && props.recommendationForm.endedAt ?
                                                    moment(props.recommendationForm.endedAt).add(-1, 'days').toDate() : ""}
                                                disabledKeyboardNavigation
                                                calendarIcon={true}
                                                popperPlacement={"top"}
                                                {...stylesClasses}
                                            />
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-xs-12'>
                                            <FormControl id='update_visit'
                                                label={labels.billing_labels.enddate_label}
                                                type='date'
                                                selected={props.isMetaRecommendation ? (props.metaRecommendationForm && props.metaRecommendationForm.endedAt) ?
                                                    new Date(props.metaRecommendationForm.endedAt) : '' :
                                                    props.recommendationForm && props.recommendationForm.endedAt ?
                                                    new Date(props.recommendationForm.endedAt) : ''}
                                                onChange={(e) => props.editRecommendation(e, "endedAt")}
                                                minDate={props.isMetaRecommendation ? (props.metaRecommendationForm && props.metaRecommendationForm.startedAt) ?
                                                    moment(props.metaRecommendationForm.startedAt).add(1, 'days').toDate() : "" :
                                                    props.recommendationForm && props.recommendationForm.startedAt ?
                                                    moment(props.recommendationForm.startedAt).add(1, 'days').toDate() : ""}
                                                disabledKeyboardNavigation
                                                calendarIcon={true}
                                                popperPlacement={"top"}
                                                {...stylesClasses}
                                            />
                                        </div>
                                    </div>
                                    {renderWarning((props.isDemo || props.isPatientDemo))}
                                    <div className='d-flex row'>
                                        <div className='col-lg-8 col-md-8 col-sm-8 col-12'></div>
                                        <div className='col-lg-4 col-md-4 col-sm-4 col-12 d-flex justify-content-end'>

                                        <button className='cancle-btn btn mr-3' onClick={() => {
                                                let group = props.recommendationForm && props.recommendationForm.group ? props.recommendationForm.group : 'General Instructions';
                                                let brand = props.recommendationForm && props.recommendationForm.brand ? props.recommendationForm.brand : '';
                                                let body = props.recommendationForm && props.recommendationForm.body ? props.recommendationForm.body : "";
                                                let strength = props.recommendationForm && props.recommendationForm.strength ? props.recommendationForm.strength : "";
                                                let recommendationStrengthType = props.recommendationForm && props.recommendationForm.recommendationStrengthType ? props.recommendationForm.recommendationStrengthType : "" ;
                                                let instructions = props.recommendationForm && props.recommendationForm.instructions ? props.recommendationForm.instructions : "";
                                                let notes = props.recommendationForm && props.recommendationForm.notes ? props.recommendationForm.notes : "";
                                                let startedAt = props.recommendationForm && props.recommendationForm.startedAt ? props.recommendationForm.startedAt : "";
                                                let endedAt = props.recommendationForm && props.recommendationForm.endedAt ? props.recommendationForm.endedAt : "";
                                                let productType = props.recommendationForm && props.recommendationForm.productType ? props.recommendationForm.productType : "";

                                                props.showNoFormChangesError(false);
                                                let recommendationForm = { group: group, brand: brand, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt, productType: productType }
                                                let initialRecommendationForm = props.initialRecommendationForm ? { ...props.initialRecommendationForm } : { group: 'General Instructions' };
                                                Object.keys(recommendationForm).forEach((p) => recommendationForm[p] == null || recommendationForm[p] == undefined || recommendationForm[p] == '' ? delete recommendationForm[p] : '');
                                                Object.keys(initialRecommendationForm).forEach((p) => initialRecommendationForm[p] == null || initialRecommendationForm[p] == undefined || initialRecommendationForm[p] == '' ? delete initialRecommendationForm[p] : '');
                                                if (recommendationForm == "" || _.isEqual(recommendationForm, initialRecommendationForm)) {
                                                    props.toggleMetaRecommendations(false);
                                                    props.toggleRecommendationModal(false)
                                                }
                                                else
                                                props.toggleRecommendFormWarning(true);
                                            }} >Cancel</button>

                                            {/* <button className='cancle-btn btn mr-3' onClick={() => { props.toggleRecommendationModal(false) }}>Cancel</button> */}
                                            <button className='add-btn btn' disabled={props.isRecommendationsLoading ? true : false} onClick={ () => {props.isMetaRecommendation ? saveMetaRecommendation() : saveRecommendation()}}>Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </> 
                    }    
                    </div>
               </div>
        </>
    )
}

const renderWarning = (shouldRender) => {
    if (shouldRender) {
      return (
        <div className='alert alert-warning d-flex'>
          <div className='icon lg alert-icon'>
            <svg aria-hidden='true' title='Persistence Warning'>
              <use xlinkHref="#x" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
              <symbol id="x" viewBox="0 0 512 512">
                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
              </symbol>
            </svg>
          </div>
          <div className='alert-content'>
            <strong>{labels.analytics_labels.changes_wont_stick}</strong>
            <p>{labels.analytics_labels.demo_mode}</p>
          </div>
        </div>
      )
    }
    else {
      return (null);
    }
}

export default connect()(RecomendationForm);
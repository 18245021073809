import { connect } from 'react-redux';
import Analytics from '../components/Analytics';
import { actionCreators } from '../modules/analytics';
import {
    logOut,
    getAuthStatus,
    setLoggedInUser,
    setHistory,
    autoRefreshToken,
    toggleLoading,
    toggleNotification,
    checkAccessToken,
    downloadDocument,
    toggleToaster,
    ignoreToaster,
    setModelWidth
} from '../../../reducers/global-reducer'
import { withRouter } from 'react-router-dom';
import { addToTableOfContents } from '../../Visits/modules/visits';

const mapStateToProps = (state) => ({
    ...state.analytics, ...state.global,
    isLoading: state.global.isLoading,
    isAuthenticated: state.global.isAuthenticated,
    loggedInUser: state.global.loggedInUser,
    patientReportDownloadTemplateSection: state.visits.patientReportDownloadTemplateSection,
    patientReportDownloadTemplatePastSection: state.visits.patientReportDownloadTemplatePastSection,
    tableOfContents: state.visits.tableOfContents
})

const mapDispatchToProps = {
    ...actionCreators,
    logOut,
    getAuthStatus,
    setLoggedInUser,
    setHistory,
    autoRefreshToken,
    toggleLoading,
    toggleNotification,
    checkAccessToken,
    downloadDocument,
    toggleToaster,
    ignoreToaster,
    setModelWidth,
    addToTableOfContents
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Analytics))
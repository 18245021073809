import React, {useEffect } from 'react';
import close from '../../../assets/images/close.svg';
import closeLight from '../../../assets/images/close-light.svg';
import { connect } from 'react-redux';
import labels from '../../../config/localization';
import CategoryOverview from '../containers/CategoryOverviewContainer';
import CardLoader from '../containers/CardLoaderContainer';

function CategoryDetails(props) {

    useEffect(() => {
    return () => {
            // Anything in here is fired on component unmount.
    }
    }, [])

    return (
        <>
            <div className='catgory-details'>
                <div className="col-12 padding category-overview-div-1024 report-card">
                    <div className="category-data-div">
                        <div className=' d-flex row header-div no-gutters'>
                            <div className='breadcrumb col-11'>
                                <span className='individual-breadcrumb'>
                                    {props.currentIndicator ? props.currentIndicator.includes('category') ? 
                                    <div className='header-div-active-element'>{ props.currentCategory && props.currentCategory.name ? props.currentCategory.name : ""}
                                    </div> 
                                   : "" : ""} 
                                </span>
                                
                            </div>
                            <div className='col-1 justify-content-center align-items-center d-flex'>
                                <a><img src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleCategoryDetailsModal(false))}></img></a>
                            </div>
                        </div>
                        <CategoryOverview {...props} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect()(CategoryDetails);
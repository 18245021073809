import React, { Component } from 'react';
import './progress-loader-styles.css';

class CircularProgressBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
      const sqSize = this.props.sqSize;
      const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
      const viewBox = `0 0 ${sqSize} ${sqSize}`;
      const dashArray = radius * Math.PI * 2;
      const dashOffset = dashArray - dashArray * this.props.percentage / 100;
  
      return (
        <svg className="circle-svg"
            width={this.props.sqSize}
            height={this.props.sqSize}
            viewBox={viewBox}>
            <circle
              className="circle-background"
              cx={this.props.sqSize / 2}
              cy={this.props.sqSize / 2}
              r={radius}
              strokeWidth={`${this.props.strokeWidth}px`} />
            <circle
              className="circle-progress"
              cx={this.props.sqSize / 2}
              cy={this.props.sqSize / 2}
              r={radius}
              strokeWidth={`${this.props.strokeWidth}px`}
              transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset
              }} />
            <text
              className="circle-text"
              x="50%"
              y="50%"
              dy=".3em"
              textAnchor="middle">
              {`${this.props.percentage}%`}
            </text>
        </svg>
      );
    }
}

export default class ProgressLoader extends Component {
    constructor(props) {
      super(props);
      this.state = {
        percentage: 1
      };
      this.increment = 1
      this.interval = 700
    }

    componentDidMount() {
        this.incrementInterval = setInterval(() => {
            this.incrementHandler(this.increment)
        }, this.interval)
    
        this.progressCheck = setInterval(() => {
            this.checkProgressLoading()
        }, 1)
    }

    componentDidUpdate(){
        if (this.state.percentage >= 90){
            clearInterval(this.incrementInterval)
            this.interval = 10000;
            this.increment = 1;
            this.incrementInterval = setInterval(() => {
                this.incrementHandler(this.increment)
            }, this.interval)
        }
    }

    incrementHandler = (increment) => {
        if (this.state.percentage <= 99) {
            this.setState(state => ({percentage: state.percentage + increment}))
        }
    }

    checkProgressLoading = () => {
        if (!this.props.isProgressLoading){
            this.setState(state => ({percentage: 100}))
            setTimeout(() => {
                this.props.toggleReportLoading(false);
            }, 1000);
        }
    }
    
    componentWillUnmount() {
        clearInterval(this.incrementInterval)
        clearInterval(this.progressCheck)
    }
    
    render() {
      return (
        <div class="loader1">
        <div class="progressApp">
          <CircularProgressBar
            strokeWidth="10"
            sqSize="200"
            percentage={this.state.percentage}
          />
        </div>
        </div>
      );
    }
}
  

import React, { Component, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Grade from './Grade';
import { round } from '../modules/analyticsBeta';
import constants from '../../../config/constants';
import ChatIcon from '../../../assets/images/icons/mark_unread_chat_alt.svg';
import { Typeahead } from 'react-bootstrap-typeahead';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CollapseAll from '../../../assets/images/collapse_all.svg';
import expandAll from '../../../assets/images/expand_all.svg';
import CollapseAllDark from '../../../assets/images/collapse-all.svg';
import expandAllDark from '../../../assets/images/expand-all.svg';
import labels from '../../../config/localization';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';


const styles = {
  list: {
    width: "100%",
  },
  links: {
    textDecoration: 'none',
  },
  menuHeader: {
    paddingLeft: '30px'
  }
};

class MenuBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: _.head(props.categories).key,
      openSearchField: false,
      selectedSeachField: null
    };
  }

  componentDidMount() {
    this.props.setRecType("Recommendation")
    this.props.setNoumenonList()
    if (this.props.currentCategory) {
      this.handleClick(this.props.currentCategory.key)
      this.setActive(this.props.currentCategory.key)
      if (this.props.currentNomenon) {
        this.handleClick(this.props.currentNomenon.sectionKey)
        this.handleClick(this.props.currentNomenon.key)
        this.setActive(this.props.currentNomenon.label)
      }
    }
  }

  componentDidUpdate() {
    if (this.props.isSearchField) {
      this.setActive(null)
      Object.keys(this.state).forEach(v => v != "openSearchField" ? this.state[v] = false : null)
      this.handleClick()
      if (this.props.currentCategory && this.state.active != this.props.currentCategory.key) {
        this.handleClick(this.props.currentCategory.key)
        this.setActive(this.props.currentCategory.key)
        if (this.props.currentSection && this.state.active != this.props.currentSection.key) {
          this.handleClick(this.props.currentSection.key)
          this.setActive(this.props.currentSection.key)
        }
        if (this.props.currentNomenon && this.state.active != this.props.currentSection.label) {
          this.handleClick(this.props.currentNomenon.key)
          this.setActive(this.props.currentNomenon.label)
        }
      }
      this.props.setSearchField(false)
    }
  }

  editNoumenonSearch = (event, history) => {
    this.props.editNoumenonSearch(_.head(event), history)
  }
  setActive = (newActive) => {
    this.setState({
      active: newActive
    });
  };

  setOpenSearchField = (value) => {
    this.setState({
      openSearchField: value,
    });
    if (!value) {
      this.setSelectedSeachField([])
    }
  };

  setSelectedSeachField = (event) => {
    this.setState({
      selectedSeachField: event,
    });
  };

  handleClick(item) {
    this.setState(prevState => (
      { [item]: !prevState[item] }
    ))
  }

  getCategoryGrades(gradeData, category) {
    var gradeArray = []
    gradeData && gradeData.length ?
      gradeData.map(s => {
        return s.data.map(t => {
          if (t.key == category.key) {
            t.value = t.value ? round(t.value.toString(), 2) : t.value
            gradeArray.push(t)
          }
        })
      }) : ''
    if (gradeArray && gradeArray.length)
      return gradeArray.filter(s => s.value);
  }
  
  resetSearchField() {
    this.setOpenSearchField(false)
    this.setSelectedSeachField([])
  }

  collapsedAll() {
    this.props.collapseAll()
    Object.keys(this.state).forEach(v => this.state[v] = false)
    this.handleClick()
    this.resetSearchField()
    this.setActive(_.head(this.props.categories).key)
    this.props.toggleCategoryOverview('category', _.head(this.props.categories), this.getCategoryGrades(this.props.grading_data, _.head(this.props.categories)))
  }

  expandAll(){
    this.collapsedAll()
    this.props.categories.map(cat => this.handleClick(cat.key) )
    this.props.categories.map(cat => cat.sections.map(section => this.handleClick(section.key)))
  }

  getSectionGradeData(grade_data, section) {
    var sectionGrades = []
    grade_data && grade_data.length ?
      grade_data.map(s => {
        s.data.map(t => {
          t.sections.map(u => {
            if (u.key == section.key) {
              u.value = u.value ? round(u.value.toString(), 2) : u.value
              return sectionGrades.push(u)
            }
          })
        })
      }) : ''
    if (sectionGrades && sectionGrades.length)
      return sectionGrades.filter(s => s.value)
  }

  renderSubheadingClassName(data, props) {
    var isCurrent;
    if (data.sections && data.sections.length > 0) {
      isCurrent = data.sections.map(s => s.data ? s.data.map(t => {
        if (new Date(props.updated_lastVisitDate) <= new Date(t.visitDate) && !constants.labelsForBioInfo.includes(t.code)) {
          return true;
        }
        else {
          return false;
        }
      }) : '').flat().some(value => value === true);

    }
    else if (data.data) {
      isCurrent = _.includes(data.data.map(t => {
        if (new Date(props.updated_lastVisitDate) <= new Date(t.visitDate) && !constants.labelsForBioInfo.includes(t.code)) {
          return true;
        }
        else
          return false;
      }), true)
    }
    else if (new Date(props.updated_lastVisitDate) <= new Date(data.visitDate) && !constants.labelsForBioInfo.includes(data.code)) {
      isCurrent = true;
    }
    return isCurrent;
  }

  hasRecommendations(indicator) {
    var hasRec;
    if (this.props.recommendations && indicator) {
      hasRec = !!this.props.recommendations.find(
        rec => { return (rec && rec.indicators && rec.indicators.indexOf(indicator) >= 0) }
      )
    }
    return hasRec;
  }
  handler(children, dataBelongs) {
    const { classes } = this.props
    const { state } = this
    return children.map((subOption) => {
      if (!subOption.sections && !subOption.data) {
        var isCurrent = this.renderSubheadingClassName(subOption, this.props);
        return (
          <div key={subOption.label} className={` ${ dataBelongs == 'category_data'  ? 'sections-tree ' :'datum-tree '} ${subOption.label == this.state.active ? 'item-active' : 'item-not-active'} ${isCurrent ? 'fade-row' : ''}`}>
            <ListItem
              alignItems="flex-start"
              button
              autoFocus={subOption.label == this.props.currentNomenon && this.props.currentNomenon.label ? true : false}
              key={subOption.label}
              onClick={() => {
                this.handleClick(subOption.key);
                this.props.toggleDatumOverview(this.props.categories, subOption, this.props.section, 'datum')
                this.setActive(subOption.label)
                this.resetSearchField()
              }}>
              {/* <Link 
                  to='#'
                  className={ classes.links }>    */}
              <div className='d-flex w-100 ml-4 align-items-center'>
                <span className='mr-1'><Grade points={subOption.points} /></span>
                <ListItemText
                  inset
                  primary={
                    <div className='d-flex'>
                      <div className='w-80'>{subOption.label}</div>
                      { isCurrent ? 
                        <div className="pl-3">
                          <div className='new-label'>
                            <div className='new-text'>New</div>
                          </div>
                        </div>
                      : "" }
                    </div>
                  }
                  primaryTypographyProps={{ style: { width: subOption.value ? "50%" : "100%", paddingLeft: '0px', lineHeight: '15px' } }}
                  secondary={subOption.value ?
                    <div className='d-flex align-items-center h-100'>
                      <span className='col-auto d-flex value-units value-units-align' style={{ width: '50%' }}><div className='value mr-1'>{subOption.value ? subOption.value : ""}</div><div className='units'> {subOption.units ? subOption.units : ""}</div></span>
                      <span className='col-auto d-flex pr-0 reference-range-value'>{subOption.referenceRange ? subOption.referenceRange : ""}</span>
                    </div> : ""
                  }
                  className='col-md-11 col-10'
                  secondaryTypographyProps={{ style: { width: "50%", lineHeight: '15px' } }}
                />
                <div className='col-md-1 col-2 noumenon-chat-icon unread-chat-icon'>
                  {this.hasRecommendations(subOption.code) ?  <MarkUnreadChatAltOutlinedIcon />  : ''}
                  {/* {<img className={`unread-chat-icon ${this.props.theme == "dark-theme" ?  "dark-icon-color" : ""}`} src={ChatIcon} /> : ''} */}
                </div>
              </div>

              {/* </Link> */}

            </ListItem>
          </div>
        )
      }
      else if (subOption.sections) {
        var categoryGrades = this.getCategoryGrades(this.props.grading_data, subOption);
        var isCurrent = this.renderSubheadingClassName(subOption, this.props)
        return (
          <div key={subOption.name} >
            <div className={`${subOption.key == this.state.active ? 'item-active' : ''} ${isCurrent ? 'fade-row' : ''}`}>
              <ListItem
                button={true}
                autoFocus={subOption.key == this.props.currentNomenon && this.props.currentNomenon.sectionKey ? true : false}
                onClick={() => {
                  this.handleClick(subOption.key);
                  this.props.toggleCategoryOverview('category', subOption, categoryGrades); this.setActive(subOption.key)
                  this.resetSearchField()
                }}>
                <div className='d-flex w-100'>
                  <div className='col-md-11 col-10 d-flex pl-0'>
                    <div className={`d-flex ${subOption.name == this.state.active ? 'item-active' : ''} align-items-center`} >
                      {state[subOption.key] ?
                        < ArrowDropDownIcon /> : < ArrowRightIcon />
                      }
                      <div className='d-flex align-items-center'>
                        <span className='mr-1'><Grade points={subOption.points} /></span>
                        <ListItemText
                          inset={true}
                          primary={subOption.name}
                          primaryTypographyProps={{ style: { paddingLeft: '0px' } }}
                          secondary={ isCurrent ?
                            <div className='d-flex pl-3 new-label-container'>
                              <span className='p-0'> 
                                <span className='new-label'> 
                                  <span className='new-text'> New  </span>
                                </span>
                              </span>
                            </div> : ""
                          }  
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1 col-2 category-chat-icon unread-chat-icon'>
                    {this.hasRecommendations(subOption.key) ?  <MarkUnreadChatAltOutlinedIcon />  : ''}
                     {/* <img className={`unread-chat-icon ${this.props.theme == "dark-theme" ?  "dark-icon-color" : ""}`} src={ChatIcon} /> : ''} */}
                  </div>
                </div>
              </ListItem>
            </div>
            <Collapse
              in={state[subOption.key]}
              timeout="auto"
              unmountOnExit
            >
              {subOption.sections && subOption.sections.length > 0 ? this.handler(subOption.sections) : this.handler(subOption.data, subOption.sections && subOption.sections.length == 0 && subOption.data ? "category_data" : "section_data")}
            </Collapse>
          </div>
        )
      }
      var sectionGradeData = this.getSectionGradeData(this.props.grading_data, subOption)
      var isCurrent = this.renderSubheadingClassName(subOption, this.props)
      return (
        <div key={subOption.name} >
          <div className={`${'sections-tree '}${subOption.key == this.state.active ? ' item-active' : 'item-not-active'} ${isCurrent ? 'fade-row' : ''}`}>
            <ListItem
              button={true}
              autoFocus={subOption.name == this.props.currentCategory.name ? true : false}
              onClick={() => {
                this.handleClick(subOption.key);
                this.props.toggleSectionOverview(this.props.categories, 'section', subOption, sectionGradeData); this.setActive(subOption.key)
                this.resetSearchField()
              }}>
              <div className='d-flex w-100'>
                <div className='col-md-11 col-10 d-flex pl-0'>
                  <div className={`d-flex ${subOption.name == this.state.active ? 'item-active' : ''} align-items-center`} >
                    {state[subOption.key] ?
                      < ArrowDropDownIcon /> : < ArrowRightIcon />
                    }
                    <div className='d-flex align-items-center'>
                      <span className='mr-1'><Grade points={subOption.points} /></span>
                      <ListItemText
                        inset={true}
                        primary={subOption.name}
                        primaryTypographyProps={{ style: { paddingLeft: '0px' } }}
                        secondary={ isCurrent ?
                          <div className='d-flex pl-3 new-label-container'>
                            <span className='p-0'> 
                              <span className='new-label'> 
                                <span className='new-text'> New  </span>
                              </span>
                            </span>
                          </div> : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-1 col-2 section-chat-icon unread-chat-icon'>
                  {this.hasRecommendations(subOption.key) ?  <MarkUnreadChatAltOutlinedIcon/>  : ''}
                  {/* {<img className={`unread-chat-icon ${this.props.theme == "dark-theme" ?  "dark-icon-color" : ""}`} src={ChatIcon} /> : ''}  */}
                </div>
              </div>
            </ListItem>
          </div>
          <Collapse
            in={state[subOption.key]}
            timeout="auto"
            unmountOnExit
          >
            {subOption.sections && subOption.sections.length > 1 ? this.handler(subOption.sections) : this.handler(subOption.data)}
          </Collapse>
        </div>
      )
    })
  }
  render() {
    const { classes, drawerOpen, menuOptions, categories, customCategories } = this.props
    return (
      <>
        <div className='breadcrumb'>
          <span>
            <span className='individual-breadcrumb'> <a className="active mr-1" href='javascript:void(0)' onClick={() => this.props.togglePages("dashboard", this.props.history)}>{labels.analytics_labels.dashboard}</a> {">"} </span>
            <span className='individual-breadcrumb'>{this.props.terms['Report Card']}</span>
          </span>
        </div>
        <div className='noumenon-search-field'>
          <div className='search-container'>
            <form
              className={`form-hover`}>
              <Typeahead
                labelKey="value"
                selected={this.state.selectedSeachField}
                id='noumenon-search'
                options={this.props.noumenonList && this.props.noumenonList.length ? this.props.noumenonList : []}
                renderMenuItemChildren={(option, props) => (
                  <span id={option.value} key={option.value}>{option.value} ({_.capitalize(option.type)})</span>
                )}
                onChange={(event) => { this.editNoumenonSearch(event, this.props.history); this.setSelectedSeachField(event); }}
                newSelectionPrefix={null}
                placeholder={"Search here ..."}
                onFocus={(event) => {this.setOpenSearchField(true)}}
              />
              <span className={`search-icon-div`}>
                {this.state.openSearchField ?
                  <CloseRoundedIcon onClick={() => this.setOpenSearchField(false)} className="search-icon times-icon" />
                  :
                  <SearchOutlinedIcon className="search-icon" />
                }
              </span>
            </form>
          </div>
          <div className='expand-collapse-container d-flex'>
            { Object.keys(this.state).filter(v => v != "active" && v != "openSearchField" && v != "selectedSeachField" && v != "undefined" ? this.state[v] == true : "").length >= 2 ?
                <div className='collapse-all-container'><img className={`collapes-all-icon ${this.props.theme == "light-theme" ? "dark-icon" : ""}`} src={CollapseAll} onClick={() => this.collapsedAll()} title="Collapse All" /></div>
              : ""
              }
              <div className='expand-all-container'><img className={`expand-all-icon ${this.props.theme == "light-theme" ? "dark-icon" : ""}`} src={expandAll}  onClick={ () => this.expandAll()} title="Expand All" /></div>
          </div>
          
        </div>
        
        <div className={`${classes.list} menubar-list-container ${Object.keys(this.state).filter(v => v != "active" && v != "undefined" ? this.state[v] == true : "").length >= 2 ? 'collapse-all' : ''}`}>
          <Drawer
            variant="persistent"
            anchor="left"
            open
            classes={{ paper: classes.list }}>
            <div className='height-mult-list'>
              <List>
                {this.handler(customCategories)}
              </List>
            </div>
          </Drawer>
        </div>
      </>
    )
  }
}
export default withStyles(styles)(MenuBar)



import { connect } from 'react-redux';
import { actionCreators } from '../modules/visits';
import Reports from '../components/Reports';
import { setBreadCrumbs, setPageTitle, toggleNotification, setCreditsCaution } from '../../../reducers/global-reducer';

const mapStateToProps = (state) => ({
    ...state.visits
})

const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    setCreditsCaution
}
export default connect(mapStateToProps, mapDispatchToProps)(Reports)
export default{
  "id": 10551,
  "date": "2019-03-12T00:00:00",
  "patientId": 5254,
  "createdAt": "2019-12-23T23:42:12",
  "timePoint": "Follow-Up 7",
  "firstname": "Female",
  "lastname": "Patient",
  "honorificPrefix": "Ms.",
  "honorificSuffix": null,
  "providerHonorificPrefix": "",
  "providerHonorificSuffix": "MD",
  "gender": "female",
  "providerId": 3,
  "dob": "May 22, 1960",
  "elkActivatedAt": null,
  "providerName": "Joseph Raffaele",
  "siteId": 83,
  "siteName": "PhysioAge Demo Site",
  "datePreference": "mdy",
  "credits": 20,
  "visits": 8,
  "acceptsMessages": 1,
  "logoFile": null,
  "logoContentType": "image/png",
  "billingStrategy": "recurring",
  "patientVisitAge": 58.843835616438355,
  "reportPreference": "en",
  "baselineVisitId": 10544,
  "enableDocuments": 1
}
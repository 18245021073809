import React from 'react';
import ReactDom from 'react-dom';

class StopMedicationWarning extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return ReactDom.createPortal(
            <>
                <div className="drawer-warning-overlay" onClick={() => (this.props.toggleStopMedicationPopup(false))} />
                <div className='drawer-warning'>
                    <h5>{this.props.resumeRecommendation ? 'Resume the Medication?' : 'Stop the Medication?'}</h5>
                    <hr />
                    <p>{this.props.resumeRecommendation ? 'This will resume the medication and the patient will be able to see it in the analytics page. Are you sure you want to resume the medication?' : 'This will stop the medication and the patient cannot see it anymore in the analytics page. Are you sure you want to stop the medication?'}</p>
                    <hr />
                    <div className='d-flex justify-content-end'>
                        <button className='btn drawer-ok' onClick={() => {
                            this.props.updateMedication(this.props.stopRecommendId ? this.props.stopRecommendId : 0, this.props.resumeRecommendation == 'historic' ? 'resumeHistoric' : (this.props.resumeRecommendation == 'current' ? 'resumeCurrent' : 'stop'));
                            // this.props.toggleHistoryRecommendationPopup(false)
                            this.props.toggleStopMedicationPopup(false);
                        }}>Yes</button>
                        <button className='ml-4 btn drawer-cancel' onClick={() => { this.props.toggleStopMedicationPopup(false); }}>Cancel</button>
                    </div>
                </div>

            </>,
            document.getElementById('surveyPortal')
        )
    }
}

export default StopMedicationWarning;
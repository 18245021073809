import axios from '../../../lib/axios';
import API_URL from '../../../config/api';

import { toggleLoading, toggleNotification, handleErrors, handleLoading } from '../../../reducers/global-reducer';
import moment from 'moment';

import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import resolveSearchFilters from '../../../utils/resolve-search-params';
import constants from '../../../config/constants';

const SET_PROPS = 'SET_PROPS'

// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }
function getBillingSummary(history, isSubmit, isReset, isSort) {
    return (dispatch, getState) => {

        var dates = getDefaultDates()//get default dates
        if (!getState().billing.startDate && !getState().billing.endDate) {

            dispatch({
                type: SET_PROPS,
                payload: {
                    startDate: dates['startDate'],
                    endDate: dates['endDate']
                }
            })
            dispatch(setSearchParam(dates['startDate'], 'startDate'))
            dispatch(setSearchParam(dates['endDate'], 'endDate'))
        }
        let searchParam = { ...getState().billing.searchParam }
        if (searchParam.sortField && searchParam.sortField.length)
            isSort = true;
        if (isSubmit)
            searchParam = { ...searchParam, pageNumber: 1 }
        dispatch({
            type: SET_PROPS,
            payload: {
                //billingSummary: [],
                totalBillings: 0,
                reports: {}
            }
        })
        dispatch(toggleLoading(true))
        //fill all search filters
        searchParam.searchFilters.map(sf => {
            if (sf.fieldName == 'startDate' || sf.fieldName == 'endDate')
                sf.fieldValue = sf.fieldValue != null
                    ? moment(sf.fieldValue).format('L')//fill the value that has been set 
                    : dates[sf.fieldName].toLocaleDateString()//value taken as default
            return sf
        })

        //filling url query params
        let searchFilters = resolveSearchFilters(searchParam.searchFilters)
        if (searchFilters.siteId != null && !isNaN(parseInt(searchFilters.siteId))) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentBillingSite: parseInt(searchFilters.siteId)
                }
            })
        }

        if (!isReset && history && !isSort) {
            if (searchFilters.siteId != null && !isNaN(parseInt(searchFilters.siteId))) {
                if (parseInt(searchFilters.siteId))
                    history.push(`?siteId=${searchFilters.siteId}&startDate=${encodeURIComponent(searchFilters.startDate)}&endDate=${encodeURIComponent(searchFilters.endDate)}&page=${searchParam.pageNumber}`)
                else
                    history.push(`?startDate=${encodeURIComponent(searchFilters.startDate)}&endDate=${encodeURIComponent(searchFilters.endDate)}&page=${searchParam.pageNumber}`)
            }
            else
                history.push(`?startDate=${encodeURIComponent(searchFilters.startDate)}&endDate=${encodeURIComponent(searchFilters.endDate)}&page=${searchParam.pageNumber}`)

        }
        if (!isReset && history && isSort) {
            if (searchFilters.siteId != null && !isNaN(parseInt(searchFilters.siteId))) {
                if (parseInt(searchFilters.siteId))
                    history.push(`?siteId=${searchFilters.siteId}&startDate=${encodeURIComponent(searchFilters.startDate)}&endDate=${encodeURIComponent(searchFilters.endDate)}&page=${searchParam.pageNumber}&sortOrder=${constants.sorting_order_decode[searchParam.sortOrder].toString()}&sortField=${searchParam.sortField}`)
                else
                    history.push(`?startDate=${encodeURIComponent(searchFilters.startDate)}&endDate=${encodeURIComponent(searchFilters.endDate)}&page=${searchParam.pageNumber}&sortOrder=${constants.sorting_order_decode[searchParam.sortOrder].toString()}&sortField=${searchParam.sortField}`)
            }
            else
                history.push(`?startDate=${encodeURIComponent(searchFilters.startDate)}&endDate=${encodeURIComponent(searchFilters.endDate)}&page=${searchParam.pageNumber}&sortOrder=${constants.sorting_order_decode[searchParam.sortOrder].toString()}&sortField=${searchParam.sortField}`)
        }
        dispatch({
            type: SET_PROPS, payload: {
                searchParam
            }
        })
        promisesList.push(axios.post(API_URL.BILLING_SUMMARY_URL, {
            ...searchParam, searchFilters: searchParam.searchFilters
        })
            .then(response => {
                dispatch(handleLoading())
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        billingSummary: response.data && response.data.value && response.data.value.billingDetailDTOs ? response.data.value.billingDetailDTOs : [],
                        totalBillings: response.data && response.data.totalCount ? response.data.totalCount : 0,
                        reports: response.data && response.data.value
                            ? {
                                numberOfReports: response.data.value.numberOfReports
                                , totalCharges: response.data.value.totalCharges
                            } : {},
                        no_grid_data: response.data && response.data.value
                            && response.data.value.billingDetailDTOs
                            && !response.data.value.billingDetailDTOs.length ? true : false,
                        initialLoading: false
                    }
                })
            }).catch(error => {
                dispatch(handleErrors(error))
                dispatch(toggleLoading(false))
            }))
    }
}
function resetRanges(history) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                startDate: null,
                endDate: null,
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    searchFilters: [{ fieldName: 'siteId', fieldValue: 0 }],
                    sortOrder: -1,
                    sortField: 'Created'
                },
                currentBillingSite: 0
            }
        })
        dispatch(getBillingSummary(history, false, true))
    }
}
function getSites() {
    return (dispatch) => {
        promisesList.push(axios.get(API_URL.SITES_NAMES).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    sites: response.data ? response.data : []
                }
            })
            dispatch(handleLoading())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}
function billingPageChange(pageNumber, history, isSearch) {
    return (dispatch, getState) => {
        let searchParam = Object.assign({}, getState().billing.searchParam, { pageNumber })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (!isSearch)
            dispatch(getBillingSummary(history, false, false, searchParam.sortField && searchParam.sortField.length ? true : false))
    }
}
export function setSearchParam(val, key) {
    return (dispatch, getState) => {

        var dates = getDefaultDates()
        let searchParam = Object.assign({}, getState().billing.searchParam)

        dispatch({
            type: SET_PROPS,
            payload: {
                [key]: val ? val : dates[key]
            }
        })

        if (searchParam.searchFilters) {
            if (searchParam.searchFilters.filter(sf => sf.fieldName == key).length) {
                searchParam.searchFilters.map(sf => {
                    if (sf.fieldName == key) { sf.fieldValue = val }
                })
            } else {
                searchParam.searchFilters.push({
                    fieldName: key,
                    fieldValue: val
                })
            }
        } else {
            searchParam.searchFilters = []
            searchParam.searchFilters.push({
                fieldName: key,
                fieldValue: val
            })
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        let currentSearchFilters = searchParam.searchFilters ? resolveSearchFilters(searchParam.searchFilters) : {}
        let currentBillingSite = currentSearchFilters && currentSearchFilters.siteId ? parseInt(currentSearchFilters.siteId) : 0;
        dispatch({
            type: SET_PROPS,
            payload: {
                currentBillingSite: currentBillingSite
            }
        })
    }
}
function getDefaultDates() {
    var date = new Date();
    date.setMonth(date.getMonth() - 1)
    var dates = {
        startDate: new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0)
    }
    return dates
}
function setDefaultBillingSummary(urlparams, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                startDate: null,
                endDate: null,
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    searchFilters: [{ fieldName: 'siteId', fieldValue: 0 }],
                    sortOrder: -1,
                    sortField: 'Created'
                },
                currentBillingSite: 0
            }
        })
        let sortField = urlparams.sortField ? urlparams.sortField : 'Created'
        let sortOrder = urlparams.sortOrder ? urlparams.sortOrder : 'desc'
        var dates = getDefaultDates()
        dispatch(setSearchParam(urlparams.startDate ? new Date(urlparams.startDate) : dates['startDate'], 'startDate'))
        dispatch(setSearchParam(urlparams.endDate ? new Date(urlparams.endDate) : dates['endDate'], 'endDate'))
        if (urlparams.siteId)
            dispatch(setSearchParam(parseInt(urlparams.siteId), 'siteId'))
        if (urlparams.page)
            dispatch(billingPageChange(parseInt(urlparams.page), history, true))
        if (urlparams.sortField)
            dispatch(billingSortOrder(sortOrder, sortField, false, false))
        dispatch(getBillingSummary(false, false, false, sortField && sortField.length ? true : false))
    }
}

export function sortBillingSummry(key, order, history, pageNumber) {
    //console.log(key, order);
    return (dispatch, getState) => {
        dispatch(toggleNotification(false));
        let searchParam = getState() && getState().billing && getState().billing.searchParam ? getState().billing.searchParam : {}
        let pageNumChanged = false
        if (searchParam && (!searchParam.sortField || searchParam.sortField != key)) {
            searchParam.sortField = key
            if (searchParam.sortField != key)
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        //billingSummary: []
                    }
                })
            pageNumChanged = true
            searchParam.pageNumber = 1
        }

        if (searchParam.sortOrder != constants.sorting_order[order]) {
            searchParam.sortOrder = constants.sorting_order[order]
            searchParam.pageNumber = 1
        } else if (!pageNumChanged) {
            searchParam.pageNumber = pageNumber ? parseInt(pageNumber) : 1
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                ...searchParam
            }
        })
        dispatch(getBillingSummary(history, false, false, true));
    }
}

export function billingSortOrder(sortOrder, sortField, history, submit) {
    return (dispatch, getState) => {
        sortOrder = constants.sorting_order[sortOrder]
        let searchParam = Object.assign({}, getState().billing.searchParam, { sortOrder, sortField })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        // if (!submit)
        //     dispatch(getBillingSummary(history, false, false, true));

    }
}

export function setInitialLoading(value) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                initialLoading: value
            }
        })
    }
}

const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    searchParam: {
        pageSize: 10,
        pageNumber: 1,
        searchFilters: [],
        sortOrder: -1,
        sortField: 'Created'
    },
    no_grid_data: false,
    currentBillingSite: 0,
    initialLoading: false
}
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export const actionCreators = {
    getBillingSummary,
    getSites,
    billingPageChange,
    setSearchParam,
    resetRanges,
    setDefaultBillingSummary,
    sortBillingSummry,
    setInitialLoading,
    billingSortOrder
}
import React, { Component } from 'react';
import labels from '../../config/localization';
import './reset-password-styles.scss';
import FormControl from '../FormControls';
import Notification from '../Notification';
import cardLogo from '../../assets/images/logo_physioage-horizontal.svg';

class ResetPassword extends Component {
    componentDidMount() {
        this.props.validateResetPassword(this.props.match.params.token, this.props.match.params.perishcode, this.props.history)
        document.title = `${labels.physioage_label}: ${labels.reset_password_label}`
    }
    render() {
        return (
            <div className='app wrapper unauth-layout' >
                <div className='card parent-card status-card' >
                    <div className='row'>
                        <img src={cardLogo} alt={'physioage-logo'} />
                    </div>
                    <div className='row'>
                        <form className='linkexpired' onSubmit={(e) => { e.preventDefault(); this.props.updatePassword(this.props.history) }}>
                            <h1>{labels.reset_password_label}</h1>
                            {this.props.isNotify ? <Notification  {...this.props.notifications} /> : ''}
                            <FormControl id='resetPasswordTxt' placeholder={labels.password} type='password' onChange={this.props.loginInput} />
                            <FormControl id='resetConfirmPasswordTxt' type='password' placeholder={labels.confirm_password} onChange={this.props.loginInput} />
                            <div className='d-flex login-btn-section justify-content-center'>
                                <button className='btn btn-primary'>{labels.update_password_btn}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >)
    }
}
export default ResetPassword;
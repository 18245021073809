import React from 'react'
import '../../routes/Patients/components/patients-styles.scss'
import './loading-template-styles.scss'

class LoadingTemplate extends React.Component {
    render() {
        return (
            <div className='loading-template row container'>
                {this.props.haveSidebar && !this.props.noSidedata ? <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2 card sidebar-skeleton'>
                    <div className='card-header'></div>
                    <div className='card-body'>
                        <p className='normal-lines'></p>
                        <p className='normal-lines'></p>
                        <p className='normal-lines'></p>
                        <p className='normal-lines'></p>
                        <p className='normal-lines-short'></p>
                        <p className='normal-lines-shortest'></p>
                        <p className='normal-lines'></p>
                    </div>
                </div> : null}
                {this.props.noSidedata ? <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'></div> : null}
                <div className={`right-panel ${this.props.haveSidebar ? 'col-lg-9' : 'col-lg-12'} col-md-12 col-sm-12 col-xs-12 order-1 ${this.props.noSidedata ? 'left-0' : ''} ${this.props.isTransparent?'transparent-bg':'non-transparent'}`}>
                    {this.props.isGrid ?
                    
                        <table className='rightpanel-skeleton grid-template'>
                            {[1,2,3,4,5,6,7,8,9,10].map(i=><tr>
                                <td className='normal-lines-short'></td>
                                <td className='normal-lines-short'></td>
                                <td className='normal-lines-short'></td>
                                <td className='normal-lines-short'></td>
                                <td className='normal-lines-short'></td>
                            </tr>)}
                            
                        </table>
                        : null}
                    {this.props.isDetails
                        ? <div className='rightpanel-skeleton'>
                            <tr>
                                <td className='normal-lines'></td>
                                <td className='normal-lines'></td>
                                <td className='normal-lines'></td>
                                <td className='normal-lines'></td>
                                <td className='normal-lines'></td>
                            </tr>

                            <tr>
                                <td className='normal-lines'></td>
                                <td className='normal-lines'></td>
                                <td className='normal-lines-short'></td>
                                <td className='normal-lines-short'></td>
                                <td className='normal-lines-short'></td>
                            </tr>
                            <tr>
                                <td className='normal-lines-short'></td>
                                <td className='normal-lines-short'></td>
                                <td className='normal-lines'></td>
                                <td className='normal-lines'></td>
                                <td className='normal-lines'></td>
                            </tr>
                        </div>
                        : null}
                    {this.props.isCards
                        ? <table className='rightpanel-skeleton'>
                            <tr>
                                <td className='normal-lines'></td>
                                <td className='normal-lines'></td>
                                <td className='normal-lines-shortest'></td>
                            </tr>
                        </table> : null}

                </div>
            </div>
        )
    }
}
export default LoadingTemplate
import { connect } from 'react-redux'
import Feedback from '../components/Feedback'
import { setBreadCrumbs, setPageTitle, toggleNotification, navigatePatients, setCreditsCaution } from '../../../reducers/global-reducer';
import { actionCreators } from '../modules/feedback'
const mapStateToProps = (state) => ({
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify
})

const mapDispatchToProps = {
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    ...actionCreators,
    navigatePatients,
    setCreditsCaution,
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
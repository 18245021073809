import React from 'react';
import _ from 'lodash';
import { CalculateChange } from '../../Analytics/tools/calendar';
import Grade from '../../Analytics/components/Grade';
import { getRoundedValue, IsInRange } from '../../Analytics/tools/helpers';

const ResultsData = (props) => {
    const getSource = (row) => {
        var arr = [];
        for (var i = row.sourceIndex + 65; i <= 90; i++) {
            return arr[i - 65] = String.fromCharCode(i);
        }
    }
    return (
        <table className='table table-hover'>
            {props.data.map(row => (
                <tr>
                    {props.showGrade ? <td><span>{row.points != null ? <Grade points={row.points} /> : ""}</span></td> : null}
                    <td><span className='table-name-break'>{row.label}</span></td>
                    <td>{row.points >= 3.7 ? <span className='success'>{(row.valueType == "NM" || row.valueType == "Numeric") ? getRoundedValue(row.value, row.scale) : row.value}</span>:
                        ((row.valueType == "TX" && (row.referenceRange == null || (row.referenceRange != null && row.referenceRange.replace(/\s/g, "").split(",").includes(row.value.replace(/\s/g, "")))))) ?
                            <span>{row.value}</span> :
                        ((row.valueType == "TX" && row.referenceRange && row.referenceRange != null && !(row.referenceRange.replace(/\s/g, "").split(",").includes(row.value.replace(/\s/g, ""))))) ?
                           <span className='danger' >{row.value}</span>:
                        (row.points != null && row.points <= 1.3) || (row.referenceRange && row.referenceRange != null && !IsInRange(row.referenceRange, row.value, row)) ? <span className='danger'>{(row.valueType == "NM" || row.valueType == "Numeric") ? getRoundedValue(row.value, row.scale) : row.value}</span>
                        : <span>{(row.valueType == "NM" || row.valueType == "Numeric") ? getRoundedValue(row.value, row.scale) : row.value}</span>}</td>
                    <td>{row.units}</td>
                    <td>{row.referenceRange}</td>
                    <td>{row.optimalRange}</td>
                    {!props.isBaseline ? <td><span>
                        {row.historicData && row.historicData.length &&
                            row.historicData.filter(s => s.visitId == props.baselineVisitId).length > 0 ?
                            _.head(row.historicData.filter(s => s.visitId == props.baselineVisitId)).value : ''}
                    </span></td> : null}
                    {!props.isBaseline ? <td><span>{CalculateChange(row, props.baselineVisitId)}</span></td> : null}
                    {props.showSource ? <td>{
                        getSource(row)
                    }</td> : null}
                </tr>
            ))}
        </table>
    )
}

export default ResultsData;

import React, { Component } from 'react';
import labels from '../../../config/localization';
import constants from '../../../config/constants';


class Videos extends Component {
    componentDidMount() {
        this.props.getVideos()
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.help_page_labels.videos_title, path: '' }
        ])
        this.props.setPageTitle(labels.help_page_labels.videos_title)
        document.title = `${labels.physioage_label} : ${labels.help_page_labels.videos_title}`
    }
    render() {
        return (

            <div className='container col-lg-6 p-0'>
                {this.props.videos ? this.props.videos.map(video => (
                    <React.Fragment>
                       <h5 className='individual-video-sec'>{video.display}</h5>
                        {video.sectiondisplay}
                        {video.videos.map(v => (
                            <div>
                                <ul className='mb-0'> <li className='videos'><a title={video.display} href={v.link}>
                                    {v.name}</a></li> </ul>
                            </div>
                        ))}
                    </React.Fragment>
                )) : null}

            </div>
        )
    }
}
export default Videos;
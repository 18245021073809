import React from 'react';
import Rickshaw from 'rickshaw'
import ChartTools from '../tools/ChartTools';
export default class HistoricDataSparkline extends React.Component {
  componentDidMount() {
    this.firstPlot = this.firstPlot.bind(this);
    this.plot_data = this.plot_data.bind(this);
    this.firstPlot();
  }
  boundarySeries(timeBoundaries) {
    // from https://stackoverflow.com/questions/17298294/how-can-i-set-min-max-of-the-x-axis-in-rickshaw-d3
    return (
      {
        data: [
          { x: timeBoundaries ? timeBoundaries['starting_timestamp'] : 0, y: null },
          { x: timeBoundaries ? timeBoundaries['ending_timestamp'] : 0, y: null }
        ]
      }
    )
  }

  firstPlot() {
    let data = this.props.historic_data;
    let plotter = this.plot_data;

    let target_element = document.getElementById(this.props.uniqueID);

    let full_plot = function () {
      target_element.innerHTML = ''
      plotter(data, target_element);
    }

    window.addEventListener('resize', full_plot);
    full_plot();
  }
  plot_data(data, target_element) {
    data = _.orderBy(data, ['visitDate'])
    var padding = new ChartTools(this.props.historic_data, 0.35);
    let prepared_values = data.map(ChartTools.rickshawCargo).filter(ChartTools.hasValue);
    let width = (target_element && target_element.offsetWidth * 0.95) || 280
    let height = width * 0.1;
    if (this.props.type == "pointed") {
      padding.plot_min = 0,
        padding.plot_max = 5
    }
    var graph = new Rickshaw.Graph({
      element: target_element,
      width: width,
      height: height,
      renderer: 'rubricplot_with_line',
      min: padding.plot_min,
      max: padding.plot_max,
      padding:
      {
        top: 0.02, left: 0.02, right: 0.02, bottom: 0.02
      },
      series: [
        {
          color: 'grey',
          data: prepared_values
        }, this.boundarySeries(this.props.timeBoundaries)
      ]
    });
    if (!isNaN(padding.plot_min) && !isNaN(padding.plot_max)) {
      graph.render();
    }
  }

  render() {
    return (
      <div className='sparkline' id={this.props.uniqueID}></div>
    );
  }
}

import {connect} from 'react-redux';
import HistoricDataChart from '../components/HistoricDataChart.js';
import { actionCreators } from '../modules/analyticsBeta.js';
const mapStateToProps = (state) => ({
  ...state.analytics,
})

const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(HistoricDataChart);

import React from 'react';
import BiomarkerChart from './BiomarkerChartComponent.js';
import labels from '../../../config/localization.js';
import constants from '../../../config/constants';
import _ from 'lodash';
export default class AggregateChart extends React.Component {
  constructor(props) {
    super(props);
    this.renderBiomarker = this.renderBiomarker.bind(this);
  }

  render() {
    return (
      <section className='page-section biomarker-charts-section' id='biomarker-aggregate-section'>
        <div className='max-container with-max-width'>
          <h2 className='section-title'>{labels.analytics_labels.comparing_your_ages}</h2>
          <div className='charts-container'>
            {this.renderBiomarker(this.props.historic_data_chart)}
          </div>
        </div>
      </section>
    )
  }
  renderBiomarker() {
    if (this.props.presentPatient.dob) {
      return (
        <React.Fragment>
          {this.props.currentPatientAging && this.props.historic_data_chart ?
            Object.keys(this.props.historic_data_chart).map(ad => {
              if (typeof (this.props.historic_data_chart[ad]) == constants.object && this.props.historic_data_chart[ad].length > 0)
                return (
                  <BiomarkerChart
                    dob={this.props.presentPatient.dob}
                    historic_data={this.props.historic_data_chart && this.props.historic_data_chart[ad] ? this.props.historic_data_chart[ad] : []}
                    title={ad}
                    enLabel={this.props.historic_data_chart[ad][0].enLabel}
                    cultureLabel={this.props.historic_data_chart[ad][0].label}
                    key={`boac_${this.props.historic_data_chart[ad] ? this.props.historic_data_chart[ad][0].enLabel : ''}`}
                    width='300'
                    height='260'
                    min={this.props.min_value}
                    max={this.props.max_value}
                    languagePreference={this.props.presentPatient.languagePreference}
                  />
                )
            }) : ''}
        </React.Fragment>
      )
    }
  }
}

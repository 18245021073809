import React, { Component } from 'react';
import UploadMultipleDocumentComponent from '../../Patients/components/UploadMultipleDocumentComponent';

class UploadSiteDocument extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.siteId) {
            this.props.setCurrentSiteDetails(this.props.match.params.siteId, 'upload-document', this.props.history)
            this.props.setDocumentsFields()
        }
    }
    render() {
        return (
            <div className='container manual-form'>
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>
                <div className='float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12' >
                    <UploadMultipleDocumentComponent {...this.props} />
                </div>
            </div>
        )
    }
}
export default UploadSiteDocument;
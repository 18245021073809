export default {
    demo_1: [ 
        {
            "id": 7,
            "visitID": 0,
            "patientID": 5794,
            "siteID": 512,
            "clinicalStatus": "active",
            "verificationStatus": "unconfirmed",
            "category": "problem-list-item",
            "severity": "Severe",
            "code": "Low risk of harm to others (finding)",
            "bodySite": "Tripier flap (body structure)",
            "onSetType": "date",
            "onSetValue1": "2024-04-15T18:30:00.000Z",
            "onSetValue2": "",
            "abatementType": "age",
            "abatementValue1": "40",
            "abatementValue2": "",
            "recordedDate": "2024-04-16T10:32:53",
            "notes": [],
            "createdDate": "2024-04-16T10:33:22",
            "updatedDate": "2024-04-16T10:33:22",
            "isCustomCode": false,
            "isCustomBodySite": false
        },
        {
            "id": 8,
            "visitID": 0,
            "patientID": 5794,
            "siteID": 512,
            "clinicalStatus": "active",
            "verificationStatus": "provisional",
            "category": "problem-list-item",
            "severity": "Severe",
            "code": "Cognitive deficit in visuospatial function (finding)",
            "bodySite": "Donor site (body structure)",
            "onSetType": "period",
            "onSetValue1": "2024-04-24T13:30:00.000Z",
            "onSetValue2": "2024-04-26T14:30:00.000Z",
            "abatementType": "period",
            "abatementValue1": "2024-04-16T12:30:00.000Z",
            "abatementValue2": "2024-04-26T14:00:00.000Z",
            "recordedDate": "0001-01-01T00:00:00",
            "notes": [],
            "createdDate": "2024-04-16T13:08:48",
            "updatedDate": "2024-04-19T07:00:45",
            "isCustomCode": false,
            "isCustomBodySite": false
        },
        {
            "id": 21,
            "visitID": 13129,
            "patientID": 5794,
            "siteID": 512,
            "clinicalStatus": "active",
            "verificationStatus": "unconfirmed",
            "category": "problem-list-item",
            "severity": "Severe",
            "code": "Opiate detected by screening method (finding)",
            "bodySite": "Acquired body structure (body structure)",
            "onSetType": "period",
            "onSetValue1": "2024-04-24T18:30:00.000Z",
            "onSetValue2": "2024-04-28T18:30:00.000Z",
            "abatementType": "date",
            "abatementValue1": "2024-04-24T18:30:00.000Z",
            "abatementValue2": null,
            "recordedDate": "2024-04-24T06:17:11",
            "notes": [
                {
                    "id": 22,
                    "problemID": 21,
                    "note": "notes1"
                },
                {
                    "id": 23,
                    "problemID": 21,
                    "note": "notes2"
                },
                {
                    "id": 24,
                    "problemID": 21,
                    "note": "notes3"
                }
            ],
            "createdDate": "2024-04-24T06:17:59",
            "updatedDate": "2024-04-24T06:17:59",
            "isCustomCode": false,
            "isCustomBodySite": false
        },
        {
            "id": 22,
            "visitID": 13129,
            "patientID": 5794,
            "siteID": 512,
            "clinicalStatus": "remission",
            "verificationStatus": "provisional",
            "category": "problem-list-item",
            "severity": null,
            "code": "Opiate detected by screening method (finding)",
            "bodySite": "Recipient site (body structure)",
            "onSetType": null,
            "onSetValue1": null,
            "onSetValue2": null,
            "abatementType": null,
            "abatementValue1": null,
            "abatementValue2": null,
            "recordedDate": "2024-04-24T10:01:12",
            "notes": [],
            "createdDate": "2024-04-24T10:01:26",
            "updatedDate": "2024-04-24T10:01:26",
            "isCustomCode": false,
            "isCustomBodySite": false
        },
        {
            "id": 23,
            "visitID": 13129,
            "patientID": 5794,
            "siteID": 512,
            "clinicalStatus": "inactive",
            "verificationStatus": "provisional",
            "category": "problem-list-item",
            "severity": "Severe",
            "code": "At increased risk of exposure to Leptospira (finding)",
            "bodySite": "Donor site (body structure)",
            "onSetType": null,
            "onSetValue1": null,
            "onSetValue2": null,
            "abatementType": null,
            "abatementValue1": null,
            "abatementValue2": null,
            "recordedDate": "2024-04-24T10:01:31",
            "notes": [],
            "createdDate": "2024-04-24T10:01:50",
            "updatedDate": "2024-04-24T10:01:50",
            "isCustomCode": false,
            "isCustomBodySite": false
        },
        {
            "id": 24,
            "visitID": 13129,
            "patientID": 5794,
            "siteID": 512,
            "clinicalStatus": "unknown",
            "verificationStatus": "differential",
            "category": "problem-list-item",
            "severity": null,
            "code": "Seroconversion (finding)",
            "bodySite": null,
            "onSetType": null,
            "onSetValue1": null,
            "onSetValue2": null,
            "abatementType": null,
            "abatementValue1": null,
            "abatementValue2": null,
            "recordedDate": "2024-04-24T10:02:04",
            "notes": [],
            "createdDate": "2024-04-24T10:02:16",
            "updatedDate": "2024-04-24T10:02:16",
            "isCustomCode": false,
            "isCustomBodySite": false
        },
        {
            "id": 25,
            "visitID": 13129,
            "patientID": 5794,
            "siteID": 512,
            "clinicalStatus": "unknown",
            "verificationStatus": "provisional",
            "category": "problem-list-item",
            "severity": null,
            "code": "At high risk for bleeding (finding)",
            "bodySite": null,
            "onSetType": null,
            "onSetValue1": null,
            "onSetValue2": null,
            "abatementType": null,
            "abatementValue1": null,
            "abatementValue2": null,
            "recordedDate": "2024-04-24T10:02:18",
            "notes": [],
            "createdDate": "2024-04-24T10:02:31",
            "updatedDate": "2024-04-24T10:02:31",
            "isCustomCode": false,
            "isCustomBodySite": false
        }
    ],
    demo_2: []
}    
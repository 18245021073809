import { connect } from 'react-redux'
import Credits from '../components/Credits'
import { actionCreators } from '../modules/credits';
import { setBreadCrumbs, setPageTitle, toggleNotification, navigatePatients, setCreditsCaution } from '../../../reducers/global-reducer';


const mapStateToProps = (state) => ({
    ...state.credits,
    isLoading: state.global.isLoading,
    isParentContainer: state.global.isParentContainer,
    isSmallScreen: state.global.isSmallScreen,
    isVerySmallScreen: state.global.isVerySmallScreen,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify
})

const mapDispatchToProps = {
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    ...actionCreators,
    navigatePatients,
    setCreditsCaution
}

export default connect(mapStateToProps, mapDispatchToProps)(Credits)
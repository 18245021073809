import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import { Chart } from 'react-google-charts';
import constants from '../../../config/constants';
import BootstrapTable from 'react-bootstrap-table-next';
import HealthReportCardComponent from './HealthReportCardComponent';
import Grade from '../../Analytics/components/Grade';
import en_labels from '../../../config/en_labels';

const openbleFiles = ['pdf', 'jpg', 'png', 'jpeg']

const patientVistDetails = {
    displayDate: 'DOB',
    gender: 'Gender',
    siteName: 'Site',
    currentVisitDate: 'Visit Date',
    patientVisitAge: 'Age at Visit'
}
class IndividualVisit extends Component {
    generate() {
        var pdf = new jsPDF('p', 'pt', 'a4');
        pdf.setFontSize(18);
        pdf.fromHTML(document.getElementById('physician_report'))
        const downloadLink = document.createElement('a');
        downloadLink.href = pdf.output('datauristring');
        downloadLink.download = 'physician_report.pdf';
        downloadLink.click();
    };
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.visitId) {
            this.props.setIsIndividualVisitPage(true);
            this.props.history.push(`/visits/${this.props.match.params.visitId}`)
            this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history)
        }
    }
    componentWillReceiveProps() {
        this.props.setHeaderType(constants.visit_labels.visit)
    }
    ageClass(physiological) {
        var chronological = this.props.currentVisit.patientVisitAge;
        var x = chronological - 5;
        var y = chronological + 5;
        if (physiological < x)
            return ('text-success');
        else if (physiological > y)
            return ('text-danger');
        else
            return ('text-muted');
    }
    isReportCardExist(category, visitId) {
        let allData = []
        if (category)
            category.map(c => {
                c.sections.map(sec => sec.data.map(d => allData.push(d)))
            })
        allData = allData.filter(ad => ad.visitId == visitId && ad.points !== undefined && ad.points != null && !isNaN(ad.points))
        return allData.length ? true : false
    }
    render() {
        const visitDocuments = [
            {
                dataField: '', formatter: (cellcontent, row) => row.format == 'pdf' ?
                    <i class='fa fa-file-pdf-o'></i> : <i class='fa fa-file-o'></i>
            },
            {
                dataField: 'title',
                formatter: (cellcontent, row) => {
                    if (row.format && openbleFiles.indexOf(row.format.toLowerCase()) != -1) {
                        return <Link to={`/documents/${row.documentId}.${row.format}`} target='_blank'>{row.title}</Link>
                    } else {
                        return <a onClick={() => this.props.downloadDocument(row, this.props.history)}>{row.title}</a>
                    }
                }, headerStyle: { width: '80%' }
            },
            { dataField: '', formatter: (cellcontent, row) => <a onClick={() => this.props.deleteDocument(row.documentId)}><i class="fa fa-trash status-indicator text-danger trash-icon"></i></a> },

        ]

        return (
            <div className='individual-visit'>
                {this.props.ordersList && this.props.ordersList.length && this.props.ordersList.filter(s => s.enableLoader == true).length ?
                    <div class="alert alert-info">
                        <i className='fa fa-gear fa-spin expositer-loader-header'></i> Results are getting exposited, sit back and relax!!
                </div> : ''}
                {((this.props.no_order_data && !this.props.ordersList.length) || (this.props.ordersList.length && !this.props.ordersList.filter(a => a.orderStatus == 'filled').length)) ?
                    <div className='jumbotron'>
                        <h1>{labels.visit_page_labels.waiting_for_data}</h1>
                        <p>{labels.visit_page_labels.notes}</p>
                    </div> : (!this.props.no_order_data && !this.props.ordersList.length) ? null
                        : <React.Fragment>
                            <div className='rightPanel'>
                                {Object.keys(this.props.biomarkers).filter(s => this.props.biomarkers[s] > "0").length > 0 ?
                                    <div className='col-lg-6 mb-2 left-detail-section'>
                                        <div className='card card-default p-2 '>
                                            <div className='card-body pl-4 pb-2 pt-2 '>
                                                <h3 className='mb-2'> {labels.visit_page_labels.biomarkers}</h3>
                                                {this.props.biomarkers ?
                                                    <React.Fragment>
                                                        <div className='patientVisitData pt-2'>
                                                            {Object.keys(this.props.biomarkers ? this.props.biomarkers : '').map(pd => {
                                                                if (this.props.biomarkers[pd] != 0) {
                                                                    return (<li className='card-content-row d-flex pt-0 pb-0 '>
                                                                        <i className={`fa fa-circle status-indicator  card-body-report mt-2 ${this.ageClass(this.props.biomarkers[pd])}`}></i>

                                                                        <div className='col-md-6 col-xs-4 p-2'>{constants.biographical_labels.ageLabels[pd]}</div>
                                                                        <div className='col-md-8  col-xs-4 p-2'> {this.props.biomarkers[pd] ? `${this.props.biomarkers[pd]} ${labels.orders_labels.years.toLowerCase()}` : labels.not_available}</div>
                                                                    </li>)
                                                                }
                                                            })}
                                                        </div>
                                                    </React.Fragment>
                                                    : null}
                                            </div>
                                        </div>
                                    </div> : ''}
                                <div className='col-lg-6 mb-2 right-detail-section'>
                                    <div className='card card-default p-2 '>
                                        <div className='card-body pl-4 pb-2 pt-2 '>
                                            <h3 className='mb-2'> {labels.patient_list_labels.patient_details}</h3>
                                            {this.props.currentVisit ?
                                                <div className='patientVisitData pt-2'>
                                                    {Object.keys(patientVistDetails).map(pd => (
                                                        pd != 'siteName' ?
                                                            <li className='card-content-row d-flex pb-0 pt-0 '>
                                                                <div className='col-md-4 col-xs-4 p-2'>{patientVistDetails[pd]}</div>
                                                                <div className='col-md-8  col-xs-4 p-2'> {this.props.currentVisit[pd] ? this.props.currentVisit[pd] : labels.not_available}</div>
                                                            </li>
                                                            :
                                                            <li className='card-content-row d-flex '>
                                                                <div className='col-md-4 col-xs-4 p-2'>{patientVistDetails[pd]}</div>
                                                                <div className='col-md-8 col-xs-4 p-2'> <Link to={`/sites/${this.props.currentVisit.siteId}`}>{this.props.currentVisit ? this.props.currentVisit[pd] : ''}</Link></div>
                                                            </li>
                                                    ))}
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    <FormControl type='button' btnType='info' className='btn-lg mt-2 col-12 view-health-analytics-lg-btn' onClick={() => this.props.navigateHealthAnalytics(this.props.history, `/patients/${this.props.currentVisit.patientId}/analytics`)} text={labels.patient_list_labels.view_health_analytics} />
                                </div>
                            </div>
                            {/* <div className='rightPanel aggregate-chart-card card'>
                                <div className='aggregate_age_chart card-body'>
                                    <h3>{labels.visit_page_labels.physiological_ages}</h3>
                                    <iframe src={this.props.followUpUrl}></iframe>
                                </div>
                            </div> */}
                            {this.props.categories && this.props.categories.length && this.isReportCardExist(this.props.categories, this.props.currentVisit.id) ?
                                <HealthReportCardComponent {...this.props} />
                                : null}
                            {this.props.currentVisit.enableDocuments ?
                                <div className='rightPanel download-visits card'>
                                    <div className='card-body'>
                                        <h3>{constants.visit_labels.download_visit_label}</h3>
                                        {this.props.currentVisitDocuments && this.props.currentVisitDocuments.length ?
                                            <div className='visit-documents'>
                                                <BootstrapTable striped={true} bordered={false} hover={true} bootstrap4 keyField='id'
                                                    data={this.props.currentVisitDocuments ? this.props.currentVisitDocuments : []} columns={visitDocuments}
                                                />
                                            </div> : ''}
                                        <div className='row'>
                                            <div className='col-xs-6 mr-2 ml-4'>
                                                <FormControl type='button' btnType='primary' text="Upload" onClick={() => this.props.history.push(`/visits/${this.props.currentVisit.id}/documents/new`)} />
                                            </div>
                                            <div className='col-xs-6'>
                                                <FormControl type='button' btnType='default' text='Browse Library' onClick={() => this.props.history.push(`/visits/${this.props.currentVisit.id}/common_documents`)} />
                                            </div>
                                            {this.props.currentVisitDocuments && this.props.currentPatientDocuments
                                                && this.props.currentPatientDocuments.length > this.props.currentVisitDocuments.length ?
                                                < div className='col-xs-6'>
                                                    <FormControl type='button' className='all-downloads-btn ml-2'
                                                        btnType='default' text={labels.visit_page_labels.all_downloads} onClick={() => this.props.history.push(`/patients/${this.props.currentVisit.patientId}/documents`)} />
                                                </div> : ''}
                                        </div>
                                    </div>
                                </div> : ''}
                            {this.props.loggedInUser.role.toLowerCase() == "admin" && this.props.ageWeightsGraph && this.props.ageWeightsGraph.length > 1 ?
                                <div className='rightPanel aggregate-chart-card card'>
                                    <div className='age-weights card-body'>
                                        <h3>{labels.visit_page_labels.age_weights}</h3>
                                        <Chart
                                            chartType='ColumnChart'
                                            height='420px'
                                            data={this.props.ageWeightsGraph ? this.props.ageWeightsGraph : ''}
                                            options={{
                                                legend: { position: 'top' },
                                                isStacked: true,
                                                hAxis: {
                                                    minValue: 0,
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                : null}
                        </React.Fragment>}
            </div>
        )
    }
}
export default IndividualVisit;
import React from 'react';
import CategoryComponent from './CategoryComponent';
import ReportCardComponent from '../components/ReportCardComponent.js';

export default class HealthDataComponent extends React.Component {
  render() {
    if (this.props.categories ? this.props.categories.length : 0 > 0)
      return (this.actuallyRender());
    else
      return (null);
  }

  actuallyRender() {
    var renderCategory = (category) => <CategoryComponent {...this.props} key={category.key} category={category} hashDrawer={this.props.hashDrawer} currentVisitDate = {this.props.latestVisitInfo}/>
    return (
      <section className='page-section health-cateogry' id='your_health'>
        <ReportCardComponent {...this.props} gpa={this.props.gpa} />
        {this.props.categories ? this.props.categories.map(renderCategory) : ''}
      </section>
    );
  }
}
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Loader from '../../../components/Loader';
import Breadcrumb from '../../../components/Breadcrumb';
import labels from '../../../config/localization';
import CategoryMultiLevelList from '../containers/CategoryMultiLevelListContainer';
import CategoryOverview from '../containers/CategoryOverviewContainer';
import SectionOverview from '../containers/SectionOverviewContainer';
import NoumenonOverview from '../containers/NoumenonOverviewContainer';

function ReportCardComponent(props) {
    useEffect(() => {
        let element = document.getElementsByClassName("breadcrumb")
        element && element[0] ? element[0].scrollIntoView(0,0) : ""
        props.setBreadCrumbs([
            { text: "Health Span Potential", path: '' }
        ])
        props.toggleCategoryDetailsModal(false)
        // props.clearBioAgeOverviewData()
    
    return () => {
            // Anything in here is fired on component unmount.
    }
    }, [])
    const currentCatgory = () => {
        if (!props.currentCategory)
            props.setCurrentCategory( _.head(props.categories))
    }
    return (
        <>
        { props.categories ? 
            <>
            { props.customCategories ? " " : props.getCustomCategories() }
            { props.currentCategory ? " " : currentCatgory()}
            <div className='d-flex row m-0 report-card report-card-container'>
                <div className="col-lg-3 col-md-5 col-12 padding-0 pr-1"> 
                    <div className="category-dropdown-div">
                        <CategoryMultiLevelList {...props} />
                    </div>
                </div>
                <div className="col-lg-9 col-md-7 col-12 padding category-overview-div-1024">
                    <div className="category-data-div">
                        <div className='header-div'>
                            <div className='breadcrumb'>
                                <span className='individual-breadcrumb'>
                                    {props.currentIndicator ? props.currentIndicator.includes('category') ? <div className='header-div-active-element'>{ props.currentCategory && props.currentCategory.name ? props.currentCategory.name : ""}</div> 
                                    :  props.currentIndicator.includes('section') && props.sectionCategory ? <div className='d-flex'>{props.sectionCategory} <div className='ml-2 mr-2'>{'>'}</div> <div className='header-div-active-element'>{props.currentSection.name}</div> </div>
                                    : props.currentCategory && props.currentCategory.sections && props.currentCategory.sections.length === 1 &&  props.currentCategory.name ===  props.currentCategory.sections[0].name ? <div className='d-flex'>{props.sectionCategory} <div className='ml-2 mr-2'>{'>'}</div> <div className='header-div-active-element'>{props.datumValues ? props.datumValues.label : ""}</div> </div> :  <div className='d-flex'>{props.sectionCategory}   <div className='ml-2 mr-2'>{'>'}</div> {props.dataSection} <div className='ml-2 mr-2'>{'>'}</div>  <div className='header-div-active-element'>{props.datumValues ? props.datumValues.label : ""}</div></div>
                                    :''} 
                                </span>
                            </div>
                        </div>
                        <CategoryOverview {...props} />
                    </div>
                </div>
            </div> 
            </>
            : null }
        </>   
    );
}
export default connect()(ReportCardComponent);
import React from 'react';
import labels from '../../../config/localization';

export default class AggregateChart extends React.Component {

  render()
  {
    return(
      <section className='page-section biomarker-aggregate-chart-section' id='biomarker-aggregate-section'>
        <div className='max-container '>
          <h2 className='section-title '>{labels.analytics_labels.comparing_your_ages}</h2>
          <img src={this.props.url} />
        </div>
      </section>
    );
  }
}

import { combineReducers } from 'redux';

// calling the global reducer to create a link
import globalReducer from './global-reducer';
import { routerReducer } from 'react-router-redux'
import patientsReducer from '../routes/Patients/modules/patients';
import usersReducer from '../routes/Users/modules/user';
import feedbackReducer from '../routes/Feedback/modules/feedback';
import creditsReducer from '../routes/Credits/modules/credits';
import sitesReducer from '../routes/Sites/modules/sites';
import providerReducer from '../routes/Providers/modules/providers';
import billingReducer from '../routes/BillingSummary/modules/billingsummary';
import messageroutingReducer from '../routes/MessageRouting/modules/messagerouting';
import helpReducer from '../routes/Help/modules/help';
import creditTransactionReducer from '../routes/ELK/modules/credittransactions';
import correctionReducer from '../routes/Corrections/modules/corrections';
import odditiesReducer from '../routes/Oddities/modules/oddities';
import inboxReducer from '../routes/Inbox/modules/inbox';
import sitesReportReducer from '../routes/ActiveSiteReports/modules/activesitereport';
import dataMenuReducer from '../routes/Data/modules/datamenu';
import analyticsReducer from '../routes/Analytics/modules/analytics';
import visitsReducer from '../routes/Visits/modules/visits';
import documentsReducer from '../routes/Documents/modules/documentview';
import caregiverReducer from '../routes/Caregivers/modules/caregivers';

const rootReducers = combineReducers({
    // add reducer files references here
    global: globalReducer,
    router: routerReducer,
    patients: patientsReducer,
    users: usersReducer,
    feedback: feedbackReducer,
    credits: creditsReducer,
    sites: sitesReducer,
    providers: providerReducer,
    billing: billingReducer,
    messagerouting: messageroutingReducer,
    help: helpReducer,
    elk: creditTransactionReducer,
    corrections: correctionReducer,
    oddities: odditiesReducer,
    inbox: inboxReducer,
    sitereport: sitesReportReducer,
    datamenu: dataMenuReducer,
    analytics: analyticsReducer,
    datamenu: dataMenuReducer,
    visits:visitsReducer,
    documents: documentsReducer,
    caregivers: caregiverReducer
});

export default rootReducers;
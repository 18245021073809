import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import { toggleNotification, toggleLoading, handleErrors, handleLoading } from '../../../reducers/global-reducer';
import labels from '../../../config/localization';
import constants from '../../../config/constants'
import messages from '../../../config/messages';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import resolveSearchFilters from '../../../utils/resolve-search-params';
const SET_PROPS = 'SET_PROPS'
const requiredFields = ['siteId']
const requiredFieldText = { siteId: labels.site_label }
// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }

function getMessageRoutingRules(history, pageNumber, isSort) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                //totalMessageRouting: [],
                totalCount: 0,
                no_grid_data: false
            }
        })
        dispatch(toggleLoading(true))
        let searchParam = { ...getState().messagerouting.searchParam }
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSearchFilters
            }
        })
        // if (pageNumber)
        //     searchParam = { ...searchParam, pageNumber: parseInt(pageNumber) }
        // if (history && !isSort)
        //     history.push(`?page=${searchParam.pageNumber}`)
        // if (history && isSort) 
        //     history.push(`?page=${searchParam.pageNumber}&sortOrder=${constants.sorting_order_decode[searchParam.sortOrder].toString()}&sortField=${searchParam.sortField}`)
        if (history)
            setSearchParamUrl(searchParam, currentSearchFilters, history)
        
        promisesList.push(axios.post(API_URL.MESSAGE_ROUTING, searchParam).then(response => {
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    totalMessageRouting: response.data && response.data.value ? response.data.value : [],
                    totalCount: response.data ? response.data.totalCount : 0,
                    no_grid_data: response.data && response.data.totalCount == 0 ? true : false,
                    initialLoading: false
                }
            })
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}
export function setInitialLoading(value) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                initialLoading: value
            }
        })
    }
}
function resetMessageRouting() {
    return (dispatch) => {
        let searchParam = Object.assign({}, {
            pageSize: 10,
            pageNumber: 1,
            sortOrder: 1,
            sortField: 'SendingApplication',
            searchFilters: []
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam,
                currentMessageRule: null,
                initialLoading: false
            }
        })
        dispatch(getMessageRoutingRules())
    }
}
function getSites() {
    return (dispatch) => {
        promisesList.push(axios.get(API_URL.SITES_NAMES).then((sites) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    sites: sites.data
                }
            })
            dispatch(handleLoading())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}
function setCurrentMessageRule(msgId) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentMessageRule: null
            }
        })
        promisesList.push(axios.get(`${API_URL.MESSAGE_ROUTING_URL}/${msgId}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentMessageRule: response.data
                }
            })
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}
function saveMessageRoute(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentMessageRule = getState() && getState().messagerouting
            && getState().messagerouting.currentMessageRule
            ? getState().messagerouting.currentMessageRule : {}

        let req = requiredFields.filter(rf => (!currentMessageRule[rf] || parseInt(currentMessageRule[rf]) == 0))

        if (req && req.length) {
            dispatch(toggleLoading(false))
            window.scrollTo(0, 0)
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                req.map(r => `${requiredFieldText[r]} ${labels.required_label}`)
                , false))
        }
        else {
            promisesList.push(axios[currentMessageRule.id ? 'put' : 'post'](API_URL.MESSAGE_ROUTING_URL, { ...currentMessageRule }).then(response => {
                dispatch(ApplyMessageRouting(response.data.value ? response.data.value : currentMessageRule.id))
                // dispatch(getMessageRoutingRules(history, 1))
                dispatch(handleLoading())
                history.push('/messagerouting/list')
            }).catch(error => {
                dispatch(handleErrors(error))
            }))
        }
    }
}
function messagePageChange(pageNumber, history, urlparams, fromUrl) {
    return (dispatch, getState) => {
        let searchParam = Object.assign({}, getState().messagerouting.searchParam, { pageNumber })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (urlparams) {
            let sortField = urlparams.sortField ? urlparams.sortField : 'SendingApplication'
            let sortOrder = urlparams.sortOrder ? urlparams.sortOrder : 'asc'
            sortOrder = constants.sorting_order[sortOrder]
            searchParam = Object.assign({}, getState().messagerouting.searchParam, { sortOrder, sortField })
            dispatch({
                type: SET_PROPS,
                payload: {
                    searchParam
                }
            })
        }
        dispatch(getMessageRoutingRules(fromUrl ? false : history, false, searchParam && searchParam.sortField && searchParam.sortField.length ? true : false))
    }
}
function editMessageRoute(eve) {
    return (dispatch, getState) => {
        let currentMessageRule = getState() && getState().messagerouting
            && getState().messagerouting.currentMessageRule
            ? { ...getState().messagerouting.currentMessageRule, [eve.target.id]: eve.target.value } : {}
        dispatch({
            type: SET_PROPS,
            payload: {
                currentMessageRule
            }
        })
    }
}

function addRule(history) {
    return (dispatch, getState) => {
        let currentMessageRule = getState().messagerouting && getState().messagerouting.currentMessageRule ? getState().messagerouting.currentMessageRule : {}
        dispatch({
            type: SET_PROPS,
            payload: {
                currentMessageRule
            }
        })
        history.push('/messagerouting/new')
    }
}
export function navigateMessage(history, path) {
    return (dispatch) => {
        history.push(path)
        dispatch(toggleNotification(false))
    }
}
function getPanelList() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(API_URL.PANELS_DATA).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    panelList: _.sortBy(response.data.panelsDTO,"name")
                }
            })
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}
export function ApplyMessageRouting(routeId) {
    return (dispatch, getState) => {
        promisesList.push(axios.post(`${API_URL.APPLY_MESSAGE_ROUTING}?routeId=${routeId}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {

                }
            })
            dispatch(handleLoading())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
        dispatch(toggleNotification(true, '', '',
            [messages.message_routing_apply]
            , false))
    }
}
export function copyMessageRoute(row) {
    return (dispatch, getState) => {
        let copyRule = Object.assign({}, getState().messagerouting.messagerouting,
            {
                sendingFacility: row.sendingFacility, receivingApplication: row.receivingApplication,
                receivingFacility: row.receivingFacility, sendingApplication: row.sendingApplication, pid18_1: row.pid18_1, siteId: row.siteId,
                panel: row.panel
            })

        dispatch({
            type: SET_PROPS,
            payload: {
                currentMessageRule: copyRule
            }
        })
        dispatch(toggleNotification(true, 'success', '',
            ['Routing rule has been copied']
            , true, 1000))
    }
}

export function sortMessageRoutingList(key, order, history, pageNumber) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false));
        let searchParam = getState() && getState().messagerouting && getState().messagerouting.searchParam ? getState().messagerouting.searchParam : {}
        let pageNumChanged = false
        if (searchParam && (!searchParam.sortField || searchParam.sortField != key)) {
            searchParam.sortField = key
            if (searchParam.sortField != key)
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        //totalMessageRouting: []
                    }
                })
            pageNumChanged = true
            searchParam.pageNumber = 1
        }
 
        if (searchParam.sortOrder != constants.sorting_order[order]) {
            searchParam.sortOrder = constants.sorting_order[order]
            searchParam.pageNumber = 1
        } else if (!pageNumChanged) {
            searchParam.pageNumber = pageNumber ? parseInt(pageNumber) : 1
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                ...searchParam
            }
        })
        dispatch(getMessageRoutingRules(history, false, true));
    }
}
function setSearchParamUrl(searchParam, currentSearchFilters, history) {
    let searchParamUrl = '', params = ''
    searchParam.pageNumber = searchParam.pageNumber ? searchParam.pageNumber : 1
    searchParam.sortField = searchParam.sortField ? searchParam.sortField : 'SendingApplication'
    searchParam.sortOrder = searchParam.sortOrder ? searchParam.sortOrder : -1
    if (currentSearchFilters.siteId && !parseInt(currentSearchFilters.siteId))
        currentSearchFilters.siteId = ''
        
    if (currentSearchFilters.panelName && currentSearchFilters.panelName == 0)
        currentSearchFilters.panelName = ''
    
    let filters = Object.keys(currentSearchFilters).filter(csf => currentSearchFilters[csf]).map(csf => `${csf}=${currentSearchFilters[csf]}`)
    searchParamUrl = filters.join('&')
    let paramFilters = Object.keys(searchParam).filter(csf => searchParam[csf])
        .map(csf => {
            if (csf != 'searchFilters' && csf != 'pageSize')
                if (csf == "sortOrder") {
                    if (!searchParam[csf])
                        return `${csf}=desc`
                    else
                        return `${csf}=${constants.sorting_order_decode[searchParam.sortOrder.toString()]}`
                }
                else if (csf == 'pageNumber') {
                    return `page=${searchParam.pageNumber}`
                }
                else {
                    return `${csf}=${searchParam[csf]}`
                }
        })
    params = _.compact(paramFilters).join('&')
    if (searchParamUrl) {
        searchParamUrl = (`?${searchParamUrl}&${params}`)
    } else {
        searchParamUrl = (`?${params}`)
    }
    return history.push(searchParamUrl)
}
export function setSearchParam(val, key, search, history, activePageNumber) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))

        let searchParam = Object.assign({}, getState().messagerouting.searchParam)

        if (searchParam.searchFilters) {
            if (searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() == key.toLowerCase()).length) {
                searchParam.searchFilters.map(sf => {
                    if (sf.fieldName == key) { sf.fieldValue = val }
                })

            } else {
                searchParam.searchFilters.push({
                    fieldName: key,
                    fieldValue: val
                })
            }
        } else {
            searchParam.searchFilters = []
            searchParam.searchFilters.push({
                fieldName: key,
                fieldValue: val
            })
        }

        searchParam.searchFilters.map(sf => {
            if (sf.fieldName == "panelName" && sf.fieldValue == 0 ) 
            { 
                searchParam.searchFilters.pop(sf) 
            }
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: !search ? Object.assign({}, searchParam, { pageNumber: activePageNumber ? activePageNumber : 1 }) :
                    searchParam
            }
        })
        if ((!search && key == 'panelName') || (!search && key == 'siteId')) {
            dispatch(getMessageRoutingRules(history))
        }
    }
}

export function clearSearch(urlparams, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortField: 'SendingApplication',
                    sortOrder: -1,
                    searchFilters: [{ fieldName: 'siteId', fieldValue: '0' }]
                },
                currentSearchFilters: {},
                currentNames: [],
                isSearchCleared: true
            }
        })
        if (urlparams && Object.keys(urlparams).length) {
            let { siteId, page, panelName, sortOrder, sortField } = urlparams

            sortField = sortField ? sortField : 'SendingApplication'
            sortOrder = sortOrder ? sortOrder : 'desc'
            if (siteId)
                dispatch(setSearchParam(siteId, 'siteId', false))

            if (panelName)
                dispatch(setSearchParam(panelName, 'panelName', false))

            if (page) {
                dispatch(messageRoutigsPageChange(parseInt(page), false))
            }
            if (sortField) {
                dispatch(messageRoutigsSortOrder(sortOrder, sortField, history, false))
            }
    
        }

        dispatch(getMessageRoutingRules())
        dispatch(toggleNotification(false))
    }
}

export function messageRoutigsPageChange(pageNumber, submit, history) {
    return (dispatch, getState) => {
        let searchParam = Object.assign({}, getState().messagerouting.searchParam, { pageNumber })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (!submit)
            dispatch(getMessageRoutingRules(history))

    }
}
export function messageRoutigsSortOrder(sortOrder, sortField, history, submit) {
    return (dispatch, getState) => {
        sortOrder = constants.sorting_order[sortOrder]
        let searchParam = Object.assign({}, getState().messagerouting.searchParam, { sortOrder, sortField })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (!submit)
            dispatch(getMessageRoutingRules(history))

    }
}

const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}
const initialState = {
    currentMessageRule: {},
    searchParam: {
        pageSize: 10,
        pageNumber: 1,
        sortOrder: 1,
        sortField: 'SendingApplication',
        searchFilters: []
    },
    no_grid_data: false,
    initialLoading: false,
    totalMessageRouting: []
}
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export const actionCreators = {
    getMessageRoutingRules,
    setCurrentMessageRule,
    saveMessageRoute,
    editMessageRoute,
    getSites,
    addRule,
    messagePageChange,
    navigateMessage,
    getPanelList,
    ApplyMessageRouting,
    resetMessageRouting,
    copyMessageRoute,
    sortMessageRoutingList,
    setInitialLoading,
    setSearchParam,
    clearSearch,
    messageRoutigsPageChange,
}
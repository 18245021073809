import React, { Component } from 'react';
import constants from '../../../config/constants';
import labels from '../../../config/localization';
import { Typeahead } from 'react-bootstrap-typeahead';
import FormControl from '../../../components/FormControls/FormControl';

class NewOrder extends React.Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.visitId) {
            if (this.props.currentVisit && !Object.keys(this.props.currentVisit).length) {
                this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history)
                this.props.setHeaderType(constants.visit_labels.new_order, false)
            } else {
                this.props.setHeaderType(constants.visit_labels.new_order, true)
            }
            this.props.getPanels(this.props.match.params.visitId)
        }
    }
    getDefaultOrders(defaultorders) {
        return defaultorders ? defaultorders.split(',').map(a => ({
            suiteName: a.split(':')[0].toString(),
            name: a.split(':')[1]
        })) : []
    }
    render() {
        return (
            <div className='container'>
                <div className='col-md-12 col-sm-12 col-xs-12'>
                    {this.props.suiteNameDisplay ?
                        <div className='row new-order'>
                            <div className="col-2">
                                <label>
                                    {labels.visit_page_labels.suite_name}<abbr title='required'>*</abbr>
                                </label>
                            </div>
                            <span className='col-9'>
                                <FormControl value={this.props.suiteName}
                                    onChange={this.props.editSuitName} type='select' id='suiteName'
                                    options={this.props.suiteNameList
                                        ? this.props.suiteNameList : []} className='form-control col-lg-11 new-order-input_field'
                                    textKey={'name'} valueKey={'name'} />
                            </span>

                            <div className="col-2">
                                <label>
                                    {labels.visit_page_labels.panel_name}<abbr title='required'>*</abbr>
                                </label>
                            </div>
                            <span className='col-9'>
                                <FormControl
                                    type='text'
                                    className=' form-control col-lg-11 new-order-input_field'
                                    id='panelName'
                                    value={this.props.panelName}
                                    onChange={this.props.editPanelName}
                                />
                            </span>
                        </div>
                        :
                        <div className='row new-order'>
                            <div className="col-2">
                                <label>
                                    {labels.visit_page_labels.panel_name}<abbr title='required'>*</abbr>
                                </label>
                            </div>
                            <span className='col-9'>
                                <FormControl value={this.getDefaultOrders(this.props.multiSelectSuites)}
                                    onChange={this.props.editMultiSelectOrders} type='select' id='panelTypeAhead' isMulti={true}
                                    getOptionLabel={opt => `${opt.suiteName.trim()}:${opt.name.trim()}`}
                                    getOptionValue={opt => `${opt.suiteName.trim()}:${opt.name.trim()}`}
                                    options={this.props.panelList
                                        ? this.props.panelList : []}
                                    className='col-lg-11'
                                />
                            </span>
                            {/* <div className='col-9'>
                            <Typeahead
                                placeholder="Choose a panel..."
                                labelKey={option => `${option.suiteName}: ${option.name}`}
                                id='panelTypeAhead'
                                options={this.props.panelList ? this.props.panelList : []}
                                renderMenuItemChildren={(option, props) => (
                                    <span id={option.id ? option.id : 0} key={`${option.suiteName}:${option.name}`}>{`${option.suiteName}: ${option.name}`}</span>
                                )}
                                onChange={this.props.AddOrder}
                                ref={(ref) => this._typeahead = ref}
                            />
                        </div> */}
                        </div>
                    }
                    {this.props.currentVisit && this.props.currentVisit.planType == labels.visit_page_labels.professional && this.props.panelNameNotFound ?
                        <div className='row'>
                            <div className="col-2">
                            </div>
                            <span className='col-9'>
                                <a onClick={() => this.props.toggleOrderSuiteNameForm(true, this.props.history)} className='ml-17'> {labels.visit_page_labels.not_listed_in_the_list}
                                </a>
                            </span>
                        </div>
                        : ''
                    }

                    <br />
                    <div className='row'>
                        <div className='col-2'>
                        </div>
                        <div className='col-9 ml-17'>
                            <FormControl type='button'
                                btnType={constants.notification_type.success}
                                text={labels.visit_page_labels.test_add_order}
                                onClick={() => { this.props.addNewOrder(this.props.history) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NewOrder;
export default {
  demo_1: {
    "id": 5254,
    "firstname": "Female",
    "lastname": "Patient",
    "honorificSuffix": null,
    "gender": "female",
    "site": "PhysioAge Demo Site",
    "siteId": 83,
    "siteLanguagePreference": "en",
    "providerId": 3,
    "email": null,
    "userId": 2,
    "createdAt": "2019-12-23T23:42:06",
    "updatedAt": "2023-07-12T18:40:32",
    "dob": "May 22, 1960",
    "physioAgeVersion": null,
    "cardioAgeVersion": null,
    "pulmoAgeVersion": null,
    "neuroAgeVersion": null,
    "cutoAgeVersion": null,
    "immunoAgeVersion": null,
    "telomerAgeVersion": null,
    "identifier": null,
    "honorificPrefix": "Ms.",
    "languagePreference": "en",
    "elkActivatedAt": "2023-07-12T18:38:53",
    "visits": 12,
    "providerName": "Joseph Raffaele",
    "lastActiveDate": "3/22/2023",
    "latestVisitId": 20612,
    "latestVisitDate": "2023-03-22T00:00:00",
    "isUser": false,
    "datePreference": "mdy",
    "lastLoggedInAt": "Never",
    "enableDocuments": true,
    "patientLanguagePref": "en",
    "active": 1,
    "priorVisitId": 20613
  },
  demo_2: {
    "id": 776,
    "firstname": "Joseph",
    "lastname": "Raffaele",
    "honorificSuffix": "",
    "gender": "male",
    "site": "Raffaele Medical",
    "siteId": 3,
    "siteLanguagePreference": "en",
    "providerId": 3,
    "email": "jmr@raffaelemedical.com",
    "userId": null,
    "createdAt": "2012-06-29T22:04:09",
    "updatedAt": "2021-04-14T13:15:22",
    "dob": "Aug 29, 1959",
    "physioAgeVersion": null,
    "cardioAgeVersion": null,
    "pulmoAgeVersion": null,
    "neuroAgeVersion": null,
    "cutoAgeVersion": null,
    "immunoAgeVersion": null,
    "telomerAgeVersion": null,
    "identifier": "000004867",
    "honorificPrefix": "Mr.",
    "languagePreference": "en",
    "elkActivatedAt": "2020-11-04T15:35:50",
    "visits": 26,
    "providerName": "Joseph Raffaele",
    "lastActiveDate": "4/14/2021",
    "latestVisitId": 13806,
    "latestVisitDate": "2021-04-14T00:00:00",
    "isUser": true,
    "datePreference": "mdy",
    "lastLoggedInAt": "Never",
    "enableDocuments": true,
    "patientLanguagePref": "en",
    "active": 1
  }
}










import { connect } from 'react-redux';
import Patients from '../components/Patients'
import { actionCreators } from '../modules/patients';
import { setBreadCrumbs, setPageTitle, toggleNotification, noHeaderLoading, navigatePatients, setCreditsCaution, downloadDocument } from '../../../reducers/global-reducer';
import { addCreditTransaction } from '../../ELK/modules/credittransactions'
import { addToTableOfContents } from '../../Visits/modules/visits';

const mapStateToProps = (state) => ({
    ...state.patients,
    ...state.visits,
    loggedInUser: state.global.loggedInUser,
    isPatientReport: state.global.isPatientReport,
    isLoading: state.global.isLoading,
    isParentContainer: state.global.isParentContainer,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    isSmallScreen: state.global.isSmallScreen,
    isVerySmallScreen: state.global.isVerySmallScreen,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify
})

const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    addCreditTransaction,
    noHeaderLoading,
    navigatePatients,
    setCreditsCaution,
    downloadDocument,
    addToTableOfContents
}
export default connect(mapStateToProps, mapDispatchToProps)(Patients)
import React, { Component } from 'react';
import { connect } from 'react-redux';


function CardLoaderComponent() {
    return (
        <> 
            <div class="card-loader card-loader--tabs"></div>
            {/* <div className='center'>
                <span class="card-loader"></span>
            </div> */}
        </>
    )
}

export default connect()(CardLoaderComponent);
import React from 'react';
// import SpriteIcon from './SpriteIcon';
import { uniformString } from '../tools/helpers';
import CategoryNav from './CategoryNav';
import '../../../assets/Analytics-JS/Analytics-scroll-sidebar'
import { createSelector } from 'reselect';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import { Link as NavigateLink } from 'react-router-dom';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';
export default class Nav extends React.Component {

  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  componentDidMount() {
    Events.scrollEvent.register('begin', function () {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function () {
      console.log('end', arguments);
    });
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }
  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });

      scroller.scrollTo('scroll-container', {
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      })
    );
  }
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  toggleMenuInMobile = () => {
    if (window.innerWidth <= 1024) {
      this.props.toggleMenu()
    }
  }
  onSetActive = (enName) => {
    let ele = document.getElementById(enName)
    if (ele)
      ele.scrollIntoView({ block: 'center' })
  }
  render() {
    return (
      this.props.terms ?
        <div className={`menu-nav sidebar-left fade-in-element-sidebar ${this.props.isMenuActive ? 'active-sidebar' : ''}`} id='left-side-bar'>
          <div className='sidebar-content'>
            <nav className='health-categories'>
              <ul className='sidebar nav nav-stacked fixed sidebar-a' >
                {this.props.isDemo ?
                  <a className='signout' onClick={() => window.location.href = 'https://physioage.com/'}>{labels.analytics_labels.exit_demo}</a>
                  :
                  <>
                    <a className='signout' onClick={() => { this.props.logOut(this.props.history) }}>{labels.analytics_labels.logOut}</a>
                    {this.props.loggedInUser && this.props.loggedInUser.role != constants.logged_roles.PT ?
                      <li className='logout'>
                        <NavigateLink to={`/patients/${this.props.presentPatient ? this.props.presentPatient.id : 0}`}>{labels.analytics_labels.exit_Analytics}</NavigateLink>
                      </li>
                      : null}
                  </>
                }
                <li id='sidebar-biographical-info'>
                  <Link id='link' activeClass='active'
                    className='scrollable spyable' to='biographical-info'
                    spy={true}
                    duration={500}
                    offset={0}
                    smooth={true} onClick={this.toggleMenuInMobile}
                    onSetActive={() => { this.onSetActive('sidebar-biographical-info') }}>
                    {this.props.terms['biographical_information']}
                  </Link>
                </li>
                {this.props.historic_data_chart && this.props.historic_data_chart.biomarkerCount > 0 ?
                  <li id='sidebar-bio-aging-info'>
                    <Link id='link' activeClass='active' className='scrollable spyable' to='biomarkers-of-aging' spy={true}
                      smooth={true}
                      duration={500}
                      offset={0} onClick={this.toggleMenuInMobile}
                      onSetActive={() => { this.onSetActive('sidebar-bio-aging-info') }}>
                      {this.props.terms['Biomarkers of Aging']}
                    </Link>
                  </li> : ''}
                  <li id='sidebar-medication'>
                    <Link id='link' activeClass='active' className='scrollable spyable' to='medication' spy={true}
                      smooth={true}
                      offset={0}
                      duration={500} onClick={this.toggleMenuInMobile}
                      onSetActive={() => { this.onSetActive('sidebar-medication') }}>
                      Medications
                    </Link>
                  </li>
                {this.props.categories && this.props.categories.length ?
                  <li id='sidebar-report-card'>
                    <Link id='link' activeClass='active' className='scrollable spyable' to='health_status_indicators' spy={true}
                      smooth={true}
                      offset={-45}
                      duration={500} onClick={this.toggleMenuInMobile}
                      onSetActive={() => { this.onSetActive('sidebar-report-card') }}>
                      {this.props.terms['Report Card']}
                    </Link>
                  </li> : ''}
                {this.props.categories ? this.props.categories.map(category => <CategoryNav {...category} {...this.props} categoryName={category.name} key={uniformString(category.enName, '_nav')} />) : ''}
                {this.props.interpretations && this.props.interpretations.length > 0 ? <li id='sidebar-interpretations'>
                  <Link id='link' activeClass='active' className='scrollable spyable' to='analytics-interpretations' spy={true}
                    smooth={true}
                    offset={0}
                    duration={500} onClick={this.toggleMenuInMobile}
                    onSetActive={() => { this.onSetActive('sidebar-interpretations') }}>
                    Interpretations
                  </Link>
                </li> : ''}
                <li id='sidebar-treatment-plan'>
                  <Link id='link' activeClass='active' className='scrollable spyable' to='treatment-plan' spy={true}
                    smooth={true}
                    offset={0}
                    duration={500} onClick={this.toggleMenuInMobile}
                    onSetActive={() => { this.onSetActive('sidebar-treatment-plan') }}>
                    {this.props.terms['Treatment Plan']}
                  </Link>
                </li>
                <li id='sidebar-download-report'>
                  <Link id='link' activeClass='active' className='scrollable spyable' to='download_report' spy={true}
                    smooth={true}
                    offset={-45}
                    duration={500} onClick={this.toggleMenuInMobile}
                    onSetActive={() => { this.onSetActive('sidebar-download-report') }}>
                    {this.props.terms['download_report']}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div > : <div></div>
    );
  }
}

import React, { Component } from 'react';

import FormControl from '../../../components/FormControls';
import labels from '../../../config/localization';
import MaskedInput from 'react-text-mask'
import constants from '../../../config/constants'
const stylesClasses = {
    labelClass: 'col-lg-2',
    inputClass: 'col-lg-7'
}
class CreditForm extends Component {
    componentDidMount() {
        if (this.creditsForm && this.creditsForm.inputEntry)
            this.creditsForm.inputEntry.focus()
    }
    render() {
        return (
            <>
                <div className="credits-form-section alert alert-info mt-4">
                    <p>{labels.messages.credit_info_1}</p><br />
                    <p>{labels.messages.credit_info_2}</p><br />
                    <p>{labels.messages.credit_info_3}</p>
                </div>
                <div className="col-lg-12 d-flex justify-center mt-5">
                    <a href="https://physioage.recurly.com/subscribe/reports" target="_blank"><FormControl text={labels.proceed_to_add_credits} type='button' btnType={constants.notification_type.success} /></a>
                </div>
            </>
        )
    }
}
export default CreditForm
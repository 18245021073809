import { connect } from 'react-redux';
import CreditTransactions from '../components/CreditTransactions';
import { actionCreators } from '../modules/credittransactions';
import {
    setBreadCrumbs, setPageTitle,
    toggleNotification, noHeaderLoading,
    navigatePatients, setCreditsCaution
} from '../../../reducers/global-reducer';

const mapStateToProps = (state) => ({
    ...state.elk,
    isLoading: state.global.isLoading,
    isParentContainer: state.global.isParentContainer,
    isSmallScreen: state.global.isSmallScreen,
    isVerySmallScreen: state.global.isVerySmallScreen,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify
})

const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    noHeaderLoading,
    navigatePatients,
    setCreditsCaution
}


export default connect(mapStateToProps, mapDispatchToProps)(CreditTransactions)
import React, { Component } from 'react';
import labels from '../../config/localization';
import './session-expire-styles.scss';
import FormControl from '../FormControls';
import constants from '../../config/constants';

class SessionExpire extends Component {
    render() {
        return (
            <div className='card page-not-found'>
                <h2>{labels.pagenotfound.session_expired}</h2>
                <FormControl type='button' btnType={constants.notification_type.success} text={labels.pagenotfound.redirect_label} onClick={() => this.props.history.push('/login')} />
            </div>
        )
    }
}
export default SessionExpire;
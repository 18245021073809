
import { toggleNotification, toggleLoading, handleErrors, handleStatusCodes } from '../../../reducers/global-reducer.js';
import axios from '../../../lib/axios.js';
import API_URL from '../../../config/api.js';
import constants from '../../../config/constants.js';
import messages from '../../../config/messages.js';
import labels from '../../../config/localization.js';
import { isFirefox, isIE } from '../../../utils/download-utils.js';

const SET_PROPS = 'SET_PROPS';

function getCurrentManual(category) {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentmanual: null
            }
        })
        axios.get(`${API_URL.CURRENT_MANUAL_URL}?category=${category}`).then(response => {
            dispatch(toggleLoading(false))
            if (typeof (response.data) == constants.object) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentmanual: response.data
                    }
                })
            } else if (typeof (response.data) == constants.string) {
                window.scrollTo(0, 0)
                dispatch(toggleNotification(true, constants.notification_type.error, '', [response.data], false))
            }
        }).catch(errors => {
            dispatch(handleErrors(errors))

        })
    }
}
function getVideos() {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                videos: constants.videos
            }
        })
        dispatch(toggleLoading(false))
    }
}
function getAllManuals(category) {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch(toggleLoading(true))
        axios.get(`${API_URL.ALL_MANUALS_URL}?category=${category}`).then(response => {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    manuals: response.data
                }
            })
            if (!response.data || !response.data.length || constants.status_codes.nocontent == response.status) {
                dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.no_manuals_exist], false))
                window.scrollTo(0, 0)
            }
        }).catch(errors => {
            dispatch(handleErrors(errors))

        })
    }
}
function helpInput(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentDoc = getState() && getState().help ? getState().help.currentDoc : {}
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc: { ...currentDoc, [eve.target.id]: eve.target.value }
            }
        })
    }
}
function getAvatarType(avatarContent) {
    if (avatarContent)
        return avatarContent.split(',')[0].split(';')[0].split(':')[1]
    else return ''
}
function postFile(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        let doctype = uploadedFile.type
        if (uploadedFile) {
            //todo: help file validation
            if (constants.valid_doc_types.indexOf(doctype) != -1) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    let avatarContent = e.target.result
                    let currentDoc = getState().help.currentDoc ? getState().help.currentDoc : {}
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            currentDoc: Object.assign({}, currentDoc,
                                {
                                    helpFile: avatarContent.split(',')[1],
                                    contentType: doctype ? doctype : getAvatarType(avatarContent),
                                    name: uploadedFile.name,
                                    size: uploadedFile.size
                                })
                        }
                    })
                }
                reader.readAsDataURL(eve.target.files[0]);
            }
            else {
                dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.help_document_validation], false))
            }
        }
    }
}
function deleteManual(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(toggleNotification(false))
        let deleteId = getState() && getState().help ? getState().help.deleteId : 0
        dispatch({
            type: SET_PROPS,
            payload: {
                isDeleteModal: getState() && getState().help ? !getState().help.isDeleteModal : false
            }
        })
        axios.delete(`${API_URL.HELP_URL}/${deleteId}`).then(response => {
            dispatch(getAllManuals(getState().help.modalCaregory))
            getState().help.modalCaregory == constants.biomarker ?
                dispatch(getCurrentManual(constants.biomarker)) : dispatch(getCurrentManual(constants.user))
            // history.push('/help/allmanuals')
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [messages.help_add_success]
                , false))
        }).catch(errors => {
            dispatch(handleErrors(errors))
        })
    }
}
function launchDeleteModal(id, category) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                deleteId: id,
                isDeleteModal: getState() && getState().help ? !getState().help.isDeleteModal : false,
                modalCaregory: category
            }
        })
        dispatch(toggleLoading(false))
    }
}
function uploadDocument(history, category) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentDoc = { ...getState().help.currentDoc }
        if (category == "usermanual") {
            category = constants.user
        } else {
            category = constants.biomarker
        }
        if (currentDoc.helpFile && currentDoc.version) {
            axios.post(API_URL.HELP_URL, { ...getState().help.currentDoc, category: category }).then(response => {
                dispatch(toggleLoading(false))
                dispatch(getAllManuals(category))
                if (category == constants.biomarker) {
                    history.push('/help/allmanuals')
                } else {
                    history.push('/help/usermanuals')
                }
                dispatch(toggleNotification(true, constants.notification_type.success, '',
                    [messages.document_upload_success]
                    , false))

            }).catch(errors => {
                dispatch(handleErrors(errors))
                dispatch(handleStatusCodes(errors))

            })
        } else {
            dispatch(toggleLoading(false))
            let errorMsg = []
            if (!currentDoc.helpFile) { errorMsg.push(labels.help_page_labels.helpFile_validation) }
            if (!currentDoc.version) { errorMsg.push(labels.help_page_labels.version_validation) }
            window.scrollTo(0, 0)
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                errorMsg
                , false))
        }
    }
}
function dataURIToBlob(dataURI, callback) {

    var binStr = atob(dataURI.split(',')[1]),
        len = binStr.length,
        arr = new Uint8Array(len),
        mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    for (var i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
    }

    return new Blob([arr], {
        type: mimeString
    });

}

function downloadHelpFile(id) {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        dispatch(toggleNotification(false))
        if (id) {
            axios.get(API_URL.DOWNLOAD_HELP_MANUAL, { params: { id } }).then(response => {
                dispatch(toggleLoading(false))
                let manual = response.data.value
                const downloadLink = document.createElement('a');
                downloadLink.download = manual.name;

                if (isIE()) {
                    var blob = dataURIToBlob(`data:${manual.contentType};base64,${manual.helpFileContent}`);
                    if (window.navigator.msSaveBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, `${manual.name}.pdf`);//help file must be pdf
                    }
                } else if (isFirefox()) {
                    var url = `data:${manual.contentType};base64,${manual.helpFileContent}`
                    downloadLink.href = url;
                    downloadLink.className = 'hidden';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    window.addEventListener('focus', e => { setTimeout(() => downloadLink.remove(), 300); URL.revokeObjectURL(downloadLink.href) }, { once: true });
                } else {
                    var blob = dataURIToBlob(`data:${manual.contentType};base64,${manual.helpFileContent}`);
                    var url = URL.createObjectURL(blob);
                    downloadLink.href = url;
                    downloadLink.click();
                    downloadLink.onclick = function () {
                        requestAnimationFrame(function () {
                            setTimeout(() => {
                                URL.revokeObjectURL(url);
                                downloadLink.remove()
                            }, 1000);
                        })
                    };
                }
            }).catch(error => {
                dispatch(handleErrors(error))
            })
        } else {
            dispatch(toggleLoading(false))
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [labels.help_page_labels.invalid_file]
                , false))
        }
    }
}

function addManual(history, category) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: { currentDoc: {} }
        })
        dispatch(toggleNotification(false))
        if (category == constants.biomarker) {
            history.push('/help/manualform?type=biomarkermanual')
        }
        else {
            history.push('/help/manualform?type=usermanual')
        }
    }
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}
const initialState = {
    currentmanual: null,
    manuals: [],
    currentDoc: {},
    isDeleteModal: false
}
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export const actionCreators = {
    getCurrentManual,
    getAllManuals,
    helpInput,
    postFile,
    uploadDocument,
    deleteManual,
    launchDeleteModal,
    addManual,
    getVideos,
    downloadHelpFile
}
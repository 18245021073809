import React from 'react';
import PropTypes from 'prop-types';
import NoumenonDescription from '../containers/NoumenonDescriptionContainer.js';
import Recommendations from '../containers/RecommendationBlockContainer';
import labels from '../../../config/localization.js';
import constants from '../../../config/constants';
import { getRouteStatus } from '../../../utils/parseJWT.js';

export default class BiomarkerDrawerComponent extends React.Component {
  static propTypes = {
    datum: PropTypes.object,
  };
  constructor(props) {
    super(props);
  }
  renderCloseControl() {
    return (
      <div className='drawer-controls'>
        <div className='icon sm fill'>
          <button className='button-close' aria-label='Close Account Info Modal Box' onClick={() => {
            // if (window.location && window.location.hash && (window.history.length <= 3 || localStorage.getItem('canOpen') || getRouteStatus())) {
            //   // window.history.back()
            //   localStorage.removeItem('canOpen')
            //   this.props.history.replace({ ...window.location, hash: '' })
            // }
            // else if (window.location && window.location.hash) {
            //   window.history.back()
            //   // this.props.closeSideBar(this.props.history)
            // }
            this.props.closeSideBar(this.props.history);
            // this.props.closeSideBarOnClose()

          }}>&times;</button>
        </div>
      </div>
    );
  }

  renderHeading() {
    return (
      <div className='drawer-heading'>
        <div className='item-detail'>
          <div className='meta'>
            <div className='drawer-category'>{labels.analytics_labels.biomarkers_label}</div>
            <div className='drawer-title'>{this.props.cultureLabel}</div>
          </div>
        </div>
      </div>
    );
  }

  showDescription() {
    return (<NoumenonDescription {...this.props} />);
  }
  renderHistoricData() {
    return (
      <div className='chart-content'>
        {/* <HistoricDataKey noumenon_name={constants.biographical_labels.age_label_lowercase[this.props.ages]} should_render_age_line={true} />
          <HistoricDataChart noumenon_name={constants.biographical_labels.age_label_lowercase[this.props.ages]} historic_data={this.props.historic_data_chart ? this.props.historic_data_chart[constants.biographical_labels.historic_data_labels[this.props.ages]] : ''} /> */}
      </div>
    );
  }

  actuallyRender() {
    const { store } = this.context;
    return (
      <React.Fragment>
        <div id='drawer' className={`drawer ${!this.props.isSideBarActive ? 'drawer-closed' : this.props.isSideBarActive ? 'open-drawer' : 'drawer-closed'} `}>
          {this.renderCloseControl()}
          <div className='drawer-body' id='drawer-body'>
            {this.renderHeading()}
            {/* {this.renderHistoricData()} */}
            <div className='drawer-content-data'>
              <div className='padded'>
                <Recommendations drawerType={this.props.drawerType} {...this.props} />
              </div>
            </div>
          </div>
        </div>
        <div className={`${this.props.isSideBarActive ? 'open-drawer ' : ''}drawer-shade`}></div>
      </React.Fragment>
    )
  }

  render() {
    if (this.props.historic_data_chart) {
      return (this.actuallyRender());
    }
    else {
      return (null);
    }
  }
}

export default[
    {
      "id": 119955,
      "visitId": 10551,
      "panelName": "Vitals",
      "suitName": "Vitals",
      "panelId": 12,
      "orderStatus": "filled",
      "position": 1,
      "panelPosition": 9,
      "resultsReceived": "2019-12-23T23:42:13",
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 1,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119948,
      "visitId": 10551,
      "panelName": "seca",
      "suitName": "Body Composition",
      "panelId": 58,
      "orderStatus": "pending",
      "position": 2,
      "panelPosition": null,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119951,
      "visitId": 10551,
      "panelName": "InBody 570",
      "suitName": "Body Composition",
      "panelId": 32,
      "orderStatus": "filled",
      "position": 2,
      "panelPosition": 21,
      "resultsReceived": "2019-12-23T23:42:12",
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 1,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119943,
      "visitId": 10551,
      "panelName": "Cutometer MPA 580",
      "suitName": "Skin Elasticity",
      "panelId": 3,
      "orderStatus": "filled",
      "position": 3,
      "panelPosition": 17,
      "resultsReceived": "2019-12-23T23:42:12",
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 1,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119949,
      "visitId": 10551,
      "panelName": "Soterogram",
      "suitName": "Arterial Stiffness",
      "panelId": 59,
      "orderStatus": "pending",
      "position": 4,
      "panelPosition": null,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119942,
      "visitId": 10551,
      "panelName": "Sphygmocor XCEL",
      "suitName": "Arterial Stiffness",
      "panelId": 26,
      "orderStatus": "filled",
      "position": 4,
      "panelPosition": 32,
      "resultsReceived": "2019-12-23T23:42:13",
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 1,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119952,
      "visitId": 10551,
      "panelName": "IQSpiro Spirometer",
      "suitName": "Lung Function",
      "panelId": 40,
      "orderStatus": "filled",
      "position": 5,
      "panelPosition": 1,
      "resultsReceived": "2019-12-23T23:42:12",
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 1,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119941,
      "visitId": 10551,
      "panelName": "EasyOne Air Spirometer",
      "suitName": "Lung Function",
      "panelId": 46,
      "orderStatus": "pending",
      "position": 5,
      "panelPosition": 6,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119947,
      "visitId": 10551,
      "panelName": "Jamar Dynamometer",
      "suitName": "Handgrip",
      "panelId": 11,
      "orderStatus": "pending",
      "position": 6,
      "panelPosition": 22,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119937,
      "visitId": 10551,
      "panelName": "CNS Vital Signs",
      "suitName": "Cognitive Function",
      "panelId": 4,
      "orderStatus": "filled",
      "position": 7,
      "panelPosition": 14,
      "resultsReceived": "2019-12-23T23:42:12",
      "lastResultsReceived": "2019-12-23T23:42:12",
      "archived": false,
      "resultCount": 2,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119939,
      "visitId": 10551,
      "panelName": "UCLA Immune Function Panel",
      "suitName": "Immune Function",
      "panelId": 6,
      "orderStatus": "filled",
      "position": 8,
      "panelPosition": 11,
      "resultsReceived": "2019-12-23T23:42:13",
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 1,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119940,
      "visitId": 10551,
      "panelName": "Repeat Diagnostics Telomere Lengths",
      "suitName": "Telomeres",
      "panelId": 5,
      "orderStatus": "filled",
      "position": 9,
      "panelPosition": 30,
      "resultsReceived": "2019-12-23T23:42:13",
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 1,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119946,
      "visitId": 10551,
      "panelName": "Bone Densitometer",
      "suitName": "Bone Density",
      "panelId": 8,
      "orderStatus": "pending",
      "position": 10,
      "panelPosition": 13,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119945,
      "visitId": 10551,
      "panelName": "Carotid Intima-Media Thickness",
      "suitName": "CIMT",
      "panelId": 9,
      "orderStatus": "pending",
      "position": 11,
      "panelPosition": 16,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119935,
      "visitId": 10551,
      "panelName": "Generic Metric Bloodwork",
      "suitName": "Bloodwork",
      "panelId": 50,
      "orderStatus": "pending",
      "position": 12,
      "panelPosition": null,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119936,
      "visitId": 10551,
      "panelName": "Generic Bloodwork",
      "suitName": "Bloodwork",
      "panelId": 71,
      "orderStatus": "pending",
      "position": 12,
      "panelPosition": null,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119954,
      "visitId": 10551,
      "panelName": "Quest Labs",
      "suitName": "Bloodwork",
      "panelId": 13,
      "orderStatus": "filled",
      "position": 12,
      "panelPosition": 28,
      "resultsReceived": "2019-12-23T23:42:12",
      "lastResultsReceived": "2019-12-23T23:42:12",
      "archived": false,
      "resultCount": 3,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119944,
      "visitId": 10551,
      "panelName": "Cardio Coach VO2 Max",
      "suitName": "Oxygen Consumption",
      "panelId": 10,
      "orderStatus": "pending",
      "position": 13,
      "panelPosition": 15,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119953,
      "visitId": 10551,
      "panelName": "Patient Survey",
      "suitName": "Survey",
      "panelId": 39,
      "orderStatus": "filled",
      "position": 15,
      "panelPosition": 2,
      "resultsReceived": "2019-12-23T23:42:12",
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 1,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119950,
      "visitId": 10551,
      "panelName": "Imatron EBT",
      "suitName": "Coronary Calcium",
      "panelId": 51,
      "orderStatus": "pending",
      "position": 16,
      "panelPosition": null,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    },
    {
      "id": 119938,
      "visitId": 10551,
      "panelName": "Zymo Research DNAge",
      "suitName": "DNA Methylation",
      "panelId": 48,
      "orderStatus": "pending",
      "position": 17,
      "panelPosition": null,
      "resultsReceived": null,
      "lastResultsReceived": null,
      "archived": false,
      "resultCount": 0,
      "patientId": null,
      "enableLoader": false,
      "data": null
    }
  ]
import React from 'react';
import ProviderAvatar from './ProviderAvatarComponent';
import sprite from '../../../assets/images/sprite_base.svg'
import labels from '../../../config/localization';

export default class MyRecommendations extends React.Component {
  renderProviderPhoto() {
    return (
      <ProviderAvatar {...this.props} />
    );
  }

  renderPlaceholder() {
    return (
      <div className='placeholder'>
        <div className='title'>{labels.analytics_labels.no_recommandations_made}</div>
        <div className='description'>{labels.analytics_labels.select_items_label}</div>
      </div>
    );
  }

  renderRec(rec, label) {
    return (
      rec ?
        (rec).map((s) => (
          <div className='item' key={'my_recs_' + s['body']}>
            {this.renderProviderPhoto()}
            <div className='details'>
              <div className='name'>{`${this.props.currentProvider.firstname} ${this.props.currentProvider.lastname}`}</div>
              <div className='summary'>{s['body']}</div>
            </div>
            {this.props.role != 'patient' && !s.isStopped ?
              <div className='icon-group'>
                {s.firstRecommendationVisit == this.props.presentPatient.latestVisitId || this.props.isDemo ?
                  <div className='icon md fill clickable refute' onClick={() => this.props.deleteRecommendations(s.id, label)} >
                    <svg aria-hidden='true' title='Delete'>
                      {/* <use xlinkHref={`${sprite}#trash`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
                      <use xlinkHref="#trash" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                      <symbol id="trash" viewBox="0 0 16 16">
                        <path d="M2,6v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V6H2z"></path>
                        <path d="M12,3V1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H0v2h16V3H12z M10,3H6V2h4V3z"></path>
                      </symbol>

                    </svg>
                  </div> : ''}
                <div className='icon md fill clickable edit' onClick={() => this.props.updateRecommendations(s.id)}>
                  <svg aria-hidden='true' title='Pencil'>
                    {/* <use xlinkHref={`${sprite}#pencil`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
                    <use xlinkHref="#pencil" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                    <symbol id="pencil" viewBox="0 0 486.219 485.22">
                      <path d="M467.476,146.438l-21.445,21.455L317.35,39.23l21.445-21.457c23.689-23.692,62.104-23.692,85.795,0l42.886,42.897 C491.133,84.349,491.133,122.748,467.476,146.438z M167.233,403.748c-5.922,5.922-5.922,15.513,0,21.436 c5.925,5.955,15.521,5.955,21.443,0L424.59,189.335l-21.469-21.457L167.233,403.748z M60,296.54c-5.925,5.927-5.925,15.514,0,21.44 c5.922,5.923,15.518,5.923,21.443,0L317.35,82.113L295.914,60.67L60,296.54z M338.767,103.54L102.881,339.421 c-11.845,11.822-11.815,31.041,0,42.886c11.85,11.846,31.038,11.901,42.914-0.032l235.886-235.837L338.767,103.54z M145.734,446.572c-7.253-7.262-10.749-16.465-12.05-25.948c-3.083,0.476-6.188,0.919-9.36,0.919 c-16.202,0-31.419-6.333-42.881-17.795c-11.462-11.491-17.77-26.687-17.77-42.887c0-2.954,0.443-5.833,0.859-8.703 c-9.803-1.335-18.864-5.629-25.972-12.737c-0.682-0.677-0.917-1.596-1.538-2.338L0,485.216l147.748-36.986 C147.097,447.637,146.36,447.193,145.734,446.572z"></path>
                    </symbol>
                  </svg>
                </div>
                {s.firstRecommendationVisit != this.props.presentPatient.latestVisitId && !this.props.isDemo ?
                  <div className="icon md fill clickable stop" onClick={() => {
                    this.props.toggleStopRecommendationPopup(true, s.id);
                  }}>
                    <i class="fa fa-stop-circle" style={{ fontSize: "21px", lineHeight: "1.9rem" }}></i>
                  </div> : ''}
              </div> : ''}
          </div>
        )) : ''
    )
  }
  renderEditControls(rec) {
    //ToDo
    if (this.props.role != 'patient') {
      return (
        rec ?
          rec.map(s => (
            <div className='icon-group'>
              <div className='icon md fill clickable refute' onClick={() => this.props.deleteRecommendations(s.id)} >
                <svg aria-hidden='true' title='Delete'>
                  <use xlinkHref={`${sprite}#trash`} xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                </svg>
              </div>
              <div className='icon md fill clickable edit' onClick={() => this.props.updateRecommendations(s.id)}>
                <svg aria-hidden='true' title='Pencil'>
                  <use xlinkHref={`${sprite}#pencil`} xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                </svg>
              </div>
            </div>
          )) : '');
    }
  }

  actuallyRender() {
    if (this.props.drawerType == 'biomarker' && this.props.recommendations) {
      return (
        <div>
          {this.renderRec(this.props.recommendations ?
            this.props.recommendations.filter(rec => rec.indicators.indexOf(this.props.ages) != -1) : '', this.props.ages)}
          {(this.props.recommendations.filter(rec => rec.indicators.indexOf(this.props.ages) != -1).map(s => s.indicators[0]).indexOf(this.props.ages)) != -1 ?
            this.props.role != "patient" ?
              <div className='item' key='my_recs_new'>
                <div className='details'>
                  <div className='name'></div>
                  <div className='summary'></div>
                </div>
                <div className='icon-group'>
                  <div className='icon md fill clickable' onClick={() => this.props.newRecommendation()}>
                    <svg aria-hidden='true' title='New'>
                      {/* <use xlinkHref={`${sprite}#plus`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
                      <use xlinkHref="#plus" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                      <symbol id="plus" viewBox="0 0 16 16" >
                        <path d="M15,7 L9,7 L9,1 C9,0.4 8.6,0 8,0 C7.4,0 7,0.4 7,1 L7,7 L1,7 C0.4,7 0,7.4 0,8 C0,8.6 0.4,9 1,9 L7,9 L7,15 C7,15.6 7.4,16 8,16 C8.6,16 9,15.6 9,15 L9,9 L15,9 C15.6,9 16,8.6 16,8 C16,7.4 15.6,7 15,7 Z"></path>
                      </symbol>
                    </svg>
                  </div>
                </div>
              </div> : " " : this.renderPlaceholder()}
        </div>
      );
    }
    else if (this.props.drawerType == 'category') {
      return (
        this.props.currentCategory ?
          <div>
            {this.renderRec(this.props.recommendations ?
              this.props.recommendations.filter(rec => rec.indicators.indexOf(this.props.currentCategory.key) != -1) : '', this.props.currentCategory.key)}
            {(this.props.recommendations.filter(rec => rec.indicators.indexOf(this.props.currentCategory.key) != -1).map(s => s.indicators[0]).indexOf(this.props.currentCategory.key)) != -1 ?
              this.props.role != "patient" ?
                <div className='item' key='my_recs_new'>
                  <div className='details'>
                    <div className='name'></div>
                    <div className='summary'></div>
                  </div>
                  <div className='icon-group'>
                    <div className='icon md fill clickable' onClick={() => this.props.newRecommendation()}>
                      <svg aria-hidden='true' title='New'>
                        <use xlinkHref="#plus" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                        <symbol id="plus" viewBox="0 0 16 16" >
                          <path d="M15,7 L9,7 L9,1 C9,0.4 8.6,0 8,0 C7.4,0 7,0.4 7,1 L7,7 L1,7 C0.4,7 0,7.4 0,8 C0,8.6 0.4,9 1,9 L7,9 L7,15 C7,15.6 7.4,16 8,16 C8.6,16 9,15.6 9,15 L9,9 L15,9 C15.6,9 16,8.6 16,8 C16,7.4 15.6,7 15,7 Z"></path>
                        </symbol>
                      </svg>
                    </div>
                  </div>
                </div> : "" : this.renderPlaceholder()}
          </div> : ''
      )
    }
    else if (this.props.drawerType == 'section') {
      return (
        this.props.currentSection ?
          <div>
            {this.renderRec(this.props.recommendations ?
              this.props.recommendations.filter(rec => rec.indicators.indexOf(this.props.currentSection.key) != -1) : '', this.props.currentSection.key)}
            {(this.props.recommendations.filter(rec => rec.indicators.indexOf(this.props.currentSection.key) != -1).map(s => s.indicators[0]).indexOf(this.props.currentSection.key)) != -1 ?
              this.props.role != "patient" ?
                <div className='item' key='my_recs_new'>
                  <div className='details'>
                    <div className='name'></div>
                    <div className='summary'></div>
                  </div>
                  <div className='icon-group'>
                    <div className='icon md fill clickable' onClick={() => this.props.newRecommendation()}>
                      <svg aria-hidden='true' title='New'>
                        <use xlinkHref="#plus" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                        <symbol id="plus" viewBox="0 0 16 16" >
                          <path d="M15,7 L9,7 L9,1 C9,0.4 8.6,0 8,0 C7.4,0 7,0.4 7,1 L7,7 L1,7 C0.4,7 0,7.4 0,8 C0,8.6 0.4,9 1,9 L7,9 L7,15 C7,15.6 7.4,16 8,16 C8.6,16 9,15.6 9,15 L9,9 L15,9 C15.6,9 16,8.6 16,8 C16,7.4 15.6,7 15,7 Z"></path>
                        </symbol>
                      </svg>
                    </div>
                  </div>
                </div> : "" : this.renderPlaceholder()}
          </div> : ''
      )
    }
    else {
      return (
        this.props.datum ?
          <div>
            {this.renderRec(this.props.recommendations ?
              this.props.recommendations.filter(rec => rec.indicators.indexOf(this.props.datum.code) != -1) : '', this.props.datum.code)}
            {(this.props.recommendations.filter(rec => rec.indicators.indexOf(this.props.datum.code) != -1).map(s => s.indicators[0]).indexOf(this.props.datum.code)) != -1 ?
              this.props.role != "patient" ?
                <div className='item' key='my_recs_new'>
                  <div className='details'>
                    <div className='name'></div>
                    <div className='summary'></div>
                  </div>
                  <div className='icon-group'>
                    <div className='icon md fill clickable' onClick={() => this.props.newRecommendation()}>
                      <svg aria-hidden='true' title='New'>
                        <use xlinkHref="#plus" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                        <symbol id="plus" viewBox="0 0 16 16" >
                          <path d="M15,7 L9,7 L9,1 C9,0.4 8.6,0 8,0 C7.4,0 7,0.4 7,1 L7,7 L1,7 C0.4,7 0,7.4 0,8 C0,8.6 0.4,9 1,9 L7,9 L7,15 C7,15.6 7.4,16 8,16 C8.6,16 9,15.6 9,15 L9,9 L15,9 C15.6,9 16,8.6 16,8 C16,7.4 15.6,7 15,7 Z"></path>
                        </symbol>
                      </svg>
                    </div>
                  </div>
                </div> : "" : this.renderPlaceholder()}
          </div> : ''
      )
    }
  }
  render() {
    if (this.props.recommendations ? this.props.recommendations.length == 0 : 1)
      return (this.renderPlaceholder());
    else
      return (this.actuallyRender());
  }
}

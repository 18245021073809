import labels from './localization'
import constants from './constants'
export default [

    {
        display: labels.header_labels.help,
        child: [
            {
                display: labels.header_labels.account_setup,
                navpath: constants.account_setup_doc_url,
                roles: ['admin', 'caregiver']
            },
            {
                display: labels.header_labels.user_guide,
                navpath : constants.user_guide_doc_URL,
                roles: ['admin', 'caregiver']
            },
            // {
            //     display: labels.header_labels.user_manuals,
            //     path: 'help/usermanuals',
            //     roles: ['admin']
            // },
            {
                display: labels.header_labels.current_manual,
                navpath: constants.biomarker_manual_doc_URL,
                roles: ['admin', 'caregiver']
            },
            {
                display: labels.header_labels.biomarker_interpretation,
                navpath: constants.biomarker_interpretation_doc_URL,
                roles: ['admin', 'caregiver']
            },
            // {
            //     display: labels.header_labels.all_manuals,
            //     path: 'help/allmanuals',
            //     roles: ['admin']
            // },
            {
                display: labels.header_labels.videos,
                path: 'help/videos',
                roles: ['admin', 'caregiver']
            },
            {
                display: labels.header_labels.schedule_a_call,
                navpath : constants.call_with_jerry,
                roles: ['admin', 'caregiver']
            }
        ],
        roles: ['admin', 'caregiver']
    },
    {
        display: labels.header_labels.ops,
        child: [
            {
                display: labels.header_labels.active_site_report,
                path: 'active-site-report'
            },
            {
                display: labels.header_labels.providers,
                path: 'providers/list'
            },
            {
                display: labels.header_labels.caregivers,
                path: 'caregivers/list'
            },
            {
                display: labels.header_labels.sites,
                path: 'sites/list'
            },
            {
                display: labels.header_labels.users,
                path: 'users/list'
            },
            {
                display: labels.header_labels.elk,
                path: 'credit-transaction/list'
            },
            {
                display: labels.header_labels.billing_summary,
                path: 'billingsummary'
            },
            {
                display: labels.header_labels.message_routing,
                path: 'messagerouting/list'
            },
            {
                display: labels.header_labels.inbox,
                path: 'messages/inbox'
            },
            {
                display: labels.header_labels.oddities,
                path: 'oddities/list'
            },
            {
                display: labels.header_labels.corrections,
                path: 'corrections'
            },
            {
                display: labels.header_labels.feedback,
                path: 'feedback_list'
            }
        ],
        roles: ['admin']
    },
    {
        display: labels.header_labels.data,
        child: [
            {
                display: labels.header_labels.explore_noumena,
                path: 'data/noumena'
            },
            {
                display: labels.header_labels.volume_report,
                path: 'data/volumereport'
            },
            {
                display: labels.header_labels.vadr,
                path: 'data/vadr'
            },
            {
                display: 'Sample Aggregate Age Charts',
                path: 'data/sample_aggregate'
            },
            {
                display: 'Sample Report Card Grades',
                path: 'data/sample_report'
            },
            {
                display: 'Sample Spectra',
                path: 'data/sample_spectra'
            },

        ],
        roles: ['admin']
    },
    {
        display: labels.header_labels.practice,
        child: [
            {
                display: labels.header_labels.explore_data,
                path: 'data/noumena',
                roles: ['caregiver']
            },
            {
                display: labels.header_labels.download_library,
                path: '',
                roles: ['caregiver'],
                checkSite: true
            },
            {
                display: labels.header_labels.users,
                path: 'users/list',
                roles: ['caregiver']
            },
            {
                display: labels.header_labels.customize_noumena,
                path: '',
                roles: ['caregiver'],
                customizations: true
            }
        ],
        roles: ['caregiver']
    }
]
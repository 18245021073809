import React, { Component } from 'react';
import moment from 'moment';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls';
import { exportTableToCSV } from '../../../utils/download-csv-table';
import LoadingTemplate from '../../../components/LoadingTemplate';

const reqdates = []
for (let i = 0; i <= 11; i++) {
    reqdates.push(moment(new Date()).subtract(i, 'months').format('M/YYYY'))
}
const reqFields = [
    'Site',
    ...reqdates.reverse(),
    'Total',
    'Credits'
]

class VolumeReport extends Component {
    componentDidMount() {
        this.props.getVolumeReport()
        this.props.setBreadCrumbs([])
        this.props.setPageTitle()
        this.props.hideHeaderSkeleton(true)
        document.title = `${labels.physioage_label}: ${labels.noumena_labels.volume_report}`
    }
    componentWillUnmount() {
        this.props.hideHeaderSkeleton(false)
    }
    render() {
        return (

            <div className='volume-report'>
                <h1>{labels.noumena_labels.volume_report}</h1>
                <FormControl type='button' btnType='primary'
                    className='col-lg-3 col-md-12 col-sm-12 col-xs-12'
                    onClick={() => {
                        this.props.downloadVolumeReport(labels.noumena_labels.volume_report_name)
                    }} text={labels.noumena_labels.download_csv} />
                {this.props.isLoading ? <LoadingTemplate isDetails={true} isTransparent={true} /> :
                    this.props.volumereports ?
                        Object.keys(this.props.volumereports).map(vr =>
                            (
                                <div className='volume-category'>
                                    <h3>{vr} ({this.props.volumereports[vr].length})</h3>
                                    <table className='table table-striped table-responsive table-hover'>
                                        <thead>
                                            <tr>
                                                {reqFields.map(rf => <th>{rf}</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.volumereports[vr] ?
                                                this.props.volumereports[vr].map(site =>
                                                    <tr>
                                                        {reqFields.map(rf => <td className={`${rf == 'Site' ? 'site-value' : ''}`}>{site[rf]}</td>)}
                                                    </tr>
                                                )
                                                :
                                                null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        )
                        : null}
            </div>
        )
    }
}
export default VolumeReport;
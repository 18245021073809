import React, { Component } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import { Link } from 'react-router-dom';
import providerDefault from '../../../assets/images/provider-default.png';
import PageTitle from '../../../components/PageTitle';

class ProviderDetails extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.providerId) {
            this.props.setProviderDetails(this.props.match.params.providerId)
        }
    }
    render() {
        return (
            <>

                <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 pr-4 provider-details-side-panel'>
                    <FormControl type='button' className='mb-2' btnType='primary' text={labels.provider_labels.edit} onClick={() => { this.props.navigateEditProvider(this.props.history, `/providers/edit/${this.props.currentProvider.id}`) }} />
                    <FormControl type='button' className='mb-2' btnType='default' text={labels.provider_labels.providers_list} onClick={() => { this.props.history.push('/providers/list') }} />
                </div>
                <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0 card flex-row provider-details'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <legend className='pt-3'>{labels.provider_labels.image_label}</legend>
                        <img className='provider-details-image' alt={this.props.currentProvider
                            && this.props.currentProvider.avatarContent ? this.props.currentProvider.avatarFileName : ''}
                            src={this.props.currentProvider
                                && this.props.currentProvider.avatarContent
                                ? `data:${this.props.currentProvider.avatarContentType};base64,${this.props.currentProvider.avatarContent}`
                                : providerDefault} />
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <legend className='pt-3'>{labels.provider_labels.sites_label}</legend>
                        <ul>
                            {this.props.currentProvider && this.props.currentProvider.sites
                                ? this.props.currentProvider.sites.map(s => (
                                    <li><Link to={`/sites/${s.id}`}>{s.name}</Link></li>
                                )) : null}
                        </ul>
                    </div>
                </div>

            </>
        )
    }
}
export default ProviderDetails
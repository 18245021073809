import sprite from '../../../assets/images/sprite_base.svg';
import moment from 'moment';
import Sprite from './SpriteIcon';
import { uniformString, getDateFormat } from '../tools/helpers';
import expandMore from '../../../assets/images/expand_more.svg';
import expandLess from '../../../assets/images/expand_less.svg';
import expandMoreLight from '../../../assets/images/expand_more_light.svg';
import expandLessLight from '../../../assets/images/expand_less_light.svg';
import edit from '../../../assets/images/edit.svg'
import editLight from '../../../assets/images/edit_light.svg'
import delete_icon from '../../../assets/images/delete_red.svg'
import React, {useRef,useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import constants from '../../../config/constants'
import labels from '../../../config/localization';
import _ from 'lodash';
import Sortable from 'sortablejs'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { Height } from '@material-ui/icons';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

function ProblemRow(props) {
  useEffect(() => {
    // componentDidMount //
  return () => {
    // componentWillUnmount() //
  }
}, [])
    
    let [expanded, setExpanded] = React.useState(false);
    let toggleExpansion = () => { setExpanded(!expanded) };
    let renderToggle = (expanded) => {
        if (expanded) {
          return (
            <img className='' src={props.theme == "light-theme" ? expandLess : expandLessLight}></img>
          );
        }
        else {
          return (
            <img className='' src={props.theme == "light-theme" ? expandMore : expandMoreLight}></img>
          );
        }
    }
    let editIcon = () =>{
      return (props.theme == "light-theme" ? edit : editLight)
    }

    let fadeProblemRow = (rec) => {
        // if (rec.endedAt && moment(Date()).format("YYYY-MM-DD") > moment(rec.endedAt).format("YYYY-MM-DD")) {
        //   return 'ended'
        // }
        // else {
        //   return 'active'
        // }
        // return 'active'
    }
    let renderSubheadingClassName = (rec, props) => {
      var isCurrent;
        if ( props.presentPatient &&  props.presentPatient.latestVisitId) {
            if (rec.visitID == props.presentPatient.latestVisitId) {
              isCurrent = true;
            }
            else {
              isCurrent = false;
            }
        }
      return isCurrent
    }  

    let renderBodyRow = (pro) => {
        
        return (
          <div className={`jw-recommendation-card`}>
          
            <div className={`jw-recommendation-content row no-gutters pr-2`} >
                <div className={` ${props.problemType == "stopped" ? 'col-lg-10 col-md-10 col-sm-10 col-7' : 'col-lg-11 col-md-10 col-sm-10 col-10'} align-self-center pl-3 pr-0`}>
                    <div className={` d-flex mr-2 ${expanded ? 'recommendation-body-text-expanded' : 'recommendation-body-text'}`}>
                      {pro.code}
                      { renderSubheadingClassName(pro, props) ? 
                        <span className={`new-label mt-1 ml-2`}>
                          <span className='new-text'>New</span>
                        </span>
                    : "" }
                    </div> 
                </div>
                <div className={`jw-recommendation-toggle d-flex justify-content-end ${props.problemType == "stopped" ? 'col-lg-2 col-md-2 col-sm-2 col-5' : 'col-lg-1 align-self-center col-sm-1 col-md-1 col-1'} ${expanded ? '' : ''}`}>
                    <div className='d-flex'>
                      { expanded ? renderActions(pro) : '' }
                      <div className={`align-self-center`}>    
                        <div className='expaned-div'>
                            {renderToggle(expanded)}
                        </div>    
                      </div>
                    </div>
                </div>
            </div>   
            <div className='pr-15 pl-15'>
                {expanded ? renderDetailsRow(props.problem) : null}
            </div>
          </div>
        );
    }
    let renderActions = (pro) =>{
            { return ( !pro.isStopped ?
                props.isDemo || props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)
                ? 
                <div className="d-flex">
                      <div  className="mr-2" title={`Edit Problem`} onClick={() => {
                        props.toggleProblemModal(true);
                        props.updateProblem(pro.id)}}>
                        <img src={editIcon()}/>
                      </div>
                     { 
                        <img className='mr-3' onClick={() => props.toggleDeleteProblemPopup(true, pro.id, pro)} src={delete_icon}></img>
                     }
                </div> : ''
                : ''
                )
            }
    }

    let  renderDetailsRow = (pro) =>  {
        // var flag = true
        return (
          <div className="jw-recommendation-details" key={pro.id + '-details'} id={pro.id + '-details'}>
            { 
                constants.problemFormField.map((field) => {
                    return (
                        getfield(field, pro)
                        
                    )
                })
            }
            {getOnSet(pro)}
            {getAbatement(pro)}
            {getNotes(pro)}
            {getRecordedDate(pro)}
        </div>
        );
    }
    
    let getOnSet = (pro) =>{
        if (pro.onSetType && pro.onSetType.trim() && (pro.onSetValue1 ||  pro.onSetValue2) ) {
            return (
              <div className="recommendation-details">
                    <span className='heading-1'>{labels.analytics_labels.onset}: </span> 
                    { pro.onSetType == 'age' && pro.onSetValue1 ?
                        <span className='text-1'> {pro.onSetValue1} years</span> 
                        : pro.onSetType == 'date' && pro.onSetValue1 ? 
                         <span className='text-1'> {getStrongDate(pro.onSetValue1)}</span>
                        :  pro.onSetType == 'period' && (pro.onSetValue1 ||  pro.onSetValue2) ? 
                          <span className='text-1'>
                            { pro.onSetValue1 ? <><span>Started</span> {getStrongDate(pro.onSetValue1)} </> : ''}
                            {pro.onSetValue2 ? <><span>Ended</span> {getStrongDate(pro.onSetValue2)} </> : ''}
                          </span>
                        : 'null'  
                    } 
              </div>
            )
        }
        else {
            return (null);
        }
    }
    let getAbatement = (pro) =>{
        if (pro.abatementType && pro.abatementType.trim() && ( pro.abatementValue1 ||  pro.abatementValue2)) {
            return (

              <div className="recommendation-details">
                    <span className='heading-1'>{labels.analytics_labels.abatement}: </span> 
                    { pro.abatementType == 'age' && pro.abatementValue1 ?
                        <span className='text-1'> {pro.abatementValue1} years</span> 
                        : pro.abatementType == 'date' && pro.abatementValue1 ? 
                         <span className='text-1'>{ getStrongDate(pro.abatementValue1)}</span>
                        :   pro.abatementType == 'period' && ( pro.abatementValue1 ||  pro.abatementValue2) ? 
                            <span className='text-1'>
                              {pro.abatementValue1 ? <><span>Started</span> {getStrongDate(pro.abatementValue1)}</> : ''}
                              {pro.abatementValue2 ? <><span>Ended</span> {getStrongDate(pro.abatementValue2)} </> : ''}
                            </span>
                        : null  
                    }
              </div>
            )
        }
        else {
            return (null);
        }
    }
    let getfield = (field, problem) => {
        if (problem[field] && problem[field].trim()) {
          return (
            <div className="recommendation-details">
              <span className='heading-1'>{labels.analytics_labels[field]}:</span> <span className='text-1'>{problem[field].charAt(0).toUpperCase() + problem[field].slice(1)}</span>
            </div>
          )
        }
        else {
          return (null);
        }
    }

    let getRecordedDate = (pro) => {
        if (pro.recordedDate && pro.recordedDate) {
          return (
            <div className="recommendation-details">
              <span className='heading-1'>{labels.analytics_labels.recordedDate}:</span> <span className='text-1'>{ getStrongDate(pro.recordedDate)}</span>
            </div>
          )
        }
        else {
          return (null);
        }
    }
    let getNotes = (pro) =>{
      if (pro.notes && pro.notes.length > 0) {
        return (
          <div className="recommendation-details">
            <span className='heading-1'>{labels.analytics_labels.notes}</span>
            { pro.notes.map((note, index) => {
               return (<div className='text-1'>{`${index + 1}. `} {note.note}</div>)
             })}
           
          </div>
        )
      }
      else {
        return (null);
      }
    }

    let getStrongDate = (date) =>  {
        return <strong>{(moment(date).format(`${getDateFormat(props.presentPatient.datePreference)} HH:mm`))}</strong>
    }
  
    return (
      <>
          <div onClick={ ()=>{ toggleExpansion()}} className={`recommendation-body ${expanded ? 'pt-2 pb-2 active-card' : ""} ${fadeProblemRow(props.problem)}`}  key={props.problem.id} data-id={props.problem.id} style={(props.loggedInUser &&
            (props.loggedInUser.role == constants.logged_roles.AN
              || props.loggedInUser.role == constants.logged_roles.CG)
            && props.problems.length > 1 && props.problemType == "active" && props.history.location.hash == "#problem_list") ? { cursor: 'move' } : { '-webkit-user-drag': 'none'}}>
            {renderBodyRow(props.problem)}
          </div>
    </>  
    );
}

export default connect()(ProblemRow);

import React, { Component } from 'react';
import { getRoundedValue, IsInRange, getDateFormat, uniformString } from '../../Analytics/tools/helpers';
import moment from 'moment';
import labels from '../../../config/localization';
import constants from '../../../config/constants';

class IndividualReportProblem extends React.Component {
  renderDetailsRow(pro)  {
        // var flag = true
        return (
          <div className="" key={pro.id + '-details'} id={pro.id + '-details'}>
            { 
                constants.problemFormField.map((field) => {
                  return (
                   <div>{ this.getfield(field, pro)}</div>
                  )  
                })
            }
            <div>{this.getOnSet(pro)}</div>
            <div>{this.getAbatement(pro)}</div>
            <div>{this.getNotes(pro)}</div>
            <div>{this.getRecordedDate(pro)}</div>
        </div>
        );
    }
    
    getOnSet(pro){
        if (pro.onSetType && pro.onSetType.trim() && (pro.onSetValue1 ||  pro.onSetValue2)) {
            return (
              <div className="instructions">
                    <strong>{labels.analytics_labels.onset}:</strong> 
                    { pro.onSetType == 'age' && pro.onSetValue1 ?
                        <span className="recommendation-instructions"> {pro.onSetValue1} years</span> 
                        : pro.onSetType == 'date' && pro.onSetValue1 ? 
                         <span className="recommendation-instructions"> Started {this.getStrongDate(pro.onSetValue1)}</span>
                        : pro.onSetType == 'period' && (pro.onSetValue1 ||  pro.onSetValue2) ? 
                          <span className="recommendation-instructions">  
                            { pro.onSetValue1 ? <><span>Started</span> {this.getStrongDate(pro.onSetValue1)}</> : ''} 
                            { pro.onSetValue2 ? <><span>Ended</span> {this.getStrongDate(pro.onSetValue2)}</> : ''} </span>
                        : ''  
                    } 
              </div>
            )
        }
        else {
            return (null);
        }
    }

    getAbatement(pro){
        if (pro.abatementType && pro.abatementType.trim() && (pro.abatementValue1 ||  pro.abatementValue2) ) {
            return (
              <div className="instructions">
                    <strong>{labels.analytics_labels.abatement}:</strong> 
                    { pro.abatementType == 'age' && pro.abatementValue1 ?
                        <span className="recommendation-instructions"> {pro.abatementValue1} years</span> 
                        : pro.abatementType == 'date' && pro.abatementValue1 ? 
                         <span className="recommendation-instructions"> Started { this.getStrongDate(pro.abatementValue1)}</span>
                        :  pro.abatementType == 'period' && (pro.abatementValue1 ||  pro.abatementValue2) ? 
                          <span className="recommendation-instructions">  
                                    { pro.abatementValue1 ? <><span>Started</span> {this.getStrongDate(pro.abatementValue1)}</> : ''} 
                                    { pro.abatementValue2 ? <><span>Ended</span> {this.getStrongDate(pro.abatementValue2)}</> : ''} 
                          </span>
                        : ''  
                    }
              </div>
            )
        }
        else {
            return (null);
        }
    }

    getfield(field, problem) {
        if (problem[field] && problem[field].trim()) {
          return (
            <div className="instructions">
              <strong>{labels.analytics_labels[field]}: </strong> 
              {problem[field].charAt(0).toUpperCase() + problem[field].slice(1)}
            </div>
          )
        }
        else {
          return (null);
        }
    }

    getRecordedDate(pro) {
        if (pro.recordedDate && pro.recordedDate.trim()) {
          return (
            <div className="instructions">
              <strong>{labels.analytics_labels.recordedDate}: </strong> 
              { this.getStrongDate(pro.recordedDate)}
            </div>
          )
        }
        else {
          return (null);
        }
    }

    getNotes(pro){
      if (pro.notes && pro.notes.length > 0) {
        return (
          <div className="instructions">
           <strong>{labels.analytics_labels.notes}</strong>
            { pro.notes.map((note, index) => {
               return (<div className="recommendation-instructions ml-4">{`${index + 1}. `} {note.note}</div>)
             })}
          </div>
        )
      }
      else {
        return (null);
      }
    }
    
    getStrongDate(date) {
      var dateFormat = getDateFormat(this.props.presentPatient && this.props.presentPatient.datePreference ? this.props.presentPatient.datePreference : (this.props.currentVisit && this.props.currentVisit.datePreference ? this.props.currentVisit.datePreference : "mdy"))
      return <strong>{(moment(date).format(`${dateFormat} HH:mm`))}</strong>
    }

    render() {
        return (
            <div className="jw-report-recommendation-card jw-report-recommendation-flex" key={this.props.id}>
                <div className="jw-report-recommendation-content">
                    {this.renderDetailsRow(this.props.problem)}
                </div>
            </div>
        )
    }
}

export default IndividualReportProblem;
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import marked from 'marked';
import html2react from 'html-to-react'
import labels from '../../../config/localization';


function InterpretationComponent(props) {
    useEffect(() => {
        let element = document.getElementsByClassName("heading-1")
        element && element[0] ? element[0].scrollIntoView(0,0) : ""
        props.toggleCategoryDetailsModal(false)
        // componentDidMount //
        return () => {
            // componentWillUnmount() //
        }
    }, [])
    
    const markdown2react = (md) => {
        marked.setOptions({
            renderer: new marked.Renderer(),
            sanitize: true,
            smartLists: true,
        });

        let parser = new html2react.Parser();
        return (parser.parse(marked(md)));
    }
    return (
        <div className='download-library'>
            <div class="row header no-gutters b-bottom">
                <div class="col-lg-9 col-md-8 col-sm-9 col-8">
                    <div class="heading-1">{labels.analytics_labels.interpretations}</div>
                </div>
            </div>
            <div className='interpretation-beta height-100'>
                <div className='height-100'>
                    {props.interpretations && props.interpretations.filter(s => (!(s.sectionName || s.categoryName))).length
                        && props.interpretations.filter(s => (!(s.sectionName || s.categoryName))).map((inpObj, index) => {
                            return (
                                <div class="card interpretation-list">
                                    <div class="card-header">
                                    {labels.analytics_labels.based_on} {inpObj.noumenaList}
                                    </div>
                                    <div class="card-body">
                                        <p class="card-text">{markdown2react(inpObj.interpretation)}</p>
                                        { inpObj.references && inpObj.references.length > 0 ?
                                            <div className='moreInfoReference references'>
                                                <p className='reference-heading'><b>{labels.analytics_labels.references}</b></p>
                                                {
                                                    inpObj.references.map((s, index) => {
                                                        return (<div className='card' key='card-reference'>
                                                            <p className="card-header"><b>{labels.analytics_labels.reference} {index + 1} :</b></p>
                                                            {s.url ?
                                                                <>
                                                                    <p className="card-body url-reference"><b>{labels.analytics_labels.url} : </b><a target="_blank" href={s.url}>{s.url}</a></p>
                                                                </>
                                                                : ''}
                                                            {s.internalNotes ?
                                                                <>
                                                                    <p className="card-body"><b>{labels.analytics_labels.internal_notes} : </b>{s.internalNotes}</p>
                                                                </>
                                                                : ''}
                                                        </div>)
                                                    })
                                                }
                                            </div> : ''
                                        }
                                    </div>
                                </div>
                            )
                        })}
                </div>        
            </div>
        </div>

    );
}
export default connect()(InterpretationComponent);
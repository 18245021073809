import { connect } from 'react-redux'
import DownloadReportComponent from '../components/DownloadReportComponent.js'

const mapStateToProps = (state, props) => {

  // let downloadPath = state.paths.patient_report_target_path ||
  //             `${state.paths.visit_path}/report.pdf?document=patient&locale=en`

  // let statusPath = state.presentation.demo ? null :
  //             `${state.paths.visit_path}/report.json?document=patient&locale=en`

  return {
    // statusPath,
    // downloadPath,
    linkText: 'Patient Report',
    isPatientReport: state.global.isPatientReport
  }
}

export default connect(mapStateToProps)(DownloadReportComponent)


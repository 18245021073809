import React, { Component } from 'react';
import constants from '../../../config/constants';
import UploadMultipleDocumentComponent from '../../Patients/components/UploadMultipleDocumentComponent';

class UploadVisitDocument extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.visitId) {
            this.props.setCurrentVisit(this.props.match.params.visitId, false)
            this.props.setHeaderType(constants.visit_labels.upload_document, false)
            this.props.setDocumentsFields()
        }
    }
    render() {
        return (
            <div className='col-md-12 col-sm-12 col-xs-12'>
                <div className='container manual-form'>
                    <UploadMultipleDocumentComponent {...this.props} />
                </div>
            </div>
        )
    }
}
export default UploadVisitDocument;
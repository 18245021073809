export default (points) => {
  if (points === undefined || points == null || isNaN(points))
    return ('N/A');
  else if (points >= 4.3)
    return ('A+');
  else if (points >= 4.0)
    return ('A');
  else if (points >= 3.7)
    return ('A-');
  else if (points >= 3.3)
    return ('B+');
  else if (points >= 3.0)
    return ('B');
  else if (points >= 2.7)
    return ('B-');
  else if (points >= 2.3)
    return ('C+');
  else if (points >= 2.0)
    return ('C');
  else if (points >= 1.7)
    return ('C-');
  else if (points >= 1.3)
    return ('D+');
  else if (points >= 1.0)
    return ('D');
  else if (points >= 0.7)
    return ('D-');
  else
    return ('F');
}

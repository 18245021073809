import AllPatientsDetails from './AllPatientsDetails'
import { connect } from 'react-redux';
import { setAllPatientDetails } from '../../reducers/global-reducer';

const mapStateToProps = state => ({
  ...state.global
});

const mapDispatchToProps = {
    setAllPatientDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllPatientsDetails);

import React, { Component } from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls';
import NoumenaCharts from './NoumenaCharts';
import constants from '../../../config/constants';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import { getRoundedValue } from '../../Analytics/tools/helpers'
import _ from 'lodash';

class FilterNoumenaData extends Component {
    render() {
        return (
            <div>
                {Object.keys(this.props.noumenaData ? this.props.noumenaData : '').map(s => {
                    let histData = this.props.noumenaData[s].agesForGoogleChart.map(s => [s])
                    let final = {}, pieChart = {}
                    this.props.noumenaData[s].gradePoints.map(nd => {
                        if (final[nd]) {
                            final[nd] += 1
                        } else {
                            final[nd] = 1
                        }
                    })
                    let middleGraphData = {
                        x: Object.keys(final).sort(),
                        y: Object.keys(final).sort().map(s => final[s])
                    }
                    if (this.props.noumenaData[s].valueType != 'NM') {
                        this.props.noumenaData[s].agesForGoogleChart.map(nd => {
                            if (pieChart[nd]) {
                                pieChart[nd] += 1
                            } else {
                                pieChart[nd] = 1
                            }
                        })
                    }
                    let textBarValues = {
                        x: Object.keys(pieChart),
                        y: Object.values(pieChart)
                    }
                    let pieGraphData = Object.keys(pieChart).map(s => ({ name: s, y: pieChart[s] }))
                    //vadr data
                    let vadrDataHistPresentor = this.props.noumenaData[s].agesForGoogleChart ? _.compact(this.props.noumenaData[s].agesForGoogleChart) : null
                    let vadrHist = {}
                    let prevValue = _.min(vadrDataHistPresentor)
                    let range = (_.max(vadrDataHistPresentor) - prevValue) / 10
                    for (let i = 1; i <= 10; i++) {
                        var maxValue = prevValue + range
                        if (i == 10)
                            maxValue = prevValue + range + 1
                        vadrHist[`${(prevValue + maxValue) / 2}`] = vadrDataHistPresentor.filter(p => prevValue <= p && p < maxValue)
                        prevValue = maxValue

                    }
                    let histX = Object.keys(vadrHist).map(p => getRoundedValue(p, this.props.noumenaData[s].scale))
                    // histX[histX.length - 1] = histX[histX.length - 1] - 0.5
                    let vadrHistogramData = {
                        x: histX,
                        y: Object.values(vadrHist).map(s => s.length)
                    }
                    let zeroCount = vadrHistogramData.y.filter(s => s == 0).length
                    return <NoumenaCharts {...this.props} histogramData={histData}
                        label={this.props.noumenaData[s].label}
                        scale={this.props.noumenaData[s].scale}
                        noumenonName={this.props.noumenaData[s].nameOfNoumena}
                        minAge={_.head(this.props.noumenaData[s].age.sort())}
                        maxAge={_.last(this.props.noumenaData[s].age.sort())}
                        code={s} femaleData={this.props.noumenaData[s].female}
                        maleData={this.props.noumenaData[s].male} middleGraphData={middleGraphData}
                        pieGraphData={pieGraphData}
                        valueType={this.props.noumenaData[s].valueType}
                        textValueTypeBarValues={textBarValues}
                        age={this.props.noumenaData[s].age}
                        histVADRpresentor={vadrHistogramData}
                        zeroCount={zeroCount}
                        type={this.props.type}
                        graphDisplayCheck={this.props.noumenaData[s].agesForGoogleChart}
                    />
                })}
            </div>
        )
    }
}
export default FilterNoumenaData
import React, { Component } from 'react';
import UrlParse from 'url-parse';
import './referral-sites.scss';
class ReferralSites extends Component {
    componentDidMount() {
        let urlParams = UrlParse(this.props.location.search, true);
        this.props.getReferralSites(urlParams.query.accepts_referral ? urlParams.query.accepts_referral : false)
    }
    render() {
        return(
        <div className='referral-site container-fluid'>
            {this.props.referralError==null ? 
            <pre>{JSON.stringify(this.props.allSitesRef)}</pre> : <p>{this.props.referralError}</p>}
        </div>
        )
    }
}
export default ReferralSites
import React, {useState, useEffect, useRef} from 'react';
import ReactDom from 'react-dom';
import FormControl from '../../../components/FormControls';
import constants from '../../../config/constants';
import labels from '../../../config/localization';
import { connect } from 'react-redux';
import closeLight from '../../../assets/images/close-light.svg';
import RatingComponent from './RatingComponent';


function QuestionaireForm(props) {
    const containerRef = useRef(null);
    useEffect(() => {
        scrollLest();
      }, []);
    
    let scrollLest = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft = 0; // Scroll to the leftmost position
        }
    }  

    let numer = 0;
    if (props.surveyData[props.questionnairePageCount - 1] && props.surveyData[props.questionnairePageCount - 1].questionsDTO) {
        props.surveyData[props.questionnairePageCount - 1].questionsDTO.map((q, index) => {
            if (q.valueType != "Enumerable") {
                numer = 1;
            }
        })
    }

    
   let setSubQuestionRange =(subQuestion) =>{
    return  subQuestion.match(/\((.*?)\)/g).map(item => item.slice(1, -1).trim()[0].toUpperCase() + item.slice(1, -1).trim().slice(1))
   }
  
    return ReactDom.createPortal(
        <>
            <div className={`overlay_styles overlay_styles-${props.theme}`} onClick={() => (props.toggleSurveyPopup(false))} />
            {props.surveySubmitted ?
                <div className={`w-50 card ratingCardStyle rating_styles questionnaire-submitted-${props.theme}`}>
                    {/* {props.submittedToast()} */}
                    <div className="card-header d-flex flex-row align-items-center text-white bg-success">
                        <i class="fa fa-check-circle text-white bg-success ratingTick"></i>
                        {props.status == "draft" ?
                            <h5 className="ml-4"> {labels.analytics_labels.successfully_saved}</h5>
                            :
                            <h5 className="ml-4">{labels.analytics_labels.successfully_submitted}</h5>
                        }
                    </div>
                    <div className="card-body">
                        {/* <h6>Average Rating</h6>
                        <div className="d-flex flex-row align-items-center"><h5 className="mr-1"><b>{this.calculateAverageRating()}</b></h5>/<span className="ml-2">5</span></div>
                        <p>Total Questions Answered: <b>{this.answeredQuestionsCount()}</b> out of 10</p>
                        <p>Percentage: <b>{this.calculateRatingPercentage()}%</b></p> */}
                        <h5>Thank you for completing the questionnaires!</h5>
                        <div>Your response has been recorded. We really appreciate your effort and time devoted filling the questionnaire. Your answers will help us improve our services of you.</div>
                        <div className="d-flex flex-row-reverse"><button type="button" className="btn btn-success" onClick={() => (props.toggleSurveyPopup(false))}>Ok</button></div>
                    </div>
                </div> :
                <div ref={containerRef} className={`${props.siteTheme} questionnaire-form-beta mobileScroll questionnaire-${props.theme}`}>
                    <form className="card survey_styles questionnaireFormStyle w-75">
                        {props.questionnairePageCount <= props.surveyData.length ?
                            <>
                                <div className="card-header surveyHeader">
                                    <h3>{props.surveyData[props.questionnairePageCount - 1].name}</h3>
                                    <p>{props.surveyData[props.questionnairePageCount - 1].explanation}</p>
                                    <a className='questionnaireFormCloseIcon' ><img src={closeLight} onClick={() => (props.toggleSurveyPopup(false))}></img></a>

                                </div>
                                <div className="card-body">
                                    <div className="container">
                                        {numer == 0 ?
                                            <div className="row">
                                                <div className="col-1"></div>
                                                <div className="col-6 justify-content-center d-flex"><b className="boldShadow"> {labels.analytics_labels.question}</b></div>
                                                <div className="col-5 justify-content-center d-flex"><b className="boldShadow">{labels.analytics_labels.value}</b></div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-1"></div>
                                                <div className="col-5 justify-content-center d-flex"><b className="boldShadow">{labels.analytics_labels.question}</b></div>
                                                <div className="col-3 justify-content-center d-flex"><b className="boldShadow">{labels.analytics_labels.value}</b></div>
                                                <div className="col-1 justify-content-center d-flex"><b className="boldShadow">{labels.analytics_labels.units}</b></div>
                                                <div className="col-2 justify-content-center d-flex"><b className="boldShadow">{labels.analytics_labels.boundary_ranges}</b></div>
                                            </div>}
                                        <br />
                                        {props.surveyData[props.questionnairePageCount - 1] && props.surveyData[props.questionnairePageCount - 1].introductionsDTO && props.surveyData[props.questionnairePageCount - 1].introductionsDTO.map((introduction) => {
                                            return (
                                                <div className='card mb-4 p-3'>
                                                    <div className='alert alert-info'>
                                                        {introduction.introduction}
                                                    </div>
                                                    {introduction.questionsDTO.map((q, index) => {
                                                        let subQuestionRange = q.subQuestion ? setSubQuestionRange(q.subQuestion) : []
                                                        if (q.valueType == "Enumerable") {
                                                            return (
                                                                <>
                                                                    <div className={`row mb-3`} id={q.id} key={q.id}>
                                                                        <div className="col-1 semiboldShadow">{index + 1}.</div>
                                                                        <div className={`${numer == 0 ? "col-6" : "col-5"}`}><div className="semiboldShadow">{q.question}</div><div className={q.subQuestion ? "" : "d-none"}>{q.subQuestion}</div></div>
                                                                        <div className="col-5 rating-component">
                                                                            <RatingComponent value={props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).changed == true &&
                                                                            props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value != null ?
                                                                            props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value :
                                                                            props.surveyData[props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value ?
                                                                                props.surveyData[props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value : ""} options={q.valueList} q={q} {...props} />
                                                                            { q.subQuestion ?
                                                                                <div className='row no-gutter pr-4 pl-4 rating-labels'>
                                                                                    <span className='no-gutter col-6 justify-content-start d-flex low-rating'>
                                                                                    {subQuestionRange[0]}  
                                                                                    </span>
                                                                                    <span className='col-6 justify-content-end d-flex no-gutter high-rating'>
                                                                                    {subQuestionRange[subQuestionRange.length -1]}
                                                                                    </span> 
                                                                                </div>
                                                                            : ""}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <>
                                                                    <div className={`row mb-3 ${props.validSurveyFields && props.validSurveyFields[props.surveyData[props.questionnairePageCount - 1].name] && props.validSurveyFields[props.surveyData[props.questionnairePageCount - 1].name].find((field)=> field.id == q.id).value ? "" : "exceed-boundary-range"}`} id={q.id}>
                                                                        <div className="col-1 semiboldShadow">{index + 1}.</div>
                                                                        <div className="col-5"><div className="semiboldShadow">{q.question}</div><div className={q.subQuestion ? "" : "d-none"}>{q.subQuestion}</div><span className={`bounds-caution ${props.validSurveyFields && props.validSurveyFields[props.surveyData[props.questionnairePageCount - 1].name] && props.validSurveyFields[props.surveyData[props.questionnairePageCount - 1].name].find((field)=> field.id == q.id).value ? "d-none" : "d-inline"}`}>{labels.visit_page_labels.out_of_bounds}</span></div>
                                                                        <div className="col-3">
                                                                            <input type="text"
                                                                                value={
                                                                                    props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).changed ?
                                                                                        props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value :
                                                                                        props.surveyData[props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value ?
                                                                                            props.surveyData[props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value : ""
                                                                                }
                                                                                onChange={(e) => {
                                                                                    props.editAnalyticsSurveyData(e, props.questionnairePageCount - 1, q.id)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-1 justify-content-center d-flex">{q.dataRangesDTO && q.dataRangesDTO["units"] ? q.dataRangesDTO["units"] : ""}</div>
                                                                        <div className="col-2 justify-content-center d-flex">{q.dataRangesDTO && q.dataRangesDTO ? (q.dataRangesDTO.low && q.dataRangesDTO.high ? q.dataRangesDTO.low + " - " + q.dataRangesDTO.high : (q.dataRangesDTO.low && !q.dataRangesDTO.high ? '>' + q.dataRangesDTO.low : (q.dataRangesDTO.high && !q.dataRangesDTO.low) ? '<' + q.dataRangesDTO.high : "")) : ""}</div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    })}

                                                </div>

                                            )
                                        })}
                                        {props.surveyData[props.questionnairePageCount - 1] && props.surveyData[props.questionnairePageCount - 1].questionsDTO && props.surveyData[props.questionnairePageCount - 1].questionsDTO.filter(ques => ques.introduction == false).map((q, index) => {
                                            let subQuestionRange = q.subQuestion ?  setSubQuestionRange(q.subQuestion) : []
                                            return (
                                                <> {}
                                                    {q.valueType == "Enumerable" ?
                                                        <div className={`row pr-3 pl-3 mb-3`} id={q.id} key={q.id}>
                                                            <div className="col-1 semiboldShadow">{index + 1}.</div>
                                                            <div className={`${numer == 0 ? "col-6" : "col-5"}`}><div className="semiboldShadow">{q.question}</div><div className={q.subQuestion ? "" : "d-none"}>{q.subQuestion}</div></div>
                                                            <div className="col-5 rating-component">
                                                                <RatingComponent value={props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).changed == true &&
                                                                            props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value != null ?
                                                                            props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value :
                                                                            props.surveyData[props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value ?
                                                                                props.surveyData[props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value : ""} options={q.valueList} q={q} {...props}   />
                                                                            { q.subQuestion ?
                                                                                <div className='row no-gutter pr-4 pl-4 rating-labels'>
                                                                                    <span className='no-gutter col-6 justify-content-start d-flex low-rating'>
                                                                                        {subQuestionRange[0]}
                                                                                    </span>
                                                                                    <span className='col-6 justify-content-end d-flex no-gutter high-rating'>
                                                                                         {subQuestionRange[subQuestionRange.length -1]}  
                                                                                    </span> 
                                                                                </div>
                                                                            : ""}               
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={`row pr-3 pl-3 mb-3 ${props.validSurveyFields && props.validSurveyFields[props.surveyData[props.questionnairePageCount - 1].name] && props.validSurveyFields[props.surveyData[props.questionnairePageCount - 1].name].find((field)=> field.id == q.id).value ? "" : "exceed-boundary-range"}`} id={q.id}>
                                                            <div className="col-1 semiboldShadow">{index + 1}.</div>
                                                            <div className="col-5"><div className="semiboldShadow">{q.question}</div><div className={q.subQuestion ? "" : "d-none"}>{q.subQuestion}</div><span className={`bounds-caution ${props.validSurveyFields && props.validSurveyFields[props.surveyData[props.questionnairePageCount - 1].name] && props.validSurveyFields[props.surveyData[props.questionnairePageCount - 1].name].find((field)=> field.id == q.id).value ? "d-none" : "d-inline"}`}>{labels.visit_page_labels.out_of_bounds}</span></div>
                                                            <div className="col-3">
                                                                <input type="text"
                                                                    value={
                                                                        props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).changed ?
                                                                            props.resultDTO[props.questionnairePageCount - 1].CreateObservationDTOs.find((cbd) => cbd.id == q.id).value :
                                                                            props.surveyData[props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value ?
                                                                                props.surveyData[props.questionnairePageCount - 1].questionsDTO.find((qd) => qd.id == q.id).value : ""
                                                                    }
                                                                    onChange={(e) => {
                                                                        props.editAnalyticsSurveyData(e, props.questionnairePageCount - 1, q.id)
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-1 justify-content-center d-flex">{q.dataRangesDTO && q.dataRangesDTO["units"] ? q.dataRangesDTO["units"] : ""}</div>
                                                            <div className="col-2 justify-content-center d-flex">{q.dataRangesDTO && q.dataRangesDTO ? (q.dataRangesDTO.low && q.dataRangesDTO.high ? q.dataRangesDTO.low + " - " + q.dataRangesDTO.high : (q.dataRangesDTO.low && !q.dataRangesDTO.high ? '>' + q.dataRangesDTO.low : (q.dataRangesDTO.high && !q.dataRangesDTO.low) ? '<' + q.dataRangesDTO.high : "")) : ""}</div>
                                                        </div>}
                                                </>
                                            )
                                        })}

                                    </div>

                                    <div className={`d-flex ${props.questionnairePageCount == 1 ? "flex-row" : "flex-row"} justify-content-between`}>
                                        <button type="button" className={`btn survey-btn ${props.questionnairePageCount == 1 ? 'invisible' : 'visible'}`} onClick={() => {props.editQuestionnairePageCount("sub"); scrollLest()}}>{labels.analytics_labels.prev}</button>
                                        <div className="row"><p className="text-secondary">{props.questionnairePageCount}/{props.surveyData.length}</p></div>
                                        {props.questionnairePageCount < props.surveyData.length ?
                                            <button type="button" className="btn survey-btn" onClick={() => {props.editQuestionnairePageCount("add"); scrollLest();}}>{labels.analytics_labels.next}</button>
                                            : <button type="button" className="btn survey-btn" onClick={() => {props.editQuestionnairePageCount("add"); scrollLest();}}>{labels.analytics_labels.review}</button>}
                                    </div>
                                    <br />

                                </div>
                            </>
                            :
                            <>
                                <div className="card-header surveyHeader">
                                    <h3> {labels.analytics_labels.questionaire_review}</h3>
                                    <a className='questionnaireFormCloseIcon' ><img src={closeLight} onClick={() => (props.toggleSurveyPopup(false))}></img></a>
                                </div>
                                <div className="card-body">
                                    <div className="pl-3 pr-3">
                                        <div className='alert alert-warning surveySubmitAlert'>
                                            <i className='fa fa-warning ml-2 mr-2'></i><span>Please review your results here, if you want to re visit to this Questionnaire please save your results. If you don't want to, then submit your results.</span>
                                        </div>
                                        <div className="row">
                                            <div className="col-1"></div>
                                            <div className="col-9"><b className="boldShadow">{labels.analytics_labels.question}</b></div>
                                            <div className="col-2"><b className="boldShadow">{labels.analytics_labels.value}</b></div>
                                        </div>
                                        <br />
                                        {props.resultDTO && props.resultDTO.map((survey, index1) => {
                                            let count = 0;
                                            return (
                                                <>
                                                    <div className="reviewSurveyHeader d-flex justify-content-center" onClick={() => (props.editQuestionnairePageCount(index1 + 1))}>
                                                        <div className="survey-name">{props.surveyData && props.surveyData[index1] ? props.surveyData[index1].name : ""}</div>
                                                    </div>
                                                    <br />
                                                    {survey.CreateObservationDTOs && survey.CreateObservationDTOs.map((ques, index2) => {
                                                        if (ques.changed) {
                                                            count++;
                                                            return (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-1 semiboldShadow">{count}.</div>
                                                                        <div className="col-9 semiboldShadow">{props.surveyData && props.surveyData[index1] && props.surveyData[index1].questionsDTO && props.surveyData[index1].questionsDTO.find((q)=> q.id == ques.id) && props.surveyData[index1].questionsDTO.find((q)=> q.id == ques.id).question ? props.surveyData[index1].questionsDTO.find((q)=> q.id == ques.id).question : ""}</div>
                                                                        <div className="col-2">{ques.value}</div>
                                                                    </div>
                                                                    <br />
                                                                </>
                                                            )
                                                        }
                                                    })}
                                                    {count == 0 ?
                                                        <>
                                                            <div className="row">
                                                                <div className="col-12 semiboldShadow">You haven't answered any new questions, please click <span className="survey-here-link" onClick={() => (props.editQuestionnairePageCount(index1 + 1))}>here</span> if you would like to take the Questionnaire again.</div>
                                                            </div>
                                                            <br />
                                                        </>
                                                        : null}
                                                </>
                                            )
                                        })}

                                    </div>

                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <div className='col-lg-9'>
                                            <button type="button" className={`btn survey-btn`} onClick={() => {props.editQuestionnairePageCount("sub"); scrollLest();}}>{labels.analytics_labels.back}</button>
                                        </div>
                                        <div>
                                            <button type="button" className="btn survey-save-btn btn-success" onClick={() => (props.submitAnalyticsSurveyForm("draft"))}>{labels.analytics_labels.save}</button>
                                        </div>
                                        <div className='ml-3 mr-3'>
                                            <button type="button" className="btn survey-btn" onClick={() => (props.submitAnalyticsSurveyForm(null))}>{labels.analytics_labels.submit}</button>
                                        </div>
                                    </div>
                                    <br />

                                </div>
                            </>}
                    </form>
                </div>}
        </>,
        document.getElementById('surveyPortal')
    )
}



export default connect()(QuestionaireForm);
import React, { Component } from 'react';
import _ from 'lodash';
import FormControl from '../../../components/FormControls/FormControl';

import labels from '../../../config/localization';
import constants from '../../../config/constants';

const stylesClasses = {
    labelClass: 'col-lg-2',
    inputClass: 'col-lg-7'
}
const getDateFormat = (date) => {
    switch (date) {
        case 'ymd': return 'yyyy/MM/dd';
        case 'dmy': return 'dd/MM/yyyy';
        case 'mdy': return 'MM/dd/yyyy';
        // case 'ydm': return 'yyyy/dd/MM';
        default: return 'MM/dd/yyyy';
    }
}
class PatientForm extends Component {
    componentDidMount() {
        this.props.toggleNotification(false)
        if (this.props.match.params && this.props.match.params.patientId) {
            this.props.setCurrentPatient(this.props.match.params.patientId)
        } else {
            this.props.setPageTitle(labels.patient_list_labels.new_patient_title)
            this.props.setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.patient_list_labels.new_patient_title, path: '' }
            ])
            this.props.newPatient(this.props.history)
            document.title = labels.patient_list_labels.patient_title_new
        }

        // if (this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.CG) {
        // }

        if (this.patientForm && this.patientForm.inputEntry) {
            this.patientForm.inputEntry.focus()
        }
    }
    handleDateOfBirth(value, isSave) {
        let localDate = value && typeof (value) == 'string' ? value : value ? new Date(value).toLocaleDateString('en-US') : null
        if (value && localDate.split('/').length == 3 && localDate.split('/').filter(d => d).length == 3) {
            if (!isNaN(new Date(value).getDate())) {
                this.props.editDateOfBirth(value, this.props.history, isSave)
            } else if (value && isNaN(new Date(value).getDate())) {
                var dateParts = value.split(charSplit);
                this.props.editDateOfBirth(new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`), this.props.history, isSave)
            }
        } else {
            // if (this.props.currentPatient.displayDate)
            //     this.props.editDateOfBirth(new Date(this.props.currentPatient.displayDate), this.props.history)
            // else
            void (0)
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.props.currentPatient ?
                    <>
                        <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                        </div>
                        <form className='patient-form container col-lg-9 mt-5' onSubmit={(e) => { e.preventDefault(); this.props.savePatient(this.props.history) }}>

                            <FormControl id='identifier' ref={(p) => this.patientForm = p} value={this.props.currentPatient.identifier}
                                onChange={this.props.editPatient}
                                label={labels.patient_list_labels.identifier}
                                help={labels.patient_list_labels.identifier_help}
                                type='text' {...stylesClasses} />

                            {/* <FormControl id='honorificPrefix' value={this.props.currentPatient.honorificPrefix} onChange={this.props.editPatient}
                                label={labels.patient_list_labels.honorific_prefix} type='select' {...stylesClasses}
                                options={_.sortBy(constants.honorific_prefix, 'value')} textKey={'value'} valueKey={'value'} /> */}

                            <FormControl id='firstname' value={this.props.currentPatient.firstname} onChange={this.props.editPatient}
                                label={labels.patient_list_labels.first_name} type='text' {...stylesClasses} required={true} />

                            <FormControl id='lastname' value={this.props.currentPatient.lastname ? this.props.currentPatient.lastname : null} onChange={this.props.editPatient}
                                label={labels.patient_list_labels.last_name} type='text' {...stylesClasses} required={true} />

                            <FormControl id='honorificSuffix' value={this.props.currentPatient.honorificSuffix} onChange={this.props.editPatient}
                                label={labels.patient_list_labels.honorific_suffix} type='text' help={labels.patient_list_labels.honorific_suffix_help} {...stylesClasses} />

                            <div className='d-flex'>
                                <label className='col-lg-2'><abbr title='required'>*</abbr>{labels.patient_list_labels.gender_label}</label>
                                <div className='col-lg-7 col-md-7 gender-sec row justify-center'>
                                    <FormControl id='male' name='gender' checked={this.props.currentPatient.gender == 'male'}
                                        onChange={this.props.editPatient} label={labels.patient_list_labels.male} value={'male'} type='radio' />
                                    <FormControl id='female' name='gender' checked={this.props.currentPatient.gender == 'female'}
                                        onChange={this.props.editPatient} label={labels.patient_list_labels.female} value={'female'} type='radio' />
                                </div>
                            </div>
                            <FormControl id='email' value={this.props.currentPatient.email} validate
                                onChange={this.props.editPatient} label={labels.patient_list_labels.email} type='email' {...stylesClasses} />

                            <FormControl id='dob'
                                locale={this.props.currentPatient.patientLanguagePref
                                    ? this.props.currentPatient.patientLanguagePref
                                    : null}
                                selected={this.props.currentPatient.dob}
                                onChange={(e) => this.handleDateOfBirth(e)}
                                disabledKeyboardNavigation
                                dateFormat={getDateFormat(this.props.currentPatient.datePreference)}
                                onKeyDown={(e) => {
                                    if (e.key.toLowerCase() == 'enter') {
                                        this.handleDateOfBirth(e.target.value, true)
                                    }
                                }}
                                label={labels.patient_list_labels.dob}
                                help={labels.allowed_date_format.replace('<>', getDateFormat(this.props.currentPatient.datePreference))}
                                type='date' {...stylesClasses} required={true} patientDob />

                            <FormControl id='siteId' value={this.props.currentPatient.siteId}
                                // disabled={this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.CG}
                                onChange={this.props.editPatient} label={labels.patient_list_labels.site} required={'true'} type='select'
                                options={this.props.sitesList && this.props.loggedInUser && this.props.loggedInUser.siteIds ? this.props.loggedInUser && this.props.loggedInUser.role == 'admin' ? this.props.sitesList : this.props.sitesList.filter(site => this.props.loggedInUser.siteIds.includes(site.id)) 
                                                              : this.props.currentSite ? [this.props.currentSite] : null
                                } 
                                valueKey={'id'} textKey={'name'} {...stylesClasses} />

                            <div className='providers-selection'>
                                <FormControl id='providerId' value={this.props.currentPatient.providerId} nodefaultOption={this.props.currentPatient.id ? false : true}
                                    title={!this.props.providers || !this.props.providers.length ? labels.patient_list_labels.providers_validate : ''}
                                    onChange={this.props.editPatient} label={labels.patient_list_labels.provider} required={'true'} type='select'
                                    options={this.props.providers} disabled={!this.props.providers || !this.props.providers.length} valueKey={'id'}
                                    help={this.props.isProvidersLoading ? <i className='fa fa-refresh fa-spin'></i> : null}
                                    textKey={'firstname,lastname'} {...stylesClasses} />
                            </div>

                            <FormControl id='languagePreference' value={this.props.currentPatient.patientLanguagePref
                                ? this.props.currentPatient.patientLanguagePref
                                : null}
                                onChange={this.props.editPatient} label={labels.patient_list_labels.language_pref} type='select'
                                options={constants.language_preference} textKey={'value'} valueKey={'code'} {...stylesClasses} />
                            {/* {this.props.currentPatient.id ? */}
                            <div className='col-lg-7 d-flex justify-center'>
                                <FormControl onChange={this.props.editPatientStatus} id='active' name='active'
                                    label={labels.user_labels.active}
                                    checked={this.props.currentPatient.active == 1 ? true : false}
                                    type='checkbox' labelClass='col-lg-2' inputClass='col-lg-7' />
                            </div>
                            {/* : null */}
                            {/* } */}
                            <div className='d-flex justify-center mt-4 mt-lg-0'>
                                <FormControl type='button' btnType={constants.notification_type.success} text={labels.patient_list_labels.save}
                                />
                            </div>
                        </form>
                    </> : null
                }
            </React.Fragment>
        )
    }
}
export default PatientForm;

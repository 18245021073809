import React, { Component } from 'react';
import labels from '../../../config/localization';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';

import FormControl from '../../../components/FormControls/FormControl';
import Notification from '../../../components/Notification';
import constants from '../../../config/constants';
import LoadingTemplate from '../../../components/LoadingTemplate';
import { Typeahead } from 'react-bootstrap-typeahead';

const SiteDetailsCard = (props) => {
    return (
        <div className='col-sm-3 hidden-xs'>
            <legend>{props.title}</legend>
            <h2 className='text-center'>{props.value}</h2>
        </div>
    )
}
class SitesList extends Component {
    componentDidMount() {
        let storedRole = localStorage.getItem('role') ? constants.logged_roles[localStorage.getItem('role')] : null
        let role = this.props.loggedInUser ? this.props.loggedInUser.role : storedRole
        if (role && role == constants.logged_roles.CG) {
            this.props.history.push('/patients/list')
        } else {
            //empty the breadcrumbs and pagetitle
            this.props.setPageTitle(null)
            this.props.setBreadCrumbs([])
            this.props.getSites()
            this.props.clearFilteredSites()
            this.props.hideHeaderSkeleton(true)
            document.title = `${labels.physioage_label}: ${!this.props.isFilteredSites ? labels.sites_labels.sites_list_label : labels.sites_labels.list_sites_filter_title}`
        }
    }
    componentWillUnmount() {
        this.props.hideHeaderSkeleton(false)
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: `${labels.sites_labels.name_label}`,
                sort: true,
                editable: false,
                formatter: (cellContent, row) => (
                    <div className=''>
                        <Link to={`/sites/${row.id}`}>{row.name}</Link>
                        <p>{row.address}  </p>
                        <p>{row.city ? `${row.city}, ` : ''}{row.state} {row.postalCode}</p>
                        {row.country && row.country != '0' ? <p> {row.country}</p> : null}
                    </div>
                )
            },
            { dataField: 'category', text: `${labels.sites_labels.category_label}`, sort: true, editable: false },
            { dataField: 'billing', text: `${labels.sites_labels.billing_label}`, sort: true, editable: false },
            { dataField: 'credits', text: `${labels.sites_labels.credits_label}`, sort: true, editable: false },
            { dataField: 'providers', text: `${labels.sites_labels.providers_label}`, sort: true, editable: false },
            { dataField: 'patients', text: `${labels.sites_labels.patients_label}`, sort: true, editable: false },
            { dataField: 'i18N', text: `${labels.sites_labels.language_label}`, sort: true, editable: false },
            { dataField: 'units', text: `${labels.sites_labels.units_label}`, sort: true, editable: false },
            { dataField: 'visitsCountForPast90Days', text: `${labels.sites_labels.recent_visits}`, sort: true, editable: false },
            { dataField: 'active', text: `${labels.sites_labels.active}`, sort: true, formatter: (cellContent, row) => (<span>{row.active == 1 ? "Active" : "Inactive"}</span>) } 
        ]

        return (
            <div className='sites-list container-fluid'>
                <h1>{!this.props.isFilteredSites ? labels.sites_labels.sites_list_label : labels.sites_labels.list_sites_filter_title}</h1>
                {this.props.isLoading ? <LoadingTemplate isGrid={true} /> :
                    <div className='card card-body'>
                        {this.props.siteSpecs ?
                            <div className='row container'>
                                <SiteDetailsCard title={labels.sites_labels.total_sites} value={!this.props.isFilteredSites ? this.props.siteSpecs.totalSites : this.props.siteSpecs.referalTotalSites} />
                                <SiteDetailsCard title={labels.sites_labels.total_clinic} value={!this.props.isFilteredSites ? this.props.siteSpecs.totalClinics : this.props.siteSpecs.referalTotalClinics} />
                                {/* <SiteDetailsCard title={labels.sites_labels.new_clinic} value={!this.props.isFilteredSites ? this.props.siteSpecs.newSitesThisQuarter : this.props.siteSpecs.referalNewSitesThisQuarter} />
                            <SiteDetailsCard title={labels.sites_labels.active_clinic} value={!this.props.isFilteredSites ? this.props.siteSpecs.activeSitesThisQuarter : this.props.siteSpecs.referalActiveSitesThisQuarter} /> */}
                                <SiteDetailsCard title={labels.sites_labels.new_clinic} value={this.props.siteSpecs.newSitesThisQuarter} />
                                <SiteDetailsCard title={labels.sites_labels.active_clinic} value={this.props.siteSpecs.activeSitesThisQuarter} />
                            </div> : null
                        }
                        <div className='row container ml-3 pl-3'>
                            <FormControl type='button' btnType='primary' onClick={() => this.props.addSite(this.props.history)} text={labels.sites_labels.add_site} />
                            <a className='sites-filter' href='javascript:void(0)' onClick={() => { this.props.filterSites(this.props.isFilteredSites ? constants.sites_labels.all : constants.sites_labels.referral) }}>{this.props.isFilteredSites ? labels.sites_labels.all_sites : labels.sites_labels.list_sites_filter}</a>
                        </div>
                        <div className='row container ml-3 pl-3 mt-3'>
                            <div className='col-lg-3 col-sm-4 pl-0' onBlur={(e) => {this.props.clearSiteNamesOnClick(e)}}>
                                <FormControl
                                    labelKey="value"
                                    id='name' label={labels.sites_labels.name_label}
                                    open={this.props.currentSitesName && this.props.currentSitesName.length ? true : false}
                                    type='typeahead' 
                                    onSearch={(name) => { this.props.populateSitesSearchParams(name); 
                                                          this.props.filterSitesList(name, this.props.searchFieldActive)}}
                                    onInputChange={(name) => { this.props.populateSitesSearchParams(name); 
                                                               this.props.filterSitesList(name, this.props.searchFieldActive)}}
                                    options={this.props.currentSitesName}
                                    emptyLabel=''
                                    newSelectionPrefix={null}
                                    onChange={(e) => {
                                        if (e && e.length && _.head(e)) {
                                            this.props.setSiteSearchParam(_.head(e), 'name')
                                            this.props.clearSiteNames()
                                            this.props.filterSitesList(_.head(e), this.props.searchFieldActive)
                                        }
                                    }}
                                    placeholder="Enter name"
                                />
                            </div>
                            <div className='col-lg-2 col-sm-3 pl-0'>
                                <FormControl
                                    id='statusID' label='Status' name='active' nodefaultOption={true}
                                    value={this.props.currentSearchFilters && this.props.currentSearchFilters.active ? this.props.currentSearchFilters.active : 'all'}
                                    onChange={(e) =>{ this.props.setSiteSearchParam(e.target.value, e.target.name), 
                                                      this.props.filterSitesList(this.props.searchFieldName, e.target.value)
                                                    }}
                                    type='select'
                                    textKey={'text'} valueKey={'value'}
                                    options={[
                                              {value: 'all', text: 'All'},
                                              {value: 1, text: 'Active'}, 
                                              {value: 0, text: 'Inactive'}
                                            ]}
                                />
                           </div>
                           { this.props.filteredSites && this.props.filteredSites.length ?
                                <div className='col-lg-2 col-sm-3 pl-0 download-site-list-btn'>
                                    <FormControl type='button' btnType='primary' text={labels.sites_labels.download_sites} onClick={() => this.props.downloadSitesList()} />
                                </div>
                            : ''}
                         </div>
                        <div className='site-table'>
                            {this.props.filteredSites && this.props.filteredSites.length ? <BootstrapTable striped={true} bordered={false} hover
                                bootstrap4 keyField='id'
                                data={this.props.filteredSites}
                                columns={columns}
                            /> : this.props.no_grid_data ?
                                <div className='jumbotron'>
                                    <h3>{labels.no_grid_data}</h3>
                                </div> : null}</div>
                    </div>}
            </div >
        )
    }
}
export default SitesList
import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import { toggleLoading, toggleNotification, handleErrors } from '../../../reducers/global-reducer';

const SET_PROPS = 'SET_PROPS'
function getActiveSiteReports() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        axios.get(API_URL.ACTIVE_SITE_REPORTS).then(response => {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    activeSitesMonths: response.data && response.data.activeSitesByMonthDTOs ? response.data.activeSitesByMonthDTOs : [],
                    excludedSitesList: response.data && response.data.excludedSitesList ? response.data.excludedSitesList : '',
                    no_grid_data: response.data && response.data.activeSitesByMonthDTOs && !response.data.activeSitesByMonthDTOs.length ? true : false
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
        })
    }
}
function getVisitVolume() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        axios.get(API_URL.ACTIVE_SITE_VOLUME_REPORT).then(response => {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    activeSitesMonths: response.data && response.data.activeSiteReport && response.data.activeSiteReport.activeSitesByMonthDTOs ? response.data.activeSiteReport.activeSitesByMonthDTOs : [],
                    excludedSitesList: response.data && response.data.activeSiteReport && response.data.activeSiteReport.excludedSitesList ? response.data.activeSiteReport.excludedSitesList : '',
                    no_grid_data: response.data && response.data.activeSiteReport && response.data.activeSiteReport.activeSitesByMonthDTOs && !response.data.activeSiteReport.activeSitesByMonthDTOs.length ? true : false,
                    activeSitesVolume: response.data.visitVolume
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
        })
    }
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    no_grid_data: false
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}
export const actionCreators = {
    getActiveSiteReports,
    getVisitVolume
}
export default {
    physioage_label: "PhysioAge",
    physioage_report_label: "PhysioAge Reporting",
    site_label: "teren",
    login_help: "Eșuți vă aflați în legătură cu problemele cu care v-ați abonat, permiteți-vă serviciul nostru de suport",
    login_help_text: "Conectați-vă folosind adresa dvs. de e-mail.",
    login_btn_text: 'войти в систему',
    forgot_password_text: 'uită parola',
    forgot_password_label: 'Resetare parolă de solicitare',
    email_label: 'Email',
    forgot_password_instruction: "Instrucțiunile pentru resetarea parolei vor fi livrate la adresa de mai sus dacă aveți un cont.",
    navigate_login: "Înapoi la ecranul de conectare",
    reset_my_password_label: "Resetați parola",
    inside_us: 'În interiorul Statelor Unite',
    outside_us: 'În afara Statelor Unite',
    done_label: 'Terminat',
    return_login_btn: 'Reveniți la pagina de conectare',
    reset_password_label: 'Reseteaza parola',
    password: 'Parola',
    confirm_password: 'confirma parola',
    update_password: 'Update Password',
    update_password_btn: 'Actualizați parola mea',
    try_again: 'Please try again:$',
    link_expired: 'Link Expired$',
    link_expired_notification: 'The link you used to reset your password expired. Typically this happens when you wait several days to use the link, or when you (or someone else) went through the process of ressing your password again.$',
    set_password: 'Set Your Password$',
    set_password_notification: 'Your password must be changed before you can access your data. We know passwords are a hassle. But we think the next step will be worth your time.$',
    login_caution_start: "Când te loghezi, ești de acord să fii legat de noi",
    login_caution_conj: 'și',
    login_cookies: "inclusiv, dar fără a se limita la, utilizarea cookie-urilor.",
    invalid_email: `Invalid Email Address. Please provide a valid email address$`,
    unauth_login: `You must be an administrator to access this page.$`,
    something_went_wrong: 'Something went wrong$',
    not_available: 'NA$',
    none: 'None$',
    login_label: 'Login$',
    pagenotfound: {
        title: "Pagina nu a fost găsită",
        help: "Căutați ceva care nu este acolo",
        redirect_label: "Redirecționare către domiciliu",
        login_label: 'Conectați-vă din nou',
        session_expired: 'Sesiunea a expirat',
        back_label: 'Navigate to home$'
    },
    footer_labels: {
        privacy: 'Politica de confidentialitate',
        tos: 'Termenii serviciului'
    },
    header_labels: {
        credits: "Credite",
        account: "Contul",
        user_settings: 'Setări utilizator',
        logout: "Logout",
        feedback: "Feedback",
        patients: "pacienți",
        help: "ajutor",
        current_manual: "Manual actual",
        all_manuals: "Toate manualele",
        videos: "Video",
        ops: "OPS",
        active_site_report: "Raportul site-ului activ",
        providers: "Furnizori",
        sites: "site-uri",
        elk: "ELK",
        users: "Utilizatori",
        billing_summary: "Rezumatul facturării",
        message_routing: 'Routing de mesaje',
        inbox: "Inbox",
        oddities: "ciudățenii",
        corrections: "Corectări",
        data: "Date",
        explore_noumena: 'Explore Noumena',
        volume_report: 'Raport volum',
        vadr: "VADR",
        remaining_label: 'remaining$',
        practice: 'Practice$',
        explore_data: 'Explore Date$',
        download_library: 'Download Library$',
    },
    patient_list_labels: {
        title: "Lista pacienților",
        patient_title: 'Patient$',
        filter_patients_label: "Filtrează pacienții",
        site_label: "Teren",
        name_label: 'Name$',
        min_visits_label: "Vizite minime",
        search: 'Căutare',
        title_label: "Listă de pacienți",
        add_patient: "Adăugați pacientul",
        patient_title_new: 'PhysioAge: New Patient$',
        new_patient_title: "Formularul noului pacient",
        edit_patient_form: "Modificați formularul pacientului",
        honorific_prefix: "Prefixul de onoare",
        honorific_suffix: 'Honorific Suffix',
        honorific_suffix_help: "CPA, DDS, MDIV",
        first_name: "Prenume",
        last_name: "Numele de familie",
        email: "Email",
        dob: "Data nașterii",
        dob_help: "Odată ce ați selectat anul și luna, trebuie să faceți clic pe zi.",
        site: "Teren",
        provider: "Furnizor",
        language_pref: "Preferința de limbă",
        save: "Salvați",
        male: "Barbat",
        female: "Femeie",
        add_visit: 'Adauga vizita',
        edit_Patient: 'Editați pacientul',
        patient_details: "Detalii pacient",
        patient_portal: "Portalul pacientului",
        view_patient_portal: "Vizualizează Portalul pacientului",
        enable_patient_portal: "Activați Portalul pacientului",
        resend_email: "Trimiteți un mesaj de întâmpinare",
        portal_caution: "Nu au folosit-o.",
        portal_last_viewed: ' Last viewed$',
        portal_help: "Acest pacient are acces la portalul pacientului",
        portal_mail_help: "Înainte de a permite acestui pacient să acceseze portalul pacientului, avem nevoie de adresa de e-mail. Introduceți-l mai jos.",
        enable_portal_caution: "Acest pacient nu are acces la portalul pacientului. Dați clic pe butonul de mai jos pentru a activa accesul. ",
        update_patient: "Actualizare pacient",
        edit_label: 'Edit',
        all_sites: "Toate site-urile",
        gender_label: "Sex",
        no_visits: "Fără vizite",
        clear_search: 'Șterge căutarea',
        create_visit: 'Creare vizită',
        visit_date: 'Data vizitei',
        visits: 'Visits$',
        add_visit_help: 'Selectați o dată pentru a adăuga vizita',
        view_health_analytics: "Vizualizare analize de sănătate",
        credits_elk: "Nu aveți credite pentru a adăuga vizita",
        future_date: `este prea departe în viitor`,
        agg_age_chart: "Diagrama îmbătrânirii agregatelor",
        resend_welcome_email: `We will send another welcome email to this user within the next 10 minutes.$`,
        no_visit_caution: `This patient does not have access to the patient portal.
                            Once you have set up a visit for them, you'll be able to provide access to them.$`,
        no_visits_patients: `Each patient visit must be set up before data will be available. Currently this system doesn't know about any visits for this patient.$`,
        data_entry: 'Data Entry$',
        never: 'Never$',
        new_visits: 'New visits for$',
        providers_validate: 'Select Sites to get providers$',
        sex: 'Sex$',
        last_active: 'Last Active$',
        identifier: 'Identifier$',
        identifier_help: 'Optional field to store your local patient identifiers$',
        delete_patient: 'Delete Patient$',
        delete_patient_prompt: 'Do you want to delete this patient permanently$',
        delete_patient_desc: "This will delete all the visits associated with the patient and its data$",
    },
    feedback_labels: {
        help_text: "Salutăm și apreciem orice feedback pe care îl aveți. Suntem interesați să auzim orice gânduri pe care le aveți despre cum să ne îmbunătățim aplicația.",
        save_text: "Trimite feedback",
        title: "Feedback",
        feedback_success: "Feedback-ul dvs. a fost livrat. Vă mulțumesc pentru contribuția dvs. "
    },
    user_labels: {
        edit: 'Edit',
        title: "Listă de utilizatori",
        new_user: "Utilizator nou",
        edit_user: 'Editare utilizator',
        account_settings: "Setările contului",
        email_addresses_help: "Adresele de e-mail pentru toți utilizatorii curenți:",
        firstname: "Prenume",
        lastname: "Numele de familie",
        username: "Utilizator",
        email: 'Email',
        active: "Active",
        activeWithQ: `Active?`,
        site: "Site",
        role: "Rol",
        update_user: 'Update User$',
        reset_password: 'Reset Password$',
        type_password: 'Type password$',
        confirm_password: 'Confirm Password$',
        update_password: 'Update Password$',
        retype_password: "Reintroduceți parola",
        password: 'Parola',
        add_user: "Adăugați un utilizator",
        password_not_matched: 'Parolele nu se potrivesc',
        job_type: 'Tipul postului',
        provider_label: 'Furnizor de',
        gender_label: 'Sex',
        filter_users: 'Filter Users$',
        master: 'master$',
    },
    credits_labels: {
        title: 'Credite de raportare',
        add_credits: 'Adăugați credite',
        download: 'Download',
        receipt: 'chitanță',
        history: "Istorie",
        receipt_header: 'Receipt$',
        date: 'Date :$',
        client: 'Client :$',
        description: 'Description$',
        quantity: 'Quantity$',
        amount: 'Amount$',
        ok_btn: 'OK$',
        credit_transactions_name: 'credit_transactions$.csv'
    },
    sites_labels: {
        new_site_label: "Site nou",
        sites_list_label: "Site-uri",
        total_sites: "Site-uri totale",
        total_clinic: "Clinici Total",
        new_clinic: "Clinici noi acest Q",
        active_clinic: "Clinici active acest Q",
        add_site: "Adăugați un site",
        list_sites_filter: 'List Sites Accepting Referrals$',
        list_sites_filter_title: 'Sites Accepting Referrals$',
        all_sites: 'Lista tuturor site-urilor',
        name_label: 'Name$',
        category_label: 'Categorie',
        billing_label: "facturare",
        credits_label: "Credite",
        providers_label: "Furnizori",
        patients_label: "Pacienții",
        language_label: "I18N",
        units_label: "Unități",
        name_help: "Acesta este numele care va apărea în portalul pacientului și va fi numele expeditorului atunci când trimiteți prin e-mail link-uri către pacienți. Acesta va fi, de asemenea, utilizat în pesmet și în alte locuri de pe întregul loc. ",
        site_referrals_help: "Site-uri acceptă Furnizorul Referrals",
        preferences: "Localization Preferences$",
        language_preference: "Preferința de limbă",
        date_preference: "Data preferință",
        unit_preference: "preferință unitate",
        unit_preference_help: 'Schimbarea pentru un site existent ar putea dura ceva timp. Vă sugerăm să consultați un dezvoltator.',
        margins_help: "Adăugați margini pentru a găzdui perforatoare cu două fețe",
        logo_label: "Logo$",
        banner_logo_label: "Banner logo$",
        settings: 'Other Settings$',
        sites_message_acknowledge: "Site-ul acceptă mesaje",
        analytics_portal_acknowledge: "Analiza ca portal",
        billing_strategy: "Strategia de facturare",
        brand: "Brand",
        default_panels: "Panourile implicite",
        referral_info: "Informațiile de trimitere și publicitate",
        address_help: "Această adresă va fi furnizată potențialilor pacienți prin intermediul Furnizorului de căutare și poate fi reprezentată pe o hartă.",
        address_label: "Adresa",
        city_label: 'City',
        state_label: "stat",
        postal_code: "Codul poștal",
        country: "Țara",
        phone: 'Telefon',
        phone_help: "Acest număr va fi furnizat pacienților și va fi utilizat de PhysioAge în cazul în care trebuie să îi contactăm pentru întreruperile site-ului.",
        website_label: "Website",
        other_info: "Alte informații",
        principle_ehr: "Principiul EHR",
        save_btn: 'Salvează',
        edit_site: 'Editează',
        add_provider: "Adăugați furnizor",
        add_user: "Adăugați un utilizator",
        add_elk: "Adăugați ELK",
        contact_info: "Informații de contact",
        portal_label: "Portal$",
        accept_referral_label: 'Accept Referrals',
        users_label: "Utilizatori",
        recent_activity: "Activitate recentă",
        lastseen: "Ultima dată văzut",
        month_label: "Luna",
        year_label: "Anul",
        visits_label: "Vizite",
        analytics_label: "Analytics",
        old_portal_label: "Portalul vechi",
        transaction_history: "Istoricul tranzacțiilor",
        current_image_label: 'Current Image: $',
        referral: 'referral$',
        all: 'all$',
        not_uploaded: 'Not Uploaded$',
        download_attendee: 'Download Attendee List$',
        features_label: 'Features$',
        include_phone_in_summary: 'Include Phone Number in Visit Summary$'
    },
    provider_labels: {
        providers_list: "Lista furnizorului",
        add_provider: "Adăugați un furnizor",
        update_provider: "Provider de actualizare",
        edit_provider: 'Editați',
        list_title_label: "Furnizori",
        provider_name: "Furnizor",
        sites_label: "Site-uri",
        patients_label: "Pacienții",
        last_visit: "Ultima vizită",
        honorific_prefix: "Prefix de onoare",
        firstname: 'Prenume',
        lastname: 'nume de familie',
        honorific_suffix: "Succes de onoare",
        honorific_suffix_help: "CPA, DDS, MDIV",
        create_provider: 'Create Provider',
        profile_pic: "Faceți clic pe imagine pentru a modifica imaginea",
        image_label: "Imagine$",
        avatar: '-avatar$',
        remove_pic: 'Remove picture$'
    },
    billing_labels: {
        title: "Rezumatul facturării",
        site_label: "teren",
        startdate_label: 'Data de începere',
        enddate_label: "Data de încheiere",
        generate: "Generați",
        num_reports_labels: "Numărul de rapoarte",
        charge_report: "Taxă pe raport",
        total_charge: "Taxele totale",
        filter_label: 'Filter',
        physician: 'Physician',
        patient: 'Patient',
        timepoint: 'Timepoint',
        created: 'Created',
        visit_date: 'Visit Date$'
    },
    message_routing_labels: {
        list_title: "Reguli de redirecționare a mesajelor",
        add_rule: "Adăugați o regulă",
        message_routes: 'Routes de mesaj',
        new_message_routing: "Reguli de redirecționare a mesajelor noi",
        edit_message_routing: "Editați regula de rutare a mesajelor",
        sending_application: "Trimiterea cererii",
        sending_facility: "Facilitatea de trimitere",
        receiving_application: "Primirea cererii",
        receiving_facility: "Facilitatea de primire",
        site: "Site",
        panel: 'panou',
        add_message_route: "Adăugați mesajul",
        update_message_route: 'Actualizați calea mesajului',
        edit_label: 'Edita',
        apply_label: 'aplica',
        pid18_1:'Pid18 1',
        copy: 'Copy'
    },
    help_page_labels: {
        current_manual_title: "Manualul curent Biomarker",
        download_label: "Descărcare",
        delete_label: "Șterge",
        all_manual_title: "Istoricul manual al biomarkerului",
        videos_title: "Video de instruire",
        cutometer: "Cutometrie",
        cutometry: "MPA 580",
        spirometer: "Analiza valurilor pulsului",
        spirometry1: "SphygmoCor XCEL",
        spirometry2: "SphygmoCor CP",
        sphygmoCor: "Spirometrie",
        sphygmocor1: 'EasyOneAir',
        sphygmocor2: "MidMark IQSpiro",
        upload_doc: "Încărcați un document nou",
        upload_doc_title: "Încarcă un manual nou pentru biomarkeri",
        version: "Versiune",
        attachment: "Atașament",
        create_btn_text: "Creați fișierul de ajutor",
        delete_warning: "Sigur ștergeți manualul?",
        ok_btn: "Ok",
        cancel_btn: 'Anulare',
        helpFile_validation: 'Please upload pdf file$',
        version_validation: 'Please enter version$',
        invalid_file: 'Invalid file$',
        uploaded: 'Uploaded$',
    },
    elk_labels: {
        title: "Tranzacții de credit",
        filter: "Filtru",
        site: "Site",
        add_title: "Adăugați credite pentru",
        no_of_sites: "Număr de credite",
        comment: "Comentariu",
        create_credit_btn: "Creați tranzacții de credit",
        user: 'User$',
        change: 'Change$'
    },
    tos_labels: {
        title: 'TERMENI DE UTILIZARE',
        last_updated: 'Ultima actualizare 31 ianuarie 2018'
    },
    privacy_labels: {
        title: 'POLITICA DE CONFIDENTIALITATE',
        last_updated: 'Ultima actualizare 31 ianuarie 2018'
    },
    corrections_labels: {
        title: 'Corectarea',
        name: 'Nume',
        date: 'Data',
        description: 'Descriere'
    },
    oddities_labels: {
        title: "ciudățenii unice",
        oddities: "ciudățenii",
        errors: "Toate erorile",
        message_label: 'Message',
        count_label: "Contele",
        resolve: "Resolve$",
        ok_btn: "Ok$",
        cancel_btn: 'Anulare',
        close_btn: 'Închide',
        observation: "Observație",
        noumenon: "Noumenon$",
        id: 'ID$',
        resultId: "Identificarea rezultatelor",
        valueType: 'Value Type',
        identifier: "Identificator",
        subIdentifier: "Sub-identificator",
        label: "Etichetă",
        value: "Valoare",
        units: "Unități",
        referenceRange: "interval de referință",
        abnormalFlags: "Steaguri anormale",
        probability: "Probabilitate",
        natureOfAbnormalTest: "Natura testului anormal",
        resultStatus: "Starea rezultatelor",
        observedAt: "Observat la",
        producer: "Producător",
        observationMethod: "Metoda de observare",
        equipmentInstance: "Exemplu de echipament",
        analyzedAt: "Analizat la",
        createdAt: 'Creat la',
        updatedAt: 'Actualizat la',
        observerType: "Tip observator",
        observerId: "Observer ID",
        datumId: "ID ID",
        result_label: 'Rezultat',
        source_label: "Sursa",
        history: "Istorie",
        observation_status: "Status",
        observation_id: "ID de observare",
        result_date: 'Date de rezultat',
        no_observations: 'No Observations found$',
        no_history: 'No History found$',
        reference: 'Reference$',
        date_time: 'Date / Time$',
        resolve_error: 'Are you sure to resolve this error?$',
        resolved: 'Resolved$',
        category: 'Category$',
        examples: 'examples$',
        messagegroup: 'Oddity Messages$',
        sample_observations: 'Sample Observations$',
        observations_info_1: 'observations$',
        observations_info_2: ' match identifier$',
        visit: 'Visit$',
        obx: 'OBX',
        identifier:'Identifier'
    },
    inbox_labels: {
        title: "Inbox",
        filter_title: "Filtru după expeditor",
        filter_subtitle: 'Mesaj filtru',
        raw_btn: "Raw",
        resolve_btn: "Resolve",
        resolve_title: 'Resolve Message',
        demo_modal: "Demo Modal Lansare",
        msg_details: 'Detalii mesaj',
        from_label: 'From',
        date_label: "Data",
        name_label: "Nume pacient",
        dob_label: "Rabdator DOB",
        close_label: "Închide",
        launch_demo_header: "Conținutul brut al mesajului",
        site_label: "Teren",
        patient: 'Patient$',
        dob: 'DOB$',
        dobLabel: 'dob$',
        visit: 'Visit$',
        received: 'Received$',
        processed: 'Processed$',
        back: 'Back$',
        aboutyear_back: 'about 1 year back$',
        about_year_back: 'years back$',
        days_back: 'days back$',
        reference: 'Reference$',
        createdAt: 'Created At$',
        processedAt: 'Processed At$',
        details: 'Details$',
        message_details: 'Message Details$',
        oddities: 'Oddities$',
        routedTo: 'Routed To$',
        imported: 'Imported?$',
        notrouted: 'Not Routed$',
        no_label: 'no$',
        un_routed_messages: 'Unrouted Messages',
        failure_messages: 'Failure Messages'
    },
    asr_labels: {
        title: "rapoarte de site-uri active",
        active_site_month: "Site-uri active după lună",
        visit_volume: 'Vizitați volumul',
        active_sites: "Site-uri active",
        excluding: "excluderii"
    },
    analytics_labels: {
        exit_Analytics: 'Exit Analytics$',
        menu: "Meniu",
        most_recent_visit: "cea mai recentă vizită",
        years_old: "ani",
        biographical_information_label: 'Biographical Information',
        biographical_info_label: "Informații biografice",
        biomarkers_of_aging_label: "Biomarkerii de îmbătrânire",
        health_card_report_label: "Raport de sănătate",
        recommendations_label: "Recomandări",
        download_report_label: 'Download Report',
        patient_report_label: "Raportul pacientului",
        download_recommendations: "Download Recomandări",
        download_pdf_label: 'Vizualizare / descărcare PDF',
        no_recommandations: "Nu ați introdus nici o recomandare.",
        no_recommandations_content: 'Pentru a introduce o recomandare, trebuie să fii în sertarul care iese din dreapta atunci când dai clic pe oricare dintre parametrii ei înșiși. Ideea este că atunci când mergi peste laboratoare sau biomarkeri în timpul consultărilor, poți da clic pe parametru, de exemplu "Testosteron Free", apoi apare un sertar din partea dreaptă a ecranului. Puteți să examinați spectrul de interpretare, modificările de la valoarea inițială, un grafic al tuturor punctelor, tabelul de date și, pe măsură ce parcurgeți în jos, există un formular pentru a introduce recomandările dvs. ',
        comparing_your_ages: "Comparând vârstele tale",
        logOut: 'Log Out$',
        no_visits_label: 'Patient has no visits on file.$',
        measured_recently: `This biomarker hasn't been measured recently.$`,
        older_actual_age: `years older than your actual age.$`,
        younger_actual_age: 'years younger than your actual age.$',
        average_for_age: 'About average for your age.$',
        older_actual_age_visits: `years older than average for your age.`,
        younger_actual_age_visits: `years younger than average for your age.`,
        older_younger: `neither older nor younger than average for your age.`,
        gpa_scale: 'Your GPA (4-point scale):$',
        report_card_label: 'Report Card$',
        treatment_plan: 'TREATMENT PLAN$',
        biomarkers_label: 'Biomarkers$',
        average_peers: 'Average for Your Peers$',
        everyone_label: 'Everyone$',
        change_from: 'Change From$',
        since_label: 'since$',
        baseline_label: 'Baseline$',
        previous_visit_label: 'Previous Visit$',
        results_label: 'Results$',
        recommendation_label: 'Recommendation$',
        more_info: 'More Info$',
        as_of_label: 'as of$',
        years_label: 'years$',
        your_age_label: 'Your Age$',
        your_label: 'Your$',
        date_label: 'Date$',
        grade_label: 'Grade$',
        value_label: 'Value$',
        units_label: 'Units$',
        reference_range: 'Reference Range$',
        no_recommandations_made: 'No recommendations have been made$',
        select_items_label: 'Select items from the search results, or the common recommendations on the left to add them to the list.$',
        submit_comments: 'Submit Comments$',
        recent_recommendations: 'Recent Recommendations$',
        changes_wont_stick: `Changes won't stick$`,
        demo_mode: `In demo mode, your changes will appear to have been saved. But if you come back to this page in the future, you won't see them. This allows all demo users to start with the same basic information each time.$`,
        loading_data: `Loading Data:$`,
        loading_categories: 'Loading Categories:$',
        loading_patient: 'Loading Patient:$',
        loading_recommendations: 'Loading Recommendations:$',
        normal_range: 'Normal Range$',
        optimal_range: 'Optimal Range$',
        no_description: 'No Description Available$',
        past_results: 'Your Past Results$',
        your_result: 'Your Result$',
        powered_by_physioage: 'Powered By PhysioAge$'
    },
    noumena_labels: {
        title: 'Explore Noumena$',
        noumena: "Noumena",
        filter_clouds: "Nori de filtrare",
        noumenon: "numele Noumenon",
        site: "Site",
        gender: "Sex",
        max_deviations: "Max Deviations",
        update_btn: "Actualizare",
        heads_up: "Heads Up!",
        filter_help: "Nori va genera mai lent dacă selectați elementele de mai sus. Numai norii impliciți sunt pre-desenați. ",
        volume_report: 'Volume Report',
        download_csv: "Descărcați CSV",
        volume_report_name: 'volume_report.csv',
        aggregate_age_chart_title: "Exemplu Aggregate Age Charts",
        sample_report_title: "Exemplu de clasă de carti de raport",
        sample_spectra_title: "Sample Spectra",
        concerns_title: "Preocupări:",
        concerns: [
            "Cum arată când este tipărit?",
            "Ce cantitate de cerneală folosește?",
            "Cum arata culoarea? (s-ar putea să nu fie mulți dintre ei, dar știm că există, și ei ar putea să se descurce și ei.) "
        ],
        color_palette_help: "Schimbați paletele de culori dacă doriți:",
        vadr_label: 'Very Abnormal Data Review$',
        no_results: 'No results$',
        next_page: 'Next Page$',
        no_results_code: 'No results for the noumena code$',
        value_format: 'Value Format$',
        system: 'System$',
        units: 'Units$',
        scale: 'Scale$',
        sorted_details: 'Sordid, Sorted Details$',
        code: 'Code$',
        appears_in_report: 'Appears in Report$',
        yes: 'Yes$',
        no: 'No$',
        equation: 'Equation$',
        gender: 'Gender$',
        anyone: 'Anyone$',
        male: 'Male$',
        female: 'Female$',
        no_values_to_plot: 'No values to plot the Graph$',
        age: 'Age$',
        visitDate: 'Visit Date$',
        visitId: 'Visit Id$',
        ordinal: 'Ordinal$',
        siteId: 'Site Id$',
        units: 'Units$',
        noumena_download: 'data$.csv',
        no_name: 'No name$',
    },
    visit_page_labels: {
        title: "Linia de bază pentru",
        breadcrum_for_visit: "Baseline",
        surveys_label: "Anchete",
        patient_conditions_link: "Studiul privind condițiile pacientului",
        tests_label: "Teste",
        btn_tests: 'Add Test',
        btn_edit_patient: "Editați pacientul",
        btn_edit_visit: 'Editați vizita',
        update_visit: "Vizita de actualizare",
        btn_delete_visit: "Șterge vizita",
        btn_visit_summary: "Vizitați rezumatul",
        btn_portal_view: "Vizualizare portal a acestei vizite",
        btn_toggle_beacon: 'Toggle Beacon',
        report_label: "Raportul pacientului",
        download_link: "Descărcare",
        visit_success: 'Vizita a fost creată cu succes.',
        visit_update_success: "Vizita a fost actualizată cu succes",
        preview_link: "Previzualizare",
        download_historic_data: 'Historical Data$',
        recycle_report_link: "Reciclarea",
        recycle_report_text: '(creați unul nou)',
        physician_report_label: "Raportul medicului",
        register_date_header: "Înregistrați data vizitei",
        active_ell_caution: "Acest pacient are un ELK activ",
        update_date_header: "Actualizați data vizitei",
        error_handling: 'O eroare a apărut Vă rugăm să introduceți o altă dată',
        waiting_for_data: "Așteptarea datelor",
        notes: "Completați rezultatele testelor din stânga. Odată ce acest lucru este făcut, aici vor apărea date de ansamblu. ",
        test_add_order: 'Adauga comanda',
        test_panel_name: 'Panel Name',
        popup_title_addTest: "Adăugați un nou test",
        lbl_addTest: 'Add Test',
        patient_survey_title: "Studiul pacientului pentru",
        nav_link_history: "Istorie",
        nav_link_import: "Import",
        nav_link_sample_image: 'Sample Image',
        nav_link_manual_entry: "Intrare manuală",
        customer_service_label: "Dacă unitățile sau intervalele de referință de pe această pagină nu se potrivesc cu ceea ce primiți de la laborator, introduceți-le manual făcând clic în câmpul corespunzător. a lua legatura ",
        customer_service_name: "service pentru clienți",
        customer_service_label_2: " cu întrebări sau probleme.",
        patient_survey_table_label: "Label",
        patient_survey_table_value: 'Valoare',
        patient_survey_table_units: "Unități",
        patient_survey_table_ref_range: "Interval de referință",
        patient_survey_table_status: 'Status',
        btn_save_label: "Salvați rezultatele",
        label_patient_survey: "Studiul pacienților",
        delete_warning: 'Sigur vrei să ștergi vizita?',
        update_visit_help: 'Selectați o dată pentru a actualiza vizita',
        elk_credits_one: "Când creați această vizită, veți folosi unul dintre dvs.",
        elk_credits_two: "creditele rămase",
        visit_date: 'Data vizitei',
        future_date: `este prea departe în viitor`,
        visit_creation_error: "Data poate fi modificată în timpul unei săptămâni după crearea vizitei",
        credits_elk: "Nu se poate adăuga Timepoint. Nu există rapoarte rămase",
        no_history: `No results have been entered or imported.`,
        biomarkers: "Biomarkerii de îmbătrânire",
        import_account_setup: "Contul dvs. nu este configurat pentru import",
        import_contact_us: 'Contact Us$',
        import_details: 'for details!$',
        import_isnt_setup: `PhysioAge a colaborat cu CNS, LabCorp, Quest, Health Diagnostics, Bio-Reference și Access pentru a obține lucrul trimis direct la software-ul nostru, evitând durerile de cap ale datelor de intrare.`,
        import_bloodwork: "Ne pare rău, putem importa numai rezultate legate de sânge în acest moment.",
        import_search: `Introduceți numele pacientului pentru care căutați. Vom afișa mesajele de corespondență pe care le-am primit în ultimele câteva luni. `,
        import_search_header: "Căutați după numele pacientului:",
        import_patient_notfound: "Niciun rezultat nu corespunde căutării dvs.",
        physiological_ages: 'Physiological Ages$',
        age_weights: 'Age Weights$',
        results_title: 'Review Results$',
        out_of_bounds: 'Out Of Bounds$',
        view: 'View$',
        no_recommandations_caution: 'There is not enough data to generate a visit summary at this time.$',
        panel_name: 'Panel name$',
        browse_library: 'Browse Library$',
        upload_btn: 'Upload$',
        all_downloads: 'View all downloads for this patient$',
        delete_visit_desc: "This will delete all the data associated with the visit$",
        historical_data_validation:'Sorry, there is no historical data for the current visit.$'
    },
    orders_labels: {
        delete_warning: 'Sigur stergeti comanda?',
        order_success: 'Adăugat o comandă pentru',
        panel_validate: "Panoul selectat adăugase deja pentru comanda",
        result: "rezultate",
        order_details: `Detalii comandă`,
        out_of_bounds_exception_one: "Ne pare rău, nu puteți trimite date care sunt depășite. Potrivit panoului nostru de medic, valorile în afara limitei sunt atât de anormale încât sunt cu siguranță greșite. ",
        out_of_bounds_exception_two: "Dacă aveți probleme la găsirea problemei, asigurați-vă că vă uitați la unități și la zecimale lipsă sau suplimentare.",
        out_of_bounds_header: "Unele date sunt în afara limitelor",
        close_btn: 'Închide',
        no_changes: 'No changes to save Results$',
        updated_panels: 'Successfully Updated Results$',
        baseline: 'Baseline$',
        one_year: '1 Year$',
        years: 'Years$',
        follow_up: 'FollowUp$',
        preset: 'Preset$',
        gap: 'Gap$',
        unfavourable: 'Unfavourable$',
        entry_date: 'Entry Date$',
        result_view: 'Result View$',
        new: 'New$',
        ordered_by: 'Ordered By:$',
        publication: 'Publication Date:$',
        lab_accession: 'Lab / Accession$',
        draw_date: 'Draw Date$',
        rpt_date: 'Rpt. Date$',
        message_count: '# Msgs$',
        imported_status: 'Imported$',
        wait_status: 'Wait$',
        import_headers: 'Importing Message(s)$',
        import_observations: 'Please wait while we process the following message. It can take up to 60 seconds per message as our system verifies each observation.$',
        import_failed: 'Import Failed.$',
        import_failed_alert: 'We have been alerted and will look in to it. We are sorry that this has happened.$',
        view_message: 'View Message$'
    },
    messages:
    {
        patient_add_success: "Patient was successfully created$",
        patient_edit_success: "Pacientul a fost actualizat cu succes",
        no_manuals_exist: "Nu există manuale existente",
        user_update_success: 'Utilizatorul a fost actualizat cu succes',
        user_add_success: "Utilizatorul a fost adăugat cu succes",
        message_routing_apply: "Vreau să mă uit la toate mesajele din anul trecut care nu au fost deja potrivite pentru a vedea care dintre ele se aplică.",
        help_add_success: "Șterse cu succes",
        elk_validation: "Nu puteți încărca un site prin Interfața Web",
        document_upload_success: "Încarcă documentul cu succes",
        help_document_validation: "Selectați un fișier PDF",
        select_visit_date: 'Selectați Vizitează data pentru a adăuga vizita',
        select_panel: 'Selectați Numele panoului pentru a adăuga Test',
        reset_password_mail_success: "Instrucțiunile de resetare a parolei au fost trimise la adresa dvs. de e-mail.",
        password_req: 'Este necesară parola',
        confirm_pwd_req: 'Este necesară confirmarea parolei',
        pic_validation_message: "Vă rugăm să introduceți fișierul imagine valid (png sau jpg / jpeg)",
        clinic_validation: "Acceptarea recomandărilor poate fi stabilită numai dacă site-ul este o clinică.",
        site_select_validation: 'Selectați atleast un site pentru a continua',
        password_updated: 'Parola a fost actualizată cu succes',
        email_req: 'Email ID is required$',
        payment_site_validation: `You don't have access to add credits for selected site$`,
        elk_validation_zero_credits: `A Site Cannot Buy Zero Credits$`,
        elk_validation_negative_credits: `A Site Cannot Buy Negative Credits$`,
        multiple_card_validation: 'Select only one card for payment$',
        feedback_validation: 'Enter feedback text$',
        no_site_providers: `You won't be able to create any patients until providers are set up for this site. Please contact your PhysioAge vendor$`,
        old_age_restriction: 'Date of Birth implies that patient is impossibly old.$',
        patient_access_msg: 'Patient can now access the portal.$',
        proper_date_validation: 'Enter date in proper format$'
    },
    payments_labels: {
        title_help: 'Add Credits for$',
        num_credits: 'Number of Credits$',
        existing_card: 'Select an Existing Card$',
        new_card: 'Or Enter a New One:$',
        card_number: 'Card Number$',
        cvc_label: 'CVC$',
        expiration: 'Expiration (MM/YY)$',
        save_btn: 'Save$',
        test_cc_title: 'Test CC Numbers$',
        test_cc_help: 'This card works with any three digit CVC and any future expiry date:$ ',
        empty_card: 'This test card is empty:$',
        credits_label: 'Credits$',
        exp_label: 'Expiration date$',
        back_to_site: 'Back to the Site$',
        max_credits_validation: 'Total Credits amount must not be more than $20,000$'
    },
    report_labels: {
        next_gen_exam: 'Next Generation Physical Exam$',
        prepared_for: 'prepared for$',
        by_provider: 'by provider$',
        tests_performed: ' based on tests performed$',
        table_contents: 'Table of Contents$',
        introduction: 'Introduction$',
        compare_system: 'Comparing Systems$',
        health_status_indicator: 'Health Status Indicators$',
        exceptional_results: 'Exceptional Results$',
        abnormal_results: 'Abnormal Results$',
        complete_data: 'Complete Data$',
        historical_data: 'Historical Data$',
        disclaimer: 'Disclaimer$',
        physioage: 'PhysioAge$',
        cutoage: 'CutoAge$',
        immunoage: 'ImmunoAge$',
        pulmoage: 'Pulmoage$',
        telomerage: 'TelomerAge$',
        cardioage: 'CardioAge$',
        neuroage: 'NeuroAge$',
        man_has_arteries: 'A man is as old as his arteries$',
        english_hypocrates: 'known as the English Hypocrates$',
        test: 'The Test$',
        factors_affect: 'What factors affect my $',
        importance: 'Importance$',
        readmore: 'readmore$',
        older_avg: 'years older than average for your age.$',
        younger_avg: 'years younger than average for your age.$',
        is_label: 'is$',
        test_not_performed: 'test not performed$',
        about_test: 'About the Test$',
        skin_aging: 'Why do I need a fancy instrument to tell me how well my skin is aging?$',
        skin_elasticity: 'Determining Skin Elasticity$',
        significance: 'Significance$',
        composite: 'Composite4',
        flag: 'Flag$',
        first_received: 'First Results Received$',
        last_received: 'Last Results Received$',
    },
    document_labels: {
        format: 'Format$',
        title: 'Title$',
        source: 'Source$',
        distributions: 'Distributions$',
        edit: 'Edit$',
        format_published: 'Format Published$',
        title: 'Title$',
        visit: 'Visit$',
        patient_downloads: 'Patient Downloads$',
        documents_text: 'To add documents, please go to the appropriate visit and find the "Upload" button.$',
        upload_document_for: 'Upload document for$',
        edit_document: 'Edit Document$',
        document_upload_optional: `<b>Optional. Will be set to today's date if you don't enter anything.</b> This field will be useful if you update this document in the future and what to know which is the most current. Once you have selected the year and month, you MUST click on the day.$`,
    },
    successfully_label: "a fost cu succes",
    added_label: "adăugat",
    updated_label: 'actualizat',
    required_label: "este necesar",
    email_help: 'Introduceți ID-ul e-mailului',
    credits_caution_title: 'Aveți credite rămase în rapoarte scrednumber rămase.',
    credits_caution_help: "Soldul dvs. de credit raportat pe PhysioAge Reporting a ajuns la scrednumber. Pentru a evita eventualele întârzieri, vă rugăm să adăugați mai multe credite utilizând acum cardul dvs. de credit. ",
    credits_caution_contact: 'Contactați-ne la întrebări.',
    acknowlegde: "Recunoaște",
    no_grid_data: 'Currently, there is no data to display$',
    pending: 'Pending$',
    no_data: 'No Data$',
    finished: 'Finished$',
    allowed_date_format: 'Enter date in <> format$',
    no_visits: 'No Visits$',
    no_summary_data: `I'm sorry, there was not enough medical data present to generate a summary. Typically this means your data has not been entered into our system yet. Please try again later.$`,
    pages: 'Pages$'
}

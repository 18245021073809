import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { resetPassword, loginInput } from '../../reducers/global-reducer'
import SessionExpire from './SessionExpire';

const mapStateToProps = state => ({
  ...state.global,
});

const mapDispatchToProps = {
  resetPassword, loginInput
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SessionExpire));

import {connect} from 'react-redux';
import Recommendations from '../components/Recommendations';

import { actionCreators } from '../modules/analyticsBeta';
const mapStateToProps = (state) => ({
    ...state.analytics
})
const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);

import React, { Component } from 'react';
import labels from '../../config/localization';
import constants from '../../config/constants';
import './tac-styles.scss';
import { Link } from 'react-router-dom';

class TermsAndConditions extends Component {
    componentDidMount() {
        document.title = `${labels.physioage_label}: ${labels.terms_and_conditions}`
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className='tac pb-0'>
                <h2 className='tac-title d-flex flex-row justify-center'>{labels.tac_labels.title1}</h2>
                <div className="d-flex flex-row justify-center mb-5"><h2 className='tac-title mr-5 ml-lg-0 ml-4'>{labels.tac_labels.title2}</h2> <h2 className='tac-title'>{labels.tac_labels.title3}</h2></div>
                <div dangerouslySetInnerHTML={{ __html: constants.terms_and_conditions }}></div>
            </div>
        )
    }
}

export default TermsAndConditions;
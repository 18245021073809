import React, { Component } from 'react';
import './statusCode-styles.scss'
import constants from '../../config/constants';
import labels from '../../config/localization';
import cardLogo from '../../assets/images/logo_physioage-horizontal.svg';

class StatusCode extends Component {
    componentDidMount() {
        document.title = `${labels.physioage_label}: ${this.props.match.params && this.props.match.params.id ? this.props.match.params.id : constants.status_codes.notfound}`
    }
    render() {
        const getStatus = (errorStatusCode) => {
            if (parseInt(errorStatusCode) != constants.status_codes.forbidden) {
                return (<div dangerouslySetInnerHTML={{ __html: constants.validaStatusCodes[errorStatusCode] }}></div>)
            } else if (parseInt(errorStatusCode) == constants.status_codes.forbidden) {
                return <div dangerouslySetInnerHTML={{ __html: constants.validaStatusCodes[errorStatusCode].replace('loggedinUsername', (this.props.loggedInUser && this.props.loggedInUser.id) ? `<span>(${this.props.loggedInUser.email})</span>` : '') }}></div>
            }
        }
        return (
            <div className='app wrapper unauth-layout' >
                <div className='card parent-card status-card' >
                    <div className='row'>
                        <img src={cardLogo} alt={'physioage-logo'} />
                    </div>
                    <div className='row'>
                        {getStatus(this.props.match.params && this.props.match.params.id ? this.props.match.params.id : constants.status_codes.notfound)}
                    </div>
                    <div className='row helptext-section text-center'>
                        <div className='d-flex justify-space-around'>
                            {/* <div>
                                <p>{labels.inside_us}</p>
                                <h2 className='text-center'>{constants.helpline_in}</h2>
                            </div> */}
                            <div>
                                {/* <p>{labels.outside_us}</p> */}
                                <h2 className='text-center'>{constants.helpline_out}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div >);
    }
}

export default StatusCode
import React, { Component } from 'react'
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import './feedback-styles.scss';
import constants from '../../../config/constants';
import PageTitle from '../../../components/PageTitle';

const breadCrumbs = [
    { text: labels.physioage_label, path: '/patients/list' },
    { text: labels.feedback_labels.title, path: '' }
]
export class Feedback extends Component {
    componentDidMount() {
        this.props.clearFeedback()
        this.props.toggleNotification(false)
        this.props.setBreadCrumbs(breadCrumbs);
        this.props.setPageTitle(labels.feedback_labels.title)
        document.title = `${labels.physioage_label}: ${labels.feedback_labels.title}`
        if (this.feedbackText && this.feedbackText.inputEntry)
            this.feedbackText.inputEntry.focus()
    }

    componentWillUnmount() {
        this.props.toggleNotification(false)
    }

    render() {
        return (
            <>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                        <PageTitle breadCrumbs={this.props.breadCrumbs}
                            pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                            navigatePatients={this.props.navigatePatients}
                            history={this.props.history}
                            {...this.props} />
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 feedback-children'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className='feedback-form col-lg-9 col-md-12 col-sm-12 col-xs-12'>
                        <p>{labels.feedback_labels.help_text}</p>
                        <FormControl required={'true'} type='textarea' id='feedback_text' ref={(ch) => this.feedbackText = ch} onChange={this.props.editFeedbackForm} />
                        <FormControl type='button' btnType={constants.notification_type.success} text={labels.feedback_labels.save_text}
                            onClick={() => this.props.submitFeedback(this.props.history)} />
                    </div>
                </div>
            </>
        )
    }
}

export default Feedback;
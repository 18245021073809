import { connect } from 'react-redux'
import Corrections from '../components/Corrections'
import { actionCreators } from '../modules/corrections'
import { setBreadCrumbs, setPageTitle, toggleNotification, navigatePatients, setCreditsCaution } from '../../../reducers/global-reducer';

const mapStateToProps = (state) => ({
    ...state.corrections,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify
})

const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    navigatePatients,
    setCreditsCaution
}

export default connect(mapStateToProps, mapDispatchToProps)(Corrections)
import axios from '../../../lib/axios';
import Axios from 'axios';
import API_URL from '../../../config/api';
import moment from 'moment';
import _ from 'lodash';
import constants from '../../../config/constants';
import en_labels from '../../../config/en_labels';

import {
    toggleLoading,
    toggleNotification,
    setPageTitle,
    setBreadCrumbs,
    handleErrors,
    setLoggedInUser,
    handleStatusCodes,
    setPatientReportStatus,
    noHeaderLoading
} from '../../../reducers/global-reducer';
import labels from '../../../config/localization';
import messages from '../../../config/messages';
import { setCurrentVisit } from '../../Visits/modules/visits';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import { isSafari, getValidSafariDate } from '../../../utils/download-utils';
import resolveSearchFilters from '../../../utils/resolve-search-params';
import { closeSideBar } from '../../Analytics/modules/analytics';
import { downloadCSV } from '../../../utils/download-csv-table';
import { getDateFormat, roundNumber } from '../../Analytics/tools/helpers';
import { downloadExcel, downloadExcelImproved } from '../../../utils/download-utils'
import cloneDeep from 'lodash/cloneDeep';


//actions
const SET_PROPS = 'SET_PROPS';
const SET_CURRENT_SITE = 'SET_CURRENT_SITE';

const requiredFields = {
    caregiver: ['firstname', 'lastname', 'gender', 'providerId', 'displayDate'],
    admin: ['firstname', 'lastname', 'gender', 'siteId', 'providerId', 'displayDate']
}
const requiredFieldText =
{
    firstname: labels.user_labels.firstname,
    lastname: labels.user_labels.lastname,
    gender: labels.user_labels.gender_label,
    siteId: labels.user_labels.site,
    providerId: labels.user_labels.provider_label,
    displayDate: labels.patient_list_labels.dob,
    no_provider: messages.no_site_providers
}
const attendeeDetails = {
    id: en_labels.patient_list_labels.patient_id,
    firstname: en_labels.user_labels.firstname,
    lastname: en_labels.user_labels.lastname,
    email: en_labels.user_labels.email,
    siteId: labels.user_labels.siteId,
    lastActiveDate: en_labels.user_labels.latest_visit_date
}
function handleLoading() {
    return (dispatch) => {
        let allPromises = promisesList.filter(p => p)
        Promise.all(allPromises).then(function (values) {
            if (allPromises.length && allPromises.length == values.length) {
                clearPromises()
                dispatch(toggleLoading(false))
            }
        });
    }
}
//action creators
export function getPatients(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        if (axios.defaults && axios.defaults.headers && !axios.defaults.headers.Authorization) {
            axios.defaults.headers[constants.authorization] = `${constants.bearer} ${localStorage.getItem(constants.accessToken)}`
        }

        let searchParam = { ...getState().patients.searchParam }
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSearchFilters
            }
        })
        if (history)
            setSearchParamUrl(searchParam, currentSearchFilters, history)

        promisesList.push(axios.post(API_URL.PATIENTS_SEARCH_URL, searchParam).then((patients) => {
            // dispatch(toggleNotification(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    totalPatients: 0,
                    no_grid_data: false,
                    isSearchCleared: false
                }
            })
            dispatch(setFirstTimeLoad(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    patients: patients.data ? patients.data.value : [],
                    totalPatients: patients.data ? patients.data.totalCount : 0,
                    totalVisits: patients.data ? patients.data.visitCount : 0,
                    visitAverage: patients.data ? patients.data.visitAverage : 0,
                    averageData: patients.data ? patients.data.averageData : 0,
                    no_grid_data: patients.data && patients.data.value && !patients.data.value.length ? true : false
                }
            })
            dispatch(handleLoading())
            // dispatch(toggleLoading(false))
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
        dispatch(filterNames())
    }
}
function setProvidersList() {
    return (dispatch, getState) => {
        let loggedInUser = getState().global && getState().global.loggedInUser ?
            getState().global.loggedInUser : null
        let currentPatient = { ...getState().patients.currentPatient }
        if (loggedInUser && !currentPatient.siteId && loggedInUser.siteId) {
            dispatch(setSiteProviders(loggedInUser.siteId))
        } else {
            axios.get(API_URL.USERS_URL).then((userresponse) => {
                if (userresponse.data && userresponse.data.siteId)
                    dispatch(setSiteProviders(userresponse.data.siteId))
            })
        }
    }
}
function deletePatient(toggleDeleteModal, patientId, history) {
    return (dispatch) => {
        if (patientId) {
            dispatch(toggleLoading(true))
            promisesList.push(axios.delete(`${API_URL.PATIENT}/${patientId}`).then(response => {
                if (history)
                    history.push(`/patients/list`)
                dispatch(handleLoading())
            }).catch(error => {
                dispatch(handleErrors(error))
            }))
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                isDeleteModal: toggleDeleteModal
            }
        })
    }
}

function setSiteProviders(siteId) {
    return (dispatch, getState) => {
        let currentPatient = { ...getState().patients.currentPatient, siteId: siteId }
        dispatch({
            type: SET_PROPS,
            payload: currentPatient
        })
        dispatch(getProvidersList(siteId))
    }
}
export function getSitesList() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        if (axios.defaults && axios.defaults.headers && !axios.defaults.headers.Authorization) {
            axios.defaults.headers[constants.authorization] = `${constants.bearer} ${localStorage.getItem(constants.accessToken)}`
        }
        promisesList.push(axios.get(API_URL.SITES_NAMES).then((sites) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    sitesList: sites.data
                }
            })
            // dispatch(handleLoading())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}
function toggleProvidersLoading(status) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                providers: [],
                isProvidersLoading: status
            }
        })
    }
}
export function getProvidersList(siteId, patientId) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        dispatch(toggleProvidersLoading(true))
        dispatch(toggleLoading(true))

        // promisesList.push(axios.get(API_URL.PATIENT_SITE_PROVIDERS, { params: { siteId } }).then(response => {
        if (siteId) {
            promisesList.push(axios.get(`${API_URL.PATIENT_SITE_PROVIDERS}?siteId=${siteId}&patientId=${patientId ? patientId : 0}`).then(response => {
                let currentPatient = { ...getState().patients.currentPatient, siteId }
                let loggedInUser = { ...getState().global.loggedInUser }
                if ((!currentPatient.languagePreference || !currentPatient.datePreference)) {
                    currentPatient = {
                        ...currentPatient,
                        languagePreference: loggedInUser.siteLanguagePreference,
                        datePreference: loggedInUser.siteDatePreference
                    }
                }
                if (response.data && response.data.length) {
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            providers: [...response.data],
                            isProvidersLoading: false,
                            currentPatient: currentPatient.providerId ? { ...currentPatient }
                                : { ...currentPatient, providerId: _.head(response.data).id },
                        }
                    })
                } else {
                    dispatch(toggleProvidersLoading(false))
                    if (parseInt(siteId))
                        dispatch(toggleNotification(true, constants.notification_type.error, '',
                            [messages.no_site_providers]))
                }
                dispatch(handleLoading())
            }).catch(errors => {
                dispatch(handleErrors(errors))
                dispatch(toggleProvidersLoading(false))
            }))
        } else {
            dispatch(toggleProvidersLoading(false))
            dispatch(toggleLoading(false))
        }
    }
}

function setHeader(details, breadcrumbType, isDocuments) {
    return (dispatch, getState) => {
        let currentPatient = getState().patients.currentPatient
        let loggedInUser = getState().global.loggedInUser
        if (!currentPatient.name && (currentPatient.firstname || currentPatient.lastname)) {
            currentPatient = { ...currentPatient, name: `${currentPatient.firstname} ${currentPatient.lastname}` }
        }
        if (breadcrumbType && breadcrumbType.indexOf('document') != -1) {
            dispatch(setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: `${currentPatient.lastname}, ${currentPatient.firstname}`, path: `/patients/${currentPatient.id}` },
                { text: labels.patient_downloads, path: '' }
            ]))
            if (isDocuments) {
                document.title = `${labels.physioage_label} : ${labels.document_list_for} ${currentPatient.lastname}, ${currentPatient.firstname}`
                dispatch(setPageTitle(`${labels.document_list_for} ${currentPatient.lastname}, ${currentPatient.firstname}`))
            } else if (breadcrumbType == 'upload-document') {
                document.title = `${labels.physioage_label} : ${labels.document_labels.upload_document_for} ${currentPatient.lastname}, ${currentPatient.firstname}`
                dispatch(setPageTitle(`${labels.document_labels.upload_document_for} ${currentPatient.lastname}, ${currentPatient.firstname}`))
            } else if (breadcrumbType == 'update-document') {
                document.title = `${labels.physioage_label} : ${labels.document_labels.edit_document}`
                dispatch(setPageTitle(labels.document_labels.edit_document))
            }
        }
        else if (details) {
            dispatch(setPageTitle(`${labels.patient_list_labels.patient_title} ${currentPatient ? currentPatient.name : ''}`))
            document.title = `${labels.physioage_label}: ${labels.patient_list_labels.patient_title} ${currentPatient ? currentPatient.name : ''}`
            dispatch(setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: currentPatient.site, path: loggedInUser.role == constants.logged_roles.CG ? '/patients/list' : `/sites/${currentPatient.siteId}` },
                { text: `${currentPatient ? `${currentPatient.lastname}, ${currentPatient.firstname}` : ''}`, path: '' }
            ]))
        } else {
            dispatch(setPageTitle(`${labels.patient_list_labels.edit_label} ${currentPatient.lastname}, ${currentPatient.firstname} `))
            dispatch(setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: currentPatient ? currentPatient.site : '', path: loggedInUser.role == constants.logged_roles.CG ? '/patients/list' : `/sites/${currentPatient.siteId}` },
                { text: currentPatient ? `${currentPatient.lastname}, ${currentPatient.firstname}` : '', path: `/patients/${currentPatient.id}` },
                { text: labels.patient_list_labels.edit_label, path: '' }
            ]))
            document.title = `${labels.physioage_label}: ${labels.patient_list_labels.edit_label} ${currentPatient.name}`
        }
        // dispatch(handleLoading())

        // setTimeout(() => dispatch(toggleLoading(false)), 1000)
    }
}
function setHeaderWithSites(details) {
    return (dispatch, getState) => {
        let currentPatient = getState().patients.currentPatient
        dispatch(toggleLoading(true))
        if (!details)
            dispatch(getProvidersList(currentPatient.siteId))
        promisesList.push(axios.get(API_URL.SITES_URL).then((sites) => {
            // dispatch(toggleLoading(true))
            dispatch({
                type: SET_CURRENT_SITE,
                payload: {
                    sitesList: sites.data,
                    site: _.head(sites.data.filter(s => s.id == currentPatient.siteId)).name
                }
            })

            dispatch(handleLoading())

            dispatch(setHeader(details))
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}
function getVisitAge(dob, visitdate) {
    // return Math.floor(Math.abs(moment(visitdate).diff(dob, 'years', true)))
    return roundNumber(Math.abs(moment(visitdate).diff(dob, 'years', true)), 1)
}
function setPatientVisits(patientId) {
    return (dispatch, getState) => {
        let currentPatient = getState().patients.currentPatient
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(API_URL.PATIENTS_VISTS_URL, { params: { patientId } }).then(response => {
            let aggregateAges = {}
            response.data.map((t) => {
                Object.keys(t.ageData).map(m => {
                    Object.keys(constants.biographical_labels.ageLabels).map(a => {
                        let key = constants.biographical_labels.ageLabels[a]
                        if (m.toLowerCase() == a.toLowerCase()) {
                            if (aggregateAges[key]) {
                                aggregateAges[key].push(t.ageData[m] != "0" ? t.ageData[m].toString() : "")
                            } else {
                                aggregateAges[key] = [];
                                aggregateAges[key].push(t.ageData[m] != "0" ? t.ageData[m].toString() : "")
                            }
                        }
                    })
                })
                if (aggregateAges.patientAges) {
                    aggregateAges.patientAges.push(getVisitAge(currentPatient.dob, t.visitDate))
                } else {
                    aggregateAges.patientAges = []
                    aggregateAges.patientAges.push(getVisitAge(currentPatient.dob, t.visitDate))
                }
            })
            let aggregateAgeUrl = constants.biographical_labels.artisan_route
            let res = Object.keys(_.omit(aggregateAges, ['patientAges'])).map(agg => {
                // if (agg != constants.true_biohealth_age && agg.toLowerCase() != constants.epigenage && agg.toLowerCase() != constants.glycanage) {
                var ages = aggregateAges[agg].map(s => {
                    if (s == "0") {
                        return s = ""
                    }
                    else {
                        return s
                    }
                })
                if (_.compact(ages).length > 0)
                    return `n:${agg}^v:${(ages).join(',')}^b:${aggregateAges.patientAges.join(',')}`
                else return null
                // }
            })

            aggregateAgeUrl = `${aggregateAgeUrl}?data=${_.compact(res).join('|')}`
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatient: { ...currentPatient, visitsData: response.data, aggregateAgeUrl },
                    no_grid_data: !(response.data.length) ? true : false
                }
            })
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}

export function setCurrentPatient(patientId, details, breadcrumbType, isDocuments, docId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatient: {},
                modalMode: null,
                isAddModal: false,
                currentDoc: null,
                isDeleteModal: false
            }
        })
        promisesList.push(axios.get(`${API_URL.PATIENTS_URL}/${patientId}`).then((response) => {
            axios.get(`${API_URL.SITE_URL}/${response.data.siteId}`).then((siteResponse) => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentSite: siteResponse.data
                    }
                })
            })
            var patientDetails = response.data
            let dob = response.data.dob;
            if (patientDetails.enableDocuments) {
                dispatch(setPatientFollowupDocuments(patientId))
            }
            if (patientDetails.datePreference == constants.safari_invalid_date_format && isSafari()) {
                dob = getValidSafariDate(dob)
            }
            patientDetails = { ...patientDetails, dob: new Date(dob) };
            let patients = { ...getState().patients };

            let name = response.data ? `${patientDetails.firstname} ${patientDetails.lastname}` : ''
            let displayDate = response.data.dob
            // let site = patients && patients.sitesList ? _.head(patients.sitesList.filter(s => s.id == patientDetails.siteId)).name : ''
            // promisesList.push(axios.get(`${API_URL.PATIENTS_URL}/${patientId}`).then((response) => {

            // }))
            let site = response.data.site
            let currentPatient = {
                ...patientDetails, name, site, displayDate,
                languagePreference: patientDetails.languagePreference
                    ? patientDetails.languagePreference : patientDetails.siteLanguagePreference,
                patientLanguagePref: patientDetails.patientLanguagePref
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatient,
                    isFirstTimeLoad: false
                }
            })
            if (details)
                dispatch(noHeaderLoading(true))
            if (isDocuments) {
                promisesList.push(axios.get(`${API_URL.DOCUMENT_WITH_GENERIC_ID}?id=${patientId}&ownedBy=patient`).then(response => {
                    dispatch(toggleLoading(false))
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            currentPatientDocumentsList: response.data.value
                        }
                    })
                }).catch(errors => {
                    dispatch(handleErrors(errors))
                    dispatch(handleStatusCodes(errors))
                }))
            }
            if (currentPatient.siteId) {
                if (!details && !isDocuments)
                    dispatch(getProvidersList(patientDetails.siteId, patientId))
                dispatch(setHeader(details, breadcrumbType, isDocuments))
            } else if (!isDocuments) {
                dispatch(setHeaderWithSites(details, breadcrumbType))
            }
            if (details && !isDocuments)
                dispatch(setPatientVisits(patientId))
            if (breadcrumbType == 'update-document' && docId)
                dispatch(getDocument(docId, patientId))
        }).catch((error) => {
            dispatch(handleStatusCodes(error))
            dispatch(handleErrors(error))
            dispatch({
                type: SET_PROPS,
                payload: {
                    isFirstTimeLoad: false
                }
            })
        }))

        // dispatch(getAggregateAges(patientId))
    }
}
export function getDocument(documentId, patientId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let documentUrl = patientId ? `${API_URL.DOCUMENT_WITH_ID}?documentId=${documentId}&type=patient&id=${patientId}`
            : `${API_URL.DOCUMENT_WITH_ID}?documentId=${documentId}`
        promisesList.push(axios.get(documentUrl).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentDoc: response.data.value
                }
            })
            dispatch(handleLoading())
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))
        }))
    }
}
export function savePatient(history) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        dispatch(toggleLoading(true))
        let loggedInUser = getState().global && getState().global.loggedInUser ?
            getState().global.loggedInUser : null
        let currentPatient = getState().patients.currentPatient
        let patientParams = {
            ...currentPatient, dob: currentPatient.displayDate,
            siteId: currentPatient.siteId ? currentPatient.siteId : loggedInUser.siteId ? loggedInUser.siteId : null
        }
        if (currentPatient.languagePreference != currentPatient.patientLanguagePref && currentPatient.patientLanguagePref != undefined && currentPatient.patientLanguagePref != null) {
            patientParams = { ...patientParams, languagePreference: currentPatient.patientLanguagePref }
        }
        let req = requiredFields[loggedInUser.role
            ? loggedInUser.role
            : constants.logged_roles.AN]
            .filter(rf => (!patientParams[rf] || parseInt(patientParams[rf]) == 0))

        if (patientParams.siteId
            && getState().patients.providers
            && !getState().patients.providers.length
            && !patientParams.providerId) {
            req = !req.find(x => x == 'providerId') ? req.push('no_provider') :
                req.map(x => { x == 'providerId' ? x = 'no_provider' : ''; return x })
        }
        if (req && req.length) {
            dispatch(toggleLoading(false))
            window.scrollTo(0, 0)
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                req.map(r => r != 'no_provider' ? `${requiredFieldText[r]} ${labels.required_label}` : `${requiredFieldText[r]}`)
                , false))
        } else if (patientParams && patientParams.dob && moment().diff(moment(patientParams.dob), 'year') > 150) {
            dispatch(toggleLoading(false))
            window.scrollTo(0, 0)
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [messages.old_age_restriction]))
        } else {
            dispatch(toggleNotification(false))
            if (!patientParams.languagePreference || parseInt(patientParams.languagePreference) == 0) {
                patientParams = { ...patientParams, languagePreference: null }
            }
            //honoric prefix has been removed
            // if (!patientParams.honorificPrefix || parseInt(patientParams.honorificPrefix) == 0) {
            //     patientParams = { ...patientParams, honorificPrefix: '' }
            // }

            // if (loggedInUser && loggedInUser.role == constants.logged_roles.CG) {
            //     patientParams = { ...patientParams, siteId: loggedInUser.siteId }
            // }

            // if (loggedInUser && !patientParams.languagePreference && !patientParams.id) {
            //     patientParams = { ...patientParams, languagePreference: loggedInUser.site ? loggedInUser.site.languagePreference : null }
            // }
            promisesList.push(axios[patientParams.id ? 'put' : 'post'](API_URL.PATIENTS_URL, { ...patientParams, userId: 0 }).then((response) => {
                // dispatch(handleLoading())
                if (response.status == constants.status_codes.success) {
                    if (response.data) {
                        dispatch(toggleNotification(true, constants.notification_type.success, '',
                            [messages.patient_add_success]
                            , false))
                        history.push(`/patients/${response.data}`)
                    }
                    else {
                        dispatch(toggleNotification(true, constants.notification_type.success, '',
                            [messages.patient_edit_success]
                            , false))
                        history.push(`/patients/${patientParams.id}`)
                    }
                } else {
                    dispatch(handleLoading())
                }
            }).catch((error) => {
                dispatch(handleErrors(error))
                dispatch(toggleLoading(false))

            }))
        }
    }
}

export function editPatientStatus(event) {
    return (dispatch, getState) => {
        let currentPatient = { ...getState().patients.currentPatient, [event.target.name ? event.target.name : event.target.id]: event.target.checked ? 1 : 0 }

        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatient: currentPatient
            }
        })
    }
}

export function editPatient(event) {
    return (dispatch, getState) => {

        if (getState().providers.length == 0) {
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [messages.no_site_providers]))
        }
        let currentPatient = { ...getState().patients.currentPatient, [event.target.name ? event.target.name : event.target.id]: event.target.value }

        if (event.target.id == "languagePreference") {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatient: { ...currentPatient, patientLanguagePref: currentPatient.languagePreference }
                }
            })
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatient: currentPatient
                }
            })
        }

        if (event.target.id == 'siteId') {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatient: { ...currentPatient, providerId: null }
                }
            })
            dispatch(getProvidersList(event.target.value, currentPatient.id))
        }

    }
}

export function setSearchParam(val, key, search, history, activePageNumber) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))

        let searchParam = Object.assign({}, getState().patients.searchParam)

        if (searchParam.searchFilters) {
            if (searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() == key.toLowerCase()).length) {
                searchParam.searchFilters.map(sf => {
                    if (sf.fieldName == key) { sf.fieldValue = val }
                })
            } else {
                searchParam.searchFilters.push({
                    fieldName: key,
                    fieldValue: val
                })
            }
        } else {
            searchParam.searchFilters = []
            searchParam.searchFilters.push({
                fieldName: key,
                fieldValue: val
            })
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: !search ? Object.assign({}, searchParam, { pageNumber: activePageNumber ? activePageNumber : 1 }) :
                    searchParam
            }
        })
        if ((!search && key == 'name' && val) || (!search && key == 'siteId') || (!search && key == 'status')) {
            dispatch(getPatients(history))
        }
    }
}
export function getNamesList() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        if (axios.defaults && axios.defaults.headers && !axios.defaults.headers.Authorization) {
            axios.defaults.headers[constants.authorization] = `${constants.bearer} ${localStorage.getItem(constants.accessToken)}`
        }
        promisesList.push(axios.get(API_URL.PATIENTS_NAMES_URL).then((responses) => {
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    namesList: responses.data
                }
            })
            dispatch(filterNames())
        }).catch((errors) => {
            dispatch(handleErrors(errors))

        }))
    }
}

function filterNames() {
    return (dispatch, getState) => {
        let allNames = getState() && getState().patients
            && getState().patients.namesList ? getState().patients.namesList.slice() : []
        let currentSearchFilters = getState() && getState().patients
            ? getState().patients.currentSearchFilters : null
        if (!currentSearchFilters) {
            return
        }

        if (currentSearchFilters.siteId && currentSearchFilters.siteId != '0') {
            allNames = allNames.filter(an => an.siteId == parseInt(currentSearchFilters.siteId))
        }
        if (currentSearchFilters.status && currentSearchFilters.status != 'all') {
            allNames = allNames.filter(an => an.active == (currentSearchFilters.status == 'active' ? 1 : 0))
        }
        if (currentSearchFilters.minimumvisits) {
            allNames = allNames.filter(an => an.visitCount >= parseInt(currentSearchFilters.minimumvisits))
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                filteredNamesList: allNames
            }
        })
    }
}

export function populateSearchParam(name, history) {
    return (dispatch, getState) => {
        let allNames = getState() && getState().patients ? getState().patients.filteredNamesList : []
        let currentNames = []
        if (name) {
            allNames.map(an => {
                if ((an.lastName.toLowerCase()+" "+ an.firstName.toLowerCase()).indexOf(name.toLowerCase()) != -1 && currentNames.indexOf(an.lastName + " " + an.firstName) == -1)
                    currentNames.push(an.lastName + " " + an.firstName)
                // if (an.firstName.toLowerCase().indexOf(name.toLowerCase()) != -1 && currentNames.indexOf(an.firstName) == -1)
                //     currentNames.push(an.firstName)
                // else if (an.lastName.toLowerCase().indexOf(name.toLowerCase()) != -1 && currentNames.indexOf(an.lastName) == -1)
                //     currentNames.push(an.lastName)

            })
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                currentNames,
                name
            }
        })
        dispatch(setSearchParam(name, 'name', name, history))
    }
}
export function clearNames() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNames: []
            }
        })
    }
}
export function clearNamesOnClick(event) {
    return (dispatch) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentNames: []
                }
            })
        }
    }
}
export function patientsPageChange(pageNumber, submit, history) {
    return (dispatch, getState) => {
        let searchParam = Object.assign({}, getState().patients.searchParam, { pageNumber })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (!submit)
            dispatch(getPatients(history))

    }
}
export function patientsSortOrder(sortOrder, sortField, history, submit) {
    return (dispatch, getState) => {
        sortOrder = constants.sorting_order[sortOrder]
        let searchParam = Object.assign({}, getState().patients.searchParam, { sortOrder, sortField })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (!submit)
            dispatch(getPatients(history))

    }
}
const getFormattedDate = (date, format) => {
    switch (format) {
        case 'ymd': return moment(date).format('YYYY/MM/DD');
        case 'dmy': return moment(date).format('DD/MM/YYYY');
        case 'mdy': return moment(date).format('MM/DD/YYYY');
        case 'ydm': return moment(date).format('YYYY/DD/MM');
        default: return moment(date).format('YYYY/MM/DD');
    }
}
// function getValidDate(displayDate) {

// var dateParts = new Date(displayDate).toLocaleDateString().split('/');
// if (isNaN(new Date(displayDate).getDate()))
//     return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
// else {
//     var charSplit = displayDate.indexOf('-') != 1 ? '-' : '/'
//     dateParts = displayDate.split(charSplit);
//     return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
// }
// }
export function editDateOfBirth(value, history, isSave) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))

        let currentPatient = { ...getState().patients.currentPatient }
        // var displayDate = value ? (currentPatient.datePreference == 'dmy' && isSave) ? getValidDate(value) : new Date(value).toLocaleDateString() : null
        var displayDate = value ? moment(value).format('L') : null
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatient: { ...currentPatient, dob: value, displayDate }
            }
        })


        // if (displayDate && isNaN(new Date(displayDate).getDate()) && displayDate.split('/').length > 1) {
        //     var dateParts = displayDate.split('/');
        //     var dateObject = new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`);
        //     displayDate = dateObject.toLocaleDateString();
        //     dispatch({
        //         type: SET_PROPS,
        //         payload: {
        //             currentPatient: { ...currentPatient, dob: value, displayDate }
        //         }
        //     })
        // } else if (isNaN(new Date(displayDate).getDate()) && isSave) {
        //     dispatch(toggleNotification(true, 'error', '', [messages.proper_date_validation], false))
        //     isSave = false
        // }
        // else {

        //     dispatch({
        //         type: SET_PROPS,
        //         payload: {
        //             currentPatient: { ...currentPatient, dob: value, displayDate }
        //         }
        //     })
        // }
        if (isSave) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatient: { ...currentPatient, displayDate }
                }
            })
            dispatch(savePatient(history))
        }
    }
}

function setSitesDetails(history) {
    return (dispatch, getState) => {
        let loggedInUser = getState().global.loggedInUser;
        dispatch(setSiteProviders(loggedInUser.siteId))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatient: { siteId: loggedInUser.siteId, languagePreference: loggedInUser.siteLanguagePreference, patientLanguagePref: loggedInUser.siteLanguagePreference, active: 1 },
                providers: [],
                currentSite: { id: loggedInUser.siteId, name: loggedInUser.siteName }
            }
        })
        history.push('/patients/new')
        dispatch(toggleLoading(false))
    }
}
export function newPatient(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let loggedInUser = getState().global.loggedInUser;
        if (loggedInUser && loggedInUser.siteId) {
            dispatch(setSitesDetails(history))
        }
        else {
            setTimeout(() => {
                let loggedInUser = getState().global.loggedInUser;
                dispatch(setSitesDetails(history))
            }, 1500);
        }
        
    }
}
export function sortPatients(key, order, history, pageNumber) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let searchParam = getState() && getState().patients && getState().patients.searchParam ? getState().patients.searchParam : {}
        let pageNumChanged = false
        if (searchParam && (!searchParam.sortField || searchParam.sortField != key)) {
            searchParam.sortField = key
            if (searchParam.sortField != key)
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        patients: []
                    }
                })
            pageNumChanged = true
            searchParam.pageNumber = 1
        }
        // searchParam.sortOrder = constants.sorting_order[order]
        // searchParam.pageNumber = 1
        if (searchParam.sortOrder != constants.sorting_order[order]) {
            searchParam.sortOrder = constants.sorting_order[order]
            searchParam.pageNumber = 1
        } else if (!pageNumChanged) {
            searchParam.pageNumber = pageNumber ? parseInt(pageNumber) : 1
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                ...searchParam
            }
        })
        // if ((searchParam.pageNumber == 1 && searchParam.sortField && searchParam.sortField.toLowerCase() == 'lastactivedate'
        //     && searchParam.sortOrder == -1)
        //     && !(searchParam.searchFilters ? searchParam.searchFilters.filter(s => parseInt(s.fieldValue) > 0).length : 0) && !hasParameters) {
        //     dispatch({
        //         type: SET_PROPS,
        //         payload: {
        //             hasParams: true
        //         }
        //     })
        //     history.push('/patients/list')
        // } else
        dispatch(getPatients(history))
    }
}
function setSearchParamUrl(searchParam, currentSearchFilters, history) {
    let searchParamUrl = '', params = ''
    searchParam.pageNumber = searchParam.pageNumber ? searchParam.pageNumber : 1
    searchParam.sortField = searchParam.sortField ? searchParam.sortField : 'LastActiveDate'
    searchParam.sortOrder = searchParam.sortOrder ? searchParam.sortOrder : -1
    if (currentSearchFilters.siteId && !parseInt(currentSearchFilters.siteId))
        currentSearchFilters.siteId = ''
    let filters = Object.keys(currentSearchFilters).filter(csf => currentSearchFilters[csf]).map(csf => `${csf}=${currentSearchFilters[csf]}`)
    searchParamUrl = filters.join('&')
    let paramFilters = Object.keys(searchParam).filter(csf => searchParam[csf])
        .map(csf => {
            if (csf != 'searchFilters' && csf != 'pageSize')
                if (csf == "sortOrder") {
                    if (!searchParam[csf])
                        return `${csf}=desc`
                    else
                        return `${csf}=${constants.sorting_order_decode[searchParam.sortOrder.toString()]}`
                }
                else if (csf == 'pageNumber') {
                    return `page=${searchParam.pageNumber}`
                }
                else {
                    return `${csf}=${searchParam[csf]}`
                }
        })
    params = _.compact(paramFilters).join('&')
    if (searchParamUrl) {
        searchParamUrl = (`?${searchParamUrl}&${params}`)
    } else {
        searchParamUrl = (`?${params}`)
    }
    return history.push(searchParamUrl)
}
export function clearFilters(isSearch) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortOrder: -1,
                    sortField: 'lastActiveDate',
                    searchFilters: []
                },
                currentNames: [],
                isSearchCleared: true
            }
        })
        if (isSearch)
            dispatch(getPatients())
    }
}
export function clearSearch(urlparams, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortField: 'lastActiveDate',
                    sortOrder: -1,
                    searchFilters: [{ fieldName: 'siteId', fieldValue: '0' }, { fieldName: 'status', fieldValue: 'active' }]
                },
                currentSearchFilters: {},
                currentNames: [],
                isSearchCleared: true
            }
        })
        if (urlparams && Object.keys(urlparams).length) {
            let { siteId, status, page, minimumvisits, name, sortOrder, sortField } = urlparams

            sortField = sortField ? sortField : 'lastActiveDate'
            sortOrder = sortOrder ? sortOrder : 'desc'
            if (siteId)
                dispatch(setSearchParam(siteId, 'siteId', false))

            if (name)
                dispatch(setSearchParam(name, 'name', false))

            if (minimumvisits)
                dispatch(setSearchParam(parseInt(minimumvisits), 'minimumvisits', false))

            if (page) {
                dispatch(patientsPageChange(parseInt(page), false))
            }
            if (sortField) {
                dispatch(patientsSortOrder(sortOrder, sortField, history, false))
            }
            if (status && constants.active_status.map(u => u.value).indexOf(status) != -1) {
                dispatch(setSearchParam(status, 'status', false, history, getState().patients.searchParam.pageNumber))
            }
        }

        dispatch(getPatients())
        dispatch(toggleNotification(false))
    }
}
export function searchCustomName(eve, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                name: eve && eve.target ? eve.target.value : '',
                currentNames: []
            }
        })
        let namesList = getState().patients.filteredNamesList
        let search = namesList.filter(n => (n.firstName == eve.target.value || n.lastName == eve.target.value)).length ? true : false
        dispatch(setSearchParam(eve && eve.target ? eve.target.value : '', 'name', search, history))
    }
}
export function navigatePatient(history, path) {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatient: {}
            }
        })
        history.push(path)
    }
}
export function addNewVisit(history) {
    return (dispatch, getState) => {
        var selectedVisitDate, visitError
        if (getState().patients && getState().patients.selectedVisitDate) {
            let validateDate = moment(new Date()).add(1, 'month').toDate()//.format(constants.dateFormats.ymd)
            selectedVisitDate = moment(getState().patients.selectedVisitDate).toDate()//.format(constants.dateFormats.ymd)
            if (validateDate >= selectedVisitDate) {
                let currentPatient = getState().patients.currentPatient
                let params = { PatientId: currentPatient.id, Date: selectedVisitDate.toDateString(), firstVisit: currentPatient.latestVisitId != 0 ? false : true }
                dispatch(toggleLoading(true))
                promisesList.push(axios.post(API_URL.VISIT_URL, { ...params }).then((response) => {
                    history.push(`/visits/${response.data}`)
                    selectedVisitDate = null
                    visitError = null
                    dispatch(toggleNotification(true, constants.notification_type.success, '',
                        [labels.visit_page_labels.visit_success]
                        , false))
                    dispatch(toggleVisitModal())
                    dispatch(setLoggedInUser())
                    dispatch(handleLoading())
                }).catch(errors => {
                    dispatch(toggleLoading(false))
                    visitError = errors.response && errors.response.data ? errors.response.data : ''
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            visitError: visitError ? typeof (visitError) == constants.string ? visitError : visitError.value ? visitError.value : null : null,
                            selectedVisitDate: null
                        }
                    })
                    if (errors.response && errors.response.status == constants.status_codes.unauthorized) {
                        dispatch(handleErrors(errors, API_URL.VISIT_URL, { ...params }, 'post'))
                    }
                }))
            }
            else {
                selectedVisitDate = null
                visitError = `${labels.patient_list_labels.visit_date} ${getState().patients.selectedVisitDate.toDateString()} ${labels.patient_list_labels.future_date}`
            }
        }
        else {
            visitError = messages.select_visit_date
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                visitError,
                selectedVisitDate
            }
        })
    }
}

export function elkmodal() {
    return (dispatch, getState) => {
        let elkdate = getState().patients.currentPatient.elkActivatedAt ? getState().patients.currentPatient.elkActivatedAt : ''
        let currentSite = getState().patients.currentSite
        let category = currentSite.billingStrategy != constants.billing_strategy_recurring ? true : false
        let credit = currentSite.credits
        let siteElks = null
        let date = elkdate ? moment().diff(moment(elkdate), 'months') : null;
        let hasElkActive = elkdate && date < 6 ? true : false
        let modalMode = constants.popup_labels.addVisit
        let visitError = null
        if (category) {
            if (hasElkActive) {
                siteElks = category ? labels.visit_page_labels.active_ell_caution : null
            }
            else if (credit < 1) {
                modalMode = constants.popup_labels.addCredits
                visitError = labels.visit_page_labels.credits_elk
            }
            else {
                siteElks = `${labels.visit_page_labels.elk_credits_one} ${credit} ${labels.visit_page_labels.elk_credits_two}`
            }
        }
        else {
            siteElks = null
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                modalMode,
                siteElks,
                visitError,
                currentSite
            }
        })

    }
}

function toggleVisitModal(id) {
    return (dispatch, getState) => {
        if (id) {
            dispatch(elkmodal())
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                isAddModal: getState() && getState().patients ? !getState().patients.isAddModal : false,
                selectedVisitDate: null
            }
        })
    }
}

function editVisit(eve) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                selectedVisitDate: eve
            }
        })
    }
}

export function navigateHealthAnalytics(history, path) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatient: getState().providers,
            }
        })
        dispatch(closeSideBar())
        history.push(path)
    }
}
// function toggleBiomarkersModal(rowId) {
//     return (dispatch, getState) => {
//         let currentRow = getState().patients && getState().patients.currentPatient
//             && getState().patients.currentPatient.visitsData
//             ? getState().patients.currentPatient.visitsData.filter(vd => vd.id == rowId) : {}

//         dispatch({
//             type: SET_PROPS,
//             payload: {
//                 currentRow: currentRow && currentRow.length ? _.head(currentRow) : {},
//                 isBiomarkersModal: !getState().patients.isBiomarkersModal
//             }
//         })

//     }
// }
function enablePatientPortal() {
    return (dispatch, getState) => {
        let currentPatient = getState().patients.currentPatient
        dispatch(toggleLoading(true))
        axios.post(`${API_URL.UPDATE_PATIENT_PORTAL_URL}?patientId=${currentPatient.id}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatient: { ...currentPatient, isUser: true }
                }
            })
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [messages.patient_access_msg]
                , false))
            dispatch(toggleLoading(false))
        }).catch(error => {
            dispatch(handleErrors(error))
        })
    }
}
function saveNewEmail() {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        if (getState().patients.currentPatient.newemail) {
            let currentPatient = { ...getState().patients.currentPatient, email: getState().patients.currentPatient.newemail, newemail: null }
            dispatch(toggleLoading(true))
            axios.put(API_URL.PATIENTS_URL, { ...currentPatient, userId: 0 }).then((response) => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentPatient
                    }
                })
                dispatch(toggleLoading(false))
            }).catch(error => {
                dispatch(handleErrors(error))
            })
        } else {
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [messages.email_req]
                , false))
        }
    }
}
function resendWelcomeEmail(history) {
    return (dispatch, getState) => {
        let currentPatient = getState().patients.currentPatient
        let loggedInUser = getState().global.loggedInUser
        dispatch(toggleLoading(true))
        if (loggedInUser && loggedInUser.role != constants.logged_roles.PT) {
            promisesList.push(axios.post(`${API_URL.RESEND_WELCOME_EMAIL}?patientId=${currentPatient.id}`).then(response => {
                dispatch(toggleLoading(false))
                dispatch(toggleNotification(true, constants.notification_type.info, '', [labels.patient_list_labels.resend_welcome_email], false))
            }).catch(error => {
                dispatch(handleErrors(error))
            }))
        } 
        // else {
        //     history.push('/patients/list')
        //     setTimeout(() => {
        //         dispatch(toggleNotification(true, 'warning', '',
        //             [labels.unauth_login]
        //             , false, 5000))
        //     }, 1000)

        // }
    }
}
function downloadPatientReport(visitId, history, pastData) {
    return (dispatch) => {
        // document.body.style.overflow = 'hidden'
        setTimeout(() => {
            dispatch(setPatientReportStatus(true))
        });
        dispatch(setCurrentVisit(visitId, history, true, undefined, undefined, pastData ? true : false, pastData ? "patientReportDownloadTemplatePast" : "patientReportDownloadTemplate"))

    }
}
function setFirstTimeLoad(status) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isFirstTimeLoad: status
            }
        })
    }
}
export function setPatientDocuments(patientId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.PATIENTS_DOCUMENTS_URL}?patientId=${patientId}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    allPatientDocuments: response.data
                }
            })
            dispatch(handleLoading())
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}
export function setPatientFollowupDocuments(patientId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.PATIENT_DOCUMENTS_VISITS}?patientId=${patientId}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatientDocuments: response.data.value ? _.orderBy(response.data.value, ['documentCreatedAt']) : []
                }
            })
            dispatch(handleLoading())
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}
function getAvatarType(avatarContent) {
    if (avatarContent)
        return avatarContent.split(',')[0].split(';')[0].split(':')[1]
    else return ''
}
export function patientDocumentInput(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentDoc = getState() && getState().patients ? getState().patients.currentDoc : {}
        if (eve.target.type == 'checkbox') {
            eve.target.value = eve.target.checked ? 1 : 0
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc: { ...currentDoc, [eve.target.id]: eve.target.value }
            }
        })
    }
}
export function handlePublicationDate(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentDoc = Object.assign({}, getState().patients.currentDoc, {
            publicationDate: eve
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc,
                publicationDate: eve
            }
        })
    }
}
export function postFile(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        if (uploadedFile) {
            let doctype = uploadedFile.type
            //if (constants.valid_doc_types_for_documents.indexOf(doctype) != -1) {
            var reader = new FileReader();
            reader.onload = function (e) {
                let avatarContent = e.target.result
                let currentDoc = getState().patients.currentDoc ? getState().patients.currentDoc : {}
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentDoc: Object.assign({}, currentDoc,
                            {
                                documentFile: avatarContent.split(',')[1],
                                fileContentType: doctype ? doctype : getAvatarType(avatarContent),
                                fileFileName: uploadedFile.name,
                                fileFileSize: uploadedFile.size,
                                title: getState().patients.currentDoc && getState().patients.currentDoc.title && getState().patients.currentDoc.title == uploadedFile.name.replace(/\.[^/.]+$/, '') ? getState().patients.currentDoc.title : uploadedFile.name.replace(/\.[^/.]+$/, '')
                            }),
                    }
                })
            }
            reader.readAsDataURL(eve.target.files[0]);
            // }
            // else {
            //     dispatch(toggleNotification(true, constants.notification_type.error, '', "select valid file", false))
            // }
        }
    }
}
function uploadDocument(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentDoc = { ...getState().patients.currentDoc }
        currentDoc = Object.assign({}, currentDoc,
            {
                patientId: getState().patients ? getState().patients.currentPatient.id : 0,
                visible: currentDoc.id ? parseInt(currentDoc.visible) : 1,
                sourceType: 'User',
                sourceId: getState().users ? getState().users.loggedInUser.id : 0,
                publicationDate: currentDoc.id ? (getState().patients.publicationDate ? getState().patients.publicationDate : currentDoc.publicationDate) :
                    currentDoc.publicationDate ? currentDoc.publicationDate : null
            })
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc
            }
        })
        if (currentDoc.title && currentDoc.title.length < 4) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    titleError: 'is too short (minimum is 4 characters)'
                }
            })
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                ['Title is too short (minimum is 4 characters)']))
            dispatch(toggleLoading(false))
        }
        else if ((currentDoc.documentFile || currentDoc.id) && currentDoc.title) {
            var params = cloneDeep(getState().patients.currentDoc);
            params.publicationDate = params.publicationDate ? moment(params.publicationDate).format('L') : params.publicationDate;
            promisesList.push(axios[getState().patients.currentDoc.id ? 'put' : 'post'](API_URL.DOCUMENT_URL, { ...params }).then(response => {
                dispatch(toggleLoading(false))
                history.push(`/patients/${getState().patients.currentPatient.id}/documents`)
            }).catch(errors => {
                dispatch(handleErrors(errors))
                dispatch(handleStatusCodes(errors))

            }))
        }
    }
}
function downloadPatientsAttendeesList() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let siteId = getState().patients.currentSearchFilters.siteId && getState().patients.currentSearchFilters.siteId != '0' ? getState().patients.currentSearchFilters.siteId : null
        const url = siteId ? `${API_URL.SITE_ATTENDEE_LIST}?id=${siteId}` : API_URL.SITE_ATTENDEE_LIST;
        promisesList.push(axios.get(url).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    siteAttendeesList: response.data
                }
            })
            const headers = Object.keys(attendeeDetails).map(r => attendeeDetails[r])
            let csv = [headers]
            if (response.data && response.data.length) {
                response.data.map(p => {
                    csv.push(
                        Object.keys(attendeeDetails).map(a => a == 'lastActiveDate' ? (p[a] ? moment(p[a]).format(getDateFormat('mdy')) : null) : p[a])
                    )
                })
            }
            downloadExcelImproved(csv, en_labels.header_labels.patients, constants.attendees_filename_xlsx)
            dispatch(toggleLoading(false))
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))
        }))
    }
}
//action handlers
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    },
    [SET_CURRENT_SITE]: (state, action) => {
        let currentPatient = { ...state.currentPatient, site: action.payload.site }
        return Object.assign({}, state, { currentPatient, sitesList: action.payload.sitesList })
    }
}

//default state
const initialState = {
    currentPatient: {},
    currentSite: {},
    searchParam: {
        pageSize: 10,
        pageNumber: 1,
        sortField: 'lastActiveDate',
        sortOrder: -1,
        searchFilters: []
    },
    currentNames: [],
    isColumnHidden: false,
    providers: [],
    isProvidersLoading: false,
    isAddModal: false,
    selectedVisitDate: null,
    visitError: null,
    siteElks: null,
    modalMode: null,
    isBiomarkersModal: false,
    providersLoaded: false,
    no_grid_data: false,
    isPatientReport: false,
    CreateDocumentList: []
}

//reducer
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

function setUuid() {
    const min = 1;
    const max = 1000;
    const rand = min + Math.random() * (max - min);
    return rand
}

export function setDocumentsFields() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    {
                        fileFileName: '',
                        title: '',
                        publicationDate: '',
                        uuid: setUuid(),
                        titleError: ''

                    }
                ]
            }
        })
    }
}

export function addDocumentFields() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...getState().patients.CreateDocumentList,
                    {
                        fileFileName: '',
                        title: '',
                        publicationDate: '',
                        uuid: setUuid(),
                        titleError: ''

                    }
                ]
            }
        })
    }
}

export function removeDocumentFields(uuid) {
    return (dispatch, getState) => {
        let CreateDocumentList = getState().visits.CreateDocumentList
        CreateDocumentList = CreateDocumentList.filter((cf) => cf.uuid != uuid)
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ]
            }
        })
    }
}

export function postDocumentFile(index, eve, uuid) {
    return (dispatch, getState) => {
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        let CreateDocumentList = getState().patients.CreateDocumentList ? getState().patients.CreateDocumentList : []
        if (uploadedFile) {
            let doctype = uploadedFile.type
            var reader = new FileReader();
            reader.onload = function (e) {
                let avatarContent = e.target.result
                let currentCreateDocumentList = []
                currentCreateDocumentList = {
                    ...getState().patients.CreateDocumentList[index],
                    ...{
                        documentFile: avatarContent.split(',')[1],
                        fileContentType: doctype ? doctype : getAvatarType(avatarContent),
                        fileFileName: uploadedFile.name,
                        fileFileSize: uploadedFile.size,
                        title: getState().patients.CreateDocumentList[index] && getState().patients.CreateDocumentList[index].title && getState().patients.CreateDocumentList[index].title == uploadedFile.name.replace(/\.[^/.]+$/, '') ? getState().patients.CreateDocumentList[index].title : uploadedFile.name.replace(/\.[^/.]+$/, '')
                    }
                }

                let fileError = false;
                let titleError = false;
                if (!fileError) {
                    if (!(currentCreateDocumentList.documentFile)) {
                        currentCreateDocumentList.fileError = "File can't be blank"
                        fileError = true
                    }
                    else {
                        currentCreateDocumentList.fileError = ""
                        fileError = false
                    }
                }

                if (!titleError) {
                    if (!currentCreateDocumentList.title) {
                        currentCreateDocumentList.titleError = "Title can't be blank"
                        titleError = true
                    }
                    else {
                        currentCreateDocumentList.titleError = ""
                        titleError = false
                    }
                }

                if (!titleError) {
                    if ((currentCreateDocumentList.title && currentCreateDocumentList.title.length < 4)) {
                        currentCreateDocumentList.titleError = 'Title is too short (minimum is 4 characters)'
                        titleError = true
                    }
                    else {
                        currentCreateDocumentList.titleError = ''
                        titleError = false
                    }
                }
                CreateDocumentList[index] = currentCreateDocumentList
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateDocumentList: [
                            ...CreateDocumentList
                        ]
                    }
                })
                dispatch(addDocumentFields())
            }
            reader.readAsDataURL(eve.target.files[0]);
        }
    }
}

export function handlePublicationDateDocumentFile(index, eve, uuid) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().patients.CreateDocumentList
        CreateDocumentList[index].publicationDate = eve
        if (CreateDocumentList[index].publicationDate && CreateDocumentList[index].fileFileName == "") {
            CreateDocumentList[index].fileError = "File can't be blank"
        }
        else {
            CreateDocumentList[index].fileError = ""
        }

        if (CreateDocumentList[index].publicationDate && CreateDocumentList[index].title == "") {
            CreateDocumentList[index].titleError = "Title can't be blank"
        }
        else {
            CreateDocumentList[index].titleError = ""
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
                publicationDate: eve
            }
        })
    }
}

export function handleTitleDocumentFile(index, eve, uuid) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().patients.CreateDocumentList
        CreateDocumentList[index].title = eve.target.value
        if (!CreateDocumentList[index].title) {
            CreateDocumentList[index].titleError = "Title can't be blank"
        }
        else {
            CreateDocumentList[index].titleError = ""
        }

        if ((CreateDocumentList[index].title && CreateDocumentList[index].title.length < 4)) {
            CreateDocumentList[index].titleError = 'Title is too short (minimum is 4 characters)'
        }
        else {
            CreateDocumentList[index].titleError = ''
        }
        if (CreateDocumentList[index].title && CreateDocumentList[index].fileFileName == "") {
            CreateDocumentList[index].fileError = "File can't be blank"
        }
        else {
            CreateDocumentList[index].fileError = ""
        }

        if (CreateDocumentList[index].title == "" && CreateDocumentList[index].fileFileName == "") {
            CreateDocumentList[index].titleError = ""
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
            }
        })
    }
}

function uploadDocumentList(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let CreateDocumentList = getState().patients.CreateDocumentList ? getState().patients.CreateDocumentList : []
        if (CreateDocumentList.length > 0) {
            CreateDocumentList.map((cdl, index) => {
                let currentDocument = []
                currentDocument = Object.assign({}, cdl,
                    {
                        patientId: getState().patients ? getState().patients.currentPatient.id : 0,
                        visible: cdl.id ? parseInt(cdl.visible) : 1,
                        sourceType: 'User',
                        sourceId: getState().users ? getState().users.loggedInUser.id : 0,
                        publicationDate: cdl.id ? (getState().patients.publicationDate ? getState().patients.publicationDate : cdl.publicationDate) :
                            cdl.publicationDate ? cdl.publicationDate : null
                    })
                CreateDocumentList[index] = currentDocument
            })

            let titleError = false;
            let fileError = false;

            CreateDocumentList.map((cdl) => {
                if (!(cdl.documentFile)) {
                    cdl.fileError = "File can't be blank"
                    fileError = true
                }
                else {
                    cdl.fileError = ""
                    fileError = true
                }
                if (!(cdl.title)) {
                    cdl.titleError = "Title can't be blank"
                    titleError = true
                }
                else {
                    cdl.titleError = ""
                    titleError = false
                }

                if (!titleError) {
                    if ((cdl.title && cdl.title.length < 4)) {
                        cdl.titleError = 'Title is too short (minimum is 4 characters)'
                        titleError = true
                    }
                    else {
                        cdl.titleError = ''
                        titleError = false
                    }
                }
            })

            let validatedFile = CreateDocumentList.filter((cf) => cf.titleError == "" && cf.fileError == "")
            let unValidFile = CreateDocumentList.filter((cf) => cf.fileFileName == "" && (cf.title != "" || cf.publicationDate != null))
            let blankRows = CreateDocumentList.filter((cf) => cf.fileFileName == "" && cf.title == "" && cf.publicationDate == null)
            let blankRowUuids = 0;
            if (CreateDocumentList.length > 1 && blankRows.length > 0) {
                blankRowUuids = blankRows.map((row) => row.uuid)
            }

            if ((titleError || fileError) && validatedFile.length !=  CreateDocumentList.filter((cf) => cf.uuid != [blankRowUuids]).length  || unValidFile.length > 0) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateDocumentList: CreateDocumentList.filter((cf) => cf.uuid != [blankRowUuids])
                    }
                })
                dispatch(toggleLoading(false))
            }
            else if (!fileError || validatedFile.length > 0 && unValidFile.length == 0) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateDocumentList: validatedFile
                    }
                })
                let CreateDocumentList = cloneDeep(validatedFile)
                let params = { CreateDocumentList }
                params.CreateDocumentList =
                    CreateDocumentList = CreateDocumentList.map(s => {
                        if (s.publicationDate) {
                            s.publicationDate = moment(s.publicationDate).format('L');
                            return s
                        }
                        else { return s }
                    })
                promisesList.push(axios['post'](API_URL.DOCUMENT_URL, { ...params }).then(response => {
                    dispatch(toggleLoading(false))
                    history.push(`/patients/${getState().patients.currentPatient.id}/documents`)
                    dispatch(clearDocumentsFields())
                }).catch(errors => {
                    dispatch(handleErrors(errors))
                    dispatch(handleStatusCodes(errors))
                    dispatch(toggleLoading(false))

                }))
            }
        }
        else {
            dispatch(toggleLoading(false))
        }
    }
}

export function clearDocumentsFields() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: []
            }
        })
    }
}

export function showImage(index) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().patients.CreateDocumentList
        CreateDocumentList[index].showImage = true
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
            }
        })
    }
}

export function hideImage(index) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().patients.CreateDocumentList
        CreateDocumentList[index].showImage = false
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
            }
        })
    }
}
export function deleteDocument(id, isCommonDocs) {
    return (dispatch, getState) => {
        if (id) {
            dispatch(toggleLoading(true))
            promisesList.push(axios.delete(`${API_URL.DOCUMENT_URL}/${id}`).then(response => {
                if (!isCommonDocs)
                    dispatch(setPatientFollowupDocuments(getState().patients.currentPatient.id))
                dispatch(getDocumentsOwnedByPatient())
            }).catch(error => {
                dispatch(handleErrors(error))
            }))
        }
    }
}
export function getDocumentsOwnedByPatient() {
    return (dispatch, getState) => {
        var patientId = getState().patients.currentPatient.id;
        promisesList.push(axios.get(`${API_URL.DOCUMENT_WITH_GENERIC_ID}?id=${patientId}&ownedBy=patient`).then(response => {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatientDocumentsList: response.data.value,
                    currentPatientDocuments: response.data.value
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))
        }))
    }
}
export function isPatientReportLoaded(loaded) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                patientReportLoaded: loaded
            }
        })
    }
}
export function addToTableOfContentsArray(ids) {
    return (dispatch, getState) => {
        let tableOfContents = [];
        ids.map(s => tableOfContents.push("##" + s))
        dispatch({
            type: SET_PROPS,
            payload: {
                tableOfContents: tableOfContents,
            }
        });
    }
}
export const actionCreators = {
    getPatients,
    getSitesList,
    setCurrentPatient,
    editPatient,
    editPatientStatus,
    editDateOfBirth,
    getProvidersList,
    savePatient,
    setSearchParam,
    populateSearchParam,
    patientsPageChange,
    getNamesList,
    newPatient,
    sortPatients,
    clearSearch,
    searchCustomName,
    navigatePatient,
    navigateHealthAnalytics,
    addNewVisit,
    editVisit,
    toggleVisitModal,
    // toggleBiomarkersModal,
    enablePatientPortal,
    resendWelcomeEmail,
    saveNewEmail,
    setProvidersList,
    downloadPatientReport,
    setFirstTimeLoad,
    clearFilters,
    clearNames,
    setPatientDocuments,
    setPatientFollowupDocuments,
    uploadDocument,
    postFile,
    patientDocumentInput,
    uploadDocument,
    handlePublicationDate,
    getDocument,
    deletePatient,
    downloadPatientsAttendeesList,
    clearNamesOnClick,
    setDocumentsFields,
    addDocumentFields,
    removeDocumentFields,
    postDocumentFile,
    handlePublicationDateDocumentFile,
    handleTitleDocumentFile,
    uploadDocumentList,
    clearDocumentsFields,
    showImage,
    hideImage,
    deleteDocument,
    isPatientReportLoaded,
    addToTableOfContentsArray,
    setLoggedInUser
}


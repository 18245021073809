import React from 'react';
import Sprite from './SpriteIcon';
import { uniformString } from '../tools/helpers';

class MetaRecommendations extends React.Component {

    saveRecommendation = (metaRec, index) => {
        this.props.deleteMetaRecommendation(index);
        this.props.saveRecommendation(this.props.history, this.props.isPlanModal, metaRec);
    }

    isNullOrEmpty(value) {
        return (value === null || value === "");
    }

    updatedMetaRecommendations(recommendations, metaRecommendations) {
        const newMetaRecommendations = Array.isArray(metaRecommendations) && Array.isArray(recommendations) ? metaRecommendations.filter(metaRecommendation => {
            return !recommendations.some(recommendation => {
                const matchBody = (this.isNullOrEmpty(recommendation.body) && this.isNullOrEmpty(metaRecommendation.body)) ? true : recommendation.body && metaRecommendation.body && 
                    recommendation.body.replace(/\s/g, '').toLowerCase() === metaRecommendation.body.replace(/\s/g, '').toLowerCase();

                const matchBrand = (this.isNullOrEmpty(recommendation.brand) && this.isNullOrEmpty(metaRecommendation.brand)) ? true : recommendation.brand && metaRecommendation.brand &&
                    recommendation.brand.replace(/\s/g, '').toLowerCase() === metaRecommendation.brand.replace(/\s/g, '').toLowerCase();

                const matchGroup = (this.isNullOrEmpty(recommendation.group) && this.isNullOrEmpty(metaRecommendation.group)) ? true : recommendation.group && metaRecommendation.group &&
                    recommendation.group.replace(/\s/g, '').toLowerCase() === metaRecommendation.group.replace(/\s/g, '').toLowerCase();

                return matchBody && matchBrand && matchGroup;
            });
        }) : []
        return newMetaRecommendations
    }
    render() {
        return (
            <>
                {this.props.metaRecommendations && this.props.recommendations && this.props.recommendations.length > 0 && this.props.metaRecommendations.length > 0 && this.updatedMetaRecommendations(this.props.recommendations, this.props.metaRecommendations) && this.updatedMetaRecommendations(this.props.recommendations, this.props.metaRecommendations).length > 0 ?
                <section className="page-section" id="metaRecommendation">
                    <div className="max-container jw-recommendation-container">
                        <h2 className="card-group-title">You may also Recommend</h2>
                        <div className="card">

                            {this.props.metaRecommendations.map((rec, index) => {
                                return (
                                    <div onMouseOver={() => { this.props.toggleMetaExpansion(index, true); }} onMouseOut={() => { this.props.toggleMetaExpansion(index, false); }} >
                                        <div className="jw-recommendation-card jw-recommendation-flex">
                                            <div className="jw-icon">
                                                <Sprite group="recommendations" label={rec.group} sprite={uniformString(rec.group)} />
                                            </div>
                                            <div className="jw-recommendation-content">
                                                <div className="jw-recommendation-body">{rec.body + " " + (rec.body.includes(rec.strength) ? "" : rec.strength ? rec.strength : "")}</div>
                                                {this.props.metaRecExpanded && this.props.metaRecExpanded[index] ? <div className="jw-recommendation-details">{`(based on ${rec.noumenaList})`}</div> : null}
                                            </div>
                                            <div className="d-flex align-items-center mr-4">
                                                <div className="icon md fill clickable edit meta-edit-style" title='Edit Recommendation' onClick={() => {
                                                    this.props.setMetaRecordIndex(index);
                                                    this.props.setMetaRecord(rec);
                                                    this.props.toggleMetaRecommendations(true);
                                                    this.props.togglePlanModal(true);
                                                    this.props.updateMetaRecommendations(index);
                                                }}>
                                                    <svg aria-hidden="true" title="Pencil">
                                                        <use xlinkHref="#pencil" xmlnsXlink="http://www.w3.org/1999/xlink"></use>
                                                        <symbol id="pencil" viewBox="0 0 486.219 485.22">
                                                            <path d="M467.476,146.438l-21.445,21.455L317.35,39.23l21.445-21.457c23.689-23.692,62.104-23.692,85.795,0l42.886,42.897 C491.133,84.349,491.133,122.748,467.476,146.438z M167.233,403.748c-5.922,5.922-5.922,15.513,0,21.436 c5.925,5.955,15.521,5.955,21.443,0L424.59,189.335l-21.469-21.457L167.233,403.748z M60,296.54c-5.925,5.927-5.925,15.514,0,21.44 c5.922,5.923,15.518,5.923,21.443,0L317.35,82.113L295.914,60.67L60,296.54z M338.767,103.54L102.881,339.421 c-11.845,11.822-11.815,31.041,0,42.886c11.85,11.846,31.038,11.901,42.914-0.032l235.886-235.837L338.767,103.54z M145.734,446.572c-7.253-7.262-10.749-16.465-12.05-25.948c-3.083,0.476-6.188,0.919-9.36,0.919 c-16.202,0-31.419-6.333-42.881-17.795c-11.462-11.491-17.77-26.687-17.77-42.887c0-2.954,0.443-5.833,0.859-8.703 c-9.803-1.335-18.864-5.629-25.972-12.737c-0.682-0.677-0.917-1.596-1.538-2.338L0,485.216l147.748-36.986 C147.097,447.637,146.36,447.193,145.734,446.572z"></path>
                                                        </symbol>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mr-4" title='Recommend'>
                                                <i className="fa fa-check meta-recommend-style" aria-hidden="true" onClick={() => { this.saveRecommendation(rec, index) }}></i>
                                            </div>
                                            <div className="d-flex align-items-center" title='Ignore'>
                                                <i className="fa fa-times meta-recommend-ignore-style" aria-hidden="true" onClick={() => { this.props.deleteMetaRecommendation(index, rec); }}></i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </section > : ''}
            </>
        )
    }
}

export default MetaRecommendations;
import React from 'react';
import _ from 'lodash';
// import SpriteIcon from './SpriteIcon';
import { uniformString } from '../tools/helpers';
import moment from 'moment';
import sprite from '../../../assets/images/sprite_base.svg'
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import constants from '../../../config/constants';
const SectionNav = (props) => {
  let isCurrent = _.includes(props.data.map(t => {
    if (new Date(props.latestVisitInfo) <= new Date(t.visitDate))
      return true;
    else
      return false
  }
  ), true)
  let myClass = "section-nav " + (isCurrent ? 'has-current-data' : '');
  return (
    <li className={myClass}>
      <Link to={uniformString(props.enName, 'section')} className='scrollable' spy={true}
        duration={500}
        offset={-80}
        onClick={() => props.toggleMenu()}
        smooth={true}>{props.sectionName}</Link>
    </li>
  )
}


export default class CategoryNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 'open': false };
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  componentDidMount() {
    Events.scrollEvent.register('begin', function () {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function () {
      console.log('end', arguments);
    });
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }
  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });

      scroller.scrollTo('scroll-container', {
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      })
    );
  }
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  renderSectionToggle() {
    if (!this.state['open']) {
      return (
        <svg aria-hidden='true' className='fill'>
          {/* <use xlinkHref={`${sprite}#slide-up`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
          <use xlinkHref="#slide-up" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
          <symbol id="slide-up" viewBox="0 0 16 6">
            <path fill="none" stroke-width="2px" d="M0,6L8,0L16,6"></path>
          </symbol>
        </svg>
      );
    }
    else {
      return (
        <svg aria-hidden='true' className='fill'>
          {/* <use xlinkHref={`${sprite}#slide-down`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
          <use xlinkHref="#slide-down" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
          <symbol id="slide-down" viewBox="0 0 16 6">
            <path fill="none" stroke-width="2px" d="M0,0L8,6L16,0"></path>
          </symbol>
        </svg>
      );
    }
  }

  renderSections() {
    if (this.state['open']) {
      return (
        <ul className='sections nav-stacked subnav' >
          {this.props.sections ? this.props.sections.map(section => <SectionNav {...section}
            sectionName={section.name}
            latestVisitInfo={this.props.latestVisitInfo}
            key1={uniformString(this.props.enName, section.enName, 'section_nav')}
            toggleMenu={this.props.toggleMenu} />
          ) : ''}
        </ul>
      )
    }
    else {
      return (null)
    }
  }
  onSetActive = () => {
    let ele = document.getElementById(`sidebar-${uniformString(this.props.enName)}`)
    if (ele)
      ele.scrollIntoView({ block: 'center' })
  }

  render() {
    var sections = []
    let isCurrent = this.props.sections.map(s => s.data.map(t => {
      if (new Date(this.props.latestVisitInfo) <= new Date(t.visitDate) && !constants.labelsForBioInfo.includes(t.code)) {
        sections.push(true)
      }
    }
    ))
    let myCat = "dropdown category-nav"
    if (sections && sections.length && _.includes(sections, true)) {
      myCat = `${myCat} has-current-data`;
    }
    let toggleMe = () => { this.setState({ 'open': !this.state['open'] }) }
    if (this.props.sections.length > 1) {
      return (
        <li className={myCat} id={`sidebar-${uniformString(this.props.enName)}`}>
          <Link className='scrollable spyable' to={uniformString(this.props.enName, 'category')} spy={true}
            duration={500}
            offset={-45}
            smooth={true} onClick={this.props.toggleMenu}
            onSetActive={this.onSetActive}>
            {this.props.categoryName}
          </Link>
          <div className='section-toggle' onClick={toggleMe}>
            {this.renderSectionToggle()}
          </div>
          {this.renderSections()}
        </li>
      );
    }
    else if (this.props.sections.length == 1) {
      return (
        <li className={myCat} id={`sidebar-${uniformString(this.props.enName)}`}>
          <Link className='scrollable spyable' to={uniformString(this.props.enName, 'category')} spy={true}
            duration={500}
            offset={-45}
            smooth={true} onClick={this.props.toggleMenu}
            onSetActive={this.onSetActive}>
            {this.props.categoryName}
          </Link>
        </li>
      );
    }
    else {
      return (null);
    }
  }
}



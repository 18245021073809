import React from 'react';
import PropTypes from 'prop-types';
import Rickshaw from 'rickshaw';
import chartTimeScale from '../tools/chartTimeScale';
import ChartTools from '../tools/ChartTools';

var chartOptions = {};

export default class BiomarkerSparkline extends React.Component {

  boundarySeries(timeBoundaries) {
    return {
      data: [
        { x: timeBoundaries['starting_timestamp'], y: null },
        { x: timeBoundaries['ending_timestamp'], y: null },
      ],
    };
  }
  plotGraph(props) {

    if (props.historic_data_values) {
      var padding = new ChartTools(props.historic_data_values, 0.35);
      let prepared_values = props.historic_data_values
        .map(ChartTools.rickshawCargo)
        .filter(ChartTools.hasValue);

      let start = props.timeBoundaries['starting_timestamp'];
      let ageLineStartingPoint = props.timeBoundaries['starting_timestamp'] - (props.timeBoundaries['ending_timestamp'] - props.timeBoundaries['starting_timestamp'])/(prepared_values.length+3);
      let ageLineEndingPoint = props.timeBoundaries['ending_timestamp'] + (props.timeBoundaries['ending_timestamp'] - props.timeBoundaries['starting_timestamp'])/(prepared_values.length+3);
      for (let i = 0; i < prepared_values.length; i++) {
          if(i == 0) {
            if(prepared_values.length == 1){
              prepared_values[i].x = (ageLineStartingPoint + ageLineEndingPoint)/2;
            }
            else{
              prepared_values[i].x = props.timeBoundaries['starting_timestamp'];
            }
          }
          else if( i == prepared_values.length-1){
            prepared_values[i].x = props.timeBoundaries['ending_timestamp'];
          }
          else{
              prepared_values[i].x = start + (props.timeBoundaries['ending_timestamp'] - props.timeBoundaries['starting_timestamp'])/(prepared_values.length-1);
              start += (props.timeBoundaries['ending_timestamp'] - props.timeBoundaries['starting_timestamp'])/(prepared_values.length-1);
          }
      }
      const extraLineSeries = {
        color: `${props.theme == 'dark-theme' ? '#F1F1F1' : '#3397C9'}`,
        data: [
          { x: ageLineStartingPoint, y: parseInt(_.first(props.historic_data_values).age)},
          { x: ageLineEndingPoint, y:  parseInt(_.last(props.historic_data_values).age)},
        ],
        dotSize: 0,
      };

      var graph = new Rickshaw.Graph({
        element: document.getElementById(props.uniqueID),
        width: 130,
        height: 30,
        renderer: 'lineplot',
        min: padding.plot_min > parseInt(_.first(props.historic_data_values).age) ? parseInt(_.first(props.historic_data_values).age) : padding.plot_min,
        max: parseInt(_.last(props.historic_data_values).age) > padding.plot_max ? parseInt(_.last(props.historic_data_values).age) : padding.plot_max,
        padding: {
          top: 0.02,
          left: 0.02,
          right: 0.02,
          bottom: 0.02,
        },
        series: [
          {
            color: '#000000',
            data: prepared_values,
            
          },
          extraLineSeries,
        ],
      });

      if (!isNaN(padding.plot_min) && !isNaN(padding.plot_max)) {
        graph.render();
      }

      graph.vis.selectAll('circle').remove();

      var series = graph.series;
      var vis = graph.vis;
      series.forEach(function (seriesData) {
        var nodes = vis.selectAll("x")
          .data(seriesData.stack.filter(function (d) { return d.y !== null }))
          .enter().append("svg:circle")
          .attr("id", function (d) { return d.datum ? `cir-${d.datum.code}` : '' })
          .attr("cx", function (d) { return graph.x(d.x) })
          .attr("cy", function (d) { return graph.y(d.y) })
          .attr('class', function (d) { return ( d.datum ? '' : `sparkline-age-circle`) })
          .attr("r", function (d) { return ("r" in d) ? d.r : 3 })
      });
    }
  }

  componentDidMount() {
    this.plotGraph(this.props);
  }
  componentDidUpdate() {
    if (document.getElementById(this.props.uniqueID)){
      document.getElementById(this.props.uniqueID).innerHTML = null;
    this.plotGraph(this.props)
    }
  }
  render() {
    if (this.props.historic_data_values) {
      return <div className="sparkline" id={this.props.uniqueID}></div>;
    } else {
      return false;
    }
  }
}

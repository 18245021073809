import StatusCode from './StatusCode'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const mapStateToProps = state => ({
  ...state.global,
});

export default connect(
  mapStateToProps
)(withRouter(StatusCode));
// export default StatusCode

import React, { useEffect, useState } from 'react';
import FormControl from '../FormControls/FormControl';
import './otp-screen.scss';
import labels from '../../config/localization';
import Notification from '../Notification';
import Loader from '../Loader';
import constants from '../../config/constants';
import cardLogo from '../../assets/images/logo_physioage-horizontal.svg';

  const EmailCode = (props) => {
    return (
      <div className='otp-container email-container'>
          <div className='pb-4 col-12'>
            <div className='label-1 pb-3'>
            {labels.user_labels.login_into_account}
            </div>
            <div className='label-2 pb-2'> {labels.user_labels.two_factor_auth_required}</div>
            <div className='label-3'>{labels.user_labels.send_6_digit_code} </div>
            <div className='label-4'> {props.email}</div>
          </div>
          <div className='col-12 no-gutters'>
            <div className='d-flex justify-center'>
              <div className='col-10 no-gutters p-0'>
                <FormControl
                  autoComplete="on"
                  type='text'
                  className='form-control mt-3'
                  id='otp'
                  placeholder={labels.user_labels.enter_the_code}
                  onChange={(e) => { props.setOTP(e.target.value);  props.toggleNotification(false) }}
                  value={props.otp}
                />
                {props.isNotify && props.notifications.title && ['VerifyOTP', 'VerifyEmail'].includes(props.notifications.title) && <Notification {...props.notifications} />}
              </div>
            </div>
            <div className='d-flex justify-center pt-4'>
              <button className='btn btn-primary col-10' onClick={() => props.onValidateOTP()}>
                {labels.login_btn_text}
              </button>
            </div>
            <div className='pt-2'>  
            {props.canResend ? (
              <button type="button" className="btn link" onClick={() => { props.resendOTP(); props.toggleNotification(false)}}>
                {labels.user_labels.resend_code}
              </button>
                  ) : (  
                    <div className='btn link'>{`${labels.user_labels.resend_code_in} ${props.counter} ${labels.user_labels.seconds}`}</div>
              )}
              { props.isAuthVerified && props.canResend ?
                <button type="button" className="btn link ml-2" onClick={() => { props.changeToAuth(); props.toggleNotification(false); props.setOTP()}}>
                  {labels.user_labels.authenticate_via_app}
                </button>
                : null
              }
              {/* { !props.isMFARequired ?
                <div className='pt-2'>  
                  <button type="button" className="btn link" onClick={() =>{props.handleSuceessDone()}}>
                    Skip
                  </button>
                </div>
              : null } */}
            </div>
          </div>
      </div>
    )
  }  

  const AuthCode = (props) => {
    return (
      <div className='otp-container'>
        <div className='pb-4 col-12'>
          <div className='label-1 pb-3'>
          {labels.user_labels.login_into_account}
          </div>
          <div className='label-2 pb-2'> {labels.user_labels.two_factor_auth_required}</div>
          <div className='label-3'>{labels.user_labels.enter_the_code_gen_by_auth} </div>
        </div>
        <div className='col-12 no-gutters'>
          <div className='d-flex justify-center'>
            <div className='col-10 no-gutters p-0'>
              <FormControl
                autoComplete="on"
                type='text'
                className='form-control mt-3'
                id='otp'
                placeholder={labels.user_labels.enter_the_code}
                onChange={(e) => { props.setOTP(e.target.value); props.toggleNotification(false)}}
                value={props.otp} 
              />
              {props.isNotify && props.notifications.title && ['VerifyOTP', 'VerifyEmail'].includes(props.notifications.title) && <Notification {...props.notifications} />}
            </div>
          </div>
          <div className='d-flex justify-center pt-4'>
            <button className='btn btn-primary col-10' onClick={() => props.onValidateOTP()}>
              {labels.login_btn_text}
            </button>
          </div>
          { props.isEmailVerified ?
            <div className='pt-2'>  
              <button type="button" className="btn link" onClick={() =>{ props.changeToEmail(); props.toggleNotification(false); props.resendOTP(); props.setOTP()}}>
                {labels.user_labels.authenticate_via_email}
              </button>
            </div>
           : null }
          {/* { !props.isMFARequired ?
           <div className='pt-2'>  
              <button type="button" className="btn link" onClick={() =>{props.handleSuceessDone()}}>
                Skip
              </button>
            </div> 
          : null } */}
        </div>
      </div>
    )  
  }  

const OtpScreen = (props) => {
  const [authMethod, setAuthMethod] = useState('');
  const [otp, setOTP] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isAuthVerified, setIsAuthVerified] = useState(false);
  const [email, setEmail] = useState('');
  const [mfa, setMfa] = useState('');
  const [isMFARequired, setIsMFARequired] = useState(true)
  const [counter, setCounter] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    const mfa = JSON.parse(localStorage.getItem('mfa'));
    setMfa(mfa)
    setIsMFARequired(storedUserData && storedUserData.isMFARequired)
    storedUserData && storedUserData.userMFA && storedUserData.userMFA.map(mfa => {
      if (mfa.mfaMode === 'authenticator') {
          setIsAuthVerified(mfa.isVerified);
      }
      if (mfa.mfaMode === 'email') {
          setIsEmailVerified(mfa.isVerified);
          // setEmail(mfa.mfaValue)
      }
    })
   if (!authMethod){ 
      storedUserData && storedUserData.userMFA && storedUserData.userMFA.map(mfa => {
          if (mfa.mfaMode === 'email' && mfa.isPrimary) {
            setAuthMethod('emailCode');
          } else if (mfa.mfaMode === 'authenticator' && mfa.isPrimary) {
            setAuthMethod('authCode');
          }
      })
    }
    setEmail(storedUserData && storedUserData.userName && storedUserData.userName)
    props.getAuthStatus()
    if (!props.isAuthenticated && window.location.href.indexOf('otp') == -1) {
      window.location.replace('/otp')
    }
    else if (props.isAuthenticated && localStorage.getItem(constants.accessToken)) {
      if (props.loggedInUser && (props.loggedInUser.role == 'admin' || props.loggedInUser.role == 'caregiver')
        && window.location.href.indexOf('patients/list') == -1) {
        window.location.replace('/patients/list')
      } else if (props.loggedInUser && (props.loggedInUser.role == 'patient')) {
        window.location.replace('/analytics')
      } else {
        window.location.replace('/otp')
      }
    }
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setCanResend(true);
    }
    const keyPressHandler = (event) => handleKeyPress(event, authMethod, otp);
    document.addEventListener('keydown', keyPressHandler);
    return () => {
                clearTimeout(timer);
                document.removeEventListener('keydown', keyPressHandler);
              }
        // componentWillUnmount() //
}, [counter, authMethod, otp]);

  const handleKeyPress = (event, authMethod, otp) => {
    if (event.keyCode === 13) {
      if (authMethod == 'emailCode')
        props.validateOTP('email', otp, false)
      else 
        props.validateOTP('authenticator', otp, false)
    }
  };

  const onValidateOTP = () => {
      props.validateOTP('email', otp, false)
  };

  const onValidateOTPQR = (e) => {
    props.validateOTP('authenticator', otp, false)
 };

  const changeToEmail = (e) => {
    setAuthMethod('emailCode')
  }

  const changeToAuth = (e) => {
    setAuthMethod('authCode')
  }

  const resendOTP = () => {
    props.sendOTP('email', email, false)
    setCounter(30);
    setCanResend(false);
  }

  return (
    <div className='app wrapper unauth-layout'>
      {props.isLoading ? <Loader /> : null}
        { !mfa && authMethod ?
          <div className='card parent-card'>
              <div className='row'>
                  <img src={cardLogo} alt={'physioage-logo'} />
              </div>
              <div className='row'>
                <div className="otp-screen">
                  { authMethod == 'emailCode' && <EmailCode {...props} onValidateOTP={onValidateOTP} resendOTP={resendOTP} setOTP={setOTP} otp={otp} changeToAuth={changeToAuth} isAuthVerified={isAuthVerified} email={email} isMFARequired={isMFARequired} counter={counter} canResend={canResend}  />}
                  { authMethod == 'authCode'  && <AuthCode {...props} onValidateOTP={onValidateOTPQR} changeToEmail={changeToEmail} setOTP={setOTP} otp={otp} resendOTP={resendOTP} isEmailVerified={isEmailVerified} isMFARequired={isMFARequired} />}
                </div>
              </div>
              <div className='row helptext-section text-center'>
                  <div className='d-flex justify-space-around'>
                      <div>
                        <h2 className='text-center'>{constants.helpline_out}</h2>
                      </div>
                  </div>
              </div>
          </div>
        : null }
    </div>
  );
};

export default OtpScreen;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import labels from '../../../config/localization';
import sample_age_charts from '../../../config/sample_age_charts';

class AggregateAgeCharts extends Component {
    componentDidMount() {
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.noumena_labels.aggregate_age_chart_title, path: '' }
        ])
        this.props.setPageTitle(labels.noumena_labels.aggregate_age_chart_title)
        document.title = `${labels.physioage_label}: ${labels.noumena_labels.aggregate_age_chart_title}`
    }
    render() {
        return (
            <>
                <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                <div className='sample-charts container col-lg-9'>
                    {sample_age_charts.map(sac => (
                        <React.Fragment>
                            <h3>{sac.title} {sac.visit ? <Link to={sac.visit.path}>{sac.visit.text}</Link> : ''}</h3>
                            <iframe className='agg-age-charts' src={sac.src}></iframe>
                        </React.Fragment>
                    ))}
                </div>
            </>
        )
    }
}
export default AggregateAgeCharts;
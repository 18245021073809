import { connect } from 'react-redux';
import ProblemListComponent from '../components/ProblemListComponent';
import { actionCreators } from '../modules/analyticsBeta';

let mapStateToProps = (state) => ({
  ...state.analytics
})

let mapDispatchToProps = {
  ...actionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemListComponent);

import React, { Component } from 'react';
import SpreadingRoutes from '../../../utils/SpreadingRoutes';

import './elk-styles.scss';
import PageTitle from '../../../components/PageTitle';

class CreditTransactions extends Component {
    componentWillUnmount() {
        this.props.toggleNotification(false)
    }

    render() {
        return (
            <div className={`elk-component row parent-component${this.props.isParentContainer ? ' container' : ''}`}>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                        <PageTitle breadCrumbs={this.props.breadCrumbs}
                            pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                            navigatePatients={this.props.navigatePatients}
                            history={this.props.history}
                            {...this.props} />
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 credits-transaction-children'>
                    <SpreadingRoutes {...this.props} />
                </div>
            </div>
        )
    }
}

export default CreditTransactions
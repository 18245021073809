import React from 'react';
import labels from '../../../config/localization';

export default class HistoricDataKey extends React.Component {
  constructor(props) {
    super(props)
    this.actuallyRender = this.actuallyRender.bind(this)
  }

  actuallyRender() {
    let age_line = ''
    if (this.props.should_render_age_line) {
      age_line = (
        <div className='chart-key-item'>
          {labels.analytics_labels.your_age_label}
          <svg>
            <g>
              <path stroke='#C3C3C3' strokeWidth='5' d='M10,10L50,10'></path>
            </g>
          </svg>
        </div>
      )
    }
    
    return (
      <div className='chart-key'>
        {age_line}
        <div className='chart-key-item'>
        {labels.analytics_labels.your_label} {this.props.noumenon_name}
          <svg>
            <g>
              <path stroke='grey' strokeWidth='2' d='M10,10L50,10'></path>
            </g>
          </svg>
        </div>
      </div>
    )
  }

  render() {
    // Leaving this here because of the possibility that we
    // would want to render a key in the absence of an age
    // line. Just change the following case-statement.
    if (this.props.should_render_age_line) {
      return this.actuallyRender()
    } else {
      return null
    }
  }
}

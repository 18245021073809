import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Loader from '../../../components/Loader';
import Recommendations from '../containers/RecommendationsContainer';
import PatientPdf from '../../../assets/sample pdfs/demo_female_patient_report.pdf';
import PatientPdfPast from '../../../assets/sample pdfs/demo_female_patient_report_past.pdf';
import PatientDemo2Pdf from '../../../assets/sample pdfs/demo_patient_report.pdf';
import demoSummary from '../../../assets/sample pdfs/demo_summary.pdf';
import demo2Summary from '../../../assets/sample pdfs/demo_2_summary.pdf'
import DocumentListComponent from './DocumentListComponent';
import constants from '../../../config/constants';
import labels from '../../../config/localization';


function ReportListComponent(props) {
    const downloadDemoReport = (filePath) => {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();
    }
    return (
        <div className='downloadable-list'>
            <div class="card download-library-card">
                <div class="card-header">
                {labels.analytics_labels.current_report}
                </div>
                <div class="card-body">
                    <h5 class="card-title">{labels.analytics_labels.patient_current_report}</h5>
                    <p class="card-text">This report contains the results of the current visit with recommendations made by provider.</p>
                    <a onClick={(event) => {
                        !props.isDemo ?
                            props.downloadPatientReport(props.presentPatient ? props.presentPatient.latestVisitId : null, props.history) :
                            downloadDemoReport(window.location.pathname.indexOf('demo_1') == 1 ? PatientPdf : PatientDemo2Pdf);
                    }} class="btn btn-primary">{labels.analytics_labels.download}</a>
                </div>
            </div>
            {props.allVisitData && props.allVisitData.length > 1 ?
                <div class="card download-library-card">
                    <div class="card-header">
                    {labels.analytics_labels.past_report} 
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{labels.analytics_labels.patient_past_report}</h5>
                        <p class="card-text">This report contains the results of all the past visits along with the current visit and recommendations made by provider.</p>
                        <a onClick={(event) => {
                            !props.isDemo ?
                                props.downloadPatientReport(props.presentPatient ?
                                    props.presentPatient.latestVisitId : null, props.history, true) :
                                downloadDemoReport(window.location.pathname.indexOf('demo_1') == 1 ? PatientPdfPast : PatientDemo2Pdf);
                        }} class="btn btn-primary">{labels.analytics_labels.download}</a>
                    </div>
                </div> : ''}
            {props.role == constants.logged_roles.PT && props.lastestVisitDataentry && props.lastestVisitDataentry.indexOf(true) == -1 ? null :
                <div class="card download-library-card">
                    <div class="card-header">
                    {labels.analytics_labels.summary}
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{labels.analytics_labels.visit_summary}</h5>
                        <p class="card-text">This report contains the summary of the current visit with recommendations made by provider.</p>
                        <a onClick={(event) => {
                            !props.isDemo ? props.handleDownloadVisitSummary(props.history) :
                                downloadDemoReport(window.location.pathname.indexOf('beta_demo_1') == 1 ? demoSummary : demo2Summary);
                        }} class="btn btn-primary">{labels.analytics_labels.download}</a>
                    </div>
                </div>}
        </div>
    );
}
export default connect()(ReportListComponent);
import moment from 'moment';
import 'moment/min/locales';

export function getLocalizedDate(date, preference, datePreference) {
    let resultantDate;
    let format;
    switch (datePreference) {
        case 'dmy': format = 'D MMMM YYYY'; break;
        case 'mdy': format = 'MMMM D, YYYY'; break;
        case 'ymd': format = 'YYYY MMMM D'; break;
        default: format = 'MMMM D, YYYY'; break;
    }
    if (date) {
        switch (preference) {
            case 'ja': resultantDate = moment(date).locale('ja').format(`${format} (ddd)`);
                break;
            default: resultantDate = moment(date).locale(preference ? preference : 'en').format(format);
                break;
        }
    }
    return resultantDate;
}
import {connect} from 'react-redux';
import BiomarkerAgingList from '../components/BiomarkerAgingList';

import { actionCreators } from '../modules/analyticsBeta';
const mapStateToProps = (state) => ({
    ...state.analytics
})
const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(BiomarkerAgingList);
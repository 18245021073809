import React, {useEffect, useState} from 'react';
import close from '../../../assets/images/close.svg';
import { connect } from 'react-redux';
import labels from '../../../config/localization';
import closeLight from '../../../assets/images/close-light.svg';
import Recommendations from './Recommendations';

function RecommendationListModel(props) {
    return (
        <div className='provider-notes treatment-plan treatment-plan-list'>
            <div className='drawer-warning'>
                <div className='d-flex row header no-gutters'>
                    <div className='col-11 bold-14-text'> {labels.analytics_labels.active_recommendation} </div>
                    <div className='col-1 justify-content-end d-flex'>
                        <a><img src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleRecommendationListModal(false))}></img></a>
                    </div>
                </div>
                <div className='card-body-div'>
                    <div className='drawer-body mt-2'>
                        <Recommendations readOnly={true} {...props} />
                    </div>
                </div>    
            </div>
        </div>
    )
}
export default connect()(RecommendationListModel);




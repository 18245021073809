import React, { Component } from 'react';


import './pagetitle-styles.scss';
import Breadcrumb from '../Breadcrumb';
import labels from '../../config/localization';
import constants from '../../config/constants';
import FormControl from '../FormControls';
import Notification from '../Notification';

export default class PageTitle extends Component {
    render() {
        return (
            <div className='page-title-section'>
                {this.props.showCreditsCaution && !this.props.isLoading
                    ? <div className='alert announcement'>
                        <h4><strong>{labels.credits_caution_title.replace('scrednumber', this.props.loggedInUser && this.props.loggedInUser.site
                            ? this.props.loggedInUser.site.credits : 0)}</strong></h4>
                        <p>{labels.credits_caution_help.replace('scrednumber', this.props.loggedInUser && this.props.loggedInUser.site
                            ? this.props.loggedInUser.site.credits : 0)}</p>
                        <p>{labels.credits_caution_contact}</p>
                        <div className='d-flex justify-space-around'>
                            {/* <div>
                                <p>{labels.inside_us}</p>
                                <h4 className='text-center'>{constants.helpline_in}</h4>
                            </div> */}
                            <div>
                                {/* <p>{labels.outside_us}</p> */}
                                <h4 className='text-center'>{constants.helpline_out}</h4>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <FormControl type='button' btnType={constants.notification_type.success} className='m-1'
                                text={labels.credits_labels.add_credits}
                                onClick={() => this.props.history.push(`/credits/${this.props.loggedInUser.siteId}/new`)} />
                            <FormControl type='button' btnType={constants.notification_type.success} className='m-1'
                                text={labels.acknowlegde}
                                onClick={() => this.props.setCreditsCaution(false)} />
                        </div>
                    </div>
                    : null}
                <React.Fragment>
                    {this.props.isLoading && !this.props.isHeaderSkeletonHidden && !this.props.isNoHeaderLoading ?
                        <div className='header-skeleton'>
                            <h1 className='title-loading-section'></h1>
                            <div className='breadcrumb-loading-section'></div>
                        </div> :
                        <>  <h1>{this.props.pageTitle}</h1>
                            {this.props.breadCrumbs && this.props.breadCrumbs.length ?
                                <Breadcrumb {...this.props} />
                                : null}
                            <h1>{this.props.subTitle}</h1></>
                    }
                </React.Fragment>
                {this.props.isNotify && (this.props.notifications.title ? !['VerifyOTP', 'VerifyEmail'].includes(this.props.notifications.title) : true) && !this.props.isLoading ? <Notification {...this.props.notifications} /> : null}
            </div>
        )
    }
}
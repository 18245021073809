import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DocumentListComponent from './DocumentListComponent';
import ReportListComponent from './ReportListComponent';

function DownloadLibraryComponent(props) {
    const [value, setValue] = React.useState("reports");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const downloadDemoReport = (filePath) => {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();
    }
    useEffect(() => {
        props.toggleCategoryDetailsModal(false)
        // componentDidMount //
    return () => {
        // componentWillUnmount() //
    }
    }, [])
    return (
        <>
            {props.categories && props.recommendations ?
                <div className='download-library'>
                    <div class="row header no-gutters b-bottom">
                        <div class="col-lg-9 col-md-8 col-sm-9 col-8">
                            <div class="heading-1">Download Library</div>
                        </div>
                    </div>
                    <div className='row no-gutters height-100'>
                        <Box className='height-100' sx={{ width: '100%', padding: '0px 10px 0px 30px', typography: 'body1' }}>
                            <TabContext value={value} className='height-100'>
                                <Box>
                                    <TabList onChange={handleChange} >
                                        <Tab label="Patient Reports" value="reports" />
                                        <Tab label="Documents" value="common_docs" />
                                    </TabList>
                                </Box>
                                <TabPanel value="reports" sx={{ padding: 0, height: '100%' }} >
                                    <ReportListComponent {...props} />
                                </TabPanel>
                                <TabPanel value="common_docs" sx={{ padding: 0, height: '100%' }} >
                                    <DocumentListComponent {...props} />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </div>
                : null}
        </>
    );
}
export default connect()(DownloadLibraryComponent);
import _ from 'lodash';
import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import { toggleLoading, toggleNotification, handleErrors, handleLoading, setBreadCrumbs, setPageTitle } from '../../../reducers/global-reducer';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import labels from '../../../config/localization';
import resolveSearchFilters from '../../../utils/resolve-search-params';
import constants from '../../../config/constants';
import moment from 'moment';


const SET_PROPS = 'SET_PROPS'

// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }

function getInboxMessages(history, isSort) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                // inboxMessages: [],
                //filteredMessages: [],
                totalCount: 0
            }
        })
        // if (!getState().inbox.date) {

        //     dispatch({
        //         type: SET_PROPS,
        //         payload: {
        //             date: ''
        //         }
        //     })
        //     dispatch(setActiveFilter('date', '', true))
        // }
        dispatch(toggleLoading(true))

        let searchParam = { ...getState().inbox.searchParam }

        searchParam.searchFilters ?
            searchParam.searchFilters.map(sf => {
                if (sf.fieldName == 'site') {
                    if (sf.fieldValue == 0) {
                        sf.fieldValue = null
                        return sf;
                    }
                }
            }) : null;
        searchParam.searchFilters ?
            searchParam.searchFilters.map(sf => {
                if (sf.fieldName == 'date')
                    sf.fieldValue = sf.fieldValue != null && sf.fieldValue != ''
                        ? moment(sf.fieldValue).format('L')//fill the value that has been set 
                        : ''//value taken as default
                return sf
            }) : null;
        let currentSearchFilters = searchParam.searchFilters ? resolveSearchFilters(searchParam.searchFilters) : {}
        if (history && !_.isEmpty(currentSearchFilters))
            setUrlParams(searchParam, currentSearchFilters, history)
        else if (history && isSort)
            setUrlParams(searchParam, currentSearchFilters, history)
        // let searchParam = { ...getState().inbox.searchParam }
        promisesList.push(axios.post(API_URL.INBOX_URL, { ...getState().inbox.searchParam }).then(response => {
            let filteredMessages = response.data && response.data.value ? response.data.value.inboxDTO : []
            let from = filteredMessages && response.data.value.inboxFilterDTO ? response.data.value.inboxFilterDTO.find(lab => lab.from == filteredMessages[0].from) : ''
            let fromValues = filteredMessages && filteredMessages.map(message => message.from);
            let uniqueFromValues = [...new Set(fromValues)];
            let totalcount = uniqueFromValues.length != 1 ? response.data && response.data.totalCount && response.data.totalCount : from ? from.count : 0
            dispatch({
                type: SET_PROPS,
                payload: {
                    // inboxMessages: response.data && response.data.value ? response.data.value : [],
                    filteredMessages: filteredMessages,
                    totalCount: totalcount,
                    no_grid_data: response.data && response.data.value && !response.data.value.length ? true : false,
                    messageFilters: response.data && response.data.value ? response.data.value.inboxFilterDTO : [],
                    totalMessagesCount: response.data && response.data.value && response.data.value.inboxFilterDTO ? _.sum(response.data.value.inboxFilterDTO.map(t => t.count)) : 0,
                    hl7NamesList: response.data && response.data.value ? response.data.value.hL7Names : [],
                    initialLoading: false,
                    isSearchCleared: false
                }
            })
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}

function clearFilters() {
    return dispatch => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortOrder: -1,
                    sortField: ''
                },
                currentSearchFilters: {},
                activeSender: null,
                isSearchCleared: true,
                date: '',
                currentHL7Names: []
            }
        })
    }

}
// function getMessageFilters() {
//     return (dispatch, getState) => {
//         promisesList.push(axios.get(API_URL.INBOX_MESSAGE_URL).then(response => {
//             dispatch({
//                 type: SET_PROPS,
//                 payload: {
//                     messageFilters: response.data,
//                     totalMessagesCount: _.sum(response.data.map(t => t.count))
//                 }
//             })
//             dispatch(handleLoading())
//         }).catch(errors => {
//             dispatch(handleErrors(errors))
//         }))
//     }
// }
function setActiveFilter(key, val, search, history) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let searchParam = Object.assign({}, getState().inbox.searchParam, { pageNumber: 1 })
        dispatch({
            type: SET_PROPS,
            payload: {
                [key]: val ? val : ''
            }
        })

        if (searchParam.searchFilters && searchParam.searchFilters.filter(sf => sf.fieldName == key).length) {
            searchParam.searchFilters.map(sf => {
                if (sf.fieldName == key) { sf.fieldValue = val }
            })
        } else {
            if (!searchParam.searchFilters) {
                searchParam.searchFilters = []
            }
            searchParam.searchFilters.push({
                fieldName: key,
                fieldValue: val
            })
        }
        let activeSender = searchParam.searchFilters && searchParam.searchFilters.find(filter => filter.fieldName == 'from')
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam,
                activeSender:  activeSender && activeSender.fieldValue ? activeSender.fieldValue : null
            }
        })
        let currentSearchFilters = searchParam.searchFilters ? resolveSearchFilters(searchParam.searchFilters) : {}
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSearchFilters
            }
        })
        // if (history && val)
        //     history.push(`?from=${val.replace(/ /g, '+')}`)
        // else if (history)
        //     history.push('/messages/inbox')
        if (!search)
            dispatch(getInboxMessages(history))
    }
}



function setUrlParams(searchParam, currentSearchFilters, history) {
    let searchParamUrl = '', params = ''
    searchParam.pageNumber = searchParam.pageNumber ? searchParam.pageNumber : 1
    searchParam.sortField = searchParam.sortField ? searchParam.sortField : ''
    searchParam.sortOrder = searchParam.sortOrder ? searchParam.sortOrder : -1
    if (currentSearchFilters.site && !parseInt(currentSearchFilters.site))
        currentSearchFilters.site = ''
    let filters = Object.keys(currentSearchFilters).filter(csf => currentSearchFilters[csf]).map(csf => {
        if (csf == 'date')
            return `${csf}=${encodeURIComponent(currentSearchFilters.date)}`
        else if (csf == 'from') {
            return `${csf}=${currentSearchFilters[csf].replace(/ /g, '+')}`
        }
        else
            return `${csf}=${currentSearchFilters[csf]}`
    })
    searchParamUrl = filters.join('&')
    let paramFilters = Object.keys(searchParam).filter(csf => searchParam[csf])
        .map(csf => {
            if (csf != 'searchFilters' && csf != 'pageSize')
                if (csf == "sortOrder") {
                    if (!searchParam[csf])
                        return `${csf}=desc`
                    else
                        return `${csf}=${constants.sorting_order_decode[searchParam.sortOrder.toString()]}`
                }
                else if (csf == 'pageNumber') {
                    return `page=${searchParam.pageNumber}`
                }
                else {
                    if (csf != 'sortOrder')
                        return `${csf}=${searchParam[csf]}`
                }
        })
    params = _.compact(paramFilters).join('&')
    if (searchParamUrl) {
        searchParamUrl = (`?${searchParamUrl}&${params}`)
    } else {
        searchParamUrl = (`?${params}`)
    }
    return history.push(searchParamUrl)
}

//For HL7Names typeahead

// export function getHL7NamesList() {
//     return (dispatch) => {
//         dispatch(toggleLoading(true))
//         promisesList.push(axios.get(API_URL.INBOX_HL7NAMESLIST).then((responses) => {
//             dispatch(handleLoading())
//             dispatch({
//                 type: SET_PROPS,
//                 payload: {
//                     hl7NamesList: responses.data,

//                 }
//             })
//             //dispatch(filterHL7Names())
//         }).catch((errors) => {
//             dispatch(handleErrors(errors))

//         }))
//     }
// }


export function populateHL7SearchNames(name, history) {
    return (dispatch, getState) => {
        let allNames = getState() && getState().inbox ? getState().inbox.hl7NamesList : []
        let currentHL7Names = []
        if (name) {
            allNames.map(an => {
                if (an.toLowerCase().indexOf(name.toLowerCase()) != -1 && currentHL7Names.indexOf(an) == -1)
                    currentHL7Names.push(an)
            })
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                currentHL7Names,
                name
            }
        })
        dispatch(setActiveFilter('name', name, true, history))
    }
}

export function searchCustomHL7Name(eve, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                name: eve && eve.target ? eve.target.value : '',
                currentHL7Names: []
            }
        })
        //let namesList = getState().inbox.currentHL7Names;
        //let search = namesList.filter(n => (n == eve.target.value)).length ? true : false
        dispatch(setActiveFilter('name', eve && eve.target ? eve.target.value : '', true, history))
    }
}

export function clearHL7Names() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentHL7Names: []
            }
        })
    }
}

function inboxPageChange(pageNumber, history, isSort) {
    return (dispatch, getState) => {
        pageNumber = pageNumber ? parseInt(pageNumber) : 1
        let searchParam = Object.assign({}, getState().inbox.searchParam, { pageNumber })
        //let activeSender = getState().inbox.activeSender
        //let queryString = window.location && window.location.search && window.location.search.substr(1)
        //queryString = queryString.replace(/(&page=[0-9]+)/g,'')

        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        // if (history && activeSender)
        //     history.push(`?from=${activeSender.replace(/ /g, '+')}&page=${pageNumber}`)
        // else if (history)
        //     history.push(`?page=${pageNumber}`
        if (!isSort) {
            if (searchParam.sortField && searchParam.sortField.length == 0) {
                if (history && !searchParam.searchFilters)
                    history.push(`?page=${pageNumber}`)
                dispatch(getInboxMessages(history))
            }
            else
                dispatch(getInboxMessages(history, true))
        }
    }
}

function setDefaultInboxParameters(urlparams, history) {
    return (dispatch, getState) => {
        //var date = getDefaultDate()
        //dispatch(setActiveFilter('date', urlparams.date ? new Date(urlparams.date) : date))
        // dispatch({
        //     type: SET_PROPS,
        //     payload: {
        //         searchParam: {
        //             pageSize: 10,
        //             pageNumber: 1,
        //             sortField: '',
        //             sortOrder: -1,
        //             searchFilters: [{ fieldName: 'site', fieldValue: null }, { fieldName: 'name', fieldValue: '' }]
        //         },
        //         clearHL7Names: []
        //     }
        // })
        // let currentSearchFilters = getState().inbox && getState().inbox.searchParam && getState().inbox.searchParam.searchFilters ? resolveSearchFilters(getState().inbox.searchParam.searchFilters) : {}
        // dispatch({
        //     type: SET_PROPS,
        //     payload: {
        //         currentSearchFilters
        //     }
        // })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortOrder: -1,
                    sortField: '',
                    searchFilters: [{ fieldName: 'site', fieldValue: 0 }]
                },
                currentSearchFilters: {},
                activeSender: null,
                isSearchCleared: true,
                date: '',
                currentHL7Names: []
            }
        })
        if (urlparams && (urlparams.from || urlparams.site || urlparams.page || urlparams.name)) {
            dispatch(toggleLoading(true))
            let sortField = urlparams.sortField ? urlparams.sortField : ''
            let sortOrder = urlparams.sortOrder ? urlparams.sortOrder : 'desc'
            if (urlparams.from) {
                dispatch(setActiveFilter('from', urlparams.from, true))
            }
            if (urlparams.site)
                dispatch(setActiveFilter('site', parseInt(urlparams.site), true))
            if (urlparams.name)
                dispatch(setActiveFilter('name', urlparams.name, true))
            if (urlparams.page)
                dispatch(inboxPageChange(parseInt(urlparams.page), false, sortField && sortField.length ? true : false))
            if (urlparams.sortField) {
                dispatch(inboxSortOrder(sortOrder, sortField, false, false))
            }
        }
        if (!urlparams.page)
            dispatch(getInboxMessages())
    }
}

function resetRanges() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                date: null,
                searchParam: {
                    pageSize: 10,
                    pageNumber: 1,
                    sortField: '',
                    sortOrder: -1,
                    searchFilters: [{ fieldName: 'site', fieldValue: null }, { fieldName: 'from', fieldValue: null }, { fieldName: 'name', fieldValue: "" }, { fieldName: 'date', fieldValue: '' }]
                },
                currentSearchFilters: {},
                currentHL7Names: []
            }
        })
        dispatch(getInboxMessages())
    }
}

export function setCurrentMessage(messageId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.INBOX_BODY_URL}/${messageId}`).then(response => {
            dispatch(handleLoading())

            dispatch({
                type: SET_PROPS,
                payload: {
                    currentMessage: response ? response.data : {},
                    messageBodyText: response.data ? response.data.body : '',
                    resolveMessage: response ? response.data : {}
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}
export function setCurrentMessageDetails(messageId, labName) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.MESSAGE_DETAILS}/${messageId}?lab=${labName}`).then(response => {
            dispatch(handleLoading())

            dispatch({
                type: SET_PROPS,
                payload: {
                    currentMessage: response ? response.data : {},
                    messageBodyText: response.data ? response.data.body : '',
                    resolveMessage: response ? response.data : {}
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}

export function launchDemoModal() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isDemoOpen: getState() && getState().inbox ? !getState().inbox.isDemoOpen : false
            }
        })
    }
}
function getSites() {
    return (dispatch) => {
        promisesList.push(axios.get(API_URL.SITES_LIST_URL).then((response) => {
            dispatch(handleLoading())

            dispatch({
                type: SET_PROPS,
                payload: {
                    sites: response.data && response.data.sitesDTOs ? response.data.sitesDTOs : []
                }
            })
        }).catch(error => {
            dispatch(handleErrors(errors))
        }))
    }
}
export function setActiveCategory(key, val, pageNum, history, getMessages, pushRoute) {
    return (dispatch, getState) => {
        let searchParam = Object.assign({}, getState().inbox.searchParam, { pageNumber: pageNum ? pageNum : 1 })
        if (key == 'resolved') {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isResolved: val,
                    current_category: val != "yes" ? "unresolved" : "resolved"
                }
            })
        }
        if (key == 'type') {
            dispatch(setHeader(val))
            dispatch({
                type: SET_PROPS,
                payload: {
                    current_type: val
                }
            })
        }
        if (searchParam.searchFilters && searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() == key.toLowerCase()).length) {
            searchParam.searchFilters.map(sf => {
                if (sf.fieldName == key) { sf.fieldValue = val }
            })
        } else {
            if (!searchParam.searchFilters) {
                searchParam.searchFilters = []
            }
            searchParam.searchFilters.push({
                fieldName: key,
                fieldValue: val
            })
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam,
                // isResolved: key == "resolved" ? val : getState().inbox.isResolved,
                no_grid_data: false,
                failureMessages: [],
                // current_category: category ? category : getState().inbox.category
            }
        })
        if (pushRoute) {
            dispatch(setSearchParamURL(history))
        }
        if (getMessages) {
            dispatch(getFailureMessages(history))
        }
    }
}
export function FailureMessagesPageChange(pageNumber, history, pushRoute) {
    return (dispatch, getState) => {
        let searchParam = Object.assign({}, getState().inbox.searchParam, { pageNumber })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam,
                failureMessages: [],
                no_grid_data: false
            }
        })
        if (pushRoute)
            dispatch(setSearchParamURL(history))

        dispatch(getFailureMessages(history))
    }
}
export function setDefaultFailureMessages(urlparams, history, pushRoute) {
    return (dispatch, getState) => {
        if (!urlparams || !urlparams.category)
            dispatch(setActiveCategory('resolved', 'no'))
        else
            dispatch(setActiveCategory('resolved', urlparams.category != "unresolved" ? "yes" : "no"))

        if (!urlparams || !urlparams.type)
            dispatch(setActiveCategory('type', 'unrouted'))
        else
            dispatch(setActiveCategory('type', urlparams.type))

        if (urlparams && urlparams.page)
            dispatch(FailureMessagesPageChange(parseInt(urlparams.page), history, true))

        if (pushRoute)
            dispatch(setSearchParamURL(history))

        dispatch(getFailureMessages(history))
    }
}
export function setSearchParamURL(history) {
    return (dispatch, getState) => {
        let category = getState().inbox.current_category ? getState().inbox.current_category : 'unresolved'
        let failureType = getState().inbox.current_type ? getState().inbox.current_type : 'unrouted'
        if (category && failureType && history) {
            history.push(`?type=${failureType}&category=${category}&page=${getState().inbox.searchParam.pageNumber}`)
        }
        else if (history) {
            history.push(`?page=${getState().inbox.searchParam.pageNumber}`)
        }
    }
}
export function getFailureMessages(history, pushRoute) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        // dispatch(setActiveCategory('Type', 'unrouted', getState().inbox.searchParam.pageNumber))
        let searchParam = !pushRoute ? { ...getState().inbox.searchParam } :
            Object.assign({}, getState().inbox.searchParam, { pageNumber: 1 })
        promisesList.push(axios.post(API_URL.FAILURE_MESSAGES, { ...searchParam }).then(response => {
            if (response.data && response.data.value && !response.data.value.length && getState().inbox.searchParam.pageNumber != 1) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        searchParam: Object.assign({}, getState().inbox.searchParam, { pageNumber: 1 }),
                        current_category: 'unresolved'
                    }
                })
                dispatch(getFailureMessages(history, true))
            }
            else
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        failureMessages: response.data && response.data.value ? response.data.value : null,
                        totalFailureMessagesCount: response.data && response.data.totalCount ? response.data.totalCount : 0,
                        no_grid_data: response.data && !response.data.length ? true : false
                    }
                })
            dispatch(handleLoading())
            if (pushRoute) {
                dispatch(setSearchParamURL(history))
            }
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}
export function resolveFailureMessage(event, row, urlparams, history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let loggedInUser = getState().global && getState().global.loggedInUser ?
            getState().global.loggedInUser : null

        axios.put(API_URL.RESOLVE_FAILURE_MESSAGES, { id: row.id, resolvedBy: loggedInUser.role }).then(response => {
            dispatch(setActiveCategory('resolved', 'no', getState().inbox.searchParam.pageNumber, history, true, true))
        }).catch(errors => {
            dispatch(handleErrors(errors))
        })
    }
}
export function setHeader(type) {
    return (dispatch, getState) => {
        if (type == 'unrouted') {
            dispatch(setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.inbox_labels.un_routed_messages, path: '' }
            ]))
            dispatch(setPageTitle(labels.inbox_labels.un_routed_messages))
            document.title = `${labels.physioage_label}: ${labels.inbox_labels.un_routed_messages}`
        }
        else if (type == 'invalid') {
            dispatch(setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.inbox_labels.invalid_messages, path: '' }
            ]))
            dispatch(setPageTitle(labels.inbox_labels.invalid_messages))
            document.title = `${labels.physioage_label}: ${labels.inbox_labels.invalid_messages}`
        }
    }
}

export function getSitesList() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        // if (axios.defaults && axios.defaults.headers && !axios.defaults.headers.Authorization) {
        //     axios.defaults.headers[constants.authorization] = `${constants.bearer} ${localStorage.getItem(constants.accessToken)}`
        // }
        promisesList.push(axios.get(API_URL.SITES_NAMES).then((sites) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    sitesList: sites.data
                }
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}

export function sortMessagesList(key, order, history, pageNumber) {
    //console.log(key, order);
    return (dispatch, getState) => {
        dispatch(toggleNotification(false));
        let searchParam = getState() && getState().inbox && getState().inbox.searchParam ? getState().inbox.searchParam : {}
        let pageNumChanged = false
        if (searchParam && (!searchParam.sortField || searchParam.sortField != key)) {
            searchParam.sortField = key
            if (searchParam.sortField != key)
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        //filteredMessages: []
                    }
                })
            pageNumChanged = true
            searchParam.pageNumber = 1
        }

        if (searchParam.sortOrder != constants.sorting_order[order]) {
            searchParam.sortOrder = constants.sorting_order[order]
            searchParam.pageNumber = 1
        } else if (!pageNumChanged) {
            searchParam.pageNumber = pageNumber ? parseInt(pageNumber) : 1
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                ...searchParam
            }
        })
        dispatch(getInboxMessages(history, true));
    }
}

export function inboxSortOrder(sortOrder, sortField, history, submit) {
    return (dispatch, getState) => {
        sortOrder = constants.sorting_order[sortOrder]
        let searchParam = Object.assign({}, getState().inbox.searchParam, { sortOrder, sortField })
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam
            }
        })
        if (!submit)
            dispatch(getInboxMessages(history, true))

    }
}

export function setInitialLoading(value) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                initialLoading: value
            }
        })
    }
}
export function editMessage(event) {
    return (dispatch, getState) => {
        let currentMessage = { ...getState().inbox.currentMessage, [event.target.name ? event.target.name : event.target.id]: event.target.value }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentMessage
            }
        })
    }
}
export function updateMessage(history, shouldImport) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentMessage = { ...getState().inbox.currentMessage }
        let updateMessageDTO = { id: currentMessage.id, siteId: currentMessage.siteId, body: currentMessage.body, 
            shouldImport: shouldImport ? shouldImport : false }
        promisesList.push(axios.put(API_URL.INBOX_UPDATEMESSAGE, { ...updateMessageDTO }).then(response => {
            dispatch(toggleLoading(false))
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                !shouldImport ? ['Message have been updated'] : ['Message have been reprocessed. It may take some time to show up in the results.'], false, 3000))
            history.push('/messages/details/' + updateMessageDTO.id)
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {

    searchParam: {
        pageSize: 10,
        pageNumber: 1,
        sortOrder: -1,
        sortField: ''
    },
    isDemoOpen: false,
    sites: [],
    activeSender: null,
    filteredMessages: [],
    no_grid_data: false,
    current_category: 'unresolved',
    initialLoading: false
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}
export const actionCreators = {
    getInboxMessages,
    inboxPageChange,
    // getMessageFilters,
    setActiveFilter,
    launchDemoModal,
    getSites,
    setCurrentMessage,
    clearFilters,
    setCurrentMessageDetails,
    setDefaultInboxParameters,
    setDefaultFailureMessages,
    resolveFailureMessage,
    FailureMessagesPageChange,
    setActiveCategory,
    getSitesList,
    //setFilterParam,
    //getHL7NamesList,
    populateHL7SearchNames,
    searchCustomHL7Name,
    clearHL7Names,
    resetRanges,
    sortMessagesList,
    inboxSortOrder,
    setInitialLoading,
    editMessage,
    updateMessage
}
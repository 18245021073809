import axios from 'axios';
import constants from '../config/constants';
import API_URL from '../config/api';


let apiConfig = window._env_ || {};
const headers = {}
const auth_token = localStorage.getItem('access_token')

if (auth_token) {
  headers['Authorization'] = `Bearer ${auth_token}`
}
let instance = axios.create({
  baseURL: apiConfig.API_BASE_URL || '',
  headers
})
instance.interceptors.request.use(function (config) {
  const auth_token = localStorage.getItem('access_token')
  config.headers.Authorization = `Bearer ${auth_token}`
  return config;
}, function (error) {
  return Promise.reject(error);
});
export default instance;
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import _, { max, floor } from 'lodash';
import Grade from './Grade';
import SpriteIcon from './SpriteIcon';
import { spriteForBiomarker } from '../tools/biomarkers';
import Rickshaw, { yearsDiff } from '../tools/rubricplot'
import ChartTools, { filled_age_line_series, is_biomarker_of_aging, chartWidth } from '../tools/ChartTools';
import { setSitePreference } from '../tools/calendar';
import labels from '../../../config/localization';
import chartTimeScale from '../tools/chartTimeScale';
import constants from '../../../config/constants';
import { getRoundedValue } from '../tools/helpers';
import tru from '../../../../src/tru.svg'

class Hover extends Rickshaw.Graph.HoverDetail {
  static propTypes = {
    datum: PropTypes.object.isRequired,
  };
}
export default class HistoricDataChart extends React.Component {
  static propTypes = {
    componentBiomarkersByVisitDate: PropTypes.object,
    dob: PropTypes.string.isRequired,
    noumenon_name: PropTypes.string.isRequired,
    historic_data: PropTypes.array.isRequired,
    suggested_low: PropTypes.number,
    suggested_high: PropTypes.number,
  };

  componentDidMount() {
    this.plot = this.plot.bind(this);
    this.plot_data = this.plot_data.bind(this);
    this.plot();
  }

  componentDidUpdate() {
    this.plot();
  }

  shouldRenderAndPlot() {
    return (this.props.historic_data ? this.props.historic_data.length >= 2 : 0);
  }

  plot() {
    if (this.shouldRenderAndPlot()) {
      this.actuallyPlot();
    }
  }
  getLowestReferenceRange(referenceRange) {
    let finalValue = 0
    if (referenceRange) {
      if (referenceRange.indexOf('<') != -1) {
        finalValue = referenceRange.substr(0, referenceRange.lastIndexOf('<'))
      } else if (referenceRange.indexOf('>') != -1) {
        finalValue = referenceRange.substr(referenceRange.lastIndexOf('>') + 1)
      } else if (referenceRange.indexOf('-') != -1) {
        finalValue = referenceRange.substr(0, referenceRange.lastIndexOf('-'))
      }
    }
    return finalValue
  }
  getSuggestedLimits(historic_data) {
    var suggested_low = 0
    var suggested_high = null
    if (historic_data && historic_data.length) {
      if (this.props.drawerType == "reportCard" || this.props.type == 'pointed') {
        suggested_low = 0
        suggested_high = 5
      }
      //if biomarkers age
      else if (Object.values(constants.biographical_labels.ageLabels).indexOf(_.head(historic_data).label) != -1) {
        suggested_low = 20
        suggested_high = 90
      } else {
        let topRubricRanges = _.head(historic_data).noumenonRubricRanges
        //if rubricranges are morethan two ranges
        if (topRubricRanges && topRubricRanges.length > 2) {
          let sortedRubricRanges = topRubricRanges.sort(function (a, b) { return a.low - b.low })
          let topRange = _.last(sortedRubricRanges)
          let lowRange = _.head(sortedRubricRanges)
          historic_data = historic_data.map(hd => {
            // hd.value = hd.value.replace('<', '').replace('>', '').replace('=', '')
            return hd
          })
          //if top range is open ended
          if (topRange.low && !topRange.high) {
            //higher of top range's low value, highest observed value
            suggested_high = Math.max(parseInt(topRange.low), Math.max(...historic_data.map(t => parseInt(t.value)), 0))
          }
          //if top range is not open ended
          else if (topRange.low && topRange.high) {
            //higher of top range's high value, highest observed value
            suggested_high = Math.max(parseInt(topRange.high), Math.max(...historic_data.map(t => parseInt(t.value)), 0))
          }
          suggested_low = Math.min(0, Math.min(...historic_data.map(t => parseInt(t.value)), 0),
            lowRange.low, this.getLowestReferenceRange(_.head(historic_data).referenceRange))
        }
        else {
          suggested_high = Math.max(...historic_data.map(t => parseInt(t.value)), 0)//highest observed value
          suggested_low = Math.min(0, Math.min(...historic_data.map(t => parseInt(t.value)), 0), this.getLowestReferenceRange(_.head(historic_data).referenceRange))
        }
      }
    }
    return { suggested_high, suggested_low }
  }
  plot_data(data) {
    var helper = new ChartTools(data);
    // TODO: This may not be needed as DrawerComponent already filters out nonBlankValue and others likely do as well. Will require research, though.
    let prepared_values = _.orderBy(data, ['visitDate']).map(ChartTools.rickshawCargo).filter(ChartTools.hasValue);
    let multiplier = (10 ** data[0].scale) || 1000;

    let drawer = document.getElementById('drawer-body');
    var y_axis = drawer.querySelector('.y_axis');
    if (drawer.querySelector('.chart_container .chart_body')) {
      drawer.querySelector('.chart_container .chart_body').innerHTML = ''
    }
    if (drawer.querySelector('.chart_container .y_axis')) {
      drawer.querySelector('.chart_container .y_axis').innerHTML = ''
    }
    let width = chartWidth();
    let height = width * 0.4;


    // if (y_axis)
    //   width = width - y_axis.offsetWidth;

    let dob = new Date(this.props.presentPatient ? this.props.presentPatient.dob : '')
    if (this.props.biomarkerAgesList ? this.props.biomarkerAgesList.indexOf(this.props.noumenon_name ? this.props.noumenon_name.toLowerCase() : "") != -1 : null) {
      var series = filled_age_line_series(dob, data, this.props.noumenon_name)
      let first_age = yearsDiff(new Date(data[0].visitDate), dob)
      let last_age = yearsDiff(new Date(), dob)
      if (first_age < helper.plot_min) {
        helper.plot_min = first_age
      }
      if (last_age > helper.plot_max) {
        helper.plot_max = last_age
      }
    } else {
      var series = [
        {
          color: 'grey',
          data: prepared_values,
          renderer: 'rubricplot_with_line',
          isBeta: false
        }
      ]
    }
    let { suggested_high, suggested_low } = this.getSuggestedLimits(this.props.historic_data)
    if (prepared_values.length >= 2) {
      if (helper.plot_min > suggested_low)
        helper.plot_min = suggested_low;

      if (helper.plot_max < suggested_high) {
        // if (prepared_values.filter(pre => pre.y > helper.plot_max).length)
        helper.plot_max = suggested_high;
      }
      if (this.props.drawerType == "reportCard" || this.props.type == 'pointed') {
        helper.plot_min = suggested_low;
        helper.plot_max = suggested_high;
      }
      if ((prepared_values.filter(pre => pre.y >= 0).length == prepared_values.length) && helper.plot_min < 0) {
        helper.plot_min = 0
      }
      var dotSize = 8;
      if (width < 766) {
        dotSize = 4;
      }

      var graph = new Rickshaw.Graph({
        element: drawer.querySelector('.chart_body'),
        width: width,
        height: height,
        renderer: 'multi',
        min: helper.plot_min,
        max: helper.plot_max,
        dotSize: dotSize,
        tension: 1,
        interpolation: 'linear',
        padding: { top: 0.05, bottom: 0.05, right: 0.07, left: 0.07 },
        series: series
      });

      new Rickshaw.Graph.Axis.Time({ graph: graph, timeFixture: new chartTimeScale() });

      if (width > 600) {
        var y_ticks = new Rickshaw.Graph.Axis.Y(
          graph.stackedData[0].filter(t => t.y > -5 && t.y < 0).length ?
            {
              graph: graph,
              width: 40,
              tickValues: [floor(graph.min), floor(graph.max), (floor(graph.min) + floor(graph.max)) / 2],
              ticks: 4,
              orientation: 'left',
              tickFormat: (y) => {
                return Rickshaw.Fixtures.Number.formatKMBT(Math.round(y * multiplier) / multiplier);
              },
              element: y_axis
            } : {
              graph: graph,
              width: 40,
              ticks: 4,
              orientation: 'left',
              tickFormat: (y) => {
                return Rickshaw.Fixtures.Number.formatKMBT(Math.round(y * multiplier) / multiplier);
              },
              element: y_axis
            });
        y_axis.style.display = 'block';
      }
      else if (y_axis) {
        y_axis.style.display = 'none';
      }

      if (!isNaN(helper.plot_min) && !(isNaN(helper.plot_max))) {
        graph.render();
      }

      if (this.hoverDetail && (typeof this.hoverDetail._removeListeners === 'function')) {
        this.hoverDetail._removeListeners();
      }

      let componentBiomarkersByVisitDate = {};

      if (this.props.noumenon_name == 'PhysioAge') {
        componentBiomarkersByVisitDate = this.props.componentBiomarkersByVisitDate;
      }

      let shouldShowGrade = true
      if (this.props.biomarkerAgesList && this.props.noumenon_name && is_biomarker_of_aging(this.props.noumenon_name, this.props.biomarkerAgesList)) {
        shouldShowGrade = false
      }
      var sitePref = this.props.siteData ? this.props.siteData.datePreference : null
      var biomarkerAgesList = this.props.biomarkerAgesList ? this.props.biomarkerAgesList : null
      this.hoverDetail = new Hover({
        graph: graph,
        formatter: function (series, x, y, obx_date, value, bulk) {
          let inputs = bulk && bulk.value
          let datum = inputs && inputs.datum
          if (datum) {
            let componentBiomarkers = datum.code == "physioage" ? datum.displayVisitAges : []

            var hover = <HoverView biomarkerAgesList={biomarkerAgesList} datum={datum} componentBiomarkers={componentBiomarkers} shouldShowGrade={shouldShowGrade} datePref={sitePref} />

            return ReactDOMServer.renderToStaticMarkup(hover)
          } else {
            return null
          }
        }
      });
    }
  }

  actuallyPlot() {
    let data = this.props.historic_data;
    if (data && data.length) {
      let plotter = this.plot_data;
      let full_plot = function () {
        //$('.drawer .chart_body').empty()
        //$('.drawer .y_axis').empty()
        plotter(data);
      }
      full_plot();
    }

  }

  render() {
    if (this.shouldRenderAndPlot()) {
      return (
        <div className='chart_container'>
          <div className='y_axis'></div>
          <div className='chart_body'></div>
        </div>
      );
    }
    else {
      return (null);
    }
  }
}

class HoverView extends React.Component {
  render() {
    let datum = this.props.datum
    let gradeComponent = null
    if (!this.props.biomarkerAgesList.indexOf(this.props.datum.label.toLowerCase()) != -1) {
      gradeComponent = (datum.points == null || datum.points === undefined || isNaN(datum.points)) ? " " : <Grade points={datum.points} style={{ 'margin-bottom': '8px' }} />
    }
    return (
      <div className={`hoverthing ${datum && datum.explanationDTO && datum.explanationDTO.length > 0 ? "hoverExplanationThing" : ''}`}>
        <div className='result'>{this.props.biomarkerAgesList.indexOf(this.props.datum.label.toLowerCase()) != -1 ? parseFloat(getRoundedValue(datum.value, datum.scale)).toFixed(1) : getRoundedValue(datum.value, datum.scale)} {datum.units} </div>
        <div className='date'>{datum ? setSitePreference(this.props.datePref ? this.props.datePref : datum.siteDatePreference, datum.visitDate ? datum.visitDate : datum.visit_date) : ''}</div>
        {gradeComponent}
        <div className={datum && datum.explanationDTO && datum.explanationDTO.length > 0 ? "mb-2" : ""}>
          {datum && datum.explanationDTO && datum.explanationDTO.length > 0 ? datum.explanationDTO.map((obj) => {
            return (
              <div className="hoverExplanation">*{obj.explanation}</div>
            )
          }) : ''}
        </div>
        {
          this.props.componentBiomarkers ? this.props.componentBiomarkers.map(label => {
            if (label != 'tru') {
              return (
                <div key={label} className='biomarker biomarker-sm'>
                  <div className='icon'>
                    <SpriteIcon label={label} sprite={label} />
                  </div>
                </div>
              )
            }
            else{
              return (<img style={{height: '32px', width: '32px'}} src={tru}></img>)
            }
          }) : ''
        }
      </div>
    )
  }
}


const convertAge = (current_age, visit) => {
  let now = Math.floor(Date.now() * 0.001) // Convert to seconds from ms
  let years_elapsed = new Date((now - visit) * 1000)
  let age = current_age - (years_elapsed.getYear() + (years_elapsed.getMonth() / 12.0) - 70.0)
  return age
}


import React, { Component } from 'react';

import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import PageTitle from '../../../components/PageTitle';
import { Typeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';

class RangeForm extends Component {
  constructor(props, context) {
    super(props, context)
  }
  componentDidMount() {
      if (this.props.match.params && this.props.match.params.siteId && this.props.match.params.code && this.props.match.params.rubric_id && this.props.match.params.range_id) {
        this.props.setCurrentRange(this.props.match.params.code, this.props.match.params.rubric_id, this.props.match.params.siteId, this.props.history, this.props.match.params.range_id)
      }
      else {
        if (this.props.match.params && this.props.match.params.siteId && this.props.match.params.code && this.props.match.params.rubric_id) {
          this.props.setCurrentRange(this.props.match.params.code, this.props.match.params.rubric_id, this.props.match.params.siteId, this.props.history)
        }
      }
  }

  saveRange = () => {
    let noFormChanges = this.checkForChanges();
    if (!noFormChanges) {
      this.props.saveRange(this.props.history)
    }
  }

  checkForChanges = () => {
    let initialCurrentRange = this.props.initialCurrentRange ? { ...this.props.initialCurrentRange } : {};
    let currentRange = {}
    if (this.props.currentRange) {
      currentRange["type"] = this.props.currentRange["type"];
      currentRange["gradePoints"] = this.props.currentRange["gradePoints"];
      currentRange["low"] = this.props.currentRange["low"];
      currentRange["high"] = this.props.currentRange["high"];
    }
    Object.keys(currentRange).forEach((p) => currentRange[p] == null || currentRange[p] == undefined || currentRange[p] == "" ? delete currentRange[p] : '');
    Object.keys(initialCurrentRange).forEach((p) => initialCurrentRange[p] == null || initialCurrentRange[p] == undefined || initialCurrentRange[p] == "" ? delete initialCurrentRange[p] : '');
    if (_.isEqual(currentRange, initialCurrentRange)) {
      this.props.showNoFormChangesErrorRange(true);
      return true;
    }
    else
      return false;

  }

  render() {
    return (
      <> { !this.props.basicPlan ?
      <>
        {this.props.currentRange && this.props.breadCrumbs ?
          <>
            <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
            <form className='provider-form container col-lg-9' onSubmit={(e) => { e.preventDefault(); this.saveRange() }} >
              <div className='col-lg-12 row'>
                <div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
                  {this.props.rangeErrors && this.props.rangeErrors.length ?
                    <div className='alert alert-danger'>
                      {this.props.rangeErrors.map(re => <p>{re.error}</p>)}
                    </div> : null
                  }

                  <FormControl
                    value={ this.props.currentRange && this.props.currentRange.type ?
                          this.props.currentRange.type : [] }
                    type='select' id='type'
                    options={constants.type}
                    label={labels.custom_noumenon_list.type} onChange={this.props.editRangeForm}
                    textKey={'text'} valueKey={'value'}
                    ref={this.type} required={true} nodefaultOption={true} />

                  <FormControl
                    value={ this.props.currentRange && this.props.currentRange.gradePoints ?
                          this.props.currentRange.gradePoints : [] }
                    type='select' id='gradePoints'
                    options={constants.gradePoints}
                    label={labels.custom_noumenon_list.grade} onChange={this.props.editRangeForm}
                    textKey={'text'} valueKey={'value'}
                    ref={this.gradePoints}
                    nodefaultOption={true}
                    help={labels.custom_noumenon_list.grade_help} />

                  <FormControl
                    ref={ref => this.low = ref}
                    id='low' label={labels.custom_noumenon_list.low }
                    defaultValue={ this.props.currentRange && this.props.currentRange.low
                        ? this.props.currentRange.low : null}
                    type='number' onBlur={this.props.editRangeForm} min={0} step="0.01" />

                  <FormControl
                    ref={ref => this.high = ref}
                    id='high' label={labels.custom_noumenon_list.high}
                    defaultValue={this.props.currentRange && this.props.currentRange.high
                        ? this.props.currentRange.high : null}
                    type='number' onBlur={this.props.editRangeForm}
                    min={this.props.currentRange && this.props.currentRange.low ?
                     this.props.currentRange.low : 0 } step="0.01" />

                  <button className="btn btn-success">Save</button>

                </div>
              </div>

            </form>
          </>
          : ""}
      </>
      : window.location.replace('/statuscode/404') }
      </>
    )
  }
}
export default RangeForm
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../components/Authentication';

import {actionCreators } from '../reducers/global-reducer.js';

const mapStateToProps = state => ({
  ...state.global
});

const mapDispatchToProps = {
  ...actionCreators
};
export default function (ComposedComponent) {
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(Authentication(ComposedComponent)));
}

import React, { useEffect, useRef } from 'react';
import TopNavComponent from '../containers/TopNavContainer';
import NavComponent from '../containers/NavContainer';
import './Analytics.scss'
import '../../../assets/AnalyticsBeta-JS/AnalyticsBeta.js'
import constants from '../../../config/constants';
import parseJWT from '../../../utils/parseJWT';
import Loader from '../../../components/Loader';
import ProviderNotesComponent from '../containers/ProviderNotesContainer'
import RecommendationForm from '../containers/RecommendationFormContainer'
import DashboardComponent from '../containers/DashboardContainer';
import { connect } from 'react-redux';
import { ToastContainer, toast, } from 'react-toastify';
import ReportCardComponent from '../containers/ReportCardContainer'
import BiomarkerAgingComponent from '../containers/BiomarkerAgingContainer';
import MedicationComponent from '../containers/MedicationContainer';
import TreatmentPlanComponent from '../containers/TreatmentPlanContainer';
import BiomarkerComparisonModel from '../containers/BiomarkerComparisonModel';
import StopRecommendationWarning from './StopRecommendationWarning';
import HistoryRecommendations from './HistoryRecommendations';
import DeleteRecommendationWarning from './DeleteRecommendationWarning';
import ProgressLoader from '../../../components/ProgressLoader';
import PatientReportDownloadTemplateFunctional from '../../Visits/components/PatientReportDownloadTemplateFunctional';
import PatientReportDownloadTemplatePast from '../../Visits/components/PatientReportDownloadTemplatePast';
import VisitSummaryTemplate from '../../AnalyticsBeta/components/VisitSummary';
import QuestionaireForm from './QuestionaireForm';
import Loading from '../containers/LoadingContainer';
import labels from '../../../config/localization';
import DownloadLibraryComponent from '../containers/DownloadLibraryContainer';
import InterpretationComponent from '../containers/InterpretationContainer';
import DrawerWarning from '../containers/DrawerWarningContainer';
import CategoryDetails from './CategoryDetails'
import FeedbackComponent from './FeedbackComponent';
import ProblemListComponent from '../containers/ProblemListContainer';
import ProblemForm from '../containers/ProblemFormContainer';
import ProblemWarning from './ProblemWarning';
import UserDetails from './UserDetails';
import RecommendationListModel from './RecommendationListModel';

function AnalyticsBeta(props) {
  const toastId = useRef(null);

  useEffect(() => {
    // props.toggleTheme("dark-theme")
    props.toggleStopRecommendationPopup(false);
    props.toggleDeleteRecommendationPopup(false);
    props.isLeftNavOpenend(false)
    props.setRecType("Recommendation")
    props.toggleSurveyPopup(false);
    props.unMountingLoadingComponent(false);
    props.toggleProviderNotes(false)
    props.toggleBiomarkerComparePopup(false)
    props.toggleRecommendationModal(false)
    props.toggleMetaRecommendations(false);
    props.toggleCategoryDetailsModal(false);
    props.toggleRecommendationListModal(false);
    // Anything in here is fired on component mount.
    let unlisten;
    if  (props.location && props.location.pathname && (props.location.pathname.indexOf('analytics/demo') > -1) && !(props.location && props.location.hash && (props.location.pathname.indexOf('demo') > -1))){
        let storedRole = localStorage.getItem('role') ? constants.logged_roles[localStorage.getItem('role')] : null
        let role = props.loggedInUser ? props.loggedInUser.role : storedRole
        if ((role && role == constants.logged_roles.CG) || (role && role == constants.logged_roles.PT) ) {
          props.history.push('/statuscode/404')
        }
    
    }    

    if (props.location && props.location.hash && (props.location.pathname.indexOf('demo') > -1) && !(props.location.pathname.indexOf('analytics/demo') > -1))
    {
      props.togglePages("", props.history, "", true, false)
    }
    else if (props.location && props.location.pathname && (props.location.pathname.indexOf('analytics/demo') > -1)) {
      props.togglePages("", props.history, "", false, true)
    }
    else {
      props.togglePages("", props.history, "", false, false)
    }
    if (localStorage && (!localStorage.access_token || checkOpenStatusWithAccessToken()) && props.location && props.location.hash) {
      localStorage.setItem('canOpen', 1)
    }
    if (props.location && props.location.pathname && (props.location.pathname.indexOf('demo') > -1) && !(props.location.pathname.indexOf('analytics/demo') > -1)) {
      loadPendo()
      props.loadDemoData()
      unlisten = props.history.listen((location, action) => {
        let currentUrl = localStorage.getItem('currenturl')
        if (currentUrl) {
          localStorage.setItem('previousurl', currentUrl)
        }
        localStorage.setItem('currenturl', `${location.pathname}${location.search}${location.hash}`)
      })
    }
    else {
      props.checkAccessToken()
      clearInterval(refreshTokenTimerHandler)
      refreshTokenTimerHandler = setInterval(() => {
        props.checkAccessToken()
      }, constants.min_for_refresh * 60 * 1000);
      props.toggleToaster(true)

      props.getAuthStatus()
      if (!props.isAuthenticated) {
        window.location.replace('/login')
      }
      else {
        if (props.loggedInUser && props.loggedInUser.role != constants.logged_roles.PT) {
          props.resetLoader(props.match.params.id)
        } else if (props.loggedInUser.role == constants.logged_roles.PT && !props.match.params.id) {
          props.resetLoader()
        } else if (props.loggedInUser.role == constants.logged_roles.PT && props.match.params.id) {
          props.history.push('/statuscode/404');
        }
      }
      props.setHistory(props.history)
      if (props.location && props.location.pathname && (props.location.pathname.indexOf('analytics/demo') > -1)) {
        props.setPatientDemo(true)
      }
      else{
        props.setPatientDemo(false)
      }

      unlisten = props.history.listen((location, action) => {
        let currentUrl = localStorage.getItem('currenturl')
        if (currentUrl) {
          localStorage.setItem('previousurl', currentUrl)
        }
        localStorage.setItem('currenturl', `${location.pathname}${location.search}${location.hash}`)
        props.setCurrentPage(props.history)
      })
    }

    // Analytics UI
    props.getAllRecommendations()
    props.getSnomedCtCode()
    props.setCurrentUser();
    
    return () => {
      props && !props.isDemo ? unlisten() : ''
      document.body.style.overflow = 'auto';
      if (!props.showSurveyToaster) {
        toast.dismiss();
      }
      // Anything in here is fired on component unmount.
    }
  }, [])

  const loadPendo = () => {
    var randomId = "demo_" + Math.random()
    pendo.initialize({
      visitor: {
        id: randomId
      },
      account: {
        id: randomId
      }
    });
  }

  const checkOpenStatusWithAccessToken = () => {
    let parsedToken = parseJWT()
    let isTokenRefreshed = localStorage.getItem('isTokenRefreshed')
    if (Object.keys(parsedToken).length) {
      if (Date.now() > (parsedToken.exp * 1000)) {
        return true
      }
      //if expiration of token is in 40min or less
      else if (((parsedToken.exp * 1000) - Date.now() <= constants.timeout_min_for_expire * 60 * 1000) && !isNaN(parseInt(isTokenRefreshed)) && !parseInt(isTokenRefreshed)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  let renderPages = () => {
    switch (props.currentPage) {
      case 'dashboard':
        return <DashboardComponent {...props} />
      case 'report_card':
        return <ReportCardComponent {...props} />
      case 'biomarker_of_age':
        return <BiomarkerAgingComponent {...props} />
      case 'medication':
        return <MedicationComponent {...props} />
      case 'treatment_plan':
        return <TreatmentPlanComponent {...props} />
      case 'download_library':
        return <DownloadLibraryComponent {...props} />
      case 'interpretations':
        return <InterpretationComponent {...props} />
      case 'problem_list':
        return <ProblemListComponent {...props} />
      case 'user_details':
          return <UserDetails {...props} />  
      default:
        return <DashboardComponent {...props} />
    }
  }

  const QuestionaireToasterNotification = () => {
    return (
      <div>
        {/* <h5 className="text-dark">Dear Visitor</h5> */}
        <p className="text-secondary"> {labels.analytics_labels.questionnaires_info}
          {/* We would like to know you a little bit better<br />
          May we ask you few simple questions */}
        </p>
        <div className={`d-flex flex-row ${props.siteTheme}`}>
          <button type="button" className="btn surveyRejectBtn mr-5" onClick={() => (toast.dismiss(this && toastId && toastId.current ? toastId.current : null))}> {labels.analytics_labels.maybe_later} </button>
          <button type="button" className="btn surveyAcceptBtn" onClick={() => { props.toggleSurveyPopup(true) }}> {labels.analytics_labels.yes_sure}</button>
        </div>
      </div>
    )
  }

  if (props.showSurveyToaster && props.categories && (!props.currentPage || props.currentPage == "dashboard")) {
    toastId.current = toast(QuestionaireToasterNotification(), {
      autoClose: false,
      closeOnClick: false,
      theme: props.theme == "light-theme" ? "light" : "dark",
      // delay: 4000
    });
    props.toggleSurveyToaster(false);
  }

  const submittedToast = () => {
    toast.dismiss(toastId && toastId.current ? toastId.current : null)
  }

  document.title = `${labels.physioage_label}: ${labels.sites_labels.analytics_label}`
  window.addEventListener('load', () => {
    refreshTokenTimerHandler = setInterval(() => {
      props.checkAccessToken()
    }, constants.min_for_refresh * 60 * 1000);
  })

  return (
    
    <div className={`${props.siteTheme} page-wrapper palette_default analytics-screen beta ${props.theme ? props.theme : "light-theme" } ${props.showRecommendationModal ? "overflow-hidden" : ""}`}>
      <React.Fragment>
        {!props.unMountLoadingComponent ? <Loading /> : ''}
        <div className={props.isLeftNavOpen ? 'nav-overlay-sm' : ''}></div>
        {props.isLoading && !props.isReportLoading ? <Loader /> : null}
        {props.isReportLoading ? <ProgressLoader isProgressLoading={props.isLoading} /> : null}
        {props.isLoading ? submittedToast() : null}
        {props.recommendFormWarning ? <DrawerWarning /> : null}
        {props.problemFormWarning ? < ProblemWarning {...props} /> : null }
        {props.surveyPopup && !props.isLoading && props.surveyData ? <QuestionaireForm submittedToast={() => (submittedToast())} {...props} /> : null}
        <div>
          {!props.unMountLoadingComponent ? null : <TopNavComponent {...props} />}
          <div className={`d-flex body-container ${props.siteData ? (props.siteData.logoFile || props.siteData.bannerLogoFile) ? 'analytics-beta' : '' : ''}`}>
            <div className={'health-content left-div-analytics-nav' +
              (props.isLeftNavOpen ? ' analytics-left-nav-open' : ' analytics-left-nav-close')}
              onClick={() => window.innerWidth >= constants.small_screen_resolution && window.innerWidth <= 992 && !props.isLeftNavOpen ? props.isLeftNavOpenend(true) : null}
              onMouseEnter={() => window.innerWidth > 992 ? props.isLeftNavOpenend(true) : null}
              onMouseLeave={() => window.innerWidth > 992 ? props.isLeftNavOpenend(false) : null}
            // onClick= {() => props.isLeftNavOpenend(true) }
            >
              {!props.unMountLoadingComponent ? null : <NavComponent {...props} />}
            </div>
            {/* {!props.showProviderNotes ? <ToastContainer autoClose={1000} /> : ""} */}
            <div className={'right-div' + (props.isLeftNavOpen ? ' analytics-right-nav-open' : ' analytics-right-nav-close')} onClick={() => window.innerWidth >= constants.small_screen_resolution && window.innerWidth <= 992 && props.isLeftNavOpen ? props.isLeftNavOpenend(false) : null}>
              {props.siteData ?
                renderPages()
                : null}
            </div>
          </div>
          { props.unMountLoadingComponent ?
            <button id="popup" className="feedback-button" onClick={() => { props.toggleFeedbackModel(true) }}>Feedback</button>
          : ""}

          {props.showProviderNotes ? <ProviderNotesComponent {...props} /> : ""}
          {props.showRecommendationModal ? <RecommendationForm {...props} /> : ""}
          {props.showBiomarkerCompareModal ? <BiomarkerComparisonModel {...props} /> : ""}
          {props.showCategoryDetailsModal ? <CategoryDetails {...props} /> : ''  }
          {props.showFeedbackModel ? <FeedbackComponent {...props} /> : ""}
          {props.showProblemModal ? <ProblemForm {...props} /> : ""}
          {props.showRecommendationListModel ? <RecommendationListModel {...props} /> : "" }
        </div>
        {props.stopRecommendationPopup && props.stopRecommendId ? <StopRecommendationWarning {...props} /> : ''}
        {props.deleteRecommendationPopup && props.deleteRecommendId ? <DeleteRecommendationWarning {...props} property={"recommendation"} /> : ''}
        {props.deleteProblemPopup && props.deleteProblemId ? <DeleteRecommendationWarning {...props} property={"problem"} /> : ''}

        {props.presentPatient && props.presentPatient.priorVisitId != 0 && props.historyRecommendationPopup && !props.isLoading ? <HistoryRecommendations {...props} /> : null}

        {props.isPatientReport && props.patientReportDownloadTemplateSection ?
          <div className='display-none reports-download-section'>
            <PatientReportDownloadTemplateFunctional {...props} />
          </div>
          : props.isPatientReport && props.patientReportDownloadTemplatePastSection ?
            <div className='display-none reports-download-section'>
              <PatientReportDownloadTemplatePast {...props} />
            </div> : null
        }
        {props.isVisitSummary ?
          <div className='display-none'>
            <VisitSummaryTemplate {...props} />
          </div>
          : null}
      </React.Fragment>

    </div>
  );

}

export default connect()(AnalyticsBeta);
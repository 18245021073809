import React from 'react';
import Section from './SectionComponent';
import SpriteIcon from './SpriteIcon';
import { uniformString } from '../tools/helpers';
import constants from '../../../config/constants';

export default class Category extends React.Component {
  constructor(props) {
    super(props);
    this.sectionNames = this.sectionNames.bind(this);
    this.renderSection = this.renderSection.bind(this);
  }

  sectionNames() {
    var name_list = this.props.category.sections.map(function (section) { return (section.name) });
    return (name_list.join(', '));
  }

  renderSection(section) {
    return (
      <div key={'health-card-' + section.key} className='card health-card'>
        <Section section={section} hashDrawer={this.props.hashDrawer} {...this.props} />
      </div>
    );
  }

  renderCategoryHeading() {
    var counter = 1;
    return (
      <div className='heading'>
        <div className='meta'>
          <h2 className='section-title' title={this.props.presentPatient.languagePreference != "en" ? this.props.category.enName : ''}>{this.props.category.name}</h2>
          {/* <div className='description'>{this.sectionNames()}</div> */}
          <div className='description'>
            {this.props.category.sections.map(s => {
              if (counter == 1) {
                counter++
                return (
                  <span className='section-culture' title={this.props.presentPatient.languagePreference != "en" ? s.enName : ''}>
                    {s.name}
                  </span>)
              }
              else
                return (
                  <span className='section-culture' title={this.props.presentPatient.languagePreference != "en" ? s.enName : ''}>
                    , {s.name}
                  </span>)
            }
            )}
          </div>
        </div>
        <div className='icon'>
          <SpriteIcon label={this.props.category.name} sprite={constants.biographical_labels.sprite_icon[this.props.category.key] || 'wellness'} />
        </div>
      </div>
    );
  }

  render() {
    var sections = this.props.category.sections.map(this.renderSection);

    if (sections.length > 0) {
      return (
        <div className='health-category' id={uniformString(this.props.category.enName, 'category')}>
          <div className='max-container'>
            {this.renderCategoryHeading()}
            {sections}
          </div>
        </div>
      );
    }
    else {
      return (null);
    }
  }
}

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import _, { max, floor } from 'lodash';
import Grade from './Grade';
import SpriteIcon from './SpriteIcon';
import { spriteForBiomarker } from '../tools/biomarkers';
import Rickshaw, { yearsDiff } from '../../Analytics/tools/rubricplot'
import ChartTools, { filled_age_line_series, is_biomarker_of_aging, chartWidth } from '../tools/ChartTools';
import { setSitePreference } from '../tools/calendar';
import labels from '../../../config/localization';
import chartTimeScale from '../tools/chartTimeScale';
import constants from '../../../config/constants';
import { getRoundedValue } from '../tools/helpers';
import tru from '../../../../src/tru.svg'
import { kMaxLength } from 'buffer';

class Hover extends Rickshaw.Graph.HoverDetail {
  static propTypes = {
    datum: PropTypes.object.isRequired,
  };
}

export default class CategoryDataChart extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }
  static propTypes = {
    componentBiomarkersByVisitDate: PropTypes.object,
    dob: PropTypes.string.isRequired,
    noumenon_name: PropTypes.string.isRequired,
    historic_data: PropTypes.array.isRequired,
    suggested_low: PropTypes.number,
    suggested_high: PropTypes.number,
    theme: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.plot = this.plot.bind(this);
    this.plot_data = this.plot_data.bind(this);
    this.plot();
    this.timeout = setTimeout(() => {
      if (this.containerRef && this.containerRef.current && this.props.historic_data ? this.props.historic_data.length > 1 : true) {
        this.scrollRight();
      }
    }, 500);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.theme !== this.props.theme || prevProps.isLeftNavOpen !== this.props.isLeftNavOpen 
      || prevProps.currentIndicator !== this.props.currentIndicator) {
      this.timeout = setTimeout(() => {
        if (this.containerRef && this.containerRef.current && this.props.historic_data ? this.props.historic_data.length > 1 : true) {
          this.scrollRight();
        }
      }, 500);
      this.plot();
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  scrollRight = () => {
    const container = this.containerRef.current;
    if (container) {
      container.scrollLeft = container.scrollWidth - container.clientWidth;
    }
  };

  shouldRenderAndPlot() {
    return (this.props.historic_data ? this.props.historic_data.length >= 1 : 0);
  }

  plot() {
    if (this.shouldRenderAndPlot()) {
      this.actuallyPlot();
    }
  }
  getLowestReferenceRange(referenceRange) {
    let finalValue = 0
    if (referenceRange) {
      if (referenceRange.indexOf('<') != -1) {
        finalValue = referenceRange.substr(0, referenceRange.lastIndexOf('<'))
      } else if (referenceRange.indexOf('>') != -1) {
        finalValue = referenceRange.substr(referenceRange.lastIndexOf('>') + 1)
      } else if (referenceRange.indexOf('-') != -1) {
        finalValue = referenceRange.substr(0, referenceRange.lastIndexOf('-'))
      }
    }
    return finalValue
  }
  getSuggestedLimits(historic_data) {
    var suggested_low = 0
    var suggested_high = null
    if (historic_data && historic_data.length) {
      if (this.props.drawerType == "reportCard" || this.props.type == 'pointed') {
        suggested_low = 0
        suggested_high = 5
      }
      //if biomarkers age
      else if (Object.values(constants.biographical_labels.ageLabels).indexOf(_.head(historic_data).label) != -1) {
        suggested_low = 20
        suggested_high = 90
      } else {
        let topRubricRanges = _.head(historic_data).noumenonRubricRanges
        //if rubricranges are morethan two ranges
        if (topRubricRanges && topRubricRanges.length > 2) {
          let sortedRubricRanges = topRubricRanges.sort(function (a, b) { return a.low - b.low })
          let topRange = _.last(sortedRubricRanges)
          let lowRange = _.head(sortedRubricRanges)
          historic_data = historic_data.map(hd => {
            // hd.value = hd.value.replace('<', '').replace('>', '').replace('=', '')
            return hd
          })
          //if top range is open ended
          if (topRange.low && !topRange.high) {
            //higher of top range's low value, highest observed value
            suggested_high = Math.max(parseInt(topRange.low), Math.max(...historic_data.map(t => parseInt(t.value)), 0))
          }
          //if top range is not open ended
          else if (topRange.low && topRange.high) {
            //higher of top range's high value, highest observed value
            suggested_high = Math.max(parseInt(topRange.high), Math.max(...historic_data.map(t => parseInt(t.value)), 0))
          }
          suggested_low = Math.min(0, Math.min(...historic_data.map(t => parseInt(t.value)), 0),
            lowRange.low, this.getLowestReferenceRange(_.head(historic_data).referenceRange))
        }
        else {
          suggested_high = Math.max(...historic_data.map(t => parseInt(t.value)), 0)//highest observed value
          suggested_low = Math.min(0, Math.min(...historic_data.map(t => parseInt(t.value)), 0), this.getLowestReferenceRange(_.head(historic_data).referenceRange))
        }
      }
    }
    return { suggested_high, suggested_low }
  }
  getMidYValueOfChart(minValue, maxValue){
    return (minValue + maxValue)/2;
  }
  plot_data(data) {
    var helper = new ChartTools(data);
    // TODO: This may not be needed as DrawerComponent already filters out nonBlankValue and others likely do as well. Will require research, though.
    let prepared_values = _.orderBy(data, ['visitDate']).map(ChartTools.rickshawCargo).filter(ChartTools.hasValue);
    let multiplier = (10 ** data[0].scale) || 1000;

    let drawer = document.getElementsByClassName("category-overview-graph")[0];
    var y_axis = drawer.querySelector('.y_axis');
    if (drawer.querySelector('.category-chart .chart_container .chart_body')) {
      drawer.querySelector('.category-chart .chart_container .chart_body').innerHTML = ''
    }
    if (drawer.querySelector('.category-chart .chart_container .y_axis')) {
      drawer.querySelector('.category-chart .chart_container .y_axis').innerHTML = ''
    }
    let width = chartWidth(this.props.noumenon_name, this.props.pageType);
    let height;
    if (this.props.reportCard) {
      width = 800
      height = 200
    }
    else if (!this.props.noumenon_name && !this.props.pageType){
      width = 500
    }
    else{
      height = width * 0.4;
    }  


    // if (y_axis)
    //   width = width - y_axis.offsetWidth;

    let dob = new Date(this.props.presentPatient ? this.props.presentPatient.dob : '')
    if (this.props.biomarkerAgesList ? this.props.biomarkerAgesList.indexOf(this.props.noumenon_name ? this.props.noumenon_name.toLowerCase() : "") != -1 : null) {
      var series = filled_age_line_series(dob, data, this.props.noumenon_name)
      let first_age = yearsDiff(new Date(data[0].visitDate), dob)
      let last_age = yearsDiff(new Date(), dob)
      if (first_age < helper.plot_min) {
        helper.plot_min = first_age
      }
      if (last_age > helper.plot_max) {
        helper.plot_max = last_age
      }
    } else {
      var series = [
        {
          color: 'grey',
          data: prepared_values,
          renderer: 'rubricplot_with_line',
          isBeta: true,
          theme: this.props.theme
        }
      ]
    }
    let { suggested_high, suggested_low } = this.getSuggestedLimits(this.props.historic_data)
    if (prepared_values.length >= 1) {
      if (helper.plot_min > suggested_low)
        helper.plot_min = suggested_low;

      if (helper.plot_max < suggested_high) {
        // if (prepared_values.filter(pre => pre.y > helper.plot_max).length)
        helper.plot_max = suggested_high;
      }
      if (this.props.drawerType == "reportCard" || this.props.type == 'pointed') {
        helper.plot_min = suggested_low;
        helper.plot_max = suggested_high;
      }
      if ((prepared_values.filter(pre => pre.y >= 0).length == prepared_values.length) && helper.plot_min < 0) {
        helper.plot_min = 0
      }
      var dotSize = 8;
      if (width < 766) {
        dotSize = 4;
      }

      let maxYAxisValue;
      let rubricRanges = this.props.datumValues ? this.props.datumValues.noumenonRubricRanges : '';
      if(this.props.currentIndicator && !this.props.currentIndicator.includes('category') && !this.props.currentIndicator.includes('section')){
        if(rubricRanges){
          rubricRanges.sort(function(a, b){
            return a.low - b.low;
          });
          maxYAxisValue = rubricRanges ? _.last(this.props.datumValues.noumenonRubricRanges) && _.last(this.props.datumValues.noumenonRubricRanges).low == helper.plot_max ? helper.plot_max +helper.plot_max*0.10 : helper.plot_max : helper.plot_max;
          maxYAxisValue = rubricRanges.low ? _.last(rubricRanges).low < 1 && _.last(rubricRanges).low > 0 ? 1 : maxYAxisValue : maxYAxisValue;
          maxYAxisValue = rubricRanges.low ? maxYAxisValue < _.last(rubricRanges).low ? parseFloat(_.last(rubricRanges).low) + parseFloat(_.last(rubricRanges).low) * 0.1 : maxYAxisValue : maxYAxisValue;
        }
      }

      var chartElement = drawer.querySelector('.chart_body');
      chartElement.setAttribute('id', `${_.head(series).renderer == 'rubricplot_filled_with_age_line' ? 60 : this.getMidYValueOfChart(helper.plot_min, maxYAxisValue ? maxYAxisValue : helper.plot_max)}`);

      var graph = new Rickshaw.Graph({
        element: drawer.querySelector('.chart_body'),
        width: width > window.innerWidth*0.46 ? width : window.innerWidth*0.46,
        height: height,
        renderer: 'multi',
        min: helper.plot_min,
        max: maxYAxisValue ? maxYAxisValue : helper.plot_max,
        dotSize: dotSize,
        tension: 1,
        interpolation: 'linear',
        padding: { top: 0.05, bottom: 0.05, right: 0.07, left: 0.07 },
        series: series
      });

      // let chartContainer = drawer.querySelector('.chart_body');
      // let yAxisLength = maxYAxisValue ? maxYAxisValue : helper.plot_max;
      // let chartRanges = [];
      // let cumulativeLength = 0;
      // let gradientBackColors = '';
      // let gradientBack = '';
      // if(this.props.currentPage == 'report_card' && this.props.currentIndicator && !this.props.currentIndicator.includes('category') && !this.props.currentIndicator.includes('section') && this.props.datumValues.points != null)
      // {
      //   if(rubricRanges){
      //     rubricRanges.sort((a, b) => {
      //       if (a.low === null && b.low === null) {
      //         return 0;
      //       }
      //       if (a.low === null) {
      //         return -1; // Move items with null "low" to the beginning
      //       }
      //       if (b.low === null) {
      //         return 1; // Move items with null "low" to the beginning
      //       }
      //       return a.low - b.low;
      //     });

      //     if(helper.plot_min < 0){
      //       yAxisLength = yAxisLength - helper.plot_min;
      //     }
      //     for (let index = 0; index < rubricRanges.length; index++) {
      //       const element = rubricRanges[index];
      //       var lengthOfRange = Math.abs(element.high - element.low)/yAxisLength;
      //       if(index == 0 && element.low > 0){
      //         lengthOfRange = Math.abs(element.high)/yAxisLength;
      //       }
      //       if(index == 0 && helper.plot_min < 0){
      //         lengthOfRange += Math.abs(Math.floor(helper.plot_min-1))/yAxisLength;
      //       }
      //       cumulativeLength = cumulativeLength + lengthOfRange*100;
      //       if(index == rubricRanges.length-1){
      //         chartRanges.push([100, constants.chart_colors_of_rubricRanges.find(item => rubricRanges[index].type in item)[rubricRanges[index].type]]);
      //       }
      //       else{
      //         chartRanges.push([cumulativeLength, constants.chart_colors_of_rubricRanges.find(item => rubricRanges[index].type in item)[rubricRanges[index].type]]);
      //       }
      //     }
      //     if(rubricRanges && rubricRanges.length > 0){
      //       var gap = _.last(rubricRanges).low < 1 && _.last(rubricRanges).low > 0 ? 0 : 0.5;
      //       if(rubricRanges.length == 1){
      //         gradientBackColors += `${_.head(chartRanges)[1]} ${_.head(chartRanges)[0]+gap}%, ${_.head(chartRanges)[1]} ${_.head(chartRanges)[0]}%`;
      //       }
      //       else{
      //         for(let index = 0; index<chartRanges.length;index++){
      //           if(index == chartRanges.length-1){
      //             gradientBackColors += `${chartRanges[index][1]} ${chartRanges[index-1][0]+gap}%, ${chartRanges[index][1]} ${chartRanges[index][0]}%`;
      //           }
      //           else if(index==0){
      //             gradientBackColors += `${chartRanges[index][1]} 0%, ${chartRanges[index][1]} ${chartRanges[index][0]}%, white ${chartRanges[index][0]}%,
      //             white ${chartRanges[index][0]+gap}%,`;
      //           }
      //           else{
      //             gradientBackColors += `${chartRanges[index][1]} ${chartRanges[index-1][0]+gap}%, ${chartRanges[index][1]} ${chartRanges[index][0]}%, white ${chartRanges[index][0]}%,
      //             white ${chartRanges[index][0]+gap}%,`;
      //           }
      //         }
      //       }
      //       gradientBack = `
      //       linear-gradient(to top, ${gradientBackColors}
      //       )`;
      //     }
      //   }
      // }
      // else if((this.props.currentPage == 'report_card' || this.props.currentPage == 'dashboard' && this.props.currentIndicator ? (this.props.currentIndicator.includes('category') || this.props.currentIndicator.includes('section')) : (!this.props.currentPage ? true : false)))
      // {
      //   gradientBack = `
      //   linear-gradient(to top,
      //     #EEB3B3 0%,
      //     #EEB3B3 25.98%,

      //     white 25.98%,
      //     white 26.48%,
    
      //     #FDD8B1 26.48%,
      //     #FDD8B1 39.98%,
    
      //     white 39.98%,
      //     white 40.48%,
    
      //     #F2F9AC 40.48%,
      //     #F2F9AC 53.98%,
    
      //     white 53.98%,
      //     white 54.48%,
    
      //     #BDE5C3 54.48%,
      //     #BDE5C3 65.98%,
    
      //     white 65.98%,
      //     white 66.48%,

      //     #A8EFB3 66.48%,
      //     #A8EFB3 100%
      //   )`;
        
      // }
      // chartContainer.style.background = gradientBack;
      new Rickshaw.Graph.Axis.Time({ graph: graph, timeFixture: new chartTimeScale() });

      // if (width > 600) {
      var y_ticks = new Rickshaw.Graph.Axis.Y(
        graph.stackedData[0].filter(t => t.y > -5 && t.y < 0).length ?
          {
            graph: graph,
            width: 40,
            tickValues: [0, floor(graph.max), (floor(graph.min) + floor(graph.max)) / 2],
            ticks: 4,
            orientation: 'left',
            tickFormat: (y) => {
              return ( y >=  1000 || y <= -1000 ? (Math.round(y * multiplier) / multiplier / 1000) + 'K' : Math.round(y * multiplier) / multiplier) ;
            },
            element: y_axis
          } : {
            graph: graph,
            width: 40,
            ticks: 4,
            orientation: 'left',
            tickFormat: (y) => {
              return ( y >= 1000 || y <= -1000 ? (Math.round(y * multiplier) / multiplier / 1000) + 'K' : Math.round(y * multiplier) / multiplier) ;
            },
            element: y_axis
          });
      y_axis.style.display = 'block';
      // }
      // else if (y_axis) {
      //   y_axis.style.display = 'none';
      // }
      if (drawer.querySelector('.category-chart .chart_container .chart_body')) {
        var svg =  drawer.querySelector('.category-chart .chart_container .chart_body svg')
        var dynamicMarginRight = this.calculateDynamicMarginRight(graph);
        var dynamicMarginLeft = this.calculateDynamicMarginLeft(graph);
        svg.style.marginRight = dynamicMarginRight + 'px';
        svg.style.marginLeft = dynamicMarginLeft + 'px';
      }
      var yAxisRight = new Rickshaw.Graph.Axis.Y(
        graph.stackedData[0].filter(t => t.y > -5 && t.y < 0).length ? 
          {
            graph: graph,
            width: 40,
            tickValues: [0, floor(graph.max), (floor(graph.min) + floor(graph.max)) / 2],
            ticks: 4,
            orientation: 'right',
            tickFormat: (y) => {
              return ( y >=  1000 || y <= -1000 ? (Math.round(y * multiplier) / multiplier / 1000) + 'K' : Math.round(y * multiplier) / multiplier) ;
            },
            element: y_axis
          } :
        {
          graph: graph,
          orientation: 'right',
          tickFormat: (y) => {
            return ( y >=  1000 || y <= -1000 ? (Math.round(y * multiplier) / multiplier / 1000) + 'K' : Math.round(y * multiplier) / multiplier) ;
          },
          width: 40,
          ticks: 4,
          element: y_axis
        });

      if (!isNaN(helper.plot_min) && !(isNaN(helper.plot_max))) {
        graph.render();
      }

      if (this.hoverDetail && (typeof this.hoverDetail._removeListeners === 'function')) {
        this.hoverDetail._removeListeners();
      }

      let componentBiomarkersByVisitDate = {};

      if (this.props.noumenon_name == 'PhysioAge') {
        componentBiomarkersByVisitDate = this.props.componentBiomarkersByVisitDate;
      }

      let shouldShowGrade = true
      if (this.props.biomarkerAgesList && this.props.noumenon_name && is_biomarker_of_aging(this.props.noumenon_name, this.props.biomarkerAgesList)) {

        shouldShowGrade = false
      }
      var sitePref = this.props.siteData ? this.props.siteData.datePreference : null
      var biomarkerAgesList = this.props.biomarkerAgesList ? this.props.biomarkerAgesList : null
      var current_noumenon = this.props.noumenon_name
      this.hoverDetail = new Hover({
        graph: graph,
        formatter: function (series, x, y, obx_date, value, bulk) {
          let inputs = bulk && bulk.value
          let datum = inputs && inputs.datum
          if (datum) {
            let componentBiomarkers = datum.code == "physioage" ? datum.displayVisitAges : []

            var hover = <HoverView biomarkerAgesList={biomarkerAgesList} datum={datum} componentBiomarkers={componentBiomarkers} shouldShowGrade={shouldShowGrade} datePref={sitePref} />

            // if (current_noumenon)
              return ReactDOMServer.renderToStaticMarkup(hover)
          } else {
            return null
          }
        }
      });
    }
  }

  calculateDynamicMarginRight(graph) {
    // Example calculation (customize as needed)
    let MaxLength;
     if (graph.max.toString().length > graph.min.toString().length){
        MaxLength = graph.max.toString().length
     }
     else{
        MaxLength = graph.min.toString().length
     }  

    if (MaxLength > 1 ) {
      return 12 + (9 * (MaxLength - 1))
    } else {
      return 12; 
    }
  }

  calculateDynamicMarginLeft(graph) {
    // Example calculation (customize as needed)
    let MaxLength;
     if (graph.max.toString().length > graph.min.toString().length){
        MaxLength = graph.max.toString().length
     }
     else{
        MaxLength = graph.min.toString().length
     } 
    if (MaxLength> 5 ) {
      return 5 + (9 * (MaxLength - 5))
    } else if ((MaxLength == 4 )){
       return 5;
    }
    else {
      return 0;
    } 
  }

  actuallyPlot() {
    let data = this.props.historic_data;
    if (data && data.length) {
      let plotter = this.plot_data;
      let full_plot = function () {
        //$('.drawer .chart_body').empty()
        //$('.drawer .y_axis').empty()
        plotter(data);
      }
      full_plot();
    }

  }

  render() {
    if (this.shouldRenderAndPlot()) {
      return (
        <div ref={this.containerRef} className="category-chart" style={{ overflowX: 'scroll', width: '100%', whiteSpace: 'nowrap' ,scrollBehavior: 'smooth'}}>
            <div className='chart_container'>
            <div className='y_axis'></div>
            <div className={'chart-gradient-background ' + (this.props.noumenon_name ? '' : (this.props.datumValues || this.props.currentPage == 'dashboard') ? ((this.props.datumValues ? this.props.datumValues.points : false) || this.props.currentPage == 'dashboard') ? 'should-gradient' : '' : '')}>
                <div className={`${'chart_body' + ' ' + this.props.theme}`} ></div>
            </div>
            </div>
        </div>
      );
    }
    else {
      return (null);
    }
  }
}

class HoverView extends React.Component {
  render() {
    let datum = this.props.datum
    let gradeComponent = null
    const rangeRegex = /\d+-\d+/g
    if (!this.props.biomarkerAgesList.indexOf(this.props.datum.label.toLowerCase()) != -1) {
      gradeComponent = (datum.points == null || datum.points === undefined || isNaN(datum.points)) ? " " : <Grade points={datum.points} style={{ 'margin-bottom': '8px' }} />
    }
    let midValue = _.head(document.getElementsByClassName('chart_body')).id;
    let chartBody = _.head(document.getElementsByClassName('detail'));
    let classesToRemove = ['top-corner', 'normalized-position', 'null-points', 'not-null', 'on-chart', 'bottom-most-point', 'physioage', 'not-physioage'];
    chartBody.classList.remove(...classesToRemove);
    chartBody.classList.add(`${parseFloat(datum.value ? datum.value : datum.points) > parseFloat(midValue) ? 'top-corner' : 'normalized-position'}`, `${(datum.points == null || datum.points === undefined || isNaN(datum.points)) ? 'null-points' : 'not-Null'}`, `${midValue/2 > datum.value ? 'bottom-most-point' : 'on-chart'}`, `${this.props.datum.code == 'physioage' ? 'physioage' : 'not-physioage'}`);
    return (
      <div className={`hoverthing ${datum && datum.explanationDTO && datum.explanationDTO.length > 0 ? "hoverExplanationThing" : ''}`}>
        <div className='result'>{this.props.biomarkerAgesList.indexOf(this.props.datum.label.toLowerCase()) != -1 ? parseFloat(getRoundedValue(datum.value, datum.scale)).toFixed(1) : rangeRegex.test(datum.value) ? datum.value : getRoundedValue(datum.value, datum.scale)} {datum.units} </div>
        <div className='date'>{datum ? setSitePreference(this.props.datePref ? this.props.datePref : datum.siteDatePreference, datum.visitDate ? datum.visitDate : datum.visit_date) : ''}</div>
        {gradeComponent}
        <div className={datum && datum.explanationDTO && datum.explanationDTO.length > 0 ? "mb-2" : ""}>
          {datum && datum.explanationDTO && datum.explanationDTO.length > 0 ? datum.explanationDTO.map((obj) => {
            return (
              <div className="hoverExplanation">*{obj.explanation}</div>
            )
          }) : ''}
        </div>
        {
          this.props.componentBiomarkers ? this.props.componentBiomarkers.map(label => {
            if (label != 'tru') {
              return (
                <div key={label} className='biomarker biomarker-sm'>
                  <div className='icon'>
                    <SpriteIcon label={label} sprite={label} />
                  </div>
                </div>
              )
            }
            else {
              return ( <div key={label} className='biomarker biomarker-sm'><img style={{ height: '32px', width: '32px' }} src={tru}></img></div>)
            }
          }) : ''
        }
      </div>
    )
  }
}

const convertAge = (current_age, visit) => {
  let now = Math.floor(Date.now() * 0.001) // Convert to seconds from ms
  let years_elapsed = new Date((now - visit) * 1000)
  let age = current_age - (years_elapsed.getYear() + (years_elapsed.getMonth() / 12.0) - 70.0)
  return age
}
import React, { Component } from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';
import BootstrapTable from 'react-bootstrap-table-next';

const regData = [
  'noumenonName',
  'noumenonCode',
  'units',
  'scale'
]

class CustomizationsDetails extends Component {
    constructor(props) {
      super(props)
    }
    componentDidMount() {
      if (this.props.match.params && this.props.match.params.siteId && this.props.match.params.code) {
        this.props.getNoumenonList(this.props.match.params.siteId, this.props.match.params.code, this.props.history, 'details')  
      }
    }
    render() {
      const rangesColumns = [
        { dataField: 'type', headerStyle: { width: '20%' }, text: labels.custom_noumenon_list.type, sort: true,
            formatter: (cellContent, row) => (<span>{constants.type.find(g => g.value == row.type) ? constants.type.find(g => g.value == row.type).text : ""}</span>)
        },
        { dataField: 'grade',headerStyle: { width: '10%' }, text: labels.custom_noumenon_list.grade, sort: true,
           formatter: (cellContent, row) => (<span>{constants.gradePoints.find(g => g.value == (parseFloat(row.gradePoints).toFixed(1))) ? constants.gradePoints.find(g => g.value == (parseFloat(row.gradePoints).toFixed(1))).text : ""}</span>)
        },
        { dataField: 'low',headerStyle: { width: '10%' }, text: labels.custom_noumenon_list.low, sort: true },
        { dataField: 'high',headerStyle: { width: '10%' }, text: labels.custom_noumenon_list.high, sort: true },
        { headerStyle: { width: '4%' },
          formatter: (cellContent, row) => (
              <i className='fa fa-pencil' onClick={() => this.props.editRange(this.props.history, `/sites/${this.props.currentSite.id}/customizations/${this.props.currentCustomNoumenon.noumenonCode}/rubric/${row.customRubricId}/range/${row.id}/edit`)}></i>
            )
        },
        { headerStyle: { width: '4%' },
          formatter: (cellContent, row) => (
            <i className='fa fa-trash text-danger trash-icon-noumenon' onClick={() => this.props.toggleRubricModal('deleteRange',  row.customRubricId, row.id )}></i>
          )
        }
    ]
      return (
        <>
          { !this.props.basicPlan ?
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 users-children'>
            <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                <div className='custom-noumenon-details-btn-section'>
                    <FormControl type='button' btnType='primary' text={labels.custom_noumenon_list.edit_customize_noumenon} onClick={() => this.props.editCustomNoumenon(this.props.history, `/sites/${this.props.currentSite.id}/customizations/${this.props.currentCustomNoumenon.noumenonCode}/edit`)}  />
                    <FormControl type='button' btnType='default' text={labels.custom_noumenon_list.add_rubric} onClick={() => this.props.addRubric(this.props.history, `/sites/${this.props.currentSite.id}/customizations/${this.props.currentCustomNoumenon.noumenonCode}/rubric/new`, this.props.currentCustomNoumenon.reportingNoumenonId)}/>
                </div>
            </div>
            <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0'>
                  { this.props.currentCustomNoumenon ?
                  <div className='users-table custimization-table w-auto'>
                    <div className='bootstrap-table-container mb-3'>
                      <div className='react-bootstrap-table'>
                        <table className='table table-striped table-hover'>
                        <thead></thead>
                          <tbody>
                          { regData.map(rd => (
                              <tr>
                                <th>{labels.custom_noumenon_list[rd]}</th>
                                <td>{this.props.currentCustomNoumenon[rd]}</td>
                              </tr>
                            )) }
                          </tbody>
                      </table>
                      </div>
                    </div>
                  </div>
                  : <div className='jumbotron'>
                      <h3>{labels.no_grid_data}</h3>
                  </div>}
                  { this.props.rubrics.length > 0 ?
                    <>
                      <h3>Rubrics Details</h3>
                      {this.props.rubrics.map(rubric =>(
                        <div>
                          <div className="card col-md-12 mb-4">
                            <div className="card-body">
                                <div className="options pull-right">
                                  <i className="fa fa-pencil mr-4" onClick={() => this.props.editRubric(this.props.history, `/sites/${this.props.currentSite.id}/customizations/${this.props.currentCustomNoumenon.noumenonCode}/rubric/${rubric.id}/edit`)} ></i>
                                  <i className='fa fa-trash text-danger trash-icon-noumenon mr-2' onClick={() => this.props.toggleRubricModal('deleteRubric', rubric.id )}></i>
                                </div>
                                <h5 class="card-title"> Rubric</h5>
                                <div className='users-table custimization-table w-auto'>
                                  <div className='bootstrap-table-container mb-3'>
                                    <div className='react-bootstrap-table'>
                                      <table className='table table-striped table-hover'>
                                      <thead></thead>
                                        <tbody>
                                          <tr>
                                            <th>{labels.noumena_labels.gender}</th>
                                            <td>{rubric.gender ? rubric.gender : constants.ALL}</td>
                                          </tr>
                                          <tr>
                                            <th>{labels.custom_noumenon_list.phase}</th>
                                            <td>{rubric.phase}</td>
                                          </tr>
                                          <tr>
                                            <th>{labels.custom_noumenon_list.startingAge}</th>
                                            <td>{rubric.startingAge}</td>
                                          </tr>
                                          <tr>
                                            <th>{labels.custom_noumenon_list.endingAge}</th>
                                            <td>{rubric.endingAge}</td>
                                          </tr>
                                          <tr>
                                            <th>{labels.custom_noumenon_list.startingHeight}</th>
                                            <td>{rubric.startingHeight}</td>
                                          </tr>
                                          <tr>
                                            <th>{labels.custom_noumenon_list.endingHeight}</th>
                                            <td>{rubric.endingHeight}</td>
                                          </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="options pull-right">
                                  <i className="fa fa-plus status-indicator text-info mr-2" onClick={() => this.props.addRange(this.props.history, `/sites/${this.props.currentSite.id}/customizations/${this.props.currentCustomNoumenon.noumenonCode}/rubric/${rubric.id}/range/new`, this.props.currentCustomNoumenon.noumenonCode, rubric.id)} >  Add Range</i>
                                </div>
                                { rubric.customRubricRangesDTO && rubric.customRubricRangesDTO.length > 0 ?
                                  <>
                                    <h5 className="card-title"> Ranges </h5>
                                    <div className='users-table custimization-table w-auto'>
                                      <div className='bootstrap-table-container'>
                                          <BootstrapTable striped={true} bordered={false} hover={true} bootstrap4 keyField='id'
                                              data={rubric.customRubricRangesDTO ? rubric.customRubricRangesDTO : []} columns={rangesColumns}
                                          />
                                      </div>
                                    </div>
                                  </>
                                : ''}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  : ""}
            </div>
            <div className={`modal fade ${this.props.isRubricModal ? 'show' : ''}`} role='dialog'>
              <div className='modal-dialog modal-dialog-centered modal-md'>
                  <div className='modal-content'>
                      <div className='modal-header'>
                          <h3>{labels.custom_fields_labels.noumeon_delete_warning}</h3>
                          <i className='fa fa-times fa-lg status-indicator text-danger' onClick={() => this.props.toggleRubricModal()}></i>
                      </div>
                      <div className='modal-body'>
                          <React.Fragment>
                              <div className="mb-4">{labels.custom_fields_labels.delete_warnings[this.props.deleteModal]}</div>
                              <div className='d-flex justify-space-between'>
                                  <FormControl type='button'
                                      btnType='default' className='btn-sm'
                                      text={labels.help_page_labels.cancel_btn} onClick={() => this.props.toggleRubricModal()} />
                                  <FormControl type='button'
                                      btnType='danger' className='btn-sm'
                                      text={labels.help_page_labels.ok_btn}
                                      onClick={() => this.props.deleteRubricDetails(this.props.history, this.props.deleteModal, this.props.deleteRubricId, this.props.deleteRangeId, )}
                                    />
                              </div>
                          </React.Fragment>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        : window.location.replace('/statuscode/404')}
        </>   
      )
    }
}

export default CustomizationsDetails;
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { parse } from 'qs';
import ReactPaginate from 'react-paginate';

import labels from '../../../config/localization';

import FormControl from '../../../components/FormControls/FormControl.js';
import constants from '../../../config/constants';
import en_labels from '../../../config/en_labels';
import LoadingTemplate from '../../../components/LoadingTemplate';
import resolveSearchFilters from '../../../utils/resolve-search-params';

const PatientDetails = (props) => {
    return (
        <div className='patient-info col-sm-3 hidden-xs'>
            <legend>{props.title}</legend>
            <h2 className='text-center'>{props.value}</h2>
        </div>
    )
}
class PatientsList extends Component {
    constructor(props) {
        super(props)
        this.name = React.createRef();

    }

    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        document.title = `${labels.physioage_label}: ${labels.patient_list_labels.title_label}`
        this.props.clearSearch(this.urlParams, this.props.history)

        this.props.setPageTitle(labels.patient_list_labels.title_label)
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.patient_list_labels.title_label, path: '' }
        ])
        this.props.setFirstTimeLoad(true)
        this.props.noHeaderLoading(true)
        this.props.getNamesList()
        this.props.setLoggedInUser()

        this.unlisten = this.props.history.listen((location, action) => {
            if (location && location.search && this.props.location
                && action == 'POP') {
                const queryString = location && location.search && location.search.substr(1)
                this.urlParams = parse(queryString, true);
                this.props.clearSearch(this.urlParams)
            }
        })
    }
    componentWillUnmount() {
        this.props.noHeaderLoading(false)
        this.unlisten()
    }
    componentWillReceiveProps(newProps) {

        if ((this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search)) {
            this.props.clearFilters(true)
            if (this.name && this.name.current) {
                this.name.current.inputEntry.clear()
            }
            if (this.minimumvisits && this.minimumvisits.inputEntry) {
                this.minimumvisits.inputEntry.value = ''
            }
        }
        else if (this.props.isSearchCleared) {
            if (this.name && this.name.current) {
                this.name.current.inputEntry.clear()
            }
            if (this.minimumvisits && this.minimumvisits.inputEntry) {
                this.minimumvisits.inputEntry.value = ''
            }

        }
    }
    // getCaret = (order) => {
    //     if (this.props.searchParam && !this.props.searchParam.sortField) {
    //         return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>)
    //     }
    //     if (!order) return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
    //     else if (order === 'asc') return (<span>&nbsp;&nbsp;<i className='fa fa-caret-up'></i></span>);
    //     else if (order === 'desc') return (<span>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
    //     return null;
    // }
    getCaret = (order, field) => {
        order = this.props.searchParam.sortOrder ? constants.sorting_order_decode[this.props.searchParam.sortOrder] : order
        if (this.props.searchParam) {
            if (!this.props.searchParam.sortField && field != 'lastActiveDate') {
                return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>)
            }
            if (!order) return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else if (order === 'asc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-up'></i></span>);
            else if (order === 'desc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
        }
        return null;
    }
    render() {
        let columns = [];
        // { this.props.loggedInUser && this.props.loggedInUser.id ?
            columns = [
                {
                    dataField: 'fullname',
                    text: labels.patient_list_labels.name_label,
                    formatter: (cellContent, row) => (<Link className='patient-fullname' to= { this.props.loggedInUser.originalRole == constants.logged_roles.PR ? `/patients/${row.id}/analytics` : `/patients/${row.id}`}>{row.lastname}, {row.firstname}</Link>),
                    sort: true,
                    sortCaret: (order, column) => this.getCaret(order, 'fullname'),
                },
                {
                    dataField: 'dob', text: labels.inbox_labels.dob,
                    formatter: (cellContent, row) => (<span>{row.dob}</span>),
                    sort: true, headerStyle: { width: '15%' },
                    sortCaret: (order, column) => this.getCaret(order, 'dob'),
                },
                {
                    dataField: 'gender', text: labels.patient_list_labels.sex,
                    headerStyle: { width: '10%' }, sort: true, sortCaret: (order, column) => this.getCaret(order, 'gender'),
                },
                {
                    dataField: 'providerName', text: labels.user_labels.provider_label,
                    headerStyle: { width: '25%' },
                    sort: true, sortCaret: (order, column) => this.getCaret(order, 'providername'),
                    formatter: (cellContent, row) => (<span className='patient-fullname'>{row.providerName}</span>)

                },
                {
                    dataField: 'visits', text: labels.patient_list_labels.visits, headerStyle: { width: '10%' },
                    sort: true, sortCaret: (order, column) => this.getCaret(order, 'minimumvisits'),
                    formatter: (cellContent, row) => (<span>{row.visits ? row.visits : 0}</span>)
                },
                {
                    dataField: 'lastactive',
                    text: labels.patient_list_labels.last_active, sort: true,
                    sortCaret: (order, column) => this.getCaret(order, 'lastActiveDate'), headerStyle: { width: '15%' },
                    formatter: (cellContent, row) => (
                        <span>{row.lastActiveDate != null && !isNaN(new Date(row.lastActiveDate).getDate()) ? moment(row.lastActiveDate).format('L') : row.lastActiveDate ? row.lastActiveDate : ""}</span>)
                }
            ]
        // : columns }
    
        // handle any data change on table here
        const onTableChange = (type, { sortField, sortOrder }) => {
            if (type == 'sort') {
                let sortfield;
                switch (sortField) {
                    case 'visits': sortfield = 'minimumvisits';
                        break;
                    case 'providerName': sortfield = 'providername'
                        break;
                    case 'lastactive': sortfield = 'lastActiveDate'
                        break;
                    default: sortfield = sortField
                }
                this.props.sortPatients(sortfield, sortOrder, this.props.history, this.urlParams && this.urlParams.page ? this.urlParams.page : 1)
            }
        }

        const paginationOptions = paginationFactory({
            page: this.props.searchParam ? this.props.searchParam.pageNumber : 1,
            pageSize: this.props.searchParam ? this.props.searchParam.pageSize : 10,
            totalSize: this.props.totalPatients,
            onPageChange: (page) => { this.props.patientsPageChange(page, false, this.props.history) },
            alwaysShowAllBtns: true,
            prePageText: 'Prev',
            nextPageText: 'Next',
            withFirstAndLast: false,
            hideSizePerPage: true,
        })
        // if (this.props.searchParam && this.props.searchParam.searchFilters)
        //     this.props.currentSearchFilters = resolveSearchFilters(this.props.searchParam.searchFilters)
        return (
            this.props.isLoading && this.props.isFirstTimeLoad ? <>
                <LoadingTemplate haveSidebar={true} isGrid={true} />
            </> :
                <>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                        <div className='card patients-filter'>
                            <div className='card-header'>
                                {labels.patient_list_labels.filter_patients_label}
                            </div>

                            <form className='card-body' onSubmit={(e) => { e.preventDefault(); this.props.clearNames(); this.props.patientsPageChange(1, true); this.props.getPatients(this.props.history) }}>
                                {/* { this.props.loggedInUser.role == constants.logged_roles.AN ? */}
                                    <FormControl
                                        id='siteId' label={labels.patient_list_labels.site_label}
                                        type='select' onChange={(e) => this.props.setSearchParam(e.target.value, e.target.id, false, this.props.history)}
                                        defaultOption={labels.patient_list_labels.all_sites}
                                        value={this.props.currentSearchFilters && this.props.currentSearchFilters.siteId ? parseInt(this.props.currentSearchFilters.siteId) : 0}
                                        disabled={!this.props.sitesList || !this.props.sitesList.length}
                                        options={this.props.sitesList && this.props.loggedInUser && this.props.loggedInUser.siteIds ? this.props.loggedInUser.role == 'admin' ? this.props.sitesList : this.props.sitesList.filter(site => this.props.loggedInUser.siteIds.includes(site.id)) : []} 
                                        textKey={'name'} valueKey={'id'} /> 
                                    {/* : null} */}
                                <FormControl
                                    id='statusID' label={'Status'} name='status' nodefaultOption={true}
                                    value={this.props.currentSearchFilters && this.props.currentSearchFilters.status ? this.props.currentSearchFilters.status : '0'}
                                    onChange={(e) => this.props.setSearchParam(e.target.value, e.target.name, false, this.props.history)}
                                    type='select' options={constants.active_status}
                                    textKey={'text'} valueKey={'value'}
                                />
                                <div onBlur={(e) => {this.props.clearNamesOnClick(e)}} >
                                    <FormControl
                                        onKeyDown={(e) => {
                                            if (e.keyCode == constants.enter_key_code || e.keyCode == constants.escape_key_code) {
                                                this.props.searchCustomName(e, this.props.history);
                                            }
                                        }}
                                        id='name' ref={this.name} label={labels.patient_list_labels.name_label}
                                        open={this.props.currentNames && this.props.currentNames.length ? true : false}
                                        type='typeahead' onSearch={(name) => this.props.populateSearchParam(name, this.props.history)}
                                        defaultSelected={this.props.currentSearchFilters && this.props.currentSearchFilters.name ? [this.props.currentSearchFilters.name] : []}
                                        onInputChange={(name) => this.props.populateSearchParam(name, this.props.history)} labelKey={'firstName'}
                                        options={this.props.currentNames}
                                        emptyLabel=''
                                        onChange={(e) => {
                                            if (e && e.length && _.head(e)) {
                                                this.props.setSearchParam(_.head(e), 'name', false, this.props.history)
                                                this.props.clearNames()
                                            }
                                        }} 
                                    />
                                </div>
                                <FormControl
                                    ref={ref => this.minimumvisits = ref}
                                    id='minimumvisits' label={labels.patient_list_labels.min_visits_label}
                                    defaultValue={this.props.currentSearchFilters && this.props.currentSearchFilters.minimumvisits
                                        ? parseInt(this.props.currentSearchFilters.minimumvisits) : null}
                                    type='numberonly' onChange={(e) => this.props.setSearchParam(e.target.value, e.target.id, true, this.props.history)} />
                                <FormControl
                                    type='button' btnType='default'
                                    className='search-btn' text={labels.patient_list_labels.search} />
                            </form>
                        </div>
                        <div className='add-patient-btn-sec'>
                            <FormControl type='button' btnType='primary' text={labels.patient_list_labels.add_patient} onClick={() => this.props.newPatient(this.props.history)} />
                        </div>
                        {this.props.loggedInUser.role == constants.logged_roles.CG ?
                            <div className='add-patient-btn-sec'>
                                <FormControl type='button' btnType='primary' text={labels.sites_labels.download_patients} onClick={() => this.props.downloadPatientsAttendeesList()} />
                            </div> : ''}
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 right-section p-0 order-1'>
                        <div className='patients-table patients-list-section'>
                            {this.props.patients && this.props.patients.length ?
                                <div className='row react-bootstrap-table patient-stats hidden-xs'>
                                    <PatientDetails title={en_labels.patient_list_labels.total_patients} value={this.props.totalPatients} />
                                    <PatientDetails title={en_labels.patient_list_labels.total_visits} value={this.props.totalVisits} />
                                    <PatientDetails title={en_labels.patient_list_labels.avg_visit_count} value={parseFloat(this.props.visitAverage) ? this.props.visitAverage : ''} />
                                    <PatientDetails title={en_labels.patient_list_labels.avg_data} value={parseFloat(this.props.averageData) ? this.props.averageData : ''} />
                                </div>
                                : ''}
                            {this.props.patients && this.props.patients.length ? <><BootstrapTable
                                remote
                                striped={false}
                                bordered={false}
                                hover={true}
                                bootstrap4
                                keyField='id'
                                data={this.props.patients}
                                columns={columns}
                                onTableChange={onTableChange}
                                // defaultSorted={this.props.searchParam && this.props.searchParam.sortField ? [{
                                //     dataField: sortingParams[this.props.searchParam.sortField.toLowerCase()],
                                //     order: constants.sorting_order_decode[this.props.searchParam.sortOrder.toString()]
                                // }] : [{ dataField: 'lastactive', order: 'desc' }]}
                                pagination={false} />

                                {this.props.totalPatients < 10 ? false :
                                    <div id='react-paginate' className='mt-3'>
                                        <ReactPaginate
                                            previousLabel={'Prev'}
                                            nextLabel={'Next'}
                                            breakLabel={'...'}
                                            previousClassName='page-item'
                                            nextClassName='page-item'
                                            breakClassName='page-item disabled'
                                            breakLinkClassName='page-link'
                                            pageClassName='page-item'
                                            pageLinkClassName='page-link'
                                            previousLinkClassName='page-link'
                                            nextLinkClassName='page-link'
                                            activeClassName='active'
                                            pageCount={Math.ceil(this.props.totalPatients / 10)}
                                            marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                            pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                            disableInitialCallback={true}
                                            initialPage={this.props.searchParam ? this.props.searchParam.pageNumber - 1 : 1}
                                            onPageChange={(page) => { this.props.patientsPageChange(Math.ceil(page.selected) + 1, false, this.props.history) }}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                        />
                                    </div>}
                            </> : this.props.no_grid_data ?
                                <div className='jumbotron'>
                                    <h3>{labels.no_grid_data}</h3>
                                </div>
                                : null}
                        </div>
                    </div>
                </>


        )
    }
}
export default PatientsList;
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import _ from 'lodash';
import { parse } from 'qs';
import constants from '../../../config/constants';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import './billing-summary-styles.scss';
import LoadingTemplate from '../../../components/LoadingTemplate';
import ReactPaginate from 'react-paginate';
import PageTitle from '../../../components/PageTitle';

class BillingSummary extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.props.setInitialLoading(true);
        this.urlParams = parse(queryString, true);
        if (this.urlParams && Object.keys(this.urlParams).length)
            this.props.setDefaultBillingSummary(this.urlParams, this.props.history)
        else
            this.props.resetRanges(this.props.history)
        this.props.getSites()
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.billing_labels.title, path: '' }
        ])
        document.title = `${labels.physioage_label}: ${labels.billing_labels.title}`
        this.props.setPageTitle(labels.billing_labels.title)
        if (this.billingSummaryForm && this.billingSummaryForm.inputEntry)
            this.billingSummaryForm.inputEntry.focus()

        this.unlisten = this.props.history.listen((location, action) => {
            if (location && location.search && this.props.location
                && action == 'POP') {
                const queryString = location && location.search && location.search.substr(1)
                this.urlParams = parse(queryString, true);
                this.props.setDefaultBillingSummary(this.urlParams)
            }
        })
    }
    componentWillUnmount() {
        this.props.toggleNotification(false)
        this.unlisten()
    }
    componentWillReceiveProps(newProps) {
        if (this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search) {
            this.props.resetRanges(this.props.history)
        }
    }
    getCaret = (order, field) => {
        order = this.props.searchParam.sortOrder ? constants.sorting_order_decode[this.props.searchParam.sortOrder] : order
        if (this.props.searchParam) {
            // if (!this.props.searchParam.sortField && field != 'lastActiveDate') {
            //     return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>)
            // }
            if (!order) return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else if (order === 'asc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-up'></i></span>);
            else if (order === 'desc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
        }
        return null;
    }
    render() {
        const columns = [
            {
                dataField: 'physician',
                text: labels.billing_labels.physician,
                sort: true,
                sortCaret: (order, column) => this.getCaret(order, 'Physician')
            },
            { dataField: 'site', text: labels.billing_labels.site_label, sort: true, sortCaret: (order, column) => this.getCaret(order, 'Site') },
            { dataField: 'patient', text: labels.billing_labels.patient, sort: true, sortCaret: (order, column) => this.getCaret(order, 'Patient') },
            { dataField: 'timePoint', text: labels.billing_labels.timepoint, sort: true, sortCaret: (order, column) => this.getCaret(order, 'TimePoint') },
            { dataField: 'created', text: labels.billing_labels.created, sort: true, sortCaret: (order, column) => this.getCaret(order, 'Created'), formatter: (cellContent, row) => <span>{moment(cellContent).format('L')}</span> },
            { dataField: 'visitDate', text: labels.billing_labels.visit_date, sort: true, sortCaret: (order, column) => this.getCaret(order, 'VisitDate'), formatter: (cellContent, row) => <span>{moment(cellContent).format('L')}</span> }
        ]

        const paginationOptions = paginationFactory({
            page: this.props.searchParam ? this.props.searchParam.pageNumber : 1,
            pageSize: this.props.searchParam ? this.props.searchParam.pageSize : 10,
            onPageChange: (page) => { this.props.billingPageChange(page, this.props.history) },
            totalSize: this.props.totalBillings,
            alwaysShowAllBtns: true,
            prePageText: 'Prev',
            nextPageText: 'Next',
            withFirstAndLast: false,
            hideSizePerPage: true,
        })

        const onTableChange = (type, { sortField, sortOrder }) => {
            if (type == 'sort') {
                let sortfield;
                switch (sortField) {
                    case 'physician': sortfield = 'Physician';
                        break;
                    case 'site': sortfield = 'Site'
                        break;
                    case 'patient': sortfield = 'Patient'
                        break;
                    case 'timePoint': sortfield = 'TimePoint'
                        break;
                    case 'created': sortfield = 'Created'
                        break;
                    case 'visitDate': sortfield = 'VisitDate'
                        break;
                    default: sortfield = sortField
                }
                this.props.sortBillingSummry(sortfield, sortOrder, this.props.history, this.urlParams && this.urlParams.page ? this.urlParams.page : 1)
            }
        }

        return (
            this.props.isLoading && this.props.initialLoading ? <>
                <LoadingTemplate haveSidebar={true} isGrid={true} />
            </> : <div className={`billing-summary-component row parent-component${this.props.isParentContainer ? ' container' : ''} billing-summary-table`}>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                        <PageTitle breadCrumbs={this.props.breadCrumbs}
                            pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                            navigatePatients={this.props.navigatePatients}
                            history={this.props.history}
                            {...this.props} />
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0'>
                    <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                        <div className='card'>
                            <div className='card-header'>
                                {labels.billing_labels.filter_label}
                            </div>
                            <div className='card-body'>
                                <form onSubmit={(e) => {
                                    e.preventDefault(); this.props.getBillingSummary(this.props.history, true, false, this.urlParams.sortField && this.urlParams.sortField.length ? true : false)
                                }}>
                                    <FormControl type='select' id='siteId' ref={(p) => this.billingSummaryForm = p}
                                        label={labels.billing_labels.site_label}
                                        defaultOption={labels.patient_list_labels.all_sites}
                                        onChange={(e) => this.props.setSearchParam(e.target.value, 'siteId')}
                                        //defaultValue={this.props.currentBillingSite ? this.props.currentBillingSite : 0}
                                        value={this.props.currentBillingSite ? this.props.currentBillingSite : 0}
                                        options={this.props.sites} textKey='name' valueKey='id' />
                                    <FormControl type='date' selected={this.props.startDate}
                                        maxDate={moment(this.props.endDate).add(-1, 'days').toDate()}
                                        disabledKeyboardNavigation
                                        id='startDate' onKeyDown={(e) => {
                                            if (e.key.toLowerCase() == 'enter')
                                                this.props.getBillingSummary(this.props.history, true)
                                        }} onChange={(e) => this.props.setSearchParam(e, 'startDate')} label={labels.billing_labels.startdate_label} />
                                    <FormControl type='date' selected={this.props.endDate}
                                        minDate={moment(this.props.startDate).add(1, 'days').toDate()}
                                        disabledKeyboardNavigation
                                        id='endDate' onKeyDown={(e) => {
                                            if (e.key.toLowerCase() == 'enter')
                                                this.props.getBillingSummary(this.props.history, true)
                                        }} onChange={(e) => this.props.setSearchParam(e, 'endDate')} label={labels.billing_labels.enddate_label} />
                                    <FormControl type='button' btnType='info' text={labels.billing_labels.generate} />
                                </form>
                            </div>
                        </div>
                        <div className='card'>
                            {this.props.reports ? <table className='table billing-reports'>
                                <tr><td><strong>{labels.billing_labels.num_reports_labels}</strong></td><td>{this.props.reports[constants.billing_labels.numberOfReports] ? this.props.reports[constants.billing_labels.numberOfReports] : '0'}</td></tr>
                                <tr><td><strong>{labels.billing_labels.charge_report}</strong></td><td>{this.props.reports[constants.billing_labels.chargePerReport] ? this.props.reports[constants.billing_labels.chargePerReport] : '50.0'}</td></tr>
                                <tr><td><strong>{labels.billing_labels.total_charge}</strong></td><td>{this.props.reports[constants.billing_labels.totalCharges] ? this.props.reports[constants.billing_labels.totalCharges] : '0.0'}</td></tr>
                            </table> : null}
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 order-1 p-0 billing-summary-table'>
                        {this.props.billingSummary && this.props.billingSummary.length ?
                            <>
                                <div className='bootstrap-table-container'>
                                    <BootstrapTable
                                        remote
                                        striped={false}
                                        bordered={false}
                                        hover={true}
                                        responsive={true}
                                        bootstrap4
                                        keyField='id'
                                        data={this.props.billingSummary}
                                        columns={columns}
                                        pagination={false}
                                        onTableChange={onTableChange} />
                                </div>
                                {this.props.totalBillings < 10 ? false :
                                    <div id='react-paginate' className='mt-3'>
                                        <ReactPaginate
                                            previousLabel={'Prev'}
                                            nextLabel={'Next'}
                                            breakLabel={'...'}
                                            previousClassName='page-item'
                                            nextClassName='page-item'
                                            breakClassName='page-item disabled'
                                            breakLinkClassName='page-link'
                                            pageClassName='page-item'
                                            pageLinkClassName='page-link'
                                            previousLinkClassName='page-link'
                                            nextLinkClassName='page-link'
                                            activeClassName='active'
                                            pageCount={Math.ceil(this.props.totalBillings / 10)}
                                            marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                            pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                            disableInitialCallback={true}
                                            initialPage={this.props.searchParam ? this.props.searchParam.pageNumber - 1 : 0}
                                            onPageChange={(page) => { this.props.billingPageChange(Math.ceil(page.selected) + 1, this.props.history) }}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                        />
                                    </div>}
                            </>
                            : this.props.no_grid_data ?
                                <div className='jumbotron'>
                                    <h3>{labels.no_grid_data}</h3>
                                </div> : null}
                    </div>
                </div>
            </div>
        )
    }
}
export default BillingSummary;
import { connect } from 'react-redux';
import Login from './Login';
import { loginHandler, loginInput, getAuthStatus ,toggleNotification,clearSentMailStatus, removeToggleNotification, toggleSignupRendering, deleteCurrentSignupPaymentDetails } from '../../reducers/global-reducer';
import { withRouter } from 'react-router';

const mapStateToProps = state => ({
  ...state.global
});

const mapDispatchToProps = {
  loginHandler,
  loginInput,
  getAuthStatus,
  toggleNotification,
  clearSentMailStatus,
  removeToggleNotification,
  toggleSignupRendering,
  deleteCurrentSignupPaymentDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Login));

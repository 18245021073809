import React, { Component } from 'react';
import FormControl from '../../../components/FormControls';
import labels from '../../../config/localization';
import moment from 'moment';
import logo from '../../../assets/images/physioage_system_logo.png';
const amount = (value) => {
    let amount
    if (value < 0)
        amount = `-$${Math.abs(value) * 50}.00`
    else
        amount = `$${value * 50}.00`
    return amount
}

class Receipt extends Component {
    render() {
        return (
            <div className='receipt container'>
                <div className={`modal fade ${this.props.isOpenModal ? 'show' : ''}`} role='dialog'>
                    <div className=' modal-dialog modal-dialog-centered modal-md'>
                        <div className='modal-content model-wrapper'>
                            <div className='modal-header d-flex justify-content-between'>
                                <div className='mt-3 '><h2>{labels.credits_labels.receipt_header}</h2></div>
                                <img src={logo} alt='Physioage-logo' className='img-opacity' />
                            </div>
                            {this.props.receipt ?
                                <div className='modal-body'>
                                    <table className='table  mb-4'>
                                        <tr className=' pt-2 border-top-0'>
                                            <th className='text-center border-top-0 pr-0 pb-0'>{labels.credits_labels.date}</th>
                                            <td className='text-left border-top-0 pl-0 pb-0'>{this.props.receipt.date ? moment(this.props.receipt.date).format('MM/DD/YYYY') : null}</td>
                                        </tr>
                                        <tr className=' pt-2'>
                                            <th className='text-center border-top-0 pr-0'>{labels.credits_labels.client}</th>
                                            <td className='text-left border-top-0 pl-0'>{this.props.receipt.siteName}</td>
                                        </tr>
                                    </table>
                                    <table className='table  '>
                                        <thead>
                                            <tr>
                                                <th>{labels.credits_labels.description}</th>
                                                <th className='text-middle'>{labels.credits_labels.quantity}</th>
                                                <th className='text-middle'>{labels.credits_labels.amount} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.props.receipt.comment}</td>
                                                <td className='text-middle'>{this.props.receipt.change}</td>
                                                <td className='text-middle'>{this.props.receipt.change ? amount(this.props.receipt.change) : null}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td className='text-middle'>{this.props.receipt.change ? amount(this.props.receipt.change) : null}</td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                </div>
                                : null}
                            <div className='modal-footer'>
                                <FormControl type='button' btnType='primary' text={labels.payments_labels.back_to_site} onClick={() => this.props.toggleReceipt(this.props.history)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default Receipt;
import React, { useEffect, useState }from 'react';
import { connect } from 'react-redux';
import close from '../../../assets/images/close.svg';
import constants from '../../../config/constants';
import BiomarkersComparisonChart from './BiomarkersComparisonChart';
import labels from '../../../config/localization';
import closeLight from '../../../assets/images/close-light.svg';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '../../../components/FormControls/FormControl';

let selectedLines = [];
let selectedData = {};
let selectedAsterisk = {};
let chartContainer;
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export function SelectBioMarker(code, fromChart=false) {
    let updatedLines;
    let hoverElements = document.querySelectorAll('.detail');
    let hoverElementsArray = Array.from(hoverElements);
    let filteredElements = hoverElementsArray.filter(element => element.hasChildNodes());
    if(fromChart){
        if(selectedLines.includes(code) && filteredElements){
            filteredElements.forEach(function(element){
                element.className = '';
                element.classList.add('detail');
                element.classList.add('selected-hover');
            });
        }
        else{
            filteredElements.forEach(function(element){
                element.className = '';
                element.classList.add('detail');
                element.classList.add('unselected-hover');
            });
        }
    }
    else{
        let selectedElement;
        let selectedDataPoints;
        if(selectedLines.includes(code)){
            let unselectedLine = document.getElementById(`${code}-biomarker-line`);
            unselectedLine ? unselectedLine.classList.remove('selected-biomarker-line') : '';
            updatedLines = selectedLines.filter((line) => line !== code);
            let unSelectedDataPoints = document.querySelectorAll(`.data-point-circle-${code}`);
            unSelectedDataPoints.forEach(data => {
                data ? data.classList.remove('selected-data-point') : '';
                data ? data.classList.add('unselected-data-point') : '';
            });
            delete selectedData[code];

            let unSelectedAsterisk = document.querySelectorAll(`.${code}-asterisk`);
            unSelectedAsterisk.forEach(data => {
                data ? data.classList.remove('selected-asterisk') : '';
                data ? data.classList.add('unselected-asterisk') : '';
            });
            delete selectedAsterisk[code];

            document.getElementById(`${code}-color-description`).classList.remove('selected');

            document.querySelectorAll(`.data-point-circle-${code}`).forEach(circle => {
                circle.style.opacity = '0.1';
            });
            document.getElementById(`${code}-line-color`) && (document.getElementById(`${code}-line-color`).style.opacity = '0.5');
            document.getElementById(`${code}-biomarker-name`) && (document.getElementById(`${code}-biomarker-name`).style.opacity = '0.5');
            document.getElementById(`${code}-checkbox`) && (document.getElementById(`${code}-checkbox`).checked = false);

        }
        else{
            selectedElement = document.getElementById(`${code}-biomarker-line`);
            selectedElement ? selectedElement.classList.add('selected-biomarker-line') : '';
            document.getElementById(`${code}-checkbox`) && (document.getElementById(`${code}-checkbox`).checked = true);
            chartContainer = document.getElementById('drawer-body-biomarkers-comparison');
            chartContainer ? chartContainer.classList.add('selected') : '';

            selectedDataPoints = document.querySelectorAll(`.data-point-circle-${code}`);
            selectedDataPoints.forEach(element => {
                element ? element.classList.remove('unselected-data-point') : '';
                element ? element.classList.add('selected-data-point') : '';
            });

            selectedAsterisk = document.querySelectorAll(`.${code}-asterisk`);
            selectedAsterisk.forEach(element => {
                element ? element.classList.remove('unselected-asterisk') : '';
                element ? element.classList.add('selected-asterisk') : '';
            });

            if(!selectedLines.includes(code)){
                selectedData[code] = selectedDataPoints;
                updatedLines = [...selectedLines, code];
                selectedAsterisk[code] = selectedAsterisk;
            }
            document.getElementById(`${code}-color-description`).classList.add('selected');

            document.getElementById(`${code}-line-color`) && (document.getElementById(`${code}-line-color`).style.opacity = '1');
            document.getElementById(`${code}-biomarker-name`) && (document.getElementById(`${code}-biomarker-name`).style.opacity = '1');

            document.querySelectorAll(`.data-point-circle-${code}`).forEach(circle => {
                circle.style.opacity = '1';
            });
            document.getElementById(`${code}-checkbox`) && (document.getElementById(`${code}-checkbox`).checked = true);

        }
        selectedLines = updatedLines;
    }
    
}

export function DeselectBioMarkerLines(){
    let selectedElements = document.querySelectorAll('.selected');
    if(selectedElements){
        selectedElements.forEach(element => {
            element ? element.classList.remove('selected') : '';
        });
    }
    chartContainer = document.getElementById('drawer-body-biomarkers-comparison');
    if(chartContainer){
        chartContainer.classList.remove('selected');
    }
    if(selectedLines){
        selectedLines.forEach(line => {
            document.getElementById(`${line}-biomarker-line`) ? document.getElementById(`${line}-biomarker-line`).classList.remove('selected-biomarker-line') : '';
            document.getElementById(`${line}-color-description`) ? document.getElementById(`${line}-color-description`).classList.remove('selected') : '';
            
            document.querySelectorAll(`.data-point-circle-${line}`).forEach(subElement => {
                subElement ? subElement.classList.remove('selected-data-point') : '';
                subElement ? subElement.classList.add('unselected-data-point') : '';
            });

            document.querySelectorAll(`.${line}-asterisk`).forEach(subElement => {
                subElement ? subElement.classList.remove('selected-asterisk') : '';
                subElement ? subElement.classList.add('unselected-asterisk') : '';
            });

            document.getElementById(`${line}-line-color`) && (document.getElementById(`${line}-line-color`).style.opacity = '0.5');
            document.getElementById(`${line}-biomarker-name`) && (document.getElementById(`${line}-biomarker-name`).style.opacity = '0.5');
            document.getElementById(`${line}-checkbox`) && (document.getElementById(`${line}-checkbox`).checked = false);

        }); 
    }
    selectedLines = [];
    selectedData = {};
    selectedAsterisk = {};
}

export function DisableBiomarkerLines(props){
    let historicDataList = Object.keys(props.historic_data_chart).map((key) => {
        const firstItem = _.head(props.historic_data_chart[key]);
        return firstItem ? firstItem.code : undefined;
    });

    let biomarkerList = props.biomarkerComparisonList.length > 0
                        ? props.biomarkerComparisonList
                        : historicDataList.filter(item => item !== undefined);

    biomarkerList.forEach(line => {
        document.getElementById(`${line}-biomarker-line`) ? document.getElementById(`${line}-biomarker-line`).classList.remove('selected-biomarker-line') : '';
        document.getElementById(`${line}-color-description`) ? document.getElementById(`${line}-color-description`).classList.remove('selected') : '';

        document.querySelectorAll(`.data-point-circle-${line}`).forEach(subElement => {
            subElement ? subElement.classList.remove('selected-data-point') : '';
            subElement ? subElement.classList.add('unselected-data-point') : '';
        });

        document.querySelectorAll(`.${line}-asterisk`).forEach(subElement => {
            subElement ? subElement.classList.remove('selected-asterisk') : '';
            subElement ? subElement.classList.add('unselected-asterisk') : '';
        });

        document.getElementById(`${line}-line-color`) && (document.getElementById(`${line}-line-color`).style.opacity = '0.5');
        document.getElementById(`${line}-biomarker-name`) && (document.getElementById(`${line}-biomarker-name`).style.opacity = '0.5');
        document.getElementById(`${line}-checkbox`) && (document.getElementById(`${line}-checkbox`).checked = false);
    });
    
    selectedLines = [];
}

export function EnableBiomarkerLines(props){
    let historicDataList = Object.keys(props.historic_data_chart).map((key) => {
                                const firstItem = _.head(props.historic_data_chart[key]);
                                return firstItem ? firstItem.code : undefined;
                            });
      
    let biomarkerList = props.biomarkerComparisonList.length > 0
                        ? props.biomarkerComparisonList
                        : historicDataList.filter(item => item !== undefined);
    biomarkerList.forEach(line => {
        document.getElementById(`${line}-biomarker-line`) ? document.getElementById(`${line}-biomarker-line`).classList.add('selected-biomarker-line') : '';
        document.getElementById(`${line}-color-description`) ? document.getElementById(`${line}-color-description`).classList.add('selected') : '';

        document.querySelectorAll(`.data-point-circle-${line}`).forEach(subElement => {
            subElement ? subElement.classList.add('selected-data-point') : '';
            subElement ? subElement.classList.remove('unselected-data-point') : '';
        });

        document.querySelectorAll(`.${line}-asterisk`).forEach(subElement => {
            subElement ? subElement.classList.add('selected-asterisk') : '';
            subElement ? subElement.classList.remove('unselected-asterisk') : '';
        });

        document.getElementById(`${line}-line-color`) && (document.getElementById(`${line}-line-color`).style.opacity = '1');
        document.getElementById(`${line}-biomarker-name`) && (document.getElementById(`${line}-biomarker-name`).style.opacity = '1');
        document.querySelectorAll(`.data-point-circle-${line}`).forEach(circle => {
            circle.style.opacity = '1';
        });
        document.getElementById(`${line}-checkbox`) && (document.getElementById(`${line}-checkbox`).checked = true);

        if(!selectedLines.includes(line)){
            selectedLines.push(line);
        }
    });
}

function BiomarkerComparisonModel(props) {
    useEffect(() => {
        if(props.biomarkerComparisonList.length > 0 || Object.keys(props.historic_data_chart).length <= 5){
            EnableBiomarkerLines(props);
        }
        else{
            DisableBiomarkerLines(props);
        }
        return () => {
          // Reset global variables
          selectedLines = [];
          selectedData = {};
          selectedAsterisk = {};
        };
      }, []);
    return (
        <>
            <div className='drawer-warning-overlay'></div> 
            <div className={props.mobile ? "biomarker-compare-popup-mobile" : "biomarker-compare-popup" }>
                <div className='d-flex row header no-gutters'>
                    <div className='col-11 bold-14-text'>{labels.analytics_labels.compare_biomarkers}</div>
                    <div className='col-1 justify-content-end d-flex'>
                        <a><img src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleBiomarkerComparePopup(false))}></img></a>
                    </div>
                </div>
                <div className='biomarkers-list-popup'>
                    <div id={'drawer-body-biomarkers-comparison'} className='biomarkers-render'>
                        <div className='chart-content col-lg-8 col-md-9 col-sm-12'>
                        {props.historic_data_chart ?  
                            
                            <BiomarkersComparisonChart {...props}
                                noumenon_name={props.biomarkerComparisonList.length > 0 ? _.head(props.historic_data_chart[constants.biomarkers_of_aging[_.head(props.biomarkerComparisonList)]]).code.toLowerCase() : _.head(props.historic_data_chart[_.head(Object.keys(props.historic_data_chart))]).code.toLowerCase()}
                                historic_data={props.historic_data_chart && props.biomarkerComparisonList.length > 0 ? props.historic_data_chart[constants.biomarkers_of_aging[_.head(props.biomarkerComparisonList)]] : props.historic_data_chart[_.head(Object.keys(props.historic_data_chart))]}
                                historic_datas={props.historic_data_chart ? props.historic_data_chart : ''}
                                pageType='model' />
                        : ''}
                        </div>
                        {props.biomarkerComparisonList.length > 0 ?
                            <div className='compare-biomarkers-color-description col-lg-4 col-md-3 col-sm-12' id = 'color-description-biomarkers'>
                                {props.biomarkerComparisonList.map(s =>(
                                    <div className={`${s} color-description row no-gutters`} id = {`${s}-color-description`}>
                                        <label class="checkbox-main">
                                                <input type="checkbox" 
                                                id={`${s}-checkbox`}
                                                onChange={ () => { SelectBioMarker(s) }}/>
                                                <span class="biomarker-checkbox-container" ></span>
                                        </label>
                                        <div className='biomarker-name unselected col-4 no-gutters' id={`${s}-biomarker-name`} onClick={ () => { SelectBioMarker(s) }}>{_.head(props.historic_data_chart[constants.biomarkers_of_aging[s]]).label}</div>
                                        <div className={'line-color-container unselected col-2 no-gutters'} id={`${s}-line-color`}>
                                            <div className='line-color'  onClick={ () => { SelectBioMarker(s) }} style={{ background: `${props.biomarkerComparisonList.length > 1 ? constants.biomarkers_of_aging_lineColors[s] : 'black'}`}}></div>
                                        </div>
                                    </div> 
                                ))}
                                <div className={`age-line color-description row no-gutters`}>
                                    <label class="checkbox-main">
                                        <input 
                                            type="checkbox" 
                                            disabled={true}
                                            checked={true}
                                        />
                                        <span class="biomarker-checkbox-container" ></span>
                                    </label>
                                    <div className='biomarker-name col-6  col-lg-4 no-gutters'>AgeLine</div>
                                    <div className='line-color-container col-2 no-gutters'>
                                        <div className='line-color' style={{ background: `${props.biomarkerComparisonList.length > 1 ? 'black' : '#3397C9'}`}}></div>
                                    </div>
                                </div>
                                <div className='reset-biomarkers row no-gutters'>
                                    <div className='col-8 reset-biomarkers-container'>
                                        {props.biomarkerComparisonList.length > 1 ? <button className='enable-all-lines' onClick={ () => { EnableBiomarkerLines(props); }}>{labels.analytics_labels.select_all}</button> : ''}
                                        {props.biomarkerComparisonList.length > 1 ? <button className='disable-all-lines' onClick={ () => { DisableBiomarkerLines(props); }}>{labels.analytics_labels.deselect_all}</button> : ''}
                                    </div>    
                                </div>
                            </div> :
                            <div className='compare-biomarkers-color-description col-lg-4 col-md-3 col-sm-12' id = 'color-description-biomarkers'>
                                {Object.keys(props.historic_data_chart).map(s =>(
                                    s != 'biomarkerCount' ?
                                    <div className={`${_.head(props.historic_data_chart[s]).code} color-description row no-gutters`} id ={`${_.head(props.historic_data_chart[s]).code}-color-description`}>
                                        <label class="checkbox-main">
                                                <input type="checkbox" 
                                                id={`${_.head(props.historic_data_chart[s]).code}-checkbox`}
                                                onChange={ () => { SelectBioMarker(_.head(props.historic_data_chart[s]).code) }}/>
                                                <span class="biomarker-checkbox-container" ></span>
                                        </label>
                                        <div className='biomarker-name col-6  col-lg-4 no-gutters' id={`${_.head(props.historic_data_chart[s]).code}-biomarker-name`} onClick={ () => { SelectBioMarker(_.head(props.historic_data_chart[s]).code); }}>{_.head(props.historic_data_chart[s]).label}</div>
                                        <div className='line-color-container col-2 no-gutters' id={`${_.head(props.historic_data_chart[s]).code}-line-color`} >
                                            <div className='line-color' style={{ background: `${constants.biomarkers_of_aging_lineColors[_.head(props.historic_data_chart[s]).code]}` }} onClick={ () => { SelectBioMarker(_.head(props.historic_data_chart[s]).code) }}></div>
                                        </div>
                                    </div> : ''
                                ))}
                                <div className={`age-line color-description row no-gutters`}>
                                    <div>
                                        <label class="checkbox-main">
                                            <input 
                                                type="checkbox" 
                                                disabled={true}
                                                checked={true}
                                            />
                                            <span class="biomarker-checkbox-container" ></span>
                                        </label>
                                    </div>
                                    <div className='biomarker-name col-6 col-lg-4 no-gutters'> {labels.analytics_labels.age_line}</div>
                                    <div className='line-color-container col-2 no-gutters'>
                                        <div className='line-color' style={{ background: 'black'}}></div>
                                    </div>
                                </div>
                                <div className='reset-biomarkers row no-gutters'>
                                    <div className='col-8 col-lg-6 justify-content-end d-flex reset-biomarkers-container'>
                                        <button className='enable-all-lines' onClick={ () => {  EnableBiomarkerLines(props); }}>{labels.analytics_labels.select_all}</button>
                                        <button className='disable-all-lines' onClick={ () => { DisableBiomarkerLines(props); }}>{labels.analytics_labels.deselect_all}</button> 
                                    </div>    
                                </div>
                          </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
export default connect()(BiomarkerComparisonModel);
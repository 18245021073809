import React from 'react';
import PropTypes from 'prop-types';
import Recommendation, { recommendation_list } from '../tools/recommendation';
import constants from '../../../config/constants';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import en_labels from '../../../config/en_labels';
import sprite from '../../../assets/images/sprite_base.svg';
import CreatableSelect from "react-select/lib/Creatable";
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';
import moment from 'moment';

export default class RecommendationInputComponent extends React.Component {
  static propTypes = {
    datum: PropTypes.object.isRequired,
    recommendation: PropTypes.object.isRequired,
    provideRecommendation: PropTypes.func.isRequired,
    updateRecommendation: PropTypes.func.isRequired,
    dataPersists: PropTypes.bool.isRequired,
  };
 
  constructor(props, context) {
    super(props, context)
    this.state = {
      recommendation: new Recommendation(this.props.recommendation)
    }
    this.creatableSelect = React.createRef()
    this.notes = React.createRef()
    this.instructions = React.createRef()
    this.strength = React.createRef()
    this.brand = React.createRef()
    this.recommendationStrengthType = React.createRef()
  }

  componentDidMount() {
    this.brandInput = true
    this.recommendationInput = true
    this.instructionInput = true
    this.notesInput = true
    this.strengthInput = true
    if (this.props && this.props.history) {
      this.unlisten = this.props.history.listen((location, action) => {
        if (location && this.props.location && action == 'POP') {
          let group = this.props.recommendationForm && this.props.recommendationForm.group ? this.props.recommendationForm.group : 'General Instructions';
          let brand = this.props.recommendationForm && this.props.recommendationForm.brand ? this.props.recommendationForm.brand : "";
          let body = this.props.recommendationForm && this.props.recommendationForm.body ? this.props.recommendationForm.body : "";
          let strength = this.props.recommendationForm && this.props.recommendationForm.strength ? this.props.recommendationForm.strength : "";
          let recommendationStrengthType = this.props.recommendationForm && this.props.recommendationForm.recommendationStrengthType ? this.props.recommendationForm.recommendationStrengthType : "";
          let instructions = this.props.recommendationForm && this.props.recommendationForm.instructions ? this.props.recommendationForm.instructions : "";
          let notes = this.props.recommendationForm && this.props.recommendationForm.notes ? this.props.recommendationForm.notes : "";
          let startedAt = this.props.recommendationForm && this.props.recommendationForm.startedAt ? this.props.recommendationForm.startedAt : "";
          let endedAt = this.props.recommendationForm && this.props.recommendationForm.endedAt ? this.props.recommendationForm.endedAt : "";

          this.props.showNoFormChangesError(false);
          let recommendationForm = { group: group, brand: brand, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt }
          let initialRecommendationForm = this.props.initialRecommendationForm ? { ...this.props.initialRecommendationForm } : { group: 'General Instructions' };
          Object.keys(recommendationForm).forEach((p) => recommendationForm[p] == null || recommendationForm[p] == undefined || recommendationForm[p] == '' ? delete recommendationForm[p] : '');
          Object.keys(initialRecommendationForm).forEach((p) => initialRecommendationForm[p] == null || initialRecommendationForm[p] == undefined || initialRecommendationForm[p] == '' ? delete initialRecommendationForm[p] : '');

          if (recommendationForm == "" || _.isEqual(recommendationForm, initialRecommendationForm)) {
            this.props.togglePlanModal(false);
            this.props.toggleMetaRecommendations(false);
          }
          else
            this.props.toggleRecommendFormWarning(true);
        }
      })
    }
  }

  componentWillUnmount() {
    this.props && !this.props.isDemo ? this.unlisten() : ''
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      recommendation: new Recommendation(nextProps.recommendation)
    })
  }

  componentDidUpdate() {
    if (!this.props.isSideBarActive && !this.props.showPlanModal)
      this.clearForm()
    if (this.props.recommendationForm && _.isEqual({ group: "General Instructions" }, this.props.recommendationForm) && !(this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.body)) {
      this._typeahead.getInstance().clear()
    }

    if (this.props.recommendationForm && !this.props.recommendationForm.body) {
      this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.body
        ? '' : this._typeahead.getInstance().clear()
    }

    if (this.props.recommendationForm && _.isEqual({ group: "General Instructions" }, this.props.recommendationForm) && !(this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.instructions)) {
      this.instructions.getInstance().clear()
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.recommendationForm && !this.props.recommendationForm.body) {
        this.instructions.getInstance().clear()
      }
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.recommendationForm && !this.props.recommendationForm.instructions) {
        this.instructions.getInstance().clear()
      }
    }

    if (this.props.recommendationForm && _.isEqual({ group: "Pharmaceutical" }, this.props.recommendationForm) && !(this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.strength)) {
      this.strength.getInstance().clear()
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.recommendationForm && !this.props.recommendationForm.body) {
        this.strength.getInstance().clear()
      }
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.recommendationForm && !this.props.recommendationForm.strength) {
        this.strength.getInstance().clear()
      }
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.recommendationForm && !this.props.recommendationForm.brand) {
        this.brand.getInstance().clear()
      }
    }


    // if (this.props.recommendationForm && _.isEqual({ group: "Pharmaceutical" }, this.props.recommendationForm) && !(this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.recommendationStrengthType)) {
    //   this.recommendationStrengthType.value = ""
    // }

    // if (!this.props.isMetaRecommendation)  {
    //     if (this.props.recommendationForm && !this.props.recommendationForm.strength) {
    //       this.recommendationStrengthType.value = ""
    //     }      
    // }   

    // if (!this.props.isMetaRecommendation)  {
    //   if (this.props.recommendationForm && !this.props.recommendationForm.recommendationStrengthType) {
    //     this.recommendationStrengthType.value = ""
    //   }      
    // }



    if (this.props.recommendationForm && _.isEqual({ group: "General Instructions" }, this.props.recommendationForm) && !(this.props.isMetaRecommendation && this.props.metaRecommendationForm && this.props.metaRecommendationForm.notes)) {
      this.notes.getInstance().clear()
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.recommendationForm && !this.props.recommendationForm.body) {
        this.notes.getInstance().clear()
      }
    }

    if (!this.props.isMetaRecommendation) {
      if (this.props.recommendationForm && !this.props.recommendationForm.notes) {
        this.notes.getInstance().clear()
      }
    }

  }

  clearForm = () => {

  }

  checkForChanges = () => {
    let initialRecommendationForm = this.props.initialRecommendationForm ? { ...this.props.initialRecommendationForm } : {};
    let recommendationForm = {}
    if (this.props.recommendationForm) {
      recommendationForm["group"] = this.props.recommendationForm["group"];
      recommendationForm["brand"] = this.props.recommendationForm["brand"];
      recommendationForm["body"] = this.props.recommendationForm["body"];
      recommendationForm["strength"] = this.props.recommendationForm["strength"];
      // recommendationForm["recommendationStrengthType"] = this.props.recommendationForm["recommendationStrengthType"];
      recommendationForm["instructions"] = this.props.recommendationForm["instructions"];
      recommendationForm["notes"] = this.props.recommendationForm["notes"];
      recommendationForm["startedAt"] = this.props.recommendationForm["startedAt"];
      recommendationForm["endedAt"] = this.props.recommendationForm["endedAt"];
      recommendationForm["productType"] = this.props.recommendationForm["productType"];
    }

    Object.keys(recommendationForm).forEach((p) => recommendationForm[p] == null || recommendationForm[p] == undefined || recommendationForm[p] == "" ? delete recommendationForm[p] : '');
    Object.keys(initialRecommendationForm).forEach((p) => initialRecommendationForm[p] == null || initialRecommendationForm[p] == undefined || initialRecommendationForm[p] == "" ? delete initialRecommendationForm[p] : '');

    let groupName = this.props.sectionValues && this.props.sectionValues.groupName ?
      this.props.sectionValues.groupName
      : 'General Instructions'
    if (_.isEqual(recommendationForm, initialRecommendationForm) && !_.isEqual(initialRecommendationForm, { group: groupName })) {
      this.props.showNoFormChangesError(true);
      return true;
    }
    else
      return false;

  }

  saveRecommendation = () => {
    let noFormChanges = this.checkForChanges();
    if (!noFormChanges) {
      // this.props.saveRecommendationNotes(this.notes.current && this.notes.current.inputEntry.value || '')
      this.props.saveRecommendation(this.props.history, this.props.isPlanModal)
      this._typeahead.clear()
      this.brand.clear()
      this.strength.clear()
      this.instructions.clear()
      this.notes.clear()
      this.clearForm()
    }
  }

  saveMetaRecommendation = () => {
    this.props.deleteMetaRecommendation(this.props.metaRecordIndex);
    // this.props.saveMetaRecommendationNotes(this.notes.current && this.notes.current.inputEntry.value || '')
    this.props.saveRecommendation(this.props.history, this.props.isPlanModal)
    this._typeahead.clear()
    this.instructions.clear()
    this.notes.clear()
    this.brand.clear()
    this.clearForm()
  }
  getStrengthList = (list) => {
    let arr = []
    list && list.length && list.map(s => {
      if (s.value && arr.map(t => t.value.replace(/\s/g, '')).indexOf(s.value.replace(/\s/g, '')) == -1) {
        return arr.push(s);
      }
    })
    return arr;
  }
  render() {
    return (
      <div className='search'>
        {this.props.recommendationErrors && this.props.recommendationErrors.length ?
          <div className='alert alert-danger'>
            {this.props.recommendationErrors.map(re => <p>{re}</p>)}
          </div> : null
        }
        {
          this.props.loadingMedications != 'finished' ?
            <div className='alert alert-success'>
              <i class="fa fa-spinner fa-spin medication-loading-spinner"></i>
              <span>We are getting your recommendations ready. Please wait for a while!</span>
            </div>
            : null
        }
        <FormControl className='form-control' id='group'
          value={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.group ? this.props.metaRecommendationForm.group : null) : (this.props.recommendationForm && this.props.recommendationForm.group ? this.props.recommendationForm.group : null)}
          onChange={this.props.editRecommendation}
          label='Group:' type='select'
          options={this.props.groups_recommendations}
          textKey={'value'} valueKey={'value'} disabled={this.props.isMetaRecommendation ? true : false} />

        <div className='form-group'>
          <label>Select {labels.analytics_labels.brand_ingredient}</label>
          <Typeahead
            labelKey="value"
            allowNew
            onKeyDown={(e) => { e.length ? this.props.editRecommendation(_.head(e), "brand") : null; this.brandInput = e.target.value ? false : true }}
            selected={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.brand ? [{ value: this.props.metaRecommendationForm.brand }] : [{ value: '' }]) : this.props.recommendationForm && this.props.recommendationForm.brand && this.brandInput ? [{ value: this.props.recommendationForm.brand }] : null}
            id='brand'
            disabled={this.props.isMetaRecommendation ? true : false}
            options={this.props.brand_list ? this.props.brand_list : []}
            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editRecommendation(_.head(e), "brand")}
            newSelectionPrefix={null}
            ref={(ref) => this.brand = ref}
            onBlur={(e) => {
              this.brandInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editRecommendation({ value: e.target.value }, "brand")
              }
            }
            }
          />
        </div>

        <div className='form-group'>
          <label>Enter your Recommendation</label>
          <Typeahead
            labelKey="value"
            allowNew
            onKeyDown={(e) => { e.length ? this.props.editRecommendation(_.head(e), "body") : null; this.recommendationInput = e.target.value ? false : true }}
            selected={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.body ? [{ value: this.props.metaRecommendationForm.body }] : [{ value: '' }]) : this.props.recommendationForm && this.props.recommendationForm.body && this.recommendationInput ? [{ value: this.props.recommendationForm.body }] : null}
            id='customTypeahead'
            disabled={this.props.isMetaRecommendation ? true : false}
            options={this.props.recommendation_list ?  this.props.recommendation_list.filter(
              (item, index, self) =>index === self.findIndex((t) => t.value === item.value)
            ) : []}
            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editRecommendation(_.head(e), "body")}
            newSelectionPrefix={null}
            ref={(ref) => this._typeahead = ref}
            onBlur={(e) => {
              this.recommendationInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editRecommendation({ value: e.target.value }, "body")
              }
            }
            }
          />
        </div>

        <div className='form-group'>
          <label>Dose</label>
          <Typeahead
            labelKey="value"
            allowNew
            selected={this.props.isMetaRecommendation ? '' : this.props.recommendationForm && this.props.recommendationForm.strength && this.strengthInput ? [{ value: this.props.recommendationForm.strength }] : null}
            onKeyDown={(e) => { e.length ? this.props.editRecommendation(_.head(e), "strength") : null; this.strengthInput = e.target.value ? false : true }}
            id='strengthTypeahead'
            options={this.props.strength_list ? this.getStrengthList(this.props.strength_list) : []}
            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editRecommendation(_.head(e), "strength")}
            newSelectionPrefix={null}
            ref={(ref) => this.strength = ref}
            onBlur={(e) => {
              this.strengthInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editRecommendation({ value: e.target.value }, "strength")
              }
            }
            }
          />
        </div>
        {/* <FormControl
            id='recommendationStrengthType' label={labels.analytics_labels.howStronglyRecommended} name='recommendationStrengthType'
            value={ this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.recommendationStrengthType) ? this.props.metaRecommendationForm.recommendationStrengthType : "0" : this.props.recommendationForm && this.props.recommendationForm.recommendationStrengthType ? this.props.recommendationForm.recommendationStrengthType : "0" }
            onChange={(e) => this.props.editRecommendation(e, "recommendationStrengthType")  }
            type='select' options={this.props.strengthTypes}
            textKey={'name'} valueKey={'name'}  ref={(ref) => this.recommendationStrengthType = ref}
            nodefaultOption={true}
        /> */}

        <div className='form-group'>
          <label>Enter instructions or sig. (optional)</label>
          <Typeahead
            labelKey="value"
            allowNew
            selected={this.props.isMetaRecommendation ? '' : this.props.recommendationForm && this.props.recommendationForm.instructions && this.instructionInput ? [{ value: this.props.recommendationForm.instructions }] : null}
            onKeyDown={(e) => { e.length ? this.props.editRecommendation(_.head(e), "instruction") : null; this.instructionInput = e.target.value ? false : true }}
            id='instructionTypeahead'
            options={ this.props.instruction_list ?  this.props.instruction_list.filter(a=> a.value != "" && a.value != null).filter(
              (item, index, self) =>index === self.findIndex((t) => t.value === item.value)
            ) : []}            
            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editRecommendation(_.head(e), "instruction")}
            newSelectionPrefix={null}
            ref={(ref) => this.instructions = ref}
            onBlur={(e) => {
              this.instructionInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editRecommendation({ value: e.target.value }, "instruction")
              }
            }
            }
          />
        </div>

        <div className='form-group'>
          <label>Enter patient notes (optional)</label>
          <Typeahead
            labelKey="value"
            allowNew
            selected={this.props.isMetaRecommendation ? '' : this.props.recommendationForm && this.props.recommendationForm.notes && this.notesInput ? [{ value: this.props.recommendationForm.notes }] : null}
            onKeyDown={(e) => { e.length ? this.props.editRecommendation(_.head(e), "notes") : null; this.notesInput = e.target.value ? false : true }}
            id='notesTypeahead'
            options={this.props.notes_list ? this.props.notes_list : []}
            renderMenuItemChildren={(option, props) => (
              <span id={option.value} key={option.value}>{option.value}</span>
            )}
            onChange={(e) => this.props.editRecommendation(_.head(e), "notes")}
            newSelectionPrefix={null}
            ref={(ref) => this.notes = ref}
            onBlur={(e) => {
              this.notesInput = true;
              if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                this.props.editRecommendation({ value: e.target.value }, "notes")
              }
            }
            }
          />
        </div>

        <div className='form-group row'>
          <div className='col-lg-6'>
            <FormControl id='update_visit'
              label={labels.billing_labels.startdate_label}
              type='date'
              selected={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.startedAt) ?
                new Date(this.props.metaRecommendationForm.startedAt) : '' :
                this.props.recommendationForm && this.props.recommendationForm.startedAt ?
                  new Date(this.props.recommendationForm.startedAt) : ''}
              onChange={(e) => this.props.editRecommendation(e, "startedAt")}
              maxDate={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.endedAt) ?
                moment(this.props.metaRecommendationForm.endedAt).add(-1, 'days').toDate() : "" :
                this.props.recommendationForm && this.props.recommendationForm.endedAt ?
                  moment(this.props.recommendationForm.endedAt).add(-1, 'days').toDate() : ""}
              disabledKeyboardNavigation
            />
          </div>
          <div className='col-lg-6'>
            <FormControl id='update_visit'
              label={labels.billing_labels.enddate_label}
              type='date'
              selected={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.endedAt) ?
                new Date(this.props.metaRecommendationForm.endedAt) : '' :
                this.props.recommendationForm && this.props.recommendationForm.endedAt ?
                  new Date(this.props.recommendationForm.endedAt) : ''}
              onChange={(e) => this.props.editRecommendation(e, "endedAt")}
              minDate={this.props.isMetaRecommendation ? (this.props.metaRecommendationForm && this.props.metaRecommendationForm.startedAt) ?
                moment(this.props.metaRecommendationForm.startedAt).add(1, 'days').toDate() : "" :
                this.props.recommendationForm && this.props.recommendationForm.startedAt ?
                  moment(this.props.recommendationForm.startedAt).add(1, 'days').toDate() : ""}
              disabledKeyboardNavigation
            />
          </div>
          <div className='col-lg-3'>
          </div>
          <div className='col-lg-9'>
            <label>
              {labels.allowed_date_format.replace('<>', 'MM/DD/YYYY')}
            </label>
          </div>
        </div>

        {this.renderWarning(this.props.isDemo)}
        <button disabled={this.props.isRecommendationsLoading ? true : false} className={`btn ${this.props.showPlanModal ? 'btn-success' : 'btn-default'}`} onClick={this.props.isMetaRecommendation ? this.saveMetaRecommendation : this.saveRecommendation}>Recommend</button>
      </div>
    );
  }

  renderWarning(shouldRender) {
    if (shouldRender) {
      return (
        <div className='alert alert-warning d-flex'>
          <div className='icon lg alert-icon'>
            <svg aria-hidden='true' title='Persistence Warning'>
              {/* <use xlinkHref={`${sprite}#x`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
              <use xlinkHref="#x" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
              <symbol id="x" viewBox="0 0 512 512">
                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
              </symbol>
            </svg>
          </div>
          <div className='alert-content'>
            <strong>{labels.analytics_labels.changes_wont_stick}</strong>
            <p>{labels.analytics_labels.demo_mode}</p>
          </div>
        </div>
      )
    }
    else {
      return (null);
    }
  }


}

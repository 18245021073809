export default[
   {
       "key": "Table of Contents",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:43",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Table of Contents"
       }
   },
   {
       "key": "Abnormal Results",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:43",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Abnormal Results"
       }
   },
   {
       "key": "Name",
       "context": "The name of a test, like \"bmi\" or \"white blood cells\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2020-07-01T13:34:01",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Name"
       }
   },
   {
       "key": "Result",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:43",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Result"
       }
   },
   {
       "key": "Units",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:43",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Units"
       }
   },
   {
       "key": "Normal Range",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:44",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Normal Range"
       }
   },
   {
       "key": "Optimal Range",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:44",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Optimal Range"
       }
   },
   {
       "key": "Baseline",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:44",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Baseline"
       }
   },
   {
       "key": "Change",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:44",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Change"
       }
   },
   {
       "key": "test not performed",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:44",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "test not performed"
       }
   },
   {
       "key": "known as the English Hypocrates",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:45",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "known as the English Hypocrates"
       }
   },
   {
       "key": "Comparative Ages",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:45",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Comparative Ages"
       }
   },
   {
       "key": "Physiological Ages for Your Current Visit",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:45",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Physiological Ages for Your Current Visit"
       }
   },
   {
       "key": "Complete Data",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:45",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Complete Data"
       }
   },
   {
       "key": "range depends on a variety of factors",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:46",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "range depends on a variety of factors"
       }
   },
   {
       "key": "Exceptional Results",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:46",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Exceptional Results"
       }
   },
   {
       "key": "Historical Data",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:46",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Historical Data"
       }
   },
   {
       "key": "Biomarkers of Aging",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:46",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Biomarkers of Aging"
       }
   },
   {
       "key": "Comparing Systems",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:47",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Comparing Systems"
       }
   },
   {
       "key": "Introduction",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:47",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Introduction"
       }
   },
   {
       "key": "Health Status Indicators",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:47",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Health Status Indicators"
       }
   },
   {
       "key": "Visit Date",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:47",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Visit Date"
       }
   },
   {
       "key": "Chronological Age",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:47",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Chronological Age"
       }
   },
   {
       "key": "Physiological Age",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:47",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Physiological Age"
       }
   },
   {
       "key": "outliers are more than",
       "context": "instructive chart",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:48",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "outliers are more than"
       }
   },
   {
       "key": "6 years older than",
       "context": "Physiologically, patient is 6 years older than average for their age",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:48",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "6 years older than"
       }
   },
   {
       "key": "7 years younger than",
       "context": "Physiologically, patient is 7 years younger than average for their age",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:48",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "7 years younger than"
       }
   },
   {
       "key": "chronological age matches physiological age",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:48",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "chronological age matches physiological age"
       }
   },
   {
       "key": "Physician's Report",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:49",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Physician's Report"
       }
   },
   {
       "key": "Timepoint",
       "context": "in medicine, a synonymy for \"visit date\" or \"episode\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:49",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Timepoint"
       }
   },
   {
       "key": "Patient Name",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:49",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Patient Name"
       }
   },
   {
       "key": "Composite",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:49",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Composite"
       }
   },
   {
       "key": "prepared for",
       "context": "Report prepared for John Q. Public",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:49",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "prepared for"
       }
   },
   {
       "key": "by provider",
       "context": "label for provider's name on cover page",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:50",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "by provider"
       }
   },
   {
       "key": "based on tests performed",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:50",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "based on tests performed"
       }
   },
   {
       "key": "Powered By",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:50",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Powered By"
       }
   },
   {
       "key": "Percentile Range",
       "context": "table heading -- \"if you score between 75% and 85%\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:50",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Percentile Range"
       }
   },
   {
       "key": "Standard Score",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:50",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Standard Score"
       }
   },
   {
       "key": "Interpretation",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:50",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Interpretation"
       }
   },
   {
       "key": "Clinical Severity",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:51",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Clinical Severity"
       }
   },
   {
       "key": "MCI ",
       "context": "( second table under Cognitive Function )",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:51",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "MCI "
       }
   },
   {
       "key": "Dementia",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:51",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Dementia"
       }
   },
   {
       "key": "Brain Injury",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:51",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Brain Injury"
       }
   },
   {
       "key": "Depression",
       "context": null,
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:51",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Depression"
       }
   },
   {
       "key": "Medication Effect",
       "context": "? (one medication or more medicationS... it changes the word)",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:52",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Medication Effect"
       }
   },
   {
       "key": "A man is as old as his arteries",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:52",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "A man is as old as his arteries"
       }
   },
   {
       "key": "Biomarker",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:52",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Biomarker"
       }
   },
   {
       "key": "Spectrum",
       "context": "the range of possible values",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:52",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Spectrum"
       }
   },
   {
       "key": "Biomarkers",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:53",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Biomarkers"
       }
   },
   {
       "key": "Follow-Up",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-26T16:12:09",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Follow-Up"
       }
   },
   {
       "key": "prev",
       "context": "short for 'previous page'",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:53",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "prev"
       }
   },
   {
       "key": "next",
       "context": "short for 'next page'",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:53",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "next"
       }
   },
   {
       "key": "Your",
       "context": "Your CardioAge is 20... ",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:53",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Your"
       }
   },
   {
       "key": "is",
       "context": "Your CardioAge is 20... ",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:53",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "is"
       }
   },
   {
       "key": "older",
       "context": "Your CardioAge is 20, which is 5 years older than average for your age",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:53",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "older"
       }
   },
   {
       "key": "younger",
       "context": "?   Your CardioAge is 20, which is 5 years younger than average for your age",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:54",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "younger"
       }
   },
   {
       "key": "neither older nor younger",
       "context": "Your CardioAge is 20, which is neither older nor younger than average for your age",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:54",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "neither older nor younger"
       }
   },
   {
       "key": "than average for your age",
       "context": "Your CardioAge is 20, which is neither older nor younger than average for your age",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:54",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "than average for your age"
       }
   },
   {
       "key": "year",
       "context": "Your CardioAge is 44, which is 1 years younger than average for your age",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:54",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "year"
       }
   },
   {
       "key": "Congratulations on completing your PhysioAge™ Biomarkers.",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:54",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Congratulations on completing your PhysioAge™ Biomarkers."
       }
   },
   {
       "key": "month",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:55",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "month"
       }
   },
   {
       "key": "of",
       "context": "page 1 of 2",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:55",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "of"
       }
   },
   {
       "key": "15 years beyond their",
       "context": "Patient is 15 years beyond their chronological age",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:55",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "15 years beyond their"
       }
   },
   {
       "key": "Treatment Guidelines",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:55",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Treatment Guidelines"
       }
   },
   {
       "key": "chronological age",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:55",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "chronological age"
       }
   },
   {
       "key": "Recommendations",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:56",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Recommendations"
       }
   },
   {
       "key": "flags",
       "context": "as in \"this value is flagged as very low\" \"este valor está marcado como muy bajo\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:56",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "flags"
       }
   },
   {
       "key": "Grade",
       "context": "A, B, C, D, F",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:56",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Grade"
       }
   },
   {
       "key": "Report Card",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:56",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Report Card"
       }
   },
   {
       "key": "Welcome",
       "context": "\"welcome to your medical dashboard\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:56",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Welcome"
       }
   },
   {
       "key": "Visualizing Your Wellness",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:56",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Visualizing Your Wellness"
       }
   },
   {
       "key": "PhysioAge Analytics",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:57",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "PhysioAge Analytics"
       }
   },
   {
       "key": "Patient History",
       "context": "",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:57",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Patient History"
       }
   },
   {
       "key": "Health Report Card",
       "context": "chapter title",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:57",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Health Report Card"
       }
   },
   {
       "key": "intro_chart_top_line",
       "context": "For baseline reports, a chart label says \"chronological age matches physiological.\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:57",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Chronological age"
       }
   },
   {
       "key": "intro_chart_bottom_line",
       "context": "For baseline reports, a chart says \"physiological age matches chronological\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:57",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "matches physiological"
       }
   },
   {
       "key": "Comprehensive Health Analysis",
       "context": "title page of PDF report",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-12-12T17:01:25",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Comprehensive Health Analysis"
       }
   },
   {
       "key": "Your Result Summary",
       "context": "Subtitle in the \"Treatment Plan\" document.",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-26T15:18:45",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Your Result Summary"
       }
   },
   {
       "key": "Visit your patient portal to see individual results",
       "context": "instructions within the \"Treatment Plan\" document",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:58",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Visit your patient portal to see individual results"
       }
   },
   {
       "key": "Treatment Plan",
       "context": "title of the \"Treatment Plan\" document",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:58",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Treatment Plan"
       }
   },
   {
       "key": "Provider",
       "context": "\"Provider: Dr. Joseph X. Raffaele\" -- Short version of \"Your provider's name is\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:59",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Provider"
       }
   },
   {
       "key": "Patient",
       "context": "\"Patient: Sarah Jessica Parker\" -- Short version of \"Patient's name is\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:59",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Patient"
       }
   },
   {
       "key": "Visit",
       "context": "short version of \"Your visit date was\"",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:59",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Visit"
       }
   },
   {
       "key": "Your Recommendations",
       "context": "Subtitle in the \"Treatment Plan\" document.",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:59",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Your Recommendations"
       }
   },
   {
       "key": "from_recommending_provider",
       "context": "Your Recommendations >>from Dr. Charles Xavier<<",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:30:59",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "from %{provider_name}"
       }
   },
   {
       "key": "Patient Portal Instructions",
       "context": "Subtitle in the \"Treatment Plan\" document.",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-26T16:07:32",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Patient Portal Instructions"
       }
   },
   {
       "key": "patient_portal_instructions",
       "context": "instructions within the \"Treatment Plan\" document",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2020-06-02T19:22:58",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Access your patient portal, powered by PhysioAge Health Analytics, from your device or desktop at"
       }
   },
   {
       "key": "provider_contact_instructions",
       "context": "instructions within the \"Treatment Plan\" document",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-12T19:01:23",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "You may contact %{site_name} by calling us at %{site_phone_number}."
       }
   },
   {
       "key": "visit_summary_subtitle",
       "context": "second part of Treatment Plan heading: \"for Gen. George Washington's January, 1776 visit",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-06T00:37:00",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "for %{patient_name}'s %{month_and_year} visit"
       }
   },
   {
       "key": "General Instructions",
       "context": "one category of recommendations",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:31:00",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "General Instructions"
       }
   },
   {
       "key": "Further Testing",
       "context": "one category of recommendations",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-26T16:13:31",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Further Testing"
       }
   },
   {
       "key": "Lifestyle",
       "context": "one category of recommendations",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:31:01",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Lifestyle"
       }
   },
   {
       "key": "Diet",
       "context": "one category of recommendations",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:31:01",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Diet"
       }
   },
   {
       "key": "Exercise",
       "context": "one category of recommendations",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:31:01",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Exercise"
       }
   },
   {
       "key": "Supplements",
       "context": "one category of recommendations",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:31:01",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Supplements"
       }
   },
   {
       "key": "Hormone Optimization",
       "context": "one category of recommendations",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:31:02",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Hormone Optimization"
       }
   },
   {
       "key": "Pharmaceuticals",
       "context": "one category of recommendations",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:31:02",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Pharmaceuticals"
       }
   },
   {
       "key": "recommendation_basis",
       "context": "Exercise three times a day (>>> based on your visceral fat level <<<)",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:31:02",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Based on your %{label}"
       }
   },
   {
       "key": "pagination",
       "context": "page numbering for PDF documents",
       "createdAt": "2019-07-03T00:00:00",
       "updatedAt": "2019-08-02T16:31:02",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "page %{current_page} of %{total_pages}"
       }
   },
   {
       "key": "Visit Summary",
       "context": "document title",
       "createdAt": "2019-08-01T21:05:03",
       "updatedAt": "2019-08-02T16:31:02",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Visit Summary"
       }
   },
   {
       "key": "optimal",
       "context": "This is the best possible value, used for ranges with no custom description like 'Athletic'",
       "createdAt": "2019-08-12T22:09:09",
       "updatedAt": "2019-09-27T15:52:31",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Optimal"
       }
   },
   {
       "key": "healthy",
       "context": "This is the second-best possible value, used for ranges with no custom description like 'Normal'",
       "createdAt": "2019-08-12T22:10:03",
       "updatedAt": "2019-09-27T16:39:54",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Healthy"
       }
   },
   {
       "key": "borderline",
       "context": "Used for ranges between Healthy and Disease, this text is used for ranges with no custom description like 'Too Low' or \"Deficient\"",
       "createdAt": "2019-08-12T22:13:27",
       "updatedAt": "2019-09-27T16:39:54",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Borderline"
       }
   },
   {
       "key": "disease",
       "context": "The second-worst range description, this text is used for ranges with no custom description like 'Inflammation' or \"Immune Risk\"",
       "createdAt": "2019-08-12T22:14:36",
       "updatedAt": "2019-09-27T16:39:55",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Disease"
       }
   },
   {
       "key": "critical",
       "context": "The worst range description, this text is used for ranges with no custom description like 'Very Weak' or 'Severely Elevated'",
       "createdAt": "2019-08-12T22:15:30",
       "updatedAt": "2019-09-27T16:39:55",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Critical"
       }
   },
   {
       "key": "biomarker_heading",
       "context": "heading for biomarker results",
       "createdAt": "2019-08-26T15:36:53",
       "updatedAt": "2019-08-26T15:42:11",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Your Biomarkers of Aging"
       }
   },
   {
       "key": "biomarker_age",
       "context": "PhysioAge: >>> 20 Years <<<",
       "createdAt": "2019-08-26T15:45:18",
       "updatedAt": "2019-08-26T15:47:14",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "%{age} Years"
       }
   },
   {
       "key": "report_card_heading",
       "context": "",
       "createdAt": "2019-08-26T15:58:14",
       "updatedAt": "2019-08-26T15:59:14",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Your Report Card"
       }
   },
   {
       "key": "You",
       "context": "short label for spectrums: \"You 72 bpm\" ie \"your value is...\"",
       "createdAt": "2019-09-02T19:12:28",
       "updatedAt": "2019-09-27T16:39:55",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "You"
       }
   },
   {
       "key": "You have not entered any recommendations.",
       "context": "heading for a box in Analytics",
       "createdAt": "2020-02-13T20:34:07",
       "updatedAt": "2020-02-13T20:34:07",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "You have not entered any recommendations."
       }
   },
   {
       "key": "most recent visit",
       "context": "field label in header for Analytics",
       "createdAt": "2020-02-13T20:35:59",
       "updatedAt": "2020-02-13T20:35:59",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "most recent visit"
       }
   },
   {
       "key": "results_of_concern",
       "context": "",
       "createdAt": "2021-11-10T21:47:26",
       "updatedAt": "2021-11-11T15:22:11",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Concerning Results"
       }
   },
   {
       "key": "download_report",
       "context": "",
       "createdAt": "2021-11-10T21:47:37",
       "updatedAt": "2021-11-10T21:48:58",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Download Report"
       }
   },
   {
       "key": "biographical_information",
       "context": "",
       "createdAt": "2021-11-10T21:47:48",
       "updatedAt": "2021-11-10T21:48:03",
       "termTranslationsDTOList": {
           "termId": 0,
           "locale": "en",
           "createdAt": "0001-01-01T00:00:00",
           "updatedAt": "0001-01-01T00:00:00",
           "text": "Biographical Information"
       }
   }
]
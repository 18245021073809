import React from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
import BiomarkerSparkline from './BiomarkerSparkline';
import SpriteIcon from './SpriteIcon';
import constants from '../../../config/constants'
import { spriteForBiomarker } from '../tools/biomarkers';
import _ from 'lodash';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import labels from '../../../config/localization';
import { roundNumber, uniformString } from '../tools/helpers';
import tru from '../../../../src/tru.svg'
import tru_visitsummary from '../../../../src/tru_visitsummary.svg'

export default class BiomarkersOfAgingComponent extends React.Component {
  static propTypes = {
    patient_age: PropTypes.number.isRequired,
    timeBoundaries: PropTypes.object.isRequired,
    drillDownOnBiomarker: PropTypes.func.isRequired,
    biomarkers: PropTypes.array.isRequired,
    shouldRender: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);
    this.renderBiomarker = this.renderBiomarker.bind(this);
    this.renderHistory = this.renderHistory.bind(this);
    this.state = { defaultOpened: false }
  }

  componentWillReceiveProps() {
    // console.log(document.getElementById(`biomarker-${this.props.hashDrawer}`))
    // console.log('entered boac')
    let ad = constants.biographical_labels.historic_data_labels[this.props.hashDrawer]
    if (this.props.hashDrawer
      && document.getElementById(`biomarker-${this.props.hashDrawer}`)
      && this.props.historic_data_chart
      && typeof (this.props.historic_data_chart[ad]) == constants.object
      && this.props.historic_data_chart[ad].length > 0
      && !this.state.defaultOpened) {
      this.setState({
        defaultOpened: true
      })
      scroller.scrollTo(`biomarker-${this.props.hashDrawer}`, { offset: -70 })
      document.getElementById(`biomarker-${this.props.hashDrawer}`).click()
    }
  }
  componentDidUpdate() {
    if (this.state.defaultOpened)
      this.setState({
        defaultOpened: false
      })
  }
  ageMessage(biomarker, latestDate, ageDate) {
    var chronological = this.props.age_round;
    var physiological = biomarker;
    if (moment(ageDate).format('YYYY-MM-DD') < moment(latestDate).format('YYYY-MM-DD')) {
      return (labels.analytics_labels.measured_recently);
    }
    else {
      if (!physiological)
        return (labels.analytics_labels.measured_recently)
      else {
        var diff = roundNumber((chronological - physiological), 1);

        if (diff < -2)
          return (`${Math.abs(diff)}  ${labels.analytics_labels.older_actual_age}`);
        else if (diff > 2)
          return (`${diff}  ${labels.analytics_labels.younger_actual_age}`);
        else
          return (labels.analytics_labels.average_for_age);
      }
    }
  }
  ageClass(physiological, latestDate, ageDate) {
    var chronological = this.props.age_round;
    if (moment(ageDate).format('YYYY-MM-DD') < moment(latestDate).format('YYYY-MM-DD')) {
      return ('historic');
    }
    else {
      var diff = roundNumber((chronological - physiological), 1);
      if (diff < -2)
        return ('danger');
      else if (diff > 2)
        return (constants.notification_type.success);
      else
        return ('warning');
    }
  }

  filterBlankValues(blankValues) {
    blankValues = blankValues.map((item) => {
      let datum = {
        value: item.value,
      }
      return {
        datum: datum
      }
    })
  }

  renderHistory(ad) {
    var historic_data = this.props.historic_data_chart && this.props.historic_data_chart[ad] ? this.props.historic_data_chart[ad] : []
    let hdata = historic_data.filter(bv => bv.value);
    if (hdata.length > 0)
      return (
        this.props.timeBoundaries ? <BiomarkerSparkline historic_data_values={hdata} timeBoundaries={this.props.timeBoundaries}
          uniqueID={`boa_${this.props.historic_data_chart[ad] ? this.props.historic_data_chart[ad][0].enLabel : ''}`} /> : ''
      );
    else
      return (null);
  }

  renderBiomarker(data) {
    return (
      <React.Fragment>
        {data ?
          Object.keys(data).map(ad => {
            let ages = constants.biographical_labels.agesForDrawer[ad]
            // console.log(uniformString(ages))
            // console.log(this.props.historic_data_chart)
            if (typeof (this.props.historic_data_chart[ad]) == constants.object && this.props.historic_data_chart[ad].length > 0) {
              return (<a id={`biomarker-${uniformString(ages)}`} className={`biomarker  ${this.props.historic_data_chart[ad] ? _.head(this.props.historic_data_chart[ad]).enLabel : ''}  ${this.ageClass(this.props.historic_data_chart[ad] ?
                _.last(this.props.historic_data_chart[ad]).value : '', this.props.updated_lastVisitDate, _.last(this.props.historic_data_chart[ad]).visitDate)}  ${this.props.marqueeBioCount && this.props.marqueeBioCount == 1 && ages != 'physioage' ? 'biomarkerMarquee' : ''}`}
                //constants.biographical_labels.ages[ad].toLowerCase()
                onClick={() => {
                  this.props.toggleSideBar(_.head(this.props.historic_data_chart[ad]).code.toLowerCase(),
                    _.last(this.props.historic_data_chart[ad]).value, _.last(this.props.historic_data_chart[ad]).visitDate, 'biomarker', _.head(this.props.historic_data_chart[ad]).enLabel,
                    this.props.history, _.head(this.props.historic_data_chart[ad]).enLabel);
                  setTimeout(() => {
                    this.setState({
                      defaultOpened: false
                    })
                  });
                }}>
                <div className='icon'>
                  {this.props.historic_data_chart[ad] && _.head(this.props.historic_data_chart[ad]).spriteName != 'tru' ?
                    <SpriteIcon label={this.props.historic_data_chart[ad] ? _.head(this.props.historic_data_chart[ad]).enLabel : ''}
                      sprite={this.props.historic_data_chart[ad] ? _.head(this.props.historic_data_chart[ad]).spriteName : ''} />
                    :
                    <img src={this.props.marqueeBioCount && this.props.marqueeBioCount == 1 ? tru_visitsummary : tru}></img>
                  }
                </div>
                <div className='detail'>
                  <div className='name' title={this.props.presentPatient.languagePreference != "en" ? (this.props.historic_data_chart[ad] ? _.head(this.props.historic_data_chart[ad]).label : '') : ''}>{this.props.historic_data_chart[ad] ? _.head(this.props.historic_data_chart[ad]).label : ''}
                  </div>
                  <div className='average'>{this.ageMessage(this.props.historic_data_chart[ad] ? _.last(this.props.historic_data_chart[ad]).value : '',
                    this.props.updated_lastVisitDate, _.last(this.props.historic_data_chart[ad]).visitDate)}</div>
                </div>
                <div className='age'>
                  <span>{this.props.historic_data_chart[ad] ? _.last(this.props.historic_data_chart[ad]).value : ''}</span>
                </div>
                <div className='graph'>{this.renderHistory(ad)}</div>
              </a>)
            }
          }) : ''}

      </React.Fragment>
    )
  }

  render() {
    if (this.props.historic_data_chart && this.props.historic_data_chart.biomarkerCount > 0) {
      return (
        <Element className='page-section biomarkers-of-aging' id='biomarkers-of-aging' name='biomarkers-of-aging'>
          <div className='max-container with-max-width'>
            <h2 className='section-title'>{this.props.terms['Biomarkers of Aging']}</h2>
            <div className='biomarkers'>
              {this.props.historic_data_chart ? this.renderBiomarker(this.props.historic_data_chart) : ''}
            </div>
          </div>
        </Element>
      );
    }
    else {
      return (null);
    }
  }
}

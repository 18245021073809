import React from 'react';
import datumChangeCalculation from '../tools/datumChangeCalculation';
import sprite from '../../../assets/images/sprite_base.svg'
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import { setSitePreference } from '../tools/calendar';

export default class DatumChangeCard extends React.Component {
  constructor(props) {
    super(props);
    this.title = this.title.bind(this);
  }

  title() {
    if (this.props.changeFrom == constants.baseline.toLowerCase())
      return (labels.analytics_labels.baseline_label);
    else if (this.props.changeFrom == constants.previous)
      return (labels.analytics_labels.previous_visit_label);
  }

  actually_render() {
    return (
      <div className='card reset'>
        <div className='result-wrapper'>
          <div className='result'>
            <div className='title'>{labels.analytics_labels.change_from} {this.title()}</div>
            <div className='trend-card '>
              {/* <div>
                <div className='trend'>{this.formattedPercentChanged()}</div>
              </div> */}
              <div className='icon'>
                <svg aria-hidden='true'>
                  {/* <use xlinkHref={`${sprite}#arrow-${this.direction()}`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
                  <use xlinkHref={`#arrow-${this.direction()}`} xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                  <symbol id="arrow-up" viewBox="0 0 16 16">
                    <polygon points="1.3,6.7 2.7,8.1 7,3.8 7,16 9,16 9,3.8 13.3,8.1 14.7,6.7 8,0 "></polygon>
                  </symbol>
                  <symbol id="arrow-down" viewBox="0 0 16 16">
                    <polygon points="14.7,9.3 13.3,7.9 9,12.2 9,0 7,0 7,12.2 2.7,7.9 1.3,9.3 8,16 "></polygon>
                  </symbol>
                </svg>
              </div>
              <div className="trend">
                {this.formattedAbsoluteChanged()}
                &nbsp;
                <span className="percent-trend">{this.formattedPercentChanged()}</span>
              </div>
            </div>
          </div>
          <div className="date">{labels.analytics_labels.since_label} {setSitePreference(this.props.siteData.datePreference, this.previousDate())}</div>
        </div>
      </div>
    );
  }

  direction() {
    return (this._calculator.direction);
  }

  formattedPercentChanged() {
    // if( this._calculator.change != null )
    //   return(this._calculator.change + ' %');
    if (this._calculator.percentChange != null)
      return ("(" + this._calculator.percentChange + " %)");
    else
      return (null);
  }
  formattedAbsoluteChanged() {
    if (this._calculator.absoluteChange != null)
      return (parseFloat(Number(Math.round(this._calculator.absoluteChange + 'e' + 2) + 'e-' + 2).toFixed(2)))
    else
      return (null);
  }
  previousValue() {
    return (this._calculator.previous_value);
  }

  previousDate() {
    return (this._calculator.previous_date);
  }

  render() {
    this._calculator = new datumChangeCalculation(this.props.datum, this.props.changeFrom);
    //return( this.formattedPercentChanged() ? this.actually_render() : null );
    return ((this.formattedAbsoluteChanged() != undefined) ? this.actually_render() : null);
  }
}


import React, { Component } from 'react';
import moment from 'moment';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import { returnLocalizedDate } from '../../../utils/resolve-timer';
import LoadingTemplate from '../../../components/LoadingTemplate';
import constants from '../../../config/constants';

class UserManual extends Component {
    componentDidMount() {
        this.props.getAllManuals('User')
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.help_page_labels.user_manual, path: '' }
        ])
        this.props.setPageTitle(labels.help_page_labels.user_manual)
        this.props.noHeaderLoading(true)
        document.title = `${labels.physioage_label} : ${labels.help_page_labels.user_manual}`
    }
    render() {
        return (
            // this.props.isLoading ? <LoadingTemplate haveSidebar={true} isCards={true} /> :
                <>
                    <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 add-doc-btn-section order-2'>
                        {this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.AN ?
                            <FormControl type='button' btnType='primary' text={labels.help_page_labels.upload_doc} onClick={() => this.props.addManual(this.props.history, 'User')} />
                            : ''}
                    </div>
                    <div className='all-manual col-lg-9 col-md-12 col-sm-12 col-xs-12'>
                        {this.props.manuals ? this.props.manuals.map(manual => (
                            <div className='card individual-manual col-lg-7'>
                                <h3>{manual.version}</h3>
                                <p>{labels.help_page_labels.uploaded} {moment(returnLocalizedDate(manual.createdAt)).format('YYYY-MM-DD h:mm:ss A')}</p>
                                <div className='d-flex btn-section'>
                                    <FormControl type='button' btnType='info'
                                        text={labels.help_page_labels.download_label}
                                        onClick={() => this.props.downloadHelpFile(manual.id)} />
                                    {this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.AN ?
                                        <FormControl type='button'
                                            btnType='warning'
                                            text={labels.help_page_labels.delete_label}
                                            onClick={() => this.props.launchDeleteModal(manual.id, 'User')} /> : ''}
                                </div>
                            </div>
                        )) : null
                        }
                    </div>
                </>
        )
    }
}
export default UserManual;
import React from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';

class UploadMultipleDocumentComponent extends React.Component {
   constructor() {
      super();
    }

  render() {
    return (
            <form onSubmit={(e) => { e.preventDefault(); this.props.uploadDocumentList(this.props.history) }} >
                <div className='alert alert-info' dangerouslySetInnerHTML={{ __html: labels.document_labels.document_upload_optional}}></div>
                <div className='bootstrap-table-container createMultipleDocument'>
                    <table className='react-bootstrap-table table'>
                        <thead>  
                        <tr>
                            <th className='required' >{labels.sites_labels.file}</th>
                            <th className='required ml-105'>{labels.sites_labels.title}</th>
                            <th className='ml-50'>{labels.sites_labels.publication_date}</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                            this.props.CreateDocumentList.map((element, index) => (  
                                <tr key={element.uuid}>
                                    <td>
                                    <FormControl type='file' onChange={(e) => this.props.postDocumentFile(index, e, element.uuid)} help={element.fileError ? element.fileError : ''} />
                                        { element && element.documentFile ?
                                            <div className='pt-2'> 
                                                { element.showImage ?
                                                    <img className='image-preview mb-3' alt={element.fileFileName} src={`data:${element.fileContentType};base64,${element.documentFile}`} />
                                                : ''}
                                            </div> : null
                                        }          
                                    </td>
                                    <td>
                                    <FormControl type='text' ref={(h) => this.helpForm = h}
                                        value={element.title ? element.title : ''}
                                        help={element.titleError ? element.titleError : ''}
                                        onChange={ (e) => this.props.handleTitleDocumentFile(index, e, element.uuid)} />
                                    </td>
                                    <td>
                                    <FormControl type='date' onChange={ (e) => this.props.handlePublicationDateDocumentFile(index, e, element.uuid)} 
                                        selected={element ? element.publicationDate : ''}
                                        disabledKeyboardNavigation
                                        popperPlacement= {'bottom-end'}
                                    />
                                    </td>
                                    <td>
                                    { element && element.documentFile ?
                                        element.fileContentType && element.fileContentType.indexOf("image") > -1 ?
                                            element.showImage ?
                                                <i class="fa fa-eye-slash icon-color" aria-hidden="true" onClick={()=> this.props.hideImage(index)}></i>
                                                :
                                                <i class="fa fa-eye icon-color" aria-hidden="true" onClick={()=> this.props.showImage(index)}></i>
                                            :
                                            <a href={`data:${element.fileContentType};base64,${element.documentFile}`} download={element.fileFileName}>
                                                <i class="fa fa-download" aria-hidden="true"></i>
                                            </a>
                                        : ''
                                      }   
                                    </td>
                                    <td>
                                    <i className="fa fa-trash status-indicator text-danger trash-icon" onClick={() =>this.props.removeDocumentFields(element.uuid)}> </i> 
                                    </td>
                                </tr>
                                ))}
                                <tr>
                                <td>
                                    <FormControl type='button'  className="btn btn-success" text={labels.sites_labels.create_document} btnType={constants.notification_type.success} />
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    {/* <i className="fa fa-plus status-indicator text-success trash-icon" onClick={() => this.props.addDocumentFields()}> </i> */}
                                </td>
                                </tr>
                            
                        </tbody> 
                    </table>
                </div>
            </form>
    );
  }
}
export default UploadMultipleDocumentComponent;
import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import { toggleLoading } from '../../../reducers/global-reducer';
const SET_PROPS = 'SET_PROPS'

function getCorrections() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        axios.get(API_URL.CORRECTIONS_URL).then(response => {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    corrections: response.data,
                    no_grid_data: response.data && !response.data.length ? true : false
                }
            })
        }).catch(error => {

        })
    }
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    corrections: [],
    no_grid_data: false
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}
export const actionCreators = {
    getCorrections
}
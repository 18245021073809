import React, { Component } from 'react';

import './patients-styles.scss';
import SpreadingRoutes from '../../../utils/SpreadingRoutes';
import PageTitle from '../../../components/PageTitle';

const excludeRoutes = ['reports', 'sites.cj', 'patients.cj', 'documents.cj']

class Patients extends Component {
    componentDidMount() {
        this.includeHeader = excludeRoutes.filter(er => this.props.location.pathname.indexOf(er) > -1).length == 0
         if (this.props.loggedInUser){
            this.props.getSitesList()
        }
    }
    componentWillUnmount() {
        this.props.toggleNotification(false)
    }

    render() {
        return (
            <div className={`patients-component row parent-component${this.props.isParentContainer && this.includeHeader ? ' container' : ''} 
            ${this.includeHeader ? '' : 'no-padding-json'}
            `}>
                {this.includeHeader ?
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                        <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                        <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                            <PageTitle breadCrumbs={this.props.breadCrumbs}
                                pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                                navigatePatients={this.props.navigatePatients}
                                history={this.props.history}
                                {...this.props} />
                        </div>
                    </div> : null}
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0'>
                    <SpreadingRoutes {...this.props} />
                </div>
            </div>
        )
    }
}
export default Patients;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RecommendationTable from '../containers/RecommendationTableContainer';
import ChatIcon from '@material-ui/icons/Chat';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import datumChangeCalculation from '../tools/datumChangeCalculation';
import labels from '../../../config/localization';
import { setSitePreference } from '../tools/calendar';
import HistoricDataChart from './HistoricDataChart';
import marked from 'marked';
import html2react from 'html-to-react';
import biomarkerDetailsSvg from '../../../../src/biomarker-details.svg';
import biomarkerDetailsSvgDark from '../../../../src/biomarker-details-dark.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NomenonDetails from './NomenonDetails';
import _ from 'lodash';
import { round } from '../modules/analyticsBeta';
import constants from '../../../config/constants';



function BiomarkerAgingOverview(props) {
    const [isRecommendationList, toggleRecommendationMenu] = useState(true);
    useEffect(() => {
        // props.setFilterRecommendation()
        props.toggleNomenonDetails(false)
        return () => {
            // Anything in here is fired on component unmount.
        }
    }, [])

    let categories = props.categories ? props.categories : [];
    let _calculator = "";
    let baseline_calculator = "";
    let previous_calculator = "";
    if (categories && props.grade_historic_data) {
        if (props.age_data) {
            baseline_calculator = new datumChangeCalculation(props.age_data, "baseline");
            previous_calculator = new datumChangeCalculation(props.age_data, "previous");
        }
    }
    let markdown2react = (md) => {
        marked.setOptions({
            renderer: new marked.Renderer(),
            sanitize: true,
            smartLists: true,
        });

        let parser = new html2react.Parser();
        return (parser.parse(marked(md)));
    }
    let formattedAbsoluteChanged = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        if (_calculator.absoluteChange != null)
            return parseFloat(round(_calculator.absoluteChange, 2));
        else
            return (null);
    }
    let formattedPercentChanged = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        if (_calculator.percentChange != null)
            return ("(" + _calculator.percentChange + " %)");
        else
            return (null);
    }

    let findDirection = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        if (_calculator.direction != null) {
            if (_calculator.direction == "up") {
                return (< ArrowUpwardIcon style={{ color: '#48D448' }} />)
            }
            else if (_calculator.direction == "down")
                return (< ArrowDownwardIcon style={{ color: '#C36900' }} />)
            else
                return (null);
        }
        else
            return (null);
    }

    let previousDate = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        return (_calculator.previous_date);
    }
    let returnValueCard = (props) => {
        return (
            <div className='baseline-container d-flex justify-content-center align-items-center'>
                <div className='mt-3 text-container'>
                    <div className='text-1 mb-2'>{_.last(props.historic_data_chart[props.biomarker_key_name]).value} Years</div>
                    <div className='text-2 mb-2'>As of {setSitePreference(props.siteData.datePreference, _.last(props.historic_data_chart[props.biomarker_key_name]).visitDate)}</div>
                </div>
            </div>
        );
    }

    return (
        <>
            {props.categories ?
                <>
                    <div className='biomarker-of-age-recommendation pr-3'>
                        <div className='graph-div'>
                            <div className='row no-gutters'>
                                <div className='header-biomarker-graph d-flex align-items-center'>
                                    <div className='bioage-header'>
                                        {props.ageName}
                                    </div>
                                    <div><img src={props.theme == "light-theme" ? biomarkerDetailsSvg : biomarkerDetailsSvgDark} alt='mySvgImage' /></div>
                                </div>
                                <div id={'drawer-body'} className='biomarkers-overview-graph row no-gutters pb-4'>
                                    <div className='chart-content'>
                                        <HistoricDataChart {...props}
                                            noumenon_name={_.head(props.historic_data_chart[props.biomarker_key_name]).code.toLowerCase()}
                                            historic_data={props.historic_data_chart ? props.historic_data_chart[props.biomarker_key_name] : ''}
                                            theme = {props.theme} />
                                    </div>
                                    <div className='biomarkers-timelines justify-content-center mt-1 ml-4'>
                                        {returnValueCard(props)}
                                        {(formattedAbsoluteChanged("baseline") != null && formattedPercentChanged("baseline") != null ?
                                            <div className='baseline-container d-flex justify-content-center align-items-center'>
                                                <div className='mt-3 text-container'>
                                                    <div className='text-1 mb-2'>{formattedAbsoluteChanged("baseline")} {formattedPercentChanged("baseline")} <span className='red-icon'>{findDirection("baseline")}</span></div>
                                                    <div className='text-2 mb-2'>{labels.analytics_labels.change_from_baseline}</div>
                                                    <div className='text-3 mb-2'>{labels.analytics_labels.since} {setSitePreference(props.siteData.datePreference, previousDate('baseline'))}</div>
                                                </div>
                                            </div> : '')}
                                        {(formattedAbsoluteChanged("previous") != null && formattedPercentChanged("previous") != null ?
                                            <div className='previous-container d-flex justify-content-center align-items-center'>
                                                <div className='mt-3 w-35 text-container'>
                                                    <div className='text-1 mb-2'>{formattedAbsoluteChanged("previous")} {formattedPercentChanged("previous")}<span className='blue-icon'>{findDirection("previous")}</span></div>
                                                    <div className='text-2 mb-2'>{labels.analytics_labels.change_from_last_visit}</div>
                                                    <div className='text-3'>{labels.analytics_labels.since} {setSitePreference(props.siteData.datePreference, previousDate('previous'))}</div>
                                                </div>
                                            </div> : '')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props.nomenonInvloved.length > 0 ?
                            <div className='noumenon-involved bg-white'>
                                <div className='d-flex'>
                                    <div className='col-9 d-flex align-items-center pl-0 pr-0'>
                                        <div className='col-12 pr-0'>
                                            <span className='nav-title mr-2'>Noumenon Involved: </span>
                                            {
                                                props.nomenonInvloved.map((nomenon) => nomenon.label).join(', ').length < 60 ?
                                                    <span> {props.nomenonInvloved && props.nomenonInvloved.map((nomenon) => nomenon.label).join(', ')}</span>
                                                    :
                                                    <span> {props.nomenonInvloved && props.nomenonInvloved.map((nomenon) => nomenon.label).join(', ').substring(0, 60)} <span className="gray-text">...</span></span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-3 d-flex align-items-center justify-content-end show-details-button-container'>
                                        <button className='add-btn btn float-right show-details-button' onClick={() => { props.toggleNomenonDetails(true) }}> Show Details</button>
                                    </div>
                                </div>
                            </div>
                            : null}

                        <div className='recommendation-list'>
                            <div className='d-flex recommendation-list-header align-items-center'>
                                <div className='col-10 d-flex'>
                                    <div class={"nav-title " + (props.isRecommendationOverViewList ? 'active' : '')} onClick={() => { props.setActiveRecommendationToggle(props.ages, 'rec') }}><ChatIcon /><span className='ml-1'>{labels.analytics_labels.recommendation_label}</span></div>
                                    <span className='ml-1'> | </span>
                                    <div class={"nav-title ml-1 d-flex align-items-center " + (!props.isRecommendationOverViewList ? 'active' : '')} onClick={() => { props.setActiveRecommendationToggle(null, 'more-info') }}>< InfoOutlinedIcon /><span> {labels.analytics_labels.more_info} </span></div>
                                </div>
                                {props.isRecommendationOverViewList ?
                                    <div className='col-2'>
                                        {props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ?
                                        <button className='add-btn btn float-right' onClick={() => {
                                            props.toggleRecommendationModal(true);
                                            props.newRecommendation();
                                            props.toggleMetaRecommendations(false)
                                        }}> {labels.analytics_labels.add}
                                        </button>
                                        : ""}
                                    </div> : ''}
                            </div>
                            {props.isRecommendationOverViewList ?
                                <RecommendationTable {...props} />
                                : <div className='more-info-description-toggle-info'>
                                    {props.noumenonReferences &&
                                        props.noumenonReferences.filter(s => s.code == props.ages).length && _.head(props.noumenonReferences.filter(s => s.code == props.ages)).noumenonExplanation ?
                                        markdown2react(_.head(props.noumenonReferences.filter(s => s.code == props.ages)).noumenonExplanation) : labels.analytics_labels.no_description}
                                    {props.noumenonReferences &&
                                        props.noumenonReferences.filter(s => s.code == props.ages).length &&
                                        _.head(props.noumenonReferences.filter(s => s.code == props.ages)).referencesDTO.length ?
                                        <div className='moreInfoReference biomarker-references'>
                                            <p className='reference-heading'><b>{labels.analytics_labels.references}</b></p>
                                            {
                                                _.head(props.noumenonReferences.filter(s => s.code == props.ages)).referencesDTO.map((s, index) => {
                                                    return (<div className='card' key='card-reference'>
                                                        <p className="card-header"><b>{labels.analytics_labels.reference} {index + 1} :</b></p>
                                                        {s.url ?
                                                            <>
                                                                <p className="card-body"><b>{labels.analytics_labels.url} : </b><a target="_blank" href={s.url}>{s.url}</a></p>
                                                            </>
                                                            : ''}
                                                        {s.internalNotes ?
                                                            <>
                                                                <p className="card-body"><b>{labels.analytics_labels.internal_notes} : </b>{s.internalNotes}</p>
                                                            </>
                                                            : ''}
                                                    </div>)
                                                })}
                                        </div> : ''}
                                </div>
                            }
                        </div>
                    </div >
                    {props.openNomenonDetails ? < NomenonDetails {...props} /> : null}
                </>
                : ""
            }
        </>
    );
}
export default connect()(BiomarkerAgingOverview);
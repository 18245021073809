import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import labels from '../../config/localization';
import './breadcrumb-styles.scss';
import constants from '../../config/constants';
import { getRoutes } from '../../routes';

export default class Breadcrumb extends Component {
    getNavigateUrl(path) {
        let allRoutes = getRoutes()
        let role = this.props.loggedInUser && this.props.loggedInUser.role ? this.props.loggedInUser.role : constants.logged_roles[localStorage.getItem(constants.role)]
        let parentRoute = (path).substring(0, _.indexOf(path, '/', 1))
        var childRoutes = _.head(allRoutes.filter(r => r.path == '/')).routes
        let currentRoute = _.head(childRoutes.filter(r => r.path == parentRoute))
        if (currentRoute && currentRoute.roles && currentRoute.roles.indexOf(role) != -1) {
            this.props.history.push(path)
        }
        else if (this.props.isAuthenticated && role == constants.logged_roles.CG && currentRoute.roles.indexOf(role) == -1) {
            this.props.history.push('/patients/list')
        } else {
            this.props.history.push('/')
        }
      
    }
    render() {
        return (
            <div className='breadcrumb'>
                {this.props.breadCrumbs && this.props.breadCrumbs.length ? this.props.breadCrumbs.map((path, index) => (
                    <span><span className='individual-breadcrumb'>
                        {(index == 0 && this.props.pageTitle == labels.signup_title)? <a href="https://physioage.com/">{path.text}</a>:
                        (index == 0 ? (this.props.location.pathname == '/patients/list' || this.props.location.pathname == '/patients') ? path.text : <a href='javascript:void(0)' key={index} onClick={() => this.props.navigatePatients(this.props.history)}>{path.text}</a> : (path.path ? <a key={index} onClick={() => this.getNavigateUrl(path.path)}>{path.text}</a> : <span key={index} >{path.text}</span>))} </span>
                        {index !== (this.props.breadCrumbs.length - 1) ? <span>/</span >
                            : ''}</span>
                            
                )) : ''}
            </div>
        )
    }
}
import React from 'react';
import ReactHighcharts from 'react-highcharts';

const ScatterChart = (props) => {
    const isValidScatterData = props.scatterData.length > 0 && props.scatterData.filter(x => x.gender != null).length > 0
                               && props.scatterData.filter(x => x.gender != '').length > 0 
    const config = isValidScatterData ? {
        chart: {
            type: 'scatter',
            height: 400
        },
        title: {
            text: `${props.xLabel} vs ${props.yLabel}`
        },
        xAxis: {
            title: {
                text: `${props.xLabel} (${props.xUnits})`
            }
        },
        yAxis: {
            title: {
                text: `${props.yLabel} (${props.yUnits})`
            }
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 2.5,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormat: `${props.xLabel}: {point.x} ${props.xUnits}<br>
                                  ${props.yLabel}: {point.y} ${props.yUnits}<br>
                                  Visit ID: {point.visitId}<br>
                                  Patient ID: {point.patientId}`
                }
            },
            series: {
                animation: false,
                cursor: 'pointer',
                turboThreshold:0,
                events: {
                    click: function (event) {
                        props.history.push(`/visits/${event.point.visitId}`)
                    }
                }
            }
        },
        series: [{
            name: 'female',
            color: '#f99deb',
            data: props.scatterData.filter(x => x.gender == 'female'),
            animation: false,
            marker: {
                symbol: 'circle'
            }
        }, {
            name: 'male',
            color: '#008FBC',
            data: props.scatterData.filter(x => x.gender == 'male')
        }]
    } : null;


    return (
        <div>
            {isValidScatterData ? (
                <ReactHighcharts config={config} />
            ) : (
                <div>No results</div>
            )}
        </div>
    );
};

export default ScatterChart;


import Axios from 'axios';
import _ from 'lodash';
import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import constants from '../../../config/constants';
import { toggleLoading, toggleNotification, handleErrors, setBreadCrumbs, setPageTitle, setLoggedInUser, handleStatusCodes, handleLoading } from '../../../reducers/global-reducer';
import messages from '../../../config/messages';
import labels from '../../../config/localization';
import { downloadCSV } from '../../../utils/download-csv-table';
import moment from 'moment';
import en_labels from '../../../config/en_labels';
import { promisesList, clearPromises } from '../../../utils/resolve-promises';
import { returnLocalizedDate } from '../../../utils/resolve-timer'

const SET_PROPS = 'SET_PROPS';
const reqFormFields = [constants.payment_labels.cardnumber, constants.payment_labels.cvc, constants.payment_labels.expiration]
const formFieldText = {
    num_credits: labels.payments_labels.credits_label,
    cardnumber: labels.payments_labels.card_number,
    cvc: labels.payments_labels.cvc_label,
    expiration: labels.payments_labels.exp_label,
}
// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }
export function getCredits(siteId, history, page) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        if (!siteId) {
            window.location.href = ('/statuscode/404')
            dispatch(toggleLoading(false))
        } else {
            dispatch(toggleLoading(true))
            dispatch(toggleNotification(false))
            axios.get(API_URL.CREDIT_TRANSACTIONS_URL, { params: { SiteId: siteId } }).then(response => {
                dispatch(toggleLoading(false))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        credits: response.data ? response.data.value : [],
                        no_grid_data: response.data.value && !response.data.value.length ? true : false,
                        defaultPage: page ? parseInt(page) : 1
                    }
                })
                dispatch(getFilteredCredits(page ? parseInt(page) : 1))
            }).catch(error => {
                dispatch(handleErrors(error))
                dispatch(handleStatusCodes(error))
            })
        }
    }
}
export function getFilteredCredits(page) {
    return (dispatch, getState) => {
        let sizePerPage = 10
        let startIndex = (page - 1) * sizePerPage
        let endIndex = startIndex + (sizePerPage - 1)
        let credits = getState().credits.credits
        dispatch({
            type: SET_PROPS,
            payload: {
                filteredCredits: credits.filter((t, i) => i >= startIndex && i <= endIndex),
                defaultPage: page ? parseInt(page) : 1
            }
        })
    }
}
export function getReceipt(transactionId) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                receipt: {},
            }
        })
        dispatch(toggleLoading(true))
        axios.get(`${API_URL.PAYMENT_URL}/${transactionId}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    receipt: response.data ? response.data : {},
                    isOpenModal: true
                }
            })
            dispatch(clearCreditForm())
            dispatch(toggleLoading(false))
        }).catch(error => {
            dispatch(handleErrors(error))
        })
    }
}
export function toggleReceipt(history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isOpenModal: false,
                showCreditsCaution: false
            }
        })
        history.push('/patients/list')
        dispatch(setLoggedInUser())

    }
}
function editCreditForm(eve) {
    return (dispatch) => {
        if (eve.target.id == constants.payment_labels.cardnumber) {
            dispatch(toggleNotification(false))
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                [eve.target.id]: eve.target.value
            }
        })
        dispatch(toggleNotification(false))
    }
}
function saveCreditForm(eve) {

    return (dispatch, getState) => {
        eve.preventDefault();
        let credits = getState().credits.num_credits
        let loggedInUser = getState().global.loggedInUser
        let currentSite = getState().credits.currentSite
        dispatch(toggleNotification(false))
        if (!credits) {
            dispatch(toggleNotification(true, constants.notification_type.error, '', formFieldText[num_credits], false))
        } else if (parseInt(credits) == 0) {
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [messages.elk_validation_zero_credits]
                , false))
        }
        else if (parseInt(credits) < 0) {
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [messages.elk_validation_negative_credits]
                , false))
        } else {
            let resFields = reqFormFields.filter(req => !getState().credits[req])
            let totalCredits = parseInt(currentSite.credits) + parseInt(credits)

            if (getState().credits.isExistingCardSelected && !resFields.length) {
                dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.multiple_card_validation], false))
            } else if (!getState().credits.isExistingCardSelected && resFields.length) {
                let errMsg = []
                resFields.map(r => errMsg.push(`${formFieldText[r]} ${labels.required_label}`))
                dispatch(toggleNotification(true, constants.notification_type.error, '', errMsg, false))
            } else if (totalCredits > constants.max_credits) {
                dispatch(toggleNotification(true, constants.notification_type.error, '', [labels.payments_labels.max_credits_validation], false))
            }
            else {
                let creditsInfo = getState().credits
                let card = {}
                if (!getState().credits.isExistingCardSelected)
                    card = {
                        number: creditsInfo.cardnumber.replace(/ /g, ''),
                        exp_month: _.head(creditsInfo.expiration.split('/')),
                        exp_year: _.last(creditsInfo.expiration.split('/')),
                        cvc: creditsInfo.cvc
                    }
                if (getState().credits.isExistingCardSelected && getState().credits.currentCustomer) {
                    dispatch(completePayment({
                        CardId: getState().credits.selectedCard,
                        SiteId: currentSite.id,
                        UserId: loggedInUser.id,
                        CustomerId: getState().credits.currentCustomer.id,
                        Credits: credits
                    }))
                } else if (getState().credits.currentCustomer && !getState().credits.isExistingCardSelected) {
                    dispatch(getToken(card, {
                        CustomerId: getState().credits.currentCustomer.id,
                        UserId: loggedInUser.id,
                        Credits: credits,
                        SiteId: currentSite.id,
                    }))
                } else if (!getState().credits.currentCustomer || !Object.keys(getState().credits.currentCustomer).length && card) {
                    dispatch(getToken(card, {
                        SiteId: currentSite.id,
                        UserId: loggedInUser.id,
                        Credits: credits
                    }))
                }
            };
        }
    }
}

function getToken(card, params) {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        let axiosInstance = Axios.create({
            baseURL: constants.stripe_url,
            headers: { Authorization: `${constants.bearer} ${process.env.REACT_APP_PAYMENT_API_KEY}`, 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        promisesList.push(axiosInstance({
            method: 'post',
            url: '/tokens',
            params: { 'card[number]': card.number, 'card[exp_month]': card.exp_month, 'card[exp_year]': card.exp_year, 'card[cvc]': card.cvc },
        }).then(res => {
            params = { ...params, StripeToken: res.data.id }
            dispatch(completePayment(params))
        }).catch(err => {
            if (err && err.error) {
                dispatch(toggleNotification(true, constants.notification_type.error, '', [err.error.message], false))
            } else {
                dispatch(handleErrors(err))
            }
        }))
    }
}
function completePayment(params) {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.post(API_URL.PAYMENT_STRIPE_URL,
            { ...params }
        ).then(res => {
            // dispatch(handleLoading())
            dispatch(getReceipt(res.data))
        }).catch(err => {
            if (err && err.error) {
                dispatch(toggleNotification(true, constants.notification_type.error, '', [err.error.message], false))
            } else {
                dispatch(handleErrors(err))
            }
            dispatch(clearCreditForm())
        }))
    }

}
function getSiteDetails(siteId, history) {
    return (dispatch, getState) => {
        let loggedInUser = getState().global && getState().global.loggedInUser ?
            getState().global.loggedInUser : null
        if (!loggedInUser.site) {
            dispatch(toggleLoading(true))
            promisesList.push(axios.get(API_URL.USERS_URL).then((userresponse) => {
                if (userresponse.data && (userresponse.data.siteId || userresponse.data.role == constants.logged_roles.AN)) {
                    if (userresponse.data.role == constants.logged_roles.AN || (userresponse.data.role == constants.logged_roles.CG && userresponse.data.siteId == siteId)) {
                        dispatch(setSiteDetails(siteId))
                    } else if (userresponse.data.role == constants.logged_roles.CG && userresponse.data.siteId != siteId) {
                        history.push('/patients/list')
                        dispatch(handleLoading())
                        dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.payment_site_validation], false))
                    }
                }
            }).catch(errors => dispatch(handleErrors(errors))))
        }
        else {
            if (loggedInUser.site && Object.keys(loggedInUser.site)
                && (loggedInUser.role == constants.logged_roles.AN || (loggedInUser.role == constants.logged_roles.CG && loggedInUser.siteId == siteId))) {
                if (loggedInUser.siteId == siteId) {
                    dispatch(getCustomerDetails(loggedInUser.site.stripeCustomerId))
                    dispatch(setHeaders(loggedInUser.site))
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            currentSite: loggedInUser.site
                        }
                    })

                } else if (loggedInUser.siteId != siteId && loggedInUser.role == constants.logged_roles.AN) {
                    dispatch(setSiteDetails(siteId))
                }
            }
        }
    }
}
function setSiteDetails(siteId) {
    return (dispatch) => {
        promisesList.push(axios.get(`${API_URL.SITES_URL}/${siteId}`).then(response => {
            if (response && response.data) {
                dispatch(getCustomerDetails(response.data.stripeCustomerId))
                dispatch(setHeaders(response.data))
                dispatch(handleLoading())
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentSite: response.data
                    }
                })

            }
        }).catch(errors => dispatch(handleErrors(errors))))
    }
}
function setHeaders(site) {
    return (dispatch) => {
        dispatch(handleLoading())
        dispatch(setPageTitle(`${labels.payments_labels.title_help} ${site.name}`))
        dispatch(setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: `${labels.payments_labels.title_help} ${site.name}`, path: '' }
        ]))
        document.title = `${labels.payments_labels.title_help} ${site.name}`
    }
}

function getCustomerDetails(cust_id) {
    return (dispatch) => {
        if (cust_id) {
            let axiosInstance = Axios.create({
                baseURL: constants.stripe_url,
                headers: { Authorization: `${constants.bearer} ${process.env.REACT_APP_SECRET_CUST_KEY}`, 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            dispatch(toggleLoading(true))
            promisesList.push(axiosInstance({
                method: 'get',
                url: `/customers/${cust_id}`
            }).then(res => {
                dispatch(handleLoading())
                let currentCustomer = res.data
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        existingCards: currentCustomer.sources.data.map(d => { return { id: d.id, card: d.last4 } }),
                        currentCustomer
                    }
                })
            }).catch(err => {
                dispatch(handleErrors(err))
            }))
        }
    }
}
function selectedExistingCard(eve) {
    return (dispatch, getState) => {
        let cardnumber = getState().credits.cardnumber
        let selectedCard = eve.target.value && eve.target.value != '0' ? eve.target.value : 0

        if (cardnumber) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    cardnumber: null,
                    expiration: null,
                    cvc: null
                }
            })
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                isExistingCardSelected: selectedCard ? true : false,
                selectedCard
            }
        })
    }
}
function exportDataToCSV() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let credits = getState().credits.credits
        let csv = []
        if (credits.length) {
            const reqlabels = {
                change: labels.header_labels.elk,
                date: labels.inbox_labels.date_label,
                nameOfTheUser: labels.elk_labels.user,
                comment: labels.elk_labels.comment
            }
            const headers = Object.keys(reqlabels).map(r => reqlabels[r])
            csv.push(headers.join())
            credits.map(c => csv.push(Object.keys(reqlabels).map(r => r == constants.payment_labels.date
                ? moment(returnLocalizedDate(c[r])).format("YYYY-MM-DD hh:mm:ss A") : r == constants.payment_labels.comment
                    ? `"${c[r] ? c[r] : ""}"` : c[r]).join()))
            downloadCSV(csv.join('\n'), en_labels.credits_labels.credit_transactions_name)
            dispatch(toggleLoading(false))

        }
    }
}
function clearCreditForm() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                cardnumber: null,
                cvc: null,
                expiration: null,
                isExistingCardSelected: false,
                num_credits: "",
                selectedCard: 0
            }
        })
    }
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    credits: [],
    receipt: {},
    currentTransaction: {},
    isOpenModal: false,
    modalMode: '',
    isExistingCardSelected: false,
    cardnumber: null,
    cvc: null,
    expiration: null,
    no_grid_data: false,
    currentSite: {},
    defaultPage: 1,
    filteredCredits: []
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}
export const actionCreators = {
    getCredits,
    getReceipt,
    toggleReceipt,
    editCreditForm,
    saveCreditForm,
    getSiteDetails,
    exportDataToCSV,
    selectedExistingCard,
    setHeaders,
    clearCreditForm,
    getFilteredCredits
}
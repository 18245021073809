import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ChatIcon from '@material-ui/icons/Chat';
import labels from '../../../config/localization';
import marked from 'marked';
import html2react from 'html-to-react';
import { setSitePreference } from '../tools/calendar';
import datumChangeCalculation from '../tools/datumChangeCalculation';
import RecommendationTable from '../containers/RecommendationTableContainer';
import { round } from '../modules/analyticsBeta';
import CategoryDataChart from './CategoryDataChart';
import constants from '../../../config/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RubricRangesComponent from './RubricRangesComponent';
import Grade from './Grade';

function CategoryOverview(props) {
    const [isRecommendationList, toggleRecommendationList] = useState(true);
    useEffect(() => {
        props.toggleRecommendationList(true)
        props.setFilterRecommendation()
        if (props.currentCategory){
            props.toggleCategoryOverview('category', props.currentCategory, getCategoryGrades(props.grading_data, props.currentCategory))
        }    
        if (props.currentNomenon) {
            props.toggleDatumOverview(props.categories, props.currentNomenon, "", 'datum')
        }
        return () => {
            // Anything in here is fired on component unmount.
        }
    }, [])

    let categories = props.categories ? props.categories : [];
    let _calculator = '';
    let baseline_calculator = '';
    let previous_calculator = '';
    let isDescriptionAvailable = false;
    let getCategoryGrades = (gradeData, category) => {
        var gradeArray = []
        gradeData && gradeData.length ?
            gradeData.map(s => {
                return s.data.map(t => {
                    if (t.key == category.key) {
                        t.value = t.value ? round(t.value.toString(), 2) : t.value
                        gradeArray.push(t)
                    }
                })
            }) : ''
        if (gradeArray && gradeArray.length)
            return gradeArray.filter(s => s.value);
    }

    if (categories && props.grade_historic_data && props.drawerType) {
        if (props.drawerType == 'category') {
            if (props.categoryGradesHistoric) {
                baseline_calculator = new datumChangeCalculation(props.categoryGradesHistoric, 'baseline');
                previous_calculator = new datumChangeCalculation(props.categoryGradesHistoric, 'previous');
            }
        }
        else if (props.drawerType == 'section') {
            if (props.sectionGradesHistoric) {
                baseline_calculator = new datumChangeCalculation(props.sectionGradesHistoric, 'baseline');
                previous_calculator = new datumChangeCalculation(props.sectionGradesHistoric, 'previous');
            }
        }
        else {
            if (props.datumValues) {
                baseline_calculator = new datumChangeCalculation(props.datumValues, 'baseline');
                previous_calculator = new datumChangeCalculation(props.datumValues, 'previous');
            }
        }

    }
    let markdown2react = (md) => {
        marked.setOptions({
            renderer: new marked.Renderer(),
            sanitize: true,
            smartLists: true,
        });

        let parser = new html2react.Parser();
        return (parser.parse(marked(md)));
    }

    let formattedAbsoluteChanged = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        if (_calculator.absoluteChange != null)
            return  parseFloat(round(_calculator.absoluteChange, 2));
        else
            return (null);
    }
    let formattedPercentChanged = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        if (_calculator.percentChange != null)
            return ("(" + _calculator.percentChange + " %)");
        else
            return (null);
    }

    let findDirection = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        if (_calculator.direction != null) {
            if (_calculator.direction == "up") {
                return (< ArrowUpwardIcon style={{ color: '#48D448' }} />)
            }
            else if (_calculator.direction == "down")
                return (< ArrowDownwardIcon style={{ color: '#C36900' }} />)
            else
                return (null);
        }
        else
            return (null);
    }

    let previousDate = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        return (_calculator.previous_date);
    }

    let getGrade = () => {
        let gradePoints;
        if (props.drawerType == 'category') {
            if (props.categoryGradesHistoric)
                gradePoints = props.categoryGradesHistoric.points != null ? props.categoryGradesHistoric.points.toFixed(2) : 'N/A'
        }
        else if (props.drawerType == 'section') {
            if (props.sectionGradesHistoric)
                gradePoints = props.sectionGradesHistoric.points != null ? props.sectionGradesHistoric.points.toFixed(2) : 'N/A'
        }
        else if (props.drawerType == 'datum') {
            if (props.datumValues)
                gradePoints = props.datumValues.points != null ? props.datumValues.points.toFixed(2) : 'N/A'
        }
        return gradePoints;
    }
    let returnGraphData = (props) => {
        if (props.drawerType == 'category') {
            return props.categoryGradesData.filter(item => !!item.value);
        }
        else if (props.drawerType == 'section') {
            return props.sectionGradesData && props.sectionGradesData.filter(item => !!item.value);
        }
        else {
            return props.datumValues && props.datumValues.historicData && props.datumValues.historicData.filter(item => !!item.value)
        }
    }

    let returnSortedRubricRanges = (props) => {
        let rubricRanges = props.datumValues ? props.datumValues.noumenonRubricRanges : [];
        if (rubricRanges) {
            rubricRanges.sort((a, b) => {
                if (a.low === null && b.low === null) {
                    return 0;
                }
                if (a.low === null) {
                    return -1; // Move items with null "low" to the beginning
                }
                if (b.low === null) {
                    return 1; // Move items with null "low" to the beginning
                }
                return a.low - b.low;
            });
        }
        return rubricRanges;
    }
    let returnValueCard = (props) => {
        if (props.drawerType == 'datum')
            return (
                <div className='border-bottom mt-3 height-22'>
                    <div className='text-1-value'>{props.datumValues.value} {props.datumValues.units}</div>
                    <div className='text-3'>As of {setSitePreference(props.siteData.datePreference, props.datumValues.visitDate)}</div>
                </div>
            );
        return '';
    }
    let returnDrawerContent = (props) => {
        if (props.isRecommendationOverViewList) {
            return <RecommendationTable {...props} />
        }
        else if (props.isMoreInfoToggle) {
            return (props.currentIndicator && (!props.currentIndicator.includes('category') && !props.currentIndicator.includes('section')) ?
                <div className='more-info-description-toggle-info'>
                    {props.noumenonReferences &&
                        props.noumenonReferences.filter(s => s.code == props.currentIndicator).length && _.head(props.noumenonReferences.filter(s => s.code == props.currentIndicator)).noumenonExplanation ?
                        markdown2react(_.head(props.noumenonReferences.filter(s => s.code == props.currentIndicator)).noumenonExplanation) : labels.analytics_labels.no_description}
                    {props.noumenonReferences &&
                        props.noumenonReferences.filter(s => s.code == props.currentIndicator).length &&
                        _.head(props.noumenonReferences.filter(s => s.code == props.currentIndicator)).referencesDTO.length ?
                        <div className='moreInfoReference references'>
                            <p className='reference-heading'><b>{labels.analytics_labels.references}</b></p>
                            {
                                _.head(props.noumenonReferences.filter(s => s.code == props.currentIndicator)).referencesDTO.map((s, index) => {
                                    return (<div className='card' key='card-reference'>
                                        <p className="card-header"><b>{labels.analytics_labels.reference} {index + 1} :</b></p>
                                        {s.url ?
                                            <>
                                                <p className="card-body url-reference"><b>{labels.analytics_labels.url} : </b><a target="_blank" href={s.url}>{s.url}</a></p>
                                            </>
                                            : ''}
                                        {s.internalNotes ?
                                            <>
                                                <p className="card-body"><b>{labels.analytics_labels.internal_notes} : </b>{s.internalNotes}</p>
                                            </>
                                            : ''}
                                    </div>)
                                })
                            }
                        </div> : ''
                    }
                </div>
                : <div className='more-info-description-toggle-info'>
                    {
                        props.currentIndicator && props.currentIndicator.includes('category')
                            ? props.categories ? props.categories.map(p => {
                                if (p.key == (props.currentIndicator)) {
                                    if (p.passagesDTO != null && p.passagesDTO.length) {
                                        return p.passagesDTO.map(q => {
                                            if (q.passageTranslationDTO != null) {
                                                isDescriptionAvailable = true
                                                return (markdown2react(q.passageTranslationDTO.content))
                                            }
                                        }
                                        )
                                    }

                                }
                            })
                                : ''
                            : props.currentIndicator && props.currentIndicator.includes('section')
                                ? props.categories ? props.categories.map(s => s.sections.map(p => {
                                    if (p.key == (props.currentIndicator)) {
                                        if (p.passagesDTO != null && p.passagesDTO.length) {
                                            return p.passagesDTO.map(q => {
                                                if (q.passageTranslationDTO != null) {
                                                    isDescriptionAvailable = true
                                                    return (markdown2react(q.passageTranslationDTO.content))
                                                }
                                            }
                                            )
                                        }
                                    }
                                }))
                                    : ''
                                : ''}
                    {!isDescriptionAvailable ? markdown2react(labels.analytics_labels.no_description) : ''}
                </div>
            )
        }
        else if (props.isInterpretationToggle) {
            return <div className='interpretations-information'>
                {
                    props.interpretations.filter(s => (s.sectionName == props.currentIndicator || s.categoryName == props.currentIndicator) || s.noumenaCodes.includes(props.currentIndicator)).map((s, index) => {
                        return (<div className='card mb-2'>
                            <p className="card-header"><b>{labels.analytics_labels.based_on} {s.noumenaList}</b></p>
                            <div class="card-body">
                                <p class="card-text">{markdown2react(s.interpretation)}</p>
                            </div>
                        </div>)
                    })
                }
            </div>
        }
    }

    return (
        <> {(props.categoryGradesHistoric || props.sectionGradesHistoric || props.datumValues) && ((props.drawerType != 'datum') || (props.drawerType == 'datum' && props.datumValues.valueType == 'NM')) && (typeof getGrade() !== 'undefined' || formattedAbsoluteChanged("baseline") || formattedPercentChanged("baseline")) ?
            <div className={`border-bottom row no-gutters pb-4 pr-3 pl-3 align-items-center graph-timelines-container ${getGrade() == 'N/A' ? ' null-points' : ' valid-points'}`}>
                <div className='col-lg-2 col-md-2 col-sm-2 col-12 timelines-container'>

                    <div className='height-35vh text'>
                        { props.drawerType == 'datum' ?
                           <div className='border-bottom mt-2'>
                                <div className='mb-2 gpa-grade'>
                                    <Grade points={getGrade()}></Grade>    
                                </div>
                            </div>
                        :
                            <div className='border-bottom mt-3'>
                                <div className='text-1'>
                                    {getGrade()}{' GPA'}
                                </div>
                            </div>
                        }
                        {returnValueCard(props)}
                        {formattedAbsoluteChanged("baseline") != null && formattedPercentChanged("baseline") != null ?
                            <div className='baseline-container'>
                                <div className='border-bottom mt-3'>
                                    <div className='text-1'>{formattedAbsoluteChanged("baseline")} {formattedPercentChanged("baseline")} <span className='red-icon ml-2'>{findDirection("baseline")}</span></div>
                                    <div className='text-2'> {labels.analytics_labels.change_from_baseline}</div>
                                    <div className='text-3'>{labels.analytics_labels.since} {setSitePreference(props.siteData.datePreference, previousDate('baseline'))}</div>
                                </div>
                            </div> : ''}
                        {formattedAbsoluteChanged("previous") != null && formattedPercentChanged("previous") != null ?
                            <div className='previous-container'>
                                <div className='mt-3'>
                                    <div className='text-1'>{formattedAbsoluteChanged("previous")} {formattedPercentChanged("previous")}<span className='blue-icon ml-2'>{findDirection("previous")}</span></div>
                                    <div className='text-2'>{labels.analytics_labels.change_from_last_visit}</div>
                                    <div className='text-3'>{labels.analytics_labels.since} {setSitePreference(props.siteData.datePreference, previousDate('previous'))}</div>
                                </div>
                            </div> : ''}
                    </div>
                </div>
                <div className='col-lg-9 col-md-10 col-sm-10 col-12 rubric-ranges-chart-container'>
                    {(props.drawerType != 'datum') || ( props.datumValues && props.datumValues.noumenonRubricRanges && props.datumValues.noumenonRubricRanges.length > 0 ) ?
                    <div className={`d-flex rubric-ranges-container ${props.currentIndicator ? (props.currentIndicator.includes('category') || props.currentIndicator.includes('section')) ? 'category-section-container' : props.datumValues ? props.datumValues.points ? '' : 'null-points' : '' : ''}`}>
                    <RubricRangesComponent {...props} datumValues = {props.datumValues} rubricRanges={returnSortedRubricRanges(props)} currentValue = {_.last(returnGraphData(props)).value} colorState = {props.drawerType == 'category' || props.drawerType == 'section' ? 'static-color' : 'dynamic-color'}/>
                </div> : ''}
                    
                    <div className="category-graph-section">
                        {/* {props.categoryGradesData && props.categoryGradesData.length ? */}
                        <div id={'drawer-body'} className='category-overview-graph'>
                            <div className='chart-content'>
                                <CategoryDataChart {...props} reportCard={true}
                                    historic_data={returnGraphData(props)} theme={props.theme} drawerType={props.drawerType} type={props.drawerType != "datum" ? "pointed" : ""} />
                            </div></div>
                        {/* : ''} */}
                    </div>
                </div>
            </div>
            : ''}
            <div className='d-flex mb-4 mt-4 align-items-center'>
                <div className='col-10 d-flex'>
                    <div class={"nav-title " + (props.isRecommendationOverViewList ? 'active' : '')} onClick={() => { props.setActiveRecommendationToggle(props.currentIndicator, 'rec') }}><ChatIcon /><span className='ml-1'>{labels.analytics_labels.recommendation_label}</span></div>
                    <span className='ml-1'> | </span>
                    <div class={"nav-title ml-1 " + (!props.isMoreInfoToggle ? '' : 'active')} onClick={() => { props.setActiveRecommendationToggle(props.currentIndicator, 'more-info') }}>< InfoOutlinedIcon /><span> {labels.analytics_labels.more_info} </span></div>
                    {(props.drawerType == 'section' || props.drawerType == 'category' || props.drawerType == 'datum')
                        && (props.interpretations && props.interpretations.filter(s => (s.sectionName == props.currentIndicator) || (s.categoryName == props.currentIndicator) || s.noumenaCodes.includes(props.currentIndicator)).length) ?
                        <>
                            <span className='ml-1'> | </span>
                            <div class={"nav-title ml-1 " + (!props.isInterpretationToggle ? '' : 'active')} onClick={() => { props.setActiveRecommendationToggle(props.currentIndicator, 'interpretations') }}><span> {labels.analytics_labels.interpretations}  </span></div>
                        </>
                        : ''}
                </div>
                {props.isRecommendationOverViewList ?
                    <div className='col-2'>
                        {props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ?
                        <button className='add-btn btn float-right' onClick={() => {
                            // props.saveProviderNotes() 
                            props.toggleRecommendationModal(true);
                            props.newRecommendation();
                            props.toggleMetaRecommendations(false)
                        }}> {labels.analytics_labels.add}</button>
                        : ""
                        }
                    </div> : ''}
            </div>
            {returnDrawerContent(props)}
        </>
    );
}
export default connect()(CategoryOverview);
import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Loader from '../../../components/Loader';
import labels from '../../../config/localization';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Typeahead } from 'react-bootstrap-typeahead';
import { clearSearch } from '../../Patients/modules/patients';
import constants from '../../../config/constants';
import Problems from './Problems';



function ProblemListComponent(props) {
    const [value, setValue] = React.useState( "active");
    const [openSearchField, setOpenSearchField] = React.useState(false);
    const [selectedSeachField, setSelectedSeachField] = React.useState( null);
    let seachField = useRef(null);

    const handleChange = (event, newValue) => {
        props.setProblemType(newValue)
        setValue(newValue);
        setOpenSearchField(false);
        props.resetProblemFilter();
        setSelectedSeachField([]);
        // clearSearchField();
    };

    
    useMemo(() => {
        if (props.problemType !== value)
            setValue(props.problemType)
    })
    useEffect(() => {
        let element = document.getElementsByClassName("heading-1")
        element && element[0] ? element[0].scrollIntoView(0,0) : ""
        let problemType =   props.activeProblems && props.activeProblems.length > 0 ? 'active' :
                            props.inactiveProblems && props.inactiveProblems.length > 0 ? 'inactive' :
                            props.unknownProblems && props.unknownProblems.length > 0 ? 'unknown' : 
                            'active'
        props.setProblemType(problemType)
        props.toggleCategoryDetailsModal(false)
        // props.resetRecommendationFilter()
        // componentDidMount //
    return () => {
        // componentWillUnmount() //
    }
  }, [])

  const editProblemSearch = (event, history, type) => {
    props.editProblemSearch(_.head(event), history, type)
  }

let isNullOrEmpty = (value) => {
    return (value === null || value === "");
}

  const problemOptions = () => {
    if(props.problemType == "active") {
        return (props.activeProblems && props.activeProblems.length > 0 ? props.activeProblems : [])
    }
    else if(props.problemType == "inactive") {
        return ( props.inactiveProblems && props.inactiveProblems.length > 0 ? props.inactiveProblems : [])
    }
    else if(props.problemType == "unknown") {
        return ( props.unknownProblems && props.unknownProblems.length > 0 ? props.unknownProblems : [])
    }
    else{
        return([])
    }
  }
  const clearSearchField =() =>{
    seachField.current.clear()
  }
  const getSelectedSeachField = (event) => {
    if (_.head(event)){ 
        setSelectedSeachField([_.head(event).code])
    }    
  }

    return (
        <> 
            { props.categories && props.activeProblems  ? 
                <div className='treatment-plan'>
                    <div className="row header bg-white no-gutters b-bottom align-items-center">
                        <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                            <div className="heading-1">{'Problem List'}</div>
                        </div>    
                        <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                            {props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ?
                                <button className='add-btn btn float-right add-problems-btn' onClick={() => {
                                        props.toggleProblemModal(true);
                                        props.newProblem();
                                    }}> {labels.analytics_labels.add_problems}
                                </button>
                            : "" }       
                        </div>
                    </div>
                    <div className='row no-gutters height-100 height-95'>
                         <Box className='height-100' sx={{ width: '100%', padding: '0px 10px 0px 30px', typography: 'body1' }}>
                            <TabContext value={value} className='height-100'>
                                <Box className="d-flex row">
                                    <TabList onChange={handleChange} className='col-lg-8 col-6' >
                                        <Tab label="Active" value="active" />
                                        <Tab label="Inactive" value="inactive" />
                                        <Tab label="Unknown" value="unknown" />
                                    </TabList>
                                    <div className='recommendation-search-field col-lg-4 col-6'>
                                        <form
                                            className={`${openSearchField ? "form-hover" : "form-close"}`}>
                                            <Typeahead
                                            labelKey="code"
                                            selected={selectedSeachField}
                                            id='noumenon-search'
                                            options={problemOptions()}
                                            renderMenuItemChildren={(option, props) => (
                                                <span key={option.code}> {option.code}</span>
                                            )}
                                            onChange={(event) => { editProblemSearch(event, props.history, props.problemType); getSelectedSeachField(event) }}
                                            newSelectionPrefix={null}
                                            placeholder={"Search here ..."}
                                            ref={seachField}
                                            />
                                            <span className={`search-icon-div ${openSearchField ? "search-open" : "serach-close"}`}>
                                                {openSearchField ?
                                                    <CloseRoundedIcon onClick={() => { setOpenSearchField(false); setSelectedSeachField([]), clearSearchField() ; props.resetProblemFilter(); setSelectedSeachField([]); }} className="search-icon" />
                                                    :
                                                    <SearchOutlinedIcon onClick={() => setOpenSearchField(true)} className="search-icon" />
                                                }
                                            </span>
                                        </form>    
                                    </div>
                                </Box>
                                <TabPanel value="active" sx={{padding: 0, height: '100%'}} >
                                    <Problems {...props} />
                                </TabPanel>
                                <TabPanel value="inactive" sx={{padding: 0, height: '100%'}} >
                                    <Problems {...props} />
                                </TabPanel>
                                <TabPanel value="unknown" sx={{padding: 0, height: '100%'}} >
                                    <Problems {...props} />
                                </TabPanel>
                              
                            </TabContext>
                        </Box>    
                    </div>
                </div>    
            : null}        
        </>
    );
}
export default connect()(ProblemListComponent);
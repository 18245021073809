import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Loader from '../../../components/Loader';
import Recommendations from '../containers/RecommendationsContainer';
import labels from '../../../config/localization';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Typeahead } from 'react-bootstrap-typeahead';
import { clearSearch } from '../../Patients/modules/patients';
import constants from '../../../config/constants';


function TreatmentPlanComponent(props) {
    const [value, setValue] = React.useState( "active");
    const [openSearchField, setOpenSearchField] = React.useState(false);
    const [selectedSeachField, setSelectedSeachField] = React.useState( null);
    let seachField = useRef(null);

    const handleChange = (event, newValue) => {
        props.setRecommendationType(newValue)
        setValue(newValue);
        setOpenSearchField(false);
        props.resetRecommendationFilter();
        setSelectedSeachField([]);
        clearSearchField();
    };

    
    useMemo(() => {
        if (props.recommendationType !== value)
            setValue(props.recommendationType)
    })
    useEffect(() => {
        let element = document.getElementsByClassName("heading-1")
        element && element[0] ? element[0].scrollIntoView(0,0) : ""
        props.setRecommendationType("active")
        props.setRecType("Recommendation")
        props.setDrawerType(null)
        props.resetRecommendationFilter()
        props.toggleCategoryDetailsModal(false)
        // componentDidMount //
    return () => {
        // componentWillUnmount() //
    }
  }, [])

  const editRecommendationSearch = (event, history, type) => {
    props.editRecommendationSearch(_.head(event), history, props.recType, type)
  }

let isNullOrEmpty = (value) => {
    return (value === null || value === "");
}
let updatedMetaRecommendations = (recommendations, metaRecommendations) => {
    const newMetaRecommendations = Array.isArray(metaRecommendations) && Array.isArray(recommendations) ? metaRecommendations.filter(metaRecommendation => {
        return !recommendations.some(recommendation => {
            const matchBody = (isNullOrEmpty(recommendation.body) && isNullOrEmpty(metaRecommendation.body)) ? true : recommendation.body && metaRecommendation.body && 
                recommendation.body.replace(/\s/g, '').toLowerCase() === metaRecommendation.body.replace(/\s/g, '').toLowerCase();

            const matchBrand = (isNullOrEmpty(recommendation.brand) && isNullOrEmpty(metaRecommendation.brand)) ? true : recommendation.brand && metaRecommendation.brand &&
                recommendation.brand.replace(/\s/g, '').toLowerCase() === metaRecommendation.brand.replace(/\s/g, '').toLowerCase();

            const matchGroup = (isNullOrEmpty(recommendation.group) && isNullOrEmpty(metaRecommendation.group)) ? true : recommendation.group && metaRecommendation.group &&
                recommendation.group.replace(/\s/g, '').toLowerCase() === metaRecommendation.group.replace(/\s/g, '').toLowerCase();

            return matchBody && matchBrand && matchGroup;
        });
    }) : []
    
    return newMetaRecommendations
}
  const recommendationOptions = () => {
    if (props.recommendationType == "meta") {
        return (props.metaRecommendations && props.metaRecommendations.length > 0 ? updatedMetaRecommendations(props.recommendations, props.metaRecommendations) : [])
    }
    else if(props.recommendationType == "active") {
      return (props.recommendations && props.recommendations.length > 0 ? props.recommendations : [])
    }
    else if(props.recommendationType == "stopped") {
      return (props.stoppedRecommendations && props.stoppedRecommendations.length > 0 ? props.stoppedRecommendations : [])
    }
    else{
      return([])
    }
  }
  const clearSearchField =() =>{
    seachField.current.clear()
  }
  const getSelectedSeachField = (event) => {
    if (_.head(event)){ 
        setSelectedSeachField([_.head(event).body + " " + ( _.head(event).body ? _.head(event).body.includes(_.head(event).strength) ? "" : _.head(event).strength ? _.head(event).strength : "" : "")])
    }    
  }

    return (
        <> 
            { props.categories && props.recommendations  ? 
                <div className='treatment-plan'>
                    <div class="row header bg-white no-gutters b-bottom align-items-center">
                        <div class="col-lg-10 col-md-8 col-sm-9 col-8">
                            <div class="heading-1">{props.terms['Treatment Plan']}</div>
                        </div>    
                        <div class="col-lg-2 col-md-4 col-sm-3 col-4">
                            <div className='row d-flex'>
                                <div className='col-11 d-flex justify-content-end'>
                                    {!props.isDemo && props.presentPatient && props.presentPatient.priorVisitId != 0 && !props.isRecommendationsLoading ?
                                        <button onClick={() => props.getHistoryRecommendations()} className="btn add-btn  w-auto mr-2">{labels.analytics_labels.historic}</button>
                                    : ''}
                                    {props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ?
                                  
                                        <button className='add-btn btn float-right' onClick={() => {
                                                props.toggleRecommendationModal(true);
                                                props.newRecommendation();
                                                props.toggleMetaRecommendations(false)
                                            }}> {labels.analytics_labels.add}
                                        </button>
                                : "" }
                                </div>
                                       
                            </div>
                        </div>
                    </div>
                    <div className='row no-gutters height-100 height-95'>
                         <Box className='height-100' sx={{ width: '100%', padding: '0px 10px 0px 30px', typography: 'body1' }}>
                            <TabContext value={value} className='height-100'>
                                <Box className="d-flex row">
                                    <TabList onChange={handleChange} className='col-lg-8 col-6' >
                                        <Tab label="Active" value="active" />
                                        <Tab label="Stopped" value="stopped" />
                                        { props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ?
                                            <Tab label={labels.analytics_labels.suggestions} value="meta" />
                                        : null }
                                    </TabList>
                                    <div className='recommendation-search-field col-lg-4 col-6'>
                                        <form
                                            className={`${openSearchField ? "form-hover" : "form-close"}`}>
                                            <Typeahead
                                            labelKey="body"
                                            selected={selectedSeachField}
                                            id='noumenon-search'
                                            options={recommendationOptions()}
                                            renderMenuItemChildren={(option, props) => (
                                                <span key={option.body}> {option.body + " " + ( option.body ? option.body.includes(option.strength) ? "" : option.strength ? option.strength : "" : "")}</span>
                                            )}
                                            onChange={(event) => { editRecommendationSearch(event, props.history, props.recommendationType); getSelectedSeachField(event) }}
                                            newSelectionPrefix={null}
                                            placeholder={"Search here ..."}
                                            ref={seachField}
                                            />
                                            <span className={`search-icon-div ${openSearchField ? "search-open" : "serach-close"}`}>
                                                {openSearchField ?
                                                    <CloseRoundedIcon onClick={() => { setOpenSearchField(false); setSelectedSeachField([]), clearSearchField() ; props.resetRecommendationFilter(); setSelectedSeachField([]); }} class="search-icon" />
                                                    :
                                                    <SearchOutlinedIcon onClick={() => setOpenSearchField(true)} class="search-icon" />
                                                }
                                            </span>
                                        </form>    
                                    </div>
                                </Box>
                                <TabPanel value="active" sx={{padding: 0, height: '100%'}} >
                                    <Recommendations {...props} />
                                </TabPanel>
                                <TabPanel value="stopped" sx={{padding: 0, height: '100%'}} > 
                                    <Recommendations {...props} />
                                </TabPanel>
                                <TabPanel value="meta" sx={{padding: 0, height: '100%'}} >
                                    <Recommendations {...props} />
                                </TabPanel>
                            </TabContext>
                        </Box>    
                    </div>
                </div>    
            : null}        
        </>
    );
}
export default connect()(TreatmentPlanComponent);
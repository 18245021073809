import React from 'react';
import DatumChange from './DatumChangeCard';
import DatumAverage from './DatumAverage';
import Grade from './Grade';
import HistoricDataKey from './HistoricDataKey';
import HistoricDataChart from '../containers/HistoricDataChartContainer';
import HistoricDataTable from './HistoricDataTable';
import NoumenonDescription from '../containers/NoumenonDescriptionContainer';
import Recommendations from '../containers/RecommendationBlockContainer';
import ValueCard from './ValueCardComponent';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import constants from '../../../config/constants';
import constant from '../../../config/reportConstants';
import labels from '../../../config/localization';
import html2react from 'html-to-react'
import marked from 'marked';
import { getRouteStatus } from '../../../utils/parseJWT';
import { uniformString } from '../tools/helpers'

export default class CategoryDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.renderHistoricData = this.renderHistoricData.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1'
        };
        window.addEventListener('resize', this.props.updateResolution);

    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidUpdate() {
        if (!this.props.isSideBarActive && this.state.activeTab != '1') {
            this.setState({
                activeTab: '1'
            });
        }
    }

    componentDidMount() {
        this.props.updateResolution()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.props.updateResolution)
    }
    renderCloseControl() {
        return (
            <div className='drawer-controls'>
                <div className='icon sm fill'>
                    <button className='button-close' aria-label='Close Account Info Modal Box' onClick={() => {
                        this.props.closeSideBar(this.props.history);
                    }}>&times;</button>
                </div>
            </div>
        );
    }

    renderHeading() {
        return (
            <div className='drawer-heading'>
                <div className='item-detail'>
                    <div className='meta'>
                        <div className='drawer-category'>Category</div>
                        <div className='drawer-title'>{this.props.currentCategory ? this.props.currentCategory.name : ''}</div>
                    </div>
                </div>
            </div>
        );
    }


    renderHistoricData() {
        if (this.props.categoryGradesData && this.props.categoryGradesData.length) {
            let nonBlankValue = item => !!item.value
            let historicNonBlankData = this.props.categoryGradesData ? this.props.categoryGradesData.filter(nonBlankValue) : '';
            if (historicNonBlankData.length >= 2) {
                return (
                    <div className='chart-content'>
                        <HistoricDataChart historic_data={historicNonBlankData} drawerType={this.props.drawerType} {...this.props} type="pointed" />
                    </div>
                );
            }
            else if (historicNonBlankData.length > 0) {
                return (
                    <div className='chart-content chart-content-table'>
                        <HistoricDataTable historic_data={historicNonBlankData} dateFormat={this.props.dateFormat ? this.props.dateFormat : ''} {...this.props} />
                    </div>
                );
            }
        }
    }
    markdown2react(md) {
        marked.setOptions({
            renderer: new marked.Renderer(),
            sanitize: true,
            smartLists: true,
        });

        let parser = new html2react.Parser();
        return (parser.parse(marked(md)));
    }
    actuallyRender() {
        if (this.props.showDrawerWarning) {
            this.toggle('3');
        }
        return (
            <React.Fragment>
                <div id='categorydrawerComponent' className={`drawer ${!this.props.isSideBarActive ? 'drawer-closed' : this.props.isSideBarActive ? 'open-drawer' : 'drawer-closed'}`}>
                    {this.renderCloseControl()}

                    <div className='drawer-body' id='drawer-body'>
                        {this.renderHeading()}
                        <div className='comments'>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>

                                <Nav tabs className='mt-4'>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.activeTab === '1' ? 'active text-secondary' : ''}
                                            onClick={() => { this.toggle('1'); }}  >
                                            {labels.analytics_labels.results_label}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.activeTab === '3' ? 'active text-secondary' : ''}
                                            onClick={() => { this.toggle('3'); }} >
                                            {labels.analytics_labels.recommendation_label}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.activeTab === '2' ? 'active text-secondary' : ''}
                                            onClick={() => { this.toggle('2'); }}>
                                            {labels.analytics_labels.more_info}
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                                <TabContent activeTab={this.state.activeTab} >
                                    <TabPane tabId='1'>
                                        <React.Fragment>
                                            <div className='datum-drawer-spec'>
                                                <div className="drawer-content-data">
                                                    <div className="results">
                                                        <ValueCard datum={this.props.datum} drawerType={this.props.drawerType} {...this.props} />
                                                        <DatumChange datum={this.props.categoryGradesHistoric} changeFrom="baseline" dateFormat={this.props.dateFormat} {...this.props} />
                                                        <DatumChange datum={this.props.categoryGradesHistoric} changeFrom="previous" dateFormat={this.props.dateFormat} {...this.props} />
                                                        {this.props.should_render_population_grades ? <DatumAverage datum={this.props.datum} /> : null}
                                                    </div>
                                                </div>
                                                {this.renderHistoricData()}
                                            </div>
                                        </React.Fragment>
                                    </TabPane>
                                    <TabPane tabId='2'>
                                        {/* {this.props.categories ? this.props.categories.map(s => s.sections.map(t => t.data.map(u => {
                                            if (u.code == this.props.datum.code) {
                                                return (<div className='description-shadow'><div className='moreInfoDescription'><p><b>{u.label}</b></p><p> {u.noumenonExplanation != null ? this.markdown2react(u.noumenonExplanation) : labels.analytics_labels.no_description}</p></div></div>)
                                            }
                                        }))) : ''} */}
                                        {this.props.categories ? this.props.categories.map(p => {
                                            var isRendered = false
                                            if (p.key == this.props.currentCategory.key) {
                                                if (p.passagesDTO != null && p.passagesDTO.length) {
                                                    return p.passagesDTO.map(q => {
                                                        if (q.passageTranslationDTO != null) {
                                                            isRendered = true
                                                            return (<div className='description-shadow'><div className='moreInfoDescription'>
                                                                <b className='healthStatHeadings'>{q.passageTranslationDTO.heading}</b>
                                                                <p className='mt-2'>{this.markdown2react(q.passageTranslationDTO.content)}</p></div></div>)
                                                        }
                                                        else if (!isRendered) {
                                                            isRendered = true
                                                            return <div className='description-shadow'><div className='moreInfoDescription'>{labels.analytics_labels.no_description}</div></div>
                                                        }
                                                    }
                                                    )
                                                }
                                                else if (!isRendered) {
                                                    isRendered = true
                                                    return <div className='description-shadow'><div className='moreInfoDescription'>{labels.analytics_labels.no_description}</div></div>
                                                }
                                            }
                                        }) : ''}
                                    </TabPane>
                                    <TabPane tabId='3' >
                                        <div className='drawer-content-data'>
                                            <div className='padded'>
                                                <Recommendations {...this.props} datum={this.props.currentCategory} drawerType={this.props.drawerType} />
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div >
                    </div>
                </div>
                <div className={`${this.props.isSideBarActive ? 'open-drawer ' : ''}drawer-shade`}></div>
            </React.Fragment>
        )
    }

    render() {
        if (this.props.categoryGradesData && this.props.categoryGradesData.length) {
            return (this.actuallyRender());
        }
        else {
            return (null);
        }
    }
}

import React, { useState,useEffect } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import close from '../../../assets/images/close.svg';
import { connect } from 'react-redux';
import labels from '../../../config/localization';
import closeLight from '../../../assets/images/close-light.svg';

function FeedbackComponent(props) {
    const [rating, setRating] = useState("3");
    
    useEffect(() => {
        props.editFeedbackForm({target: {id: 'rating', value: rating }})
        return () => {
                // Anything in here is fired on component unmount.
        }
    }, [])

    const Rating =
    {
        notes: 1,

    }
    let handleRating = (event) =>{
        setRating(event.target.value)
        props.editFeedbackForm({target: {id: 'rating', value: event.target.value }})
    }

    return (
        <>
            <div className='drawer-warning-overlay'></div>
            <div className='provider-notes feedback'>
                <div className='drawer-warning'>
                    <div className='d-flex row header no-gutters'>
                        <div className='col-12'> <span className='justify-content-center d-flex bold-14-text'>Help us Improve!</span> <a className='justify-content-end d-flex close-icon'><img src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleFeedbackModel(false))}></img></a> </div>
                    </div>
                    <div className='card-body-div'> 
                        {props.feedbackErrors && props.feedbackErrors.length ?
                            <div className='alert alert-danger'>
                                {props.feedbackErrors.map(re => <p>{re}</p>)}
                            </div> : null
                        }
                        <div className="new-notes mt-2">
                            <div className="emoji-container">
                                <form id="emoji" className=''>
                                    <div  className='rating-label'> How would you rate your experience?</div>
                                    <div className='d-flex justify-content-center'>
                                        <input type="radio" value="1" class="sad" checked={['1','2','3','4','5'].includes(rating) ? 'checked' : "" } onChange={ handleRating }/>
                                        <input type="radio" value="2" class="confused" checked={['2','3','4','5'].includes(rating) ? 'checked' : "" } onChange={ handleRating } />
                                        <input type="radio" value="3" class="neutral" checked={['3','4','5'].includes(rating) ? 'checked' : "" } onChange={ handleRating } />
                                        <input type="radio" value="4" class="happy" checked={['4','5'].includes(rating) ? 'checked' : "" } onChange={ handleRating }  />
                                        <input type="radio" value="5" class="love" checked={['5'].includes(rating) ? 'checked' : "" } onChange={ handleRating } />
                                    </div>
                                </form>
                            </div>
                            <FormControl type="textarea" id= "feedback_text" label={'Tell us how can we improve (Optional)'} onChange={props.editFeedbackForm} defaultValue={props.feedbackForm && props.feedbackForm.feedback_text ? props.feedbackForm.feedback_text : ""} inputClass={"mt-2 text-area-note-height"} placeholder={"Write here.."} />
                            <div className='d-flex row'>
                                <div className='col-lg-8 col-md-8 col-sm-8 col-12'></div>
                                <div className='col-lg-4 col-md-4 col-sm-4 col-12 d-flex justify-content-end'>
                                    <button className='cancle-btn btn mr-3' onClick={() => { props.toggleFeedbackModel(false) }}>{labels.analytics_labels.cancel}</button>
                                    <button className='add-btn btn' onClick={() => { props.submitFeedback() }}> Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </>
    )
}
export default connect()(FeedbackComponent);
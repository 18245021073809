import React from 'react';
import ReactDom from 'react-dom';
import Sprite from './SpriteIcon';
import constants from '../../../config/constants';
import { uniformString } from '../tools/helpers';
import moment from 'moment';
import { getDateFormat } from '../tools/helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import labels from '../../../config/localization';

class HistoryRecommendations extends React.Component {
    constructor(props) {
        super(props);
    }
    instruction(recommendation) {
        if (recommendation.instructions && recommendation.instructions.trim()) {
            return (
                <p className="recommendation-instructions">
                    <strong>Instructions:</strong> {recommendation.instructions}
                </p>
            )
        }
        else {
            return (null);
        }
    }

    notes(rec) {
        // remove show_notes flag
        if (rec.notes && rec.notes.trim()) {
            return (
                <div className="recommendation-notes">
                    <p>
                        <strong>Notes:</strong>
                        &nbsp;
                        {rec.notes}</p>
                </div>
            )
        }
        else {
            return (null);
        }
    }
    renderDetailsRow(rec) {
        return (
            <div className="jw-recommendation-details" key={rec.id + '-details'} id={rec.id + '-details'}>
                {this.instruction(rec)}
                {this.notes(rec)}
            </div>
        );
    }
    renderStoppedRecommendationInfo(rec) {
        return (
            <div className="jw-recommendation-details" key={rec.id + '-details'} id={rec.id + '-details'}>
                <div className="recommendation-notes">
                    {'Recommendation was recommended at ' + moment(rec.createdAt).format(getDateFormat(this.props.presentPatient.datePreference)) + ' and provider has stopped at ' + moment(rec.stoppedAt).format(getDateFormat(this.props.presentPatient.datePreference))}
                </div>
            </div>
        );
    }
    render() {
        const columns = [
            {
                dataField: 'group',
                text: `Group`,
                sort: true,
                headerStyle: { width: '6%' },
                editable: false,
                formatter: (cellContent, row) => (
                    <div className="jw-icon" title={row.group}>
                        <Sprite group="recommendations" label={row.group} sprite={uniformString(row.group)} />
                    </div>
                )
            },
            {
                dataField: 'body',
                text: `Body`,
                sort: true,
                editable: false,
                formatter: (cellContent, row) => (
                    <div>
                        <div className="">{row.body + " " + (row.body.includes(row.strength) ? "" : row.strength ? row.strength : '')}</div>
                        { row.brand ? <>
                            <b>{labels.analytics_labels.brand_ingredient}: </b> 
                                {row.brand}</> : null 
                        }
                        { row.recommendationStrengthType ? <>
                            <b>{labels.analytics_labels.howStronglyRecommended}:</b> 
                                {row.recommendationStrengthType}</> : null 
                        }
        
                        {row.culturedNoumena ? <div>
                            <p key="" className="" >
                                (based on your <b>{row.culturedNoumena}</b>)</p>
                        </div> : null}
                    </div>
                )
            },
            {
                dataField: 'visitDate', headerStyle: { width: '9%' }, text: `Visit Date`, sort: true, editable: false, formatter: (cellContent, row) =>
                    <div>
                        <div>{moment(cellContent).format('L')}</div>
                        <div>{`(${row.timePoint})`}</div>
                    </div>
            },
            { dataField: 'notes', text: `Notes`, sort: true, editable: false },
            { dataField: 'instructions', text: `Instructions`, sort: true, editable: false },
            {
                dataField: 'previousRecommendation', text: `Previous Recommendation`, sort: false, editable: false, formatter: (cellContent, row) =>
                    <div className="">
                        {row.previousRecommendation && row.previousRecommendation.group != '' && row.group != row.previousRecommendation.group ? <div><b>Group: </b> {row.previousRecommendation.group}</div> : ''}
                        {row.previousRecommendation && row.previousRecommendation.body != '' && row.body != row.previousRecommendation.body ? <div><b>Body: </b> {row.previousRecommendation.body}</div> : ''}
                        {row.previousRecommendation && row.previousRecommendation.notes != '' && row.notes != row.previousRecommendation.notes ? <div><b>Notes: </b> {row.previousRecommendation.notes}</div> : ''}
                        {row.previousRecommendation && (row.previousRecommendation.instructions != '' && row.previousRecommendation.instructions != null) && row.instructions != row.previousRecommendation.instructions ? <div><b>Instructions: </b> {row.previousRecommendation.instructions}</div> : ''}
                        {row.previousRecommendation && row.isStopped == 0 && row.previousRecommendation.isStopped == 1 && row.previousRecommendation.stoppedAt ? <div><b>Resumed: </b> This recommendation was stopped at {moment(row.previousRecommendation.stoppedAt).format(getDateFormat(this.props.presentPatient.datePreference))} and resumed at {moment(row.createdAt).format(getDateFormat(this.props.presentPatient.datePreference))}</div> : ''}
                        {row.nextRecommendation && row.nextRecommendation.createdAt ? <div><b>(until {moment(row.nextRecommendation.createdAt).format(getDateFormat(this.props.presentPatient.datePreference))})</b></div> : ''}
                        {row.previousRecommendation && row.previousRecommendation.startedAt ? <div><b>Started: </b> {moment(row.previousRecommendation.startedAt).format(getDateFormat(this.props.presentPatient.datePreference))}</div> : ''}
                        {row.previousRecommendation && row.previousRecommendation.endedAt ? <div><b>Ended: </b> {moment(row.previousRecommendation.endedAt).format(getDateFormat(this.props.presentPatient.datePreference))}</div> : ''}
                        {/* {row.isStopped == 1 && row.stoppedAt ? <div>(This recommendation was stopped at {moment(row.stoppedAt).format(getDateFormat(this.props.presentPatient.datePreference))}. Please resume if you want patients to continue.)</div> : ''} */}
                    </div>
            },
            {
                dataField: 'createdAt', headerStyle: { width: '8%' }, text: `Started On`, sort: true, editable: false, formatter: (cellContent, row) => <><span>{cellContent ? moment(cellContent).format('L') : ''}</span>
                    {/* {row.isStopped == 0 && row.startedAt ? <div><center>(Started)</center></div> : ''} */}
                    {/* <div>{row.isStopped == 1 && row.stoppedAt ? <div>(This recommendation was stopped. Please resume if you want patients to continue.)</div> : ''}</div> */}
                </>
            },
            {
                dataField: 'stoppedAt', headerStyle: { width: '8%' }, text: `Stopped On`, sort: true, editable: false, formatter: (cellContent, row) => <><span>{cellContent ? moment(cellContent).format('L') : ''}</span>
                    {row.isStopped == 1 && row.stoppedAt ? <div><center>(Stopped)</center></div> : ''}
                    {/* <div>{row.isStopped == 1 && row.stoppedAt ? <div>(This recommendation was stopped. Please resume if you want patients to continue.)</div> : ''}</div> */}
                </>
            },
            {
                dataField: 'stoppedAt', headerStyle: { width: '4%' }, text: ``, sort: false, editable: false, formatter: (cellContent, row) => <div>{cellContent ?
                    <div className="icon md fill clickable" title='This recommendation was stopped. Please resume if you want patients to continue.' onClick={() => {
                        this.props.toggleStopRecommendationPopup(true, row.id, 'historic', row);
                    }}>
                        <i className="fa fa-play-circle analytics-blue" style={{ fontSize: "21px", lineHeight: "1.7rem" }}></i>
                    </div> : ''}</div>,
                hidden: this.props.role != 'patient' ? false : true

            }
        ]
        return ReactDom.createPortal(
            <>
                <div className="overlay_styles" onClick={() => (this.props.toggleHistoryRecommendationPopup(false))} />
                <div id="historic_recommendations">
                    <div className="card history_recommend_styles historyRecommendFormStyle questionnaireFormStyle">
                        <div className="card-header surveyHeader d-flex align-items-center">
                            <h3>Prior Recommendations</h3>
                            <div className="recm-history-search ml-4">
                                <input type="text" id="searchRecm" value={this.props.currentSearchRecm && this.props.currentSearchRecm.searchRecm ? this.props.currentSearchRecm.searchRecm : ''} onChange={this.props.setRecommendationSearchFilters} placeholder="Search.." />
                            </div>
                            <i class="fa fa-close historyRecommendCloseIcon" onClick={() => (this.props.toggleHistoryRecommendationPopup(false))}></i>
                        </div>
                        <div className="card-body">
                            <BootstrapTable striped={true} bordered={false} hover
                                bootstrap4 keyField='guId'
                                data={this.props.filteredRecommendations}
                                columns={columns}
                            />
                            {/* {this.props.filteredRecommendations && this.props.filteredRecommendations.length > 0 ? this.props.filteredRecommendations.map((rec) => {
                                return (
                                    <>
                                        <div className="jw-recommendation-card jw-recommendation-flex" key={rec.id} data-id={rec.id}>
                                            <div className="jw-icon">
                                                <Sprite group="recommendations" label={rec.group} sprite={uniformString(rec.group)} />
                                            </div>
                                            <div className="jw-recommendation-content">
                                                <div className="jw-recommendation-body">{rec.body}</div>
                                                {rec.culturedNoumena ? <div className="jw-recommendation-details" key={rec.id + '-details'} id={rec.id + '-details'}>
                                                    <p key="ages" className="recommendation-indicator" >
                                                        (based on your <b>{rec.culturedNoumena}</b>)</p>
                                                </div> : null}
                                                {rec.isStopped != 1 ? this.renderDetailsRow(rec) : this.renderStoppedRecommendationInfo(rec)}
                                            </div>
                                        </div>
                                    </>
                                )
                            }) : "No previous Recommendations available"} */}
                        </div>
                    </div>
                </div>
            </>,
            document.getElementById('surveyPortal')
        )
    }

}

export default HistoryRecommendations;
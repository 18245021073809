import ApiCollection from './ApiCollection'
import { connect } from 'react-redux';
import { getApiCollection } from '../../reducers/global-reducer';

const mapStateToProps = state => ({
  ...state.global
});

const mapDispatchToProps = {
  getApiCollection
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApiCollection);

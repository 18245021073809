import React from 'react';
import grader from '../tools/grader.js'
export default class Grade extends React.Component {
  letter() {
    return grader(this.props.points)
  }

  htmlClass() {
    var out = this.letter();
    if (out) {
      return ('grade-' + out.replace('+', 'p').replace('-', 'm').replace('/', ''));
    }
  }

  render() {
    return (
      <div className={this.htmlClass() + ' grade-block' + (this.props.isReportCard ? ' report-card-grade' : '')}>
        {this.letter()}
      </div>
    )
  }
}

import analyticsInfo from './analyticsInfo';
import analyticsInfo2 from './analyticsInfo2';
import comparingChartsData from './comparingChartsData';
import historicCategoryInfo from './historicCategoryInfo';
import patientInfo from './patientInfo';
import providerData from './providerData';
import recommendationsInfo from './recommendationsInfo';
import allRecommendationsInfo from './allRecommendationsInfo';
import siteData from './siteData';
import termTranslations from './termTranslations';
import visitsData from './visitsData';
import sampleNewRecommendation from './sampleNewRecommendation';
import latestVisitInfo from './latestVisitInfo';
import ordersInfo from './ordersInfo';
import categoriesInfo from './categoriesInfo';
import passagesData from './passagesInfo';
import followUpData from './followUpData';
import historicCategoryInfo2 from './historicCategoryInfo2';
import documentsInfo from './documentsInfo';
import documentsList from './documentsList';
// Analytics UI

import providerNotes from './providerNotes';
import sectionData from './sectionData';
import noumenonReferenceInfo from './noumenonReferenceInfo';
import problemsInfo from './problemsInfo';

export default {
    analyticsInfo,
    comparingChartsData,
    historicCategoryInfo,
    patientInfo,
    providerData,
    recommendationsInfo,
    siteData,
    termTranslations,
    visitsData,
    sampleNewRecommendation,
    latestVisitInfo,
    ordersInfo,
    categoriesInfo,
    passagesData,
    followUpData,
    historicCategoryInfo2,
    analyticsInfo2,
    documentsInfo,
    documentsList,
    allRecommendationsInfo,
    providerNotes,
    sectionData,
    noumenonReferenceInfo,
    problemsInfo
}
export default [
    {
        title: 'Narrow, with value',
        ranges: [
            {
                title: 'Three Ranges',
                src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Not+infected+seronegative,cls:O,rng:0.0-0.91%7Clbl:Infected+seropositive,cls:D,rng:1-5%7Clbl:High+Antibody+level,cls:D,rng:5-20&value=0.30&style=narrow'
            },

            {
                title: 'Four Ranges / One Line'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Anorexia,cls:D,rng:%3C39%7Clbl:Healthy,cls:H,rng:39-91%7Clbl:Obese,cls:D,rng:91-140%7Clbl:Morbid+Obesity,cls:C,rng:%3E140&value=67.2&units=kg&style=narrow'
            },
            {
                title: 'Four Ranges / Two Lines'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Optimal,cls:O,rng:%3C8%7Clbl:Healthy,cls:H,rng:8-28%7Clbl:Borderline,cls:B,rng:28-35%7Clbl:Central+Hypertension,cls:D,rng:%3E35&value=25&units=%25&style=narrow'
            },
            {
                title: 'Four Ranges / Two Lines / Lots Of Text'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Decreased+coronary+blood+flow,cls:C,rng:50-130%7Clbl:Deconditioned,cls:B,rng:130-180%7Clbl:Healthy,cls:H,rng:180-220%7Clbl:Athletic,cls:O,rng:220-350&value=180&units=%25&style=narrow'
            },
            {
                title: 'Five Ranges / One Line'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Athletic,cls:O,rng:33-55%7Clbl:Healthy,cls:H,rng:55-72%7Clbl:Deconditioned,cls:B,rng:72-82%7Clbl:Unhealthy,cls:D,rng:82-100%7Clbl:Tachycardia,cls:C,rng:100-310&value=55&units=bpm&style=narrow'
            },
            {
                title: 'Five Ranges / Two Lines'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Very+Low,cls:C,rng:%3C70%7Clbl:Low,cls:D,rng:70-79%7Clbl:Low+Average,cls:B,rng:80-89%7Clbl:Healthy,cls:H,rng:90-110%7Clbl:Optimal,cls:O,rng:%3E110&value=119&style=narrow'
            },
            {
                title: 'Six Ranges / Two Lines'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Stiff+Arteries+or+Dehydration,cls:B,rng:%3C65%7Clbl:Optimal,cls:O,rng:65-75%7Clbl:Healthy,cls:H,rng:75-85%7Clbl:Borderline+Hypertension,cls:B,rng:85-90%7Clbl:Hypertension,cls:D,rng:90-120%7Clbl:Severe+Hypertension,cls:C,rng:120-130&value=77&units=mm+Hg&style=narrow'
            },
            {
                title: 'Six Ranges / Plus & Minus'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Critical,cls:C,rng:30-40%7Clbl:Significant+loss,cls:D,rng:40-55%7Clbl:Moderate+Aging,cls:D,rng:55-70%7Clbl:Mild+aging,cls:B,rng:70-80%7Clbl:Youthful,cls:H,rng:80-90%7Clbl:Optimal,cls:O,rng:90-100&value=51&units=%25&style=narrow'
            }
        ]
    },
    {
        title: 'Thick, no value',
        ranges: [
            {
                title: 'Three Ranges'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Not+infected+seronegative,cls:O,rng:0.0-0.91%7Clbl:Infected+seropositive,cls:D,rng:1-5%7Clbl:High+Antibody+level,cls:D,rng:5-20'
            }

            , {
                title: 'Four Ranges / One Line'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Anorexia,cls:D,rng:%3C39%7Clbl:Healthy,cls:H,rng:39-91%7Clbl:Obese,cls:D,rng:91-140%7Clbl:Morbid+Obesity,cls:C,rng:%3E140'
            }

            , {
                title: 'Four Ranges / Two Lines'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Optimal,cls:O,rng:%3C8%7Clbl:Healthy,cls:H,rng:8-28%7Clbl:Borderline,cls:B,rng:28-35%7Clbl:Central+Hypertension,cls:D,rng:%3E35'
            }

            , {
                title: 'Five Ranges / One Line'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Athletic,cls:O,rng:33-55%7Clbl:Healthy,cls:H,rng:55-72%7Clbl:Deconditioned,cls:B,rng:72-82%7Clbl:Unhealthy,cls:D,rng:82-100%7Clbl:Tachycardia,cls:C,rng:100-310'
            }

            , {
                title: 'Five Ranges / Two Lines'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Very+Low,cls:C,rng:%3C70%7Clbl:Low,cls:D,rng:70-79%7Clbl:Low+Average,cls:B,rng:80-89%7Clbl:Healthy,cls:H,rng:90-110%7Clbl:Optimal,cls:O,rng:%3E110'
            }

            , {
                title: 'Six Ranges / Two Lines'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Stiff+Arteries+or+Dehydration,cls:B,rng:%3C65%7Clbl:Optimal,cls:O,rng:65-75%7Clbl:Healthy,cls:H,rng:75-85%7Clbl:Borderline+Hypertension,cls:B,rng:85-90%7Clbl:Hypertension,cls:D,rng:90-120%7Clbl:Severe+Hypertension,cls:C,rng:120-130'
            }

            , {
                title: 'Six Ranges / Plus & Minus'

                , src: '//artisan.physioage.com/spectrum.svg?ranges=lbl:Critical,cls:C,rng:30-40%7Clbl:Significant+loss,cls:D,rng:40-55%7Clbl:Moderate+Aging,cls:D,rng:55-70%7Clbl:Mild+aging,cls:B,rng:70-80%7Clbl:Youthful,cls:H,rng:80-90%7Clbl:Optimal,cls:O,rng:90-100'
            }

        ]
    }
]
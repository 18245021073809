import React from 'react';
import PropTypes from 'prop-types';
import sprite from '../../../assets/images/sprite_base.svg'
import Nav from '../components/Nav';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
const VisitBreadcrumb = (props) => {
  if (props.lastVisit) {
    return (
      <div className='header-breadcrumb visit-breadcrumb '>
        <div className='patient-name'>
          {props.lastVisit}
        </div>
        <div className='patient-name'>
          {labels.analytics_labels.most_recent_visit}
        </div>
      </div>
    )
  }
  else {
    return (null);
  }
}

const PatientBreadcrumb = (props) => {
  return (
    <div className='header-breadcrumb patient-breadcrumb'>
      <div className='patient-name'>
        {props.patient.firstname} {props.patient.lastname}
      </div>
      <div className='patient-age'>
        {props.patient.gender}, {props.age} {labels.analytics_labels.years_old}
      </div>
    </div>
  )
}
export default class TopNav extends React.Component {
  static prop_types = {
    brand: PropTypes.string.isRequired,
    dataset: PropTypes.array.isRequired,
    exit_button_path: PropTypes.string.isRequired,
    exit_button_text: PropTypes.string.isRequired,
    patient: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  componentDidMount() {
    var nav = document.getElementById('site-logo-top-nav');
    if (nav) {
      nav.style.display = 'none';
    }
    var topNav = document.getElementById('topNav');
    if (topNav) {
      topNav.style.boxShadow = 'none';
    }
    Events.scrollEvent.register('begin', function () {
      // console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function () {
      // console.log('end', arguments);
    });
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }
  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });

      scroller.scrollTo('scroll-container', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      })
    );
  }
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  optional_vendor_brand() {
    return (this.props.brand == 'Generic' ? 'vendor' : null);
  }

  categoryLink(category) {
    return (
      <a key={category.code + '_nav'} className='scrollable' href={'#' + category.code + '_category'}>
        {category.name}
      </a>
    );
  }

  renderCategories() {
    return (
      <nav className='secondary'>
        <div className='max-container'>
          {this.props.dataset.map(this.categoryLink)}
        </div>
      </nav>
    );
  }

  render() {
    let last_visit = this.props.patient && this.props.patient.visits && this.props.patient.visits[this.props.patient.visits.length - 1];
    return (

      <div className={`${this.props.siteData ? `main-nav analytics-menu ${this.props.siteData.logoFile ? 'vendor' : ''}`
        : ''} ${this.props.splashVisibility ? "header-nav-position" : ""}`} id='topNav'>
        <nav className='primary'>
          <div className='max-container'>
            <Link className='scrollable brand-logo' to='splash' spy={true} smooth={true} duration={500} offset={-45}>
              {this.props.siteData ? <img id='site-logo-top-nav' className='nav-logo' src={this.props.siteData.bannerLogoFile
                ? `data:${this.props.siteData.bannerLogoContentType};base64,${this.props.siteData.bannerLogoFile}`
                : (this.props.siteData.logoFile ? this.props.masthead_path_logo : this.props.masthead_path_noLogo)} /> : ''}
            </Link>
            <VisitBreadcrumb lastVisit={this.props.lastVisitDate} dateFormat={this.props.dateFormat} patient={this.props.presentPatient} />
            <PatientBreadcrumb patient={this.props.presentPatient} age={this.props.age_round} />
            <a onClick={() => {
              if (this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.PT)
                this.props.logOut(this.props.history)
              else if (this.props.isDemo)
                window.location.href = 'https://physioage.com/'
              else
                this.props.exitAnalytics(this.props.presentPatient.id, this.props.history)
            }}
              className='btn-exit'>
              <svg aria-hidden='true' title='Exit Analytics'>
                {/* <use xlinkHref={`${sprite}#exit`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
                <use xlinkHref="#exit" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                <symbol id="exit" viewBox="0 0 16 16">
                  <polygon points="11,12.414 15.414,8 11,3.586 9.586,5 11.586,7 5,7 5,9 11.586,9 9.586,11 "></polygon>
                  <path d="M12,14H3V2h9V0H2C1.448,0,1,0.448,1,1v14c0,0.552,0.448,1,1,1h10V14z"></path>
                </symbol>
              </svg>
              {this.props.isDemo
                ? labels.analytics_labels.exit_demo
                : this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.PT
                  ? labels.analytics_labels.logOut : labels.analytics_labels.exit_Analytics}
            </a>
            <a className='button-menu' onClick={() => this.props.toggleMenu()}>
              <label className={`${this.props.siteData ? `mobile-nav-toggle-btn ${this.props.siteData.logoFile ? 'vendor' : ''}`
                : ''}`} htmlFor='mobile-nav-toggle-input'>
                <span>{labels.analytics_labels.menu}</span>
              </label>
            </a>
          </div>
        </nav>
      </div>
    );
  }
}

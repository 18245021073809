import React from 'react';
import labels from '../../../config/localization';

export default class ProviderComment extends React.Component {
  render() {
    return (
      <div className='provider_feedback'>
        <div className='provider_avatar_container'>
          <img src='/assets/raffaelemd.jpg' className='provider_avatar_image' />
        </div>
        <textarea className='feedback_copy'></textarea>
        <button className='btn btn-primary'>{labels.analytics_labels.submit_comments}</button>
      </div>
    )
  }
}

import React, { Component } from 'react';

import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import PageTitle from '../../../components/PageTitle';
import { Typeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';

class RubricForm extends Component {
  constructor(props, context) {
    super(props, context)
  }
  componentDidMount() {
      if (this.props.match.params && this.props.match.params.siteId && this.props.match.params.code && this.props.match.params.rubric_id) {
        this.props.setCurrentRubric(this.props.match.params.code, this.props.match.params.siteId,  this.props.match.params.rubric_id)
        this.props.setCurrentSiteDetails(this.props.match.params.siteId, 'rubric_form_edit', this.props.history)
      }
      else {
        if (this.props.match.params && this.props.match.params.siteId && this.props.match.params.code) {
          this.props.setCurrentSiteDetails(this.props.match.params.siteId, 'rubric_form_new', this.props.history)
          this.props.setCurrentRubric(this.props.match.params.code, this.props.match.params.siteId)
        }
      }
  }

  saveRubric = () => {
    let noFormChanges = this.checkForChanges();
    if (!noFormChanges) {
      this.props.saveRubric(this.props.history)
    }
  }

  checkForChanges = () => {
    let initialCurrentRubric = this.props.initialCurrentRubric ? { ...this.props.initialCurrentRubric } : {};
    let currentRubric = {}
    if (this.props.currentRubric) {
      currentRubric["gender"] = this.props.currentRubric["gender"];
      currentRubric["phase"] = this.props.currentRubric["phase"];
      currentRubric["startingAge"] = this.props.currentRubric["startingAge"];
      currentRubric["endingAge"] = this.props.currentRubric["endingAge"];
      currentRubric["startingHeight"] = this.props.currentRubric["startingHeight"];
      currentRubric["endingHeight"] = this.props.currentRubric["endingHeight"];
    }
    Object.keys(currentRubric).forEach((p) => currentRubric[p] == null || currentRubric[p] == undefined || currentRubric[p] == "" ? delete currentRubric[p] : '');
    Object.keys(initialCurrentRubric).forEach((p) => initialCurrentRubric[p] == null || initialCurrentRubric[p] == undefined || initialCurrentRubric[p] == "" ? delete initialCurrentRubric[p] : '');
    if (_.isEqual(currentRubric, initialCurrentRubric)) {
      this.props.showNoFormChangesErrorRubric(true);
      return true;
    }
    else
      return false;

  }

  render() {
    return (
      <> { !this.props.basicPlan ?
      <>
        {this.props.currentRubric && this.props.breadCrumbs ?
          <>
            <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
            <form className='provider-form container col-lg-9' onSubmit={(e) => { e.preventDefault(); this.saveRubric() }} >
              <div className='col-lg-12 row'>
                <div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
                  {this.props.rubricErrors && this.props.rubricErrors.length ?
                    <div className='alert alert-danger'>
                      {this.props.rubricErrors.map(re => <p>{re.error}</p>)}
                    </div> : null
                  }
                  <FormControl id='gender' type='select' label={labels.noumena_labels.gender}
                    options={constants.gender_lower} onChange={this.props.editRubricForm}
                    textKey={'text'} valueKey={'value'}
                    value={this.props.currentRubric && this.props.currentRubric.gender
                        ? (this.props.currentRubric.gender) : null} required={true}
                    nodefaultOption={true}  />

                  <FormControl
                    value={this.props.currentRubric && this.props.currentRubric.phase ?
                          this.props.currentRubric.phase : []}
                    type='select' id='phase'
                    options={constants.phase}
                    label={labels.custom_noumenon_list.phase} onChange={this.props.editRubricForm}
                    textKey={'text'} valueKey={'value'}
                    ref={this.phase} nodefaultOption={true} />

                  <FormControl
                    ref={ref => this.startingAge = ref}
                    id='startingAge' label={labels.custom_noumenon_list.startingAge }
                    defaultValue={ this.props.currentRubric && this.props.currentRubric.startingAge
                        ? parseInt(this.props.currentRubric.startingAge) : null}
                    type='number' onBlur={this.props.editRubricForm} min={0} max={100} />

                  <FormControl
                    ref={ref => this.endingAge = ref}
                    id='endingAge' label={labels.custom_noumenon_list.endingAge}
                    defaultValue={this.props.currentRubric && this.props.currentRubric.endingAge
                        ? parseInt(this.props.currentRubric.endingAge) : null}
                    type='number' onBlur={this.props.editRubricForm}
                    min={this.props.currentRubric && this.props.currentRubric.startingAge ? this.props.currentRubric.startingAge : 0 } 
                    max={100} />

                  <FormControl
                    ref={ref => this.startingHeight = ref}
                    id='startingHeight' label={labels.custom_noumenon_list.startingHeight }
                    defaultValue={ this.props.currentRubric && this.props.currentRubric.startingHeight
                        ? this.props.currentRubric.startingHeight : null}
                    type='number' onBlur={this.props.editRubricForm} min={0} max={200} />

                  <FormControl
                    ref={ref => this.endingHeight = ref}
                    id='endingHeight' label={labels.custom_noumenon_list.endingHeight}
                    defaultValue={this.props.currentRubric && this.props.currentRubric.endingHeight
                        ? parseInt(this.props.currentRubric.endingHeight) : null}
                    type='number' onBlur={this.props.editRubricForm}
                    min={this.props.currentRubric && this.props.currentRubric.startingHeight ? this.props.currentRubric.startingHeight : 0 }
                    max={200} />
                  <button className="btn btn-success">Save</button>

                </div>
              </div>

            </form>
          </>
          : ""}
      </>
      : window.location.replace('/statuscode/404') }
      </>
    )
  }
}
export default RubricForm
import React, { Component } from 'react';
import moment from 'moment';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls';

class ResolveMessage extends Component {
    componentDidMount() {
        this.props.getSites()
        if (this.props.match.params && this.props.match.params.messageId) {
            this.props.setCurrentMessage(this.props.match.params.messageId)
            this.props.setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.inbox_labels.resolve_title, path: '' }
            ])
            this.props.setPageTitle(labels.inbox_labels.resolve_title)
            document.title = `${labels.physioage_label}: ${labels.inbox_labels.resolve_title}`
        }

    }
    render() {
        return (
            <div className='resolve-message-section container col-lg-9 row'>
                <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                    <div>
                        {this.props.resolveMessage ?
                            <div className='msg-details card'>
                                <div className='card-header'>
                                    {labels.inbox_labels.msg_details}
                                </div>
                                <div className='table'>
                                    <tr><td>{labels.inbox_labels.from_label}</td><td>{this.props.resolveMessage.from}</td></tr>
                                    <tr><td>{labels.inbox_labels.date_label}</td><td>{ this.props.resolveMessage.visit ? moment(this.props.resolveMessage.visit).format('MMMM Do YYYY, h:mm:ss a'): ""}</td></tr>
                                    <tr><td>{labels.inbox_labels.name_label}</td><td>{this.props.resolveMessage.patient}</td></tr>
                                    <tr><td>{labels.inbox_labels.dob_label}</td><td>{moment(this.props.resolveMessage.dob).format('L')}</td></tr>
                                </div>
                            </div> : null
                        }
                        <div className='d-flex justify-center'>
                            <FormControl type='button'
                                btnType='primary' onClick={() => this.props.launchDemoModal()}
                                text={labels.inbox_labels.demo_modal} />
                        </div>
                    </div>
                </div>
                <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12'>
                    <FormControl label={labels.inbox_labels.site_label} type='select' options={this.props.sites} textKey='name' valueKey='id' />
                    <div className={`modal fade ${this.props.isDemoOpen ? 'show' : ''}`} role='dialog'>
                        <div className='modal-dialog'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <span><h3>{labels.inbox_labels.launch_demo_header}</h3></span>
                                    <i className='fa fa-times' onClick={() => this.props.launchDemoModal()}></i>
                                </div>
                                <div className='modal-body'>
                                    {this.props.resolveMessage ? this.props.resolveMessage.body : ''}
                                </div>
                                <div className='modal-footer'>
                                    <FormControl type='button' btnType='default' text={labels.inbox_labels.close_label} onClick={() => this.props.launchDemoModal()} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ResolveMessage
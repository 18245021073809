import { connect } from 'react-redux'
import SignupPaymentGateway from './SignupPaymentGateway';
import {setPageTitle, setBreadCrumbs, toggleNotification, editSignupPaymentDetails, paymentDetailsSubmissionError, toggleSignupRendering, registerSignUpUser, toggleLoading } from '../../reducers/global-reducer';

const mapStateToProps = (state) => ({
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    currentSignupPaymentDetails: state.global.currentSignupPaymentDetails,
    isLoading: state.global.isLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify,
    toggleSignupRender: state.global.toggleSignupRender
})

const mapDispatchToProps = {
    setPageTitle,
    setBreadCrumbs, 
    editSignupPaymentDetails,
    toggleNotification,
    paymentDetailsSubmissionError,
    toggleSignupRendering,
    registerSignUpUser,
    toggleLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupPaymentGateway);

import React, { useRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Loader from '../../../components/Loader';
import constants from '../../../config/constants'
import Sortable from 'sortablejs'
import labels from '../../../config/localization';
import ProblemRow from './ProblemRow';


function Problems(props) {
    useEffect(() => {
      if (props.loggedInUser &&
        (props.loggedInUser.role == constants.logged_roles.AN
          || props.loggedInUser.role == constants.logged_roles.CG)
        && props.problems && props.problems.length > 1) {
        let simpleList = document.getElementById('simpleList')
        if (!simpleList)
          return
        Sortable.create(simpleList, {
          animation: 150,
          // handle: '.handle',
          onUpdate: () => {
            // props.savePriorities()
            // props.toggleResetPriority()
          }
        })
      }
        // componentDidMount //
        return () => {
        // componentWillUnmount() //
        }
    }, [])

    let isNullOrEmpty = (value) => {
        return (value === null || value === "");
    }

    let problems = () => {
       if(props.problemType == "active") {
            return (props.filterProblems.length > 0 ? props.filterProblems : props.activeProblems && props.activeProblems.length > 0 ? props.activeProblems : [])
        }
        else if(props.problemType == "inactive") {
            return (props.filterProblems.length > 0 ? props.filterProblems : props.inactiveProblems && props.inactiveProblems.length > 0 ? props.inactiveProblems : [])
        }
        else if(props.problemType == "unknown") {
            return (props.filterProblems.length > 0 ? props.filterProblems : props.unknownProblems && props.unknownProblems.length > 0 ? props.unknownProblems : [])
        }
        else{
            return([])
        }
    } 


    let renderProblems = (list) => {
        return (
        list.map(
            (problem, index) => {
            return (
                <ProblemRow problem={problem} {...props} index={index} />
            )
            }
        )
        );
    }

    let renderGroup = (problems) => {
        if (problems.length > 0) {
        return (
            <>
                <div className={`recommendation-list ${props.showProblemModal ? "overflow-hidden" : ""}`}  id="simpleList">
                {renderProblems(problems)}
                </div>
            </>  
        )
        }
        else {
        return null;
        }
    }

    return (
        <>
            {
            props.isPriorityLoader && <center><i class="fa fa-circle-o-notch fa-spin fa-lg ml-4 priorityLoader"></i></center>
            }
            { props.isProblemsLoading ? <center><i className='fa fa-spinner fa-spin pt-4'></i></center> :
        
                problems() && problems().length > 0 ? renderGroup(problems()) :
                    props.problemType == "inactive" ||  props.problemType == "unknown" || (props.problems && props.problems.length > 0 && props.problemType == "active") ?
                        <div className='row justify-content-center p-3'>
                            <div className='col-11'>
                                <div class="card no-meta-recommendations">
                                <div class="card-body">{`No ${props.problemType} Problems exists`}</div>
                                </div>
                            </div>  
                        </div> 
                    : (props.problems && props.problems.length == 0 && props.problemType == "active")  ?
                        <div className="instructions no-recommendations pt-3">
                            <div className="card-content card-content-container">
                                { props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ? 
                                    <>
                                        <p>You have not entered any problems.</p>
                                        <p>To enter a problem tied to this Visit - Click on <b>Add Problems</b> above.</p><br />
                                        <p>Problems will be visible to patients in this section and on the Visit Summary PDF.</p>
                                    </>
                                    : <p> Problems added for you by the provider will be listed in this section.</p>
                                }
                            </div>
                        </div>
                    : ""   
                }
        </>
    )
}  

export default connect()(Problems);


import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormControl from '../../../components/FormControls/FormControl';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller
} from 'react-scroll';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import _ from 'lodash';
import reportConstants from '../../../config/reportConstants';
import Grade from '../../Analytics/components/GradeFunctional';
import logo from '../../../assets/images/patientReportLogo.png';
// import '../../Analytics/components/Analytics.scss'
import ReactHighcharts from 'react-highcharts';
import HealthStatusIndicators from './HealthStatusIndicatorsFunctional';
import IndividualVisit from './IndividualVisit';
import labels from '../../../config/localization';
import constant from '../../../config/constants';
import { CalculateChange } from '../../Analytics/tools/calendar'
import enLabels from '../../../config/en_labels';
import marked from 'marked';
import html2react from 'html-to-react';
import ResultsData from './ResultsDataFunctional';
import { getLocalizedDate } from '../../../utils/reports-date-localization';
import constants from '../../../config/constants';
import report_constants from '../../../config/reportConstants'
import getHistoricalChartUrl from '../../../utils/historical-chart-util';
import { getRoundedValue, IsInRange } from '../../Analytics/tools/helpers';
import IndividualReportRecommendation from './IndividualReportRecommendationFunctional';
import IndividualReportProblem from './IndividualReportProblem';


function PatientReportDownloadTemplateFunctional(props) {
    var toc = []
    const [tableOfContents, pushTableOfContents] = useState([]);
    useEffect(() => {
        if (props.match && props.match.params && props.match.params.visitId && !props.currentVisit.id) {
            props.setCurrentVisit(props.match.params.visitId, props.history, true)
            // props.getAllCategories(props.match.params.visitId, false)
        } else if (props.latestVisitId) {
            props.setCurrentVisit(props.latestVisitId, props.history)
            // props.getAllCategories(props.latestVisitId)
        }
        // this.reqNum = 0
        // this.pagenumbersDictionary = {}
        // this.previousOffset = 0
    }, [])

    let markdown2react = (md) => {
        marked.setOptions({
            renderer: new marked.Renderer(),
            sanitize: true,
            smartLists: true,
        });

        let parser = new html2react.Parser();
        return (parser.parse(marked(md)));
    }

    let getTermTranslations = (terms, key) => {
        return terms ? _.head(terms.filter(s => s.key == key)).termTranslationsDTOList.text : ''
    }
    let returnBiomarkerAgePassage = (passagesData, ageName) => {
        return (passagesData.filter(s => s.key.toLowerCase() == ageName.toLowerCase()).map(s => s.passagesDTO.map(t => {
            if (t.passageTranslationDTO != null && t.passageTranslationDTO.content != null) {
                return <div className="passage">
                    {/* {["Description", "Descriere", "説明"].indexOf(t.passageTranslationDTO.heading) == -1 ? <h3 className="passage-heading"><b>{t.passageTranslationDTO.heading}</b></h3> : " "} */}
                    {markdown2react(t.passageTranslationDTO.content)}
                </div>
            }
        })))
    }
    let renderExplaination = (passageData) => {
        return <div className="passage">
            {markdown2react(passageData)}
        </div>
    }
    let renderCategoryGrade = (category, visitId) => {
        const reportlabels = reportConstants[props.currentVisit.reportPreference]
        var reportCards = []
        category.map(p => {
            if (p.sections.length > 0) {
                p.sections.map(s => {

                    let currentVisitsData = s.data.filter(t => t.visitId == visitId
                        && !(t.points === undefined || t.points == null || isNaN(t.points)))

                    if (currentVisitsData.length > 0) {
                        var points = _.sum(currentVisitsData.map(csd => csd.points)) / currentVisitsData.length
                        reportCards.push(<div className='grade-card'>
                            <Grade points={points} isReportCard={true} />
                            <React.Fragment>
                                <div className='description'>{s.name}</div>
                                <div className='reference'><a href={`#${s.key}`}>{reportlabels.report_labels.readmore}</a></div>
                            </React.Fragment>
                        </div>)
                    }
                })
            }

        })
        return reportCards;
    }
    let getPointColor = (aggAge, age) => {
        if (aggAge) {
            if (aggAge > age)
                return 'RED'
            else if (aggAge < age)
                return 'GREEN'
        }
        return 'GREY'
    }
    let getAgeData = (ageName) => {
        return props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == ageName && s.value != "0").map(s => s.value) : '';
    }
    let getBaselineChart = (ageName) => {
        let age =  props.currentAggregateAge ? props.currentAggregateAge[ageName] : []
        let patientAge = props.currentVisit ? props.currentVisit.patientVisitAge : ''
        const plotLines = [];
        for (let i = 0; i <= 100; i += 5) {
            plotLines.push(
            {
                color: '#bbb',
                value: i,
                width: 1,
                zIndex: 3
            }
            );
        }
        let options = {
            chart: {
              type: 'scatter',
              animation: false,
              width: 320,
              height: 250
            },
            title: {
              text: 'Physiological Age vs Chronological Age'
            },
            xAxis: {
              title: {
                enabled: true,
                text: 'Chronological Age (years)'
              },
              startOnTick: true,
              endOnTick: true,
              showLastLabel: true,
              max: 100,
              min: 0,
              plotLines: plotLines,
              
            },
            yAxis: {
              title: {
                text: 'Physiological Age (years)'
              },
              startOnTick: true,
              endOnTick: true,
              showLastLabel: true,
              max: 100,
              min: 0,
              plotLines: plotLines
            },
            plotOptions: {
              scatter: {
                marker: {
                  radius: 5,
                  states: {
                    hover: {
                      enabled: true,
                      lineColor: 'rgb(100,100,100)'
                    }
                  }
                },
                states: {
                  hover: {
                    marker: {
                      enabled: false
                    }
                  }
                },
                tooltip: {
                  headerFormat: '',
                  pointFormat: `Patient Age: {point.x} years <br> ${props.culturedBiomarkers[ageName]} Age: {point.y} years`
                }
              }
            },
            series: [{
              color: '#36c',
              data: [[patientAge, parseFloat(age[0])]]
            }]
          };
        return (
            <div className='react-high-chart'> 
                <ReactHighcharts
                config={options}
                />
            </div>
        );
        // return <img className='baseline-chart' src={`http://chart.apis.google.com/chart?cht=s&chd=t:${props.currentVisit.patientVisitAge},0,100|${getAgeData(ageName)},0,100|100,0,0&chg=5,5,1,0,0&chco=${getPointColor(getAgeData(ageName), props.currentVisit.patientVisitAge)}&chxt=x,y,x,y&chxr=0,0,100,20|1,0,100,20&chs=320x250&chf=c,ls,135,FFFFFF,0.40,EEEEEE,0.2,FFFFFF,0.4&chxl=2:|Chronological Age|3:|Physiological Age|&chxp=2,50|3,50&chm=D,999999,0,1:2,1,-1`} />
    }
    let ageCalculation = (ageName) => {
        const labels = reportConstants[props.currentVisit.reportPreference]
        var ageData = getAgeData(ageName) //props.followUpData ? Math.round(props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == ageName && s.value != "0").map(s => s.value)) : '';
        if (ageData) {
            var chronological = props.currentVisit.patientVisitAge;
            var physiological = ageData;
            if (!physiological)
                return (labels.report_labels.measured_recently)
            else {
                var diff = getRoundedValue(chronological - physiological, 1);
                if (physiological > chronological)
                    return (`${props.terms['Your']} ${props.culturedBiomarkers[ageName]} ${props.terms[enLabels.report_labels.is_label]} ${physiological}, 
                    ${props.terms['6 years older than'].replace(props.terms['6 years older than'][0], Math.abs(diff))} ${props.terms['than average for your age']}`);
                else if (physiological < chronological)
                    return (`${props.terms['Your']} ${props.culturedBiomarkers[ageName]} ${props.terms[enLabels.report_labels.is_label]} ${physiological},
                    ${props.terms['7 years younger than'].replace(props.terms['7 years younger than'][0], diff)} ${props.terms['than average for your age']}`);
                else {
                    return (`${labels.report_labels.your_label} ${props.culturedBiomarkers[ageName]} ${labels.report_labels.is_label} ${physiological}, ${props.terms['neither older nor younger']}`);
                }
            }
        }
        else {
            return <div className="warning-caution"><h3><i className="warn warning"></i> {props.terms[enLabels.report_labels.test_not_performed]}</h3></div>
        }
    }
    let getBiomakersConfig = (rowData, graphVisitData, xAxisData) => {
        if (rowData) {
            return {
                chart: {
                    type: 'spline',
                    animation: false,
                    width: 320,
                    height: 250
                },
                xAxis: {
                    categories: xAxisData ? xAxisData : '',
                    tickInterval: 1,
                    plotLines: [{
                        color: 'black',
                        width: 2,
                        value: -0.5
                    }]
                },
                plotOptions: {
                    series: {
                        lineWidth: 2,
                        animation: false,
                        connectNulls: true,
                        marker: {
                            radius: 3
                        }
                    }
                },
                yAxis: {
                    value: 20,

                    tickInterval: 20,
                },
                series: [
                    {
                        data: rowData ? rowData.map(s => parseFloat(s)) : '',
                        color: '#36c'
                    },
                    {
                        data: graphVisitData ? graphVisitData : '',
                        color: '#bbb'
                    }
                ]
            };
        }
    }
    let getAbnormalHeaders = (showGrade, isBaseline) => {
        const abnormalResultHeaders = []
        if (showGrade)
            abnormalResultHeaders.push(props.terms[enLabels.report_labels.grade_label])
        abnormalResultHeaders.push(props.terms[enLabels.report_labels.name_label])
        abnormalResultHeaders.push(props.terms[enLabels.report_labels.result_label])
        abnormalResultHeaders.push(props.terms[enLabels.report_labels.units_label])
        //abnormalResultHeaders.push(props.terms[enLabels.report_labels.normal_range])
        abnormalResultHeaders.push("Lab Ref Range")
        abnormalResultHeaders.push(props.terms[enLabels.report_labels.optimal_range])
        if (!isBaseline) {
            abnormalResultHeaders.push(props.terms[enLabels.report_labels.baseline_label])
            abnormalResultHeaders.push(props.terms[enLabels.report_labels.change])
        }
        return abnormalResultHeaders;
    }
    let isReportCardExist = (category, visitId) => {
        let allData = []
        if (category)
            category.map(c => {
                c.sections.map(sec => sec.data.map(d => allData.push(d)))
            })
        allData = allData.filter(ad => ad.visitId == visitId && ad.points !== undefined && ad.points != null && !isNaN(ad.points))
        return allData.length ? true : false
    }
    let getFullName = (detail) => {
        return detail ? `${detail.providerHonorificPrefix ? `${detail.providerHonorificPrefix} ` : ''}${detail.providerName}${detail.providerHonorificSuffix ? `, ${detail.providerHonorificSuffix}` : ''}` : null
    }
    let renderRecommendations = () => {
        if (Object.keys(props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><strong>{props.terms['Your Recommendations']}</strong> <span className='rec-provider-name'>{props.terms['from_recommending_provider'].replace('%{provider_name}', getFullName(props.currentVisit))}</span></h3>
                    {props.patientReportCurrentRecommendations.map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...props} key={i} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;

    }
    let renderStoppedRecommendations = () => {
        if (Object.keys(props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><strong>Stopped Recommendations</strong> <span className='rec-provider-name'>{props.terms['from_recommending_provider'].replace('%{provider_name}', getFullName(props.currentVisit))}</span></h3>
                    {props.patientReportStoppedRecommendations.map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...props} key={i} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;

    }
    let renderMedications = () => {
        if (Object.keys(props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><strong>Medications</strong> <span className='rec-provider-name'>{props.terms['from_recommending_provider'].replace('%{provider_name}', getFullName(props.currentVisit))}</span></h3>
                    {props.patientReportCurrentRecommendations.filter(s => constants.medication_groups.includes(s.group)).map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...props} key={i} isMedication={true} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;
    }
    let renderStoppedMedications = () => {
        if (Object.keys(props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><strong>Stopped Medications</strong> <span className='rec-provider-name'>{props.terms['from_recommending_provider'].replace('%{provider_name}', getFullName(props.currentVisit))}</span></h3>
                    {props.patientReportStoppedRecommendations.filter(s => constants.medication_groups.includes(s.group)).map(
                        (recommendation, i) => {
                            return (
                                <IndividualReportRecommendation {...recommendation}{...props} key={i} isMedication={true} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;
    }
    let renderProblems = () => {
        if (Object.keys(props.currentVisit).length > 0) {
            return (
                <div className="patient-recom-pdf">
                    <h3 className='complete-col-span'><b>Your Problems List </b><span className='rec-provider-name'>{props.terms['from_recommending_provider'].replace('%{provider_name}', getFullName(props.currentVisit))}</span></h3>
                    {props.patientReportProblems.map(
                        (problem, i) => {
                            return (
                                <IndividualReportProblem problem={problem}{...props} key={i} />
                            )
                        }
                    )}
                </div>
            );
        }
        else
            return null;
    }
    let addToTableOfContents = (ff, isHealthStatusIndicators, props) => {
        if (isHealthStatusIndicators) {
            {
                props.categories ?
                    props.categories.map(
                        s => s.sections.map((p) => {
                            if (p.data.filter(props.patientReportDownloadTemplatePast ? q => q.value : q => q.visitId == props.currentVisit.id).length > 0) {
                                tableOfContents.push(p.key)
                            }
                        })) : ''
            }
        }
        else {
            tableOfContents.push(ff);
        }
        if (props && !props.patientReportLoaded && !isHealthStatusIndicators) {
            props.isPatientReportLoaded(true)
            props.addToTableOfContentsArray(tableOfContents)
        }
    }
    const historicColumns = [
        {
            dataField: 'label',
            text: props.terms[enLabels.report_labels.name_label],
            formatter: (cellContent, row) => (<span className='table-name-break'>{row.label}</span>)
        },
        {
            dataField: 'value',
            text: props.terms[enLabels.report_labels.result_label],
            formatter: (cellContent, row) => {
                return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => {
                    return s.value === null
                        ? "No-value " : <span className='spanForAlignment'>{(s.value && s.valueType == constants.numerical_value_type)
                            ? parseFloat(s.value).toFixed(s.scale) : s.value}</span>
                }) : ''}</span>
            },
        },
        {
            dataField: 'visitDate',
            text: props.terms[enLabels.report_labels.visit_date],
            formatter: (cellContent, row, index) => {
                return <span>{row.historicData != null && row.historicData.length ? row.historicData.map(s => { return !isNaN(new Date(s.visitDate).getDate()) ? <span className='spanForAlignment'>{moment(s.visitDate).format('L')}</span> : 'No-Date' }) : ''}</span>
            },
        },
    ]
    var localeLabels = Object.keys(props.currentVisit).length ? report_constants[props.currentVisit.patientLanguagePref].report_labels : ''
    return (
        props.terms && props.currentVisit && Object.keys(props.currentVisit).length > 0 && props.passagesData ?
            <div>{
                props.currentVisit && props.currentAggregateAge && props.graphVisitData ?
                    <div className='titlepage' id='patientReportDownloadTemplate'> 
                        <div id="main-toc"> 
                            <div className='coverpage' id='coverpage'>
                                <div className='reportImg'>
                                    <img src={props.currentVisit.logoFile
                                        ? `data:${props.currentVisit.logoContentType};base64,${props.currentVisit.logoFile}`
                                        : props.physioageLogo}></img>
                                </div>
                                <div className='titlepage'>
                                    <div className='title'>{props.terms[enLabels.report_labels.comprehensive_health_analysis]}</div>
                                    <div className='subtitle'>{props.currentVisit.timePoint.toLowerCase() != 'baseline' ? props.currentVisit.timePoint.replace('Follow-Up', props.terms['Follow-Up']) : props.terms['Baseline']}</div>
                                </div>
                                <div className='cover-page-details row'>
                                    <div className='patient-identification'>
                                        <div className='title-block'>
                                            <div className='small-line'>
                                                {props.terms[enLabels.report_labels.prepared_for]}
                                            </div>
                                            <div className='line'>
                                                {props.currentVisit.honorificPrefix} {props.currentVisit.firstname} {props.currentVisit.lastname}{props.currentVisit.honorificSuffix ? `, ${props.currentVisit.honorificSuffix}` : ''}
                                            </div>
                                        </div>
                                        <div className='title-block'>
                                            <div className='small-line'>
                                                {props.terms[enLabels.report_labels.by_provider]}
                                            </div>
                                            <div className='line'>
                                                {props.currentVisit.providerHonorificPrefix} {props.currentVisit.providerName}{props.currentVisit.providerHonorificSuffix ? `, ${props.currentVisit.providerHonorificSuffix}` : ''}
                                            </div>
                                        </div>
                                        <div className='title-block'>
                                            <div className='small-line'>
                                                {props.terms[enLabels.report_labels.tests_performed]}
                                            </div>
                                            <div className='line'>
                                                {getLocalizedDate(props.currentVisit.date, props.currentVisit.reportPreference, props.currentVisit.datePreference)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='reports-powered-by'>
                                        <div>{props.terms[enLabels.report_labels.powered_by]}</div>
                                        <img src={props.poweredByLogo} />
                                    </div>
                                </div>
                            </div>
                            <p className='break-page-before'></p>
                            <div className='toc' id='toc'>
                                <div className='chapter-heading' style={{ paddingTop: 20 }}>
                                    {props.terms[enLabels.report_labels.table_contents]}
                                </div>
                                <ul id='contents' className='contents'>
                                    {props.followUpData && props.followUpData.filter(s => s.value != "0" && s.timePoint == props.currentVisit.timePoint).length > 0 ?
                                        <>
                                            <li className="flexSpaceBetween">
                                                <a className="xref paddingRight" href='#introduction'>{props.terms[enLabels.report_labels.introduction]}</a>
                                                <span class="paddingLeft" id="##introduction">0</span>
                                            </li>
                                            <hr class="dottedLine" />
                                        </> : ''}
                                    {props.completeData && props.completeData.length > 0
                                        && isReportCardExist(props.categories, props.currentVisit.id) ?
                                        <>
                                            <li className="flexSpaceBetween">
                                                <a className="xref paddingRight" href='#report-card'>
                                                    {props.terms[enLabels.report_labels.report_card_label]}
                                                </a>
                                                <span class="paddingLeft" id="##report-card">0</span>
                                            </li>
                                            <hr class="dottedLine" />
                                        </> : ''}
                                    {props.followUpData && props.followUpData.filter(s => s.value != "0" && s.timePoint == props.currentVisit.timePoint).length > 0 ?
                                        <li>
                                            <li className="flexSpaceBetween">
                                                <a className="xref paddingRight" href='#boa'>
                                                    {props.terms[enLabels.report_labels.biomarkers_of_aging_label]}</a>
                                                <span class="paddingLeft" id="##boa">0</span>
                                            </li>
                                            <hr class="dottedLine" />
                                            {props.culturedBiomarkers ? Object.keys(props.culturedBiomarkers).map(s =>
                                                <ul>
                                                    <li className="flexSpaceBetween"><a className="xref paddingRight" href={`#${s}`}>{props.culturedBiomarkers[s]}</a><span class="paddingLeft" id={`##${s}`}>0</span></li>
                                                    <hr class="dottedLine" />
                                                </ul>
                                            ) : ''}
                                        </li>
                                        : ''}
                                    {props.followUpData && props.followUpData.filter(s => s.value != "0" && s.timePoint == props.currentVisit.timePoint).length > 0 ?
                                        <>
                                            <li className="flexSpaceBetween">

                                                <a className="xref paddingRight" href='#comparing_systems'>{props.terms[enLabels.report_labels.compare_system]}</a>
                                                <span class="paddingLeft" id="##comparing_systems">0</span>
                                            </li>
                                            <hr class="dottedLine" />
                                        </>
                                        : ''}
                                    {props.completeData && props.completeData.length > 0 ?
                                        <li>
                                            <li className="flexSpaceBetween">
                                                <a className="xref paddingRight" href='#health_status_indicators'>{props.terms[enLabels.report_labels.health_status_indicator]}</a>
                                                <span class="paddingLeft" id="##health_status_indicators">0</span>
                                            </li>
                                            <hr class="dottedLine" />
                                            <ul>
                                                {props.categories ? props.categories.map(s => s.sections.map(t =>
                                                    t.data.filter(u => u.visitId == props.currentVisit.id).length > 0 ?
                                                        <><li className='linkForHealth flexSpaceBetween'><a className="xref paddingRight" href={`#${t.key}`}>{t.name}</a> <span class="paddingLeft" id={`##${t.key}`}>0</span></li>
                                                            <hr class="dottedLine" /></>
                                                        : '')) : ''}
                                            </ul>
                                        </li> : ''}
                                    {props.patientReportRecommendations && props.patientReportRecommendations.length > 0 ?
                                        <>
                                            <li className="flexSpaceBetween">
                                                <a className="xref paddingRight" href='#patient_treatment_plan'>{props.terms['Treatment Plan']}</a>
                                                <span class="paddingLeft" id="##patient_treatment_plan">0</span>
                                            </li>
                                            <hr class="dottedLine" />
                                        </> : ''}
                                    {props.patientReportRecommendations && props.patientReportRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ?
                                        <>
                                            <li className="flexSpaceBetween">
                                                <a className="xref paddingRight" href='#patient_medications'>Medications</a>
                                                <span class="paddingLeft" id="##patient_medications">0</span>
                                            </li>
                                            <hr class="dottedLine" />
                                        </> : ''}
                                    {props.patientReportProblems && props.patientReportProblems.length > 0 ?
                                            <>
                                                <li className="flexSpaceBetween">
                                                    <a className="xref paddingRight" href='#patient_problems'>Problems List</a>
                                                    <span class="paddingLeft" id="##patient_problems">0</span>
                                                </li>
                                                <hr class="dottedLine" />
                                            </> : ''}
                                    {props.exceptionalData && props.exceptionalData.length > 0 ?
                                        <><li className="flexSpaceBetween"><a className="xref paddingRight" href='#exceptional_results'>{props.terms[enLabels.report_labels.exceptional_results]}</a><span class="paddingLeft" id="##exceptional_results">0</span></li>
                                            <hr class="dottedLine" /></> : ''}
                                    {props.abnormalData && props.abnormalData.length > 0 ?
                                        // <li><a className="xref" href='#abnormal_results'>{props.terms[enLabels.report_labels.abnormal_results]}</a></li> : ''}
                                        <>
                                            <li className="flexSpaceBetween">
                                                <a className="xref paddingRight" href='#abnormal_results'>{props.terms['results_of_concern']}</a>
                                                <span class="paddingLeft" id="##abnormal_results">0</span>
                                            </li>
                                            <hr class="dottedLine" />
                                        </> : ''}
                                    {props.completeData && props.completeData.length > 0 ?
                                        <>
                                            <li className="flexSpaceBetween">
                                                <a className="xref paddingRight" href='#complete_data'>{props.terms[enLabels.report_labels.complete_data]}</a>
                                                <span class="paddingLeft" id="##complete_data">0</span>
                                            </li>
                                            <hr class="dottedLine" />
                                        </> : ''}
                                    {/* {props.currentVisit.timePoint != constant.baseline
                                    && props.categories && props.categories.length ?
                                    <>
                                        <li className="flexSpaceBetween">
                                            <a className="xref paddingRight" href='#historic_data'>{props.terms[enLabels.report_labels.historical_data]}{this.getPageNumberFromOffset('historic_data', true, 'complete_data')}</a>
                                            <span class="paddingLeft" id="##historic_data">0</span>
                                        </li>
                                        <hr class="dottedLine" />
                                    </>
                                    : ''} */}
                                    <>
                                        <li className="flexSpaceBetween"><a className="xref paddingRight" href='#disclaimer'>{localeLabels.disclaimer}</a><span class="paddingLeft" id="##disclaimer">0</span></li>
                                        <hr class="dottedLine" />
                                    </>
                                </ul>
                            </div>
                        </div>
                        <div id="other-toc">
                            {props.followUpData && props.followUpData.filter(s => s.value != "0" && s.timePoint == props.currentVisit.timePoint).length > 0 ?
                                <div className='chapter two-columns-layout' id='introduction'>
                                    <h1 className='chapter-heading'>{props.terms[enLabels.report_labels.introduction]}{addToTableOfContents("introduction")}</h1><span className="headerId">##introduction</span>
                                    <div className='passage'>
                                        {props.currentVisit.timePoint == constant.baseline ? <center className='baseline-image'><img src={props.baselineGraph} alt="Failed to load image"></img></center> : ''}
                                        {props.currentVisit.timePoint != constant.baseline ?
                                            <React.Fragment>
                                                <p>{returnBiomarkerAgePassage(props.passagesData, "followup_welcome_section")}</p>
                                                <p>{returnBiomarkerAgePassage(props.passagesData, "understanding_follow_up_biomarker_diagrams_section")}</p>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <p>{returnBiomarkerAgePassage(props.passagesData, "baseline_welcome_section")}</p>
                                                <p>{returnBiomarkerAgePassage(props.passagesData, "baseline_illustrations_section")}</p>
                                                <p>{returnBiomarkerAgePassage(props.passagesData, "aging_happens_over_time_section")}</p>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div> : ''}
                            {props.completeData && props.completeData.length > 0
                                && isReportCardExist(props.categories, props.currentVisit.id) ?
                                <div className='chapter two-columns-layout' id='report-card'>
                                    <h1 className='chapter-heading'>{props.terms[enLabels.report_labels.report_card_label]}{addToTableOfContents("report-card")}</h1><span className="headerId">##report-card</span>
                                    <div className='copy palette palette_default'>
                                        <div className='grade-card'>
                                            {renderCategoryGrade(props.categories, props.currentVisit.id)}
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            {props.followUpData && props.followUpData.filter(s => s.value != "0" && s.timePoint == props.currentVisit.timePoint).length > 0 ?
                                <div className='chapter two-columns-layout' id='boa'>
                                    <h1 className='chapter-heading'>{props.terms[enLabels.report_labels.biomarkers_of_aging_label]}{addToTableOfContents("boa")}</h1><span className="headerId">##boa</span>
                                    <div className='copy'>
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'physioAge' && s.value != "0").length > 0 ?
                                            <div className='section'>
                                                <h2 className='section-heading' id='physioAge'>{props.culturedBiomarkers[enLabels.report_labels.physioage]}	{props.terms[enLabels.report_labels.composite]}{addToTableOfContents("physioAge")}</h2><span className="headerId">##physioAge</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.physioAge : ''
                                                            , props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div>
                                                    : getBaselineChart('physioAge')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('physioAge')}</b>
                                                </p>
                                                {returnBiomarkerAgePassage(props.passagesData, "physioage_composite_section")}

                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'truAge_trudiag' && s.value != "0").length > 0 ?
                                            <div className='section' id='truAge_trudiag'>
                                                <h2 className='section-heading'>{props.culturedBiomarkers['truAge_trudiag']}{addToTableOfContents("truAge_trudiag")}</h2><span className="headerId">##truAge_trudiag</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.truAge_trudiag : '', props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div> : getBaselineChart('truAge_trudiag')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('truAge_trudiag')}</b>
                                                </p>
                                                {renderExplaination(_.head(props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'truAge_trudiag' && s.value != "0")).noumenaExplaination)}
                                                {/* {this.returnBiomarkerAgePassage(props.passagesData, "physioage_composite_section")} */}
                                            </div> : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'epigenAge' && s.value != "0").length > 0 ?
                                            <div className='section'>
                                                <h2 className='section-heading' id='epigenAge'>{props.culturedBiomarkers[enLabels.report_labels.epigenage]}{addToTableOfContents("epigenAge")}</h2><span className="headerId">##epigenAge</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.epigenAge : ''
                                                            , props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div>
                                                    : getBaselineChart('epigenAge')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('epigenAge')}</b>
                                                </p>
                                                {renderExplaination(_.head(props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'epigenAge' && s.value != "0")).noumenaExplaination)}
                                                {returnBiomarkerAgePassage(props.passagesData, "epigenage_section")}
                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'phenoAge_levine' && s.value != "0").length > 0 ?
                                            <div className='section' id='phenoAge_levine'>
                                                <h2 className='section-heading'>{props.culturedBiomarkers['phenoAge_levine']}{addToTableOfContents("phenoAge_levine")}</h2><span className="headerId">##phenoAge_levine</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.phenoAge_levine : '', props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div> : getBaselineChart('phenoAge_levine')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('phenoAge_levine')}</b>
                                                </p>
                                                {/* {renderExplaination(_.head(props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'phenoAge_levine' && s.value != "0")).noumenaExplaination)} */}
                                                {returnBiomarkerAgePassage(props.passagesData, "phenoage_section")}
                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'glycanAge' && s.value != "0").length > 0 ?
                                            <div className='section' id='glycanAge'>
                                                <h2 className='section-heading'>{props.culturedBiomarkers['glycanAge']}{addToTableOfContents("glycanAge")}</h2><span className="headerId">##glycanAge</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.glycanAge : '', props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div> : getBaselineChart('glycanAge')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('glycanAge')}</b>
                                                </p>
                                                {renderExplaination(_.head(props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'glycanAge' && s.value != "0")).noumenaExplaination)}
                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'telomerAge' && s.value != "0").length > 0 ?
                                            <div className='section'>
                                                <h2 className='section-heading' id='telomerAge'>{props.culturedBiomarkers[enLabels.report_labels.telomerage]}{addToTableOfContents("telomerAge")}</h2><span className="headerId">##telomerAge</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.telomerAge : ''
                                                            , props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div>
                                                    : getBaselineChart('telomerAge')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('telomerAge')}</b>
                                                </p>
                                                {returnBiomarkerAgePassage(props.passagesData, "telomerage_section")}

                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'immunoAge' && s.value != "0").length > 0 ?
                                            <div className='section'>
                                                <h2 className='section-heading' id='immunoAge'>{props.culturedBiomarkers[enLabels.report_labels.immunoage]}{addToTableOfContents("immunoAge")}</h2><span className="headerId">##immunoAge</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.immunoAge : ''
                                                            , props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div>
                                                    : getBaselineChart('immunoAge')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('immunoAge')}</b>
                                                </p>
                                                {returnBiomarkerAgePassage(props.passagesData, "immunoage_section")}

                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'cardioAge' && s.value != "0").length > 0 ?
                                            <div className='section'>
                                                <h2 className='section-heading' id='cardioAge'>{props.culturedBiomarkers[enLabels.report_labels.cardioage]}{addToTableOfContents("cardioAge")}</h2><span className="headerId">##cardioAge</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.cardioAge : ''
                                                            , props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div>
                                                    : getBaselineChart('cardioAge')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('cardioAge')}</b>
                                                </p>
                                                {returnBiomarkerAgePassage(props.passagesData, "cardioage_section")}
                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'pulmoAge' && s.value != "0").length > 0 ?
                                            <div className='section'>
                                                <h2 className='section-heading' id='pulmoAge'>{props.culturedBiomarkers[enLabels.report_labels.pulmoage]}{addToTableOfContents("pulmoAge")}</h2><span className="headerId">##pulmoAge</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.pulmoAge : ''
                                                            , props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div>
                                                    : getBaselineChart('pulmoAge')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('pulmoAge')}</b>
                                                </p>
                                                {returnBiomarkerAgePassage(props.passagesData, "pulmoage_section")}
                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'neuroAge' && s.value != "0").length > 0 ?
                                            <div className='section'>
                                                <h2 className='section-heading' id='neuroAge'>{props.culturedBiomarkers[enLabels.report_labels.neuroage]}{addToTableOfContents("neuroAge")}</h2><span className="headerId">##neuroAge</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.neuroAge : ''
                                                            , props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div>
                                                    : getBaselineChart('neuroAge')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('neuroAge')}</b>
                                                </p>
                                                {returnBiomarkerAgePassage(props.passagesData, "neuroage_section")}
                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'cutoAge' && s.value != "0").length > 0 ?
                                            <div className='section'>
                                                <h2 className='section-heading' id='cutoAge'>{props.culturedBiomarkers[enLabels.report_labels.cutoage]}{addToTableOfContents("cutoAge")}</h2><span className="headerId">##cutoAge</span>
                                                {returnBiomarkerAgePassage(props.passagesData, "cutoage_quote_section")}
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.cutoAge : ''
                                                            , props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div>
                                                    : getBaselineChart('cutoAge')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('cutoAge')}</b>
                                                </p>
                                                {returnBiomarkerAgePassage(props.passagesData, "cutoage_section")}

                                            </div>
                                            : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'incite_trubiohealth_Age' && s.value != "0").length > 0 ?
                                            <div className='section' id='incite_trubiohealth_Age'>
                                                <h2 className='section-heading'>{props.culturedBiomarkers['incite_trubiohealth_Age']}{addToTableOfContents("incite_trubiohealth_Age")}</h2><span className="headerId">##incite_trubiohealth_Age</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.incite_trubiohealth_Age : '', props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div> : getBaselineChart('incite_trubiohealth_Age')}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('incite_trubiohealth_Age')}</b>
                                                </p>
                                                {renderExplaination(_.head(props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'incite_trubiohealth_Age' && s.value != "0")).noumenaExplaination)}
                                                {/* {returnBiomarkerAgePassage(props.passagesData, "physioage_composite_section")} */}
                                            </div> : '' : ''}
                                        {props.followUpData ? props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'omicmAge' && s.value != "0").length > 0 ?
                                            <div className='section' id='omicmAge'>
                                                <h2 className='section-heading'>{props.culturedBiomarkers['omicmAge']}{addToTableOfContents("omicmAge")}</h2><span className="headerId">##omicmAge</span>
                                                {props.currentVisit.timePoint != constant.baseline ?
                                                    <div className='react-high-chart'>
                                                        <ReactHighcharts config={getBiomakersConfig(props.currentAggregateAge ? props.currentAggregateAge.omicmAge : '', props.graphVisitData ? props.graphVisitData : '', props.xAxisLabels ? props.xAxisLabels : '')} />
                                                    </div> : ''}
                                                <p className='age_summary'>
                                                    <b>{ageCalculation('omicmAge')}</b>
                                                </p>
                                                {renderExplaination(_.head(props.followUpData.filter(s => s.timePoint == props.currentVisit.timePoint && s.label == 'omicmAge' && s.value != "0")).noumenaExplaination)}
                                                {/* {returnBiomarkerAgePassage(props.passagesData, "physioage_composite_section")} */}
                                            </div> : '' : ''}    
                                        {props.followUpData && props.followUpData.filter(s => s.value != "0" && s.timePoint == props.currentVisit.timePoint).length > 0 ?
                                            <div className='section' >
                                                <h2 className='section-heading' id='comparing_systems'>{props.terms[enLabels.report_labels.compare_system]}{addToTableOfContents("comparing_systems")}</h2><span className="headerId">##comparing_systems</span>
                                                {returnBiomarkerAgePassage(props.passagesData, "comparing_your_calculated_ages_section")}
                                                <iframe className='aggregate' src={props.currentFollowUpUrl} />
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                                : ''}


                            {props.completeData && props.completeData.length > 0 ?
                                <div className='chapter' id='health_status_indicators'>
                                    <h1 className='chapter-heading'>{props.terms[enLabels.report_labels.health_status_indicator]}{addToTableOfContents("health_status_indicators")}</h1><span className="headerId">##health_status_indicators</span>
                                    <HealthStatusIndicators addToTableOfContents={addToTableOfContents} {...props}></HealthStatusIndicators> {addToTableOfContents("", true, props)}
                                </div> : ''}
                            {props.patientReportRecommendations && props.patientReportRecommendations.length > 0 ? <div id="patient_treatment_plan">
                                <h1 className='chapter-heading'>{props.terms['Treatment Plan']}{addToTableOfContents("patient_treatment_plan")}</h1><span className="headerId">##patient_treatment_plan</span>
                                {props.patientReportCurrentRecommendations && props.patientReportCurrentRecommendations.length > 0 ? renderRecommendations() : null}
                                {props.patientReportStoppedRecommendations && props.patientReportStoppedRecommendations.length > 0 ? renderStoppedRecommendations() : null}
                            </div> : null}
                            {props.patientReportRecommendations && props.patientReportRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ? 
                                <div id="patient_medications">
                                    <h1 className='chapter-heading'>Medications{addToTableOfContents("patient_medications")}</h1><span className="headerId">##patient_medications</span>
                                    {props.patientReportCurrentRecommendations && props.patientReportCurrentRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ? renderMedications() : null}
                                    {props.patientReportStoppedRecommendations && props.patientReportStoppedRecommendations.filter(s => constants.medication_groups.includes(s.group)).length > 0 ? renderStoppedMedications() : null}
                                </div> 
                            : null}
                            {props.patientReportProblems && props.patientReportProblems.length > 0 ?
                                    <div id="patient_problems">
                                        <h1 className='chapter-heading'>Problems List {addToTableOfContents("patient_problems")}</h1><span className="headerId">##patient_problems</span>
                                        {props.patientReportProblems && props.patientReportProblems.length > 0 ? renderProblems() : null}
                                    </div> 
                            : null}
                            {((props.exceptionalData && props.exceptionalData.length > 0)
                                || (props.abnormalData && props.abnormalData.length > 0))
                                ? <div id='exceptional_results_section'>

                                    {props.exceptionalData && props.exceptionalData.length > 0 ?
                                        <div id='exceptional_results'>
                                            <p className='break-page-before'></p>
                                            <div className='abnormalResult'><h1>{props.terms[enLabels.report_labels.exceptional_results]}{addToTableOfContents("exceptional_results")}</h1><span className="headerId">##exceptional_results</span>
                                                <table><thead className='absolute-result-header'><tr>{getAbnormalHeaders(false, props.currentVisit.timePoint == constant.baseline).map(a => <th>{a}</th>)}</tr></thead></table>
                                                {props.categories ? props.categories.map(
                                                    s => s.sections.map(
                                                        t => (
                                                            t.data.filter(u => u.points >= 3.7 && u.visitId == props.currentVisit.id).length ?
                                                                <div className='patients-report-preview-table'>
                                                                    <div className='complete-col-span'>
                                                                        <div className='categoriesPhysio'><h3>{t.name}</h3></div>
                                                                        <ResultsData
                                                                            baselineVisitId={props.currentVisit.baselineVisitId}
                                                                            showGrade={false}
                                                                            isBaseline={props.currentVisit.timePoint == constant.baseline}
                                                                            data={t ? t.data.filter(s => s.points >= 3.7 && s.visitId == props.currentVisit.id) : ''} />

                                                                    </div>
                                                                </div> : ''))) : null}
                                            </div>
                                        </div> : ''}
                                    {props.abnormalData && props.abnormalData.length > 0 ?
                                        <div id='abnormal_results'>
                                            {/* <div className='abnormalResult'><h1>{props.terms[enLabels.report_labels.abnormal_results]}</h1> */}
                                            <div className='abnormalResult'><h1>{props.terms['results_of_concern']}{addToTableOfContents("abnormal_results")}</h1><span className="headerId">##abnormal_results</span>
                                                <table><thead className='absolute-result-header'><tr>{getAbnormalHeaders(false, props.currentVisit.timePoint == constant.baseline).map(a => <th>{a}</th>)}</tr></thead></table>
                                                {props.categories ? props.categories.map(
                                                    s => s.sections.map(
                                                        p => (
                                                            <div className='patients-report-preview-table'>
                                                                {p.data.filter(s => ((s.points != null && s.points <= 1.3) || (s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s))) && s.visitId == props.currentVisit.id).length > 0 ?
                                                                    <React.Fragment>
                                                                        <div className='complete-col-span'>
                                                                            <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                                            <ResultsData
                                                                                baselineVisitId={props.currentVisit.baselineVisitId}
                                                                                showGrade={false}
                                                                                isBaseline={props.currentVisit.timePoint == constant.baseline}
                                                                                data={p ? p.data.filter(s => ((s.points != null && s.points <= 1.3) || (s.points < 3.7 && s.referenceRange && s.referenceRange != null && !IsInRange(s.referenceRange, s.value, s))) && s.visitId == props.currentVisit.id) : ''} />

                                                                        </div></React.Fragment> : null}
                                                            </div>))) : null}
                                            </div>
                                        </div> : ''}
                                </div> : ''}
                            {props.completeData && props.completeData.length > 0 ?
                                <div id='complete_data'>
                                    <p className='break-page-before'></p>
                                    <div className='completeData'><h1>{props.terms[enLabels.report_labels.complete_data]}{addToTableOfContents("complete_data")}</h1><span className="headerId">##complete_data</span>
                                        <table><thead className='absolute-result-header'><tr>{getAbnormalHeaders(true, props.currentVisit.timePoint == constant.baseline).map(a => <th>{a}</th>)}</tr></thead></table>
                                        {props.categories ? props.categories.map(
                                            s => s.sections.map(
                                                p => (p.data.filter(q => q.visitId == props.currentVisit.id).length > 0 ?
                                                    <>
                                                        <div className='patients-report-preview-table complete-col-span'>
                                                            <div className='categoriesPhysio'><h3>{p.name}</h3></div>
                                                            {p.data.filter(q => q.visitId == props.currentVisit.id && q.valueType == "ST" && q.isTextArea == 1).length > 0 ?
                                                                p.data.filter(q => q.visitId == props.currentVisit.id && q.valueType == "ST" && q.isTextArea == 1).map(noumenon => {
                                                                    return (
                                                                        <div className="pt-2 pb-2 text-color">
                                                                            <div>
                                                                                <div className='fw-10000 mb-2 h5 pb-2'>{noumenon.label}</div>
                                                                            </div>
                                                                            <div className='pb-2 noumenon-value-card'>
                                                                                <div className='p-4 bb'>
                                                                                    <div className='fw-10000 mb-2'>{props.terms[enLabels.report_labels.result_label]}</div>
                                                                                    <div className='ml-3'>{noumenon.value}</div>
                                                                                </div>
                                                                                <div className='pt-2 p-4'>
                                                                                    <div className='fw-10000 mb-2'>{props.terms['Baseline']}</div>
                                                                                    <div className='ml-3'>{noumenon.historicData && noumenon.historicData.length &&
                                                                                        noumenon.historicData.filter(s => s.visitId == props.currentVisit.baselineVisitId).length > 0 ?
                                                                                        _.head(noumenon.historicData.filter(s => s.visitId == props.currentVisit.baselineVisitId)).value : ''}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                })
                                                                : ""}
                                                            {p.data.filter(s => s.visitId == props.currentVisit.id && s.valueType != "ST" && s.isTextArea != 1).length > 0 ?
                                                                <ResultsData
                                                                    baselineVisitId={props.currentVisit.baselineVisitId}
                                                                    showGrade={true}
                                                                    isBaseline={props.currentVisit.timePoint == constant.baseline}
                                                                    data={p.data.filter(s => s.visitId == props.currentVisit.id).filter((q) => q.valueType != "ST" && q.isTextArea != 1)} />
                                                                : ""}
                                                        </div>
                                                    </>
                                                    : "")
                                            )) : null}
                                    </div>
                                </div> : ''}
                            {/* {props.categories && props.categories.length && props.currentVisit.timePoint != constant.baseline ?
                            <div id='historic_data'>
                                <p className='break-page-before'></p>
                                <div className='hdataPhysician d-inline-block'>
                                    <div>
                                        <h1>{props.terms['Historical Data']}{addToTableOfContents("historic_data")}</h1><span className="headerId">##historic_data</span>
                                    </div>
                                    <div>
                                        {getHistoricData()}
                                    </div>

                                </div>
                            </div> : ''} */}

                            {props.passagesData && props.passagesData.length ? <div className='chapter' id='disclaimer'>
                                <h1 className='chapter-heading'>{localeLabels.disclaimer}{addToTableOfContents("disclaimer", false, props)}</h1><span className="headerId">##disclaimer</span>
                                <div className='passage'>
                                    <div className='passage-heading'>{localeLabels.disclaimer_heading}</div>
                                    {returnBiomarkerAgePassage(props.passagesData, "disclaimer_section")}
                                </div>
                            </div> : null}
                        </div></div> : null
            }
            </div >
            : ''
    )
}
export default connect()(PatientReportDownloadTemplateFunctional);
import React from 'react';
import ReactDom from 'react-dom';
import Sprite from './SpriteIcon';
import constants from '../../../config/constants';
import { uniformString } from '../tools/helpers';
import moment from 'moment';
import { getDateFormat } from '../tools/helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import labels from '../../../config/localization';
import { connect } from 'react-redux';
import closeLight from '../../../assets/images/close-light.svg';



function HistoryRecommendations(props){

    const columns = [
        {
            dataField: 'group',
            text: `Group`,
            sort: true,
            headerStyle: { width: '10%' },
            editable: false,
            formatter: (cellContent, row) => (
                <div className="jw-icon" title={row.group}>
                    <Sprite group="recommendations" label={row.group} sprite={uniformString(row.group)} />
                </div>
            )
        },
        {
            dataField: 'body',
            text: `Body`,
            sort: true,
            editable: false,
            headerStyle: { width: '20%' },
            formatter: (cellContent, row) => (
                <div>
                    <div className="">{row.body + " " + (row.body.includes(row.strength) ? "" : row.strength ? row.strength : '')}</div>
                    { row.brand ? <>
                        <b>{labels.analytics_labels.brand_ingredient}: </b> 
                            {row.brand}</> : null 
                    }
                    { row.recommendationStrengthType ? <>
                        <b>{labels.analytics_labels.howStronglyRecommended}:</b> 
                            {row.recommendationStrengthType}</> : null 
                    }
    
                    {row.culturedNoumena ? <div>
                        <p key="" className="" >
                            (based on your <b>{row.culturedNoumena}</b>)</p>
                    </div> : null}
                </div>
            )
        },
        {
            dataField: 'visitDate', headerStyle: { width: '10%' }, text: `Visit Date`, sort: true, editable: false, formatter: (cellContent, row) =>
                <div>
                    <div>{moment(cellContent).format('L')}</div>
                    <div>{`(${row.timePoint})`}</div>
                </div>
        },
        { dataField: 'notes', text: `Notes`, sort: true, editable: false, headerStyle: { width: '15%' }, 
          editable: false, formatter: (cellContent, row) => {
                if (row.notes) {
                    let notes = row.notes
                    if (typeof notes === 'string') {
                        notes = notes.trim()
                        notes = notes.split("\n")
                    }
                    return (
                        <div>
                            { notes.map((note, index) => {
                                return (<div>{`${index + 1}. `} {note}</div>)
                            })}  
                        </div>
                    )
                }
                else {
                    return (null);
                }
            }    
        },
        { dataField: 'instructions', text: `Instructions`, sort: true, editable: false, headerStyle: { width: '15%' } },
        {
            dataField: 'previousRecommendation', text: `Previous Recommendation`, sort: false,  headerStyle: { width: '20%' }, editable: false, formatter: (cellContent, row) =>
                <div className="">
                    {row.previousRecommendation && row.previousRecommendation.group != '' && row.group != row.previousRecommendation.group ? <div><b>Group: </b> {row.previousRecommendation.group}</div> : ''}
                    {row.previousRecommendation && row.previousRecommendation.body != '' && row.body != row.previousRecommendation.body ? <div><b>Body: </b> {row.previousRecommendation.body}</div> : ''}
                    {row.previousRecommendation && (row.previousRecommendation.notes != '' && row.previousRecommendation.notes != null ) && row.notes != row.previousRecommendation.notes ? <div><b>Notes: </b> {row.previousRecommendation.notes}</div> : ''}
                    {row.previousRecommendation && (row.previousRecommendation.notes != '' && row.previousRecommendation.notes != null ) && row.notes != row.previousRecommendation.notes ? <div><b>Notes: </b> {row.previousRecommendation.notes}</div> : ''}
                    {row.previousRecommendation && (row.previousRecommendation.instructions != '' && row.previousRecommendation.instructions != null) && row.instructions != row.previousRecommendation.instructions ? <div><b>Instructions: </b> {row.previousRecommendation.instructions}</div> : ''}
                    {row.previousRecommendation && row.isStopped == 0 && row.previousRecommendation.isStopped == 1 && row.previousRecommendation.stoppedAt ? <div><b>Resumed: </b> This recommendation was stopped at {moment(row.previousRecommendation.stoppedAt).format(getDateFormat(props.presentPatient.datePreference))} and resumed at {moment(row.createdAt).format(getDateFormat(props.presentPatient.datePreference))}</div> : ''}
                    {row.nextRecommendation && row.nextRecommendation.createdAt ? <div><b>(until {moment(row.nextRecommendation.createdAt).format(getDateFormat(props.presentPatient.datePreference))})</b></div> : ''}
                    {row.previousRecommendation && row.previousRecommendation.startedAt ? <div><b>Started: </b> {moment(row.previousRecommendation.startedAt).format(getDateFormat(props.presentPatient.datePreference))}</div> : ''}
                    {row.previousRecommendation && row.previousRecommendation.endedAt ? <div><b>Ended: </b> {moment(row.previousRecommendation.endedAt).format(getDateFormat(props.presentPatient.datePreference))}</div> : ''}
                    {/* {row.isStopped == 1 && row.stoppedAt ? <div>(This recommendation was stopped at {moment(row.stoppedAt).format(getDateFormat(props.presentPatient.datePreference))}. Please resume if you want patients to continue.)</div> : ''} */}
                </div>
        },
        {
            dataField: 'createdAt', headerStyle: { width: '10%' }, text: `Started On`, sort: true, editable: false, formatter: (cellContent, row) => <><span>{cellContent ? moment(cellContent).format('L') : ''}</span>
                {/* {row.isStopped == 0 && row.startedAt ? <div><center>(Started)</center></div> : ''} */}
                {/* <div>{row.isStopped == 1 && row.stoppedAt ? <div>(This recommendation was stopped. Please resume if you want patients to continue.)</div> : ''}</div> */}
            </>
        },
        {
            dataField: 'stoppedAt', headerStyle: { width: '10%' }, text: `Stopped On`, sort: true, editable: false, formatter: (cellContent, row) => <><span>{cellContent ? moment(cellContent).format('L') : ''}</span>
                {row.isStopped == 1 && row.stoppedAt ? <div><center>(Stopped)</center></div> : ''}
                {/* <div>{row.isStopped == 1 && row.stoppedAt ? <div>(This recommendation was stopped. Please resume if you want patients to continue.)</div> : ''}</div> */}
            </>
        },
        {
            dataField: 'stoppedAt', headerStyle: { width: '4%' }, text: ``, sort: false, editable: false, formatter: (cellContent, row) => <div>{cellContent ?
                <div className="icon md fill clickable" title='This recommendation was stopped. Please resume if you want patients to continue.' onClick={() => {
                    props.toggleStopRecommendationPopup(true, row.id, 'historic', row);
                }}>
                    <i className="fa fa-play-circle" style={{ fontSize: "21px", lineHeight: "1.7rem" }}></i>
                </div> : ''}</div>,
            hidden: props.role != 'patient' ? false : true

        }
    ]
    return ReactDom.createPortal(
        <>
            <div className="overlay_styles" onClick={() => (props.toggleHistoryRecommendationPopup(false))} />
            <div id="historic_recommendations" className={`${props.siteTheme} ${props.theme}`}>
                <div className="card history_recommend_styles historyRecommendFormStyle questionnaireFormStyle">
                    <div className="card-header surveyHeader d-flex align-items-center">
                        <h3> {labels.analytics_labels.prior_recommendations}</h3>
                        <div className="recm-history-search ml-4">
                            <input type="text" id="searchRecm" value={props.currentSearchRecm && props.currentSearchRecm.searchRecm ? props.currentSearchRecm.searchRecm : ''} onChange={props.setRecommendationSearchFilters} placeholder="Search.." />
                        </div>
                        {/* <i class="fa fa-close historyRecommendCloseIcon" onClick={() => (props.toggleHistoryRecommendationPopup(false))}></i> */}
                        <a className='historyRecommendCloseIcon' ><img src={closeLight} onClick={() => (props.toggleHistoryRecommendationPopup(false))}></img></a>

                    </div>
                    <div className="card-body">
                        <BootstrapTable striped={true} bordered={false} hover
                            bootstrap4 keyField='guId'
                            data={props.filteredRecommendations}
                            columns={columns}
                        />
                    </div>
                </div>
            </div>
        </>,
        document.getElementById('surveyPortal')
    )
}

export default connect()(HistoryRecommendations);
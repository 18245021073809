import React from 'react';
import DatumRow from '../containers/DatumRowContainer';
import Grade from './Grade';
import { uniformString } from '../tools/helpers';
import _ from 'lodash';
import moment from 'moment';
import constants from '../../../config/constants';
import DatumSparkline from '../containers/DatumSparklineContainer';
import { round } from '../selectors/datasetSelector'
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';

export default class SectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
    this.renderHeading = this.renderHeading.bind(this);
  }
  componentDidMount() {
    if (this.props.grading_data && this.props.hashSectionsDrawer
      && document.getElementById(`${this.props.hashSectionsDrawer}`)) {
      scroller.scrollTo(this.props.hashSectionsDrawer, { offset: -70 })
      document.getElementById(this.props.hashSectionsDrawer).click()
    }
  }

  renderSubheadingClassName(props) {
    var isCurrent = props.section.data.map(t => {
      if (new Date(props.currentVisitDate) <= new Date(t.visitDate) && !constants.labelsForBioInfo.includes(t.code))
        return true;
      else
        return false
    }
    )
    return _.includes(isCurrent, true);
  }

  renderRow(datum) {
    return (
      <DatumRow key={uniformString(datum.code, 'in', this.props.section.name)} datum={datum} section={this.props.section} hashDrawer={this.props.hashDrawer} {...this.props} />
    );
  }
  renderSectionGradeData(grade_data, section) {
    var sectionGrades = []
    grade_data && grade_data.length ?
      grade_data.map(s => {
        s.data.map(t => {
          t.sections.map(u => {
            if (u.key == section.key) {
              u.value = u.value ? round(u.value.toString(), 2) : u.value
              return sectionGrades.push(u)
            }
          })
        })
      }) : ''
    if (sectionGrades && sectionGrades.length)
      return sectionGrades.filter(s => s.value)
  }
  renderHeading() {
    var headingClass = this.renderSubheadingClassName(this.props)
    var sectionGradeData = this.renderSectionGradeData(this.props.grading_data, this.props.section)
    let commentClass = "";
    if (this.props.recommendations) {
      var hasRec = !!this.props.recommendations.find(
        rec => { return (rec.indicators.indexOf(this.props.section.key) >= 0) }
      )
    }
    if (hasRec) {
      commentClass = "btn-reset comment-indicator-section active"
    }
    else {
      commentClass = "btn-reset comment-indicator-section "
    }
    return (
      sectionGradeData && sectionGradeData.length ?
        <div className={headingClass ? 'card-subheading d-flex flex-wrap' : 'card-subheading fade-row d-flex flex-wrap'} id={`${uniformString(this.props.section.key)}-drawer`} onClick={() => {
          this.props.toggleSectionSideBar('section', this.props.history, this.props.section, sectionGradeData, this.props.category)
        }} tabIndex="0">
          <Grade points={this.props.section.points} />
          <div className='title' title={this.props.presentPatient.languagePreference != "en" ? this.props.section.enName : ''}>{this.props.section.name}
          </div>
          <button className={commentClass} title="Recommendation">
            {/* <svg aria-hidden="true">
              <use xlinkHref="#recommendation" xmlnsXlink="http://www.w3.org/1999/xlink"></use>
              <symbol id="recommendation" viewBox="0 0 18 18">
                <path fill="#444444" fill-rule="nonzero" d="M3.617,16.924 C3.24340926,16.7691037 2.99989823,16.4044291 3,16 L3,9 L5,9 L5,13.586 L7.293,11.293 C7.48049273,11.1054506 7.73480519,11.0000566 8,11 L15,11 L15,5 L9,5 L9,3 L16,3 C16.5522847,3 17,3.44771525 17,4 L17,12 C17,12.5522847 16.5522847,13 16,13 L8.414,13 L4.707,16.707 C4.42101445,16.9931653 3.99077762,17.0788179 3.617,16.924 Z" opacity=".365" />
                <polygon fill="#444444" fill-rule="nonzero" points="7 3 5 3 5 1 3 1 3 3 1 3 1 5 3 5 3 7 5 7 5 5 7 5" />
                <circle cx="4" cy="4" r="3" fill="#5BC0DE" stroke="#79E0FF" />
              </symbol>
            </svg> */}
            <i className="fa fa-circle message-dot message-blue-dot-section" aria-hidden="true"></i>
            <svg aria-hidden="true" version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#676060" stroke="#676060" stroke-width="2">
                <path d="M655 4920 c-319 -35 -574 -268 -640 -585 -22 -105 -22 -2645 0 -2750
49 -235 203 -427 419 -525 107 -49 174 -60 389 -67 l197 -6 0 -321 c0 -180 4
-337 10 -356 5 -20 25 -51 45 -70 46 -45 125 -54 180 -21 20 12 253 189 518
394 l482 372 1120 6 c1234 6 1164 2 1311 69 216 98 370 290 419 525 22 105 22
2645 0 2750 -59 280 -264 495 -548 572 -57 16 -207 17 -1947 19 -1037 1 -1916
-2 -1955 -6z m3887 -338 c110 -45 191 -129 240 -251 l23 -56 0 -1315 0 -1315
-23 -56 c-49 -122 -130 -206 -240 -251 l-57 -23 -1155 -5 c-1050 -5 -1158 -7
-1185 -22 -16 -9 -205 -152 -420 -317 l-390 -302 -5 263 c-6 294 -9 311 -80
353 -32 18 -57 20 -325 25 -271 5 -294 7 -347 28 -110 44 -191 129 -240 251
l-23 56 -3 1264 c-1 763 1 1292 7 1333 13 94 59 187 121 248 58 56 90 75 170
101 52 17 158 18 1965 16 l1910 -2 57 -23z"/>
              </g>
            </svg>
          </button>
          {sectionGradeData && sectionGradeData.length ?
            <DatumSparkline className='section-grades' type='pointed' uniqueID={'section_grade_for_' + this.props.section.key} historic_data={sectionGradeData} />
            : ''}
          <div className='controls-grade'>
            <button className='btn-reset view-more'>
              <svg className='fill' aria-hidden='true'>
                {/* <use xlinkHref={`${sprite}#arrow-right`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
                <use xlinkHref="#arrow-right" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                <symbol id="arrow-right" viewBox="0 0 16 16">
                  <path d="M5.108,16.41L3.59,15.108L9.683,8L3.59,0.892L5.108-0.41l6.651,7.76c0.321,0.374,0.321,0.927,0,1.301L5.108,16.41z"></path>
                </symbol>
              </svg>
            </button>

          </div>
        </div> :
        <div className={headingClass ? 'card-subheading' : 'card-subheading fade-row'}>
          <Grade points={this.props.section.points} />
          <div className='title' title={this.props.presentPatient.languagePreference != "en" ? this.props.section.enName : ''}>{this.props.section.name}
          </div>
        </div>

    )
  }

  renderDatum(data) {
    var rows = data.map(this.renderRow)
    var out = null;

    if (rows.length > 0) {
      out =
        <ul className='card-content '>
          {rows}
        </ul>
    }
    return (out);
  }

  render() {
    var content = this.renderDatum(this.props.section.data);

    if (content) {
      return (
        <div id={uniformString(this.props.section.enName, 'section')}>
          {this.renderHeading()}
          {content}
        </div>
      )
    }
    else {
      return (null);
    }
  }
}

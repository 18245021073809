import React, { Component } from 'react';
import labels from '../../../config/localization';
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';
// import ObservationPopUp from '../../Oddities/components/ObservationDetails';
import constants from '../../../config/constants';
import FormControl from '../../../components/FormControls';
import { returnLocalizedDate } from '../../../utils/resolve-timer'

const orderDetails = {
	source: labels.oddities_labels.source_label,
	createdAt: labels.orders_labels.entry_date,
	publicationDate: labels.orders_labels.publication,
	orderedBy: labels.orders_labels.ordered_by,
	panelName: labels.message_routing_labels.panel
}
import moment from 'moment';

class ResultView extends Component {
	componentDidMount() {
		// if (this.props.loggedInUser
		// 	&& ((this.props.loggedInUser.role == constants.logged_roles.AN)
		// 		|| (this.props.loggedInUser.role == constants.logged_roles.CG && this.props.currentVisit.siteId
		// 			&& this.props.loggedInUser.siteId == this.props.currentVisit.siteId))) {
		document.title = `${labels.physioage_label}: ${labels.visit_page_labels.results_title}`;
		this.props.setCalledSetCurrentOrder(true)
		this.props.setCurrentVisit(this.props.match.params.visitId, this.props.history);
		this.props.setCurrentResult(this.props.match.params.resultId, this.props.match.params.visitId, this.props.history);
		// }
		// else {
		// 	this.props.history.push('/')
		// }//this.props.orderDetails(this.props.match.params.resultId)
	}
	componentWillReceiveProps() {
		this.props.setHeaderType(constants.visit_labels.result)
	}
	render() {
		const columns = (row, type) => {
			if (type == 'message') {
				if (row.odditieDTO !== null && row.odditieDTO.category == constants.visit_labels.ignored_value) {
					return <i class="fa fa-info" aria-hidden="true"></i>;
				}
				// else if (row.odditieDTO !== null && row.odditieDTO.category == "Unmatched Identifier") {
				// 	return <i class="fa fa-info" aria-hidden="true"></i>;
				// }
				else if (row.odditieDTO === null && row.datumId != null) {
					return <i className="fa fa-check status-indicator text-success"></i>;
				}
				else {
					return <i className="fa fa-times status-indicator text-danger"></i>
				}

			}
			// ,
			// headerStyle: (colum, colIndex) => {
			// 	return { width: '30px', textAlign: 'center' };
			// }
			else if (type == 'primary') {
				return (
					<a href='javascript:void(0)' onClick={() => this.props.history.push(`/observations/${row.id}`)}>{
						row.sourceType && row.sourceType.toLowerCase() == constants.message_type ?
							finalStrings(row.primaryLabel).map(l => <p>{l}</p>)
							: row.primaryLabel}</a>
				)
			}
		}
		function finalStrings(t) {
			var strs = []
			t = t.split('^')
			var n = t.length - 3
			strs.push(_.dropRight(t, n).join('^'))
			strs.push(_.drop(t, 3).join('^'))
			return strs.filter(s => s)
		}

		function getFinalValues(val) {
			var val;
			if (val && val.indexOf('<') > -1)
				val = val.replace('<', ',')
			else if (val && val.indexOf('>') > -1)
				val = `${val.replace('>', '')},`
			else {
				val = `${val.substring(0, val.lastIndexOf('-'))},${val.substring(val.lastIndexOf('-') + 1, val.length)}`
			}
			return val.split(',');
		}
		function getRowClasses(row) {
			var referenceRanges = row.referenceRange ? getFinalValues(row.referenceRange) : null;
			var low = referenceRanges && referenceRanges[0].trim() != "" ? referenceRanges[0].trim() : "";
			var high = referenceRanges && referenceRanges[1].trim() != "" ? referenceRanges[1].trim() : "";
			let classes = null;
			let value = parseFloat(row.value)
			// if (value) {
			// 	if (row.referenceRanges) {
			// 		if ((row.referenceRanges.high != null && value > row.referenceRanges.high) || (row.referenceRanges.low != null && value < row.referenceRanges.low)) {
			// 			if (row.boundaryRange) {
			// 				if ((row.boundaryRange.high != null && value > row.boundaryRange.high) || (row.boundaryRange.low != null && value < row.boundaryRange.low)) {
			// 					classes = 'exceed-boundary-range';
			// 				} else {
			// 					classes = 'exceed-reference-range';
			// 				}
			// 			} else {
			// 				classes = 'exceed-reference-range';
			// 			}
			// 		}
			// 	}
			// 	else if (row.boundaryRange) {
			// 		if ((row.boundaryRange.high != null && value > row.boundaryRange.high) || (row.boundaryRange.low != null && value < row.boundaryRange.low)) {
			// 			classes = 'exceed-boundary-range';
			// 		}
			// 	}
			// }
			if (value) {
				if (referenceRanges) {
					if ((parseFloat(high) != "" && (value > parseFloat(high))) || (parseFloat(low) != "" && (value < parseFloat(low)))) {
						classes = 'exceed-reference-range';
					}
				}
			}
			return classes;
		}
		return (
			<div className='individual-visit'>
				<div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 results-table'>
					<>
						{/* <div className='card card-default mb-3 result-width'> */}

						{this.props.results ? <div className='card react-bootstrap-table individual-result-details'>
							<table className='table mb-0'>
								<th className='pl-3'>{labels.orders_labels.order_details}</th>
								{Object.keys(orderDetails).map(pd => (
									<tr className='pl-3'>
										<td className='pl-5 align-middle source-label'>{orderDetails[pd]}</td>
										{this.props.results[pd]
											? ((this.props.results.sourceType && this.props.results.sourceType.toLowerCase() == constants.message_type) && pd == 'source') ?
												<td><div>{pd != "createdAt" ? this.props.results[pd] : moment(this.props.results[pd], "MMM-DD-YYYY").format('YYYY') != "0001" ? returnLocalizedDate(this.props.results[pd]).format('MMMM Do, YYYY h:mm A') : "See Archives"}</div>
													<div>
														<FormControl type='button' btnType='default btn-sm' text={labels.orders_labels.view_message}
															onClick={() => this.props.history.push(`/messages/details/${this.props.results.sourceId}`)} />
													</div>
												</td>
												: <td> {pd != "createdAt" ? this.props.results[pd] : moment(this.props.results[pd], "MMM-DD-YYYY").format('YYYY') != "0001" ? returnLocalizedDate(this.props.results[pd]).format('MMMM Do, YYYY h:mm A') : "See Archives"} </td> : <td>{labels.not_available}</td>}
									</tr>
								))}
							</table></div> : null}

						{/* </div> */}
					</>
					<div className='review-results-table'>
						<div className='bootstrap-table-container'>
							{this.props.results && this.props.results.observationsDTO
								? <table className='table table-striped'>
									<thead>
										<th></th>
										<th>{labels.oddities_labels.label}</th>
										<th>{labels.oddities_labels.noumenon}</th>
										<th className='value-row'>{labels.oddities_labels.value}</th>
										<th>{labels.oddities_labels.units}</th>
										<th>{labels.oddities_labels.referenceRange}</th>
										<th>{labels.oddities_labels.observation_status}</th>
									</thead>
									<tbody>
										{this.props.results.observationsDTO.map(row => {
											return <><tr className={getRowClasses(row)}>
												<td className='message-icon'>{columns(row, 'message')}</td>
												<td className='label-section'>{columns(row, 'primary')}</td>
												<td>{row.noumenonLabel}</td>
												<td className='value-row'>{row.value}</td>
												<td className='units-row'>{row.units}</td>
												<td>{<p className='mb-0 mt-1'>{row.referenceRange}</p>}</td>
												<td>{row.resultStatus}</td>
											</tr>
												{
													row.odditieDTO && row.odditieDTO.category != constants.visit_labels.ignored_value ? <tr><td></td><td colSpan='6'>
														<span className="badge badge-warning">{row.odditieDTO.category}</span> {row.odditieDTO.message}
													</td>
													</tr> : null
												}
											</>
										})}
									</tbody>
								</table>
								: null}
							{/* <BootstrapTable
							striped={false}
							bordered={false}
							hover={false}
							bootstrap4
							keyField='id'
							data={this.props.results && this.props.results.observationsDTO ? this.props.results.observationsDTO : []}
							columns={columns}
							//expandRow={ this.expandRow(this.props.results.observationsDTO) }
							rowClasses={getRowClasses}
						/> */}
						</div>
					</div>
					{/* {
						this.props.openObsModal ?
							<ObservationPopUp  {...this.props}></ObservationPopUp> : null
					} */}
				</div>
			</div>
		);
	}
}
export default ResultView;

import React, { Component } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import { Link } from 'react-router-dom';
import providerDefault from '../../../assets/images/provider-default.png';
import PageTitle from '../../../components/PageTitle';

class CaregiverDetails extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.caregiverId) {
            this.props.setCaregiverDetails(this.props.match.params.caregiverId)
            this.props.getSitesList()
        }
    }
    render() {
        return (
            <>

                <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 pr-4 provider-details-side-panel'>
                    <FormControl type='button' className='mb-2' btnType='primary' text={labels.provider_labels.edit} onClick={() => { this.props.navigateEditCaregiver(this.props.history, `/caregivers/edit/${this.props.currentCaregiver.id}`) }} />
                    <FormControl type='button' className='mb-2' btnType='default' text={labels.caregiver_labels.caregiver_list} onClick={() => { this.props.history.push('/caregivers/list') }} />
                </div>
                <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0 card flex-row provider-details'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <legend className='pt-3'>{labels.provider_labels.sites_label}</legend>
                        <ul>
                            {this.props.currentCaregiver && this.props.currentCaregiver.selectedsites
                                ? this.props.currentCaregiver.selectedsites.map(s => (
                                    <li><Link to={`/sites/${s.id}`}>{ s.name}</Link></li>
                                )) : null}
                        </ul>
                    </div>
                </div>

            </>
        )
    }
}
export default CaregiverDetails
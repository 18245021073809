// export default class datumChangeCalculator {
//   constructor( datum, change_from )
//   {
//     this.datum = datum;
//     this.change_from = change_from;

//     this.computeChange();
//   }

//   computeChange()
//   {
//     this._historic_nonblank_data = this.datum.historicData ? this.datum.historicData.filter( this.ignoreBlank ):'';
//     this._other = this.findComparableTo();

//     if( this._other )
//     {
//       this.actualChange = this.findChange();

//       this.change = null;

//       if( this.actualChange == 0 )
//         this.change = 0;
//       else if( isFinite( this.actualChange) )
//         this.change = Math.abs( this.actualChange );

//       this.direction = this.findDirection();

//       this.previous_date = this._other.visitDate;
//       this.previous_value = this._other.value + ' ' + this._other.units;
//     }
//   }

//   ignoreBlank(datum)
//   {
//     return( datum.value );
//   }

//   findComparableTo()
//   {

//     if( this.change_from == 'baseline' )
//     {
//       if(this._historic_nonblank_data.length > 1)
//         return( this._historic_nonblank_data[0] );
//       else
//         return( null );
//     }
//     else if( this.change_from == 'previous' )
//     {
//       if(this._historic_nonblank_data.length > 2)
//         return(
//           this._historic_nonblank_data[this._historic_nonblank_data.length - 2]
//         );
//       else
//         return( null );
//     }
//   }

//   findChange()
//   {
//     var p_change = 100 * (this.datum.value - this._other.value)/(this._other.value);
//     return( Math.round( p_change * 10 ) / 10 );
//   }

//   findDirection()
//   {
//     if( this.actualChange > 0 )
//       return('up');
//     else if( this.actualChange < 0 )
//       return('down');
//     else if( this.actualChange == 0 )
//       return('equal');
//     else
//       return(null);
//   }
// }
import { roundNumber } from '../tools/helpers';
export default class datumChangeCalculator {
  constructor(datum, change_from) {
    this.datum = datum;
    this.change_from = change_from;

    this.computeChange();
  }

  computeChange() {
    this._historic_nonblank_data = this.datum.historicData ? this.datum.historicData.filter(this.ignoreBlank) : '';
    this._other = this.findComparableTo();

    if (this._other) {
      let tempPercentChange = this.calculatePercentChange();
      let tempAbsChange = this.calculateAbsChange();

      if (tempPercentChange == 0)
        this.percentChange = 0;
      else if (isFinite(tempPercentChange))
        this.percentChange = Math.abs(tempPercentChange);
      else
        this.percentChange = null;
      if (tempAbsChange == 0)
        this.absoluteChange = 0;
      else if (isFinite(tempAbsChange)) {
        if (parseInt(this.datum.scale) != 0) {
          this.absoluteChange = roundNumber(Math.abs(tempAbsChange), this.datum ? parseInt(this.datum.scale) : 2);
        }
        else if (parseInt(this.datum.scale) == 0) {
          this.absoluteChange = roundNumber(Math.abs(tempAbsChange), 3);
        }
        else {
          this.absoluteChange = null;
        }
      }
      this.direction = this.findDirection(tempPercentChange);

      this.previous_date = this._other.visitDate;
      this.previous_value = this._other.value + ' ' + this._other.units;
    }
  }

  ignoreBlank(datum) {
    return (datum.value);
  }

  findComparableTo() {

    if (this.change_from == 'baseline') {
      if (this._historic_nonblank_data.length > 1)
        return (this._historic_nonblank_data[0]);
      else
        return (null);
    }
    else if (this.change_from == 'previous') {
      if (this._historic_nonblank_data.length > 2)
        return (
          this._historic_nonblank_data[this._historic_nonblank_data.length - 2]
        );
      else
        return (null);
    }
  }

  calculatePercentChange() {
    var p_change = 100 * (this.datum.value - this._other.value) / (this._other.value);
    return (Math.round(p_change * 10) / 10);
  }

  calculateAbsChange() {
    return (this.datum.value - this._other.value);
  }

  findDirection(change) {
    if (change > 0)
      return ('up');
    else if (change < 0)
      return ('down');
    else if (change == 0)
      return ('equal');
    else
      return (null);
  }
}
export default {
  demo_1: [
    {
      "id": 1054,
      "providerName": "Mahsin test",
      "group": "General Instructions",
      "body": "Continue smoking abstinence.",
      "instructions": "",
      "notes": "Since you quit smoking, your FEV1 has continued to improve.",
      "culturedNoumena": "FEV1 Percent Predicted",
      "priority": 1,
      "visible": null,
      "indicators": [
        "fev1_pp"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 4561,
      "providerName": "Joseph Raffaele",
      "group": "Lifestyle",
      "body": "Start meditation",
      "instructions": "",
      "notes": "",
      "culturedNoumena": null,
      "priority": 2,
      "visible": null,
      "indicators": [],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2021-05-12T13:54:43",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1063,
      "providerName": "Mahsin test",
      "group": "Lifestyle",
      "body": "Paced breathing with Inner Balance or Muse",
      "instructions": "",
      "notes": "While your BP and arterial stiffness are improved since restarting the lisinopril, you could gradually reduce the need for medication with 3-5 times per week breathing exercises and/or meditation.",
      "culturedNoumena": "Augmentation Pressure",
      "priority": 3,
      "visible": null,
      "indicators": [
        "augmentation_pressure"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1062,
      "providerName": "Mahsin test",
      "group": "Diet",
      "body": "Continue 5/2 diet.",
      "instructions": "",
      "notes": "Try also to substitute healthy fats such as avocado and fish for meat and dairy fats.",
      "culturedNoumena": "LDL Cholesterol",
      "priority": 4,
      "visible": null,
      "indicators": [
        "ldl"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 3422,
      "providerName": " ",
      "group": "Exercise",
      "body": "HIIT",
      "instructions": "Twice a week",
      "notes": null,
      "culturedNoumena": "Percent Bodyfat",
      "priority": 5,
      "visible": null,
      "indicators": [
        "body_fat_percent"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2020-10-20T14:37:02",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 4422,
      "providerName": "Joseph Raffaele",
      "group": "Supplement",
      "body": "Vitamin C 500 mg",
      "instructions": "Take one a day with food",
      "notes": "",
      "culturedNoumena": null,
      "priority": 6,
      "visible": null,
      "indicators": [],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2021-04-16T17:58:56",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1065,
      "providerName": "Mahsin test",
      "group": "Supplement",
      "body": "Curcumin: Volt03 ",
      "instructions": "Take one capsule daily",
      "notes": "To reduce general inflammation and possibly improve lung function.",
      "culturedNoumena": "Spirometry Interpretation",
      "priority": 7,
      "visible": null,
      "indicators": [
        "interp_spiro"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1066,
      "providerName": "Mahsin test",
      "group": "Supplement",
      "body": "Acetyl L carnitine",
      "instructions": "One a day with food.",
      "notes": "",
      "culturedNoumena": "Standard Processing Speed",
      "priority": 8,
      "visible": null,
      "indicators": [
        "processing_speed_domain"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 4558,
      "providerName": "Joseph Raffaele",
      "group": "Supplement",
      "body": "TA-65 500 IU ",
      "instructions": "Take two a day first thing in the morning",
      "notes": "",
      "culturedNoumena": "TelomerAge",
      "priority": 10,
      "visible": null,
      "indicators": [
        "telomerage"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2021-05-11T19:21:15",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1058,
      "providerName": "Mahsin test",
      "group": "Supplement",
      "body": "Increase Raffaele Medical Methylated B Vitamins.",
      "instructions": "Take 2 a day with food.",
      "notes": "",
      "culturedNoumena": "Homocysteine",
      "priority": 11,
      "visible": null,
      "indicators": [
        "homocysteine"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 4852,
      "providerName": "Joseph Raffaele",
      "group": "Hormone Optimization",
      "body": "Estradiol 6 mg/ml cream 30 ml Topi-pump",
      "instructions": "Apply 2 pumps daily after showering",
      "notes": "",
      "culturedNoumena": "Estradiol",
      "priority": 12,
      "visible": null,
      "indicators": [
        "estradiol_extraction"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2021-07-26T21:11:17",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1061,
      "providerName": "Mahsin test",
      "group": "Further Testing",
      "body": "Recheck  homocysteine, B12, and folate levels in 3 months.",
      "instructions": "",
      "notes": "",
      "culturedNoumena": "Homocysteine",
      "priority": 13,
      "visible": null,
      "indicators": [
        "homocysteine"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1060,
      "providerName": "Mahsin test",
      "group": "Further Testing",
      "body": "Recheck a female hormone initial panel in 3 months.",
      "instructions": "",
      "notes": "Be sure to use your estradiol/testosterone cream the day of the test.",
      "culturedNoumena": "Estradiol",
      "priority": 14,
      "visible": null,
      "indicators": [
        "estradiol_extraction"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1059,
      "providerName": "Mahsin test",
      "group": "Further Testing",
      "body": "Recheck IGF-1 in 3 months.",
      "instructions": "",
      "notes": "Your level has been gradually decreasing since baseline.  If it drops below 150 will consider GH secretagogue therapy or GHRT.",
      "culturedNoumena": "Insulin-Like Growth Factor 1",
      "priority": 15,
      "visible": null,
      "indicators": [
        "igf1"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 5389,
      "providerName": "Joseph Raffaele",
      "group": "Exercise",
      "body": "Zone 2 cardio",
      "instructions": "Exercise at 60-70% of maximum heart rate for 60 min 4 times a week.",
      "notes": "",
      "culturedNoumena": "Hemoglobin A1C",
      "priority": 16,
      "visible": null,
      "indicators": [
        "hemoglobin_a1c"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2022-05-15T19:56:09",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1055,
      "providerName": "Mahsin test",
      "group": "General Instructions",
      "body": "Your hormone replacement therapy regimen is working well.",
      "instructions": "",
      "notes": "Since starting your estradiol cream, your symptoms have resolved and your level is in excellent range.",
      "culturedNoumena": "Estradiol",
      "priority": 17,
      "visible": null,
      "indicators": [
        "estradiol_extraction"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1053,
      "providerName": "Mahsin test",
      "group": "General Instructions",
      "body": "Heart rate has significantly improved.",
      "instructions": "",
      "notes": "This is likely a result of your exercise program.  Keep up the good work!",
      "culturedNoumena": "Resting Heart Rate",
      "priority": 18,
      "visible": null,
      "indicators": [
        "hr"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1052,
      "providerName": "Mahsin test",
      "group": "General Instructions",
      "body": "PhysioAge change interpretation",
      "instructions": "",
      "notes": "Your PhysioAge age has decreased significantly from baseline and the most recent tests.  This is largely because of the improvements in TelomerAge, ImmunoAge, and NeuroAge and most recently PulmoAge and CardioAge.",
      "culturedNoumena": "PhysioAge",
      "priority": 19,
      "visible": null,
      "indicators": [
        "physioage"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:13",
      "visitDate": "2019-03-12T00:00:00",
      "visitId": 10551,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 7",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10551,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1049,
      "providerName": "Mahsin test",
      "group": "Pharmaceutical",
      "body": "Enalapril 10 mg",
      "instructions": "Take one capsule in the morning.",
      "notes": "If you develop a dry cough let your doctor know.",
      "culturedNoumena": "Systolic Blood Pressure (at rest)",
      "priority": 20,
      "visible": null,
      "indicators": [
        "sbp"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:11",
      "visitDate": "2017-04-17T00:00:00",
      "visitId": 10549,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 5",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10549,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1043,
      "providerName": "Mahsin test",
      "group": "Diet",
      "body": "Nutritional consultation",
      "instructions": "If you have trouble or would like a more comprehensive meal plan, we can set up a consultation with our nutritionist.",
      "notes": "",
      "culturedNoumena": "Visceral Fat Level",
      "priority": 22,
      "visible": null,
      "indicators": [
        "vfl"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:11",
      "visitDate": "2017-04-17T00:00:00",
      "visitId": 10549,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 5",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10549,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1046,
      "providerName": "Mahsin test",
      "group": "Hormone Optimization",
      "body": "Progesterone: 100 mg SR",
      "instructions": "Take one capsule daily at bedtime",
      "notes": "If you note any dizziness or grogginess during the day, stop the capsule the following night and see if it recurs.",
      "culturedNoumena": "Progesterone",
      "priority": 23,
      "visible": null,
      "indicators": [
        "progesterone"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:11",
      "visitDate": "2017-04-17T00:00:00",
      "visitId": 10549,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 5",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10549,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1042,
      "providerName": "Mahsin test",
      "group": "Diet",
      "body": "Increase your servings of vegetables a day to 5",
      "instructions": "This should be with leafy greens and colorful vegetables.",
      "notes": "This should increase you skin antioxidant score when we recheck it in 3 months.",
      "culturedNoumena": "Visceral Fat Level",
      "priority": 24,
      "visible": null,
      "indicators": [
        "vfl",
        "immunoage"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:11",
      "visitDate": "2017-04-17T00:00:00",
      "visitId": 10549,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 5",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10549,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 1041,
      "providerName": "Mahsin test",
      "group": "Further Testing",
      "body": "Pulmonary consultation",
      "instructions": "Moderately severe COPD is the cause of your high lung age.",
      "notes": "A pulmonary consultation for more comprehensive lung function evaluation and therapy could help to improve lung function and your PulmoAge.",
      "culturedNoumena": "PulmoAge",
      "priority": 25,
      "visible": null,
      "indicators": [
        "pulmoage"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2019-12-23T23:42:11",
      "visitDate": "2017-04-17T00:00:00",
      "visitId": 10549,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 5",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 10549,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 5388,
      "providerName": "Joseph Raffaele",
      "group": "Supplement",
      "body": "Elysium Basis Capsule",
      "instructions": "Take 2 capsules daily",
      "notes": "",
      "culturedNoumena": "TruAge",
      "priority": 26,
      "visible": null,
      "indicators": [
        "truage_trudiag"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2022-05-15T19:55:29",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 6264,
      "providerName": "Joseph Raffaele",
      "group": "Supplement",
      "body": "Alpha Lipoic Acid (RM) Vcap",
      "instructions": "Take 1 Capsule By Mouth Daily",
      "notes": "",
      "culturedNoumena": null,
      "priority": 27,
      "visible": null,
      "indicators": [],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2023-03-20T14:36:37",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": "200 mg",
      "recommendationStrengthType": "",
      "brand": null,
      "groupNames": null
    },
    {
      "id": 6265,
      "providerName": "Joseph Raffaele",
      "group": "Hormone",
      "body": "Testosterone cream",
      "instructions": "Apply 0.25ml to 1ml (1 to 4 pumps) topically daily as directed",
      "notes": "Apply behind both knees and rub in vigorously.",
      "culturedNoumena": null,
      "priority": 28,
      "visible": null,
      "indicators": [],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2023-03-20T14:38:08",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": "3 mg/ml",
      "recommendationStrengthType": "",
      "brand": null,
      "groupNames": null
    },
    {
      "id": 6266,
      "providerName": "Joseph Raffaele",
      "group": "Hormone",
      "body": "Estradiol cream",
      "instructions": "Apply 1ml (2 pumps) topically daily",
      "notes": "",
      "culturedNoumena": null,
      "priority": 29,
      "visible": null,
      "indicators": [],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2023-03-20T18:17:04",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": "6 mg/ml",
      "recommendationStrengthType": "",
      "brand": null,
      "groupNames": null
    },
    {
      "id": 6267,
      "providerName": "Joseph Raffaele",
      "group": "Hormone",
      "body": "Progesterone S.R. capsule",
      "instructions": "Take 1 capsule by mouth Every Night At Bedtime ",
      "notes": "",
      "culturedNoumena": null,
      "priority": 30,
      "visible": null,
      "indicators": [],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2023-03-20T18:18:13",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": "100 mg",
      "recommendationStrengthType": "",
      "brand": null,
      "groupNames": null
    },
    {
      "id": 6268,
      "providerName": "Joseph Raffaele",
      "group": "Hormone",
      "body": "NP Thyroid",
      "instructions": null,
      "notes": null,
      "culturedNoumena": "LDL Cholesterol",
      "priority": 31,
      "visible": null,
      "indicators": [
        "ldl"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2023-03-20T21:26:33",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": null,
      "recommendationStrengthType": null,
      "brand": null,
      "groupNames": null
    },
    {
      "id": 6602,
      "providerName": "Joseph Raffaele",
      "group": "Pharmaceutical",
      "body": "metFORMIN XR (Oral Pill)",
      "instructions": "One daily by mouth",
      "notes": "",
      "culturedNoumena": "Hemoglobin A1C",
      "priority": 32,
      "visible": null,
      "indicators": [
        "hemoglobin_a1c"
      ],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2023-06-28T21:36:00",
      "visitDate": "2021-03-01T00:00:00",
      "visitId": 13246,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 9",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 13246,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": "1000 MG",
      "recommendationStrengthType": "",
      "brand": "metformin",
      "groupNames": null
    },
    {
      "id": 6646,
      "providerName": "Joseph Raffaele",
      "group": "Pharmaceutical",
      "body": "Omeprazole (Oral Pill)",
      "instructions": "1qam",
      "notes": "",
      "culturedNoumena": null,
      "priority": 33,
      "visible": null,
      "indicators": [],
      "noumenaList": null,
      "noumenaCodes": null,
      "conditions": null,
      "recommendationText": null,
      "createdAt": "2023-07-12T21:29:46",
      "visitDate": "2023-03-22T00:00:00",
      "visitId": 20612,
      "isStopped": 0,
      "stoppedAt": null,
      "timePoint": "Follow-Up 11",
      "previousRecommendation": null,
      "nextRecommendation": null,
      "firstRecommendationVisit": 20612,
      "guId": null,
      "previousRecommendationDate": null,
      "productType": null,
      "startedAt": null,
      "endedAt": null,
      "strength": "40 MG",
      "recommendationStrengthType": "",
      "brand": "omeprazole",
      "groupNames": null
    }
  ],
  demo_2: []
}



import Rickshaw from 'rickshaw'
import grader from './grader'
import linearRegression from '../tools/linear_regression'
import * as d3 from "d3";
import _ from 'lodash';
import constants from '../../../config/constants';

Rickshaw.Graph.Renderer.RubricPlot = Rickshaw.Class.create(Rickshaw.Graph.Renderer, {
  name: 'rubricplot',
  defaults: function ($super) {

    return Rickshaw.extend($super(), {
      unstack: true,
      fill: false,
      stroke: true,
      padding: { top: 0.01, right: 0.01, bottom: 0.01, left: 0.01 },
      dotSize: 3,
      strokeWidth: 2
    });
  },

  initialize: function ($super, args) {
    $super(args);
    this.renderHooks = [];
  },

  // Override the default domain function to add padding when there is only one
  // data point, or when the data points are all along a single line
  domain: function ($super, data) {
    let out = $super(data);
    if (out.x[0] == out.x[1]) {
      out.x[0] = out.x[0] - 1000
      out.x[1] = out.x[1] + 1000
    }

    if (out.y[0] == out.y[1]) {
      out.y[0] = out.y[0] - 1000
      out.y[1] = out.y[1] + 1000
    }

    return out;
  },

  render: function (args) {
    args = args || {};

    let gradeClass = (points) => {
      let letter = grader(points)
      let htmlClass = letter.replace('+', 'p').replace('-', 'm');
      return ("grade-" + htmlClass);
    }

    var graph = this.graph;

    var series = args.series || graph.series;
    var vis = args.vis || graph.vis;

    var dotSize = this.dotSize;

    vis.selectAll('*').remove();

    var data = series
      .filter(function (s) { return !s.disabled })
      .map(function (s) { return s.stack });

    this.renderHooks.forEach(function (hook) {
      hook(args);
    });

    series.forEach(function (seriesData) {
      let distance = 10;
      function checkOverlap(currentIndex, nextIndex) {
        if (nextIndex >= seriesData.stack.length) {
          return false;
        }
        // console.log(series.data[currentIndex].datum.type);
        if (seriesData.data[currentIndex].datum.type == "points") {
          distance = grader(seriesData.stack[currentIndex].y).toString().length <= 2 ? 5 * (grader(seriesData.stack[currentIndex].y).toString().length) : (grader(seriesData.stack[currentIndex].y).toString().length - 1) * 8;
        }
        else {
          distance = seriesData.stack[currentIndex].y.toString().length <= 2 ? 5 * (seriesData.stack[currentIndex].y.toString().length) : (seriesData.stack[currentIndex].y.toString().length - 1) * 8;
        }


        let currentX = graph.x(seriesData.stack[currentIndex].x);
        let currentY = graph.y(seriesData.stack[currentIndex].y);

        let nextX = graph.x(seriesData.stack[nextIndex].x);
        let nextY = graph.y(seriesData.stack[nextIndex].y);
        if (nextX - currentX > distance) {
          return false;
        }

        if (nextIndex - currentIndex == 1) {
          if (currentIndex % 2 == 0 && nextY > currentY && (nextY - currentY > 20 && nextY - currentY < 43)) {
            return true;
          }
          else if (currentIndex % 2 == 1 && nextY < currentY && (currentY - nextY > 20 && currentY - nextY < 43)) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return (Math.abs(nextY - currentY) > 15) ? false : true;
        }
      }

      var values = [];
      var count = 0;
      if (seriesData.disabled) return;

      var nodes;
      if (seriesData.isBeta &&  !seriesData.isComparingCharts ) {
          nodes = vis.selectAll("x")
          .data(seriesData.stack.filter(function (d) { return d.y !== null }))
          .enter().append("svg:circle")
          .attr("id", function (d) { return d.datum ? `cir-${d.datum.code}` : '' })
          .attr("fill", () => { return "white" })
          .attr("stroke", () => { return "#33363E" })
          .attr("cx", function (d) { return graph.x(d.x) })
          .attr("cy", function (d) { return graph.y(d.y) })
          .attr('class', function (d) {return `${gradeClass(d.datum && d.datum.points)} ${seriesData.isComparingCharts ? ` unselected-data-point data-point-circle-${d.datum.code}` : ''} `})
          .attr("r", function (d) { return ("r" in d) ? d.r : dotSize  })
      }
      else if (seriesData.isBeta && seriesData.isComparingCharts ) {
        nodes = vis.selectAll("x")
        .data(seriesData.stack.filter(function (d) { return d.y !== null }))
        .enter().append("svg:circle")
        .attr("id", function (d) { return d.datum ? `cir-${d.datum.code}` : '' })
        .attr("fill", () => { return "white" })
        .attr("stroke", () => { return "#33363E" })
        .attr("cx", function (d) { return graph.x(d.x) })
        .attr("cy", function (d) { return graph.y(d.y) })
        .attr('class', function (d) {return `${gradeClass(d.datum && d.datum.points)} ${seriesData.isComparingCharts ? ` unselected-data-point data-point-circle-${d.datum.code}` : ''} `})
        .attr("r", function (d) { return 4 })
        .attr('opacity', series.length <= 4 ? '1' : '0.2')
      }
      else {
        nodes = vis.selectAll("x")
          .data(seriesData.stack.filter(function (d) { return d.y !== null }))
          .enter().append("svg:circle")
          .attr("id", function (d) { return d.datum ? `cir-${d.datum.code}` : '' })
          .attr("cx", function (d) { return graph.x(d.x) })
          .attr("cy", function (d) { return graph.y(d.y) })
          .attr('class', function (d) { return (gradeClass(d.datum && d.datum.points)) })
          .attr("r", function (d) { return ("r" in d) ? d.r : dotSize })
      }
      var maxValue = Math.max(...seriesData.stack.map(t => t.y))
      var minValue = Math.min(...seriesData.stack.map(t => t.y))
      var stacklength = seriesData.stack.length
      var finalseries = seriesData.stack
      var x_plots = []
      const x_min_diff = 24
      const y_min_diff = 13

      var textnodes = vis.selectAll("x")
        .data(seriesData.stack.filter(function (d) { return d.y !== null }))
        .enter()
        .append('text')
        .attr('xlink:href', function (d) { return d.datum ? `#cir-${d.datum.code}` : '' })
        .attr('font-size', window.innerWidth >= 768 ? 14 : window.innerWidth > 530 ? 12 : 10)
        .attr('font-weight', 600)
        .attr('dx', function (d, index) {
          if (checkOverlap(index, index + 1)) {
            return;
          }
          else if (checkOverlap(index, index + 2)) {
            return;
          }
          else {
            return index % 2 == 0 ? graph.x(d.x) - 5 : graph.x(d.x) - 5;
          }
          // return index % 2 == 0 ? graph.x(d.x) - 15 : graph.x(d.x) - 5;
          // if (d.y && (d.y).toString().length >= 3)
          //   return graph.x(d.x) - 15
          // else
          //   return graph.x(d.x) - 5
        })
        .attr('dy', function (d, index) {
          const y_offset = index % 2 == 0 ? 20 : -10
          //   if (index - 1 == 0 || index == stacklength - 2 ||
          //     (finalseries[index - 1] && (finalseries[index - 1].y == maxValue
          //       || finalseries[index - 1].y == minValue)) ||
          //     (finalseries[index + 1] && (finalseries[index + 1].y == maxValue
          //       || finalseries[index + 1].y == minValue)) && index % 2 != 0) {
          //       }
          //     }

          if (checkOverlap(index, index + 1)) {
            values.push(null);
            return;
          }
          else if (checkOverlap(index, index + 2)) {
            values.push(null);
            return;
          }
          else {
            values.push(d.datum.value);
            return (index % 2 == 0) ? graph.y(d.y) + 20 : graph.y(d.y) - 10;
          }

          // return (index % 2 == 0) ? graph.y(d.y) + 10 : graph.y(d.y);

          // if (index == 0 || index == stacklength - 1) {
          //   return graph.y(d.y) - 15
          // }
          // else if (index % 2 == 0) {
          //   if (index != 0 || index != stacklength - 1)
          //     return graph.y(d.y) + 20
          //   else
          //     return graph.y(d.y) - 10
          // }
          // else {
          //   if (index - 1 == 0 || index - 1 == stacklength - 1)
          //     return graph.y(d.y) + 20
          //   return graph.y(d.y) - 10
          // }
        })
        // .attr("transform", function(d, index){
        //   return `rotate(-90, ${index % 2 == 0 ? graph.x(d.x) - 15 : graph.x(d.x) - 5}, ${(index % 2 == 0) ? graph.y(d.y) - 10 : graph.y(d.y) - 10})` 
        // })
        .text(function (d, index) {
          //if (d.y == maxValue || d.y == minValue || index == 0 || index == stacklength - 1)
          if (values[count] === null) {
            count++;
            return
          }
          else {
            count++;
            let asterisk = d.datum && d.datum.explanationDTO && d.datum.explanationDTO.length > 0 ? '*' : '';
            if (!seriesData.isBeta) {
              if (d.datum.type == "points") {
                return grader(d.datum.value) + asterisk;
              }
              return (d.datum.value) + asterisk;
            }
            else {
              if(seriesData.isComparingCharts){
                d3.select(this).classed(`${d.datum.code}-asterisk`, true).classed('unselected-asterisk', true);
              }
              return asterisk ? asterisk : '';
            }

          }
        })

      // .attr('stroke', 'red')
      // .attr('stroke-width', '2px')
      Array.prototype.forEach.call(nodes[0], function (n) {
        if (!n) return;
      }.bind(this));

    }, this);
  }
});

// Plots the usual rubricplot dots, but adds an additional
// arbitrary line on top of it.
Rickshaw.Graph.Renderer.RubricPlotWithLine = Rickshaw.Class.create(Rickshaw.Graph.Renderer.RubricPlot, {
  name: 'rubricplot_with_line',

  initialize: function ($super, args) {
    $super(args);
    this.renderHooks = [renderAdditionalLine.bind(this)];
    this.seriesPathFactory = seriesPathFactory.bind(this);
  }
});

// Plots the usual rubricplot dots, but adds an additional
// regression line on top of it.
Rickshaw.Graph.Renderer.RubricPlotWithRegression = Rickshaw.Class.create(Rickshaw.Graph.Renderer.RubricPlot, {
  name: 'rubricplot_with_regression',

  initialize: function ($super, args) {
    $super(args);
    this.renderHooks = [this.renderLinearRegression.bind(this)];
    this.seriesPathFactory = seriesPathFactory.bind(this);
  },

  renderLinearRegression: function (args) {
    var graph = this.graph;
    var series = args.series || graph.series;
    var vis = args.vis || graph.vis;

    var data = series
      .filter(function (s) { return !s.disabled })
      .map(function (s) { return s.stack });

    var line_values = data.map(function (series) {
      var seriesMap = series.map(function (datum) {
        return [datum.x, datum.y];
      });
      return linearRegression(seriesMap);
    });

    var nodes = vis.selectAll("path")
      .data(line_values)
      .enter().append("svg:path")
      .attr("d", this.seriesPathFactory())

    var i = 0;
    series.forEach(function (series) {
      if (series.disabled) return;
      series.path = nodes[0][i++];
      this._styleSeries(series);
    }, this);
  }
});

// Plots the usual rubricplot dots, but adds an additional
// age line on top of it.
Rickshaw.Graph.Renderer.RubricPlotWithAgeLine = Rickshaw.Class.create(Rickshaw.Graph.Renderer.RubricPlot, {
  name: 'rubricplot_with_age_line',

  initialize: function ($super, args) {
    $super(args);
    this.renderHooks = [
      renderAgeLine.bind(this),
      renderAdditionalLine.bind(this)
    ];
    this.seriesPathFactory = seriesPathFactory.bind(this);
  },
});

Rickshaw.Graph.Renderer.RubricPlotFilledWithAgeLine = Rickshaw.Class.create(Rickshaw.Graph.Renderer.RubricPlot, {
  name: 'rubricplot_filled_with_age_line',

  initialize: function ($super, args) {
    $super(args);
    this.renderHooks = [
      addAgeLine.bind(this),
      renderFilledArea.bind(this),
      renderAgeLine.bind(this),
      renderAdditionalLine.bind(this)
    ];
    this.seriesPathFactory = seriesPathFactory.bind(this);
    this.upperClipFactory = upperClipFactory.bind(this);
    this.lowerClipFactory = lowerClipFactory.bind(this);
    this.upperFillFactory = upperFillFactory.bind(this);
    this.upperFillFactoryExtend = upperFillFactoryExtend.bind(this);
    this.lowerFillFactoryExtend = lowerFillFactoryExtend.bind(this);
    this.lowerFillFactory = lowerFillFactory.bind(this);
    this.shouldFillEdgeOfPadding = true;
  }
})

const addAgeLine = function (args) {
  var graph = this.graph;
  var series = args.series || graph.series;
  var vis = args.vis || graph.vis;

  let first_visit = new Date(graph.x.invert(0.0) * 1000)
  let last_visit = new Date(graph.x.invert(graph.width) * 1000)

  series.forEach(function (s) {
    s.age_line = [
      {
        x: first_visit / 1000,
        y: yearsDiff(first_visit, s.dob)
      }, {
        x: last_visit / 1000,
        y: yearsDiff(last_visit, s.dob)
      }
    ]
  })
}

const renderAgeLine = function (args) {
  var graph = this.graph;
  var series = args.series || graph.series;
  var vis = args.vis || graph.vis;

  var age_lines = series
    .filter(function (s) { return !s.disabled })
    .map(function (s) { return s.age_line })

  if (_.head(series).isBeta && !_.head(series).isComparingCharts) {
    var nodes = vis.data(age_lines)
      .append("svg:path")
      .attr("stroke-width", () => { return "2" })
      .attr("stroke", () => { return "#3397C9" })
      .attr("fill", () => { return "url(#redGradient)" }) // Use a gradient ID for red
      .attr("d", this.seriesPathFactory())
      .attr('id', 'age-line');
    }
  else if(_.head(series).isComparingCharts){
    var nodes = vis.data(age_lines)
      .append("svg:path")
      .attr("stroke-width", () => { return "2" })
      .attr("stroke", () => { return series.length > 1 ? 'black' : '#3397C9'})
      .attr("fill", () => { return series.length > 1 ? "url(#blackGradient)" : "url(#redGradient)" }) // Use different gradients for black and red
      .attr("d", this.seriesPathFactory())
      .attr('id', 'biomarker-age-line');
  }
  else {
    var nodes = vis.data(age_lines)
      .append("svg:path")
      .attr("stroke-width", () => { return "2" })
      .attr("stroke", () => { return "#A2A2A2" })
      .attr("fill", () => { return "url(#greenGradient)" }) // Use a gradient ID for green
      .attr("d", this.seriesPathFactory());
  }

}

const getGradientLineForHistoricDataChart = (data) => {
  var stops = [];
  const lineLength = _.last(_.first(data)).x - _.first(_.first(data)).x;
  const firstData = _.first(_.first(data)).x;
  for(var i = 0; i < _.first(data).length; i++){
    if(i == 0){
      stops.push({ offset: "0%", color: `${getColorForOverallData(_.first(data)[i].datum.points)}` });
    }
    else if(i == _.first(data).length-1){
      stops.push({ offset: "100%", color: `${getColorForOverallData(_.first(data)[i].datum.points)}` });
    }
    else{
      stops.push({ offset: `${(_.first(data)[i].x - firstData)*100/lineLength}%`, color: `${getColorForOverallData(_.first(data)[i].datum.points)}` })
    }
  }

  return stops;
}

function getColorForOverallData(points) {
  points = parseFloat(points)
  if (points === null || isNaN(parseFloat(points))) {
      return "#999";
  } else if (points >= 4.3) {
      return "#2eb263";
  } else if (points >= 4.0) {
      return "#37b45e";
  } else if (points >= 3.7) {
      return "#48b854";
  } else if (points >= 3.3) {
      return "#59bc4a";
  } else if (points >= 3.0) {
      return "#72c13b";
  } else if (points >= 2.7) {
      return "#7bc336";
  } else if (points >= 2.3) {
      return "#c7d40a";
  } else if (points >= 2.0) {
      return "#D8D800";
  } else if (points >= 1.7) {
      return "#cda60e";
  } else if (points >= 1.3) {
      return "#c2771b";
  } else if (points >= 1.0) {
      return "#ba5225";
  } else if (points >= 0.7) {
      return "#b64129";
  } else {
      return "#b2302e";
  }
}


const renderAdditionalLine = function (args) {
  var graph = this.graph;
  var series = args.series || graph.series;
  var vis = args.vis || graph.vis;
  for(var i=0;i<series.length;i++){
    var data = [series[i]]
    .filter(function (s) { return !s.disabled })
    .map(function (s) { return s.stack })

    if (this.shouldFillEdgeOfPadding) {
      data = data.map(fillEdgeOfPadding(graph))
    }

    if(_.head(series).isComparingCharts){
      var nodes = vis.data(data)
      .append("svg:path")
      .attr("stroke-width", () => { return this.strokeWidth || 1 })
      .attr("stroke", () => { return series.length > 1 && _.head(series[i].data) && _.head(series[i].data).datum ? constants.biomarkers_of_aging_lineColors[_.head(series[i].data).datum.code] : 'black'})
      .attr("fill", () => { return "none" })
      .attr('opacity', series.length <= 4 ? '1' : '0.2')
      .attr("d", this.seriesPathFactory())
      .attr('id', `${_.head(series[i].data).datum.code}-biomarker-line`)
      .attr('class', 'not-selected');
    }
    else if(_.head(series).theme == 'dark-theme' && !_.head(series).isBiomarker){
      vis.append("defs").append("linearGradient")
      .attr("id", "gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%")
      .selectAll("stop")
      .data(getGradientLineForHistoricDataChart(data))
      .enter().append("stop")
      .attr("offset", d => d.offset)
      .attr("stop-color", d => d.color);

      var nodes = vis.data(data)
      .append("svg:path")
      .attr("stroke-width", () => { return this.strokeWidth || 2 })
      .attr("stroke", () => { return "url(#gradient)"})
      .attr("fill", () => { return "none" })
      .attr("d", this.seriesPathFactory());
    }
    else{
      var nodes = vis.data(data)
      .append("svg:path")
      .attr("stroke-width", () => { return this.strokeWidth || 2 })
      .attr("stroke", () => { return 'black'})
      .attr("fill", () => { return "none" })
      .attr("d", this.seriesPathFactory());
    }
  }
}

const renderFilledArea = function (args) {
  var graph = this.graph
  var series = args.series || graph.series
  var vis = args.vis || graph.vis
  var isDarkTheme = _.head(series).theme == 'dark-theme'

  series.forEach(series => {
    if(series.isComparingCharts){
      var data = [series]
      .filter((s) => { return !s.disabled })
      .map((s) => { return s.stack })
      .map(fillEdgeOfPadding(graph))

      vis.data([series.age_line])
        .append("clipPath")
        .attr("id", `upper-clip[${series.title}-compare]`)
        .append("path")
        .attr("d", this.upperClipFactory())

      vis.data(data)
      .append("svg:path")
      .attr("stroke", () => { return "none" })
      .attr("fill", () => { return "#FFC3C3" })
      .attr("clip-path", `url(#upper-clip[${series.title}-compare])`)
      .attr("d", this.upperFillFactory())

      vis.data(data)
      .append("svg:path")
      .attr("stroke", () => { return "none" })
      .attr("fill", () => { return "#FFC3C3" })
      .attr("clip-path", `url(#upper-clip[${series.title}-compare])`)
      .attr("d", this.upperFillFactoryExtend())
     
      vis.data([series.age_line])
        .append("clipPath")
        .attr("id", `lower-clip[${series.title}-compare]`)
        .append("path")
        .attr("d", this.lowerClipFactory())

      vis.data(data)
      .append("svg:path")
      .attr("stroke", () => { return "none" })
      .attr("fill", () => { return "#B3FFBE" })
      .attr("clip-path", `url(#lower-clip[${series.title}-compare])`)
      .attr("d", this.lowerFillFactory())

      vis.data(data)
      .append("svg:path")
      .attr("stroke", () => { return "none" })
      .attr("fill", () => { return "#B3FFBE" })
      .attr("clip-path", `url(#lower-clip[${series.title}-compare])`)
      .attr("d", this.lowerFillFactoryExtend())
    }
    else{
      var data = [series]
      .filter((s) => { return !s.disabled })
      .map((s) => { return s.stack })
      .map(fillEdgeOfPadding(graph))

      vis.data([series.age_line])
        .append("clipPath")
        .attr("id", `upper-clip[${series.title}]`)
        .append("path")
        .attr("d", this.upperClipFactory())

      if(series.isBeta){
        vis.data(data)
        .append("svg:path")
        .attr("stroke", () => { return "none" })
        .attr("fill", () => { return isDarkTheme ? '#B84E4E' : "#E5BDBD" })
        .attr("clip-path", `url(#upper-clip[${series.title}])`)
        .attr("d", this.upperFillFactory())
      }
      else{
        vis.data(data)
        .append("svg:path")
        .attr("stroke", () => { return "none" })
        .attr("fill", () => { return "#ff0000" })
        .attr("fill-opacity", () => { return "0.4" })
        .attr("clip-path", `url(#upper-clip[${series.title}])`)
        .attr("d", this.upperFillFactory())
      }
      vis.data([series.age_line])
        .append("clipPath")
        .attr("id", `lower-clip[${series.title}]`)
        .append("path")
        .attr("d", this.lowerClipFactory())

      if(series.isBeta){
        vis.data(data)
        .append("svg:path")
        .attr("stroke", () => { return "none" })
        .attr("fill", () => { return isDarkTheme ? '#51AB51' : "#BDE5C3" })
        .attr("clip-path", `url(#lower-clip[${series.title}])`)
        .attr("d", this.lowerFillFactory())
      }
      else{
        vis.data(data)
        .append("svg:path")
        .attr("stroke", () => { return "none" })
        .attr("fill", () => { return "#00ff00" })
        .attr("fill-opacity", () => { return "0.4" })
        .attr("clip-path", `url(#lower-clip[${series.title}])`)
        .attr("d", this.lowerFillFactory())
      }
    }
  });
}

const seriesPathFactory = function () {
  var graph = this.graph;

  var factory = d3.svg.line()
    .x(function (d) { return graph.x(d.x) })
    .y(function (d) { return graph.y(d.y) })
    .interpolate(this.graph.interpolation).tension(this.tension);

  factory.defined && factory.defined(function (d) { return d.y !== null });
  return factory;
}

const upperFillFactory = function () {
  var graph = this.graph
  var factory = d3.svg.area()
    .x((d) => { return graph.x(d.x) })
    .y0((d) => { return graph.y(d.y) })
    .y1((d) => { return graph.y(graph.min) })
    .interpolate(this.graph.interpolation).tension(this.tension);

  factory.defined && factory.defined(function (d) { return d.y !== null });
  return factory;
}

const upperFillFactoryExtend = function () {
  var graph = this.graph
  var factory = d3.svg.area()
    .x((d) => { return graph.x(d.x) })
    .y0((d) => { return graph.y(d.y) })
    .y1((d) => { return graph.y(graph.max) })
    .interpolate(this.graph.interpolation).tension(this.tension);

  factory.defined && factory.defined(function (d) { return d.y !== null });
  return factory;
}

const upperClipFactory = function () {
  var graph = this.graph
  var factory = d3.svg.area()
    .x((d) => { return graph.x(d.x) })
    .y0((d) => { return graph.y(graph.max) })
    .y1((d) => { return graph.y(d.y) })
    .interpolate(this.graph.interpolation).tension(this.tension);

  factory.defined && factory.defined(function (d) { return d.y !== null });
  return factory;
}

const lowerFillFactory = function () {
  var graph = this.graph
  var factory = d3.svg.area()
    .x((d) => { return graph.x(d.x) })
    .y0((d) => { return graph.y(graph.max)})
    .y1((d) => { return graph.y(d.y) })
    .interpolate(this.graph.interpolation).tension(this.tension);

  factory.defined && factory.defined(function (d) { return d.y !== null });
  return factory;
}

const lowerFillFactoryExtend = function () {
  var graph = this.graph
  var factory = d3.svg.area()
    .x((d) => { return graph.x(d.x) })
    .y0((d) => { return graph.y(graph.min)})
    .y1((d) => { return graph.y(d.y) })
    .interpolate(this.graph.interpolation).tension(this.tension);

  factory.defined && factory.defined(function (d) { return d.y !== null });
  return factory;
}

const lowerClipFactory = function () {
  var graph = this.graph
  var factory = d3.svg.area()
    .x((d) => { return graph.x(d.x) })
    .y0((d) => { return graph.y(d.y) })
    .y1((d) => { return graph.y(graph.min) })
    .interpolate(this.graph.interpolation).tension(this.tension);

  factory.defined && factory.defined(function (d) { return d.y !== null });
  return factory;
}

const yearsDiff = function (a, b) {
  return ((12 * a.getYear() + a.getMonth()) - (12 * b.getYear() + b.getMonth())) / 12.0
}

// Returns a function that can be used in a call to map in order to pad the
// lower and upper range of a line to the edge of the graph padding, even when
// there is no more data available
const fillEdgeOfPadding = function (graph) {
  return (series) => {
    let out = series.map((d) => { return d })
    out.unshift({
      x: graph.x.invert(0.0),
      y: _.head(series).y
    })

    out.push({
      x: graph.x.invert(graph.width),
      y: series.slice(-1)[0].y
    })

    return out
  }
}

export { yearsDiff }
export default Rickshaw


import { connect } from 'react-redux'
import MessageBody from '../components/MessageBody';
import { setCurrentMessage } from '../modules/inbox'
const mapStateToProps = (state) => ({
    ...state.inbox,
    isLoading: state.global.isLoading,
    isAuthenticated: state.global.isAuthenticated
})
const mapDispatchToProps = {
    setCurrentMessage
}
export default connect(mapStateToProps, mapDispatchToProps)(MessageBody)
import React from 'react';
import Splash from '../containers/SplashContainer';
import TopNavContainer from '../containers/TopNavContainer';
import Nav from '../containers/NavContainer';
import PatientHistory from '../containers/PatientHistoryContainer'
import SlidingNav from '../containers/SlidingNavContainer';
import BiomarkersOfAging from '../containers/BiomarkersOfAgingContainer';
import ComparingBiomarkers from '../containers/ComparingBiomarkersContainer';
import HealthDataContainer from '../containers/HealthDataContainer';
import TreatmentPlan from '../containers/TreatmentPlanContainer';
import Medication from '../containers/MedicationContainer';
import MetaRecommendations from '../containers/MetaRecommendationsContainer';
import Interpretations from '../containers/InterpretationsContainer';
import DownloadReport from '../containers/DownloadReportContainer';
import Drawer from '../containers/DrawerContainer';
import './Analytics.scss'
import '../../../assets/Analytics-JS/Analytics-Scroll'
import labels from '../../../config/localization';
import Loader from '../../../components/Loader';
import constants from '../../../config/constants';
import Loading from '../containers/LoadingContainer';
import PatientReportDownloadTemplate from '../../Visits/components/PatientReportDownloadTemplate';
import PatientReportDownloadTemplatePast from '../../Visits/components/PatientReportDownloadTemplatePast';
import VisitSummaryTemplate from './VisitSummary';
import { resetTimer, timerDecrement, refreshTokenTimer, tokenTimerIncrement, tokenTimerReset } from '../../../utils/resolve-timer'
import { scroller } from 'react-scroll';
import { uniformString } from '../tools/helpers';
import parseJWT from '../../../utils/parseJWT';
import DocumentList from './DocumentListComponent';
import RecommendationInputComponent from '../components/RecommendationInputComponent';
import MedicationInputComponent from './MedicationInputComponent';
import FormControl from '../../../components/FormControls/FormControl';
import Toaster from '../../../components/Toaster';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QuestionaireForm from './QuestionaireForm';
import DrawerWarningComponent from '../containers/DrawerWarningContainer';
import HistoryRecommendations from './HistoryRecommendations';
import StopRecommendationWarning from './StopRecommendationWarning';
import StopMedicationWarning from './StopMedicationWarning';
import DrawerMedicationWarningComponent from '../containers/DrawerMedicationWarningContainer';
import ProgressLoader from '../../../components/ProgressLoader';

function Plan(props) {
  return (
    <div className="recommendation-review">
      <div className="photo-bg Further"></div>
      <div className="content">
        {props.children}
      </div>
    </div>
  )
}

toast.configure();
export default class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.hashBiomarkerDrawer;
    this.hashDatumDrawer;
    this.hashReportCardDrawer;
    this.hashCategoryDrawer;
    this.hashSectionDrawer;
    this.hashDrawer = null;
    this.toastId = React.createRef(null);
    this.QuestionaireToasterNotification = this.QuestionaireToasterNotification.bind(this);
    this.submittedToast = this.submittedToast.bind(this);
    window.addEventListener('click', (e) => {
      if ((document.getElementsByClassName('open-drawer drawer-shade')
        && document.getElementsByClassName('open-drawer drawer-shade')[0]
        && document.getElementsByClassName('open-drawer drawer-shade')[0].contains(e.target))) {
        this.props.closeSideBar(this.props.history);
        // this.props.closeSideBarOnClose()

        // if (window.location && window.location.hash && (history.length <= 3 || localStorage.getItem('canOpen'))) {
        //   // window.history.back()
        //   localStorage.removeItem('canOpen')
        //   this.props.history.replace({ ...window.location, hash: '' })
        // }
        // else if (window.location && window.location.hash) {
        //   window.history.back()
        // }
      }
      if (document.getElementsByClassName('menu-nav sidebar-left active-sidebar')
        && document.getElementsByClassName('menu-nav sidebar-left active-sidebar')[0]
        && (!document.getElementsByClassName('menu-nav sidebar-left active-sidebar')[0].contains(e.target)
          && !document.getElementsByClassName('max-container')[0].contains(e.target)) && window.innerWidth <= 1024) {
        this.props.toggleMenu(false);
      }
      if (document.getElementsByClassName('open-drawer drawer-shade')
        ? document.getElementsByClassName('open-drawer drawer-shade')[0] : '') {
        document.body.style.overflow = 'hidden';
      }
    });
    document.title = `${labels.physioage_label}: ${labels.sites_labels.analytics_label}`
    window.addEventListener('load', () => {
      refreshTokenTimerHandler = setInterval(() => {
        this.props.checkAccessToken()
      }, constants.min_for_refresh * 60 * 1000);
    })
    // window.addEventListener('keypress', () => {
    //   this.props.checkAccessToken()
    // })
    // window.addEventListener('click', () => {
    //   this.props.checkAccessToken()
    // })
    // window.addEventListener('mousemove', () => {
    //   this.props.checkAccessToken()
    // })
    // window.addEventListener('mousedown', () => {
    //   this.props.checkAccessToken()
    // })
    // window.addEventListener('touchstart', () => {
    //   this.props.checkAccessToken()
    // })
    // clearInterval(idleTimerHandler)
    // idleTimerHandler = setInterval(() => {
    //   timerDecrement()
    // }, 1000);
  }
  componentWillMount() {
    if (localStorage && (!localStorage.access_token || this.checkOpenStatusWithAccessToken()) && this.props.location && this.props.location.hash) {
      localStorage.setItem('canOpen', 1)
    }
  }
  checkOpenStatusWithAccessToken() {
    let parsedToken = parseJWT()
    let isTokenRefreshed = localStorage.getItem('isTokenRefreshed')
    if (Object.keys(parsedToken).length) {
      if (Date.now() > (parsedToken.exp * 1000)) {
        return true
      }
      //if expiration of token is in 40min or less
      else if (((parsedToken.exp * 1000) - Date.now() <= constants.timeout_min_for_expire * 60 * 1000) && !isNaN(parseInt(isTokenRefreshed)) && !parseInt(isTokenRefreshed)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  QuestionaireToasterNotification() {
    return (
      <div className="old_questionnaire">
        {/* <h5 className="text-dark">Dear Visitor</h5> */}
        <p className="text-secondary"> To help us better interpret your results, please complete the following brief questionnaires
          {/* We would like to know you a little bit better<br />
          May we ask you few simple questions */}
        </p>
        <div className="d-flex flex-row">
          <button type="button" className="btn surveyRejectBtn mr-5" onClick={() => (toast.dismiss(this && this.toastId && this.toastId.current ? this.toastId.current : null))}>Maybe later</button>
          <button type="button" className="btn surveyAcceptBtn" onClick={() => { this.props.toggleSurveyPopup(true) }}>Yes sure!</button>
        </div>
      </div>
    )
  }

  submittedToast() {
    // toast.update(this.toastId.current, {
    //     render: ()=><h6>Successfully Submitted</h6>,
    //     autoClose: 3000,
    //     type: toast.TYPE.SUCCESS,
    //     transition: Flip,
    //     delay: 100,
    //     hideProgressBar: false,
    //     progress: undefined,
    //  });
    toast.dismiss(this && this.toastId && this.toastId.current ? this.toastId.current : null)
  }

  loadPendo() {
    var randomId = "demo_" + Math.random()
    pendo.initialize({
      visitor: {
        id: randomId
      },
      account: {
        id: randomId
      }
    });
  }

  isNullOrEmpty(value) {
    return (value === null || value === "");
  }
  updatedMetaRecommendations(recommendations, metaRecommendations) {
    const newMetaRecommendations = Array.isArray(metaRecommendations) && Array.isArray(recommendations) ? metaRecommendations.filter(metaRecommendation => {
        return !recommendations.some(recommendation => {
            const matchBody = (this.isNullOrEmpty(recommendation.body) && this.isNullOrEmpty(metaRecommendation.body)) ? true : recommendation.body && metaRecommendation.body && 
                recommendation.body.replace(/\s/g, '').toLowerCase() === metaRecommendation.body.replace(/\s/g, '').toLowerCase();

            const matchBrand = (this.isNullOrEmpty(recommendation.brand) && this.isNullOrEmpty(metaRecommendation.brand)) ? true : recommendation.brand && metaRecommendation.brand &&
                recommendation.brand.replace(/\s/g, '').toLowerCase() === metaRecommendation.brand.replace(/\s/g, '').toLowerCase();

            const matchGroup = (this.isNullOrEmpty(recommendation.group) && this.isNullOrEmpty(metaRecommendation.group)) ? true : recommendation.group && metaRecommendation.group &&
                recommendation.group.replace(/\s/g, '').toLowerCase() === metaRecommendation.group.replace(/\s/g, '').toLowerCase();

            return matchBody && matchBrand && matchGroup;
        });
    }) : []
    
    return newMetaRecommendations
}

  componentDidMount() {
    this.props.unMountingLoadingComponent(false);
    this.props.toggleSurveyPopup(false);
    this.props.toggleHistoryRecommendationPopup(false);
    this.props.toggleStopRecommendationPopup(false);
    this.props.toggleStopMedicationPopup(false);
    this.props.togglePlanModal(false);
    this.props.toggleMedicationModal(false)
    if (this.props.location && this.props.location.pathname && this.props.location.pathname.indexOf('demo') > -1) {
      this.loadPendo()
      this.props.loadDemoData()
      if (this.props.location && this.props.location.hash && !this.hashDrawer) {
        this.hashDrawer = this.props.location.hash.replace('#', '')
        if (this.hashDrawer) {
          this.props.setInitialHash(this.hashDrawer)
          // this.props.history.push({ ...window.location, hash: '' })

          // localStorage.setItem('previousurl', location.pathname)
          // localStorage.setItem('currenturl', `${location.pathname}${location.search}${location.hash}`)

          // this.props.history.push({ ...window.location, hash: `#${this.hashDrawer}` })
        }
      }
      this.drawerActions()
      this.unlisten = this.props.history.listen((location, action) => {


        // if (action == "POP" && this.props.location.hash) {
        //   this.props.history.goBack()
        // } else {

        if (this.props.location && this.props.location.hash) {
          this.hashDrawer = this.props.location.hash.replace('#', '')
          if (this.hashDrawer) {
            this.props.setInitialHash(this.hashDrawer)
          }
        }
        let currentUrl = localStorage.getItem('currenturl')
        if (currentUrl) {
          localStorage.setItem('previousurl', currentUrl)
        }
        localStorage.setItem('currenturl', `${location.pathname}${location.search}${location.hash}`)

        setTimeout(() => {
          this.drawerActions()
        })
        // }
      })
    } else {
      this.props.checkAccessToken()
      clearInterval(refreshTokenTimerHandler)
      refreshTokenTimerHandler = setInterval(() => {
        this.props.checkAccessToken()
      }, constants.min_for_refresh * 60 * 1000);
      this.props.toggleToaster(true)
      if (this.props.location && this.props.location.hash && !this.hashDrawer) {
        this.hashDrawer = this.props.location.hash.replace('#', '')
        if (this.hashDrawer) {
          this.props.setInitialHash(this.hashDrawer)
          // this.props.history.push({ ...window.location, hash: '' })

          // localStorage.setItem('previousurl', location.pathname)
          // localStorage.setItem('currenturl', `${location.pathname}${location.search}${location.hash}`)

          // this.props.history.push({ ...window.location, hash: `#${this.hashDrawer}` })
        }
      }
      this.drawerActions()
      // refreshTokenTimerHandler = setInterval(() => {
      //   tokenTimerIncrement()
      //   if (this.props && refreshTokenTimer == (constants.min_for_refresh * 60))
      //     this.props.autoRefreshToken(false, this.props.history)
      // }, 1000);
      this.props.getAuthStatus()
      // if (this.props.location && this.props.location.pathname && this.props.location.pathname.indexOf('demo') > -1) {
      //   this.props.loadDemoData()
      // } else {
      if (!this.props.isAuthenticated) {
        window.location.replace('/login')
      }
      else {
        if (this.props.loggedInUser && this.props.loggedInUser.role != constants.logged_roles.PT) {
          this.props.resetLoader(this.props.match.params.id)
        } else if (this.props.loggedInUser.role == constants.logged_roles.PT && !this.props.match.params.id) {
          this.props.resetLoader()
        } else if (this.props.loggedInUser.role == constants.logged_roles.PT && this.props.match.params.id) {
          this.props.history.push('/statuscode/404');
        }
      }
      // }
      this.props.setHistory(this.props.history)

      this.unlisten = this.props.history.listen((location, action) => {

        // if (action == "POP" && this.props.location.hash) {
        //   this.props.history.goBack()
        // } else {

        if (this.props.location && this.props.location.hash) {
          this.hashDrawer = this.props.location.hash.replace('#', '')
          if (this.hashDrawer) {
            this.props.setInitialHash(this.hashDrawer)
          }
        }
        let currentUrl = localStorage.getItem('currenturl')
        if (currentUrl) {
          localStorage.setItem('previousurl', currentUrl)
        }
        localStorage.setItem('currenturl', `${location.pathname}${location.search}${location.hash}`)

        setTimeout(() => {
          this.drawerActions()
        })
        // }
      })
    }
  }

  drawerActions() {
    // setTimeout(() => {
    // if (Object.values(constants.biographical_labels.agesForDrawer).indexOf(this.hashDrawer) != -1) {}
    if (this.props.hashBiomarkerDrawer) {
      let ad = constants.biographical_labels.historic_data_labels[this.props.hashBiomarkerDrawer]
      if (this.props.hashBiomarkerDrawer
        && document.getElementById(`biomarker-${this.props.hashBiomarkerDrawer}`)
        && this.props.historic_data_chart
        && typeof (this.props.historic_data_chart[ad]) == constants.object
        && this.props.historic_data_chart[ad].length > 0) {
        scroller.scrollTo(`biomarker-${this.props.hashBiomarkerDrawer}`, { offset: -70 })
        document.getElementById(`biomarker-${this.props.hashBiomarkerDrawer}`).click()
      }
    } else if (this.props.hashDatumDrawer) {
      var drawerId = `datumdrawer-${uniformString(this.props.hashDatumDrawer)}`
      if (this.props.hashDatumDrawer
        && document.getElementById(drawerId)) {
        scroller.scrollTo(drawerId, { offset: -70 })
        document.getElementById(drawerId).click()
      }
    }
    else if (this.props.hashReportCardDrawer) {
      var drawerId = `${uniformString(this.props.hashReportCardDrawer)}`
      if (this.props.hashReportCardDrawer
        && document.getElementById(drawerId)) {
        scroller.scrollTo(drawerId, { offset: -70 })
        document.getElementById(drawerId).click()
      }
    }
    else if (this.props.hashCategoryDrawer) {
      var drawerId = `${uniformString(this.props.hashCategoryDrawer)}`
      if (this.props.hashCategoryDrawer
        && document.getElementById(drawerId)) {
        scroller.scrollTo(drawerId, { offset: -70 })
        document.getElementById(drawerId).click()
      }
    }
    else if (this.props.hashSectionDrawer) {
      var drawerId = `${uniformString(this.props.hashSectionDrawer)}`
      if (this.props.hashSectionDrawer
        && document.getElementById(drawerId)) {
        scroller.scrollTo(drawerId, { offset: -70 })
        document.getElementById(drawerId).click()
      }
    }
    else {
      // this.props.closeSideBar()
    }
    // });
  }

  componentWillUpdate(newProps) {
    if ((this.props.location && this.props.location.hash) && this.props.location !== newProps.location && !newProps.location.hash) {
      if ((document.getElementsByClassName('open-drawer drawer-shade')) && (document.getElementsByClassName('open-drawer drawer-shade')).length && newProps.isSideBarActive) {
        // this.props.closeSideBarOnClose()
        this.props.closeSideBar()
      }
    }
  }


  componentWillUnmount(e) {
    // this.props.closeSideBarOnClose()
    this.props && !this.props.isDemo ? this.unlisten() : ''
    document.body.style.overflow = 'auto';
    if (!this.props.showSurveyToaster) {
      toast.dismiss();
    }
  }
  render() {
    // if (this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.PT {
    if (this.props.showSurveyToaster) {
      this.toastId.current = toast(this.QuestionaireToasterNotification(), {
        autoClose: false,
        closeOnClick: false,
        // delay: 4000
      });
      this.props.toggleSurveyToaster(false);
    }
    return (
      <div className='page-wrapper palette_default analytics-screen'>
        <React.Fragment>
          {!this.props.unMountLoadingComponent ? <Loading /> : ''}
          {this.props.isLoading ? this.submittedToast() : null}
          {this.props.isLoading && !this.props.isReportLoading  ? <Loader /> : null}
          {this.props.isReportLoading ? <ProgressLoader isProgressLoading={this.props.isLoading} /> : null}
          {this.props.surveyPopup && !this.props.isLoading && this.props.surveyData ? <QuestionaireForm submittedToast={() => (this.submittedToast())} {...this.props} /> : null}
          {this.props.showDrawerWarning || this.props.recommendFormWarning ? <DrawerWarningComponent /> : null}
          {this.props.medicationFormWarning ? <DrawerMedicationWarningComponent /> : null}
          {this.props.stopRecommendationPopup && this.props.stopRecommendId ? <StopRecommendationWarning {...this.props} /> : ''}
          {this.props.stopMedicationPopup && this.props.stopRecommendId ? <StopMedicationWarning {...this.props} /> : ''}
          {this.props.presentPatient && this.props.presentPatient.priorVisitId != 0 && this.props.historyRecommendationPopup && !this.props.isLoading ? <HistoryRecommendations {...this.props} /> : null}
          {!this.props.isInternetError && this.props.siteData && this.props.terms ?
            <div>
              {/* {this.props.routeFailureCount && this.props.loggedInUser.role == constants.logged_roles.AN
                ? <Toaster toastHeaderText='Message Failures' {...this.props} onClose={this.props.toggleToaster} /> : null} */}
              <TopNavContainer {...this.props} />

              <Splash />
              <SlidingNav />
              <div className='health-content'>
                <div className={this.props.isMenuActive ? 'nav-overlay' : ''}></div>
                <Nav {...this.props} />
                <div className='content'>
                  <PatientHistory />
                  <BiomarkersOfAging hashDrawer={this.props.hashBiomarkerDrawer} {...this.props} />
                  <ComparingBiomarkers />
                  {this.props.isRecommendationsLoading ? <center><i className='fa fa-spinner fa-spin'></i></center> :
                      <Medication />}
                  <HealthDataContainer hashDrawer={this.props.hashDatumDrawer} hashReportDrawer={this.props.hashReportCardDrawer}
                    hashCategoriesDrawer={this.props.hashCategoryDrawer} hashSectionsDrawer={this.props.hashSectionDrawer} {...this.props} />
                  <Plan>
                    {this.props.interpretations && this.props.interpretations.length > 0 ? <Interpretations /> : ''}
                    {this.props.isRecommendationsLoading ? <center><i className='fa fa-spinner fa-spin'></i></center> :
                      <TreatmentPlan />}
                    {this.props.metaRecommendations && this.props.recommendations && this.props.recommendations.length > 0 
                    && this.props.metaRecommendations.length > 0 && this.updatedMetaRecommendations(this.props.recommendations, this.props.metaRecommendations) 
                    && this.updatedMetaRecommendations(this.props.recommendations, this.props.metaRecommendations).length > 0  ? <MetaRecommendations /> : null}
                    {!this.props.isDemo && this.props.presentPatient && this.props.presentPatient.priorVisitId != 0 && !this.props.isRecommendationsLoading ?
                      <div className="max-container mt-5"><button onClick={() => this.props.getHistoryRecommendations()} className="btn btn-primary d-flex align-items-center" style={{ marginLeft: '50%' }}>Prior Recommendations <i className='fa fa-chevron-circle-right ml-2' style={{ fontSize: '20px', color: 'white' }}></i></button></div> : ''}
                    <DocumentList {...this.props} />
                    <DownloadReport {...this.props} />
                  </Plan>
                </div>
                <Drawer />

              </div></div> : ''}
          {this.props.isVisitSummary ?
            <div className='display-none'>
              <VisitSummaryTemplate {...this.props} />
            </div>
            : null}
          {this.props.isPatientReport && this.props.patientReportDownloadTemplateSection ?
            <div className='display-none reports-download-section'>
              <PatientReportDownloadTemplate {...this.props} />
            </div> : this.props.isPatientReport && this.props.patientReportDownloadTemplatePastSection ? <div className='display-none reports-download-section'>
              <PatientReportDownloadTemplatePast {...this.props} />
            </div> : null
          }
          {this.props.isDemo || (this.props.loggedInUser && (this.props.loggedInUser.role == constants.logged_roles.AN || this.props.loggedInUser.role == constants.logged_roles.CG)) ?
            <div className={`modal fade ${this.props.showPlanModal ? 'show' : ''}`} role='dialog' style={{ zIndex: 10000 }}>
              <div className='modal-dialog  modal-dialog-centered modal-md'>
                <div className='modal-content'>
                  <div className='modal-body'>
                    <h4><b>Add Recommendation</b></h4>
                  </div>
                  <div class="p-4"><RecommendationInputComponent {...this.props} isPlanModal={true} /></div>
                  <div className='modal-footer justify-space-between mx-auto'>
                    <FormControl type='button'
                      btnType='default'
                      text={labels.help_page_labels.cancel_btn}
                      onClick={() => {
                        let group = this.props.recommendationForm && this.props.recommendationForm.group ? this.props.recommendationForm.group : 'General Instructions';
                        let brand = this.props.recommendationForm && this.props.recommendationForm.brand ? this.props.recommendationForm.brand : '';
                        let body = this.props.recommendationForm && this.props.recommendationForm.body ? this.props.recommendationForm.body : "";
                        let strength = this.props.recommendationForm && this.props.recommendationForm.strength ? this.props.recommendationForm.strength : "";
                        let recommendationStrengthType = this.props.recommendationForm && this.props.recommendationForm.recommendationStrengthType ? this.props.recommendationForm.recommendationStrengthType : "" ;
                        let instructions = this.props.recommendationForm && this.props.recommendationForm.instructions ? this.props.recommendationForm.instructions : "";
                        let notes = this.props.recommendationForm && this.props.recommendationForm.notes ? this.props.recommendationForm.notes : "";
                        let startedAt = this.props.recommendationForm && this.props.recommendationForm.startedAt ? this.props.recommendationForm.startedAt : "";
                        let endedAt = this.props.recommendationForm && this.props.recommendationForm.endedAt ? this.props.recommendationForm.endedAt : "";
                        let productType = this.props.recommendationForm && this.props.recommendationForm.productType ? this.props.recommendationForm.productType : "";
                        this.props.showNoFormChangesError(false);
                        let recommendationForm = { group: group, brand: brand, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt, productType: productType }
                        let initialRecommendationForm = this.props.initialRecommendationForm ? { ...this.props.initialRecommendationForm } : { group: 'General Instructions' };
                        Object.keys(recommendationForm).forEach((p) => recommendationForm[p] == null || recommendationForm[p] == undefined || recommendationForm[p] == '' ? delete recommendationForm[p] : '');
                        Object.keys(initialRecommendationForm).forEach((p) => initialRecommendationForm[p] == null || initialRecommendationForm[p] == undefined || initialRecommendationForm[p] == '' ? delete initialRecommendationForm[p] : '');
                        if (recommendationForm == "" || _.isEqual(recommendationForm, initialRecommendationForm)) {
                          this.props.togglePlanModal(false);
                          this.props.toggleMetaRecommendations(false);
                        }
                        else
                          this.props.toggleRecommendFormWarning(true);
                      }} ></FormControl>
                  </div>
                </div>
              </div>
            </div> : null}
            {this.props.isDemo || (this.props.loggedInUser && (this.props.loggedInUser.role == constants.logged_roles.AN || this.props.loggedInUser.role == constants.logged_roles.CG)) ?
              <div className={`modal fade ${this.props.showMedicationModal ? 'show' : ''}`} role='dialog' style={{ zIndex: 10000 }}>
                <div className='modal-dialog  modal-dialog-centered modal-md'>
                  <div className='modal-content'>
                    <div className='modal-body'>
                      <h4><b>Add Medication</b></h4>
                    </div>
                    <div class="p-4"><MedicationInputComponent {...this.props} isPlanModal={true} /></div>
                    <div className='modal-footer justify-space-between mx-auto'>
                      <FormControl type='button'
                        btnType='default'
                        text={labels.help_page_labels.cancel_btn}
                        onClick={() => {
                          let group = this.props.medicationForm && this.props.medicationForm.group ? this.props.medicationForm.group : 'Supplement';
                          let brand = this.props.medicationForm && this.props.medicationForm.brand ? this.props.medicationForm.brand : "";
                          let body = this.props.medicationForm && this.props.medicationForm.body ? this.props.medicationForm.body : "";
                          let strength = this.props.medicationForm && this.props.medicationForm.strength ? this.props.medicationForm.strength : "";
                          let recommendationStrengthType = this.props.medicationForm && this.props.medicationForm.recommendationStrengthType ? this.props.medicationForm.recommendationStrengthType : "";
                          let instructions = this.props.medicationForm && this.props.medicationForm.instructions ? this.props.medicationForm.instructions : "";
                          let notes = this.props.medicationForm && this.props.medicationForm.notes ? this.props.medicationForm.notes : "";
                          let startedAt = this.props.medicationForm && this.props.medicationForm.startedAt ? this.props.medicationForm.startedAt : "";
                          let endedAt = this.props.medicationForm && this.props.medicationForm.endedAt ? this.props.medicationForm.endedAt : "";
                          let productType = this.props.medicationForm && this.props.medicationForm.productType ? this.props.medicationForm.productType : "";

                          this.props.showNoMedicationChangesError(false);
                          let medicationForm = { group: group,brand: brand, body: body, strength: strength,recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt, productType: productType }
                          let initialMedicationForm = this.props.initialMedicationForm ? { ...this.props.initialMedicationForm } : { group: 'Supplement' };
                          Object.keys(medicationForm).forEach((p) => medicationForm[p] == null || medicationForm[p] == undefined || medicationForm[p] == '' ? delete medicationForm[p] : '');
                          Object.keys(initialMedicationForm).forEach((p) => initialMedicationForm[p] == null || initialMedicationForm[p] == undefined || initialMedicationForm[p] == '' ? delete initialMedicationForm[p] : '');
                          if (medicationForm == "" || _.isEqual(medicationForm, initialMedicationForm)) {
                            this.props.toggleMedicationModal(false);
                            this.props.toggleMetaRecommendations(false);
                          }
                          else
                            this.props.toggleMedicationFormWarning(true);
                        }} ></FormControl>
                    </div>
                  </div>
                </div>
              </div> : null
            }  
        </React.Fragment>
      </div>
    );
  }
}

import React, { Component } from 'react';
import _ from 'lodash';
import './documents-styles.scss';
import ReactDOM from 'react-dom';


class HandleDownloadDocument extends Component {
    componentDidMount() {
        const headerSections = document.querySelectorAll('.header-section.fixed-header');
            headerSections.forEach(element => {
                element.innerHTML = '';
        });
        document.body.style = 'background: #ffffff!important';
        document.title = window.location.href
        if (this.props.match.params && this.props.match.params.documentId) {
            let documentId = _.head(this.props.match.params.documentId.split('.'))
            if ( this.props.match.params.action && this.props.match.params.action == "download"){
                this.props.downloadDocument({documentId: documentId}, this.props.history, false, true)
            }    
            else
                this.props.setDocumentView(documentId)

        }
    }
    componentDidUpdate() {
        document.body.style.overflow = 'hidden'
        if (this.props.isLoading != undefined && !this.props.isLoading){
            this.removeAccessToken();
        }
    }
    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }
    removeAccessToken() {
        if (localStorage.getItem('access_token'))
            localStorage.removeItem('access_token');
        if (localStorage.getItem('refreshToken'))
            localStorage.removeItem('refreshToken');
        // const rootElement = document.getElementById('root');
        // if (rootElement) {
        //     ReactDOM.render(
        //         <div className='p-5'>
        //             Successfully downloaded the patient document. Now please close the browser and continue exploring the analytics.
        //         </div>,
        //         rootElement
        //     );
        // }    
    }
    render() {
        return this.props.libraryDocument && this.props.libraryDocument.documentFileContent
            ? this.props.isImage
                ?
                <a className='document-image'
                    href={`data:${this.props.libraryDocument.fileContentType};base64,${this.props.libraryDocument.documentFileContent}`}
                    download={this.props.libraryDocument.title}>
                    <img
                        src={`data:${this.props.libraryDocument.fileContentType};base64,${this.props.libraryDocument.documentFileContent}`}
                    />
                </a>
                :
                <a className='document-pdf'
                    href={this.props.downloadLink}
                    download={this.props.libraryDocument.title}>
                    <object data={this.props.downloadLink} />
                </a>
            : <></>

    }
}
export default HandleDownloadDocument
import {connect} from 'react-redux';
import DrawerMedicationWarningComponent from '../components/DrawerMedicationWarningComponent';

import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
    ...state.analytics
})
const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawerMedicationWarningComponent);
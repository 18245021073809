import React from 'react';
import { Switch, Route } from 'react-router-dom';

function SpreadingRoutes(props) {
    var componentProps = props
    return (
        <Switch>
            {componentProps && componentProps.routes ? componentProps.routes.map((route, index) => {
                return route.render ?
                    <route.render /> :
                    <Route
                        path={route.path}
                        render={(props) => {
                            return (
                                <route.component {...componentProps} {...props} routes={route.routes} index={index} />
                            )
                        }}
                    />
            }) : ''}
        </Switch>
    )
}
export default SpreadingRoutes;
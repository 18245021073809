export default `

body {
    font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    page-break-after: avoid;
    color: #896a48;
    font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
    page-break-inside: avoid;
}

a {
    cursor: pointer;
    text-decoration: none !important;
    color: #352274;
}

ul.contents {
    max-width: 7in;
    padding: 0;
    overflow-x: hidden;
    list-style: none}
ul.contents li:before {
    float: left;
    width: 0;
    white-space: nowrap;
/*     content:
//  ". . . . . . . . . . . . . . . . . . . . "
//  ". . . . . . . . . . . . . . . . . . . . "
//  ". . . . . . . . . . . . . . . . . . . . "
//  ". . . . . . . . . . . . . . . . . . . . "
//  ". . . . . . . . . . . . . . . . . . . . " */
}
ul.contents a.xref span:first-child {
    padding-right: 0.33em;
    background: white
}
ul.contents a.xref span:last-child {
    float: right;
    padding-left: 0.33em;
    background: white
}

.chapter,
.passage,
p {
    color: #444 !important;
    text-align: justify;
    line-height: unset;
    font-size: 14px;
}

.headerId {
    color: white;
    font-size: 1px;
    position: absolute;
}

.flexSpaceBetween{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3px;
}

.dottedLine {
    border: none;
    border-bottom: 1px dotted #327ea5;
    z-index: -10;
    left: 42px;
    margin-bottom: -8px;
    position: relative;
    bottom: 15px;
}

.paddingRight {
    padding-right: 3px;
    background-color: white;
}

.paddingLeft {
    padding-left: 3px;
    position: relative;
    top: 3px;
    background-color: white;
    color: #155678;
}

.coverpage {
    padding: 5%;
}

.break-page {
    page-break-after: always;
    column-span: all;
    margin: auto;
}

.break-page-before {
    page-break-before: always!important;
    column-span: all;
    margin: auto;
}

.complete-col-span {
    column-span: all;
    padding-top: 15px;
}

.two-columns-layout {
    column-count: 2;
    column-gap: 40px;
}


table {
    font-family: "Sarala", 'Noto Sans JP' !important;
    font-weight: 400 !important;
    border: none;
    column-span: all;
    width: 100%;
    font-size: 12px;
    border-collapse: collapse;
}

table td {
    word-break: break-all;
    padding: 10px;
    page-break-inside: avoid;
}

th,
thead,
thead tr {
    background-color: #c7b392 !important;
    color: white;
    padding: 10px;
    text-align: left;
    margin-top: 0;
}


tbody {
    color: #444;
    font-weight: 500 !important;
    font-size: 12px;
}

tr {
    line-height: 1 !important;
}

.categoriesPhysio {
    page-break-after: avoid;
    width: 100%;
}
.categoriesPhysio h3 {
    background-color: #eae2d5;
    font-family: "Sarala", 'Noto Sans JP';
    color: #444;
    font-size: smaller;
    font-weight: 700 !important;
    padding: 10px;
    margin: 0;
    column-span: all;
    page-break-after: avoid;
}

.spanForAlignment {
    padding-right: 20px;
    word-break: break-all;
}

.physicianReportRender {
    height: 0;
    overflow: hidden;
}

iframe {
    border: none;
}

.aggregate-chart-card {
    margin: 15px;
}

.card {
    margin-bottom: 30px;
}

.reportImg img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 4in;
    max-height: 2.5in;
}

.title {
    font-family: 'Alegreya Sans SC', 'Noto Sans JP', sans-serif;
    text-align: center;
    color: #0091c7 !important;
    font-weight: 500;
    font-size: 0.60in;
    padding-bottom: 0.5in;
    max-width: 500px;
    margin: 4% auto 0;
}

.subtitle {
    font-family: 'Alegreya Sans SC', 'Noto Sans JP', sans-serif;
    font-weight: lighter;
    font-size: 0.45in;
    text-align: center;
    color: #896a48;
    font-weight: 300;
}

.patient-identification {
    margin-bottom: 30px;
    display: grid;
    text-align: left;
    left: 0;
    bottom: 0;
    position: absolute;
    padding-bottom: 10%;
    padding-left: 5%;
}

.title-block {
    display: inline-block;
    vertical-align: top;
}

.small-line {
    font-family: 'Alegreya Sans SC', 'Noto Sans JP', sans-serif;
    font-weight: 300;
    font-size: 0.2in;
}

.line {
    font-family: 'Alegreya Sans SC', 'Noto Sans JP', sans-serif;
    font-size: 0.28in;
    font-weight: 500;
    margin-bottom: 20px;
    display: flex;
}

.toc .chapter-heading {
    font-weight: 500;
    font-size: .5in;
    color: #896a48;
    column-span: all;
    font-family: 'Alegreya Sans SC', 'Noto Sans JP', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    white-space: nowrap;
}

.toc ul,
.toc ul ul {
    list-style-type: none;
}

.chapter .chapter-heading {
    font-family: 'Roboto', sans-serif;
    color: #896a48;
    font-size: .4in;
    font-weight: 500;
    column-span: all;
    font-weight: normal;
    margin-top: auto;
    margin-bottom: auto;
    white-space: nowrap;
    font-variant: small-caps;
}

.grade-block {
    font-weight: bolder !important;
}

.report-card-grade {
    width: 48px !important;
    font-weight: 900 !important;
    font-size: 24px;
    padding: 2px !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
}

.chapter-heading {
    font-weight: 500 !important;
}

.healthStatHeadings {
    font-family: 'Alegreya Sans SC', 'Noto Sans JP', sans-serif;
    font-size: 22px !important;
    font-weight: 500 !important;
    line-height: 1.1;
    display: inline-block;
    text-align: left;
}
.passage .healthStatHeadings {
    font-family: 'Roboto', sans-serif;
    font-size: 22px !important;
    font-weight: 400 !important;
    line-height: 1.1;
    display: inline-block;
    text-align: left;
    text-transform: capitalize;
    color: #155678 !important;
}

.passage {
    line-height: 1.1em !important;
}

.chapter {
    page-break-before: always;
}

.section {
    margin-top: 1em;
}

i {
    page-break-inside: avoid !important;
    page-break-before: avoid !important;
    page-break-after: avoid !important;
    page-break-inside: avoid;
}

.section .section-heading:first-of-type {
    margin-top: 0;
}

blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
}

.highcharts-container {
    height: 194px;
    margin-top: 10px !important;
}
.baselinechart {
    height: 205px;
    padding: 0;
    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: avoid;
    margin: auto;
    z-index: 1;
    position: relative;
}
.age_summary {
    z-index: 2;
    position: relative;
}
.passage .passage-heading {
    font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    font-size: .24in;
    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: avoid ;
    padding-bottom: 0.25em;
    color: #155678 !important;
}

.datum {
    clear: both;
    margin: 15px 0 0;
}

.passage .name {
    font-weight: 700;
    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: avoid ;
    color: #155678 !important;
}

.passage .explanation p:first-child {
    display: inline;
}


ul li {
    color: #444;
}

ul li a {
    color: #155678;
    font-size: 17px;
    text-decoration: none;
}

.explanation ul li {
    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: avoid ;
}

.passage ul {
    margin: 0px;
}

.baseline-image img {
    width: 250px;
}

.categoriesReadMore {
    padding-right: 15px;
}

.grade-card {
    position: relative;
    height: 60px;
    width: 98%;
    padding: 0;
    margin: 6px 4px;
    display: inline-table;
}

.grade-card .grade-block {
    background-color: brown;
    position: absolute;
    padding: 12px;
    border-radius: 4px;
    width: 64px;
    text-align: center;
}

.grade-card .description {
    font-size: 20px;
    left: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
    color: #896a48;
    font-family: 'Roboto', sans-serif;
    max-width: 220px;
    word-break: break-word;
    text-align: left;
}

.grade-card .details,
.grade-card .reference {
    color: #352271 !important;
    font-size: 14px;
    left: 100px;
    position: initial;
    top: 32px;
    margin-left: 100px;
}

table .grade-block {
    background-color: #a58274;
    color: white;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0px;
    max-width: 25px;
}

.highcharts-credits {
    display: none;
}

.highcharts-title {
    display: none;
}

.highcharts-legend-item {
    display: none;
}

.danger {
    color: red;
}

.success {
    color: green;
}

.full-width {
    width: 92% !important;
    display: block;
    width: auto;
    margin-top: -29px;
    margin-bottom: -25px;
    padding-left: 13px;
    padding-right: 13px;
}

.full-width-huge {
    width: 92% !important;
    display: block;
    width: auto;
    margin-top: -29px;
    margin-bottom: -25px;
    padding-left: 13px;
    padding-right: 13px;
}
.age_summary b, .passage strong, .passage li::marker {
    color: #155678 !important;
 }
 
.spectrum {
    page-break-inside: avoid;
}

.section-heading {
    page-break-after: avoid ;
    page-break-inside: avoid;
    margin: 0;
    color: #155678;
    font-size: 30px;
    font-weight: bold;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
}

.react-high-chart {
    padding: 0;
    page-break-inside: avoid ;
    page-break-before: avoid ;
    page-break-after: avoid;
    margin: auto;
    height: 205px;
}
.react-high-chart-noumenon {
    height: 60px
}
.grade-Ap.grade-block {
    color: white;
    background-color: #2eb263;
}

.grade-A.grade-block {
    color: white;
    background-color: #37b45e;
}


.grade-Am.grade-block {
    color: white;
    background-color: #48b854;
}

.grade-Bp.grade-block {
    color: white;
    background-color: #59bc4a;
}

.grade-B.grade-block {
    color: white;
    background-color: #72c13b;
}

.grade-Bm.grade-block {
    color: white;
    background-color: #7bc336;
}

.grade-Cp.grade-block {
    color: white;
    background-color: #c7d40a;
}

.grade-C.grade-block {
    color: white;
    background-color: #D8D800;
}

.grade-Cm.grade-block {
    color: white;
    background-color: #cda60e;
}

.grade-Dp.grade-block {
    color: white;
    background-color: #c2771b;
}

.grade-D.grade-block {
    color: white;
    background-color: #ba5225;
}

.grade-Dm.grade-block {
    color: white;
    background-color: #b64129;
}

.grade-F.grade-block {
    color: white;
    background-color: #b2302e;
}

.palette_default {
    display: flex;
}

.physician-details {
    display: flex;
    margin-bottom: 1%;
}

.physician-details .individual {
    margin: 25px;
    display: contents;
    line-height: 2em;
}

.physician-details .individual h5 {
    line-height: 2;
    display: flex;
    font-weight: 400;
}

img.aggregate {
    width: 800px;
    margin-top: 20px;
    page-break-inside: avoid;
}

iframe.aggregate {
    width: 100%;
}

.physician-detail {
    background-color: #5f9be3;
    font-family: "Alegreya Sans SC", 'Noto Sans JP';
    font-weight: bolder;
    color: white;
    padding: 10px;
    margin: 2px 15px;
    white-space: nowrap;
    height: fit-content;
}

.physician-label {
    font-family: "Alegreya Sans SC", 'Noto Sans JP';
    margin-top: inherit;
}

.physician-report-title {
    text-align: justify;
}

.physician-report-title h1 {
    color: #0091c7 !important;
}

.artisen-physician {
    display: flex;
    justify-content: center;
}

tr:nth-of-type(odd) {
    background-color: #f3f3f3 !important;
}

tr:nth-of-type(even) {
    background-color: #f8f8f8 !important;

}

.warn,
.warn::before,
.warn::after {
    position: relative;
    padding: 0;
    margin: 0;
}

.warn {
    font-size: 23px;
    color: transparent;
    font-style: initial;
}

.warning-caution {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    page-break-inside: avoid;
}

.warn.warning {
    display: inline-block;

    top: 0.225em;

    width: 1.15em;
    height: 1.15em;

    overflow: hidden;
    border: none;
    background-color: transparent;
    border-radius: 0.625em;
}

.warn.warning::before {
    content: "";
    display: block;
    top: -0.08em;
    left: 0.0em;
    position: absolute;
    border: transparent 0.6em solid;
    border-bottom-color: #fd3;
    border-bottom-width: 1em;
    border-top-width: 0;
    box-shadow: #999 0 1px 1px;
}

.warn.warning::after {
    display: block;
    position: absolute;
    top: 0.3em;
    left: 0;
    width: 100%;
    padding: 0 1px;
    text-align: center;
    font-family: "Garamond", 'Noto Sans JP';
    content: "!";
    font-size: 0.65em;
    font-weight: bold;
    color: #333;
}

.cover-page-details {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
}

.reports-powered-by {
    font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
    text-align: center;
    bottom: 0;
    right: 0;
    position: absolute;
    padding-bottom: 10%;
    padding-right: 5%;
}

.reports-powered-by img {
    max-width: 0.7in;
    max-height: 0.6in;
}

#exceptional_results .react-bootstrap-table thead,
#abnormal_results .react-bootstrap-table thead,
#historic_data .react-bootstrap-table thead,
#complete_data .react-bootstrap-table thead {
    display: none;
    page-break-inside: avoid;
}

#exceptional_results table tr td:nth-child(n+6),
#abnormal_results table tr td:nth-child(n+6),
#complete_data table tr td:nth-child(n+7) {
    text-align: center!important;
}

#exceptional_results table,
#abnormal_results table,
#historic_data table {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
}
tr{
    page-break-inside: avoid;
}
table th,table td{
    text-align: left;
}
.sourcesPhysician table th,
.sourcesPhysician table td{
            min-width: 25% !important;
            max-width: 25% !important;
            text-align: left;

}
.sourcesPhysician table th:first-child,
.sourcesPhysician table td:first-child {
    min-width: 15% !important;
    max-width: 15% !important;
}
#exceptional_results table tr td{
    min-width: 50px;
    white-space: normal;
    word-break: break-all;
}

#exceptional_results table th {
    min-width: 50px;
    white-space: nowrap;
}

#exceptional_results table tr td:nth-child(2),
#exceptional_results table th:nth-child(2) {
    width: 50px;
}


#exceptional_results table tr td:first-child {
    width: 180px !important;
    word-break: break-all;
    white-space: normal;
    padding-right: 10px;
}

#exceptional_results table th:first-child {
    width: 180px !important;
    word-break: break-word;
    text-align: left;
    white-space: intial !important;
}

#exceptional_results .complete-col-span,
#abnormal_results .complete-col-span,
#historic_data .complete-col-span,
#complete_data .complete-col-span {
    padding-top: 0;
}

#abnormal_results table td,
#abnormal_results table th,
#complete_data table td,
#complete_data table th {
    min-width: 50px !important;
    max-width: 50px !important;
    white-space: normal;
    word-break: break-word;
}

#abnormal_results table td:nth-child(2),
#abnormal_results table th:nth-child(2),
#abnormal_results table td:last-child,
#abnormal_results table th:last-child,
#complete_data table td:first-child,
#complete_data table th:first-child,
#complete_data table td:nth-child(3),
#complete_data table th:nth-child(3),
#complete_data table td:last-child,
#complete_data table th:last-child {
    min-width: 40px !important;
    max-width: 40px !important;
}

#abnormal_results table td:first-child,
#abnormal_results table th:first-child,
#complete_data table td:nth-child(2),
#complete_data table th:nth-child(2) {
    min-width: 100px !important;
    max-width: 100px !important;
}

.absolute-result-header td {
    padding: 10px;
}

#historic_data th,
#historic_data td {
    word-break: break-word;
    white-space: normal;
}
#historic_data table th.dates{
    padding: 0!important;
}
#historic_data table th{
    text-transform: capitalize;
}
#historic_data table td:first-child,
#historic_data table th:first-child{
    min-width: 10%!important;
}
#historic_data table th:not(:first-child),
#historic_data table td:not(:first-child){
    text-align: center!important;
}

#historic_data td:not(:first-child){
    word-break: normal !important;
    white-space: break-spaces !important;
}  

#complete_data table td:nth-child(3),
#complete_data table td:nth-child(4)
    {
        min-width: none !important;
        max-width: none !important;
        word-break: normal !important;
        padding: 10px;
        page-break-inside: avoid;
        white-space: pre !important;
    }
#abnormalResult table td:nth-child(2),
#abnormalResult td:nth-child(3)
    {
        min-width: none !important;
        max-width: none !important;
        word-break: normal !important;
        white-space: pre !important;
    } 
#exceptional_results table td:nth-child(2),
#exceptional_results td:nth-child(3)
    {
        min-width: none !important;;
        white-space: pre !important;
        word-break: normal !important; ;
    }   

#health_status_indicators table td:nth-child(3)
  {
    word-break: normal !important;
    padding: 10px;
    page-break-inside: avoid;
    white-space: pre !important;
  }

#health_status_indicators table td:nth-child(4)
{
    word-break: normal !important;
    padding: 10px;
    page-break-inside: avoid;
    white-space: pre !important;
}

.hdataPhysician h1{
    page-break-after: avoid!important;
    margin: 0!important;
    white-space: nowrap;
}
.d-inline-block{
    display: grid;
}
tr.historic-chart-panel-header{
    background-color: #eae2d5!important;
}

#historic_data strong{
    background-color: #eae2d5!important;
    white-space: nowrap!important;
    overflow-wrap: break-word!important;
}
table th:first-child {
    white-space: nowrap;
}

img.baseline-chart {
    height: 180px;
    width: 230px;
}
.jw-report-recommendation-details{
    page-break-inside: avoid!important;
    display: flex;
}
.jw-report-recommendation-card{
    page-break-inside: avoid!important;
    margin: 20px 0px;
}
.jw-report-recommendation-flex{
    display:flex;
}
.jw-report-icon {
    color: #896a48;
    display: grid;
    vertical-align: text-bottom;
    text-align: center;
    width: 100px;
    page-break-inside: avoid!important;
    font-size: 12px;
    word-break: break-word;
    line-height: 1;
    height: 70px;
}
.jw-report-icon svg{
    max-width: 1cm;
    max-height: 1cm;
    color: #896a48;
    margin: auto;
    page-break-inside: avoid!important;
}
.jw-report-recommendation-content{
    padding: 0;
    max-width: 500px;
    page-break-inside: avoid!important;
    margin-top: 4px;
}
.jw-report-recommendation-content strong{
    font-weight: 1000;
}
.patient-recom-pdf{
    color: #155678 !important;
    text-align: justify;
    line-height: unset;
    font-family: 'Crimson Text', 'Noto Sans JP', serif;
}

.fw-10000 {
    font-weight: 1000;
}

.text-color {
    color: #155678 !important;
}
.noumenon-value-card{
    background-color: #f3f3f3;
}

.bb{
    border-bottom: 1px solid  #0000000d;
}
.pb-2 {
    padding-bottom: 0.5rem !important;
}
.pt-2 {
    padding-top: 0.5rem !important;
}
.p-4 {
    padding: 1.5rem !important;
}
.mb-2 {
    margin-bottom: 0.5rem !important;
}
.ml-3 {
    margin-left: 1rem !important;
}

.pl-4{
    padding-left: 1.5rem !important;
}

.pt-4{
    padding-top: 1.5rem !important;
}

.bt{
    border-top: 1px solid  #0000000d;
}
.mr-3{
    margin-right: 1rem !important;
}

.mb-3{
    margin-bottom: 1rem !important;
}
`
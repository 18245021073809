import { connect } from 'react-redux'
import { setBreadCrumbs, setPageTitle, toggleNotification, setLoggedInUser, navigatePatients, setCreditsCaution } from '../../../reducers/global-reducer';
import IndividualUser from '../components/IndividualUser';
import { actionCreators } from '../modules/user'

const mapStateToProps = (state) => ({
    ...state.users,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify,
    loggedInUser: state.global.loggedInUser,
})
const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    setLoggedInUser,
    navigatePatients,
    setCreditsCaution
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualUser)
import { connect } from 'react-redux';
import OtpScreen from './OtpScreen';
import {sendOTP, validateOTP, toggleNotification, getAuthStatus, handleSuceessDone} from '../../reducers/global-reducer';
import { withRouter } from 'react-router';

const mapStateToProps = state => ({
  ...state.global
});

const mapDispatchToProps = {
  sendOTP,
  validateOTP,
  toggleNotification,
  getAuthStatus, handleSuceessDone
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OtpScreen));

import React from 'react';
import {uniformString} from '../tools/helpers';
import constants from '../../../config/constants';
import labels from '../../../config/localization';

export default class SlidingNavComponent extends React.Component {
  createCategory(category)
  {
    if( category.sections.length > 0 )
    {
      return(
        <li key={category.key + '_nav'}>
          <a className='scrollable spyable' href={'#' + uniformString(category.name, 'category')}>
            {category.name}
          </a>
        </li>
      );
    }
    else
    {
      return(null);
    }
  }

  render()
  {
    return (
      <div className='sidebar-left'>
        <div className='sidebar-content'>
          <nav className='health-categories'>
            <ul className='sidebar nav nav-stacked fixed'>
              <li>
                <a className='scrollable spyable' href='#biographical-info'>
                  {labels.analytics_labels.biographical_information_label}
                </a>
              </li>
              <li>
                <a className='scrollable spyable' href='#biomarkers-of-aging'>
                {labels.analytics_labels.biomarkers_of_aging_label}
                </a>
              </li>
              <li>
                <ul className='sidebar nav nav-stacked fixed'>
                  <li key='repord_card_category_nav'>
                    <a className='scrollable spyable' href='#health_status_indicators'>
                    {labels.analytics_labels.health_card_report_label}
                    </a>
                  </li>
                  {this.props.categories ? this.props.categories.map(this.createCategory):null}
                </ul>
              </li>
              <li>
                <a className='scrollable spyable' href='#recommendations'>
                {labels.analytics_labels.recommendations_label}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

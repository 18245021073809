import React from 'react'
import Authentication from '../containers/AppContainer';
import CoreLayout from '../layouts/CoreLayout';
import UnAuthLayout from '../layouts/UnAuthLayout';
import StatusCode from '../components/StatusCode';
import Patients from '../routes/Patients';
import Login from '../components/Login';
import SignUp from '../components/SignUp';
import SignupPaymentGateway from '../components/SignupPaymentGateway';
import ForgotPassword from '../components/ForgotPassword';
import MultiFactorAuth from '../components/MultiFactorAuth';
import PatientForm from '../routes/Patients/components/PatientForm';
import ViewPatient from '../routes/Patients/components/ViewPatient';
import Feedback from '../routes/Feedback';
import PatientsList from '../routes/Patients/components/PatientsList';
import Users from '../routes/Users';
import UsersList from '../routes/Users/components/UsersList';
import IndividualUser from '../routes/Users/components/IndividualUser';
import Account from '../routes/Users/containers/AccountContainer';
import Credits from '../routes/Credits/containers/CreditsContainer';
import CreditsList from '../routes/Credits/components/CreditsList';
import CreditsForm from '../routes/Credits/components/CreditsForm';
import Receipt from '../routes/Credits/components/Receipt';
import Sites from './Sites';
import SitesList from './Sites/components/SitesList';
import SitesForm from './Sites/components/SiteForm';
import SitesDetails from './Sites/components/SiteDetails';
import Providers from './Providers';
import ProvidersList from './Providers/components/ProvidersList';
import ProviderForm from './Providers/components/ProviderForm';
import ProviderDetails from './Providers/components/ProviderDetails';
import Caregivers from './Caregivers';
import CaregiversList from './Caregivers/components/CaregiversList';
import CaregiverForm from './Caregivers/components/CaregiverForm';
import CaregiverDetails from './Caregivers/components/CaregiverDetails';
import BillingSummary from './BillingSummary';
import MessageRouting from './MessageRouting';
import MessageRoutingList from './MessageRouting/components/MessageRoutingList';
import MessageRoutingForm from './MessageRouting/components/MessageForm';
import Help from './Help';
import CurrentManual from './Help/components/CurrentManual';
import AllManual from './Help/components/AllManuals';
import ManualForm from './Help/components/UploadManual';
import Videos from './Help/components/Videos';
import CreditTransaction from './ELK';
import CreditTransactionList from './ELK/components/CreditTransactionsList';
import CreditTransactionForm from './ELK/components/CreditTransactionForm';
import TermsOfService from '../components/TermsOfService/TermsOfService';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import Corrections from './Corrections';
import Oddities from './Oddities';
import Inbox from './Inbox';
import MessageBody from './Inbox/containers/MessageBodyContainer';
import MessagesList from './Inbox/components/MessagesList';
import ResolveMessage from './Inbox/components/ResolveMessage';
import ActiveSiteReport from './ActiveSiteReports';
import Analytics from './Analytics';
import DataMenu from './Data';
import ExploreNoumena from './Data/components/Noumena';
import VolumeReport from './Data/components/VolumeReport';
import AggregateAgeCharts from './Data/components/AggregateAgeCharts';
import ReportCardGrades from './Data/components/ReportCardGrades';
import Spectra from './Data/components/Spectra';
import NoumenaPreview from './Data/components/NoumenaPreview';
import VADR from './Data/components/VADR';
import IndividualVisit from '../routes/Visits/components/IndividualVisit';
import PhysiciansReport from './Visits/components/PhysiciansReport';
import PhysiciansReportPast from './Visits/components/PhysiciansReportPast';
import IndividualOrder from '../routes/Visits/components/IndividualOrder';
import ResultView from '../routes/Visits/components/ResultView';
import ResetPassword from '../../src/components/ResetPassword';
import SetPassword from '../../src/components/SetPassword';
import SessionExpire from '../../src/components/SessionExpire';
import PatientReport from './Visits/components/PatientReport';
import PatientReportPast from './Visits/components/PatientReportPast';
import VisitsContainer from './Visits/containers/VisitsContainer';
import ReportsContainer from './Visits/containers/ReportsContainer';
import MessageDetails from './Inbox/components/MessageDetails';
import MessageForm from './Inbox/components/MessageForm';
import OdditiesList from './Oddities/components/OdditiesList';
import OddityMessageGroup from './Oddities/components/OddityMessageGroup';
//import ReferralSites from './Sites/components/ReferralSites';
import ObservationsContainer from './Oddities/containers/ObservationsContainer';
import ObservationDetails from './Oddities/components/ObservationDetails';
import VisitSummaryContainer from './Analytics/containers/VisitSummaryContainer';
import VisitSummaryBetaContainer from './AnalyticsBeta/containers/VisitSummaryContainer';
import ObservationsList from './Oddities/components/ObservationsList';
import { Redirect } from 'react-router-dom';
import NewOrder from './Visits/components/NewOrder';
import UserManual from './Help/components/UserManual';
import UserCurrentManual from './Help/components/UserCurrentManual';
import RelComponent from '../components/RelComponent';
import AllPatientsDetails from '../components/AllPatientsDetails';
import PatientDocuments from './Patients/components/PatientDocuments';
import VisitDocuments from './Visits/components/VisitDocuments';
import ApiCollection from '../components/ApiCollection';
import ReferralSites from '../components/ReferralSites';
import UploadSiteDocument from './Sites/components/UploadSiteDocument';
import UploadPatientDocument from './Patients/components/UploadPatientDocument';
import CommonDocuments from './Visits/components/CommonDocuments';
import SiteDocuments from './Sites/components/SiteDocuments';
import SiteDocumentCollection from './Sites/components/SiteDocumentCollection';
import UpdateSiteDocument from './Sites/components/UpdateSiteDocument';
import Documents from '../routes/Documents';
import DocumentsView from './Documents/components/DocumentsView';
import PatientsDocuments from './Patients/components/PatientsDocuments';
import UpdatePatientDocument from './Patients/components/UpdatePatientDocument';
import UploadVisitDocument from './Visits/components/UploadVisitDocument';
import FailureMessages from './Inbox/components/FailureMessages';
import NoumenaDistributionContainer from './Data/containers/NoumenaDistributionContainer';
import TermsAndConditions from '../components/TermsAndConditions/TermsAndConditions';
import NewQuestionnaire from './Visits/components/NewQuestionnaire';
import Customizations from  './Sites/components/Customizations';
import CustomizationsForm from  './Sites/components/CustomizationsForm';
import CustomizationsDetails from  './Sites/components/CustomizationsDetails';
import RubricForm from './Sites/components/RubricForm';
import RangeForm from './Sites/components/RangeForm';
import HandleDownloadReport from './Visits/components/HandleDownloadReport';
import AnalyticsBeta from './AnalyticsBeta';
import HandleBiomarkerComparison from './AnalyticsBeta/containers/HandleBiomarkerComparisonContainer';
import HandleDownloadDocument from './Documents/components/HandleDownloadDocument';
import OnboardingVideo from '../components/SignUp/onboardingVideo';
import FeedbackList from './Feedback/containers/FeedBackListContainer';
import CompareNoumena from './Data/components/CompareNoumena';
import UserSetting from './Users/containers/UserSettingContainer';
import OtpScreen from '../components/OtpScreen';

export const getRoutes = store => {
  return [
    {
      path: '/demo_1',
      component: AnalyticsBeta,
      exact: true
    },
    {
      path: '/demo_2',
      component: AnalyticsBeta,
      exact: true
    },
    {
      path: '/demo',
      render: () => (<Redirect to='/demo_1' />)
    },
    {
      path: '/sample_data/demo_1_visit_summary.pdf',
      component: VisitSummaryBetaContainer,
      exact: true
    },
    {
      path: '/sample_data/demo_2_visit_summary.pdf',
      component: VisitSummaryBetaContainer,
      exact: true
    },
    {
      path: '/patients/:id/analytics',
      component: AnalyticsBeta,
      exact: true,
      roles: ['admin', 'caregiver']
    },
    {
      path: '/patients/:id/analytics/demo',
      component: AnalyticsBeta,
      exact: true,
      roles: ['admin']
    },
    {
      path: '/visits/:id/summary.pdf',
      component: VisitSummaryBetaContainer,
      search: '?locale=locale',
      exact: true,
      roles: ['admin', 'caregiver']
    },
    {
      path: '/analytics',
      component: AnalyticsBeta,
      exact: true,
      roles: ['patient']
    },
    {
      path: '/analytics/:anyString',
      render: () => (<Redirect to="/statuscode/404" />),
      roles: ['patient']
    },
    {
      path: '/analytics_old',
      render: () => (<Redirect to='/analytics' />)
    }, 
    {
      path: '/login',
      component: UnAuthLayout(Login),
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/signup',
      component: SignUp,
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/forgotpassword',
      component: UnAuthLayout(ForgotPassword),
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/verify',
      component: UnAuthLayout(MultiFactorAuth),
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/otp',
      component: OtpScreen,
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/noumena/:noumena/:chart',
      component: NoumenaDistributionContainer,
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/setpassword/:perishcode',
      component: SetPassword,
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/resetpassword/:token/:perishcode',
      component: ResetPassword,
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/statuscode/:id',
      component: StatusCode,
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/tos',
      component: TermsOfService,
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/termsandconditions',
      component: TermsAndConditions,
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/privacy',
      component: PrivacyPolicy,
      exact: true,
      roles: ['admin', 'patient', 'caregiver']
    },
    {
      path: '/message-body/:messageId',
      component: MessageBody,
      exact: true,
      roles: ['admin', 'caregiver']
    },
    {
      path: '/sessionexpire',
      component: SessionExpire,
      exact: true,
      roles: ['admin', 'caregiver', 'patient']
    },
    {
      path: '/onbording_video',
      component: OnboardingVideo,
      exact: true,
      roles: ['admin', 'caregiver', 'patient']
    },
    {
      path: '/',
      component: Authentication(CoreLayout),
      routes: [
        {
          path: '/api.html',
          component: RelComponent,
          exact: true,
          roles: ['admin', 'caregiver']
        },
        {
          path: '/api.cj',
          component: ApiCollection,
          exact: true,
          roles: ['admin', 'caregiver']
        },
        {
          path: '/patients.cj',
          component: AllPatientsDetails,
          exact: true,
          roles: ['admin', 'caregiver']
        },
        {
          path: '/sites.cj',
          search: '?accepts_referral=acceptReferral',
          component: ReferralSites,
          exact: true
        },
        {
          path: '/documents',
          component: Documents,
          routes: [
            {
              path: '/documents/:documentId/:action/mobilerequest/:token',
              component: HandleDownloadDocument,
              search: '?locale=locale',
              exact: true
            },
            {
              path: '/documents/:documentId',
              component: DocumentsView,
              exact: true
            }],
          roles: ['admin', 'patient', 'caregiver']
        },
        {
          path: '/credits',
          component: Credits,
          routes: [
            {
              path: '/credits/:siteId/list',
              component: CreditsList,
              search: '?siteId=siteId',
              exact: true
            },
            //removing credits form as we no longer going to use stripe
            {
              path: '/credits/:siteId/new',
              component: CreditsForm,
              exact: true
            },
            {
              path: '/credits/receipt/:receiptId',
              component: Receipt,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/statuscode/404' />)
            }
          ],
          roles: ['admin', 'caregiver']
        },
        {
          path: '/patients',
          component: Patients,
          routes: [
            {
              path: '/patients/list',
              component: PatientsList,
              exact: true
            },
            {
              path: '/patients/new',
              component: PatientForm,
              exact: true
            },
            {
              path: '/patients/:id/documents/new',
              component: UploadPatientDocument,
              exact: true
            },
            {
              path: '/patients/edit/:patientId(\\d+)',
              component: PatientForm,
              exact: true
            },
            {
              path: '/patients/:patientId/documents.cj',
              component: PatientDocuments,
              exact: true
            },
            {
              path: '/patients/:patientId/documents/:documentId/edit',
              component: UpdatePatientDocument,
              exact: true
            },
            {
              path: '/patients/:patientId/documents',
              component: PatientsDocuments,
              exact: true
            },
            {
              path: '/patients/:patientId(\\d+)',
              component: ViewPatient,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/patients/list' />)
            }
            // , {
            //   path: '*',
            //   render: () => (<Redirect to='/statuscode/404' />)
            // }
          ],
          roles: ['admin', 'caregiver']
        },
        {
          path: '/users',
          component: Users,
          routes: [
            {
              path: '/users/list',
              component: UsersList,
              exact: true
            },
            {
              path: '/users/new',
              component: IndividualUser,
              exact: true
            },
            {
              path: '/users/edit/:userId(\\d+)',
              component: IndividualUser,
              exact: true
            },
            {
              path: '/users/:userId(\\d+)',
              component: IndividualUser,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/users/list' />)
            }
            // , {
            //   path: '*',
            //   render: () => (<Redirect to='/statuscode/404' />)
            // }
          ],
          roles: ['admin', 'caregiver']
        },
        {
          path: '/sites',
          component: Sites,
          routes: [
            {
              path: '/sites/list',
              component: SitesList,
              exact: true
            },
            {
              path: '/sites/new',
              component: SitesForm,
              exact: true
            },
            {
              path: '/sites/edit/:siteId(\\d+)',
              component: SitesForm,
              exact: true
            },
            {
              path: '/sites/:siteId/documents.cj',
              component: SiteDocumentCollection,
              exact: true
            },
            {
              path: '/sites/:siteId/documents/new',
              component: UploadSiteDocument,
              exact: true
            },
            {
              path: '/sites/:siteId/documents/:documentId/edit',
              component: UpdateSiteDocument,
              exact: true
            },
            {
              path: '/sites/:siteId/documents',
              component: SiteDocuments,
              exact: true
            },
            {
              path: '/sites/:siteId/customizations/new',
              component: CustomizationsForm,
              exact: true
            },
            {
              path: '/sites/:siteId/customizations/:code/edit',
              component: CustomizationsForm,
              exact: true
            },
            {
              path: '/sites/:siteId/customizations/:code/rubric/:rubric_id/range/new',
              component: RangeForm,
              exact: true
            },
            {
              path: '/sites/:siteId/customizations/:code/rubric/:rubric_id/range/:range_id/edit',
              component: RangeForm,
              exact: true
            },
            {
              path: '/sites/:siteId/customizations/:code/rubric/new',
              component: RubricForm,
              exact: true
            },
            {
              path: '/sites/:siteId/customizations/:code/rubric/:rubric_id/edit',
              component: RubricForm,
              exact: true
            },
            {
              path: '/sites/:siteId/customizations/:code',
              component: CustomizationsDetails,
              exact: true
            },
            {
            path: '/sites/:siteId/customizations',
            component: Customizations,
            exact: true
            },
            {
              path: '/sites/:siteId(\\d+)',
              component: SitesDetails,
              exact: true
            },
            {
              path: '*',
              render: () => (<Redirect to='/sites/list' />)
            }
            // , {
            //   path: '*',
            //   render: () => (<Redirect to='/statuscode/404' />)
            // }
          ],
          roles: ['admin', 'caregiver']
        },
        {
          path: '/providers',
          component: Providers,
          routes: [
            {
              path: '/providers/list',
              component: ProvidersList,
              search: '?page=page',
              exact: true
            },
            {
              path: '/providers/new',
              component: ProviderForm,
              exact: true
            },
            {
              path: '/providers/edit/:providerId(\\d+)',
              component: ProviderForm,
              exact: true
            },
            {
              path: '/providers/:providerId(\\d+)',
              component: ProviderDetails,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/providers/list' />)
            }
          ],
          roles: ['admin']
        },
        {
          path: '/caregivers',
          component: Caregivers,
          routes: [
            {
              path: '/caregivers/list',
              component: CaregiversList,
              search: '?page=page',
              exact: true
            },
            {
              path: '/caregivers/new',
              component: CaregiverForm,
              exact: true
            },
            {
              path: '/caregivers/edit/:caregiverId(\\d+)',
              component: CaregiverForm,
              exact: true
            },
            {
              path: '/caregivers/:caregiverId(\\d+)',
              component: CaregiverDetails,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/caregivers/list' />)
            }
          ],
          roles: ['admin']
        },
        {
          path: '/billingsummary',
          component: BillingSummary,
          search: '?siteId=siteId',
          exact: true,
          roles: ['admin']
        },
        {
          path: '/messagerouting',
          component: MessageRouting,
          routes: [
            {
              path: '/messagerouting/list',
              component: MessageRoutingList,
              search: '?page=page',
              exact: true
            }, {
              path: '/messagerouting/new',
              component: MessageRoutingForm,
              exact: true
            }, {
              path: '/messagerouting/edit/:mrrId(\\d+)',
              component: MessageRoutingForm,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/messagerouting/list' />)
            }
          ],
          roles: ['admin']
        },
        {
          path: '/help',
          component: Help,
          routes: [
            // {
            //   path: '/help/biomarkermanual',
            //   component: CurrentManual,
            //   exact: true
            // },
            // {
            //   path: '/help/userguide',
            //   component: UserCurrentManual,
            //   exact: true
            // },
            // {
            //   path: '/help/allmanuals',
            //   component: AllManual,
            //   exact: true
            // },
            // {
            //   path: '/help/usermanuals',
            //   component: UserManual,
            //   exact: true
            // },
            // {
            //   path: '/help/manualform',
            //   component: ManualForm,
            //   search: '?type=category',
            //   exact: true
            // },
            {
              path: '/help/videos',
              component: Videos,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/statuscode/404' />)
            }
          ],
          roles: ['admin', 'patient', 'caregiver']
        },
        {
          path: '/credit-transaction',
          component: CreditTransaction,
          routes: [
            {
              path: '/credit-transaction/list',
              component: CreditTransactionList,
              search: '?siteId=siteId',
              exact: true
            }, {
              path: '/credit-transaction/:siteId/new',
              component: CreditTransactionForm,
              exact: true
            }
            , {
              path: '*',
              render: () => (<Redirect to='/credit-transaction/list' />)
            }
          ],
          roles: ['admin']
        }, {
          path: '/corrections',
          component: Corrections,
          exact: true,
          roles: ['admin']
        }, {
          path: '/observations',
          component: ObservationsContainer,
          routes: [
            {
              path: '/observations/:obsId(\\d+)',
              component: ObservationDetails,
              exact: true
            },
            {
              path: '/observations',
              search: '?identifier=identifier',
              component: ObservationsList,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/statuscode/404' />)
            }
          ],
          roles: ['admin', 'caregiver']
        }, {
          path: '/oddities',
          component: Oddities,
          routes: [
            {
              path: '/oddities/list',
              component: OdditiesList,
              search: '?tab=tab',
              exact: true
            },
            {
              path: '/oddities',
              search: '?message=message',
              component: OddityMessageGroup,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/oddities/list?tab=oddity' />)
            }
          ],
          roles: ['admin']
        }, {
          path: '/messages',
          component: Inbox,
          routes: [
            {
              path: '/messages/inbox',
              search: '?from=from',
              component: MessagesList,
              exact: true
            },
            {
              path: '/messages/edit/:labName/:messageId(\\d+)',
              component: MessageForm,
              exact: true
            },
            {
              path: '/messages/details/:labName/:messageId(\\d+)',
              component: MessageDetails,
              exact: true
            },
            {
              path: '/messages/resolve/:messageId(\\d+)',
              component: ResolveMessage,
              exact: true
            },
            {
              path: '/messages/failuremessages',
              search: '?type=type',
              component: FailureMessages,
              exact: true
            },
            {
              path: '*',
              render: () => (<Redirect to='/messages/inbox' />)
            }
          ],
          roles: ['admin']
        },
        {
          path: '/data',
          component: DataMenu,
          routes: [
            {
              path: '/data/compare_noumena',
              component: CompareNoumena,
              exact: true
            },
            {
              path: '/data/noumena',
              component: ExploreNoumena,
              exact: true
            },
            {
              path: '/data/volumereport',
              component: VolumeReport,
              exact: true
            },
            {
              path: '/data/vadr',
              component: VADR,
              exact: true
            },
            {
              path: '/data/sample_aggregate',
              component: AggregateAgeCharts,
              exact: true
            },
            {
              path: '/data/sample_report',
              component: ReportCardGrades,
              exact: true
            },
            {
              path: '/data/sample_spectra',
              component: Spectra,
              exact: true
            },
            {
              path: '/data/:noumena_name',
              component: NoumenaPreview,
              exact: true
            }, {
              path: '*',
              render: () => (<Redirect to='/statuscode/404' />)
            }
          ],
          roles: ['admin', 'caregiver']
        },
        {
          path: '/visits',
          component: VisitsContainer,
          routes: [
            {
              path: '/visits/:visitId/documents/new',
              component: UploadVisitDocument,
              exact: true
            },
            {
              path: '/visits/:visitId/common_documents',
              component: CommonDocuments,
              exact: true
            },
            {
              path: '/visits/:visitId/questionnaire/new',
              component: NewQuestionnaire,
              exact: true
            },
            {
              path: '/visits/:visitId/orders/new',
              component: NewOrder,
              exact: true
            },
            {
              path: '/visits/:visitId/results/:resultId(\\d+)',
              component: ResultView,
              exact: true
            },
            {
              path: '/visits/:visitId/:panelName/:panelId(\\d+)',
              component: IndividualOrder,
              exact: true
            },
            {
              path: '/visits/:visitId/documents.cj',
              component: VisitDocuments,
              exact: true
            },
            {
              path: '/visits/:visitId(\\d+)',
              component: IndividualVisit,
              exact: true
            }
            ,{
              path: '*',
              render: () => (<Redirect to='/statuscode/404' />)
            }
          ],
          roles: ['admin', 'caregiver']
        },
        {
          path: '/reports',
          component: ReportsContainer,
          routes: [
            {
              path: '/reports/:visitId/physicianreport',
              component: PhysiciansReport,
              search: '?locale=locale',
              exact: true
            },
            {
              path: '/reports/:visitId/physicianreportpast',
              component: PhysiciansReportPast,
              search: '?locale=locale',
              exact: true
            },
            {
              path: '/reports/:visitId/patientreport',
              component: PatientReport,
              search: '?locale=locale',
              exact: true
            },
            {
              path: '/reports/:visitId/patientreportpast',
              component: PatientReportPast,
              search: '?locale=locale',
              exact: true
            },
            {
              path: '/reports/:visitID/:type/mobilerequest/:token',
              component: HandleDownloadReport,
              search: '?locale=locale',
              exact: true
            },
            {
              path: '*',
              render: () => (<Redirect to='/statuscode/404' />)
            }
          ],
          roles: ['admin', 'caregiver']
        },

        {
          path: '/active-site-report',
          component: ActiveSiteReport,
          exact: true,
          roles: ['admin']
        },
        {
          path: '/account',
          component: Account,
          exact: true,
          roles: ['admin', 'caregiver']
        },
        {
          path: '/user_settings',
          component: UserSetting,
          exact: true,
          roles: ['admin', 'caregiver']
        },
        {
          path: '/feedback_list',
          component: FeedbackList,
          exact: true,
          roles: ['admin']
        },
        {
          path: '/feedback',
          component: Feedback,
          exact: true,
          roles: ['admin', 'caregiver']
        },
        {
          path: '/biomarker_comparison/:visitID/:DOB/:biomarkerComparisonList/mobilerequest/:token',
          component: HandleBiomarkerComparison,
          search: '?locale=locale',
          exact: true
        }
      ]
    }

    // , {
    //   path: '*',
    //   render: () => (<Redirect to='/statuscode/404' />)
    // }
  ];
};

import React, { Component } from 'react';
import moment from 'moment';
import { parse } from 'qs';
import labels from '../../../config/localization';
import NoumenaCharts from './NoumenaCharts';
import FilterNoumenaData from './FilterNoumenaData';
import _ from 'lodash';
import LoadingTemplate from '../../../components/LoadingTemplate';
class VADR extends Component {
    componentDidMount() {
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.noumena_labels.vadr_label, path: '' },
        ])
        this.props.setPageTitle(labels.noumena_labels.vadr_label)
        document.title = `${labels.physioage_label}: ${labels.noumena_labels.vadr_label}`
        //this.props.setNoumenonVadrValues()
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);

        this.props.setVadrValues(this.urlParams.page ? parseInt(this.urlParams.page) : 1)
        // }
        this.props.getSitesList()
        this.props.setUnitsData()
    }
    render() {
        return (
            this.props.isLoading ? <LoadingTemplate isCards={true} haveSidebar={true} noSidedata={true} /> :
                <>
                    <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0'>
                        {/* {Object.keys(this.props.vadrData ? this.props.vadrData :'').map(s=>{
                        return <NoumenaCharts label = {s} femaleData = {this.props.vadrData[s].female} maleData = {this.props.vadrData[s].male} extremeGraphData={this.extremeGraphData} middleGraphData={this.middleGraphData} />
                   })} */}
                        {this.props.noumenaResponse && this.props.noumenaResponse.length > 0 ?
                            Object.keys(this.props.noumenaData ? this.props.noumenaData : '').map(s => s).length > 0 ?
                                <FilterNoumenaData type="VADR" noumenaData={this.props.noumenaData} {...this.props}></FilterNoumenaData>
                                : <div className='jumbotron'>
                                    <h1>{labels.noumena_labels.no_results_code}</h1>
                                </div> : <div className='jumbotron'>
                                <h1>{labels.noumena_labels.no_results}</h1>
                            </div>}
                        {/* {this.props.noumenaResponse && this.props.noumenaResponse.length >= 3 ?
                            <h3 style={{ marginTop: '15px' }}>
                                <a onClick={() => this.props.setVadrValues(this.props.pageNumber, null, null, this.props.history)} style={{ fontSize: '1em', color: '#008FBC' }}>{labels.noumena_labels.next_page} &gt;&gt;</a>
                            </h3>
                            : ''} */}
                    </div>
                </>
        )
    }
}
export default VADR;
import { connect } from 'react-redux'
import Sites from '../components/Sites'
import { setBreadCrumbs, setPageTitle, toggleNotification, hideHeaderSkeleton, navigatePatients, setCreditsCaution, noHeaderLoading, downloadDocument } from '../../../reducers/global-reducer';
import { actionCreators } from '../modules/sites';
import { addSiteProvider, navigateEditProvider } from '../../Providers/modules/providers'
import { addSiteCaregiver } from '../../Caregivers/modules/caregivers'
import { addUserSites } from '../../Users/modules/user'
import { addCreditTransaction } from '../../ELK/modules/credittransactions'

const mapStateToProps = (state) => ({
    ...state.sites,
    notifications: state.global ? state.global.notifications : {},
    isNotify: state.global ? state.global.isNotify : false,
    isLoading: state.global.isLoading,
    isParentContainer: state.global.isParentContainer,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify
})

const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    addSiteProvider,
    addUserSites,
    addCreditTransaction,
    navigateEditProvider,
    toggleNotification,
    hideHeaderSkeleton,
    navigatePatients,
    setCreditsCaution,
    noHeaderLoading,
    downloadDocument,
    addSiteCaregiver    

}

export default connect(mapStateToProps, mapDispatchToProps)(Sites)
import React, { Component } from 'react';

import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import userIcon from '../../../assets/images/user_avtar.png';
import constants from '../../../config/constants';
import PageTitle from '../../../components/PageTitle';
import { Typeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';
class CustomizationsForm extends Component {
  constructor(props, context) {
    super(props, context)
    this.noumenonCode = React.createRef()
    this.units = React.createRef()
    this.scale = React.createRef()
    this.scaleList = [{ value: '0 Point'}, { value: '1 Point'}, { value: '2 Point'}, { value: '3 Point' }]
  }
  componentDidMount() {
      if (this.props.match.params && this.props.match.params.siteId && this.props.match.params.code) {
          this.props.setCurrentSiteDetails(this.props.match.params.siteId, 'customizations_form_edit', this.props.history)
          this.props.getNoumenonList(this.props.match.params.siteId, this.props.match.params.code, this.props.history, 'edit') 
      }
      else {
        if (this.props.match.params && this.props.match.params.siteId) {
            this.props.setCurrentSiteDetails(this.props.match.params.siteId, 'customizations_form_new', this.props.history)
            this.props.getNoumenonList(this.props.match.params.siteId, null, this.props.history, 'new')
          } 
      }
      this.noumenonInput = true
  }

  saveCustomNoumenon = () => {
    let noFormChanges = this.checkForChanges();
    if (!noFormChanges) {
      this.props.saveCustomNoumenon(this.props.match.params.siteId, this.props.history)
    }
  }

  checkForChanges = () => {
    let initialcurrentCustomNoumenon = this.props.initialcurrentCustomNoumenon ? { ...this.props.initialcurrentCustomNoumenon } : {};
    let currentCustomNoumenon = {}
    if (this.props.currentCustomNoumenon) {
      currentCustomNoumenon["noumenonCode"] = this.props.currentCustomNoumenon["noumenonCode"];
      currentCustomNoumenon["noumenonName"] = this.props.currentCustomNoumenon["noumenonName"];
      currentCustomNoumenon["units"] = this.props.currentCustomNoumenon["units"];
      currentCustomNoumenon["scale"] = this.props.currentCustomNoumenon["scale"];
    }
    Object.keys(currentCustomNoumenon).forEach((p) => currentCustomNoumenon[p] == null || currentCustomNoumenon[p] == undefined || currentCustomNoumenon[p] == "" ? delete currentCustomNoumenon[p] : '');
    Object.keys(initialcurrentCustomNoumenon).forEach((p) => initialcurrentCustomNoumenon[p] == null || initialcurrentCustomNoumenon[p] == undefined || initialcurrentCustomNoumenon[p] == "" ? delete initialcurrentCustomNoumenon[p] : '');
    if (_.isEqual(currentCustomNoumenon, initialcurrentCustomNoumenon)) {
      this.props.showNoFormChangesError(true);
      return true;
    }
    else
      return false;

  }

  render() {
    return (
        <> { !this.props.basicPlan ?
        <>
          {this.props.currentCustomNoumenon && this.props.breadCrumbs?
            <>
              <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
              <form className='provider-form container col-lg-9' onSubmit={(e) => { e.preventDefault(); this.saveCustomNoumenon(this.props.match.params.siteId) }} >
                <div className='col-lg-12 row'>
                  <div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
                    {this.props.customizationErrors && this.props.customizationErrors.length ?
                      <div className='alert alert-danger'>
                        {this.props.customizationErrors.map(re => <p>{re.error}</p>)}
                      </div> : null
                    }
                    <div className='form-group'>
                      <label>Enter Noumenon</label>
                      <Typeahead
                        required={true}
                        labelKey="value"
                        allowNew={false}
                        selected={this.props.currentCustomNoumenon && this.props.currentCustomNoumenon.noumenonName && this.noumenonInput ? [{ value: this.props.currentCustomNoumenon.noumenonName }] : null}
                        onKeyDown={(e) => { e.length ? this.props.editCustomNoumenonForm(_.head(e), "noumenonCode") : null; this.noumenonInput = e.target.value ? false : true }}
                        id='noumenonCode'
                        options={this.props.noumenonNameList ? this.props.noumenonNameList : []}
                        renderMenuItemChildren={(option, props) => (
                          <span id={option.code} key={option.code}>{option.value}</span>
                        )}
                        onChange={(e) => this.props.editCustomNoumenonForm(_.head(e), "noumenonCode")}
                        // newSelectionPrefix={null}
                        ref={(ref) => this.noumenonCode = ref}
                        onBlur={(e) => {
                          this.noumenonInput = true;
                          if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                            this.props.editCustomNoumenonForm({ value: e.target.value, code: e.target.code }, "noumenonCode")
                          }
                        }
                        }
                        disabled={this.props.currentCustomNoumenon && this.props.currentCustomNoumenon.id ? true : false}
                      />
                    </div>
                    <FormControl
                      value={this.props.currentCustomNoumenon && this.props.currentCustomNoumenon.units ? this.props.currentCustomNoumenon.units : []}
                      type='select' id='units'
                      options={this.props.unitsList ? this.props.unitsList : []}
                      label={labels.custom_noumenon_list.units} onChange={this.props.editCustomNoumenonForm}
                      textKey={'value'} valueKey={'value'}
                      required={true} ref={this.units} />

                    <FormControl
                      value={this.props.currentCustomNoumenon && this.props.currentCustomNoumenon.scale ? this.props.currentCustomNoumenon.scale : []}
                      type='select' id='scale'
                      options={this.scaleList ? this.scaleList : []}
                      label={labels.custom_noumenon_list.scale} onChange={this.props.editCustomNoumenonForm}
                      textKey={'value'} valueKey={'value'}
                      help={labels.custom_noumenon_list.scale_help}
                      ref={this.scale} />
                    <button className="btn btn-success">Save</button>

                  </div>
                </div>

              </form>
            </>
            : ""}
        </>
        : window.location.replace('/statuscode/404')
        }
        </>
      )
    }
}
export default CustomizationsForm
import React, { Component } from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls';
import constants from '../../../config/constants';

const stylesClasses = {
  labelClass: 'col-lg-2',
  inputClass: 'col-lg-7'
}

class CreditTransactionForm extends Component {
  componentDidMount() {
    this.props.toggleNotification(false)
    if (!((this.props.currentCredit) && (Object.keys(this.props.currentCredit).length))) {
      this.props.loadCreditTransactions(this.props.match.params.siteId)
    }
    let pageTitle = `${labels.elk_labels.add_title} ${this.props.currentCredit && this.props.currentCredit.site ? this.props.currentCredit.site.name : ''}`
    document.title = `${labels.physioage_label}: ${pageTitle}`

    this.props.setPageTitle(pageTitle)
    this.props.setBreadCrumbs([
      { text: labels.physioage_label, path: '/patients/list' },
      { text: pageTitle, path: '' }
    ])
    if (this.elkForm && this.elkForm.inputEntry) {
      this.elkForm.inputEntry.focus()
    }
  }
  render() {
    return (
      <form className='credit-transformation-form container col-lg-9' onSubmit={(e) => { e.preventDefault(); this.props.saveCredits(this.props.history) }}>
        <FormControl ref={(elk) => this.elkForm = elk} {...stylesClasses} required validate type='numberonly' id='credits' onChange={this.props.editCredit} label={labels.elk_labels.no_of_sites} />
        <FormControl {...stylesClasses} type='textarea' id='comment' onChange={this.props.editCredit} label={labels.elk_labels.comment} />
        <div className='d-flex credit-submit-btn'>
          <FormControl type='button' btnType={constants.notification_type.success} text={labels.elk_labels.create_credit_btn} />
        </div>
      </form>
    )
  }
}
export default CreditTransactionForm
import React, { Component } from 'react';
import labels from '../../config/localization';
import './forgot-password-styles.scss';
import FormControl from '../FormControls';
import Notification from '../Notification';

class ForgotPassword extends Component {
    componentDidMount() {
        this.props.clearUsermail()
        document.title = `${labels.physioage_label}: ${labels.forgot_password_text}`
    }
    render() {
        return (
            <form className='linkexpired' onSubmit={(e) => { e.preventDefault(); this.props.resetPassword() }}>
                {this.props.linkexpired ?
                    <div><h1>{labels.link_expired}</h1>
                        <p>{labels.link_expired_notification}
                            <b>{labels.try_again}</b></p>
                    </div>
                    : <h1>{!this.props.sentResetEmail ? labels.forgot_password_label : labels.done_label}</h1>}
                {this.props.isNotify ? <Notification  {...this.props.notifications} /> : ''}
                {!this.props.sentResetEmail ? <React.Fragment>
                    <p>{labels.email_label}:</p>
                    <input id='forgotUsermail'
                        required='true'
                        type='email' className={`form-control`} validate onChange={this.props.loginInput} />
                    <p>{labels.forgot_password_instruction}</p>
                    <div className='d-flex login-btn-section'>
                        <button className='btn btn-primary'>{labels.reset_my_password_label}</button>
                        <button className='btn btn-default' onClick={() => this.props.history.push('/login')}>{labels.navigate_login}</button>
                    </div>
                </React.Fragment> :
                    <React.Fragment>
                        <FormControl type='button' btnType='primary' onClick={() => this.props.history.push('/login')} text={labels.return_login_btn} />
                    </React.Fragment>
                }
                <p>{labels.login_help}:</p>
            </form>
        )
    }
}
export default ForgotPassword;
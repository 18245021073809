export default [
    { rel: 'avatar', description: 'image of a provider or other person' },
    { rel: 'current-patient', description: 'patient record for current user' },
    { rel: 'document', description: 'a pdf or other file' },
    { rel: 'document-download', description: 'download the "current" file' },
    { rel: 'login', description: 'where to login' },
    { rel: 'next', description: 'next page' },
    { rel: 'next-visit', description: 'For any rel with a visit-specific scope, get the same information for the next chronological visit' },
    { rel: 'patient', description: 'a patient record... note that the currently-logged-in patient is current-patient' },
    { rel: 'patient-documents' },
    { rel: 'patients', description: 'patient list, available to admins or practices' },
    { rel: 'patient-data', description: "All current and historical data" },
    { rel: 'patient-files', description: 'list of files relating to the current patient' },
    { rel: 'patient-visits', description: 'list of visits for a patient' },
    { rel: 'practice', description: 'Health care organizations with providers currently accepting patients for PhysioAge-related services.' },
    { rel: 'practice-documents', description: 'A practice\'s "document library" – a document list available to attach to any patient.' },
    { rel: 'practices', description: 'A practice list.' },
    { rel: 'practice-website', description: 'authoritative website for a provider' },
    { rel: 'prev', description: 'previous page' },
    { rel: 'prev-visit', description: 'For any rel with a visit-specific scope, get the same information for the previous chronological visit' },
    { rel: 'visit', description: 'prompt is the visit date' },
    { rel: 'visit-documents', description: 'All documents related to a specific visit that are available for download' },
    { rel: 'visit-recommendations', description: 'All recommendations for a specific visit.' }
]
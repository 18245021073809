
const resolveRedirect = () => {
    let changed = false
    let redirectTo = localStorage.getItem('redirectTo')
    let location = window.location
    let currentRoute = location.hash ? `${location.pathname}${location.search}${location.hash}` : `${location.pathname}${location.search}`
    if ((!redirectTo
        && currentRoute
        && currentRoute.indexOf('login') == -1)
        || (redirectTo && currentRoute
            && redirectTo.indexOf('login') != -1
            && currentRoute.indexOf('login') == -1)
        || (redirectTo && currentRoute && redirectTo.indexOf('login') == -1
            && currentRoute.indexOf('login') == -1
            && redirectTo != currentRoute)) {
        localStorage.setItem('redirectTo', currentRoute);
        changed = true
    }
    return changed
}

export default resolveRedirect
import {connect} from 'react-redux';
import UserSettingComponet from '../components/UserSetting';
import { actionCreators } from '../modules/user';
import { setBreadCrumbs, setPageTitle, toggleNotification, setLoggedInUser, navigatePatients, setCreditsCaution, headerNavigation, sendOTP, validateOTP, getQRCode, handleSuceessDone } from '../../../reducers/global-reducer';

const mapStateToProps = (state) => ({
    ...state.users,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify,
    loggedInUser: state.global.loggedInUser,
})
const mapDispatchToProps = {
  ...actionCreators,
  setBreadCrumbs,
  setPageTitle,
  toggleNotification,
  setLoggedInUser,
  navigatePatients,
  setCreditsCaution,
  headerNavigation,
  sendOTP, validateOTP, getQRCode,
  handleSuceessDone
}
export default connect(mapStateToProps, mapDispatchToProps)(UserSettingComponet);

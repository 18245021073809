import {connect} from 'react-redux';
import Medication from '../components/MedicationComponent';
import { actionCreators } from '../modules/analytics';
let mapStateToProps = (state) => ({
  ...state.analytics
})

let mapDispatchToProps = {
  ...actionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(Medication);

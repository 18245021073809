import React, { Component } from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls';
import NoumenaCharts from './NoumenaCharts';
import constants from '../../../config/constants';
import './data-menu-styles.scss'
import FilterNoumenaData from './FilterNoumenaData';
class NoumenaPreview extends Component {
    componentDidMount() {
        if (this.props.noumenaResponse.length > 0) {
            this.props.setHeaderForNoumena(this.props.noumenaResponse, this.props.match.params.noumena_name, constants.preview)
        }
        if (this.props.noumenaResponse.length == 0) {
            this.props.setNoumenonValues(1, this.props.match.params.noumena_name, constants.preview, this.props.history)
        }
        this.props.getSitesList()
        this.props.setUnitsData()
    }
    render() {
        return (
            <>
                <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 noumena-preview order-2'>
                    <div className='download-csv-btn'>
                        {Object.keys(this.props.noumenaData ? this.props.noumenaData : '').length > 0 ?
                            <FormControl type='button' btnType='primary' text={labels.noumena_labels.download_excel} onClick={() => this.props.downloadCSVNoumena(this.props.match.params.noumena_name)} />
                            : ''}    </div>
                    {this.props.unitsData ? this.props.unitsData.map(s => {
                        if (s.noumenonCode == this.props.match.params.noumena_name) {
                            return (<React.Fragment>{s.scale.length > 0 ? <div className='card card-default toggle-beacon'>

                                <div className='card-header'>
                                    {labels.noumena_labels.value_format}
                                </div>
                                <div className='card-body-report'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>{labels.noumena_labels.system}</th>
                                                <th>{labels.noumena_labels.units}</th>
                                                <th>{labels.noumena_labels.scale}</th>
                                            </tr>
                                        </thead>
                                        {s.scale.map(t => (
                                            < tbody >
                                                <tr>
                                                    <td>{t.unitSystem}</td>
                                                    <td>{t.units}</td>
                                                    <td>{t.scale}</td>
                                                </tr>
                                            </tbody>))}
                                    </table>
                                </div>
                            </div> : ''}
                                <div className='card card-default toggle-beacon appear-report'>
                                    <div className='card-header'>
                                        {labels.noumena_labels.sorted_details}
                                    </div>
                                    <div className='card-body-report'>
                                        <table className='table'>
                                            <tbody>
                                                <tr className='border-top-0 border-bottom'>
                                                    <td className='border-top-0 border-bottom'>{labels.noumena_labels.code}</td>
                                                    <td className='border-top-0 border-bottom'>{this.props.match.params.noumena_name}</td>
                                                </tr>
                                                <tr className='border-top-0'>
                                                    <td className='border-top-0' >{labels.noumena_labels.appears_in_report}</td>
                                                    {s.visible == 1 ?
                                                        <td className='border-top-0'> {labels.noumena_labels.yes}</td> : <td>{labels.noumena_labels.no}</td>}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {s.equations.length > 0 ?
                                    <div className='card card-default toggle-beacon appear-report'>
                                        <div className='card-header'>
                                            {labels.noumena_labels.equation}
                                        </div>
                                        <div className='card-body-report'>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>{labels.noumena_labels.gender}</th>
                                                        <th>{labels.noumena_labels.equation}</th>
                                                    </tr>
                                                </thead>
                                                {s.equations.map(p => (
                                                    <tbody>
                                                        <tr>
                                                            <td>{p.gender ? p.gender : labels.noumena_labels.anyone}</td>
                                                            <td>{p.equation}</td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                    : ''}
                            </React.Fragment>)
                        }
                    }) : ''}
                </div>
                <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 order-1 p-0'>
                    <div className='noumena-preview-chart'>
                        {this.props.noumenaData ?
                            Object.keys(this.props.noumenaData ? this.props.noumenaData : '').length > 0 ?
                                Object.keys(this.props.noumenaData ? this.props.noumenaData : '').map(s => (
                                    s == this.props.match.params.noumena_name ?
                                        <FilterNoumenaData {...this.props} noumenaData={Object.keys(this.props.noumenaData).filter(q => q == this.props.match.params.noumena_name).map(s => ({ [this.props.match.params.noumena_name]: this.props.noumenaData[s] }))[0]}></FilterNoumenaData>
                                        : ''
                                )) : <div className='jumbotron'>
                                    <h1>{labels.noumena_labels.no_results_code}</h1>
                                </div>
                            : <div className='jumbotron'>
                                <h1>{labels.visit_page_labels.waiting_for_data}<i className="fa fa-spinner fa-spin" style={{ marginLeft: '20px' }}></i></h1>
                            </div>}
                    </div>
                </div>
            </>
        );
    }
}
export default NoumenaPreview;
import Rickshaw from 'rickshaw'

// THIS IS TESTED!
//
// One thing about formatters... you'd think they would dictate which
// dates were used. If you used 'decade' below, you'd think it would put
// an X axis line (a vertical line) at 2000, 2010, 2020, ... but no. It
// picks a date close to a datapoint and starts there. So you'll end up
// with 2003, 2013, 2023. Thus, formatters need to include offsets.

let chartTimeScale = function() {

	var self = this;
	let day_in_seconds = 60 * 60 * 24;

	this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

	let monthAndYear = (date) => `${self.months[date.getUTCMonth()]} ${date.getUTCFullYear()}`
	this.units = [
		{
			name: 'decade',
			seconds: day_in_seconds * 365.25 * 10,
			formatter: function(d) { let year = d.getUTCFullYear(); return(year);}
		}, {
			name: 'leapyear',
			seconds: day_in_seconds * 365.25 * 4,
			formatter: function(d) { let year = d.getUTCFullYear(); return(year);}
		}, {
			name: 'year',
			seconds: day_in_seconds * 365.25,
			formatter: function(d) { return d.getUTCFullYear() }
		}, {
			name: 'halfyear',
			seconds: day_in_seconds * 30.5 * 6,
                        formatter: function(d) { return monthAndYear(d) }
		}, {
			name: 'quarteryear',
			seconds: day_in_seconds * 30.5 * 3,
                        formatter: function(d) { return(d.getUTCMonth() < 3 ? monthAndYear(d) : self.months[d.getUTCMonth()])}
                }, {
			name: 'month',
			seconds: day_in_seconds * 30.5,
						//as per dan requirement changed current year month displaying to current month followed by year
						//formatter: function(d) { return self.months[d.getUTCMonth()] }
						formatter: function(d) { return monthAndYear(d) }
                }
	];

	this.unit = function(unitName) {
		return this.units.filter( function(unit) { return unitName == unit.name } ).shift();
	};

	this.formatDate = function(d) {
		return d3.time.format('%b %e')(d);
	};

	this.formatTime = function(d) {
		return d.toUTCString().match(/(\d+:\d+):/)[1];
	};

	this.ceil = function(time, unit) {

		var date, floor, year;

		if (unit.name == 'month') {

			date = new Date(time * 1000);

			floor = Date.UTC(date.getUTCFullYear(), date.getUTCMonth()) / 1000;
			if (floor == time) return time;

			year = date.getUTCFullYear();
			var month = date.getUTCMonth();

			if (month == 11) {
				month = 0;
				year = year + 1;
			} else {
				month += 1;
			}

			return Date.UTC(year, month) / 1000;
		}

		if (unit.name == 'year') {

			date = new Date(time * 1000);

			floor = Date.UTC(date.getUTCFullYear(), 0) / 1000;
			if (floor == time) return time;

			year = date.getUTCFullYear() + 1;

			return Date.UTC(year, 0) / 1000;
		}

		return Math.ceil(time / unit.seconds) * unit.seconds;
	};
};

export default chartTimeScale
export default {
    LOGIN: '/auth/login',
    PATIENTS_URL: '/Patient',
    PATIENTS_SEARCH_URL: '/Patient/Search',
    PATIENTS_NAMES_URL: '/Patient/Names',
    PATIENTS_VISTS_URL: '/Patient/Visits',
    AGGREGATE_AGES_URL: '/Patient/AggregateAgeChart',
    UPDATE_PATIENT_PORTAL_URL: '/Patient/UpdatePatientPortal',
    PATIENTS_DETAILS_URL: '/Patient/AllPatientDocuments',
    PATIENTS_DOCUMENTS_URL: '/Patient/PatientsDocuments',
    PATIENT_DOCUMENTS_VISITS: 'Patient/GetFollowUpDocs',
    API_COLLECTION_URL: '/Patient/ApiCollection',
    PROVIDERS_URL: '/Provider',
    SITES_URL: '/Site',
    SITE_URL: '/Site/GetSiteById',
    SITES_LIST_URL: '/Site/List',
    SITES_NAMES: '/Site/GetSiteNames',
    SITES_DOCUMENTS_URL: '/Site/SitesDocuments',
    SIGNUP_USER: '/Site/Signup',
    SITE_ATTENDEE_LIST: '/Site/AttendeeList',
    QUESTIONNAIRE_QUESTIONS: '/Questionnaire/Questionnaire',
    QUESTIONNAIRE: '/Questionnaire',
    QUESTIONNAIRE_LIST: '/Questionnaire/QuestionnairesList',
    PROVIDERS_LIST_URL: '/Provider/List',
    USERS_LIST: '/User/List',
    USERS_SEARCH_URL: '/User/Search',
    USERS_URL: '/User',
    SITE_PROVIDERS_URL: '/Provider/SiteProviders',
    PATIENT_SITE_PROVIDERS: '/Patient/SiteProviders',
    CREDIT_TRANSACTIONS: '/CreditTransaction/Search',
    CREDIT_TRANSACTIONS_URL: '/CreditTransaction',
    MESSAGE_ROUTING: '/MessageRouting/Search',
    MESSAGE_ROUTING_URL: '/MessageRouting',
    APPLY_MESSAGE_ROUTING: '/MessageRouting/applyrouting',
    BILLING_SUMMARY_URL: '/BillingSummary/BillingSummary',
    CORRECTIONS_URL: '/Corrections/List',
    ODDITY_URL: '/Odditie/OdditieSummary',
    ODDITY_ERRORS: '/Odditie/AllErrors',
    ODDITY_ERROR_RESOLVE: '/Odditie/Resolve',
    ODDITY_RESOLVE: '/Odditie/UpdateOddities',
    ODDITY_MESSAGE_GROUP: '/Odditie/GetMessageGroup',
    OBSERVATION_URL: '/Observation',
    INBOX_URL: '/Inbox/List',
    INBOX_BODY_URL: '/Inbox',
    INBOX_MESSAGE_URL: '/Inbox/MessageFilter',
    INBOX_HL7NAMESLIST: '/Inbox/GetAllHL7Names',
    INBOX_UPDATEMESSAGE: '/Inbox/updatemessage',
    ACTIVE_SITE_REPORTS: '/ActiveSite/ActiveSitesByMonth',
    ACTIVE_SITE_VOLUME_REPORT: '/ActiveSite/VisitVolume',
    HELP_URL: '/Help',
    ALL_MANUALS_URL: '/Help/AllManuals',
    CURRENT_MANUAL_URL: '/Help/CurrentManual',
    DOWNLOAD_HELP_MANUAL: '/Help/HelpFile',
    VISIT_VOLUME_REPORT: '/Site/VisitVolumeReport',
    PANELS_DATA: '/PhysioAgeData/Panels',
    PHYSIOAGE_DATA: '/CustomNoumenon/CreateCustomSiteUnits',
    ÇUSTOM_UNIT_UPDATE : '/CustomNoumenon/UpdateCustomSiteUnits',
    VISIT_URL: '/Visit',
    VISIT_COMMON_DOCS_URL: '/Documents/CommonDocuments',
    ORDER_URL: '/Order',
    VISIT_ORDER_URL: '/Visit/Orders',
    VISIT_DOCUMENTS_URL: '/Visit/VisitsDocuments',
    SECTIONS_URL: '/Visit/GetSectionsWithPassages',
    ANALYTICS_BIO_INFO: '/Analytics',
    ANALYTICS_HISTORIC_CATEGOREY: '/Analytics/GetHistoricForCategories',
    AGGREGATE_COMPARING_CHARTS: '/Analytics/ComparingCharts',
    FOLLOW_UP_DATA: '/Analytics/GetFollowUpByVisitId',
    RESEND_WELCOME_EMAIL: '/PostOffice/ResendWelcomeEmail',
    FEEDACK_URL: '/PostOffice/Feedback',
    RECOMMENDATION_URL: '/Recommendation',
    GET_ALL_RECOMMENDATIONS_URL: '/Recommendation/GetAllRecommendations',
    RECOMMENDATION_REORDER: '/Recommendation/Reorder',
    ORDER_RESULTS_URL: '/Order/Results',
    RESULT_URL: '/Result',
    BIOMARKERS_AGE: '/PhysioAgeData/GetBiomarkersAgeData',
    REFRESH_TOKEN: '/Auth/refresh',
    PAYMENT_URL: '/Payment',
    PAYMENT_STRIPE_URL: '/Payment/Stripe',
    FORGOT_PASSWORD: '/Auth/forgotpassword',
    RESET_PASSWORD: '/Auth/resetpassword',
    SET_PASSWORD: '/Auth/setpassword',
    ACCESS_TOKEN: '/Auth/getAccessTokenWithPerishableToken',
    UPDATE_PASSWORD: '/User/UpdatePassword',
    PATIENT_REPORT_NEW: '/Visit/ConvertToPdfNew',
    PATIENT_REPORT_DOWNLOAD: '/Visit/ConvertToPdf',
    PATIENT_REPORT_DOWNLOAD_PRINCE: '/Visit/ConvertToPdfByPrince',
    NOUMENON: '/Noumenon',
    NOUMENON_UPDATE: '/Noumenon/ExploreNoumenon',
    NOUMENON_NAME: '/Noumenon/ExploreNoumenonByName',
    VADR: '/Noumenon/GetVadrNoumenonData',
    VALIDATERESETPASSWORD: '/Auth/validateResetPassword',
    VALIDATESETPASSWORD: '/Auth/validateSetPassword',
    ACCESSIONSEARCH: '/Visit/AccessionSearch',
    IMPORT: '/Message/MessageImport',
    MESSAGE: '/Message',
    MESSAGE_DETAILS: '/Message/MessageDetails',
    FAILURE_MESSAGE_COUNT: '/Message/FailureMessageCount',
    FAILURE_MESSAGES: '/Message/FailureMessages',
    RESOLVE_FAILURE_MESSAGES: '/Message/ResolveMessage',
    TERM_TRANSLATIONS: '/Report/GetTermTranslations',
    DOWNLOAD_VISIT_SUMMARY: '/Visit/DownloadVisitSummary',
    DEMO_REPORT_DOWNLOAD: '/auth/ConvertToPdf',
    SITE_REFERRAL: '/Sites/sites.cj',
    SEARCH_OBSERVATION: '/Observation/GetByIdentifier',
    DOCUMENT_URL: '/Documents',
    DOCUMENT_WITH_GENERIC_ID: '/Documents/DocumentsWithId',
    DOCUMENT_WITH_ID: '/Documents/DocumentFile',
    ATTACH_DOCUMENT: '/Documents/AttachDocument',
    DELETE_DISTRIBUTION: '/Documents/DeleteDistribution',
    PATIENT: '/Patient',
    GET_ALL_NOUMENON_NAMES: '/Noumenon/GetAllNoumenonNames',
    UNITS_DATA: '/Noumenon/unitsdata',
    CUSTOM_ORDER_FIELDS: '/Order/CreateCustomFields',
    GET_CUSTOM_Fields: 'Order/GetCustomFields',
    CUSTOM_NOUMENON_LIST: '/CustomNoumenon/GetCustomSiteUnitsBySiteID',
    GET_CUSTOM_NOUMENA: '/CustomNoumenon/GetCustomSiteUnits',
    GET_NOUMENON_LIST:  '/Noumenon/GetNoumenaWithValueFormats',
    DELETE_CUSTOM_NOUMENON: '/CustomNoumenon/DeleteCustomSiteUnits',
    NOUMENON_REFERENCES: '/Noumenon/GetAllNoumenonReferences',
    CREATE_CUSTOM_RUBRICS: '/CustomNoumenon/CreateCustomRubrics',
    UPDATE_CUSTOM_RUBRICS: '/CustomNoumenon/UpdateCustomRubrics',
    GET_RUBRICS: 'CustomNoumenon/GetCustomRubricsByNoumenonId',
    GET_INDIVIDUAL_RUBRIC: 'CustomNoumenon/GetCustomRubric',
    DELETE_RUBRICS: 'CustomNoumenon/DeleteCustomRubrics',
    CREATE_CUSTOM_RANGES: 'CustomNoumenon/CreateCustomRubricRanges',
    UPDATE_CUSTOM_RANGES: 'CustomNoumenon/UpdateCustomRubricRanges',
    GET_RANGE: 'CustomNoumenon/GetCustomRubricRanges',
    DELETE_RANGE: 'CustomNoumenon/DeleteCustomRubricRanges',
    GET_PROVIDERS_NOTES: '/ProviderNotes/GetProviderNotesByPatientId',
    CREATE_PROVIDER_NOTES: '/ProviderNotes/CreateProviderNotes',
    UPDATE_PROVIDER_NOTES: '/ProviderNotes/UpdateProviderNotes',
    DELETE_PROVIDER_NOTES: "/ProviderNotes/DeleteProviderNotesHistoryById",
    GET_CUSTOM_RECOMMNEDATION: "/Recommendation/GetCustomRecommendationsBySiteId",
    GET_CUSTOM_SIG: "/Recommendation/GetCustomSigsBySiteId",
    PROBLEMS_URL: '/problem',
    GET_PROBLEMS_URL: '/problem/GetByPatient',
    GET_BY_VISIT_PROBLEMS_URL: '/problem/GetByVisit',
    FEEDBACK: 'Feedback',
    GET_ALL_FEEDBACK: 'Feedback/GetAll',
    ANALYTICS_SITESPECIFIC_GPA: '/Analytics/GetSiteSpecificGPA',
    SET_THEME_PREFERENCE: '/User/SetThemePreference',
    GET_AUTH: 'Auth/GenerateToken',
    SEND_OTP: 'Auth/SendOTP',
    VALIDATE_OTP: 'Auth/ValidateOTP',
    GET_QRCODE: 'Auth/GenerateQRCode',
    SET_PRIMARY_MFA: 'User/SetPrimaryMFA',
    DOWNLOAD_MECOMMENDATION: 'Recommendation/download_recommendation_list'
}
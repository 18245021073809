import React, { Component } from 'react';
import '../../styles/app.scss';
import cardLogo from '../../assets/images/logo_physioage-horizontal.svg';
import labels from '../../config/localization';
import constants from '../../config/constants';

export default function (ComposedComponent) {
    class UnAuthLayout extends Component {
        render() {
            return (
                <div className='app wrapper unauth-layout'>
                    <div className='card parent-card'>
                        <div className='row'>
                            <img src={cardLogo} alt={'physioage-logo'} />
                        </div>
                        <div className='row'>
                            <ComposedComponent />
                        </div>
                        <div className='row helptext-section text-center'>
                            <div className='d-flex justify-space-around'>
                                {/* <div>
                                    <p>{labels.inside_us}</p>
                                    <h2 className='text-center'>{constants.helpline_in}</h2>
                                </div> */}
                                <div>
                                    {/* <p>{labels.outside_us}</p> */}
                                    <h2 className='text-center'>{constants.helpline_out}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    return UnAuthLayout
}

import React, {useEffect, useState } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import close from '../../../assets/images/close.svg';
import moment from 'moment';
import { connect } from 'react-redux';
import CardLoader from '../containers/CardLoaderContainer';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography'; 
import constants from '../../../config/constants';
import BiomarkersNoumenonChart from './BiomarkersNoumenonChart'
import labels from '../../../config/localization';
import closeLight from '../../../assets/images/close-light.svg';
import RubricRangesComponent from './RubricRangesComponent';


function NomenonDetails(props) {

    useEffect(() => {
        // props.getProviderNotes()
    return () => {
            // Anything in here is fired on component unmount.
    }
    }, [])
    const card = (nomenon) => {
        return (
            <React.Fragment>
                <CardContent sx={{ padding: '10px !important' }} className={`${active == nomenon.label && 'active'}`}>
                    <Typography component={'div'}>
                        <div className="d-flex justify-content-start">
                            <div className='d-flex' style={{width: '100%'}}>
                              <span style={{width: '50%'}} className="justify-content-start"> {nomenon.label}</span>  
                              <span className='col-auto d-flex justify-content-start ml-3' style={{width: '30%'}}><div className='value'>{nomenon.value ? nomenon.value : ""}</div><div className='units'> {nomenon.units ? nomenon.units : ""}</div></span>
                              <span className='col-auto d-flex pr-0 justify-content-start"' style={{width: '20%'}}>{nomenon.referenceRange ? nomenon.referenceRange : ""}</span>
                            </div>
                        </div>
                    </Typography>
                </CardContent>
            </React.Fragment>
        );
    }
    const [active, setActive] = useState('');
    const [datumValues, setDatumValue] = useState('');
    { !active && props.nomenonInvloved ? setActive(_.head(props.nomenonInvloved).label) : "" }
    { !datumValues && props.nomenonInvloved ? setDatumValue(_.head(props.nomenonInvloved)) : "" }
    let returnGraphData = () => {
        _.last(datumValues.historicData).noumenonRubricRanges == null ? datumValues.historicData.map((data) => data.noumenonRubricRanges = datumValues.noumenonRubricRanges) : ''
        return datumValues && datumValues.historicData ? datumValues.historicData.filter(item => !!item.value) : []
    }
    let returnSortedRubricRanges = () => {
        let rubricRanges = datumValues ? datumValues.noumenonRubricRanges : '';
        if (rubricRanges) {
            rubricRanges.sort((a, b) => {
                if (a.low === null && b.low === null) {
                    return 0;
                }
                if (a.low === null) {
                    return -1; // Move items with null "low" to the beginning
                }
                if (b.low === null) {
                    return 1; // Move items with null "low" to the beginning
                }
                return a.low - b.low;
            });
        }
        return rubricRanges;
    }
    return (
        <>
            <div className='drawer-warning-overlay'></div>
            <div className='nomenon-details'>
                <div className='drawer-warning'>
                    <div className='d-flex row header no-gutters'>
                        <div className='col-11 bold-14-text'> {labels.analytics_labels.nomenon_involved}</div>
                        <div className='col-1 justify-content-end d-flex'>
                            <a><img src={props.theme == "light-theme" ? close : closeLight} onClick={() => (props.toggleNomenonDetails(false))}></img></a>
                        </div>
                    </div>
                    <div className='row card-body-div no-gutters'>
                        <div className='col-lg-4 col-md-6 noumenon-list'>
                        {props.nomenonInvloved ?
                            props.nomenonInvloved.map(nomenon => {
                                return(
                                <Box sx={{ minWidth: '100%' }} 
                                onClick={() => { setActive(nomenon.label); setDatumValue(nomenon) }}
                                   
                                   >
                                    <Card sx={{ height: '200px', marginTop: '10px', }} className='card-div' variant="outlined">{
                                        card({
                                            label: nomenon.label ? nomenon.label : '',
                                            value: nomenon.value ? nomenon.value : '',
                                            units:  nomenon.units ? nomenon.units : "",
                                            referenceRange : nomenon.referenceRange ? nomenon.referenceRange : "",
                                        })}
                                    </Card>
                                </Box>
                                )
                        }) : null
                     }
                        </div>
                        {  
                           datumValues && datumValues.code != props.ages ?
                            <div className='col-lg-8 col-md-6'>
                                <div className='rubric-ranges-chart-container'>
                                    <div className='row mt-4 noumenon-label-container ml-4'>
                                        <div className='bold-14-text'>    
                                            {datumValues.label}
                                        </div>
                                        <div className='rubric-ranges-bar-container col-8'>    
                                            {(datumValues.noumenonRubricRanges && datumValues.noumenonRubricRanges.length > 0) ?
                                            <div className={`d-flex rubric-ranges-container`}>
                                                <RubricRangesComponent rubricRanges={returnSortedRubricRanges()} currentValue = {_.last(datumValues.historicData).value} colorState = {'dynamic-color'}/>
                                            </div> : ''}
                                        </div>
                                    </div>    
                                    
                                    <div className="category-graph-section">
                                        <div id={'drawer-body-popup'} className='category-overview-graph'>
                                            <div className='chart-content'>
                                                <BiomarkersNoumenonChart reportCard={true} {...props}
                                                    noumenon_name= {datumValues ? datumValues.label : ""}
                                                    historic_data={returnGraphData()} type={ "pointed"} drawerType={"reportCard"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            : ''
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect()(NomenonDetails);
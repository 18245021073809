import React, { Component } from 'react';
import './loader-styles.scss';

export default class Loader extends Component {
    render() {
        return (
            // <div className='loader'>
            //     <div></div>
            //     <div></div>
            //     <div></div>
            //     <div></div>
            // </div>
            // <div>
                <div className="loader">
                    <div className="set-one">
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </div>
                    <div className="set-two">
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </div>
                </div>
            // </div>
        )
    }
}
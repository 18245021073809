import React from 'react';
import AggregateChart from '../components/AggregateChart';
import BiomarkerCharts from '../components/BiomarkerChartsComponent';

export default class ComparingBiomarkersComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.presentPatient.visits == 1 && this.props.historic_data_chart ? this.props.historic_data_chart.biomarkerCount : 0 > 0) {
      return (<AggregateChart url={this.props.aggregateAgeUrl} />)
    }
    else if (this.props.presentPatient.visits > 0 && this.props.historic_data_chart ? this.props.historic_data_chart.biomarkerCount : 0 > 0) {
      return (
        <BiomarkerCharts {...this.props} />
      );
    }
    else {
      return (null);
    }
  }
}

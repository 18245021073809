import React, { Component } from 'react';

class PatientDocuments extends Component {
    componentDidMount() {
        document.body.style = 'background: #ffffff!important';

        if (this.props.match.params
            && this.props.match.params.patientId
            && !isNaN(this.props.match.params.patientId)) {
            this.props.setPatientDocuments(this.props.match.params.patientId)
        } else {
            this.props.history.push('/statuscode/404')
        }
        document.title = window.location.href
    }
    render() {
        return (
            this.props.allPatientDocuments ? <div className='patient-document-details container-fluid'>
                <pre>{JSON.stringify(this.props.allPatientDocuments)}</pre>
            </div> : null
        )
    }
}
export default PatientDocuments
import axios from 'axios';
import constants from '../config/constants';
import API_URL from '../config/api';


let apiConfig = window._env_ || {};
const headers = {}
const auth_token = localStorage.getItem('access_token')

let instance = axios.create({
})

instance.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

export default instance;
import React, { Component } from 'react';

import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import userIcon from '../../../assets/images/user_avtar.png';
import constants from '../../../config/constants';
import PageTitle from '../../../components/PageTitle';
import Select, { components } from 'react-select';

const MultiValueRemove = (props) => {
    if (!props.data.removable) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
};
class CaregiverForm extends Component {
    
    componentDidMount() {
        this.props.getSitesList()
        this.props.toggleNotification(false)
        
        if (this.props.match.params && this.props.match.params.caregiverId) {
            this.props.setCurrentCaregiver(this.props.match.params.caregiverId)
        } else {
            document.title = `${labels.physioage_label}: ${labels.caregiver_labels.add_caregiver}`
            this.props.setPageTitle(labels.caregiver_labels.add_provider)
            this.props.setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.caregiver_labels.add_caregiver, path: '' }
            ])

        }
        if (this.caregiverForm && this.caregiverForm.inputEntry)
            this.caregiverForm.inputEntry.focus()
    }

    render() {
        const { sitesList } = this.props;
        const options = sitesList ? 
          sitesList.map(site => ({
            id: site.id,
            name: site.name,
            removable: true
          })) : [];
          let isClearable = this.props.currentCaregiver && this.props.currentCaregiver.selectedsites ? this.props.currentCaregiver.selectedsites.some(option => option.removable === false) ? false : true : false;
        return (
                <>
                { this.props.currentCaregiver ? 
                    <>
                        <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                        <form className='provider-form container col-lg-9' onSubmit={(e) => { e.preventDefault(); this.props.submitCaregiver(this.props.history) }} >
                            <div className='col-lg-12 row'>
                                <div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
                                    <FormControl value={this.props.currentCaregiver.firstName} required={true} id='firstName' type='text' label={labels.user_labels.firstname} onChange={this.props.editCaregiver} />
                                    <FormControl value={this.props.currentCaregiver.lastName} required={true} id='lastName' type='text' label={labels.user_labels.lastname} onChange={this.props.editCaregiver} />
                                    <FormControl required={true} onChange={this.props.editCaregiver} value={this.props.currentCaregiver && this.props.currentCaregiver.id ? this.props.currentCaregiver.email : null} id='email' validate label={labels.user_labels.email} type='email' />
                                    <div className='d-flex mb-2'>
                                        <div className="col-lg-12 pl-0">
                                            <FormControl onChange={this.props.editCaregiver} id='active'
                                                label={labels.user_labels.active} checked={this.props.currentCaregiver.active ? true : false}
                                                type='checkbox' labelClass='ml-2' />
                                        </div>
                                    </div>
                                    <div className='sites-section'>
                                        <label><abbr title='required'>*</abbr>{labels.provider_labels.sites_label}</label>
                                        <FormControl type='select'
                                            value={this.props.currentCaregiver.selectedsites}
                                            id='siteIds' isMulti={true} 
                                            getOptionLabel={opt => opt.name}
                                            getOptionValue={opt => opt.id} 
                                            // options={this.props.sitesList ? this.props.sitesList : []}
                                            options={options}
                                            onChange={(e) => this.props.editCaregiver(e, 'selectedsites')}
                                            components={{ MultiValueRemove }}
                                            isClearable={isClearable}/>

                                    </div>
                                    <div className='create-provider-btn row justify-center'>
                                        <FormControl type='button' btnType={constants.notification_type.success}
                                            text={this.props.currentCaregiver && this.props.currentCaregiver.id
                                                ? labels.caregiver_labels.update_caregiver : labels.caregiver_labels.create_caregiver} />
                                    </div>
                                </div>
                            </div>    
                        </form>
                    </>
                : null
                }
            </>
        )
    }
}
export default CaregiverForm
import Documents from '../components/Documents'
import { connect } from 'react-redux';
import { actionCreators } from '../modules/documentview';
import { toggleNotification } from '../../../reducers/global-reducer';

const mapStateToProps = state => ({
  isLoading: state.global.isLoading,
  ...state.documents
});

const mapDispatchToProps = {
  ...actionCreators,
  toggleNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Documents);

import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import ReactToPrint from 'react-to-print';
import PatientPdf from '../../../assets/sample pdfs/demo_female_patient_report.pdf';
import PatientPdfPast from '../../../assets/sample pdfs/demo_female_patient_report_past.pdf';
import PatientDemo2Pdf from '../../../assets/sample pdfs/demo_patient_report.pdf';
import demoSummary from '../../../assets/sample pdfs/demo_summary.pdf';
import demo2Summary from '../../../assets/sample pdfs/demo_2_summary.pdf'
// import SpriteIcon from './SpriteIcon';

import sprite from '../../../assets/images/sprite_base.svg';
import labels from '../../../config/localization';
import PatientReport from '../../Visits/components/PatientReport';
import constants from '../../../config/constants';
export default class DownloadReportComponent extends Component {
  static propTypes = {
    linkText: PropTypes.string.isRequired,
    statusPath: PropTypes.string,
    downloadPath: PropTypes.string.isRequired,
  }

  constructor() {
    super()
    this.state = {
      reportStatus: 'checking'
    }
  }


  componentDidMount() {
    let eight_second_delay = 8000;
    this.patientTimerID = setInterval(
      this.fireReportStatus, eight_second_delay
    )
  }
  componentWillUnmount() {
    clearInterval(this.patientTimerID)
  }
  render() {
    return (
      <Element name='download_report'>
        <div className='page-section' id='download_report'>
          <div className='content'>
            <div className='max-container with-max-width'>
              <h2 className='section-title'>
                {/* {labels.analytics_labels.download_report_label} */}
                {this.props.terms['download_report']}
              </h2>
              <div className='download-card'>
                <DownloadButton
                  {...this.props}
                  title="Patient Report"
                  type="current_report"
                  onClick={() => this.props.downloadPatientReport(this.props.presentPatient ? this.props.presentPatient.latestVisitId : null, this.props.history)}
                  sprite={this.spriteFor(this.state['reportStatus'])}
                  target={this.props.downloadPath}
                  message={(this.props.allVisitData && this.props.allVisitData.length == 1) ? "Download PDF" : "Download PDF with Current Results"}
                  isDemo={this.props.isDemo}
                />
                {this.props.allVisitData && this.props.allVisitData.length > 1 ?
                  <DownloadButton
                    {...this.props}
                    title="Patient Report"
                    type="past_report"
                    onClick={() => this.props.downloadPatientReport(this.props.presentPatient ? this.props.presentPatient.latestVisitId : null, this.props.history, true)}
                    sprite={this.spriteFor(this.state['reportStatus'])}
                    target={this.props.downloadPath}
                    message="Download PDF with Past Results"
                    isDemo={this.props.isDemo}
                  />
                  : null}
                {(this.props.allVisitData && this.props.allVisitData.length == 1) ?
                   this.props.role == constants.logged_roles.PT && this.props.lastestVisitDataentry && this.props.lastestVisitDataentry.indexOf(true) == -1 ? null :
                      <DownloadButton
                        title="Visit Summary"
                        sprite={this.spriteFor(this.state['reportStatus'])}
                        target={this.props.visitSummaryPath}
                        onClick={() => this.props.handleDownloadVisitSummary(this.props.history)}
                        isDemo={this.props.isDemo}
                        message="Download PDF" />
                   : null}
              </div>
              {this.props.allVisitData && this.props.allVisitData.length > 1 ? 
               this.props.role == constants.logged_roles.PT && this.props.lastestVisitDataentry && this.props.lastestVisitDataentry.indexOf(true) == -1 ? null :
                <div className='download-card mt-0'>
                  <DownloadButton
                    title="Visit Summary"
                    sprite={this.spriteFor(this.state['reportStatus'])}
                    target={this.props.visitSummaryPath}
                    onClick={() => this.props.handleDownloadVisitSummary(this.props.history)}
                    isDemo={this.props.isDemo}
                    message="Download PDF" />
                </div> : null}
            </div>
          </div>
        </div>
      </Element>
    )
  }

  statusForCode(code) {
    switch (code) {
      case null: return ('checking');
      case 200: return ('ready');
      case 202: return ('buildling');
      case 409: return ('processing');
      case 410: return ('failure');
      default: return ('failure');
    }
  }

  spriteFor(reportStatus) {
    switch (reportStatus) {
      case 'checking': return ('clock');
      case 'ready': return ('download');
      case 'buildling': return ('pencil');
      case 'processing': return ('pencil');
      case 'failure': return ('x');
      default: return ('x');
    }
  }

  messageFor(reportStatus) {
    switch (reportStatus) {
      case 'checking': return ('Checking PDF status');
      case 'ready': return ('Download PDF');
      case 'buildling': return ('Building Report');
      case 'processing': return ('Receiving Data');
      case 'failure': return ('Error building report.');
      default: return ('Unexpected Reporting Glitch');
    }
  }
}

class DownloadButton extends Component {
  render() {
    // if (this.props.title == "Patient Report") {
    return (
      !this.props.isDemo ?
        <a className='downloadable'
          onClick={() => this.props.onClick()}>
          <svg aria-hidden='true' title={this.props.title}>
            {/* <use xlinkHref={`${sprite}#download`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
            <use xlinkHref="#download" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
            <symbol id="download" viewBox="0 0 48 48">
              <line data-cap="butt" fill="none" stroke-width="3" stroke-miterlimit="10" x1="24" y1="3" x2="24" y2="36" stroke-linejoin="miter" stroke-linecap="butt"></line>
              <polyline fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" points="11,23 24,36 37,23 " stroke-linejoin="miter"></polyline>
              <line data-color="color-2" fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" x1="2" y1="45" x2="46" y2="45" stroke-linejoin="miter"></line>
            </symbol>
          </svg>
          <div className='detail'>
            <div className='action'>{this.props.title}</div>
            <div className='description'>{this.props.message}</div>
          </div>
        </a>
        :
        this.props.title == 'Patient Report' ?
          <a className='downloadable' href={window.location.pathname.indexOf('old_demo_1') == 1 ? this.props.type == 'current_report' ? PatientPdf : PatientPdfPast : PatientDemo2Pdf} download
          >
            <svg aria-hidden='true' title={this.props.title}>
              {/* <use xlinkHref={`${sprite}#download`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
              <use xlinkHref="#download" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
              <symbol id="download" viewBox="0 0 48 48">
                <line data-cap="butt" fill="none" stroke-width="3" stroke-miterlimit="10" x1="24" y1="3" x2="24" y2="36" stroke-linejoin="miter" stroke-linecap="butt"></line>
                <polyline fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" points="11,23 24,36 37,23 " stroke-linejoin="miter"></polyline>
                <line data-color="color-2" fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" x1="2" y1="45" x2="46" y2="45" stroke-linejoin="miter"></line>
              </symbol>
            </svg>
            <div className='detail'>
              <div className='action'>{this.props.title}</div>
              <div className='description'>{this.props.message}</div>
            </div>
          </a > :
          <a className='downloadable' href={window.location.pathname.indexOf('old_demo_1') == 1 ? demoSummary : demo2Summary} download
          >
            <svg aria-hidden='true' title={this.props.title}>
              {/* <use xlinkHref={`${sprite}#download`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
              <use xlinkHref="#download" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
              <symbol id="download" viewBox="0 0 48 48">
                <line data-cap="butt" fill="none" stroke-width="3" stroke-miterlimit="10" x1="24" y1="3" x2="24" y2="36" stroke-linejoin="miter" stroke-linecap="butt"></line>
                <polyline fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" points="11,23 24,36 37,23 " stroke-linejoin="miter"></polyline>
                <line data-color="color-2" fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="10" x1="2" y1="45" x2="46" y2="45" stroke-linejoin="miter"></line>
              </symbol>
            </svg>
            <div className='detail'>
              <div className='action'>{this.props.title}</div>
              <div className='description'>{this.props.message}</div>
            </div>
          </a >
    )
    // }
    // else {
    //   return (
    //       <a className='downloadable'
    //         onClick="#">
    //         <svg aria-hidden='true' title={this.props.title}>
    //           <use xlinkHref={`${sprite}#download`} xmlnsXlink='http://www.w3.org/1999/xlink'></use>
    //         </svg>
    //         <div className='detail'>
    //           <div className='action'>{this.props.title}</div>
    //           <div className='description'>{this.props.message}</div>
    //         </div>
    //       </a>
    //   )
    // }
  }
}



import ResetPassword from './ResetPassword';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updatePassword, loginInput ,validateResetPassword} from '../../reducers/global-reducer'

const mapStateToProps = state => ({
  ...state.global,
});

const mapDispatchToProps = {
  updatePassword, loginInput,validateResetPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ResetPassword));

import React from 'react';
import PropTypes from 'prop-types';
import Grade from './Grade';
import labels from '../../../config/localization';
import { setSitePreference } from '../tools/calendar';
import { getRoundedValue, getReferenceRanges } from '../tools/helpers';
import NoumenonValue from './NoumenonValue';

export default class HistoricDataTable extends React.Component {
  static propTypes = {
    noumenon_name: PropTypes.string.isRequired,
    historic_data: PropTypes.array.isRequired,
    dateFormat: PropTypes.string.isRequired,
  };
  roundReferenceRanges(referenceRange, scale) {
    var refRanges = referenceRange ? getReferenceRanges(referenceRange, scale) : null
    return refRanges && typeof (refRanges) != "number" ? refRanges : referenceRange
  }
  render() {
    var createRow = (datum, index) => <tr key={index}><td data-label='Date'>{setSitePreference(this.props.siteData.datePreference, datum.visitDate)}</td>
      <td data-label='Grade'>{(datum.points == null || datum.points === undefined || isNaN(datum.points)) ? " " : <Grade points={datum.points} />}</td>
      <td data-label='Value' title={datum.explanationDTO && datum.explanationDTO.length > 0 ? datum.explanationDTO[0].explanation : ''}>
          { (datum.valueType && datum.valueType.toLowerCase() == "st" && datum.isTextArea == 1) ?
            datum.value.length > 50 ?
                <div>
                { datum.value.slice(0,50)} <span onMouseOver={() => this.props.openValueModel(datum.value,datum.label)}> ... </span>
                </div>
            : datum.value
            :(datum.valueType == "NM" || datum.valueType == "Numeric") ? getRoundedValue(datum.value, datum.scale) : datum.value}{datum.explanationDTO && datum.explanationDTO.length > 0 ? '*' : ''}
        </td>
      <td data-label='Units'>{datum.units}</td>
      <td data-label='Reference Range'>{(datum.valueType == "NM" || datum.valueType == "Numeric") ? this.roundReferenceRanges(datum.referenceRange, datum.scale) : datum.referenceRange}</td></tr>;

    return (
      <>
        <table className='table table-striped table-default'>
          <thead>
            <tr>
              <th>{labels.analytics_labels.date_label}</th>
              <th>{labels.analytics_labels.grade_label}</th>
              <th>{labels.analytics_labels.value_label}</th>
              <th>{labels.analytics_labels.units_label}</th>
              <th>{labels.analytics_labels.reference_range}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.historic_data.map(createRow)}
          </tbody>
        </table>
        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 credits-children'>
          {this.props.openValueModal ?
            <NoumenonValue {...this.props}></NoumenonValue> : null}
        </div>
      </>
    );
  }
}

import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import { toggleLoading, toggleNotification, setPageTitle, setBreadCrumbs, navigatePatients, handleErrors, handleStatusCodes, handleLoading } from '../../../reducers/global-reducer';
import labels from '../../../config/localization';
import messages from '../../../config/messages';
import constants from '../../../config/constants';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import { toast } from 'react-toastify';


const SET_PROPS = 'SET_PROPS'
const notifySuccess = (message) => toast.success(message);
const notifyError = (message) => toast.error(message);

function submitFeedback(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let feedbackForm = getState().feedback.feedbackForm ? getState().feedback.feedbackForm : {}
        if (feedbackForm && feedbackForm.feedback_text) {  
            let feedbackParams = [];
            feedbackParams.Comment = feedbackForm.feedback_text.replace(new RegExp(/\n/, 'g'),'<br/>' )
            feedbackParams.Rating = feedbackForm.rating ? feedbackForm.rating : ""
            feedbackParams.Source = 'Business'
            feedbackParams.UserID = getState().feedback.loggedInUser.id
            axios.post(`${API_URL.FEEDBACK}`, { ...feedbackParams }).then(response => {
                dispatch(toggleLoading(false))
                history.push('/patients/list')
                let message = labels.feedback_labels.feedback_success
                notifySuccess(message)
            }).then(error => {
                dispatch(handleErrors(error))
            })
        }
        else {
            dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.feedback_validation], false))
            dispatch(toggleLoading(false))
        }
    }
}
function clearFeedback() {
    return (dispatch) => {
        dispatch(
            {
                type: SET_PROPS,
                payload: {
                    feedbackForm: {},
                }
            }
        )
    }
}

function getAllFeedbackList(){
    return (dispatch) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(API_URL.GET_ALL_FEEDBACK).then((response) => {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    feedbackList: response.data,
                    no_grid_data: response.data && response.data[0].id == 0 ? true : false,
                }
            })
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
        
        dispatch(
            {
                type: SET_PROPS,
                payload: {
                    feedbackList: null
                }
            }
        )
    }
}
export function deleteFeedback(id) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                feedbackList: [],
            }
        })
        axios.delete(`${API_URL.FEEDBACK}/${id}`).then(response => {
            dispatch(getAllFeedbackList());
            notifyError("Feedback deleted successfully")

        })
    }
}

export function editFeedbackForm(event) {
    return (dispatch, getState) => {
        let feedbackForm = { ...getState().feedback.feedbackForm }
        let key = event.target.id
        let value = event.target.value
        if (getState().feedback.feedbackForm && getState().feedback.feedbackForm[key] != value) {
            feedbackForm = getState().feedback.feedbackForm ? { ...getState().feedback.feedbackForm, [key]: value } : { [key]: value }
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                feedbackForm,
            }
        })
    }
}


const initialState = {
    feedbackForm: {feedback_text: ""},
    feedbackCommnetErrors: []
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export const actionCreators = {
    submitFeedback,
    clearFeedback,
    getAllFeedbackList,
    setBreadCrumbs,
    setPageTitle,
    deleteFeedback,
    editFeedbackForm,
    navigatePatients
}
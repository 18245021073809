import React, {useState} from 'react';
import ReactDom from 'react-dom';
import delete_icon from '../../../assets/images/delete_red.svg'
import { connect } from 'react-redux';
import closeLight from '../../../assets/images/close-light.svg';
import close from '../../../assets/images/close.svg';


function DeleteRecommendationWarning(props){
    let [property, setProperty] = React.useState(props.property);
    return ReactDom.createPortal(
        <>
            <div className={`drawer-warning-overlay drawer-warning-overlay-${props.theme}`} onClick={() => ( property == "problem" ?  props.toggleDeleteProblemPopup(false) : props.toggleDeleteRecommendationPopup(false))} />
            <div className={`warning-div popup-${props.theme} ${props.siteTheme}`}>
                <div className='row header no-gutters'>
                <div className='col-11'></div>
                <div className='col-1 pt-1'>
                    <a><img className='float-right' src={props.theme == "light-theme" ? close : closeLight} onClick={() => (property == "problem" ?  props.toggleDeleteProblemPopup(false) : props.toggleDeleteRecommendationPopup(false))}></img></a>
                </div>
                </div>
                <div className='warning-body'>
                    <img className='width-30' src={delete_icon}/>
                    <h5>{`Remove ${ property == "problem" ? "Problem" : props.recType}`}</h5>
                    <hr />
                        <p>{ `This will delete the ${ property == "problem" ? "Problem" : props.recType.toLowerCase()} and the patient cannot see it anymore in the analytics page. Are you sure you want to delete the ${ property == "problem" ? "Problem" : props.recType.toLowerCase()}?`}</p>
                    <hr />
                    <div className='d-flex justify-content-center'>
                        <button className='btn drawer-cancel' onClick={() => { property == "problem" ?  props.toggleDeleteProblemPopup(false) : props.toggleDeleteRecommendationPopup(false); }}>Cancel</button>
                       { property == "problem" ?  
                            <button className='btn drawer-ok ml-4' onClick={() => {
                                props.deleteProblem(props.deleteProblemId ? props.deleteProblemId : 0);
                                props.toggleDeleteProblemPopup(false); 

                            }}>Delete</button>
                            : 
                            <button className='btn drawer-ok ml-4' onClick={() => {
                                props.deleteRecommendations(props.deleteRecommendId ? props.deleteRecommendId : 0 , _.head(props.recommendationCurrentRow.indicators), props.recType);
                                props.toggleHistoryRecommendationPopup(false);
                                props.toggleDeleteRecommendationPopup(false);
                            }}>Delete</button>
                        }  
                    </div>
                </div>    
            </div>

        </>,
        document.getElementById('surveyPortal')
    )
}
   

export default connect()(DeleteRecommendationWarning);
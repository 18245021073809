import { connect } from 'react-redux'
import SignUp from './SignUp';
import {setPageTitle, setBreadCrumbs, toggleNotification, setLoggedInUser, navigatePatients, setCreditsCaution, editSignUpUser, resetSignUpPassword, editSignUpPassword, editSignUpSite, setCurrentSignUpSite, logoUploadedInSignUp, removeLogoPicInSignUp, removeSignUpUserPic, uploadSignUpUserPic, saveSignUpUser, setCurrentSignUpUser, toggleSignupRendering, editBloodAndUrinePanels, editOptionalBiomarkerPanels, GetAllNoumenonNames, editNoumenon, setNoumenonFields, removeNoumenonFields, addNoumenonFields } from '../../reducers/global-reducer';

const mapStateToProps = (state) => ({
    currentSignUpUser: state.global.currentSignUpUser,
    isPasswordMatched: state.global.isPasswordMatched,
    breadCrumbs: state.global.breadCrumbs,
    currentSignUpSite: state.global.currentSignUpSite,
    isLoading: state.global.isLoading,
    pageTitle: state.global.pageTitle,
    // isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify, 
    toggleSignupRender: state.global.toggleSignupRender,
    // loggedInUser: state.global.loggedInUser,
    allNoumenonNameList: state.global.allNoumenonNameList,
    CreateNoumenonList : state.global.CreateNoumenonList
})

const mapDispatchToProps = {
    setPageTitle,
    toggleNotification,
    editSignUpUser,
    resetSignUpPassword,
    editSignUpPassword,
    editSignUpSite,
    setCurrentSignUpSite,
    logoUploadedInSignUp,
    removeLogoPicInSignUp,
    removeSignUpUserPic,
    uploadSignUpUserPic,
    navigatePatients,
    setBreadCrumbs,
    saveSignUpUser,
    setCurrentSignUpUser,
    toggleSignupRendering,
    editBloodAndUrinePanels,
    editOptionalBiomarkerPanels,
     // setCreditsCaution,
     GetAllNoumenonNames,
     editNoumenon,
     setNoumenonFields,
     removeNoumenonFields,
     addNoumenonFields
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
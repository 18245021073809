import React, { Component } from 'react';
import _ from 'lodash';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls';
import constants from '../../../config/constants';
import './palettes.scss';
import sample_report_cards from '../../../config/sample_report_cards';

class ReportCardGrades extends Component {
    componentDidMount() {
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.noumena_labels.sample_report_title, path: '' }
        ])
        this.props.setPageTitle(labels.noumena_labels.sample_report_title)
        document.title = `${labels.physioage_label}: ${labels.noumena_labels.sample_report_title}`
    }
    render() {
        return (
            <div className='row container report-card'>
                <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                    {labels.noumena_labels.concerns_title}
                    <ul>
                        {labels.noumena_labels.concerns.map(con => (
                            <li>{con}</li>
                        ))}
                    </ul>
                    {labels.noumena_labels.color_palette_help}
                    <FormControl options={constants.color_palettes}
                        nodefaultOption={true}
                        defaultValue={_.head(constants.color_palettes).value}
                        type='select'
                        onChange={this.props.colorPalatteChange}
                        valueKey={'value'} textKey={'text'} />
                </div>
                <div className={`col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.currentcolor}`}>
                    {sample_report_cards.map((cp) => (
                        <div className='grade-section'>
                            <div className={`grade-block text-center ${cp.class}`}>
                                <b>{cp.display}</b>
                            </div>
                            <div className='desc'>{cp.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
export default ReportCardGrades;
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import sprite from '../../../assets/images/sprite_base.svg';
import Grade from './Grade';
import DatumSparkline from '../containers/DatumSparklineContainer';
import { formatDate } from '../tools/calendar';
import labels from '../../../config/localization';
import { setSitePreference } from '../tools/calendar';
import { uniformString, getRoundedValue, getReferenceRanges } from '../tools/helpers';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import moment from 'moment';
import NoumenonValue from './NoumenonValue';

export default class DatumRowComponent extends React.Component {
  static propTypes = {
    datum: PropTypes.object.isRequired,
    namespace: PropTypes.string.isRequired,
    hasRecommendation: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props)
    this.renderRow = this.renderRow.bind(this);
    this.renderLongView = this.renderLongView.bind(this);
    this.nonblankHistoricData = this.props.datum.historicData ? this.props.datum.historicData.filter(this.ignoreBlank) : '';
    this.state = {
      defaultDrawer: false
    }
  }
  componentDidMount() {
    var drawerId = `datumdrawer-${uniformString(this.props.datum.code)}`
    if (this.props.datum && this.props.hashDrawer
      && (this.props.hashDrawer == uniformString(this.props.datum.code))
      && document.getElementById(drawerId)) {
      scroller.scrollTo(drawerId, { offset: -70 })
      let datum_code = this.props.datum.code
      if (this.props.categories) {
        this.props.categories.forEach(function (cat) {
          if (cat.sections) {
            cat.sections.forEach(function (section) {
              if (section.codes && section.codes.indexOf(datum_code) != -1) {
                if (cat.sections.length > 1) {
                  _.head(document.getElementById(`sidebar-${uniformString(
                    cat.enName
                  )}`).getElementsByClassName("section-toggle")).click()
                }
                document.getElementById(`sidebar-${uniformString(
                  cat.enName
                )}`).scrollIntoView()
              }
            })
          }
        })
      }
      document.getElementById(drawerId).click()
    }
  }
  ignoreBlank(item) {
    return (item.value);
  }

  renderLongView(datum) {
    if (this.nonblankHistoricData.length) {
      if (_.head(this.nonblankHistoricData).valueType == "NM") {
        return (
          <DatumSparkline uniqueID={this.props.section.key + '_' + this.props.datum.code + '_sparkline'} historic_data={this.nonblankHistoricData} />
        );
      }
    }
  }
  historic_value(datum) {
    if (datum.historic) {
      return (
        <div className="observation-date icon">
          <svg aria-hidden="true" title="Observation Date">
            {/* <use xlinkHref={`${sprite}#clock`} xmlnsXlink="http://www.w3.org/1999/xlink"></use> */}
            <use xlinkHref="#clock" xmlnsXlink="http://www.w3.org/1999/xlink"></use>
            <symbol id="clock" viewBox="0 0 512 512">
              <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z" class="">
              </path>
            </symbol>
          </svg>
          {labels.analytics_labels.as_of_label} {setSitePreference(datum.siteDatePreference, datum.visitDate)}
        </div>
      )
    }
    else {
      return (null);
    }
  }

  reference_range(datum) {
    var refRanges = this.props.datum.referenceRange ? getReferenceRanges(this.props.datum.referenceRange, this.props.datum.scale) : null
    if (datum.referenceRange && datum.referenceRange.length > 0) {
      return (
        <div className="reference_range">{refRanges && typeof (refRanges) != "number" ? refRanges : this.props.datum.referenceRange}</div>
      )
    }
    else {
      return (null);
    }
  }

  getRowClassName(datum) {
    if (moment(this.props.updated_lastVisitDate).format('YYYY-MM-DD') <= moment(datum.visitDate).format('YYYY-MM-DD')) {
      return "card-content-row"
    }
    else {
      return "card-content-row fade-row"
    }
  }

  renderRow() {
    let datum = this.props.datum;
    let commentClass = "";
    let hasMetaRec = false;
    if (this.props.recommendations) {
      var hasRec = !!this.props.recommendations.find(
        rec => { return (rec.indicators.indexOf(datum.code) >= 0) }
      )
    }

    if (this.props.metaRecommendationCodes && this.props.metaRecommendationCodes.length > 0) {
      hasMetaRec = this.props.metaRecommendationCodes.find((code) => {
        return code == datum.code;
      })
    }


    if (hasRec || hasMetaRec) {
      commentClass = "btn-reset comment-indicator active"
    }
    else {
      commentClass = "btn-reset comment-indicator "
    }

    return (
      <>
      <li id={`datumdrawer-${uniformString(datum.code)}`} className='datum-drawer' onClick={() => {
        this.props.toggleDatumSideBar(datum, this.props.section, 'datum', this.props.history)
      }} className={`${this.getRowClassName(datum)}`} tabIndex="0">
        <div className="heading">
          <button className={commentClass} >
            {/* <svg aria-hidden="true">
              <use xlinkHref={`#${datum.code}_data`} xmlnsXlink="http://www.w3.org/1999/xlink"></use>
              <symbol id={`${datum.code}_data`} viewBox="0 0 18 18">
                <path fill="#444444" fill-rule="nonzero" d="M3.617,16.924 C3.24340926,16.7691037 2.99989823,16.4044291 3,16 L3,9 L5,9 L5,13.586 L7.293,11.293 C7.48049273,11.1054506 7.73480519,11.0000566 8,11 L15,11 L15,5 L9,5 L9,3 L16,3 C16.5522847,3 17,3.44771525 17,4 L17,12 C17,12.5522847 16.5522847,13 16,13 L8.414,13 L4.707,16.707 C4.42101445,16.9931653 3.99077762,17.0788179 3.617,16.924 Z" opacity=".365" />
                <polygon fill="#444444" fill-rule="nonzero" points="7 3 5 3 5 1 3 1 3 3 1 3 1 5 3 5 3 7 5 7 5 5 7 5" />
                {hasRec ? <circle cx="4" cy="4" r="3" fill="#5BC0DE" stroke="#79E0FF" /> : ''}
                {!hasRec && hasMetaRec ? <circle cx="4" cy="4" r="3" fill="#dc3545" stroke="#fd6271" /> : ''}
                {hasRec && hasMetaRec ? <circle cx="11.1" cy="4" r="3" fill="#dc3545" stroke="#fd6271" /> : ''}
              </symbol>
            </svg> */}
            <Link id='link' activeClass='active' className='scrollable spyable' to='treatment-plan' spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              {hasRec ? <i className="fa fa-circle message-dot message-blue-dot" aria-hidden="true"></i> : ''}
            </Link>
            <Link id='link' activeClass='active' className='scrollable spyable' to='metaRecommendation' spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              {hasMetaRec ? <i className="fa fa-circle message-dot message-red-dot" aria-hidden="true"></i> : ''}
            </Link>
            {/* {hasRec ? <i className="fa fa-circle message-dot message-blue-dot" aria-hidden="true"></i> : ''} */}
            {/* {hasMetaRec ? <i className="fa fa-circle message-dot message-red-dot" aria-hidden="true"></i> : ''} */}
            <svg aria-hidden="true" version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#676060" stroke="#676060" stroke-width="2">
                <path d="M655 4920 c-319 -35 -574 -268 -640 -585 -22 -105 -22 -2645 0 -2750
49 -235 203 -427 419 -525 107 -49 174 -60 389 -67 l197 -6 0 -321 c0 -180 4
-337 10 -356 5 -20 25 -51 45 -70 46 -45 125 -54 180 -21 20 12 253 189 518
394 l482 372 1120 6 c1234 6 1164 2 1311 69 216 98 370 290 419 525 22 105 22
2645 0 2750 -59 280 -264 495 -548 572 -57 16 -207 17 -1947 19 -1037 1 -1916
-2 -1955 -6z m3887 -338 c110 -45 191 -129 240 -251 l23 -56 0 -1315 0 -1315
-23 -56 c-49 -122 -130 -206 -240 -251 l-57 -23 -1155 -5 c-1050 -5 -1158 -7
-1185 -22 -16 -9 -205 -152 -420 -317 l-390 -302 -5 263 c-6 294 -9 311 -80
353 -32 18 -57 20 -325 25 -271 5 -294 7 -347 28 -110 44 -191 129 -240 251
l-23 56 -3 1264 c-1 763 1 1292 7 1333 13 94 59 187 121 248 58 56 90 75 170
101 52 17 158 18 1965 16 l1910 -2 57 -23z"/>
              </g>
            </svg>
          </button>
          {/* IMPORTENT: DO NOT ADD ANY ELEMENT HERE as there is a sibling css selector used for the below element */}
          <div className="messageIconHover">
            <Link id='link' activeClass='active' className='scrollable spyable' to='treatment-plan' spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              {hasRec && !hasMetaRec ? <div><span className="mr-1"><svg height="15" width="10"><circle cx="5" cy="6" r="4" fill="#5BC0DE" stroke="#79E0FF" /></svg></span>Recommendation</div> : ''}
            </Link>
            <Link id='link' activeClass='active' className='scrollable spyable' to='metaRecommendation' spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              {!hasRec && hasMetaRec ? <div><span className="mr-1"><svg height="15" width="10"><circle cx="5" cy="6" r="4" fill="#dc3545" stroke="#fd6271" /></svg></span>Equation based Recommendation</div> : ''}
            </Link>
            <Link id='link' activeClass='active' className='scrollable spyable' to='treatment-plan' spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              {hasRec && hasMetaRec ?
                <div>
                  <div><span className="mr-1"><svg height="15" width="10"><circle cx="5" cy="6" r="4" fill="#5BC0DE" stroke="#79E0FF" /></svg></span>Recommendation</div>
                  <div><span className="mr-1"><svg height="15" width="10"><circle cx="5" cy="6" r="4" fill="#dc3545" stroke="#fd6271" /></svg></span>Equation based Recommendation</div>
                </div>
                : ''}
            </Link>
          </div>
          <Grade points={datum.points} />
          <div className='title' title={this.props.presentPatient.languagePreference != "en" ? datum.enLabel : ''}>{datum.label}</div>
        </div>
        <div className='result'>
          { (datum.valueType && datum.valueType.toLowerCase() == "st" && datum.isTextArea == 1) ?
          <div className='result-value'>
              {datum.value.length > 50 ?
                <div>
                  {datum.value.slice(0,50)} <span onMouseOver={() => this.props.openValueModel(datum.value, datum.label)}> ... </span>
                </div>
              : "" }
          </div>
          :
            <div className='result-value'>{(datum.valueType == "NM" || datum.valueType == "Numeric") ? getRoundedValue(datum.value, datum.scale) : datum.value} {datum.units}</div>
          }
        </div>
        <div className='reference-range-wrapper'>
          {(datum.valueType == "NM" || datum.valueType == "Numeric") ? this.reference_range(datum) : datum.referenceRange}
        </div>
        <div className='graph'>
          {this.renderLongView(datum)}
        </div>
        <div className='controls'>
          <button className='btn-reset view-more'>
            <svg className='fill' aria-hidden='true'>
              {/* <use xlinkHref={`${sprite}#arrow-right`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
              <use xlinkHref="#arrow-right" xmlnsXlink='http://www.w3.org/1999/xlink'></use>
              <symbol id="arrow-right" viewBox="0 0 16 16">
                <path d="M5.108,16.41L3.59,15.108L9.683,8L3.59,0.892L5.108-0.41l6.651,7.76c0.321,0.374,0.321,0.927,0,1.301L5.108,16.41z"></path>
              </symbol>
            </svg>
          </button>
        </div>

      </li>
      {this.props.openValueModal ?
      <NoumenonValue {...this.props}></NoumenonValue> : null}
      </>
    )
  }
  render() {
    return (this.props.datum.value ? this.renderRow() : null);
  }
}

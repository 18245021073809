import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { parse } from 'qs';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';

import labels from '../../../config/localization';
import constants from '../../../config/constants';
import './oddities-styles.scss';
import { returnLocalizedDate } from '../../../utils/resolve-timer'
import LoadingTemplate from '../../../components/LoadingTemplate';

class OddityMessageGroup extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        if (this.urlParams && (!this.props.messageGroup || !this.props.messageGroup.length)) {
            this.props.getMessageGroup(this.urlParams.message)
        }
        this.props.setPageTitle(labels.oddities_labels.messagegroup)
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.oddities_labels.oddities, path: '/oddities/list' },
            { text: labels.oddities_labels.messagegroup, path: '' }
        ])
        document.title = `${labels.physioage_label}: ${labels.oddities_labels.messagegroup}`
    }
    render() {
        const columns = [
            {
                dataField: 'date', text: labels.oddities_labels.date_time,
                formatter: (cellContent, row) => (moment(returnLocalizedDate(cellContent)).format("YYYY-MM-DD hh:mm:ss A"))
            },
            {
                dataField: 'observationId', text: labels.oddities_labels.reference
                , formatter: (cellContent, row) => (<p>{labels.oddities_labels.source_label}: {row.sourceType ? row.sourceType && row.sourceType.toLowerCase() == constants.message_type
                    ? <a href='javascript:void(0)' onClick={() => this.props.history.push(`/messages/details/${row.messageId}`)}>{row.source}</a>
                    : row.sourceType && row.sourceType.toLowerCase() == constants.sourceType.provider
                        ? <a href='javascript:void(0)' onClick={() => this.props.history.push(`/providers/${row.messageId}`)}>{row.source}</a>
                        : row.sourceType && row.sourceType.toLowerCase() == constants.sourceType.user
                            ? <a href='javascript:void(0)' onClick={() => this.props.history.push(`/users/${row.messageId}`)}>{row.source}</a>
                            : <p>{row.source}</p>:'Unknown'}<br></br>
                    {`${labels.oddities_labels.result_label}: ${row.resultId}`}<br></br>
                    {labels.oddities_labels.observation}: {row.observationId
                        ? <a href='javascript:void(0)'
                            onClick={() => this.props.history.push(`/observations/${row.observationId}`)}>
                            {row.observationId}
                        </a>
                        : row.observationId}
                </p>)
            },
            {
                dataField: 'message', text: labels.oddities_labels.message_label
                , formatter: (cellContent, row) => (<p className='message-column'>{row.message}</p>)
            },
        ]
        return (
            this.props.isLoading ? <LoadingTemplate haveSidebar={true} isGrid={true} noSidedata={true} /> :
                <>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className='react-bootstrap-table oddity-messages-list col-lg-9'>
                        {this.props.messageGroup && this.props.messageGroup.length ?
                            <div className='bootstrap-table-container'>
                                <BootstrapTable
                                    remote
                                    striped={true}
                                    bordered={true}
                                    hover={true}
                                    bootstrap4
                                    keyField='id'
                                    data={this.props.messageGroup}
                                    columns={columns}
                                    pagination={false}
                                />
                            </div>
                            : this.props.no_grid_data ? <div className='oddity-messages-list'>{labels.no_grid_data}</div> : null}
                    </div></>
        )
    }
}
export default OddityMessageGroup
import React, { useEffect } from 'react';
import physioAgeLogo from '../../../assets/images/analytics-masthead-inverted.svg'
import {
  Link,
  Element,
  animateScroll as scroll,
} from 'react-scroll';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import TopNavLoadingContainer from '../containers/TopNavLoadingContainer';
import { connect } from 'react-redux';

function LoadingComponent(props) {
  useEffect(() => {
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.add('body-class');
    // componentDidMount //
    return () => {
      let body = document.getElementsByTagName("BODY")[0];
      body.classList.remove('body-class');
      // componentWillUnmount() //
    }
    }, [])


  const state_description = (status)=> {
    if (status == 'finished')
      return labels.finished
    else if (status == 'pending') {
      return labels.pending
    }
    else {
      props.updateLoadingStatus(true)
      // return labels.no_data
    }
  }
  const myClass =(statusData, statusGraph) => {
    if (statusData == 'pending') {
      return ('visible');
    }
    if (statusData == constants.notification_type.error) {
      if (statusGraph == 'pending' || statusGraph == constants.notification_type.error) {
        return ('visible');
      }
      else {
        return ('clear');
      }
    }
    else {
      return ('clear');
    }
  }

  return (
    props.terms && props.siteData ?
      <section id='loading' className='dashboard-loading-div'>
        {props.toggleLoading(false)}
        {myClass(props.loadingData, props.loadingPatient) == 'clear' ? props.unMountingLoadingComponent(true)  : ''}
        {/* <TopNavLoadingContainer splashVisibility={myClass(props.loadingData, props.loadingPatient)} {...props} /> */}
        {/* <Element name='splash'>
          <div id='splash' className={`${props.siteData ? `hero brand beta ${props.siteData.logoFile ? 'vendor' : ''}`
            : ''}`}>
            {props.siteData ?
              <>
                <div className={`logo ${props.siteData.logoFile ? "mt-0 mb-0" : ''}`}>{props.siteData ? <img src={props.siteData.logoFile
                  ? `data:${props.siteData.logoContentType};base64,${props.siteData.logoFile}`
                  : physioAgeLogo} alt='PhysioAge Health Analytics' /> : ''}
                  <div className={`loading-data-text ${props.siteData.logoFile ? 'loading-data-text-black' : ''}`}>
                    Your data is being prepared...
                  </div>
                </div>
                {props.siteData.logoFile ?
                  <div className='powered-by-physioage row invisible'>
                    {labels.analytics_labels.powered_by_physioage}
                  </div> : null}
              </>
              : <></>}
            <div className='quick-links'>
              <Link className='link-item scrollable stroke' activeClass='active' to='biographical-info' spy={true}
                smooth={true}
                offset={0}
                duration={500}>
                {(state_description(props.loadingSections) == "Finished" && state_description(props.loadingCategories) == "Finished") || myClass(props.loadingData, props.loadingPatient) == 'clear' ? <svg className={`animated-check ${props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                  <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                <span className='title'>{props.terms['biographical_information']}</span>
              </Link>
              <Link activeClass='active' className='link-item scrollable fill' to='biomarkers-of-aging' spy={true}
                smooth={true}
                offset={0}
                duration={500}>
                {(state_description(props.loadingPatient) == "Finished") || myClass(props.loadingData, props.loadingPatient) == 'clear' ? <svg className={`animated-check ${props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                  <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                <span className='title'>{props.terms['Biomarkers of Aging']}</span>
              </Link>
              <Link activeClass='active' className='link-item scrollable stroke' to='medication' spy={true}
                smooth={true}
                offset={0}
                duration={500}>
                {(state_description(props.loadingRecommendations) == "Finished") || myClass(props.loadingData, props.loadingPatient) == 'clear' ? <svg className={`animated-check ${props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                  <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                <span className='title'>Medications</span>
              </Link>
              <Link activeClass='active' className='link-item scrollable stroke' to='health_status_indicators' spy={true}
                smooth={true}
                offset={-45}
                duration={500}>
                {(state_description(props.loadingData) == "Finished" && state_description(props.loadingCategories) == "Finished") || myClass(props.loadingData, props.loadingPatient) == 'clear' ? <svg className={`animated-check ${props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                  <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                <span className='title'>{props.terms['Health Report Card']}</span>
              </Link>
              <Link activeClass='active' className='link-item scrollable stroke' to='treatment-plan' spy={true}
                smooth={true}
                offset={0}
                duration={500}>
                {(state_description(props.loadingRecommendations) == "Finished") || myClass(props.loadingData, props.loadingPatient) == 'clear' ? <svg className={`animated-check ${props.siteData.logoFile ? 'animated-check-black' : ''}`} viewBox="0 0 24 24">
                  <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg> : <p className={`splash-spinner ${props.siteData.logoFile ? 'splash-spinner-black' : ''}`}><i className='fa fa-spinner fa-spin'></i></p>}
                <span className='title'>{props.terms['Treatment Plan']}</span>
              </Link>
            </div>
            <div className='nav-trigger'></div>
          </div>
        </Element> */}
          <div className="">
            <div className="row height-50">
              <div className="col-12 skeleton-box height-53">
              </div>
            </div>
            <div className="row height-95 d-flex">
              <div className="width-4_2 skeleton-box height-95">
              </div>
              <div className="width-95_8 pl-3">
                <div className="row d-flex mt-3">
                  <div className="col-6 height-95 no-gutters pr-3">
                    <div className="row no-gutters height-30">
                      <div className="col-12 no-gutters skeleton-box br-r height-30"> 
                      </div>
                    </div>
                    <div className="row no-gutters height-65 mt-3">
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    </div>
                  </div>
                  <div className="col-6 height-95 pl-0 pr-5">
                    <div className="row no-gutters height-70vh">
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className=" col-12 height-8 skeleton-box br-r">
                      </div>
                      <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    <div className="col-12 height-8 skeleton-box br-r">
                    </div>
                    </div>
                    <div className="row no-gutters height-25vh mt-3 justify-content-center">
                      <div className="width-23 skeleton-box br-r ml-1"></div>
                      <div className="width-23 skeleton-box br-r ml-2"></div>
                      <div className="width-23 skeleton-box br-r ml-2"></div>
                      <div className="width-23 skeleton-box br-r ml-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section> : <>{props.toggleLoading(true)}</>
  );
}
export default connect()(LoadingComponent);
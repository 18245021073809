import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import FormControl from '../FormControls/FormControl';
import './login.scss';
import labels from '../../config/localization';
import Notification from '../Notification';
import { toggleNotification } from '../../reducers/global-reducer';
import Loader from '../Loader';
import constants from '../../config/constants';

export default class Login extends Component {
  componentDidMount() {
    this.props.toggleNotification(false)
    this.props.toggleSignupRendering(true);
    this.props.deleteCurrentSignupPaymentDetails();
    this.props.getAuthStatus()
    this.props.clearSentMailStatus()
    if (!this.props.isAuthenticated && window.location.href.indexOf('login') == -1) {
      // this.props.history.location = { ...window.location, href: '/login' }
      // this.props.history.replace(history)
      window.location.replace('/login')
    }
    else if (this.props.isAuthenticated && localStorage.getItem(constants.accessToken)) {
      if (this.props.loggedInUser && (this.props.loggedInUser.role == 'admin' || this.props.loggedInUser.role == 'caregiver')
        && window.location.href.indexOf('patients/list') == -1) {
        window.location.replace('/patients/list')
      } else if (this.props.loggedInUser && (this.props.loggedInUser.role == 'patient')) {
        window.location.replace('/analytics')
      } else {
        window.location.replace('/login')
      }
    }
    document.title = `${labels.physioage_label}: ${labels.login_label}`
  }

  render() {
    return (
      <form className='login-container' onSubmit={(e) => { this.props.loginHandler(e, this.props.history); }}>
        {this.props.isNotify ? <Notification  {...this.props.notifications} /> : ''}
        {this.props.isLoading ? <Loader /> : null}
        <FormControl
          autoComplete="on"
          type='text'
          className='form-control mt-3'
          id='email'
          placeholder={labels.user_labels.username}
          onChange={(e) => this.props.loginInput(e)}
        />
        <div className='help-block'>{labels.login_help_text}</div>
        <FormControl
          autoComplete="on"
          type='password'
          className='form-control mt-3'
          id='password'
          placeholder={labels.user_labels.password}
          onChange={(e) => this.props.loginInput(e)}
        />
        <div className='alert alert-warning'>
          <i className='fa fa-warning'></i><span>{labels.login_caution_start} <Link to='tos'>{labels.footer_labels.tos}</Link> {labels.login_caution_conj} <Link to='privacy'>{labels.footer_labels.privacy}</Link> {labels.login_cookies}</span>
        </div>
        <div className='d-flex login-btn-section'>
          <button className='btn btn-primary'>
            {labels.login_btn_text}
          </button>
          <button className='btn btn-default' onClick={() => this.props.history.push('/forgotpassword')}>
            {labels.forgot_password_text}
          </button>
          <button className='btn btn-primary ml-auto' onClick={()=>this.props.history.push('/signup')}>
            {labels.signup_btn_text}
          </button>
        </div>
        <p>{labels.login_help}:</p>
      </form>
    );
  }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import labels from '../../config/localization';
import './tos-styles.scss';
import constants from '../../config/constants';
import moment from 'moment';

class TermsOfService extends Component {
    componentDidMount() {
        document.title = `${labels.physioage_label}: ${labels.footer_labels.tos}`
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className='tos pb-0'>
                <h1 className='tos-title'>{labels.tos_labels.title}</h1>
                <p>{labels.tos_labels.last_updated}</p>
                <div dangerouslySetInnerHTML={{ __html: constants.terms_of_service }}></div>
                <div className='footer-sec'>
                    <div>
                        <span> &copy; {moment(new Date()).format('YYYY')} {labels.physioage_footer_label} | <span className='footer-link'><Link to='/privacy'>{labels.footer_labels.privacy}</Link></span> |  <span className='footer-link'><Link to='/tos'>{labels.footer_labels.tos}</Link></span></span>
                    </div>
                </div>
            </div>
        )
    }
}
export default TermsOfService
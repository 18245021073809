export default
    {
        demo_1: [
            {
                "patientId": 5254,
                "visitId": null,
                "followUpName": null,
                "documentCreatedAt": "2021-11-06T17:38:38",
                "publicationDate": "2021-11-06T00:00:00",
                "title": "EXERCISE TESTING  PRESCRIPTION GUIDELINES",
                "documentId": 3840,
                "format": "docx",
                "visible": 1
            },
            {
                "patientId": 5254,
                "visitId": null,
                "followUpName": null,
                "documentCreatedAt": "2021-11-06T17:38:50",
                "publicationDate": "2021-11-06T00:00:00",
                "title": "Hotel-based Exercise Program",
                "documentId": 3841,
                "format": "pdf",
                "visible": 1
            },
            {
                "patientId": 5254,
                "visitId": null,
                "followUpName": null,
                "documentCreatedAt": "2021-11-06T17:38:59",
                "publicationDate": "2021-11-06T00:00:00",
                "title": "In-Home Exercise Program",
                "documentId": 3842,
                "format": "pdf",
                "visible": 1
            },
            {
                "patientId": 5254,
                "visitId": null,
                "followUpName": null,
                "documentCreatedAt": "2021-11-06T17:39:10",
                "publicationDate": "2021-11-06T00:00:00",
                "title": "Ketogenic Dietary Plan Guidelines",
                "documentId": 3843,
                "format": "pdf",
                "visible": 1
            },
            {
                "patientId": 5254,
                "visitId": null,
                "followUpName": null,
                "documentCreatedAt": "2021-11-06T17:40:49",
                "publicationDate": "2021-11-06T00:00:00",
                "title": "NUTRITION Program",
                "documentId": 3844,
                "format": "pdf",
                "visible": 1
            }
        ],
        demo_2: null
    }

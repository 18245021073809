import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { parse } from 'qs';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import FormControl from '../../../components/FormControls';
// import Observation from './Observation';
import { returnLocalizedDate } from '../../../utils/resolve-timer'
import LoadingTemplate from '../../../components/LoadingTemplate';

// import ObservationPopUp from './ObservationDetails';
import './oddities-styles.scss';

class OdditiesList extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);

        if (this.urlParams && this.urlParams.tab) {
            this.props.setActiveTab(this.urlParams.tab, this.props.history)

            this.props.getOddities()
            if (this.urlParams.tab.toLowerCase() == 'oddity') {
                if (this.urlParams.page)
                    this.props.oddityPageChange(this.urlParams.page
                        ? parseInt(this.urlParams.page) : 1, this.props.history)
                if (this.urlParams.sortField)
                    this.props.odditiesSortParams(this.urlParams.sortField, this.urlParams.sortOrder, this.props.history)
                this.props.getOddityErrors()
            }

            if (this.urlParams.tab.toLowerCase() == 'errors') {
                if (this.urlParams.page)
                    this.props.errorPageChange(this.urlParams.page
                        ? parseInt(this.urlParams.page) : 1, this.props.history)
                else
                    this.props.getOddityErrors()
            }
        }
        else {
            this.props.clearTabSelection(this.props.history)
            this.props.getOddities()
            this.props.getOddityErrors()
        }
        document.title = `${labels.physioage_label}: ${labels.oddities_labels.oddities}`
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.oddities_labels.oddities, path: '' }
        ])
        this.props.setPageTitle(labels.physioage_report_label)

        this.unlisten = this.props.history.listen((location, action) => {
            if (location && location.search && this.props.location
                && action == 'POP') {
                const queryString = location && location.search && location.search.substr(1)
                this.urlParams = parse(queryString, true);
                if (this.urlParams && this.urlParams.tab) {
                    this.props.setActiveTab(this.urlParams.tab)

                    // this.props.getOddities()
                    if (this.urlParams.tab.toLowerCase() == 'oddity') {
                        if (this.urlParams.page)
                            this.props.oddityPageChange(this.urlParams.page
                                ? parseInt(this.urlParams.page) : 1)
                        if (this.urlParams.sortField)
                            this.props.odditiesSortParams(this.urlParams.sortField, this.urlParams.sortOrder)
                        // this.props.getOddityErrors()
                    }

                    if (this.urlParams.tab.toLowerCase() == 'errors') {
                        if (this.urlParams.page)
                            this.props.errorPageChange(this.urlParams.page
                                ? parseInt(this.urlParams.page) : 1)
                        // else
                        //     this.props.getOddityErrors()
                    }
                }
            }
        })

    }
    componentWillUnmount() {
        this.props.toggleNotification(false)
        this.unlisten()
    }
    componentWillReceiveProps(newProps) {
        if ((this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search)) {
            this.props.clearTabSelection(this.props.history)
            this.props.getOddities()
            this.props.getOddityErrors()
        }
    }
    getCaret = (order, field) => {
        order = this.props.sortOrder ? this.props.sortOrder : order
        if (!order) return (<span className='order-4'></span>);
        else if (order === 'asc' && this.props.sortField == field) return (<span className="caret-4-asc"></span>);
        else if (order === 'desc' && this.props.sortField == field) return (<span className="caret-4-desc"></span>);
        else return (<span className='order-4'></span>);
    }
    render() {
        const columns = [
            {
                dataField: 'date', text: labels.oddities_labels.date_time,
                formatter: (cellContent, row) => (moment(returnLocalizedDate(cellContent)).format("YYYY-MM-DD hh:mm:ss A"))
            },
            {
                dataField: 'observationId', text: labels.oddities_labels.reference, formatter: (cellContent, row) => (<a href='javascript:void(0)'
                    onClick={() => this.props.history.push(`/observations/${row.observationId}`)}>{`${labels.oddities_labels.observation} ${row.observationId}`}</a>)
            },
            { dataField: 'message', text: labels.oddities_labels.message_label },
            { formatter: (cellContent, row) => (<a href='javascript:void(0)' onClick={() => this.props.resolveError(row.id)}>{labels.oddities_labels.resolve}</a>) },
        ]
        const oddityColumns = [
            {
                dataField: 'resolved', text: labels.oddities_labels.resolved, sort: true,
                onSort: (sortField, sortOrder) => this.props.sortOddities(sortField, sortOrder, this.props.history),
                sortCaret: (order, column) => this.getCaret(order, 'resolved'),
                formatter: (cellContent, row) => (<FormControl type='checkbox' id={row.message} defaultChecked={row.resolved} onChange={(e) => this.props.editOddity(e, row)} />)
            },
            {
                dataField: 'category', sort: true,
                onSort: (sortField, sortOrder) => this.props.sortOddities(sortField, sortOrder, this.props.history),
                sortCaret: (order, column) => this.getCaret(order, 'category'),
                text: labels.oddities_labels.category
            },
            {
                dataField: 'message', sort: true,
                onSort: (sortField, sortOrder) => this.props.sortOddities(sortField, sortOrder, this.props.history),
                sortCaret: (order, column) => this.getCaret(order, 'message'),
                text: labels.oddities_labels.message_label,
                formatter: (cellContent, row) =>
                    (
                        <span>
                            {row.message} (<a onClick={() => this.props.getMessageGroup(row.message, this.props.history)}>{labels.oddities_labels.examples}</a>)
                            <i className='fa fa-search ml-1' onClick={() => this.props.searchObservation(row.identifier, null, this.props.history)}></i>
                        </span>
                    )
            },
            {
                dataField: 'count', sort: true,
                onSort: (sortField, sortOrder) => this.props.sortOddities(sortField, sortOrder, this.props.history),
                sortCaret: (order, column) => this.getCaret(order, 'count'),
                text: labels.oddities_labels.count_label
            }
        ]
        const sorted = _.sortBy(this.props.oddities.uniqueOdditieMessage, [function (o) { return o.count; }]).reverse();
        const paginationOptions = paginationFactory({
            page: this.props.searchParam ? this.props.searchParam.pageNumber : 1,
            pageSize: this.props.searchParam ? this.props.searchParam.pageSize : 10,
            totalSize: this.props.totalErrors,
            onPageChange: (page) => { this.props.errorPageChange(page, this.props.history) },
            alwaysShowAllBtns: true,
            prePageText: 'Prev',
            nextPageText: 'Next',
            withFirstAndLast: false,
            hideSizePerPage: true
        });

        const options = {
            page: this.props.currentOddityPage,
            onPageChange: (page) => this.props.oddityPageChange(page, this.props.history),
            prePageText: 'Prev',
            nextPageText: 'Next',
            withFirstAndLast: false,
            alwaysShowAllBtns: true,
            sizePerPage: 10
        }

        return (
            <>
                <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'>

                </div>
                <div className='react-bootstrap-table oddities-list col-lg-9'>
                    <Nav tabs>
                        <NavItem onClick={() => this.props.setActiveTab('oddity', this.props.history)}>
                            <NavLink className={this.props.activeTab == 'oddity' ? 'active-tab' : 'inactive-tab'} >
                                <strong>{labels.oddities_labels.title}</strong>
                            </NavLink>
                        </NavItem>
                        <NavItem onClick={() => this.props.setActiveTab('errors', this.props.history)}>
                            <NavLink className={this.props.activeTab == 'errors' ? 'active-tab' : 'inactive-tab'}>
                                <strong>{labels.oddities_labels.errors}</strong>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.props.activeTab}>
                        <TabPane tabId='oddity'>
                            <div className='oddities-section'>

                                {this.props.isLoading ? <LoadingTemplate haveSidebar={false} isGrid={true} /> : this.props.oddities && this.props.oddities.uniqueOdditieCount ?
                                    <>
                                        <div className='bootstrap-table-container'>
                                            <BootstrapTable
                                                className='oddities-table'
                                                striped={true}
                                                bordered={true}
                                                hover={true}
                                                bootstrap4
                                                keyField='id'
                                                data={this.props.filteredOddities ? this.props.filteredOddities : []}
                                                columns={oddityColumns}
                                                defaultSorted={[{
                                                    order: this.props.sortOrder,
                                                    dataField: this.props.sortField
                                                }]}
                                                pagination={false} />
                                        </div>
                                        {this.props.oddities.uniqueOdditieMessage
                                            && this.props.oddities.uniqueOdditieMessage.length < 10 ? false :
                                            <div id='react-paginate' className='mt-3'>
                                                <ReactPaginate
                                                    previousLabel={'Prev'}
                                                    nextLabel={'Next'}
                                                    breakLabel={'...'}
                                                    previousClassName='page-item'
                                                    nextClassName='page-item'
                                                    breakClassName='page-item disabled'
                                                    breakLinkClassName='page-link'
                                                    pageClassName='page-item'
                                                    pageLinkClassName='page-link'
                                                    previousLinkClassName='page-link'
                                                    nextLinkClassName='page-link'
                                                    activeClassName='active'
                                                    pageCount={Math.ceil(this.props.oddities.uniqueOdditieMessage.length / 10)}
                                                    marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                                    pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                                    disableInitialCallback={true}
                                                    forcePage={this.props.currentOddityPage ? this.props.currentOddityPage - 1 : 0}
                                                    onPageChange={(page) => {
                                                        this.props.oddityPageChange(Math.ceil(page.selected) + 1, this.props.history)
                                                    }}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                />
                                            </div>}
                                    </> : this.props.no_grid_data ?
                                        <div className='jumbotron'>
                                            <h3>{labels.no_grid_data}</h3>
                                        </div> : null}
                            </div>
                        </TabPane>
                        <TabPane tabId='errors'>
                            <div className='errors-section'>
                                {this.props.isLoading ? <LoadingTemplate haveSidebar={false} isGrid={true} /> : this.props.oddityerrors && this.props.oddityerrors.length ?
                                    <>
                                        <div className='bootstrap-table-container'>
                                            <BootstrapTable
                                                remote
                                                striped={false}
                                                bordered={false}
                                                hover={true}
                                                bootstrap4
                                                keyField='id'
                                                data={this.props.oddityerrors}
                                                columns={columns}
                                                pagination={false}
                                            />
                                        </div>
                                        {this.props.totalErrors < 10 ? false :
                                            <div id='react-paginate' className='mt-3'>
                                                <ReactPaginate
                                                    previousLabel={'Prev'}
                                                    nextLabel={'Next'}
                                                    breakLabel={'...'}
                                                    previousClassName='page-item'
                                                    nextClassName='page-item'
                                                    breakClassName='page-item disabled'
                                                    breakLinkClassName='page-link'
                                                    pageClassName='page-item'
                                                    pageLinkClassName='page-link'
                                                    previousLinkClassName='page-link'
                                                    nextLinkClassName='page-link'
                                                    activeClassName='active'
                                                    pageCount={Math.ceil(this.props.totalErrors / 10)}
                                                    marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                                    pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                                    disableInitialCallback={true}
                                                    forcePage={this.props.searchParam ? this.props.searchParam.pageNumber - 1 : 0}
                                                    onPageChange={(page) => {
                                                        this.props.errorPageChange(Math.ceil(page.selected) + 1, this.props.history)
                                                    }}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                />
                                            </div>}
                                    </> : this.props.no_grid_data ?
                                        <div className='jumbotron'>
                                            <h3>{labels.no_grid_data}</h3>
                                        </div> : null}
                            </div>
                        </TabPane>
                    </TabContent>
                    <div className={`modal fade ${this.props.openErrorModal ? 'show' : ''} resolve-modal`} role='dialog'>
                        <div className='modal-dialog'>
                            <div className='modal-content'>
                                <div className='modal-body'>
                                    <p>{labels.oddities_labels.resolve_error}</p>
                                </div>
                                <div className='modal-footer justify-space-between'>
                                    <FormControl type='button' btnType='warning' text={labels.oddities_labels.cancel_btn} onClick={() => this.props.resolveError()} />
                                    <FormControl type='button' btnType={constants.notification_type.success} text={labels.oddities_labels.ok_btn} onClick={() => this.props.confirmResolveError()} />
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* {this.props.openObsModal ? <ObservationPopUp {...this.props}></ObservationPopUp> : null} */}
                </div>
            </>
        )
    }
}
export default OdditiesList;
import React, { Component } from 'react';
import BiomarkerComparisonModel from '../containers/BiomarkerComparisonModel';
import { parse } from 'qs';
import Loader from '../../../components/Loader';

class HandleBiomarkerComparison extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.visitID) {
            const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
            this.urlParams = parse(queryString, true);
            this.props.getAllCategories(this.props.match.params.visitID, this.urlParams && this.urlParams.locale ? this.urlParams.locale.toLowerCase() : null, false)
        }
    }
  
    render() {
        return (
            <>{ 
                this.props.match.params.biomarkerComparisonList && this.props.loadingPatient == "finished" && this.props.historic_data_chart?
                    <div>
                        <BiomarkerComparisonModel dob={this.props.match.params.DOB} biomarkerComparisonList={this.props.match.params.biomarkerComparisonList != "all" ? this.props.match.params.biomarkerComparisonList.split(",") : []} mobile={"true"} { ...this.props}/>
                    </div>
                    : <Loader />
                    }
            </>
        )

    }
}
export default HandleBiomarkerComparison

import {connect} from 'react-redux';
import {dittoRecommendationThunk} from '../thunks/recommendations';
import RecentRecommendations from '../components/RecentRecommendations'

// import {inactiveRecommendationsSelector} from '../selectors/recommendationsSelector';
import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
  ...state.analytics,
})

const mapDispatchToProps = {
  ...actionCreators
}
// let dittoRecommendation = (dispatch) => {
//   return (atts) => {
//     dispatch(dittoRecommendationThunk(
//       {
//                  indicator: atts.indicator,
//          recommendation_id: atts.recommendation.id,
//       },
//     ));
//   }
// }

// let mapStateToProps = (state) => {
//   return {
//     // recommendations: inactiveRecommendationsSelector(state),
//     // datum: state.drawer_content.datum,
//   }
// }

// let mapDispatchToProps = (dispatch) => {
//   return {
//             dittoRecommendation: dittoRecommendation(dispatch),
//          }
// }

export default connect(mapStateToProps, mapDispatchToProps)(RecentRecommendations);

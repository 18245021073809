import moment from 'moment';
import constants from '../../../config/constants';
import _ from 'lodash';
function padding(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

function parseDate(string) {
  if (string) {
    var parts = string.split('-');
    return (new Date(parts[0], parts[1] - 1, parts[2]))
    //months are 0-based :(
  }
}

function makeSureItIsADate(input) {
  if (typeof input == 'string') {
    return (parseDate(input));
  }
  else if (typeof input.getMonth == 'function' && !isNaN(input.toDateString)) {
    return input;
  }
  else {
    return undefined
  }
}
function setSitePreference(sitePreference, dateOfVisit) {
  if (sitePreference != "0" && sitePreference)
    return moment(dateOfVisit).format(constants.biographical_labels.dateFormats[sitePreference])
  else
    return moment(dateOfVisit).format('YYYY-MM-DD')
}
function CalculateChange(row, baselineVisit) {
  if (row && row.value) {
    var baselineValue = row.historicData && row.historicData.length && row.historicData.filter(s => s.visitId == baselineVisit).length > 0
      && !isNaN(parseFloat(_.head(row.historicData.filter(s => s.visitId == baselineVisit)).value))
    var finalVal = (baselineValue ? Math.round((((row.value[0] == "<" || row.value[0] == ">") ?
      row.value.slice(1) : row.value - _.head(row.historicData.filter(s => s.visitId == baselineVisit)).value) /
      _.head(row.historicData.filter(s => s.visitId == baselineVisit)).value) * 100) + '%' : '')
    if (!isFinite(parseFloat(finalVal)) || isNaN(parseFloat(finalVal))) {
      return ""
    } else {
      return finalVal
    }
  }
}
function formatDate(date, format) {
  date = makeSureItIsADate(date);

  if (!date) {
    return null;
  }

  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();

  if (format == "mdy") {
    return (padding(month, 2) + "/" + padding(day, 2) + "/" + year);
  }
  else if (format == "dmy") {
    return (padding(day, 2) + "/" + padding(month, 2) + "/" + year);
  }
  else if (format == "ymd") {
    return (year + "-" + padding(month, 2) + "-" + padding(day, 2));
  }
  else if (format == 'ymdhms') {
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();

    return (year + padding(month, 2) + padding(day, 2) + padding(hour, 2) + padding(minute, 2) + padding(second, 2));
  }
  else {
    return (year + "-" + padding(month, 2) + "-" + padding(day, 2));
  }
}

export { parseDate, formatDate, setSitePreference, CalculateChange }

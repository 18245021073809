import React, { Component } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
const stylesClasses = {
    labelClass: 'col-lg-2',
    inputClass: 'col-lg-7'
}
class MessageForm extends Component {
    componentDidMount() {
        // this.props.getMessageRoutingRules(this.props.history)
        this.props.getSites()
        this.props.getPanelList()
        this.props.toggleNotification(false)
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.message_routing_labels.message_routes, path: '' }
        ])
        if (this.props.match.params && this.props.match.params.mrrId) {
            this.props.setCurrentMessageRule(this.props.match.params.mrrId)
            this.props.setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.message_routing_labels.edit_message_routing, path: '' }
            ])
            document.title = `${labels.physioage_label}: ${labels.message_routing_labels.edit_message_routing}`
            this.props.setPageTitle(labels.message_routing_labels.edit_message_routing)
        } else {
            document.title = `${labels.physioage_label}: ${labels.message_routing_labels.new_message_routing}`
            this.props.setPageTitle(labels.message_routing_labels.new_message_routing)
        }
        if (this.ruleForm && this.ruleForm.inputEntry)
            this.ruleForm.inputEntry.focus()
    }
    render() {
        return (
            <>
                <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                </div>
                <form className='container message-form col-lg-9 col-md-12 col-sm-12 col-xs-12' onSubmit={(e) => { e.preventDefault(); this.props.saveMessageRoute(this.props.history) }}>
                    <FormControl ref={(m) => this.ruleForm = m} {...stylesClasses} value={this.props.currentMessageRule ? this.props.currentMessageRule.sendingApplication : ''} id='sendingApplication' onChange={this.props.editMessageRoute} type='text' label={labels.message_routing_labels.sending_application} />
                    <FormControl {...stylesClasses} value={this.props.currentMessageRule ? this.props.currentMessageRule.sendingFacility : ''} id='sendingFacility' onChange={this.props.editMessageRoute} type='text' label={labels.message_routing_labels.sending_facility} />
                    <FormControl {...stylesClasses} value={this.props.currentMessageRule ? this.props.currentMessageRule.receivingApplication : ''} id='receivingApplication' onChange={this.props.editMessageRoute} type='text' label={labels.message_routing_labels.receiving_application} />
                    <FormControl {...stylesClasses} value={this.props.currentMessageRule ? this.props.currentMessageRule.receivingFacility : ''} id='receivingFacility' onChange={this.props.editMessageRoute} type='text' label={labels.message_routing_labels.receiving_facility} />
                    <FormControl {...stylesClasses} value={this.props.currentMessageRule ? this.props.currentMessageRule.pid18_1 : ''} id='pid18_1' onChange={this.props.editMessageRoute} type='text' label={labels.message_routing_labels.pid18_1} />
                    <FormControl {...stylesClasses} value={this.props.currentMessageRule ? this.props.currentMessageRule.siteId : ''} id='siteId' onChange={this.props.editMessageRoute} type='select' options={this.props.sites ? this.props.sites : []} textKey='name' valueKey='id' label={labels.message_routing_labels.site} required={true} />
                    <FormControl {...stylesClasses} value={this.props.currentMessageRule ? this.props.currentMessageRule.panel : ''} id='panel' onChange={this.props.editMessageRoute} type='select' options={this.props.panelList ? this.props.panelList : []} label={labels.message_routing_labels.panel} textKey='name' valueKey='name' />
                    <div className='d-flex'>
                        <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                        </div>
                        <div className='col-lg-9 col-md-9 col-sm-9 col-xs-12 msg-route-form-create-btn'>
                            <FormControl type='button' btnType={constants.notification_type.success}
                                text={this.props.currentMessageRule && this.props.currentMessageRule.id
                                    ? labels.message_routing_labels.update_message_route
                                    : labels.message_routing_labels.add_message_route}
                            />
                        </div>
                    </div>
                </form>
            </>
        )
    }
}
export default MessageForm
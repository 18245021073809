import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css';
import './form-control-styles.scss';
import calendar from '../../assets/images/calendar_month.svg';


import { es, ja, enUS, ro, pl } from 'date-fns/locale';
registerLocale('en', enUS)
registerLocale('es', es)
registerLocale('ja', ja)
registerLocale('pl', pl)


export default class FormControl extends Component {
  constructor(props) {
    super(props);
    this.datePickerRef = React.createRef();
  }
  handleDateIconClick = () => {
    this.datePickerRef.current.setOpen(true);
  };
  render() {
    switch (this.props.type) {
      case 'checkbox':
        return (
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              {...this.props}
            />
            {this.props.label ? <label for={this.props.id} className={`form-check-label ${this.props.labelClass ? this.props.labelClass : ''}`}>
              {this.props.label}
            </label> : null}
            {this.props.help ? <span className='help-block'>{this.props.help}</span> : null}
          </div>
        );
      case 'date':
        return (
          <div className='form-group'>
            {this.props.label
              ? <label className={`${this.props.labelClass} ${this.props.patientDob ? "patientDob" : ''}`}>{this.props.required ? <abbr title='required'>*</abbr> : ''}{this.props.label}</label>
              : null}       
            <div className={`input-entry ${this.props.calendarIcon ? 'input-container' : ''}  `} ref={(ie) => this.inputEntry = ie}>
              <DatePicker {...this.props}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                ref={this.datePickerRef}
                placeholderText={this.props.placeholder ? this.props.placeholder : ""}
                isClearable = {this.props.isClearable ? true : false}
                popperPlacement = {this.props.popperPlacement ? this.props.popperPlacement : ''}
                dropdownMode='select'
                autoComplete='off'
                maxDate={this.props.maxDate ? new Date(this.props.maxDate) : null}
                className={`form-control ${this.props.inputClass ? this.props.inputClass : ''}`}
                />
                { this.props.calendarIcon ? <img src={calendar} class="icon" aria-hidden="true" onClick={this.handleDateIconClick}></img>  : null}
              {/* <input type='date' {...this.props} className='form-control' /> */}
              {this.props.help ? <div dangerouslySetInnerHTML={{ __html: this.props.help }} className='help-block'></div> : null}
            </div>
          </div>
        );
      case 'radio':
        return (
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              {...this.props}
            />
            {this.props.label ? <label for={this.props.id} className='form-check-label'>
              {this.props.label}
            </label> : null}
            {this.props.help ? <span className='help-block'>{this.props.help}</span> : null}
          </div>
        );
      case 'select':
        return (
          <div className='form-group'>
            {this.props.label ? <div>
              <label className={`${this.props.labelClass}`}>{this.props.label}{this.props.required ? <span className={`${this.props.is_no_required_icon_color ? '' : 'red-color'}`} title='required'>*</span>: ""}</label>
            </div> : null}
            {!this.props.isMulti ?
              <div className='input-entry'>
                <select ref={(ie) => this.inputEntry = ie} className={`form-control ${this.props.inputClass}`} {...this.props}>
                  {!this.props.nodefaultOption ? <option value={0}>{this.props.defaultOption}</option> : null}
                  {this.props.options ? this.props.options.map((op, i) => (
                    <option value={op[this.props.valueKey]} disabled={op.isDisabled ? op.isDisabled : ''}>{this.props.textKey.split(',').map(tk => {
                      return op[tk] ? op[tk] : tk
                    }).join(' ')}</option>
                  )) : []}
                </select>
                {this.props.help ? <span className={`help-block ${this.props.inputClass}`}>{this.props.help}</span> : null}
              </div>
              :
              <Select {...this.props} />

            }
          </div>
        )
      case 'typeahead':
        return (
          <div className='form-group'>
            {this.props.label ? <div>
              <label className={`${this.props.labelClass}`}>{this.props.label}{this.props.required ? <span className="red-color" title='required'>*</span> : ''}</label>
            </div> : null}
            <AsyncTypeahead {...this.props} ref={(ie) => this.inputEntry = ie} />
          </div>
        )
      case 'button':
        return (
          <>
            <button className={`btn btn-${this.props.btnType ? this.props.btnType : 'default'} ${this.props.className ? this.props.className : ''}`}
              disabled={this.props.disabled}
              id={this.props.id}
              onClick={this.props.onClick}>{this.props.text}</button>
            {this.props.help ? <span className='help-block'>{this.props.help}</span> : null}
          </>
        )
      case 'textarea':
        return (
          <div className='form-group'>
            {this.props.label ? <div>
              <label className={`${this.props.labelClass}`}>{this.props.required ? <abbr title='required'>*</abbr> : ''}{this.props.label}</label>
            </div> : null}
            <div className='input-entry'>
              <textarea ref={(ie) => this.inputEntry = ie} className={`form-control ${this.props.inputClass} ${this.props.signupInput ? 'setTextFieldMargin' : ''}`} {...this.props}></textarea>
              {this.props.help ? <span className={`help-block ${this.props.inputClass}`}>{this.props.help}</span> : null}
            </div>
          </div>
        )
      case 'textonly':
        return (
          <div className='form-group'>
            {this.props.label ? <div>
              <label className={`${this.props.labelClass}`}>{this.props.required ? <abbr title='required'>*</abbr> : ''}{this.props.label}</label>
            </div> : null}
            <div className='input-entry'>
              <input type='text' className={`form-control ${this.props.inputClass}`} {...this.props} title='Preferable to enter Alphabets'
                autoComplete='off'
                ref={(ie) => this.inputEntry = ie}
              // onKeyPress={(event) => {
              //   if (!((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123))) {
              //     event.preventDefault()
              //   }
              // }} 
              />
              {this.props.help ? <span className={`help-block ${this.props.inputClass}`}>{this.props.help}</span> : null}
            </div>
          </div>

        )
      case 'numberonly':
        return (
          <div className='form-group'>
            {this.props.label ? <div>
              <label className={`${this.props.labelClass}`}>{this.props.required ? <abbr title='required'>*</abbr> : ''}{this.props.label}</label>
            </div> : null}
            <div className='input-entry'>
              <input type='text' className={`form-control ${this.props.inputClass}`} {...this.props} title='Only Numbers are allowed'
                autoComplete='off'
                ref={(ie) => this.inputEntry = ie}
                onKeyPress={(event) => {
                  if ((event.charCode > 31 && (event.charCode < 48 || event.charCode > 57))) {
                    event.preventDefault()
                  }
                }} defaultValue={this.props.defaultValue} />
              {this.props.help ? <span className={`help-block ${this.props.inputClass}`}>{this.props.help}</span> : null}
            </div>
          </div>

        )
      case 'alphanumeric':
        return (
          <div className='form-group'>
            {this.props.label ? <div>
              <label className={`${this.props.labelClass}`}>{this.props.required ? <abbr title='required'>*</abbr> : ''}{this.props.label}</label>
            </div> : null}
            <div className='input-entry'>
              <input type='text' className={`form-control ${this.props.inputClass}`} {...this.props} title='Only Numbers are allowed'
                autoComplete='off'
                ref={(ie) => this.inputEntry = ie}
                onKeyPress={(event) => {
                  if ((event.charCode > 31 && (event.charCode < 45 || event.charCode > 62))) {
                    event.preventDefault()
                  }
                }} />
              {this.props.help ? <span className={`help-block ${this.props.inputClass}`}>{this.props.help}</span> : null}
            </div>
          </div>
        )
      case 'text-pre':
        return (
          <React.Fragment>
            {this.props.label ? <p>{this.props.required ? <abbr title='required'>*</abbr> : ''}{this.props.label}</p> : null}
            <div className='input-entry'>
              <pre><input type="text" className={`form-control ${this.props.inputClass}`}
                value={this.props.value} readOnly
              /></pre>
            </div>
          </React.Fragment>
        );
      case 'email':
      case 'password':
      default:
        return (
          <div className='form-group'>
            {this.props.label ? <div>
              <label className={`${this.props.labelClass}`}>{this.props.required ? <abbr title='required'>*</abbr> : ''}{this.props.label}</label>
            </div> : null}
            <div className='input-entry'>
              <input type={this.props.type} className={`form-control ${this.props.inputClass}`}
                autoComplete='off'
                ref={(ie) => this.inputEntry = ie}
                {...this.props} />
              {this.props.help ? <span className={`help-block ${this.props.inputClass}`}>{this.props.help}</span> : null}
            </div>
          </div>
        );
    }
  }
}

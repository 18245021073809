import { connect } from 'react-redux'
import DataMenu from '../components/DataMenu'
import { actionCreators } from '../modules/datamenu'
import { setBreadCrumbs, setPageTitle, toggleNotification, hideHeaderSkeleton, navigatePatients, setCreditsCaution } from '../../../reducers/global-reducer';

const mapStateToProps = (state) => ({
    ...state.datamenu,
    isLoading: state.global.isLoading,
    isParentContainer: state.global.isParentContainer,
    pageTitle: state.global.pageTitle,
    breadCrumbs: state.global.breadCrumbs,
    subTitle: state.global.subTitle,
    showCreditsCaution: state.global.showCreditsCaution,
    isHeaderSkeletonHidden: state.global.isHeaderSkeletonHidden,
    isNoHeaderLoading: state.global.isNoHeaderLoading,
    notifications: state.global.notifications,
    isNotify: state.global.isNotify
})

const mapDispatchToProps = {
    ...actionCreators,
    setBreadCrumbs,
    setPageTitle,
    toggleNotification,
    hideHeaderSkeleton,
    navigatePatients,
    setCreditsCaution
}

export default connect(mapStateToProps, mapDispatchToProps)(DataMenu)
import React from 'react';
import { Route } from 'react-router-dom';

function RouteWithSubRoutes(route, index) {
  return route.render ?
    <route.render /> : (<Route
      path={route.path}
      render={props => {
        return (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} index={index} />
        )
      }}
    />
    );
}
export default RouteWithSubRoutes;

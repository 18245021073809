import React from 'react';
import PropTypes from 'prop-types';
import Rickshaw from 'rickshaw'

import ChartTools from '../tools/ChartTools';

var chartOptions = {};

export default class BiomarkerSparkline extends React.Component {

  boundarySeries(timeBoundaries)
  {
    // from https://stackoverflow.com/questions/17298294/how-can-i-set-min-max-of-the-x-axis-in-rickshaw-d3
    return(
      {
        data: [
          {x: timeBoundaries['starting_timestamp'], y: null},
          {x: timeBoundaries['ending_timestamp'], y: null}
        ]
      }
    )
  }
  componentDidMount() {
    if (this.props.historic_data_values) {
      var padding = new ChartTools(this.props.historic_data_values, 0.35);
      let prepared_values = this.props.historic_data_values.
        map(ChartTools.rickshawCargo)
      prepared_values = prepared_values.filter(ChartTools.hasValue)
      var graph = new Rickshaw.Graph({
        element: document.getElementById(this.props.uniqueID),
        width: 150,
        height: 40,
        renderer: 'lineplot',
        min: padding.plot_min,
        max: padding.plot_max,
        padding:
        {
          top: 0.02, left: 0.02, right: 0.02, bottom: 0.02
        },
        series: [
          {
            color: 'grey',
            data: prepared_values,
          }, this.boundarySeries(this.props.timeBoundaries)
        ]
      });

      if (!isNaN(padding.plot_min) && !(isNaN(padding.plot_max))) {
        graph.render();
      }
    }
  }

  render() {
    if (this.props.historic_data_values) {
      return (
        <div className='sparkline' id={this.props.uniqueID}></div>
      );
    }
    else {
      return (false);
    }
  }
}

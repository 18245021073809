import React, { useState } from 'react';
import labels from '../../config/localization';
import FormControl from '../FormControls/FormControl';
import constants from '../../config/constants';
import Loader from '../Loader';
import countries from 'country-list';

import {
  CardElement,
  Elements,
  RecurlyProvider,
  useRecurly
} from '@recurly/react-recurly';

const stylesClasses = {
  labelClass: 'col-lg-12',
  inputClass: 'col-lg-12'
}


function PaymentCardDetails(props) {
  return (
    <div className="DemoSection d-f">
      <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY} required={['cvv']}>
        <Elements>
          <CardForm {...props} />
        </Elements>
      </RecurlyProvider>
    </div>
  );
}

function CardForm(props) {
  const recurly = useRecurly();
  const formRef = React.useRef();

  const handlePaymentDetailsSubmission = (event) => {
    props.toggleLoading(true);
    event.preventDefault();
    recurly.token(formRef.current, (err, token) => {
      if (err) {
        props.toggleLoading(false);
        props.paymentDetailsSubmissionError(err.message);
      }
      else {
        props.toggleNotification(false);
        props.registerSignUpUser(props.history, token);
      }
    });
  }

  return (
    <>
    <form className="card col-lg-8 col-md-12 col-sm-12 col-xs-12 p-0 d-b" onSubmit={handlePaymentDetailsSubmission} ref={formRef}>
      {props.isLoading ? <Loader /> : null}
      <h3 className="card-header col-12 text-center">{labels.payment_details}</h3>
      <div className="card-body">
        <div className="col-12 d-flex flex-lg-row flex-column justify-content-between mt-3">
          <div>
            <FormControl required={true} onChange={props.editSignupPaymentDetails} value={props.currentSignupPaymentDetails.firstName ? props.currentSignupPaymentDetails.firstName : ''} id='firstName' label={labels.first_name} type='text' labelClass='col-12 p-0' inputClass='col-12' data-recurly="first_name" />
          </div>
          <div>
            <FormControl required={true} onChange={props.editSignupPaymentDetails} value={props.currentSignupPaymentDetails.lastName ? props.currentSignupPaymentDetails.lastName : ''} id='lastName' label={labels.last_name} type='text' labelClass='col-12 p-0' inputClass='col-12' data-recurly="last_name" />
          </div>
        </div>
        <div className="col-12 mt-3">
      
          <FormControl required={true} value={props.currentSignupPaymentDetails.street1} onChange={props.editSignupPaymentDetails} type='text' id='street1' data-recurly="address1" {...stylesClasses} label={labels.sites_labels.street1_label} />
          <FormControl value={props.currentSignupPaymentDetails.street2} onChange={props.editSignupPaymentDetails} type='text' id='street2' data-recurly="address2" {...stylesClasses} label={labels.sites_labels.street2_label} />
          <FormControl required={true} value={props.currentSignupPaymentDetails.city} data-recurly="city" onChange={props.editSignupPaymentDetails} type='text' id='city' {...stylesClasses} label={labels.sites_labels.city_label} />
          <FormControl required={true} value={props.currentSignupPaymentDetails.state} data-recurly="state" onChange={props.editSignupPaymentDetails} type='text' id='state' {...stylesClasses} label={labels.sites_labels.state_label} />
          <FormControl required={true} value={props.currentSignupPaymentDetails.country} data-recurly="country"
            onChange={props.editSignupPaymentDetails} type='select' id='country' {...stylesClasses} label={labels.sites_labels.country} textKey='name' valueKey='code' options={countries.getData()} />
          <FormControl required={true} value={props.currentSignupPaymentDetails.postalCode} data-recurly="postal_code" onChange={props.editSignupPaymentDetails} type='text' id='postalCode' {...stylesClasses} label={labels.sites_labels.postal_code} />
          {/* <FormControl value={props.currentSignupPaymentDetails.phone} data-recurly="phone"
            onChange={props.editSignupPaymentDetails} type='text' id='phone'
            {...stylesClasses} label={labels.sites_labels.phone} /> */}
        </div>

        <div className="col-12 mt-4 pt-1">
          <CardElement
            style={{ fontSize: '1em' }}
          />
        </div>

        {/* <input
          data-recurly="postal_code"
          placeholder="Postal Code"
        />
        <input
          data-recurly="address1"
          placeholder="Address1"
        />
        <input
          data-recurly="address2"
          placeholder="Address2"
        />
        <input
          data-recurly="city"
          placeholder="City"
        />
        <input
          data-recurly="state"
          placeholder="State"
        />
        <input
          data-recurly="country"
          placeholder="Country"
        /> */}
        <div className="col-12 mt-4 pt-2">
          <FormControl text={labels.confirm_payment} type='button' btnType={constants.notification_type.success} className="w-100" />
        </div>
      </div>
    </form>
    { process.env.REACT_APP_IS_PROD == 0 ?
        <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
            <div className='signup-form-info alert alert-info'>
                <p class="fw-bold mt-2">{labels.payments_labels.test_cc_title}</p>
                <p>{labels.payments_labels.test_cc_help}</p>
                <div className="mt-1">
                  <FormControl type='text'
                  readOnly value={constants.sample_test_card}
                  />
                </div>  
            </div>
        </div>
        : null
    }
  </>
  );
}

export default PaymentCardDetails;

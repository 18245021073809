import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';

function RatingComponent(props) {  
    const [stars, setStarts] = useState([]);
    const [rating, setRating] = useState(0);
    const [hovered, setHovered] = useState(0);
   
    useEffect(() => {
        setStarts(props.options)
        setRating(props.value)
      return () => {
        // componentWillUnmount() //
      }
    }, [])

    let changeRating = (newRating) => {
      setRating(newRating)
        props.editAnalyticsSurveyData({target: {value: newRating}},  props.questionnairePageCount - 1, props.q.id);
    }
  
    let hoverRating = (rating) => {
      setHovered(rating)
    }
  
      return (
        <div key={props.q.id}>
          <div className="rating rating-div">
            {stars.map((star) => {
              return (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    changeRating(star);
                  }}
                  onMouseEnter={() => {
                    hoverRating(star);
                  }}
                  onMouseLeave={() => {
                    hoverRating(0);
                  }} >

                  { rating < star
                    ? hovered < star
                    ? <div className='box not-selected-box'>{star}</div>
                    : rating == "" ? <div className='box not-selected-box'>{star}</div> : <div className='box selected-box'>{star}</div>
                    : <div className='box selected-box'>{star}</div> 
                  }
                </span>
              );
            })}
          </div>
        </div>
      );
  }

  export default connect()(RatingComponent);
import React from 'react';
import Grade from './Grade';
import constants from '../../../config/constants'
import sprite from '../../../assets/images/sprite_base.svg';
import physioagelogo from '../../../assets/images/physioage-logo.jpg';
import Sprite from './SpriteIcon';
import { uniformString, getDateFormat } from '../tools/helpers';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import labels from '../../../config/localization';
import _ from 'lodash';
import Sortable from 'sortablejs'
import moment from 'moment';

class RecommendationRowComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false }
    this.renderBodyRow = this.renderBodyRow.bind(this);
    this.renderDetailsRow = this.renderDetailsRow.bind(this);
  }

  render() {
    return (
      <div>
        {this.renderBodyRow(this.props, this.state)}
      </div>
    )
  }

  renderToggle(expanded) {
    if (expanded) {
      return (
        <svg aria-hidden='true' title={this.props.label}>
          {/* <use xlinkHref={`${sprite}#slide-up`}></use> */}
          <use xlinkHref="#slide-up"></use>
          <symbol id="slide-up" viewBox="0 0 16 6">
            <path fill="none" stroke-width="2px" d="M0,6L8,0L16,6"></path>
          </symbol>
        </svg>
      );
    }
    else {
      return (
        <svg aria-hidden='true' title={this.props.label}>
          {/* <use xlinkHref={`${sprite}#slide-down`}></use> */}
          <use xlinkHref="#slide-down"></use>
          <symbol id="slide-down" viewBox="0 0 16 6">
            <path fill="none" stroke-width="2px" d="M0,0L8,6L16,0"></path>
          </symbol>
        </svg>
      );
    }
  }
  fadeRecommendationRow(rec) {
    if (rec.endedAt && moment(Date()).format("YYYY-MM-DD") > moment(rec.endedAt).format("YYYY-MM-DD")) {
      return 'ended'
    }
    else {
      return 'active'
    }
  }
  renderBodyRow(rec, atts) {
    let toggleExpansion = () => { this.setState({ expanded: !atts.expanded }) };
    return (
      <div className={`jw-recommendation-card jw-recommendation-flex ${this.fadeRecommendationRow(rec)}`} key={rec.id} data-id={rec.id}
        onClick={toggleExpansion}
        style={(this.props.loggedInUser &&
          (this.props.loggedInUser.role == constants.logged_roles.AN
            || this.props.loggedInUser.role == constants.logged_roles.CG)
          && this.props.medications.length > 1) ? { cursor: 'move' } : {}}>
        {/* {this.props.loggedInUser &&
          (this.props.loggedInUser.role == constants.logged_roles.AN
            || this.props.loggedInUser.role == constants.logged_roles.CG)
          && this.props.medications.length > 1 && rec.isStopped != 1 &&
          <i class="fa fa-align-justify fa-lg my-auto mr-3 handle"></i>} */}
        <div className="jw-icon">
          <Sprite group="recommendations" label={rec.group} sprite={uniformString(rec.group)} />
        </div>
        <div className="jw-recommendation-content">
          <div className="jw-recommendation-body">{rec.body + " " + (rec.body.includes(rec.strength) ? "" : rec.strength ? rec.strength : "")}</div>
          {this.state.expanded ? this.renderDetailsRow(this.props) : null}
        </div>
        <div className="jw-recommendation-toggle">
          {this.renderToggle(atts.expanded)}
        </div>
      </div>
    );
  }
  renderRefuteRefuse(recommendation, datum) {
    if (this.props.editable) {
      return (
        <div className="refute" title="Remove" onClick={this.refute(recommendation, datum.code)}>
          <svg aria-hidden="true" className="fill">
            <use xlinkHref={"/assets/sprite_base.svg#trash"} xmlnsXlink="http://www.w3.org/1999/xlink"></use>
          </svg>
        </div>
      )
    }
  }

  renderDetailsRow(rec) {
    var flag = true
    return (
      <div className="jw-recommendation-details" key={rec.id + '-details'} id={rec.id + '-details'}>
        {this.brand_ingredient(rec)}
        {this.strength(rec)}
        {this.recommendationStrengthType(rec)}
        {this.instruction(rec)}
        {this.notes(rec)}
        {/* {this.startedAt(rec)} */}
        {this.endedAt(rec)}
        {this.updatedStrength(rec)}
        {this.updatedInstructions(rec)}
        {this.props.historic_data_chart ? Object.keys(this.props.historic_data_chart).map(ad => {
          if ((typeof (this.props.historic_data_chart) == constants.object) && ((constants.biographical_labels.ages[ad] && rec.indicators) ? (constants.biographical_labels.ages[ad].toLowerCase() == _.head(rec.indicators)) : '')) {
            return (
              <React.Fragment>
                <p key="ages" className="recommendation-indicator" >
                  (based on your {constants.biographical_labels.age_label_lowercase[rec.indicators]} being <b>{constants.biographical_labels.ages[ad] && rec.indicators ?
                    constants.biographical_labels.ages[ad].toLowerCase() == _.head(rec.indicators) ? ` ${this.props.historic_data_chart[ad].filter(s => s.visitId == rec.visitId).length > 0 ?
                      _.head(this.props.historic_data_chart[ad].filter(s => s.visitId == rec.visitId)).value : _.last(this.props.historic_data_chart[ad]).value} 
                        ${labels.orders_labels.years}` : '' : ''}</b>)
                </p>
              </React.Fragment>)
          }
        }) : ''}
        {this.props.categories ? this.props.categories.map(t => t.sections.map(sec => sec.data.map(d => {
          if (d.code == _.head(rec.indicators) && flag && !Object.values(constants.biographical_labels.ages).map(s => s.toLowerCase()).includes(d.code)) {
            flag = false;
            return (
              <React.Fragment>
                <p key={d.label} className="recommendation-indicator" >
                  (based on your {d.label} being <b>{d.visitId == rec.visitId ? d.value : d.historicData
                    && d.historicData.filter(s => s.visitId == rec.visitId).length > 0 ? _.head(d.historicData.filter(s => s.visitId == rec.visitId)).value : d.value} {d.units}</b>)
                </p>
              </React.Fragment>)
          }
        }))) : ''}
        {this.props.categories ? this.props.categories.map(t => {
          if (t.key == _.head(rec.indicators)) {
            return (<React.Fragment>
              <p key={t.key} className="recommendation-indicator" >
                (based on your {t.name})
              </p>
            </React.Fragment>)
          }
        }) : ''}
        {this.props.categories ? this.props.categories.map(s => s.sections.map(t => {
          if (t.key == _.head(rec.indicators)) {
            return (<React.Fragment>
              <p key={t.key} className="recommendation-indicator" >
                (based on your {t.name})
              </p>
            </React.Fragment>)
          }
        })) : ''}
        {!rec.isStopped ?
          this.props.isDemo || this.props.loggedInUser && (this.props.loggedInUser.role == constants.logged_roles.AN || this.props.loggedInUser.role == constants.logged_roles.CG)
            ? <div className="input-group">
              {rec.firstRecommendationVisit == this.props.presentPatient.latestVisitId || this.props.isDemo ?
                <div className="icon md fill clickable refute pr-4" onClick={() => this.props.deleteMedication(rec.id, _.head(rec.indicators))} >
                  <svg aria-hidden="true" title="Delete" fill="red">
                    <use xlinkHref="#trash" xmlnsXlink="http://www.w3.org/1999/xlink"></use>
                    <symbol id="trash" viewBox="0 0 16 16">
                      <path d="M2,6v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V6H2z"></path>
                      <path d="M12,3V1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H0v2h16V3H12z M10,3H6V2h4V3z"></path>
                    </symbol>
                  </svg>
                </div> : ''}
              <div className="icon md fill clickable edit pr-4" onClick={() => {
                this.props.toggleMedicationModal(true);
                this.props.updateMedication(rec.id)
              }}>
                <svg aria-hidden="true" title="Pencil">
                  <use xlinkHref="#pencil" xmlnsXlink="http://www.w3.org/1999/xlink"></use>
                  <symbol id="pencil" viewBox="0 0 486.219 485.22">
                    <path d="M467.476,146.438l-21.445,21.455L317.35,39.23l21.445-21.457c23.689-23.692,62.104-23.692,85.795,0l42.886,42.897 C491.133,84.349,491.133,122.748,467.476,146.438z M167.233,403.748c-5.922,5.922-5.922,15.513,0,21.436 c5.925,5.955,15.521,5.955,21.443,0L424.59,189.335l-21.469-21.457L167.233,403.748z M60,296.54c-5.925,5.927-5.925,15.514,0,21.44 c5.922,5.923,15.518,5.923,21.443,0L317.35,82.113L295.914,60.67L60,296.54z M338.767,103.54L102.881,339.421 c-11.845,11.822-11.815,31.041,0,42.886c11.85,11.846,31.038,11.901,42.914-0.032l235.886-235.837L338.767,103.54z M145.734,446.572c-7.253-7.262-10.749-16.465-12.05-25.948c-3.083,0.476-6.188,0.919-9.36,0.919 c-16.202,0-31.419-6.333-42.881-17.795c-11.462-11.491-17.77-26.687-17.77-42.887c0-2.954,0.443-5.833,0.859-8.703 c-9.803-1.335-18.864-5.629-25.972-12.737c-0.682-0.677-0.917-1.596-1.538-2.338L0,485.216l147.748-36.986 C147.097,447.637,146.36,447.193,145.734,446.572z"></path>
                  </symbol>
                </svg>
              </div>
              {rec.firstRecommendationVisit != this.props.presentPatient.latestVisitId && !this.props.isDemo ?
                <div className="icon md fill clickable stop" title='stop recommending' onClick={() => {
                  this.props.toggleStopMedicationPopup(true, rec.id);
                }}>
                  <i class="fa fa-stop-circle danger" style={{ fontSize: "21px", lineHeight: "1.7rem" }}></i>
                </div> : ''}
            </div> : ''
          : <div>
            {'Medication was recommended on ' + moment(rec.previousRecommendationDate).format(getDateFormat(this.props.presentPatient.datePreference)) + ' and provider has stopped on ' + moment(rec.stoppedAt).format(getDateFormat(this.props.presentPatient.datePreference))}
            {this.props.role != 'patient' && !this.props.isDemo ?
              <div className="icon md fill clickable mt-2" title='resume recommendation' onClick={() => {
                this.props.toggleStopMedicationPopup(true, rec.id, 'current');
              }}>
                <i className="fa fa-play-circle analytics-blue" style={{ fontSize: "21px", lineHeight: "1.7rem" }}></i>
              </div> : ''}
          </div>}
      </div>
    );
  }
  
  brand_ingredient(recommendation) {
    if (recommendation.brand && recommendation.brand.trim()) {
      return (
        <p className="recommendation-instructions">
          <strong>{labels.analytics_labels.brand_ingredient}</strong> {recommendation.brand}
        </p>
      )
    }
    else {
      return (null);
    }
  }
  strength(recommendation) {
    if (recommendation.strength && recommendation.strength.trim()) {
      return (
        <p className="recommendation-instructions">
          <strong>Dose:</strong> {recommendation.strength}
        </p>
      )
    }
    else {
      return (null);
    }
  }
  recommendationStrengthType(recommendation) {
    if (recommendation.recommendationStrengthType && recommendation.recommendationStrengthType.trim()) {
      return (
        <p className="recommendation-instructions">
          <strong>{labels.analytics_labels.howStronglyRecommended}:</strong> {recommendation.recommendationStrengthType}
        </p>
      )
    }
    else {
      return (null);
    }
  }

  instruction(recommendation) {
    if (recommendation.instructions && recommendation.instructions.trim()) {
      return (
        <p className="recommendation-instructions">
          <strong>Instructions:</strong> {recommendation.instructions}
        </p>
      )
    }
    else {
      return (null);
    }
  }

  notes(rec) {
    // remove show_notes flag
    if (rec.notes && rec.notes.trim()) {
      return (
        <div className="recommendation-notes">
          <p>
            <strong>Notes:</strong>
            &nbsp;
            {rec.notes}</p>
        </div>
      )
    }
    else {
      return (null);
    }
  }

  startedAt(recommendation) {
    if (recommendation.startedAt && recommendation.startedAt.trim()) {
      return (
        <p className="recommendation-instructions">
          <strong>Start Date:</strong> {moment(recommendation.startedAt).format(getDateFormat(this.props.presentPatient.datePreference))}
        </p>
      )
    }
    else {
      return (null);
    }
  }
  getStrongDate(date) {
    return <strong>{(moment(date).format(getDateFormat(this.props.presentPatient.datePreference)))}</strong>
  }
  endedAt(recommendation) {
    if (recommendation.startedAt) {
      if (recommendation.endedAt && recommendation.endedAt.trim()) {
        return (
          moment(new Date()).format("YYYY-MM-DD") > moment(recommendation.endedAt).format("YYYY-MM-DD") ?
            <p className="recommendation-instructions">Started {this.getStrongDate(recommendation.startedAt)}, Ended {this.getStrongDate(recommendation.endedAt)}.</p> :
            <p className="recommendation-instructions">Patient is taking up this from {this.getStrongDate(recommendation.startedAt)} and will be ending up taking this on {this.getStrongDate(recommendation.endedAt)}.</p>
        )
      }
      else {
        return (
          <p className="recommendation-instructions">
            Started {this.getStrongDate(recommendation.startedAt)}.
          </p>
        )
      }
    }
    else if (recommendation.endedAt && recommendation.endedAt.trim()) {
      return (
        moment(Date()).format("YYYY-MM-DD") > moment(recommendation.endedAt).format("YYYY-MM-DD") ?
          <p className='recommendation-instructions'>
            Ended {this.getStrongDate(recommendation.endedAt)}
          </p> :
          <p className='recommendation-instructions'> Patient will be ending up taking this on {this.getStrongDate(recommendation.endedAt)}</p>
      )
    }
    else {
      return (null);
    }
  }
  
  updatedStrength(rec) {
    if ((rec.previousStrength && rec.strength && rec.previousStrength != rec.strength) || (rec.strength == "" || rec.strength == null) && rec.previousStrength  ){
      return (
      <div className="recommendation-details">
          { rec.previousStrength && rec.strength && rec.previousStrength != rec.strength ?
            <span className='text-1'>{`We updated the dose from ${rec.previousStrength} to ${rec.strength} on ${moment(rec.createdAt).format(getDateFormat(this.props.presentPatient.datePreference))}`}</span>
          :
          (rec.strength == "" || rec.strength == null) && rec.previousStrength ?
            <span className='text-1'>{`We removed the dose ${rec.previousStrength} on ${moment(rec.createdAt).format(getDateFormat(this.props.presentPatient.datePreference))}`}</span>
          : null
          }  
      </div>
      )
    }
  }

  updatedInstructions(rec){
    if ((rec.previousInstructions && rec.instructions && rec.previousInstructions != rec.instructions) || (rec.instructions == "" || rec.instructions == null) && rec.previousInstructions  ){
      return (
      <div className="recommendation-details">
          { rec.previousInstructions && rec.instructions && rec.previousInstructions != rec.instructions ?
            <span className='text-1'>{`We updated the instructions from ${rec.previousInstructions} to ${rec.instructions} on ${moment(rec.createdAt).format(getDateFormat(this.props.presentPatient.datePreference))}`}</span>
          :
          (rec.instructions == "" || rec.instructions == null) && rec.previousInstructions ?
            <span className='text-1'>{`We removed the instructions ${rec.previousInstructions} on ${moment(rec.createdAt).format(getDateFormat(this.props.presentPatient.datePreference))}`}</span>
          : null
          }  
      </div>
      )
    }
  }

}

export default class MedicationComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.recommendations && this.props.stoppedRecommendations) {
      this.props.getMedication()
    }

    // if (this.props.loggedInUser &&
    //   (this.props.loggedInUser.role == constants.logged_roles.AN
    //     || this.props.loggedInUser.role == constants.logged_roles.CG)
    //   && this.props.medications && this.props.medications.length > 1) {
    //   let simpleList = document.getElementById('simpleList')
    //   if (!simpleList)
    //     return
    //   Sortable.create(simpleList, {
    //     animation: 150,
    //     // handle: '.handle',
    //     onUpdate: () => {
    //       this.props.savePriorities()
    //       this.props.toggleResetPriority()
    //     }
    //   })
    // }
  }


  render() {
    return (
      <div className='medication-banner'>
        <section className="page-section" id="medication">
          {this.renderInside()}
        </section>
      </div>
    )
  }

  renderInside() {
    if (this.props.presentPatient.visits == 0) {
      return (null);
    }
    else if ((this.props.medications ? this.props.medications.length > 0 : 0) || (this.props.stoppedMedications ? this.props.stoppedMedications.length > 0 : 0)) {
      return (this.actuallyRender());
    }
    else if (this.props.isDemo || (this.props.loggedInUser &&
      (this.props.loggedInUser.role == constants.logged_roles.AN
        || this.props.loggedInUser.role == constants.logged_roles.CG))) {
      return (this.renderInstructions());
    }
    else {
      return (null);
    }
  }

  actuallyRender() {
    return (
      // <div className="recommendation-review">
      //   <div className="photo-bg Further"></div>
      //   <div className="content">
      <div className="max-container jw-recommendation-container">
        <h2 className="card-group-title">Medications
          {this.props.isDemo || (this.props.loggedInUser && (this.props.loggedInUser.role == constants.logged_roles.AN || this.props.loggedInUser.role == constants.logged_roles.CG))
            ? <button class="btn btn-success ml-4" onClick={
              () => { this.props.toggleMedicationModal(true), this.props.clearParamsOfRecommendations() }
            }>Add Medication</button> : ''}
          {
            this.props.isPriorityLoader && <i class="fa fa-circle-o-notch fa-spin fa-lg ml-4 priorityLoader"></i>
          }

        </h2>
        {this.props.medications && this.props.medications.length ?
          <>
            <h3 className="card-group-title mt-4" style={{ fontSize: '2rem' }}>Active Medications</h3>
            <div className="card">
              {this.renderGroup(this.props.medications)}
            </div>
            {this.props.loggedInUser &&
              (this.props.loggedInUser.role == constants.logged_roles.AN || this.props.loggedInUser.role == constants.logged_roles.CG)
              && this.props.isReorder
              && <button class="btn btn-danger ml-2 mt-2 priorityResetBtn" onClick={
                () => { this.props.resetPriorities() }
              }>Reset</button>}
          </> : ''}
        {this.props.stoppedMedications && this.props.stoppedMedications.length
          ? <><h3 className="card-group-title mt-4" style={{ fontSize: '2rem' }}>Stopped Medications</h3><div className="card">
            {this.renderGroup(_.orderBy(this.props.stoppedMedications, ['stoppedAt'], ['desc']))}
          </div></> : ''}
      </div>
      //   </div>
      // </div>
    )
  }

  renderInstructions() {
    return (
      <div className="max-container with-max-width">
        <h2 className="section-title "> {"Medications"}
          {this.props.isDemo || this.props.loggedInUser &&
            (this.props.loggedInUser.role == constants.logged_roles.AN
              || this.props.loggedInUser.role == constants.logged_roles.CG) ?
            <button class="btn btn-success ml-4" onClick={
              () => { this.props.toggleMedicationModal(true), this.props.clearParamsOfRecommendations() }
            }>Add medication</button> : ''}</h2>
        <div className="instructions card">
          <div className="card-content">
            <h3>You have not entered any medications.</h3>
            <p>To enter a medications tied to this Visit - Click on <b>Add Medication</b> above.</p><br />
            <p>Medications will be visible to patients in this section and on the Visit Summary PDF.</p>
          </div>
        </div>
      </div>
    )
  }

  renderRecommendations(list) {
    return (
      list.map(
        (medication) => {
          return (
            <RecommendationRowComponent {...medication}{...this.props} />
          )
        }
      )
    );
  }

  renderGroup(medications) {
    if (medications.length > 0) {
      return this.renderRecommendations(medications);
    }
    else {
      return null;
    }
  }
}  
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import labels from '../../config/localization';
import './footer-styles.scss';
import moment from 'moment';

class Footer extends Component {
    render() {
        return (
            <div className='footer container row'>
                <span> &copy; {moment(new Date()).format('YYYY')} {labels.physioage_footer_label} | <span className='footer-link'><Link to='/privacy'>{labels.footer_labels.privacy}</Link></span> |  <span className='footer-link'><Link to='/tos'>{labels.footer_labels.tos}</Link></span></span>
            </div>
        )
    }
}

export default Footer
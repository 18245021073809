import React from 'react';

import ProviderComment from './ProviderComment';
import ValueCard from './ValueCardComponent';
import RecentRecommendations from '../containers/RecentRecommendationsContainer';
import MyRecommendations from '../containers/MyRecommendationsContainer';
import RecommendationInput from '../containers/RecommendationInputContainer';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import labels from '../../../config/localization';
import NoumenonDescription from '../containers/NoumenonDescriptionContainer.js';
import HistoricDataKey from './HistoricDataKey';
import HistoricDataChart from '../containers/HistoricDataChartContainer';
import constants from '../../../config/constants';
import AnalyticsLabels from '../../../config/analyticsConstants'
import html2react from 'html-to-react'
import marked from 'marked';
import _ from 'lodash';

export default class RecommendationBlockComponent extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  markdown2react(md) {
    marked.setOptions({
      renderer: new marked.Renderer(),
      sanitize: true,
      smartLists: true,
    });

    let parser = new html2react.Parser();
    return (parser.parse(marked(md)));
  }

  componentDidUpdate() {
    if (!this.props.isSideBarActive && this.state.activeTab != '1') {
      this.setState({
        activeTab: '1'
      });
    }
  }
  render() {
    if (this.props.showDrawerWarning) {
      this.toggle('3');
    }
    if (this.props.drawerType == 'biomarker') {
      return (
        <div className='comments'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>

            <Nav tabs className='mt-4'>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '1' ? 'active text-secondary' : ''}
                  onClick={() => { this.toggle('1'); }}  >
                  {labels.analytics_labels.results_label}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '3' ? 'active text-secondary' : ''}
                  onClick={() => { this.toggle('3'); }} >
                  {labels.analytics_labels.recommendation_label}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '2' ? 'active text-secondary' : ''}
                  onClick={() => { this.toggle('2'); }}>
                  {labels.analytics_labels.more_info}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab} >
              <TabPane tabId='1'>
                <React.Fragment>
                  {this.showDescription()}
                  {/* constants.biographical_labels.age_label_lowercase[ */}
                  <div className='chart-content'>
                    {this.props.historic_data_chart && this.props.ages ?
                      <React.Fragment>
                        <HistoricDataKey noumenon_name={this.props.ageName}
                          should_render_age_line={this.props.historic_data_chart[constants.biographical_labels.historic_data_labels[this.props.ages]] ?
                            this.props.historic_data_chart[constants.biographical_labels.historic_data_labels[this.props.ages]].length > 1 : 0 ? true : false} />
                        <HistoricDataChart noumenon_name={_.head(this.props.historic_data_chart[constants.biographical_labels.historic_data_labels[this.props.ages]]).code.toLowerCase()} {...this.props}
                          historic_data={this.props.historic_data_chart ? this.props.historic_data_chart[constants.biographical_labels.historic_data_labels[this.props.ages]] : ''} />
                      </React.Fragment>
                      : ''}
                  </div>
                </React.Fragment>
              </TabPane>
              <TabPane tabId='2'>
                {this.props.historic_data_chart ? Object.keys(this.props.historic_data_chart).map(s => {
                  if (constants.biographical_labels.agesForDrawer[s] == this.props.ages) {
                    //return AnalyticsLabels[s].map(s => {
                    return (<div className='description-shadow'>
                      <div className='moreInfoDescription'>
                        {this.props.noumenonReferences &&
                          this.props.noumenonReferences.filter(s => s.code == this.props.ages).length && _.head(this.props.noumenonReferences.filter(s => s.code == this.props.ages)).noumenonExplanation != null ?
                          this.markdown2react(_.head(this.props.noumenonReferences.filter(s => s.code == this.props.ages)).noumenonExplanation) : labels.analytics_labels.no_description}
                        {/* <p>{this.markdown2react(_.head(this.props.historic_data_chart[s]).noumenonExplanation)}</p> */}
                      </div>
                      {this.props.noumenonReferences &&
                        this.props.noumenonReferences.filter(s => s.code == this.props.ages).length &&
                        _.head(this.props.noumenonReferences.filter(s => s.code == this.props.ages)).referencesDTO.length ?
                        <div className='moreInfoReference biomarker-references'>
                          <p className='reference-heading'><b>References:</b></p>
                          {
                            _.head(this.props.noumenonReferences.filter(s => s.code == this.props.ages)).referencesDTO.map((s, index) => {
                              return (<div className='card' key='card-reference'>
                                <p className="card-header"><b>Reference {index + 1} :</b></p>
                                {s.url ?
                                  <>
                                    <p className="card-body"><b>URL : </b><a target="_blank" href={s.url}>{s.url}</a></p>
                                  </>
                                  : ''}
                                {s.internalNotes ?
                                  <>
                                    <p className="card-body"><b>Internal Notes : </b>{s.internalNotes}</p>
                                  </>
                                  : ''}
                              </div>)
                            })}
                        </div> : ''}
                    </div>
                    )
                    //})
                  }
                }
                ) : ''}
              </TabPane>
              <TabPane tabId='3' >
                {this.renderContent()}
              </TabPane>
            </TabContent>
          </div>
        </div >
      );
    }
    else if (this.props.drawerType == 'category' || this.props.drawerType == 'section') {
      return (
        <div className='comments'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <TabContent>
              <TabPane>
                {this.renderContent()}
              </TabPane>
            </TabContent>
          </div>
        </div >
      );
    }
    else {
      return (
        this.props.datum ?
          <div className='comments'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <TabContent>
                <TabPane>
                  {this.renderContent()}
                </TabPane>
              </TabContent>
            </div>
          </div > : ''
      );
    }
  }
  showDescription() {
    return (<NoumenonDescription {...this.props} />);
  }

  renderContent() {
    if (this.props.recommendations) {
      return (
        <div className='recommendation-content row'>
          {this.props.role != "patient" ?
            <div className='heading col-lg-8 col-md-8 col-sm-6 col-xs-12'>
              <RecommendationInput />
              {/* <RecentRecommendations /> */}
            </div> : ''}
          {this.props.role != "patient" ?
            <div className='content col-lg-4 col-md-4 col-sm-6 col-xs-12'>
              {this.props.isRecommendationsLoading ?
                <i className='fa fa-spinner fa-spin'></i> :
                <MyRecommendations datum={this.props.datum} drawerType={this.props.drawerType} {...this.props} />
              }</div> :
            <div className='content col-lg-6 col-md-4 col-sm-6 col-xs-12'>
              {this.props.isRecommendationsLoading ?
                <i className='fa fa-spinner fa-spin'></i> :
                <MyRecommendations datum={this.props.datum} drawerType={this.props.drawerType} {...this.props} />}
            </div>
          }
        </div>
      );
    }
    else {
      return (
        <div className='recommendation-content read-only'>
          <MyRecommendations />
        </div>
      );
    }
  }
}

const BIOMARKERS = [
  {name: 'physioAge', code: 'physioage', sprite: 'gem', position: 1},
  {name: 'TruBioHealth Age', code: 'incite_trubiohealth_age', sprite: 'healthspan-potential', position: 2},
  {name: 'EpiGenAge', code: 'epigenage', sprite: 'epigenage', position: 3},
  {name: 'CardioAge', code: 'cardioage', sprite: 'heart', position: 4},
  {name: 'pulmoAge', code: 'pulmoage', sprite: 'lung', position: 5},
  {name: 'immunoAge', code: 'immunoage', sprite: 'immune', position: 6},
  {name: 'cutoAge', code: 'cutoage', sprite: 'sun', position: 7},
  {name: 'neuroAge', code: 'neuroage', sprite: 'brain', position: 8},
  {name: 'telomerAge', code: 'telomerage', sprite: 'telomere', position: 9},
]

let spriteForBiomarker = (name) =>
{
  let needle = BIOMARKERS.find((biomarker) => biomarker.name.toLocaleLowerCase() == name.toLocaleLowerCase());
  return(needle && needle.sprite);
}

let biomarkerPosition = (name) =>
{
  let needle = BIOMARKERS.find((biomarker) => biomarker.name.toLocaleLowerCase() == name.toLocaleLowerCase());
  return(needle && needle.position);
}

export { BIOMARKERS, spriteForBiomarker, biomarkerPosition }

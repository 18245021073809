import constants from '../config/constants';
import XLSX from 'xlsx';
import ExcelJS from 'exceljs';


export function b64toBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
}

//detect browser
export function isFirefox() {
    if (typeof InstallTrigger !== 'undefined')
        return true
    return false
}
export function isIE() {
    var isIE = false;
    var ua = window.navigator.userAgent;
    var old_ie = ua.indexOf('MSIE ');
    var new_ie = ua.indexOf('Trident/');

    if ((old_ie > -1) || (new_ie > -1)) {
        isIE = true;
    }

    return isIE
}
export function isSafari() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') == -1) {
            return true
        }
    }
    return false
}
export function getValidSafariDate(dob) {
    if (dob && typeof (dob) == 'string') {
        dob = dob.split(' ').reverse()
        let finalDate = []
        finalDate.push(constants.shortMonths.indexOf(dob[1]) + 1)
        finalDate.push(dob[0])
        finalDate.push(dob[2])
        return finalDate.join('/')
    }
    return dob
}

export function downloadExcel(aoa, sheetname, filename) {
    var wb = XLSX.utils.book_new()
    wb.SheetNames.push(sheetname)
    wb.Sheets[sheetname] = XLSX.utils.aoa_to_sheet(aoa)
    var wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'})
    var buf = new ArrayBuffer(wbout.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=wbout.length; ++i) view[i] = wbout.charCodeAt(i) & 0xFF;

    var excelFile = new Blob([buf], { type: "application/octet-stream" });
    var downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(excelFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}

export function downloadExcelImproved(aoa, sheetname, filename) {
    // Create a new workbook
    var wb = new ExcelJS.Workbook();

    // Add a new worksheet
    var ws = wb.addWorksheet(sheetname);

    // Add data to the worksheet
    aoa.forEach(row => {
        ws.addRow(row);
    });

    // Apply style to the header row
    const headerRow = ws.getRow(1);
    headerRow.eachCell((cell) => {
        cell.font = { bold: true };
    });

    // Apply style to the entire worksheet
    ws.eachRow(function (row, rowNumber) {
        row.eachCell(function (cell) {
            cell.alignment = { horizontal: 'left' };
        });
    });

    // Set fixed width for all columns (20 pixels)
    const columnWidth = 20;
    ws.columns.forEach(column => {
        column.width = columnWidth;
    });

    // Write the workbook to a buffer
    return wb.xlsx.writeBuffer()
        .then(buffer => {
            // Create a Blob from the buffer
            const blob = new Blob([buffer], { type: 'application/octet-stream' });

            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = filename;

            // Append the link to the body and click it programmatically
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Cleanup
            window.URL.revokeObjectURL(downloadLink.href);
            document.body.removeChild(downloadLink);
        })
        .catch(function (error) {
            console.log('Error:', error);
        });
}
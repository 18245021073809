import React, { Component } from 'react';
 
class SiteDocumentCollection extends Component {
    componentDidMount() {
        document.body.style = 'background: #ffffff!important';
 
        if (this.props.match.params
            && this.props.match.params.siteId
            && !isNaN(this.props.match.params.siteId)) {
            this.props.setSiteDocuments(this.props.match.params.siteId)
        } else {
            this.props.history.push('/statuscode/404')
        }
        document.title = window.location.href
    }
    render() {
        return (
            this.props.allSiteDocuments ? <div className='site-document-details container-fluid'>
                <pre>{JSON.stringify(this.props.allSiteDocuments)}</pre>
            </div> : null
        )
    }
}
export default SiteDocumentCollection
import React from 'react';
import Grade from './Grade';
import { uniformString } from '../tools/helpers';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from 'react-scroll';
import labels from '../../../config/localization';
import _ from 'lodash';
import constants from '../../../config/constants';
import DatumSparkline from '../containers/DatumSparklineContainer';
import { round } from '../selectors/datasetSelector'

export default class ReportCardComponent extends React.Component {
  constructor(category) {
    super(category);
    this.state = {
      showOverallGrade: false,
      defaultDrawer: false
    };
    this.renderCategoryGrade = this.renderCategoryGrade.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  componentDidMount() {
    var drawerId = `overall-grade-drawer`
    if (this.props.grading_data && this.props.hashReportDrawer
      && (this.props.hashReportDrawer == 'overall-grade-drawer')
      && document.getElementById(drawerId)) {
      scroller.scrollTo(drawerId, { offset: -70 })
      document.getElementById(drawerId).click()
    }
    else if (this.props.grading_data && this.props.hashCategoriesDrawer
      && document.getElementById(`${this.props.hashCategoriesDrawer}`)) {
      scroller.scrollTo(this.props.hashCategoriesDrawer, { offset: -70 })
      document.getElementById(this.props.hashCategoriesDrawer).click()
    }
    else {

    }
    Events.scrollEvent.register('begin', function () {
      // console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function () {
      // console.log('end', arguments);
    });
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }
  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });

      scroller.scrollTo('scroll-container', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      })
    );
  }
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  renderSubheadingClassName(category, date) {
    var finalVal = []
    category.sections.map(t => t.data.map(u => {
      if (new Date(date) <= new Date(u.visitDate) && !constants.labelsForBioInfo.includes(u.code)) {
        if (!this.state.showOverallGrade) {
          this.setState({ showOverallGrade: true });
        }
        finalVal.push(true);
      }
    }))
    return _.includes(finalVal, true)
  }
  getCategoryGrades(gradeData, category) {
    var gradeArray = []
    gradeData && gradeData.length ?
      gradeData.map(s => {
        return s.data.map(t => {
          if (t.key == category.key) {
            t.value = t.value ? round(t.value.toString(), 2) : t.value
            gradeArray.push(t)
          }
        })
      }) : ''
    if (gradeArray && gradeArray.length)
      return gradeArray.filter(s => s.value);
  }
  renderCategoryGrade(category) {
    if (category.sections.length > 0) {
      var categoryGrades = this.getCategoryGrades(this.props.grading_data, category);
      return (
        <div key={'category_grade_for_' + category.name} className={this.renderSubheadingClassName(category, this.props.latestVisitInfo) ? 'report-card-item default' : 'report-card-item default fade-row'}>
          <div className='row'>
            {categoryGrades && categoryGrades.length ?
              <div id={uniformString(category.key) + '-drawer'} onClick={() => {
                this.props.toggleCategorySideBar('category', this.props.history, category, categoryGrades)
              }} className='col-2' tabIndex="0">
                <Grade points={category.points} />
              </div> : <div className='col-md-2'><Grade points={category.points} /></div>}
            <div className='col-10 align-self-center'>
              <Link to={uniformString(category.enName, 'category')} className='scrollable' spy={true} smooth={true} duration={500} offset={-45}>
                {/* <div className='content'> */}
                <div className='title float-left' title={this.props.presentPatient.languagePreference != "en" ? category.enName : ''}>{category.name}</div>
                {/* </div> */}
              </Link>
            </div>
          </div>
          {categoryGrades && categoryGrades.length ?
            <div className='content full-graph'>
              <div id={uniformString(category.key) + '-drawer'} onClick={() => {
                this.props.toggleCategorySideBar('category', this.props.history, category, categoryGrades)
              }}>
                <DatumSparkline type='pointed' uniqueID={'category_grade_for_' + category.key} historic_data={categoryGrades} />
              </div>
            </div> : ''}
          {/* {categoryGrades && categoryGrades.length ?
            <li id={"category_drawer_" + category.key} onClick={() => {
              this.props.toggleCategorySideBar('category', this.props.history, category, categoryGrades)
            }} className='card-content-row category-grade-individual' tabIndex="0">
              <div className='content'>
                <DatumSparkline type='pointed' uniqueID={'category_grade_for_' + category.key} historic_data={categoryGrades} />
              </div>
            </li>
            : ''} */}
        </div>
      )
    }
    else {
      return (null);
    }
  }

  render_overall_grade(number) {
    let designer_has_made_this_beautiful = false

    if (!designer_has_made_this_beautiful) {
      return (<div className='gpa-container'>
        <div className='name'>{labels.analytics_labels.gpa_scale}</div>
        <div className={`gpa-grade ${this.state.showOverallGrade ? '' : 'fade'} row ml-lg-1`}>
          <Grade points={number} />
          <div className='gpa'>{number}</div> 
          {this.props.grading_data && this.props.grading_data.length ?
            <DatumSparkline type='pointed' uniqueID='overall_gpa_sparkline' historic_data={this.props.grading_data} /> : ''}
        </div></div>);
    }
    else {
      return (null);
    }
  }
  render() {
    return (
      <div className='health-category' id='health_status_indicators'>
        <div className='max-container'>
          <div className='heading'>
            <div className='meta'>
              <h2 className='section-title'>{this.props.terms['Report Card']}</h2>
            </div>
          </div>
          <div className='card report-card-card'>
            <li id='overall-grade-drawer' className='grade-drawer card-content-row' onClick={() => {
              this.props.toggleGradeSideBar('reportCard', this.props.history)
            }} tabIndex="0">
              {this.render_overall_grade(this.props.gpa)}
            </li>
            <div className='report-card-content'>
              {this.props.categories ? this.props.categories.map(this.renderCategoryGrade) : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, {useEffect} from 'react';
import { connect } from 'react-redux';

function SectionOverview(props) {
    useEffect(() => {
        //
    return () => {
            // Anything in here is fired on component unmount.
    }
    }, [])

    return (
        <>
            <div className=''>
            </div>
        </>   
    );
}
export default connect()(SectionOverview);
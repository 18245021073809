import React, {useEffect } from 'react';
import filter_list from '../../../assets/images/filter_list.svg'
import Grade from './Grade';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { roundNumberFixedToTwo } from '../tools/helpers';
import labels from '../../../config/localization';
import _ from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import datumChangeCalculation from '../tools/datumChangeCalculation';
import { round } from '../modules/analyticsBeta';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DownArrow from '../../../assets/images/category-tree-down-arrow.svg';
import UpArrow from '../../../assets/images/category-tree-up-arrow.svg';
import FilterListIcon from '@mui/icons-material/FilterList';


function CategoryComponent(props) {
    useEffect(() => {
        props.filterCategoryData('default')
        return () => {
            // Anything in here is fired on component unmount.
        }
    }, [])
    let _calculator = '';
    let getCategoryGrades = (gradeData, category) => {
        var gradeArray = []
        gradeData && gradeData.length ?
            gradeData.map(s => {
                return s.data.map(t => {
                    if (t.key == category.key) {
                        t.value = t.value ? round(t.value.toString(), 2) : t.value
                        gradeArray.push(t)
                    }
                })
            }) : ''
        if (gradeArray && gradeArray.length)
            return gradeArray.filter(s => s.value);
    }

    let formattedAbsoluteChanged = (row, change_from) => {
        let categoryGradesData =  getCategoryGrades(props.grading_data, row)
        let categoryGradesHistoric = categoryGradesData && categoryGradesData.length ?
        {
            value: _.last(categoryGradesData).value,
            points: _.last(categoryGradesData).points,
            visitDate: _.last(categoryGradesData).visitDate,
            visitId: _.last(categoryGradesData).visitId,
            historicData: categoryGradesData
        } : null
        if(categoryGradesHistoric == null){
            return (null)
        }
        if (change_from == "baseline") {
            _calculator = new datumChangeCalculation(categoryGradesHistoric, 'baseline');
        }
        else {
            _calculator = new datumChangeCalculation(categoryGradesHistoric, 'previous');
        }
        if (_calculator.absoluteChange != null)
            return (_calculator.absoluteChange);
        else
            return (null);
    }
    let formattedPercentChanged = (row, change_from) => {
        let categoryGradesData =  getCategoryGrades(props.grading_data, row)
        let categoryGradesHistoric = categoryGradesData && categoryGradesData.length ?
        {
            value: _.last(categoryGradesData).value,
            points: _.last(categoryGradesData).points,
            visitDate: _.last(categoryGradesData).visitDate,
            visitId: _.last(categoryGradesData).visitId,
            historicData: categoryGradesData
        } : null
        if(categoryGradesHistoric == null){
            return (null)
        }
        if (change_from == "baseline") {
            _calculator = new datumChangeCalculation(categoryGradesHistoric, 'baseline');
        }
        else {
            _calculator = new datumChangeCalculation(categoryGradesHistoric, 'previous');
        }
        if (_calculator.percentChange != null)
            return ("(" + _calculator.percentChange + " %)");
        else
            return (null);
    }

    let findDirection = (row, change_from) => {
        let categoryGradesData =  getCategoryGrades(props.grading_data, row)
        let categoryGradesHistoric = categoryGradesData && categoryGradesData.length ?
        {
            value: _.last(categoryGradesData).value,
            points: _.last(categoryGradesData).points,
            visitDate: _.last(categoryGradesData).visitDate,
            visitId: _.last(categoryGradesData).visitId,
            historicData: categoryGradesData
        } : null
        if(categoryGradesHistoric == null){
            return (null)
        }
        if (change_from == "baseline") {
            _calculator = new datumChangeCalculation(categoryGradesHistoric, 'baseline');
        }
        else {
            _calculator = new datumChangeCalculation(categoryGradesHistoric, 'previous');
        }
        if (_calculator.direction != null) {
            if (_calculator.direction == "up") {
                return (<img src={UpArrow} alt="Up Arrow" />)
            }
            else if (_calculator.direction == "down")
                return (<img src={DownArrow} alt="Down Arrow" />)
            else
                return (null);
        }
        else
            return (null);
    }
    const columns = [
        {
            dataField: 'points',
            text: `Grades`,
            headerStyle: { 
            width: '10%',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '15px',
            letterSpacing: '0px'
            },
            editable: false,
            formatter: (cellContent, row) => (
                <Grade points={row.points}></Grade>
            )
        },
        {
            dataField: 'name',
            text: `Parameter Names`,
            editable: false,
            headerStyle: { 
                width: '35%',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '15px',
                letterSpacing: '0px'
            },
            formatter: (cellContent, row) => (
                <span style={{ fontSize: '14px', fontWeight: 600, lineHeight: '15px', letterSpacing: '0px' }}
                 onClick={() => { props.toggleCategoryDetailsModal(true, row); props.toggleCategoryOverview('category', row, getCategoryGrades(props.grading_data, row))  }}> {row.name} </span>
            )
        },
        {
            dataField: 'points',
            text: `GPA`,
            editable: false,
            headerStyle: { 
                width: '10%',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '15px',
                letterSpacing: '0px'
            },
            formatter: (cellContent, row) => ( row.points != null ?
                <span style={{ fontSize: '12px', fontWeight: 500, lineHeight: '14.52px', letterSpacing: '0em', textAlign: 'left' }}>{roundNumberFixedToTwo(row.points, 2)}</span> : <div>N/A</div>
            )
        },
        {
            dataField: '', headerStyle: { width: '20%', fontSize: '14px', fontWeight: 500, lineHeight: '15px', letterSpacing: '0px' } , text: 'From Last Visit', editable: false, formatter: (cellContent, row) =>( <div className='text-1 row'><span style={{ fontSize: '12px', fontWeight: 500, lineHeight: '14.52px', letterSpacing: '0em', textAlign: 'left' }} className='col-9 justify-content-end d-flex p-0'>{formattedAbsoluteChanged( row, "previous")} {formattedPercentChanged(row, "previous")}</span> <span className='red-icon col-3 d-flex'>{findDirection(row, "previous")}</span></div>)

        },

        {
            dataField: '', headerStyle: { width: '25%', fontSize: '14px', fontWeight: 500, lineHeight: '15px', letterSpacing: '0px' }, text: 'From Baseline Visit', editable: false, formatter: (cellContent, row) => ( <div className='text-1 row'> <span style={{ fontSize: '12px', fontWeight: 500, lineHeight: '14.52px', letterSpacing: '0em', textAlign: 'left' }} className='col-9 justify-content-end d-flex p-0'>{formattedAbsoluteChanged(row, "baseline")} {formattedPercentChanged(row, "baseline")}</span> <span className='red-icon col-3 d-flex'>{findDirection(row, "baseline")}</span></div>)
        }
    ]
    return (
        <> 
            { props.categories && props.categories.length > 0 ?
                <div className='category-overview'>
                    <div className='d-flex justify-content-end noumenon-filter'>
                        <div className='align-self-center'><span className='view-all-text' onClick={() => {props.togglePages('report_card', props.history)}}>{labels.analytics_labels.view_all}</span></div>
                        <Dropdown drop={'down'} align={'start'}>
                            <Dropdown.Toggle> <FilterListIcon /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className='filter-list-container'>
                                    <Dropdown.Item className={props.filterType == "default" ? "active" : ""} style={{ cursor: "pointer" }} onClick={() => { props.filterCategoryData("default") }} >{labels.analytics_labels.default}</Dropdown.Item>
                                    <Dropdown.Item className={props.filterType == "byRisk" ? "active" : ""} style={{ cursor: "pointer" }} onClick={() => { props.filterCategoryData("byRisk") }} >{labels.analytics_labels.by_risk}</Dropdown.Item>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='nuomenon-table recommendation-list-table'>
                        { props.categories && props.categories.length > 0 ?
                            <BootstrapTable striped={false} bordered={false} hover
                                bootstrap4 keyField='guId'
                                data={ props.filterType == "byRisk" ? props.byRiskCategories : props.categories }
                                columns={columns}
                            />
                            
                        : ""} 
                    </div>
                </div>
            : null}
        </>
    );
}

const batchClass = (point) => {
    if (point > 1.8 )
       return ("");
    else if (point >= 1.7)   
        return ('text-Dp');
    else if (point >= 1.0)
       return ('text-D');
    else if (point >= 0.7)
        return ('text-Dm');
    else
       return ('text-F');
}

// let renderCategoryRow = (category, props) => {
//     return (
//         <tr>
//             <td>
//                 <div className='d-flex'>
//                     {category.points != null ? <span className={`${batchClass(category.points)}`}>{roundNumberFixedToTwo(category.points, 2)}</span> : 'N/A'}
//                     <div className='ml-1 align-self-start'><Grade points={category.points} /></div>
//                 </div>
//             </td>
//             <td>
//                 <div className='noumenon-name ml-2' onClick={() => props.setCurrentCategory(category, props.history)} >{category.name}</div>
//                 <div className='noumenon-text ml-2'>
//                     <span>
//                         <span>
//                             {_.head(category.nouenon_list) ?
//                                 <span onClick={() => props.setCurrentNomenon(category,  _.head(category.nouenon_list), props.history)} >
//                                     <span>{_.head(category.nouenon_list) && _.head(category.nouenon_list).label ? _.head(category.nouenon_list).label : ""}
//                                         : </span>
//                                     <span>
//                                         {_.head(category.nouenon_list) ? _.head(category.nouenon_list).value : ""}</span>
//                                     <span> {_.head(category.nouenon_list) ? _.head(category.nouenon_list).units : ""}
//                                     </span>
//                                 </span> : ""
//                             }
//                             {_.nth(category.nouenon_list, 1) ?
//                                 <span onClick={() => props.setCurrentNomenon(category, _.nth(category.nouenon_list, 1), props.history)}> |
//                                     <span>{_.nth(category.nouenon_list, 1) && _.nth(category.nouenon_list, 1).label ? " " +_.nth(category.nouenon_list, 1).label : ""}
//                                         : </span>
//                                     <span>
//                                         {_.nth(category.nouenon_list, 1) ? _.nth(category.nouenon_list, 1).value : ""}</span>
//                                     <span> {_.nth(category.nouenon_list, 1) ? _.nth(category.nouenon_list, 1).units : ""}
//                                     </span>
//                                 </span> : ""
//                             }
//                         </span>
//                     </span>
//                 </div>
//             </td>
//         </tr>
//     )
// }


export default connect()(CategoryComponent);
import { connect } from 'react-redux';
import InterpretationComponent from '../components/InterpretationComponent';
import { actionCreators } from '../modules/analyticsBeta';

let mapStateToProps = (state) => ({
  ...state.analytics
})

let mapDispatchToProps = {
  ...actionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(InterpretationComponent);

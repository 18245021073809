import MultiFactorAuth from './MultiFactorAuth';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { sendOTP, validateOTP, getQRCode, toggleNotification, getAuthStatus, handleSuceessDone } from '../../reducers/global-reducer'

const mapStateToProps = state => ({
  ...state.global,
});

const mapDispatchToProps = {
  sendOTP, validateOTP, 
  getQRCode, toggleNotification, getAuthStatus, handleSuceessDone
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MultiFactorAuth));

import React, { Component } from 'react';

import './all-patients-details.scss';

class AllPatientsDetails extends Component {
    componentDidMount() {
        document.body.style = 'background: #ffffff!important';
        document.title = window.location.href
        this.props.setAllPatientDetails()
    }
    render() {
        return (
            this.props.allPatientDetails ? <div className='all-patients-details container-fluid'>
                <pre>{JSON.stringify(this.props.allPatientDetails)}</pre>
            </div> : null
        )
    }
}
export default AllPatientsDetails
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { parse } from 'qs';
import ReactPaginate from 'react-paginate';
import './elk-styles.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import LoadingTemplate from '../../../components/LoadingTemplate';
import constants from '../../../config/constants';

// const getCaret = (order) => {
//     if (this.props.searchParam && !this.props.searchParam.sortField) {
//         return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>)
//     }
//     if (!order) return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
//     else if (order === 'asc') return (<span>&nbsp;&nbsp;<i className='fa fa-caret-up'></i></span>);
//     else if (order === 'desc') return (<span>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
//     return null;
// }
class CreditTransactionList extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        this.props.toggleFirstTimeLoad(true)
        this.props.noHeaderLoading(true)
        this.props.clearCreditSearch()
        // this.hasURLParams = Object.keys(this.urlParams).length ? true : false
        if (this.urlParams && Object.keys(this.urlParams).length) {
            this.props.getCreditTransactionsWithParams(this.urlParams, this.props.history)
        } else {
            this.urlParams = { page: 1 }
            this.props.getCreditTransactions()
        }
        this.props.getSites()
        this.props.setPageTitle(labels.elk_labels.title)
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.elk_labels.title, path: '' }
        ])
        document.title = `${labels.physioage_label}: ${labels.elk_labels.title}`

        this.unlisten = this.props.history.listen((location, action) => {
            if (location && location.search && this.props.location
                && action == 'POP') {
                const queryString = location && location.search && location.search.substr(1)
                this.urlParams = parse(queryString, true);
                this.props.getCreditTransactionsWithParams(this.urlParams)
            }
        })
    }
    componentWillUnmount() {
        this.props.noHeaderLoading(false)
        this.unlisten()
    }
    componentWillReceiveProps(newProps) {
        if ((this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search)) {
            this.props.clearCreditSearch()
            this.props.getCreditTransactions()
        }
    }
    getCaret = (order, field) => {
        order = this.props.searchParam.sortOrder ? constants.sorting_order_decode[this.props.searchParam.sortOrder] : order
        if (this.props.searchParam) {
            if (!this.props.searchParam.sortField && field != 'date') {
                return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>)
            }   
            if (!order) return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else if (order === 'asc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-up'></i></span>);
            else if (order === 'desc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
        }
        return null;
    }
    render() {
        const columns = [
            {
                dataField: 'siteName',
                text: labels.elk_labels.site,
                sort: true,
                sortCaret: (order, column) => this.getCaret(order, 'siteName'),
                onSort: (sortField, sortOrder) => this.props.sortCredits(sortField, sortOrder, true, this.props.history, this.urlParams.page),
                formatter: (cellContent, row) => (<Link to={`/sites/${row.siteId}`}>{row.siteName}</Link>)
            },
            {
                dataField: 'date', sort: true,
                onSort: (sortField, sortOrder) => this.props.sortCredits(sortField, sortOrder, true, this.props.history, this.urlParams.page),
                sortCaret: (order, column) => this.getCaret(order, 'date'), text: labels.inbox_labels.date_label, formatter: (cellContent, row) => (<span>{moment(new Date(row.date)).format('L')}</span>)
            },
            {
                dataField: 'nameOfTheUser', sort: true,
                onSort: (sortField, sortOrder) => this.props.sortCredits(sortField, sortOrder, true, this.props.history, this.urlParams.page),
                sortCaret: (order, column) => this.getCaret(order, 'nameOfTheUser'), text: labels.elk_labels.user
            },
            {
                dataField: 'number', sort: true,
                onSort: (sortField, sortOrder) => { this.props.sortCredits(sortField, sortOrder, true, this.props.history, this.urlParams.page) },
                sortCaret: (order, column) => this.getCaret(order, 'number'), text: labels.elk_labels.change
            },
            {
                dataField: 'comment', sort: true,
                onSort: (sortField, sortOrder) => this.props.sortCredits(sortField, sortOrder, true, this.props.history, this.urlParams.page),
                sortCaret: (order, column) => this.getCaret(order, 'comment'), text: labels.elk_labels.comment
            },
        ]
        // handle any data change on table here
        // const onTableChange = (type, newState, pageNumber) => {
        //     if (type == 'sort') {
        //         this.props.sortCredits(newState.sortField, newState.sortOrder, true, this.props.history, pageNumber)
        //     }
        // }

        // const paginationOptions = paginationFactory(Object.assign({}, {
        //     page: this.props.searchParam ? this.props.searchParam.pageNumber : 1,
        //     pageSize: this.props.searchParam ? this.props.searchParam.pageSize : 10,
        //     totalSize: this.props.totalCount,
        //     onPageChange: (page) => { this.props.creditsPageChange(page, this.props.history, true) },
        //     alwaysShowAllBtns: true,
        //     prePageText: 'Prev',
        //     nextPageText: 'Next',
        //     withFirstAndLast: false,
        //     hideSizePerPage: true,
        // }))
        return (
            this.props.isLoading && this.props.isFirstTimeLoading ? <>
                <LoadingTemplate haveSidebar={true} isGrid={true} />
            </> :
                <>
                    <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2 elk-filter-card'>
                        <div className='card'>
                            <div className='card-header'>
                                {labels.elk_labels.filter}
                            </div>
                            <div className='card-body'>
                                <FormControl value={this.props.searchParam
                                    && this.props.searchParam.searchFilters
                                    && _.head(this.props.searchParam.searchFilters)
                                    ? _.head(this.props.searchParam.searchFilters).fieldValue : 0}
                                    type='select' label={labels.elk_labels.site}
                                    defaultOption={labels.patient_list_labels.all_sites}
                                    disabled={!this.props.sites || !this.props.sites.length}
                                    options={this.props.sites ? this.props.sites : []}
                                    onChange={(e) => this.props.setSearchSite(e.target.value, true, this.props.history, 1)} textKey='name' valueKey='id' />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0 elk-list-table'>
                        <div className='credits-table'>
                            {this.props.creditTransactions && this.props.creditTransactions.length ?
                                <>
                                    <div className='bootstrap-table-container'>
                                        <BootstrapTable
                                            remote
                                            striped={false}
                                            bordered={false}
                                            hover={true}
                                            bootstrap4
                                            keyField='id'
                                            data={this.props.creditTransactions}
                                            columns={columns}
                                            // defaultSorted={this.props.searchParam && this.props.searchParam.sortField ? [{
                                            //     order: constants.sorting_order_decode[this.props.searchParam.sortOrder.toString()],
                                            //     dataField: this.props.searchParam.sortField
                                            // }] : []}
                                            // onTableChange={(e, v) => onTableChange(e, v, (this.urlParams && this.urlParams.page && this.props.isFirstTimeLoading) ? parseInt(this.urlParams.page) : this.props.searchParam ? this.props.searchParam.pageNumber : 1)}
                                            onTableChange={() => void (0)}
                                            pagination={false} />
                                    </div>
                                    {this.props.totalCount < 10 ? false :
                                        <div id='react-paginate' className='mt-3'>
                                            <ReactPaginate
                                                previousLabel={'Prev'}
                                                nextLabel={'Next'}
                                                breakLabel={'...'}
                                                previousClassName='page-item'
                                                nextClassName='page-item'
                                                breakClassName='page-item disabled'
                                                breakLinkClassName='page-link'
                                                pageClassName='page-item'
                                                pageLinkClassName='page-link'
                                                previousLinkClassName='page-link'
                                                nextLinkClassName='page-link'
                                                activeClassName='active'
                                                pageCount={Math.ceil(this.props.totalCount / 10)}
                                                marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                                pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                                disableInitialCallback={true}
                                                initialPage={this.props.searchParam ? this.props.searchParam.pageNumber - 1 : 0}
                                                onPageChange={(page) => { this.props.creditsPageChange(Math.ceil(page.selected) + 1, this.props.history, true) }}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                            />
                                        </div>}</> : this.props.no_grid_data ?
                                    <div className='jumbotron'>
                                        <h3>{labels.no_grid_data}</h3>
                                    </div> : null}
                        </div>
                    </div>
                </>
        )
    }
}

export default CreditTransactionList
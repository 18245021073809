import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { parse } from 'qs';
import ReactPaginate from 'react-paginate';
import labels from '../../../config/localization';
import LoadingTemplate from '../../../components/LoadingTemplate';
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';

class MessagesList extends Component {
    constructor(props) {
        super(props)
        this.name = React.createRef();

    }

    componentDidMount() {
        this.props.clearFilters()
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.props.setInitialLoading(true);
        this.urlParams = parse(queryString, true);
        this.props.setDefaultInboxParameters(this.urlParams, this.props.history)
        // this.props.getMessageFilters()
        this.props.getSitesList();
        // this.props.getHL7NamesList();
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.inbox_labels.title, path: '' }
        ])
        this.props.setPageTitle(labels.inbox_labels.title)
        document.title = `${labels.physioage_label}: ${labels.inbox_labels.title}`

        this.unlisten = this.props.history.listen((location, action) => {
            if (location && location.search && this.props.location
                && action == 'POP') {
                const queryString = location && location.search && location.search.substr(1)
                this.urlParams = parse(queryString, true);
                this.props.setDefaultInboxParameters(this.urlParams, this.props.history)
            }
        })
        this.nameInput = true
    }
    componentWillReceiveProps(newProps) {
        if ((this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search)) {
            this.props.clearFilters()
            if (this.name && this.name.current) {
                this.name.current.inputEntry.clear()
            }
            this.props.getInboxMessages(this.props.history)
        }
        else if (this.props.isSearchCleared) {
            if (this.name && this.name.current) {
                this.name.current.inputEntry.clear()
            }
        }
    }
    componentWillUnmount() {
        this.unlisten()
    }
    getCaret = (order, field) => {
        order = this.props.searchParam.sortOrder ? constants.sorting_order_decode[this.props.searchParam.sortOrder] : order
        if (this.props.searchParam) {
            // if (!this.props.searchParam.sortField && field != 'lastActiveDate') {
            //     return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>)
            // }
            if (!order) return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else if (order === 'asc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-up'></i></span>);
            else if (order === 'desc' && this.props.searchParam.sortField == field) return (<span>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
            else return (<span className='grey-out'>&nbsp;&nbsp;<i className='fa fa-caret-down'></i></span>);
        }
        return null;
    }
    render() {
        const columns = [
            { dataField: 'from', text: labels.inbox_labels.from_label, headerStyle: { width: '15%' }, sort: true, sortCaret: (order, column) => this.getCaret(order, 'From') },
            {
                dataField: 'site', text: labels.inbox_labels.site_label, headerStyle: { width: '15%' }, sort: true, sortCaret: (order, column) => this.getCaret(order, 'Site')
            },
            {
                dataField: 'patient', text: labels.inbox_labels.patient,
                headerStyle: { width: '15%' }, sort: true,
                sortCaret: (order, column) => this.getCaret(order, 'Patient'),
            },
            { dataField: 'dob', headerStyle: { width: '15%' }, text: labels.inbox_labels.dob, sort: true, sortCaret: (order, column) => this.getCaret(order, 'DOB'), formatter: (cellContent, row) => <span>{row.dob ? moment(row.dob).format('L') : ''}</span> },
            { dataField: 'visit', headerStyle: { width: '15%' }, text: labels.inbox_labels.visit, sort: true, sortCaret: (order, column) => this.getCaret(order, 'Visit'), formatter: (cellContent, row) => (row.visit ? <Link to={`/visits/${row.visitId}`}>{moment(row.visit).format('L')}</Link> : '') },
            {
                formatter: (cellContent, row) => (
                    <div className='d-flex justify-center'>
                        <button className='btn btn-small raw-btn' onClick={() => this.props.navigateMessage(this.props.history, `/messages/details/${row.from}/${row.id}`)}>{labels.inbox_labels.raw_btn}</button>
                    </div>),
                headerStyle: { width: '5%' }
            },
            {
                dataField: 'received', text: labels.inbox_labels.received, sort: true, headerStyle: { width: '15%' }, sortCaret: (order, column) => this.getCaret(order, 'CreatedAt'), formatter: (cellContent, row) => <span>{row.received.replace("-", "")} </span>
            },
            {
                dataField: 'processed', text: labels.inbox_labels.processed, sort: true, headerStyle: { width: '15%' }, sortCaret: (order, column) => this.getCaret(order, 'ProcessedAt'), formatter: (cellContent, row) => <span>{row.processed.replace("-", "")} </span>
            }

        ]

        const onTableChange = (type, { sortField, sortOrder }) => {
            if (type == 'sort') {
                let sortfield;
                // console.log(1 + " " + sortField);
                switch (sortField) {
                    case 'from': sortfield = 'From';
                        break;
                    case 'site': sortfield = 'Site'
                        break;
                    case 'patient': sortfield = 'Patient'
                        break;
                    case 'dob': sortfield = 'DOB'
                        break;
                    case 'visit': sortfield = 'Visit'
                        break;
                    case 'received': sortfield = 'CreatedAt'
                        break;
                    case 'processed': sortfield = 'ProcessedAt'
                        break;
                    default: sortfield = sortField
                }
                // console.log(2 + " " + sortfield);
                this.props.sortMessagesList(sortfield, sortOrder, this.props.history, this.urlParams && this.urlParams.page ? this.urlParams.page : 1)
            }
        }

        const paginationOptions = paginationFactory({
            page: this.props.searchParam ? parseInt(this.props.searchParam.pageNumber) : 1,
            pageSize: this.props.searchParam ? this.props.searchParam.pageSize : 10,
            totalSize: this.props.totalCount,
            onPageChange: (page) => { this.props.inboxPageChange(page, this.props.history) },
            alwaysShowAllBtns: true,
            prePageText: 'Prev',
            nextPageText: 'Next',
            withFirstAndLast: false,
            hideSizePerPage: true
        });
        return (
            this.props.isLoading && this.props.initialLoading ? <>
                <LoadingTemplate haveSidebar={true} isGrid={true} />
            </> :
                <>
                    <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2 inbox-section inbox-messages-sidepanel'>
                        {/* <h4>{labels.inbox_labels.filter_title}</h4> */}
                        <div>
                            <div className='card sender-filter-card'>
                                <div className='card-header'>Filter</div>
                                <form className="card-body" onSubmit={(e) => {
                                    e.preventDefault(); this.props.getInboxMessages(this.props.history);
                                }}>
                                    <FormControl
                                        id='site' label={labels.inbox_labels.site_label}
                                        type='select'
                                        onChange={(e) => this.props.setActiveFilter(e.target.id, e.target.value, true, this.props.history)}
                                        defaultOption={labels.inbox_labels.all_sites}
                                        value={this.props.currentSearchFilters && this.props.currentSearchFilters.site ? parseInt(this.props.currentSearchFilters.site) : 0}
                                        disabled={!this.props.sitesList || !this.props.sitesList.length}
                                        options={this.props.sitesList ? this.props.sitesList : []}
                                        textKey={'name'} valueKey={'id'}
                                    />
                                    <FormControl 
                                        onKeyDown={(e) => {
                                            if (e.keyCode == constants.enter_key_code || e.keyCode == constants.escape_key_code) {
                                                e.length ? this.props.searchCustomHL7Name(e, this.props.history) : null; this.nameInput = e.target.value ? false : true ;
                                            }
                                        }}
                                        id='name' ref={this.name} label={labels.inbox_labels.name_only_label}
                                        open={this.props.currentHL7Names && this.props.currentHL7Names.length ? true : false}
                                        type='typeahead' onSearch={(name) => this.props.populateHL7SearchNames(name, this.props.history)}
                                        selected={this.props.currentSearchFilters && this.props.currentSearchFilters.name ? [this.props.currentSearchFilters.name] : []}
                                        onInputChange={(name) => this.props.populateHL7SearchNames(name, this.props.history)}
                                        //labelKey={}
                                        options={this.props.currentHL7Names}
                                        emptyLabel=''
                                        onChange={(e) => {
                                            if (e && e.length && _.head(e)) {
                                                this.props.setActiveFilter('name', _.head(e), true, this.props.history)
                                                this.props.clearHL7Names()
                                            }
                                        }}
                                        // onBlur={(e) => {
                                        //     this.nameInput = true;
                                        //     if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                                        //         this.props.setActiveFilter('name', _.head(e), true, this.props.history)
                                        //         this.props.clearHL7Names()
                                        //     }
                                        // }}
                                    />

                                    <FormControl type='date' selected={this.props.date}
                                        //maxDate={moment(this.props.endDate).add(-1, 'days').toDate()}
                                        disabledKeyboardNavigation
                                        id='date'
                                        onKeyDown={(e) => {
                                            if (e.key.toLowerCase() == 'enter')
                                                this.props.getInboxMessages(this.props.history)
                                        }}
                                        onChange={(e) => this.props.setActiveFilter('date', e, true, this.props.history)} label={labels.inbox_labels.date_label} />

                                    <FormControl
                                        type='button' btnType='default'
                                        className='search-btn' text="Search" />
                                </form>
                            </div>
                            <br />
                            <div className='card sender-filter-card'>
                                <div className='card-header'>{labels.inbox_labels.filter_subtitle}</div>
                                <div onClick={() => this.props.setActiveFilter('from', null, false, this.props.history)} className={`msg-filters card ${!this.props.activeSender ? 'active-card' : ''}`}>
                                    <div className='d-flex'><span className='sender'>All</span><span className='badge'>{this.props.totalMessagesCount}</span></div>
                                </div>
                                {this.props.messageFilters ? this.props.messageFilters.map(msg => (
                                    <div onClick={() => this.props.setActiveFilter('from', msg.from, false, this.props.history)} className={`msg-filters card ${msg.from == this.props.activeSender ? 'active-card' : ''}`}>
                                        <div className='d-flex'><span className='sender'>{msg.from}</span><span className='badge'>{msg.count}</span></div>
                                        <span className='last-active'>{msg.lastReceived.replace("-", "")}</span>
                                    </div>
                                )) : null}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0 inbox-table'>
                        {this.props.filteredMessages && this.props.filteredMessages.length ?
                            <>
                                <div className='bootstrap-table-container'>
                                    <BootstrapTable
                                        remote
                                        striped={false}
                                        bordered={false}
                                        hover={true}
                                        bootstrap4
                                        keyField='id'
                                        data={this.props.filteredMessages}
                                        columns={columns}
                                        pagination={false}
                                        onTableChange={onTableChange}
                                    />
                                </div>
                                {this.props.totalCount < 10 ? false :
                                    <div id='react-paginate' className='mt-3'>
                                        <ReactPaginate
                                            previousLabel={'Prev'}
                                            nextLabel={'Next'}
                                            breakLabel={'...'}
                                            previousClassName='page-item'
                                            nextClassName='page-item'
                                            breakClassName='page-item disabled'
                                            breakLinkClassName='page-link'
                                            pageClassName='page-item'
                                            pageLinkClassName='page-link'
                                            previousLinkClassName='page-link'
                                            nextLinkClassName='page-link'
                                            activeClassName='active'
                                            pageCount={Math.ceil(this.props.totalCount / 10)}
                                            marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                            pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                            disableInitialCallback={true}
                                            initialPage={this.props.searchParam ? this.props.searchParam.pageNumber - 1 : 0}
                                            onPageChange={(page) => { this.props.inboxPageChange(Math.ceil(page.selected) + 1, this.props.history) }}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                        />
                                    </div>}
                            </> : this.props.no_grid_data ?
                                <div className='jumbotron'>
                                    <h3>{labels.no_grid_data}</h3>
                                </div>
                        : null}
                    </div>
                </>
        )
    }
}
export default MessagesList
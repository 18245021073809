import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import Sprite from './SpriteIcon';
import { uniformString } from '../tools/helpers';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import EditIcon from '@material-ui/icons/Edit';
import constants from '../../../config/constants';

function RecommendationTable(props) {  
    useEffect(() => {   

    return () => {
            // Anything in here is fired on component unmount.
    }
    }, [])
    
    let recommendations = () =>{
        if (props.drawerType == 'biomarker') {
            return (props.recommendations ? props.recommendations.filter(rec => rec.indicators.includes(props.ages)) : [])
        }
        else {
            return (props.recommendations ? props.recommendations.filter(rec => rec.indicators.includes(props.currentIndicator)) : [])
        }
    }

    const columns = [
        {
            dataField: 'group',
            text: `Group`,
            headerStyle: { width: '12%' },
            editable: false,
            formatter: (cellContent, row) => (
                <div className="jw-icon" title={row.group}>
                    <Sprite group="recommendations" label={row.group} sprite={uniformString(row.group)} />
                </div>
            )
        },
        {
            dataField: 'body',
            text: `Body`,
            editable: false,
            headerStyle: { width: '80%' },
            formatter: (cellContent, row) => (
                // <div>
                //     <div className="">
                        row.body + " " + (row.body.includes(row.strength) ? "" : row.strength ? row.strength : '')
                        // </div>
                    
                // </div>
            )
        },
        {
            dataField: 'startedAt', headerStyle: { width: '19%' }, text: 'Start Date', editable: false, formatter: (cellContent, row) => <><span>{cellContent ? moment(cellContent).format('L') : ''}</span>
            </>
        },

        {
            dataField: 'endedAt', headerStyle: { width: '19%' }, text: 'End Date', editable: false, formatter: (cellContent, row) => <><span>{cellContent ? moment(cellContent).format('L') : ''}</span>
            </>
        },
        
        {  
            dataField: '', headerStyle: { width: '14%' }, text: props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ? 'Action' : null ,
            formatter: (cellcontent, row) => <> { 
                props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ?
                    <div className='actions lh-30'>
                        <span className='padding-left-8px pr-3' onClick={() => {
                        props.toggleRecommendationModal(true);
                        props.updateRecommendations(row.id)
                        props.toggleMetaRecommendations(false)
                        props.setMetaRecord(null)}} > <EditIcon /></span>
                        <span ><i class='fa fa-trash' 
                            onClick={() =>{ props.deleteRecommendations(row.id, _.head(row.indicators), "Recommendation")}} 
                            aria-hidden="true"></i> 
                        </span>
                    </div>
                    : null }
            </>
        }
    ]

    return (
        
        <>
            <div className='recommendation-list-table pr-3'>
                { props.isRecommendationsLoading ? <center><i className='fa fa-spinner fa-spin'></i></center> 
                : recommendations() && recommendations().length > 0 ?
                    <BootstrapTable striped={false} bordered={false} hover
                        bootstrap4 keyField='guId'
                        data={recommendations()}
                        columns={columns}
                    />
                    : <div className="instructions no-recommendations">
                        <div className="card-content">
                        { props.isDemo || (props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)) ? 
                        <>
                            <p>You have not entered any recommendations.</p>
                            <p>You can enter recommendations on the Recommendations tab of any "drawer". There are several ways to access drawers:
                            </p>
                            <ol style={{ lineHeight: '200%' }}>
                                <li><b>Test level</b> - Click on any test name (eg, HGA1C) to open the drawer for that test. </li>
                                <li><b>Category level</b> - Click on any Category name (eg; Heart Health) to open the drawer for that Category.</li>
                                <li><b>Section level</b> - Click on the letter grade to the  left of any Section (eg, Arterial Stiffness) to open the drawer for that Section</li>
                            </ol>
                            <p>To enter a recommendation tied to this Visit - Click on <b>Add Recommendation</b> above.</p><br />
                            <p>Recommendations will be visible to patients in this section and on the Visit Summary PDF.</p>
                        </>
                        : <p>Recommendations added for you by the provider will be listed in this section.</p>
                    }
                        </div>
                    </div>
                }
            </div>    
        </>
    );
}
export default connect()(RecommendationTable);
import { connect } from 'react-redux'
import FeedBackList from '../components/FeedBackList'
import { actionCreators } from '../modules/feedback'

let mapStateToProps = (state) => ({
    ...state.feedback
  })
  
  let mapDispatchToProps = {
    ...actionCreators
  }

export default connect(mapStateToProps, mapDispatchToProps)(FeedBackList)
import React, { Component } from 'react';
import { Switch } from 'react-router-dom';

import '../../styles/app.scss';
import Header from '../../components/Header';
import RouteWithSubRoutes from '../../utils/RouteWithSubRoutes';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';
import PageTitle from '../../components/PageTitle';
import Notification from '../../components/Notification';
import labels from '../../config/localization';
import FormControl from '../../components/FormControls/FormControl';
import constants from '../../config/constants';
import Toaster from '../../components/Toaster';
import ProgressLoader from '../../components/ProgressLoader';

const excludeRoutes = ['reports', 'sites.cj', 'patients.cj', 'documents.cj', 'api.cj', 'pdf', 'jpg', 'png', 'jpeg', 'json']
const excludeFooterRoutes = ['patients.cj', 'documents.cj', 'api.cj', 'sites.cj', 'pdf', 'jpg', 'png', 'jpeg', 'json']
const publicUrls = ['sites.cj']

class CoreLayout extends Component {
  componentDidMount() {
    //get toaster on login
    // this.props.toggleToaster(true)
    if (this.props.loggedInUser && this.props.loggedInUser.role != constants.logged_roles.PT)
      this.props.setLoggedInUser()
    this.props.setHistory(this.props.history)
    this.props.makeParentContainer()
    this.props.setModelWidth()

    window.addEventListener('resize', (e) => {
      this.props.makeParentContainer();
      this.props.setModelWidth()
    })
    window.addEventListener('click', (e) => {
      if (this.props && this.props.isHeaderCollapseOpen && document.getElementById('mainHeaderMenu') && !document.getElementById('mainHeaderMenu').contains(e.target)) {
        this.props.headerCollapseToggle(false)
      }
    })
  }
  componentWillUpdate(newProps) {
    if (this.props.location.pathname !== newProps.location.pathname) {
      this.props.setPageTitle('')
      this.props.setBreadCrumbs([])
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.headerNavigation()
    }
  }
  render() {
    return (
      <div className={`app wrapper${excludeRoutes.filter(er => this.props.location.pathname.indexOf(er) > -1).length == 0 ? '' : ' no-page-title'}
      ${publicUrls.filter(p => this.props.location.pathname.indexOf(p) > -1).length == 0 ? '' : ' public-access-route'}
      ${this.props.isLoading ? ' app-rendering' : ''}`}>
        {this.props.isLoading && !this.props.isReportLoading  ? <Loader /> : null}
        
        {this.props.isReportLoading ? <ProgressLoader isProgressLoading={this.props.isLoading} /> : null}
        <Header {...this.props} />
        {excludeRoutes.filter(er => this.props.location.pathname.indexOf(er) > -1).length == 0 ?
          <div className='row children-section'>
            {/* <div className='container-fluid mt-5'>
              <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
              <div className={`float-right col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                <PageTitle breadCrumbs={this.props.breadCrumbs}
                  pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                  navigatePatients={this.props.navigatePatients}
                  history={this.props.history}
                  {...this.props} />
              </div>
            </div> */}

            <Switch>
              <div className='children-content container'>

                {this.props.routes.map((route, index) => {
                  return RouteWithSubRoutes(route, index);
                })}
              </div>
            </Switch>
          </div> :
          <div className='row children-section'>
            <Switch>
              <div className='children-content'>
                {this.props.routes.map((route, index) => {
                  return RouteWithSubRoutes(route, index);
                })}
              </div>
            </Switch>
          </div>
        }
        {/* {this.props.routeFailureCount && this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.AN
          ? <Toaster toastHeaderText='Message Failures' {...this.props} onClose={this.props.toggleToaster} /> : null} */}
        {excludeFooterRoutes.filter(er => this.props.location.pathname.indexOf(er) > -1).length == 0 ?
          <div className='container d-flex footer-section'>
            <Footer />
          </div> : null}
      </div>
    );
  }
}

export default CoreLayout;

import {connect} from 'react-redux';
import {provideRecommendationThunk, updateRecommendationThunk} from '../thunks/recommendations';
import RecommendationInputComponent from '../components/RecommendationInputComponent';
import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
  ...state.analytics,
})

const mapDispatchToProps = {
  ...actionCreators
}
// let provideRecommendation = (dispatch) => {
//   return (params) => dispatch(provideRecommendationThunk(params))
// }

// let updateRecommendation = (dispatch) => {
//   return (params) => dispatch(updateRecommendationThunk(params))
// }

// let mapStateToProps = (state) => {
//   return {
//     recommendation: state.current_recommendation,
//     // datum: state.drawer_content.datum,
//     // dataPersists: !state.presentation.demo
//   }
// }

// let mapDispatchToProps = (dispatch) => {
//   return {
//     provideRecommendation: provideRecommendation(dispatch),
//     updateRecommendation: updateRecommendation(dispatch)
//   };
// }

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationInputComponent);

import { connect } from 'react-redux';
import SlidingNav from '../components/SlidingNavComponent';
import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
  ...state.analytics,
})

const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(SlidingNav);

import { connect } from 'react-redux';
import { getReferralSites } from '../../reducers/global-reducer';
import ReferralSites from './ReferralSites';

const mapStateToProps = state => ({
  ...state.global
});

const mapDispatchToProps = {
    getReferralSites
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralSites);

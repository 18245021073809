import API_URL from '../../../config/api';
import axios from '../../../lib/axios';
import { toggleLoading, setPageTitle, setBreadCrumbs, handleErrors, toggleNotification, handleLoading, handleStatusCodes, downloadDocument } from '../../../reducers/global-reducer';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import { b64toBlob, isFirefox } from '../../../utils/download-utils';

const SET_PROPS = 'SET_PROPS';
const imgFiles = ['jpg', 'png', 'jpeg']
const docFiles = ['pdf']

export function setDocumentView(documentId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                libraryDocument: null
            }
        })
        promisesList.push(axios.get(`${API_URL.DOCUMENT_WITH_ID}?documentId=${documentId}&renderBase64=${true}`).then(response => {
            let responseData = response.data ? response.data.value : null
            let isImage = imgFiles.filter(i => responseData.fileContentType.toLowerCase().indexOf(i) != -1).length ? true : false
            if (responseData)
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        libraryDocument: responseData,
                        isImage
                    }
                })
            if (!isImage) {
                let downloadLink
                if (!isFirefox()) {
                    let pdfblob = b64toBlob(`data:${responseData.fileContentType};base64,` + responseData.documentFileContent);
                    downloadLink = URL.createObjectURL(pdfblob)
                }
                else {
                    downloadLink = `data:${responseData.fileContentType};base64,` + responseData.documentFileContent
                }
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        downloadLink
                    }
                })
            }
            // if (docFiles.filter(d => responseData.fileContentType.toLowerCase().indexOf(d) != -1).length)
            document.body.style = 'background: #000000!important';
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))
            dispatch(handleStatusCodes(error))
        }))
    }
}


//action handlers
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
};
//default state
const initialState = {

};
//reducer
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};

export const actionCreators = {
    setDocumentView,
    downloadDocument
}
import {connect} from 'react-redux';

import DatumSparkline from '../components/DatumSparkline';
import { actionCreators } from '../modules/analytics';

const mapStateToProps = (state) => ({
    ...state.analytics
})
const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(DatumSparkline);

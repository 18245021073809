import React, { Component } from 'react';
import labels from '../../../config/localization';
import CreditForm from './CreditForm';
import FormControl from '../../../components/FormControls';
import constants from '../../../config/constants';


class CreditsForm extends Component {
    componentDidMount() {
        this.props.clearCreditForm()
        this.props.getSiteDetails(this.props.match.params.siteId, this.props.history)
    }

    render() {
        return (
            <div className='row container add-credits-form'>
                <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                </div>
                <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 order-1 mb-4 input-credit-form'>
                    <CreditForm {...this.props} />
                </div>

            </div>
        )
    }
}

export default CreditsForm;
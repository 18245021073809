import React, { Component } from 'react';
import SpreadingRoutes from '../../../utils/SpreadingRoutes';
import './visits-styles.scss';
class Reports extends Component {
    componentDidMount() {
        this.props.setPageTitle(null)
        this.props.setBreadCrumbs([])
    }
    render() {
        return (
            <div className='reports-container container parent-component'>
                <SpreadingRoutes {...this.props} />
            </div>
        )
    }
}
export default Reports;
import React, { Component } from 'react';
import UploadMultipleDocumentComponent from './UploadMultipleDocumentComponent'

class UploadPatientDocument extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.id) {
            this.props.setCurrentPatient(this.props.match.params.id, true, 'upload-document')
            this.props.setDocumentsFields()
        }
    }
    render() {
        return (
            <div className='container manual-form upload-patient-document-table'>
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>  {
                this.props.currentPatient && Object.keys(this.props.currentPatient).length ?
                    <div className='float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12' >
                        <UploadMultipleDocumentComponent {...this.props} />
                    </div>
                : '' 
                }
            </div>

        )
    }
}
export default UploadPatientDocument;
import {connect} from 'react-redux';
import PatientHistoryComponent from '../components/PatientHistoryComponent';
import { actionCreators } from '../modules/analytics';
const mapStateToProps = (state) => ({
  ...state.analytics
})

const mapDispatchToProps = {
  ...actionCreators
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientHistoryComponent);

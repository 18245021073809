import React from 'react';
import PropTypes from 'prop-types';
import ValueCard from './ValueCardComponent';

export default class NoumenonDescription extends React.Component {
  static propTypes = {
    noumenon: PropTypes.object
  };

  render() {
    return (
      // <div className='drawer-content-data'>
        <div className='results w-100'>
          <ValueCard {...this.props} />
        </div>
      // </div>
    )
  }
}

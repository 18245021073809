import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { parse } from 'qs';

import labels from '../../../config/localization';
import constants from '../../../config/constants'
import FormControl from '../../../components/FormControls/FormControl.js';
import LoadingTemplate from '../../../components/LoadingTemplate';
import ReactPaginate from 'react-paginate';
const breadCrumbs = [
    { text: labels.physioage_label, path: '/patients/list' },
    { text: labels.credits_labels.title, path: '' }
]


class CreditsList extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        this.props.getCredits(this.props.match.params.siteId, this.props.history, this.urlParams.page)
        this.props.setBreadCrumbs(breadCrumbs)
        this.props.setPageTitle(labels.credits_labels.title)
        document.title = `${labels.physioage_label}: ${labels.credits_labels.title}`;

        this.unlisten = this.props.history.listen((location, action) => {
            if (location && location.search && this.props.location
                && action == 'POP') {
                const queryString = location && location.search && location.search.substr(1)
                this.urlParams = parse(queryString, true);
                this.props.getCredits(this.props.match.params.siteId, this.props.history, this.urlParams.page)
            }
        })
    }
    componentWillReceiveProps(newProps) {
        if ((this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search)) {
            this.props.getFilteredCredits(1)
        }
    }
    componentWillUnmount() {
        this.unlisten()
    }
    render() {
        const paginationOptions = paginationFactory({
            alwaysShowAllBtns: true,
            prePageText: 'Prev',
            nextPageText: 'Next',
            withFirstAndLast: false,
            hideSizePerPage: true,
            page: this.props.defaultPage,
            onPageChange: (page) => this.props.history.push(`?page=${page}`)
        });
        const columns = [
            {
                formatter: (cellContent, row) => (<FormControl className='user-edit-btn' type='button' btnType='default btn-sm'
                    onClick={() => this.props.getReceipt(row.creditTransactionId)} text={labels.credits_labels.receipt}></FormControl>)
                , headerStyle: { width: '10%' }
            },
            {
                dataField: 'change', text: labels.header_labels.elk,
                headerStyle: { width: '15%' }
            },
            { dataField: 'date', headerStyle: { width: '20%' }, text: labels.inbox_labels.date_label, formatter: (cellContent, row) => (row.date ? <span>{moment(row.date).format('MM/DD/YYYY')}</span> : null) },
            { dataField: 'nameOfTheUser', text: labels.elk_labels.user },
            { dataField: 'comment', text: labels.elk_labels.comment },
        ]

        return (
            this.props.isLoading ? <LoadingTemplate haveSidebar={true} isGrid={true} /> :
                <>
                    <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                        <div className='form-group credits-btn-group'>
                            <FormControl type='button' btnType='primary' className='add-credits-btn' text={labels.credits_labels.add_credits} onClick={() => this.props.history.push(`/credits/${this.props.match.params.siteId}/new`)} />
                            <FormControl type='button' btnType='info' className='download-btn' text={labels.credits_labels.download} onClick={() => {
                                this.props.exportDataToCSV()
                            }} />
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 mb-4 order-1 p-0 report-credits-table'>
                        {this.props.credits && _.head(this.props.credits) ? <div className="page-title-section alert alert-info" role="alert">

                            <h3>{_.head(this.props.credits).siteName} has {_.head(this.props.credits).credits} credits remaining.</h3>

                            {/* <h3>House of Capulet Memorial Hospital has {this.props.credits && _.head(this.props.credits) ? _.head(this.props.credits).credits : ''} credits remaining.</h3> */}
                        </div> : null}

                        {this.props.credits && this.props.credits.length ?
                            <React.Fragment>
                                <h3>
                                    {labels.credits_labels.history}
                                </h3>
                                <div className='credits-table'>
                                    {this.props.credits && this.props.credits.length ?
                                        <>
                                            <div className='bootstrap-table-container'>
                                                <BootstrapTable striped={false}
                                                    bordered={false}
                                                    hover={true} bootstrap4 keyField='id'
                                                    data={this.props.filteredCredits} columns={columns}
                                                    pagination={false}
                                                />
                                            </div>
                                            {this.props.credits.length < 10 ? false :
                                                <div id='react-paginate' className='mt-3'>
                                                    <ReactPaginate
                                                        previousLabel={'Prev'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        previousClassName='page-item'
                                                        nextClassName='page-item'
                                                        breakClassName='page-item disabled'
                                                        breakLinkClassName='page-link'
                                                        pageClassName='page-item'
                                                        pageLinkClassName='page-link'
                                                        previousLinkClassName='page-link'
                                                        nextLinkClassName='page-link'
                                                        activeClassName='active'
                                                        pageCount={Math.ceil(this.props.credits.length / 10)}
                                                        marginPagesDisplayed={this.props.isVerySmallScreen ? 1 : 2}
                                                        pageRangeDisplayed={this.props.isVerySmallScreen ? 3 : this.props.isSmallScreen ? 5 : 7}
                                                        disableInitialCallback={true}
                                                        forcePage={this.props.defaultPage ? this.props.defaultPage - 1 : 0}
                                                        onPageChange={(page) => {
                                                            this.props.getFilteredCredits(Math.ceil(page.selected) + 1);
                                                            this.props.history.push(`?page=${Math.ceil(page.selected) + 1}`)
                                                        }}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                    />
                                                </div>}
                                        </>
                                        : null}
                                </div>
                            </React.Fragment> : null}
                        {this.props.no_grid_data ?
                            <div className='jumbotron'>
                                <h3>{labels.no_grid_data}</h3>
                            </div>
                            : null}
                    </div>
                </>
        )
    }
}
export default CreditsList;
import React from 'react';
import sprite from '../../../assets/images/sprite_base.svg'
import labels from '../../../config/localization';
export default class RecentRecommendations extends React.Component {
constructor(props)
{
  super(props);
  this.renderRec = this.renderRec.bind(this);
}

renderRec(rec)
{
  return(
    rec ?
    (rec).map((s) => (
    <div className='pa-checkbox' key={'recent_recommendation' + s['body']}>
      <label htmlFor={'recommendation_ipsa-' + s['body']}>
        <input id={'recommendation_ipsa-' + s['body']} type='checkbox' name='common-recommendation' onClick='#' />
        <div className='faux-input'>
          <div className='icon '>
            <svg aria-hidden='true' title='Previous'>
              <use xlinkHref={`${sprite}#checkmark`} xmlnsXlink='http://www.w3.org/1999/xlink'></use>
            </svg>
          </div>
        </div>
        <span>{s['body']}</span>
      </label>
    </div>
  )):'')
}

actuallyRender()
{
  return(
    <div className='touch-group-vertical checkbox lg'>
      <fieldset>
        <legend>{labels.analytics_labels.recent_recommendations}</legend>
        {this.renderRec(this.props.recommendations ?
        this.props.recommendations.filter(rec => rec.indicators.indexOf(this.props.ages) == -1) : '')}
      </fieldset>
    </div>
  );
}

render() {
  if( this.props.recommendations ? this.props.recommendations.length === 0 : 1)
    return(null);
  else
    return(this.actuallyRender());
}

onCheckboxClick(rec)
{
  let fn = () => {
    this.props.dittoRecommendation({
      indicator: 'physioage',
      recommendation: rec
    })
  };

  return( fn.bind(this) );
}
}

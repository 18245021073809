import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import { returnLocalizedDate } from '../../../utils/resolve-timer'
import BootstrapTable from 'react-bootstrap-table-next';

const regData = [
    'noumenon',
    'id',
    'resultId',
    'valueType',
    'identifier',
    'subIdentifier',
    'label',
    'value',
    'units',
    'referenceRange',
    'abnormalFlags',
    'probability',
    'natureOfAbnormalTest',
    'resultStatus',
    'observedAt',
    'producer',
    'observationMethod',
    'equipmentInstance',
    'analyzedAt',
    'createdAt',
    'updatedAt',
    'observerType',
    'observerId',
    'datumId'
]

class ObservationDetails extends Component {
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.obsId) {
            this.props.getObservation(this.props.match.params.obsId)
        }
        this.props.setPageTitle(`${labels.oddities_labels.observation}: ${this.props.match.params.obsId}`)
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.oddities_labels.observation, path: '' }
        ])
        document.title = `${labels.physioage_label}: ${labels.oddities_labels.observation}`
    }
    getResultRoute = (obs) => {
        if (obs && obs.sourceType && obs.sourceId) {
            return (obs.sourceType && obs.sourceType.toLowerCase() == constants.sourceType.provider) ? `/providers/${obs.sourceId}`
                : (obs.sourceType && obs.sourceType.toLowerCase()) == constants.sourceType.user ? `/users/${obs.sourceId}` : ''
        } else {
            return ''
        }
    }
    getResult = (props) => {
        // if (props.headerType == constants.visit_labels.result) {
        //     props.toggleObservation()
        // }
        // else {
        props.history.push(`/visits/${props.currentObservation.visitId}/results/${props.currentObservation.resultId}`)
        // }
    }
    render() {
        const columns = [
            {
                text: labels.oddities_labels.result_date,
                formatter: (cellContent, row) => (moment(returnLocalizedDate(row.resultDate)).format("L")),
                headerStyle: { width: '15%' }
            },
            { dataField: 'observationId', text: `${labels.oddities_labels.obx}#`, headerStyle: { width: '10%' } },
            { dataField: 'identifierValue', text: labels.oddities_labels.identifier, headerStyle: { width: '35%' } },
            { dataField: 'value', text: labels.oddities_labels.value, headerStyle: { width: '10%' } },
            { dataField: 'units', text: labels.oddities_labels.units, headerStyle: { width: '10%' } },
            { dataField: 'referenceRange', text: labels.oddities_labels.referenceRange, headerStyle: { width: '10%', paddingRight: '5px', paddingLeft: '5px' } },
            { dataField: 'status', text: labels.oddities_labels.observation_status, headerStyle: { width: '10%' } },
        ]
        return (
            this.props.currentObservation ?
                <>
                    <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                    <div className='observation react-bootstrap-table col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0'>
                        <div className='d-flex mb-2'>
                            <div className='col-lg-6 p-0'><b>{labels.oddities_labels.result_label}</b></div>
                            <div className='col-lg-6 p-0'><a onClick={() => this.getResult(this.props)}>{this.props.currentObservation.resultId ? this.props.currentObservation.resultId : '-'}</a></div>
                        </div>
                        <div className='d-flex mb-3'>
                            <div className='col-lg-6 p-0'><b>{labels.oddities_labels.source_label}</b></div>
                            <div className='col-lg-6 p-0'>{this.props.currentObservation.source ?
                                this.props.currentObservation.sourceType && this.props.currentObservation.sourceId
                                    && this.props.currentObservation.sourceType.toLowerCase() == constants.message_type ?

                                    <a href='javascript:void(0)' title={this.props.currentObservation.source}
                                        onClick={() => this.props.navigateMessage(this.props.history, `/messages/details/${this.props.currentObservation.sourceId}`)}>
                                        {this.props.currentObservation.source}
                                    </a>
                                    : this.props.currentObservation.sourceType && this.props.currentObservation.sourceId
                                        && this.props.currentObservation.sourceType.toLowerCase() != constants.message_type ?
                                        <a href='javascript:void(0)' onClick={() => this.props.history.push(this.getResultRoute(this.props.currentObservation))}>
                                            {this.props.currentObservation.source}
                                        </a> : this.props.currentObservation.source
                                : '-'}</div>
                        </div>
                        {regData.map(rd => (
                            <div className='obs-details-rows d-flex'>
                                <div className='col-lg-6 p-0'>{labels.oddities_labels[rd]}</div>
                                <div className={`col-lg-6 p-0${this.props.currentObservation[rd] && this.props.currentObservation[rd].toString().indexOf(' ') != -1 ? '' : ' break-all'}`}>{this.props.currentObservation[rd] ?
                                    (rd == 'createdAt' || rd == 'updatedAt' || rd == 'observedAt' || rd == 'analyzedAt') ? moment(returnLocalizedDate(this.props.currentObservation[rd])).format("YYYY-MM-DD h:mm:ss A") : this.props.currentObservation[rd] : '-'}</div></div>
                        ))}

                        <div className='history-section'>
                            {this.props.currentObservation
                                && this.props.currentObservation.datums
                                && this.props.currentObservation.datums.length ?
                                <>
                                    <h1>{labels.oddities_labels.history}</h1>
                                    <div className='bootstrap-table-container'>
                                        <BootstrapTable
                                            striped={true}
                                            bordered={true}
                                            hover={true}
                                            bootstrap4
                                            keyField='id'
                                            data={this.props.currentObservation ? this.props.currentObservation.datums : []}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </div>
                                </>
                                : null}
                        </div>
                    </div>
                </> : <div>{this.props.no_grid_data ? labels.no_grid_data : ''}</div>
        )
    }
}
export default ObservationDetails

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import reducers from './reducers';
import { getRoutes } from './routes';
import * as serviceWorker from './serviceWorker';

import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/scss/font-awesome.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import RouteWithSubRoutes from './utils/RouteWithSubRoutes';
import resolveRedirect from './utils/resolve-redirection';

const middleware = [thunk];

//IE polyfill for filter
Object.values = Object.values ? Object.values : o => Object.keys(o).map(k => o[k]);
//IE polyfill for find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function (predicate) {
      if (this == null) {
        throw TypeError('"this" is null or not defined');
      }
      var o = Object(this);
      var len = o.length >>> 0;
      if (typeof predicate !== 'function') {
        throw TypeError('predicate must be a function');
      }
      var thisArg = arguments[1];
      var k = 0;
      while (k < len) {
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        k++;
      }
      return undefined;
    },
    configurable: true,
    writable: true
  });
}

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", {
    enumerable: false,
    value: function(obj) {
        var newArr = this.filter(function(el) {
          return el == obj;
        });
        return newArr.length > 0;
      }
  });
}

const store = createStore(
  reducers,
  compose(
    applyMiddleware(...middleware)
  ),
);
resolveRedirect();

let routes = getRoutes(store);
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        {routes.map((route, index) => {
          return (
            <RouteWithSubRoutes key={index} {...route} />
          )
        })}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

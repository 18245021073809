import React, { Component } from 'react';
import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';

class NoumenonValue extends Component {
    render() {
        return (
            <div className='noumneonValue'>
                <div className={`modal fade  ${this.props.openValueModal ? 'show' : ''}`} role='dialog' style={{ zIndex: 10000 }}>
                    <div className='modal-dialog modal-dialog-centered modal-lg ml-23'>
                        <div className='modal-content'>
                            <div className='modal-header justify-content-between'>
                                <div className='title'>{this.props.noumenonLabel}</div>
                            </div>
                            <div className='modal-body mh-250 overflow-x'>
                                <p className='result'>{this.props.noumenonValue}</p>
                            </div>
                            <div className='modal-footer justify-space-between mx-auto'>
                                <FormControl type='button' btnType='default' text={labels.analytics_labels.close} onClick={() => this.props.closeValueModel()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NoumenonValue;